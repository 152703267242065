import * as actions from 'network/actions';
import { PostFilterValues } from "modules/posts/models";
import cookie from '../utils/cookie'
import { ApiRequest } from './network';
import { SparrowApi } from './network/adapters/SparrowApi';

const BASE_SUBMISSION_PATH = '/{tenant}/adminapi/v1/submissions'

export const FetchSubmissionPosts = (pageNumber: number, filters: Partial<PostFilterValues>, pageAmount?: number) => {
    let rowsPerPage = pageAmount || cookie.getRowsPerPage();

    return actions.NetworkRequest({
        headers: { "content-type": "application/json" },
        method: "POST",
        url: `${BASE_SUBMISSION_PATH}/paged`,
        queryParams: { "pageAmount": rowsPerPage, "pageNumber": pageNumber },
        body: JSON.stringify({ ...filters })
    });
}

export class SubmissionPostsApi {
    constructor (private _sparrowApi: SparrowApi) {}

    public unsubmitSubmissionPost = (postId: string) => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().post(`${BASE_SUBMISSION_PATH}/unsubmit/${postId}`)
        );
    }
}