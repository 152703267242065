import * as actions from './actions';

interface IRequestBuilder {
    build: () => { init: any, url: string }
    setBaseUrl: (baseURL: string) => void
    addAuthToken: (authToken: string) => void
    addParam: (key: string, value: string) => void
}

class RequestBuilder implements IRequestBuilder {

    private action: actions.NetworkRequest
    private init: RequestInit = {}
    private url: string = ""
    private baseUrl: string = ""

    private headers: any = {}
    private params: any = {}
    private queryParams: any = {}
    private body: any = {}

    constructor(action: actions.NetworkRequest) {
        this.action = action
        this.url = this.action.url
        this.headers = { ...action.headers }
        this.params = { ...this.action.params }
        this.queryParams = { ...this.action.queryParams }
        this.body = typeof this.action.body === "string" ?
            this.action.body : { ...this.action.body }
        
        return this;
    }

    public setBaseUrl(baseURL: string) {
        this.baseUrl = baseURL
        return this;
    }

    public addAuthToken(authToken: string) {
        this.headers.Authorization = "Bearer " + authToken
        return this;
    }

    public addParam(key: string, value: string) {
        this.params[key] = value;
    }

    public build() {
        let urlBuilder = new UrlBuilder(this.baseUrl)
        urlBuilder.addPath(this.url)
        urlBuilder.mapParams(this.params || {})
        urlBuilder.addQueryParams(this.queryParams || {})

        let reqHeaders = new Headers();
        this.init = {
            method: this.action.method,
            mode: "cors",
            signal: this.action.abortSignal
        } as RequestInit

        if (this.body && Object.keys(this.body).length > 0)
            this.init.body = typeof this.body == "string" ? 
                this.body : JSON.stringify(this.body)

        if (this.init.method === "POST" && !this.headers.hasOwnProperty("Content-Type"))
            this.headers["Content-Type"] = "application/json; charset=UTF-8";

        Object.keys(this.headers || {}).forEach((value, index) => {
            if (this.headers[value] !== null)
                reqHeaders.set(value, this.headers[value]);
        });
        
        if (this.action.internal === true) {
            this.init.mode = "same-origin";
            this.init.credentials = "same-origin";
        }

        if (this.action.mode) this.init.mode = this.action.mode
        if (this.action.credentials) this.init.credentials = this.action.credentials as RequestCredentials

        this.init.headers = reqHeaders;

        return { init: this.init, url: urlBuilder.getUrl() }
    }
}

class UrlBuilder {
    private url: string;

    constructor(baseUri: string) {
        this.url = baseUri;
    }

    public addPath(path: string) {
        if (!path.startsWith("/")) throw Error("Invalid Request URL; path must start with /");

        this.url += path;
        return this;
    }

    public mapParams(params) {
        const keys = Object.keys(params);
        for (let i = 0; i < keys.length; i++) {
            if (this.url.indexOf(keys[i]) < 0) throw Error("Invalid Path Parameter");

            this.url = this.url.replace(`{${keys[i]}}`, params[keys[i]]);
        }

        return this;
    }

    public addQueryParams(queryParams: {[key: string]: string}) {
        if(Object.keys(queryParams).length === 0) {
            if (this.url.indexOf("?") < 0) {
                this.url += "?platform=Web";
            } else {
                this.url += "&platform=Web";
            }
            return this;
        }

        let queryString = Object.keys(queryParams)
        .reduce((a, key) => {
            const b = key + "=" + queryParams[key];
            return (a && a.length > 0) ? a + "&" + b : b;
        }, "");
        
        if (this.url.indexOf("?") < 0) {
            this.url += ("?" + queryString);
        } else {
            this.url += ("&" + queryString);
        }

        this.url += "&platform=Web";

        return this;
    }

    public getUrl() {
        return encodeURI(this.url);
    }
}

export default RequestBuilder;
