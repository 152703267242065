import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import * as actions from "../actionCreator";
import ToggleSwitch from "modules/common/components/toggleSwitch";
import TableView, { DataColumn } from "modules/common/components/table-view/components/table-view";
import { Tag } from "modules/posts/models";
import moment from "moment";
import "./styles/externalSourcesSettingsForm.sass";
import { ExternalPostSource, ExternalPostSourceTypes } from "modules/settings/models";
import ExternalPostSourceEditForm from "./externalPostSourceEditForm";

interface ComponentState {
    editingExternalPostSource: ExternalPostSource | null;
    working: boolean;
}

class ExternalSourcesSettingsForm extends React.Component<PropsWithRedux, ComponentState> {
    constructor(props) {
        super(props);
        this.state = { editingExternalPostSource: null, working: false };
    }
    public render() {
        const working = this.props.saving || this.props.fetching;

        const columns = [
            {
                dataKey: "name",
                name: "Name",
                renderer: (rowData: ExternalPostSource, column, selected) => {
                    return <span>{rowData.name}</span>;
                },
                sortable: false,
            },
            {
                dataKey: "type",
                name: "Type",
                renderer: (rowData: ExternalPostSource, column, selected) => {
                    return <span>{this.getExternalResourceTypeName(rowData.sourceType)}</span>;
                },
                sortable: false,
            },
            {
                dataKey: "topics",
                name: "Topics",
                renderer: (rowData: ExternalPostSource, column, selected) => {
                    return (
                        <span>
                            {(rowData.topics || ([] as Tag[]))
                                .map((currTag) => {
                                    return !!currTag ? this.getTagName(currTag.id) : "";
                                })
                                .join(", ")}
                        </span>
                    );
                },
                sortable: false,
            },
            {
                dataKey: "lastChecked",
                name: "Last Check",
                renderer: (rowData: ExternalPostSource, column, selected) => {
                    return (
                        <span>
                            {rowData.lastChecked
                                ? moment(rowData.lastChecked).format("MM/DD/YYYY h:mm A").toString()
                                : rowData.enabled
                                ? "waiting for first check"
                                : "never"}
                        </span>
                    );
                },
                sortable: false,
            },
            {
                dataKey: "enabled",
                name: "Enabled",
                renderer: (rowData: ExternalPostSource, column, selected) => {
                    return (
                        <ToggleSwitch
                            value={rowData.enabled}
                            onClick={() => {
                                this.setExternalSourceEnabled(rowData.id, !rowData.enabled);
                            }}
                            disabled={working}
                        />
                    );
                },
                sortable: false,
            },
        ] as DataColumn[];

        return (
            <>
                <div className="external-sources-settings">
                    <div className="list-controls">
                        <button className="add-btn" onClick={() => this.addNewSource()}>
                            + Create New External Post Source
                        </button>
                    </div>

                    <TableView
                        columns={columns}
                        data={this.props.tenantSettings.externalPostSources || []}
                        noCheckbox={true}
                        forceUpdate={true}
                        onRowClick={this.onRowClicked}
                    />
                </div>
                {!!this.state.editingExternalPostSource && (
                    <ExternalPostSourceEditForm
                        editingExternalPostSource={this.state.editingExternalPostSource}
                        onChange={this.addOrUpdateExternalPostSource}
                        onClose={this.onCloseDialog}
                        onDelete={this.deleteExternalPostSource}
                        working={this.state.working}
                    />
                )}
            </>
        );
    }

    private getTagName = (tagId: string): string | undefined => {
        return this.props.tenantSettings.tagGroups?.find((g) => g.tags.some((t) => t.id === tagId))?.tags.find((t) => t.id === tagId)?.name;
    };
    private addNewSource = () => {
        this.setState((state) => ({
            ...state,
            editingExternalPostSource: {} as ExternalPostSource,
        }));
    };

    private onCloseDialog = () => {
        this.setState((state) => ({
            ...state,
            editingExternalPostSource: null,
        }));
    };

    private onRowClicked = (selectedKey: string) => {
        let selectedSource = this.props.tenantSettings.externalPostSources?.[selectedKey];

        this.setState((state) => ({
            ...state,
            editingExternalPostSource: selectedSource,
        }));
    };

    private addOrUpdateExternalPostSource = (updatedExternalPostSource: ExternalPostSource) => {
        const call = updatedExternalPostSource.id
            ? this.props.updateExternalPostSource(updatedExternalPostSource)
            : this.props.addExternalPostSource(updatedExternalPostSource);
        this.setState({working: true})
        call.then((success) => {
            this.setState({working: false});
            if (success) {
                this.onCloseDialog();
            }
        });
    };

    private deleteExternalPostSource = (deletedExternalPostSource: ExternalPostSource) => {
        this.setState({working: true})
        this.props.deleteExternalPostSource(deletedExternalPostSource.id).then(() => {
            this.setState({working: false})
            this.onCloseDialog();
        });
    };

    private setExternalSourceEnabled = (sourceId: string, enabled: boolean) => {
        this.props.setExternalPostSourceEnabled(sourceId, enabled);
    };

    private getExternalResourceTypeName = (type: ExternalPostSourceTypes): string => {
        switch (type) {
            case ExternalPostSourceTypes.RSS:
                return "RSS";
        }
    };
}

interface ComponentProps {}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        fetching: state.settings.fetching,
        saving: state.settings.saving,
        tenantSettings: state.settings.tenantSettings,
        tenant: state.tenant.id,
    }),
    {
        addExternalPostSource: actions.addExternalPostSource,
        updateExternalPostSource: actions.updateExternalPostSource,
        deleteExternalPostSource: actions.deleteExternalPostSource,
        setExternalPostSourceEnabled: actions.setExternalPostSourceEnabled,
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(ExternalSourcesSettingsForm);
