import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import * as settingsActions from "modules/settings/actionCreator";
import * as resourceActions from "modules/resources/actionCreator";
import { withRouter, RouteComponentProps } from "react-router";

class TenantRouteWrapper extends React.Component<PropsWithRedux, ComponentState> {
    constructor(props: PropsWithRedux) {
        super(props);

        this.state = {
            isLoading: true
        };
    }

    public async componentDidMount() {
        let pathArr = this.props.location.pathname.split(`${this.props.match.url}/`);
        const path = `/${this.props.tenantId}/admin/${pathArr[1] !== undefined ? `${pathArr[1]}` : "dashboard"}${this.props.location.search}`;

        this.props.history.replace(path);

        //Await first call in case the token is expired, if we do not await then it will call all 
        //others and fail the requests with unauthorized. (This is due to the popup for MSAL)
        await this.props.getLCIDMappings();
        await Promise.all([
            this.props.getCurrentUser(),
            this.props.getEmailResource(),
            this.props.getSMSResource(),
            this.props.getMobileResource(),
            this.props.getNotificationSettings(),
            this.props.getTenantConfig()
        ]);

        this.setState({isLoading: false});
    }

    public render() {
        return <div style={{ height: "100%", minHeight: "100%" }}>{!this.state.isLoading ? this.props.children : null}</div>;
    }
}

interface RouteParams {
    tenant: string;
}

interface ComponentState {
    isLoading: boolean;
}

interface ComponentProps {}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps & RouteComponentProps<RouteParams>) => ({
        ...ownProps,
        tenantId: state.tenant.id,
    }),
    {
        getTenantConfig: settingsActions.getTenantSettings,
        getLCIDMappings: resourceActions.getLCIDMappings,
        getEmailResource: resourceActions.getEmailResource,
        getSMSResource: resourceActions.getSMSResource,
        getMobileResource: resourceActions.getMobileResource,
        getCurrentUser: settingsActions.getUserSettings,
        getNotificationSettings: settingsActions.getNotificationSettings,
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default withRouter(connector(TenantRouteWrapper));
