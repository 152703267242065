import { ContentBandStatus, IContentBand, IContentBandConfig, IContentBandInfo } from "modules/contentBands/models";
import { ApiRequest } from "./network";
import { SparrowApi } from "./network/adapters/SparrowApi";
import { AxiosPromise } from "axios";
import { ImageScale } from "modules/posts/models";

export interface IContentBandsApi {
    getContentBands: (imageScale: ImageScale, filters?: IContentBandFilters) => AxiosPromise<IContentBandInfo>;
    saveContentBands: (bands: IContentBand[]) => AxiosPromise<void>;
    getContentBandContent: (
        band: IContentBand,
        imageScale?: ImageScale,
        pageNumber?: number
    ) => AxiosPromise<IContentBand>;
    publishContentBands: (bands: IContentBand[]) => AxiosPromise<void>;
    getContentBandConfig: (status?: ContentBandStatus) => AxiosPromise<IContentBandConfig>;
    saveContentBandConfig: (config: IContentBandConfig) => AxiosPromise<void>;
    publishContentBandConfig: (config: IContentBandConfig) => AxiosPromise<void>;
}

export interface IContentBandFilters {
    statuses: ContentBandStatus[];
    includeContent: boolean;
}

export class ContentBandsApi implements IContentBandsApi {
    constructor(private _sparrowApi: SparrowApi) {}

    getContentBands(imageScale: ImageScale = ImageScale.Mobile, filters?: IContentBandFilters): AxiosPromise<IContentBandInfo> {
        return new ApiRequest<IContentBand[]>(
            this._sparrowApi.TenantRequest().post(`/{tenant}/adminapi/v1/contentbands`, filters, { params: { imageScale } })
        ).catch((err) => {
            throw err;
        });
    }

    saveContentBands(bands: IContentBand[]): AxiosPromise<void> {
        return new ApiRequest<IContentBand[]>(
            this._sparrowApi.TenantRequest().post(`/{tenant}/adminapi/v1/contentbands/draft`, bands)
        ).catch((err) => {
            throw err;
        });
    }

    getContentBandContent(
        band: IContentBand,
        imageScale: ImageScale = ImageScale.Mobile,
        pageNumber: number = 1
    ): AxiosPromise<IContentBand> {
        return new ApiRequest<IContentBand[]>(
            this._sparrowApi.TenantRequest().post(`/{tenant}/adminapi/v1/contentbands/content`, band, {
                params: { pageNumber, imageScale },
            })
        ).catch((err) => {
            throw err;
        });
    }

    publishContentBands(bands: IContentBand[]): AxiosPromise<void> {
        return new ApiRequest(this._sparrowApi.TenantRequest().post(`/{tenant}/adminapi/v1/contentbands/publish`, bands)).catch((err) => {
            throw err;
        });
    }

    getContentBandConfig(status: ContentBandStatus = ContentBandStatus.Draft): AxiosPromise<IContentBandConfig> {
        return new ApiRequest<IContentBandConfig>(
            this._sparrowApi.TenantRequest().get(`/{tenant}/adminapi/v1/contentbands/config`, { params: { status } })
        ).catch((err) => {
            throw err;
        });
    }

    saveContentBandConfig(config: IContentBandConfig): AxiosPromise<void> {
        return new ApiRequest(this._sparrowApi.TenantRequest().post(`/{tenant}/adminapi/v1/contentbands/config`, config)).catch((err) => {
            throw err;
        });
    }

    publishContentBandConfig(config: IContentBandConfig): AxiosPromise<void> {
        return new ApiRequest(this._sparrowApi.TenantRequest().post(`/{tenant}/adminapi/v1/contentbands/config/${config.id}/publish`, config)).catch(
            (err) => {
                throw err;
            }
        );
    }
}
