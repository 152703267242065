import React from 'react';

interface ComponentProps {
    icon?: any;
    title: string;
    handleClick: () => any;
    downloadCsv?: () => any;
}

const ToolboxButton: React.FC<ComponentProps> = ({icon, title, handleClick, downloadCsv}) => {

    const iconStyle: React.CSSProperties = {
        fontSize: '40px', 
        color: "#2196f3", 
        verticalAlign: "middle",
        marginRight: "5px"
    };

    return (
        <React.Fragment>
            <button 
            className="toolbox-button"
            onClick={() => handleClick()}>
                <i 
                className="material-icons"
                style={iconStyle}>
                    {icon}
                </i>
                {title}
            </button>
    </React.Fragment>
    )
}

export default ToolboxButton;