import { ApiRequest } from "./network";
import { SparrowApi } from "api/network/adapters/SparrowApi";
import { IUpdateOption } from "modules/updateOptions/models";

interface IResourcesApi {
    getSharePointPackage(): Promise<IUpdateOption>;
}

export class ResourcesApi implements IResourcesApi {
    constructor(private _sparrowApi: SparrowApi) {}

    async getSharePointPackage(): Promise<IUpdateOption> {
        try {
            const res = await new ApiRequest(
                this._sparrowApi.TenantRequest()
                    .get<IUpdateOption>(`/{tenant}/adminapi/v1/resources/sharepoint/download`)
            );
            return res;
        } catch (error) {
            throw error;
        }
    }
}
