import * as React from 'react';
import { UserTrends } from '../models';
import {injectIntl, IntlShape} from "react-intl";
import Loading from 'modules/common/components/loading';
import EmptyDataMessage from './emptyDataMessage';

interface UserTrendProps {
    loading?: boolean
    userTrends: UserTrends | null;
    intl: IntlShape;
    filterType: string;
}

class UserTrend extends React.Component<UserTrendProps, {}> {

    public render() {
        const useThe = this.props.filterType.toLowerCase().includes("last");
        return <div className="analytics-widget">
            <div className="header">
                User Trends for {useThe ? "the" : ""} <span style={{textTransform: "capitalize"}}>{this.props.filterType}</span>
            </div>
            {this.props.loading ? 
            <div style={{position: "relative", top: "55px"}}>
                <Loading/>
            </div>
            : <div className="body">
                {this.props.userTrends
                ? 
                <>
                    <div className='number-wrapper' style={{height: "100px", paddingTop: "10px"}}>
                        <div>
                            {this.props.userTrends.uniqueUserViewPostEvent ?? 0}
                        </div>
                        <span className='title'>
                            Users that viewed at least one post or event
                        </span>
                    </div>

                    <div className='number-wrapper' style={{height: "100px", paddingTop: "10px"}}>
                        <div>
                            {this.props.userTrends.uniqueUserReactionCommentOnPost ?? 0}
                        </div>
                        <span className='title'>
                            Users reacted or commented on a post
                        </span>
                    </div>

                </>

                : <EmptyDataMessage/> }
            </div>
            }
            </div>
    }
}

interface UserTrendSummary {
    lastMonth: UserMonthTrend
    monthBefore: UserMonthTrend
}

interface UserMonthTrend {
    monthName: string; userCount: number; trend: number;
}

export default injectIntl(UserTrend)
