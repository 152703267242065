import { useEffect, useState } from "react";

import { ICustomCssModelv1 } from "api/files";
import useIsMounted from "../useIsMounted";

type UseCustomLoadCustomCssStylesReturnType = {
    isLoadingStyles: boolean;
}

/**
 * Load custom CSS into document head
 * @param customCss - the css model to load
 * @returns isLoadingStyles - boolean indicating if the styles are loading
 */
export const useLoadCustomCssStyles = (customCss: ICustomCssModelv1 | undefined): UseCustomLoadCustomCssStylesReturnType => {
    const isMounted = useIsMounted();
    const [isLoadingStyles, setIsLoadingStyles] = useState<boolean>(false);

    useEffect(() => {
        if (customCss?.sasUrl) {
            setIsLoadingStyles(true);
            const existingLinkEl = document.querySelector(`link[href="${customCss.sasUrl}"]`);
            
            if (!existingLinkEl) {
                const linkEl = document.createElement("link");
                linkEl.setAttribute("rel", "stylesheet");
                linkEl.setAttribute("type", "text/css");
                linkEl.setAttribute("href", customCss.sasUrl);
                linkEl.setAttribute("id", "custom-portal-page-styles");
    
                // Listen for the load event to know when the CSS has been applied
                linkEl.onload = () => {
                    if (isMounted()) {
                        setIsLoadingStyles(false);
                    }
                };
    
                document.head.appendChild(linkEl);
    
                return () => {
                    document.head.removeChild(linkEl);
                };
            } else {
                if (isMounted()) setIsLoadingStyles(false);
            }
        }
    }, [customCss?.sasUrl, isMounted]);

    return {
        isLoadingStyles
    };
};