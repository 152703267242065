import React from "react";
import { Home, SettingsOutlined } from "@mui/icons-material";

import { FluentPostUpdateIcon } from "modules/common/icons/fluentPostUpdateIcon";
import { FluentCalendarIcon } from "modules/common/icons/fluentCalendarIcon";

import "./styles/teamsPreview.sass";
import { Divider } from "@mui/material";

interface ITeamsPreviewProps {
    displayName: string;
    eventsEnabled: boolean;
}

export const TeamsPreview: React.FC<ITeamsPreviewProps> = ({ displayName, eventsEnabled, children }) => {
    return (
        <div className="teams-preview">
            <div className="teams-logo-bar">
                <img width="36" src="/images/icons/teams_icon.png" alt="Teams icon" />
            </div>
            <div className="tabs">
                <div className="logo">LOGO</div>
                <span className="company-name">{displayName}</span>
                <div className="tab active">Home</div>
                <div className="tab">Activity</div>
                <div className="tab">About</div>
            </div>
            <div className="content-container">
                <div className="side-bar">
                    <div className="item active">
                        <Home htmlColor="#ffffff" className="side-bar-icon" />
                        <span className="side-bar-label">Home</span>
                    </div>
                    <div className="item">
                        <FluentPostUpdateIcon height={20} width={20} />
                        <span className="side-bar-label">My Feed</span>
                    </div>
                    <div className="item">
                        <FluentCalendarIcon height={15} width={15} />
                        <span className="side-bar-label">Events</span>
                    </div>
                    <Divider />
                    <div className="item">
                        <SettingsOutlined htmlColor="#333333" className="side-bar-icon" />
                        <span className="side-bar-label">Preferences</span>
                    </div>
                    <Divider />
                </div>
                <div className="content">{children}</div>
            </div>
        </div>
    );
};
