import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";

import TenantFilter from "../filters/tenantFilter";
import { Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material";

const TenantListing: React.FunctionComponent<ComponentProps> = () => {
    const [textToSearch, setTextToSearch] = React.useState("");
    const [canSearch, setCanSearch] = React.useState(false);

    /* Gave the page some look, functionality is currently disabled until the next iteration. */
    const getFilter = (): JSX.Element => {
        return (
            <TenantFilter 
            textToSearch={textToSearch} 
            canSearch={canSearch}
            searchTextPlaceholder={"Search tenant name or tenant ID"}
            onUpdateTextToSearch={onChangeSearch} 
            />
        );
    }

    /*
    Will be used in the next iteration, this is just set up and ready for now.
    */
    const getList = (): JSX.Element => {
        return (
            <>
                <TableContainer>
                    <Table size="medium">
                        <TableHead>
                            <TableRow></TableRow>
                        </TableHead>
                        <TableBody></TableBody>
                    </Table>
                </TableContainer>
            </>
        );
    };

    const onChangeSearch = (event: any) => {
        if(event.target.value.length > 0) {
            setCanSearch(true)
        } else {
            setCanSearch(false)
        }

        setTextToSearch(event.target.value);
    }
    
    return (
        <>
        {getFilter()}
        {getList()}
        </>
    );
}

interface ComponentProps {}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps
  })
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(TenantListing);