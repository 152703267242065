import * as React from "react";

import { AttendanceType, Attendee } from "../../models";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";

import CloseIcon from "@mui/icons-material/Close";

import "../../styles/dialogs.sass";


class ChangeRSVPStatus extends React.Component<ComponentProps, ComponentState> {
  constructor(props: ComponentProps) {
    super(props);

    this.state = {
      attendanceType: ""
    };
  }

  componentDidUpdate(prevProps: ComponentProps) {
    if (this.props.show && !prevProps.show)
      this.setState({
        attendanceType: ""
      });
  }

  public render() {
    if (!this.props.attendees || (this.props.attendees.length < 2))
      return <React.Fragment></React.Fragment>;

    return (
      <Dialog open={this.props.show} maxWidth={false} onClose={this.onClose}>
        <DialogTitle className="events-dialog-header">
          <div className="events-dialog-header-title">
            <div>
              <Typography variant="h2">Change RSVP Status</Typography>
              <Typography variant="subtitle2">{this.props.attendees.length} Selected Participants</Typography>
            </div>
            <IconButton onClick={this.onClose} size="large">
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent className="manage-attendance-content">
          <div>
            <div className="emphasis">Change RSVP status to:</div>
            <div>
              <Select
                variant="outlined"
                value={this.state.attendanceType || ""}
                fullWidth
                onChange={this.onChangeAttendanceType}
              >
                <MenuItem value="InPerson">Attending in-person</MenuItem>
                <MenuItem value="Online">Attending online</MenuItem>
                <MenuItem value="Waitlist">Waitlist</MenuItem>
                <MenuItem value="NotAttending">Not Attending</MenuItem>
              </Select>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="events-dialog-footer">
          <Button variant="text" color="primary" onClick={this.onClose}>Cancel</Button>
          <Button variant="contained" color="primary" disabled={!this.state.attendanceType || (this.state.attendanceType === "Undecided")} onClick={this.onApply}>Save</Button>
        </DialogActions>
      </Dialog>
    );
  }

  private onApply = () => {
    this.props.onApply(this.state.attendanceType as AttendanceType);
  }

  private onChangeAttendanceType = (event) => {
    this.setState({ attendanceType: event.target.value });
  }

  private onClose = () => {
    this.props.onClose();
  }
}


interface ComponentProps {
  show: boolean;
  attendees: Attendee[];
  onApply: (attendanceType: AttendanceType) => void;
  onClose: () => void;
}

interface ComponentState {
  attendanceType: AttendanceType | "";
}

export default ChangeRSVPStatus;