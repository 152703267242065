import React from "react";

import { IContentBand } from "../../models";
import {
    AuthoringAppBarAction,
    AuthoringAppBarActions,
    IAuthoringAppBarAction,
} from "modules/common/components/authoring/authoringAppBarActions";

interface IContentBandsEditorActionsProps {
    contentBands: IContentBand[];
    onSave: () => void;
    onDiscard: () => void;
    onPreview: () => void;
    onBack: () => void;
    onToggleSettingsPanel: () => void;
    onPublish: () => void;
    isDirty: boolean;
    disable?: boolean; // flag to disable everything
}

export const ContentBandsEditorActions: React.FunctionComponent<IContentBandsEditorActionsProps> = ({
    contentBands,
    isDirty,
    disable,
    onSave,
    onToggleSettingsPanel,
    onDiscard,
    onPublish,
    onPreview,
    onBack,
}) => {
    const isPreviewDisabled = disable || contentBands.length === 0;
    const isPublishedDisabled = disable || contentBands.length === 0;
    const isSaveDisabled = disable || !isDirty;
    const isDiscardDisabled = disable || !isDirty;

    const getActions = (): Record<AuthoringAppBarAction, IAuthoringAppBarAction> => ({
        [AuthoringAppBarAction.Publish]: {
            onClick: onPublish,
            disabled: isPublishedDisabled,
        },
        [AuthoringAppBarAction.Save]: {
            disabled: isSaveDisabled,
            onClick: onSave,
        },
        [AuthoringAppBarAction.Preview]: {
            disabled: isPreviewDisabled,
            onClick: onPreview,
        },
        [AuthoringAppBarAction.ToggleSettingsPanel]: {
            disabled: false,
            onClick: onToggleSettingsPanel,
            tooltip: "Content band settings"
        },
        [AuthoringAppBarAction.Discard]: {
            disabled: isDiscardDisabled,
            onClick: onDiscard,
        },
        [AuthoringAppBarAction.Back]: {
            disabled: disable || false,
            onClick: onBack,
        },
    });

    return <AuthoringAppBarActions actions={getActions()} />;
};
