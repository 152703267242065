import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { GlobalApplicationState } from 'globalApplicationState';
import Button from "@mui/material/Button";

import Tenor from 'react-tenor';
import 'react-tenor/dist/styles.css'
import { BasicTinyMce } from 'modules/common/components/basicTinyMce';

export interface IEditCommentProps {
    text: string;
    onEdit: (bodyFormatted: string, bodyPlain: string) => Promise<boolean>;
    onCancel: () => void;
    editButtonText: string;
    cancelText: string;
    disabled?: boolean;
}

export interface IEditCommentState {
    bodyPlaintext: string;
    bodyFormatted: string;
    charsCount: number;
    snapshot: string;
}

export class EditComment extends React.Component<PropsWithRedux, ComponentState> {
    constructor(props: PropsWithRedux) {
        super(props);

        const { bodyPlain, bodyFormatted } = props;

        this.state = {
            bodyPlain: bodyPlain,
            bodyFormatted: bodyFormatted,
            charsCount: bodyPlain ? bodyPlain.length : 0,
            snapshot: bodyFormatted,
            gifsVisible: false,
            imageVisible: false,
            editorRef: React.createRef<any>(),
            gifRef: React.createRef<any>()
        };
    }

    public render() {
        const { commentMaxLength, onCancel, savingComment } = this.props;
        const { bodyPlain, bodyFormatted, charsCount, gifsVisible, editorRef, gifRef } = this.state;

        let onlyWhitespace = true;
        if (editorRef.current != null)
            onlyWhitespace = (bodyPlain.trim() === "" && editorRef.current.getContent().match(/<img id="inserted-tenor-gif"/g) === null)

        return (
            <div className="edit-comment">
                {gifsVisible ?
                    <div className="tenor-positioning">
                        <Tenor onSelect={(result) => this.addGif(result)} token={'A9HCCDQ5VCW0'} defaultResults={true} autoFocus={true} limit={8} contentFilter={"high"} />
                    </div>
                    : null}
                <BasicTinyMce
                    isGifsVisible={gifsVisible}
                    onChangeIsGifsVisible={(value: boolean) => this.setState({ ...this.state, gifsVisible: value })}
                    editorRef={editorRef}
                    gifRef={gifRef}
                    id="edit-comment-tiny"
                    value={bodyFormatted}
                    onChange={this.handleTinyText}
                    disabled={savingComment}
                />
                <div>
                    <div>
                        <Button variant="contained" color="primary" disabled={savingComment || charsCount === 0 || onlyWhitespace || gifsVisible} onClick={this.onEdit}>Save</Button>
                        {onCancel &&
                            <Button variant="text" color="primary" style={{marginLeft: "10px"}} disabled={savingComment} onClick={onCancel}>Discard</Button>
                        }
                    </div>
                    <div className="maximum-word-count">{charsCount}/{commentMaxLength}</div>
                </div>
            </div>
        );
    }

    private handleTinyText = (event, editor) => {
        var count = (editor.getContent({ format: 'text' }).length + ((editor.getContent().match(/<img id="inserted-tenor-gif"/g) ||
            editor.getContent().match(/<img id="inserted-image"/g)) || []).length)

        this.setState({ ...this.state, bodyFormatted: editor.getContent(), bodyPlain: editor.getContent({ format: 'text' }), charsCount: count })
    }

    private onEdit = () => {
        var plain = this.state.bodyPlain;
        if ((this.state.editorRef.current.getContent().match(/<img id="inserted-tenor-gif"/g)?.length > 0) && this.state.bodyPlain === "") {
            plain = "📷 GIF";
        }
        this.props.onEdit(this.state.bodyFormatted, plain, this.state.snapshot).then((response) => {
            if(this.props.confirmCallback)
                this.props.confirmCallback();

            if (response) {
                this.props.onCancel();
            }
        })
    }

    private addGif = (result) => {
        this.state.editorRef.current.insertContent('<img id="inserted-tenor-gif" style="max-width: 200px; max-height: 150px; display: inline-block" src=' + result.media[0].tinygif.url + ' alt="gif inserted from tenor"/>');
        if (this.state.editorRef.current.getContent({ format: 'text' }).length === 0) {
            this.setState({ charsCount: 1, bodyPlain: "📷 GIF" })
        }

        var editedState = this.state.editorRef;
        editedState.current.options.set('tenor_visible', false);

        var toggledOffGif = this.state.gifRef;
        toggledOffGif.current.setActive(false);

        this.setState({ gifsVisible: false, editorRef: editedState, gifRef: toggledOffGif })
    }
}


interface ComponentProps {
    bodyPlain: string;
    bodyFormatted: string;
    onEdit: (bodyFormatted: string, bodyPlain: string, snapshot: string) => Promise<boolean>;
    onCancel: () => void;
    confirmCallback?: () => void;
}

interface ComponentState {
    bodyPlain: string;
    bodyFormatted: string;
    charsCount: number;
    snapshot: string;
    gifsVisible: boolean;
    imageVisible: boolean;
    editorRef: any;
    gifRef: any;
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        commentMaxLength: state.settings.tenantSettings.commentMaxLength,
        savingComment: state.posts.postView.savingComment,
    }),
);
type PropsWithRedux = ConnectedProps<typeof connector>;
export default connector(EditComment);
