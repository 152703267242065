import * as React from "react";


import { ILanguage } from "modules/localization/models";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, MenuItem, Select } from "@mui/material";

interface ComponentProps {
    show: boolean;
    currentLanguagesWithText: ILanguage[];
    onConfirm: (lcid: string) => void;
    onCancel: () => void;
  }

  interface ComponentState {
    currentSelectLanguage: string;
    allLanguages: ILanguage[];
  }

class TranslateContentConfirmation extends React.Component<ComponentProps, ComponentState> {
    public render() {
        return (
        <Dialog open={this.props.show} onClose={this.props.onCancel}>
            <DialogTitle>{"Translate Content"}</DialogTitle>
            <DialogContent>
            <DialogContentText style={{ color: "black" }}>Your post has multiple translations. Which language would you like to translate from?</DialogContentText>
                <FormControl variant="outlined" fullWidth size="small" style={{ paddingTop: "2%", paddingBottom: "2%"}}>
                    <Select
                        fullWidth
                        variant="outlined"
                        autoFocus
                        defaultValue={ this.props.currentLanguagesWithText.length > 0 ? this.props.currentLanguagesWithText[0].lcid : "" }
                        onBlur={(e) => this.setSelectedValue(e)}
                    >
                        {this.props.currentLanguagesWithText.length > 0 && this.props.currentLanguagesWithText.map((language) => (
                            <MenuItem key={language.lcid} value={language.lcid}>{language.language}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
            <Button variant="text" style={{ color: "grey" }} onClick={this.onCancel}>Cancel</Button>
            <Button variant="contained" color="primary" onClick={this.onConfirm}>Translate</Button>
            </DialogActions>
        </Dialog>
        );
    }

    private setSelectedValue = (event) => {
        this.setState({currentSelectLanguage: event.target.value})
    }

    private onConfirm = () => {
        this.props.onConfirm(this.state.currentSelectLanguage);
    }

    private onCancel = () => {
        this.props.onCancel();
    }

}

export default TranslateContentConfirmation;
