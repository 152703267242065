import * as React from "react";
import Modal from "modules/common/components/modal";
import './audiences.sass'
import { Audience } from "../models";

interface ComponentProps {
    show: boolean
    deletingAudience: { id: string, name: string }
    audienceList: Audience[]
    closeModal: () => void
    deleting: boolean
    deleteAndReplace: (deleteId: string, replaceId: string) => void
}

interface ComponentState {
    replaceSelection: { id: string, name: string }
}

export default class DeleteReplaceModal extends React.PureComponent<ComponentProps, ComponentState> {

    constructor(props) {
        super(props);
        this.state = { 
            replaceSelection: { id: "", name: "" }
        }
    }

    public render() {
        
        let audienceList = this.props.audienceList.filter(a => a.id !== this.props.deletingAudience.id)
        
        return   (<Modal active={this.props.show}
                    isWorking={this.props.deleting}
                    title={"Delete and Replace Audience"}
                    showControls={true}
                    onCloseClick={this.handleModalCloseClick}
                    buttons={[
                        { title: "Replace", workingTitle: "processing...", isWorking: this.props.deleting, isPrimary: true, isEnabled: !this.props.deleting && this.state.replaceSelection.id !== "", 
                            onClick: () => { this.props.deleteAndReplace(this.props.deletingAudience.id, this.state.replaceSelection.id) } 
                        },
                    ]}>
                    <div>
                        <p>The audience you are trying to delete is currently in use by an existing topic.</p>
                        <p>Please pick from these enabled audiences to replace the one you wish to delete </p>
                        <div style={{display: "flex", marginTop: "40px"}}>
                            <label className="audience-title" style={{marginBottom: "0px", marginRight: "10px"}}>Audience being deleted:</label>
                            <input className="form-control"
                                autoComplete="off"  
                                value={this.props.deletingAudience.name}
                                style={{width: "200px"}}
                                readOnly 
                            />
                        </div>
                        <div style={{display: "flex", marginTop: "10px"}}>
                            <label className="audience-title" style={{marginBottom: "0px", marginRight: "10px"}}>Replace with:</label>
                            <input className="form-control"
                                autoComplete="off"  
                                value={this.state.replaceSelection.name}
                                style={{width: "200px"}}
                                readOnly 
                            />
                        </div>
                        <div className="results delete" style={{height: "400px", overflowY: "auto"}}>
                          {
                                audienceList.map(a => {
                                    return <div key={a.id} className="item">
                                          {a.displayName}
                                          <span className="add-btn" onClick={() => this.handleInputChange(a)}>
                                            <i className="material-icons">add</i>
                                          </span>  
                                        </div>                                 
                                })
                          }
                          </div>
                       
                    </div>
                   
                </Modal>);
    }

    private handleModalCloseClick = () => {
        this.props.closeModal()
    }

    private handleInputChange = (a: Audience) => {
        this.setState({
            ...this.state,
            replaceSelection: { id: a.id, name: a.displayName }
        })
    }
}
