import * as React from "react"
import SurveyListing from "./surveyListing";

import BasePage from "pages/common/basePage";
import Header from "pages/common/header";
import MainContent from "pages/common/mainContent";

class SurveyManagementPage extends React.Component<{}, {}> {
    public render() {
        return (
            <BasePage fullWidth>
                <Header
                    title="Manage Surveys"
                />
                <MainContent>
                    <div className="surveys-page">
                        <SurveyListing />
                    </div>
                </MainContent>
            </BasePage>
        );
    }

}

export default SurveyManagementPage;
