import * as React from 'react';

interface ComponentProps { onChange: () => void }
interface ComponentState { }

export default class WindowResizeListener extends React.PureComponent<ComponentProps, ComponentState> {
    private _resizeDebounceToken: number | null = null;
    private _resizeDebounceTimeout = 50;

    public componentDidMount() {
        window.addEventListener('resize', this.handleWindowResize);
        window.addEventListener('transitionend', this.handleTransitionEnd);
    }

    public componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
        window.removeEventListener('transitionend', this.handleTransitionEnd)
    }

    public render() {
        return (
            <></>
        );
    }

    /**
     * On transition end of drawer opening or closing emit resize event
     * @param e
     */
    private handleTransitionEnd = (e: any) => {
        if (e.target.id === "adminAppNav") {
            this.handleWindowResize();
        }
    }

    private handleWindowResize = () => {
        if (this._resizeDebounceToken != null) {
            window.clearTimeout(this._resizeDebounceToken);
        }
        this._resizeDebounceToken = window.setTimeout(() => {
            this.props.onChange();
        }, this._resizeDebounceTimeout);
    }
}
