import React, { useEffect, useState } from "react";
import { RouteComponentProps, useHistory, withRouter } from "react-router-dom";

import BasePage from "../common/basePage";
import Header from "../common/header";
import MainContent from "../common/mainContent";
import PublicFeed from "modules/posts/components/post-feed/publicFeed";
import Loading from "modules/common/components/loading";
import { useIsAuthenticated } from "@azure/msal-react";
import useIsMounted from "modules/common/hooks/useIsMounted";
import tenantManagementUtils, { ISelectOpt } from "utils/tenantManagementUtils";
import ErrorSnackbar from "modules/common/components/snackbars/errorSnackbar";

const PublicPostsPage: React.FunctionComponent<RouteComponentProps<RouteParams>> = ({
    ...props
}) => {
    const [fetching, setFetching] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = useState<string>();
    const isAuthenticated = useIsAuthenticated();
    const history = useHistory();
    const isMounted = useIsMounted();
    const { tenant: requestTenantId, postId } = props.match.params;

    useEffect(() => {
        // grab authenticated user's tenants and see if they match the public post's
        // if so, send user to private feed
        const loadTenants = async () => {
            try {
                setFetching(true)

                const res: ISelectOpt[] = await tenantManagementUtils.setupTenantUserData();
                const userBelongsToTenant = res.some((opt: ISelectOpt) => opt.value === requestTenantId);

                if (userBelongsToTenant)
                    history.push(`/${requestTenantId}/posts/${postId}`);
            } catch (err) {
                setErrorMessage("An error has occured. Please try again later.");
            }
            finally {
                setFetching(false);
            }
        };

        if (isAuthenticated)
            loadTenants();
        else
            setFetching(false);
    }, [isMounted, history, isAuthenticated, requestTenantId, postId]);

    return (
        <BasePage fullWidth>
            <Header
                title="Public Feed"
            />
            <MainContent addPadding>
                {fetching
                    ? <Loading />
                    : <PublicFeed tenantId={requestTenantId} selectedPostId={postId} />}
            </MainContent>
            {errorMessage && <ErrorSnackbar errorMessage={errorMessage} clearErrorMessage={() => setErrorMessage(undefined)} />}
        </BasePage>
    );
}

interface RouteParams {
    tenant: string;
    postId: string;
}

export default withRouter(PublicPostsPage);
