import { CustomFileData, MediaFile, Image } from "./models"

export const ROOT_FOLDER_ID = 'rootFolder';

export const asChonkyFileData = (images: Image[], folders: MediaFile[]): CustomFileData[] => {
    let resultList = [] as CustomFileData[];

    if (folders.length)
        resultList.push(
            ...folders.map(x => {
                const childrenIdArrays = Object.values(x.childrenIds);
                const mergedChildrenIds = ([] as string[]).concat(...childrenIdArrays);
            
                return {
                    id: x.id,
                    isDir: true,
                    name: x.name,
                    parentId: x.parentId,
                    childrenIds: mergedChildrenIds,
                    modDate: x.updatedTime?.date ?? x.createdTime.date,
                };
            })
        );
    
    if (images.length)
        resultList.push(
            ...images.map(x => {
                return {
                    id: x.id,
                    isDir: false,
                    name: x.name,
                    parentId: x.parentId,
                    thumbnailUrl: x.url,
                    modDate: x.updatedTime?.date ?? x.createdTime.date,
                    size: x.fileSize,
                };
            })
        );

    return resultList;
}

export const asChonkyFileMap = (mediaFiles: CustomFileData[], currDir?: MediaFile): Record<string, CustomFileData> => {
    let fileMap = mediaFiles.length ? Object.fromEntries(mediaFiles.map(x => [x.id, x])) : {};

    if (currDir) {
        let currDirChildIds = new Set(currDir.childrenIds);

        fileMap[currDir.id] = {
            id: currDir.id,
            name: currDir.name,
            // map out the correct ordering of the files using mediaFiles
            childrenIds: mediaFiles.filter(x => currDirChildIds.has(x.id)).map(x => x.id)
        };
    }

    fileMap[ROOT_FOLDER_ID] = {
        id: ROOT_FOLDER_ID,
        name: "Media Gallery",
        childrenIds: currDir ? [] : Object.values(fileMap).filter(x => !x.parentId)?.map(x => x.id),
        isDir: true,
    }

    return fileMap;
}

export const imageExtFromMimeType = (mimeType?: string): string => {
    let imageExt = "";

    if (mimeType === "image/jpg" || mimeType === "image/jpeg") {
        imageExt = ".jpg";
    }
    else if (mimeType === "image/png") {
        imageExt = ".png";
    }

    return imageExt;
}