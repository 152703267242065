import React from "react";

import { IReadabilityOptionModel, IReadabilitySettings, IReadabilitySettingsOptionModel } from "../models";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";

import "./styles/readabilitySettings.sass";

interface IReadabilitySettingsProps {
    options?: IReadabilitySettingsOptionModel;
    selections: IReadabilitySettings;
    enableDefaults?: boolean;
    enableInternalAverages?: boolean;
    internalAverages?: IReadabilitySettings;
    defaults: IReadabilitySettings | undefined;
    onChange: (newSelections: IReadabilitySettings) => void;
}

/**
 * List of selects for readability settings
 */
const ReadabilitySettings: React.FunctionComponent<IReadabilitySettingsProps> = ({
    selections,
    options,
    defaults,
    internalAverages,
    enableDefaults = false,
    enableInternalAverages = false,
    onChange,
}) => {
    const onChangeLocal = (settingKey: string, selection: string) => {
        let newSelections = { ...selections };

        newSelections[settingKey] = selection;

        onChange(newSelections);
    }

    const isDefault = (settingKey: string, option: IReadabilityOptionModel): boolean => {
        if (!enableDefaults) return false;

        // global default
        let result: boolean = option.isDefault;

        // check if tenant has a default (this will be the same as global if tenant hasn't saved settings yet)
        if (defaults) {
            result = defaults[settingKey] === option.name;
        }

        return result;
    }

    const isInternalAverage = (settingKey: string, option: IReadabilityOptionModel): boolean => {
        if (!enableInternalAverages) return false;

        let result: boolean = false;

        if (internalAverages) {
            result = internalAverages[settingKey] === option.name;
        }

        return result;
    }

    const renderSetting = (settingKey: string, label: string, options: IReadabilityOptionModel[], selection: string) => {
        return (
            <div className="readability-setting-container">
                <label>{label}</label>
                <Select
                    id={`${settingKey}-select`}
                    fullWidth
                    variant="outlined"
                    value={selection}
                    onChange={(event: SelectChangeEvent) => onChangeLocal(settingKey, event.target.value as string)}
                    classes={{
                        select: "readability-select"
                    }}
                >
                    {options && options.map((opt: IReadabilityOptionModel) => (
                        <MenuItem key={`${opt.name}-opt`} value={opt.value}>
                            {opt.name} {isDefault(settingKey, opt) && "(Default)"} {isInternalAverage(settingKey, opt) && "(Internal Average)"}
                        </MenuItem>))}
                </Select>
            </div>
        )
    }

    return (
        <div id="readability-settings">
            {options?.readability && renderSetting("readability", "Readability", options.readability, selections.readability)}
            {options?.tone && renderSetting("tone", "Tone", options.tone, selections.tone)}
            {options?.personalism && renderSetting("personalism", "Personalism", options.personalism, selections.personalism)}
            {options?.sentiment && renderSetting("sentiment", "Sentiment", options.sentiment, selections.sentiment)}
            {options?.gender &&  renderSetting("gender", "Gender", options.gender, selections.gender)}
            {options?.length && renderSetting("length", "Length", options.length, selections.length)}
        </div>
    );
}

export { ReadabilitySettings };
