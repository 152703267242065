import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import * as actions from "../../actionCreator";
import { GlobalApplicationState } from "globalApplicationState";

import { SaveNewsletterModelStateErrors } from "modules/newsletters/models";

import InfoHover from "modules/common/components/hovers/infoHover";
import LoadingOverlay from "modules/common/components/loadingOverlay";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";

import CloseIcon from "@mui/icons-material/Close";

import moment from "moment";


import "../../styles/dialogs.sass";


class SendTestEmailDialog extends React.Component<PropsWithRedux, ComponentState> {
  constructor(props: PropsWithRedux) {
    super(props);

    this.state = {
      additionalAddresses: ""
    };
  }

  public componentDidMount() {
    moment.locale("en");
  }

  public render() {
    return (
      <Dialog open={this.props.show} maxWidth={false} scroll="paper" onClose={this.props.onClose} classes={{ paper: "newsletter-dialog" }}>
        <DialogTitle className="newsletter-dialog-title">
          <div>
            <span>Send test email</span>
            <InfoHover>Sending a test email will give you a real-time preview of the newsletter issue that uses the current settings and placeholder posts and events.</InfoHover>
          </div>
          <IconButton onClick={this.props.onClose} size="large">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div className="test-email-details">
            <div>
              <div>Newsletter</div>
              <div>{this.props.newsletterTitle}</div>
            </div>
            {/* {!!this.props.availableIssues && <div>
              <div>Issue</div>
              <div><FormControl fullWidth size="small">
                <Select
                  variant="outlined"
                  MenuProps={selectMenuProps}
                  value={this.state.selectedIssueDate || this.props.issueDate}
                  onChange={(e) => this.setState({selectedIssueDate: e.target.value as string})}
                >
                  {
                    this.props.availableIssues.map((issueDate) =>
                    <MenuItem key={issueDate} value={issueDate}>{moment(issueDate).format("MMM D, YYYY")}</MenuItem>
                  )}
                </Select>
              </FormControl>
              </div>
            </div>} */}
            <div>
              <div>To</div>
              <div>
                {this.props.userEmail}
              </div>
            </div>
            <div>
              <div>CC</div>
              <div>
              <TextField
                  variant="outlined"
                  size="small"
                  value={this.state.additionalAddresses}
                  placeholder="Enter email address(es), separate with commas"
                  fullWidth
                  autoComplete="off"
                  onChange={(event) => this.setState({additionalAddresses:event.target.value})}
                />
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="newsletter-dialog-actions">
          <div></div>
          <div>
            <Button
              variant="text"
              color="primary"
              onClick={this.props.onClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={this.onSend}
            >
              Send
            </Button>
          </div>
        </DialogActions>
        <LoadingOverlay show={this.state.isSending || false} styles={{ paddingTop: 150 }} />
      </Dialog>
    );
  }

  private onSend = () => {
    const additionalAddresses: string[] = this.state.additionalAddresses.split(/[,; ]/).filter(s=>!!s);
    const issueDate = !!this.state.selectedIssueDate ? moment(this.state.selectedIssueDate) : (!!this.props.issueDate ? moment(this.props.issueDate) : moment());
    this.props.sendTestEmail(this.props.newsletterId, issueDate.format("YYYY-MM-DD"), additionalAddresses).then(result =>{
      if(!!result.success){
        this.props.onSuccess("Test email sent");
        this.props.onClose();
      }else if(!!result.error){
        this.props.onError(result.error.ModelState);
      }
    });
  }
}

interface ComponentProps {
  show: boolean;
  issueDate?: string;
  availableIssues?: string[];
  newsletterId: string;
  newsletterTitle: string;
  onClose: () => void;
  onSuccess: (message: string) => any;
  onError: (modelErrors: SaveNewsletterModelStateErrors) => any;
}

interface ComponentState {
  additionalAddresses: string;
  selectedIssueDate?: string;
  isSending?: boolean;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    userEmail: state.settings.currentUser.email
  }),
  {
    sendTestEmail: actions.sendTestEmail
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(SendTestEmailDialog);