import React from "react";

import InfoHover from "modules/common/components/hovers/infoHover";

import { NewsletterFutureIssueDetailModel, NewsletterIssueInput, SaveNewsletterIssueModelStateErrors } from "../../models";

import TextField from "@mui/material/TextField";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import moment from "moment";
import _ from "lodash";


class IssueDetails extends React.Component<ComponentProps, ComponentState> {

  constructor(props:ComponentProps){
    super(props);
    this.state = {};
    const now = new Date();
    this._today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  }

  private _today : Date;

  public componentDidMount() {
    moment.locale("en");
  }

  public render() {
    const { issue, issueFromServer} = this.props;

    return (
      <div className="newsletter-issue-section newsletter-issue-details">
        <div>
          <div>Newsletter</div>
          <div className="newsletter-title">{this.props.newsletterTitle}</div>
        </div>
        {!!issueFromServer && !!issueFromServer.sendDateTime &&
          <div>
            <div>Original date of issue</div>
            <div className="newsletter-issue-date">{issueFromServer.sendDateTime ? moment(issueFromServer.sendDateTime).format("MMM D, YYYY, h:mmA") : ""}</div>
          </div>
        }
        <div>
          <div>Send issue on</div>
          <div>
            <div>
              <div>
                <DatePicker
                    format={"MMM dd yyyy"}
                    value={Date.parse(!!issue.overriddenIssueDateTime ? issue.overriddenIssueDateTime : issue.sendDateTime) || null}
                    slotProps={{ 
                        textField: { 
                          size: "small", 
                          placeholder: "", 
                          inputProps: { readOnly: true },
                          error: _.some(this.props.issueModelErrors?.OverriddenIssueDateTime),
                          helperText: _.first(this.props.issueModelErrors?.OverriddenIssueDateTime)
                        },
                        openPickerIcon: { color: "primary" }
                    }}
                    onChange={this.onChangeIssueDate}
                    className="date-picker"
                    shouldDisableDate={this.shouldDisableDate}
                />
                <TextField
                  variant="outlined"
                  size="small"
                  color="primary"
                  type="time"
                  value={(issue.overriddenIssueDateTime ? moment(issue.overriddenIssueDateTime).format("HH:mm") : moment(issue.sendDateTime).format("HH:mm")) || ""}
                  inputProps={{
                    step: 900
                  }}
                  onChange={this.onChangeIssueTime}
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div>Subject<span className="required-coloring">*</span></div>
          <div>
            <TextField
              variant="outlined"
              size="small"
              value={issue.emailSubject || ""}
              placeholder="Ex. Here's what you missed!"
              fullWidth
              error={!issue.emailSubject || _.some(this.props.issueModelErrors?.EmailSubject)}
              helperText={_.first(this.props.issueModelErrors?.EmailSubject)}
              autoComplete="off"
              inputProps={{
                maxLength: 78
              }}
              onChange={this.onChangeEmailSubject}
            />
          </div>
        </div>
        <div>
          <div>
            <span>Preheader</span>
            <InfoHover>The <span className="emphasis">Preheader</span> is the text following the subject line when an email is previewed.</InfoHover>
          </div>
          <div>
            <TextField
              variant="outlined"
              size="small"
              value={issue.emailPreheader || ""}
              placeholder="Here's what you missed in Flightward!"
              fullWidth
              error={_.some(this.props.issueModelErrors?.EmailPreheader)}
              helperText={_.first(this.props.issueModelErrors?.EmailPreheader)}
              autoComplete="off"
              inputProps={{
                maxLength: 250
              }}
              onChange={this.onChangeEmailPreheader}
            />
          </div>
        </div>
      </div>
    );
  }

  
  private shouldDisableDate = (day: Date) => {
    if(day < this._today) return true;

    if(!!this.props.issue.earliestOverrideDateTime && day < moment(this.props.issue.earliestOverrideDateTime).toDate())
      return true;
    
    if(!!this.props.issue.latestOverrideDateTime && day > moment(this.props.issue.latestOverrideDateTime).toDate())
      return true;
    
    return false;
  };

  private onChangeEmailPreheader = (event) => {
    this.props.onChange({ emailPreheader: event.target.value });
  }

  private onChangeEmailSubject = (event) => {
    this.props.onChange({ emailSubject: event.target.value });
  }

  private onChangeIssueDate = (date) => {
    const updatedDate: string = moment(date).toISOString();
    this.props.onChange({ overriddenIssueDateTime: updatedDate });
  }

  private onChangeIssueTime = (event) => {
    const times: number[] = event.target.value.split(":");
    const updatedDate: string = moment(this.props.issue.overriddenIssueDateTime || this.props.issue.sendDateTime).set("hour", times[0]).set("minutes", times[1]).toISOString();
    this.props.onChange({ overriddenIssueDateTime: updatedDate });
  }
}
interface ComponentProps {
  issue: NewsletterFutureIssueDetailModel;
  issueFromServer?: NewsletterFutureIssueDetailModel;
  issueModelErrors?: SaveNewsletterIssueModelStateErrors | null;
  newsletterTitle: string;
  onChange: (issue: Partial<NewsletterIssueInput>) => void;
}

interface ComponentState {}

export default IssueDetails;