import React from "react";

import { AudienceItem } from "../../models";

import MoreOptionsIconButton from "modules/common/components/buttons/moreOptionsIconButton";

import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import MoreOptionsItem from "modules/common/components/moreOptionsItem";

const MoreAudienceOptions: React.FunctionComponent<ComponentProps> = props => {
  const { audience } = props;

  return (
    <MoreOptionsIconButton>
      <List disablePadding>
        <MoreOptionsItem
          text="Edit audience details"
          onClick={() => {
            props.onEditAudience(audience.id);
            props.onSelection();
          }}
        />
        <Divider light />
        {audience.state === "enabled" &&
          <MoreOptionsItem
            text="Disable"
            onClick={() => {
              props.onDisableAudience(audience.id);
              props.onSelection();
            }}
          />
        }
        {audience.state !== "enabled" &&
          <MoreOptionsItem
            text="Enable"
            onClick={() => {
              props.onEnableAudience(audience.id);
              props.onSelection();
            }}
          />
        }
        <MoreOptionsItem
          text="Delete"
          onClick={() => {
            props.onDeleteAudience(audience.id);
            props.onSelection();
          }}
        />
        <Divider light />
        <MoreOptionsItem
          text="Download user data"
          onClick={() => {
            props.onDownloadUserData(audience.id, audience.title);
          }}
        />
      </List>
    </MoreOptionsIconButton>
  );
}

interface ComponentProps {
  audience: AudienceItem;
  onDeleteAudience: (id: string) => void;
  onDisableAudience: (id: string) => void;
  onDownloadUserData: (id: string, title: string) => void;
  onEditAudience: (id: string) => void;
  onEnableAudience: (id: string) => void;
  onSelection: () => void;
}

export default MoreAudienceOptions;