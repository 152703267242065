import React, { useState } from "react";

import Callout from "modules/common/components/callout";
import Loading from "modules/common/components/loading";

import { AudienceInvite, AudienceInviteSuggestions } from "../../../models";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";

import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";


const SearchInvites: React.FunctionComponent<ComponentProps> = props => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [results, setResults] = useState([] as AudienceInviteSuggestions[]);
  const [searchText, setSearchText] = useState("");
  const [showCallout, setShowCallout] = useState(false);
  
  const onKeyPress = (key) => {
    if (key.keyCode === 13 && !!searchText) {
      setIsSearching(true);
      setResults([]);
      setShowCallout(true);
      props.onSearch(searchText).then((userSuggestions) => {
        setResults(userSuggestions);
        setIsSearching(false);
      });
    }
  }

  const loadOptions = (result) => {
    if(result.isIncluded || props.includedInvites.findIndex(invite => invite.inviteId === result.id) !== -1)
    {
      return (<div>
        <IconButton
          size="small"
          onClick={() => {
            props.onRemoveUser(result.id);
          }}
          className="remove-icon"
        >
          <ClearIcon />
        </IconButton>
      </div>)
    }
     else {
      return (
      <div>
        <Button
          variant="text"
          color="primary"
          onClick={() => {
            props.onAddUser(result);
          }}
          className="add-button"
        >
          Add
        </Button>
      </div> )
     }
  }

  return (
    <div>
      <TextField
        variant="outlined"
        size="small"
        fullWidth
        value={searchText}
        placeholder="Search invites"
        InputProps={{
          startAdornment: <SearchIcon className="search-icon" />
        }}
        onChange={(ev: any) => {
          setSearchText(ev.target.value);
          setAnchorEl(ev.currentTarget);
        }}
        onKeyUp={onKeyPress}
      />
      <Callout
        anchorEl={showCallout ? anchorEl : null}
        open={showCallout}
        setOpen={setShowCallout}
      >
        <div className="audience-search-results-callout">
          {isSearching
            ? <Loading padding={12} />
            : <div>
                {!results.length
                  ? <div className="no-search-results">No invites were found</div>
                  : <div className="audience-user-list">
                      {results.map((result) =>
                        <div key={result.id}>
                          <div className="user-suggestion">
                            <div>
                              <div style={{paddingLeft: "20px"}}className="user-name">{result.email}</div>
                            </div>
                          </div>
                            {loadOptions(result)}
                        </div>
                      )}
                    </div>
                  }
                </div>
            }
          </div>
      </Callout>
    </div>
  );
}

interface ComponentProps {
  amountFetched: number;
  includedInvites: AudienceInvite[];
  onAddUser: (user: AudienceInviteSuggestions) => void;
  onRemoveUser: (id: string) => void;
  onSearch: (searchText: string) => Promise<AudienceInviteSuggestions[]>;
}

export default SearchInvites;