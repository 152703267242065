import { ApiRequest } from './network';
import { SparrowApi } from 'api/network/adapters/SparrowApi';
import { SupportItem, SupportFilter } from 'modules/support';


export class SupportApi {
    constructor(private _sparrowApi: SparrowApi) {
    }

    public CreateNewItem = (itemType: string): ApiRequest => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().post('/{tenant}/adminapi/v1/support/newItem',  { params: { itemType }})
        )
    }

    public FetchSupportItem = (itemId: string): ApiRequest => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().get('/{tenant}/adminapi/v1/support/{itemId}', { params: { itemId }})
        )
    }

    public SaveSupportItem = (item: SupportItem): ApiRequest => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().post('/{tenant}/adminapi/v1/support', item)
        )
    }

    public DeleteSupportItem = (itemId: string): ApiRequest => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().delete('/{tenant}/adminapi/v1/support', { params: { id: itemId }})
        )
    }

    public FetchAllSupportItems = (filter: SupportFilter, continuationToken: string | null, itemType: string): ApiRequest => {
        let body ={
            ...filter,
            continuationToken
        }
        return new ApiRequest(
            this._sparrowApi.TenantRequest().post('/{tenant}/adminapi/v1/support/filtered',  body, { params: { itemType }})
        )
    }

    public PublishSupportItem = (item: SupportItem): ApiRequest => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().post('/{tenant}/adminapi/v1/support/publish', item)
        )
    }

    public GetDocumentURL = (docId: string): ApiRequest  => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().get('/{tenant}/ospreyapi/v1/files/{id}', { params: { id: docId}})
        )
    }

}
