import React from "react";
import { InfoOutlined } from "@mui/icons-material";
import { FormControl, MenuItem, Select } from "@mui/material";

import { ContentBandContentType, ContentBandLayoutType } from "modules/contentBands/models";
import { IContentBandSettingsInputProps } from "../editor/contentBandSettingsForm";

export interface IContentBandInputWithContentType<T> extends IContentBandSettingsInputProps<T> {
    contentType: ContentBandContentType;
}

export const LayoutInput: React.FunctionComponent<IContentBandSettingsInputProps<ContentBandLayoutType>> = ({ value, idx, onChange }) => {
    return (
        <>
            <div className="form-group with-disclaimer">
                <label style={{ width: 190 }}>Layout</label>
                <FormControl>
                    <Select
                        id={`cb-layout-${idx}`}
                        className="input"
                        value={value}
                        variant="outlined"
                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => onChange(evt.target.value as ContentBandLayoutType, idx)}
                        MenuProps={{ disablePortal: true }} // clickawaylistener breaks with selects as children when portal is enabled (more info: https://mui.com/joy-ui/api/menu/#Menu-prop-disablePortal)
                    >
                        <MenuItem value={ContentBandLayoutType.Card}>Card</MenuItem>
                        <MenuItem value={ContentBandLayoutType.Window}>Window</MenuItem>
                        <MenuItem value={ContentBandLayoutType.Carousel}>Carousel</MenuItem>
                    </Select>
                </FormControl>
                {value === ContentBandLayoutType.Carousel && (
                    <div className="disclaimer-text" style={{ maxWidth: 285, left: 460 }}>
                        <InfoOutlined fontSize="small" />
                        Carousels occupy the full width of mobile devices
                    </div>
                )}
            </div>
        </>
    );
};
