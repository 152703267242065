import React from "react";
import { connect, ConnectedProps } from "react-redux";
import * as actions from "../../actionCreator";
import { GlobalApplicationState } from "globalApplicationState";

import MoreOptionsButton from "modules/common/components/buttons/moreOptionsButton";

import { DocumentPreview } from "../../models";

import confirm from "utils/notyPopups";

import Divider from "@mui/material/Divider";
import List from "@mui/material/List";

import { Theme } from "@mui/material/styles";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import MoreOptionsItem from "modules/common/components/moreOptionsItem";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    moreOptions: {
      backgroundColor: "#ffffff",
      boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
      "&:hover": {
        backgroundColor: "#ffffff"
      }
    }
  })
);


const PreviewOptions: React.FunctionComponent<PropsWithRedux> = props => {
  const classes = useStyles();

  const onSelect = () => {
    props.hidePreview();
    props.onSuccess();
  }

  const { preview } = props;

  return (
    <div>
      <MoreOptionsButton text="Actions" className={classes.moreOptions}>
        <List disablePadding>
          <MoreOptionsItem
            text="Edit/replace document"
            onClick={() => {
              onSelect();
              props.showEditor(preview);
            }}
          />
          <Divider light />
          {preview.state === "enabled" &&
            <MoreOptionsItem
              text="Disable"
              onClick={() => {
                onSelect();
                props.disableDocument(preview.id);
              }}
            />
          }
          {preview.state !== "enabled" &&
            <MoreOptionsItem
              text="Enable"
              onClick={() => {
                onSelect();
                props.enableDocument(preview.id);
              }}
            />
          }
          <MoreOptionsItem
            text="Delete"
            onClick={async () => {
              if (await confirm.show({ text: "Are you sure you want to delete this document?", title: "Delete document" })) {
                onSelect();
                props.deleteDocument(preview.id);
              }
            }}
          />
        </List>
      </MoreOptionsButton>
    </div>
  );
}

interface ComponentProps {
  preview: DocumentPreview;
  onSuccess: () => void;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps
  }),
  {
    deleteDocument: actions.deleteDocument,
    disableDocument: actions.disableDocument,
    enableDocument: actions.enableDocument,
    hidePreview: actions.hidePreview,
    showEditor: actions.showEditor
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(PreviewOptions);