import * as React from "react";
import { ConnectedProps, connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { GlobalApplicationState } from "globalApplicationState";
import * as actions from "../actionCreator";
import * as audiencesActions from "modules/audiences/actionCreator";

import GlobalComponentsDrawer from "../components/dialogs/globalComponentsDrawer";
import MoreOptions from "../components/action-buttons/moreOptions";
import NewPortalPage from "../components/action-buttons/newPortalPage";
import NewPortalPageDialog from "../components/dialogs/newPortalPageDialog";
import PortalPagesListing from "../components/portal-pages-listing/portalPagesListing";
import PortalPagesTutorial from "../components/dialogs/portalPagesTutorial";

import ErrorSnackbar from "modules/common/components/snackbars/errorSnackbar";
import LoadingOverlay from "modules/common/components/loadingOverlay";

import BasePage from "pages/common/basePage";
import Header from "pages/common/header";
import MainContent from "pages/common/mainContent";

import "../styles/dialogs.sass";


class PortalPagesManagementPage extends React.Component<PropsWithRedux, {}> {
  public componentDidMount() {
    if (!this.props.audiences.length)
      this.props.getAudiences();
  }

  public render() {
    return (
      <BasePage fullWidth>
        <Header
          title={
            <React.Fragment>
              <span>Manage Portal Pages</span>
              <PortalPagesTutorial />
            </React.Fragment>
          }
          rightComponent={
            <React.Fragment>
              <MoreOptions />
              <NewPortalPage />
            </React.Fragment>
          }
        />
        <MainContent>
          <div className="authoring-page">
            <PortalPagesListing />
            <GlobalComponentsDrawer />
            <NewPortalPageDialog />
            <ErrorSnackbar errorMessage={this.props.errorMessage} clearErrorMessage={this.props.clearErrorMessage} />
            <LoadingOverlay absolute={true} show={this.props.isDeleting || this.props.isFetching || this.props.isSaving} />
          </div>
        </MainContent>
      </BasePage>
    );
  }
}

interface ComponentProps {}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps & RouteComponentProps) => ({
    ...ownProps,
    audiences: state.audiences.audiences,
    errorMessage: state.portalPages.errorMessage,
    isDeleting: state.portalPages.isDeleting,
    isFetching: state.portalPages.isFetching,
    isSaving: state.portalPages.isSaving
  }),
  {
    clearErrorMessage: actions.clearErrorMessage,
    getAudiences: audiencesActions.getAudiences
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(PortalPagesManagementPage);