import React from "react";

import LocalOfferIcon from "@mui/icons-material/LocalOfferOutlined";


const Topics: React.FunctionComponent<ComponentProps> = props => {
  const { topics } = props;

  if (!topics || topics.length === 0) {
    return (null);
  }

  return <div className="topics"><LocalOfferIcon fontSize="inherit" />{topics.map(topic => topic.name).join(", ")}</div>;
}

interface ComponentProps {
  topics: { id: string, name: string }[];
}

export default Topics;