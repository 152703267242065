import React from 'react';
import UserPlatformStats from '../components/user-insights-components/userPlatformStats';
import UserLanguageStats from '../components/user-insights-components/userLanguageStats';
import UserPlatformChart from '../components/user-insights-components/userPlatformChart';
import ActivityTimeOfDayStats from '../components/user-insights-components/activityTimeOfDayStats';
import UserOverviewInsights from './userOverviewInsights';

import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from 'globalApplicationState';

import InfoHover from "modules/common/components/hovers/infoHover";
import TabContent from "pages/common/tabContent";

import Typography from "@mui/material/Typography";


interface ComponentProps { 
    show: boolean;
}

const UserInsightsView: React.FunctionComponent<PropsWithRedux> = (props) => {
    if (!props.show)
        return <React.Fragment></React.Fragment>;
    
    return (
        <TabContent>
            <div className="user-insights-container">
                <div className="row-insights">
                    <div className="overview-insights">
                        <div className="user-insights-wrapper">
                            <Typography variant="h2">
                                Key Insights
                                <InfoHover>
                                    <div>Total users counts all the accounts in your Sparrow directory.</div>
                                    <br />
                                    <div>Deactivated users are accounts who can not momentarily access the platform. You can activate these users to return their access.</div>
                                    <br />
                                    <div>Engaged users have logged in to the platform and viewed published content within the last 30 days.</div>
                                    <br />
                                    <div>Social users count Google, LinkedIn, Facebook, Apple and Microsoft Personal accounts. Pending social user invites are social users who have not logged in and activated their accounts yet.</div>
                                </InfoHover>
                            </Typography>
                            <UserOverviewInsights />
                        </div>
                    </div>
                    <div className="insights-account-types">
                        <div className="user-insights-wrapper">
                            <Typography variant="h2">User Account Types</Typography>
                            <div className="insights-platforms">
                                <UserPlatformChart />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row-insights">
                    <div className="user-insights-wrapper">
                        <Typography variant="h2">User Preferences</Typography>
                        <UserLanguageStats
                            users={props.users} />
                        <UserPlatformStats />
                    </div>
                </div>
                <div className="row-insights">
                    <div className="user-insights-wrapper">
                        <Typography variant="h2">Behavior</Typography>
                        <ActivityTimeOfDayStats timeZone={props.tenantSettings.timeZone} />
                    </div>
                </div>
            </div>
        </TabContent>
    )
}


const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        tenantSettings: state.settings.tenantSettings,
        users: state.users.users,
    }), 
    {
    }
);

type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(UserInsightsView);