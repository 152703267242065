import * as actions from "network/actions";

export const SendAcknowledgement = (postId: string) => {
  return actions.NetworkRequest({
    headers: { "content-type": "application/json" },
    method: "PATCH",
    url: `/{tenant}/api/v1/posts/${postId}/acknowledge`,
    body: JSON.stringify({
      complianceSource: "Web"
    })
  });
}