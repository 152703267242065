import { AxiosInstance, AxiosRequestConfig } from 'axios'
import MsalAuthModule from 'authentication/msalAuthModule';

export class GraphAuthInterceptor {
    public bind(networkLayer: AxiosInstance) {
        networkLayer.interceptors.request.use(this.handle);
    }

    public handle = (config: AxiosRequestConfig) => {
        return MsalAuthModule.getInstance().getGraphAccessToken()
            .then(accessToken => {
                config.headers.Authorization = `Bearer ${accessToken}`;
                return config;
            });
    }
}
