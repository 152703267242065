import { ApiRequest } from "./network";
import { SparrowApi } from "./network/adapters/SparrowApi";

export interface ExpiryLink {
    isExists: boolean;
    isUsed: boolean;
    maskedEmail: string;
    isEnabled: boolean;
    isExpired: boolean;
    isPasswordResetLink: boolean;
}

interface ILinkApi {
    getExpiryLink: (token: string, tenantId?: string | null, isResetPasswordLink?: boolean | null) => Promise<ExpiryLink>;
    resendSocialInvite: (tenantId: string, token: string) => Promise<void>;
    resendSparrowAccountPasswordResetLink: (token: string) => Promise<void>;
}

export class LinkApi implements ILinkApi {
    constructor(private _sparrowApi: SparrowApi) { }

    async getExpiryLink(token: string, tenantId?: string | null, isResetPasswordLink?: boolean | null): Promise<ExpiryLink> {
        try {
            const res = await new ApiRequest<ExpiryLink>(
                this._sparrowApi.CreateAnonymousRequest().post(`api/v1/links`, { tenantId, token, isResetPasswordLink })
            );

            return res;
        } catch (error) {
            throw error;
        }
    }

    async resendSocialInvite(tenantId: string, token: string): Promise<void> {
        try {
            await new ApiRequest(
                this._sparrowApi.CreateAnonymousRequest().post("api/v1/links/resendInvite", { tenantId, token })
            );
        } catch (error) {
            throw error;
        }
    }

    async resendSparrowAccountPasswordResetLink(token: string): Promise<void> {
        try {
            await new ApiRequest(
                this._sparrowApi.CreateAnonymousRequest().post("/api/v1/links/sendResetPassword", { token })
            );
        } catch (error) {
            throw error;
        }
    }
}
