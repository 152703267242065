import React, { useState, useEffect } from "react";
import ToggleSwitch from "modules/common/components/toggleSwitch";
import { ExternalPostSource, ExternalPostSourceTypes } from "../models";
import TextInput from "modules/common/components/forms/inputs/textInput";
import "./styles/externalPostSourceEditForm.sass";
import Select from "react-select";
import TopicsSelect from "./topicsSelect";
import { Tag } from "modules/tenant/models";
import Modal, { ModalButton } from "modules/common/components/modal";
import InlineError from "modules/common/components/forms/messages/InlineError";
import ImageSelector from 'modules/gallery/components/imageSelector';
import { fetchImage } from "modules/posts/actionCreator";
import { useDispatch } from "react-redux";
import { emailRegex } from "utils/regexUserValidation";

interface ComponentProps {
    editingExternalPostSource: ExternalPostSource;
    working: boolean;
    onChange: (updatedExternalPostSource: ExternalPostSource) => any;
    onClose: () => any;
    onDelete: (deletedExternalPostSource: ExternalPostSource) => any;
}
interface Option {
    label: string;
    value: string;
}

const sourceTypeOptions = [{ value: "1", label: "RSS" }] as Option[];

export default function ExternalPostSourceEditForm(props: ComponentProps) {
    const [source, setSource] = useState<ExternalPostSource | null>(null);
    const [attemptedToSave, setAttemptedToSave] = useState(false);
    const [selectedImage, setSelectedImage] = useState({id: '', url: ''});
    const [showingImageSelector, setShowingImageSelector] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        setSource(props.editingExternalPostSource);
    }, [props.editingExternalPostSource]);

    useEffect(() => {
        if(source?.defaultDraftImage
            && !selectedImage.url){
            fetchImage(source.defaultDraftImage)(dispatch)
            .then(img => {
                setSelectedImage(img);
            });
        }
    })

    const closeDialog = () => {
        setSource(null);
        setAttemptedToSave(false);

        props.onClose();
    };
    const addOrUpdateExternalPostSource = () => {
        setAttemptedToSave(true);

        if (isSourceValid(source)) {
            if (source) props.onChange(source);
        }
    };
    const deleteExternalPostSource = async () => {
        if (await window.confirm("Are you sure you want to delete this external post source?")) {
            if (source) {
                props.onDelete(source);
            }
        }
    };

    const fieldChanged = (fieldName: string, value: any) => {
        const updatedSource = {
            ...source,
            [fieldName]: value,
        } as ExternalPostSource;
        setSource(updatedSource);
    };

    const isSourceValid = (sourceToValidate: ExternalPostSource | null): boolean => {
        return (
            !!sourceToValidate &&
            !!sourceToValidate.name &&
            !!sourceToValidate.defaultAuthorName &&
            !!sourceToValidate.sourceType &&
            !!sourceToValidate.url &&
            isValidUrl(sourceToValidate.url) &&
            (!sourceToValidate.defaultAuthorEmail ||
                (!!sourceToValidate.defaultAuthorEmail && isValidEmail(sourceToValidate.defaultAuthorEmail))) &&
            isValidTopics(sourceToValidate.topics as Tag[])
        );
    };

    const nameChanged = (name: string) => fieldChanged("name", name);
    const urlChanged = (url: string) => {
        fieldChanged("url", url);

        if (isValidUrl(url) && source?.sourceType) {
            checkUrl(url, source?.sourceType);
        }
    };
    const checkUrl = (url: string, type: ExternalPostSourceTypes) => {
        if (type === ExternalPostSourceTypes.RSS) {
            //TODO:
        }
    };
    const defaultAuthorNameChanged = (defaultAuthorName: string) => fieldChanged("defaultAuthorName", defaultAuthorName);
    const defaultAuthorEmailChanged = (defaultAuthorEmail: string) => fieldChanged("defaultAuthorEmail", defaultAuthorEmail);
    const enableDisableChanged = (isEnabled: boolean) => fieldChanged("enabled", isEnabled);
    const defaultImageChanged = (defaultImageId: string) => fieldChanged("defaultDraftImage", defaultImageId);
    const typeChanged = (o: Option) => {
        fieldChanged("sourceType", getExternalResourceType(o.value));
    };

    const topicsChanged = (topics: { id: string }[]) => {
        fieldChanged("topics", topics);
    };

    const getExternalResourceType = (type: string | number): ExternalPostSourceTypes | undefined => {
        switch (type) {
            case 1:
            case "1":
                return ExternalPostSourceTypes.RSS;
        }
    };

    const isValidUrl = (url: string): boolean => {
        var urlRegExpression = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi; // eslint-disable-line
        var regex = new RegExp(urlRegExpression);
        return regex.test(url);
    };

    const isValidEmail = (email: string): boolean => {
        return emailRegex.test(email);
    };

    const isValidTopics = (topics: Tag[] | null): boolean => {
        return !!topics && topics?.length > 0;
    };
    const isUpdate = !!source?.id;

    const modalButtons = [
        {
            title: isUpdate ? "Update" : "Add",
            workingTitle: isUpdate ? "Updating" : "Adding",
            isWorking: props.working,
            isPrimary: true,
            isEnabled: true,
            onClick: () => {
                addOrUpdateExternalPostSource();
            },
        },
        { title: "Cancel", isPrimary: false, isEnabled: true, onClick: closeDialog },
    ] as ModalButton[];

    if (isUpdate) {
        modalButtons.push({
            title: "Delete",
            workingTitle: "Deleting",
            isWorking: props.working,
            isPrimary: false,
            isEnabled: isUpdate,
            className: "delete",
            onClick: () => {
                deleteExternalPostSource();
            },
        } as ModalButton);
    }

    return (
        <div>
        <Modal
            active={!!source}
            title={source?.id ? source.name : "New External Post Source"}
            isWorking={props.working}
            showControls={true}
            onCloseClick={closeDialog}
            buttons={modalButtons}
        >
            <div className="external-post-source-edit-form">
                <TextInput
                    label={"Name"}
                    isTitle={true}
                    onChange={nameChanged}
                    value={source?.name}
                    isRequired={true}
                    isRequiredErrorMessage="Required"
                    showError={attemptedToSave}
                />
                <div className="row">
                    <TopicsSelect
                        value={source?.topics as Tag[]}
                        onChange={topicsChanged}
                        showError={attemptedToSave && !isValidTopics(source?.topics as Tag[])}
                        errorMessage="Required"
                    />
                </div>
                <div className="row">
                    <label className={attemptedToSave && !source?.sourceType ? "has-error" : ""}>Type</label>
                    <Select
                        options={sourceTypeOptions}
                        isDisabled={false}
                        value={sourceTypeOptions.filter((l) => l.value === source?.sourceType?.toString())}
                        onChange={typeChanged}
                        isClearable={false}
                        isMulti={false}
                        placeholder={"Select..."}
                    />
                    {attemptedToSave && !source?.sourceType && <InlineError>Required</InlineError>}
                </div>
                <div className="row">
                    <label>Default Image (optional)</label>
                    {
                        selectedImage.url &&
                        <div className="img-wrapper" onClick={() => setShowingImageSelector(true)}>
                            <div className="img" style={{ backgroundImage: `url(${selectedImage.url})`}}></div>
                        </div>
                    }
                    <button onClick={() => setShowingImageSelector(true)}>
                        {
                            selectedImage.url ? "Update Image" : "Select Image"
                        }
                    </button>
                    {
                        selectedImage.url &&
                        <button onClick={() => {
                            setSelectedImage({id: '', url: ''})
                            defaultImageChanged('');
                        }}>Remove</button>
                    }
                </div>
                <TextInput
                    label={"URL"}
                    onChange={urlChanged}
                    value={source?.url}
                    isRequired={true}
                    isRequiredErrorMessage="Required"
                    errorMessage={source?.url && !isValidUrl(source?.url) ? "Invalid URL" : undefined}
                    className="row"
                    showError={attemptedToSave}
                />
                <TextInput
                    label={"Default Author Name"}
                    onChange={defaultAuthorNameChanged}
                    value={source?.defaultAuthorName}
                    isRequired={true}
                    isRequiredErrorMessage="Required"
                    className="row"
                    showError={attemptedToSave}
                />
                <TextInput
                    label={"Default Author E-mail (optional)"}
                    onChange={defaultAuthorEmailChanged}
                    value={source?.defaultAuthorEmail}
                    errorMessage={source?.defaultAuthorEmail && !isValidEmail(source?.defaultAuthorEmail) ? "Invalid Email" : undefined}
                    className="row"
                    showError={attemptedToSave}
                />
                <div className="toggle-switch-container">
                    <ToggleSwitch onClick={() => enableDisableChanged(!source?.enabled)} value={source?.enabled || false} />
                    <label>Enabled</label>
                </div>

            </div>
        </Modal>
        {
            showingImageSelector &&
            <Modal
                styles={{maxWidth: "1200px"}}
                active={!!source}
                title={"Select Default Image"}
                isWorking={props.working}
                showControls={true}
                onCloseClick={() => setShowingImageSelector(false)}
                buttons={[]}>
                <ImageSelector
                    forGallery={true}
                    selectedImageIds={[selectedImage.id]}
                    imageSelected={(img) => {
                        defaultImageChanged(img.id);
                        setSelectedImage(img);
                        setShowingImageSelector(false);
                    }}
                    imageDeselected={() => setSelectedImage({id: '', url: ''})}
                    />
                </Modal>
            }
        </div>
    );
}
