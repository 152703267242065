import * as React from "react";

import { Answers, AttendanceType, CurrentUserResponse, EventView, Questions } from "../../../models";

import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";


class SignUpForm extends React.Component<ComponentProps, ComponentState> {
  constructor(props: ComponentProps) {
    super(props);

    this.state = {
      answers: props.isCurrentUserAttending && !!props.currentUserResponse ? props.currentUserResponse.answers : {},
      attendanceType: !!props.currentUserResponse ? props.currentUserResponse.attendanceType : "NotAttending"
    };
  }

  public render() {
    const { questions } = this.props;
    
    return (
      <React.Fragment>
        <div className="sign-up-header">
          <IconButton onClick={this.onClose} size="large">
            <ArrowBackIcon />
          </IconButton>
          <div>
            <Button variant="text" onClick={this.onClose}>Cancel</Button>
            <Button
              variant="contained"
              color="primary"
              disabled={this.state.attendanceType !== "NotAttending" && !!questions && Object.keys(questions).findIndex((questionNumber) => questions[questionNumber].isRequired && (!this.state.answers || !this.state.answers[questionNumber])) !== -1}
              onClick={this.onSignUp}
            >
              Submit
            </Button>
          </div>
        </div>
        <div className="sign-up-form">
          <div>
            <div>RSVP Status</div>
            <div>
              <FormControl size="small" fullWidth>
                <Select
                  variant="outlined"
                  value={this.state.attendanceType || ""}
                  fullWidth
                  onChange={this.onChangeAttendanceType}
                >
                  {(this.props.isInPersonAvailable || this.props.isCurrentUserAttendingInPerson) &&
                    <MenuItem value="InPerson">Attending in-person</MenuItem>
                  }
                  {(this.props.isOnlineAvailable || this.props.isCurrentUserAttendingOnline) &&
                    <MenuItem value="Online">Attending online</MenuItem>
                  }
                  {(this.props.isWaitlistAvailable || this.props.isCurrentUserOnWaitlist) &&
                    <MenuItem value="Waitlist">Waitlist</MenuItem>
                  }
                  <MenuItem value="NotAttending">Not Attending</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          {!!questions && Object.keys(questions).map((questionNumber) => {
            const required = questions[questionNumber].isRequired;
            return (
              <div key={questionNumber}>
                <div>{questions[questionNumber].body}{required && "*"}</div>
                <TextField
                  variant="outlined"
                  size="small"
                  value={this.state.answers[questionNumber] || ""}
                  placeholder="Enter text"
                  autoComplete="off"
                  fullWidth
                  required={required}
                  onChange={(ev) => this.onChangeAnswers(ev, questionNumber)}
                />
              </div>
            );
          })}
        </div>
      </React.Fragment>
    );
  }

  private onChangeAnswers = (event, questionNumber: string) => {
    this.setState({
      answers: {
        ...this.state.answers,
        [questionNumber]: event.target.value
      }
    });
  }

  private onChangeAttendanceType = (event) => {
    this.setState({ attendanceType: event.target.value });
  }

  private onClose = () => {
    this.props.onClose();
  }

  private onSignUp = () => {
    this.props.onSignUp(this.state.attendanceType, this.state.answers).then((event) => {
      if (!!event)
        this.onClose();
    });
  }
}


interface ComponentProps {
  respondingEnabled: boolean;
  currentUserResponse?: CurrentUserResponse;
  isCapacityAvailable: boolean;
  isCurrentUserAttending: boolean;
  isCurrentUserAttendingInPerson: boolean;
  isCurrentUserAttendingOnline: boolean;
  isCurrentUserNotAttending: boolean;
  isCurrentUserOnWaitlist: boolean;
  isInPersonAvailable: boolean;
  isOnlineAvailable: boolean;
  isResponseEditable: boolean;
  isWaitlistAvailable: boolean;
  questions: Questions;
  title: string;
  onSignUp: (attendanceType: AttendanceType, answers: Answers) => Promise<EventView>;
  onClose: () => void;
}

interface ComponentState {
  answers: Answers;
  attendanceType: AttendanceType;
}

export default SignUpForm;