import React, { useRef, useState } from "react";

import Popover from "@mui/material/Popover";

import HelpOutlineIcon from "@mui/icons-material/HelpOutline";


import "./infoHover.sass";


const InfoHover: React.FC<ComponentProps> = ({ size, children }) => {
  const [showPopover, setShowPopover] = useState(false);
  const popoverRef = useRef(null);

  return (
    <>
      <HelpOutlineIcon
        ref={popoverRef}
        onMouseEnter={() => setShowPopover(true)}
        onMouseLeave={() => setShowPopover(false)}
        style={size === "small" ? { fontSize: 16 } : {}}
        className="help-icon"
      />
      <Popover
        open={showPopover}
        anchorEl={popoverRef.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        PaperProps={{
          onMouseEnter: () => setShowPopover(true),
          onMouseLeave: () => setShowPopover(false),
          style: {
            maxWidth: 250
          }
        }}
        style={{ pointerEvents: "none" }}
        onClose={() => setShowPopover(false)}
        classes={{
          paper: "help-icon-description"
        }}
      >
        {children}
      </Popover>
    </>
  )
}

interface ComponentProps {
  size?: "initial" | "small";
}

export default InfoHover;