import React from 'react';
import { AccountCreationTypes } from '..';
import Select from 'react-select';
import { ValueLabel } from 'modules/common/components/selectFilter';
import SingleUserCreation from './singleUserCreation';
import BulkUserCreation from './bulkUserCreation';
import { push } from 'react-router-redux';
import { ConnectedProps, connect } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import * as actions from '../actionCreator';
import LoadingOverlay from 'modules/common/components/loadingOverlay';
import LoadingSpinner from 'modules/common/components/loadingSpinner';
import { errorAlert } from 'utils/notyPopups';
import { usersApi } from 'api/instances';
import SuccessfullyPublishedDialog from 'modules/common/components/successfullyPublishedDialog';

import BasePage from "pages/common/basePage";
import Breadcrumb from "pages/common/breadcrumb";
import MainContent from "pages/common/mainContent";


interface ComponentProps {

}

interface ComponentState {
    accountTypeSelected: AccountCreationTypes;
    userCreationType: string;
    sending: boolean;
    uploadedCSV: File | null;
    successfulCreationTitle: string;
    successfulCreationMessage: string;
    showDialog: boolean;
}

enum UserCreationType {
    Single = "single",
    Bulk = "bulk"
}
class UserCreationScreen extends React.PureComponent<PropsWithRedux, ComponentState> {

    constructor(props) {
        super(props);
        // const defaultAccountType = this.props.localAccountsEnabled ? AccountCreationTypes.Sparrow : AccountCreationTypes.Social;
        this.state = {
            accountTypeSelected: AccountCreationTypes.Sparrow,
            userCreationType: UserCreationType.Single,
            sending: false,
            uploadedCSV: null,
            showDialog: false,
            successfulCreationTitle: "",
            successfulCreationMessage: ""
        }
    }
    public render() {

        /* Moving the external user creation elsewhere.
        const labels = this.props.localAccountsEnabled ? 
                        ["Create External Login User",
                        "Create Sparrow Standard User"] 
                        : 
                        ["Create External Login User"]
                        
                        
        const labels = this.props.localAccountsEnabled ? ["Create Sparrow Standard User"] : [];
        */


        const singleOrBulk = [
            { value: UserCreationType.Single, label: "Create 1 New User"},
            { value: UserCreationType.Bulk, label: "Create more than 1 New Users (Bulk Import)"}
        ]

        const selectStyles = {
            control: (provided) => ({
                ...provided,
                width: 400,
                marginTop: 20,
                marginBottom: 20
            }),
            menu: (provided) => {
                return {
                    ...provided,
                    width: 400,
                }
            }        
        };

        return (
            <BasePage fullWidth>
                <Breadcrumb
                    items={[
                        { title: "Users & Permissions", link: "~/admin/users" },
                        { title: "Create new user(s)"},
                    ]}
                />
                <MainContent>
                    <div className="user-creation-page">
                        <div className="user-creation-header">
                            <div className="description-text" style={{float: "left", width: "50%"}}>Creating Sparrow Standard Users will allow employees to log in using the User ID and Password through the Sparrow Connected Login Portal. When creating External Users, they will be invited to login using the email provided in the User ID field through an external login provider e.g. Apple, Facebook, Google, etc.</div>
                            <button className="apply-btn-2" style={{float:"right"}} 
                                onClick={() => this.createUser()}>
                                { this.state.sending ?
                                <LoadingSpinner size={'14px'} />
                                : <span>FINISH</span>
                                }
                            </button>
                            <button className="button-no-background" style={{float:"right"}} onClick={() => this.cancelCreation()}>CANCEL</button>
                        </div>

                        {/*
                        <div style={{marginTop: 20}}>
                            <CreateAccountTypeRadio
                                selected={this.state.accountTypeSelected}
                                handleSelection={this.handleChangeAccountType}
                                labels={labels} />
                        </div>
                        */}
                        {this.state.accountTypeSelected === AccountCreationTypes.Sparrow &&
                            <Select 
                                options={singleOrBulk}
                                styles={selectStyles}
                                defaultValue={singleOrBulk[0]}
                                value={singleOrBulk.find((index) => index.value === this.state.userCreationType)}
                                onChange={this.handleChangeCreationAmount}
                            />
                        }

                        {this.state.userCreationType === UserCreationType.Bulk && this.state.accountTypeSelected === AccountCreationTypes.Sparrow ?
                            <BulkUserCreation 
                                accountTypeSelected={this.state.accountTypeSelected}
                                handleCSVUpload={this.handleCSVUpload}
                            />
                            : 
                            <SingleUserCreation 
                                accountTypeSelected={this.state.accountTypeSelected}
                            />
                        }

                    </div>

                    {
                        this.state.showDialog ? 
                        <SuccessfullyPublishedDialog 
                            title={this.state.successfulCreationTitle}
                            displayMessage={ <p> {this.state.successfulCreationMessage}  </p> }
                            afterPublishSuccess={this.dialogClose}
                        /> 
                        : null
                    }

                    <LoadingOverlay absolute={true} show={this.state.sending} />
                </MainContent>
            </BasePage>
        )
    }

    private dialogOpen = (title: string, message: string) => {
        
        this.setState({
            ...this.state,
            successfulCreationMessage: message,
            successfulCreationTitle: title,
            showDialog: true
        })
    }

    private dialogClose = () => {
        this.setState({
            ...this.state,
            showDialog: false,

        }, () => {
            this.props.redirectTo('/' + this.props.tenant + '/admin/users/')        
        })
    }

    private handleCSVUpload = (csv: File) => {
        this.setState({
            ...this.state,
            uploadedCSV: csv
        })
    }
    
    private createUser = () => {

        this.setState({
            ...this.state, sending: true
        }, async () => {

            if (this.state.accountTypeSelected === AccountCreationTypes.Sparrow && this.state.userCreationType === UserCreationType.Single) {
                this.props.createSingleSparrowUser()
                .then((response) => {
                    this.setState({
                        ...this.state,
                        sending: false
                    })
                    if (response === 200){
                        this.dialogOpen("Success", "User successfully created")
                    }
                })
            } else if (this.state.accountTypeSelected === AccountCreationTypes.Sparrow && this.state.userCreationType === UserCreationType.Bulk) {

                if (this.state.uploadedCSV) {
                    usersApi.BatchCreateSparrowLocalAccounts(this.state.uploadedCSV)
                    .then((response) => {
                        this.setState({
                            ...this.state,
                            sending: false
                        }, () => {
                            this.dialogOpen("Batch creation in process", "To see more details, download your report on the batch creation screen.")
                        })
                    })
                    .catch((error) => {
                        this.setState({
                            ...this.state,
                            sending: false})
                        errorAlert(error.response.data.Message, 4000);
                    })
                } else {
                    this.setState({
                        ...this.state,
                        sending: false
                    })
                    errorAlert("There was an error uploading your CSV, please try again", 4000);
                }
            } else {
                if (this.props.invitedUserEmail)
                {
                    this.props.inviteUser(this.props.invitedUserEmail)
                    .then((response) => {
                        this.setState({
                            ...this.state,
                            sending: false
                        })
                        if (response === 200) {
                            this.dialogOpen("User invite sent", "Log in instructions have been sent to their email")
                        }
                    })
                } else {
                    errorAlert("Email field cannot be empty", 3000)
                    this.setState({
                        ...this.state,
                        sending: false
                    })
                }
            }

        })    
    }

    private cancelCreation = () => {
        this.props.redirectTo('/' + this.props.tenant + '/admin/users/')
    }

    private handleChangeAccountType = (index: AccountCreationTypes) => {
        this.setState({
            ...this.state,
            accountTypeSelected: index
        })
    }

    private handleChangeCreationAmount = (o: ValueLabel) => {
        this.setState({
            ...this.state,
            userCreationType: o.value
        })
    }

}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        maxAllowedAudiences: state.settings.tenantSettings.maxAllowedAudiences,
        tenant: state.tenant.id,
        canEdit: state.settings.tenantSettings.manageTopicsAudiencesInAdmin,
        localAccountsEnabled: state.settings.tenantSettings.localAccountsEnabled,
        invitedUserEmail: state.users.invitingUserEmail,
    }),
    {
        inviteUser: actions.inviteUser,
        redirectTo: push,
        createSingleSparrowUser: actions.createSingleSparrowUser
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default (connector(UserCreationScreen));