import * as React from "react"
import moment from 'moment';
import { usersApi } from "api/instances";
import { LobbyUser, UsersTab } from "../models";
import LoadingSpinner from "modules/common/components/loadingSpinner";
import { errorAlert, infoAlert } from "utils/notyPopups";

import BasePage from "pages/common/basePage";
import Breadcrumb from "pages/common/breadcrumb";
import MainContent from "pages/common/mainContent";


interface ComponentState {
    lobbyUsers: LobbyUser[]
    isLoading: boolean
    processingDelete: string | null
    processingGrant: string | null
}
interface ComponentProps {
    activeTab: UsersTab;
}
export default class ExternalUsersLobbyView extends React.Component<ComponentProps, ComponentState> {
    private _isMounted = false;
    constructor(props){
        super(props);
        this.state = { lobbyUsers: [], 
            isLoading: false,
            processingDelete: null, 
            processingGrant: null,
        };
    }
    
    public componentDidMount() {
        this._isMounted = true;
        this.fetchLobby();
    }
    public componentWillReceiveProps(nextProps) {
        if( this.props.activeTab !== nextProps.activeTab) { 
            this.fetchLobby();
        }
    }
    public componentWillUnmount() {
        this._isMounted = false;
    }
    public render() {
        return (
            <BasePage fullWidth>
                <Breadcrumb
                    items={[
                        { title: "Users & Permissions", link: "~/admin/users" },
                        { title: "External User Lobby"},
                    ]}
                />
                <MainContent>
                    <div style={{display: 'inline-flex', marginTop: 20}}>
                        <p className="alert-info" style={{width: 'fit-content', marginLeft: '35px', paddingLeft: '15px', paddingRight: '15px'}}>
                            The lobby shows external users that have attempted to sign into the App and that are currently awaiting approval. <br />
                            Granting them access will allow them to log in.
                        </p>
                    </div>
                    <table className="table external-lobby" style={{marginTop: '20px'}}>
                        <thead>
                            <tr>
                                <th className="auth-provider-col"></th>
                                <th className="email">Email</th>
                                <th className="actions">Actions</th> 
                                <th className="date">First Login Attempt</th>

                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.lobbyUsers.map(user => {
                                
                                return <tr key={user.email}>
                                        <td className="auth-provider-col">
                                            { this.mapExternalAuthToIcon(user) }
                                        </td>
                                        <td className="email">
                                                {user.email} 
                                        </td>
                                        <td className="actions">
                                            <button
                                                disabled={this.state.isLoading || this.state.processingDelete === user.email}
                                                className = "btn"
                                                style={{backgroundColor: '#D9534F',  padding: '8px', color: "white"}}
                                                onClick = {() => this.deleteRequest(user.objectId, user.email)}
                                            >      
                                            {
                                                this.state.processingDelete === user.objectId? <LoadingSpinner size={'14px'} /> : 
                                                    <span>
                                                        Delete
                                                        <i className="material-icons" 
                                                        style={{fontSize: '17px', verticalAlign: 'text-bottom'}}>delete</i>
                                                    </span>
                                            }
                                            
                                            </button>
                                            <button
                                                disabled={this.state.isLoading || this.state.processingGrant === user.email}
                                                className = "btn"
                                                style={{backgroundColor: '#2196f3', padding: '9px', color: "white"}}
                                                onClick = {() => this.grantAccess(user.objectId, user.issuerName, user.email)}
                                            >
                                            {
                                                this.state.processingGrant === user.objectId ? <LoadingSpinner size={'14px'} /> : 
                                                <span>
                                                    Grant Access
                                                    <i className="material-icons" 
                                                    style={{fontSize: '17px', verticalAlign: 'text-bottom', marginLeft: '4px'}}>done</i>
                                                </span>
                                            }
                                            </button>
                                        </td>
                                        <td className="date">
                                            { moment(user.createdDateTime).format('MM/DD/YYYY').toString()}
                                        </td> 
                                    </tr>
                                })
                            }
                            {
                                this.state.lobbyUsers.length === 0 ?
                                <tr>
                                    <td colSpan={5} style={{ textAlign: 'center' }}>
                                        There are no external users in the lobby
                                    </td>
                                </tr>
                                : null
                            }
                        </tbody>
                    </table>
                </MainContent>
            </BasePage>
        );
    }
    private trimIssuerName = (issuer: string) => {
        if (issuer.toLowerCase().includes("facebook"))
        {
            return "facebook";
        } else if (issuer.toLowerCase().includes("google"))
        {
            return "google";
        } else if (issuer.toLowerCase().includes("linkedin"))
        {
            return "linkedin";
        } else if (issuer.toLowerCase().includes("apple"))
        {
            return "apple";
        } else
        {
            return "";
        }
    }
    private mapExternalAuthToIcon(user: LobbyUser): JSX.Element {
        if (user.issuerName && user.issuerName !== "") {
            switch (this.trimIssuerName(user.issuerName)) {
                case "google":
                    return <img className="auth-provider-logo"
                                title="This user uses Google auth to log in."
                                src="/images/g-logo.png" alt="Google" />;
                case "facebook":
                    return <img className="auth-provider-logo"
                                title="This user uses Facebook auth to log in."
                                src="/images/fb-logo.png" alt="Facebook" />;
                case "linkedin":
                    return <img className="auth-provider-logo"
                                title="This user uses LinkedIn auth to log in."
                                src="/images/linkedin-logo.png" alt="Linkedin" />;
                case "apple":
                    return <img className="auth-provider-logo"
                                title="This user uses Apple auth to log in."
                                src="/images/apple-logo.png" alt="apple" />;
                default:
                    return <React.Fragment></React.Fragment>
            }
        }
        return <React.Fragment></React.Fragment>;
    }
    private fetchLobby = () => {
        this.setState(prev => ({...prev, isLoading: true, error: ""}));
        usersApi.GetExternalUsersLobby()
            .then(result => {;
                if (this._isMounted) {
                    this.setState(prev => ({
                        ...prev,
                        lobbyUsers: result,
                        isLoading: false
                    }));
                }
            })
    }
    private deleteRequest = (objectId: string, email: string) => {
        this.setState(prev => ({...prev, processingDelete: objectId}));
        usersApi.DeleteExternalUserRequest(objectId)
            .then(result => {
                infoAlert(`${email} request has been deleted`, 4000);

                this.setState(prev => ({
                    ...prev,
                    lobbyUsers: this.state.lobbyUsers.filter(o => o.objectId !== objectId),
                    processingDelete: null
                }));
            })
            .catch(err => {
                errorAlert(err.response.data.Message, 4000);
                this.setState(prev => ({
                    ...prev, 
                    lobbyUsers: prev.lobbyUsers,
                    processingGrant: null
                }));
            })
    }
    private grantAccess = (objectId: string, issuer: string, email: string) => {
        this.setState(prev => ({...prev, processingGrant: objectId}));
        usersApi.GrantExternalUserAccess(objectId, issuer, email)
            .then(result => {
                infoAlert(`${email} has been granted access`, 4000);
                this.setState(prev => ({
                    ...prev,
                    lobbyUsers: this.state.lobbyUsers.filter(o => o.objectId !== objectId),
                    processingGrant: null,
                }));
            })
            .catch(err => {
                if (err.response.status === 400) {
                    errorAlert(err.response.data.Message, 4000);
                    this.setState(prev => ({
                        ...prev, 
                        lobbyUsers: prev.lobbyUsers,
                        processingGrant: null
                    }));
                }
            })
    }
}

