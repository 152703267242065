import { FileBrowserHandle, FileBrowserProps, } from 'chonky';
import React, { useEffect, useRef, useState } from 'react';
import { StylesProvider, createGenerateClassName } from '@material-ui/core/styles';
import * as PostActions from 'modules/posts/actionCreator';
import { CustomFileData, FileUploadAction, FilterGroupName, MoveFilesWithSrc, SortGroupName } from '../models';
import { connect, ConnectedProps } from 'react-redux';
import { GlobalApplicationState } from 'globalApplicationState';
import { ROOT_FOLDER_ID } from '../chonkyFileAdapter';
import cookie, { ROWS_PER_PAGE_COOKIE_NAMES } from 'utils/cookie';
import { resetMoveFileStateAction } from '../customChonkyFileActions';
import { useDragEventDebounce, useFileUploadFunctions, useSearchValueDebounce } from '../galleryVfsHooks';
import './galleryVFSBrowser.sass';
import MoveFilesAppBar from './moveFilesAppBar';
import { InvalidFilesDialog } from './invalidFilesDialog';
import { DuplicateFilesDialog } from './duplicateFilesDialog';
import SuccessSnackbar from 'modules/common/components/snackbars/successSnackbar';
import ErrorSnackbar from 'modules/common/components/snackbars/errorSnackbar';
import GalleryVFSOverlays from './galleryVFSOverlays';
import GalleryVFSFileActionDialogs from './galleryVFSFileActionDialogs';
import GalleryVFS from './galleryVFS';

const muiJSSClassNameGenerator = createGenerateClassName({
    // Seed property is used to add a prefix classes generated by MUI by chonky to separate our own MUI themes.
    seed: 'chonky'
});

interface GalleryVFSBrowserProps {
    externalFilesToUpload: FileList | null;
    clearExternalFilesToUpload? : () => void;
    onSingleSelect: (mediaFile: CustomFileData) => void;
    onMultiSelect: (mediaFiles: CustomFileData[]) => void;
    externalFileToRename?: CustomFileData;
    clearExternalFileToRename?: () => void;
    maxNumItemsPerPage?: number;
    singleClickFileSelect?: boolean;
    onSingleClickSelect?: (mediaFile: CustomFileData) => void;
    skipFetchOnRender?: boolean;
    disableActions?: boolean;
    fullScreenDragNDrop?: boolean;
    allowUploadCancel?: boolean;
    onUploadedImages?: (mediaFiles: CustomFileData[]) => void;
    maxNumUploads?: number;
}

type VFSProps = Partial<FileBrowserProps> & GalleryVFSBrowserProps;

const GalleryVFSBrowser: React.FC<PropsWithRedux> = React.memo(props => {
    const maxNumUploads = props.maxNumUploads && props.maxNumUploads > 0 ? props.maxNumUploads : 50;
    const maxUploadMbSize = 25;

    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const [pageNumber, setPageNumber] = useState(1);
    const [pageAmount, setPageAmount] = useState(props.maxNumItemsPerPage ?? cookie.getRowsPerPage(ROWS_PER_PAGE_COOKIE_NAMES.GALLERY));

    const [runFetchOnEmptySearch, setRunFetchOnEmptySearch] = useState(true);
    const uploadedImageIds = useRef<Set<string>>(new Set());

    const [showDragDropOverlay, setShowDragDropOverlay] = useState(false);
    const [openCreateFolderDialog, setOpenCreateFolderDialog] = useState(false);
    const [fileToRename, setFileToRename] = useState<CustomFileData | null>(null);
    const [openRenameDialog, setOpenRenameDialog] = useState(false);
    const [filesToDelete, setFilesToDelete] = useState<CustomFileData[]>([]);
    const [deleting, setDeleting] = useState(false);
    const [isInnerDragNDrop, setIsInnerDragNDrop] = useState(false);
    const dragEventValueDebounce = useDragEventDebounce(isInnerDragNDrop, showDragDropOverlay, setShowDragDropOverlay);

    const [moveFilesForAppBar, setMoveFilesForAppBar] = useState<MoveFilesWithSrc>({ filesToMove: [], moveSrc: null });
    const fileBrowserRef = React.useRef<FileBrowserHandle>(null);
    const [currentFolderId, setCurrentFolderId] = useState(props.imageLibrary.currDir?.id ?? ROOT_FOLDER_ID);
    const [currSortGroupName, setCurrSortGroupName] = useState(SortGroupName.Newest);
    const [currFilterGroupName, setCurrFilterGroupName] = useState(FilterGroupName.AllImages);
    const [toggleShowFoldersFirst, setToggleShowFoldersFirst] = useState(true);

    const fetchImages = async (
        newPageAmount?: number, 
        newPageNumber?: number,
        parentId?: string,
        searchText?: string,
        sortType?: SortGroupName,
        filterType?: FilterGroupName,
        showFoldersFirst?: boolean,
    ) => {
        
        await props.fetchImages(
            true, 
            newPageAmount ?? pageAmount, 
            newPageNumber ?? pageNumber,
            moveFilesForAppBar,
            {
                parentId: parentId === ROOT_FOLDER_ID ? undefined : parentId ?? props.imageLibrary.currDir?.id,
                searchText,
                sortType: sortType ?? currSortGroupName,
                filterType: filterType ?? currFilterGroupName,
                showFoldersFirst: showFoldersFirst ?? toggleShowFoldersFirst,
            }
        );

        setRunFetchOnEmptySearch(true);
    }

    const {
        totalToUpload,
        totalUploaded,
        invalidFiles,
        showInvalidFilesConfirmDialog,
        onInvalidFilesDialogConfirm,
        verifiedFiles,
        duplicateFiles,
        consolidatedDuplicateFiles,
        showDuplicateFilesConfirmDialog,
        duplicateFilesDirName,
        applyActionToAllDuplicates,
        setApplyActionToAllDuplicates,
        verifyUploadFiles,
        verifyMediaForMove,
        resetAllFilesUploadState,
        popLastDuplicateFile,
        hideDuplicateDialogAndUploadFiles,
        onDuplicateDialogAction
    } = useFileUploadFunctions(
        maxNumUploads,
        maxUploadMbSize,
        setErrorMessage,
        setSuccessMessage,
        props.fetchImageByName,
        props.fetchImageByNameAsFileData,
        props.fetchFolderByNameAsFileData,
        props.deleteFolderByName,
        props.deleteImagesFromLibrary,
        props.getUniqueFolderName,
        props.getUniqueFileName,
        props.renameFolder,
        props.renameImage,
        props.moveMedia,
        fetchImages,
        props.deleteImageByName,
        props.upload,
        props.setUploading,
        props.imageLibrary.currDir,
        props.onUploadedImages,
        props.clearExternalFilesToUpload
    );

    const searchValueDebounce = useSearchValueDebounce("", runFetchOnEmptySearch, setCurrentFolderId, fetchImages);
    const showLoading = props.imageLibrary.deleting ||
        props.imageLibrary.fetching ||
        deleting;
    const abortController = React.useRef(new AbortController());

    useEffect(() => {
        if (props.skipFetchOnRender && !props.imageLibrary.shouldFetch) return;
        
        fetchImages();
    }, []);

    const onChangePage = (page: number, rowsPerPage: number) => {
        setPageNumber(page);
        setPageAmount(rowsPerPage);

        fetchImages(rowsPerPage, page, undefined, searchValueDebounce.debVal);
    }

    const onFilterChange = (sortType?: SortGroupName, filterType?: FilterGroupName, showFoldersFirst?: boolean) => {
        if (sortType !== undefined) setCurrSortGroupName(sortType);
        if (filterType !== undefined) setCurrFilterGroupName(filterType);
        if (showFoldersFirst !== undefined) setToggleShowFoldersFirst(showFoldersFirst);

        fetchImages(undefined, undefined, undefined, searchValueDebounce.debVal, sortType, filterType, showFoldersFirst);
    }

    const onCurrentFolderChange = (parentId: string) => {
        setRunFetchOnEmptySearch(false);
        searchValueDebounce.setDebVal("");
        setCurrentFolderId(parentId);
        setPageNumber(1);
        fetchImages(undefined, 1, parentId);
        setRunFetchOnEmptySearch(true);
    }

    const onClearSearchText = () => {
        setRunFetchOnEmptySearch(false);
        searchValueDebounce.setDebVal("");
        setPageNumber(1);
        fetchImages(undefined, 1, undefined, searchValueDebounce.debVal);
        setRunFetchOnEmptySearch(true);
    }

    const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
        onDragEnd(e);

        verifyUploadFiles(e.dataTransfer.files);
    }

    const onDragStartDelay = (e: React.DragEvent<HTMLDivElement>) => {
        e.persist();
        dragEventValueDebounce.setDebVal(e);
    }

    const onDivDragEnd = (e: React.DragEvent<HTMLDivElement>) => {
        if (isInnerDragNDrop) {
            e.preventDefault();
            setIsInnerDragNDrop(false);
        }

        setShowDragDropOverlay(false);
    }

    const onDragEnd = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        if (showDragDropOverlay) setShowDragDropOverlay(false);
    }

    const onCloseMoveFiles = () => {
        setMoveFilesForAppBar({ filesToMove: [], moveSrc: null });

        if (fileBrowserRef.current)
            fileBrowserRef.current.requestFileAction(resetMoveFileStateAction, undefined);
    }

    useEffect(() => {
        if (props.externalFilesToUpload && props.externalFilesToUpload.length > 0) {
            verifyUploadFiles(props.externalFilesToUpload);
        }
    }, [props.externalFilesToUpload]);

    useEffect(() => {
        if (props.externalFileToRename && props.clearExternalFileToRename) {
            setFileToRename(props.externalFileToRename);
            setOpenRenameDialog(true);
        }
    }, [props.externalFileToRename]);

    useEffect(() => {
        if (uploadedImageIds.current.size > 0 && props.onUploadedImages) {
            const uploadedImages = props.imageLibrary.chonkyFileData.filter(file => uploadedImageIds.current.has(file.id));
            if (uploadedImages) {
                props.onUploadedImages(uploadedImages);
                uploadedImageIds.current = new Set();
            }
        }
    }, [props.imageLibrary.chonkyFileData, uploadedImageIds]);

    return (
        <StylesProvider generateClassName={muiJSSClassNameGenerator} injectFirst>
            <div
                onDragStart={onDragStartDelay}
                onDragEnter={onDragStartDelay}
                onDragEnd={onDivDragEnd}
                className='gallery-drag-drop'
            >
                <GalleryVFS
                    {...props}
                    currentFolderId={currentFolderId}
                    setOpenCreateFolderDialog={setOpenCreateFolderDialog}
                    onCurrentFolderChange={onCurrentFolderChange}
                    setOpenRenameDialog={setOpenRenameDialog}
                    setFileToRename={setFileToRename}
                    setFilesToDelete={setFilesToDelete}
                    verifyMediaForMove={verifyMediaForMove}
                    setIsInnerDragNDrop={setIsInnerDragNDrop}
                    setMoveFilesForAppBar={setMoveFilesForAppBar}
                    fileBrowserRef={fileBrowserRef}
                    searchValueDebounce={searchValueDebounce}
                    showSearchTextField={moveFilesForAppBar.filesToMove.length === 0}
                    showLoading={showLoading}
                    pageNumber={pageNumber}
                    pageAmount={pageAmount}
                    onChangePage={onChangePage}
                    onFilterChange={onFilterChange}
                    currFilterGroupName={currFilterGroupName}
                    currSortGroupName={currSortGroupName}
                    toggleShowFoldersFirst={toggleShowFoldersFirst}
                    onClearSearchText={onClearSearchText}
                    setPageNumber={setPageNumber}
                />
                <GalleryVFSOverlays
                    showLoadingOverlay={showLoading}
                    showDragDropOverlay={showDragDropOverlay && !isInnerDragNDrop}
                    maxNumUploads={maxNumUploads}
                    maxUploadMbSize={maxUploadMbSize}
                    onDrop={onDrop}
                    onDragEnd={onDragEnd}
                    fullScreenDragNDrop={props.fullScreenDragNDrop}
                    totalToUpload={totalToUpload}
                    totalUploaded={totalUploaded}
                    allowUploadCancel={props.allowUploadCancel}
                    onUploadCancel={() => abortController.current.abort()}
                />
                <GalleryVFSFileActionDialogs
                    openCreateFolderDialog={openCreateFolderDialog}
                    onCreateFolderDialogClose={() => setOpenCreateFolderDialog(false)}
                    fetchImages={() => fetchImages(undefined, undefined, undefined, searchValueDebounce.debVal)}
                    clearExternalFileToRename={props.clearExternalFileToRename}
                    openRenameDialog={openRenameDialog}
                    fileToRename={fileToRename}
                    onRenameDialogClose={() => setOpenRenameDialog(false)}
                    filesToDelete={filesToDelete}
                    setSuccessMessage={(message: string) => setSuccessMessage(message)}
                    onCurrentFolderChange={onCurrentFolderChange}
                    onDeleteDialogClose={() => setFilesToDelete([])}
                    onDeleteStarted={() => setDeleting(true)}
                    onDeleteFinished={() => setDeleting(false)}
                />
                <MoveFilesAppBar
                    moveFiles={moveFilesForAppBar}
                    onClose={onCloseMoveFiles}
                    onMove={verifyMediaForMove}
                />
                <InvalidFilesDialog
                    invalidFiles={invalidFiles}
                    open={showInvalidFilesConfirmDialog}
                    onClose={() => resetAllFilesUploadState()}
                    onDeny={() => resetAllFilesUploadState()}
                    onConfirm={() => onInvalidFilesDialogConfirm()}
                    maxUploadMbSize={maxUploadMbSize}
                    maxUploadNumber={maxNumUploads}
                    hasValidFilesToUpload={verifiedFiles.length > 0 || duplicateFiles.length > 0}
                >
                    {
                        verifiedFiles.length > 0 && 
                        `Only ${verifiedFiles.length + duplicateFiles.length} will be uploaded. Would you like to continue?`
                    }
                </InvalidFilesDialog>
                <DuplicateFilesDialog
                    duplicateFiles={consolidatedDuplicateFiles}
                    open={showDuplicateFilesConfirmDialog}
                    onClose={() => resetAllFilesUploadState()}
                    onDeny={() => applyActionToAllDuplicates ? hideDuplicateDialogAndUploadFiles() : popLastDuplicateFile()}
                    onOption={() => onDuplicateDialogAction(FileUploadAction.Replace)}
                    onConfirm={() => onDuplicateDialogAction(FileUploadAction.Rename)}
                    onCheckboxChange={(_, checked) => setApplyActionToAllDuplicates(checked)}
                    dirName={duplicateFilesDirName}
                />
                <SuccessSnackbar
                    successMessage={successMessage}
                    clearSuccessMessage={() => setSuccessMessage("")}
                />
                <ErrorSnackbar
                    errorMessage={errorMessage}
                    clearErrorMessage={() => setErrorMessage("")}
                />
            </div>
        </StylesProvider>
    );
});

const connector = connect(
    (state: GlobalApplicationState, ownProps: VFSProps)=>({
        ...ownProps,
        imageLibrary: state.posts.imageLibrary,
        currentUser: state.settings.currentUser,
    }), 
    {
        fetchImages: PostActions.fetchImages,
        fetchFolderByName: PostActions.fetchFolderByName,
        fetchImageByName: PostActions.fetchImageByName,
        fetchFolderByNameAsFileData: PostActions.fetchFolderByNameAsFileData,
        fetchImageByNameAsFileData: PostActions.fetchImageByNameAsFileData,
        renameImage: PostActions.renameImage,
        renameFolder: PostActions.renameFolder,
        deleteFolder: PostActions.deleteFolder,
        deleteImages: PostActions.deleteImagesFromLibrary,
        deleteImageByName: PostActions.deleteImageByName,
        setUploading: PostActions.setUploading,
        getUniqueFileName: PostActions.getUniqueFileName,
        upload: PostActions.uploadImage,
        deleteFolderByName: PostActions.deleteFolderByName,
        getUniqueFolderName: PostActions.getUniqueFolderName,
        deleteImagesFromLibrary: PostActions.deleteImagesFromLibrary,
        moveMedia: PostActions.moveMedia
    }
)
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(GalleryVFSBrowser);