import { Button, Divider, Drawer } from '@mui/material';
import * as React from 'react';

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ContentAnalysesSimple from 'modules/insights/components/contentAnalysesSimple';
import { PostAnalysisScores, PostAverages } from 'modules/posts/models';
import useAccordion from 'modules/common/hooks/useAccordion';
import makeStyles from '@mui/styles/makeStyles';


const useStyles = makeStyles({
    paper: {
        width: 500
    }
});

const AnalyticsDrawer: React.FunctionComponent<ComponentProps> = (props) => {
    const classes = useStyles();
    const { accordionOpenStates, onChangeAccordionOpen, expandAllLabel, onExpandOrCollapseAll } = useAccordion(3);

    return (
        <Drawer open={props.isOpen} anchor='right' classes={{ paper: classes.paper }} onClose={props.closeDrawer}>
                <div style={{ padding: 20, paddingBottom: "0px" }}>
                    <div style={{display: "flex", flexDirection: "row"}}>
                        <ArrowBackIcon className="close-modal-icon" style={{position: "relative", top: "4px"}} onClick={props.closeDrawer} />
                        <h4 style={{paddingLeft: "14px"}}>Content Analysis</h4>
                    </div>
                </div>

                <Divider />
                <div style={{display: "flex", flexDirection: "column", flex: 1, overflowY: "hidden"}}>
                    <div style={{ margin: 20, marginBottom: 0, flex: 1, overflowY: "hidden"}}>
                        {props.tenantAverages && props.globalAverages && props.postAnalysis &&
                        <ContentAnalysesSimple
                            fetching={false}
                            contentAnalyses={props.postAnalysis}
                            tenantAverages={props.tenantAverages}
                            globalAverages={props.globalAverages}
                            isUsingTenantAverages={props.isUsingTenantAverages}
                            setIsUsingTenantAverages={props.setIsUsingTenantAverages}
                            accordionStates={accordionOpenStates}
                            onChangeAccordion={onChangeAccordionOpen}
                        />
                        }
                    </div>

                    <div style={{marginTop: "0", paddingBottom: "20px"}}>
                        <Divider />
                        <Button variant="text" color="primary" style={{float: "right", position: "relative", top: "6px", marginRight: "12px"}} onClick={onExpandOrCollapseAll}>
                            {expandAllLabel}
                        </Button>
                    </div>
                </div>

        </Drawer>
    )
}

interface ComponentProps {
    isOpen: boolean;
    closeDrawer: () => void;
    postAnalysis: PostAnalysisScores;
    tenantAverages: PostAverages;
    globalAverages: PostAverages;
    isUsingTenantAverages: boolean;
    setIsUsingTenantAverages: (toSet: boolean) => void;
}

export default(AnalyticsDrawer)
