import React from 'react';
import { ConnectedProps, connect } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import ToggleSwitch from 'modules/common/components/toggleSwitch';
import { actions } from '..';

interface ComponentProps {
    title: string; 
    shareToggleEvent: string;
    postsShareUrlEvent: string;
    eventsShareUrlEvent: string;
    handleClick?: (toggleEvent: string, urlShareEvent1: string, urlShareEvent2: string) => void;
    onMouseOver?: (toggleEvent: string, urlShareEvent1: string, urlShareEvent2: string) => void;
}

const ShareUrlsToggleOnly: React.FC<PropsWithRedux> = ({title, shareToggleEvent, postsShareUrlEvent, eventsShareUrlEvent, shareUrlSettings, handleClick, onMouseOver}) => {
    return (
        <div>
            <div className="clearfix">
                <div style={{float:"left", display: "inline-block", width: "1000px", marginTop: "6px"}}>
                    <span style={{fontWeight: 'bold' }}>
                        {title}
                    </span>
                    <span style={{float:"right"}}>
                        <ToggleSwitch 
                            value={shareUrlSettings[shareToggleEvent]}
                            onClick={() => { handleClick && handleClick(shareToggleEvent, postsShareUrlEvent, eventsShareUrlEvent) }}
                            onMouseOverHandler={() => { onMouseOver && onMouseOver(shareToggleEvent, postsShareUrlEvent, eventsShareUrlEvent) }}
                        />
                    </span>
                </div>
            </div>
        </div>
    );
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        shareUrlSettings: state.settings.tenantSettings.shareUrlsConfig,
    }),
    {
        updateShareToggle: actions.SetToggleShareUrl,
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default (connector(ShareUrlsToggleOnly));