import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";

import RoundedTabs from "modules/common/components/roundedTabs";


const Views: React.FunctionComponent<PropsWithRedux> = props => {
  return (
    <RoundedTabs
      tabs={[
        { label: "Upcoming", value: "upcoming" },
        { label: "Open for RSVP", value: "open" },
        { label: "My events", value: "my" },
        { label: "Past", value: "past" }
      ]}
      selectedTab={props.selectedView}
      onChangeTab={props.onChangeView}
    />
  );
}

interface ComponentProps {
  onChangeView: (view: string) => void;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    selectedView: state.events.eventsFeed.selectedView
  })
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(Views);