import * as React from "react"

import NewEvent from "./action-buttons/newEvent";
import EventListing from "./event-listing/eventListing";

import BasePage from "pages/common/basePage";
import Header from "pages/common/header";
import MainContent from "pages/common/mainContent";


import "../styles/events.sass";

class EventManagementPage extends React.Component<{}, {}> {
  public render() {
    return (
      <BasePage fullWidth>
        <Header
          title="Manage Events"
          rightComponent={
            <NewEvent />
          }
        />
        <MainContent>
          <div className="authoring-page">
            <EventListing />
          </div>
        </MainContent>
      </BasePage>
    );
  }
}

export default EventManagementPage;
