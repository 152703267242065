import React from "react";
import { RouteComponentProps } from "react-router-dom";

import BasePage from "../common/basePage";
import Header from "../common/header";
import MainContent from "../common/mainContent";

import queryString from 'query-string';


const PublicNewsletterUnsubscribedPage: React.FunctionComponent<RouteComponentProps<RouteParams>> = props => {
  return (
    <BasePage fullWidth>
      <Header
        title="Confirmation"
      />
      <MainContent addPadding>
        <div>
          You have unsubscribed from <span style={{fontStyle:"italic", fontWeight:"bold"}}>{queryString.parse(props.location.search).newsletter}</span>.
        </div>
      </MainContent>
    </BasePage>
  );
}

interface RouteParams {
  tenant: string;
}

export default PublicNewsletterUnsubscribedPage;