import {select, scaleBand, selectAll, scaleLinear, max, axisBottom} from 'd3';
const d3 = { select, scaleBand, selectAll, scaleLinear, max, axisBottom };

export interface VBarChartProps {
    el: any;
    height: number;
    data: VBarChartDataPoint[];
    labelBars: boolean;
    showOnHover: boolean;
    locale?: string;
}

export interface VBarChartDataPoint {
    id: string;
    label: string;
    value: number;
    valueSecond: number;
    onClick?: (point: VBarChartDataPoint) => any;
}

export class VerticalBarChart {

    constructor(props: VBarChartProps) {
        this.update(props);
    }

    public update(props: VBarChartProps) {

        if (!props.el) {
            return;
        }

        let boundingRect = props.el.getBoundingClientRect();

            d3.select(props.el)
            .select('svg').remove();

        // set the dimensions and margins of the graph
        const margin = {top: 20, right: 20, bottom: 40, left: 20},
            width = boundingRect.width - margin.left - margin.right,
            height = props.height - margin.top - margin.bottom;

        // set the ranges
        const x0 = d3.scaleBand()
                .range([0, width])
                .padding(0.1);
        const x1 = d3.scaleBand()
                .range([0, width])
                .padding(0.05);
        const y = d3.scaleLinear()
                .range([height, 0]);

        d3.select('body').append('div')
            .attr('class', 'bar-chart-tooltip')
            .style('opacity', 0);

        // append the svg object to the body of the page
        // append a 'group' element to 'svg'
        // moves the 'group' element to the top left margin
        const svg = d3.select(props.el)
            .append('svg')
            .attr('width', width + margin.left + margin.right)
            .attr('height', height + margin.top + margin.bottom)
            .append('g')
            .attr('transform',
                'translate(' + margin.left + ',' + margin.top + ')')


        // Scale the range of the data in the domains
        x0.domain(props.data.map((d) => d.label));
        x1.domain(props.data.map((d) => d.label));
        y.domain([0, d3.max(props.data, (d) =>  d.value > d.valueSecond ? d.value : d.valueSecond ) ?? 0]);

        const shiftViewsBar =  x0.bandwidth() / 1.9;
        const shiftReadsBar =  x0.bandwidth() * 0.03;
        const shiftViewsTopLabel =  x0.bandwidth() / 3.7;
        const shiftReadsTopLabel =  x0.bandwidth() / 4;

        // append the rectangles for the bar chart
        const bar = svg.selectAll('.bar')
            .data(props.data)
            .enter()
            .append('g')
            // allow the class that is using this to
            // decide if value will appear when user hovers
            // over the bar
            .attr('class', props.showOnHover ? 'bar-group' : '')

        bar.append('rect')
            .attr('class', 'bar')
            .attr('x', function (d) { return x0(d.label)! + shiftViewsBar; })
            .attr('width', x0.bandwidth() / 2)
            .attr('y', function (d) { return y(d.value); })
            .attr('height', function (d) { return height - y(d.value); });

        bar.append('rect')
            .attr('class', 'barSecond')
            .attr('x', function (d) { return x0(d.label)! - shiftReadsBar; })
            .attr('width', x0.bandwidth() / 2)
            .attr('y', function (d) { return y(d.valueSecond); })
            .attr('height', function (d) { return height - y(d.valueSecond); });

        if (props.labelBars) {
            bar.append('text')
                .attr('class', 'top-label')
                .text(function (d) { return d.value; })
                .attr('x', function (d) { return x1(d.label)! + shiftViewsTopLabel; })
                .attr('y', function (d) { return y(d.value); })
                .attr('width', x1.bandwidth())
                .attr('transform', function (d) {
                    const xOffset = (x1.bandwidth() / 2 - (this as any).getBBox().width / 2);
                    return 'translate(' + xOffset + ', -5)'
                });
            bar.append('text')
                .attr('class', 'top-label')
                .text(function (d) { return d.valueSecond; })
                .attr('x', function (d) { return x1(d.label)! - shiftReadsTopLabel; })
                .attr('y', function (d) { return y(d.valueSecond); })
                .attr('width', x1.bandwidth())
                .attr('transform', function (d) {
                    const xOffset = (x1.bandwidth() / 2 - (this as any).getBBox().width / 2);
                    return 'translate(' + xOffset + ', -5)'
                });
        }

        // add the x Axis
        svg.append('g')
            .attr('transform', 'translate(0,' + height + ')')
            .call(d3.axisBottom(x0));
          }
          
    public destroy() {
        // Any clean-up would go here
        // in this example there is nothing to do
    }
}

