import React from "react";

const EditorWrapperLayout: React.FunctionComponent<{}> = (props) => {
    return (
        <div className="authoring-container">
            {props.children}
        </div>
    );
}

export default EditorWrapperLayout;
