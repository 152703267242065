import React from "react";
import { connect, ConnectedProps } from "react-redux";
import * as actions from "../../actionCreator";
import { GlobalApplicationState } from "globalApplicationState";
import { push } from "react-router-redux";
import { PortalPagesFilterValues, PortalPagesListingPage, PortalPagesListItem, PortalPageState } from "../../models";
import moment from "moment";

import AudienceChipLabels from "modules/common/components/chips/audienceChipLabels";
import Loading from "modules/common/components/loading";

import MorePortalPageOptions from "../action-buttons/morePortalPageOptions";
import ModifyPortalPageDetailsDialog from "../dialogs/modifyPortalPageDetailsDialog";
import ModifyPortalPageTitleDialog from "../dialogs/modifyPortalPageTitleDialog";
import PortalPagesFilters from "../portal-pages-filters/portalPagesFilters";
import PortalPagePreview from "../portal-pages-views/portalPagePreview";

import TabContent from "pages/common/tabContent";
import TabContentToolbar from "pages/common/tabContentToolbar";

import confirm from "utils/notyPopups";

import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import BlockIcon from "@mui/icons-material/Block";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { InfoOutlined } from "@mui/icons-material/";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { SortStyle } from "utils/managementUtils";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import Cookies from "js-cookie";
import Paging from "modules/common/components/paging";
import ManagementEmptyResults from "modules/common/components/managementEmptyResults";
import { ROWS_PER_PAGE_COOKIE_NAMES } from "utils/cookie";
import HoverText from "modules/documents/components/action-buttons/hoverText";
import { setShouldDisplayNav } from "modules/adminLayout/actionCreator";


interface HeaderCell {
  disablePadding: boolean;
  id: string;
  label: string;
  ascValue?: SortStyle;
  descValue?: SortStyle;
  clickable?: boolean;
}


class PortalPagesList extends React.Component<PropsWithRedux, ComponentState> {
  constructor(props: PropsWithRedux) {
    super(props);

    this.state = {
      filters: { ...this.getDefaultFilterValues() },
      hasFiltersApplied: false,
      isPagedLoad: false,
      portalPageDetails: undefined,
      selectedItems: [],
      showPortalPageDetails: false,
      showPortalPageTitle: false,
      sortMethod: props.initialSort
    };
  }

  public componentDidMount() {
    moment.locale("en");

    if (!this.props.shouldDisplayNav)
        this.props.setShouldDisplayNav(true);
  }

  public componentDidUpdate(prevProps: PropsWithRedux) {
    if (this.props.page.id !== prevProps.page.id) {
      this.setState({ selectedItems: [] });
    }

    if (this.props.hasRefreshed && !prevProps.hasRefreshed) {
      this.setState({ filters: { ...this.getDefaultFilterValues() }, hasFiltersApplied: false });
    }
  }

  public render() {
    const { page } = this.props;

    if (!this.props.show)
      return <React.Fragment></React.Fragment>;

    if (page.isFetching && !this.state.isPagedLoad)
      return <Loading />;

    return (
      <div className="portal-pages-list">
        {this.getToolbar()}
        <TabContent>
          {this.getFilter()}
          {this.getList()}
          <PortalPagePreview portalPagesListItem={this.state.portalPageDetails} onShowPortalPageDetails={this.onShowPortalPageDetails} onShowPortalPageTitle={this.onShowPortalPageTitle} />
          <ModifyPortalPageDetailsDialog show={this.state.showPortalPageDetails} portalPage={this.state.portalPageDetails} onClose={this.onHidePortalPageDetails} />
          <ModifyPortalPageTitleDialog show={this.state.showPortalPageTitle} portalPage={this.state.portalPageDetails} onClose={this.onHidePortalPageTitle} />
        </TabContent>
      </div>
    );
  }

  private formatModifiedTime = (time?: string, hasBeenPreviouslyPublished?: boolean): JSX.Element => {
    if (time) {
      return (
        <div className="portal-page-time">
          <span>{moment(new Date(time)).format("MMM D, YYYY, h:mmA")}</span>
          {hasBeenPreviouslyPublished &&
            <div title="This is a draft of a currently published page.">
              <CheckCircleOutlineIcon className="modified-time" />
            </div>
          }
        </div>
      );
    }
    return <div>-</div>;
  }

  private formatPublishedTime = (time?: string, hasPendingDraft?: boolean): JSX.Element => {
    if (time) {
      return (
        <div className="portal-page-time">
          <span>{moment(new Date(time)).format("MMM D, YYYY, h:mmA")}</span>
          {hasPendingDraft &&
            <HoverText label={<InfoOutlined style={{ color: "#e6911a" }} />} underline={false} inline>
            You have unpublished, saved changes to this page.
            </HoverText>}
        </div>
      );
    }
    return <div>-</div>;
  }

  private formatState = (state: PortalPageState): JSX.Element => {
    if (state === "Disabled")
      return <span className="portal-page-state grey">{state}</span>;
    else if (state === "Draft")
      return <span className="portal-page-state yellow">{state}</span>;
    else
      return <span className="portal-page-state green">{state}</span>;
  }

  private formatTitle = (title: string, isHome: boolean): JSX.Element => {
    return (
      <div className="portal-page-title">
        <span>{title}</span>
        {isHome &&
          <div title="This is your homepage.">
            <HomeOutlinedIcon fontSize="small" />
          </div>
        }
      </div>
    );
  }


  private getDefaultFilterValues = (): Partial<PortalPagesFilterValues> => {
    const filters: Partial<PortalPagesFilterValues> = {
      audiences: [],
      currentFilter: [],
      publishedFromDate: "",
      publishedToDate: "",
      searchText: ""
    };
    return filters;
  }


  private getFilter = (): JSX.Element => {
    return (
      <PortalPagesFilters
        id={this.props.page.id}
        filters={this.state.filters}
        onChangeFilters={this.onChangeFilters}
        onClearFilters={this.onClearFilters}
      />
    );
  }

  //Default to descending, if currently descending make it ascending.
  private onClickHeader = (descValue: SortStyle | undefined, ascValue: SortStyle | undefined) => {
    const rowsPerPage = Cookies.get(ROWS_PER_PAGE_COOKIE_NAMES.DEFAULT);

    if(descValue === undefined || ascValue === undefined) {
      return;
    }

    if(this.state.sortMethod === descValue) {
      this.setState({sortMethod: ascValue, filters: {...this.state.filters, sortType: ascValue}}, () => {
        this.onChangePage(0, Number(rowsPerPage) ?? 10);
      });
    }
    else {
      this.setState({sortMethod: descValue, filters: {...this.state.filters, sortType: descValue}}, () => {
        this.onChangePage(0, Number(rowsPerPage) ?? 10);
      });
    }
  }

  private getHeader = (): HeaderCell[] => {
    const { page } = this.props;

    let header: HeaderCell[] = [
      { id: "title", disablePadding: true, label: "Page title", descValue: SortStyle.titleDesc, ascValue: SortStyle.titleAsc, clickable: true },
      { id: "options", disablePadding: true, label: "" }
    ];

    if (page.id === "all")
      header.push({ id: "state", disablePadding: false, label: "State", descValue: SortStyle.stateDesc, ascValue: SortStyle.stateAsc, clickable: true });

    if (page.id !== "drafts")
      header.push({ id: "publishDate", disablePadding: false, label: "Publish date", descValue: SortStyle.publishDesc, ascValue: SortStyle.publishAsc, clickable: true });
    if (page.id === "drafts")
      header.push({ id: "lastModifiedTime", disablePadding: false, label: "Last saved", descValue: SortStyle.modifiedDesc, ascValue: SortStyle.modifiedAsc, clickable: true });

    header.push({ id: "lastModifiedBy", disablePadding: false, label: "Last modified by", descValue: SortStyle.authorDesc, ascValue: SortStyle.authorAsc, clickable: true });
    header.push({ id: "audiences", disablePadding: false, label: "Accessible to" });

    return header;
  }

  private getList = (): JSX.Element => {
    const { page } = this.props;
    const { selectedItems } = this.state;

    const rowCount = page.portalPages.length || 10;

    if (page.isFetching)
      return <Loading />;

    if (!page.portalPages.length)
      return (
        <ManagementEmptyResults searchType={"Portal Pages"} hasFilters={this.state.hasFiltersApplied}/>
      );

    return (
      <React.Fragment>
        {this.state.hasFiltersApplied &&
          <Typography variant="h2" className="portal-page-results">Results</Typography>
        }
        <TableContainer>
          <Table size="medium">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    indeterminate={selectedItems.length > 0 && selectedItems.length < rowCount}
                    checked={rowCount > 0 && selectedItems.length === rowCount}
                    onChange={this.onSelectAllPortalPages}
                    inputProps={{ "aria-label": "select all pages" }}
                  />
                </TableCell>
                {this.getHeader().map((headerCell) => {
                  if (headerCell.id === "options")
                    return <TableCell key={headerCell.id} padding="checkbox"></TableCell>

                  return (
                    <TableCell
                      key={headerCell.id}
                      align={headerCell.disablePadding ? "left" : "center"}
                      padding={headerCell.disablePadding ? "none" : "normal"}
                      onClick={() => this.onClickHeader(headerCell.descValue, headerCell.ascValue)}
                      style={{cursor: headerCell.clickable ? "pointer" : ""}}
                    >
                      {headerCell.label}
                      {this.state.sortMethod === headerCell.ascValue && <ArrowUpward htmlColor="#7A7A7A" style={{position: "relative", top: "8px", paddingLeft: "2px"}}/>}
                      {this.state.sortMethod === headerCell.descValue && <ArrowDownward htmlColor="#7A7A7A" style={{position: "relative", top: "8px", paddingLeft: "2px"}}/>}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {page.portalPages
                .map((portalPage, index) => this.getListItem(portalPage, index))
              }
            </TableBody>
          </Table>
        </TableContainer>
        <Paging
          currentPage={page.currentPage}
          items={page.portalPages}
          totalItems={page.totalPortalPages}
          totalPages={page.totalPages}
          onChangePage={this.onChangePage}
          resetSelection={this.resetSelection}
        />
      </React.Fragment>
    );
  }

  private getListItem = (portalPage: PortalPagesListItem, index: number): JSX.Element => {
    const isPortalPageSelected = this.isPortalPageSelected(portalPage);
    const labelId = `portal-page-list-checkbox-${index}`;

    return (
      <React.Fragment key={portalPage.id}>
        <TableRow
          hover
          onClick={(ev) => this.onPreviewClickedPortalPage(ev, portalPage)}
          role="checkbox"
          aria-checked={isPortalPageSelected}
          tabIndex={-1}
          key={portalPage.id}
          selected={isPortalPageSelected}
        >
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              checked={isPortalPageSelected}
              inputProps={{ "aria-labelledby": labelId }}
              onClick={(ev) => this.onSelectPortalPage(ev, portalPage)}
            />
          </TableCell>
          <TableCell component="th" id={labelId} scope="row" padding="none">{this.formatTitle(portalPage.title, portalPage.isHome)}</TableCell>
          <TableCell padding="checkbox">
            <MorePortalPageOptions portalPage={portalPage} onPreviewPortalPage={() => this.onPreviewPortalPage(portalPage)} onSelection={this.onClearAllPortalPages} />
          </TableCell>
          {this.props.page.id === "all" && <TableCell align="center">{this.formatState(portalPage.state)}</TableCell>}
          {this.props.page.id !== "drafts"
            ? <TableCell align="center">{this.formatPublishedTime(portalPage.publishDate, portalPage.hasPendingDraft)}</TableCell>
            : <TableCell align="center">{this.formatModifiedTime(portalPage.lastModifiedTime, portalPage.hasBeenPreviouslyPublished)}</TableCell>
          }
          <TableCell align="center">{portalPage.lastModifiedBy}</TableCell>
          <TableCell align="center"><AudienceChipLabels audienceIds={portalPage.audiences} audiences={this.props.audiences} /></TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  private getToolbar = (): JSX.Element => {
    const { selectedItems } = this.state;

    const isOnlyHomeSelected = selectedItems.length === 1 && selectedItems[0].isHome;

    if (!selectedItems.length)
      return <React.Fragment></React.Fragment>;

    return (
      <TabContentToolbar>
        <div>
          {this.getToolbarIcon(`${selectedItems.length} selected`, <ClearIcon />, this.onClearAllPortalPages)}
        </div>
        <div>
          {selectedItems.length === 1 &&
            <React.Fragment>
              {this.getToolbarIcon("Open in page editor", <EditIcon />, this.onEditPortalPage)}
              {this.getToolbarIcon("Preview", <VisibilityIcon />, () => this.onPreviewPortalPage(selectedItems[0]))}
              {selectedItems[0].state === "Disabled" && this.getToolbarIcon("Enable", <CheckCircleOutlineIcon />, this.onEnablePortalPage)}
              {selectedItems[0].state === "Enabled" && this.getToolbarIcon("Disable", <BlockIcon style={{ transform: "rotate(90deg)" }} />, this.onDisablePortalPage)}
              {/* {this.getToolbarIcon("Duplicate", <FileCopyIcon />, this.onClonePortalPage)} */}
            </React.Fragment>
          }
          {!isOnlyHomeSelected && this.getToolbarIcon("Delete", <DeleteIcon />, this.onDeletePortalPages)}
        </div>
      </TabContentToolbar>
    );
  }

  private getToolbarIcon = (title: string, icon: React.ReactNode, onClick: () => void) => {
    return (
      <Button aria-label={title.toLowerCase()} color="primary" startIcon={icon} onClick={onClick}>
        {title}
      </Button>
    );
  }


  private isPortalPageSelected = (portalPage: PortalPagesListItem): boolean => {
    return this.state.selectedItems.findIndex((selectedItem) => selectedItem.id === portalPage.id) !== -1;
  }


  private onChangeFilters = (filters: Partial<PortalPagesFilterValues>) => {
    const updatedFilters = { ...this.state.filters, ...filters };
    this.setState({ filters: updatedFilters, hasFiltersApplied: true, isPagedLoad: true });
    this.props.fetchPage(1, updatedFilters);
  }

  private onClearFilters = () => {
    this.setState({ hasFiltersApplied: false, filters: { ...this.getDefaultFilterValues() }, isPagedLoad: true });
    this.props.fetchPage(1, {});
  }


  private onClearAllPortalPages = () => {
    this.setState({ selectedItems: [] });
  }

  private onClonePortalPage = () => {
    // this.props.clonePortalPage(this.state.selectedItems[0].draftId).then((response) => {
    //   if (!!response) {
    //     this.props.redirectTo("/" + this.props.tenant + "/admin/portalPages/edit/" + response.id);
    //     this.onClearAllPortalPages();
    //   }
    // });
  }

  private onDeletePortalPages = async () => {
    if (this.state.selectedItems.length === 1) {
      const selectedItem: PortalPagesListItem = this.state.selectedItems[0];
      if (selectedItem.state === "Draft" && !selectedItem.isHome) {
        if (await confirm.show({
          text: `You're about to permanently delete '${selectedItem.title}'. You cannot undo this action. Are you sure?`,
          title: "Delete Draft",
          yesColor: "#a80000",
          yesText: "Delete",
          noText: "Cancel"
        }))
          this.props.deleteDraft(selectedItem.draftId);
      } else {
        if(!selectedItem.isHome) {
          if (await confirm.show({
            text: `You're about to permanently delete '${selectedItem.title}'. You cannot undo this action. Are you sure?`,
            title: "Delete Portal Page",
            yesColor: "#a80000",
            yesText: "Delete",
            noText: "Cancel"
          }))
            this.props.deletePortalPage(selectedItem.id);
        }
      }
      this.onClearAllPortalPages();
    } else {
      let validDeletionItems = this.state.selectedItems.filter(s => !s.isHome);

      if (this.props.page.id === "drafts") {
        if (await confirm.show({
            text: "Are you sure you want to delete these drafts?",
            title: "Delete Drafts",
            yesColor: "#a80000",
            yesText: "Delete",
            noText: "Cancel"
          })) {
          this.props.deletePortalPages(validDeletionItems);
          this.onClearAllPortalPages();
        }
      } else {
        if (await confirm.show({
            text: "Are you sure you want to delete these pages?",
            title: "Delete Portal Pages",
            yesColor: "#a80000",
            yesText: "Delete",
            noText: "Cancel"
          })) {
          this.props.deletePortalPages(validDeletionItems);
          this.onClearAllPortalPages();
        }
      }
    }
  }

  private onEnablePortalPage = () => {
    this.props.enablePortalPage(this.state.selectedItems[0].id).then(() => {
      this.onClearAllPortalPages();
    });
  }

  private onEditPortalPage = () => {
    this.props.redirectTo("/" + this.props.tenant + "/admin/portalPages/edit/" + this.state.selectedItems[0].draftId);
  }

  private onDisablePortalPage = () => {
    this.props.disablePortalPage(this.state.selectedItems[0].id).then(() => {
      this.onClearAllPortalPages();
    });
  }

  private onPreviewPortalPage = (portalPage: PortalPagesListItem) => {
    this.setState({ portalPageDetails: portalPage });
    this.props.getDraft(portalPage.draftId).then((draft) => {
      this.props.showPreview(draft);
    });
  }

  private onSelectAllPortalPages = () => {
    if (this.state.selectedItems.length === this.props.page.portalPages.length)
      this.setState({ selectedItems: [] });
    else
      this.setState({ selectedItems: this.props.page.portalPages });
  }

  private onSelectPortalPage = (ev, portalPage: PortalPagesListItem) => {
    ev.stopPropagation();
    let selectedItems = this.state.selectedItems.slice();

    if (selectedItems.findIndex((selectedItem) => selectedItem.draftId === portalPage.draftId) === -1)
      selectedItems = selectedItems.concat([portalPage]);
    else
      selectedItems = selectedItems.filter(selectedItem => selectedItem.draftId !== portalPage.draftId);

    this.setState({ selectedItems });
  }

  private onPreviewClickedPortalPage = (ev, portalPage: PortalPagesListItem) => {
    this.setState({ portalPageDetails: portalPage });
    this.props.getDraft(portalPage.draftId).then((draft) => {
      this.props.showPreview(draft);
    });
  }

  private onHidePortalPageDetails = () => {
    this.setState({ portalPageDetails: undefined, showPortalPageDetails: false });
  }

  private onHidePortalPageTitle = () => {
    this.setState({ portalPageDetails: undefined, showPortalPageTitle: false });
  }

  private onShowPortalPageDetails = (portalPage: PortalPagesListItem) => {
    this.setState({ portalPageDetails: portalPage, showPortalPageDetails: true });
  }

  private onShowPortalPageTitle = (portalPage: PortalPagesListItem) => {
    this.setState({ portalPageDetails: portalPage, showPortalPageTitle: true });
  }

  private resetSelection = () => {
    this.setState({selectedItems: []});
  }

  private onChangePage = (page: number, pageAmount: number) => {
    this.props.fetchPage(page + 1, this.state.filters, pageAmount);
  }
}


interface ComponentProps {
  show: boolean;
  page: PortalPagesListingPage;
  hasRefreshed?: boolean;
  fetchPage: (pageNumber: number, filters: Partial<PortalPagesFilterValues>, pageAmount?: number) => void;
  initialSort: SortStyle;
}

interface ComponentState {
  filters: Partial<PortalPagesFilterValues>;
  hasFiltersApplied: boolean;
  isPagedLoad: boolean;
  portalPageDetails: PortalPagesListItem | undefined;
  selectedItems: PortalPagesListItem[];
  showPortalPageDetails: boolean;
  showPortalPageTitle: boolean;
  sortMethod: SortStyle;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    audiences: state.audiences.audiences,
    tenant: state.tenant.id,
    shouldDisplayNav: state.adminLayout.shouldDisplayNav
  }),
  {
  //   clonePortalPage: actions.clonePortalPage,
    deleteDraft: actions.deleteDraft,
    deletePortalPage: actions.deletePortalPage,
    deletePortalPages: actions.deletePortalPages,
    disablePortalPage: actions.disablePortalPage,
    enablePortalPage: actions.enablePortalPage,
    getDraft: actions.getDraft,
    redirectTo: push,
    showPreview: actions.showPreview,
    setShouldDisplayNav
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(PortalPagesList);
