import _ from 'lodash';
import numeral from 'numeral';
import {select, scaleBand, selectAll, scaleLinear, scaleOrdinal, max, axisLeft, axisBottom, map, range, schemeCategory10} from 'd3';
const d3 = { select, scaleBand, selectAll, scaleLinear, scaleOrdinal, max, axisLeft, axisBottom, map, range, schemeCategory10 };

export interface GroupedBarChartProps {
    el: any;
    width?: number;
    height: number;
    data: GroupedBarChartDataPoint[];
    showOnHover: boolean;
    locale?: string;
    colors?: string[];
}

export interface GroupedBarChartDataPoint {
    id: string;
    group: string;
    label: string;
    value: number;
    onClick?: (point: GroupedBarChartDataPoint) => any;
}

export class GroupedBarChart {

    constructor(props: GroupedBarChartProps) {
        this.update(props);
    }

    public update(props: GroupedBarChartProps) {

        if (!props.el) {
            return;
        }
        d3.select(props.el).select('svg').remove();

        const X = _.map(props.data, d => d.group);
        const Y = _.map(props.data, d => d.value);
        const Z = _.map(props.data, d => d.label);

        const xDomain = _.uniq(X);
        const maxY = _.max(Y);
        const yDomain = [0, !!maxY ? maxY : 1];
        const zDomain = _.uniq(Z);

        const boundingRect = props.el.getBoundingClientRect();
        // set the dimensions and margins of the graph
        const margin = {top: 15, right: 10, bottom: 25, left: 40};
        const width = props.width || boundingRect.width;
        const height = props.height;
        const xPadding = 0.1; // amount of x-range to reserve to separate groups
        const zPadding = 0.05; // amount of x-range to reserve to separate bars

        const colors = !!props.colors ? props.colors : d3.schemeCategory10

        const xScale = d3.scaleBand().domain(xDomain).range([margin.left, width-margin.right]).padding(xPadding);
        const xzScale = d3.scaleBand().domain(zDomain).range([0, xScale.bandwidth()]).padding(zPadding);
        const yScale = d3.scaleLinear().domain(yDomain).range([height-margin.bottom, margin.top]);
        const zScale = d3.scaleOrdinal(colors).domain(zDomain);
        const xAxis = d3.axisBottom(xScale).tickSizeOuter(0);
        const yAxis = d3.axisLeft(yScale).ticks(height / 60).tickFormat(y=>numeral(y).format("0a"));

        // d3.select('body').append('div')
        //     .attr('class', 'bar-chart-tooltip').style('opacity', 0);

        const svg = d3.select(props.el)
            .append('svg')
            .attr('width', width)
            .attr('height', height)
            .attr("viewBox", `0, 0, ${width}, ${height}`)
            .attr("style", "height: auto; height: intrinsic;");
        
        svg.append('g')
            .attr('transform', `translate(${margin.left},0)`)
            .call(yAxis)
            .call(g=>g.select(".domain").remove())
            .call(g=>g.selectAll(".tick line").clone().attr("x2", width - margin.left - margin.right).attr("stroke-opacity", 0.1))
            .call(g => g.append("text")
                .attr("x", -margin.left)
                .attr("y", 10)
                .attr("fill", "currentColor"));

        // append the rectangles for the bar chart
        const bar = svg.selectAll('.bar')
            .data(props.data)
            .enter()
            .append('g');

        bar.append('rect')
            .attr('class', 'bar')
            .attr('x', d => (xScale(d.group)! + xzScale(d.label)!))
            .attr('width', xzScale.bandwidth())
            .attr('y', d => yScale(d.value))
            .attr('height', d => yScale(0) - yScale(d.value))
            .attr('fill', d => zScale(d.label));
        
        if(props.showOnHover)
            bar.append("title").text(d=>`${numeral(d.value).format("0,000")} ${d.label}`);

        // add the x Axis
        svg.append('g')
            .attr('transform', `translate(0,${height - margin.bottom})`)
            .call(xAxis);
        
        Object.assign(svg.node() as any, {scales : {color: zScale}});
    }
          
    public destroy() {
        // Any clean-up would go here
        // in this example there is nothing to do
    }
}

