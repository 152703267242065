import React, { useState } from "react";

import Callout from "modules/common/components/callout";

import Button from "@mui/material/Button";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";


const FilterContainer: React.FunctionComponent<ComponentProps> = props => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [calloutOpen, setCalloutOpen] = useState(false);

  return (
    <div className="filter-callout-button">
      <Button
        variant="text"
        color="primary"
        endIcon={<ExpandMoreIcon />}
        onClick={(event: any) => {
          setAnchorEl(event.currentTarget)
          setCalloutOpen(true)
        }}
        ref={anchorEl}
      >
        {props.text}
      </Button>
      <Callout
        anchorEl={anchorEl}
        setOpen={setCalloutOpen}
        footer={props.footer}
        open={calloutOpen}
      >
        {props.children}
      </Callout>
    </div>
  );
}


interface ComponentProps {
  text: string;
  footer?: JSX.Element;
}


export default FilterContainer;