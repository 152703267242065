import * as React from "react";

import Switch from "@mui/material/Switch";

class ReactingEnabled extends React.Component<ComponentProps, ComponentState> {
    public render() {
        const { reactingEnabled = false, disabled = false } = this.props;

        return (
            <React.Fragment>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: 15
                }}>
                    <div>Enable Reactions</div>
                    <Switch
                        color="primary"
                        checked={reactingEnabled}
                        onChange={this.onToggleReactingEnabled}
                        disabled={disabled}
                    />
                </div>
            </React.Fragment>
        );
    }

    private onToggleReactingEnabled = () => {
        this.props.onChange(!this.props.reactingEnabled);
    }
}


interface ComponentProps {
    reactingEnabled: boolean;
    onChange: (reactingEnabled: boolean) => void;
    disabled?: boolean;
}

interface ComponentState { }

export default ReactingEnabled;
