import * as React from "react";

import { AttachedFile } from "modules/gallery/models";
import DocumentGallery from "./dialogs/documentGallery";

import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";

import AddIcon from "@mui/icons-material/Add";
import BlockIcon from "@mui/icons-material/Block";
import DeleteIcon from "@mui/icons-material/Delete";
import HoverText from "modules/documents/components/action-buttons/hoverText";


class FileAttachments extends React.Component<ComponentProps, ComponentState> {
  constructor(props: ComponentProps) {
    super(props);

    this.state = {
      showDocumentGallery: false
    };
  }

  private _getTitle = (): string => {
    const { fileAttachments } = this.props;

    let label = "Attach files";
    if (fileAttachments && fileAttachments.length === 1)
        label = "Attached file (1)";
    else if (fileAttachments && fileAttachments.length > 1)
        label = `Attached files (${fileAttachments.length})`;

    return label;
  }

    private _getItem = (fileAttachment: AttachedFile) => {
        const { itemStyle, itemCloseIcon } = this.props;
        return (
            <Paper
                key={fileAttachment.ospreyId}
                elevation={1}
                className="file-attachment"
                style={{ ...itemStyle }}
            >
                <div>
                    {/* only show 37 characters */}
                    {fileAttachment.fileName.length > 37
                        ? <HoverText popoverProps={{ anchorOrigin: { horizontal: "left", vertical: "center"}}} underline={false} label={`${fileAttachment.fileName.substring(0, 37)}...`}>
                            <span style={{ whiteSpace: "normal", wordBreak:"break-all" }}>{fileAttachment.fileName}</span>
                            </HoverText>
                        : <span>{fileAttachment.fileName}</span>}
                </div>
                <div>
                    <IconButton size="small" onClick={() => this.onRemoveFileAttachment(fileAttachment)}>
                        {itemCloseIcon || <DeleteIcon />}
                    </IconButton>
                </div>
            </Paper>
        );
    }

  public render() {
    const {
        fileAttachments,
        paperStyle,
        footNoteStyle,
        buttonStyle,
        buttonLabel,
        removeLabel,
        v2 = false,
    } = this.props;

    return (
      <Collapse in={this.props.show}>
        <Paper elevation={0} className="section-card" style={{ ...paperStyle }}>
          <div className="optional-content-header">
            <div>{this._getTitle()}</div>
            {v2
                ? <Button variant="text" color="primary" size="small" startIcon={<AddIcon fontSize="small" />} onClick={this.onShowDocumentGallery} className="add-new-item" style={{ ...buttonStyle }}>
                    {buttonLabel || "ADD FILE" }
                </Button>
                : <>
                    {fileAttachments && fileAttachments.length > 0 &&
                        <div className="remove-optional-content">
                            <Button variant="text" color="inherit" startIcon={<BlockIcon />} onClick={this.props.onRemove}>Remove All Attachments</Button>
                        </div>}
                    </>}
          </div>
          {!!(fileAttachments || []).length && fileAttachments!.map(this._getItem)}
          {v2
            ? fileAttachments && fileAttachments.length > 1 &&
                <div className="remove-optional-content">
                    <Button variant="text" color="inherit" startIcon={<BlockIcon />} onClick={this.props.onRemove}>{removeLabel || "Remove All Attachments"}</Button>
                </div>
            : <Button variant="text" color="primary" size="small" startIcon={<AddIcon fontSize="small" />} onClick={this.onShowDocumentGallery} className="add-new-item" style={{ ...buttonStyle }}>
                    {buttonLabel || "ADD FILE" }
                </Button>}
          <div className="optional-content-disclaimer" style={{ ...footNoteStyle }}>
            Attaching large files can affect publishing time
          </div>
          <DocumentGallery
            show={this.state.showDocumentGallery}
            fileAttachments={fileAttachments}
            onApply={this.onApplyFileAttachmentChanges}
            onClose={this.onHideDocumentGallery}
          />
        </Paper>
      </Collapse>
    );
  }

  private onApplyFileAttachmentChanges = (fileAttachments: AttachedFile[]) => {
    this.props.onChange(fileAttachments);
  }

  private onHideDocumentGallery = () => {
    this.setState({ ...this.state, showDocumentGallery: false });
  }

  private onRemoveFileAttachment = (fileAttachment: AttachedFile) => {
    this.props.onChange(this.props.fileAttachments!.filter((file) => file.ospreyId !== fileAttachment.ospreyId));
  }

  private onShowDocumentGallery = () => {
    this.setState({ ...this.state, showDocumentGallery: true });
  }
}

interface ComponentProps {
  show: boolean;
  fileAttachments: AttachedFile[] | undefined;
  paperStyle?: React.CSSProperties;
  footNoteStyle?: React.CSSProperties;
  buttonLabel?: string;
  buttonStyle?: React.CSSProperties;
  itemStyle?: React.CSSProperties;
  itemCloseIcon?: JSX.Element;
  removeLabel?: string;
  v2?: boolean; //authoring v2 layout
  onChange: (fileAttachments: AttachedFile[]) => void;
  onRemove: () => void;
}

interface ComponentState {
  showDocumentGallery: boolean;
}

export default FileAttachments;
