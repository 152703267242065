import * as React from 'react';

import './three-dots.sass';

interface ComponentProps {
    width?: number
    height?: number
}
interface ComponentState { }

export default class ThreeDots extends React.Component<ComponentProps, ComponentState> {

    public shouldComponentUpdate() {
        return false;
    }

    public render() {
        return (
            <div className="three-dots">
                <svg
                    width={this.props.width || '50'}
                    height={this.props.height || '25'}
                    viewBox="0 0 50 25">
                    <g
                        id="layer1"
                        transform="translate(0,-1027.3621)">
                        <rect
                            className="three-dots-dot dot-1"
                            width="10.871767"
                            height="10.960155"
                            x="3.6801386"
                            y="1033.6238" />
                        <rect
                            className="three-dots-dot dot-2"
                            width="10.871767"
                            height="10.871767"
                            x="19.457458"
                            y="1033.7008" />
                        <rect
                            className="three-dots-dot dot-3"
                            width="10.871767"
                            height="10.871767"
                            x="35.54414"
                            y="1033.668" />
                    </g>
                </svg>
            </div>
        );
    }
}