import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { GlobalApplicationState } from 'globalApplicationState';
import {injectIntl, IntlShape} from "react-intl";
import BasePage from "pages/common/basePage";
import Header from "pages/common/header";
import MainContent from "pages/common/mainContent";
import GalleryVFSBrowser from './galleryVFSBrowser';
import UploadMediaButton from './uploadMediaButton';
import MediaPreviewer from './mediaPreviewer';
import { CustomFileData, Image } from '../models';
import * as actions from 'modules/posts/actionCreator';

interface ComponentProps {
  intl: IntlShape;
}

interface ComponentState {
  filesToUpload: FileList | null;
  previewImage?: Image;
  fileToRename?: CustomFileData;
}

class ImageGallery extends React.PureComponent<PropsWithRedux, ComponentState> {
    constructor(props){
        super(props);

        this.state = {
            filesToUpload: null,
            previewImage: undefined,
            fileToRename: undefined,
        };
    }

    public render() {
      return (
        <BasePage fullWidth>
          <Header
            title="Media Gallery"
            rightComponent={<UploadMediaButton onFileUpload={files => this.setState({ filesToUpload: files })} />}
          />
          <MainContent fullHeight>
            <div 
                className="image-gallery"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    overflowX: 'hidden'
                }}
            >
                <GalleryVFSBrowser
                    externalFilesToUpload={this.state.filesToUpload}
                    onSingleSelect={file => this.onSingleSelect(file)}
                    onMultiSelect={files => this.onMultiSelect(files)}
                    externalFileToRename={this.state.fileToRename}
                    clearExternalFileToRename={() => this.state.previewImage ? this.onSingleSelect(this.state.previewImage) : undefined}
                />
                <MediaPreviewer
                    previewImage={this.state.previewImage}
                    onPreviewClose={() => this.setState({ previewImage: undefined })}
                    onKeywordsChange={keywords => this.onKeywordsChange(keywords)}
                    onNameEdit={(imageId: string) => {
                        const chonkyImageData = this.props.imageLibrary.chonkyFileData.find(x => x.id === imageId);
                        if (chonkyImageData) {
                            this.setState({ fileToRename: chonkyImageData });
                        }
                    }}
                />
            </div>
          </MainContent>
        </BasePage>
      );
    }

    private async onSingleSelect(mediaFile: CustomFileData): Promise<void> {
        if (mediaFile === undefined || mediaFile.isDir) return;

        let image = await this.props.fetchSingleImage(mediaFile.id);
        
        this.setState({ previewImage: image });
    }

    private onMultiSelect(mediaFiles: CustomFileData[]) {}

    private async onKeywordsChange (keywords: string[]) {
        if (this.state.previewImage?.id)
            await this.props.setImageKeywords(this.state.previewImage.id, keywords);
    }
}

const connector = connect((state: GlobalApplicationState, ownProps: ComponentProps) => ({ 
        ...ownProps,
        imageLibrary: state.posts.imageLibrary,
    }),
    {
        fetchSingleImage: actions.fetchImage,
        setImageKeywords: actions.setImageKeywords,
    }
);

type PropsWithRedux = ConnectedProps<typeof connector>;

export default injectIntl(connector(ImageGallery));