import { Action } from "redux";
import { CompleteAction, ActionCreator } from "typedActions";
import { Document, DocumentFeed, DocumentFeedFilters, DocumentFeedItem, DocumentFeedLayout, DocumentPreview, DocumentsListingPage } from "./models";


export const GET_ATTACHED_ARTICLES = "GET_ATTACHED_ARTICLES";
export interface GetAttachedArticles extends Action {};
export const GetAttachedArticles = ActionCreator<GetAttachedArticles>(GET_ATTACHED_ARTICLES);

export const GET_ATTACHED_ARTICLES_COMPLETE = "GET_ATTACHED_ARTICLES_COMPLETE";
export interface GetAttachedArticlesComplete extends CompleteAction {};
export const GetAttachedArticlesComplete = ActionCreator<GetAttachedArticlesComplete>(GET_ATTACHED_ARTICLES_COMPLETE);

export const GET_DOCUMENT_DETAILS = "GET_DOCUMENT_DETAILS";
export interface GetDocumentDetails extends Action {};
export const GetDocumentDetails = ActionCreator<GetDocumentDetails>(GET_DOCUMENT_DETAILS);

export const GET_DOCUMENT_DETAILS_COMPLETE = "GET_DOCUMENT_DETAILS_COMPLETE";
export interface GetDocumentDetailsComplete extends CompleteAction {};
export const GetDocumentDetailsComplete = ActionCreator<GetDocumentDetailsComplete>(GET_DOCUMENT_DETAILS_COMPLETE);

export const GET_CATEGORY_TAG_BY_ID = "GET_CATEGORY_TAG_BY_ID"
export interface GetCategoryTagById extends Action {};
export const GetCategoryTagById = ActionCreator<GetCategoryTagById>(GET_CATEGORY_TAG_BY_ID);

export const GET_DOCUMENT_FEED = "GET_DOCUMENT_FEED";
export interface GetDocumentFeed extends Action { filters: Partial<DocumentFeedFilters>, hasFiltersApplied?: boolean };
export const GetDocumentFeed = ActionCreator<GetDocumentFeed>(GET_DOCUMENT_FEED);

export const GET_DOCUMENT_FEED_COMPLETE = "GET_DOCUMENT_FEED_COMPLETE";
export interface GetDocumentFeedComplete extends CompleteAction { documentFeed: DocumentFeed };
export const GetDocumentFeedComplete = ActionCreator<GetDocumentFeedComplete>(GET_DOCUMENT_FEED_COMPLETE);

export const GET_DOCUMENT_PROPERTIES = "GET_DOCUMENT_PROPERTIES";
export interface GetDocumentProperties extends Action {};
export const GetDocumentProperties = ActionCreator<GetDocumentProperties>(GET_DOCUMENT_PROPERTIES);

export const GET_DOCUMENT_PROPERTIES_COMPLETE = "GET_DOCUMENT_PROPERTIES_COMPLETE";
export interface GetDocumentPropertiesComplete extends CompleteAction {};
export const GetDocumentPropertiesComplete = ActionCreator<GetDocumentPropertiesComplete>(GET_DOCUMENT_PROPERTIES_COMPLETE);

export const GET_DOCUMENTS = "GET_DOCUMENTS";
export interface GetDocuments extends Action {};
export const GetDocuments = ActionCreator<GetDocuments>(GET_DOCUMENTS);

export const GET_DOCUMENTS_COMPLETE = "GET_DOCUMENTS_COMPLETE";
export interface GetDocumentsComplete extends CompleteAction { page: DocumentsListingPage };
export const GetDocumentsComplete = ActionCreator<GetDocumentsComplete>(GET_DOCUMENTS_COMPLETE);

export const GET_DISABLED_DOCUMENTS = "GET_DISABLED_DOCUMENTS";
export interface GetDisabledDocuments extends Action {};
export const GetDisabledDocuments = ActionCreator<GetDisabledDocuments>(GET_DISABLED_DOCUMENTS);

export const GET_DISABLED_DOCUMENTS_COMPLETE = "GET_DISABLED_DOCUMENTS_COMPLETE";
export interface GetDisabledDocumentsComplete extends CompleteAction { page: DocumentsListingPage };
export const GetDisabledDocumentsComplete = ActionCreator<GetDisabledDocumentsComplete>(GET_DISABLED_DOCUMENTS_COMPLETE);

export const GET_ENABLED_DOCUMENTS = "GET_ENABLED_DOCUMENTS";
export interface GetEnabledDocuments extends Action {};
export const GetEnabledDocuments = ActionCreator<GetEnabledDocuments>(GET_ENABLED_DOCUMENTS);

export const GET_ENABLED_DOCUMENTS_COMPLETE = "GET_ENABLED_DOCUMENTS_COMPLETE";
export interface GetEnabledDocumentsComplete extends CompleteAction { page: DocumentsListingPage };
export const GetEnabledDocumentsComplete = ActionCreator<GetEnabledDocumentsComplete>(GET_ENABLED_DOCUMENTS_COMPLETE);

export const GET_SCHEDULED_DOCUMENTS = "GET_SCHEDULED_DOCUMENTS";
export interface GetScheduledDocuments extends Action {};
export const GetScheduledDocuments = ActionCreator<GetScheduledDocuments>(GET_SCHEDULED_DOCUMENTS);

export const GET_SCHEDULED_DOCUMENTS_COMPLETE = "GET_SCHEDULED_DOCUMENTS_COMPLETE";
export interface GetScheduledDocumentsComplete extends CompleteAction { page: DocumentsListingPage };
export const GetScheduledDocumentsComplete = ActionCreator<GetScheduledDocumentsComplete>(GET_SCHEDULED_DOCUMENTS_COMPLETE);


export const HIDE_DOCUMENT_FEED_FILTERS = "HIDE_DOCUMENT_FEED_FILTERS";
export interface HideDocumentFeedFilters extends Action {};
export const HideDocumentFeedFilters = ActionCreator<HideDocumentFeedFilters>(HIDE_DOCUMENT_FEED_FILTERS);

export const HIDE_EDITOR = "HIDE_EDITOR";
export interface HideEditor extends Action {};
export const HideEditor = ActionCreator<HideEditor>(HIDE_EDITOR);

export const HIDE_PREVIEW = "HIDE_PREVIEW";
export interface HidePreview extends Action {};
export const HidePreview = ActionCreator<HidePreview>(HIDE_PREVIEW);

export const SHOW_DOCUMENT_FEED_FILTERS = "SHOW_DOCUMENT_FEED_FILTERS";
export interface ShowDocumentFeedFilters extends Action {};
export const ShowDocumentFeedFilters = ActionCreator<ShowDocumentFeedFilters>(SHOW_DOCUMENT_FEED_FILTERS);

export const SHOW_EDITOR = "SHOW_EDITOR";
export interface ShowEditor extends Action { document: Document };
export const ShowEditor = ActionCreator<ShowEditor>(SHOW_EDITOR);

export const SHOW_PREVIEW = "SHOW_PREVIEW";
export interface ShowPreview extends Action { document: DocumentPreview };
export const ShowPreview = ActionCreator<ShowPreview>(SHOW_PREVIEW);


export const SET_DOCUMENT_FEED_FILTERS = "SET_DOCUMENT_FEED_FILTERS";
export interface SetDocumentFeedFilters extends Action { filters: Partial<DocumentFeedFilters> };
export const SetDocumentFeedFilters = ActionCreator<SetDocumentFeedFilters>(SET_DOCUMENT_FEED_FILTERS);

export const SET_DOCUMENT_FEED_LAYOUT = "SET_DOCUMENT_FEED_LAYOUT";
export interface SetDocumentFeedLayout extends Action { layout: DocumentFeedLayout };
export const SetDocumentFeedLayout = ActionCreator<SetDocumentFeedLayout>(SET_DOCUMENT_FEED_LAYOUT);


export const DELETE_DOCUMENT = "DELETE_DOCUMENT";
export interface DeleteDocument extends Action {};
export const DeleteDocument = ActionCreator<DeleteDocument>(DELETE_DOCUMENT);

export const DELETE_DOCUMENT_COMPLETE = "DELETE_DOCUMENT_COMPLETE";
export interface DeleteDocumentComplete extends CompleteAction {};
export const DeleteDocumentComplete = ActionCreator<DeleteDocumentComplete>(DELETE_DOCUMENT_COMPLETE);

export const DELETE_DOCUMENTS = "DELETE_DOCUMENTS";
export interface DeleteDocuments extends Action {};
export const DeleteDocuments = ActionCreator<DeleteDocuments>(DELETE_DOCUMENTS);

export const DELETE_DOCUMENTS_COMPLETE = "DELETE_DOCUMENTS_COMPLETE";
export interface DeleteDocumentsComplete extends CompleteAction {};
export const DeleteDocumentsComplete = ActionCreator<DeleteDocumentsComplete>(DELETE_DOCUMENTS_COMPLETE);

export const DISABLE_DOCUMENT = "DISABLE_DOCUMENT";
export interface DisableDocument extends Action {};
export const DisableDocument = ActionCreator<DisableDocument>(DISABLE_DOCUMENT);

export const DISABLE_DOCUMENT_COMPLETE = "DISABLE_DOCUMENT_COMPLETE";
export interface DisableDocumentComplete extends CompleteAction {};
export const DisableDocumentComplete = ActionCreator<DisableDocumentComplete>(DISABLE_DOCUMENT_COMPLETE);

export const ENABLE_DOCUMENT = "ENABLE_DOCUMENT";
export interface EnableDocument extends Action {};
export const EnableDocument = ActionCreator<EnableDocument>(ENABLE_DOCUMENT);

export const ENABLE_DOCUMENT_COMPLETE = "ENABLE_DOCUMENT_COMPLETE";
export interface EnableDocumentComplete extends CompleteAction {};
export const EnableDocumentComplete = ActionCreator<EnableDocumentComplete>(ENABLE_DOCUMENT_COMPLETE);

export const REPLACE_FILE = "REPLACE_FILE";
export interface ReplaceFile extends Action {};
export const ReplaceFile = ActionCreator<ReplaceFile>(REPLACE_FILE);

export const REPLACE_FILE_COMPLETE = "REPLACE_FILE_COMPLETE";
export interface ReplaceFileComplete extends CompleteAction { document?: Document };
export const ReplaceFileComplete = ActionCreator<ReplaceFileComplete>(REPLACE_FILE_COMPLETE);

export const UPDATE_DOCUMENT_FEED = "UPDATE_DOCUMENT_FEED";
export interface UpdateDocumentFeed extends Action { documentFeed: DocumentFeedItem[] };
export const UpdateDocumentFeed = ActionCreator<UpdateDocumentFeed>(UPDATE_DOCUMENT_FEED);

export const UPDATE_DOCUMENT_PROPERTIES = "UPDATE_DOCUMENT_PROPERTIES";
export interface UpdateDocumentProperties extends Action {};
export const UpdateDocumentProperties = ActionCreator<UpdateDocumentProperties>(UPDATE_DOCUMENT_PROPERTIES);

export const UPDATE_DOCUMENT_PROPERTIES_COMPLETE = "UPDATE_DOCUMENT_PROPERTIES_COMPLETE";
export interface UpdateDocumentPropertiesComplete extends CompleteAction {};
export const UpdateDocumentPropertiesComplete = ActionCreator<UpdateDocumentPropertiesComplete>(UPDATE_DOCUMENT_PROPERTIES_COMPLETE);

export const UPLOAD_FILE = "UPLOAD_FILE";
export interface UploadFile extends Action {};
export const UploadFile = ActionCreator<UploadFile>(UPLOAD_FILE);

export const UPLOAD_FILE_COMPLETE = "UPLOAD_FILE_COMPLETE";
export interface UploadFileComplete extends CompleteAction {};
export const UploadFileComplete = ActionCreator<UploadFileComplete>(UPLOAD_FILE_COMPLETE);


export const CHANGED_SINCE_SAVED = "CHANGED_SINCE_SAVED";
export interface ChangedSinceSaved extends Action {};
export const ChangedSinceSaved = ActionCreator<ChangedSinceSaved>(CHANGED_SINCE_SAVED);

export const CLEAR_CHANGED_SINCE_SAVED = "CLEAR_CHANGED_SINCE_SAVED";
export interface ClearChangedSinceSaved extends Action {};
export const ClearChangedSinceSaved = ActionCreator<ClearChangedSinceSaved>(CLEAR_CHANGED_SINCE_SAVED);

export const CLEAR_DOCUMENT_FEED = "CLEAR_DOCUMENT_FEED";
export interface ClearDocumentFeed extends Action {};
export const ClearDocumentFeed = ActionCreator<ClearDocumentFeed>(CLEAR_DOCUMENT_FEED);

export const CLEAR_DOCUMENT_FEED_FILTERS = "CLEAR_DOCUMENT_FEED_FILTERS";
export interface ClearDocumentFeedFilters extends Action {};
export const ClearDocumentFeedFilters = ActionCreator<ClearDocumentFeedFilters>(CLEAR_DOCUMENT_FEED_FILTERS);

export const CLEAR_ERROR_MESSAGE = "CLEAR_ERROR_MESSAGE";
export interface ClearErrorMessage extends Action {};
export const ClearErrorMessage = ActionCreator<ClearErrorMessage>(CLEAR_ERROR_MESSAGE);

export const CLEAR_SHOULD_FETCH = "CLEAR_SHOULD_FETCH";
export interface ClearShouldFetch extends Action {};
export const ClearShouldFetch = ActionCreator<ClearShouldFetch>(CLEAR_SHOULD_FETCH);

export const CLEAR_SHOW_PUBLISH_SUCCESS = "CLEAR_SHOW_PUBLISH_SUCCESS";
export interface ClearShowPublishSuccess extends Action {};
export const ClearShowPublishSuccess = ActionCreator<ClearShowPublishSuccess>(CLEAR_SHOW_PUBLISH_SUCCESS);

export const CLEAR_SUCCESS_MESSAGE = "CLEAR_SUCCESS_MESSAGE";
export interface ClearSuccessMessage extends Action {};
export const ClearSuccessMessage = ActionCreator<ClearSuccessMessage>(CLEAR_SUCCESS_MESSAGE);