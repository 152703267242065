import { Action } from "redux";
import { CompleteAction, ActionCreator } from "typedActions";
import { User, ExternalUser, UserList, UsersTab, ValidationResponse } from "./models";

// fetch users
export const FETCH_USERS_INIT = "FETCH_USERS_INIT";
export interface FetchUsersInit extends Action { }
export const CreateFetchUsersInitAction = ActionCreator<FetchUsersInit>(FETCH_USERS_INIT);

export const FETCH_USERS_V1_COMPLETE =  "FETCH_USERS_V1_COMPLETE";
export interface FetchUsersV1Complete extends CompleteAction {
    continuationToken: string | null;
    users: User[];
}
export const CreateFetchUsersV1CompleteAction = ActionCreator<FetchUsersV1Complete>(FETCH_USERS_V1_COMPLETE);

export const FETCH_USERS_COMPLETE =  "FETCH_USERS_COMPLETE";
export interface FetchUsersComplete extends CompleteAction {
    userList: UserList;
}
export const CreateFetchUsersCompleteAction = ActionCreator<FetchUsersComplete>(FETCH_USERS_COMPLETE);

// set active users tab
export const SET_ACTIVE_USERS_TAB =  "SET_ACTIVE_USERS_TAB";
export interface SetActiveUsersTab extends Action {
    activeTab: UsersTab;
}
export const CreateSetActiveUsersTabAction = ActionCreator<SetActiveUsersTab>(SET_ACTIVE_USERS_TAB);

// select user
export const SELECT_USER =  "SELECT_USER";
export interface SelectUser extends Action {
    user: User;
}
export const SelectUser = ActionCreator<SelectUser>(SELECT_USER);

// toggle user role
export const TOGGLE_USER_ROLE =  "TOGGLE_USER_ROLE";
export interface ToggleUserRole extends Action {
    roleName: string;
}
export const CreateToggleUserRoleAction = ActionCreator<ToggleUserRole>(TOGGLE_USER_ROLE);

// togle user account enabled
export const TOGGLE_USER_ENABLED =  "TOGGLE_USER_ENABLED";
export interface ToggleUserEnabled extends Action {
    enabled: boolean;
}
export const CreateToggleUserEnabledAction = ActionCreator<ToggleUserEnabled>(TOGGLE_USER_ENABLED);

// toggle user submission management status
export const TOGGLE_SUBMISSION_MANAGEMENT =  "TOGGLE_SUBMISSION_MANAGEMENT";
export interface ToggleSubmissionManagement extends Action { 
    canManageSubmissions: boolean
}
export const CreateToggleSubmissionManagementAction = ActionCreator<ToggleSubmissionManagement>(TOGGLE_SUBMISSION_MANAGEMENT);


// toggle user flagging notifications on
export const TOGGLE_USER_FLAGGING_NOTIFICATIONS =  "TOGGLE_USER_FLAGGING_NOTIFICATIONS";
export interface ToggleFlaggingNotifications extends Action {
    enabled: boolean
 }
export const CreateToggleFlaggingNotificationAction = ActionCreator<ToggleFlaggingNotifications>(TOGGLE_USER_FLAGGING_NOTIFICATIONS);

// save user
export const SAVE_USER_INIT = "SAVE_USER_INIT";
export interface SaveUserInit extends Action { }
export const CreateSaveUserInitAction = ActionCreator<SaveUserInit>(SAVE_USER_INIT);

export const SAVE_USER_COMPLETE =  "SAVE_USER_COMPLETE";
export interface SaveUserComplete extends CompleteAction {
    user: User | null;
}
export const CreateSaveUserCompleteAction = ActionCreator<SaveUserComplete>(SAVE_USER_COMPLETE);

// set inviting email
export const SET_INVITING_USER_EMAIL =  "SET_INVITING_USER_EMAIL";
export interface SetInvitingUserEmail extends Action {
    email: string;
}
export const CreateSetInvitingUserEmailAction = ActionCreator<SetInvitingUserEmail>(SET_INVITING_USER_EMAIL);

// invite user
export const INVITE_USER_INIT = "INVITE_USER_INIT";
export interface InviteUserInit extends Action {
    email: string;
}
export const CreateInviteUserInitAction = ActionCreator<InviteUserInit>(INVITE_USER_INIT);

export const INVITE_USER_COMPLETE =  "INVITE_USER_COMPLETE";
export interface InviteUserComplete extends CompleteAction {
    user: ExternalUser | null;
    validationResponse: ValidationResponse | null;
}
export const CreateInviteUserCompleteAction = ActionCreator<InviteUserComplete>(INVITE_USER_COMPLETE);

// resend user invite
export const RESEND_INVITE_USER_INIT = "RESEND_INVITE_USER_INIT";
export interface ResendInviteUserInit extends Action {
    user: ExternalUser;
}
export const CreateResendInviteUserInitAction = ActionCreator<ResendInviteUserInit>(RESEND_INVITE_USER_INIT);

export const RESEND_INVITE_USER_COMPLETE =  "RESEND_INVITE_USER_COMPLETE";
export interface ResendInviteUserComplete extends CompleteAction {
    user: ExternalUser | null;
}
export const CreateResendInviteUserCompleteAction = ActionCreator<ResendInviteUserComplete>(RESEND_INVITE_USER_COMPLETE);

export const INVITE_BATCH_USERS_INIT = "INVITE_BATCH_USERS_INIT";
export interface InviteBatchUsersInit extends Action {}
export const InviteBatchUsersInitAction = ActionCreator<InviteBatchUsersInit>(INVITE_BATCH_USERS_INIT)

export const INVITE_BATCH_USERS_COMPLETE = "INVITE_BATCH_USERS_COMPLETE";
export interface InviteBatchUsersComplete extends CompleteAction {
    result: number | null;
}
export const InviteBatchUsersCompleteAction = ActionCreator<InviteBatchUsersComplete>(INVITE_BATCH_USERS_COMPLETE)

export const GET_BULK_INVITE_REPORT_CSV = "GET_BULK_INVITE_REPORT_CSV"
export interface GetBulkInviteReportCSV extends Action {};
export const GetBulkInviteReportCSV = ActionCreator<GetBulkInviteReportCSV>(GET_BULK_INVITE_REPORT_CSV);

export const GET_BULK_IMPORT_CSV = "GET_BULK_IMPORT_CSV"
export interface GetBulkImportCSV extends Action {};
export const GetBulkImportCSV = ActionCreator<GetBulkInviteReportCSV>(GET_BULK_IMPORT_CSV);

export const GET_USER_ACTIVITY_COMPLETE = "GET_USER_ACTIVITY_COMPLETE";
export interface GetUserActivityComplete extends CompleteAction {};
export const GetUserActivityComplete = ActionCreator<GetUserActivityComplete>(GET_USER_ACTIVITY_COMPLETE);

// enable external user
export const SET_EXTERNAL_USER_ENABLED_INIT = "SET_EXTERNAL_USER_ENABLED_INIT";
export interface SetExternalUserEnabledInit extends Action {
    email: string;
    enabled: boolean;
}
export const CreateSetExternalUserEnabledInitAction = ActionCreator<SetExternalUserEnabledInit>(SET_EXTERNAL_USER_ENABLED_INIT);

export const SET_EXTERNAL_USER_ENABLED_COMPLETE =  "SET_EXTERNAL_USER_ENABLED_COMPLETE";
export interface SetExternalUserEnabledComplete extends CompleteAction {
    user: ExternalUser | null;
}
export const CreateSetExternalUserEnabledCompleteAction = ActionCreator<SetExternalUserEnabledComplete>(SET_EXTERNAL_USER_ENABLED_COMPLETE);

// upload batch ivite
export const UPLOAD_BATCH_INVITE_FILE_INIT = "UPLOAD_BATCH_INVITE_FILE_INIT";
export interface UploadBatchInviteFileInit extends Action { }
export const CreateUploadBatchInviteFileInitAction = ActionCreator<UploadBatchInviteFileInit>(UPLOAD_BATCH_INVITE_FILE_INIT);

export const UPLOAD_BATCH_INVITE_FILE_COMPLETE =  "UPLOAD_BATCH_INVITE_FILE_COMPLETE";
export interface UploadBatchInviteFileComplete extends CompleteAction {
    validationResponse: ValidationResponse | null;
}
export const CreateUploadBatchInviteFileCompleteAction = ActionCreator<UploadBatchInviteFileComplete>(UPLOAD_BATCH_INVITE_FILE_COMPLETE);

// resend pending invite
export const CREATE_RESEND_PENDING_INVITE_INIT = "CREATE_RESEND_PENDING_INVITE_INIT";
export interface CreateResendPendingInviteInit extends Action { }
export const CreateResendPendingInviteInitAction = ActionCreator<CreateResendPendingInviteInit>(CREATE_RESEND_PENDING_INVITE_INIT);

export const CREATE_RESEND_PENDING_INVITE_COMPLETE =  "CREATE_RESEND_PENDING_INVITE_COMPLETE";
export interface CreateResendPendingInviteComplete extends CompleteAction { }
export const CreateResendPendingInviteCompleteAction = ActionCreator<CreateResendPendingInviteComplete>(CREATE_RESEND_PENDING_INVITE_COMPLETE);

// delete external user
export const DELETE_EXTERNAL_USER_INIT = "DELETE_EXTERNAL_USER_INIT";
export interface DeleteExternalUserInit extends Action { user: ExternalUser }
export const DeleteExternalUserInitAction = ActionCreator<DeleteExternalUserInit>(DELETE_EXTERNAL_USER_INIT);

export const DELETE_EXTERNAL_USER_COMPLETE = "DELETE_EXTERNAL_USER_COMPLETE";
export interface DeleteExternalUserComplete extends CompleteAction { user: ExternalUser | null }
export const DeleteExternalUserCompleteAction = ActionCreator<DeleteExternalUserComplete>(DELETE_EXTERNAL_USER_COMPLETE);

//delete pending user list
export const DELETE_PENDING_USER_LIST_INIT = "DELETE_PENDING_USER_LIST_INIT";
export interface DeletePendingUserListInit extends Action { }
export const DeletePendingUserListInit = ActionCreator<DeletePendingUserListInit>(DELETE_PENDING_USER_LIST_INIT);

export const DELETE_PENDING_USER_LIST_COMPLETE = "DELETE_PENDING_USER_LIST_COMPLETE";
export interface DeletePendingUserListComplete extends CompleteAction { pendingUserEmails: string[] }
export const DeletePendingUserListComplete = ActionCreator<DeletePendingUserListComplete>(DELETE_PENDING_USER_LIST_COMPLETE);

// update global user list
export const UPDATE_GLOBAL_USERS = "UPDATE_GLOBAL_USERS";
export interface UpdateGlobalUsers extends Action {
    userList: UserList;
}
export const UpdateGlobalUsersAction = ActionCreator<UpdateGlobalUsers>(UPDATE_GLOBAL_USERS);

//update global external user list
export const UPDATE_GLOBAL_EXTERNAL_USERS = "UPDATE_GLOBAL_EXTERNAL_USERS";
export interface UpdateGlobalExternalUsers extends Action {
    users: ExternalUser[]
}
export const UpdateGlobalExternalUsersAction = ActionCreator<UpdateGlobalExternalUsers>(UPDATE_GLOBAL_EXTERNAL_USERS);

//legacy user fix
export const LEGACY_FIX_INIT = "LEGACY_FIX_INIT";
export interface LegacyFixInit extends Action { }
export const CreateLegacyFixInitAction = ActionCreator<LegacyFixInit>(LEGACY_FIX_INIT);

export const LEGACY_FIX_COMPLETE =  "LEGACY_FIX_COMPLETE";
export interface LegacyFixComplete extends CompleteAction {
    userId: string
}
export const CreateLegacyFixCompleteAction = ActionCreator<LegacyFixComplete>(LEGACY_FIX_COMPLETE);

/* User creation */

export const HANDLE_USER_CREATION_FIRSTNAME = "HANDLE_USER_CREATION_FIRSTNAME"
export interface HandleUserCreationFirstName extends Action {
    firstName: string
}
export const CreateHandleUserCreationFirstName = ActionCreator<HandleUserCreationFirstName>(HANDLE_USER_CREATION_FIRSTNAME);

export const HANDLE_USER_CREATION_LASTNAME = "HANDLE_USER_CREATION_LASTNAME"
export interface HandleUserCreationLastName extends Action {
    lastName: string
}
export const CreateHandleUserCreationLastName = ActionCreator<HandleUserCreationLastName>(HANDLE_USER_CREATION_LASTNAME);


export const HANDLE_USER_CREATION_USERID = "HANDLE_USER_CREATION_USERID"
export interface HandleUserCreationUserId extends Action {
    userId: string
}
export const CreateHandleUserCreationUserId = ActionCreator<HandleUserCreationUserId>(HANDLE_USER_CREATION_USERID);

export const HANDLE_USER_CREATION_PASSWORD = "HANDLE_USER_CREATION_PASSWORD"
export interface HandleUserCreationPassword extends Action {
    password: string
}
export const CreateHandleUserCreationPassword = ActionCreator<HandleUserCreationPassword>(HANDLE_USER_CREATION_PASSWORD);

export const HANDLE_USER_CREATION_EMAIL = "HANDLE_USER_CREATION_EMAIL"
export interface HandleUserCreationEmail extends Action {
    email: string
}
export const CreateHandleUserCreationEmail = ActionCreator<HandleUserCreationEmail>(HANDLE_USER_CREATION_EMAIL);

export const HANDLE_USER_CREATION_EMPLOYEEID = "HANDLE_USER_CREATION_EMPLOYEEID"
export interface HandleUserCreationEmployeeId extends Action {
    employeeId: string
}
export const CreateHandleUserCreationEmployeeId = ActionCreator<HandleUserCreationEmployeeId>(HANDLE_USER_CREATION_EMPLOYEEID);

export const HANDLE_USER_CREATION_TITLE = "HANDLE_USER_CREATION_TITLE"
export interface HandleUserCreationTitle extends Action {
    title: string
}
export const CreateHandleUserCreationTitle = ActionCreator<HandleUserCreationTitle>(HANDLE_USER_CREATION_TITLE);

export const HANDLE_USER_CREATION_DEPARTMENT = "HANDLE_USER_CREATION_DEPARTMENT"
export interface HandleUserCreationDepartment extends Action {
    department: string
}
export const CreateHandleUserCreationDepartment = ActionCreator<HandleUserCreationDepartment>(HANDLE_USER_CREATION_DEPARTMENT);

export const HANDLE_USER_CREATION_REGION = "HANDLE_USER_CREATION_REGION"
export interface HandleUserCreationRegion extends Action {
    region: string
}
export const CreateHandleUserCreationRegion = ActionCreator<HandleUserCreationRegion>(HANDLE_USER_CREATION_REGION);

export const HANDLE_USER_CREATION_COUNTRY = "HANDLE_USER_CREATION_COUNTRY"
export interface HandleUserCreationCountry extends Action {
    country: string
}
export const CreateHandleUserCreationCountry = ActionCreator<HandleUserCreationCountry>(HANDLE_USER_CREATION_COUNTRY);

export const HANDLE_USER_CREATION_PREFERREDEMAIL = "HANDLE_USER_CREATION_PREFERREDEMAIL"
export interface HandleUserCreationPreferredEmail extends Action {
    preferredEmail: string
}
export const CreateHandleUserCreationPreferredEmail = ActionCreator<HandleUserCreationPreferredEmail>(HANDLE_USER_CREATION_PREFERREDEMAIL);

export const HANDLE_USER_CREATION_PREFERREDSMSPHONE = "HANDLE_USER_CREATION_PREFERREDSMSPHONE"
export interface HandleUserCreationPreferredSMSPhone extends Action {
    preferredSMSPhone: string
}
export const CreateHandleUserCreationPreferredSMSPhone = ActionCreator<HandleUserCreationPreferredSMSPhone>(HANDLE_USER_CREATION_PREFERREDSMSPHONE);

export const HANDLE_USER_CREATION_SPARROW_LOCAL_SUCCESS = "HANDLE_USER_CREATION_SPARROW_LOCAL_SUCCESS"
export interface HandleSparrowLocalAccountCreationSuccess extends Action {
    userPreferenceId: string
}

export const CreateHandleSparrowLocalAccountCreationSuccess = ActionCreator<HandleSparrowLocalAccountCreationSuccess>(HANDLE_USER_CREATION_SPARROW_LOCAL_SUCCESS);

export const HANDLE_USER_CREATION_AUDIENCES = "HANDLE_USER_CREATION_AUDIENCES"
export interface HandleUserCreationAudiences extends Action {
    audiences: string[]
}
export const CreateHandleUserCreationAudiences = ActionCreator<HandleUserCreationAudiences>(HANDLE_USER_CREATION_AUDIENCES);

export const HANDLE_USER_CREATION_ROLES = "HANDLE_USER_CREATION_ROLES"

export interface HandleUserCreationRoles extends Action {
    roleName: string
}

export const CreateHandleUserCreationRoles = ActionCreator<HandleUserCreationRoles>(HANDLE_USER_CREATION_ROLES);

export const HANDLE_USER_CREATION_SUBMISSION_MANAGER = "HANDLE_USER_CREATION_SUBMISSION_MANAGER"

export interface HandleUserCreationSubmissionManager extends Action {
    canManageSubmissions: boolean
}

export const CreateHandleUserCreationSubmissionManager = ActionCreator<HandleUserCreationSubmissionManager>(HANDLE_USER_CREATION_SUBMISSION_MANAGER);

export const HANDLE_USER_CREATION_SUBSCRIBED_TO_FLAGGING_NOTIFICATIONS = "HANDLE_USER_CREATION_SUBSCRIBED_TO_FLAGGING_NOTIFICATIONS"

export interface HandleUserCreationSubscribedToFlaggingNotifications extends Action {
    subscribedToFlaggingNotifications: boolean
}

export const CreateHandleUserCreationSubscribedToFlaggingNotifications = ActionCreator<HandleUserCreationSubscribedToFlaggingNotifications>(HANDLE_USER_CREATION_SUBSCRIBED_TO_FLAGGING_NOTIFICATIONS);


export const HANDLE_UPDATE_UPDATING_USER_ID = "HANDLE_UPDATE_UPDATING_USER_ID"

export interface HandleUpdateUpdatingUserId extends Action {
    id: string
}

export const CreateHandleUpdateUpdatingUserId = ActionCreator<HandleUpdateUpdatingUserId>(HANDLE_UPDATE_UPDATING_USER_ID)

export const HANDLE_SEND_SMS_VERIFY_USER_ID = "HANDLE_SEND_SMS_VERIFY_USER_ID"
export interface HandleSendSMSVerifyUserId extends Action {
    id: string
}
export const CreateHandleSendSMSVerifyUserId = ActionCreator<HandleSendSMSVerifyUserId>(HANDLE_SEND_SMS_VERIFY_USER_ID)


export const HANDLE_EDIT_PASSWORD = "HANDLE_EDIT_PASSWORD"

export interface EditPasswordAction extends Action {
    password: string
}

export const CreateEditPasswordAction = ActionCreator<EditPasswordAction>(HANDLE_EDIT_PASSWORD)

export const HANDLE_UPDATE_MOBILE_CONSENT = "HANDLE_UPDATE_MOBILE_CONSENT";

export interface UpdateMobileConsentAction extends Action {
    consent: boolean | null;
}

export const CreateUpdateMobileConsentAction = ActionCreator<UpdateMobileConsentAction>(HANDLE_UPDATE_MOBILE_CONSENT);

export const UPDATE_USER_PERMISSIONS = "UPDATE_USER_PERMISSIONS"

export interface UpdateUserPermissionsAction extends Action {
    id: string
}

export const CreateUpdateUserPermissionsAction = ActionCreator<UpdateUserPermissionsAction>(UPDATE_USER_PERMISSIONS)