import React, { useEffect, useRef } from "react";

import { ContentBandContentType, ContentBandLayoutType, IContentBand, IContentBandSettings } from "../../models";
import { PostFeedItem } from "modules/posts/models";
import { EventFeedItem } from "modules/events";
import { ContentBandCard } from "./contentBandCard";
import { getTranslatedTitle } from "utils/getTranslatedContent";
import Attendance from "modules/events/components/attendance/attendance";
import BreakingFeaturedBanner from "modules/posts/components/banners/breakingFeaturedBanner";
import moment from "moment";
import ReadDate from "modules/posts/components/post-feed/components/dates/ReadDate";
import PostTypeBanner from "modules/common/components/banners/postTypeBanner";
import { IDateBlockProps } from "modules/events/components/date-block/dateBlock";
import { ContentBandWindow } from "./contentBandWindow";
import { ContentBandCarousel } from "./contentBandCarousel";
import { ContentBandCardSeeMore, ContentBandWindowSeeMore } from "./contentBandSeeMore";
import { calculateNumberOfLines } from "utils/htmlUtils";
import Loading from "modules/common/components/loading";
import { IHomeScreenConfig } from "modules/homeScreens/models";

import "../../styles/contentBandLayouts.sass";

export interface IContentBandLayoutProps {
    settings: IContentBandSettings;
    bannerColor?: string; // solid color to display instead of hero url
    heroUrl?: string;
    title: string;
    leftInfo?: JSX.Element; // component to render in whitespace to left and below of title
    rightInfo?: JSX.Element; // component to render in whitespace below and right of title
    leftHeroBadge?: JSX.Element; // component to render top left corner of hero
    dateBlock?: IDateBlockProps;
    mobileHomeScreenSettings?: IHomeScreenConfig;
    size?: "sm" | "lg";
}

interface IContentBandLayoutPreviewProps {
    idx: number;
    contentBand: IContentBand;
    activeLcid: string;
    fullWidth?: boolean; // controls whether carousel is full width or not
    containerClassName?: string;
    isLoading?: boolean;
    size?: "sm" | "lg";
}

export const ContentBandLayoutPreview: React.FunctionComponent<IContentBandLayoutPreviewProps> = ({
    idx,
    fullWidth = true,
    isLoading = false,
    contentBand,
    activeLcid,
    containerClassName = "",
    size = "sm",
}) => {
    const isPosts = contentBand.contentType === ContentBandContentType.Post;
    const { seeMoreEnabled } = contentBand;
    const scrollId = `content-band-content-scroll-${idx}`;

    const isTitleGreaterThanTwoLinesOnCard = (p: PostFeedItem | EventFeedItem): boolean =>
        calculateNumberOfLines(204, getTranslatedTitle(p.translatedContent, activeLcid)) > 2;

    const shouldStretch: boolean =
        contentBand.layoutType === ContentBandLayoutType.Card
            ? isPosts
                ? contentBand.posts?.some(isTitleGreaterThanTwoLinesOnCard)
                : contentBand.events?.some(isTitleGreaterThanTwoLinesOnCard)
            : false;

    // keep track of whether or not we should scroll when seeMoreEnabled = true
    const seeMoreOffOnce = useRef<boolean>(!seeMoreEnabled);

    useEffect(() => {
        moment.locale("en");
    }, []);

    // scroll to end of content to see see more button
    useEffect(() => {
        if (!seeMoreOffOnce.current) seeMoreOffOnce.current = !seeMoreEnabled;

        if (seeMoreEnabled && seeMoreOffOnce.current) {
            const el = document.getElementById(scrollId);
            if (el) el.scrollLeft = 781; // max value for the container
        }
    }, [seeMoreEnabled, scrollId]);

    const getLayout = (item: PostFeedItem | EventFeedItem): JSX.Element => {
        let result: JSX.Element = <></>;

        switch (contentBand.layoutType) {
            case ContentBandLayoutType.Window:
                result = getWindow(item);
                break;
            case ContentBandLayoutType.Card:
            default:
                result = getCard(item);
                break;
        }

        return result;
    };

    const getLeftInfo = (item: PostFeedItem | EventFeedItem): JSX.Element => {
        let result = <></>;

        if (isPosts) {
            let post = item as PostFeedItem;
            result = <span className="date">{moment(post.publishTime).format("MMM D, YYYY")}</span>;
        } else {
            let event = item as EventFeedItem;
            result = <Attendance {...event} />;
        }

        return result;
    };

    const getPostTypeBanner = (item: PostFeedItem): JSX.Element => {
        return <PostTypeBanner {...item} lcid={activeLcid} iconOnly={contentBand.layoutType === ContentBandLayoutType.Window} />;
    };

    const getBaseProps = (item: PostFeedItem | EventFeedItem): IContentBandLayoutProps => ({
        settings: contentBand,
        title: getTranslatedTitle(item.translatedContent, activeLcid),
        heroUrl: item.imageUrl,
        leftInfo: getLeftInfo(item),
        leftHeroBadge: isPosts ? getPostTypeBanner(item as PostFeedItem) : undefined,
        dateBlock: isPosts ? undefined : { ...(item as EventFeedItem), showMultiBlocks: false },
        bannerColor: isPosts ? undefined : (item as EventFeedItem).bannerColor,
        rightInfo: <BreakingFeaturedBanner activeLcid={activeLcid} isBreaking={item.isBreaking} isFeatured={item.isFeatured} />,
        size,
    });

    const getCard = (item: PostFeedItem | EventFeedItem): JSX.Element => (
        <ContentBandCard
            {...getBaseProps(item)}
            fullTitle={contentBand.displayFullTitles}
            key={`cb-card-${item.id}`}
            rightHeroBadge={isPosts ? <ReadDate hideWhenRead lastReadTime={(item as PostFeedItem).lastReadTime} /> : undefined}
        />
    );

    const getWindow = (item: PostFeedItem | EventFeedItem): JSX.Element => (
        <ContentBandWindow
            key={`cb-window-${item.id}`}
            {...getBaseProps(item)}
            rightHeroBadge={isPosts ? <ReadDate hideWhenRead lastReadTime={(item as PostFeedItem).lastReadTime} /> : undefined}
        />
    );

    const getCarousel = (): JSX.Element => (
        <ContentBandCarousel
            contentType={contentBand.contentType}
            size={size}
            fullWidth={fullWidth}
            autoPlay={contentBand.autoPlayCarousel || false}
            items={isPosts ? contentBand.posts : contentBand.events}
            contentBand={contentBand}
            getLeftInfo={getLeftInfo}
            activeLcid={activeLcid}
            getPostTypeBanner={getPostTypeBanner}
        />
    );

    return (
        <div
            id={scrollId}
            className={`cb-content ${contentBand.layoutType} ${containerClassName}`}
            style={{
                // for cards, height of card can grow with display full titles
                // this makes sure all items are the same height when one of the items has grown
                alignItems: shouldStretch ? "stretch" : "center",
                justifyContent: isLoading ? "center" : "flex-start",
            }}
        >
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    {contentBand.layoutType === ContentBandLayoutType.Carousel ? (
                        getCarousel()
                    ) : (
                        <>{isPosts ? contentBand.posts.map(getLayout) : contentBand.events.map(getLayout)}</>
                    )}
                    {/* only display if enabled and have fetched content already */}
                    {contentBand.seeMoreEnabled &&
                        ((isPosts && contentBand.posts.length > 0) || (!isPosts && contentBand.events.length > 0)) &&
                        (contentBand.layoutType === ContentBandLayoutType.Card ? (
                            <ContentBandCardSeeMore size={size} />
                        ) : (
                            <ContentBandWindowSeeMore size={size} />
                        ))}
                </>
            )}
        </div>
    );
};
