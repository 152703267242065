import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Prompt } from "react-router-dom";
import * as actions from "../../actionCreator";
import { GlobalApplicationState } from "globalApplicationState";
import { push } from "react-router-redux";
import moment from "moment";

import ErrorSnackbar from "modules/common/components/snackbars/errorSnackbar";
import Loading from "modules/common/components/loading";
import LoadingOverlay from "modules/common/components/loadingOverlay";

import BasePage from "pages/common/basePage";
import Breadcrumb from "pages/common/breadcrumb";
import Columns from "pages/common/columns";
import MainContent from "pages/common/mainContent";

import confirm from "utils/notyPopups";

import { Document, DocumentProperties, Notifications, NotificationRange } from "../../models";
import DocumentSummary from "./components/documentSummary";
import DocumentUploader from "./components/documentUploader";
import DocumentPropertiesEditor from "./documentProperties";

import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Hidden from "@mui/material/Hidden";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";


import "modules/common/components/authoring/authoring.sass";


class DocumentCreation extends React.Component<PropsWithRedux, ComponentState> {
  constructor(props: PropsWithRedux) {
    super(props);

    const document = { ...this.getNewDocument() };

    this.state = {
      document: document,
      documentProperties: {},
      hasError: false,
      isFetching: false,
      showNotificationSettings: false,
      showSummary: false,
      showTabCommandList: false
    };
  }

  componentWillMount() {
    this.props.clearChangedSinceSaved();
  }

  componentDidMount() {
    moment.locale("en");
  }

  public render() {
    const { document } = this.state;

    return (
      <BasePage fullWidth>
        <Prompt when={this.props.changedSinceSaved} message="You have unsaved changes, are you sure you want to leave this page?" />
        <Breadcrumb
          items={[
            { title: "Documents", link: "~/admin/documents" },
            { title: "Create new document" }
          ]}
          backItem={{ title: "Back to Manage Documents", onClick: this.onBackToManageDocuments }}
          rightComponent={this.getCommands()}
        />
        <Columns
          leftComponent={
            <MainContent>
              <div>
                <DocumentUploader
                  acceptedFiles="application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/jpeg,application/pdf,image/png,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  message="Choose or drag a file here to add a new document"
                  showSupportedFileTypes
                  onUpload={this.onUpload}
                />
              </div>
            </MainContent>
          }
          rightComponent={
            <MainContent>
              <div className="authoring-page">
                {this.state.isFetching
                  ? <Loading />
                  : this.state.hasError
                    ? <div className="no-event-data">This document could not be loaded.</div>
                    : <DocumentPropertiesEditor
                        document={document}
                        documentProperties={this.state.documentProperties}
                        isUnpublished
                        onChange={this.onChangeDocumentProperties}
                      />
                }
                <ErrorSnackbar errorMessage={this.props.errorMessage} clearErrorMessage={this.props.clearErrorMessage} />
                <LoadingOverlay absolute={true} show={this.props.isDeleting || this.props.isSaving} />
              </div>
            </MainContent>
          }
        />
        <DocumentSummary show={this.state.showSummary} document={this.state.document} documentProperties={this.state.documentProperties} onPublish={this.publishDocument} onClose={this.onHideSummary} />
        {/* <NotificationSettings show={this.state.showNotificationSettings} isPreviouslyPublished={false} notifications={document.notifications} type="document" onChangeNotifications={this.onChangeNotifications} onClose={this.onHideNotificationSettings} /> */}
      </BasePage>
    );
  }

  private getCommands = (): JSX.Element => {
    return (
      <React.Fragment>
        <Hidden mdUp>
          <div className="event-tab-command">
            <ButtonGroup variant="contained" color="primary">
              <Button disabled={!this.state.document.id} onClick={this.publishDocument}>Publish</Button>
              <Button color="primary" size="small" onClick={this.onShowTabCommandList}>
                <ArrowDropDownIcon />
              </Button>
            </ButtonGroup>
            {this.state.showTabCommandList &&
              <ClickAwayListener onClickAway={this.onHideTabCommandList}>
                <List className="event-tab-command-list">
                  <ListItem button onClick={this.cancelDocument}>
                    <ListItemText primary="Cancel" />
                  </ListItem>
                </List>
              </ClickAwayListener>
            }
          </div>
        </Hidden>
        <Hidden mdDown>
          <Button variant="text" onClick={this.cancelDocument}>Cancel</Button>
          <Button variant="contained" color="primary" disabled={!this.state.document.id} onClick={this.onShowSummary}>Publish</Button>
        </Hidden>
      </React.Fragment>
    );
  }

  private getNewDocument = (): Partial<Document> => {
    const { notificationSettings, tenantSettings } = this.props;

    const lcid: string = tenantSettings ? tenantSettings.defaultLCID : "en-us";

    const document: Partial<Document> = {
      lcid: lcid,
      notifications: {
        emailOnPublish: notificationSettings.defaultEventSettings?.emailOnPublish as NotificationRange || "none",
        mobileOnPublish: notificationSettings.defaultEventSettings?.mobileOnPublish as NotificationRange || "subscribers",
        reminders: [],
        smsOnPublish: notificationSettings.defaultEventSettings?.smsOnPublish as NotificationRange || "none",
        teamsOnPublish: notificationSettings.defaultEventSettings?.teamsOnPublish as NotificationRange || "subscribers"
      }
    };
    return document;
  }

  private onBackToManageDocuments = async () => {
    if (this.props.changedSinceSaved) {
      if (!(await confirm.show({
          title: "Back to Manage Documents",
          text: (
            <div style={{ minWidth: 400 }}>
              <div style={{ fontWeight: 500 }}>Your changes will not be saved.</div>
              <br />
              <div>Do you want to continue?</div>
            </div>
          ),
          yesText: "Keep editing",
          noText: "Exit"
      }))) {
        this.props.clearChangedSinceSaved();
        this.props.redirectTo("/" + this.props.tenant + "/admin/documents");
      }
    } else {
      this.props.redirectTo("/" + this.props.tenant + "/admin/documents");
    }
  }

  private onChangeDocumentProperties = (value: Partial<DocumentProperties>) => {
    const documentProperties = { ...this.state.documentProperties, ...value };
    this.setState({ documentProperties: documentProperties });
    this.props.hasChangedSinceSaved();
  }

  private onChangeNotifications = (notifications: Notifications) => {
    this.onChangeDocumentProperties({ notifications });
  }


  private onHideNotificationSettings = () => {
    this.setState({ showNotificationSettings: false });
  }

  private onHideSummary = () => {
    this.setState({ showSummary: false });
  }

  private onHideTabCommandList = () => {
    this.setState({ showTabCommandList: false });
  }

  private onShowNotificationSettings = () => {
    this.setState({ showNotificationSettings: true });
  }

  private onShowSummary = () => {
    this.setState({ showSummary: true });
  }

  private onShowTabCommandList = () => {
    this.setState({ showTabCommandList: true });
  }

  private onUpload = (file: File, onProgress, onLoad, onError, onAbort): Promise<Document> => {
    return this.props.uploadFile(file, onProgress, onLoad, onError, onAbort).then((document) => {
      const documentProperties: Partial<DocumentProperties> = {
        accessibleAudiences: document.audiences.map(audience => audience.id),
        availability: document.availability,
        description: document.description,
        enabled: true,
        lcid: document.lcid,
        notifications: document.notifications,
        notifiedAudiences: [],
        publishingTime: document.state === "scheduled" ? document.published.date : "",
        tags: document.tags.map(tag => tag.id),
        title: document.title
      };
      this.setState({ document, documentProperties });
      return document;
    });
  }

  private cancelDocument = () => {
    this.props.redirectTo("/" + this.props.tenant + "/admin/documents");
  }

  private publishDocument = () => {
    if(!this.state.documentProperties.tags || this.state.documentProperties.tags?.length === 0) {
      let uncategorizedTag = this.props.categoryTags.find(t => t.name === "Uncategorized");
      let newProperties = this.state.documentProperties;
      newProperties.tags = [uncategorizedTag!.id];
      this.setState({documentProperties: newProperties});
    }

    this.onHideSummary();
    this.props.updateDocumentProperties(this.state.document.id!, this.state.documentProperties!).then((succeeded) => {
      if (succeeded)
        this.props.redirectTo("/" + this.props.tenant + "/admin/documents");
    });
  }
}


interface ComponentProps {}

interface ComponentState {
  document: Partial<Document>;
  documentProperties: Partial<DocumentProperties>;
  hasError: boolean;
  isFetching: boolean;
  showNotificationSettings: boolean;
  showSummary: boolean;
  showTabCommandList: boolean;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    changedSinceSaved: state.documents.changedSinceSaved,
    errorMessage: state.documents.errorMessage,
    isDeleting: state.documents.isDeleting,
    isSaving: state.documents.isSaving,

    notificationSettings: state.settings.notificationSettings,
    tenant: state.tenant.id,
    tenantSettings: state.settings.tenantSettings,
    categoryTags: state.categoryTags.userCategoryTags
  }),
  {
    clearChangedSinceSaved: actions.clearChangedSinceSaved,
    clearErrorMessage: actions.clearErrorMessage,
    // deleteDocuments: actions.deleteDocuments,
    hasChangedSinceSaved: actions.hasChangedSinceSaved,
    updateDocumentProperties: actions.updateDocumentProperties,
    uploadFile: actions.uploadFile,
    redirectTo: push
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(DocumentCreation);
