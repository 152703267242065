import React from "react";

import TextField from "@mui/material/TextField";


const Title: React.FunctionComponent<ComponentProps> = props => {
  return (
    <div>
      <div>Audience title</div>
      <TextField
        variant="outlined"
        size="small"
        placeholder="Enter title"
        value={props.title || ""}
        fullWidth
        error={!props.title}
        inputProps={{
          maxLength: 50
        }}
        onChange={(event) => props.onChange(event.target.value)}
      />
    </div>
  );
}


interface ComponentProps {
  title: string;
  onChange: (title: string) => void;
}

export default Title;