import { TenantCreation } from 'modules/tenantManagement/models';
import { ApiRequest } from './network';
import { SparrowApi } from './network/adapters/SparrowApi';


export class TenantManagementApi {

    constructor(private _sparrowApi: SparrowApi) {
    }
    
    public CreateNewTenant = (tenantCreationValues: TenantCreation): ApiRequest => {

        return new ApiRequest(
            this._sparrowApi.TenantRequest().post('{tenant}/sparrowapi/v1/newTenant/createNewTenant', tenantCreationValues)
        );
    }
}