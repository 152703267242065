import { GlobalApplicationState } from 'globalApplicationState';
import {
    Comment,
    ValidationResponse,
    Post,
    PostFeedItem,
    PostListingPage,
    PostFilterValues,
    TranslatablePostContent,
    ImageScale,
    PostDeleteModel,
    PostListingPageId,
    PostFeedFilters
} from 'modules/posts/models';
import * as Actions from 'modules/posts/actions';
import { push } from 'react-router-redux';
import XhrUpload from 'utils/xhrUpload';
import API from 'api';
import { getTranslatedTitle } from 'utils/getTranslatedContent';
import { errorAlert } from 'utils/notyPopups'
import once from 'utils/once';
import MsalAuthModule from 'authentication/msalAuthModule';
import { ContentAnalysis } from 'modules/common/components/authoring/models';
import cookie, { ROWS_PER_PAGE_COOKIE_NAMES } from 'utils/cookie';
import { CustomFileData, ImageAndFolderCountReturn, ImageUploadReturn, Image as ImageV1, MediaFile, MediaFiltersModel, MoveFiles, MoveFilesWithSrc } from 'modules/gallery';

export const setChatbotNotificationsEnabled = (enabled: boolean) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.CreateSetChatbotNotificationsEnabled({ enabled }));
}

export const setMobileNotificationsEnabled = (enabled: boolean) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.CreateSetMobileNotificationsEnabled({ enabled }));
}
export const setSMSNotificationHours = (hours: number) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.CreateSetSMSNotificationHours({ hours }));
}

export const setSMSNotificationFrequency = (frequency: number) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.CreateSetSMSNotificationFrequency({ frequency }));
}

export const setEmailNotificationHours = (hours: number) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.CreateSetEmailNotificationHours({ hours }));
}

export const setEmailNotificationFrequency = (frequency: number) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.CreateSetEmailNotificationFrequency({ frequency }));
}

export const setCommentingEnabled = (enabled: boolean) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.CreateSetCommentingEnabledAction({ enabled }));
}

export const setReactingEnabled = (enabled: boolean) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.CreateSetReactingEnabledAction({ enabled }));
}

export const setNotificationsEnabled = (enabled: boolean) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.CreateSetNotificationsEnabledAction({ enabled }));
}

export const setEmailChannelEnabled = (enabled: boolean) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.CreateSetEmailChannelEnabled({ enabled }));
}

export const setSMSChannelEnabled = (enabled: boolean) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.CreateSetSMSChannelEnabled({ enabled }));
}

export const getMentionsUsers = (filter: string) => (dispatch, getState: () => GlobalApplicationState) => {
    return dispatch(API.comments.FetchMentionsUsers(filter))
        .then(response => response.json());
}

export const fetchAllReplies = (parentId: string) => (dispatch, getState: () => GlobalApplicationState) => {
    return dispatch(API.comments.FetchAllReplies(parentId))
        .then(response => response.json());
}

export const fetchDraftPostList = (
    pageNumber: number, 
    filters: Partial<PostFilterValues>, 
    pageAmount?: number
) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.FetchDraftPostList({}));

    dispatch(API.draftPost.FetchDraftPosts(pageNumber, filters, pageAmount))
        .then(response => response.json())
        .then((page: PostListingPage) => dispatch(Actions.FetchDraftPostListComplete({ succeeded: true, page })))
        .catch(err => dispatch(
            Actions.FetchDraftPostListComplete({ 
                succeeded: false, 
                page: { 
                    currentPage: 1, 
                    id: PostListingPageId.DRAFTS, 
                    isFetching: false, 
                    posts: [], 
                    totalPages: 0, 
                    totalPosts: 0 
                } 
            })
        ));
}

export const FetchExpiredPosts = (pageNumber: number, filters: Partial<PostFilterValues>) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.FetchExpiredPostList({}));

    //TODO: Once API is available
}

export const fetchPublishedPosts = (
    pageNumber: number, 
    filters: Partial<PostFilterValues>, 
    pageAmount?: number
) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.FetchPublishedPostList({}));

    dispatch(API.draftPost.FetchPublishedPosts(pageNumber, filters, pageAmount))
        .then(response => response.json())
        .then((page: PostListingPage) => dispatch(Actions.FetchPublishedPostListComplete({ succeeded: true, page })))
        .catch(err => dispatch(
            Actions.FetchPublishedPostListComplete({ 
                succeeded: false, 
                page: { 
                    currentPage: 1, 
                    id: PostListingPageId.PUBLISHED, 
                    isFetching: false, 
                    posts: [], 
                    totalPages: 0, 
                    totalPosts: 0 
                }
            })
        ));
}

export const fetchAllPost = (
    pageNumber: number, 
    filters: Partial<PostFilterValues>, 
    pageAmount?: number
) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.FetchAllPostList({}));

    dispatch(API.draftPost.FetchAllPost(pageNumber, filters, pageAmount))
        .then(response => response.json())
        .then((page: PostListingPage) => dispatch(Actions.FetchAllPostListComplete({ succeeded: true, page })))
        .catch(err => dispatch(
            Actions.FetchAllPostListComplete({ 
                succeeded: false, 
                page: { 
                    currentPage: 1, 
                    id: PostListingPageId.ALL, 
                    isFetching: false, 
                    posts: [], 
                    totalPages: 0, 
                    totalPosts: 0 
                } 
            })
        ));
}

export const fetchScheduledPosts = (
    pageNumber: number, 
    filters: Partial<PostFilterValues>, 
    pageAmount?: number
) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.FetchScheduledPostList({}));

    dispatch(API.draftPost.FetchScheduledPosts(pageNumber, filters, pageAmount))
        .then(response => response.json())
        .then((page: PostListingPage) => dispatch(Actions.FetchScheduledPostListComplete({ succeeded: true, page })))
        .catch(err => dispatch(
            Actions.FetchScheduledPostListComplete({ 
                succeeded: false, 
                page: { 
                    currentPage: 1, 
                    id: PostListingPageId.SCHEDULED, 
                    isFetching: false, 
                    posts: [], 
                    totalPages: 0, 
                    totalPosts: 0 
                } 
            })
        ));
}

export const fetchSubmissionPosts = (
    pageNumber: number, 
    filters: Partial<PostFilterValues>, 
    pageAmount?: number
) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.FetchSubmissionPostList({}));

    dispatch(API.submissionPosts.FetchSubmissionPosts(pageNumber, filters, pageAmount))
        .then(response => response.json())
        .then((page: PostListingPage) => dispatch(Actions.FetchSubmissionPostListComplete({ succeeded: true, page })))
        .catch(err => dispatch(
            Actions.FetchSubmissionPostListComplete({ 
                succeeded: false, 
                page: { 
                    currentPage: 1, 
                    id: PostListingPageId.SUBMISSIONS, 
                    isFetching: false, 
                    posts: [], 
                    totalPages: 0, 
                    totalPosts: 0 
                } 
            })
        ));
}

export const fetchPostsFeedLocal = (filters: Partial<PostFeedFilters>, maxResults: number, pageNumber: number) => (dispatch, getState: () => GlobalApplicationState): Promise<PostFeedItem[]> => {
    dispatch(Actions.FetchPostsFeedLocal({}));

    return dispatch(API.clientPosts.FetchPostsFeedLocal(filters, maxResults, pageNumber))
        .then(response => response.json())
        .then((postsFeed) => {
            dispatch(Actions.FetchPostsFeedLocalComplete({ succeeded: true }));
            return postsFeed;
        })
        .catch(error => {
            dispatch(Actions.FetchPostsFeedLocalComplete({ succeeded: false }));
            return [];
        });
}

export const fetchPostsFeed = (filters: Partial<PostFeedFilters>, filtersApplied: boolean, pageNumber: number) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.FetchPostsFeed({}));

    dispatch(API.clientPosts.FetchPostsFeed(filters, pageNumber))
        .then(response => response.json())
        .then((postsFeed) => dispatch(Actions.FetchPostsFeedComplete({ succeeded: true, filtersApplied, postsFeed })))
        .catch(error => dispatch(Actions.FetchPostsFeedComplete({ succeeded: false, filtersApplied, postsFeed: [] })));
}

export const updatePostsFeed = (postsFeed: PostFeedItem[]) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.UpdatePostsFeed({ postsFeed }));
}

export const fetchDraftPost = (id: string, imageScale: ImageScale = ImageScale.Mobile) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.FetchDraftPost({}));

    return dispatch(API.draftPost.FetchDraftPost(id))
        .then(response => response.json())
        .then((post: Post) => {
            dispatch(Actions.FetchDraftPostComplete({ succeeded: true, post }));
            return post;
        })
        .catch(err => Actions.FetchDraftPostComplete({ succeeded: false, post: null }));
}

export const createNewDraftPost = () => (dispatch, getState: () => GlobalApplicationState): Promise<string> => {
    dispatch(Actions.CreateNewDraft({}));

    return dispatch(API.draftPost.CreateNewPost())
        .then(response => response.json())
        .then((postId: string) => {
            dispatch(Actions.CreateNewDraftComplete({ succeeded: true, postId }));
            return postId;
        });
}

export const setNewDraftPost = (post: Partial<Post>) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.SetNewDraft({ post }));
}

export const submitPost = (draftId: string) => async (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
    dispatch(Actions.SubmitPost({}));

    try {
        await dispatch(API.draftPost.SubmitPost(draftId));
        dispatch(Actions.ClearPostFeed({}));
        dispatch(Actions.ClearPostFeedFilters({}));
        dispatch(Actions.SubmitPostComplete({ succeeded: true }));
        dispatch(push(`/${getState().tenant.id}/admin/posts`));
        return true;
    }
    catch {
        dispatch(Actions.SubmitPostComplete({ succeeded: false }));
        errorAlert("There was an error while attempting to submit.");
        return false;
    }
}

export const clonePost = (draftId: string) => (dispatch, getState: () => GlobalApplicationState): Promise<string> => {
    dispatch(Actions.CreateNewDraft({}));

    return dispatch(API.draftPost.CreateClonedPost(draftId))
        .then(response => response.json())
        .then((draftId: string) => {
            dispatch(Actions.CreateNewDraftComplete({ succeeded: true, postId: draftId }));
            return draftId;
        });
}

export const updateField = <F extends keyof Post>(field: F) => (value: Post[F]) => (dispatch, getState: () => GlobalApplicationState) => {
    return dispatch(Actions.FieldUpdate({ field, value }));
}

export const updatePost = (post: Partial<Post>) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.UpdatePost({ post }));
}

export const saveDraft = (isSubmission: boolean = false) => (dispatch, getState: () => GlobalApplicationState) : Promise<boolean> => {
    dispatch(Actions.DraftPostSave({}));
    return dispatch(API.draftPost.SaveDraftPost(getState().posts.editor.post, isSubmission))
        .then(response => {
            if (response.status === 200) {
                dispatch(Actions.DraftPostSaveComplete({ succeeded: true }))
                return true;
            }
            else {
                dispatch(Actions.DraftPostSaveComplete({ succeeded: false }))
                errorAlert("There was an error while attempting to save")
                return false;
            }
        })
        .catch(e => {
            dispatch(Actions.DraftPostSaveComplete({ succeeded: false }));
            errorAlert("There was an error while attempting to save");
            return false;
        });
}


export const deleteDraftPosts = (postList: PostDeleteModel[]) => (dispatch, getState: () => GlobalApplicationState): Promise<any> => {
    dispatch(Actions.DraftPostDelete({ id: '' }));

    return Promise.all(postList.map(p => dispatch(p.status === 'draft' ? API.draftPost.DeleteDraftOnlyPost(p.draftId) : API.draftPost.DeleteDraftPost(p.draftId))))
    .then(response => dispatch(Actions.DraftPostDeleteCompleteBatch({ succeeded: true, ids: postList.map(c => c.draftId) })))
}

export const fetchImages = (
    forGallery?: boolean, 
    pageAmount?: number, 
    pageNumber?: number,
    moveFilesForAppBar?: MoveFilesWithSrc,
    mediaFilter?: MediaFiltersModel,
) => (dispatch, getState: () => GlobalApplicationState): Promise<any> => {
    dispatch(Actions.FetchImagesFromLibrary({}));

    let cookiePageAmount = cookie.getRowsPerPage(ROWS_PER_PAGE_COOKIE_NAMES.GALLERY, 100);
    return dispatch(API.mediaLibrary.FetchImages(forGallery, pageAmount ?? cookiePageAmount, pageNumber ?? 1, mediaFilter))
        .then(response => response.json())
        .then(results => {
            dispatch(Actions.FetchImagesFromLibraryComplete({
                succeeded: true,
                chonkyFileData: results.mediaFiles,
                totalCount: results.totalCount,
                totalPages: results.totalPages,
                currDir: results.currDir,
                folderChain: results.folderChain,
                moveFilesForAppBar
            }));
        });
}

export const fetchImageByName = (filename: string, type: string, parentId?: string) => (dispatch, getState: () => GlobalApplicationState): Promise<ImageV1 | undefined> => {
    return dispatch(API.mediaLibrary.FetchImageByName(filename, type, parentId, false))
        .then(response => response.json())
        .catch(_ => undefined);
}

export const fetchFolderByName = (fileName: string, parentId?: string) => (dispatch, getState: () => GlobalApplicationState): Promise<MediaFile | undefined> => {
    return dispatch(API.mediaLibrary.FetchFolderByName(fileName, parentId, false))
        .then(response => response.json())
        .catch(_ => undefined);
}

export const fetchImageByNameAsFileData = (filename: string, type: string, parentId?: string) => (dispatch, getState: () => GlobalApplicationState): Promise<CustomFileData | undefined> => {
    return dispatch(API.mediaLibrary.FetchImageByName(filename, type, parentId, true))
        .then(response => response.json())
        .catch(_ => undefined);
}

export const fetchFolderByNameAsFileData = (fileName: string, parentId?: string, asChonkyFileData: boolean = false) => (dispatch, getState: () => GlobalApplicationState): Promise<CustomFileData | undefined> => {
    return dispatch(API.mediaLibrary.FetchFolderByName(fileName, parentId, true))
        .then(response => response.json())
        .catch(_ => undefined);
}

export const uploadFolder = (fileName: string, parentId?: string) => (dispatch, getState: () => GlobalApplicationState): Promise<void> => {
    return dispatch(API.mediaLibrary.UploadFolder(fileName, parentId))
        .catch(_ => undefined);
}

export const getUniqueFileName = (filename: string, type: string, parentId?: string, abortSignal?: AbortSignal) => (dispatch, getState: () => GlobalApplicationState): Promise<string> => {
    return dispatch(API.mediaLibrary.GetUniqueFileName(filename, type, parentId, abortSignal))
        .then(response => response.json());
}

export const getUniqueFolderName = (folderName: string, parentId?: string) => (dispatch, getState: () => GlobalApplicationState): Promise<string> => {
    return dispatch(API.mediaLibrary.GetUniqueFolderName(folderName, parentId))
        .then(response => response.json());
}

export const deleteImageByName = (filename: string, type: string, parentId?: string, abortSignal?: AbortSignal) => (dispatch, getState: () => GlobalApplicationState): Promise<any> => {
    return dispatch(API.mediaLibrary.DeleteImageByName(filename, type, parentId, abortSignal))
        .then(_ => undefined);
}

export const deleteFolderByName = (folderName: string, parentId?: string) => (dispatch, getState: () => GlobalApplicationState): Promise<any> => {
    return dispatch(API.mediaLibrary.DeleteFolderByName(folderName, parentId))
        .then(_ => undefined);
}

export const renameImage = (id: string, newFileName: string) => (dispatch, getState: () => GlobalApplicationState): Promise<any> => {
    return dispatch(API.mediaLibrary.RenameImage(id, newFileName))
        .then(_ => undefined);
}

export const renameFolder = (id: string, newFolderName: string) => (dispatch, getState: () => GlobalApplicationState): Promise<any> => {
    return dispatch(API.mediaLibrary.RenameFolder(id, newFolderName))
        .then(_ => undefined);
}

export const fetchDocuments = (forDirectory?: boolean) => (dispatch, getState: () => GlobalApplicationState): Promise<void> => {
    dispatch(Actions.FetchDocumentsFromDirectory({}));

    let continuationToken = getState().posts.documentDirectory.continuationToken;

    return dispatch(API.mediaLibrary.FetchDocuments(continuationToken!, forDirectory))

        .then(response => response.json()).catch(err => console.log(err))
        .then(results => {
            dispatch(Actions.FetchDocumentsFromDirectoryComplete({
                succeeded: true,
                files: results.documents,
                continuationToken: results.continuationToken
            }))
        })
}

export const fetchImage = (imageId: string) => (dispatch): Promise<ImageV1> => {
    return dispatch(API.draftPost.FetchImageForId(imageId))
        .then(response => response.json())
        .then(image => {
            dispatch(Actions.FetchImageFromLibraryComplete({ succeeded: true, image }))
            return image;
        });
}

export const fetchDocument = (docId: string) => (dispatch, getState: () => GlobalApplicationState): Promise<ImageV1> => {
    return dispatch(API.draftPost.FetchDocumentForId(docId))
        .then(response => response.json())
        .then(file => {
            dispatch(Actions.FetchDocumentFromDirectoryComplete({ succeeded: true, file }))
            return file;
        });
}

export const deleteImagesFromLibrary = (imageIds: string[]) => (dispatch, getState: () => GlobalApplicationState): Promise<any> => {
    dispatch(Actions.DeleteImagesFromLibraryInit({}));

    return dispatch(API.mediaLibrary.DeleteBulkImages(imageIds))
        .then(response => response.status === 200 ? imageIds : null)
        .then((ids: string[]) => {
            const deletedImageIds = ids.filter(id => id);
            dispatch(Actions.DeleteImagesFromLibraryComplete({ succeeded: true, ids: deletedImageIds }))
        })
        .catch(e => null);
}

export const getFolderAndImageCount = (folderId: string) => (dispatch, getState: () => GlobalApplicationState): Promise<ImageAndFolderCountReturn> => {
    return dispatch(API.mediaLibrary.GetFolderAndImageCount(folderId))
        .then(response => response.json())
        .catch(_ => undefined);
}

export const getBulkFolderAndImageCount = (folderIds: string[]) => (dispatch, getState: () => GlobalApplicationState): Promise<ImageAndFolderCountReturn> => {
    return dispatch(API.mediaLibrary.GetBulkFolderAndImageCount(folderIds))
        .then(response => response.json())
        .catch(_ => undefined);
}

export const deleteFolder = (folderId: string) => (dispatch, getState: () => GlobalApplicationState): Promise<any> => {
    return dispatch(API.mediaLibrary.DeleteFolder(folderId))
        .then(_ => undefined);
}

export const deleteBulkFolders = (folderIds: string[]) => (dispatch, getState: () => GlobalApplicationState): Promise<any> => {
    return dispatch(API.mediaLibrary.DeleteBulkFolders(folderIds))
        .then(_ => undefined);
}

export const moveMedia = (movePayload: MoveFiles) => (dispatch, getState: () => GlobalApplicationState): Promise<any> => {
    return dispatch(API.mediaLibrary.MoveMedia(movePayload))
        .then(_ => undefined);
}

/*
 * In the upload image case we cant use the default fetch implementation,
 * we have to resort to xhr; hence we need to call the authmanager ourselves.
 * This however lets us attach an onProgress event listener.
 * This xhr request could have been abstracted in the middleware, but we would lose
 * the onProgress event when we dispatch the network request.
 */
export const uploadImage = (
    file: File,
    onProgress: (percentCompleted: number) => void, 
    onLoad?: (e: ProgressEvent<EventTarget>, xhr: XMLHttpRequest) => void, 
    onError?: (e: ProgressEvent<EventTarget>, xhr: XMLHttpRequest) => void, 
    onAbort?: (e: ProgressEvent<EventTarget>, xhr: XMLHttpRequest) => void,
    parentId?: string,
    abortSignal?: AbortSignal,
) => async (dispatch, getState: () => GlobalApplicationState): Promise<ImageUploadReturn<string, string> | null> => {
    if (!(/^image\/(jpg|jpeg|png|bmp)/).test(file.type)) {
        Actions.UploadImageComplete({ succeeded: false, id: null })
        return Promise.resolve(null);
    }

    dispatch(Actions.UploadImage({}));

    const fileToUpload = file.type === 'image/gif' ? await getFirstGifFrame(file) : file;

    return MsalAuthModule
        .getInstance()
        .getAccessToken()
        .then(accessToken => {
            const requestDetails = API.mediaLibrary.UploadImage(fileToUpload, parentId, abortSignal);
            const url = getState().config.SparrowClientApiUrl + requestDetails.url
                .replace("{tenant}", getState().tenant.id);

            const handlers = { onLoad, onAbort, onError, onProgress };

            return new XhrUpload(url, requestDetails, handlers)
                .authenticateWith(accessToken)
                .upload();
        })
        .then((response: string) => {
            let image = JSON.parse(response) as { imageId: string, fileName: string }
            dispatch(Actions.UploadImageComplete({ succeeded: true, id: image.imageId, fileName: image.fileName }))
            return { id: image.imageId, name: image.fileName }
        });
}


const getFirstGifFrame = (gif: File): Promise<File> => {
    return new Promise((resolve, reject) => {
        const canvas = document.createElement('canvas');
        const img = new Image();
        img.onload = function () {
            canvas.width = img.width;
            canvas.height = img.height;
            let context = canvas.getContext('2d')!;
            context.drawImage(img, 0, 0, img.width, img.height);
            canvas.toBlob((newBlob: any) => {
                newBlob.lastModifiedDate = new Date();
                newBlob.name = gif.name;
                return resolve(newBlob as File);
            });
        }
        img.src = URL.createObjectURL(gif);
    })
}


export const ImageSelected = (id: string, url: string) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.ImageSelected({ imageId: id, imageUrl: url }));
}

export const DocumentSelected = (id: string, url: string) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.DocumentSelected({ docId: id, docUrl: url }));
}

export const ImageTransformed = (transforms: { points: number[], zoom?: number }) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.ImageTransformed({ transforms }));
}

export const SaveImageTransformations = () => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.ImageTweaksConfirmed({}));
}

export const CancelImageChanges = () => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.CancelImageChanges({}));
}

export const DeleteImage = () => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.DeleteEditorPostImage({}));
}

export const EditPlacedImage = () => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.EditPlacedImage({}));
}

export const setImageKeywords = (imageId: string, keywords: string[]) => (dispatch): Promise<void> => {
    return dispatch(API.mediaLibrary.SetImageKeywords(imageId, keywords))
        .then(_ => undefined);
}

export const getContentAnalysis = (id: string, text: string, includeHighlightResults: boolean = false) => (dispatch, getState: () => GlobalApplicationState): Promise<ContentAnalysis> => {
    dispatch(Actions.GetContentAnalysis({}));
    return dispatch(API.draftPost.GetContentAnalysis(id, text, includeHighlightResults))
        .then(response => {
            dispatch(Actions.GetContentAnalysisComplete({ succeeded: true }));
            return response.json();
        })
        .catch(err => {
            dispatch(Actions.GetContentAnalysisComplete({ succeeded: false, err }));
            return;
        });
}


export const getDraftTranslation = (id: string, content: TranslatablePostContent, outputLCID: string) => (dispatch, getState: () => GlobalApplicationState): Promise<TranslatablePostContent> => {
    dispatch(Actions.GetDraftTranslation({}));
    return dispatch(API.events.GetDraftTranslation(id, content, outputLCID))
        .then(response => {
            dispatch(Actions.GetDraftTranslationComplete({ succeeded: true }));
            return response.json();
        })
        .catch(err => {
            dispatch(Actions.GetDraftTranslationComplete({ succeeded: false, err }));
            return;
        });
}

export const unpublishPost = (postId: string) => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
    dispatch(Actions.UnpublishPost({}));

    dispatch(Actions.ClearPostFeed({}));
    dispatch(Actions.ClearPostFeedFilters({}));

    return dispatch(API.draftPost.UnpublishPost(postId))
        .then(response => {
            dispatch(Actions.UnpublishPostComplete({ succeeded: response.status === 200 }));
            return response.status === 200;
        })
        .catch(err => {
            dispatch(Actions.UnpublishPostComplete({ succeeded: false }));
            return false;
        });
}

export const publishPost = (id: string, time?: string) => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
    dispatch(Actions.PublishPost({}));

    let defaultLang = getState().settings.tenantSettings.defaultLCID;

    let attemptedPost = getState().posts.draftPostList.posts.find(p => p.id === id)
        || getState().posts.editor.post;

    // let editingPost = getState().posts.editor.post;

    // if (editingPost.emailNotifications.enabled && (editingPost.emailNotifications.frequency === 1 || editingPost.emailNotifications.frequency === 2)) {
    //     if (editingPost.emailNotifications.hours! < 1) {
    //         errorAlert("Publishing hours for Email must be greater than 0");
    //         return dispatch(Actions.PublishPostComplete({ succeeded: false }));
    //     }
    // }

    // if (editingPost.smsNotifications.enabled && (editingPost.smsNotifications.frequency === 1 || editingPost.smsNotifications.frequency === 2)) {
    //     if (editingPost.smsNotifications.hours! < 1) {
    //         errorAlert("Publishing hours for SMS must be greater than 0");
    //         return dispatch(Actions.PublishPostComplete({ succeeded: false }));
    //     }
    // }

    return dispatch(API.draftPost.PublishPost(id, time))
        .then(response => {
            if (response.status !== 200)
                throw response;

            dispatch(Actions.ClearPostFeed({}));
            dispatch(Actions.ClearPostFeedFilters({}));

            return dispatch(Actions.PublishPostComplete({ succeeded: true }))
        })
        .then(() => dispatch(publishSuccess(id, time)))
        .then(() => true)
        .catch((response) => {
            let validationResult = { postId: id, postTitle: getTranslatedTitle(attemptedPost.translatedContent, defaultLang) };
            let unexpectedError = 'An unexpected error occured. Please try again later or contact support if this issue persists.';

            if (response && response.json && response.status === 400) {
                (response.json() as Promise<ValidationResponse>)
                    .then(errors => dispatch(Actions.PublishPostComplete({ succeeded: false, validationResult: { ...validationResult, errors: errors.ModelState } })))
            } else {
                dispatch(Actions.PublishPostComplete({ succeeded: false, validationResult: { ...validationResult, errors: { 'server error': [unexpectedError] } } }));
            }

            return false;
        });
}

export const publishSuccess = (id?: string, time?: string) => (dispatch, getState: () => GlobalApplicationState) => {
    const state = getState();
    const returnUrl = `/${state.tenant.id}/admin/posts`;

    const postInList = state.posts.draftPostList.posts.filter(p => p.id === id)[0] || {} as any;
    const postInEditor = state.posts.editor.post;

    const video = id ? postInList.video
        : postInEditor.video;

    const videoExists = !!(video && video.id);

    const videoInAttachments = (postInEditor.attachedContent || []).filter(f => f.fileType === "video").length > 0
        || (postInList.attachedContent || []).filter(f => f.fileType === "video").length > 0

    dispatch(Actions.PublishSuccessful({ containsVideoContent: videoExists || videoInAttachments, expectedPublishTime: time, id }))
    dispatch(push(returnUrl));
}

export const hidePublishSuccessfulDialog = () => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.HidePublishSuccessfulDialog({}))
}

export const FetchIdealWordCount = () => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.FetchIdealWordCount({}));
    return dispatch(API.authoring.FetchIdealWordCountLimit())
        .then(response => response.json())
        .then(response => {
            return dispatch(Actions.FetchIdealWordCountComplete({ succeeded: true, idealWordCount: response.wordCount }))
        })
}

export const DismissIdealWordCountTip = () => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.DismissIdealWordCountTip({}));
}

export const GetCurrentSentiment = (content: string, cb: (err: any, n: number) => void) => (dispatch, getState: () => GlobalApplicationState) => {
    return dispatch(API.authoring.FetchCurrentPostSentiment(content))
        .then(response => response.json())
        .then(result => {
            result.Score
                ? cb(null, result.Score)
                : cb('Sentiment Analysis Failed', 0);
        });
}


export const SetPostType = (postType: string) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.SetPostType({ postType }));
}

export const SetChangedSinceSaved = (changed: boolean) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.SetChangedSinceSave({ changed }));
}

export const SetSaving = (saving: boolean) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.SetSaving({ saving }));
}

export const updatePostList = (newPostList: PostListingPage) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.setNewPostList({ page: newPostList }))
}


export const setUploading = (uploading: boolean) => (dispatch, getState) => {
    dispatch(Actions.SetUploading({ uploading }))
}

export const uploadThumbImage = (blob: Blob, videoId: string, onProgress: (percentCompleted: number) => void, onLoad?: (e) => void, onError?: (e) => void, onAbort?: (e) => void) => (dispatch, getState: () => GlobalApplicationState) => {
    return MsalAuthModule.getInstance().getAccessToken()
        .then((accessToken) => {
            let requestDetails = API.mediaLibrary.VideoUploads.UploadImageThumbnail(blob, videoId);
            let url = getState().config.SparrowClientApiUrl + requestDetails.url
                .replace('{tenant}', getState().tenant.id);

            let handlers = {
                onProgress,
                onLoad,
                onError,
                onAbort
            }
            return new XhrUpload(url, requestDetails, handlers)
                .authenticateWith(accessToken)
                .upload();
        })
        .then((response: string) => {
            let resultingThumb = JSON.parse(response) as { id: string, url: string, thumbnail: ImageV1 }
            return resultingThumb;
        });
}

function getFileChunksFrom(file: File, chunkSize?: number) {
    let fileChunks = [] as Blob[];
    let maxChunkSize = chunkSize || 1048576;

    file.slice = (file as any).mozSlice ? (file as any).mozSlice :
        (file as any).webkitSlice ? (file as any).webkitSlice :
            (file as any).slice ? (file as any).slice : () => { };

    while ((fileChunks.length * maxChunkSize) < file.size) {

        let byteStart = fileChunks.length * maxChunkSize;
        let byteEnd = byteStart + maxChunkSize;
        let chunk = file.slice(byteStart, byteEnd, file.type);

        if (chunk.size > 0)
            fileChunks.push(chunk);

        if (chunk.size < maxChunkSize)
            break;
    }
    return fileChunks;
}

class VideoFramer {

    private _videoSrc;
    private _videoElement: HTMLVideoElement;
    private _canvasElement: HTMLCanvasElement;

    private _videoReadyStatus = 0;
    private _ready: Promise<any>;

    constructor(src: string) {
        this._videoSrc = src;

        this._videoElement = document.createElement('video');
        this._canvasElement = document.createElement('canvas');

        this._ready = new Promise<void>((resolve, reject) => {
            // start loading video
            once(this._videoElement, 'loadedmetadata loadeddata', (e) => {
                this._videoReadyStatus += 1;
                if (this._videoReadyStatus === 2) {
                    // video is ready
                    resolve();
                }
            });
        });

        this._videoElement.crossOrigin = 'anonymous';
        this._videoElement.src = this._videoSrc;
    }

    public ready = (cb) => {
        this._ready.then(() => {
            cb()
        });
    }

    public getImageBlobAtTimeAsync = (time: number, callback: (blob: Blob | null, error: any) => void) => {
        once(this._videoElement, 'seeked', (e) => {
            this._canvasElement.width = this._videoElement.videoWidth;
            this._canvasElement.height = this._videoElement.videoHeight;

            let context = this._canvasElement.getContext('2d');
            !!context && context.drawImage(this._videoElement, 0, 0, this._videoElement.videoWidth, this._videoElement.videoHeight)

            try {
                this._canvasElement.toBlob((blob) => {
                    callback(blob, null);
                }, 'image/jpeg');
            } catch (ex) {
                callback(null, ex)
            }
        });
        this._videoElement.currentTime = time;
    }

    public getVideoDuration = () => {
        return this._videoElement.duration;
    }

    public teardown = () => {
    }
}


export const setPostFeedFilters = (filters: Partial<PostFeedFilters>) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.SetPostFeedFilters({ filters }));
}

export const setShowPostFeedFilters = (show: boolean) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.SetShowPostFeedFilters({ show }));
}


export const sendAcknowledgement = (postId: string) => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
    dispatch(Actions.SendAcknowledgement({}));
    return dispatch(API.compliance.SendAcknowledgement(postId))
        .then(response => {
            dispatch(Actions.SendAcknowledgementComplete({ succeeded: response.status === 200 }));
            return response.status === 200;
        })
        .catch(err => {
            dispatch(Actions.SendAcknowledgementComplete({ succeeded: false, err }));
            return false;
        });
}


export const setReaction = (postId: string, reactionId: string | null) => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
    dispatch(Actions.SetReaction({}));
    return dispatch(API.reactions.SetReaction(postId, reactionId))
        .then(response => {
            dispatch(Actions.SetReactionComplete({ succeeded: response.status === 200 }));
            return response.status === 200;
        })
        .catch(err => {
            dispatch(Actions.SetReactionComplete({ succeeded: false, err }));
            return false;
        });
}

export const addComment = (postId: string, bodyHtml: string, bodySimple: string) => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
    dispatch(Actions.AddComment({}));
    return dispatch(API.comments.AddComment(postId, bodyHtml, bodySimple))
        .then(response => {
            dispatch(Actions.AddCommentComplete({ succeeded: response.status === 200 }));
            return response.status === 200
        })
        .catch(err => {
            dispatch(Actions.AddCommentComplete({ succeeded: false, err }));
            return false;
        });
}

export const addCommentReply = (postId: string, bodyHtml: string, bodySimple: string, parentId: string) => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
    dispatch(Actions.AddCommentReply({}));
    return dispatch(API.comments.AddCommentReply(postId, bodyHtml, bodySimple, parentId))
        .then(response => {
            dispatch(Actions.AddCommentReplyComplete({ succeeded: response.status === 200 }));
            return response.status === 200
        })
        .catch(err => {
            dispatch(Actions.AddCommentReplyComplete({ succeeded: false, err }));
            return false;
        });
}

export const deleteComment = (commentId: string) => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
    dispatch(Actions.DeleteComment({}));
    return dispatch(API.comments.DeleteComment(commentId))
        .then(response => {
            dispatch(Actions.DeleteCommentComplete({ succeeded: response.status === 200 }));
            return response.status === 200;
        })
        .catch(err => {
            dispatch(Actions.DeleteCommentComplete({ succeeded: false, err }));
            return false;
        });
}

export const destarComment = (commentId: string) => (dispatch, getState: () => GlobalApplicationState): Promise<Comment> => {
    dispatch(Actions.DestarComment({}));
    return dispatch(API.comments.DestarComment(commentId))
        .then(response => {
            dispatch(Actions.DestarCommentComplete({ succeeded: response.status === 200 }));
            return response.json();
        })
        .catch(err => {
            dispatch(Actions.DestarCommentComplete({ succeeded: false, err }));
            return;
        });
}

export const editComment = (commentId: string, bodyHtml: string, bodySimple: string, snapshot: string) => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
    dispatch(Actions.EditComment({}));
    return dispatch(API.comments.EditComment(commentId, bodyHtml, bodySimple, snapshot))
        .then(response => {
            dispatch(Actions.EditCommentComplete({ succeeded: response.status === 200 }));
            return response.status === 200;
        })
        .catch(err => {
            dispatch(Actions.EditCommentComplete({ succeeded: false, err }));
            return false;
        });
}

export const flagComment = (commentId: string) => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
    dispatch(Actions.FlagComment({}));
    return dispatch(API.comments.FlagComment(commentId))
        .then(response => {
            dispatch(Actions.FlagCommentComplete({ succeeded: response.status === 200 }));
            return response.status === 200;
        })
        .catch(err => {
            dispatch(Actions.FlagCommentComplete({ succeeded: false, err }));
            return false;
        });
}

export const starComment = (commentId: string) => (dispatch, getState: () => GlobalApplicationState): Promise<Comment> => {
    dispatch(Actions.StarComment({}));
    return dispatch(API.comments.StarComment(commentId))
        .then(response => {
            dispatch(Actions.StarCommentComplete({ succeeded: response.status === 200 }));
            return response.json();
        })
        .catch(err => {
            dispatch(Actions.StarCommentComplete({ succeeded: false, err }));
            return;
        });
}

export const subscribeToComments = (postId: string) => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
    dispatch(Actions.SubscribeToComments({}));
    return dispatch(API.comments.SubscribeToPostComments(postId, true))
        .then(response => {
            dispatch(Actions.SubscribeToCommentsComplete({ succeeded: response.status === 200 }));
            return response.status === 200;
        })
        .catch(err => {
            dispatch(Actions.SubscribeToCommentsComplete({ succeeded: false, err }));
            return false;
        });
}

export const unsubscribeFromComments = (postId: string) => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
    dispatch(Actions.UnsubscribeFromComments({}));
    return dispatch(API.comments.SubscribeToPostComments(postId, false))
        .then(response => {
            dispatch(Actions.UnsubscribeFromCommentsComplete({ succeeded: response.status === 200 }));
            return response.status === 200;
        })
        .catch(err => {
            dispatch(Actions.UnsubscribeFromCommentsComplete({ succeeded: false, err }));
            return false;
        });
}

export const clearFetchPost = () => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.ClearFetchPost({}));
}

export const clearPostAuthoringErrorMessage = () => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.ClearPostAuthoringErrorMessage({}));
}

export const clearPostFeed = () => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.ClearPostFeed({}));
}

export const clearPostFeedErrorMessage = () => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.ClearPostFeedErrorMessage({}));
}

export const clearPostFeedFilters = () => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.ClearPostFeedFilters({}));
}

export const clearPostViewErrorMessage = () => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.ClearPostViewErrorMessage({}));
}

export const clearShouldFetch = () => (dispatch, getState: () => GlobalApplicationState): void => {
    dispatch(Actions.ClearShouldFetch({}));
}
