import * as React from 'react';
const queryIcon: string = "/images/icons/query_stats_grey.svg";

interface IEmptyDataMessageProps {
    containerStyle?: React.CSSProperties;
}

const EmptyDataMessage: React.FunctionComponent<IEmptyDataMessageProps> = ({
    containerStyle,
    children = <span>No data available</span>
}) => {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                ...containerStyle
            }}
        >
            <img src={queryIcon} alt={"Magnifying glass with chart behind it."} />
            <span style={{ color: "#666666" }}>
                {children}
            </span>
        </div>
    );
}
export default EmptyDataMessage;
