import React, { useState } from "react";

import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";

import { Theme } from "@mui/material/styles";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import MoreVertIcon from "@mui/icons-material/MoreVert";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    optionsWrapper: {
      position: "relative",
      visibility: "hidden",
      "&.visible": {
        visibility: "visible"
      }
    },
    options: {
      position: "absolute",
      top: 8
    },
    iconButton: {
      minWidth: 0,
      padding: 3
    }
  })
);


const MoreOptionsIconButton: React.FunctionComponent<ComponentProps> = props => {
  const [anchorEl, setAnchorEl] = useState(null);

  const classes = useStyles();

  const onClose = (ev) => {
    ev.stopPropagation();
    setAnchorEl(null);
  }

  return (
    <div className={`${classes.optionsWrapper} ${!!anchorEl ? "visible" : ""} more-options-icon`}>
      <Button
        variant="outlined"
        color="primary"
        onClick={(ev: any) => {
          ev.stopPropagation();
          setAnchorEl(ev.currentTarget);
        }}
        className={`${classes.iconButton} ${props.className || ""}`}
      >
        <MoreVertIcon fontSize="small" />
      </Button>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={(ev: any) => onClose(ev)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        onClick={(ev: any) => onClose(ev)}
      >
        {props.children}
      </Popover>
    </div>
  );
}

interface ComponentProps {
  onClick?: (ev) => void;
  className?: string;
}

export default MoreOptionsIconButton;