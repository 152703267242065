import * as React from "react";

import moment from "moment";

import "../../styles/ReadDate.sass";

const ReadDate: React.FunctionComponent<ComponentProps> = (props) => {
    moment.locale("en");

    const { hideWhenRead, lastReadTime } = props;
    const unread = !lastReadTime;

    if (hideWhenRead && !unread) return null;

    return (
        <div className={`read-date ${unread ? "unread" : "has-been-read"}`}>
            {!unread ? `Read ${moment(lastReadTime).fromNow()}` : "Unread"}
        </div>
    );
};

interface ComponentProps {
    hideWhenRead?: boolean;
    lastReadTime?: string;
}

export default ReadDate;
