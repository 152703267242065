import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { AppBar, Toolbar, IconButton, Link } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ConnectedProps, connect } from "react-redux";

import { GlobalApplicationState } from "globalApplicationState";
import { dynamicLinksApi } from "api/instances";
import cookies, { COMMON_COOKIE_KEYS } from "utils/cookie";
import useIsMounted from "modules/common/hooks/useIsMounted";
import * as userLayoutActions from "modules/userPortalLayout/actionCreator";

import "./openInMobileAppBanner.sass";

interface IOpenInMobileAppBannerProps {
}

const OpenInMobileAppBanner: React.FunctionComponent<PropsWithRedux> = ({
    mobileAppBannerOpen,
    userEmail,
    setMobileAppBannerOpen
}) => {
    const [dynamicLink, setDynamicLink] = useState<string>();
    const [fetching, setFetching] = useState<boolean>(false);
    const isMounted = useIsMounted();
    const { location: { pathname, search} } = useHistory();

    useEffect(() => {
        // if the banner is showing, then scroll to top of page so banner is visible at first
        if (mobileAppBannerOpen && !fetching && dynamicLink)
            document.documentElement.scrollTop = 0;
    }, [mobileAppBannerOpen, fetching, dynamicLink]);

    useEffect(() => {
        const fetch = async () => {
            try {
                setFetching(true);
                const link = await dynamicLinksApi.getDynamicLink(window.location.href);

                if (isMounted())
                    setDynamicLink(link);
            } catch (err) { }
            finally {
                if (isMounted())
                    setFetching(false);
            }
        }

        if (mobileAppBannerOpen)
            fetch();
    }, [isMounted, pathname, search, mobileAppBannerOpen]);

    const onClose = () => {
        setMobileAppBannerOpen(false);
        cookies.setWithOpts(cookies.getPrefixedKey(userEmail, COMMON_COOKIE_KEYS.MOBILE_APP_BANNER_CLOSED), "true", { expires: 60 });
    }

    return (
        <>
            <div className="mobile-app-banner">
                {mobileAppBannerOpen && !fetching && dynamicLink &&
                    <AppBar position="relative" className="app-bar">
                        <Toolbar className="tool-bar">
                            <div>
                                <IconButton sx={{ color: "#ffffff" }} onClick={onClose}>
                                    <CloseIcon />
                                </IconButton>
                                <span style={{ color: "#ffffff" }}>Join us on mobile!</span>
                            </div>
                            <Link id="open-in-app-link" sx={{ color: "#ffffff" }} variant="button" type="link" href={dynamicLink} target="_blank">
                                OPEN IN APP
                            </Link>
                        </Toolbar>
                    </AppBar>
                }
            </div>
        </>
    );
}

const connector = connect((state: GlobalApplicationState, ownProps: IOpenInMobileAppBannerProps) => ({
    ...ownProps,
    mobileAppBannerOpen: state.userPortalLayout.mobileAppBannerOpen,
    userEmail: state.settings.currentUser.email
}),
    {
        setMobileAppBannerOpen: userLayoutActions.setMobileAppBannerOpen
    });
type PropsWithRedux = ConnectedProps<typeof connector>;

export const MobileAppBanner = connector(OpenInMobileAppBanner);
