/* eslint-disable react/style-prop-object */
import * as React from 'react';
import { IPieChartProps, PieChart } from 'modules/insights/components/charts/pieChart';
import WindowResizeListener from 'modules/common/components/window-resize-listener';
import {FormattedNumber, injectIntl, IntlShape} from "react-intl";
import {connect, ConnectedProps} from "react-redux";
import { GlobalApplicationState } from 'globalApplicationState';
import { NewsletterPastIssueDigestModel } from 'modules/newsletters';

import TextHover from "modules/common/components/hovers/textHover";

import _ from 'lodash';

class IssuePublishingStatsWidget extends React.PureComponent<PropsWithRedux, {}> {

    private _chart: PieChart;
    private _chartElement: HTMLElement;

    private _colors = ['#4caf50', '#9e9e9e', '#03a9f4', '#9c27b0'];

    public componentDidMount() {
        this._chart = new PieChart(this.getChartState());
    }

    public componentDidUpdate() {
        if (this._chart) {
            this._chart.update(this.getChartState());
        }
    }

    public componentWillUnmount() {
        if (this._chart) {
            this._chart.destroy();
        }
    }

    public render() {
        const chartState = this.getChartState();
        const total = _.sum(_.map(chartState.data,d=>d.value));

        return <div className="analytics-widget">
            <header>
                Publishing Stats
            </header>
            {chartState.data.length === 0
                ? <div>Issue has not yet been published.</div>
                : <div className="body">
                        <div className="app-usage">
                            <div className="chart-wrapper">
                                <div ref={(e) => this._chartElement = e! }></div>
                            </div>
                            <div className="legend">
                                <ul>
                                    {chartState.data.map((data, index) => {
                                        return <li key={index}>
                                            <span className="color-indicator" style={{ background: this._colors[index] }}></span>
                                            {data.label === "Excluded"
                                                ? <TextHover text={data.label}><span className="emphasis">Excluded</span> includes dynamic contacts or addressees who are fully up-to-date and have already viewed recent and new content(s) e.g. posts and events in your intranet AND/OR are addressees who have unsubscribed from this newsletter in the past.</TextHover>
                                                : data.label
                                            }: <span> <FormattedNumber value={data.value / total} style="percent" maximumSignificantDigits={3} /> (<FormattedNumber value={data.value} />)</span>
                                        </li>
                                    })}
                                </ul>
                            </div>
                        </div>
                    <WindowResizeListener onChange={this.handleWindowResize} />
                </div>
            }
            <footer>
            </footer>
        </div>
    }

    private getChartState = (): IPieChartProps => {
        const issue = this.props.issue;
        const receipt = !!issue && issue.sentReceipt;
        const data = !!receipt ? [{
            label: 'Copies sent',
            value: issue.stats.UniqueProcessed,
            color: this._colors[0]
        },{
            label: 'Excluded',
            value: receipt.countOfSkippedWithNoContent,
            color: this._colors[1]
        },{
            label: 'Unsubscribers',
            value: receipt.countOfUnsubscribers,
            color: this._colors[2]
        },{
            label: 'Missing addresses',
            value: receipt.countOfInvalidEmailAddresses,
            color: this._colors[3]
        }] : [];

        return {
            el: this._chartElement,
            diameter: 150,
            innerDiameter: 100,
            data: data
        } as IPieChartProps;
    }

    private handleWindowResize = () => {
        this._chart.update(this.getChartState());
    }
}

interface ComponentProps {
    issue: NewsletterPastIssueDigestModel;
    intl: IntlShape;
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps)=>({
        ...ownProps,
        locale: state.localization.locale
    })
);
type PropsWithRedux = ConnectedProps<typeof connector>;
  
export default injectIntl(connector(IssuePublishingStatsWidget));
