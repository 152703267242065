import styled from "styled-components";

const Label = styled.label`
  color: var(--tina-color-grey-8);
  display: block;
  font-size: var(--tina-font-size-1);
  font-weight: 600;
  letter-spacing: 0.01em;
  line-height: 1.35;
  margin-bottom: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

export default Label;