import React from "react";
import { Button, IconButton } from "@mui/material";
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import copy from "copy-to-clipboard";

import { ISuggestion } from "./models";
import HoverText from "modules/documents/components/action-buttons/hoverText";

interface ISmartContentSuggestionProps {
    suggestion: ISuggestion;
    confirmLabel: string;
    copyToClipboard?: boolean;
    confirmPosition?: "inline" | "block"
    onConfirm: (suggestion: ISuggestion) => void;
}

const SmartContentSuggestion: React.FunctionComponent<ISmartContentSuggestionProps> = ({
    suggestion,
    confirmLabel,
    copyToClipboard = false,
    confirmPosition = "block",
    onConfirm,
}) => {
    const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onConfirm(suggestion);
    }

    const isInline = confirmPosition === "inline";
    return (
        <div
            key={suggestion.suggestionId}
            style={{
                border: "solid 1px #dde1e5",
                borderRadius: 4,
                margin: "16px 0"
            }}
        >
            <div style={{
                display: "flex",
                justifyContent: "flex-start",
                padding: 18,
                alignItems: "center",
                flexWrap: isInline ? "nowrap" : "wrap"
            }}>
                <p style={{ flex: 1 }}>{suggestion.suggestion}</p>
                {copyToClipboard &&
                    <HoverText label={
                        <IconButton
                            id={`smart-content-suggestion-copy-${suggestion.suggestionId}`}
                            onClick={() => { copy(suggestion.suggestion, { format: "text/plain" }); }}>
                            <FileCopyOutlinedIcon htmlColor="#aaaaaa" />
                        </IconButton>}>
                        Copy text
                    </HoverText>}
                {isInline &&
                    <Button id={`smart-content-suggestion-confirm-${suggestion.suggestionId}`} color="primary" onClick={onClick}>
                        {confirmLabel}
                    </Button>}
            </div>
            {!isInline &&
                <div style={{ textAlign: "right" }}>
                    <Button
                        id={`smart-content-suggestion-confirm-${suggestion.suggestionId}`}
                        color="primary"
                        onClick={onClick}
                    >
                        {confirmLabel}
                    </Button>
                </div>}
        </div>
    )
}

export { SmartContentSuggestion };
