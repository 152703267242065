import * as React from 'react';
import { CommentAndReactionCount, PlatformAnalyticsReport } from '../models';
import { VerticalBarChart, VBarChartProps, VBarChartDataPoint } from './charts/verticalDoubleBarChart';
import moment from 'moment';
import WindowResizeListener from 'modules/common/components/window-resize-listener';
import {injectIntl, IntlShape} from "react-intl";
import {connect, ConnectedProps} from "react-redux";
import { GlobalApplicationState } from 'globalApplicationState';
import Loading from 'modules/common/components/loading';
import EmptyDataMessage from './emptyDataMessage';
import DownloadIcon from './downloadIconSvg';

class CommentsByMonth extends React.Component<PropsWithRedux, ComponentState> {
    constructor(props) {
        super(props);
        this.state = {
            widthToUse: "2400px",
            itemsToDisplay: 1,
        };
    }

    private _colors = ['#E6911A', '#337AB7'];
    private _chart: VerticalBarChart;
    private _chartElement: HTMLElement;

    public componentDidMount() {
        this._chart = new VerticalBarChart(this.getChartState());
    }

    public componentDidUpdate() {
        if (this._chart && !this.props.loading) {
            this._chart.update(this.getChartState());
        }
    }

    public componentWillUnmount() {
        if (this._chart) {
            this._chart.destroy();
        }
    }

    public render() {
        moment.locale("en");

        return <div id={PlatformAnalyticsReport.commentsAndReactions} className="analytics-widget">
                <div className='downloadIcon' id={`${PlatformAnalyticsReport.commentsAndReactions}-downloadIcon`}>  
                    <DownloadIcon
                        downloadExcel={this.props.downloadExcel}
                        downloadImage={this.props.downloadImage}
                    />
                </div>
                <div style={{overflowX: "auto"}}>
                    <div id={PlatformAnalyticsReport.commentsAndReactions} style={ this.state.itemsToDisplay > 7 ? {width: this.state.widthToUse, backgroundColor: '#fff'} : { backgroundColor: '#fff'}}>                            
                        <div className="header">
                            Post Reactions &amp; Comments
                        </div>

                        <div className="legend">
                                <span style={{ background: this._colors[0], display: "inline-block", width: "15px", height: "15px", marginRight: "5px", position: "relative", top: "2px" }}></span>
                                <span style={{fontSize: "13px", paddingRight: "10px"}}>Reactions</span>
            
                                <span style={{ background: this._colors[1], display: "inline-block", width: "15px", height: "15px", marginRight: "5px", position: "relative", top: "2px" }}></span>
                                <span style={{fontSize: "13px"}}>Comments</span>
                        </div> 
                        <div className="body"style={{marginTop: "45px"}}>
                        {
                            this.props.loading ?
                            <div style={{position: "relative", top: "55px"}}>
                                <Loading/>
                            </div> :
                            <div style={{overflowX: "auto"}}>
                                {this.props.commentAndReactionCounts ?
                                <div 
                                    ref={(e) => this._chartElement = e!} 
                                    className="dashboard-chart-widget yellow-chart"
                                    style={ this.state.itemsToDisplay > 7 ? {width: this.state.widthToUse} : {}}
                                >
                                </div>
                                : <EmptyDataMessage/>}
                            </div>
                        }
                        <WindowResizeListener onChange={this.handleWindowResize} />
                        </div>
                    </div>
                </div>
            </div>
    }

    private getChartState = (): VBarChartProps => {
        let commentCounts = this.props.commentAndReactionCounts ?? [];

        //Use first relevant month and skip over the empty ones.
        if(commentCounts.length > 0) {
            let oldestValidData = commentCounts.findIndex(u => u.commentCount > 0 || u.reactionCount > 0);
            if(oldestValidData === -1)
                oldestValidData = 0;

            commentCounts = commentCounts.slice(oldestValidData);
        }

        //Use short labels if only one month AND the range is a single month.
        let useShortLabels = commentCounts.length > 1 || moment(this.props.startDate, "MMM D, YYYY, h:mmA").month() !== moment(this.props.endDate, "MMM D, YYYY, h:mmA").month();

        let widthToUse = Math.max((commentCounts.length * 80), 380).toString() + "px"
        if(this.state.widthToUse !== widthToUse) {
            this.setState({widthToUse, itemsToDisplay: commentCounts.length});
        }

        let longLabelToUse = `${moment(this.props.startDate, "MMM D, YYYY, h:mmA").format("MMM DD, YYYY")} - ${moment(this.props.endDate, "MMM D, YYYY, h:mmA").format("MMM DD, YYYY")}`;

        return {
            el: this._chartElement,
            height: 200,
            data: commentCounts
                .map((range, index) => {
                    return {
                        x: index,
                        value: range.commentCount,
                        valueSecond: range.reactionCount,
                        label: useShortLabels ? moment(range.rangeStart).format('MMM YYYY') 
                            : longLabelToUse,
                        id: index.toString()
                    } as VBarChartDataPoint;
            }),
            labelBars: true,
            locale: this.props.locale
        } as VBarChartProps;
    }

    private handleWindowResize = () => {
        this._chart.update(this.getChartState());
    }
}

interface ComponentProps {
    loading?: boolean;
    commentAndReactionCounts: CommentAndReactionCount[] | null;
    intl: IntlShape;
    startDate: string;
    endDate: string;
    downloadExcel: () => void;
    downloadImage: () => void;
}

interface ComponentState {
    widthToUse: string;
    itemsToDisplay: number;
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps)=>({
        ...ownProps,
        locale: state.localization.locale
    })
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default injectIntl(connector(CommentsByMonth));
