import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { Button, Grid } from "@mui/material";
import { removeLeftoverCountryCode, setVerify } from "utils/phoneInputFunctions";

interface PhoneFieldProps {
    description?: string;
    label: string;
    value: string;
    country: string;
    enableSearch: boolean;
    autoFormat: boolean;
    changedDescription?: string;
    placeholder?: string;
    disabled?: boolean;
    onChange?: (event: any) => void;
    onVerifySms: () => void;
}
const PhoneInputField: React.FunctionComponent<PhoneFieldProps> = (props) => {
    const [isActive, setActive] = useState(false);
    const [isVerifyDisabled, setVerifyDisabled] = useState(false);
    
    const focusedColor = "#E6911A";
    const disabledColor = "#3b78ab";

    const styleInputClasses = "MuiOutlinedInput-input MuiInputBase-inputMarginDense MuiOutlinedInput-inputMarginDense Mui-focused react-tel-input";

    useEffect(() => {
    }, [isActive, isVerifyDisabled]);

    return (
        <div className="section-field">
            <div className="section-input-field-heading">
                <label>{props.label}</label>
            </div>
            <div className="section-input-field">
                <PhoneInput
                    inputClass={ styleInputClasses }
                    inputStyle={{ boxShadow: "none", border: isActive && !props.disabled ? "solid 2px" + focusedColor : "", width: "100%", padding: "10.5px 14.5px 10.5px 50px" }}
                    searchStyle={{ width: "93%", padding: "4px", height: "40px", maxHeight: "100%" }}
                    dropdownStyle={{ maxHeight: "320px" }}
                    searchPlaceholder="Search country"
                    key={props.value}
                    autoFormat={props.autoFormat}
                    country={props.country}
                    value={props.value}
                    enableSearch={props.enableSearch}
                    placeholder={props.disabled ? "" : props.placeholder}
                    disabled={props.disabled}
                    specialLabel=""
                    onFocus={() => setActive(true)}
                    onChange={(event) => {
                        setVerifyDisabled(setVerify(event));
                        event = removeLeftoverCountryCode(event);

                        if (!!props.onChange && event !== props.value) {
                            props.onChange(event);
                        }
                    }}
                    onBlur={() => {
                        setActive(false);
                    }}
                />
                <Grid justifyContent="space-between" container >
                    <Grid item>
                        {props.description && (
                            <Typography style={{ color: isActive ? focusedColor : "" }} variant="caption">
                                {isActive ? props.changedDescription : props.description}
                            </Typography>
                        )}
                    </Grid>
                    <Grid item>
                    <Button disabled={isVerifyDisabled} onClick={props.onVerifySms} style={{ color: isVerifyDisabled ? "" : disabledColor, height: '20px', textDecoration: "underline", background: "transparent", fontSize: "11px" }}>
                            Verify mobile number
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};
export default PhoneInputField;