export type AudienceState = "disabled" | "enabled";
export type AudienceType = "smart" | "standard";
export type RuleApplicability = "all" | "any";
export type RuleComparator = "contains" | "equals" | "notContains" | "notEquals" | "equalToAny";
export const RuleComparatorOptions = {
    contains: "contains",
    equals: "equals",
    notContains: "notContains",
    notEquals: "notEquals",
    equalToAny: "equalToAny"
}

export interface AudiencesState {
    audiences: Audience[];
    all: AudiencePage;
    enabled: AudiencePage;
    disabled: AudiencePage;
    changedSinceSaved: boolean;
    errorMessage: string;
    isDeleting: boolean;
    isFetching: boolean;
    isInitialLoad: boolean;
    isSaving: boolean;
    isUploading: boolean;
    shouldFetch: boolean;
  }

export interface Audience {
    id: string
    displayName: string
    description: string
    enabled: boolean
    type: string
}

export interface AudienceDetails {
    id: string;
    audienceType: AudienceType;
    description: string;
    includedGroups: AudienceGroup[];
    includedUsers: AudienceUser[];
    includedInvites: AudienceInvite[];
    isEnabled: boolean;
    isReadOnly: boolean;
    ruleApplicability: RuleApplicability;
    rules: Rule[];
    title: string;
    totalGroups: number;
    totalIndividualUsers: number;
    totalUsers: number;
    totalInvites: number;
}

export interface AudienceGroup {
    groupId: string;
    name: string;
}

export interface AudienceItem {
    audienceType: AudienceType;
    description: string;
    id: string;
    internalUsers: number;
    isReadOnly: boolean;
    socialUsers: number;
    sparrowUsers: number;
    invitedUsers: number;
    state: "disabled" | "enabled";
    title: string;
    totalUsers: number;
}

export interface AudienceFilterValues {
    audienceTypes: AudienceType[];
    textToSearch: string;
    maxResults: number;
    audienceId: string;
}

export interface AudiencePage {
    audiences: AudienceItem[];
    currentPage: number;
    totalAudiences: number;
    totalPages: number;
    isFetching?: boolean;
}

export interface AudienceUser {
    userId: string;
    email: string;
    name: string;
}

export interface AudienceInvite {
    inviteId: string;
    email: string;
}

export interface DraftAudience {
    audienceType: AudienceType;
    description: string;
    emails: string[];
    groups: AudienceGroup[];
    isEnabled: boolean;
    ruleApplicability: RuleApplicability;
    rules: Rule[];
    title: string;
    users: AudienceUser[];
    invites: AudienceInvite[];
}

export interface EmailValidation {
    validEmailCount: number;
    invalidEmailCount: number;
}

export interface Rule {
    comparator: RuleComparator;
    key: string;
    value: string;
    valueCollection?: string[];
}

export interface UpdateAudience {
    displayName: string
    description: string
    enabled: boolean
    includedUsers: UserSuggestions[]
    includedGroups: GroupSuggestions[]
    readOnly: boolean
    bulkUploadUsers: string[]
}

export interface UserSuggestions {
    Id: string
    Details: {  firstName: string, lastName: string, email: string }
}

export interface AudienceUserSuggestionsV2 {
    id: string
    name: string
    email: string
    isIncluded: boolean
}

export interface AudienceInviteSuggestions {
    id: string
    email: string
    isIncluded: boolean
}

export interface GroupSuggestions {
    id: string
    name: string
    disabled: boolean
    isIncluded: boolean
}

export interface GroupSuggestionsV1 {
    Id: string;
    Name: string;
    Disabled: boolean;
}

export interface AudienceSuggestions {
    Id: string
    DisplayName: string
    Enabled: boolean
}

export interface UpdateEveryoneAudience {
    displayName: string
    description: string
    enabled: boolean
    includedGroups: AdGroupSuggestions[]
}

export interface AdGroupSuggestions {
    id: string
    displayName: string
    referenceId: string
    syncStatus: GroupSyncStatus
}

export interface GroupGraphSuggestions {
    group: Group
    Disabled: boolean
}
export interface Group {
    id: string
    displayName: string
    memberCount: number
    referenceId: string
    syncStatus: GroupSyncStatus
}
export interface GroupSyncStatus {
    status: string;
    lastSyncCompletedTime: string;
}

export interface SyncAction {
    id: string;
    action: string;
}

export enum SyncType {
    MicrosoftAD = "Microsoft AD",
    Okta = "Okta"
}

export enum SyncLevel {
    Basic = "basic",
    Essentials = "essentialsOnly"
}
