import { useState } from "react";
import Cookies from "../../../utils/cookie";
import { useMediaQuery, useTheme } from "@mui/material";

/**
 * Encapsulate drawer expand logic
 * - read/set expand state into cookies
 * @param cookieKey - the cookie key value to store with
 * @param useSmallScreenLogic - whether or not to default closed if on small screen (currently only used for user portal)
 * @returns
 *  expanded: boolean,
 *  toggleExpanded: () => void
 */

export type DrawerStatus = {
    drawerExpanded: boolean;
    toggleDrawerExpanded: () => void;
}

const useDrawerExpanded = (cookieKey: string, useSmallScreenLogic: boolean = false): DrawerStatus => {
    const theme = useTheme();
    const isSmallAndSmaller = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });

    /**
     * try get default expanded from cookies
     * - if doesn't exist default to expanded
     * @returns boolean
    */
    const getDefaultExpandedState = (): boolean => {
        const savedExpandedSetting = Cookies.get(
            cookieKey
        );

        let result: boolean;
        // default to expanded if no saved value
        if (savedExpandedSetting === undefined) result = true;
        // convert the stored string to boolean
        else result = savedExpandedSetting === "true";

        return useSmallScreenLogic && isSmallAndSmaller
            ? false
            : result;
    };


    const [drawerExpanded, setDrawerExpanded] = useState<boolean>(getDefaultExpandedState());

    // toggle drawer expanded state
    const toggleDrawerExpanded = () => {
        setDrawerExpanded((prev) => {
            // update state and save value in cookies
            let newValue = !prev;
            Cookies.setWithOpts(
                cookieKey,
                newValue.toString(),
                { expires: 365 }
            );

            return newValue;
        });
    };

    return { drawerExpanded, toggleDrawerExpanded };
}

export default useDrawerExpanded;
