import moment from 'moment-timezone';

/*
* This function is used to get the current time in the user's timezone.
* It uses the Moment Timezone library to handle timezone conversions.
* 
* The function does the following:
* 1. It creates a new Date object to get the current time.
* 2. It uses moment.tz.guess() to guess the user's timezone. This is based on the Internationalization API's Intl.DateTimeFormat().resolvedOptions().timeZone property.
* 3. It uses moment.tz() to convert the current time to the user's timezone.
* 
* The function returns a Moment.js object representing the current time in the user's timezone.
*/
export function getCurrentTimeInUserTimezone(): moment.Moment {
    return moment.tz(new Date(), moment.tz.guess());
}