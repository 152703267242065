import * as React from "react";
import { withRouter, RouteComponentProps, Prompt } from "react-router";
import { GlobalApplicationState } from 'globalApplicationState';
import * as actions from '../actionCreator';
import { push } from 'react-router-redux';
import { connect, ConnectedProps } from "react-redux";
import { Survey, SurveyQuestion } from "../models";
import * as RemoteFocusRequests from 'modules/common/remoteFocusRequest';
import PageToolBar from "modules/common/components/pageToolBar";
import Loading from "modules/common/components/loading";
import TextInput from 'modules/common/components/forms/inputs/textInput';
import moment from 'moment';
import DateTimePicker from "modules/common/components/DateTimePicker";
import confirm from 'utils/notyPopups';

import BasePage from "pages/common/basePage";
import Breadcrumb from "pages/common/breadcrumb";
import MainContent from "pages/common/mainContent";

import './surveyEdit.sass';

class SurveyEdit extends React.Component<PropsWithRedux, ComponentState>{

  constructor(props){
    super(props);

    let survey = {
      id: '',
      title: '',
      author: this.props.currentUserFullName || '',
      questions: [{body: '', answers: [{body: ''}, {body: ''}]}] as SurveyQuestion[],
      publishTime: `${moment().add(1, 'days').toISOString()}`,
      expiryTime: `${moment().add(1, 'months').toISOString()}`
    } as Survey;

    this.state = {
      survey,
      changedSinceSaved: false,
      errors: [],
      top: 0,
      published: false,
      contentLoaded: !!!this.props.match.params.surveyId
    };
  }

  public componentWillMount() {
    if(this.props.match.params.surveyId){
      this.props.getSurvey(this.props.match.params.surveyId)
      .then(survey => {
        this.setState(prev => ({
          ...prev,
          survey,
          published: new Date(survey.publishTime!).getTime() <= Date.now(),
          contentLoaded: true
        }));
      });
    }
  }

  public componentDidMount() {
    this.props.clearSurveyList();

    if (typeof (window as any) !== 'undefined') {
      window.addEventListener('scroll', this.windowScrolledHandler);
    }
  }

  public componentWillUnmount() {
    if (typeof (window as any) !== 'undefined') {
      window.removeEventListener('scroll', this.windowScrolledHandler);
    }
  }

  private windowScrolledHandler = (e) => {
    this.setState(prev => ({...prev,  top: window.pageYOffset })); // pageYOffset instead of scrollY for IE compatibility
  }

  public shouldComponentUpdate(np, nextState) {
    return nextState.top === this.state.top
        || nextState.errors.length > 0
        || nextState.errors.length !== this.state.errors.length;
  }

  public render() {
    moment.locale("en");

    // https://github.com/arqex/react-datetime#selectable-dates - for getting valid dates
    var yesterday = moment().subtract( 1, 'day' );
    var valid = function( current ) {
      return current.isAfter( yesterday );
    };

    return (
      <BasePage fullWidth>
        <Prompt when={this.state.changedSinceSaved} message="You have unsaved changes, are you sure you want to leave this page?" />
        <RemoteFocusRequests.Component />
        <Breadcrumb items={[
          {title: "Surveys", link: '~/admin/surveys'},
          {title: "Create new survey"}
        ]} />
        <MainContent>
          <PageToolBar
            rightSideButtons={[
              { key: 'save', label: <span style={{'color': '#F4B032'}}>{`Save`}</span>, onClick: this.save },
              { key: 'back', label: 'Back', onClick: this.cancelEditing }
            ]}
            overflowButtons={this.state.survey.id ? [{ key: 'delete', label: 'Delete', onClick: this.delete }] : []}
            fixedOptions={{ fixedAtHeight: 91 }} />
          <div className="primary-content">
            <div className="editing-form">
              <div className="surveys-form-section">
                <div className="surveys-form-group">
                {
                  this.state.contentLoaded &&
                  <TextInput
                    label={"Title"}
                    isTitle={true}
                    multiline={true}
                    onChange={this.titleChanged}
                    value={this.state.survey.title}
                    disabled={this.state.published}
                  />
                }

                  <TextInput
                    label={"Author"}
                    onChange={this.authorChanged}
                    value={this.state.survey.author}
                    disabled={this.state.published}
                  />
                  <div>
                      <p className="label-date-picker">{`Publish Time:`}</p>
                      <DateTimePicker
                        value={new Date(this.state.survey.publishTime!)}
                        onChange={this.onPublishTimeChanged}
                        disabled={this.state.published}
                        valid={valid}
                      />
                      <p style={{'color': '#f4a742', 'paddingLeft': '8px'}}>{`Note: Once a survey becomes available, only its Expiry Time can be edited.`}</p>
                  </div>
                  <div>
                      <p className="label-date-picker">{`Expiry Time:`}</p>
                      <DateTimePicker
                        value={new Date(this.state.survey.expiryTime!)}
                        onChange={this.onExpiryTimeChanged}
                        valid={valid}
                      />
                  </div>
                </div>
                <div className="surveys-surveys-form-group">
                {
                  this.state.survey.questions.map((question, questionIndex) => {
                    return (
                      <div key={`question-${questionIndex}`}>
                        { this.state.contentLoaded &&
                        <TextInput
                          label={'Question'}
                          multiline={true}
                          onChange={(changedVal) => this.questionBodyChanged(questionIndex, changedVal)}
                          value={question.body}
                          disabled={this.state.published}
                        />
                        }
                        <div className={'survey-answers'}>
                          {
                            question.answers
                              .concat(!this.state.published && question.answers.length < 5 && question.answers[question.answers.length - 1].body ? [{body: ''}] : [])
                              .map((answer, answerIndex) => {
                              return (
                                this.state.contentLoaded &&
                                <TextInput
                                  label={answerIndex === (question.answers.length) && question.answers.length < 5 ? '+ New Answer' : `Answer ${answerIndex + 1}`}
                                  multiline={true}
                                  onChange={(s) => this.answerChanged(questionIndex, answerIndex, s)}
                                  value={answer.body}
                                  disabled={this.state.published}
                                  key={`question-${questionIndex}-answer-${answerIndex}`}
                                />
                              );
                            })
                          }
                        </div>
                        {
                          question.answers.length > 2 && !this.state.published &&
                          <input type='button' className='remove-answer' onClick={() => this.removeAnswer(questionIndex)} value='Remove Answer' />
                        }
                      </div>
                    );
                  })
                }
                </div>
              </div>
            </div>
            {
              this.isBusy() &&
                <div className="loading-bird-overlay">
                    <Loading />
                </div>
            }
          </div>
          { this.state.errors.length > 0 &&
          <div className="rightSideHelperContainer" style={{ top: (this.state.top > 50 ) ? `${this.state.top + 60}px` : 'auto' }}>
            <div className="error-message">{this.state.errors.map((e,i) => <li key={`err-${i}`}>{`- ${e}`}</li>) }</div>
          </div>
          }
        </MainContent>
      </BasePage>
    );
  }

  private onPublishTimeChanged = (moment) => {
    let date = moment.toDate ? new Date(moment.toDate()).toISOString() : moment;
    this.fieldChanged("publishTime", date);
  }

  private onExpiryTimeChanged = (moment) => {
    let date = moment.toDate ? new Date(moment.toDate()).toISOString() : moment;
    this.fieldChanged("expiryTime", date);
  }

  private removeAnswer = (questionIndex: number) => {
    const questions = [...this.state.survey.questions];
    questions[questionIndex].answers.pop();
    this.fieldChanged("questions", questions);
  }

  private answerChanged = (questionIndex: number, answerIndex: number, answerBody: string) => {
    const questions = [...this.state.survey.questions];
    const answers = questions[questionIndex].answers;
    if(!answers[answerIndex])
      answers.push({body: ''})
    answers[answerIndex].body = answerBody;
    this.fieldChanged("questions", questions);
  }

  private questionBodyChanged = (index: number, questionBody: string) => {
    const questions = [...this.state.survey.questions];
    questions[index].body = questionBody;
    this.fieldChanged("questions", questions);
  }

  private titleChanged = (title: string) => this.fieldChanged("title", title);
  private authorChanged = (author: string) => this.fieldChanged("author", author);

  private fieldChanged = (fieldName: string, value: any) => {
    this.setState(prev => ({
      ...prev,
      survey: {
        ...prev.survey,
        [fieldName]: value
      },
      changedSinceSaved: true
    }));
  }

  private cancelEditing = () => {
    this.props.redirectTo(`/${this.props.match.params.tenant}/admin/surveys`)
  }

  private save = async () => {
    const errors = this.surveyErrors();
    if (errors.length > 0) {
      this.setState(prev => ({ ...prev, errors }));
    }else if (!this.isBusy()) {
      const updateMethod = this.state.published ? this.props.updateExpiryTime : this.props.saveSurvey;
      let published = this.state.published;
      const saveWillPublish = "Based on the Publish Time, this survey will be immediately available after saving. After a survey is available, it cannot be changed. Are you sure you want to save?";

      if(new Date(this.state.survey.publishTime!).getTime() <= Date.now() && !published){
        if(!(await confirm.show({ text: saveWillPublish, title: "Immediate publishing" })))
          return;
        published = true;
      }

      updateMethod(this.state.survey)
      .then(survey => {
        this.setState(prev => ({
          ...prev,
          survey,
          changedSinceSaved: false,
          errors: [],
          published
        }));
      });
    }
  }

  private surveyErrors = (): string[] => {
    let errors = [] as string[];
    const survey = this.state.survey;
    const publishDate = new Date(survey.publishTime!);
    const expiryDate = new Date(survey.expiryTime!);

    if (publishDate.getTime() >= expiryDate.getTime())
      errors.push("Expiry time must come after publish time.");

    if (!survey.title || !survey.author)
      errors.push("Surveys must include a Title and an Author.");

    for (let question of survey.questions){
      if (!question.body)
        errors.push("All questions must have content.");
      if (question.answers.filter(a => a.body).length < 2)
        errors.push("Questions must have at least 2 answers with content.");
      for (let answer of question.answers){
        if(!answer.body)
          errors.push("Each answer must have content. Add content or remove empty answers.");
      }
    }
    return Array.from(new Set(errors)); //remove duplicates
  }

  private delete = async () => {
    if (!this.isBusy() && (await confirm.show({ text: "Are you sure you want to delete this survey?", title: "Survey deletion" }))){
      this.props.deleteSurveys([this.state.survey.id])
      .then(() => this.props.redirectTo(`/${this.props.match.params.tenant}/admin/surveys`));
    }
  }

  private isBusy = () => this.props.loading || this.props.saving || this.props.deleting;
}

interface RouteParams {
  tenant: string;
  surveyId: string;
}

interface ComponentState {
  survey: Survey;
  changedSinceSaved: boolean;
  errors: string[];
  top: number;
  published: boolean;
  /** Used so that text inputs only render after content arrives (unless creating new). This
  fixed an issue where multi-line text inputs were not refitting to content size b/c on their
  initial render, there was no content */
  contentLoaded: boolean;
}

interface ComponentProps {
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps & RouteComponentProps<RouteParams>) => ({
      ...ownProps,
      surveyList: state.surveys.surveyList,
      loading: state.surveys.isFetching,
      deleting: state.surveys.isDeleting,
      saving: state.surveys.isSaving,
      currentUserFullName: state.settings.currentUser ? `${state.settings.currentUser.firstName} ${state.settings.currentUser.lastName}` : null
  }),
  {
    deleteSurveys: actions.deleteSurveys,
    saveSurvey: actions.saveSurvey,
    updateExpiryTime: actions.updateSurveyExpiry,
    redirectTo: push,
    getSurvey: actions.getSurvey,
    clearSurveyList: actions.clearSurveyList
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(withRouter(SurveyEdit));
