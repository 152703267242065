import * as React from 'react';
import { PlatformAnalyticsReport, ReadViewStatByHour } from '../models';
import { VerticalBarChart, VBarChartProps, VBarChartDataPoint } from './charts/verticalBarChart';
import WindowResizeListener from 'modules/common/components/window-resize-listener';
import {injectIntl, IntlShape} from "react-intl";
import {connect, ConnectedProps} from "react-redux";
import { GlobalApplicationState } from 'globalApplicationState';
import Loading from 'modules/common/components/loading';
import EmptyDataMessage from './emptyDataMessage';
import DownloadIcon from './downloadIconSvg';
import moment from 'moment';
import momentTimezone from 'moment-timezone';

class ReadsViewsByHour extends React.PureComponent<PropsWithRedux, {}> {

    private _chart: VerticalBarChart;

    public componentDidMount() {
        this._chart = new VerticalBarChart(this.getBarChartState());
    }

    public componentDidUpdate() {
        if (this._chart) {
            this._chart.update(this.getBarChartState());
        }
    }

    public componentWillUnmount() {
        if (this._chart) {
            this._chart.destroy();
        }
    }

    public render() {
        return <div id={PlatformAnalyticsReport.postViewsByHour} className="analytics-widget">
            <div className='downloadIcon' id={`${PlatformAnalyticsReport.postViewsByHour}-downloadIcon`}>  
                <DownloadIcon
                    downloadExcel={this.props.downloadExcel}
                    downloadImage={this.props.downloadImage}
                />
            </div>
           <div className="header">
                Post Views by Time of Day ({moment.tz(new Date(), momentTimezone.tz.guess()).format('z')})
           </div>
            <div className="body">
                {
                    this.props.loading ?
                    <div style={{position: "relative", top: "55px"}}>
                        <Loading/>
                    </div> : 
                    <div>
                        {this.props.readViewStats && this.props.readViewStats.length > 0 ? 
                        <div ref="chart" className="dashboard-chart-widget"></div>
                        : <EmptyDataMessage/> }
                    </div>
                }
                <WindowResizeListener onChange={this.onWindowResize} />
            </div>
            <footer>
            </footer>
        </div>
    }

    private getBarChartState = (): VBarChartProps => {
        
        let date = new Date();

        let readViewStats = this.props.readViewStats || [];

        if(readViewStats.length !== 0){
            for(let i = 0; i < 24; i++){
                const filter = (s: ReadViewStatByHour) => s.utcHour === i;
                const stat = readViewStats.find(filter)!;
                date.setUTCHours(stat.utcHour);

                stat.rangeStart = date.getHours();
                date.setHours(stat.rangeStart + 1);
                stat.rangeEnd = date.getHours();
            }
        }

        readViewStats = readViewStats.sort((a,b) => a.rangeStart - b.rangeStart);

        return {
            el: this.refs.chart,
            locale: this.props.locale,
            height: 200,
            data: readViewStats
                .map(stat => {
                    return {
                        x: stat.rangeEnd.toString(),
                        value: stat.viewCount,
                        label: stat.rangeStart.toString() + ':00',
                        id: stat.rangeEnd.toString()
                    } as VBarChartDataPoint;
                }),
            labelBars: true,
            showOnHover: false
        } as VBarChartProps;
    }

    private onWindowResize = () => this._chart.update(this.getBarChartState())
}

interface ComponentProps {
    loading?: boolean
    readViewStats: ReadViewStatByHour[] | null;
    readViewStatsLocalized?: ReadViewStatByHour[]
    intl: IntlShape;
    downloadImage: () => void;
    downloadExcel: () => void;
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        locale: state.localization.locale
    })
);
type PropsWithRedux = ConnectedProps<typeof connector>;
export default injectIntl(connector(ReadsViewsByHour));