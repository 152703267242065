import React from 'react';
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import { Button, Dialog, DialogContent, DialogTitle, IconButton, Snackbar, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { usersApi } from 'api/instances';

import { Alert } from '@mui/material';

import Disclaimer from 'modules/common/components/disclaimer';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import UserCSVUploader from './userCSVUploader';
import UserInsightsBlockTiny from './userInsightsBlockTiny';
import BackArrow from '@mui/icons-material/ArrowBack';
import LoadingSpinner from 'modules/common/components/loadingSpinner';
import Loading from 'modules/common/components/loading';
import InfoHover from 'modules/common/components/hovers/infoHover';
import { SeverityOptions } from 'utils/muiHelpers';
import { FileDownloader } from 'utils/fileDownloader';

interface ComponentProps {
    isOpen: boolean;
    showDialog: () => void;
    closeDialog: () => void;
    showDrawer: () => void;
}

const BulkLocalDialog: React.FC<PropsWithRedux> = ({isOpen, closeDialog, showDialog, showDrawer}) => {

    const [alertOpen, setAlertOpen] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState("");
    const [alertSeverity, setAlertSeverity] = React.useState<SeverityOptions>("info");

    const [valid, setValid] = React.useState(0);
    const [invalid, setInvalid] = React.useState(0);
    const [duplicate, setDuplicate] = React.useState(0);

    const [file, setFile] = React.useState<File>();
    const [sending, setSending] = React.useState(false);

    const [loading, setLoading] = React.useState(false);
    const [reportLoading, setReportLoading] = React.useState(false);
    const [displayWarning, setDisplayWarning] = React.useState(false);

    const [confirmationOpen, setConfirmationOpen] = React.useState(false);
    const [resultsOpen, setResultsOpen] = React.useState(false);

    const [uploadedUsers, setUploadedUsers] = React.useState(0);

    let expectedHeaders = [ "First Name", "Last Name", "Preferred Name",
    "Email Address", "Mobile Number", "EmployeeID", "Title", "Department", "Region", "Country\r" ]

    let setNewAlert = (message: string, open: boolean, type: SeverityOptions) => {
        setAlertOpen(open)
        setAlertMessage(message)
        setAlertSeverity(type)
    }

    let hideSnackbar = () => {
        setAlertOpen(false);
        setAlertMessage("");
    }

    let swapToUpload = () => {
        setConfirmationOpen(false);
        showDialog();
    }

    let uploadFileAndCreateUsers = () => {
        if (file) {
            setSending(true)
            usersApi.BatchInviteSparrowLocalAccounts(file)
            .then((response) => {
                    setSending(false);
                    setUploadedUsers(response.newUsers);
                })
            .catch((error) => {
                    setSending(false);
                    setNewAlert(error.response.data.Message, true, "error");
            })
        } else {
                setSending(false);
                setNewAlert("There was an error uploading your CSV. Please try again.", true, "error");
        }

        setConfirmationOpen(false);
        setResultsOpen(true);
    }

    const swapToUserInvites = () => {
        setResultsOpen(false);
        showDrawer();
    }

    const parseCSV = async (file: File) => {
        await new Promise<void>((resolve) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                const contents = reader.result!.toString().trim();
                var rows = contents.split("\n");

                //Check for errors
                if(rows.length > 0)
                {
                    var rowOne = rows[0].split(",");
                    if (rowOne.length !== 10) {
                        setNewAlert("File follows an incorrect format and/or contains commas (,). Please use the provided CSV template.", true, "error");
                        reader.readAsText(file);
                        return;
                    }

                    var headers = rowOne;
                    if (JSON.stringify(headers).toLowerCase() !== JSON.stringify(expectedHeaders).toLocaleLowerCase())
                    {
                        setNewAlert("File follows an incorrect format and/or contains commas (,). Please use the provided CSV template.", true, "error");
                        reader.readAsText(file);
                        return;
                    }

                    for(var i in rows) {
                        if(rows[i].split(",").length > expectedHeaders.length ){
                            setNewAlert("File follows an incorrect format and/or contains commas (,). Please use the provided CSV template.", true, "error");
                            reader.readAsText(file);
                            return;
                        } 
                    }
                }
                if(rows.length > 5000) {
                    setDisplayWarning(true);
                }

                resolve();
            };
            reader.readAsText(file);
        });

        setLoading(true);
        setFile(file);
        closeDialog();
        setConfirmationOpen(true);
        var results = await usersApi.GetIntermediateResultsBulk(file);
        setLoading(false);
        setDisplayWarning(false);

        setValid(results.valid);
        setInvalid(results.invalid);
        setDuplicate(results.duplicate); 
    }

    const downloadSample = () => {
        let rows = [
            ["", "First Name", "Last Name", "Preferred Name", "Email Address", "Mobile Number",
             "EmployeeID", "Title", "Department", "Region", "Country"],
        ];
        const file = {
            name: "sampleBulkUpload.csv",
            type: "data:text/csv;charset=utf-8"
        };
        new FileDownloader(file).downloadSampleCSV(rows);
    }

    let downloadCSVReport = async () => {
        if(file) {
            setReportLoading(true)
            var results = await usersApi.GetIntermediateResultsCSV(file);
            var link = window.document.createElement("a");
            link.setAttribute("href", "data:text/csv;charset=utf-8" + encodeURI(results));
            link.setAttribute("download", `bulk-upload-sparrow-users-${new Date().toISOString()}.csv`);
            link.click(); 
            setReportLoading(false)
        }
        else {
            return;
        }
    }

    return (
        <React.Fragment>
            <Dialog open={isOpen} onClose={closeDialog} maxWidth="md">
                <DialogTitle className="external-user-dialog-formatting">
                    <div className="external-user-dialog-header-title">
                        <Typography variant="h2">Bulk Import New Sparrow Users</Typography>
                        <div style={{position: "relative", top: "4px", marginRight: "auto"}}>
                            <InfoHover>
                                Sparrow Users are accounts directly provided, managed and created by our system. These users do not require external providers to login.
                            </InfoHover>
                        </div>
                        <IconButton onClick={closeDialog} size="large">
                            <CloseIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent className="external-user-dialog-content" style={{paddingBottom: "20px"}}>
                    <Disclaimer
                        icon={<InfoOutlinedIcon />}
                        text={
                        <React.Fragment>
                            <span>
                                Download and complete the CSV template below. First name, last name, and an email address are required to add and invite each user.
                                Sparrow Invites allow users to activate their accounts.
                            </span>
                        </React.Fragment>
                        }
                    />

                    <div style={{paddingTop: "22px", paddingBottom: "20px"}}>
                        <UserCSVUploader parseCSV={parseCSV} />
                    </div>

                    <Button 
                        style={{float: "left"}} 
                        color="primary" 
                        variant="outlined" 
                        startIcon={<img style={{width: "22px", position: "relative", top: "-2px"}} src="/images/icons/downloadIcon.svg" alt="Arrow pointing downwards"></img>} 
                        onClick={downloadSample}
                    >
                        Download csv template
                    </Button>
                    <Button style={{float: "right"}} onClick={closeDialog}>Cancel</Button>
                </DialogContent>
                <Snackbar open={alertOpen} autoHideDuration={3000} anchorOrigin={{vertical: "top", horizontal: "right"}} onClose={hideSnackbar}>
                    <Alert severity={alertSeverity}>{alertMessage}</Alert>
                </Snackbar>
            </Dialog>

            <Dialog open={confirmationOpen} maxWidth="md" onClose={() => setConfirmationOpen(false)}>
                <DialogTitle className="external-user-dialog-formatting">
                    <div className="external-user-dialog-header-title">
                        <Typography variant="h2">Bulk Import New Sparrow Users</Typography>
                        <div style={{position: "relative", top: "4px", marginRight: "auto"}}>
                            <InfoHover>
                                Sparrow Users are accounts directly provided, managed and created by our system. These users do not require external providers to login.
                            </InfoHover>
                        </div>
                        <IconButton onClick={(e) => setConfirmationOpen(false)} size="large">
                            <CloseIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent>
                    {loading
                    ? <div>
                        {displayWarning 
                            ? <Disclaimer
                                icon={<InfoOutlinedIcon />}
                                text={
                                <React.Fragment>
                                    <div>
                                        <span>Due to the volume of users in attached CSV, this may take a while.</span>
                                    </div>
                                </React.Fragment>
                                }
                            />
                            : null}
                        <Loading />
                    </div>
                    : <div>
                        <div className="external-user-dialog-content">
                        <Disclaimer
                            icon={<InfoOutlinedIcon style={{color: "#366531"}}/>}
                            text={
                            <React.Fragment>
                                <div style={{display: "flex", flexDirection: "row"}}>
                                    <b style={{color: "#366531"}}>CSV successfully scanned!&nbsp;</b><span>In case of invalid or duplicate entries, download scan results&nbsp;</span>
                                    {reportLoading
                                        ? <div style={{position: "relative", top: "-3px", left: "4px"}}><LoadingSpinner size="12px"/></div>
                                        : <u style={{cursor: "pointer", color: "#6795BC"}} onClick={downloadCSVReport}>here.</u>}
                                </div>
                            </React.Fragment>
                            }
                        />

                        <div style={{display: "flex", flexDirection: "row", paddingTop: "20px", paddingBottom: "20px", justifyContent: "center"}}>
                            <UserInsightsBlockTiny stats={valid} title='Valid user profiles'/>
                            <UserInsightsBlockTiny stats={invalid} title='Invalid user profiles'/>
                            <UserInsightsBlockTiny stats={duplicate} title='Duplicate entries'/>
                        </div>

                        <div style={{fontSize: "14px", paddingBottom: "20px"}}>
                            <strong>Quick Reminders:</strong>
                            <ul>
                                <li>Only valid user profiles will be added and invited.</li>
                                <li>Users with existing emails or mobile numbers in your CSV are skipped.</li>
                                <li>Invalid user profiles are rows in your CSV which are missing required fields, or have invalid emails.</li>
                            </ul>
                            To proceed and complete this process, click Finish.
                        </div>

                        <div style={{paddingTop: "20px"}}>
                            <Button style={{float: "left"}} color="primary" startIcon={<BackArrow fontSize="small"/>} onClick={swapToUpload}>Replace CSV file</Button>
                            <Button style={{float: "right", marginLeft: "10px"}} color="primary" variant="contained" onClick={uploadFileAndCreateUsers}>Finish</Button>
                            <Button style={{float: "right"}} onClick={(e) => setConfirmationOpen(false)}>Cancel</Button>
                        </div>
                        </div>
                    </div>
                    }
                </DialogContent>
            </Dialog>

            <Dialog open={resultsOpen} onClose={() => setResultsOpen(false)}>
                <DialogTitle className="external-user-dialog-formatting">
                    <div className="external-user-dialog-header-title">
                        <Typography variant="h2">{sending ? "Inviting users..." : "Successfully sent!"}</Typography>
                        <IconButton onClick={() => setResultsOpen(false)} size="large">
                            <CloseIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent className="external-user-dialog-content" style={{paddingBottom: "20px"}}>
                        {sending ?
                            <Loading />
                        : <div>
                            <div>
                                <span style={{fontSize: "14px"}}>You've successfully send invites to <strong>{uploadedUsers}</strong> user{uploadedUsers !== 1 ? "s" : ""}! <br/>
                                The invitees are required to accept the invitation, and use the link to activate their account.</span>
                                <br/>
                                <a style={{float: "left"}} href="https://support.sparrowconnected.com/en/after-adding-new-sparrow-users" target="_blank" rel="noopener noreferrer">Learn what's next</a>
                            </div>
                            <div style={{paddingTop: "30px"}}>
                                <Button onClick={swapToUserInvites} style={{float: "right", marginLeft: "6px"}} color="primary" variant="contained">Manage User Invites</Button>
                                <Button onClick={() => setResultsOpen(false)} style={{float: "right"}}>Back to users & permissions</Button>
                            </div>
                        </div>
                        }
                </DialogContent>
                <Snackbar open={alertOpen} autoHideDuration={3000} anchorOrigin={{vertical: "top", horizontal: "right"}} onClose={hideSnackbar}>
                    <Alert severity={alertSeverity}>{alertMessage}</Alert>
                </Snackbar>
            </Dialog>
        </React.Fragment>
    );
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
      ...ownProps,
    }),
    {
    },
);

type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(BulkLocalDialog);