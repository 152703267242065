import React, { useCallback, useEffect, useRef } from "react";
import { List, ListItem } from "@mui/material";

import { useDragReorder } from "modules/common/hooks/useDragReorder";
import Loading from "../loading";
import { IContentSearchResultItem } from "./contentSearch";
import { ContentSearchResultItem } from "./contentSearchResultItem";
import uuid from "uuid";

export interface IContentSearchResultsProps {
    action?: (item: IContentSearchResultItem) => JSX.Element;
    onClick?: (item: IContentSearchResultItem) => void;
    onDrag?: (id: string) => void;
    onDrop?: (id: string) => void;
    draggedIdx?: number | null;
}

interface IContentSearchResultProps extends IContentSearchResultsProps {
    results: IContentSearchResultItem[];
    pageNumber?: number;
    pageCount?: number;
    isLoading?: boolean;
    emptyMessage: string;
    draggable?: boolean;
    onPage?: () => Promise<void>;
}

export const ContentSearchResultList: React.FunctionComponent<IContentSearchResultProps> = ({
    results,
    pageNumber,
    pageCount,
    isLoading,
    emptyMessage,
    draggable = false,
    draggedIdx,
    action,
    onPage,
    onDrag,
    onDrop,
    onClick,
}) => {
    const dropZoneClassName = useRef<string>(uuid());
    const isDragging = draggedIdx !== null && draggedIdx !== undefined && draggedIdx !== -1;
    const { mousePos, dropIdx } = useDragReorder<string>({
        enabled: draggable,
        items: results,
        draggedIdx: isDragging ? draggedIdx : null,
        dropZoneClassName: dropZoneClassName.current,
        onDrop: onDrop || null,
    });

    const handleIntersect = useCallback(
        (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
            entries.forEach((entry: IntersectionObserverEntry) => {
                if (entry.intersectionRatio > 0.1 && onPage) {
                    onPage();
                    observer.disconnect();
                }
            });
        },
        [onPage]
    );

    const getItemId = (item: IContentSearchResultItem): string => `cs-result-${item.id}`;

    // ea time second last item on "page" is visible, do another search request
    // this uses Intsersection Observer api, more here: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
    useEffect(() => {
        let observer: IntersectionObserver | null;

        if (pageNumber && pageCount) {
            // calc if there might be more search results
            const isPagesFull: boolean = pageNumber * pageCount === results.length;

            if (isPagesFull) {
                let options = {
                    root: null,
                    rootMargin: "0px",
                    threshold: 0.9,
                };

                observer = new IntersectionObserver(handleIntersect, options);

                let secondLastIdx = pageNumber * pageCount - 2;

                if (secondLastIdx > -1 && secondLastIdx < results.length) {
                    let secondLastItem = results[secondLastIdx];

                    let domEl = document.getElementById(getItemId(secondLastItem));

                    if (domEl) observer.observe(domEl);
                }
            }
        }

        return () => {
            if (observer) observer.disconnect();
        };
    }, [pageNumber, pageCount, results, handleIntersect]);

    // isDragging ensures draggedIdx is a number
    const floatingResult = isDragging ? results[draggedIdx!] : null;
    const floatingId = isDragging && floatingResult ? getItemId(floatingResult) : "";

    return (
        <List disablePadding sx={{ width: "100%" }}>
            {results.length > 0 ? (
                <>
                    {isDragging && floatingResult && (
                        // z-index to make sure floating item displays above drawer and other items
                        <div style={{ zIndex: 1400, position: "absolute" }}>
                            <ContentSearchResultItem
                                id={floatingId}
                                key={floatingId}
                                item={floatingResult}
                                onClick={onClick}
                                onDrag={onDrag}
                                onDrop={onDrop}
                                action={action}
                                draggedIdx={draggedIdx}
                                idx={draggedIdx!}
                                mousePos={mousePos}
                            />
                        </div>
                    )}
                    {draggable && (
                        <div className={`drop-zone ${dropZoneClassName.current} ${isDragging && dropIdx === 0 ? "" : "hidden"}`} />
                    )}
                    {results.map((result: IContentSearchResultItem, idx: number) => {
                        const id = getItemId(result);

                        return (
                            <React.Fragment key={id}>
                                {draggedIdx !== idx && (
                                    <>
                                        <ContentSearchResultItem
                                            id={id}
                                            key={id}
                                            item={result}
                                            onClick={onClick}
                                            onDrag={draggable ? onDrag : undefined}
                                            onDrop={draggable ? onDrop : undefined}
                                            action={action}
                                            draggedIdx={draggedIdx}
                                            idx={idx}
                                            mousePos={mousePos}
                                        />
                                        {draggable && (
                                            <div
                                                className={`drop-zone ${dropZoneClassName.current} ${
                                                    isDragging && dropIdx === idx + 1 ? "" : "hidden"
                                                }`}
                                            />
                                        )}
                                    </>
                                )}
                            </React.Fragment>
                        );
                    })}
                </>
            ) : (
                <> {!isLoading && <ListItem className="empty-message">{emptyMessage}</ListItem>}</>
            )}
            {isLoading && (
                <ListItem sx={{ justifyContent: "center" }}>
                    <Loading padding={12} />
                </ListItem>
            )}
        </List>
    );
};
