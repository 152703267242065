import React from "react";

import Paper from "@mui/material/Paper";
import MuiTab from "@mui/material/Tab";
import MuiTabs from "@mui/material/Tabs";
import { Theme } from "@mui/material/styles";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabNavigation: {
      alignItems: "center",
      display: "flex",
      height: 48,
      justifyContent: "space-between",
      position: "sticky",
      top: 0,
      zIndex: 3
    },
    tabItem: {
      textTransform: "none",
      minWidth: 160
    },
    tabDottedItem: {
      paddingLeft: 28
    },
    tabDottedLabel: {
      position: "relative",
      width: "fit-content",
      "&::before": {
        content: `""`,
        borderRadius: "50%",
        height: 8,
        left: -16,
        marginTop: 4,
        position: "absolute",
        width: 8
      },
      "&.green::before": {
        backgroundColor: "#3b6531"
      },
      "&.grey::before": {
        backgroundColor: "#adb7c1"
      },
      "&.yellow::before": {
        backgroundColor: "#e9b848"
      },
      "&.red::before": {
        backgroundColor: "#ff0000"
      },
    },
    tabOptions: {
      position: "relative",
      "& button": {
        marginRight: 15,
        "&:first-child": {
          marginLeft: 15
        }
      }
    }
  })
);


const Tabs: React.FunctionComponent<ComponentProps> = props => {
  const classes = useStyles();

  return (
    <Paper square className={classes.tabNavigation}>
      <MuiTabs
        value={props.selectedTab}
        indicatorColor="primary"
        textColor="primary"
        onChange={(ev, tabIndex) => props.onSelectTab(tabIndex)}
      >
        {props.tabs.map((tab, index) => (
          <MuiTab
            key={index}
            label={
              <span className={ tab.dotColor ? `${classes.tabDottedLabel} ${tab.dotColor}`: "" }>
              {tab.label}
              </span>
            }
            value={tab.value}
            className={`${classes.tabItem} ${tab.dotColor ? classes.tabDottedItem : ""}`}
          />
        ))}
      </MuiTabs>
      {props.tabOptions &&
        <div className={classes.tabOptions}>
          {props.tabOptions}
        </div>
      }
    </Paper>
  );
}


export interface Tab {
  dotColor?: "green" | "grey" | "yellow" | "red";
  label: string;
  value?: any;
}

interface ComponentProps {
  tabs: Tab[];
  tabOptions?: JSX.Element;
  selectedTab: number | any;
  onSelectTab: (tab: number | any) => void;
  className?: string;
}

export default Tabs;
