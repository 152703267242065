import React, { Dispatch } from "react";
import { Editor as IEditor} from "tinymce";

export const handleTinyText = (setText: Dispatch<string>, setPlaintext: Dispatch<string>, setCount: Dispatch<number>, editor: IEditor) => {
    setText(editor.getContent())
    setPlaintext(editor.getContent({ format: 'text' }))

    setCount(editor.getContent({ format: 'text' }).length + ((editor.getContent().match(/<img id="inserted-tenor-gif"/g) ||
        editor.getContent().match(/<img id="inserted-image"/g)) || []).length);
};

export const addGif = (result: any, setPlaintext: Dispatch<string>, setGifsVisible: Dispatch<boolean>, editorRef: React.MutableRefObject<IEditor | null>, gifRef: any) => {
    if (!editorRef.current) return;

    editorRef.current.insertContent('<img id="inserted-tenor-gif" style="max-width: 200px; max-height: 150px; display: inline-block" src=' + result.media[0].tinygif.url + ' alt="gif inserted from tenor"/>');
    if (editorRef.current.getContent({ format: 'text' }).trim().length === 0) {
        setPlaintext("📷 GIF");
    }

    gifRef.current.setActive(false)
    editorRef.current.options.set('tenor_visible', false);
    setGifsVisible(false);
};