import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import { push } from "react-router-redux";

import Button from "@mui/material/Button";

import AddIcon from "@mui/icons-material/Add";


const NewDocument: React.FunctionComponent<PropsWithRedux> = props => {
  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={<AddIcon />}
      onClick={() => props.redirectTo("/" + props.tenant + "/admin/documents/upload")
    }>
      New Document
    </Button>
  );
}

interface ComponentProps {}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    tenant: state.tenant.id
  }),
  {
    redirectTo: push
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(NewDocument);