import { imageExtFromMimeType } from "modules/gallery/chonkyFileAdapter";
import { AttachedFile, CustomFileData } from "modules/gallery/models";

export const chonkyCustomDataToAttachedFile = (image: CustomFileData): AttachedFile => {
    return {
        blobId: image.blobId ?? image.id,
        fileExtension: imageExtFromMimeType(image.mimeType),
        fileName: image.name,
        fileType: "image",
        fileUrl: image.thumbnailUrl,
        ospreyId: image.id
    } as AttachedFile
}