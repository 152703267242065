import React, { useEffect } from 'react';
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import { Button, Dialog, DialogContent, DialogTitle, Divider, FormControl, IconButton, MenuItem, Select, Switch, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowIcon from '@mui/icons-material/ArrowForwardIos';
import OpenNewIcon from '@mui/icons-material/OpenInNew';
import { actions, InvitedUser, InvitePlatform } from 'modules/users';
import { usersApi } from 'api/instances';
import Disclaimer from 'modules/common/components/disclaimer';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InfoHover from 'modules/common/components/hovers/infoHover';
import { emailRegex } from "utils/regexUserValidation";
import { USER_ROLE_DESCRIPTIONS } from 'modules/authorization/models';


interface ComponentProps {
    isOpen: boolean;
    closeDialog: () => void;
    swapDialogs: () => void;
    closeReport: () => void;
    bulkClicked: () => void;
    setLoading: (loading: boolean) => void;
    setUploadFailed: (status: boolean) => void;
    updateSparrowUser: (name: string, email: string) => void;
}

const specialCharacterRegex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/; //eslint-disable-line

const LocalUserDialog: React.FC<PropsWithRedux> = ({isOpen, closeDialog, inviteSparrowUser, swapDialogs, bulkClicked, setLoading, setUploadFailed, updateSparrowUser}) => {
    const [optionalFieldsOpen, setOptionalFieldsOpen] = React.useState(false);
    //const [customFieldsOpen, setCustomFieldsOpen] = React.useState(false);
    const [permissionsOpen, setPermissionsOpen] = React.useState(false);

    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [email, setEmail] = React.useState("");

    const [errorFirstName, setErrorFirstName] = React.useState(false);
    const [errorLastName, setErrorLastName] = React.useState(false);
    const [errorEmail, setErrorEmail] = React.useState(false);
    const [errorEmailMessage, setErrorEmailMessage] = React.useState("");

    const [phoneNumber, setPhoneNumber] = React.useState("");
    const [title, setTitle] = React.useState("");
    const [department, setDepartment] = React.useState("");
    const [reportsTo, setReportsTo] = React.useState("");
    const [country, setCountry] = React.useState("");
    const [region, setRegion] = React.useState("");
    const [nickname, setNickname] = React.useState("");

    const [errorPhoneNumber, setErrorPhoneNumber] = React.useState(false);

    const [roleSelected, setRoleSelected] = React.useState("user");
    const [isSubManager, setIsSubManager] = React.useState(false);

    let errorsInFormatting = errorPhoneNumber
    const isUserValid = firstName !== "" && lastName !== "" && email !== "" && !errorFirstName && !errorLastName && !errorEmail && errorEmailMessage === "";

    //Reset error values when the component is re-opened
    useEffect(() => {
        if(isOpen) {
            setErrorEmail(false);
            setErrorFirstName(false);
            setErrorLastName(false);
            setErrorPhoneNumber(false);
            setRoleSelected("user");
            setOptionalFieldsOpen(false);
            setPermissionsOpen(false);
        }
    }, [isOpen])

    //Only check the inputted email after they stop typing for .5 seconds.
    useEffect(() => {
        const interval = setTimeout(() => {
            if (!email.match(emailRegex) || !validateEmail(email)) {
                setErrorEmail(true);
                setErrorEmailMessage("Enter valid email address.")
            }
            else {
                usersApi.CheckIfEmailAvailable(email)
                .then((response) => {
                    if (response) {
                        setErrorEmail(false)
                        setErrorEmailMessage("")
                    } else {
                        setErrorEmail(true)
                        setErrorEmailMessage("Email address is already taken.")
                    }
                })
            }
        }, 500);

        return () => clearInterval(interval);
    }, [email]);

    const validateEmail = (email: string) => {
        if(specialCharacterRegex.test(email.charAt(0)) || specialCharacterRegex.test(email.charAt(email.length - 1))) {
            return false;
        }
        return true;
    }

    let updateNumber = (e) => {
        if(e.target.value.match(/^[0-9\+\-\(\)]*$/)) //eslint-disable-line
            setPhoneNumber(e.target.value);
    }

    const updateEmail = (newValue: string) => {
        setEmail(newValue);
    }

    const verifyAndSubmitUser = async() => {
        var validToUpload = true;

        if(firstName === "") {
            setErrorFirstName(true);
            validToUpload = false;
        }

        if(lastName === "") {
            setErrorLastName(true);
            validToUpload = false;
        }

        //Email validation handled elsewhere.

        var submissionManager = false;

        var userRoles:string[] = [];
        if(roleSelected === "author" || roleSelected === "owner")
            userRoles.push("role_author")

        if(roleSelected === "owner") {
            userRoles.push("role_owner")
        }

        if(userRoles.length > 0 && isSubManager)
            submissionManager = true;

        let number: string = (phoneNumber || "").replace(/[^0-9]/g, "");

        if (number.length < 10 && phoneNumber !== "") {
            setErrorPhoneNumber(true);
            validToUpload = false;
        }

        const countryCode: string = `+${!!number.slice(0, -10).length ? number.slice(0, -10) : "1"} `;
        let formattedNumber = countryCode + "(" + number.slice(-10, -7) + ") " + number.slice(-7, -4) + "-" + number.slice(-4);

        if(validToUpload) {
            let toSend:InvitedUser = {
                inviteType: InvitePlatform.Sparrow,
                enabled: true, 
                email: email.toLowerCase(),
                smsPhone: phoneNumber === "" ? "" : formattedNumber,
                userDetails: {
                    firstName: firstName,
                    lastName: lastName,
                    title: title,
                    department: department,
                    reportsTo: reportsTo,
                    country: country,
                    region: region,
                    preferredName: nickname,
                    roles: userRoles,
                    canManageSubmissions: submissionManager
                },
            }

            swapDialogs();
            setUploadFailed(false); //Reset value to false before making request.
            var result = await inviteSparrowUser([toSend]);

            //Check if call failed
            if(result && result.toString() !== "200") {
               setUploadFailed(true);
            }
            else {
                updateSparrowUser(firstName+" "+lastName, email);
            }
            clearUserData();
            setLoading(false);
        }
    }

    const clearUserData = () => {
        setLastName("");
        setFirstName("");
        setEmail("");
        setPhoneNumber("");
        setTitle("");
        setDepartment("");
        setReportsTo("");
        setNickname("");
        setCountry("");
        setRoleSelected("user");
    }

    const onRoleChange = (e) => {
        setRoleSelected(e.target.value)
    }

    return (
        <React.Fragment>
            <Dialog open={isOpen} onClose={closeDialog} maxWidth={"md"} fullWidth={true}>
                <DialogTitle className="external-user-dialog-formatting">
                    <div className="external-user-dialog-header-title">
                        <Typography variant="h2">Add New Sparrow User</Typography>
                        <div style={{position: "relative", top: "4px", marginRight: "auto"}}>
                            <InfoHover>
                                Sparrow Users are accounts directly provided, managed and created by our system. These users do not require external providers to login.
                            </InfoHover>
                        </div>
                        <IconButton onClick={closeDialog} size="large">
                            <CloseIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent className="external-user-dialog-content">
                    <div style={{maxHeight: "600px", minHeight: "600px", overflowY: "auto", paddingLeft: "10px", paddingRight: "10px"}}>
                        <div className='local-fields-formatting'>
                            <div>
                                <span style={{float: "left"}}>First name<span style={{color: "#B72026", top: "0px"}}>*</span></span>
                                <TextField
                                error={errorFirstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                onClick={() => setErrorFirstName(false)}
                                placeholder="Enter first name"
                                className="textfield-formatting"
                                variant="outlined"
                                size="small"/>
                            </div>

                            <div>
                                <span style={{float: "left"}}>Last name<span style={{color: "#B72026", top: "0px"}}>*</span></span>
                                <TextField
                                error={errorLastName}
                                onChange={(e) => setLastName(e.target.value)}
                                onClick={() => setErrorLastName(false)}
                                placeholder="Enter last name"
                                className="textfield-formatting"
                                variant="outlined"
                                size="small"/>
                            </div>

                            <div>
                                <span style={{float: "left"}}>Email address<span style={{color: "#B72026", top: "0px"}}>*</span></span>
                                <div style={{display: "flex", flexDirection: "column"}} className="textfield-formatting">
                                    <TextField
                                        onChange={(e) => updateEmail(e.target.value)}
                                        onClick={() => setErrorEmail(false)}
                                        error={errorEmail}
                                        placeholder="Ex. alan.smith@example.com"
                                        variant="outlined"
                                        helperText={errorEmailMessage}
                                        size="small"
                                    />
                                </div>
                            </div>
                        </div>

                        <Divider/>

                        <div
                            style={{cursor: "pointer", height: "70px", display: "flex", alignItems: "center", minWidth: "500px", maxWidth: "880px"}}
                            onClick={() => setOptionalFieldsOpen(!optionalFieldsOpen)}
                        >
                            <b style={{fontWeight: 500}}>Standard profile fields (optional) </b>{(!optionalFieldsOpen && errorsInFormatting) ? <b> - Errors inside</b> : null}
                            { optionalFieldsOpen ? <ArrowIcon style={{transform: "rotate(90deg)", marginLeft: "auto", color: "#707070"}}/> : <ArrowIcon style={{transform: "rotate(270deg)", marginLeft: "auto", color: "#707070"}}/>}
                        </div>

                        <Divider/>
                        {optionalFieldsOpen &&
                            <div className='local-fields-formatting'>
                                <div>
                                    <span style={{float: "left"}}>Mobile no.</span>
                                    <TextField onClick={(e) => setErrorPhoneNumber(false)} onChange={updateNumber} error={errorPhoneNumber} placeholder='Ex. +1-123-867-5309' className="textfield-formatting" variant="outlined" size="small"/>
                                </div>

                                <div>
                                    <span style={{float: "left"}}>Title</span>
                                    <TextField onChange={(e) => setTitle(e.target.value)} placeholder='Enter role or title' className="textfield-formatting" variant="outlined" size="small"/>
                                </div>

                                <div>
                                    <span style={{float: "left"}}>Department</span>
                                    <TextField onChange={(e) => setDepartment(e.target.value)} placeholder='Enter department' className="textfield-formatting" variant="outlined" size="small"/>
                                </div>

                                <div>
                                    <span style={{float: "left"}}>Reports to</span>
                                    <TextField onChange={(e) => setReportsTo(e.target.value)} placeholder='Enter name' className="textfield-formatting" variant="outlined" size="small"/>
                                </div>

                                <div>
                                    <span style={{float: "left"}}>Country</span>
                                    <TextField onChange={(e) => setCountry(e.target.value)} placeholder='Enter country' className="textfield-formatting" variant="outlined" size="small"/>
                                </div>

                                <div>
                                    <span style={{float: "left"}}>Region</span>
                                    <TextField onChange={(e) => setRegion(e.target.value)} placeholder='Enter region, city, or province' className="textfield-formatting" variant="outlined" size="small"/>
                                </div>

                                <div>
                                    <span style={{float: "left"}}>Preferred Name</span>
                                    <TextField onChange={(e) => setNickname(e.target.value)} placeholder='Enter preferred name' className="textfield-formatting" variant="outlined" size="small"/>
                                </div>

                                <Divider/>
                            </div>
                        }

                        {/* Placeholder for optional profile stuff.
                        <div
                            style={{cursor: "pointer", height: "70px", display: "flex", alignItems: "center", minWidth: "500px", maxWidth: "880px"}}
                            onClick={() => setOptionalFieldsOpen(!optionalFieldsOpen)}
                        >
                            <div style={{display: "flex", flexDirection: "column"}}>
                                <b>Custom profile fields (optional)</b>
                                <span>Profile fields customized by your organization</span>
                            </div>
                            { optionalFieldsOpen ? <ArrowIcon style={{transform: "rotate(90deg)", marginLeft: "auto"}}/> : <ArrowIcon style={{transform: "rotate(270deg)", marginLeft: "auto"}}/>}
                        </div>

                        <Divider/>
                        */}

                        <div
                            style={{cursor: "pointer", height: "70px", display: "flex", alignItems: "center", minWidth: "500px", maxWidth: "880px"}}
                            onClick={() => setPermissionsOpen(!permissionsOpen)}
                        >
                            <div style={{display: "flex", flexDirection: "column"}}>
                                <b style={{fontWeight: 500}}>Roles</b>
                                <span>Set more roles for this user</span>
                            </div>
                            { permissionsOpen ? <ArrowIcon style={{transform: "rotate(90deg)", marginLeft: "auto", color: "#707070"}}/> : <ArrowIcon style={{transform: "rotate(270deg)", marginLeft: "auto", color: "#707070"}}/>}
                        </div>

                        <Divider/>

                        {permissionsOpen &&
                            <div style={{paddingBottom: "20px"}}>
                                <div style={{display: "flex", flexDirection: "row", paddingBottom: "15px"}}>
                                    <span style={{width: "230px", position: "relative", top: "14px"}}>Give permissions of a </span>
                                    <FormControl size='small' fullWidth style={{paddingTop: "6px"}}>
                                        <Select
                                            fullWidth
                                            variant='outlined'
                                            onChange={onRoleChange}
                                            value={roleSelected}
                                            MenuProps={{
                                                anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left"
                                                },
                                                transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left"
                                            }}}
                                        >
                                            <MenuItem key={"user"} value={"user"}>User</MenuItem>
                                            <MenuItem key={"author"} value={"author"}>Author</MenuItem>
                                            <MenuItem key={"owner"} value={"owner"}>Owner</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>

                                <div>
                                <Disclaimer
                                    icon={<InfoOutlinedIcon />}
                                    text={
                                    <React.Fragment>
                                        {roleSelected === "user" &&
                                            <span>{USER_ROLE_DESCRIPTIONS.INTERNAL}</span>
                                        }

                                        {roleSelected === "author" &&
                                            <span>{USER_ROLE_DESCRIPTIONS.AUTHOR}</span>
                                        }

                                        {roleSelected === "owner" &&
                                            <span>{USER_ROLE_DESCRIPTIONS.OWNER}</span>
                                        }
                                    </React.Fragment>
                                    }
                                />
                                </div>

                                {(roleSelected === "author" || roleSelected === "owner") &&
                                    <div style={{paddingTop: "15px"}}>
                                        <div>
                                            <span style={{paddingTop: "6px"}}>Assign as a Submission Manager</span>
                                            <FormControl style={{float: "right", position: "relative", top: "-7px"}}>
                                                <Switch size='medium' onChange={(e) => setIsSubManager(e.target.checked)}/>
                                            </FormControl>
                                        </div>
                                        <span className='description-text'>Notify this {roleSelected} when a post is submitted by a Contributor.</span>
                                    </div>
                                }
                            </div>
                        }
                    </div>


                </DialogContent>
                <Divider style={{marginBottom: "10px"}}/>
                <div style={{paddingBottom: "10px", paddingRight: "5px", paddingLeft: "5px"}}>
                    <Button style={{float: "left"}} onClick={bulkClicked} color="primary" endIcon={<OpenNewIcon/>}>Bulk Import Sparrow Users</Button>
                    <Button
                        onClick={verifyAndSubmitUser}
                        style={{float: "right", marginLeft: "6px"}}
                        color="primary"
                        variant="contained"
                        disabled={!isUserValid}
                    >
                        Finish
                    </Button>
                    <Button onClick={closeDialog} style={{float: "right"}}>Cancel</Button>
                </div>
            </Dialog>
        </React.Fragment>
    );
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
      ...ownProps,
    }),
    {
        inviteSparrowUser: actions.inviteSparrowUsers,
    },
);

type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(LocalUserDialog);
