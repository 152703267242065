import * as Actions from './actions';
import { Action } from 'redux';
import { PostsState, EditorState } from '../posts/models';
import { EmptyDefaultSupportItem } from './models';

export const actionHandler: { [actionType: string]: (state: PostsState, action: Action) => PostsState } = {
    
    [Actions.SET_UPLOADING_DOC]: (state: PostsState, action: Actions.SetUploadingDoc) => {
        return {
            ...state,
            documentDirectory: {
                ...state.documentDirectory,
                uploading: action.uploading
            }
        }
    },

    [Actions.UPLOAD_DOCUMENT]: (state: PostsState, action: Actions.UploadDocument) => {
        return { ...state, documentDirectory: { ...state.documentDirectory, uploading: true } }
    },

    [Actions.UPLOAD_DOCUMENT_COMPLETE]: (state: PostsState, action: Actions.UploadDocumentComplete) => {
        return { ...state, documentDirectory: { ...state.documentDirectory }, shouldFetch: false }
    },

    [Actions.CREATE_NEW_SUPPORT_ITEM]: (state: PostsState, action: Actions.CreateNewSupportItem) => {
        return { ...state, creatingNewPost: true }
    },

    [Actions.CREATE_NEW_ITEM_COMPLETE]: (state: PostsState, action: Actions.CreateNewItemComplete) => {
        return { ...state, creatingNewPost: false }
    },

    [Actions.FETCH_SUPPORT_ITEM]: (state: PostsState, action: Actions.FetchSupportItem) => {
        let editorState = {
            ...state.editor,
            supportItem: { },
            fetching: true,
        } as EditorState;

        return { ...state, editor: editorState }
    },

    [Actions.FETCH_SUPPORT_ITEM_COMPLETE]: (state: PostsState, action: Actions.FetchSupportItemComplete) => {
        
        let itemDefaults = {...EmptyDefaultSupportItem};
        let stringProperties = Object.keys(itemDefaults).filter(k => typeof itemDefaults[k] === 'string')
       
        let translatedContent = (!!action.item && action.item.translatedContent) ? action.item.translatedContent : 
             (state.editor.supportItem?.translatedContent || itemDefaults.translatedContent);
        
             let editorState = {
            ...state.editor,
            supportItem: { ...action.item, translatedContent: {...translatedContent} },
            fetching: false,
        } as EditorState;

        // dont allow possible null values in the editor; default to empty string
        stringProperties.forEach((value, index) => {
            if(editorState.supportItem[value] == null)
                editorState.supportItem[value] = ""
        });

        let tc = editorState.supportItem.translatedContent;
        for(let lang of Object.keys(tc || {})){
            for(let field of Object.keys(tc[lang])){
                if(tc[lang][field] === null)
                    tc[lang][field] = ''
            }
        }

        return { ...state, editor: editorState }
    },

    [Actions.SAVE_SUPPORT_ITEM]: (state: PostsState, action: Actions.SaveSupportItem) => {
        return { ...state, editor: { ...state.editor, saving: true } };
    },

    [Actions.SAVE_SUPPORT_ITEM_COMPLETE]: (state: PostsState, action: Actions.SaveSupportItemComplete) => {
        return { ...state, editor: { ...state.editor, saving: false, changedSinceSaved: false } };
    },

    [Actions.DELETE_SUPPORT_ITEM]: (state: PostsState, action: Actions.DeleteSupportItem) => {
        return {
            ...state,
            editor: {
                ...state.editor,
                deleting: true
            } as EditorState
        };
    },

    [Actions.DELETE_SUPPORT_ITEM_COMPLETE]: (state: PostsState, action: Actions.DeleteSupportItemComplete) => {
        return {
            ...state,
            editor: {
                ...state.editor,
                deleting: false
            } as EditorState
        };
    },

    [Actions.SUPPORT_EDIT_FIELD_UPDATE]: (state: PostsState, action: Actions.FieldUpdate) => {
        return { 
            ...state, 
            editor: { 
                ...state.editor, 
                changedSinceSaved: true, 
                supportItem: {
                    ...state.editor.supportItem,
                    [action.field]: action.value
                }
            } 
        };
    },

    [Actions.PUBLISH_SUPPORT_ITEM]: (state: PostsState, action: Actions.PublishSupportItem) => {
        return {
            ...state,
            publishing: true,
            publishValidationErrors: { errors: {}, title: null, id: null }
        }
    },

    [Actions.PUBLISH_SUPPORT_ITEM_COMPLETE]: (state: PostsState, action: Actions.PublishSupportItemComplete) => {
        let publishValidationErrors = (!action.succeeded && action.validationResult) ? {
            id: action.validationResult.id!,
            title: action.validationResult.itemTitle!,
            errors: { ...action.validationResult.errors },
        } : {id: null, title: null, errors: {}};
        
        return {
            ...state,
            publishValidationErrors,
            publishing: false,
            editor: {
                ...state.editor,
                changedSinceSaved: false
            }
        };
    },

    [Actions.PUBLISH_SUCCESS]: (state: PostsState, action: Actions.PublishSuccess) => {
        return {
            ...state,
            publishConfirmation: {
                shouldShowDialog: true,
            }
        } as PostsState
    },

    [Actions.UPLOAD_FILES]: (state: PostsState, action: Actions.UploadFiles) => {
        return { ...state, imageLibrary: { ...state.imageLibrary, uploading: true } }
    },

    [Actions.UPLOAD_FILES_COMPLETE]: (state: PostsState, action: Actions.UploadFilesComplete) => {
        return { ...state, imageLibrary: { ...state.imageLibrary }, shouldFetch: false }
    },

    [Actions.SUPPORT_ASSOCIATED_LINKS_UPDATE]: (state: PostsState, action: Actions.SupportAssociatedLinksUpdate) => {

        let previousLinks = state.editor.supportItem.associatedLinks || []
        let nextLinks = action.associatedLink || []
        return { 
            ...state, 
            editor: { 
                ...state.editor, 
                changedSinceSaved: true, 
                supportItem: {
                    ...state.editor.supportItem,
                    associatedLinks: [...previousLinks, nextLinks]
                }
            } 
        };
    },

    [Actions.REMOVE_ASSOCIATED_LINK]:  (state: PostsState, action: Actions.RemoveAssociatedLink) => {
        let previousLinks = state.editor.supportItem.associatedLinks || []

        return { 
            ...state, 
            editor: { 
                ...state.editor, 
                changedSinceSaved: true, 
                supportItem: {
                    ...state.editor.supportItem,
                    associatedLinks: previousLinks.filter(a => a.blobId !== action.id)
                }
            } 
        };
    },

    [Actions.SET_ACTIVE_SUPPORT_TAB]: (state: PostsState, action: Actions.SetActiveSupportTab) => {
        return {
            ...state,
            activeSupportTab: action.activeTab
        }
    },
    
    [Actions.CLEAR_VALIDATION_ERRORS]: (state: PostsState, action: Actions.ClearValidationErrors) => {
        return {
            ...state,
            publishValidationErrors: { errors: {}, title: null, id: null }
        }
    }
}

export const reducer = (state: PostsState, action: Action) => {

    const actionHandlerMethod = actionHandler[action.type];
    if (actionHandlerMethod) {
        return actionHandlerMethod(state, action);
    }

    return state || {} as PostsState;
};
