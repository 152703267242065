import React from "react";

import { Theme } from "@mui/material/styles";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      margin: 20
    }
  })
);


const TabContent: React.FunctionComponent<ComponentProps> = props => {
  const classes = useStyles();

  return (
    <div id={props.id} className={classes.content}>
      {props.children}
    </div>
  );
}

interface ComponentProps {
  id?: string;
}

export default TabContent;