import * as React from "react";

import { createDevTools } from "redux-devtools";
import LogMonitor from "redux-devtools-log-monitor";
import DockMonitor from "redux-devtools-dock-monitor";
import FilterMonitor from "redux-devtools-filter-actions";

// createDevTools takes a monitor and produces a devTools component
// dockmonitor just provides an area to hide and show the log monitor in.
// log monitor can be placed directly in the application, if we wanted to show it there.
export const DevTools = createDevTools(
    // monitors are individually adjustable with props.
    <DockMonitor toggleVisibilityKey="ctrl-h"
                 changePositionKey="ctrl-q"
                 defaultIsVisible={false}>
        <FilterMonitor blacklist={['POST_EDIT_FIELD_UPDATE']}>
            <LogMonitor theme="tomorrow" />
        </FilterMonitor>
    </DockMonitor>
);