import * as React from "react";
import { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { push } from "react-router-redux";
import * as actions from "../../actionCreator";
import { GlobalApplicationState } from "globalApplicationState";

import Loading from "modules/common/components/loading";
import PageNotFound from "pages/pageNotFound/pageNotFound";
import Page from "../../tinacms/page";

import { NavigationItem } from "../../models";
import { useCustomPortalPageCssContent } from "modules/common/hooks/data/useCustomPortalPagesCssContent";
import { useLoadCustomCssStyles } from "modules/common/hooks/data/useLoadCustomCssStyles";
import useIsMounted from "modules/common/hooks/useIsMounted";

interface PortalPageViewProps {
    portalPageUrl: string;
}

const PortalPageView = ({ portalPageUrl, getNavigationItemByURL }: PropsWithRedux) => {
    const isMounted = useIsMounted();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [navigationItem, setNavigationItem] = useState<NavigationItem | undefined>(undefined);
    const { customCss, fetching } = useCustomPortalPageCssContent(navigationItem?.sourceDraftId || "");
    const { isLoadingStyles } = useLoadCustomCssStyles(customCss);

    useEffect(() => {
        const getNavigationItemByPortalPageURL = async () => {
            try {
                setIsLoading(true);
                const url: string = portalPageUrl || "home";
                const item = await getNavigationItemByURL(url);
                if (isMounted()) {
                    setNavigationItem(item);
                }
            } finally {
                if (isMounted()) {
                    setIsLoading(false);
                }
            }
        };
    
        getNavigationItemByPortalPageURL();
    }, [portalPageUrl, getNavigationItemByURL]);

    if (isLoading || fetching || isLoadingStyles) return <Loading />;
    if (!navigationItem) return <PageNotFound />;
    return <Page data={navigationItem.portalPageJson[0]} />;
};

const connector = connect(
    (state: GlobalApplicationState, ownProps: PortalPageViewProps) => ({
        ...ownProps,
        tenant: state.tenant.id,
        tenantSettings: state.settings.clientSettings,
    }),
    {
        getNavigationItemByURL: actions.getNavigationItemByURL,
        redirectTo: push,
    }
);

type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(PortalPageView);
