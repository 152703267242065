import * as React from "react";

import PostTypeBanner from "modules/common/components/banners/postTypeBanner";

import { PostFeedItem } from "../../../../../models";
import BreakingFeaturedBanner from "../../../../banners/breakingFeaturedBanner";
import QuickPostBanner from "../../../../banners/quickPostBanner";
import PublishTime from "../../dates/PublishTime";
import Stats from "../../stats/Stats";


const Tile: React.FunctionComponent<ComponentProps> = props => {
  const { cardCount, post, preferredLCID, onPostSelected } = props;

  if (!post) {
    return (null);
  }

  const backgroundImageUrl = post.imageUrl || "";
  const selectPost = () => onPostSelected ? onPostSelected(post) : null;

  return (
    <div className={`tile-cell tile-cell-${cardCount}`}>
      <div onClick={selectPost} className="tile">
        <div 
          style={post.isQuickPost ? { backgroundColor: "#000000" } : { 
            backgroundImage: "url('" + backgroundImageUrl + "')", 
            backgroundSize: "contain", backgroundColor: "#DDE1E5" 
          }} 
          className="image"
        >
          <QuickPostBanner {...post} />
          <PostTypeBanner {...props} {...post} lcid={preferredLCID} />
          {!props.hideStats &&
            <Stats {...post} />
          }
          {!props.hidePublishTime &&
            <PublishTime publishTime={post.publishTime} />
          }
          <div title={post.title} className="title">{post.title}</div>
          <div className="summary">
            <BreakingFeaturedBanner {...props} {...post} activeLcid={preferredLCID} />
            {!props.hideSummary &&
              post.excerpt
            }
          </div>
        </div>
        <div className="gradient-overlay"></div>
      </div>
    </div>
  );
}

interface ComponentProps {
  cardCount: number;
  hidePublishTime?: boolean;
  hideStats?: boolean;
  hideSummary?: boolean;
  post: PostFeedItem;
  preferredLCID: string;
  onPostSelected: (post: PostFeedItem) => void;
}

export default Tile;