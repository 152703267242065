//Intention is to replace all of the <ListItemOption> items with this univeral one. 
//Any functionality beyond text and onClick() should be optional, to avoid updating a million files on change.

import * as React from 'react';
import { ListItem, ListItemText } from "@mui/material";

export interface Option {
  text: string;
  disabled?: boolean;
  onClick: (any) => any;
  textStyle?: any;
}

export const MoreOptionsItem: React.FunctionComponent<Option> = props => {
  return (
    <ListItem
      button
      onClick={props.onClick}
      disabled={props.disabled ?? false}
    >
      <ListItemText primary={props.text} style={props.textStyle} />
    </ListItem>
  );
}

export default MoreOptionsItem;