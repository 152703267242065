import React from "react";
import { connect, ConnectedProps } from "react-redux";
import * as actions from "../../actionCreator";
import { GlobalApplicationState } from "../../../../globalApplicationState";

import List from "@mui/material/List";
import confirm from "utils/notyPopups";

import MoreOptionsButton from "../../../../modules/common/components/buttons/moreOptionsButton";
import { Divider } from "@mui/material";
import { CategoryTagDetails, CategoryTagsListItem } from "modules/categorytags";
import MoreOptionsItem from "modules/common/components/moreOptionsItem";
import { FileDownloader } from "utils/fileDownloader";

const MoreOptions: React.FunctionComponent<PropsWithRedux> = props => {
  const getTagForDownload = async (toDownload: CategoryTagDetails) => {
    const file = {
      name: `${props.categoryTag.title}-${new Date().toISOString()}.csv`
    }
    props.downloadReport(toDownload)
      .then((data) => new FileDownloader(file).downloadBlob(data))
  }

  const onDeleteCategoryTag = async (id: string, name: string, ev) => {
    const confirmationMessage: string = "Are you sure you want to delete " + name + "?"
    if (await confirm.show({text: confirmationMessage, title: "Delete Tag"})) {
        props.deleteTag(id);
        //UNSELECT THE TAG JUST IN CASE HERE.
        props.closeDialog();
    }
  }

  return (
    <div style={{paddingTop: "6px", paddingRight: "30px"}}>
      <MoreOptionsButton text="Actions">
        <List disablePadding>
          {props.categoryTag.title !== "Uncategorized" &&
          <MoreOptionsItem
            text="Edit tag details"
            onClick={() => {
              props.closeDialog();
              props.setTagToEdit(props.categoryTag);
              props.editCategoryTag();
            }}
          />
        }
          <Divider light />
          {!props.categoryTag.enabled && props.categoryTag.title !== "Uncategorized" &&
          <MoreOptionsItem
            text="Enable"
            onClick={() => {
              props.closeDialog();
              props.enableTag(props.categoryTag.id);
            }}
          />
          }
          {props.categoryTag.enabled && props.categoryTag.title !== "Uncategorized" &&
            <MoreOptionsItem
            text="Disable"
            onClick={() => {
              props.closeDialog();
              props.disableTag(props.categoryTag.id);
            }}
          />
          }
          {props.categoryTag.title !== "Uncategorized" &&
          <MoreOptionsItem
            text="Delete"
            onClick={(ev) => {
              onDeleteCategoryTag(props.categoryTag.id, props.categoryTag.title, ev);
            }}
          />
          }
          <Divider light />
          <MoreOptionsItem
            text="Download Tag Report"
            onClick={() => {
              getTagForDownload(props.categoryTagAnalytics)
            }}
          />
        </List>
      </MoreOptionsButton>
    </div>
  );
}

interface ComponentProps {
    categoryTag: CategoryTagsListItem,
    categoryTagAnalytics: CategoryTagDetails,
    closeDialog: () => void
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
  }),
  {
    downloadReport: actions.getCategoryTagDownloadFromAnalytics,
    deleteTag: actions.deleteCategoryTag,
    enableTag: actions.enableCategoryTag,
    disableTag: actions.disableCategoryTag,
    setTagToEdit: actions.setTagToEdit,
    editCategoryTag: actions.showEditCategoryTag
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(MoreOptions);