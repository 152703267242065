import React, { useState } from "react";

import { UserRoles } from "modules/authorization/models";
import RequireRole from "modules/common/components/requireRole";
import TenantLink from "modules/common/components/tenantLink";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import { Theme } from "@mui/material/styles";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import ChevronRightIcon from "@mui/icons-material/ChevronRight";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordion: {
      backgroundColor: theme.palette.background.default,
      "&::before": {
        display: "none"
      },
      "&$accordionExpanded": {
        margin: 0
      },
      "& $accordion": {
        backgroundColor: "inherit",
        "& $accordionDetails": {
          borderTop: "1px solid rgba(0, 0, 0, 0.08)",
          marginLeft: 20
        },
        "& $accordionSummary": {
          "&:hover": {
            backgroundColor: theme.palette.background.default
          }
        }
      }
    },
    accordionDetails: {
      backgroundColor: "#e8e8e8",
      display: "block",
      padding: 0
    },
    accordionExpanded: {
      "& $accordionSummary": {
        minHeight: 45
      },
      "& $accordionSummaryContent": {
        margin: 0
      },
      "& > div > $accordionExpandIcon": {
        transform: "rotate(90deg)"
      }
    },
    accordionExpandIcon: {
        color: "#666666"
    },
    accordionSummary: {
      boxSizing: "border-box",
      display: "inline-flex",
      height: 45,
      margin: 0,
      minHeight: 45,
      minWidth: "100%",
      padding: "0 8px 0 0",
      "&:hover $listItemText": {
        fontWeight: 500
      },
      "&.collapsed": {
        display: "block",
        "& $accordionExpandIcon": {
          display: "none"
        },
      },
      "&.selected $accordionExpandIcon": {
        color: theme.palette.secondary.main
      }
    },
    accordionSummaryContent: {
      display: "block",
      margin: 0
    },
    menuItem: {
      position: "relative",
      "& a": {
        color: "inherit",
        textDecoration: "none",
        "&:hover": {
          textDecoration: "none"
        }
      }
    },
    expandableText: {
      opacity: 0,
      overflow: "hidden",
      width: 0,
      "&.expanded": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        opacity: 1,
        transition: "opacity linear 0.1s 0.2s",
        width: "100%"
      },
      "& svg": {
        fill: "#888888",
        marginTop: -3
      }
    },
    listItem: {
      borderLeftColor: "transparent",
      borderLeftStyle: "solid",
      borderLeftWidth: 5,
      height: 45,
      "&:hover": {
        backgroundColor: "inherit",
        "& $listItemText": {
          fontWeight: 500
        }
      },
      "&.selected": {
        borderLeftColor: theme.palette.secondary.main,
        "& $listItemIcon svg": {
          fill: theme.palette.secondary.main
        },
        "& $listItemText": {
          fontWeight: 500
        }
      }
    },
    listItemIcon: {
      minWidth: 36,
      "& svg": {
        fill: "#333333"
      }
    },
    listItemText: {
      color: "#333333",
      fontSize: 14,
      whiteSpace: "nowrap"
    },
    subList: {
      padding: 0,
      "& $listItem": {
        "&:hover": {
          backgroundColor: theme.palette.background.default
        }
      }
    }
  })
);

export interface IMenuItem {
  children?: IMenuItem[];
  endIcon?: JSX.Element;
  hide?: boolean;
  icon?: JSX.Element;
  label: string;
  pushToBrowserHistory?: boolean;
  type: "divider" | "link";
  url?: string;
  userRole?: UserRoles;
  userRoles?: UserRoles[];
  strictRole?: boolean; // for restricting users who are ONLY a certain role instead of having a role via the hierarchy 
}


const AccordionWrapper: React.FunctionComponent<ComponentProps & { setExpandedParentMenuItem?: (childMenuItem: string) => void }> = props => {
  const [expandedMenuItem, setExpandedMenuItem] = useState("");

  const classes = useStyles();

  const { menuItem } = props;

  if (!!menuItem.children && !!menuItem.children.length) {
    const subList: JSX.Element = (
      <List className={classes.subList}>
        <div className={classes.menuItem}>
          {menuItem.children.map((subMenuItem, i) => {
            if (subMenuItem.hide)
              return <React.Fragment key={i}></React.Fragment>;

            return (
              <AccordionWrapper
                key={i}
                {...props}
                expandedMenuItem={expandedMenuItem}
                menuItem={subMenuItem}
                setExpandedMenuItem={setExpandedMenuItem}
                setExpandedParentMenuItem={props.setExpandedMenuItem}
              >
                <TenantLink to={subMenuItem.url || ""} pushToBrowserHistory={subMenuItem.pushToBrowserHistory}>
                  <ListItem
                    button
                    disableRipple
                    onClick={() => {
                      if (!!props.setExpandedParentMenuItem)
                        props.setExpandedParentMenuItem("");
                      else
                        props.setExpandedMenuItem("");
                    }}
                    className={classes.listItem}
                  >
                    <div className={`${classes.expandableText} ${props.expand ? "expanded" : ""}`}>
                      <ListItemText primary={subMenuItem.label} classes={{ primary: classes.listItemText }} />
                    </div>
                  </ListItem>
                </TenantLink>
              </AccordionWrapper>
            );
          })}
        </div>
      </List>
    );

    return (
      <Accordion
        square
        elevation={0}
        expanded={props.expand && props.expandedMenuItem === menuItem.label}
        onChange={(event: any, expanded: boolean) => {
          if (expanded && !menuItem.url)
            props.setExpandedMenuItem(menuItem.label);
          else
            props.setExpandedMenuItem("");

          if (!props.expand)
            props.setExpand(true);
        }}
        classes={{ expanded: classes.accordionExpanded }}
        className={classes.accordion}
      >
        <AccordionSummary
          expandIcon={<ChevronRightIcon />}
          disableRipple
          onClick={(event: any) => {
            if (!!menuItem.url) {
              event.stopPropagation();

              if (props.expandedMenuItem !== menuItem.label)
                props.setExpandedMenuItem(menuItem.label);
              else
                props.setExpandedMenuItem("");
            }
          }}
          classes={{
            content: classes.accordionSummaryContent,
            expandIconWrapper: classes.accordionExpandIcon
          }}
          className={`${classes.accordionSummary} ${props.expand ? "" : "collapsed" } ${props.selected ? "selected" : ""}`}
        >
          {props.children}
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          {subList}
        </AccordionDetails>
      </Accordion>
    );
  }

  return (
    <React.Fragment>
      {props.children}
    </React.Fragment>
  );
}

const UserRoleWrapper: React.FunctionComponent<{ userRole?: UserRoles, strictRole?: boolean }> = props => {
  if (!!props.userRole)
    return (
      <RequireRole roleName={props.userRole} strictRole={props.strictRole ?? false}>
        {props.children}
      </RequireRole>
    );

  return (
    <React.Fragment>
      {props.children}
    </React.Fragment>
  );
}

export const MenuItem: React.FunctionComponent<ComponentProps> = props => {
  const { menuItem } = props;
  const classes = useStyles();

  if (menuItem.hide)
    return <React.Fragment></React.Fragment>;

  return (
    <UserRoleWrapper userRole={menuItem.userRole} strictRole={menuItem.strictRole}>
      <AccordionWrapper {...props}>
        <div className={classes.menuItem}>
          <TenantLink to={menuItem.url || ""} pushToBrowserHistory={menuItem.pushToBrowserHistory}>
            <ListItem
              button
              disableRipple
              onClick={() => {
                props.setExpandedMenuItem("");
              }}
              className={`${classes.listItem} ${props.selected ? "selected" : ""}`}
            >
              {!props.hideIcons &&
                <ListItemIcon title={menuItem.label} className={classes.listItemIcon}>
                  {menuItem.icon}
                </ListItemIcon>
              }
              <div className={`${classes.expandableText} ${props.expand ? "expanded" : ""}`}>
                <ListItemText primary={menuItem.label} classes={{ primary: classes.listItemText }} />
                {menuItem.endIcon}
              </div>
            </ListItem>
          </TenantLink>
        </div>
      </AccordionWrapper>
    </UserRoleWrapper>
  );
}


interface ComponentProps {
  expand?: boolean;
  expandedMenuItem: string;
  hideIcons?: boolean;
  menuItem: IMenuItem;
  selected?: boolean;
  setExpand: (expand: boolean) => void;
  setExpandedMenuItem: (menuItem: string) => void;
}
