import { Action } from "redux";
import * as Actions from "./actions";
import { ProfileState } from "./models";


const defaultState: ProfileState = {
  userSettings: null,
  errorMessage: "",
  isFetching: false,
  isInitialLoad: true,
  isSaving: false,
  isUploading: false,
  shouldFetch: false
};

const actionHandler: { [actionType: string]: (state: ProfileState, action: any) => ProfileState } = {

  [Actions.INITIALIZE_USER_SETTINGS]: (state: ProfileState, action: Actions.InitializeUserSettings) => {
    return {
      ...state,
      userSettings: { ...action.userSettings },
      errorMessage: "",
      isFetching: false,
      isInitialLoad: false,
      shouldFetch: false
    };
  },

  [Actions.UPDATE_USER_SETTINGS]: (state: ProfileState, action: Actions.UpdateUserSettings) => {
    return {
      ...state,
      userSettings: {
        ...state.userSettings!,
        ...action.userSettings
      }
    };
  },

  [Actions.SAVE_USER_SETTINGS]: (state: ProfileState, action: Actions.SaveUserSettings) => {
    return {
      ...state,
      isSaving: true
    };
  },

  [Actions.SAVE_USER_SETTINGS_COMPLETE]: (state: ProfileState, action: Actions.SaveUserSettingsComplete) => {
    return {
      ...state,
      errorMessage: action.succeeded ? "" : "Unable to save settings",
      isSaving: false
    };
  },

  [Actions.CLEAR_ERROR_MESSAGE]: (state: ProfileState, action: Actions.ClearErrorMessage) => {
    return {
      ...state,
      errorMessage: ""
    };
  }

}

export const reducer = (state: ProfileState, action: Action) => {
  const actionHandlerMethod = actionHandler[action.type];
  if (actionHandlerMethod) {
    return actionHandlerMethod(state, action);
  }
  return state || defaultState;
};
