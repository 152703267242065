import { Backdrop } from '@mui/material';
import { Container } from '@mui/system';
import * as React from 'react';

interface ComponentProps {
    show: boolean;
    middleComponent?: JSX.Element;
    fullScreen?: boolean;
    containerStyle?: React.CSSProperties;
}

const BaseComponentOverlay: React.FC<ComponentProps> = (props) => {
    return (
        <Backdrop
            open={props.show}
            sx={{
                // default is for this component to fit its parent component. For this to happen, The parent component must be position: relative
                // and the Backdrop component has to have position: absolute.
                // if you want this component to take up the entire screen, then just have the Backdrop component to have position: fixed
                position: props.fullScreen ? 'fixed' : 'absolute',
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                zIndex: (theme) => theme.zIndex.drawer + 1
            }}
        >
            <Container
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    ...(props.containerStyle)
                }}
                maxWidth={false}
            >
                {props.middleComponent}
            </Container>
        </Backdrop>
    );
}

export default BaseComponentOverlay;