import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";

import ClearIcon from "@mui/icons-material/Clear";
import CropIcon from "@mui/icons-material/Crop";
import CreateIcon from '@mui/icons-material/CreateOutlined';
import { Fab, useMediaQuery, useTheme } from "@mui/material";

import { Icons } from "modules/common/icons";
import { CroppableImage } from "../models";
import { AttachedFile } from "modules/gallery";
import CanvasPreviewImage from "../../canvasPreviewImage";
import * as galleryActions from "modules/gallery/actionCreator";
import ImageCropper from "../dialogs/imageCropper";
import ImageGalleryDialog from "../dialogs/imageGalleryDialog";
import Pagination from "../../pagination/pagination";
import { GlobalApplicationState } from "globalApplicationState";

import "./heroBannerv2.sass";
import PortraitImage from "../../paddedImage";

interface IHeroBannerEditorProps {
    onEditHeroImage: (image: CroppableImage | undefined) => void;
    onEditAttachedContent: (images: AttachedFile[]) => void;
    images: AttachedFile[];
}

// authoring v2
const HeroBannerEditor: React.FunctionComponent<PropsWithRedux> = ({
    images,
    onEditAttachedContent,
    onEditHeroImage,
    getImage,
    getVideo,
    getVideosComplete
}) => {
    const theme = useTheme();
    const isSmallAndSmaller = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });

    const [pageIndex, setPageIndex] = useState<number>(0);
    const [imageGalleryOpen, setImageGalleryOpen] = useState<boolean>(false);
    const [imageCropperOpen, setImageCropperOpen] = useState<boolean>(false);
    const includeVideos = false; // we don't support videos for heros currently
    const [imagesToCrop, setImagesToCrop] = useState<CroppableImage[]>();
    const [imagesToCropAttachedFile, setImagesToCropAttachedFile] = useState<AttachedFile[]>(); // same as imagesToCrop just in AttachedFile interface
    const [isCropSkippable, setIsCropSkippable] = useState<boolean>(false);
    const [fetching, setFetching] = useState<boolean>(false);
    const [previewedImage, setPreviewedImage] = useState<AttachedFile | undefined>();

    useEffect(() => {
        let newImage = pageIndex < images.length
            ? images[pageIndex]
            : undefined;

        setPreviewedImage(newImage);
    }, [pageIndex, images]);

    const onCloseImageCropper = () => {
        setImagesToCrop([]);
        setImagesToCropAttachedFile([]);
        setImageCropperOpen(false);
        setIsCropSkippable(false);
    }

    const onCloseImageGallery = () => {
        setImageGalleryOpen(false);
    }

    const getImagePreview = (): JSX.Element => {
        let result = <></>;
        if (images.length === 0) {
            result = <div className="hero-banner-placeholder" onClick={() => { setImageGalleryOpen(true); }}>
                <Icons.AddImage width="60px" height="60px" color="#aaaaaa" />
                <span className="instruction">Set a hero banner image</span>
            </div>;
        } else {
            result = canvas();
        }

        return result;
    }

    const canvas = () => {
        return previewedImage && previewedImage.transforms && previewedImage.transforms.zoom && previewedImage.transforms.points.length > 0
            ? <>
                <CanvasPreviewImage
                    onClick={() => { setImageGalleryOpen(true); }}
                    height={234}
                    width={416}
                    src={previewedImage.fileUrl!}
                    cropPoints={previewedImage.transforms?.points || []}
                />
                {getImageActions()}
            </>
            : 
                <PortraitImage 
                    src={previewedImage?.fileUrl!}
                    height="234px"
                    width="416px"
                    backgroundColor="#DDE1E5"
                    onClick={() => { setImageGalleryOpen(true); }}
                    footer={getImageActions()}
                />
    }

    const getImageActions = () => {
        return (
            <>
                {images.length > 0 &&
                    <div className="hero-banner-actions">
                        <Fab className="fab-action" size="small" title="Remove" onClick={onRemoveImage}>
                            <ClearIcon />
                        </Fab>
                        <Fab className="fab-action" size="small" title="Crop image" onClick={onCropImage}>
                            <CropIcon />
                        </Fab>
                        <Fab className="fab-action edit" size="small" title="Edit" onClick={() => { setImageGalleryOpen(true); }}>
                            <CreateIcon />
                        </Fab>
                    </div>}
            </>
        );
    }

    /**
     * Apply transforms to image
     * - first find it in images array
     * - if it is the first element, call hero image update from props
     * - else build new attached content array and call attached content update from props
     */
    const onApplyImageTransform = (newImage: CroppableImage, finished: boolean = false) => {
        if (!imagesToCropAttachedFile)
            return;

        // find image in images
        let imageToUpdateIndex = images?.findIndex((img: AttachedFile) => img.blobId === newImage.id);
        if (imageToUpdateIndex === -1)
            return;

        // handle main hero image
        if (imageToUpdateIndex === 0) {
            onEditHeroImage({ ...newImage });
        } else {
            let [, ...newAttachedContent] = [...images!];
            let valueToReplace = images[imageToUpdateIndex];

            // -1 since hero image is always first
            newAttachedContent[imageToUpdateIndex - 1] = { ...valueToReplace, ...newImage };

            onEditAttachedContent(newAttachedContent);
        }

        if (finished) onCloseImageCropper();
    }

    const onSelectImages = async (attachedContent: AttachedFile[]) => {
        setFetching(true);
        onCloseImageGallery();
        setIsCropSkippable(true);
        setImageCropperOpen(true);

        const results = await getAttachedContents(attachedContent);

        let newImagesAttachedFileToCrop = results
            .filter((value: AttachedFile) => value.fileType !== "video");

        let newImagesToCrop = newImagesAttachedFileToCrop
            .map((value: AttachedFile) => {
                return ({ id: value.ospreyId, url: value.fileUrl!, transforms: { points: [] } });
            });

        let [newHero, ...rest] = results;

        if (newHero)
            onEditHeroImage({ id: newHero.ospreyId, url: newHero.fileUrl!, transforms: newHero.transforms || { points: [] } });

        if (rest && rest.length > 0)
            onEditAttachedContent(rest);

        setFetching(false);
        setImagesToCrop(newImagesToCrop);
        setImagesToCropAttachedFile(newImagesAttachedFileToCrop);
    }

    const getAttachedContents = async (attachedContent: AttachedFile[]): Promise<AttachedFile[]> => {
        let containsVideo = false
        let tasks: Promise<AttachedFile>[] = [];

        for (let i = 0; i < attachedContent.length; i++) {
            const curr = attachedContent[i];

            let taskToAdd: Promise<AttachedFile>;
            if (curr.fileType === "video") {
                taskToAdd = Promise.resolve({ ...curr, fileUrl: (await getVideo(curr.ospreyId)) });
                containsVideo = true;
            } else {
                taskToAdd = getImage(curr.ospreyId);
            }

            tasks.push(taskToAdd);
        }

        if (containsVideo)
            getVideosComplete();

        return await Promise.all(tasks);
    }

    const onCropImage = () => {
        if (previewedImage)
            setImagesToCrop([{
                url: previewedImage.fileUrl!,
                id: previewedImage.blobId,
                transforms: previewedImage.transforms || { points: [] },
            }]);

        setImageCropperOpen(true);
    }

    /**
     * Remove an image
     * - first image always hero banner
     */
    const onRemoveImage = () => {
        let [currHeroImage, ...newAttachedContent] = [...images];
        let newHeroImage: AttachedFile | undefined = { ...currHeroImage };

        // removing hero, next element becomes hero if it exists otherwise empty all images
        if (pageIndex === 0) {
            if (images.length > 1) {
                newHeroImage = { ...images[1] };
                newAttachedContent.splice(0, 1);
            } else {
                newHeroImage = undefined;
                newAttachedContent = [];
            }
        } else {
            newAttachedContent = newAttachedContent.filter((img: AttachedFile) => img.blobId !== previewedImage?.blobId);
        }

        // set current image preview to the one before that was removed or the first one
        setPageIndex(prev => prev === 0 ? 0 : prev - 1);
        onEditAttachedContent(newAttachedContent);
        onEditHeroImage({ url: newHeroImage?.fileUrl!, id: newHeroImage?.ospreyId!, transforms: newHeroImage?.transforms! });
    }

    return (
        <>
            <div className="hero-banner-container">
                <div className="hero-banner-header">
                    <label className="hero-banner-label">
                        Hero banner
                    </label>
                    <Pagination
                        onPageChange={setPageIndex}
                        currentPage={pageIndex}
                        count={images.length}
                    />
                </div>
                <div className="hero-banner-previews" style={{ margin: isSmallAndSmaller ? "auto" : "" }}>
                    {getImagePreview()}
                </div>
            </div>
            <ImageCropper
                show={imageCropperOpen}
                images={imagesToCrop}
                loading={fetching}
                onPageChange={onApplyImageTransform}
                onClose={onCloseImageCropper}
                skippable={isCropSkippable}
                cropBoundary={{ width: 800, height: 450 }}
            />
            <ImageGalleryDialog
                show={imageGalleryOpen}
                attachedContent={images}
                includeVideos={includeVideos}
                multiSelect={true}
                onApply={onSelectImages}
                onClose={onCloseImageGallery}
            />
        </>
    );
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: IHeroBannerEditorProps) => ({
        ...ownProps,
        isFetching: state.gallery.isFetching
    }), {
    getImage: galleryActions.getImage,
    getVideo: galleryActions.getVideo,
    getVideosComplete: galleryActions.getVideosComplete
}
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(HeroBannerEditor);
