import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { GlobalApplicationState } from 'globalApplicationState';
import './textAreaLanguageSelector.sass'


interface ComponentProps { 
    returnTranslatedContent: (any) => any;
    maxTextLength?: number;
    disabled?: boolean;
}

interface ComponentState {
    defaultLCID: string;
    translatedContent: {
        [lcid: string]: string,
    },
    selectedLCID: string;
}


class TextAreaLanguageSelector extends React.Component<PropsWithRedux, ComponentState> {

    constructor(props) {
        super(props);
        this.state = {
            defaultLCID: this.props.tenantSettings.defaultLCID,
            translatedContent: { 
                [this.props.tenantSettings.defaultLCID]: ""
            },
            selectedLCID: this.props.tenantSettings.defaultLCID,
        }
    }
    public componentDidMount()  {
    }
    public render() {

        const lcidToLanguage = {
            "en-ca": "English",
            "en-us": "English",
            "es-us": "Español",
            "fr": "Français",
            "fr-ca": "Français",
            "it": "Italiano",
            "tl": "Tagalog",
            "pt-br": "Português",
            "vi": "Tiếng Việt"
        }

        const availableLCID = Object.keys(this.props.lcid);
        const lcidButtons = availableLCID.map((lcid) => {
            return (
                <span
                    key={`${lcid}-buton`}
                    onClick={() => this.selectLanguage(lcid)}
                    className={`lcid-options-dropdown`}>
                    {lcidToLanguage[lcid]} ({lcid})
                </span>
            )
        })

        const tabs = Object.keys(this.state.translatedContent).map((lcid) => {
            const active = (lcid === this.state.selectedLCID) ? "active" : ""
            return (
                <span
                key={`${lcid}-tab`}
                onClick={() => this.selectLanguageTab(lcid)}
                className={`text-area-lcid-tab ${active}`}>

                    {lcidToLanguage[lcid]} ({lcid}) 
                    {lcid !== this.state.defaultLCID ?
                    <i 
                        style={{marginLeft: 5, cursor: "pointer", color: "#2f4050"}}
                        className="material-icons"
                        onClick={(e) => {
                            e.stopPropagation()
                            this.removeLcid(lcid)}}
                    >delete</i>
                    : null
                    }
                </span>
            )
        })
        return (

            <React.Fragment>
                <div className="language-section">
                    {tabs}
                    <div className="language-button-selector">
                        <button className="dropdown-button-lcid">+</button>
                        <div className="dropdown-btn-content-lcid">
                            {lcidButtons}
                        </div>
                    </div>                    
                </div>
                <div className="text-area-container">
                    <textarea
                        value={this.state.translatedContent[this.state.selectedLCID] ?? ""}
                        className="text-area-language-body" 
                        onChange={(e) => this.handleTextArea(e)}
                        maxLength={this.props.maxTextLength}
                        disabled={this.props.disabled}>
                    </textarea>
                </div>
            </React.Fragment>
        )
    }

    private removeLcid = (lcid: string) => {
        delete this.state.translatedContent[lcid]
        this.setState({
            ...this.state,
            selectedLCID: this.state.defaultLCID,
            translatedContent: this.state.translatedContent,
        })
    }
    private selectLanguageTab = (lcid: string) => {
        this.setState({
            ...this.state,
            selectedLCID: lcid,
        })
    }

    private selectLanguage = (lcid: string) => {
        if (!Object.keys(this.state.translatedContent).includes(lcid)) {
            this.setState({
                ...this.state,
                translatedContent: {
                    ...this.state.translatedContent,
                    [lcid]: ""
                }
            })
        } else {
            this.selectLanguageTab(lcid)
        }
    }
    private handleTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({
            ...this.state,
            translatedContent: {
                ...this.state.translatedContent,
                [this.state.selectedLCID]: e.target.value
            },
        }, () => {
            this.props.returnTranslatedContent(this.state.translatedContent);
        })
    }
}



const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        tenantSettings: state.settings.tenantSettings,
        lcidMappings: state.resources.lcidMappings,
        lcid: state.settings.tenantSettings.translatedContent
    }),
    {
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default (connector(TextAreaLanguageSelector));

