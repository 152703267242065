import * as React from "react";

import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";

const TenantFilterButtons: React.FunctionComponent<ComponentProps> = props => {
  return (
    <React.Fragment>
      <Button color="primary" onClick={props.onClearFilters} disabled={!props.canSearch}>Clear All</Button>
      <Button variant="contained" color="primary" disableElevation disabled={!props.canSearch} onClick={props.onApplyFilters}>Search</Button>
      {props.onCloseFilters &&
        <React.Fragment>
          <Divider orientation="vertical" flexItem />
          <IconButton onClick={props.onCloseFilters}>
            <ClearIcon />
          </IconButton>
        </React.Fragment>
      }
    </React.Fragment>
  );
}
  

interface ComponentProps {
  canSearch: boolean;
  onApplyFilters: () => void;
  onClearFilters: () => void;
  onCloseFilters?: () => void;
}

export default TenantFilterButtons;