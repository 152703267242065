import { Button, Typography } from '@mui/material';
import TenantLink from 'modules/common/components/tenantLink';
import NoticeWebpage from 'modules/common/noticeWebpage';
import * as React from 'react';


const ReportExpired : React.FunctionComponent<ComponentProps> = (props) => {
    return (
        <NoticeWebpage
            imageComponent={<img src="/images/report-hourglass.png" alt="expired banner" style={{maxWidth: "130.6px", maxHeight: "130.6px", width: "100%", height: "100%"}}/>}
            titleComponent={<Typography variant="h1" align="center">Report is no longer available</Typography>}
            bodyComponent={
                <React.Fragment>
                    <Typography variant="body1" align="center">Report links expire after {props.expiryDays} days.</Typography>
                    <Typography variant="body1" align="center">Please generate your report again.</Typography>
                </React.Fragment>
            }
            buttonComponent={
                <TenantLink to="~/admin/dashboard">
                    <Button variant="text" color="primary">Back to Home</Button>
                </TenantLink>
            }
        />
    );
}

interface ComponentProps {
    expiryDays: number;
}

export default ReportExpired;