import {select, scaleBand, selectAll, scaleLinear, max, axisBottom} from 'd3';
const d3 = { select, scaleBand, selectAll, scaleLinear, max, axisBottom };

export interface VBarChartProps {
    el: any;
    height: number;
    data: VBarChartDataPoint[];
    labelBars: boolean;
    showOnHover: boolean;
    locale?: string;
}

export interface VBarChartDataPoint {
    id: string;
    label: string;
    value: number;
    onClick?: (point: VBarChartDataPoint) => any;
}

export class VerticalBarChart {

    constructor(props: VBarChartProps) {
        this.update(props);
    }

    public update(props: VBarChartProps) {

        if (!props.el) {
            return;
        }

        const boundingRect = props.el.getBoundingClientRect();

            d3.select(props.el)
            .select('svg').remove();

        // set the dimensions and margins of the graph
        const margin = {top: 20, right: 20, bottom: 40, left: 20},
            width = boundingRect.width - margin.left - margin.right,
            height = props.height - margin.top - margin.bottom;

        // set the ranges
        const x = d3.scaleBand()
                .range([0, width])
                .padding(0.1);
        const y = d3.scaleLinear()
                .range([height, 0]);

        d3.select('body').append('div')
            .attr('class', 'bar-chart-tooltip')
            .style('opacity', 0);

        // append the svg object to the body of the page
        // append a 'group' element to 'svg'
        // moves the 'group' element to the top left margin
        const svgTranslate = `translate(${margin.left}, ${margin.top})`;
        const svg = d3.select(props.el)
            .append('svg')
            .attr('width', width + margin.left + margin.right)
            .attr('height', height + margin.top + margin.bottom)
            .append('g')
            .attr('transform', svgTranslate);

        // Scale the range of the data in the domains
        x.domain(props.data.map(function(d) { return d.label; }));
        y.domain([0, d3.max(props.data, function(d) { return d.value; })!]);

        // append the rectangles for the bar chart
        const bar = svg.selectAll('.bar')
            .data(props.data)
            .enter()
            .append('g')
            // allow the class that is using this to
            // decide if value will appear when user hovers
            // over the bar
            .attr('class', props.showOnHover ? 'bar-group' : '')

        bar.append('rect')
            .attr('class', 'bar')
            .attr('x', (d) => x(d.label)! )
            .attr('width', x.bandwidth())
            .attr('y', function (d) { return y(d.value); })
            .attr('height', function (d) { return height - y(d.value); });

        if (props.labelBars) {
            bar.append('text')
                .attr('class', 'top-label')
                .text((d) => d.value)
                .attr('x', (d) => x(d.label)! )
                .attr('y', (d) => y(d.value) )
                .attr('transform', function (d) {
                    const xOffset = (x.bandwidth() / 2 - (this as any).getBBox().width / 2);
                    return 'translate(' + xOffset + ', -5)'
                });
        }
        // add the x Axis
        svg.append('g')
            .attr('transform', 'translate(0,' + height + ')')
            .call(d3.axisBottom(x));
          }

    public destroy() {
        // Any clean-up would go here
        // in this example there is nothing to do
    }
}

