import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import * as actions from "../../actionCreator";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";

import { NewsletterDigest, SaveNewsletterModelStateErrors } from "modules/newsletters";

import _ from "lodash";
import "../../styles/dialogs.sass";

class CopyNewsletterDialog extends React.Component<PropsWithRedux, ComponentState> {
  constructor(props: PropsWithRedux) {
    super(props);

    this.state = {newTitle: ""};
  }

  public render() {
    return (
      <Dialog open={true} maxWidth={false} scroll="paper" onClose={() => this.props.onClose(null)} classes={{ paper: "newsletter-dialog" }}>
        <DialogTitle className="newsletter-dialog-title">
          <div>Duplicate</div>
          <IconButton onClick={() => this.props.onClose(null)} size="large">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            A duplicate of {this.props.copyingNewsletter.title} will be created and saved as a draft.
          </DialogContentText>
            <TextField
                autoFocus
                variant="outlined"
                margin="dense"
                fullWidth
                id="newTitle"
                value={this.state.newTitle}
                error={!!this.state.modelErrors && _.some(_.values(this.state.modelErrors))}
                helperText={!!this.state.modelErrors && _.first(_.values(this.state.modelErrors))}
                placeholder="New title"
                autoComplete="off"
                onChange={(event) => this.setState({newTitle: event.target.value})}
              />
        </DialogContent>
        <DialogActions>
          <div>
            <Button
              variant="text"
              onClick={() => this.props.onClose(null)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={!this.state.newTitle}
              onClick={this.onCopyNewsletter}
            >
              Duplicate
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    );
  }

  private onCopyNewsletter = () => {
    this.props.cloneNewsletter(this.props.copyingNewsletter.id, this.state.newTitle)
    .then(result => {
      if(!!result.success){
        this.setState({modelErrors: null});
        this.props.onClose(result.success.id);
      }else if(!!result.error){
        this.setState({modelErrors: result.error.ModelState});
      }
    })
  }
}

interface ComponentProps {
  copyingNewsletter: NewsletterDigest;
  onClose: (newNewsletterId: string | null) => void;
}

interface ComponentState {
  newTitle: string;
  modelErrors?: SaveNewsletterModelStateErrors | null;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ownProps,
  {
    cloneNewsletter: actions.cloneNewsletter
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(CopyNewsletterDialog);