import * as React from "react";

import Loading from "modules/common/components/loading";

import { ContentAnalysis as IContentAnalysis } from "../models";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import CloseIcon from "@mui/icons-material/Close";
import NavigationIcon from "@mui/icons-material/Navigation";


type SentimentScale = 1 | 2 | 3 | 4 | 5;


class ContentAnalysis extends React.Component<ComponentProps, ComponentState> {
  constructor(props: ComponentProps) {
    super(props);

    this.state = {
      contentAnalysis: undefined
    };
  }

  componentDidUpdate(prevProps: ComponentProps) {
    if (this.props.show && !prevProps.show) {
      this.getContentAnalysis();
    }
  }

  public render() {
    const { contentAnalysis } = this.state;

    const sentiment: SentimentScale = this.getSentimentNumber();
    const sentimentColor = this.getSentimentColor(sentiment);

    return (
      <Dialog open={this.props.show} maxWidth={false} onClose={this.onClose}>
        <DialogTitle className="events-dialog-header">
          <div className="events-dialog-header-title">
            <Typography variant="h2">Content Analysis</Typography>
            <IconButton onClick={this.onClose} size="large">
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent dividers className="content-analysis-content">
          {!this.state.contentAnalysis
            ? <Loading padding={12} />
            : <div>
                <div className="analysis-columns">
                  <div className="analysis-column">
                    <div>
                      <Typography variant="subtitle1">Word Count</Typography>
                      <div className="analysis-number-stat">{contentAnalysis!.wordCount}</div>
                    </div>
                    <div>
                      <Typography variant="subtitle1">Estimated Reading Time</Typography>
                      <div className="analysis-read-time">
                        <div className="analysis-number-stat">{this.formatReadTime(contentAnalysis!.readingTime) === "min" ? contentAnalysis!.readingTime : (contentAnalysis!.readingTime.split(":"))[1]}</div>
                        <div className="analysis-read-text">{this.formatReadTime(contentAnalysis!.readingTime) === "min" ? "minutes" : "seconds"}</div>
                      </div>
                    </div>
                  </div>
                  <div className="analysis-column">
                    <div>
                      <Typography variant="subtitle1">Tone</Typography>
                      <div className="analysis-word-stat">{contentAnalysis!.tone}</div>
                    </div>
                    <div>
                      <Typography variant="subtitle1">Sentiment</Typography>
                      <div className="analysis-bar-wrapper">
                        <div className="analysis-sentiment-bar">{this.getSentimentBar(sentiment, sentimentColor)}</div>
                        <div style={{ color: sentimentColor }}>{`${this.getSentimentVerb(sentiment)}`}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="analysis-columns">
                  <div>
                    <Typography variant="subtitle1">English Proficiency Required</Typography>
                    <div className="analysis-bar-wrapper">
                      <div className="analysis-proficiency-bar">{this.getProficiencyBar(contentAnalysis!.ieltsScoreText, contentAnalysis!.ieltsScore)}</div>
                    </div>
                  </div>
                </div>
                <div className="analysis-columns">
                  <div>
                    <Typography variant="subtitle1">Education Level for Easy Comprehension</Typography>
                    <div className="analysis-word-stat">
                      {(contentAnalysis!.fogScoreText.split("("))[0]}
                      <div className="ideal">{this.isIdealScore()}</div>
                    </div>
                  </div>
                </div>
              </div>
          }
        </DialogContent>
        <DialogActions className="events-dialog-footer">
          <div className="events-dialog-disclaimer">*Analytics are provided for English content only.</div>
          <Button variant="text" onClick={this.onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  }

  private getContentAnalysis = () => {
    this.props.getContentAnalysis(this.props.id || "00000000-0000-7368-6172-65706f696e74", this.props.body).then((contentAnalysis) => {
      this.setState({ ...this.state, contentAnalysis });
    });
  }

  private getProficiencyBar = (scoreText: string, score: string): JSX.Element => {
    return (
      <React.Fragment>
        <div className="analysis-label-wrapper">
          <div>Common (Ideal)</div>
          <div>Expert (Highly Academic)</div>
        </div>
        <div>
          {Array.apply(null, Array(3)).map((_, index) =>
            <div key={index} style={{ backgroundColor: this.getProficiencyColor(index) }} className="analysis-bar-section"></div>)
          }
        </div>
        <div className="analysis-score-wrapper">
          <NavigationIcon style={{ paddingLeft: this.getScoreLocation(score) }} />
        </div>
        <div className="analysis-score-text">{scoreText}</div>
      </React.Fragment>
    );
  }

  private getProficiencyColor = (index: number): string => {
    if (index === 2)
      return "#E23030";
    else if (index === 1)
      return "#FFC327";
    else
      return "#5C9A26";
  }

  private getScoreLocation = (score: string): string => {
    return `${(parseFloat(score) - 5) * (100 / 3)}%`;
  }

  private getSentimentBar = (sentiment: SentimentScale, backgroundColor: string): JSX.Element => {
    return (
      <React.Fragment>
        {Array.apply(null, Array(5)).map((_, index) => <div key={index} style={index < sentiment ? { backgroundColor } : {}} className="analysis-bar-section"></div>)}
      </React.Fragment>
    );
  }

  private getSentimentColor = (sentiment: SentimentScale): string => {
    if (sentiment === 5)
      return "#5c9a26";
    else if (sentiment === 4)
      return "#8bc34a";
    else if (sentiment === 3)
      return "#cddc39";
    else if (sentiment === 2)
      return "#ffc327";

    return "#e23030";
  }

  private getSentimentNumber = (): SentimentScale => {
    if (!this.state.contentAnalysis)
      return 1;

    const sentiment: number = this.state.contentAnalysis.uiSentimentScore;

    if (sentiment >= 0.7)
      return 5;
    else if (sentiment > 0.3)
      return 4;
    else if (sentiment > -0.3)
      return 3;
    else if (sentiment > -0.7)
      return 2;

    return 1;
  }

  private getSentimentVerb = (sentiment: SentimentScale): string => {
    if (sentiment === 5)
      return "Positive";
    else if (sentiment === 4)
      return "Slightly Positive";
    else if (sentiment === 3)
      return "Neutral";
    else if (sentiment === 2)
      return "Slightly Negative";

    return "Negative";
  }

  private isIdealScore = (): string => {
    if (this.state.contentAnalysis!.fogScoreText.indexOf("(") >= 0)
      return "(Ideal)";
    return "";

  }

  private formatReadTime = (time: string): string => {
    if ((time.split(":"))[0] === "0") {
      return "sec";
    }
    return "min";
  }

  private onClose = () => {
    this.setState({ ...this.state, contentAnalysis: undefined });
    this.props.onClose();
  }
}


interface ComponentProps {
  show: boolean;
  body: string;
  id?: string;
  getContentAnalysis: (id: string, text: string) => Promise<IContentAnalysis>;
  onClose: () => void;
}

interface ComponentState {
  contentAnalysis: IContentAnalysis | undefined;
}

export default ContentAnalysis;