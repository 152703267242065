import React from "react";

import { IGPTChoice } from "./models";
import { SmartContentSuggestion } from "./smartContentSuggestion";
import { ISmartContentListPageProps, SmartContentListPage } from "./smartContentListPage";

interface ISmartContentSummariesProps extends ISmartContentListPageProps {
    summaries: IGPTChoice[];
    onApply: (newSummary: string) => void;
}

const SmartContentSummaries: React.FunctionComponent<ISmartContentSummariesProps> = ({
    summaries,
    onApply,
    ...props
}) => {
    // before passing to propped function, scroll to bottom of list so spinner shows
    const onGenerateMoreHandler = async (promptType: "title" | "body" | "idea" | "summarize"): Promise<void> => {
        const element = document.getElementById("smart-content-summaries-list");
        if (element)
            element.scroll({ top: element.scrollHeight, behavior: "smooth" });

        await props.onGenerateMore(promptType);
    }

    return (
        <SmartContentListPage
            {...props}
            contentId={"smart-content-summaries-list"}
            onGenerateMore={onGenerateMoreHandler}
            enableGenerateMoreListButton
            disclaimerText="Applying a suggestion will clear and replace any existing text on the summary of your post."
        >
            {summaries.map(({ index, message: { content } }: IGPTChoice) =>
                <SmartContentSuggestion
                    copyToClipboard
                    key={`${index}-summary`}
                    onConfirm={() => onApply(content)}
                    suggestion={{ suggestion: content, suggestionId: index.toString() }}
                    confirmLabel="APPLY"
                    confirmPosition="inline"
                />)}
        </SmartContentListPage>
    );
}

export { SmartContentSummaries };
