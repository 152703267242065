import * as React from 'react';
import ConfirmDialog from 'modules/common/components/dialogs/confirmDialog';
import { ConsolidatedDuplicateFiles } from '../models';

interface IDuplicateFilesDialog {
    duplicateFiles: ConsolidatedDuplicateFiles[];
    onClose: () => void;
    onDeny: () => void;
    onOption: () => void;
    onConfirm: () => void;
    onCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    open: boolean;
    dirName: string;
}

export const DuplicateFilesDialog: React.FC<IDuplicateFilesDialog> = ({
    duplicateFiles,
    onClose,
    onDeny,
    onOption,
    onConfirm,
    onCheckboxChange,
    open,
    dirName
}) => {
    const getImageDuplicateDiv = (imageTitle: string, imageUrl: string): JSX.Element => {
        return <div
            style={{
                display:'flex',
                flexDirection:'column',
                textAlign:'center'
            }}
        >
            <div>{imageTitle}</div>
            <img
                src={imageUrl}
                style={{
                    maxWidth:'400px',
                    marginTop:'auto',
                    marginBottom:'auto'
                }}
            />
        </div>;
    }

    const dialogTitle = duplicateFiles.length > 1 ? "Images already exist" : "Image already exists";
    
    return <ConfirmDialog
        title={dialogTitle}
        denyLabel="SKIP"
        denyButtonProps={{sx:{color:'grey'}}}
        optionLabel="REPLACE"
        confirmLabel="KEEP BOTH"
        checkboxLabel={duplicateFiles.length > 1 ? `Apply to all ${duplicateFiles.length} duplicates` : undefined}
        open={duplicateFiles.length > 0 && open}
        onClose={onClose}
        onDeny={onDeny}
        onOption={onOption}
        onConfirm={onConfirm}
        onCheckboxChange={onCheckboxChange}
        dialogMaxWidth='md'
    >
        {
            duplicateFiles.length > 0 && 
            <div style={{ minWidth: 400 }}>
                <div>
                    <b>{`'${duplicateFiles[0].newFile.name}'`}</b>
                    {` already exists in `}
                    <b>{`${dirName}.`}</b>
                    {` Select an action you'd like to proceed with.`}
                </div>
                {
                    !duplicateFiles[0].isDir && 
                    <div style={{display: 'flex', justifyContent: 'space-evenly', padding: '10px 0 10px 0'}}>
                        {getImageDuplicateDiv('Existing', duplicateFiles[0].duplicateFile.url)}
                        {getImageDuplicateDiv('New', duplicateFiles[0].newFile.url)}
                    </div>
                }
            </div>
        }
    </ConfirmDialog>;
}