import React from "react";

import { Button, Checkbox, List, ListItem, ListItemText } from "@mui/material";
import { Add } from "@mui/icons-material";

import { useAnchorEl } from "modules/common/hooks/useAnchorEl";
import Callout from "../../callout";
import { SelectOption } from "modules/common/models";

interface IMultiselectMuiProps {
    options: SelectOption[];
    contentClassName?:string;
    selections: string[];
    buttonLabel: string | JSX.Element;
    onChange: (key: string) => void;
    onClose?: () => void;
}

// a basic single nested multi select using MUI
export const MultiselectMui: React.FunctionComponent<IMultiselectMuiProps> = ({
    options,
    selections,
    buttonLabel,
    contentClassName,
    onClose,
    onChange,
}) => {
    const { open, setAnchorEl, anchorEl } = useAnchorEl<HTMLButtonElement>();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const onCloseCallout = () => {
        setAnchorEl(null);

        if (onClose) onClose();
    }

    return (
        <>
            <Button
                style={{ height: "40px" }}
                startIcon={<Add color="primary" />}
                color="primary"
                variant={"text"}
                onClick={handleClick}
            >
                {buttonLabel}
            </Button>
            <Callout
                anchorEl={anchorEl}
                open={open}
                setOpen={onCloseCallout}
                contentClassName={contentClassName}
                arrowPosition="top"
            >
                <List disablePadding>
                    {options.map((opt: SelectOption) => (
                        <ListItem
                            sx={{ width: 205, "&:hover": { cursor: "pointer" } }}
                            disablePadding
                            key={opt.key}
                            onClick={() => onChange(opt.key)}
                        >
                            <Checkbox
                                size="small"
                                color="primary"
                                checked={selections.includes(opt.key)}
                            />
                            <ListItemText classes={{
                                primary: "font-14"
                            }} primary={opt.text} />
                        </ListItem>
                    ))}
                </List>
            </Callout>
        </>
    );
}
