import React from "react";

import { DocumentState } from "../../../models";

import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { DatePicker } from "@mui/x-date-pickers";

import moment from "moment";


class State extends React.Component<ComponentProps, ComponentState> {
  constructor(props: ComponentProps) {
    super(props);

    moment.locale("en");

    this.state = {
      state: props.enabled
        ? moment(props.publishingTime) > moment()
          ? "scheduled"
          : "enabled"
        : "disabled"
    };
  }

  public render() {
    const showScheduledOption: boolean = this.props.isUnpublished || (this.props.enabled && moment(this.props.publishingTime) >= moment());

    return (
      <div>
        <div>State</div>
        <FormControl size="small" fullWidth className={showScheduledOption ? "document-state" : ""}>
          <Select
            variant="outlined"
            fullWidth
            value={this.state.state}
            onChange={this.onChangeState}
          >
            <MenuItem value="enabled">Enabled</MenuItem>
            <MenuItem value="disabled">Disabled</MenuItem>
            {showScheduledOption &&
              <MenuItem value="scheduled">Scheduled to enable on:</MenuItem>
            }
          </Select>
          {showScheduledOption &&
            <DatePicker
              format="MMM dd yyyy"
              disablePast
              value={this.props.publishingTime ? Date.parse(this.props.publishingTime) : null}
              slotProps={{ 
                textField: { size: "small", placeholder: "Choose date", inputProps: { readOnly: true }},
                openPickerIcon: { color: "primary" }
              }}
              disabled={this.state.state !== "scheduled"}
              onChange={this.onChangePublishingTime}
              className="date-picker"
            />
          }
        </FormControl>
      </div>
    );
  }

  private onChangePublishingTime = (date) => {
    this.props.onChangePublishingTime(moment(date).toISOString());
  }

  private onChangeState = (event) => {
    const state: DocumentState = event.target.value;
    const publishingTime: string = state === "scheduled"
      ? moment().toISOString()
      : "";
    this.setState({ state });
    this.props.onChangeState(state !== "disabled", publishingTime);
  }
}


interface ComponentProps {
  enabled: boolean;
  publishingTime: string;
  isUnpublished?: boolean;
  onChangePublishingTime: (publishingTime: string) => void;
  onChangeState: (enabled: boolean, publishingTime: string) => void;
}

interface ComponentState {
  state: DocumentState;
}

export default State;