import * as React from 'react';


import "./quickPostBanner.sass";


const QuickPostBanner: React.FunctionComponent<ComponentProps> = props => {
  if (!props.isQuickPost)
    return (null);
  return <div style={{ backgroundColor: props.bannerColor, position: 'absolute', height: props.height }} className="quick-post-banner"></div>;
};

interface ComponentProps {
  bannerColor: string;
  isQuickPost?: boolean;
  height?: string | number;
}

export default QuickPostBanner;