import RequestBuilder from "network/requestBuilder";
import { utils } from "api/network/utils";
import { Store } from "redux";
import { GlobalApplicationState } from "globalApplicationState";
import MsalAuthModule from "./msalAuthModule";
import { NetworkRequest } from "network/actions";

const handleNetworkRequest = (store: Store<GlobalApplicationState>, next, action: NetworkRequest) => {
    let config = store.getState().config;
    let baseURL = (!!action.internal) ? utils.getWebAppUrl() : config.SparrowClientApiUrl;

    const req = new RequestBuilder(action);
    req.setBaseUrl(baseURL);

    if (action.url.indexOf("{tenant}") > -1) {
        req.addParam("tenant", store.getState().tenant?.id);
    }

    return MsalAuthModule.getInstance().getAccessToken().then(accessToken => {
        req.addAuthToken(accessToken);
        const { url, init } = req.build();

        return fetch(url, init)
            .then(response => {
                switch(response.status) {
                    case 200: 
                        return response;
                    default: // should handle error codes here
                        return response;
                }
            })
            .catch(error => {
                // could not complete request
                // something terrible went wrong? no network?
                throw error;
            });
    });
}

const createAuthenticatedRequestMiddleware = () => {
    return store => next => action => {
        if (action.type !== "NETWORK_REQUEST") {
            return next(action);
        }
        
        return handleNetworkRequest(store, next, action)
    }
}

const middleware = createAuthenticatedRequestMiddleware();
export default middleware;