import React, { useEffect, useState } from 'react';
import numeral from "numeral";

import { Pie } from '@nivo/pie';

import { User } from 'modules/users/models';
import { insightsApi } from 'api/instances';


interface Data {
    id: string;
    value: number;
}

interface ComponentProps {
    users: User[]
}
const UserLanguageStats: React.FunctionComponent<ComponentProps> = ( {users} ) => {
 
    const [data, setData] = useState<Data[]>([]);

    useEffect(() => {
        insightsApi.GetPlatformPreferredLanguageStats()
        .then((response) => {
            var stats: Data[] = [];
            response.map((platformStats) => {
                stats.push({ id: platformStats.preferredLCIDNameEnglish, value: platformStats.totalUsersWithPreferredLCID} as Data);
                return stats;
            });
            setData(stats);
        })
    }, []);

    return (
        <div className="nivo-container">
            <div className="nivo-container-title">Preferred Language</div>
            <Pie
                height={400}
                width={450}
                colors={["#366531","#3b78ab","#e6911a","#aa214e","#599fa0"]}
                data={data.map(d => ({ ...d, id: `${d.id}: ${numeral(d.value).format("0,0")}`}))} 
                enableArcLabels={false}
                enableArcLinkLabels={false}
                isInteractive={false}
                padAngle={1}
                activeOuterRadiusOffset={8}     
                margin={{ top: 40, right: 80, bottom: 20, left: 150 }}
                legends={[
                    {
                        anchor: 'left',
                        direction: 'column',
                        justify: false,
                        translateX: -150,
                        translateY: 0,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemsSpacing: 10,
                        symbolSize: 20,
                        itemDirection: 'left-to-right'
                    }
                ]}   
                innerRadius={0.7}
            />
        </div>
    )
}

export default UserLanguageStats;