import { GlobalApplicationState } from "globalApplicationState";
import * as Actions from "./actions";
import API from "api";

import { NavigationItem, PortalPage, PortalPagesFilterValues, PortalPagesListingPage, PortalPagesListItem } from "./models";
import { defaultListingPage, defaultPortalPage } from "./reducer";
import { adminFilesApi, filesApi } from "api/instances";


export const getDraft = (draftId: string) => (dispatch, getState: () => GlobalApplicationState): Promise<PortalPage> => {
  dispatch(Actions.GetDraft({}));

  return dispatch(API.portalPages.GetDraft(draftId))
    .then(response => {
      dispatch(Actions.GetDraftComplete({ succeeded: response.status === 200 }));
      return response.json();
    })
    .catch(err => {
      dispatch(Actions.GetDraftComplete({ succeeded: false, err }));
      return;
    });
}

export const getPortalPages = (pageNumber: number, filters: Partial<PortalPagesFilterValues>, pageAmount?: number) => (dispatch, getState: () => GlobalApplicationState): Promise<PortalPagesListingPage> => {
  dispatch(Actions.GetPortalPages({}));

  return dispatch(API.portalPages.GetPages(pageNumber, filters, pageAmount))
    .then(response => response.json())
    .then(page => {
      dispatch(Actions.GetPortalPagesComplete({ succeeded: true, page }));
      return page;
    })
    .catch(err => {
      dispatch(Actions.GetPortalPagesComplete({ succeeded: false, page: defaultListingPage, err }));
      return;
    });
}

export const getDisabledPortalPages = (pageNumber: number, filters: Partial<PortalPagesFilterValues>, pageAmount?: number) => (dispatch, getState: () => GlobalApplicationState): Promise<PortalPagesListingPage> => {
  dispatch(Actions.GetDisabledPortalPages({}));

  return dispatch(API.portalPages.GetDisabledPages(pageNumber, filters, pageAmount))
    .then(response => response.json())
    .then(page => {
      dispatch(Actions.GetDisabledPortalPagesComplete({ succeeded: true, page }));
      return page;
    })
    .catch(err => {
      dispatch(Actions.GetDisabledPortalPagesComplete({ succeeded: false, page: defaultListingPage, err }));
      return;
    });
}

export const getDraftPortalPages = (pageNumber: number, filters: Partial<PortalPagesFilterValues>, pageAmount?: number) => (dispatch, getState: () => GlobalApplicationState): Promise<PortalPagesListingPage> => {
  dispatch(Actions.GetDraftPortalPages({}));

  return dispatch(API.portalPages.GetDraftPages(pageNumber, filters, pageAmount))
    .then(response => response.json())
    .then(page => {
      dispatch(Actions.GetDraftPortalPagesComplete({ succeeded: true, page }));
      return page;
    })
    .catch(err => {
      dispatch(Actions.GetDraftPortalPagesComplete({ succeeded: false, page: defaultListingPage, err }));
      return;
    });
}

export const getEnabledPortalPages = (pageNumber: number, filters: Partial<PortalPagesFilterValues>, pageAmount?: number) => (dispatch, getState: () => GlobalApplicationState): Promise<PortalPagesListingPage> => {
  dispatch(Actions.GetEnabledPortalPages({}));

  return dispatch(API.portalPages.GetEnabledPages(pageNumber, filters, pageAmount))
    .then(response => response.json())
    .then(page => {
      dispatch(Actions.GetEnabledPortalPagesComplete({ succeeded: true, page }));
      return page;
    })
    .catch(err => {
      dispatch(Actions.GetEnabledPortalPagesComplete({ succeeded: false, page: defaultListingPage, err }));
      return;
    });
}

export const getNavigation = () => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
  dispatch(Actions.GetNavigation({}));

  return dispatch(API.portalPages.GetNavigation())
    .then(response => response.json())
    .then(navigationItems => {
      dispatch(Actions.GetNavigationComplete({ succeeded: true, navigationItems }));
      return true;
    })
    .catch(err => {
      dispatch(Actions.GetNavigationComplete({ succeeded: false, navigationItems: [], err }));
      return false;
    });
}

export const getNavigationItemByURL = (url: string) => (dispatch, getState: () => GlobalApplicationState): Promise<NavigationItem> => {
  dispatch(Actions.GetNavigationItemByURL({}));

  return dispatch(API.portalPages.GetNavigationItemByURL(url))
    .then(response => response.json())
    .then(navigationItem => {
      dispatch(Actions.GetNavigationItemByURLComplete({ succeeded: true }));
      return navigationItem;
    })
    .catch(err => {
      dispatch(Actions.GetNavigationItemByURLComplete({ succeeded: false, err }));
      return;
    });
}

export const getNavigationItems = () => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
  dispatch(Actions.GetNavigationItems({}));

  return dispatch(API.portalPages.GetNavigationItems())
    .then(response => response.json())
    .then(navigationItems => {
      dispatch(Actions.GetNavigationItemsComplete({ succeeded: true, navigationItems }));
      return true;
    })
    .catch(err => {
      dispatch(Actions.GetNavigationItemsComplete({ succeeded: false, navigationItems: [], err }));
      return false;
    });
}

export const getNavigationPortalPages = (pageNumber: number, filters: Partial<PortalPagesFilterValues>) => (dispatch, getState: () => GlobalApplicationState): Promise<PortalPagesListingPage> => {
  dispatch(Actions.GetNavigationPortalPages({}));

  return dispatch(API.portalPages.GetEnabledPages(pageNumber, filters))
    .then(response => response.json())
    .then(page => {
      dispatch(Actions.GetNavigationPortalPagesComplete({ succeeded: true }));
      return page;
    })
    .catch(err => {
      dispatch(Actions.GetNavigationPortalPagesComplete({ succeeded: false, err }));
      return defaultListingPage;
    });
}


export const hideBackToPortalPages = () => (dispatch, getState: () => GlobalApplicationState): void => {
  dispatch(Actions.HideBackToPortalPages({}));
}

export const hideGlobalComponents = () => (dispatch, getState: () => GlobalApplicationState): void => {
  dispatch(Actions.HideGlobalComponents({}));
}

export const hideGoToNavigationItems = () => (dispatch, getState: () => GlobalApplicationState): void => {
  dispatch(Actions.HideGoToNavigationItems({}));
}

export const hideNewPortalPage = () => (dispatch, getState: () => GlobalApplicationState): void => {
  dispatch(Actions.HideNewPortalPage({}));
}

export const hidePreview = () => (dispatch, getState: () => GlobalApplicationState): void => {
  dispatch(Actions.HidePreview({}));
}

export const hidePublishChanges = () => (dispatch, getState: () => GlobalApplicationState): void => {
  dispatch(Actions.HidePublishChanges({}));
}

export const showBackToPortalPages = () => (dispatch, getState: () => GlobalApplicationState): void => {
  dispatch(Actions.ShowBackToPortalPages({}));
}

export const showGlobalComponents = () => (dispatch, getState: () => GlobalApplicationState): void => {
  dispatch(Actions.ShowGlobalComponents({}));
}

export const showGoToNavigationItems = () => (dispatch, getState: () => GlobalApplicationState): void => {
  dispatch(Actions.ShowGoToNavigationItems({}));
}

export const showNewPortalPage = () => (dispatch, getState: () => GlobalApplicationState): void => {
  dispatch(Actions.ShowNewPortalPage({}));
}

export const showPreview = (page: PortalPage) => (dispatch, getState: () => GlobalApplicationState): void => {
  dispatch(Actions.ShowPreview({ page }));
}

export const showPublishChanges = () => (dispatch, getState: () => GlobalApplicationState): void => {
  dispatch(Actions.ShowPublishChanges({}));
}


export const checkIfUrlExists = (url: string) => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
  dispatch(Actions.CheckIfUrlExists({}));

  return dispatch(API.portalPages.CheckIfUrlExists(url))
    .then(response => {
      return response.status !== 200;
    })
    .catch(() => {
      return true;
    });
}

export const createNewChildNavigationItem = (portalPageId: string, parentId: string, name: string, url: string, sequence: number) => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
  dispatch(Actions.CreateNewChildNavigationItem({}));

  return dispatch(API.portalPages.CreateNewChildNavigationItem(portalPageId, parentId, name, url, sequence))
    .then(response => {
      dispatch(Actions.CreateNewChildNavigationItemComplete({ succeeded: response.status === 200 }));
      return response.status === 200;
    })
    .catch(err => {
      dispatch(Actions.CreateNewChildNavigationItemComplete({ succeeded: false, err }));
      return false;
    });
}

export const createNewDraft = (pageTitle: string, pageUrl: string, audiences: string[]) => (dispatch, getState: () => GlobalApplicationState): Promise<string> => {
  dispatch(Actions.CreateNewDraft({}));

  const pageJSON = {
    ...defaultPortalPage,
    header: {
      ...defaultPortalPage.header,
      banner_text: "<p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p>" +
                   "<h1 style=\"text-align: left; font-size: 48pt; color: #333333;\">&nbsp; &nbsp; " +
                   pageTitle +
                   "&nbsp;</h1><h4 style=\"text-align: left;\"><span style=\"color: #333333;\">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Get the latest news and updates and stay connected with your organization!</span></h4>" +
                   "<p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p>"
    }
  };

  return dispatch(API.portalPages.CreateNewDraft(pageTitle, pageUrl, pageJSON, audiences))
    .then(response => {
      dispatch(Actions.CreateNewDraftComplete({ succeeded: response.status === 200 }));
      return response.json();
    })
    .catch(err => {
      dispatch(Actions.CreateNewDraftComplete({ succeeded: false, err }));
      return "";
    });
}

export const createNewNavigationItem = (portalPageId: string, name: string, url: string, sequence: number) => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
  dispatch(Actions.CreateNewNavigationItem({}));

  return dispatch(API.portalPages.CreateNewNavigationItem(portalPageId, name, url, sequence))
    .then(response => {
      dispatch(Actions.CreateNewNavigationItemComplete({ succeeded: response.status === 200 }));
      return response.status === 200;
    })
    .catch(err => {
      dispatch(Actions.CreateNewNavigationItemComplete({ succeeded: false, err }));
      return false;
    });
}

export const deleteDraft = (draftId: string) => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
  dispatch(Actions.DeleteDraft({}));

  return dispatch(API.portalPages.DeleteDraft(draftId))
    .then(response => {
      dispatch(Actions.DeleteDraftComplete({ succeeded: response.status === 200 }));
      return response.status === 200;
    })
    .catch(err => {
      dispatch(Actions.DeleteDraftComplete({ succeeded: false, err }));
      return false;
    });
}

export const deleteNavigationItem = (id: string) => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
  dispatch(Actions.DeleteNavigationItem({}));

  return dispatch(API.portalPages.DeleteNavigationItem(id))
    .then(response => {
      dispatch(Actions.DeleteNavigationItemComplete({ succeeded: response.status === 200 }));
      return response.status === 200;
    })
    .catch(err => {
      dispatch(Actions.DeleteNavigationItemComplete({ succeeded: false, err }));
      return false;
    });
}

export const deletePortalPage = (id: string) => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
  dispatch(Actions.DeletePortalPage({}));

  return dispatch(API.portalPages.DeletePage(id))
    .then(response => {
      dispatch(Actions.DeletePortalPageComplete({ succeeded: response.status === 200 }));
      return response.status === 200;
    })
    .catch(err => {
      dispatch(Actions.DeletePortalPageComplete({ succeeded: false, err }));
      return false;
    });
}

export const deletePortalPages = (portalPages: PortalPagesListItem[]) => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
  dispatch(Actions.DeletePortalPages({}));

  return Promise.all(portalPages.map(portalPage => portalPage.state === "Draft"
      ? dispatch(API.portalPages.DeleteDraft(portalPage.draftId))
      : dispatch(API.portalPages.DeletePage(portalPage.id))
    ))
    .then(response => {
      dispatch(Actions.DeletePortalPagesComplete({ succeeded: true }));
      return true;
    })
    .catch(err => {
      dispatch(Actions.DeletePortalPagesComplete({ succeeded: false, err  }));
      return false;
    });
}

export const disablePortalPage = (id: string) => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
  dispatch(Actions.DisablePortalPage({}));

  return dispatch(API.portalPages.DisablePage(id))
    .then(response => {
      dispatch(Actions.DisablePortalPageComplete({ succeeded: response.status === 200 }));
      return response.status === 200;
    })
    .catch(err => {
      dispatch(Actions.DisablePortalPageComplete({ succeeded: false, err }));
      return false;
    });
}

export const enablePortalPage = (id: string) => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
  dispatch(Actions.EnablePortalPage({}));

  return dispatch(API.portalPages.EnablePage(id))
    .then(response => {
      dispatch(Actions.EnablePortalPageComplete({ succeeded: response.status === 200 }));
      return response.status === 200;
    })
    .catch(err => {
      dispatch(Actions.EnablePortalPageComplete({ succeeded: false, err }));
      return false;
    });
}

export const modifyDraftDetails = (draftId: string, pageTitle: string, pageUrl: string, audiences: string[]) => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
  dispatch(Actions.ModifyDraftDetails({}));

  return dispatch(API.portalPages.ModifyDraftDetails(draftId, pageTitle, pageUrl, audiences))
    .then(response => {
      dispatch(Actions.ModifyDraftDetailsComplete({ succeeded: response.status === 200 }));
      return response.status === 200;
    })
    .catch(err => {
      dispatch(Actions.ModifyDraftDetailsComplete({ succeeded: false, err }));
      return false;
    });
}

export const modifyDraftTitle = (draftId: string, pageTitle: string) => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
  dispatch(Actions.ModifyDraftTitle({}));

  return dispatch(API.portalPages.ModifyDraftTitle(draftId, pageTitle))
    .then(response => {
      dispatch(Actions.ModifyDraftTitleComplete({ succeeded: response.status === 200 }));
      return response.status === 200;
    })
    .catch(err => {
      dispatch(Actions.ModifyDraftTitleComplete({ succeeded: false, err }));
      return false;
    });
}

export const modifyPortalPageDetails = (id: string, pageTitle: string, pageUrl: string, audiences: string[]) => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
  dispatch(Actions.ModifyPortalPageDetails({}));

  return dispatch(API.portalPages.ModifyPageDetails(id, pageTitle, pageUrl, audiences))
    .then(response => {
      dispatch(Actions.ModifyPortalPageDetailsComplete({ succeeded: response.status === 200 }));
      return response.status === 200;
    })
    .catch(err => {
      dispatch(Actions.ModifyPortalPageDetailsComplete({ succeeded: false, err }));
      return false;
    });
}

export const modifyPortalPageTitle = (id: string, pageTitle: string) => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
  dispatch(Actions.ModifyPortalPageTitle({}));

  return dispatch(API.portalPages.ModifyPageTitle(id, pageTitle))
    .then(response => {
      dispatch(Actions.ModifyPortalPageTitleComplete({ succeeded: response.status === 200 }));
      return response.status === 200;
    })
    .catch(err => {
      dispatch(Actions.ModifyPortalPageTitleComplete({ succeeded: false, err }));
      return false;
    });
}

export const publishDraft = (draftId: string) => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
  dispatch(Actions.PublishDraft({}));

  return dispatch(API.portalPages.PublishDraft(draftId))
    .then(response => {
      dispatch(Actions.PublishDraftComplete({ succeeded: response.status === 200 }));
      return response.status === 200;
    })
    .catch(err => {
      dispatch(Actions.PublishDraftComplete({ succeeded: false, err }));
      return false;
    });
}

export const saveDraft = (draftId: string, body: string) => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
  dispatch(Actions.SaveDraft({}));

  return dispatch(API.portalPages.SaveDraft(draftId, body))
    .then(response => {
      dispatch(Actions.SaveDraftComplete({ succeeded: response.status === 200 }));
      return response.status === 200;
    })
    .catch(err => {
      dispatch(Actions.SaveDraftComplete({ succeeded: false, err }));
      return false;
    });
}

export const setNavigationItemAsParent = (id: string, sequence: number) => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
  dispatch(Actions.SetNavigationItemAsParent({}));

  return dispatch(API.portalPages.SetNavigationItemAsParent(id, sequence))
    .then(response => {
      dispatch(Actions.SetNavigationItemAsParentComplete({ succeeded: response.status === 200 }));
      return response.status === 200;
    })
    .catch(err => {
      dispatch(Actions.SetNavigationItemAsParentComplete({ succeeded: false, err }));
      return false;
    });
}

export const setPortalPageAsHome = (id: string) => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
  dispatch(Actions.SetPortalPageAsHome({}));

  return dispatch(API.portalPages.SetPageAsHome(id))
    .then(response => {
      dispatch(Actions.SetPortalPageAsHomeComplete({ succeeded: response.status === 200 }));
      return response.status === 200;
    })
    .catch(err => {
      dispatch(Actions.SetPortalPageAsHomeComplete({ succeeded: false, err }));
      return false;
    });
}

export const updateDraft = () => (dispatch, getState: () => GlobalApplicationState): void => {
  dispatch(Actions.UpdateDraft({}));
}

export const updateNavigationItemSequence = (id: string, parentId: string, sequence: number) => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
  dispatch(Actions.UpdateNavigationItemSequence({}));

  return dispatch(API.portalPages.UpdateNavigationItemSequence(id, parentId, sequence))
    .then(response => {
      dispatch(Actions.UpdateNavigationItemSequenceComplete({ succeeded: response.status === 200 }));
      return response.status === 200;
    })
    .catch(err => {
      dispatch(Actions.UpdateNavigationItemSequenceComplete({ succeeded: false, err }));
      return false;
    });
}


export const hasChangedSinceSaved = () => (dispatch, getState: () => GlobalApplicationState): void => {
  dispatch(Actions.ChangedSinceSaved({}));
}

export const clearChangedSinceSaved = () => (dispatch, getState: () => GlobalApplicationState): void => {
  dispatch(Actions.ClearChangedSinceSaved({}));
}

export const clearErrorMessage = () => (dispatch, getState: () => GlobalApplicationState): void => {
  dispatch(Actions.ClearErrorMessage({}));
}

export const clearShouldFetch = () => (dispatch, getState: () => GlobalApplicationState): void => {
  dispatch(Actions.ClearShouldFetch({}));
}

export const clearShowPublishSuccess = () => (dispatch, getState: () => GlobalApplicationState): void => {
  dispatch(Actions.ClearShowPublishSuccess({}));
}

export const clearSuccessMessage = () => (dispatch, getState: () => GlobalApplicationState): void => {
  dispatch(Actions.ClearSuccessMessage({}));
}

export const fetchCustomCss = (pageId: string, pathname: string ) => async (dispatch, getState: () => GlobalApplicationState) => {
  dispatch(Actions.FetchCustomCssRequest({}));
  try {
      const customCss = pathname.includes("/admin/")
          ? await adminFilesApi.getCustomPortalPagesCss(pageId)
          : await filesApi.getCustomPortalPagesCss(pageId);

          dispatch(Actions.FetchCustomCssSuccess({customCss, pageId}));
  } catch (error) {
      dispatch(Actions.FetchCustomCssFailure({}));
  }
};