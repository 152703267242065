import * as React from "react";

import MenuBookIcon from "@mui/icons-material/MenuBook";


const formatReadingTime = (readingTime?: string, simple?: boolean): string => {
  if (readingTime) {

    const times = readingTime.split(":");
    const minuteLabel = simple ? "min" : times[0] === "1" ? "minute" : "minutes";
    const secondLabel = simple ? "sec" : times[1] === "01" ? "second" : "seconds";

    const minutes = times[0] !== "0" && `${times[0]} ${minuteLabel}`;
    const seconds = times[1] !== "00" && `${times[1]} ${secondLabel}`;

    if (minutes && seconds)
      return minutes + " " + seconds;
    if (!minutes && !seconds)
      return "";
    if (!seconds)
      return minutes as string;
    return seconds;
  }
  return "";
}


const ReadingTime: React.FunctionComponent<ComponentProps> = props => {
  if (props.hideReadTime || !props.readingTime)
    return (null);
  
  const readingTime = formatReadingTime(props.readingTime, props.simple);
  if (!readingTime)
    return (null);

  return (
    <div className="reading-time">
      <MenuBookIcon fontSize="inherit" />
      {readingTime}
    </div>
  );
}

interface ComponentProps {
  hideReadTime?: boolean;
  readingTime?: string;
  simple?: boolean;
}

export default ReadingTime;