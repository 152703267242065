import React from "react";

interface ISmartContentFooterProps {
}

const SmartContentFooter: React.FunctionComponent<ISmartContentFooterProps> = ({
    children,
}) => {
    return (
        <div
            style={{
                minHeight: 65,
                borderTop: "solid 1px #dde1e5",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                padding: 17,
                boxSizing: "border-box",
                marginTop: "auto",
            }}
        >
            {children}
        </div>
    );
}

export { SmartContentFooter };
