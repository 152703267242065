import * as React from "react";
import moment from "moment";

import { Answers, AttendanceType, Attendee, Questions } from "../../models";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import CloseIcon from "@mui/icons-material/Close";

import "../../styles/dialogs.sass";


class EditResponse extends React.Component<ComponentProps, ComponentState> {
  constructor(props: ComponentProps) {
    super(props);

    this.state = {
      answers: props.attendee ? props.attendee.answers : {},
      attendanceType: props.attendee ? props.attendee.attendanceType : ""
    };
  }

  componentDidMount() {
    moment.locale("en");
  }

  componentDidUpdate(prevProps: ComponentProps) {
    if (this.props.show && !prevProps.show)
      this.setState({
        answers: this.props.attendee!.answers,
        attendanceType: this.props.attendee!.attendanceType
      });
  }

  public render() {
    if (!this.props.attendee)
      return <React.Fragment></React.Fragment>;

    return (
      <Dialog open={this.props.show} maxWidth={false} onClose={this.onClose}>
        <DialogTitle className="events-dialog-header">
          <div className="events-dialog-header-title">
            <div>
              <Typography variant="h2">Edit Response</Typography>
              <Typography variant="subtitle2">Event: {this.props.title}, {moment(this.props.eventStartTime).format("MMM D, YYYY")}</Typography>
            </div>
            <IconButton onClick={this.onClose} size="large">
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent className="manage-attendance-content">
          <div>
            <div>Participant</div>
            <div className="emphasis">{this.props.attendee.name}</div>
          </div>
          <div>
            <div className="emphasis">RSVP Status</div>
            <Select
              variant="outlined"
              value={this.state.attendanceType}
              fullWidth
              onChange={this.onChangeAttendanceType}
            >
              <MenuItem value="InPerson">Attending in-person</MenuItem>
              <MenuItem value="Online">Attending online</MenuItem>
              <MenuItem value="Waitlist">Waitlist</MenuItem>
              <MenuItem value="NotAttending">Not Attending</MenuItem>
            </Select>
          </div>
          {!!Object.keys(this.props.questions).length &&
            Object.keys(this.props.questions).map((questionNumber, index) =>
              <div key={questionNumber}>
                <div>Question {index + 1}:</div>
                <div className="emphasis">{this.props.questions[questionNumber].body}</div>
                <TextField
                  variant="outlined"
                  value={this.state.answers ? this.state.answers[questionNumber] || "" : ""}
                  autoComplete="off"
                  fullWidth
                  onChange={(ev) => this.onChangeAnswers(ev, questionNumber)}
                />
              </div>
            )
          }
        </DialogContent>
        <DialogActions className="events-dialog-footer">
          <Button variant="text" color="primary" onClick={this.onClose}>Cancel</Button>
          <Button variant="contained" color="primary" disabled={!this.state.attendanceType || (this.state.attendanceType === "Undecided")} onClick={this.onApply}>Save</Button>
        </DialogActions>
      </Dialog>
    );
  }

  private onApply = () => {
    this.props.onApply(this.state.attendanceType as AttendanceType, this.state.answers);
  }

  private onChangeAnswers = (event, questionNumber: string) => {
    this.setState({
      answers: {
        ...this.state.answers,
        [questionNumber]: event.target.value
      }
    });
  }

  private onChangeAttendanceType = (event) => {
    this.setState({ attendanceType: event.target.value });
  }

  private onClose = () => {
    this.props.onClose();
  }
}


interface ComponentProps {
  show: boolean;
  attendee?: Attendee;
  eventStartTime: string;
  questions: Questions;
  title: string;
  onApply: (attendanceType: AttendanceType, answers: Answers) => void;
  onClose: () => void;
}

interface ComponentState {
  answers: Answers;
  attendanceType: AttendanceType | "";
}

export default EditResponse;