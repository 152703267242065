import React from "react";

const ChartSwatchComponent: React.FunctionComponent<ComponentProps> = props => {
  return (
      <div className='swatches'>
          {props.swatches.map((s,i)=>{
              return <span key={i} style={{float:'left', display:'inline-flex', alignItems:'center', marginRight: '1em'}}>
                      <div style={{background:s.color, width:15, height:15, marginRight: '0.5em'}}></div>
                      {s.label}
                  </span>;
          })}
      </div>
  );
}

interface Swatch {
    label: string;
    color: string;
}

interface ComponentProps {
  swatches: Swatch[];
}

export default ChartSwatchComponent;