import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { GlobalApplicationState } from 'globalApplicationState';
import * as actions from 'modules/posts/actionCreator';

import LoadingOverlay from "modules/common/components/loadingOverlay";

import { PostViewStats } from '../../../models';

import Checkbox from "@mui/material/Checkbox";


import "./compliance.sass";


class Compliance extends React.Component<PropsWithRedux, ComponentState> {
  constructor(props: PropsWithRedux) {
    super(props);

    this.state = {
      isSaving: false
    };
  }

  public render() {
    const { postType, stats, currentUserHasComplied } = this.props;

    if (postType !== 'compliance')
      return <React.Fragment></React.Fragment>;
    
    const acknowledgedCompliance = (
      <React.Fragment>
        <img src="/images/complied.svg" alt="complied icon" />
        <span className="compliance-text">{this.props.complianceText}</span>
      </React.Fragment>
    );
    const unacknowledgedCompliance = (
      <React.Fragment>
        <Checkbox size="small" disabled={this.state.isSaving} onClick={this.onSendAcknowledgement} className="checkbox" />
        <span className="compliance-text">{this.props.complianceText}</span>
      </React.Fragment>
    );
    const hasComplied = stats ? !!stats && !!stats.complianceTime : currentUserHasComplied;

    return (
      <React.Fragment>
        <div className={`compliance ${hasComplied ? "acknowledged" : "unacknowledged"}`}>
          {hasComplied
            ? acknowledgedCompliance
            : unacknowledgedCompliance
          }
        </div>
        <LoadingOverlay show={this.state.isSaving} styles={{ paddingTop: 150 }} />
      </React.Fragment>
    );
  }

  private onSendAcknowledgement = () => {
    this.setState({ isSaving: true });
    
    const postId = this.props.postId;
    this.props.sendAcknowledgement(postId).then(success => {
      if (success) {
        this.props.updatePostsFeed(
          this.props.postsFeed.map(post => post.id === postId
            ? { ...post, stats: { ...post.stats, complianceTime: new Date().toISOString() } }
            : post
          )
        );
      }
      this.setState({ isSaving: false });
    });
  }
}


interface ComponentProps {
  complianceText: string;
  postId: string;
  postType: string;
  stats?: PostViewStats;
  currentUserHasComplied?: boolean
}

interface ComponentState {
  isSaving: boolean;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    commentsEnabled: state.settings.tenantSettings.commentsEnabled,
    postsFeed: state.posts.postsFeed.postsFeed
  }),
  {
    sendAcknowledgement: actions.sendAcknowledgement,
    updatePostsFeed: actions.updatePostsFeed
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;
export default connector(Compliance);