import * as React from "react";
import { Button, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import ThemeDisplay from "./themeDisplay";
import { NewsletterTheme, SaveNewsletterModel } from "modules/newsletters/models";


const TemplateThemeSearchAndSelect: React.FunctionComponent<ComponentProps> = props =>  {

    const [filteredThemes, setFilteredThemes] = React.useState(props.themes
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(t => ({theme: t, isOpen: false})));

    const [searchText, setSearchText] = React.useState("");

    const totalOpen = filteredThemes.filter(f => f.isOpen).length;

    React.useEffect(() => {
        setFilteredThemes(props.themes
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(t => ({theme: t, isOpen: false})));
    }, [props.themes])

    const onUpdateTextToSearch = (toSet: string) => {
        setSearchText(toSet);
    }

    //Changes state of drawer to open or closed.
    const setIndexOpen = (index: number, toSet: boolean) => {
        let newState = JSON.parse(JSON.stringify(filteredThemes)); //Duplicate data to not use the same reference.
        if(index < filteredThemes.length) {
          newState[index].isOpen = toSet;
        }
        setFilteredThemes(newState);
    }

    //Sets ALL items in the list to closed or open.
    const setFlip = (toSet: boolean) => {
        let newFiltered = [...filteredThemes];
        if(toSet) {
          newFiltered = newFiltered.map(theme => {
            theme.isOpen = true;
            return theme;
          });
        }
        else
        {
          newFiltered = newFiltered.map(theme => {
            theme.isOpen = false;
            return theme;
          });
        }
        setFilteredThemes(newFiltered);
    }

    //Hitting enter with search text will perform the filtering.
    const onKeyPress = (key) => {
        if (key.keyCode === 13 && !!searchText) {
            let newThemes = props.themes
                .filter(t => t.name.toLowerCase()
                .includes(searchText.toLowerCase()) 
                || t.lastModifiedByDisplayName?.toLowerCase().includes(searchText.toLowerCase()))
                .map(t => ({theme: t, isOpen: false}))

            setFilteredThemes(newThemes);
        }
    }

    //Remove all filters.
    const resetSearch = () => {
        var newThemes = props.themes
            .map(t => ({theme: t, isOpen: false}));

        setFilteredThemes(newThemes);
        setSearchText("");
    }

    return (
        <div>
            <TextField
              variant="outlined"
              size="small"
              style={{width: "77%"}}
              value={searchText}
              placeholder="Search theme or author"
              InputProps={{
                startAdornment: <SearchIcon className="search-icon" />,
                endAdornment: filteredThemes.length !== props.themes.length 
                    && <CloseIcon htmlColor="grey" style={{cursor: "pointer"}} onClick={resetSearch}/>
              }}
              onChange={(e) => onUpdateTextToSearch(e.target.value)}
              onKeyUp={onKeyPress}
            />
            {totalOpen >= 1 && 
                <Button style={{float: "right"}} onClick={() => setFlip(false)}>
                    Collapse all
                </Button>
            }
            {totalOpen === 0 &&
                <Button style={{float: "right"}} onClick={() => setFlip(true)}>
                    Expand All
                </Button>
            }

            <div style={{marginTop: "15px", width: "100%", maxHeight: "530px", overflowY: "scroll"}}>
                {filteredThemes.map((theme, key) => 
                    <ThemeDisplay 
                        key={key} 
                        theme={theme.theme} 
                        isOpen={theme.isOpen} 
                        setOpen={setIndexOpen} 
                        index={key} 
                        selectedTheme={props.selectedTheme} 
                        onNewsletterChange={props.onNewsletterChange} 
                        newsletter={props.newsletter}
                    />
                )}
            </div>
        </div>
    );
}

interface ComponentProps {
    themes: NewsletterTheme[];
    selectedTheme: string;
    onNewsletterChange: (value: SaveNewsletterModel) => any;
    newsletter: SaveNewsletterModel;
}

export default TemplateThemeSearchAndSelect;