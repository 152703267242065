import { Action } from "redux";
import { CompleteAction, ActionCreator } from "typedActions";


export const GET_ACTIVITY_LOG = "GET_ACTIVITY_LOG";
export interface GetActivityLog extends Action {};
export const GetActivityLog = ActionCreator<GetActivityLog>(GET_ACTIVITY_LOG);

export const GET_ACTIVITY_LOG_COMPLETE = "GET_ACTIVITY_LOG_COMPLETE";
export interface GetActivityLogComplete extends CompleteAction {};
export const GetActivityLogComplete = ActionCreator<GetActivityLogComplete>(GET_ACTIVITY_LOG_COMPLETE);

export const GET_ACTIVITY_LOG_CSV = "GET_ACTIVITY_LOG_CSV";
export interface GetActivityLogCSV extends Action {};
export const GetActivityLogCSV = ActionCreator<GetActivityLogCSV>(GET_ACTIVITY_LOG_CSV);

export const GET_ACTIVITY_LOG_CSV_COMPLETE = "GET_ACTIVITY_LOG_CSV_COMPLETE";
export interface GetActivityLogCSVComplete extends CompleteAction {};
export const GetActivityLogCSVComplete = ActionCreator<GetActivityLogCSVComplete>(GET_ACTIVITY_LOG_CSV_COMPLETE);

export const GET_RECIPIENTS_CSV = "GET_RECIPIENTS_CSV";
export interface GetRecipientsCSV extends Action {};
export const GetRecipientsCSV = ActionCreator<GetRecipientsCSV>(GET_RECIPIENTS_CSV);

export const GET_RECIPIENTS_CSV_COMPLETE = "GET_RECIPIENTS_CSV_COMPLETE";
export interface GetRecipientsCSVComplete extends CompleteAction {};
export const GetRecipientsCSVComplete = ActionCreator<GetRecipientsCSVComplete>(GET_RECIPIENTS_CSV_COMPLETE);

export const GET_CONFIG = "GET_CONFIG";
export interface GetConfig extends Action {};
export const GetConfig = ActionCreator<GetConfig>(GET_CONFIG);

export const GET_CONFIG_COMPLETE = "GET_CONFIG_COMPLETE";
export interface GetConfigComplete extends CompleteAction {};
export const GetConfigComplete = ActionCreator<GetConfigComplete>(GET_CONFIG_COMPLETE);

export const GET_EVENT = "GET_EVENT";
export interface GetEvent extends Action {};
export const GetEvent = ActionCreator<GetEvent>(GET_EVENT);

export const GET_EVENT_COMPLETE = "GET_EVENT_COMPLETE";
export interface GetEventComplete extends CompleteAction {};
export const GetEventComplete = ActionCreator<GetEventComplete>(GET_EVENT_COMPLETE);

export const GET_INSIGHTS_DAILY = "GET_INSIGHTS_DAILY";
export interface GetDailyNewsletterInsights extends Action {};
export const GetDailyNewsletterInsights = ActionCreator<GetDailyNewsletterInsights>(GET_INSIGHTS_DAILY);

export const GET_INSIGHTS_DAILY_COMPLETE = "GET_INSIGHTS_DAILY_COMPLETE";
export interface GetDailyNewsletterInsightsComplete extends CompleteAction {};
export const GetDailyNewsletterInsightsComplete = ActionCreator<GetDailyNewsletterInsightsComplete>(GET_INSIGHTS_DAILY_COMPLETE);

export const GET_TOTAL_NEWSLETTER_INSIGHTS = "GET_TOTAL_NEWSLETTER_INSIGHTS";
export interface GetTotalNewsletterInsights extends Action {};
export const GetTotalNewsletterInsights = ActionCreator<GetTotalNewsletterInsights>(GET_TOTAL_NEWSLETTER_INSIGHTS);

export const GET_TOTAL_NEWSLETTER_INSIGHTS_COMPLETE = "GET_TOTAL_NEWSLETTER_INSIGHTS_COMPLETE";
export interface GetTotalNewsletterInsightsComplete extends CompleteAction {};
export const GetTotalNewsletterInsightsComplete = ActionCreator<GetTotalNewsletterInsightsComplete>(GET_TOTAL_NEWSLETTER_INSIGHTS_COMPLETE);

export const GET_INSIGHTS_WEEKLY = "GET_INSIGHTS_WEEKLY";
export interface GetWeeklyNewsletterInsights extends Action {};
export const GetWeeklyNewsletterInsights = ActionCreator<GetWeeklyNewsletterInsights>(GET_INSIGHTS_WEEKLY);

export const GET_INSIGHTS_WEEKLY_COMPLETE = "GET_INSIGHTS_WEEKLY_COMPLETE";
export interface GetWeeklyNewsletterInsightsComplete extends CompleteAction {};
export const GetWeeklyNewsletterInsightsComplete = ActionCreator<GetWeeklyNewsletterInsightsComplete>(GET_INSIGHTS_WEEKLY_COMPLETE);

export const GET_NEWSLETTER = "GET_NEWSLETTER";
export interface GetNewsletter extends Action {};
export const GetNewsletter = ActionCreator<GetNewsletter>(GET_NEWSLETTER);

export const GET_NEWSLETTER_COMPLETE = "GET_NEWSLETTER_COMPLETE";
export interface GetNewsletterComplete extends CompleteAction {};
export const GetNewsletterComplete = ActionCreator<GetNewsletterComplete>(GET_NEWSLETTER_COMPLETE);

export const GET_NEWSLETTER_DETAILS = "GET_NEWSLETTER_DETAILS";
export interface GetNewsletterDetails extends Action {};
export const GetNewsletterDetails = ActionCreator<GetNewsletterDetails>(GET_NEWSLETTER_DETAILS);

export const GET_NEWSLETTER_DETAILS_COMPLETE = "GET_NEWSLETTER_DETAILS_COMPLETE";
export interface GetNewsletterDetailsComplete extends CompleteAction {};
export const GetNewsletterDetailsComplete = ActionCreator<GetNewsletterDetailsComplete>(GET_NEWSLETTER_DETAILS_COMPLETE);

export const GET_NEWSLETTER_TAGS = "GET_NEWSLETTER_TAGS";
export interface GetNewsletterTags extends Action {};
export const GetNewsletterTags = ActionCreator<GetNewsletterTags>(GET_NEWSLETTER_TAGS);

export const GET_NEWSLETTER_TAGS_COMPLETE = "GET_NEWSLETTER_TAGS_COMPLETE";
export interface GetNewsletterTagsComplete extends CompleteAction { };
export const GetNewsletterTagsComplete = ActionCreator<GetNewsletterTagsComplete>(GET_NEWSLETTER_TAGS_COMPLETE);

export const GET_NEWSLETTERS = "GET_NEWSLETTERS";
export interface GetNewsletters extends Action {};
export const GetNewsletters = ActionCreator<GetNewsletters>(GET_NEWSLETTERS);

export const GET_NEWSLETTERS_COMPLETE = "GET_NEWSLETTERS_COMPLETE";
export interface GetNewslettersComplete extends CompleteAction { };
export const GetNewslettersComplete = ActionCreator<GetNewslettersComplete>(GET_NEWSLETTERS_COMPLETE);

export const GET_DISABLED_NEWSLETTERS = "GET_DISABLED_NEWSLETTERS";
export interface GetDisabledNewsletters extends Action {};
export const GetDisabledNewsletters = ActionCreator<GetDisabledNewsletters>(GET_DISABLED_NEWSLETTERS);

export const GET_DISABLED_NEWSLETTERS_COMPLETE = "GET_DISABLED_NEWSLETTERS_COMPLETE";
export interface GetDisabledNewslettersComplete extends CompleteAction { };
export const GetDisabledNewslettersComplete = ActionCreator<GetDisabledNewslettersComplete>(GET_DISABLED_NEWSLETTERS_COMPLETE);

export const GET_DRAFT_NEWSLETTERS = "GET_DRAFT_NEWSLETTERS";
export interface GetDraftNewsletters extends Action {};
export const GetDraftNewsletters = ActionCreator<GetDraftNewsletters>(GET_DRAFT_NEWSLETTERS);

export const GET_DRAFT_NEWSLETTERS_COMPLETE = "GET_DRAFT_NEWSLETTERS_COMPLETE";
export interface GetDraftNewslettersComplete extends CompleteAction { };
export const GetDraftNewslettersComplete = ActionCreator<GetDraftNewslettersComplete>(GET_DRAFT_NEWSLETTERS_COMPLETE);

export const GET_TEMPLATES = "GET_TEMPLATES";
export interface GetTemplates extends Action {};
export const GetTemplates = ActionCreator<GetTemplates>(GET_TEMPLATES);

export const GET_TEMPLATES_COMPLETE = "GET_TEMPLATES_COMPLETE";
export interface GetTemplatesComplete extends CompleteAction {};
export const GetTemplatesComplete = ActionCreator<GetTemplatesComplete>(GET_TEMPLATES_COMPLETE );

export const GET_THEMES = "GET_THEMES";
export interface GetThemes extends Action {};
export const GetThemes = ActionCreator<GetThemes>(GET_THEMES);

export const GET_THEMES_COMPLETE = "GET_THEMES_COMPLETE";
export interface GetThemesComplete extends CompleteAction {};
export const GetThemesComplete = ActionCreator<GetThemesComplete>(GET_THEMES_COMPLETE );

export const SET_THEME_DEFAULT = "SET_THEME_DEFAULT";
export interface SetThemeDefault extends Action {};
export const SetThemeDefault = ActionCreator<SetThemeDefault>(SET_THEME_DEFAULT);

export const SET_THEME_DEFAULT_COMPLETE = "SET_THEME_DEFAULT_COMPLETE";
export interface SetThemeDefaultComplete extends CompleteAction {};
export const SetThemeDefaultComplete = ActionCreator<SetThemeDefaultComplete>(SET_THEME_DEFAULT_COMPLETE);

export const DELETE_THEME = "DELETE_THEME";
export interface DeleteTheme extends Action {};
export const DeleteTheme = ActionCreator<DeleteTheme>(DELETE_THEME);

export const DELETE_THEME_COMPLETE = "DELETE_THEME_COMPLETE";
export interface DeleteThemeComplete extends CompleteAction {};
export const DeleteThemeComplete = ActionCreator<DeleteThemeComplete>(DELETE_THEME_COMPLETE);

export const SAVE_THEME = "SAVE_THEME";
export interface SaveTheme extends Action {};
export const SaveTheme = ActionCreator<SaveTheme>(SAVE_THEME);

export const SAVE_THEME_COMPLETE = "SAVE_THEME_COMPLETE";
export interface SaveThemeComplete extends CompleteAction {};
export const SaveThemeComplete = ActionCreator<SaveThemeComplete>(SAVE_THEME_COMPLETE);

export const SAVE_THEME_IMAGES = "SAVE_THEME_IMAGES";
export interface SaveThemeImages extends Action {};
export const SaveThemeImages = ActionCreator<SaveThemeImages>(SAVE_THEME_IMAGES);

export const SAVE_THEME_IMAGES_COMPLETE = "SAVE_THEME_IMAGES_COMPLETE";
export interface SaveThemeImagesComplete extends CompleteAction {};
export const SaveThemeImagesComplete = ActionCreator<SaveThemeImagesComplete>(SAVE_THEME_IMAGES_COMPLETE);

export const GET_THEME_IMAGE = "GET_THEME_IMAGE";
export interface GetThemeImage extends Action {};
export const GetThemeImage = ActionCreator<GetThemeImage>(GET_THEME_IMAGE);

export const GET_THEME_IMAGE_COMPLETE = "GET_THEME_IMAGE_COMPLETE";
export interface GetThemeImageComplete extends CompleteAction {};
export const GetThemeImageComplete = ActionCreator<GetThemeImageComplete>(GET_THEME_IMAGE_COMPLETE);

export const DELETE_THEME_IMAGE = "DELETE_THEME_IMAGE";
export interface DeleteThemeImage extends Action {};
export const DeleteThemeImage = ActionCreator<DeleteThemeImage>(DELETE_THEME_IMAGE);

export const DELETE_THEME_IMAGE_COMPLETE = "DELETE_THEME_IMAGE_COMPLETE";
export interface DeleteThemeImageComplete extends CompleteAction {};
export const DeleteThemeImageComplete = ActionCreator<DeleteThemeImageComplete>(DELETE_THEME_IMAGE_COMPLETE);

export const GET_NEWSLETTER_PREVIEW = "GET_NEWSLETTER_PREVIEW";
export interface GetNewsletterPreview extends Action {};
export const GetNewsletterPreview = ActionCreator<GetNewsletterPreview>(GET_NEWSLETTER_PREVIEW);

export const GET_NEWSLETTER_PREVIEW_COMPLETE = "GET_NEWSLETTER_PREVIEW_COMPLETE";
export interface GetNewsletterPreviewComplete extends CompleteAction {};
export const GetNewsletterPreviewComplete = ActionCreator<GetNewsletterPreviewComplete>(GET_NEWSLETTER_PREVIEW_COMPLETE);

export const GET_TEMPLATE_HTML = "GET_TEMPLATE_HTML";
export interface GetTemplateHtml extends Action {};
export const GetTemplateHtml = ActionCreator<GetTemplates>(GET_TEMPLATE_HTML);

export const GET_TEMPLATE_HTML_COMPLETE = "GET_TEMPLATE_HTML_COMPLETE";
export interface GetTemplateHtmlComplete extends CompleteAction {};
export const GetTemplateHtmlComplete = ActionCreator<GetTemplateHtmlComplete>(GET_TEMPLATE_HTML_COMPLETE );

export const GET_TEMPLATE_IMAGE = "GET_TEMPLATE_IMAGE";
export interface GetTemplateImage extends Action {};
export const GetTemplateImage = ActionCreator<GetTemplateImage>(GET_TEMPLATE_IMAGE);

export const GET_TEMPLATE_IMAGE_COMPLETE = "GET_TEMPLATE_IMAGE_COMPLETE";
export interface GetTemplateImageComplete extends CompleteAction {};
export const GetTemplateImageComplete = ActionCreator<GetTemplateImageComplete>(GET_TEMPLATE_IMAGE_COMPLETE );

export const GET_ENABLED_NEWSLETTERS = "GET_ENABLED_NEWSLETTERS";
export interface GetEnabledNewsletters extends Action {};
export const GetEnabledNewsletters = ActionCreator<GetEnabledNewsletters>(GET_ENABLED_NEWSLETTERS);

export const GET_ENABLED_NEWSLETTERS_COMPLETE = "GET_ENABLED_NEWSLETTERS_COMPLETE";
export interface GetEnabledNewslettersComplete extends CompleteAction {};
export const GetEnabledNewslettersComplete = ActionCreator<GetEnabledNewslettersComplete>(GET_ENABLED_NEWSLETTERS_COMPLETE);

export const GET_PAST_ISSUES = "GET_PAST_ISSUES";
export interface GetPastIssues extends Action {};
export const GetPastIssues = ActionCreator<GetPastIssues>(GET_PAST_ISSUES);

export const GET_PAST_ISSUES_COMPLETE = "GET_PAST_ISSUES_COMPLETE";
export interface GetPastIssuesComplete extends CompleteAction {};
export const GetPastIssuesComplete = ActionCreator<GetPastIssuesComplete>(GET_PAST_ISSUES_COMPLETE);

export const GET_PAST_ISSUE = "GET_PAST_ISSUE";
export interface GetPastIssue extends Action {};
export const GetPastIssue = ActionCreator<GetPastIssue>(GET_PAST_ISSUE);

export const GET_PAST_ISSUE_COMPLETE = "GET_PAST_ISSUE_COMPLETE";
export interface GetPastIssueComplete extends CompleteAction {};
export const GetPastIssueComplete = ActionCreator<GetPastIssueComplete>(GET_PAST_ISSUE_COMPLETE);

export const GET_FUTURE_ISSUE_DETAILS = "GET_FUTURE_ISSUE_DETAILS";
export interface GetFutureIssueDetails extends Action {};
export const GetFutureIssueDetails = ActionCreator<GetFutureIssueDetails>(GET_FUTURE_ISSUE_DETAILS);

export const GET_FUTURE_ISSUE_DETAILS_COMPLETE = "GET_FUTURE_ISSUE_DETAILS_COMPLETE";
export interface GetFutureIssueDetailsComplete extends CompleteAction {};
export const GetFutureIssueDetailsComplete = ActionCreator<GetFutureIssueDetailsComplete>(GET_FUTURE_ISSUE_DETAILS_COMPLETE);

export const GET_POST = "GET_POST";
export interface GetPost extends Action {};
export const GetPost = ActionCreator<GetPost>(GET_POST);

export const GET_POST_COMPLETE = "GET_POST_COMPLETE";
export interface GetPostComplete extends CompleteAction {};
export const GetPostComplete = ActionCreator<GetPostComplete>(GET_POST_COMPLETE);

export const GET_ISSUE_IMAGE = "GET_ISSUE_IMAGE";
export interface GetIssueImage extends Action {};
export const GetIssueImage = ActionCreator<GetIssueImage>(GET_ISSUE_IMAGE);

export const GET_ISSUE_IMAGE_COMPLETE = "GET_ISSUE_IMAGE_COMPLETE";
export interface GetIssueImageComplete extends CompleteAction {};
export const GetIssueImageComplete = ActionCreator<GetIssueImageComplete>(GET_ISSUE_IMAGE_COMPLETE);

export const UPLOAD_ISSUE_IMAGE = "UPLOAD_ISSUE_IMAGE";
export interface UploadIssueImage extends Action {};
export const UploadIssueImage = ActionCreator<UploadIssueImage>(UPLOAD_ISSUE_IMAGE);

export const UPLOAD_ISSUE_IMAGE_COMPLETE = "UPLOAD_ISSUE_IMAGE_COMPLETE";
export interface UploadIssueImageComplete extends CompleteAction {};
export const UploadIssueImageComplete = ActionCreator<UploadIssueImageComplete>(UPLOAD_ISSUE_IMAGE_COMPLETE);

export const UPLOAD_HEADER_FOOTER_ISSUE_IMAGE = "UPLOAD_HEADER_FOOTER_ISSUE_IMAGE";
export interface UploadHeaderFooterIssueImage extends Action {};
export const UploadHeaderFooterIssueImage = ActionCreator<UploadHeaderFooterIssueImage>(UPLOAD_HEADER_FOOTER_ISSUE_IMAGE);

export const UPLOAD_HEADER_FOOTER_ISSUE_IMAGE_COMPLETE = "UPLOAD_HEADER_FOOTER_ISSUE_IMAGE_COMPLETE";
export interface UploadHeaderFooterIssueImageComplete extends CompleteAction {};
export const UploadHeaderFooterIssueImageComplete = ActionCreator<UploadHeaderFooterIssueImageComplete>(UPLOAD_HEADER_FOOTER_ISSUE_IMAGE_COMPLETE);

export const DELETE_ISSUE_IMAGE = "DELETE_ISSUE_IMAGE";
export interface DeleteIssueImage extends Action {};
export const DeleteIssueImage = ActionCreator<DeleteIssueImage>(DELETE_ISSUE_IMAGE);

export const DELETE_ISSUE_IMAGE_COMPLETE = "DELETE_ISSUE_IMAGE_COMPLETE";
export interface DeleteIssueImageComplete extends CompleteAction {};
export const DeleteIssueImageComplete = ActionCreator<DeleteIssueImageComplete>(DELETE_ISSUE_IMAGE_COMPLETE);

export const SAVE_NEWSLETTER = "SAVE_NEWSLETTER";
export interface SaveNewsletter extends Action {};
export const SaveNewsletter = ActionCreator<SaveNewsletter>(SAVE_NEWSLETTER);

export const SAVE_NEWSLETTER_COMPLETE = "SAVE_NEWSLETTER_COMPLETE";
export interface SaveNewsletterComplete extends CompleteAction {};
export const SaveNewsletterComplete = ActionCreator<SaveNewsletterComplete>(SAVE_NEWSLETTER_COMPLETE);

export const GET_NEWSLETTER_IMAGE = "GET_NEWSLETTER_IMAGE";
export interface GetNewsletterImage extends Action {};
export const GetNewsletterImage = ActionCreator<GetNewsletterImage>(GET_NEWSLETTER_IMAGE);

export const GET_NEWSLETTER_IMAGE_COMPLETE = "GET_NEWSLETTER_IMAGE_COMPLETE";
export interface GetNewsletterImageComplete extends CompleteAction {};
export const GetNewsletterImageComplete = ActionCreator<GetNewsletterImageComplete>(GET_NEWSLETTER_IMAGE_COMPLETE);

export const UPLOAD_NEWSLETTER_IMAGE = "UPLOAD_NEWSLETTER_IMAGE";
export interface UploadNewsletterImage extends Action {};
export const UploadNewsletterImage = ActionCreator<UploadNewsletterImage>(UPLOAD_NEWSLETTER_IMAGE);

export const UPLOAD_NEWSLETTER_IMAGE_COMPLETE = "UPLOAD_NEWSLETTER_IMAGE_COMPLETE";
export interface UploadNewsletterImageComplete extends CompleteAction {};
export const UploadNewsletterImageComplete = ActionCreator<UploadNewsletterImageComplete>(UPLOAD_NEWSLETTER_IMAGE_COMPLETE);

export const UPLOAD_NEWSLETTER_HEADER_FOOTER_IMAGE = "UPLOAD_NEWSLETTER_HEADER_FOOTER_IMAGE";
export interface UploadNewsletterHeaderFooterImage extends Action {};
export const UploadNewsletterHeaderFooterImage = ActionCreator<UploadNewsletterHeaderFooterImage>(UPLOAD_NEWSLETTER_HEADER_FOOTER_IMAGE);


export const UPLOAD_NEWSLETTER_HEADER_FOOTER_IMAGE_COMPLETE = "UPLOAD_NEWSLETTER_HEADER_FOOTER_IMAGE_COMPLETE";
export interface UploadNewsletterHeaderFooterImageComplete extends Action {};
export const UploadNewsletterHeaderFooterImageComplete = ActionCreator<UploadNewsletterHeaderFooterImageComplete>(UPLOAD_NEWSLETTER_HEADER_FOOTER_IMAGE_COMPLETE);

export const DELETE_NEWSLETTER_IMAGE = "DELETE_NEWSLETTER_IMAGE";
export interface DeleteNewsletterImage extends Action {};
export const DeleteNewsletterImage = ActionCreator<DeleteNewsletterImage>(DELETE_NEWSLETTER_IMAGE);

export const DELETE_NEWSLETTER_IMAGE_COMPLETE = "DELETE_NEWSLETTER_IMAGE_COMPLETE";
export interface DeleteNewsletterImageComplete extends CompleteAction {};
export const DeleteNewsletterImageComplete = ActionCreator<DeleteNewsletterImageComplete>(DELETE_NEWSLETTER_IMAGE_COMPLETE);

export const RESET_ISSUE = "RESET_ISSUE";
export interface ResetIssue extends Action {};
export const ResetIssue = ActionCreator<ResetIssue>(RESET_ISSUE);

export const RESET_ISSUE_COMPLETE = "RESET_ISSUE_COMPLETE";
export interface ResetIssueComplete extends CompleteAction {};
export const ResetIssueComplete = ActionCreator<ResetIssueComplete>(RESET_ISSUE_COMPLETE);

export const RESET_ISSUES = "RESET_ISSUES";
export interface ResetIssues extends Action {};
export const ResetIssues = ActionCreator<ResetIssues>(RESET_ISSUES);

export const RESET_ISSUES_COMPLETE = "RESET_ISSUES_COMPLETE";
export interface ResetIssuesComplete extends CompleteAction {};
export const ResetIssuesComplete = ActionCreator<ResetIssuesComplete>(RESET_ISSUES_COMPLETE);

export const SAVE_CONFIG = "SAVE_CONFIG";
export interface SaveConfig extends Action {};
export const SaveConfig = ActionCreator<SaveConfig>(SAVE_CONFIG);

export const SAVE_CONFIG_COMPLETE = "SAVE_CONFIG_COMPLETE";
export interface SaveConfigComplete extends CompleteAction {};
export const SaveConfigComplete = ActionCreator<SaveConfigComplete>(SAVE_CONFIG_COMPLETE);

export const SAVE_ISSUE = "SAVE_ISSUE";
export interface SaveIssue extends Action {};
export const SaveIssue = ActionCreator<SaveIssue>(SAVE_ISSUE);

export const SAVE_ISSUE_COMPLETE = "SAVE_ISSUE_COMPLETE";
export interface SaveIssueComplete extends CompleteAction {};
export const SaveIssueComplete = ActionCreator<SaveIssueComplete>(SAVE_ISSUE_COMPLETE);

export const SEARCH_EVENTS = "SEARCH_EVENTS";
export interface SearchEvents extends Action {};
export const SearchEvents = ActionCreator<SearchEvents>(SEARCH_EVENTS);

export const SEARCH_EVENTS_COMPLETE = "SEARCH_EVENTS_COMPLETE";
export interface SearchEventsComplete extends CompleteAction {};
export const SearchEventsComplete = ActionCreator<SearchEventsComplete>(SEARCH_EVENTS_COMPLETE);

export const SEARCH_POSTS = "SEARCH_POSTS";
export interface SearchPosts extends Action {};
export const SearchPosts = ActionCreator<SearchPosts>(SEARCH_POSTS);

export const SEARCH_POSTS_COMPLETE = "SEARCH_POSTS_COMPLETE";
export interface SearchPostsComplete extends CompleteAction {};
export const SearchPostsComplete = ActionCreator<SearchPostsComplete>(SEARCH_POSTS_COMPLETE);

export const SEND_TEST_EMAIL = "SEND_TEST_EMAIL";
export interface SendTestEmail extends Action {};
export const SendTestEmail = ActionCreator<SendTestEmail>(SEND_TEST_EMAIL);

export const SEND_TEST_EMAIL_COMPLETE = "SEND_TEST_EMAIL_COMPLETE";
export interface SendTestEmailComplete extends CompleteAction {};
export const SendTestEmailComplete = ActionCreator<SendTestEmailComplete>(SEND_TEST_EMAIL_COMPLETE);

export const SKIP_ISSUE = "SKIP_ISSUE";
export interface SkipIssue extends Action {};
export const SkipIssue = ActionCreator<SkipIssue>(SKIP_ISSUE);

export const SKIP_ISSUE_COMPLETE = "SKIP_ISSUE_COMPLETE";
export interface SkipIssueComplete extends CompleteAction {};
export const SkipIssueComplete = ActionCreator<SkipIssueComplete>(SKIP_ISSUE_COMPLETE);

export const UNSKIP_ISSUE = "UNSKIP_ISSUE";
export interface UnskipIssue extends Action {};
export const UnskipIssue = ActionCreator<UnskipIssue>(UNSKIP_ISSUE);

export const UNSKIP_ISSUE_COMPLETE = "UNSKIP_ISSUE_COMPLETE";
export interface UnskipIssueComplete extends CompleteAction {};
export const UnskipIssueComplete = ActionCreator<UnskipIssueComplete>(UNSKIP_ISSUE_COMPLETE);

export const CLONE_NEWSLETTER = "CLONE_NEWSLETTER";
export interface CloneNewsletter extends Action {};
export const CloneNewsletter = ActionCreator<CloneNewsletter>(CLONE_NEWSLETTER);

export const CLONE_NEWSLETTER_COMPLETE = "CLONE_NEWSLETTER_COMPLETE";
export interface CloneNewsletterComplete extends CompleteAction {};
export const CloneNewsletterComplete = ActionCreator<CloneNewsletterComplete>(CLONE_NEWSLETTER_COMPLETE);

export const DELETE_NEWSLETTER = "DELETE_NEWSLETTER";
export interface DeleteNewsletter extends Action {};
export const DeleteNewsletter = ActionCreator<DeleteNewsletter>(DELETE_NEWSLETTER);

export const DELETE_NEWSLETTER_COMPLETE = "DELETE_NEWSLETTER_COMPLETE";
export interface DeleteNewsletterComplete extends CompleteAction {};
export const DeleteNewsletterComplete = ActionCreator<DeleteNewsletterComplete>(DELETE_NEWSLETTER_COMPLETE);

export const DISABLE_NEWSLETTER = "DISABLE_NEWSLETTER";
export interface DisableNewsletter extends Action {};
export const DisableNewsletter = ActionCreator<DisableNewsletter>(DISABLE_NEWSLETTER);

export const DISABLE_NEWSLETTER_COMPLETE = "DISABLE_NEWSLETTER_COMPLETE";
export interface DisableNewsletterComplete extends CompleteAction {};
export const DisableNewsletterComplete = ActionCreator<DisableNewsletterComplete>(DISABLE_NEWSLETTER_COMPLETE);

export const ENABLE_NEWSLETTER = "ENABLE_NEWSLETTER";
export interface EnableNewsletter extends Action {};
export const EnableNewsletter = ActionCreator<EnableNewsletter>(ENABLE_NEWSLETTER);

export const ENABLE_NEWSLETTER_COMPLETE = "ENABLE_NEWSLETTER_COMPLETE";
export interface EnableNewsletterComplete extends CompleteAction {};
export const EnableNewsletterComplete = ActionCreator<EnableNewsletterComplete>(ENABLE_NEWSLETTER_COMPLETE);

export const GET_ASSOCIATED_NEWSLETTERS = "GET_ASSOCIATED_NEWSLETTERS";
export interface GetAssociatedNewsletters extends Action{};
export const GetAssociatedNewsletters = ActionCreator<GetAssociatedNewsletters>(GET_ASSOCIATED_NEWSLETTERS);