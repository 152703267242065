import React, { useState } from "react";

import { InfoOutlined, Replay } from "@mui/icons-material";
import { IconButton } from "@mui/material";

import DrawerWithBookmarkButton from "modules/common/components/drawers/drawerWithBookmarkButton";
import Disclaimer from "modules/common/components/disclaimer";
import { IColorOption } from "modules/homeScreens/models";
import ColorOption from "modules/newsletters/edit/components/colorOption";
import HoverText from "modules/documents/components/action-buttons/hoverText";
import { deepStrictEqualWrapper } from "utils/equalityUtils";

import "../../styles/contentBandsSettingsPanel.sass";

interface IContentBandsSettingsEditorProps {
    open: boolean;
    gradientColor: IColorOption;
    headerTextColor: IColorOption;
    pristineGradientColor: IColorOption;
    pristineHeaderTextColor: IColorOption;
    toggleOpen: () => void;
    onSetGradientColor: (newColor: IColorOption) => void;
    onSetHeaderTextColor: (newColor: IColorOption) => void;
}

export const ContentBandsSettingsPanel: React.FunctionComponent<IContentBandsSettingsEditorProps> = ({
    open,
    gradientColor,
    headerTextColor,
    pristineGradientColor,
    pristineHeaderTextColor,
    onSetGradientColor,
    onSetHeaderTextColor,
    toggleOpen,
}) => {
    const [isHeaderColorPickerVisible, setIsHeaderColorPickerVisible] = useState<boolean>(false);
    const [isGradientColorPickerVisible, setIsGradientColorPickerVisible] = useState<boolean>(false);

    const getResetButton = (onClick: () => void): JSX.Element => (
        <IconButton onClick={onClick}>
            <Replay htmlColor="#3b78ab" />
        </IconButton>
    );

    const resetGradient = () => {
        onSetGradientColor(pristineGradientColor);
    };

    const resetHeaderTextColor = () => {
        onSetHeaderTextColor(pristineHeaderTextColor);
    };

    const shouldShowResetGradient = (): boolean => !deepStrictEqualWrapper(pristineGradientColor, gradientColor);

    const shouldShowResetHeaderColor = (): boolean => !deepStrictEqualWrapper(pristineHeaderTextColor, headerTextColor);

    return (
        <DrawerWithBookmarkButton
            open={open}
            onBookmarkClick={toggleOpen}
            PaperProps={{
                id: "content-bands-settings-drawer",
            }}
            showBookmark={false}
        >
            <div id="content-bands-settings-editor">
                <Disclaimer
                    text={
                        "Content shown vary per user, based on their topic subscriptions, the content they have access to, and the settings applied to each content band."
                    }
                    icon={<InfoOutlined htmlColor="#e6911a" />}
                />
                <div className="form-group">
                    <label>Header text</label>
                    <div
                        style={{
                            display: "flex",
                        }}
                    >
                        {shouldShowResetHeaderColor() && getResetButton(resetHeaderTextColor)}
                        <ColorOption
                            pickerContainerStyle={{
                                position: "absolute",
                                right: 15,
                            }}
                            isColorPickerVisible={isHeaderColorPickerVisible}
                            colorSelected={headerTextColor.hexCode}
                            setColor={onSetHeaderTextColor}
                            setVisible={setIsHeaderColorPickerVisible}
                            changeText={(newHex: string) => onSetHeaderTextColor({ hexCode: newHex, rgb: { ...headerTextColor.rgb } })}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <HoverText label="Background accent color">
                        Applied as the background color for when using the Window or Carousel layout.
                    </HoverText>
                    <div
                        style={{
                            display: "flex",
                        }}
                    >
                        {shouldShowResetGradient() && getResetButton(resetGradient)}
                        <ColorOption
                            pickerContainerStyle={{
                                position: "absolute",
                                right: 15,
                            }}
                            isColorPickerVisible={isGradientColorPickerVisible}
                            colorSelected={gradientColor.hexCode}
                            setColor={onSetGradientColor}
                            setVisible={setIsGradientColorPickerVisible}
                            changeText={(newHex: string) => onSetHeaderTextColor({ hexCode: newHex, rgb: { ...gradientColor.rgb } })}
                        />
                    </div>
                </div>
            </div>
        </DrawerWithBookmarkButton>
    );
};
