import * as React from "react";

import AuthoringSearch from "modules/common/components/filters/authoringSearch";
import FilterContainer from "modules/common/components/filters/filterContainer";
import FilterDropdown from "modules/common/components/filters/filterDropdown";

import { EmailActivityEvent, ActivityLogFilterValues, emailActivityEvents, toEmailActivityEventName, SimpleTag } from "../../models";

import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import _ from "lodash";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { PickerLocalization } from "modules/common/components/pickerLocalization";

class EmailActivityLogFilters extends React.Component<ComponentProps, ComponentState> {
  constructor(props: ComponentProps) {
    super(props);

    this.state = {
      canSearch: false
    };
  }

  public render() {
    const { filters } = this.props;

    return (
      <FilterContainer
        filters={
          <React.Fragment>
            <FilterDropdown text="Activity">
              <List disablePadding>
                {emailActivityEvents.map((emailActivityEvent) =>
                  <ListItem key={emailActivityEvent} dense button onClick={() => this.onToggleEventName(emailActivityEvent)}>
                    <ListItemIcon className="callout-checkbox">
                      <Checkbox
                        edge="start"
                        tabIndex={-1}
                        disableRipple
                        size="small"
                        color="primary"
                        checked={_.some(filters.eventNames,(name) => name === emailActivityEvent)}
                      />
                    </ListItemIcon>
                    <ListItemText primary={toEmailActivityEventName(emailActivityEvent)} />
                  </ListItem>
                )}
              </List>
            </FilterDropdown>
            {!!this.props.newsletterTags && <FilterDropdown text="Newsletters">
              <List disablePadding>
                {this.props.newsletterTags.map((tag) =>
                  <ListItem key={tag.id} dense button onClick={() => this.onToggleNewsletter(tag.id)}>
                    <ListItemIcon className="callout-checkbox">
                      <Checkbox
                        edge="start"
                        tabIndex={-1}
                        disableRipple
                        size="small"
                        color="primary"
                        checked={_.some(filters.newsletterIds,(id) => id === tag.id)}
                      />
                    </ListItemIcon>
                    <ListItemText primary={tag.name} />
                  </ListItem>
                )}
              </List>
            </FilterDropdown>}
            {!!this.props.issueTags && <FilterDropdown text="Issues">
              <List disablePadding>
                {this.props.issueTags.map((tag) =>
                  <ListItem key={tag.id} dense button onClick={() => this.onToggleIssue(tag.id)}>
                    <ListItemIcon className="callout-checkbox">
                      <Checkbox
                        edge="start"
                        tabIndex={-1}
                        disableRipple
                        size="small"
                        color="primary"
                        checked={_.some(filters.issueIds,(id) => id === tag.id)}
                      />
                    </ListItemIcon>
                    <ListItemText primary={moment(tag.name).format("MMM D, YYYY")} />
                  </ListItem>
                )}
              </List>
            </FilterDropdown>}
            <FilterDropdown text="Event time">
              <PickerLocalization>
                <div className="event-time">
                  <div>
                    <div>From</div>
                    <DatePicker
                        format={"MMM dd yyyy"}
                        value={filters.eventDateFrom ? Date.parse(filters.eventDateFrom) : null}
                        slotProps={{ 
                            textField: { size: "small", placeholder: "", inputProps: { readOnly: true }},
                            openPickerIcon: { color: "primary" }
                        }}
                        onChange={this.onChangeEventDateFrom}
                        className="date-picker"
                    />
                  </div>
                  <div>
                    <div>To</div>
                    <DatePicker
                      format={"MMM dd yyyy"}
                      value={filters.eventDateTo ? Date.parse(filters.eventDateTo) : null}
                      slotProps={{ 
                          textField: { size: "small", placeholder: "", inputProps: { readOnly: true }},
                          openPickerIcon: { color: "primary" }
                      }}
                      onChange={this.onChangeEventDateTo}
                      className="date-picker"
                    />
                  </div>
                </div>
              </PickerLocalization>
            </FilterDropdown>
          </React.Fragment>
        }
        filterCommands={
          <AuthoringSearch
            canSearch={this.state.canSearch}
            onApplyFilters={() => this.onChangeFilters(this.props.filters)}
            onClearFilters={this.onClearFilters}
          />
        }
        filterSelection={this.getFilterSelection()}
      />
    );
  }
  
  private getFilterSelection = () => {
    const { filters, newsletterTags, issueTags } = this.props;

    return (
      <React.Fragment>
        {filters.eventNames.map((selectedEventName) =>
          <Chip
            key={selectedEventName}
            label={selectedEventName}
            onDelete={() => this.onClearEventName(selectedEventName)}
            deleteIcon={<CloseIcon />}
          />
        )}
        {!!newsletterTags && filters.newsletterIds.map((newsletterId) =>
          <Chip
            key={newsletterId}
            label={_.first(_.filter(newsletterTags, tag => tag.id === newsletterId))?.name}
            onDelete={() => this.onClearNewsletter(newsletterId)}
            deleteIcon={<CloseIcon />}
          />
        )}
        {!!issueTags && filters.issueIds.map((issueId) =>
          <Chip
            key={issueId}
            label={moment(_.first(_.filter(issueTags, tag => tag.id === issueId))?.name).format("MMM D, YYYY")}
            onDelete={() => this.onClearIssue(issueId)}
            deleteIcon={<CloseIcon />}
          />
        )}
        {!!filters.eventDateFrom && <Chip
            label={`From ${moment(filters.eventDateFrom).format("MMM D, YYYY")}`}
            onDelete={this.onClearEventDateFrom}
            deleteIcon={<CloseIcon />}
          /> }
          {!!filters.eventDateTo && <Chip
              label={`To ${moment(filters.eventDateTo).format("MMM D, YYYY")}`}
              onDelete={this.onClearEventDateTo}
              deleteIcon={<CloseIcon />}
            /> }
      </React.Fragment>
    );
  }
  
  private onToggleEventName = (selectedEventName: EmailActivityEvent) => {
    const isRemoving = _.some(this.props.filters.eventNames,(e) => e === selectedEventName);
    if (isRemoving)
      this.onChangeFilters({...this.props.filters, eventNames: _.filter(this.props.filters.eventNames, e => selectedEventName !== e) });
    else
      this.onChangeFilters({ ...this.props.filters, eventNames: _.concat(this.props.filters.eventNames,[selectedEventName]) });
  }

  private onToggleNewsletter = (selectedNewsletterId: string) => {
    const isRemoving = _.some(this.props.filters.newsletterIds,(e) => e === selectedNewsletterId);
    if (isRemoving)
      this.onChangeFilters({...this.props.filters, newsletterIds: _.filter(this.props.filters.newsletterIds, e => selectedNewsletterId !== e) });
    else
      this.onChangeFilters({ ...this.props.filters, newsletterIds: _.concat(this.props.filters.newsletterIds, [selectedNewsletterId]) });
  }

  private onToggleIssue = (selectedIssueId: string) => {
    const isRemoving = _.some(this.props.filters.issueIds,(e) => e === selectedIssueId);
    if (isRemoving)
      this.onChangeFilters({...this.props.filters, issueIds: _.filter(this.props.filters.issueIds, e => selectedIssueId !== e) });
    else
      this.onChangeFilters({ ...this.props.filters, issueIds: _.concat(this.props.filters.issueIds, [selectedIssueId]) });
  }

  private onChangeEventDateFrom = (date) => {
    this.onChangeFilters({...this.props.filters, eventDateFrom: !!date ? date : null });
  }

  private onChangeEventDateTo = (date) => {
    this.onChangeFilters({...this.props.filters, eventDateTo: !!date ? date : null });
  }

  private onChangeFilters = (value: ActivityLogFilterValues) => {
    this.setState({ canSearch: true });
    this.props.onChangeFilters(value);
  }

  private onClearEventName = (selectedEventName: EmailActivityEvent) => {
    this.props.onChangeFilters({...this.props.filters, eventNames: _.filter(this.props.filters.eventNames, e => selectedEventName !== e) });
  }

  private onClearNewsletter = (selectedNewsletterId: string) => {
    this.props.onChangeFilters({...this.props.filters, newsletterIds: _.filter(this.props.filters.newsletterIds, e => selectedNewsletterId !== e) });
  }

  private onClearIssue = (selectedIssueId: string) => {
    this.props.onChangeFilters({...this.props.filters, issueIds: _.filter(this.props.filters.issueIds, e => selectedIssueId !== e) });
  }

  private onClearEventDateFrom = () => {
    this.props.onChangeFilters({...this.props.filters, eventDateFrom: null });
  }

  private onClearEventDateTo = () => {
    this.props.onChangeFilters({...this.props.filters, eventDateTo: null });
  }

  private onClearFilters = () => {
    this.setState({ canSearch: false });
    this.props.onClearFilters();
  }
}

interface ComponentProps {
  filters: ActivityLogFilterValues;
  newsletterTags?: SimpleTag[];
  issueTags?: SimpleTag[];
  onChangeFilters: (filters: ActivityLogFilterValues) => void;
  onClearFilters: () => void;
}

interface ComponentState {
  canSearch: boolean;
}

export default EmailActivityLogFilters;