import React from "react"
import { ConnectedProps, connect } from "react-redux";
import * as actions from "../actionCreator";
import * as settingsActions from "modules/settings/actionCreator";
import { withRouter, RouteComponentProps } from "react-router";
import { GlobalApplicationState } from "globalApplicationState";

import ErrorSnackbar from "modules/common/components/snackbars/errorSnackbar";
import Loading from "modules/common/components/loading";

import Preferences from "modules/profile/components/preferences";
import ProfileDetails from "modules/profile/components/profileDetails";

import Divider from "@mui/material/Divider";


class Details extends React.Component<PropsWithRedux, {}> {
  public componentDidMount() {
    if (!!this.props.currentUser.userId)
      this.initializeUserSettings();
    
    if(!!this.props.tenantSettings)
      this.props.getTenantConfig();
  }

  public componentDidUpdate(prevProps: PropsWithRedux) {
    if (prevProps.currentUser.userId !== this.props.currentUser.userId)
      this.initializeUserSettings();
  }

  public render() {
    if (this.props.isInitialLoad || !this.props.userSettings)
      return <Loading />;
    
    return (
      <React.Fragment>
        <ProfileDetails />
        <Divider light />
        <Preferences />
        <ErrorSnackbar errorMessage={this.props.errorMessage} clearErrorMessage={this.props.clearErrorMessage} />
      </React.Fragment>
    );
  }

  private initializeUserSettings = () => {
    this.props.initializeUserSettings(this.props.currentUser);
  }
}

interface ComponentProps {}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps & RouteComponentProps) => ({
    ...ownProps,
    currentUser: state.settings.currentUser,
    errorMessage: state.profile.errorMessage,
    isInitialLoad: state.profile.isInitialLoad,
    isSaving: state.profile.isSaving,
    userSettings: state.profile.userSettings,
    tenantSettings: state.settings.tenantSettings
  }),
  {
    clearErrorMessage: actions.clearErrorMessage,
    initializeUserSettings: actions.initializeUserSettings,
    getTenantConfig: settingsActions.getTenantSettings
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default withRouter(connector(Details));