import React from 'react';

interface Props {
    stats: string | number,
    title: string,
    icon?: any
}

const UserInsightsBlockTiny = ({ stats, title, icon }: Props) => {
    
    return (
        <div className="block-container-tiny">
            <div className="block-container-info-tiny">
                {icon}
                <div className="insights-stats-tiny">
                    {stats}
                </div>
                <div className="insights-title-tiny">
                    {title}
                </div>
            </div>
        </div>
    )
}

export default UserInsightsBlockTiny;