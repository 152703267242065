import * as React from "react";
import { useState } from "react";
import { AuthenticationResult } from "@azure/msal-browser";
import { Redirect } from "react-router";

import MsalAuthModule, { ICustomState } from "./msalAuthModule";
import useIsMounted from "modules/common/hooks/useIsMounted";
import { QUERY_PARAM_KEYS } from "modules/common/hooks/useQueryParams";

const AuthRedirectHandler: React.FunctionComponent<{}> = (props) => {
    const [handlingRedirectResponse, setHandlingRedirectResponse] = useState<boolean>();
    const [redirectTo, setRedirectTo] = useState<string>();
    const isMounted = useIsMounted();

    React.useEffect(() => {
        const handleAuthRedirectResult = (redirectResponse: AuthenticationResult | null) => {
            try {
                if (redirectResponse) {
                    setHandlingRedirectResponse(true);
                    let jsonStr = redirectResponse.state || "{}";
                    let { originatedFrom }: ICustomState = JSON.parse(jsonStr);

                    if (originatedFrom && !originatedFrom.includes("/invitation?inviteToken") && !originatedFrom.includes(QUERY_PARAM_KEYS.SIGNED_OUT_SUCCESS)) {
                        let url = new URL(originatedFrom);
                        if (isMounted()) setRedirectTo(url.pathname + url.search);
                    }
                }
            } catch (error) {
                // defualt to redirect to home
                if (isMounted()) setRedirectTo(undefined);
            } finally {
                if (isMounted()) setHandlingRedirectResponse(false);
            }
        };

        MsalAuthModule.getInstance().initRedirectHandler(handleAuthRedirectResult);
    }, [isMounted]);

    return (
        <>
            {handlingRedirectResponse
                ? <></>
                : (redirectTo
                    ? <Redirect to={redirectTo} />
                    : props.children)}
        </>
    )
};

export default AuthRedirectHandler;
