import React from "react";
import {Select, FormControl, MenuItem } from "@mui/material";

import { SortStyle } from "utils/managementUtils";
import { IContentBandInputWithContentType } from "./layoutInput";
import { ContentBandContentType } from "modules/contentBands/models";

export const SortByInput: React.FunctionComponent<IContentBandInputWithContentType<SortStyle>> = ({ idx, value, contentType, onChange }) => {
    return (
        <div className="form-group">
            <label>Sort by</label>
            <FormControl>
                {contentType === ContentBandContentType.Post ? (
                    <Select
                        variant="outlined"
                        className="input"
                        value={value}
                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => onChange(Number(evt.target.value) as SortStyle, idx)}
                        MenuProps={{ disablePortal: true }} // clickawaylistener breaks with selects as children when portal is enabled (more info: https://mui.com/joy-ui/api/menu/#Menu-prop-disablePortal)
                    >
                        <MenuItem id={`cb-sort-by-${idx}-po`} value={SortStyle.publishAsc}>
                            Oldest first
                        </MenuItem>
                        <MenuItem id={`cb-sort-by-${idx}-pn`} value={SortStyle.publishDesc}>
                            Newest first
                        </MenuItem>
                    </Select>
                ) : (
                    <Select
                        variant="outlined"
                        className="input"
                        value={value}
                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => onChange(Number(evt.target.value) as SortStyle, idx)}
                        MenuProps={{ disablePortal: true }} // clickawaylistener breaks with selects as children when portal is enabled (more info: https://mui.com/joy-ui/api/menu/#Menu-prop-disablePortal)
                    >
                        <MenuItem id={`cb-sort-by-${idx}-en`} value={SortStyle.startAsc}>
                            Start date - Nearest first
                        </MenuItem>
                        <MenuItem id={`cb-sort-by-${idx}-ef`} value={SortStyle.startDesc}>
                            Start date - Farthest first
                        </MenuItem>
                    </Select>
                )}
            </FormControl>
        </div>
    );
};
