import * as React from "react";

import TextField from "@mui/material/TextField";


const Title: React.FunctionComponent<ComponentProps> = props => {
  return (
    <div>
      <div>Document title</div>
      <TextField
        variant="outlined"
        size="small"
        placeholder="Enter title (optional)"
        value={props.title}
        fullWidth
        inputProps={{
          maxLength: 50
        }}
        onChange={(event) => props.onChange(event.target.value)}
      />
    </div>
  );
}


interface ComponentProps {
  title: string;
  onChange: (title: string) => void;
}

export default Title;