import React from "react";

import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";


interface InputFieldProps {
  description?: string;
  label: string;
  error?: boolean;
  value: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  helperText?: string;
  onChange?: (event: any) => void;
  onCheckEmptyField?: (event: any) => void;
}

const InputField: React.FunctionComponent<InputFieldProps> = props => {
  return (
    <div className="section-field">
      <div className="section-input-field-heading">
      {props.required ? <label className={"control-label"}>{props.label}</label> : <label>{props.label}</label>}
      </div>
      <div className="section-input-field">
        <TextField
          key={props.value}
          variant="outlined"
          size="small"
          error={props.error}
          helperText={props.helperText}
          defaultValue={props.value}
          placeholder={props.disabled ? "" : props.placeholder}
          fullWidth
          autoComplete="off"
          disabled={props.disabled}
          onBlur={(event) => {
            if (!!props.onChange && event.target.value !== props.value)
              props.onChange(event);
          }}
          onChange={(event) => {
            if (!!props.onCheckEmptyField && event.target.value !== props.value)
              props.onCheckEmptyField(event);
          }}
        />
        {props.description &&
          <Typography variant="caption">{props.description}</Typography>
        }
      </div>
    </div>
  );
}

export default InputField;