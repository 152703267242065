import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { GlobalApplicationState } from 'globalApplicationState';
import * as actions from 'modules/posts/actionCreator';
import moment from "moment";

import { Comment as IComment } from '../../../models';
import DeleteCommentConfirmation from "./deleteComment";
import EditComment from "./editComment";
import FlagCommentConfirmation from "./flagComment";

import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Popover from "@mui/material/Popover";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import CommentFooter from './commentFooter';


const Commands: React.FunctionComponent<{ comment: IComment, commandMenu: CommandMenu[], flaggedIcon: JSX.Element, savingComment: boolean, deletionEnabled: boolean, isAuthor: boolean }> = props => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const { comment, commandMenu, isAuthor } = props;

  let commandsToUse = commandMenu;

  if (!comment.flaggedByCurrentUser && !commandMenu.length)
    return <React.Fragment></React.Fragment>;

  if (!props.deletionEnabled && !isAuthor) {
    commandsToUse = commandMenu.filter(c => c.text !== "Delete");
  }

  return (
    <div className="commands">
      {comment.flaggedByCurrentUser && props.flaggedIcon}
      {commandsToUse.length > 0 &&
        <React.Fragment>
          <IconButton size="small" onClick={(e) => setAnchorEl(e.currentTarget)}>
            <MoreVertIcon color="primary" fontSize="small" />
          </IconButton>
          <Popover
            id="comment-command-menu"
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <List dense style={{minWidth: "150px"}}>
              {commandsToUse.map((command, index) =>
                <ListItem
                  key={index}
                  button
                  disabled={props.savingComment || command.disabled}
                  onClick={() => {
                    command.onClick();
                    setAnchorEl(null);
                  }}
                >
                  <ListItemText primary={command.text} style={{color: command.textColor ?? ""}} />
                </ListItem>
              )}
            </List>
          </Popover>
        </React.Fragment>
      }
    </div>
  );
}

class Comment extends React.Component<PropsWithRedux, ComponentState> {
  constructor(props: PropsWithRedux) {
    super(props);

    this.state = {
      isEditing: false,
      pendingDeletion: false,
      pendingFlag: false,
      pendingStar: false,
      showDeleteConfirmation: false,
      showFlagConfirmation: false
    };
  }

  public componentDidMount() {
    moment.locale("en");
  }

  public render() {
    const { comment, userId, onReply } = this.props;
    const { isEditing, pendingDeletion, pendingStar, showDeleteConfirmation, showFlagConfirmation } = this.state;
    
    const isAuthor = userId === comment.author.id;

    const flaggedIcon: JSX.Element = (
      <div title="Reported as inappropriate" className="command flagged">
        <svg xmlns="http://www.w3.org/2000/svg" fill="#d64c5d" height="100%" viewBox="0 0 24 24" width="100%">
          <path d="M0 0h24v24H0z" fill="none"></path>
          <path d="M14.4 6L14 4H5v17h2v-7h5.6l.4 2h7V6z"></path>
        </svg>
      </div>
    );

    let commandMenu: CommandMenu[] = [];

    const formattedDate = moment(comment.createdTime).fromNow();
    const initials = comment.author.name ? comment.author.name[0] : '';

    if (!isAuthor && !comment.flaggedByCurrentUser) {
      commandMenu.push(
        {
          text: "Report as inappropriate",
          onClick: this.onFlagClick
        }
      );
    }

    if (comment.isEditable) {
      if (isAuthor) {
        commandMenu.push(
          {
            text: "Edit",
            onClick: this.onEditClick,
            disabled: this.props.editingComment
          }
        );
      }
      commandMenu.push(
        {
          text: "Delete",
          textColor: "#B72026",
          onClick: this.onDeleteClick
        }
      );
    }

    const wasEdited = comment.updatedTime !== null && comment.updatedTime !== comment.createdTime &&
      <div className="edited">edited</div>;

    const body = (
      <React.Fragment>
        <div className="body" style={{paddingBottom: "0px"}}>{comment.formattedBody === null ? comment.body : <div dangerouslySetInnerHTML={{__html: comment.formattedBody}}/> }</div>
        {wasEdited}
      </React.Fragment>
    );

    return (
      <div className={`comment ${pendingDeletion ? "pending-deletion" : ''} ${pendingStar ? "pending-star" : ''}`}>
        <div>
          <div className="avatar">
            <div style={{ backgroundColor: comment.author.avatar.color }} className="author-image">{initials}</div>
          </div>
          <div className="comment-info">
            <div>
              <div className="author">{comment.author.name}</div>
              <div className="date">{formattedDate}</div>
            </div>
            {!isEditing
              ? body
              : <EditComment
                  bodyPlain={comment.body}
                  bodyFormatted={comment.formattedBody ?? comment.body}
                  onEdit={this.onEditConfirm}
                  confirmCallback={this.props.confirmCallback}
                  onCancel={this.onEditCancel} />
            }
            <CommentFooter 
              comment={comment} 
              onStar={this.onStarClick} 
              onDestar={this.onDestarClick}
              pendingStar={this.state.pendingStar}
              isAuthor={isAuthor}
              onReply={onReply}
            />
          </div>
          <DeleteCommentConfirmation show={showDeleteConfirmation} onConfirm={this.onDeleteConfirm} onCancel={this.onDeleteDismiss} hasReplies={(comment.replyCount ?? 0) > 0}/>
          <FlagCommentConfirmation show={showFlagConfirmation} onConfirm={this.onFlagConfirm} onCancel={this.onFlagDismiss} />
        </div>
        <Commands
          comment={comment}
          commandMenu={commandMenu}
          isAuthor={isAuthor}
          flaggedIcon={flaggedIcon}
          savingComment={this.props.savingComment}
          deletionEnabled={this.props.deletionEnabled}
        />
      </div>
    );
  }

  private onDeleteClick = () => this.setState({ showDeleteConfirmation: true });
  private onDeleteConfirm = () => {
    this.setState({ pendingDeletion: true });
    this.onEditCancel();
    this.handleDelete();
    this.onDeleteDismiss();
  }
  private onDeleteDismiss = () => this.setState({ showDeleteConfirmation: false });

  private onEditClick = () => {
    if(!this.props.editingComment) {
      this.setState({ isEditing: true }); 
      this.props.toggleEdit(true);
    }
  }

  private onEditConfirm = (htmlBody: string, plainBody: string, snapshot: string) => this.props.editComment(this.props.comment.id, htmlBody, plainBody, snapshot);
  
  private onEditCancel = () => { 
    this.props.toggleEdit(false);
    this.setState({ isEditing: false });
  }
  
  private onFlagClick = () => this.setState({ showFlagConfirmation: true });
  private onFlagConfirm = () => {
    this.handleFlag();
    this.onFlagDismiss();
  }
  private onFlagDismiss = () => this.setState({ showFlagConfirmation: false });

  private onStarClick = () => {
    this.setState({ pendingStar: true });
    this.props.onStar(this.props.comment.id).then(() => {
      this.setState({ pendingStar: false });
  
      if(this.props.confirmCallback) {
        this.props.confirmCallback();
      }
    });
  }; 

  private onDestarClick = () => {
    this.setState({ pendingStar: true });
    this.props.onDestar(this.props.comment.id).then(() => {
      this.setState({ pendingStar: false });
  
      if(this.props.confirmCallback) {
        this.props.confirmCallback();
      }
    });
  }

  private handleDelete = () => {
    this.props.deleteComment(this.props.comment.id).then(_ => {
      this.setState({ pendingDeletion: false });

      if(this.props.isReply && this.props.confirmCallback) {
        this.props.confirmCallback();
      }
    });
  }
  private handleFlag = (): Promise<void> => {
    return new Promise((resolve) => {
      this.props.flagComment(this.props.comment.id).then(_ => {
        resolve();

        if(this.props.confirmCallback) {
          this.props.confirmCallback();
        }
      })
    });
  }
}


interface CommandMenu {
  text: string;
  textColor?: string;
  disabled?: boolean;
  onClick: () => void;
}


interface ComponentProps {
  comment: IComment;
  onStar: (toStarId: string) => Promise<void>;
  onDestar: (toStarId: string) => Promise<void>;
  editingComment: boolean;
  toggleEdit: (isEditing: boolean) => void;
  onReply: (comment: IComment) => void;
  confirmCallback?: () => void;
  isReply?: boolean;
}

interface ComponentState {
  isEditing: boolean;
  pendingDeletion: boolean;
  pendingFlag: boolean;
  pendingStar: boolean;
  showDeleteConfirmation: boolean;
  showFlagConfirmation: boolean;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    savingComment: state.posts.postView.savingComment,
    userId: state.settings.currentUser.userId,
    deletionEnabled: state.settings.tenantSettings.commentModerationEnabled
  }),
  {
    deleteComment: actions.deleteComment,
    destarComment: actions.destarComment,
    editComment: actions.editComment,
    flagComment: actions.flagComment,
    starComment: actions.starComment
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;
export default connector(Comment);