import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import * as actions from "../../../actionCreator";

import Loading from "modules/common/components/loading";

import { NewsletterDetails, NewsletterPastIssueDigestModel } from "../../../models";

import moment from "moment";
import numeral from "numeral";
import { InsightsInfoHover } from "./insightsInfoHover";

import IssuePublishingStatsWidget from "./issuePublishingStatsWidget";
import IssueEmailEventsWidget from "./issueEmailEventsWidget";
import useIsMounted from "modules/common/hooks/useIsMounted";
import usePrevious from "modules/common/hooks/usePrevious";

interface AnalyticsNumberProps {
    hoverLabel: string;
    analytic: string;
    title: string;
    subtitle: string;
}

const AnalyticsNumber: React.FunctionComponent<AnalyticsNumberProps> = ({ title, subtitle, hoverLabel, analytic }) => (
    <div className="number-wrapper">
        <div className="number">{analytic}</div>
        <span className="title">{title}</span>
        <span className="subtitle">{subtitle}</span>
        <div className="stats-hover-info">{hoverLabel}</div>
    </div>
);

const IssueInsightsTab: React.FunctionComponent<PropsWithRedux> = ({
    newsletter,
    onIssueLoaded,
    goBackToDashboard,
    getPastIssue,
    issueDate,
}) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [issue, setIssue] = useState<NewsletterPastIssueDigestModel>();
    const isMounted = useIsMounted();
    const prevProps = usePrevious({ newsletterId: newsletter.id, issueDate });

    /**
     * Effect of mount
     * - load issue from propped newsletter
     */
    useEffect(() => {
        // async op to get issue by propped newsletter
        const getPastIssueAsync = async () => {
            const newIssue = await getPastIssue(newsletter.id, issueDate);
            if (newIssue && isMounted()) {
                setIssue(newIssue);
                onIssueLoaded(newIssue.id!);
                setIsLoading(false);
            } else if (!newIssue) {
                goBackToDashboard();
            }
        };

        // check if necessary to make api call
        if (prevProps?.newsletterId !== newsletter.id || prevProps.issueDate !== issueDate) getPastIssueAsync();
    }, [getPastIssue, goBackToDashboard, isMounted, issueDate, newsletter.id, onIssueLoaded, prevProps]);

    // format a number for display
    const formatNumeralLabel = (value: number) => numeral(value).format("0,0");

    /**
     * helper to get props for AnalyticsNumber component for a specific stat
     */
    const getAnalyticNumberProps = (key: keyof IssueInsightsTabAnalytics, stats: IssueInsightsTabAnalytics): AnalyticsNumberProps => {
        const {
            unsubscribes = 0,
            dropped = 0,
            bounced = 0,
            spamReported = 0,
            invalidAddresses = 0,
            copiesSent = 0,
            copiesPublished = 0,
        } = stats || {};

        const copiesSentLabel = `${formatNumeralLabel(copiesSent)} sent copies`;

        let result: AnalyticsNumberProps;
        switch (key) {
            case "invalidAddresses":
                result = {
                    title: "Invalid Addresses",
                    subtitle: `${numeral(invalidAddresses).format("0,0")} of ${formatNumeralLabel(copiesPublished)} published copies`,
                    analytic: `${numeral(copiesPublished === 0 ? 0 : invalidAddresses / copiesPublished).format("0%")}`,
                    hoverLabel:
                        "Number of addressees who had malformed email addresses or whose mail provider reported the address as invalid",
                };
                break;
            case "bounced":
                result = {
                    title: "Bounced Emails",
                    subtitle: `${numeral(bounced).format("0,0")} of ${copiesSentLabel}`,
                    analytic: `${numeral(copiesSent === 0 ? 0 : bounced / copiesSent).format("0%")}`,
                    hoverLabel:
                        "Number of emails that were denied by the receiving server and have been suppressed moving forward",
                };
                break;
            case "dropped":
                result = {
                    title: "Drops",
                    subtitle: `${numeral(dropped).format("0,0")} of ${copiesSentLabel}`,
                    analytic: `${numeral(copiesSent === 0 ? 0 : dropped / copiesSent).format("0%")}`,
                    hoverLabel: "Number of emails that were not allowed to be delivered by the internet service provider",
                };
                break;
            case "unsubscribes":
                result = {
                    title: "New Unsubscribes",
                    subtitle: `${numeral(unsubscribes).format("0,0")} from ${copiesSentLabel}`,
                    analytic: `${numeral(copiesSent === 0 ? 0 : unsubscribes / copiesSent).format("0%")}`,
                    hoverLabel: "Number of recipients who unsubscribed from an email",
                };
                break;
            case "spamReported":
                result = {
                    title: "Spam Reports",
                    subtitle: `${numeral(spamReported).format("0,0")} of ${copiesSentLabel}`,
                    analytic: `${numeral(copiesSent === 0 ? 0 : spamReported / copiesSent).format("0%")}`,
                    hoverLabel: "Number of recipients who reported the email as spam",
                };
                break;
            default:
                throw new Error("Analytic not supported");
        }

        return result;
    };

    /**
     * helper to get stats to display
     */
    const getStats = (): IssueInsightsTabAnalytics | undefined => {
        if (!issue) return;

        // destructure
        const { stats } = issue;
        const { Dropped, Processed, UniqueUnsubscribed, SpamReported, UniqueBounced } = stats;

        // calculate
        const copiesSent = !!sentReceipt ? sentReceipt.countOfCopiesSent : !!stats ? Processed : 0;
        const invalidAddresses = (!stats ? 0 : Dropped) + (!sentReceipt ? 0 : sentReceipt.countOfInvalidEmailAddresses);
        const copiesPublished = !!sentReceipt
            ? sentReceipt.countOfCopiesSent + sentReceipt.countOfInvalidEmailAddresses
            : !!stats
            ? copiesSent
            : 0;

        return {
            copiesPublished,
            bounced: UniqueBounced,
            dropped: Dropped,
            copiesSent,
            spamReported: SpamReported,
            unsubscribes: UniqueUnsubscribed,
            invalidAddresses,
        };
    };

    if (isLoading) return <Loading />;

    if (!newsletter || !issue) return <React.Fragment>Issue not found</React.Fragment>;

    const { stats, sentReceipt, status } = issue;
    if (status === "Skipped")
        return <React.Fragment>This issue has been skipped. It has not been published. There are no insights to report.</React.Fragment>;

    const displayStats = getStats() || ({} as IssueInsightsTabAnalytics);

    return (
        <div className="newsletter-insights">
            <div className="newsletter-insights-header">
                <div className="header">
                    <span>{moment(issue.issueProcessedOnDateTime).format("dddd, MMM D, YYYY h:mmA")}</span>
                    <InsightsInfoHover />
                </div>
            </div>
            <IssuePublishingStatsWidget issue={issue} />
            <IssueEmailEventsWidget issue={issue} />
            {!!stats && (
                <div className="primary-stats stats-row">
                    <AnalyticsNumber {...getAnalyticNumberProps("bounced", displayStats)} />
                    <AnalyticsNumber {...getAnalyticNumberProps("invalidAddresses", displayStats)} />
                    <AnalyticsNumber {...getAnalyticNumberProps("dropped", displayStats)} />
                    <AnalyticsNumber {...getAnalyticNumberProps("unsubscribes", displayStats)} />
                    <AnalyticsNumber {...getAnalyticNumberProps("spamReported", displayStats)} />
                </div>
            )}
        </div>
    );
};

interface ComponentProps {
    newsletter: NewsletterDetails;
    issueDate: string;
    onIssueLoaded: (issueId: string) => any;
    goBackToDashboard: () => any;
}

interface IssueInsightsTabAnalytics {
    copiesPublished: number;
    bounced: number;
    dropped: number;
    copiesSent: number;
    spamReported: number;
    unsubscribes: number;
    invalidAddresses: number;
}

const connector = connect((state: GlobalApplicationState, ownProps: ComponentProps) => ownProps, {
    getPastIssue: actions.getPastIssue,
});

type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(IssueInsightsTab);
