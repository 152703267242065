import * as React from 'react';
import { Comment } from '../../../models';
import { StarBorder, KeyboardReturn, Star } from '@mui/icons-material';
import { Button } from '@mui/material';

const CommentFooter: React.FunctionComponent<ComponentProps> = (props) => {
    const { comment, pendingStar, isAuthor, onReply } = props;
    const replyCount = comment.replyCount ?? 0;
    const starCount = comment.starCount ?? 0;

    const formattedLikes = starCount.toLocaleString() ?? 0;
    const formattedReplies = replyCount.toLocaleString() ?? 0;
    const commentLiked = comment.starredByCurrentUser;
    
    const handleLikeClick = () => {
        if(!pendingStar && !isAuthor) {
            if(commentLiked) {
                props.onDestar(comment);
            }
            else {
                props.onStar(comment);
            }
        }
    }

    return (
        <div className={"comment-footer-section"}>
            <div onClick={handleLikeClick}>
                <Button 
                    variant="text" 
                    color={commentLiked ? "primary" : "info"}
                    disabled={pendingStar || isAuthor}
                >
                    LIKE
                </Button>
                {starCount > 0 &&
                    <>
                        {commentLiked || isAuthor
                            ? <Star 
                                fontSize="small" 
                                className="star-icon"
                            />
                            : <StarBorder 
                                fontSize="small" 
                                className="star-icon"
                            />
                        }
                    
                        <span>{formattedLikes}</span>
                    </>
                }
            </div>

            <span className="divider-padding">|</span>

            <div className="reply">
                <Button onClick={() => onReply(comment)} variant="text" color="primary" className="button-padding">REPLY</Button>

                {replyCount > 0 && comment.repliesPreview.length > 0 &&
                    <>
                        <KeyboardReturn className="reply-icon"/>
                        <span>{formattedReplies}</span>
                    </>
                }
            </div>
        </div>
    )
}

interface ComponentProps {
    comment: Comment;
    onStar: (comment: Comment) => void;
    onDestar: (comment: Comment) => void;
    onReply: (comment: Comment) => void;
    pendingStar: boolean;
    isAuthor: boolean;
}

export default CommentFooter;