//For use inside the templateThemeSearchAndSelect file.
//Intended to be used in an array.for loop.
import { Divider, List } from '@mui/material';
import * as React from 'react';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import confirm from "utils/notyPopups";

import '../../styles/themes.sass'
import { NewsletterTheme } from 'modules/newsletters/models';
import MoreOptionsIconButton from 'modules/common/components/buttons/moreOptionsIconButton';
import moment from 'moment';
import { connect, ConnectedProps } from 'react-redux';
import { GlobalApplicationState } from 'globalApplicationState';
import { push } from 'react-router-redux';
import MoreOptionsItem from 'modules/common/components/moreOptionsItem';

interface ComponentProps {
    theme: NewsletterTheme;
    isOpen: boolean;
    setOpen: (index: number, toSet: boolean) => void;
    index: number;
    deleteTheme: (id: string, isCustom: boolean) => void;
    defaultTheme: (id: string, isCustom: boolean) => void;
}

const ThemeDisplayWithDropdown: React.FunctionComponent<PropsWithRedux> = ( {theme, isOpen, setOpen, index, defaultTheme, deleteTheme, tenantId, redirectTo} ) => {
    const [canSeeKebab, setCanSeeKebab] = React.useState(false);

    let dateToDisplay = moment(new Date(theme.lastModifiedOnUTC)).format("MMM D, YYYY");
    let nameToDisplay = theme.lastModifiedByDisplayName?.substring(0, 27) + (theme.lastModifiedByDisplayName?.length > 27 ? "..." : "");

    return (
        <div style={{border: "1px solid #E1E5E8", borderRadius: "5px"}} onMouseEnter={() => setCanSeeKebab(true)} onMouseLeave={() => setCanSeeKebab(false)}>
            <div style={{display: "flex", flexDirection: "row"}}>
                <div style={{display: "flex", flexDirection: "row", cursor: "pointer", width: "100%"}} onClick={() => setOpen(index, !isOpen)}>
                    <div style={{display: "flex", flexDirection: "column", padding: "8px 0"}}>
                        <span style={{fontSize: "16px", marginLeft: "12px"}}>
                            {theme.name}
                            {theme.isDefault && <span style={{backgroundColor: "#F2F2F2", fontSize: "12px", marginLeft: "10px", fontWeight: 500}}>DEFAULT</span>}
                        </span>
                        {!theme.isCustom 
                            ? <span style={{fontSize: "13px", marginLeft: "12px"}}>Standard template theme</span> 
                            : <span style={{fontSize: "13px", marginLeft: "12px"}}>{theme.lastModifiedOnUTC ? "Last modified on " + dateToDisplay + " by " + nameToDisplay : "Custom template theme"}</span>
                        }
                    </div>
                    <div style={{display: "flex", alignItems: "center", marginLeft: "auto", marginRight: "20px"}}>
                        {canSeeKebab && 
                        <MoreOptionsIconButton className='more-theme-options-kebab' onClick={(e) => e.stopPropagation()}>
                            <List disablePadding>
                                <MoreOptionsItem
                                    disabled={!theme.isCustom}
                                    text="Edit"
                                    onClick={() => {
                                        redirectTo(`/${tenantId}/admin/newsletters/theme/${theme.id}`);
                                    }}
                                />
                                <MoreOptionsItem
                                    text="Delete"
                                        onClick={async () => {
                                            if (await confirm.show({
                                                title: "Delete '" + theme.name + "'",
                                                text: (
                                                <div>
                                                    <div>You're about to permanently delete this theme. 
                                                        This will not affect newsletters that used these settings.
                                                        You cannot undo this action.</div>
                                                    <br />
                                                    <div>Are you sure?</div>
                                                </div>
                                                ),
                                                yesColor: "#a80000",
                                                yesText: "Delete",
                                                noText: "Cancel"
                                            })) {
                                                deleteTheme(theme.id, theme.isCustom);
                                            }
                                        }}
                                    disabled={!theme.isCustom || theme.isDefault}
                                />
                                <Divider/>
                                <MoreOptionsItem
                                    text="Set as default"
                                    onClick={() => {
                                        defaultTheme(theme.id, theme.isCustom);
                                    }}
                                    disabled={theme.isDefault}
                                />
                            </List>
                        </MoreOptionsIconButton>
                        }
                        {isOpen
                        ? <KeyboardArrowUpIcon style={{fontSize: "28px", float: "right"}}/> 
                        : <KeyboardArrowDownIcon style={{fontSize: "28px", float: "right"}}/> 
                        }
                    </div>
                </div>
            </div>
            {isOpen && 
            <div className="theme-section">
                <div style={{display: "flex", flexDirection: "row", width: "100%", marginTop: "20px"}}>
                    <div className='left-theme-label'>Email background</div>
                    <div style={{height: "20px", width: "20px", backgroundColor: theme.emailBackground, borderRadius: "3px", border: "1px solid #DDE1E5"}}></div>
                    <div className='right-theme-label'>Secondary background</div>
                    <div style={{height: "20px", width: "20px", backgroundColor: theme.secondaryBackground, borderRadius: "3px", border: "1px solid #DDE1E5"}}></div>
                </div>

                <div style={{display: "flex", flexDirection: "row", width: "100%", marginTop: "10px"}}>
                    <div className='left-theme-label'>Primary background</div>
                    <div style={{height: "20px", width: "20px", backgroundColor: theme.primaryBackground, borderRadius: "3px", border: "1px solid #DDE1E5"}}></div>
                    <div className='right-theme-label'>Secondary text</div>
                    <div style={{height: "20px", width: "20px", backgroundColor: theme.secondaryText, borderRadius: "3px", border: "1px solid #DDE1E5"}}></div>
                </div>

                <div style={{display: "flex", flexDirection: "row", width: "100%", marginTop: "10px"}}>
                    <div className='left-theme-label'>Primary text</div>
                    <div style={{height: "20px", width: "20px", backgroundColor: theme.primaryText, borderRadius: "3px", border: "1px solid #DDE1E5"}}></div>
                    <div className='right-theme-label'>Link text</div>
                    <div style={{height: "20px", width: "20px", backgroundColor: theme.linkText, borderRadius: "3px", border: "1px solid #DDE1E5"}}></div>
                </div>
            </div>}
        </div>
    )
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
      ...ownProps,
      tenantId: state.tenant.id
    }),
    {
        redirectTo: push
    }
    );
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(ThemeDisplayWithDropdown);