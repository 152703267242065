import * as React from "react";
import { Audience, UpdateEveryoneAudience, GroupSyncStatus, SyncAction, AdGroupSuggestions, SyncType } from "../models";
import { RouteComponentProps, Prompt } from "react-router";
import "./audiences.sass";
import { audiencesApi, syncFunctionsApi, settingsApi } from "api/instances";
import EveryoneSuggestionsManager from "./everyoneSuggestionsManager";
import Loading from "modules/common/components/loading";
import { User } from "modules/users/models";
import LoadingSpinner from "modules/common/components/loadingSpinner";
import { injectIntl, IntlShape, FormattedMessage } from "react-intl";
import { GlobalApplicationState } from "globalApplicationState";
import { push } from "react-router-redux";
import { connect, ConnectedProps } from "react-redux";
import { errorAlert, warning } from "utils/notyPopups";
import DeleteReplaceModal from "./deleteReplaceModal";
import * as settingsActions from "modules/settings/actionCreator";
import DownloadButton from "modules/common/components/buttons/downloadButton";
import ToggleSwitch from "modules/common/components/toggleSwitch";
import Select from "react-select";
import { ValueLabel } from "modules/common/components/selectFilter";
import Modal from "modules/common/components/modal";
import InfoHover from "modules/common/components/hovers/infoHover";

import BasePage from "pages/common/basePage";
import Breadcrumb from "pages/common/breadcrumb";
import MainContent from "pages/common/mainContent";
import CalloutHover from "modules/common/components/hovers/calloutHover";
import ConfirmDialog from "modules/common/components/dialogs/confirmDialog";
import SuccessSnackbar from "modules/common/components/snackbars/successSnackbar";
import ErrorSnackbar from "modules/common/components/snackbars/errorSnackbar";


class EveryoneAudienceManager extends React.Component<PropsWithRedux, ComponentState> {
    private _isMounted = false;
    private _waitingForAdSyncCompletion = false;
    private _allAzureADGroupName = "All Azure AD Users";

    constructor(props) {
        super(props);
        this.state = {
            updateAudience: {
                displayName: "",
                description: "",
                enabled: false,
                includedGroups: [],
            },
            pristineAudience: {
                displayName: "",
                description: "",
                enabled: false,
                includedGroups: [],
            },
            audienceId: "",
            saving: false,
            deleting: false,
            fetching: true,
            activeTab: "groups",
            usersPerGroup: {},
            syncStatusPerGroup: {},
            pristineSync: {},
            audienceList: [],
            showReplaceDeleteModal: false,
            syncStatusesToBeUpdated: [],
            edited: false,
            defaultEnforcementState: false,
            enforcementChanged: false,
            mobileSyncChanged: false,
            showUserSyncModal: false,
            defaultSyncOptions: "",
            allAdGroupId: "",
            waitingForAdSyncCompletion: false,
            confirmResyncDialogOpen: false,
            waitingForResyncCompletion: false,
            resyncSnackbarMsg: "",
            resyncErrorSnackbarMsg: ""
        };
    }
    public componentDidMount() {
        this._isMounted = true;

        this.fetchSyncedUserGroups();
        this.setState({
            ...this.state,
            defaultEnforcementState: this.props.tenantSettings.everyoneAudienceEnforcementEnabled,
            defaultSyncOptions: this.props.tenantSettings.syncConfig.syncLevel
        })
    }
    
    public componentWillUnmount() {
        this._isMounted = false;
    }
    private defaultStyles: React.CSSProperties = {
        backgroundColor: '#2f4050',
        color: "#ffffff",
        border: '1px solid #ccc', 
        outline: 'none',
        padding: '8px',
        cursor: "pointer",
        display: "inline-block",
        fontSize: "14px",
        borderRadius: '3px',
        float: "right",
        marginRight: '3px'
    }
    
    public render() {

        const selectStyles = {
            control: (provided) => ({
                ...provided,
                width: "100%",
                marginBottom: 20
            }),
            menu: (provided) => {
                return {
                    ...provided,
                    width: "100%",
                }
            }        
        };

        const options = this.GetMobileFieldOptions(this.props.tenantSettings.syncConfig.syncType);
        const preferredMobileField = this.GetPreferredMobileField(this.props.tenantSettings.syncConfig.syncType);

        const essentialsSyncFields = this.GetEssentialsSyncFields(this.props.tenantSettings.syncConfig.syncType);
        const basicsSyncFields = this.GetBasicsSyncFields(this.props.tenantSettings.syncConfig.syncType);

        const syncOptions = [
            {value: "essentialsOnly", label: "Essentials"},
            {value: "basic", label: "Basic"},
        ]

        let hoverDescription = 
            <div>
                <strong>Essentials:</strong> {essentialsSyncFields} <br />
                <strong>Basic:</strong> Everything in Essentials plus: {basicsSyncFields}
            </div>
                
        let resyncBtnDisabled = this.state.waitingForResyncCompletion || this.isNameEmpty() || this.state.updateAudience.includedGroups.length === 0;    
        let saveBtnDisabled = (
            this.state.saving || 
            this.state.fetching || 
            this.state.deleting || 
            !this.props.canEdit || 
            this.isNameEmpty() || 
            !this.isEdited() || 
            !this.state.edited ||
            !this.props.tenantSettings.canManageEveryoneAudience
        );
        let audience = this.state.updateAudience;
        let userGroupEdited = this.isUserGroupsEdited();

        let syncOptionsIndex = syncOptions.findIndex(o => o.value === this.props.tenantSettings.syncConfig.syncLevel);
        let userAdSyncEnabled = this.props.tenantSettings.syncConfig.syncAllAdUsers;
        let syncType = this.props.tenantSettings.syncConfig.syncType;

        let allAdSyncStatus = this.state.syncStatusPerGroup[this.state.allAdGroupId]
        if (allAdSyncStatus && allAdSyncStatus.syncStatus.status !== 'Completed') {
            this._waitingForAdSyncCompletion = true;
        }
        return (
            <BasePage fullWidth>
                <Prompt
                    when={userGroupEdited}
                    message="You have unsaved changes, are you sure you want to leave this page?"
                />
                <Breadcrumb
                    items={[
                        {
                            title: "Manage Audiences",
                            link: "~/admin/audiences",
                        },
                        { title: `Sync ${syncType} Groups` },
                    ]}
                />
                <MainContent>
                    <div className="everyone-audience-manager">
                        <div className="audience-column" style={{width:"50%"}}>
                            
                            <div className="audience-title">Audience Details</div>
                            {this.props.tenantSettings.canManageEveryoneAudience ? null : <div className="audience-warning">Only {syncType} Accounts from this Organization with the Owner permission can modify settings in Everyone Audience.</div>}
                            <div className="details">
                                <div className="input-wrapper">
                                    <label>
                                        <FormattedMessage id="common.name" defaultMessage="Name" />
                                    </label>
                                    <input
                                        className="form-control"
                                        style={{ border: this.isNameEmpty() ? "1px solid red" : "" }}
                                        autoComplete="off"
                                        disabled={true}
                                        value={audience.displayName ?? ""}
                                        onChange={(e) => this.updateAudienceName(e)}
                                    />
                                </div>
                                <div className="input-wrapper">
                                    <label>
                                        <FormattedMessage id="common.description" defaultMessage="Description" />
                                    </label>
                                    <input
                                        className="form-control"
                                        autoComplete="off"
                                        disabled={true}
                                        value={audience.description ?? ""}
                                        onChange={(e) => this.updateAudienceDescription(e)}
                                    />
                                </div>
                                <div className="clearfix" style={{marginBottom: 10}}>
                                    <label>Everyone Audience Enforcement</label>
                                    <span style={{float: "right",
                                                marginRight: -38}}>
                                    {!this.state.updateAudience.includedGroups.length ? 
                                        (
                                                <CalloutHover
                                                arrow
                                                component= { 
                                                <ToggleSwitch value={this.props.tenantSettings.everyoneAudienceEnforcementEnabled}
                                                    onClick={() => this.handleEnforcementChange() }
                                                    disabled={ true } /> 
                                                } 
                                                >
                                                    <div className="disabled-button-message">
                                                    Sync at least 1 {syncType} Group to enable the Everyone Audience Enforcement setting.
                                                    </div>
                                                </CalloutHover>
                                        ) : (
                                                <ToggleSwitch value={this.props.tenantSettings.everyoneAudienceEnforcementEnabled}
                                                    onClick={() => this.handleEnforcementChange() }
                                                    disabled={!this.props.tenantSettings.canManageEveryoneAudience } />
                                        )
                                    }
                                    </span>
                                </div>
                                <div style={{margin: "20px 0px"}}>
                                    <div>
                                        <div className="description-text" style={{margin: "10px 0px", display: "flex"}}>Sync options: <InfoHover size="small">{hoverDescription}</InfoHover></div>
                                        <Select 
                                            options={syncOptions}
                                            defaultValue={syncOptions[syncOptionsIndex]}
                                            onChange={this.handleSelectSyncLevel}
                                            styles={selectStyles}
                                            isDisabled={!this.props.tenantSettings.canManageEveryoneAudience}/>
                                    </div>
                                                            
                                    {this.props.tenantSettings.syncConfig.syncLevel === "basic" &&
                                        <div>
                                        <div className="description-text" style={{margin: "10px 0px"}}>{syncType} Mobile Field to pull from:</div>
                                        <Select 
                                            defaultValue= {options!.find(o => o.value === preferredMobileField)} 
                                            options={options}
                                            onChange={this.handleSelectMobileSyncType}
                                            styles={selectStyles}/>
                                        </div>
                                    }
                                </div>  
                            </div>
                            
                        </div>
                        <div className="audience-column">
                            <div className="manager-controls-everyone">
                                <button
                                    style={{ 
                                        float: "right",
                                        maxHeight: "34px",
                                        backgroundColor: saveBtnDisabled ? "#CCCCCC" : "",
                                        pointerEvents: saveBtnDisabled ? "none" : "all"
                                    }}
                                    disabled={saveBtnDisabled}
                                    onClick={() => this.setState({
                                        ...this.state,
                                        saving: true,
                                    }, this.saveGroupAndTenantSettings )}
                                >
                                    {this.state.saving ? (
                                        <LoadingSpinner 
                                            size={"14px"}
                                            style={{
                                                height:"14px"
                                            }}
                                        />
                                    ) : (
                                        <span>
                                            <FormattedMessage id="common.save" defaultMessage="Save" />
                                        </span>
                                    )}
                                </button>
                                <DownloadButton
                                    onClick={() => this.downloadEveryoneUserList() }
                                    text={" Download User Data"}
                                    downloadName={"sparrow-user-data"}
                                    styles={{
                                        ...this.defaultStyles,
                                        maxHeight: "34px",
                                        pointerEvents: !this.props.tenantSettings.canManageEveryoneAudience ? "none" : "all"
                                    }}
                                    disabled={!this.props.tenantSettings.canManageEveryoneAudience}
                                />
                                <button
                                    id="resyncBtn"
                                    style={{ 
                                        ...this.defaultStyles,
                                        maxHeight: "34px",
                                        backgroundColor: resyncBtnDisabled ? "#CCCCCC" : "",
                                        pointerEvents: resyncBtnDisabled ? "none" : "all"
                                    }}
                                    onClick={() => this.setState({ ...this.state, confirmResyncDialogOpen: true })}
                                >
                                    <span
                                        style={{
                                            marginRight: "4px",
                                            verticalAlign: "top"
                                        }}
                                    >
                                        {
                                            this.state.waitingForResyncCompletion ?
                                                <LoadingSpinner
                                                    size={"14px"}
                                                    style={{
                                                        height:"14px"
                                                    }}
                                                />:
                                                <i className="material-icons" style={{verticalAlign: "middle"}}>sync</i>
                                        }
                                    </span>
                                    <span
                                        style={{
                                            verticalAlign: "top"
                                        }}
                                    >
                                        {
                                            this.state.waitingForResyncCompletion ? 
                                                <FormattedMessage id="audience.resyncBtnMsgInProg" defaultMessage={"Resync in progress.."}/> :
                                                <FormattedMessage 
                                                    id="audience.resyncBtnMsg" 
                                                    defaultMessage={`Resync All ${syncType} Users`}
                                                    values={{syncType: syncType}}
                                                />
                                        }
                                    </span>
                                </button>
                                {/* {!this.props.canEdit ? (
                                    <p style={{ marginTop: "40px" }} className="alert-info wrapped">
                                        {" "}
                                        To edit audiences, managing topics and audiences from the Admin Portal must be enabled. Management can
                                        be enabled &nbsp;<TenantLink to="~/admin/settings#features">here</TenantLink>.
                                    </p>
                                ) : null} */}
                            </div>
                        </div>
                    </div>
                    <div style={{display:"flex"}} className="everyone-audience-manager-groups">
                        <div style={{padding:"0px 50px"}}>
                            <div className="audience-title">
                                <FormattedMessage id="audience.groupsInEveryoneAudience" defaultMessage="Synced Groups in Everyone Audience" />
                            </div>
                            <div className="details list">
                                <div className="tabs" style={{ borderBottom: "1px solid #ddd" }}>
                                    <ul className="tab-btns">
                                        <li>
                                            <span
                                                onClick={() => {
                                                    this.setState({
                                                        ...this.state,
                                                        activeTab: "groups",
                                                    });
                                                }}
                                                className={"tab-btn" + (this.state.activeTab === "groups" ? " active" : "")}
                                                title={this.props.syncType + " Groups"}
                                            >
                                                <FormattedMessage id="users.groups" defaultMessage={this.props.syncType + " Groups"} />
                                            </span>
                                            <button 
                                                disabled={!this.props.tenantSettings.canManageEveryoneAudience}
                                                style={{float: 'right'}}
                                                className="search-btn btn-primary"
                                                    onClick={() => 
                                                        this.setState({
                                                            ...this.state,
                                                            fetching: true,
                                                        }, this.fetchEveryoneAudienceGroups)}
                                                >
                                                {this.state.fetching ? (
                                                    <LoadingSpinner size={"14px"} />
                                                ) : (
                                                    <span>
                                                        <FormattedMessage id="common.refresh" defaultMessage="Refresh" />
                                                    </span>
                                                )}
                                            </button>
                                            
                                        </li>
                                    </ul>
                                </div>
                                    <div style={{ marginTop: "30px" }}>
                                        <div className="results" style={{ height: "465px", overflowY: "auto" }}>
                                            {this.state.fetching ? (
                                                <Loading padding={12} />
                                            ) : this.state.updateAudience.includedGroups.length !== 0 ? (
                                                this.state.updateAudience.includedGroups.map((group) => {
                                                    
                                                    return (
                                                        <div key={group.id} className="item">
                                                            {group.displayName}
                                                            {this.props.canEdit && group.displayName !== this._allAzureADGroupName ?
                                                            (this.checkIfGroupToBeRemoved(group) ?
                                                                (
                                                                    <span
                                                                        className="add-btn"
                                                                        onClick={() => this.addToAudience(group)}
                                                                    >
                                                                        <i className="material-icons">add</i>
                                                                    </span>
                                                                ) 
                                                                :
                                                                (
                                                                    <span
                                                                        className="remove-btn"
                                                                        onClick={() => this.removeFromAudience(group)}
                                                                    >
                                                                        <i className="material-icons">clear</i>
                                                                    </span>
                                                                ) 
                                                            )
                                                            : null}
                                                            {this.state.syncStatusPerGroup[group.referenceId]?.syncStatus ||
                                                            this.state.syncStatusPerGroup[group.id]?.syncStatus ?  
                                                                    (
                                                                    <div
                                                                        key={group.referenceId}
                                                                        className="group-user"
                                                                        style={{ marginTop: "5px" }}
                                                                    >
                                                                            <p>{`Sync Status: ${
                                                                                this.getSyncStatus(group)}`    
                                                                            }</p>
                                                                            <p>{`Last Synced: ${this.state.syncStatusPerGroup[group.referenceId]?.syncStatus?.lastSyncCompletedTime ? 
                                                                            new Date(this.state.syncStatusPerGroup[group.referenceId].syncStatus.lastSyncCompletedTime)
                                                                                : 'Waiting For Sync'}`}</p>
                                                                    </div>
                                                                ) : null}

                                                        </div>
                                                    );
                                                })
                                            ) : (
                                                <div style={{ textAlign: "center", color: "#666" }}>
                                                    <FormattedMessage id="audience.noItems" defaultMessage="No items in audience yet" />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                            </div>
                        </div>
                        <div>
                            <div className="audience-title">
                                <FormattedMessage id="audience.addGroupsToEveryoneAudience" defaultMessage="Add {syncType} Groups to Audience (sync)" values= {{ syncType: syncType}}  />
                            </div>
                            <EveryoneSuggestionsManager
                                working={this.state.saving || this.state.deleting}
                                updateAudience={this.updateAudience}
                                currentAudience={this.state.updateAudience}
                                // ADGroupSyncEnabled={this.props.ADGroupSyncEnabled}
                                canEdit={this.props.canEdit}
                                tenantSettings={this.props.tenantSettings}
                            />
                        </div>
                    </div>
                    <DeleteReplaceModal
                        show={this.state.showReplaceDeleteModal}
                        deleting={this.state.deleting}
                        closeModal={this.closeModal}
                        audienceList={this.state.audienceList.filter((a) => a.enabled)}
                        deleteAndReplace={this.deleteAndReplace}
                        deletingAudience={{ id: this.state.audienceId, name: this.state.updateAudience.displayName }}
                    />
                    <Modal 
                        active={this.state.showUserSyncModal}
                        title={userAdSyncEnabled ? "Sync All AD Users" : "UnSync All AD Users"}
                        onCloseClick={() => this.closeSyncUserModal}
                        showControls={true}
                        buttons={[
                            { title: "Ok", workingTitle: "Ok", isWorking: this.state.saving, isPrimary: true, isEnabled: !this.state.saving, onClick: () => { this.handleSyncUsers() } },
                            { title: "Cancel", isPrimary: false, isEnabled: true, onClick: this.closeSyncUserModal }
                        ]}
                        children={<>Are you sure you want to {userAdSyncEnabled ? "sync" : "unsync"} all Users from AD?</>}

                    />
                    <SuccessSnackbar
                        successMessage={ this.state.resyncSnackbarMsg || "" }
                        clearSuccessMessage={() => this.setState({ ...this.state, resyncSnackbarMsg: "" })}
                    />
                    <ErrorSnackbar
                        errorMessage={ this.state.resyncErrorSnackbarMsg || "" }
                        clearErrorMessage={ () => this.setState({ ...this.state, resyncErrorSnackbarMsg: "" }) }
                    />
                    {this.getResyncConfirm()}
                </MainContent>
            </BasePage>
        );
    }

    private GetMobileFieldOptions(syncType: string) {
        if(syncType === SyncType.MicrosoftAD){
            return [
                {value: "mobilePhone", label: "Mobile Phone"},
                {value: "businessPhones", label: "Business Phone"}
            ];
        }
        else if(syncType === SyncType.Okta){
            return [
                {value: "mobilePhone", label: "Mobile Phone"},
                {value: "primaryPhone", label: "Primary Phone"}
            ];
        }
    }

    private GetPreferredMobileField(syncType: string) {
        if(syncType === SyncType.MicrosoftAD){
            return this.props.tenantSettings.syncConfig.mobileSyncConfig.graphMobileFieldSource;
        }
        else if(syncType === SyncType.Okta){
            return this.props.tenantSettings.syncConfig.mobileSyncConfig.oktaMobileFieldSource;
        }
    }

    private GetEssentialsSyncFields(syncType: string) {
        if(syncType === SyncType.MicrosoftAD){
            return 'First Name, Last Name, Email, Title, Account Enabled';
        }
        else if(syncType === SyncType.Okta){
            return 'First Name, Last Name, Email, Title, Account Enabled';
        }
    }

    private GetBasicsSyncFields(syncType: string) {
        if(syncType === SyncType.MicrosoftAD){
            return 'Preferred SMS Phone, Country, Department, Manager, Office Location';
        }
        else if(syncType === SyncType.Okta){
            return 'Preferred SMS Phone, Country, Department, Manager';
        }
    }
    
    private closeSyncUserModal = () => {
        this.props.setSyncAllAdUsers(!this.props.tenantSettings.syncConfig.syncAllAdUsers);
        this.setState({
            ...this.state,
            showUserSyncModal: false,
            mobileSyncChanged: !this.state.mobileSyncChanged,
        })
    }

    private openSyncUserModal = () => {
        this.props.setSyncAllAdUsers(!this.props.tenantSettings.syncConfig.syncAllAdUsers);
        this.setState({ 
            ...this.state, 
            showUserSyncModal: true,
            mobileSyncChanged: !this.state.mobileSyncChanged,
        });
    }

    private handleSyncUsers = () => {
        // Make my api call
        this.setState({
            ...this.state,
            saving: true,
            mobileSyncChanged: true
        })
        let action = this.props.tenantSettings.syncConfig.syncAllAdUsers ? "SyncRequested" : "DeleteRequested";
        syncFunctionsApi.manageAdUserSync(action)
        .then(() => {
            this.saveTenantSettings();
        })
    }
    private getSyncStatus(group: AdGroupSuggestions) {

        /* The reason why we're checking for if the referenceId is undefined is because when a suggestion 
        gets added in from the right side, the api call doesn't return a referenceid yet. Instead, it returns
        an ID which is actually what the reference ID is for groups that have already been synced */
        let status;
        if (group.referenceId === undefined) {
            status = this.state.syncStatusPerGroup[group.id].syncStatus.status
        } else {
            status = this.state.syncStatusPerGroup[group.referenceId].syncStatus.status
        };

        switch (status){
            case "NotSyncedYet":
                return "Not Synced Yet";
            case "SyncRequested":
                return "Sync Requested";
            case "DeleteRequested":
                return "Delete Requested";
            case "Syncing":
                return "Syncing";
            case "Completed":
                return "Completed";
            case "Error":
                return "Error";
            case "WaitingForSave":
                return "Waiting For Save Request";
            default:
                return "";
        }
    }

    private handleEnforcementChange = () => {
        if(this.state.updateAudience.includedGroups.length !== 0) {
            this.setState({
                ...this.state,
                enforcementChanged: !this.state.enforcementChanged,
                edited: true
            })
            this.props.setEveryoneAudienceEnforcementEnabled(!this.props.tenantSettings.everyoneAudienceEnforcementEnabled); 
        }   
    }

    private handleSelectMobileSyncType = (o: ValueLabel ) => {
        this.setState({
            ...this.state,
            mobileSyncChanged: true,
            edited: true
        })
        this.props.setMobileSyncNumberSelected(o.value);
    }

    private handleSelectSyncLevel = (o: ValueLabel ) => {
        this.setState({
            ...this.state,
            mobileSyncChanged: true,
            edited: true
        })
        this.props.setSyncLevel(o.value);
    }
    private handleMobileSyncChange = () => {
        this.setState({
            ...this.state,
            mobileSyncChanged: true,
            edited: true
        })
        this.props.setMobileSyncEnabled(!this.props.tenantSettings.syncConfig.mobileSyncConfig.mobileSyncEnabled);
    }
    private checkIfGroupToBeRemoved(groupToBeRemoved: any) {
        return this.state.syncStatusesToBeUpdated.some(group => 
            group.action === "DeleteRequested" && groupToBeRemoved.referenceId === group.id
        ) 
    }

    private updateAudienceName = (e: React.ChangeEvent<HTMLInputElement>) => this.updateAudience("displayName", "input", e.target.value);

    private updateAudienceDescription = (e: React.ChangeEvent<HTMLInputElement>) =>
        this.updateAudience("description", "input", e.target.value);

    private updateAudience = (field: string, type: string, groupToAdd: any) => {
        let setValue;
        if (type === "input") {
            setValue = groupToAdd;
        } else {
            setValue = this.state.updateAudience[field].concat(groupToAdd);
        }

        this.setState(
            {
                ...this.state,
                edited: true,
                updateAudience: {
                    ...this.state.updateAudience,
                    [field]: setValue,
                },
                syncStatusPerGroup: {
                    ...this.state.syncStatusPerGroup,
                    [groupToAdd.id]: {
                        syncStatus: {status: "WaitingForSave", lastSyncCompletedTime: "Waiting For Sync"} as GroupSyncStatus,
                    }
                },
                syncStatusesToBeUpdated: [...this.state.syncStatusesToBeUpdated, ({id: groupToAdd.id, action: "WaitingForSave"} as SyncAction)],
            }
        );
    };

    private addToAudience = (groupToAdd: any) => {
        /* the only state that can exist is to return an audience that previosly existed
        from delete requested back to the previous state, so 2 things need to happen here
        1: need to remove the delete request from the sync queue that will be sent 
        2: need to restore to the pristine sync status */   
        var indexToRemove = this.state.syncStatusesToBeUpdated.findIndex((group => group.id === groupToAdd.referenceId));
        var modifiedSyncStatusesToBeUpdated = this.state.syncStatusesToBeUpdated;
        modifiedSyncStatusesToBeUpdated.splice(indexToRemove, 1);

        var newSyncStatusPerGroup = this.state.syncStatusPerGroup;
        var previousSyncStatus = this.state.pristineSync[groupToAdd.referenceId].sync;
        newSyncStatusPerGroup[groupToAdd.referenceId].syncStatus.status = previousSyncStatus;
        
        this.setState({
            ...this.state,
            edited: true,
            syncStatusesToBeUpdated: modifiedSyncStatusesToBeUpdated,
            syncStatusPerGroup: newSyncStatusPerGroup,
        })

    }

    private isUserGroupsEdited = () => {
        /* Two things to check, if the size of the sync status in syncStatusPerGroup is different from pristineSync */
        if (Object.keys(this.state.syncStatusPerGroup).length !== Object.keys(this.state.pristineSync).length) {
            return true;
        }
        
        /* If the status has been changed OR there was an error, we can resave */
        for ( var key in this.state.pristineSync) {
            if ((this.state.pristineSync[key].sync !== this.state.syncStatusPerGroup[key].syncStatus.status) || this.state.pristineSync[key].sync === 'Error') {
                return true;
            }
        }
        return false;
    }
    
    private isEdited = () => {
        /* Two things to check, if the size of the sync status in syncStatusPerGroup is different from pristineSync */
        if (Object.keys(this.state.syncStatusPerGroup).length !== Object.keys(this.state.pristineSync).length || this.state.enforcementChanged || this.state.mobileSyncChanged) {
            return true;
        }
        
        /* If the status has been changed OR there was an error, we can resave */
        for ( var key in this.state.pristineSync) {
            if ((this.state.pristineSync[key].sync !== this.state.syncStatusPerGroup[key].syncStatus.status) || this.state.pristineSync[key].sync === 'Error') {
                return true;
            }
        }
        return false;
    }

    private removeFromAudience = (groupToRemove: any) => {
        const checkForId = group => group.id === groupToRemove.referenceId || group.id === groupToRemove.id;
        const everyoneEnforcementWarningMsg = "After clicking Save to confirm, any Users that are not in another Synced group than the ones that are deleted will be disabled.";

        /* First we want to check to see if there is already an action for that specific id in the queue,
        so we are only modifying the action per id */
        if (this.state.syncStatusesToBeUpdated.some(checkForId)) {
            let updatedSyncStatus = this.state.syncStatusPerGroup;
            let updatedArray = this.state.syncStatusesToBeUpdated;
            var groupIndex = this.state.syncStatusesToBeUpdated.findIndex((group => group.id === groupToRemove.referenceId));

            /* We also want to check to see if this was an action on a current group or a newly added action
            There is no use sending a delete requested if it was never synced. */
            if (this.state.pristineAudience.includedGroups.some(group => (group.id === groupToRemove.referenceId) || (group.id === groupToRemove.id) )) {
                updatedArray[groupIndex].action = "DeleteRequested";
                this.setState({
                    ...this.state,
                    edited: true,
                    syncStatusesToBeUpdated: updatedArray,
                    syncStatusPerGroup: updatedSyncStatus,
                })
                if (this.props.tenantSettings.everyoneAudienceEnforcementEnabled){
                    warning(everyoneEnforcementWarningMsg);
                }
            } else {
                /* Need to remove from 2 places, need to remove from the updated audience list as well as remove from sync requested list */
                updatedArray.splice(groupIndex, 1); 
                var updatedAudienceList = updatedAudienceList = this.state.updateAudience["includedGroups"];
                var indexToRemove = this.state.updateAudience["includedGroups"].findIndex(function(item, i) {
                    return item.id === groupToRemove.referenceId;
                });
                updatedAudienceList.splice(indexToRemove, 1);
                this.setState({
                    ...this.state,
                    edited: true,
                    syncStatusesToBeUpdated: updatedArray,
                    updateAudience: {
                        ...this.state.updateAudience,
                        includedGroups: updatedAudienceList,
                    },
                })
            }

        } else {
            let updatedSyncStatus = this.state.syncStatusPerGroup;
            updatedSyncStatus[groupToRemove.referenceId].syncStatus.status = "DeleteRequested";
            this.setState({
                ...this.state,
                edited: true,
                syncStatusesToBeUpdated: [...this.state.syncStatusesToBeUpdated, ({id: groupToRemove.referenceId, action: "DeleteRequested"} as SyncAction)],
                syncStatusPerGroup: updatedSyncStatus,
            })
            if (this.props.tenantSettings.everyoneAudienceEnforcementEnabled){
                warning(everyoneEnforcementWarningMsg);
            }
        }
    };

    private isNameEmpty = (): boolean => {
        let audience = this.state.updateAudience;
        return audience.displayName === "" || !audience.displayName;
    };

    private fetchUsersInGroups = () => {
        this.setState({ ...this.state, fetching: true });
        this.state.updateAudience.includedGroups.forEach((group) => {
            audiencesApi.getUsersInGroup(group.referenceId).then((response) => {
                if (this._isMounted) {
                    this.setState({
                        ...this.state,
                        usersPerGroup: {
                            ...this.state.usersPerGroup,
                            [group.referenceId]: {
                                users: response,
                            },
                        },
                        fetching: false,
                    });
                }
            });
        });
    };

    private fetchSyncedUserGroups = () => {
        audiencesApi.getEveryoneAudience().then((response) => {
            if (this._isMounted) {
                this.setState({
                    ...this.state,
                    edited: false,
                    audienceId: response.id,
                    updateAudience: {
                        ...this.state.updateAudience,
                        ...response,
                    },
                    pristineAudience: {
                        ...this.state.pristineAudience,
                        ...response,
                    }
                });
            }
        }).then( () => {
             audiencesApi.getIncludedEveryoneGroups(this.state.audienceId).then((response) => {
                let groupList: AdGroupSuggestions[] = response.map((group) => {
                    return { id: group.Id, displayName: group.Name, referenceId: group.ReferenceId };
                });
                const allAdGroupId = response.find(g => g.Name === this._allAzureADGroupName);
                if (allAdGroupId) {
                    this.setState({
                        ...this.state,
                        allAdGroupId: allAdGroupId.ReferenceId
                    })
                }
                if (this._isMounted) {
                    this.setState(
                        {
                            ...this.state,
                            updateAudience: {
                                ...this.state.updateAudience,
                                includedGroups: groupList,
                            },
                            pristineAudience: {
                                ...this.state.pristineAudience,
                                includedGroups: groupList
                            },
                            fetching: false,
                        },
                        () => {
                            this.setState({ ...this.state, pristineAudience: this.state.updateAudience }, () => {
                                if (this.state.updateAudience.includedGroups.length !== 0) {
                                    this.fetchSyncStatusForGroups(true);
                                }

                            });
                        }
                    );
                }
            });
        });
    }

    private fetchEveryoneAudienceGroups= () => {
        audiencesApi.getEveryoneAudienceGroups().then((response) => {
            let groupList: AdGroupSuggestions[] = response.map((group) => {
                return { id: group.Id, displayName: group.Name, referenceId: group.ReferenceId };
            });

          
            this.setState(
                {
                    ...this.state,
                    updateAudience: {
                        ...this.state.updateAudience,
                        includedGroups: groupList,
                    },
                    fetching: false,
                },
                () => {
                    this.setState({ ...this.state, pristineAudience: this.state.updateAudience }, () => {
                        if (this.state.updateAudience.includedGroups.length !== 0) {
                            this.fetchSyncStatusForGroups(true);
                        }

                    });
                }
            );
        })
    }
    private fetchSyncStatusForGroups = (onFirstMount?: boolean) => {
        this.setState({ ...this.state, fetching: true });
        this.state.updateAudience.includedGroups.forEach((group) => {
            audiencesApi.getGroupSyncStatus(group.id).then((response) => {
                if (this._isMounted) {
                    this.setState({
                        ...this.state,
                        syncStatusPerGroup: {
                            ...this.state.syncStatusPerGroup,
                            [group.referenceId]: {
                                syncStatus: response,
                            },
                        },
                        fetching: false,
                        saving: false,
                    });
                    if (onFirstMount) {
                        this.setState({
                            ...this.state,
                            pristineSync: {
                                ...this.state.pristineSync,
                                [group.referenceId]: {
                                    sync: response.status,
                                },
                            },
                        })
                    }
                }
            });
        });
    };

    private saveAudience = () => {
        let groups = this.state.updateAudience.includedGroups;
        let syncStatuses = this.state.syncStatusPerGroup; 
        let newSyncStatusPerGroup = this.state.syncStatusPerGroup;
        let statusesToUpdate: SyncAction[] = [];

        if (this._waitingForAdSyncCompletion) {
            errorAlert(`You must wait for ${this._allAzureADGroupName} to finish Syncing before you try to sync another group. Please try again later`, 5000);
            this.setState({
                ...this.state,
                saving: false,
                edited: false
            })
            return;
        }
        groups.forEach((group) => {
            /* Want to see if
            1:  it is not synced yet, and not already added to sync list
            2:  there was an error syncing, then we add again to the sync list */
            if ((syncStatuses[group.referenceId]?.syncStatus?.status === 'NotSyncedYet' && !this.state.syncStatusesToBeUpdated.some(g => g.id === group.referenceId))
                || syncStatuses[group.referenceId]?.syncStatus.status === 'Error' || syncStatuses[group.id]?.syncStatus.status === 'WaitingForSave') {
                
                if (group.referenceId) {
                    newSyncStatusPerGroup[group.referenceId].syncStatus.status = "SyncRequested";
                    statusesToUpdate.push({id: group.referenceId, action: "SyncRequested"} as SyncAction)    
                } else {
                    newSyncStatusPerGroup[group.id].syncStatus.status = "SyncRequested";
                    statusesToUpdate.push({id: group.id, action: "SyncRequested"} as SyncAction)    
                }
            }
            if (group.referenceId === null) {
                statusesToUpdate.push({id: group.referenceId, action: "SyncRequested"} as SyncAction)
            }
            if (syncStatuses[group.referenceId]?.syncStatus?.status === "DeleteRequested") {
                newSyncStatusPerGroup[group.referenceId].syncStatus.status = "DeleteRequested";
            }
        });
            this.setState({
                ...this.state,
                syncStatusesToBeUpdated: [
                    ...this.state.syncStatusesToBeUpdated, ...statusesToUpdate]
            }, () => {

                if ((this.state.enforcementChanged || this.state.mobileSyncChanged) && this.state.syncStatusesToBeUpdated.length > 0) {
                    this.setState({
                        ...this.state,
                        saving: true,
                        edited: true
                    })
                    //Begin sync
                    syncFunctionsApi.startGroupSync(this.state.syncStatusesToBeUpdated, this.props.tenantSettings.syncConfig.syncType)
                     .then(() => {
                         //Save tenant settings
                        settingsApi.UpdateTenantSettings(this.props.tenantSettings)
                            .then(() => {
                            this.setState({
                                ...this.state,
                                syncStatusesToBeUpdated: [],
                                syncStatusPerGroup: newSyncStatusPerGroup,
                                saving: false,
                                edited: false,
                                enforcementChanged: false,
                                mobileSyncChanged: false,
                            }, 
                            this.fetchSyncedUserGroups
                            );
                        })
                        .catch((error) => {
                            if (error.response.status === 500) {
                                errorAlert(this.props.intl.formatMessage({id: "audiences.internalServerError", defaultMessage: "There was an error processing your request, please contact support."}));
                                this.fetchSyncedUserGroups();
                            } else if (error.response.status === 400) {
                                errorAlert(this.props.intl.formatMessage({id: "audiences.badRequestError", defaultMessage: "There was an error sending your request."}));
                                this.fetchSyncedUserGroups();
                            } 
                        });
                    })
                }
                else if (this.state.syncStatusesToBeUpdated.length > 0) {
                    this.syncGroups(newSyncStatusPerGroup)
                }
                else if (this.state.enforcementChanged || this.state.mobileSyncChanged) {
                    this.saveTenantSettings()
                }
               else {
                    this.setState({
                        ...this.state,
                        saving: false
                    })
                }
            });
    };

    private syncGroups = (newSyncStatusPerGroup: any) => {
        this.setState({
            ...this.state,
            saving: true,
            edited: true
        })
        syncFunctionsApi.startGroupSync(this.state.syncStatusesToBeUpdated, this.props.tenantSettings.syncConfig.syncType)
        .then(() => {
            this.setState({
                ...this.state,
                syncStatusesToBeUpdated: [],
                syncStatusPerGroup: newSyncStatusPerGroup,
                saving: false,
                edited: false,
                enforcementChanged: false,
                mobileSyncChanged: false,
            }, 
            this.fetchSyncedUserGroups
            );
        })
        .catch((error) => {
            if (error.response.status === 500) {
                errorAlert(this.props.intl.formatMessage({id: "audiences.internalServerError", defaultMessage: "There was an error processing your request, please contact support."}));
                this.fetchSyncedUserGroups();
            } else if (error.response.status === 400) {
                errorAlert(this.props.intl.formatMessage({id: "audiences.badRequestError", defaultMessage: "There was an error sending your request."}));
                this.fetchSyncedUserGroups();
            } 
        });
    }
    private saveGroupAndTenantSettings = () => {
        /* Only need to make a call to tenant settings if the enforcement was changed */
        if (this.isEdited()) {
            this.saveAudience();
        }
    }

    private saveTenantSettings = () => {
        if (this.state.enforcementChanged || this.state.mobileSyncChanged) {
            this.setState({
                ...this.state,
                saving: true,
                edited: true
            }, () => {
                settingsApi.UpdateTenantSettings(this.props.tenantSettings)
                .then(() => {
                    this.setState({
                        ...this.state,
                        saving: false,
                        edited: false,
                        enforcementChanged: false,
                        mobileSyncChanged: false,
                        showUserSyncModal: false,
                    })
                    this.fetchEveryoneAudienceGroups()
                })
            })
        }
    }

    private deleteAndReplace = (deleteId: string, replaceId: string) => {
        this.setState({ ...this.state, deleting: true });
        audiencesApi.deleteAndReplaceAudience(deleteId, replaceId).then((response) => {
            this.setState({
                ...this.state,
                deleting: false,
            });
            this.props.getTenantConfig(); //update tags in tenant settings store
            this.closeModal();
            this.props.redirectTo("/" + this.props.match.params.tenant + "/admin/audiences");
        });
    };

    private closeModal = () => {
        this.setState({ ...this.state, showReplaceDeleteModal: false });
    };

    private downloadEveryoneUserList = () => audiencesApi.getEveryoneGroupUsersCsv();

    private resync = async () => {
        this.setState({ 
            ...this.state, 
            confirmResyncDialogOpen: false,
            waitingForResyncCompletion: true
        })

        try {
            await audiencesApi.startAllResync();

            this.setState({ 
                ...this.state,
                resyncSnackbarMsg: "Sync is in progress and may take some time to complete. Please check back later.",
            });
        }
        catch {
            this.setState({
                ...this.state,
                resyncErrorSnackbarMsg: "There was a problem with the sync. Please try again later.",
            });
        }

        this.setState({
            ...this.state,
            waitingForResyncCompletion: false
        })
    }

    private getResyncConfirm = (): JSX.Element => (
        <ConfirmDialog
            title={`Resync all ${this.props.syncType} users`}
            confirmLabel="Resync"
            denyLabel="Cancel"
            open={this.state.confirmResyncDialogOpen}
            onClose={() => this.setState({ ...this.state, confirmResyncDialogOpen: false })}
            onConfirm={this.resync}
            onDeny={() => this.setState({ ...this.state, confirmResyncDialogOpen: false })}
        >
            <>
                Resyncing will update and match user details of all your current {this.props.syncType} accounts into Sparrow.
                Deleted users from your {this.props.syncType} will also be removed from Sparrow.
                <br />
                <br />
                Do you want to continue?
                <br />
                <br />
            </>
        </ConfirmDialog>
    );

}

interface ComponentState {
    audienceId: string;
    updateAudience: UpdateEveryoneAudience;
    pristineAudience: UpdateEveryoneAudience;
    saving: boolean;
    deleting: boolean;
    fetching: boolean;
    activeTab: string;
    usersPerGroup: {
        [Id: string]: {
            users: User[];
        };
    };
    syncStatusPerGroup: {
        [referenceId: string]: {
            syncStatus: GroupSyncStatus;
        };
    };
    pristineSync: {
        [referenceId: string]: {
            sync: string;
        };
    };
    showReplaceDeleteModal: boolean;
    audienceList: Audience[];
    syncStatusesToBeUpdated: SyncAction[];
    edited: boolean;
    defaultEnforcementState: boolean;
    enforcementChanged: boolean;
    mobileSyncChanged: boolean;
    showUserSyncModal: boolean;
    defaultSyncOptions: string;
    allAdGroupId: string;
    waitingForAdSyncCompletion: boolean;
    confirmResyncDialogOpen: boolean;
    waitingForResyncCompletion: boolean;
    resyncSnackbarMsg: string;
    resyncErrorSnackbarMsg: string;
}

interface RouteParams {
    tenant: string;
    // audienceId: string;
}

interface ComponentProps {
    intl: IntlShape;
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps & RouteComponentProps<RouteParams>) => ({
        ...ownProps,
        // TODO: Change canEdit in the future once we figure out how the message should work
        canEdit: true,
        tenantSettings: state.settings.tenantSettings,
        syncType: state.settings.tenantSettings.syncConfig.syncType
    }),
    {
        redirectTo: push,
        getTenantConfig: settingsActions.getTenantSettings,
        getClientConfig: settingsActions.getClientSettings,
        saveTenantSettings: settingsActions.saveTenantSettings,
        setEveryoneAudienceEnforcementEnabled: settingsActions.setEveryoneAudienceEnforcementEnabled,
        setMobileSyncEnabled: settingsActions.setMobileSyncEnabled,
        setMobileSyncNumberSelected: settingsActions.setMobileSyncNumberSelected,
        setSyncLevel: settingsActions.setSyncLevel,
        setSyncAllAdUsers: settingsActions.setSyncAllAdUsersEnabled
    }
);

type PropsWithRedux = ConnectedProps<typeof connector>;

export default injectIntl(connector(EveryoneAudienceManager));
