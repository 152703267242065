import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import * as actions from "../../actionCreator";
import { GlobalApplicationState } from "globalApplicationState";
import { push } from "react-router-redux";
import { Epoch, Event as IEvent, EventFilterValues, EventListingPage, EventListItem } from "../../models";
import moment from "moment";
import Loading from "modules/common/components/loading";

import DeleteEventConfirmation from "../confirmations/deleteEvent";
import EventFilters from "../event-filters/eventFilters";
import EventPreview from "../event-views/eventPreview";

import TabContent from "pages/common/tabContent";
import TabContentToolbar from "pages/common/tabContentToolbar";

import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import SettingsIcon from "@mui/icons-material/Settings";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ChipLabels from "modules/common/components/chips/chipLabels";
import Paging from "modules/common/components/paging";
import { ImageScale } from "modules/posts/models";
import Cookies from "js-cookie";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import MoreOptions from "./moreOptions";
import confirm from "utils/notyPopups";
import { SortStyle } from "utils/managementUtils";
import ManagementEmptyResults from "modules/common/components/managementEmptyResults";
import { TenantSettingsTagGroup } from "modules/settings";
import { ROWS_PER_PAGE_COOKIE_NAMES } from "utils/cookie";
import { formatDate } from "utils/dateFormatting";
import { contentBandsSlice } from "modules/contentBands/reducer";
import { isPinnedDeleted } from "modules/common/hooks/data/useContentBands";


interface HeaderCell {
    id: string;
    label: string;
    ascValue?: SortStyle;
    descValue?: SortStyle;
    clickable?: boolean;
}


class EventList extends React.Component<PropsWithRedux, ComponentState> {
    constructor(props: PropsWithRedux) {
        super(props);

        let initialFilters = this.getDefaultFilterValues();
        initialFilters.sortType = props.initialSort;

        this.state = {
            eventPreview: undefined,
            filters: initialFilters,
            hasFiltersApplied: false,
            isPagedLoad: false,
            selectedItems: [],
            showDeleteEventConfirmation: false,
            showEventPreview: false,
            Method: this.props.initialSort, //default ing on page load.
            previewDraftId: ""
        };
    }

    public componentDidMount() {
        moment.locale("en");
    }

    public render() {
        const { page } = this.props;

        if (!this.props.show)
            return <React.Fragment></React.Fragment>;

        if (page.isFetching && !this.state.isPagedLoad)
            return <div style={{ paddingTop: "60px" }}><Loading /></div>;

        return (
            <div>
                {this.getToolbar()}
                <TabContent>
                    {this.getFilter()}
                    {this.getList()}
                    {this.state.eventPreview &&
                        <EventPreview show={this.state.showEventPreview} event={this.state.eventPreview} onEditDetails={this.onEditEvent} onClose={this.onHideEventPreview} />
                    }
                    <DeleteEventConfirmation show={this.state.showDeleteEventConfirmation} multiple={this.state.selectedItems.length > 1} onConfirm={this.onDeleteEvents} onCancel={this.onHideDeleteEventConfirmation} />
                </TabContent>
            </div>
        );
    }

    private formatEpochTime = (time?: Epoch): string => {
        if (time) {
            return moment(new Date(time.date)).format("MMM D, YYYY");
        }
        return "-";
    }

    private formatEpochTimeFromNow = (time?: Epoch): string => {
        if (time) {
            return moment(new Date(time.date)).fromNow();
        }
        return "-";
    }

    private formatTime = (startTime: string, isAllDay?: boolean): string => {
        if (!startTime) return "-";
        if (isAllDay) return formatDate(new Date(startTime), true, false);
        return moment(new Date(startTime)).format("MMM D, YYYY");
    }


    private getDefaultFilterValues = (): Partial<EventFilterValues> => {
        const filters: Partial<EventFilterValues> = {
            eventTypes: [],
            eventStates: [],
            lcidToSearch: "en-us",
            textToSearch: "",
            tags: []
        };
        return filters;
    }


    private getFilter = (): JSX.Element => {
        return (
            <EventFilters
                filters={this.state.filters}
                pageId={this.props.page.id}
                onChangeFilters={this.onChangeFilters}
                onClearFilters={this.onClearFilters}
                availableTopics={this.props.availableTopics}
                onlyNewer={this.props.page.id === 'scheduled'}
                onlyOlder={this.props.page.id === 'published'}
            />
        );
    }

    private getHeader = (): HeaderCell[] => {
        const { page } = this.props;

        let header: HeaderCell[] = [
            {
                id: "title",
                label: "Event title",
                ascValue: SortStyle.titleAsc,
                descValue: SortStyle.titleDesc,
                clickable: true
            },
        ];

        header.push({ id: "options", label: "" })

        if (page.id === "all")
	      header.push({ id: "eventState", label: "State", ascValue: SortStyle.stateAsc, descValue: SortStyle.stateDesc, clickable: true });

    	header.push({ id: "eventStartTime", label: "Start date", ascValue: SortStyle.startAsc, descValue: SortStyle.startDesc, clickable: true });

        if (page.id !== "drafts")
	      header.push({ id: "publishedTime", label: page.id === "published" ? "Published" : "Publish date", ascValue: SortStyle.publishAsc, descValue: SortStyle.publishDesc, clickable: true });

    	header.push({ id: "modifiedTime", label: "Last modified", ascValue: SortStyle.modifiedAsc, descValue: SortStyle.modifiedDesc, clickable: true });
    	header.push({ id: "eventType", label: "Type", ascValue: SortStyle.typeAsc, descValue: SortStyle.typeDesc, clickable: true });
    	header.push({ id: "author", label: "Published by", ascValue: SortStyle.authorAsc, descValue: SortStyle.authorDesc, clickable: true });

        if (page.id === "published" || page.id === "all")
          header.push({ id: "rsvpCount", label: "RSVPs"});

        header.push({ id: "tags", label: "Topics" });

        return header;
    }

    private getList = (): JSX.Element => {
        const { page } = this.props;
        const { selectedItems } = this.state;

        const rowCount = page.events.length || 10;

        if (page.isFetching)
            return <div style={{ paddingTop: "60px" }}><Loading /></div>;

        if (!page.events.length)
            return (
        	<ManagementEmptyResults searchType={"Events"} hasFilters={this.state.hasFiltersApplied}/>
            );

        return (
            <React.Fragment>
                {this.state.hasFiltersApplied &&
                    <Typography variant="h2" className="filter-results">Results</Typography>
                }
	        <TableContainer>
                    <Table size="medium">
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        color="primary"
                                        indeterminate={selectedItems.length > 0 && selectedItems.length < rowCount}
                                        checked={rowCount > 0 && selectedItems.length === rowCount}
                                        onChange={this.onSelectAllEvents}
                                        inputProps={{ "aria-label": "select all events" }}
                                    />
                                </TableCell>
                                {this.getHeader().map((headerCell) => (
                                    <TableCell
                                        key={headerCell.id}
                                        align={headerCell.id === "title" ? "left" : "center"}
                                        padding={headerCell.id === "title" ? "none" : "normal"}
                                        onClick={() => this.onClickHeader(headerCell.descValue, headerCell.ascValue)}
                                    >
                                        <div style={{ paddingBottom: this.state.Method === headerCell.ascValue || this.state.Method === headerCell.descValue ? "8px" : "0px", cursor: headerCell.clickable ? "pointer" : "" }}>
                                            {headerCell.label}
                                            {this.state.Method === headerCell.ascValue && <ArrowUpward htmlColor="#7A7A7A" style={{ position: "relative", top: "8px", paddingLeft: "2px" }} />}
                                            {this.state.Method === headerCell.descValue && <ArrowDownward htmlColor="#7A7A7A" style={{ position: "relative", top: "8px", paddingLeft: "2px" }} />}
                                        </div>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {page.events
                                .map((event, index) => {
                                    const isEventSelected = this.isEventSelected(event);
                                    const labelId = `event-list-checkbox-${index}`;
                                    const colorToUse = event.status.toLowerCase() === "published" ? "green" : event.status.toLowerCase() === "draft" ? "grey" : "yellow";

                                    return (
                                        <TableRow
                                            hover
                                            onClick={() => this.handleEventClick(event)}
                                            role="checkbox"
                                            aria-checked={isEventSelected}
                                            tabIndex={-1}
                                            key={event.id}
                                            selected={isEventSelected}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    color="primary"
                                                    checked={isEventSelected}
                                                    inputProps={{ "aria-labelledby": labelId }}
                                                    onClick={(ev) => this.onSelectEvent(ev, event)}
                                                />
                                            </TableCell>
                                            <TableCell component="th" id={labelId} scope="row" padding="none">
                                                {event.title}
                                            </TableCell>
                                            <TableCell >
                                                <MoreOptions
                                                    onPreview={this.onPreviewEvent}
                                                    onClone={this.onCloneEvent}
                                                    onDelete={this.onDeleteEvent}
                                                    onUnpublish={this.onUnpublishEvent}
                                                    event={event} />
                                            </TableCell>
                                            {page.id === "all" &&
                                                <TableCell align="center" style={{ textTransform: "capitalize" }}>
                                                    <span className={`item-state ${colorToUse}`}>{event.status}</span>
                                                </TableCell>
                                            }
                                            <TableCell align="center">{this.formatTime(event.eventStartTime, event.isAllDayEvent)}</TableCell>
                                            {page.id !== "drafts" && <TableCell align="center">{this.formatEpochTime(event.publishedTime)}</TableCell>}
                                            <TableCell align="center">{this.formatEpochTimeFromNow(event.updatedTime)}</TableCell>
                                            <TableCell align="center" style={{ textTransform: "capitalize" }}>{event.eventType}</TableCell>
                                            <TableCell align="center">{event.authorFullName ? event.authorFullName : event.author}</TableCell>
                                            {(page.id === "published" || page.id === "all") && <TableCell align="center">{event.rsvpCount || "-"}</TableCell>}
                                            <TableCell align="center"><ChipLabels chips={event.tags || []} emptyText="No tags selected" /></TableCell>
                                        </TableRow>
                                    );
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <Paging
                    currentPage={page.currentPage}
                    items={page.events}
                    totalItems={page.totalEvents}
                    totalPages={page.totalPages}
                    onChangePage={this.onChangePage}
                    resetSelection={this.resetSelection}
                />
            </React.Fragment>
        );
    }

    private getToolbar = (): JSX.Element => {
        const { page } = this.props;
        const { selectedItems } = this.state;

        if (!selectedItems.length)
            return <React.Fragment></React.Fragment>;

        return (
            <TabContentToolbar>
                <div>
                    {this.getToolbarIcon(`${selectedItems.length} selected`, <ClearIcon />, this.onClearAllEvents)}
                </div>
                <div>
                    {selectedItems.length === 1 &&
                        <React.Fragment>
                            {(page.id === "published" || page.id === "all") && selectedItems[0].eventType !== "informational" && selectedItems[0].publishedTime !== null &&
                                this.getToolbarIcon("Manage Participants", <SettingsIcon />, this.onManageAttendance)
                            }
                            {this.getToolbarIcon("Edit", <EditIcon />, this.onEditEvent)}
                            {this.getToolbarIcon("Preview", <VisibilityIcon />, () => this.onPreviewEvent())}
                            {this.getToolbarIcon("Duplicate", <FileCopyIcon />, () => this.onCloneEvent())}
                        </React.Fragment>
                    }
                    {this.getToolbarIcon("Delete", <DeleteIcon />, this.onShowDeleteEventConfirmation)}
                </div>
            </TabContentToolbar>
        );
    }

    private getToolbarIcon = (title: string, icon: React.ReactNode, onClick: () => void) => {
        return (
            <Button aria-label={title.toLowerCase()} color="primary" startIcon={icon} onClick={onClick}>
                {title}
            </Button>
        );
    }

    /**
     * Removes content bands from redux when a pinned post
     * gets deleted. This will force a re-fetch if user goes to content bands
     * page during session and keeps their content up to date.
     */
    private getIsPinnedDeleted = (eventToDelete?: EventListItem): boolean => {
        const { contentBands, publishedContentBands } = this.props.contentBandsState;
        const selectedIds = this.state.selectedItems.map((event: EventListItem) => event.id);
        const idsToCheck = eventToDelete === undefined ? selectedIds : [eventToDelete.id];

        return isPinnedDeleted(contentBands, publishedContentBands, idsToCheck);
    };

    private onDeleteEvent = async (eventToDelete: EventListItem) => {
        const confirmationMessage: string = `You're about to permanently delete '${eventToDelete.title}'. You cannot undo this action.`;
        const isPinnedDeleted = this.getIsPinnedDeleted(eventToDelete);

        if (await confirm.show({
            title: `Delete Event`,
            text: (
                <div>
                    <div>{confirmationMessage}</div>
                    <br />
                    <div>Are you sure?</div>
                </div>
            ),
            yesColor: "#a80000",
            yesText: "Delete",
            noText: "Cancel"
        })) {
            if (isPinnedDeleted)
                this.props.clearContentBands();

            this.props.deleteEvents([eventToDelete]).then(() => {
                this.onClearAllEvents();
            });
        }
    }

    private onUnpublishEvent = async (eventToUnpublish: EventListItem) => {
        const confirmationMessage: string = `Unpublishing an event will reset its RSVP report, clearing the list of users who have submitted their RSVP and their response to any event questions.`;

        if (await confirm.show({
            title: `Unpublish Event`,
            text: (
                <div>
                    <div>{confirmationMessage}</div>
                    <br />
                    <div>Would you like to proceed?</div>
                </div>
            ),
            yesColor: "#3b78ab",
            yesText: "Unpublish",
            noText: "Cancel"
        })) {
            this.props.unpublishEvent(eventToUnpublish.id).then(() => {
                this.onClearAllEvents();
            });
        }
    }

    private isExpired = (time?: Epoch): boolean => {
        if (time) {
            return moment(new Date(time.date)) <= moment();
        }
        return false;
    }


    private isEventSelected = (event: EventListItem): boolean => {
        return this.state.selectedItems.findIndex((selectedItem) => selectedItem.id === event.id) !== -1;
    }


    private onChangeFilters = (filters: Partial<EventFilterValues>) => {
        const updatedFilters = { ...this.state.filters, ...filters };
        this.setState({ filters: updatedFilters, hasFiltersApplied: true, isPagedLoad: true });
        this.props.fetchPage(1, updatedFilters);
    }

    private onClearFilters = () => {
        this.setState({ hasFiltersApplied: false, filters: { ...this.getDefaultFilterValues() }, isPagedLoad: true });
        this.props.fetchPage(1, {});
    }


    private onClearAllEvents = () => {
        this.setState({ selectedItems: [] });
    }

    private onCloneEvent = (eventToClone?: EventListItem) => {
        let toPreview = (eventToClone === undefined) ? this.state.selectedItems[0] : eventToClone

        this.props.cloneEvent(toPreview.draftId).then((response) => {
            if (!!response) {
                this.props.redirectTo("/" + this.props.tenant + "/admin/events/edit/" + response.id);
                this.onClearAllEvents();
            }
        });
    }

    private handleEventClick = (event: EventListItem) => {
        if (event.publishedTime !== null) {
            this.setState({ previewDraftId: event.draftId });
            this.onPreviewEvent(event);
        }
        else {
            this.props.redirectTo("/" + this.props.tenant + "/admin/events/edit/" + event.id);
        }
    }

    private onDeleteEvents = () => {
        this.onHideDeleteEventConfirmation();
        this.props.deleteEvents(this.state.selectedItems);
        this.onClearAllEvents();
    }

    private onEditEvent = () => {
        let toView = this.state.selectedItems[0]?.draftId ?? this.state.previewDraftId;
        this.props.redirectTo("/" + this.props.tenant + "/admin/events/edit/" + toView);
    }

    private onHideDeleteEventConfirmation = () => {
        this.setState({ showDeleteEventConfirmation: false });
    }

    private onHideEventPreview = () => {
        this.setState({ eventPreview: undefined, showEventPreview: false });
    }

    private onManageAttendance = () => {
        this.props.redirectTo("/" + this.props.tenant + "/admin/events/attendance/" + this.state.selectedItems[0].id);
    }

    private onPreviewEvent = async (eventToDisplay?: EventListItem) => {
        const toPreview = (eventToDisplay === undefined) ? this.state.selectedItems[0] : eventToDisplay

        try {
            const draft = await this.props.getDraft(toPreview.draftId, ImageScale.Modal);
            this.setState({ eventPreview: draft, showEventPreview: true });
        } catch (error) {
            this.setState({ eventPreview: undefined, showEventPreview: false });
        }

    }

    private onSelectAllEvents = () => {
        if (this.state.selectedItems.length === this.props.page.events.length)
            this.setState({ selectedItems: [] });
        else
            this.setState({ selectedItems: this.props.page.events });
    }

    private onSelectEvent = (ev, event: EventListItem) => {
        let selectedItems = this.state.selectedItems.slice();
        ev.stopPropagation();

        if (selectedItems.findIndex((selectedItem) => selectedItem.id === event.id) === -1)
            selectedItems = selectedItems.concat([event]);
        else
            selectedItems = selectedItems.filter(selectedItem => selectedItem.id !== event.id);

        this.setState({ selectedItems });
    }

    private onShowDeleteEventConfirmation = async () => {
        const confirmationMessage: string = (this.state.selectedItems.length > 1)
            ? `You're about to permanently delete these ${this.state.selectedItems.length} events.`
            : `You're about to permanently delete '${this.state.selectedItems[0].title}'. You cannot undo this action.`;

        if (await confirm.show({
            title: `Delete Event`,
            text: (
                <div>
                    <div>{confirmationMessage}</div>
                    <br />
                    <div>Are you sure?</div>
                </div>
            ),
            yesColor: "#a80000",
            yesText: "Delete",
            noText: "Cancel"
        })) {
            if (this.state.selectedItems.length === 1) {
                this.props.deleteEvents([this.state.selectedItems[0]]).then(() => {
                    this.onClearAllEvents();
                });
            }
            else {
                this.props.deleteEvents(this.state.selectedItems).then(() => {
                    this.onClearAllEvents();
                });
            }
        }
    }

    //Default to descending, if currently descending make it ascending.
    private onClickHeader = (descValue: SortStyle | undefined, ascValue: SortStyle | undefined) => {
        const rowsPerPage = Cookies.get(ROWS_PER_PAGE_COOKIE_NAMES.DEFAULT);

        if (descValue === undefined || ascValue === undefined) {
            return;
        }

        if (this.state.Method === descValue) {
            this.setState({ Method: ascValue, filters: { ...this.state.filters, sortType: ascValue } }, () => {
                this.onChangePage(0, Number(rowsPerPage) ?? 10);
            });
        }
        else {
            this.setState({ Method: descValue, filters: { ...this.state.filters, sortType: descValue } }, () => {
                this.onChangePage(0, Number(rowsPerPage) ?? 10);
            });
        }
    }

    private resetSelection = () => {
        this.setState({selectedItems: []});
    }

    private onChangePage = (page: number, rowsPerPage: number) => {
        this.setState({ isPagedLoad: true });
        this.props.fetchPage(page + 1, this.state.filters, rowsPerPage);
    }
}


interface ComponentProps {
  show: boolean;
  page: EventListingPage;
  fetchPage: (pageNumber: number, filters: Partial<EventFilterValues>, pageAmount?: number) => void;
  initialSort: SortStyle;
  availableTopics: TenantSettingsTagGroup[];
}

interface ComponentState {
    eventPreview: IEvent | undefined;
    filters: Partial<EventFilterValues>;
    hasFiltersApplied: boolean;
    isPagedLoad: boolean;
    selectedItems: EventListItem[];
    showDeleteEventConfirmation: boolean;
    showEventPreview: boolean;
    Method: SortStyle;
    previewDraftId: string;
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        tenant: state.tenant.id,
        contentBandsState: state.contentBands
    }),
    {
        cloneEvent: actions.cloneEvent,
        deleteEvents: actions.deleteEvents,
        unpublishEvent: actions.unpublishEvent,
        getDraft: actions.getDraft,
        redirectTo: push,
        clearContentBands: contentBandsSlice.actions.CLEAR_BANDS
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(EventList);
