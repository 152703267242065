import * as React from "react";

import Button from "@mui/material/Button";
import { AttachedFile } from "modules/gallery";


class FileAttachments extends React.Component<ComponentProps, ComponentState> {
  public render() {
    if (!this.props.fileAttachments || !this.props.fileAttachments.length)
      return <React.Fragment></React.Fragment>;

    return (
      <div className="documents">
        <div className="documents-label">Documents ({this.props.fileAttachments.length})</div>
        <div>
          {this.props.fileAttachments.map((fileAttachment) =>
            <Button key={fileAttachment.blobId} variant="outlined" size="small" onClick={() => this.openFile(fileAttachment)}>{fileAttachment.fileName}</Button>
          )}
        </div>
      </div>
    );
  }

  private openFile = (fileAttachment: AttachedFile) => {
    window.open(fileAttachment.fileUrl!);
  }
}


interface ComponentProps {
  fileAttachments: AttachedFile[] | undefined;
}

interface ComponentState {}

export default FileAttachments;
