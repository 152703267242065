import * as Actions from './actions';
import { SupportItem, AssociatedLink, SupportTab, SupportItemTypes } from './models';
import { supportApi } from 'api/instances';
import API from 'api';
import { AppThunkResult } from 'typedActions';
import { push } from 'react-router-redux';
import XhrUpload from 'utils/xhrUpload';
import { GlobalApplicationState } from 'globalApplicationState';
import MsalAuthModule from 'authentication/msalAuthModule';

export const createSupportItem = (itemType: string): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.CreateNewSupportItem({}));

    return supportApi.CreateNewItem(itemType)
    .then(result => {
        dispatch(Actions.CreateNewItemComplete({ itemId: result, succeeded: true }));
        return result;
    })

}

export const fetchSupportItem = (itemId: string): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.FetchSupportItem({}));

    supportApi.FetchSupportItem(itemId)
    .then(result => {
        dispatch(Actions.FetchSupportItemComplete({ item: result, succeeded: true }));
        return result;
    })
    .catch(err => {
        dispatch(Actions.FetchSupportItemComplete({  item: null, succeeded: false }));
    })

}

export const saveSupportItem = (item: SupportItem): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.SaveSupportItem({}));

    return supportApi.SaveSupportItem(item)
    .then(result => {
       dispatch(Actions.SaveSupportItemComplete({ succeeded: true }));
    })
    .catch(err => {
       dispatch(Actions.SaveSupportItemComplete({ succeeded: false }));
    })
}

export const deleteSupportItems = (ids: string[]): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.DeleteSupportItem({}));

    return Promise.all(ids.map(id => supportApi.DeleteSupportItem(id)))
    .then(result => {
        dispatch(Actions.DeleteSupportItemComplete({ succeeded: true }))
    })
    .catch(err => {
        dispatch(Actions.DeleteSupportItemComplete({ succeeded: false }))
    })
}

export const publishSupportItem = (item: SupportItem): AppThunkResult => (dispatch, getState) => {
    var returnUrl;
    if (item.itemType === SupportItemTypes.training) {
        returnUrl = `/${getState().tenant.id}/training`;
    } else {
        returnUrl = `/${getState().tenant.id}/support`;
    }

    dispatch(Actions.PublishSupportItem({}));

    let defaultLang = getState().settings.tenantSettings.defaultLCID;

    return supportApi.PublishSupportItem(item)
    .then(result => {
        dispatch(Actions.PublishSupportItemComplete({ succeeded: true }))
    })
    .then(() => {
        dispatch(push(returnUrl));
        dispatch(Actions.PublishSucess({}));
    })
    .catch(error => {
        let validationResult = { id: item.id, itemTitle: getTranslatedContentField("title", item.translatedContent, defaultLang) };
        if (error) {
            dispatch(Actions.PublishSupportItemComplete({ succeeded: false, validationResult: { ...validationResult, errors: error.response.data?.ModelState } }))
        }
    })
}

export const updateField = <F extends keyof SupportItem>(field: F) => (value: SupportItem[F]) => (dispatch, getState) => {
    return dispatch(Actions.FieldUpdate({ field, value }));
}

const getTranslatedContentField = (field: string, tc: {[lang: string]: any}, lang: string): string => {
    const langs = Object.keys(tc || {}).sort();
    if(!tc || langs.length === 0) return "";
    return tc[lang] ? tc[lang][field] : tc[langs[0]][field];
}

export const uploadSupportingDocuments = (file: File, type: string, onProgress: (percentCompleted: number) => void, onLoad?: (e) => void, onError?: (e) => void, onAbort?: (e) => void) => async (dispatch, getState) => {

    dispatch(Actions.UploadFiles({}));

    return MsalAuthModule.getInstance().getAccessToken().then(accessToken => {
        const requestDetails = API.mediaLibrary.UploadSupportingDocument(file, type);
        const url = getState().config.SparrowClientApiUrl + requestDetails.url
                    .replace("{tenant}", getState().tenant.id);

                    const handlers = { onLoad, onAbort, onError, onProgress };

            return new XhrUpload(url, requestDetails, handlers)
                .authenticateWith(accessToken)
                .upload();
    })
    .then((response: string) => {
         let document = JSON.parse(response) as { docId: string, fileName: string }
         dispatch(Actions.UploadFilesComplete({ succeeded: true, id: document.docId, fileName: document.fileName }))
         return { id: document.docId, fileName: document.fileName }
    });
}

export const uploadDocuments = (file: File, onProgress: (percentCompleted: number) => void, onLoad?: (e) => void, onError?: (e) => void, onAbort?: (e) => void) => async (dispatch, getState: () => GlobalApplicationState) : Promise<any> => {
    if(!( ((((((((((((((((((((((((((((((((((((((((/^application\/(vnd.openxmlformats-officedocument.wordprocessingml.document|msword|pdf|zip|x-zip-compressed)/))))))))))))))))))))))))))))))))))))))))).test(file.type)){
        Actions.UploadDocumentComplete({ succeeded: false, id: null })
        return Promise.resolve(null);
    }

    dispatch(Actions.UploadDocument({}));


    return MsalAuthModule.getInstance().getAccessToken().then(accessToken=>{
        const requestDetails = API.mediaLibrary.UploadDocument(file);
        const url = getState().config.SparrowClientApiUrl + requestDetails.url
                    .replace("{tenant}", getState().tenant.id);

                    const handlers = { onLoad, onAbort, onError, onProgress };
        
            return new XhrUpload(url, requestDetails, handlers)
                .authenticateWith(accessToken)
                .upload();
    })
    .then((response: string) => {
        let document = JSON.parse(response) as { docId: string, fileName: string }
        dispatch(Actions.UploadDocumentComplete({ succeeded: true, id: document.docId, fileName: document.fileName }))
        return { id: document.docId, fileName: document.fileName }
    });
}

export const setUploadingDoc = (uploading: boolean) => (dispatch, getState) => {
    dispatch(Actions.SetUploadingDoc({uploading}))
}

export const uploadDocument = (file: File, onProgress: (percentCompleted: number) => void, onLoad?: (e) => void, onError?: (e) => void, onAbort?: (e) => void) => async (dispatch, getState) => {

    dispatch(Actions.UploadDocument({}));

    return MsalAuthModule.getInstance().getAccessToken().then(accessToken => {
        const requestDetails = API.mediaLibrary.UploadDocument(file);
        const url = getState().config.SparrowClientApiUrl + requestDetails.url
                    .replace("{tenant}", getState().tenant.id);

                    const handlers = { onLoad, onAbort, onError, onProgress };

            return new XhrUpload(url, requestDetails, handlers)
                .authenticateWith(accessToken)
                .upload();
    })
    .then((response: string) => {
         let document = JSON.parse(response) as { docId: string, fileName: string }
         dispatch(Actions.UploadDocumentComplete({ succeeded: true, id: document.docId, fileName: document.fileName }))
         return { id: document.docId, fileName: document.fileName }
    });
}

export const updateAssociatedLinks = (docId: string, fileName: string, url: string) => (dispatch, getState) => {
    let associatedLink = {
        blobId: docId,
        fileName,
        url
    } as AssociatedLink; 

    return dispatch(Actions.SupportAssociatedLinksUpdate({ associatedLink }));
}

export const removeAssociatedLink = (id: string) => (dispatch, getState) => {
    return dispatch(Actions.RemoveAssociatedLink({ id }));
}

export const setActiveTab = (activeTab: SupportTab)  => (dispatch, getState) => {
    dispatch(Actions.SetActiveSupportTab({activeTab}));
}

export const clearValidationErrors = () => (dispatch, getState) =>  {
    dispatch(Actions.ClearValidationErrors({}));
}