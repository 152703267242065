import * as React from 'react';
import DateTime from 'react-datetime';
import {connect, ConnectedProps} from "react-redux";
import './dateTimePicker.sass';
import { GlobalApplicationState } from 'globalApplicationState';
interface ComponentProps {
    forFiltering?: boolean;
    value: Date | null;
    onChange: (moment) => void;
    onError?: (error: string) => void;
    disabled?: boolean;
    inputError?: boolean;
    valid?: (current: any) => boolean;
}

class DateTimePicker extends React.Component<PropsWithRedux, {}> {

    public render() {

        return (
            <div className={ this.props.forFiltering? "filtering" : "date-time-picker" }>
                <DateTime
                    timeFormat={this.props.forFiltering? false : true}
                    value={this.props.value === null ? " ": this.props.value}
                    locale={this.props.locale}
                    onChange={this.onChange}
                    inputProps={{disabled: this.props.disabled || false, readOnly: true, className: this.props.inputError? "form-control date error" : "form-control date"}}
                    isValidDate={ this.props.valid }
                    />
            </div>
        );    
    }

    private onChange = (moment) => {
        if (typeof moment === "string")
            this.props.onError && this.props.onError(moment);
        else
            this.props.onChange(moment);
    }
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        locale: state.localization.locale
    })
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(DateTimePicker)
