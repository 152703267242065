import { useEffect, useState } from "react";

import { ICustomCssModelv1 } from "api/files";
import { useDispatch, useSelector } from "react-redux";
import { fetchCustomCss } from "modules/portalPages/actionCreator";
import { GlobalApplicationState } from "globalApplicationState";
import useIsMounted from "../useIsMounted";
import { useHistory } from "react-router";

type UseCustomPortalPagesCssContentReturnType = {
    customCss?: ICustomCssModelv1;
    fetching: boolean;
}

/**
 * Fetch custom css file from blob storage
 * @param pageId - DraftId of the page for which to fetch the custom css
 * @returns UseCustomPortalPagesCssContentReturnType
 */
export const useCustomPortalPageCssContent = (pageId: string): UseCustomPortalPagesCssContentReturnType => {
    const [isLoadingCss, setIsLoadingCss] = useState<boolean>(false);

    const customCssState = useSelector((state: GlobalApplicationState) => state.portalPages.customCss[pageId]);
    const fetchingCss = useSelector((state: GlobalApplicationState) => state.portalPages.fetchingCss[pageId]);

    const dispatch = useDispatch();
    const isMounted = useIsMounted();
    const { location: { pathname } } = useHistory();

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoadingCss(true);
                if (pageId && !customCssState && !fetchingCss && !customCssState) {
                    await dispatch(fetchCustomCss(pageId, pathname));
                }
            } catch (error) {} 
            finally {
                if (isMounted()) {
                    setIsLoadingCss(false);
                }
            }
        };

        fetchData();
    }, [pageId, fetchingCss, customCssState, dispatch, isMounted]);

    return {
        customCss: customCssState,
        fetching: isLoadingCss
    };
}
