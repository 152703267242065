export enum UserRoles {
    SparrowSuperAdmin = "role_sparrowsuperadmin",
    Owner = "role_owner",
    Author = "role_author",
    Internal = "role_internal",
    Contributor = "role_contributor"
}

export const USER_ROLE_DESCRIPTIONS = {
    OWNER: "Owners have access to all features and tools in your portal.",
    AUTHOR: "Authors can publish and manage content. You can also customize the access of authors to optimize the tools they can use within the portal.",
    INTERNAL: "Users can access the portal and view content targeted to them or content they are subscribed to. You can also allow users to submit and contribute content."
}

