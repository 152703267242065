import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Button, IconButton, useMediaQuery, useTheme } from "@mui/material";
import ReplayIcon from "@mui/icons-material/Replay";
import WhereToVoteIcon from '@mui/icons-material/WhereToVote';
import PublicIcon from '@mui/icons-material/Public';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import HoverText from "modules/documents/components/action-buttons/hoverText";
import Disclaimer from "modules/common/components/disclaimer";
import { ContentAnalysis } from "modules/common/components/authoring/models";
import Loading from "modules/common/components/loading";
import EmptyDataMessage from "./emptyDataMessage";

import "../styles/contentAnalyses.sass";
import ReadabilityAndReachIndicators from "./readabilityAndReachIndicators";
import TextAndWords from "./textAndWords";
import ContentIssues from "./contentIssues";
import { PostAverages } from "modules/posts/models";
import useAccordion from "modules/common/hooks/useAccordion";

interface IContentAnalysesProps {
    fetching: boolean;
    content?: string;
    contentAnalyses?: ContentAnalysis;
    tenantAverages: PostAverages;
    globalAverages: PostAverages;
    highlightsOn: boolean;
    toggleHighlights?: () => void;
    analyze: () => void;
    isUsingTenantAverages: boolean;
    setIsUsingTenantAverages: (usingTenantData: boolean) => void;
}

const SCORE_ICON_SRC: string = "/images/icons/change_history.svg";
const SYNC_ARROWS_SRC: string = "/images/icons/sync_arrows.svg";

// indices for open state array
enum ACCORDIONS {
    REACH_AND_READABILITY,
    TEXT_AND_WORDS,
    ISSUES
}

// NOTE: This was made when we used the language "Content Analyses" in the authoring settings panel tab which is now "Content Analysis"
const ContentAnalyses: React.FunctionComponent<IContentAnalysesProps> = ({
    content,
    contentAnalyses,
    tenantAverages,
    globalAverages,
    fetching,
    highlightsOn,
    toggleHighlights,
    analyze,
    isUsingTenantAverages,
    setIsUsingTenantAverages
}) => {
    const theme = useTheme();
    const isSmallAndSmaller = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });

    const accordionHook = useAccordion(3);
    const { accordionOpenStates, onChangeAccordionOpen, expandAllLabel, onExpandOrCollapseAll } = accordionHook;

    const {
        toneIndicator,
        sentimentIndicator,
        personalismIndicator,
        readabilityIndicator,
        wordsPerSentence,
        sentencesPerParagraph,
        lettersPerWord,
        wordsPerParagraph,
        syllablesPerWord,
        sentenceCount,
        wordCount,
        readingTime,
        highlightAnalysis,
    } = contentAnalyses || {};

    const disableHighlightsButton = (): boolean => {
        let disable = !content;

        if (!disable) {
            disable = !contentAnalyses?.highlightAnalysis?.processedHighlightText.includes("data-issues");
        }

        return disable;
    }

    const getAccordion = (key: ACCORDIONS, label: string, content: JSX.Element | undefined = <></>) => {
        return (
            <Accordion
                expanded={accordionOpenStates[key]}
                elevation={0}
                onChange={(event: React.ChangeEvent, expanded: boolean) => onChangeAccordionOpen(expanded, key)}
                classes={{
                    expanded: "expanded"
                }}
                className="content-analyses-accordion"
                style={{
                    width: isSmallAndSmaller ? "95%" : 428
                }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    style={{ fontWeight: 500 }}
                >
                    {label}
                </AccordionSummary>
                <AccordionDetails style={{ flexWrap: "wrap" }}>
                    {content}
                </AccordionDetails>
            </Accordion>
        );
    }

    const highlightsDisabled = disableHighlightsButton();
    return (
        <div className="content-analyses-container">
            <div className="content-analyses-header">
                <div className="content-analyses-header-item" style={{paddingLeft: "12px"}}>
                    {isUsingTenantAverages ?
                        <>
                            <WhereToVoteIcon htmlColor="#adb7c1" style={{ width: 13, color: "#adb7c1", marginRight: 5}} />
                            <HoverText labelClassName="legend-text" inline label="Internal avg.">
                                Average scores taken from your organization's most engaging posts.
                            </HoverText>
                        </>
                        :
                        <>
                            <PublicIcon htmlColor="#599FA0" style={{ width: 13, color: "#599FA0", marginRight: 5}} />
                            <HoverText labelClassName="legend-text-global" inline label="Global avg.">
                                Global average scores are taken from most engaging posts in Sparrow.
                            </HoverText>
                        </>
                    }
                    <img alt="Your score" src={SCORE_ICON_SRC} width={16} style={{ marginRight: 5, marginLeft: 15, }} />
                    <span className="legend-text" style={{ color: "#3b78ab" }}>Current scores</span>
                </div>

                <div style={{ marginLeft: "auto", marginRight: 4 }} >
                    <HoverText
                        label={
                            <IconButton
                                onClick={toggleHighlights}
                                className="content-analyses-header-item"
                                color="primary"
                                disabled={highlightsDisabled}
                            >
                                <img style={{width: "1em"}} alt={`Toggle highlights ${highlightsOn ? "off" : "on"}`} src={!highlightsOn ? "/images/icons/turn_on_highlights.svg" : "/images/icons/turn_off_highlights.svg"}></img>
                            </IconButton>
                        }>
                            Show or hide highlights
                    </HoverText>
                </div>

                <HoverText
                    label={
                        <IconButton
                            onClick={analyze}
                            style={{ marginRight: 21 }}
                            className="content-analyses-header-item"
                            color="primary"
                            disabled={!content}
                        >
                            <ReplayIcon/>
                        </IconButton>
                    }
                >
                    Analyze content again
                </HoverText>
            </div>
            {!content
                ? <EmptyDataMessage containerStyle={{ alignSelf: "center" }}>
                    <div style={{ width: 265 }}>
                        Start writing the body of your post to try our content analysis tool.
                    </div>
                </EmptyDataMessage>
                : (fetching
                    ? <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center" }}>
                        <Loading />
                    </div>
                    : <div className="content-analyses-content">
                        <Disclaimer
                            containerStyle={{ marginTop: 21, marginBottom: 15, width: isSmallAndSmaller ? "95%" : 428, boxSizing: "border-box" }}
                            icon={<InfoOutlinedIcon />}
                            text={"Content analysis applies to English text only."}
                        />
                        {getAccordion(
                            ACCORDIONS.REACH_AND_READABILITY,
                            "Reach & Readability",
                            <ReadabilityAndReachIndicators
                                isUsingTenantAverages={isUsingTenantAverages}
                                toneIndicator={toneIndicator}
                                readabilityIndicator={readabilityIndicator}
                                personalismIndicator={personalismIndicator}
                                sentimentIndicator={sentimentIndicator}
                            />)}
                        {getAccordion(
                            ACCORDIONS.TEXT_AND_WORDS,
                            "Text & Words",
                            <TextAndWords
                                wordCount={wordCount}
                                estimatedReadingTime={readingTime}
                                usingGlobalStats={!isUsingTenantAverages}
                                averageWordCount={isUsingTenantAverages ? tenantAverages.averageWordCount : globalAverages.averageWordCount}
                            />)}
                        {getAccordion(
                            ACCORDIONS.ISSUES,
                            "Issues",
                            <ContentIssues
                                totalSentenceCount={sentenceCount}
                                totalWordCount={wordCount}
                                issues={highlightAnalysis}
                                wordsPerParagraph={wordsPerParagraph}
                                lettersPerWord={lettersPerWord}
                                sentencesPerParagraph={sentencesPerParagraph}
                                syllablesPerWord={syllablesPerWord}
                                wordsPerSentence={wordsPerSentence}
                            />)}
                    </div>)
            }
            <div className="content-analyses-footer">
                <Button variant="text" color="primary" onClick={() => setIsUsingTenantAverages(!isUsingTenantAverages)} startIcon={
                    <img src={SYNC_ARROWS_SRC} style={{width: "26px"}} alt={"Swap average locale icon"}></img>
                }>
                    {!isUsingTenantAverages ? "USE INTERNAL SCORES" : "USE GLOBAL SCORES"}
                </Button>
                <Button variant="text" color="primary" disabled={!content} onClick={onExpandOrCollapseAll}>
                    {expandAllLabel}
                </Button>
            </div>
        </div>
    );
}

export default ContentAnalyses;
