
  export function setVerify(event: string) {
    if(event.length >= 9) {
        return false;
    } else {
        return true;
    }
  }

  export function removeLeftoverCountryCode (event: string) {
    //In case the user puts nothing for input we don't want to just add a country code.
    if (event === "+1" || event === "1"  || event === "+") { 
        return event = "";
    }
    else {
        return event;
    }
  }
