import * as React from "react";

import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";

import ArrowRightIcon from "@mui/icons-material/ArrowRight";


class SignUp extends React.Component<ComponentProps, ComponentState> {
  public render() {
    if (!this.props.respondingEnabled)
      return (null);
    
    return (
      <div className="sign-up-button">
        <ButtonGroup variant="contained" color="primary" disabled={!this.props.isResponseEditable}>
          <Button color="primary" endIcon={<ArrowRightIcon />} onClick={this.props.onClick}>{this.currentSignUpText()}</Button>
        </ButtonGroup>
      </div>
    );
  }

  private currentSignUpText = (): string => {
    if (this.props.isCurrentUserAttendingInPerson ||
        this.props.isCurrentUserAttendingOnline ||
        this.props.isCurrentUserOnWaitlist ||
        this.props.isCurrentUserNotAttending)
      return "Edit RSVP";
    
    return "RSVP now";
  }
}

interface ComponentProps {
  respondingEnabled: boolean;
  isCurrentUserAttendingInPerson: boolean;
  isCurrentUserAttendingOnline: boolean;
  isCurrentUserNotAttending: boolean;
  isCurrentUserOnWaitlist: boolean;
  isResponseEditable: boolean;
  onClick: () => void;
}

interface ComponentState {}

export default SignUp;