import * as network from "utils/network";
import { GlobalApplicationState } from "globalApplicationState";
import * as Actions from "./actions";
import { User, UserEdit, UserList, ExternalUser, UsersFilterValues, UsersTab, ValidationResponse, defaultFilters, UserCreation, InvitedUser, PermissionDetails } from "./models";
import { errorAlert } from "utils/notyPopups";
import { usersApi } from "api/instances";

export const setActiveTab = (activeTab: UsersTab) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.CreateSetActiveUsersTabAction({activeTab}));
}

interface GetUserResponse {
    continuationToken: string,
    users: User[]
}

export const updateGlobalUsers = (userList: UserList) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.UpdateGlobalUsersAction({userList}));
}

export const updateGlobalExternalUsers = (users: ExternalUser[]) => (dispatch, getState: () => GlobalApplicationState): Promise<any> => {
   return dispatch(Actions.UpdateGlobalExternalUsersAction({users}));
}

export const getUsersV1 = () => (dispatch, getState: () => GlobalApplicationState) => {
    const state = getState();

    const uri = '/{tenant}/adminapi/v1/users/filtered';

    const filter = {
        ...defaultFilters,
        continuationToken: state.users.continuationToken,
        currentSort: "",
        sortAscending: true
    };

    return network.CreateApiRequestInit(uri, {
        type: network.RequestMethod.POST,
        params: {},
        body: JSON.stringify(filter)
    }, getState).then(request => {

            dispatch(Actions.CreateFetchUsersInitAction({}));

            return fetch(request.uri, request.init)
                .then(response => {
                    if (response.status === 200) {
                        return response.json().then((getUserResponse: GetUserResponse) => {
                            if (getUserResponse) {
                                dispatch(Actions.CreateFetchUsersV1CompleteAction({continuationToken: getUserResponse.continuationToken, users: getUserResponse.users, succeeded: true}));
                            }
                        });
                    }  else {
                        dispatch(Actions.CreateFetchUsersV1CompleteAction({continuationToken: null, users: new Array<User>(), succeeded: false}));
                    }
                });
        });
}

export const getUsers = (pageNumber: number, filters: Partial<UsersFilterValues>) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.CreateFetchUsersInitAction({}));
    return dispatch(usersApi.getUsers(pageNumber, filters))
        .then(response => response.json())
        .then(userList => {
            dispatch(Actions.CreateFetchUsersCompleteAction({ userList, succeeded: true }));
            return userList;
        });
};

export const toggleUserRole = (roleName: string) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.CreateToggleUserRoleAction({roleName}));
}

export const toggleUserEnabled = (enabled: boolean) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.CreateToggleUserEnabledAction({enabled}));
}

export const toggleSubmissionManagement = (canManageSubmissions: boolean) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.CreateToggleSubmissionManagementAction({canManageSubmissions}));
}

export const toggleFlaggingNotifications = (enabled: boolean) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.CreateToggleFlaggingNotificationAction({enabled}));
}

export const saveUser = () => (dispatch, getState: () => GlobalApplicationState) => {

    const state = getState();
    const uri = getState().config.Users;
    const editingUser = state.users.editingUser;

    return network.CreateApiRequestInit(uri, {
        type: network.RequestMethod.POST,
        params: {},
        body: JSON.stringify(editingUser)
    }, getState).then(request => {

        dispatch(Actions.CreateSaveUserInitAction({}));

        return fetch(request.uri, request.init)
            .then(response => {
                if (response.status === 200) {
                    return response.json().then((user: User) => {
                        if (user) {
                            dispatch(Actions.CreateSaveUserCompleteAction({user, succeeded: true}));
                        }
                    });
                } else {
                    dispatch(Actions.CreateSaveUserCompleteAction({user: null, succeeded: false}));
                }
            });
    });
}

export const setInvitingUserEmail = (email: string) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.CreateSetInvitingUserEmailAction({email}));
}

export const inviteUser = (email: string) => (dispatch, getState: () => GlobalApplicationState) => {
    
    const uri = getState().config.ExternalUsers;

    return network.CreateApiRequestInit(uri, {
        type: network.RequestMethod.POST,
        params: {},
        body: JSON.stringify({
            email: email,
            enabled: true
        } as ExternalUser)
    }, getState).then(request => {

            dispatch(Actions.CreateInviteUserInitAction({email}));

            return fetch(request.uri, request.init)
                .then((response: any) => {
                    if (response.status === 200) {
                        response.json().then((user: ExternalUser) => {
                            if (user) {
                                dispatch(Actions.CreateInviteUserCompleteAction({user, succeeded: true, validationResponse: null}));
                            }
                        });
                        return response.status;
                    } else if (response.status === 400) {
                        errorAlert("There was an error inviting your user, this may be because the user is already has an account", 3000)
                        const parsedResponse = response.json() as Promise<ValidationResponse>;
                        parsedResponse.then((validationResponse) => {
                            dispatch(Actions.CreateInviteUserCompleteAction({
                                    user: null,
                                    succeeded: false,
                                    validationResponse: validationResponse
                                }));
                        });
                        return response.status;
                    } else {
                        dispatch(Actions.CreateInviteUserCompleteAction({user: null, succeeded: false, validationResponse: null}));
                        return response.status;
                    }
                });
        });
}

export const inviteBatchUsers = (emails: string[]) => (dispatch, getState: () => GlobalApplicationState) => {
    var usersToInvite:Partial<ExternalUser>[] = []

    emails.forEach(email => {
        let userToAdd:Partial<ExternalUser> = {email: email, enabled: true};
        userToAdd.email = email.toLowerCase();
        userToAdd.enabled = true;
        usersToInvite.push(userToAdd)
    })

    const uri = getState().config.ExternalUsers + "/emailGroup"

    return network.CreateApiRequestInit(uri, {
        type: network.RequestMethod.POST,
        params: {},
        body: JSON.stringify(usersToInvite)
    }, getState).then(request => {
        dispatch(Actions.InviteBatchUsersInitAction({}));

        return fetch(request.uri, request.init)
            .then(response => {
                if(response.status === 200) {
                    return response.json().then((result: number) => 
                        dispatch(Actions.InviteBatchUsersCompleteAction({result: result, succeeded: true}))
                    )
                } else {
                    dispatch(Actions.InviteBatchUsersCompleteAction({result: null, succeeded: false}))
                }
            })
    })
}

export const getBulkInviteReportCSV = (valid: string[], invalid: string[], duplicate: string[]) => (dispatch, getState: () => GlobalApplicationState) => {
    const uri = getState().config.ExternalUsers + "/downloadCSVReport"

    return network.CreateApiRequestInit(uri, {
        type: network.RequestMethod.POST,
        params: {},
        body: JSON.stringify({valid, invalid, duplicate})
    }, getState).then(request => {
        dispatch(Actions.GetBulkInviteReportCSV({}));

        return fetch(request.uri, request.init)
            .then(response => {
                if(response.status === 200) {
                    return response.json();
                } else {
                    return "";
                }
            })
    })
}

export const getBulkImportResults = (emails: string[]) => (dispatch, getState: () => GlobalApplicationState) => {
    const uri = getState().config.ExternalUsers + "/downloadImportCSV"
    let withQuotes = emails.map((e) => {return "\""+e+"\""});
    let toSend = "["+withQuotes.toString()+"]";

    return network.CreateApiRequestInit(uri, {
        type: network.RequestMethod.POST,
        params: {},
        body: toSend
    }, getState).then(request => {
        dispatch(Actions.GetBulkImportCSV({}));

        return fetch(request.uri, request.init)
            .then(response => {
                if(response.status === 200) {
                    return response.json();
                } else {
                    return "";
                }
            })
    })
}

export const resendInviteUser = (externalUser: ExternalUser) => (dispatch, getState: () => GlobalApplicationState) => {
    
    const uri = getState().config.ExternalUsers + "/resendInvite";

    return network.CreateApiRequestInit(uri, {
        type: network.RequestMethod.POST,
        params: {},
        body: JSON.stringify({...externalUser
        } as ExternalUser)
    }, getState).then(request => {

            dispatch(Actions.CreateResendInviteUserInitAction({user: externalUser}));

            return fetch(request.uri, request.init)
                .then(response => {
                    if (response.status === 200) {
                        return response.json().then((user: ExternalUser) => {
                            if (user) {
                                dispatch(Actions.CreateResendInviteUserCompleteAction({user, succeeded: true}));
                            }
                        });;
                    } else {
                        dispatch(Actions.CreateResendInviteUserCompleteAction({user: null, succeeded: false}));
                    }
                });
        });
}

export const setExternalUserEnabled = (user: ExternalUser, enabled: boolean) => (dispatch, getState: () => GlobalApplicationState) => {
    
    const uri = getState().config.ExternalUsers;

    return network.CreateApiRequestInit(uri, {
        type: network.RequestMethod.POST,
        params: {},
        body: JSON.stringify({
            email: user.email,
            enabled: enabled,
            inviteToken: user.inviteToken,
            inviteTokenUsed: user.inviteTokenUsed,
            invitedAt: user.invitedAt,
            user: user.user,
            provider: user.provider
        } as ExternalUser)
    }, getState).then(request => {

            dispatch(Actions.CreateSetExternalUserEnabledInitAction({email: user.email, enabled}));

            return fetch(request.uri, request.init)
                .then(response => {
                    if (response.status === 200) {
                        return response.json().then((user: ExternalUser) => {
                            if (user) {
                                dispatch(Actions.CreateSetExternalUserEnabledCompleteAction({user, succeeded: true}));
                                dispatch(getUsers(1, {}));
                            }
                        });
                    } else {
                        dispatch(Actions.CreateSetExternalUserEnabledCompleteAction({user: null, succeeded: false}));
                    }
                });
        });
}

export const uploadBatchInviteFile = (file: File) => (dispatch, getState: () => GlobalApplicationState) => {


    const uri = getState().config.ExternalUsers + "/batch";

    const data = new FormData()
    data.append("file", file);

    return network.CreateApiRequestInit(uri, {
        type: network.RequestMethod.POST,
        params: {},
        body: data
    }, getState).then(request => {

            dispatch(Actions.CreateUploadBatchInviteFileInitAction({}));

            return fetch(request.uri, request.init)
                .then(response => {
                    if (response.status === 200) {
                        dispatch(Actions.CreateUploadBatchInviteFileCompleteAction({ succeeded: true, validationResponse: null }));
                    } else if (response.status === 400) {
                        const parsedResponse = response.json() as Promise<ValidationResponse>;
                        parsedResponse.then((validationResponse) => {
                            dispatch(Actions.CreateUploadBatchInviteFileCompleteAction({succeeded: false, validationResponse }));
                        });
                    } else {
                        dispatch(Actions.CreateUploadBatchInviteFileCompleteAction({ succeeded: false, validationResponse: null }));
                    }
                });
        });
}

export const resendInvitesForPendingUsers = () => (dispatch, getState: () => GlobalApplicationState) => {

    const uri = getState().config.ExternalUsers + "/resendPendingInvite";

    return network.CreateApiRequestInit(uri, {
        type: network.RequestMethod.POST,
        params: {}
    }, getState).then(request => {

            dispatch(Actions.CreateResendPendingInviteInitAction({}));

            return fetch(request.uri, request.init)
                .then(response => {
                    if (response.status === 200) {
                        dispatch(Actions.CreateResendPendingInviteCompleteAction({succeeded: true})); // Consider Mimicking FetchUsers so that we can update the UI without requiring a Refresh
                    } else {
                        dispatch(Actions.CreateResendPendingInviteCompleteAction({succeeded: false}));
                    }
                })
        });
}


export const deletePendingUsersList = (pendingUserEmails: string[]) => (dispatch, getState): Promise<any> => { 

    return network.CreateApiRequestInit(getState().config.ExternalUsers  + "/deletePending", {
            type: network.RequestMethod.DELETE,
            headers: {"content-type": 'application/json'},
            params: {},
            body: JSON.stringify(pendingUserEmails)
        }, getState).then(request => {

            dispatch(Actions.DeletePendingUserListInit({}));

            return fetch(request.uri, request.init)
                .then((response: any) => {
                    if (response.status === 200) {
                        dispatch(Actions.DeletePendingUserListComplete({pendingUserEmails: pendingUserEmails, succeeded: true}));
                    } else {
                        dispatch(Actions.DeletePendingUserListComplete({pendingUserEmails: [], succeeded: false}));
                        return response as Promise<any>;
                    }
                });
        });
}


export const deleteExternalUser = (externalUser: ExternalUser) => (dispatch, getState) => {
    
    return network.CreateApiRequestInit(getState().config.ExternalUsers, {
            type: network.RequestMethod.DELETE,
            headers: {"content-type": 'application/json'},
            params: {},
            body: JSON.stringify(externalUser)
        }, getState).then(request => {

        dispatch(Actions.DeleteExternalUserInitAction({user: externalUser}));

        return fetch(request.uri, request.init)
            .then(response => {
                if (response.status === 200) {
                    dispatch(Actions.DeleteExternalUserCompleteAction({user: externalUser, succeeded: true}));
                } else {
                    dispatch(Actions.DeleteExternalUserCompleteAction({user: null, succeeded: false}));
                }
            });
    });
}

export const fixLegacyUser = (userId: string) => (dispatch, getState): Promise<any> => {

    return network.CreateApiRequestInit(getState().config["Users"] + "/legacy", {
            type: network.RequestMethod.POST,
            queryParams: { userId },
        }, getState).then(request => {

        dispatch(Actions.CreateLegacyFixInitAction({}));

        return fetch(request.uri, request.init)
            .then(response => {
                if (response.status === 200) {
                    dispatch(Actions.CreateLegacyFixCompleteAction({userId, succeeded: true}));
                } else {
                    dispatch(Actions.CreateLegacyFixCompleteAction({userId, succeeded: false}));
                }
            })
    });
 }

/* User creation actions */

export const handleUserCreationFirstName = (firstName: string) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.CreateHandleUserCreationFirstName({firstName}));
}

export const handleUserCreationLastName = (lastName: string) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.CreateHandleUserCreationLastName({lastName}));
}

export const handleUserCreationUserId = (userId: string) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.CreateHandleUserCreationUserId({userId}));
}

export const handleUserCreationPassword = (password: string) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.CreateHandleUserCreationPassword({password}));
}

export const handleUserCreationEmail = (email: string) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.CreateHandleUserCreationEmail({email}));
}

/* Company details */
export const handleUserCreationEmployeeId = (employeeId: string) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.CreateHandleUserCreationEmployeeId({employeeId}));
}

export const handleUserCreationTitle = (title: string) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.CreateHandleUserCreationTitle({title}));
}

export const handleUserCreationDepartment = (department: string) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.CreateHandleUserCreationDepartment({department}));
}

export const handleUserCreationRegion = (region: string) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.CreateHandleUserCreationRegion({region}));
}

export const handleUserCreationCountry = (country: string) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.CreateHandleUserCreationCountry({country}));
}

export const handleUserCreationPreferredEmail = (preferredEmail: string) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.CreateHandleUserCreationPreferredEmail({preferredEmail}));
}

export const handleUserCreationPreferredSMSPhone = (preferredSMSPhone: string) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.CreateHandleUserCreationPreferredSMSPhone({preferredSMSPhone}));
}

export const handleUserCreationAudiences = (audiences: string[]) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.CreateHandleUserCreationAudiences({audiences}));
}

export const handleUserCreationRoles = (roleName: string) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.CreateHandleUserCreationRoles({roleName}))
}

export const handleUserCreationSubmissionManager = (canManageSubmissions: boolean) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.CreateHandleUserCreationSubmissionManager({canManageSubmissions}))
}

export const HandleUserCreationSubscribedToFlaggingNotifications = (subscribedToFlaggingNotifications: boolean) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.CreateHandleUserCreationSubscribedToFlaggingNotifications({subscribedToFlaggingNotifications}))
}

export const createSingleSparrowUser = () => (dispatch, getState: () => GlobalApplicationState) => {

    const uri = getState().config.LocalUsers;

    return network.CreateApiRequestInit(uri, {
        type: network.RequestMethod.POST,
        body: JSON.stringify(getState().users.creatingUser!)
    }, getState).then(request => {

        return fetch(request.uri, request.init) 
            .then((response: any) => {
                if (response.status === 200) {
                    return response.status;
                } else {
                    errorAlert("There was an error creating your user.", 3000)
                    return response.status;
                }
            });
 
    })
}

//No longer used
export const createOneSparrowUser = (userToCreate: UserCreation) => (dispatch, getState: () => GlobalApplicationState) => {
    const uri = getState().config.LocalUsers;

    return network.CreateApiRequestInit(uri, {
        type: network.RequestMethod.POST,
        body: JSON.stringify(userToCreate)
    }, getState).then(request => {

        return fetch(request.uri, request.init) 
            .then((response: any) => {
                if (response.status === 200) {
                    return response.status;
                } else {
                    errorAlert("There was an error creating your user.", 3000)
                    return response.status;
                }
            });
 
    })
}

export const inviteSparrowUsers = (usersToInvite: InvitedUser[]) => async (dispatch, getState: () => GlobalApplicationState) => {
    const uri = getState().config.InviteSparrowUsers;

    try {
        let request = await network.CreateApiRequestInit(uri, {
            type: network.RequestMethod.POST,
            body: JSON.stringify(usersToInvite)
        }, getState);

        let response = await fetch(request.uri, request.init);

        if (response.status === 200) {
            return response.status;
        } else {
            errorAlert("There was an error inviting your user.", 3000)
            return response.status;
        }
    }
    catch(error) {
        errorAlert("There was an error inviting your user.", 3000)
        return 500;
    }
}

export const updateEditPassword = (password: string) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.CreateEditPasswordAction({password}));
}

export const updateMobileConsent = (consent: boolean | null) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.CreateUpdateMobileConsentAction({consent}))
}

export const updateUser = (id: string, user: Partial<UserEdit>) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.CreateHandleUpdateUpdatingUserId({ id }));
    
    return usersApi.UpdateUser(id, user)
        .then((response) => {
            return response;
        })
        .catch(() => {
            return;
        });
}

export const sendSMSVerify = (id: string) => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
    dispatch(Actions.CreateHandleSendSMSVerifyUserId({ id }));
    return usersApi.SendSMSVerify(id)
        .then(response=> {
            return response !== null
        })
        .catch(() => {
            return false;
        });
}

export const updatePermissions = (toUpdate: PermissionDetails, id: string) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.CreateUpdateUserPermissionsAction ({ id }));

    return usersApi.UpdateUserPermissions(id, toUpdate)
        .then((response) => {
            return response !== null;
        })
    .catch(() => {
        return false;
    });
}
