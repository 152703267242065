import * as React from "react";

import { ValidationCheck, ValidationChecks } from "./models";

import ClickAwayListener from "@mui/material/ClickAwayListener";



const ProgressBar: React.FunctionComponent<{validationChecks: ValidationCheck[]}> = (props) => {
  const valid: number = props.validationChecks.filter(check => !check.skip && check.isValid).length;
  const total: number = props.validationChecks.filter(check => !check.skip).length;

  const percentComplete = (valid / total) * 100;
  const progressBarText = `${valid} of ${total}`;

  return (
    <div className="progress-info">
      <div className="progress-bar">
        <div style={{ width: percentComplete + "%" }} className="progress-bar-fill"></div>
      </div>
      <div className="progress-bar-text">{progressBarText}</div>
    </div>
  );
};

const ProgressList: React.FunctionComponent<{validationChecks: ValidationCheck[]}> = (props) => {
  return (
    <React.Fragment>
      <div className="progress-list-arrow"></div>
      <div className="progress-list">
        {props.validationChecks.map((check) => {
          if (check.skip)
            return <React.Fragment key={check.key}></React.Fragment>;
          else
            return (
              <div key={check.key} className={`progress-list-item${check.isValid ? " item-valid" : ""}`}>
                <div className="item-valid-indicator">
                  <div className="item-valid-indicator-icon"></div>
                </div>
                <div className="item-description">{check.description}</div>
              </div>
            );
        })}
        {props.children}
      </div>
    </React.Fragment>
  );
};


class Validator extends React.Component<ComponentProps, ComponentState> {
  constructor(props: ComponentProps) {
    super(props);

    this.state = {
      expandProgressList: false
    };
  }

  public render() {
    return (
      <ClickAwayListener onClickAway={this.onCollapseProgressList}>
        <div onClick={this.onExpandProgressList} className={`validator${this.state.expandProgressList ? " expand-progress-list" : ""}`}>
          <div>
            <ProgressBar validationChecks={this.props.validationChecks.checks} />
            <ProgressList validationChecks={this.props.validationChecks.checks}>{this.props.children}</ProgressList>
          </div>
        </div>
      </ClickAwayListener>
    );
  }

  private onCollapseProgressList = () => {
    this.setState({ expandProgressList: false });
  }

  private onExpandProgressList = () => {
    this.setState({ expandProgressList: true });
  }
}


interface ComponentProps {
  validationChecks: ValidationChecks;
}

interface ComponentState {
  expandProgressList: boolean;
}

export default Validator;
export { ProgressBar, ProgressList };
