import * as React from "react";
import "../styles/textInput.sass";
import InlineError from "modules/common/components/forms/messages/InlineError";

interface ComponentProps {
    label?: string;
    placeholder?: string;
    value?: string | null;
    tooltip?: string;
    multiline?: boolean;
    isTitle?: boolean;
    onChange?: (value: string) => void;
    onBlur?: () => void;
    onFocus?: () => void;
    onKeyDown?: (e) => void;
    isRequired?: boolean;
    isRequiredErrorMessage?: string;
    showError?: boolean;
    errorMessage?: string;
    registerFocus?: (ref) => void;
    disabled?: boolean;
    maxLength?: number;
    className?: string;
    checkError?: (text: string, maxLength: number) => boolean
    isLanguageField?: boolean;
}

interface ComponentState {
    isFocused: boolean;
    touched: boolean;
    hasError: boolean;
}

export default class TextInput extends React.PureComponent<ComponentProps, ComponentState> {
    private textInput: HTMLInputElement;
    private multiLineTextInput: HTMLTextAreaElement;

    constructor(props) {
        super(props);
        this.state = { 
            isFocused: false, 
            touched: false, 
            hasError: false,
        }
    }

    public render() {
        if(this.props.value && !!this.props.checkError && this.props.isLanguageField) {
            this.setState({hasError: this.props.checkError(this.props.value ?? "", this.props.maxLength ?? 0)})
        }

        return (
            <div className={`textInput${this.styleModifier()} ` + (this.props.className ? this.props.className : "")}>
                {this.props.children}
                <div className={!!this.props.isTitle ? "isTitle" : ""}>
                    <label
                        className={
                            this.showRequiredErrorMessage() || (this.props.showError && !!this.props.errorMessage) || this.state.hasError ? "has-error" : ""
                        }
                    >
                        {this.props.label}
                    </label>
                    <div className="inputWrapper" title={this.props.tooltip ? this.props.tooltip : ""}>
                        {!this.props.multiline ? (
                            <input
                                title={this.props.value || ""}
                                className="input"
                                type="text"
                                ref={(input) => (this.textInput = input!)}
                                autoComplete="new-password"
                                style={{
                                    textOverflow: this.props.isTitle ? "" : "ellipsis",
                                    whiteSpace: this.props.isTitle ? "normal" : "nowrap",
                                }}
                                {...this.inputProps()}
                            />
                        ) : (
                            <textarea
                                className="input-textarea"
                                ref={(input) => (this.multiLineTextInput = input!)}
                                {...this.inputProps()}
                            ></textarea>
                        )}
                        <span className="highlightBar"></span>
                        {this.checkTextMaxLength()}
                    </div>
                    {this.showRequiredErrorMessage() && <InlineError>{this.props.isRequiredErrorMessage}</InlineError>}
                    {this.props.showError && this.props.errorMessage && <InlineError>{this.props.errorMessage}</InlineError>}
                </div>
            </div>
        );
    }

    private checkTextMaxLength = ()  => {
       return (
            this.props.maxLength && (
                <span style={{ display: "inline-block", width: "98%", textAlign: "right", fontSize: "14px" }}>
                    {this.props.isLanguageField 
                    ?
                    (this.props.value == null ? 0 : this.props.value.length) 
                    : 
                    Math.min(this.props.maxLength, this.props.value == null ? 0 : this.props.value.length)}/
                    {this.props.maxLength}
                </span>
            )
        )
    }

    private styleModifier = () => {
        let modifier = "";
        if (this.props.value === "") modifier = "-empty";

        if (this.state.isFocused) modifier = "-focused";
        return modifier;
    };

    private inputProps = () => {
        const value = this.props.value || "";
        let props = {
            placeholder: this.props.placeholder || " ", // IE and Edge textarea height collapses when empty without this
            value: this.props.isLanguageField ? value : (this.props.maxLength ? value.slice(0, this.props.maxLength) : value),
            onKeyDown: this.onKeyDown,
            onChange: this.onChange,
            onBlur: this.onBlur,
            onFocus: this.onFocus,
            disabled: this.props.disabled,
        };
        if (this.showRequiredErrorMessage()) {
            props["style"] = { borderBottom: "solid 1px #de6262" };
        }
        return props;
    };

    public componentDidMount() {
        if (this.props.multiline) this.resizeMultiLineTextAreaToFitContent();

        // register element with remote focus events
        if (this.props.registerFocus) {
            this.props.multiline ? this.props.registerFocus(this.multiLineTextInput) : this.props.registerFocus(this.textInput);
        }
    }

    private onKeyDown = (e) => {
        if (this.props.onKeyDown) {
            this.props.onKeyDown(e);
        }
    };

    private onChange = (e) => {
        let textValue = this.props.multiline ? this.multiLineTextInput.value : this.textInput.value;

        if (!!this.props.checkError && this.props.isLanguageField === true) this.setState({hasError: this.props.checkError(textValue ?? "", this.props.maxLength ?? 0)})

        if (this.props.multiline) this.resizeMultiLineTextAreaToFitContent();

        if (this.props.onChange) this.props.onChange(textValue);
    };

    private onBlur = (e) => {
        if (this.state.isFocused) this.setState({ ...this.state, touched: true, isFocused: false });

        if (this.props.onBlur) this.props.onBlur();
    };

    private onFocus = (e) => {
        if (!this.state.isFocused) this.setState({ ...this.state, isFocused: true });

        if (this.props.onFocus) this.props.onFocus();

        if (this.props.multiline) this.resizeMultiLineTextAreaToFitContent();
    };

    private resizeMultiLineTextAreaToFitContent = () => {
        let input = this.multiLineTextInput;
        if (!input) return;

        input.style.height = "0px"; // reset to 0. (otherwise there is weird behavior)
        let scrollHeight = input.scrollHeight < 35 ? (this.props.isTitle ? 46 : 35) : input.scrollHeight; //scrollHeight is bunk in IE
        let paddingTotal = (parseInt(input.style.paddingTop || "0") || 0) + (parseInt(input.style.paddingBottom || "0") || 0);
        let borderTotal = (parseInt(input.style.borderTopWidth || "0") || 0) + (parseInt(input.style.borderBottomWidth || "0") || 0);

        let totalHeight = Number(scrollHeight - paddingTotal - borderTotal);
        input.style.height = `${totalHeight}px`;
    };

    private showRequiredErrorMessage = () => {
        return (
            ((this.props.showError === undefined && this.state.touched) || this.props.showError === true) &&
            this.props.isRequired &&
            (this.props.value || "").trim().length === 0
        );
    };
}
