import * as React from 'react';
import { GlobalApplicationState } from "globalApplicationState";
import { ConnectedProps, connect } from "react-redux";
import ToggleSwitch from 'modules/common/components/toggleSwitch';
import * as actions from '../actionCreator';
import DeleteIcon from '@mui/icons-material/Delete';
import Select from "react-select";

interface ComponentProps { 
    defaultName: string
    notificationName: string
}

export interface reminderForInsertion {
    delay: number,
    range: RangeType,
    channel: Channel
}

interface ComponentState {
    reminders: reminderData[]
    reminderBackup: reminderData[]
    toEditIndex: number
    remindersEnabled: boolean

    defaultTimeLocation: string
    arrayLocation: string

    teamsDisabled: boolean
    mobileDisabled: boolean
    smsDisabled: boolean
    emailDisabled: boolean
}

export enum RangeType {
    Default = "default",
    Everyone = "everyone",
    Fallback = "fallback",
    None = "none",
    Subscribers = "subscribers"
}

export enum Channel {
    Email = "email",
    Teams = "teams",
    SMS = "sms",
    Mobile = "mobile",
    Inherit = "inherit"
}

export interface reminderData {
    delay: number;
    displayDelay: number;
    daysSelected: boolean;
    
    inherit: boolean
    teams: boolean
    mobile: boolean
    sms: boolean
    email: boolean

    emailRange: RangeType;
    smsRange: RangeType;
}

class ReminderPopout extends React.Component<PropsWithRedux, ComponentState> {
    constructor(props) {
        super(props);
        this.state = {
            reminders: [],
            reminderBackup: [],
            defaultTimeLocation: this.props.defaultName+"Reminder",
            arrayLocation: this.props.defaultName+"Settings",
            remindersEnabled: this.props.notificationSettings[this.props.defaultName+"Settings"].reminders.length > 0,
            toEditIndex: 0,
            teamsDisabled: !this.props.notificationSettings.settings[`${this.props.notificationName}TeamsAttempt`].toggle ?? false,
            mobileDisabled: !this.props.notificationSettings.settings[`${this.props.notificationName}MobileAttempt`].toggle ?? false,
            emailDisabled: !this.props.notificationSettings.settings[`${this.props.notificationName}EmailAttempt`].toggle ?? false,
            smsDisabled: !this.props.notificationSettings.settings[`${this.props.notificationName}SMSAttempt`].toggle ?? false
        };
    }

    componentDidMount()
    {
        const uniqueDelays = Array.from(new Set(this.props.notificationSettings[this.state.arrayLocation].reminders.map(e => e.delay)))
        var toLoad = new Array<reminderData>(uniqueDelays.length);

        for(let i=0; i<uniqueDelays.length; i++) {
            let daySelected = uniqueDelays[i] as number % 24 === 0;
            toLoad[i] = this.getDefaultReminder();
            toLoad[i].delay = uniqueDelays[i] as number;
            toLoad[i].displayDelay = daySelected ? toLoad[i].delay / 24 : toLoad[i].delay;
            toLoad[i].daysSelected = daySelected;
            toLoad[i].inherit = false;
        }

        this.props.notificationSettings[this.state.arrayLocation].reminders.forEach(reminder => {
            let currentReminderToEdit = uniqueDelays.indexOf(reminder.delay);

            switch(reminder.channel)
            {
                case Channel.Inherit:
                    toLoad[currentReminderToEdit].inherit = true;
                    break;
                    
                case Channel.Email:
                    toLoad[currentReminderToEdit].email = true;
                    toLoad[currentReminderToEdit].emailRange = reminder.range;
                    break;

                case Channel.SMS:
                    toLoad[currentReminderToEdit].sms = true;
                    toLoad[currentReminderToEdit].smsRange = reminder.range;
                    break;

                case Channel.Teams:
                    toLoad[currentReminderToEdit].teams = true;
                    break;

                case Channel.Mobile:
                    toLoad[currentReminderToEdit].mobile = true;
                    break;

                default:
                    console.log("Critical error with loading saved data, investigate tenant settings.")
            }
        });

        this.setState({reminders: toLoad})
    }

    componentDidUpdate(){
        this.props.setReminders(this.state.reminders, this.state.arrayLocation);
    }    

    public render() {
        const timeOptions = [
            {label: 'Hours', value: false},
            {label: 'Days', value: true}
        ]

        const rangeOptionsEmail = [
            {label: 'Only if standard app notifications are not set up', value: RangeType.Fallback, id: 'emailRange'},
            {label: 'To all topic subscribers', value: RangeType.Subscribers, id: 'emailRange'},
        ]

        const rangeOptionsSMS = [
            {label: 'Only if standard app or email notifications are not set up', value: RangeType.Fallback, id: 'smsRange'},
            {label: 'To all topic subscribers', value: RangeType.Subscribers, id: 'smsRange'},
        ]

        return(
            <div>
                <div className="authoring-prompt">
                    <div>
                        <h4>Edit Reminder Default Settings</h4>
                        <span className="description-text">The following details will be the default reminder setting for each time a notification is created and/or published.</span>
                    </div>
                </div>

                <div className="authoring-settings-zone">
                    <div>
                        <div>
                            Remind if notification  is unread.
                            <ToggleSwitch 
                                onClick={this.togglePostUnread}
                                value={this.state.reminders.length !== 0}
                            />
                        </div>
                        <span className="description-text">Remind employees if the original notification is still unread after a set amount of time.</span>
                    </div>
                    <span className="description-text">Add/edit additional reminder channels:</span>

                    <div className="reminder-spacing">
                        {this.state.reminders.map((reminder, index) => 
                            <div key={index} className="reminder-background">
                                <div>
                                    <span>Reminder {index+1}</span>
                                    {index !== 0 && 
                                        <DeleteIcon className="reminder-trash" onClick={() => this.onDeleteReminder(index)}/>}
                                </div>

                                <div>
                                    <div className="settings-input-label">If unread, send reminder after </div>
                                            <input 
                                                className="settings-input-field-sm"
                                                type="number"
                                                value={reminder.displayDelay}
                                                onChange={(e) => this.updateDelay(index, e.target.valueAsNumber)}
                                                min={0}
                                            ></input>
                                            <Select 
                                                className="settings-input-field-select"
                                                options={timeOptions}
                                                defaultValue={timeOptions[0]}
                                                onChange={(e) => this.toggleTime(e, index)}
                                                value={reminder.daysSelected ? timeOptions[1] : timeOptions[0]}
                                            />
                                            
                                </div>

                                <div className="range-channel-selection">
                                    <label>
                                        <input type="radio"
                                            checked={reminder.inherit}
                                            onChange={() => this.toggleRadio(index)}
                                        />
                                        <span>Use channels selected in authoring notifications</span>
                                    </label>
                                    <label>
                                        <input type="radio"
                                            checked={!reminder.inherit}
                                            onChange={() => this.toggleRadio(index)}
                                        />
                                        <span>Custom channel options</span>
                                    </label>

                                    {!reminder.inherit && 
                                    <div className="checkbox-display-vertical">
                                        {!this.state.teamsDisabled && 
                                            <label>
                                                <input type="checkbox"
                                                    checked={reminder.teams}
                                                    onChange={(e) => this.toggleCheck(e, index)}
                                                    name="teams"
                                                /> <span>Teams Chatbot</span>
                                            </label>
                                        }
                                        {!this.state.mobileDisabled &&
                                            <label>
                                                <input type="checkbox"
                                                    checked={reminder.mobile}
                                                    onChange={(e) => this.toggleCheck(e, index)}
                                                    name="mobile"
                                                /> <span>Mobile App</span>
                                            </label>
                                        }
                                        {!this.state.emailDisabled &&
                                        <>
                                            <label>
                                                <input type="checkbox"
                                                    checked={reminder.email}
                                                    onChange={(e) => this.toggleCheck(e, index)}
                                                    name="email"
                                                /> <span>Email</span>
                                            </label>
                                            <div className="range-dropdown">
                                                <Select 
                                                    options={rangeOptionsEmail}
                                                    defaultValue={rangeOptionsEmail[1]}
                                                    name={'emailRange'}
                                                    value={reminder.emailRange === RangeType.Subscribers ? rangeOptionsEmail[1] : rangeOptionsEmail[0]}
                                                    onChange={(e) => this.toggleDropdown(e, index)}
                                                    isDisabled={!reminder.email}
                                                />
                                            </div>
                                        </>
                                        }
                                        {!this.state.smsDisabled &&
                                        <>
                                            <label>
                                                <input type="checkbox"
                                                    checked={reminder.sms}
                                                    onChange={(e) => this.toggleCheck(e, index)}
                                                    name="sms"
                                                /> <span>SMS</span>
                                            </label>
                                            <div className="range-dropdown">
                                                <Select 
                                                    options={rangeOptionsSMS}
                                                    defaultValue={rangeOptionsSMS[1]}
                                                    name={'smsRange'}
                                                    value={reminder.smsRange === RangeType.Subscribers ? rangeOptionsSMS[1] : rangeOptionsSMS[0]}
                                                    onChange={(e) => this.toggleDropdown(e, index)}
                                                    isDisabled={!reminder.sms}
                                                />
                                            </div>
                                        </>
                                        }
                                    </div>}
                                </div>
                            </div>
                        )}
                    </div>

                    <div>
                        {this.state.remindersEnabled && this.state.reminders.length < 5 &&
                            <span onClick={this.onAddReminder} className="reminder-text">+ Add reminder</span>
                        }
                    </div>
                </div>
            </div>
        );
    }

    private togglePostUnread = () => {
        if(this.state.reminders.length === 0)
        {
            this.setState({remindersEnabled: true})
            if(this.state.reminderBackup.length === 0)
            {
                this.onAddReminder();
            }
            else
            {
                this.setState({reminders: this.state.reminderBackup})
            }
        }
        else
        {
            this.setState({remindersEnabled: false})
            this.setState({reminderBackup: this.state.reminders})
            this.setState({reminders: []})
        }
    }

    private onAddReminder = () => {
        this.setState({ reminders: this.state.reminders.concat([ this.getDefaultReminder() ]) })
    
    }

    private getDefaultReminder = (): reminderData => {
        return { 
            delay: this.props.notificationSettings[this.state.defaultTimeLocation] ?? 0, 
            displayDelay: this.props.notificationSettings[this.state.defaultTimeLocation] ?? 0,
            daysSelected: false,
            inherit: true,
            email: false,
            sms: false,
            teams: false,
            mobile: false,
            emailRange: RangeType.Subscribers,
            smsRange: RangeType.Subscribers
        }
    }

    private onDeleteReminder = (reminderIndex: number) => {
        let reminders: reminderData[] = this.state.reminders.slice();
        reminders.splice(reminderIndex, 1);
        this.setState({ reminders });
    }

    private updateDelay = (index: number, newValue: number) => {
        const newArray = this.state.reminders;
        newArray[index].displayDelay = newValue;
        this.setState({reminders: newArray});
    }

    private toggleRadio = (index: number) => {
        const newArray = this.state.reminders;
        newArray[index].inherit = !newArray[index].inherit
        this.setState({reminders: newArray});
    }

    private toggleCheck = (e: any, index: number) => {
        const newArray = this.state.reminders;
        newArray[index][e.target.name] = !newArray[index][e.target.name]
        this.setState({reminders: newArray})
    }

    private toggleDropdown = (e: any, index: number) => {
        const newArray = this.state.reminders;
        newArray[index][e.id] = e.value
        this.setState({reminders: newArray})
    }

    private toggleTime = (e: any, index: number) => {
        const newArray = this.state.reminders;
        newArray[index].daysSelected = e.value
        this.setState({reminders: newArray})
    }
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        notificationSettings: state.settings.notificationSettings,
    }),
    {
        setReminders: actions.SetRemindersAction,
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default (connector(ReminderPopout));