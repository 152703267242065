import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux'
import { GlobalApplicationState } from 'globalApplicationState';
import * as actions from '../actionCreator';
import ToggleSwitch from 'modules/common/components/toggleSwitch';

import confirm from "utils/notyPopups";


class FeatureControlsSettingsForm extends React.Component<PropsWithRedux, {}> {
    public render() {
        const working = this.props.saving || this.props.fetching;

        return <div>
            <div className="form-section">
                <h4>Content Creation, Management & Accessibility</h4>
                <div className="form-group" >
                    <label>Enable Events</label>
                    <div>
                        <ToggleSwitch value={this.props.tenantSettings.mobileEventsEnabled}
                            onClick={() => { this.props.setMobileEventsEnabled(!this.props.tenantSettings.mobileEventsEnabled); }}
                            disabled={working} />
                    </div>
                </div>
                {this.props.tenantSettings.showFeatures.documentsEnabled &&
                    <div className="form-group">
                        <label>Enable Documents</label>
                        <div>
                            <ToggleSwitch value={this.props.tenantSettings.documentsEnabled}
                                onClick={() => { this.props.setDocumentsEnabled(!this.props.tenantSettings.documentsEnabled); }}
                                disabled={working} />
                        </div>
                    </div>
                }
                {this.props.tenantSettings.showFeatures.portalPagesEnabled &&
                    <div className="form-group">
                        <label>Enable Portal Pages</label>
                        <div>
                            <ToggleSwitch value={this.props.tenantSettings.portalPagesEnabled}
                                onClick={async () => {
                                    if (!this.props.tenantSettings.portalPagesEnabled) {
                                        if (await confirm.show({
                                            title: "Enable Portal Pages",
                                            text: (
                                                <div>The Homepage will be automatically published and accessible in the Company Portal when you enable this feature. Click <a href="https://support.sparrowconnected.com/en/intro-to-portal-pages-coming-soon" rel="noopener noreferrer" target="_blank">here</a> to preview the Homepage. Would you like to continue?</div>
                                            )
                                        }))
                                            this.props.setPortalPagesEnabled(true);
                                    } else {
                                        this.props.setPortalPagesEnabled(false);
                                    }
                                }}
                                disabled={working} />
                        </div>
                    </div>
                }
                {this.props.tenantSettings.showFeatures.postSmartContentEnabledForTenant &&
                    <div className="form-group">
                        <label>Enable Post Smart Content</label>
                        <div>
                            <ToggleSwitch
                                value={this.props.tenantSettings.postSmartContentEnabledByTenant || false}
                                onClick={() => {
                                    let newValue = !this.props.tenantSettings.postSmartContentEnabledByTenant;

                                    this.props.setPostSmartContentEnabled(newValue)
                                }}
                                disabled={working}
                            />
                        </div>
                    </div>
                }
            </div>
            <div className="form-section">
                <h4>Mobile App Features</h4>
                <div className="form-group">
                    <label>Enable Surveys</label>
                    <div>
                        <ToggleSwitch value={this.props.tenantSettings.surveysEnabled}
                            onClick={() => { this.props.setSurveysEnabled(!this.props.tenantSettings.surveysEnabled); }}
                            disabled={working} />
                    </div>
                </div>
                <div className="form-group" >
                    <label>Enable Chat</label>
                    <div>
                        <ToggleSwitch value={this.props.tenantSettings.chatEnabled}
                            onClick={() => { this.props.setChatEnabled(!this.props.tenantSettings.chatEnabled); }}
                            disabled={working} />
                    </div>
                </div>
                <div className="form-group" >
                    <label>Enable Post Authoring</label>
                    <div>
                        <ToggleSwitch value={this.props.tenantSettings.mobilePublishingEnabled}
                            onClick={() => { this.props.setMobilePublishingEnabled(!this.props.tenantSettings.mobilePublishingEnabled); }}
                            disabled={working} />
                    </div>
                </div>
            </div>
        </div>

    }
}

interface ComponentProps { }

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        fetching: state.settings.fetching,
        saving: state.settings.saving,
        tenantSettings: state.settings.tenantSettings,
    }),
    {
        setSurveysEnabled: actions.setSurveysEnabled,
        setChatEnabled: actions.setChatEnabled,
        setDocumentsEnabled: actions.setDocumentsEnabled,
        setMobilePublishingEnabled: actions.setMobilePublishingEnabled,
        setMobileEventsEnabled: actions.setMobileEventsEnabled,
        setPortalPagesEnabled: actions.setPortalPagesEnabled,
        setPostSmartContentEnabled: actions.SetPostSmartContentEnabledByTenant,
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(FeatureControlsSettingsForm);
