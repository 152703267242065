import * as React from "react";
import { ConnectedProps, connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { GlobalApplicationState } from "globalApplicationState";
import * as actions from "../actionCreator";
import * as audiencesActions from "modules/audiences/actionCreator";

import NewNavigationItem from "../global-components/navigation-items/action-buttons/newNavigationItem";
import NavigationItemsListing from "../global-components/navigation-items/navigation-items-listing/navigationItemsListing";

import ErrorSnackbar from "modules/common/components/snackbars/errorSnackbar";
import LoadingOverlay from "modules/common/components/loadingOverlay";

import BasePage from "pages/common/basePage";
import Breadcrumb from "pages/common/breadcrumb";
import MainContent from "pages/common/mainContent";

import "../styles/dialogs.sass";
import "../styles/navigationItems.sass";


class NavigationItemsManagementPage extends React.Component<PropsWithRedux, {}> {
  public componentDidMount() {
    if (!this.props.audiences.length)
      this.props.getAudiences();
  }
  
  public render() {
    return (
      <BasePage fullWidth>
        <Breadcrumb
          items={[
            { title: "Global Components" },
            { title: "Navigation Items" }
          ]}
          backItem={{ title: "Back to Manage Portal Pages", link: "~/admin/portalPages" }}
          rightComponent={
            <div className="navigation-items">
              <NewNavigationItem />
            </div>
          }
        />
        <MainContent>
          <NavigationItemsListing />
          <ErrorSnackbar errorMessage={this.props.errorMessage} clearErrorMessage={this.props.clearErrorMessage} />
          <LoadingOverlay absolute={true} show={this.props.isDeleting || this.props.isSaving} />
        </MainContent>
      </BasePage>
    );
  }
}

interface ComponentProps {}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps & RouteComponentProps) => ({
    ...ownProps,
    audiences: state.audiences.audiences,
    errorMessage: state.portalPages.errorMessage,
    isDeleting: state.portalPages.isDeleting,
    isSaving: state.portalPages.isSaving
  }),
  {
    clearErrorMessage: actions.clearErrorMessage,
    getAudiences: audiencesActions.getAudiences
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(NavigationItemsManagementPage);