import * as actions from "network/actions";

export const SetReaction = (postId: string, reactionId: string | null) => {
  return actions.NetworkRequest({
    headers: { "content-type": "application/json" },
    method: "POST",
    url: `/{tenant}/api/v1/reactions`,
    body: JSON.stringify({
      postId,
      reactionId
    })
  });
}