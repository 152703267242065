import React from "react";
import { connect, ConnectedProps } from "react-redux";
import * as actions from "../../actionCreator";
import { GlobalApplicationState } from "globalApplicationState";

import Button from "@mui/material/Button";

import AddIcon from "@mui/icons-material/Add";


const NewPortalPage: React.FunctionComponent<PropsWithRedux> = props => {
  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={<AddIcon />}
      onClick={props.showNewPortalPage}
    >
      New Page
    </Button>
  );
}

interface ComponentProps {
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps
  }),
  {
    showNewPortalPage: actions.showNewPortalPage
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(NewPortalPage);