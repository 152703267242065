import React from "react";
import { connect, ConnectedProps } from "react-redux";
import * as actions from "../../actionCreator";
import { GlobalApplicationState } from "globalApplicationState";
import InfoHover from "modules/common/components/hovers/infoHover";

import confirm from "utils/notyPopups";

import { NewsletterDetails } from "../../models";

import EditQueuedIssueDialog from "./editQueuedIssueDialog";

import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import AddIcon from "@mui/icons-material/Add";
import SyncIcon from "@mui/icons-material/Sync";

import moment from "moment";

class FutureIssues extends React.Component<PropsWithRedux, ComponentState> {

  public constructor(props:PropsWithRedux){
    super(props);
    this.state={
      isAddingNewIssue:false,
      editingIssueDateLocal:null
    };
  }

  public componentDidMount(){
    moment.locale("en");
  }

  public render() {
    const { newsletter } = this.props;
    const isNewsletterAdhoc = newsletter.recurrenceType === "Adhoc";

    if (!newsletter)
      return <React.Fragment />;
    
    return (
      <React.Fragment>
        <div>
          <div className="newsletter-issues-header">
            <div className="header">
              <span>Future Issues</span>
              <InfoHover>Five future issues are automatically added even if you change the date of one edition. Newsletter issues added follow the farthest date sent, depending on the frequency you've set.</InfoHover>
            </div>
            <div>
            {!isNewsletterAdhoc
              ? <Button
                  variant="text"
                  color="primary"
                  startIcon={<SyncIcon />}
                  disabled={newsletter.futureIssues.findIndex(futureIssue => futureIssue.isCustomized) === -1}
                  onClick={async () => {
                    if (await confirm.show({
                      title: `Reset queued issues in '${newsletter.title}'`,
                      text: (
                        <div>
                          <div>You're about to reset the queued items in '{newsletter.title}'. You'll lose customizations in all currently queued issues.</div>
                          <br />
                          <div>Are you sure?</div>
                        </div>
                      ),
                      yesColor: "#a80000",
                      yesText: "Reset",
                      noText: "Cancel"
                    }))
                      this.onResetIssues(newsletter.futureIssues.filter(futureIssue => futureIssue.isCustomized).map(futureIssue => futureIssue.issueDateLocal));
                  }}
                >
                  Reset issues
                </Button>
              : <Button variant="outlined" color="primary" startIcon={<AddIcon />} onClick={() => this.setState({isAddingNewIssue:true})} disabled={newsletter.status === "Disabled"}>Future issue</Button>
              }
            </div>
          </div>
          {newsletter.status === "Disabled"
            ? <div className="no-issues-message">Newsletter is disabled.</div>
            : newsletter.futureIssues.length === 0
                ? <div className="no-issues-message">No future issues were found.</div>
                : (
                    <TableContainer>
                      <Table size="medium">
                        <TableHead>
                          <TableRow>
                            <TableCell align="left" padding="normal">Scheduled for</TableCell>
                            <TableCell />
                            <TableCell />
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {newsletter.futureIssues.map((futureIssue, index) => {
                            return (
                              <TableRow key={index}>
                                <TableCell align="left" className={futureIssue.status === "Skip" ? "skipped" : ""}>
                                  {moment(futureIssue.sendDateTime).format("MMM D, YYYY h:mmA")}
                                  {futureIssue.status === "Skip" ? " (Skipping)" : (futureIssue.isCustomized ? " (Customized)" : "")}
                                </TableCell>
                                <TableCell align="right">
                                  {futureIssue.status === "Future" &&
                                    <Button variant="text" color="primary" onClick={() => this.setState({editingIssueDateLocal: futureIssue.issueDateLocal})}>Edit issue</Button>
                                  }
                                  </TableCell>
                                <TableCell align="right">
                                  {!isNewsletterAdhoc && futureIssue.status === "Skip"
                                    && <Button
                                        variant="text"
                                        color="primary"
                                        onClick={() => {
                                          this.onUnskipIssue(futureIssue.issueDateLocal);
                                        }}
                                      >
                                        Unskip
                                      </Button>
                                  }
                                  {!isNewsletterAdhoc && futureIssue.status === "Future"
                                    && <Button
                                        variant="text"
                                        className="skip"
                                        onClick={() => {
                                          this.onSkipIssue(futureIssue.issueDateLocal);
                                        }}
                                      >
                                        Skip
                                      </Button>
                                  }
                                  {isNewsletterAdhoc &&
                                  <Button
                                    variant="text"
                                    className="skip"
                                    onClick={async () => {
                                      if (await confirm.show({
                                        title: `Remove '${moment(futureIssue.sendDateTime).format("MMM D, YYYY")}' issue`,
                                        text: (
                                          <div>
                                            <div>You're about to remove the issue for '{moment(futureIssue.sendDateTime).format("MMM D, YYYY")}'.</div>
                                            <br />
                                            <div>Are you sure?</div>
                                          </div>
                                        ),
                                        yesColor: "#a80000",
                                        yesText: "Remove",
                                        noText: "Cancel"
                                      }))
                                        this.onResetIssue(futureIssue.issueDateLocal);
                                    }}
                                  >
                                    Remove
                                  </Button>
                                  }
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )
          }
        </div>
        {(this.state.isAddingNewIssue || !!this.state.editingIssueDateLocal) && 
            <EditQueuedIssueDialog 
              newsletter={newsletter}
              isAddingNewIssue={this.state.isAddingNewIssue}
              editingIssueDateLocal={this.state.editingIssueDateLocal}
              onIssueChanged={this.onIssueChanged}
              onResetIssue={() => this.onResetIssue(this.state.editingIssueDateLocal!)}
              onCloseIssue={this.onCloseIssue}
              onSuccess={this.props.onSuccess}
              onError={this.props.onError}
              /> 
          }
      </React.Fragment>
    );
  }
  
  private onCloseIssue = () => {
    this.setState({ isAddingNewIssue: false, editingIssueDateLocal: null });
  }

  private onIssueChanged = () => {
    this.props.onNewsletterUpdated();
    this.onCloseIssue();
  }

  private onSkipIssue = (issueDateLocal: string): Promise<void> => {
    this.props.onSaving();
    return this.props.skipIssue(this.props.newsletter.id, issueDateLocal).then((succeeded) => {
      if (succeeded){
        this.props.onSuccess("Skipped");
      }else{
        this.props.onError("Failed");
      }
      this.props.onNewsletterUpdated();
    });
  }

  private onUnskipIssue = (issueDateLocal: string): Promise<void> => {
    this.props.onSaving();
    return this.props.unskipIssue(this.props.newsletter.id, issueDateLocal).then((succeeded) => {
      if (succeeded){
        this.props.onSuccess("Re-queued");
      }else{
        this.props.onError("Failed");
      }
      this.props.onNewsletterUpdated();
    });
  }

  private onResetIssue = (issueDateLocal: string): Promise<boolean> => {
    this.props.onSaving();
    return this.props.resetIssue(this.props.newsletter.id, issueDateLocal).then((succeeded) => {
      if (succeeded) {
        this.props.onSuccess("Issue reset");
      } else {
        this.props.onError("Failed");
      }
      this.props.onNewsletterUpdated();
      return succeeded;
    });
  }

  private onResetIssues = (issueDatesLocal: string[]): Promise<void> => {
    this.props.onSaving();
    return this.props.resetIssues(this.props.newsletter.id, issueDatesLocal).then((succeeded) => {
      if (succeeded){
        this.props.onSuccess("Issues Reset");
      }else{
        this.props.onError("Failed");
      }
      this.props.onNewsletterUpdated();
    });
  }
}

interface ComponentState{
  isAddingNewIssue: boolean;
  editingIssueDateLocal: string | null;
}

interface ComponentProps {
  newsletter: NewsletterDetails;
  onNewsletterUpdated:() => any;
  onSaving: () => any;
  onError: (message: string) => any;
  onSuccess: (message: string) => any;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ownProps,
  {
    resetIssue: actions.resetIssue,
    resetIssues: actions.resetIssues,
    skipIssue: actions.skipIssue,
    unskipIssue: actions.unskipIssue
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(FutureIssues);