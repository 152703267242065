import * as React from "react";
import UploadIcon from "modules/common/components/authoring/uploader/uploadIconSvg";

import ErrorSnackbar from "modules/common/components/snackbars/errorSnackbar";


interface componentProps {
    parseCSV: (File) => any;
    downloadSample: () => void;
}

interface componentState {
    dragging: boolean;
    errorMessage: string;
}

class CSVUploader extends React.Component<componentProps, componentState> {
    constructor(props) {
        super(props);
        this.state = {
            dragging: false,
            errorMessage: ""
        };
    }

    public render() {
        return this.renderUploadForm();
    }

    private renderUploadForm() {
        let draggingProps = {
            onDrag: this.dragging(true),
            onDragStart: this.dragging(true),
            onDragEnd: this.dragging(false),
            onDragOver: this.dragging(true),
            onDragEnter: this.dragging(true),
            onDragLeave: this.dragging(false),
            onDrop: this.onFileDropped,
        };

        return (
            <React.Fragment>
                <div className={"uploader" + (this.state.dragging ? " is-dragging" : "")} {...draggingProps}>
                    <div className="centered-content">
                        <UploadIcon color="#ccc" width="75px" height="75px" />
                        <div>
                            <input
                                className="original-input"
                                id="choose-file"
                                multiple
                                name="choose-file"
                                type="file"
                                accept={this.acceptedFiles()}
                                onChange={this.onFileInputChanged}
                            />
                            <div className="large">
                                <div>
                                    <label className="choose-file-button" htmlFor="choose-file">
                                        Choose a CSV
                                    </label>
                                    <span>or drag file here.</span>
                                </div>
                                <div>
                                    <label className="choose-file-button" onClick={this.props.downloadSample}>
                                        Download Sample CSV
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ErrorSnackbar errorMessage={this.state.errorMessage} clearErrorMessage={this.clearErrorMessage} />
            </React.Fragment>
        );
    }

    private onFileInputChanged = (e: React.FormEvent<HTMLInputElement>) => {
        const files = (e.target as HTMLInputElement).files;
        if (!this.acceptedFiles().includes(files![0].type)) {
            this.setState({ errorMessage: "Incorrect file format, please upload a CSV file" });
            return;
        }
        this.dragging(false, () => {
            this.props.parseCSV(files![0]); // only take in one file
        })(e);
    };

    private acceptedFiles = () => {
        return ".csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv";
    };
    private onFileDropped = (e: React.DragEvent<HTMLDivElement>) => {
        const files = e.dataTransfer.files;
        if (!this.acceptedFiles().includes(files![0].type)) {
            this.setState({ errorMessage: "Incorrect file format, please upload a CSV file" });
            return;
        }
        this.dragging(false, () => {
            this.props.parseCSV(files[0]); // only take in one file
        })(e);
    };

    private dragging = (isDragging: boolean, callback?: () => void) => (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (typeof callback !== "function") callback = () => {};

        this.setState(
            {
                ...this.state,
                dragging: isDragging,
            },
            callback
        );
        return false;
    };
    
    private clearErrorMessage = () => {
        this.setState({ errorMessage: "" });
    }
}

export default CSVUploader;
