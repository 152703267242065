import * as React from 'react';
import { GlobalApplicationState } from "globalApplicationState";
import { ConnectedProps, connect } from "react-redux";
import { actions } from '..';
import { Drawer, IconButton } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import ArrowBack from '@mui/icons-material/ArrowBack';
import TextBubble from 'modules/common/components/textBubble';
import { Tooltip } from '@mui/material';
import WarningLabel from './warningLabel'
import SingleTextAreaInput from './singleTextAreaInput';
import { NotificationEvents } from 'modules/messaging';

interface TextBubble {
    id: string;
    name: string;
}

interface ComponentProps { 
    updateToggle: () => any
    eventPrompt: string
    event: string
    bubbles: TextBubble[]
}

const EmailNotificationPopout: React.FunctionComponent<PropsWithRedux> = props => {
    const [isOpen, setIsOpen] = React.useState(true);

    const itemDisplay = () => {
        const LightTooltip = withStyles((theme) => ({
            tooltip: {
                backgroundColor: '#f5f5f9',
                color: 'rgba(0, 0, 0, 0.87)',
                boxShadow: theme.shadows[1],
                fontSize: "12px"
            }
        }))(Tooltip);

        const eventToCheck = props.event + "EmailAttempt";
        
        return (
            <div className={"full-list"}>
                <div className="top-options">
                    <IconButton onClick={toggleDrawer(false)} size="large">
                        <ArrowBack/>
                    </IconButton>
                    <span className="top-text">{"More "+props.eventPrompt+" Settings"}</span> 
                </div>
                <div className="drawer-line-break">
                    <hr className="separator-line-popout"/>
                </div>

                <h4>{"Edit Notification Contents"}</h4>
                <span className="description-text">Change the content of your notifications. Use suggestions to personalize your content.  </span>
                <LightTooltip title="You can edit and personalize the content of the notifications you send out, which include the subject in email notifications, the title and body of mobile app notifications, and the text message of SMS notifications.">
                    <ins className="description-text">Learn More.</ins>
                </LightTooltip>

                <div className="override-titles">
                    <span>Email Notification: Subject</span>
                </div>

                {!(props.notificationSettings.settings[eventToCheck].toggle) && 
                    <WarningLabel platform="Email"/>
                }

                <SingleTextAreaInput
                    returnTranslatedContent={updateSubject}
                    maxTextLength={400}
                    placeholder={props.emailSubjects[eventUppercase(NotificationEvents.CommentFlaggedEmail)].toString()}
                    prompt={"Subject"}
                    toLoad={props.notificationSettings.settings[NotificationEvents.CommentFlaggedEmail].translatedSubject}
                    event={NotificationEvents.CommentFlaggedEmail}
                    platform={'email'}
                />           

                <div className="word-bubble-formatting">
                    <p className="suggestions-section-text">Use suggestions to personalize: </p>
                    <TextBubble list={props.bubbles} />
                </div>
            </div>
        );
    }

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        
        props.updateToggle();
        setIsOpen(open);
    }

    const updateSubject = (translatedContent: any) => {
        const toChange = props.event + "EmailAttempt"
        props.setEmailSubject(toChange, translatedContent);
    }

    const eventUppercase = (eventToFix: string) => {
        if(eventToFix !== null && eventToFix.length > 2)
            return eventToFix[0].toUpperCase() + eventToFix.slice(1);
        else
            return "Invalid string to fix."
    }

    return (
        <Drawer
        anchor={'right'}
        open={isOpen}
        onClose={toggleDrawer(false)}
        >
            {itemDisplay()}

        </Drawer>
    );
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        notificationSettings: state.settings.notificationSettings,
        emailSubjects: state.resources.emailSubjectsResource
    }),
    {
        setEmailSubject: actions.SetEmailSubject
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default (connector(EmailNotificationPopout));