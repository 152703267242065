import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import * as actions from "../../actionCreator";
import { GlobalApplicationState } from "globalApplicationState";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";


import "../../styles/dialogs.sass";


class PublishSuccess extends React.Component<PropsWithRedux, {}> {
  public render() {
    return (
      <Dialog open={this.props.showPublishSuccess} maxWidth={false} onClose={this.onClose}>
        <DialogTitle>Published!</DialogTitle>
        <DialogContent className="publish-success-content">
          <div className="success-checkmark-pop-wrapper">
            <div className="success-checkmark-pop-bg">
              <div className="success-checkmark-pop-checkmark"></div>
            </div>
          </div>
          <div>Please refresh shortly to see published event.</div>
        </DialogContent>
        <DialogActions className="events-dialog-footer">
          <Button variant="text" color="primary" onClick={this.onAccept}>OK</Button>
        </DialogActions>
      </Dialog>
    );
  }

  private onAccept = () => {
    this.props.clearShowPublishSuccess();
  }

  private onClose = () => {
    this.props.clearShowPublishSuccess();
  }
}

interface ComponentProps {
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    showPublishSuccess: state.events.showPublishSuccess
  }),
  {
    clearShowPublishSuccess: actions.clearShowPublishSuccess
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(PublishSuccess);