import React from "react";
import { connect, ConnectedProps } from "react-redux";
import * as actions from "../../../actionCreator";
import { defaultListingPage } from "../../../reducer";
import { GlobalApplicationState } from "globalApplicationState";

import Callout from "modules/common/components/callout";
import Loading from "modules/common/components/loading";
import { NavigationItem, PortalPagesFilterValues, PortalPagesListingPage, PortalPagesListItem } from "modules/portalPages/models";

import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";

import Pagination from '@mui/material/Pagination';


class NewChildNavigationItem extends React.Component<PropsWithRedux, ComponentState> {
  constructor(props: PropsWithRedux) {
    super(props);

    this.state = {
      anchorEl: null,
      calloutOpen: false,
      filters: {
        searchText: ""
      },
      isFetching: false,
      pagesList: { ...defaultListingPage },
      selectedPortalPage: undefined
    };
  }

  public render() {
    return (
      <React.Fragment>
        <IconButton
          size="small"
          onClick={this.onShowPagesList}
        >
          <AddIcon />
        </IconButton>
        <Callout
          anchorEl={this.state.anchorEl}
          open={this.state.calloutOpen}
          setOpen={(toSet) => {
            this.onHidePagesList();
            this.setState({calloutOpen: toSet});
          }}
          arrowStyle={{ left: "70%" }}
          header={<div>Select a page</div>}
          footer={
            <Pagination color="primary" count={this.state.pagesList.totalPages} page={this.state.pagesList.currentPage} boundaryCount={2} showFirstButton showLastButton onChange={(ev, pageNumber) => this.fetchNavigationPortalPages(pageNumber, this.state.filters)} />
          }
        >
          <div className="navigation-items-callout">
            {this.state.isFetching
              ? <Loading padding={12} />
              : <React.Fragment>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={this.state.filters.searchText}
                    placeholder="Search page"
                    InputProps={{
                      startAdornment: <SearchIcon className="search-icon" />
                    }}
                    onChange={this.onChangeSearchText}
                    onKeyUp={this.onKeyUp}
                    className="text-to-search"
                  />
                  <List disablePadding>
                    {this.state.pagesList.portalPages.length === 0
                      ? <div>No pages were found.</div>
                      : <div className="pages-list">
                          {this.state.pagesList.portalPages.map((portalPage) => {
                            return (
                              <ListItem key={portalPage.draftId} dense button onClick={() => this.onSelectPortalPage(portalPage)}>
                                <ListItemText
                                  primary={portalPage.title}
                                  secondary={
                                    <React.Fragment>
                                      <Typography
                                        component="span"
                                        display="block"
                                        variant="body2"
                                      >
                                        /{portalPage.url}
                                      </Typography>
                                      <Typography
                                        component="span"
                                        display="block"
                                        variant="body2"
                                      >
                                        {portalPage.lastModifiedBy}
                                      </Typography>
                                    </React.Fragment>
                                  }
                                />
                              </ListItem>
                            );
                          })}
                        </div>
                    }
                  </List>
                </React.Fragment>
            }
          </div>
        </Callout>
      </React.Fragment>
    );
  }

  private fetchNavigationPortalPages = (pageNumber: number, filters: Partial<PortalPagesFilterValues>) => {
    this.setState({ isFetching: true });
    this.props.getNavigationPortalPages(pageNumber, filters).then((pagesList) => {
      this.setState({ isFetching: false, pagesList: pagesList });
    });
  }

  private onApplyFilters = () => {
    this.fetchNavigationPortalPages(1, this.state.filters);
  }

  private onChangeFilters = (value: Partial<PortalPagesFilterValues>) => {
    this.setState({ filters: { ...this.state.filters, ...value } });
  }

  private onChangeSearchText = (event) => {
    this.onChangeFilters({ searchText: event.target.value });
  }

  private onKeyUp = (key) => {
    if (key.keyCode === 13)
      this.onApplyFilters();
  }

  private onSelectPortalPage = (portalPage: PortalPagesListItem) => {
    const sequence: number = this.props.parentNavigationItem.children.length + 1 || 1;
    this.props.createNewChildNavigationItem(portalPage.id, this.props.parentNavigationItem.id, portalPage.title, portalPage.url, sequence);
    this.onHidePagesList();
  }


  private onHidePagesList = () => {
    this.setState({ anchorEl: null });
  }

  private onShowPagesList = (event: any) => {
    this.setState({ anchorEl: event.currentTarget, filters: { searchText: "" } });
    this.fetchNavigationPortalPages(1, {});
  }
}

interface ComponentProps {
  parentNavigationItem: NavigationItem;
}

interface ComponentState {
  anchorEl: any;
  calloutOpen: boolean;
  filters: Partial<PortalPagesFilterValues>;
  isFetching: boolean;
  pagesList: PortalPagesListingPage;
  selectedPortalPage: PortalPagesListItem | undefined;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    navigation: state.portalPages.navigationItems
  }),
  {
    createNewChildNavigationItem: actions.createNewChildNavigationItem,
    getNavigationPortalPages: actions.getNavigationPortalPages
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(NewChildNavigationItem);