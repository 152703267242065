/**
 * A fallback mapper from the old sentiment score we used to the new label system described here:
 * https://help.readable.com/en/article/tone-sentiment-personalism-and-reach-xc2eog/
 *
 * This works by normalizing the score to a 0-100 range and then mapping to the new labels
 * @param score - sentiment score
 * @param minVal - min score
 * @param maxVal - max score
 * @param minLabel - min label
 * @param maxLabel - max label
 * @param reverse - controls whether low score should be at right end of indicator or not
 */
export const fallbackSentimentLabel = (score: number, minVal: number, maxVal: number, minLabel: string, maxLabel: string, reverse: boolean) => {
    let normalizedScore = ((score - minVal) * 100) / (maxVal - minVal);
    let leftLabel = reverse ? maxLabel : minLabel;
    let rightLabel = reverse ? minLabel : maxLabel;

    if (normalizedScore <= 19) {
        return leftLabel;
    } else if (normalizedScore <= 39) {
        return "Neutral (Slightly " + leftLabel + ")";
    } else if (normalizedScore <= 59) {
        return "Neutral";
    } else if (normalizedScore <= 79) {
        return "Neutral (Slightly " + rightLabel + ")";
    } else {
        return rightLabel;
    }
}
