import React from "react";

import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";


interface DialogSwitchFieldProps {
  description?: string;
  label: string;
  value: boolean;
  disabled?: boolean;
  onChange: (event: any, checked: boolean) => void;
}

const DialogSwitchField: React.FunctionComponent<DialogSwitchFieldProps> = props => {
  return (
    <div className="dialog-field dialog-switch-field">
      <div className="dialog-field-heading">
        <label>{props.label}</label>
        {props.description &&
          <Typography variant="caption">{props.description}</Typography>
        }
      </div>
      <div>
        <Switch
          color="primary"
          checked={props.value}
          disabled={props.disabled}
          onChange={props.onChange}
        />
      </div>
    </div>
  );
}

export default DialogSwitchField;