import React from "react";

import InfoHover from "modules/common/components/hovers/infoHover";
import Loading from "modules/common/components/loading";

import { NewsletterPastIssuesPage } from "../../models";

import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import moment from "moment";
import numeral from "numeral";


const PastIssues: React.FunctionComponent<ComponentProps> = props => {
  const { pastIssues, isLoading, onGetPastIssues } = props;

  return (
    <div>
      <div className="newsletter-issues-header">
        <div className="header">Past Issues</div>
      </div>
      {(isLoading || !pastIssues)
        ? <Loading />
        : pastIssues.totalItems === 0
          ? <div className="no-issues-message">No past issues were found.</div>
          : (
              <div>
                <TableContainer>
                  <Table size="medium">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Issue</TableCell>
                        <TableCell align="center">Recipients</TableCell>
                        <TableCell align="center">Failed attempts</TableCell>
                        <TableCell align="center">Unique opens</TableCell>
                        <TableCell align="center">Unique clicks</TableCell>
                        <TableCell align="right" className="header-info-hover">
                          <InfoHover>
                            <span className="emphasis">Recipients</span> are unique individuals who received the issue. <span className="emphasis">Failed attempts</span> are issues that were not successfully delivered.
                            <span className="emphasis">Unique opens</span> count the recipients who opened the issue and <span className="emphasis">Unique clicks</span> count individuals who interacted with the email.
                          </InfoHover>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {pastIssues?.results?.map((pastIssue, index) => {
                        if (pastIssue.status === 'Skipped')
                          return (
                            <TableRow key={index}>
                              <TableCell align="left">{moment(pastIssue.issueDateLocal).format("MMM D, YYYY")}</TableCell>
                              <TableCell align="center" colSpan={4}>Skipped</TableCell>
                              <TableCell align="right"></TableCell>
                            </TableRow>
                          );

                        return (
                          <TableRow key={index}>
                            <TableCell align="left">{moment(pastIssue.issueDateLocal,"YYYY-MM-DD").format("MMM D, YYYY")}</TableCell>
                            <TableCell align="center">{numeral(pastIssue.stats.Delivered).format('0,0')}</TableCell>
                            <TableCell align="center">{numeral(pastIssue.stats.Bounced + pastIssue.stats.Dropped).format('0,0')}</TableCell>
                            <TableCell align="center">{numeral(pastIssue.stats.UniqueOpened).format('0,0')}</TableCell>
                            <TableCell align="center">{numeral(pastIssue.stats.UniqueClicked).format('0,0')}</TableCell>
                            <TableCell align="right">
                              <Button
                                variant="text"
                                color="primary"
                                onClick={() => {
                                  props.onViewInsightsClicked(pastIssue.issueDateLocal);
                                }}
                              >
                                View Insights
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                {pastIssues.currentPage > 0 && pastIssues.totalPages > 1 &&
                  <TablePagination
                    rowsPerPageOptions={[10]}
                    component="div"
                    backIconButtonProps={{ color: "primary" }}
                    nextIconButtonProps={{ color: "primary" }}
                    count={pastIssues.totalItems}
                    rowsPerPage={10}
                    page={pastIssues.currentPage - 1}
                    onPageChange={(ev, pageNumber) => {
                      onGetPastIssues(pageNumber + 1);
                    }}
                  />
                }
              </div>
            )
      }
    </div>
  );
}

interface ComponentProps {
  pastIssues?: NewsletterPastIssuesPage;
  isLoading: boolean;
  onGetPastIssues: (pageNumber: number) => any;
  onViewInsightsClicked: (issueDateLocal: string) => any;
}

export default PastIssues;
