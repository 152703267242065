const TIME_UNITS = {
    hours: {
        requiredLength: 3,
    },
    minutes: {
        requiredLength: 2,
    },
    seconds: {
        requiredLength: 1,
    }
};

/**
 * Convert a timer string value, ex. 2:11:02, to a nice string, ex. 2 hrs 11 mins 2 sec
 * @param timerValue
 */
export const getNiceTimerString = (timerValue: string): string => {
    if (!timerValue) return "";

    let separated = timerValue.split(":");

    if (separated.length > 3){
        throw new Error("I don't know how to do more time units");
    }

    let hours: string = "", minutes: string = "", seconds: string = "";

    if (separated.length >= TIME_UNITS.hours.requiredLength) {
        hours = separated[0];
        let hourNum = Number(hours);

        if (hourNum > 0)
            hours = `${hourNum} ${hourNum === 1 ? "hr" : "hrs"}`;
        else
            hours = "";
    }

    if (separated.length >= TIME_UNITS.minutes.requiredLength) {
        minutes = separated[separated.length - 2];
        let minutesNum = Number(minutes);

        if (minutesNum > 0)
            minutes = `${minutesNum} ${minutesNum === 1 ? "min" : "mins"}`;
        else
            minutes = "";
    }

    if (separated.length >= TIME_UNITS.seconds.requiredLength) {
        seconds = separated[separated.length - 1];
        let secondsNum = Number(seconds);

        if (secondsNum > 0)
            seconds = `${secondsNum} sec`;
        else
            seconds = "";
    }

    return `${hours ? `${hours} `: ""}${minutes ? `${minutes} `: ""}${seconds ? `${seconds} ` : ""}`;
}

export const getPercentString = (total: number, value: number) => {
    if (total === 0) return "0%";

    return `${Math.round((value / total) * 100)}%`;
}

export const truncateStringWithElipsis = (source: string, size: number) => {
    return source.length > size ? source.slice(0, size - 1) + "…" : source;
};
