import React from "react";

import { Editor } from "@tinymce/tinymce-react";
import { Editor as IEditor } from "tinymce";

import { acceptableLanguages, spellcheckerRpcUrl } from 'utils/spellcheckLanguages';
import { ConnectedProps, connect } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import * as actions from 'modules/posts/actionCreator';

interface IBasicTinyMceProps {
    placeholder?: string;
    editorRef: React.MutableRefObject<IEditor | null>;
    gifRef: React.MutableRefObject<any>;
    disabled?: boolean;
    value?: string;
    id: string;
    isGifsVisible: boolean;
    onChangeIsGifsVisible: (value: boolean) => void;
    onChange: (newText: string, event: IEditor) => void;
    initializeCallback?: () => void;
}

/**
 * A tiny mce text box with basic plugins
 */
const BasicTinyMceComponent: React.FunctionComponent<PropsWithRedux> = ({
    placeholder,
    commentMaxLength,
    editorRef,
    gifRef,
    defaultLcid,
    tenantGifsEnabled,
    value,
    disabled,
    id,
    isGifsVisible,
    onChangeIsGifsVisible,
    grabMentionUsers,
    onChange,
    initializeCallback
}) => {
    const setup = (editor: IEditor) => {
        editor.on("init", (e) =>{
            editorRef.current = editor;
            if(initializeCallback) 
                initializeCallback();
        });

        editor.options.register('tenor_visible', {
            processor: 'boolean',
            default: isGifsVisible
        });

        if (tenantGifsEnabled) {
            editor.ui.registry.addToggleButton('gifs', {
                icon: 'embed',
                text: 'GIF',
                onAction: function (api) {
                    gifRef.current = api;
                    api.setActive(!api.isActive())
                    editor.options.set('tenor_visible', !editor.options.get('tenor_visible'))

                    onChangeIsGifsVisible(editor.options.get('tenor_visible'));
                }
            });
        }

        const onAction = function (autocompleteApi, rng, value) {
            editor.selection.setRng(rng);
            editor.insertContent(`<mark data-user-id={${value.id}} style="background-color: transparent; color: #3B78AB;">@${value.name}</mark> `);
            autocompleteApi.hide();
        }

        editor.ui.registry.addAutocompleter('specialchars', {
            ch: '@',
            minChars: 0,
            columns: 1,
            highlightOn: ['name_text', 'email_text'],
            onAction: onAction,
            fetch: async function (pattern) {
                let results = await grabMentionUsers(pattern);

                const userData = results.map(u => ({
                    fullName: (u.firstName === "" ? "[Unknown]" : u.firstName + ' ' + u.lastName),
                    email: u.email, id: u.id, color: u.color, firstName: u.firstName, lastName: u.lastName, initials: u.firstName === "" || u.lastName === "" ? "Un" : u.firstName[0] + u.lastName[0]
                }));

                return userData.map(function (char) {
                    let colorForAvatar = char.color;
                    if (char.color !== null && char.color !== "")
                        colorForAvatar = colorForAvatar.substr(1);

                    return {
                        type: 'cardmenuitem',
                        value: ({ id: char.id, name: char.fullName === "[Unknown]" ? char.email : char.fullName }),
                        label: char.fullName,
                        items: [
                            {
                                type: 'cardcontainer',
                                direction: 'horizontal',
                                items: [
                                    {
                                        type: 'cardtext',
                                        text: char.initials,
                                        name: 'name_image',
                                        classes: ['mentions-icon-formatting', 'avatar-color-' + colorForAvatar]
                                    },
                                    {
                                        type: 'cardcontainer',
                                        direction: 'vertical',
                                        items: [
                                            {
                                                type: 'cardtext',
                                                text: char.fullName,
                                                name: 'name_text'
                                            },
                                            {
                                                type: 'cardtext',
                                                text: char.email,
                                                name: 'email_text',
                                                classes: ['mentions-email-formatting']
                                            }
                                        ]
                                    }]
                            }
                        ]
                    }
                }).slice(0, 6);
            }
        });
    }

    let spellcheckEnabled = true;
    let spellcheckLanguage = defaultLcid.substring(0, 2)?.toLocaleLowerCase() ?? "en";

    if (!acceptableLanguages.includes(spellcheckLanguage.toLocaleLowerCase())) {
        spellcheckLanguage = "en";
        spellcheckEnabled = false;
    }

    return (
        <Editor
            init={{
                object_resizing: false,
                image_title: true,
                menubar: false,
                branding: false,
                statusbar: false,
                paste_block_drop: true,
                powerpaste_block_drop: true,
                commentMaxLength: commentMaxLength,
                force_br_newlines: true,
                paste_as_text: true,
                placeholder: placeholder,
                tenorvisible: isGifsVisible,
                plugins: ['tinymcespellchecker', 'powerpaste'],
                noneditable_noneditable_class: 'mceNonEditable',
                toolbar: "bold italic underline | gifs",
                spellchecker_rpc_url: spellcheckerRpcUrl,
                spellchecker_language: spellcheckLanguage,
                spellchecker_active: spellcheckEnabled,
                value: value,
                width: '100%',
                height: '210px',
                paddingtop: '10px',
                content_style: "body { min-height: 100px; font-family: Roboto, Helvetica, Arial, sans-serif; }" +
                    ".mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before { color: #aaaaaa }",
                setup,
            }}
            id={id}
            value={value}
            onEditorChange={onChange}
            disabled={disabled}
        />
    );
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: IBasicTinyMceProps) => ({
        ...ownProps,
        commentMaxLength: state.settings.clientSettings.commentSystem.commentMaxLength,
        defaultLcid: state.settings.tenantSettings.defaultLCID,
        tenantGifsEnabled: state.settings.tenantSettings.gifSelectionEnabledForComments
    }),
    {
        grabMentionUsers: actions.getMentionsUsers
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;
export const BasicTinyMce = connector(BasicTinyMceComponent);
