export const utils = {
    join: (base: string, path: string) => {
        base = base.lastIndexOf('/') === base.length - 1 ?
            base.substr(0, base.lastIndexOf('/')) : base;

        path = path.indexOf('/') === 0 ?
            path.substr(1) : path;

        return `${base}/${path}`;
    },

    getWebAppUrl: (url?: string) => {
        const urlOrDefault = url || window.location.href;
        return urlOrDefault.indexOf("/",8) > 0 ? urlOrDefault.substring(0, urlOrDefault.indexOf("/",8)) : urlOrDefault;
    }
}
