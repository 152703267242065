import * as React from "react";
import { ConnectedProps, connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router";
import { GlobalApplicationState } from "globalApplicationState";


const ProfileBanner: React.FunctionComponent<PropsWithRedux> = props => {
  const { userSettings } = props;

  if (!userSettings)
    return <React.Fragment></React.Fragment>;
  
  return (
    <div className="profile-banner"></div>
  );
}

interface ComponentProps {}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps & RouteComponentProps) => ({
    ...ownProps,
    userSettings: state.profile.userSettings
  })
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default withRouter(connector(ProfileBanner));