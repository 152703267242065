import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import * as Actions from 'modules/posts/actionCreator';
import { GlobalApplicationState } from 'globalApplicationState';
import Loading from 'modules/common/components/loading';
import InfiniteScrollView from 'modules/common/components/table-view/components/infinite-scroll-view';
import {injectIntl, IntlShape} from "react-intl";
import { Image } from "modules/gallery/models";
import { mediaApi } from 'api/instances';

import './imageSelector.sass';

class ImageSelector extends React.Component<PropsWithRedux, ComponentState> {

    private videoRef: HTMLVideoElement;

    constructor(props){
        super(props);
        this.state = {
            videoPreviewUrl: null,
            videoId: null
        };
    }
    
    public componentDidMount() {
        let secondsSinceLastFetch = (new Date().getTime() - this.props.imageLibrary.lastFetched!) / 1000;
        if (this.props.imageLibrary.shouldFetch || secondsSinceLastFetch > 15 * 60) {
            this.props.fetchImages(true);
        }
    }

    public componentWillUpdate(nextProps) {
        if (!this.props.previewImage && nextProps.previewImage){
            document.querySelector("body")!.setAttribute("style", "overflow: hidden")
        }
    }

    public render() {
        let imageList = (
            this.props.imageLibrary && this.props.imageLibrary.images && this.props.imageLibrary.images.length > 0
            ? this.props.imageLibrary.images.map(image => {
                let className = ((this.props.selectedImageIds || []).indexOf(image.id) > -1 ? 'library-image active' : 'library-image');
                return (
                    <div
                        key={image.id}
                        className={className}
                        onClick={() => {
                            if (this.props.selectedImageIds!.indexOf(image.id) < 0) {
                                this.props.imageSelected(image)
                            } else {
                                this.props.imageDeselected(image)
                            }
                        }}
                        >
                        {
                            this.props.showPreviews &&
                            <i className="preview-icon material-icons" 
                            onClick={(e) => {
                                e.stopPropagation(); 
                                this.props.onPreview(image);
                            }}>pageview</i>
                        }
                        <div className="img" style={{ backgroundImage: `url(${image.url})`}}></div>
                        
                            {
                                image.videoId && this.props.showVideoIcons &&
                                <div className="selector-overlay">
                                    <i className="material-icons" onClick={(e) => e.stopPropagation()}>play_circle_outline</i>
                                </div>
                            }
                        
                    </div>
                );
            })
            : !this.props.imageLibrary.fetching &&
            <h3>{this.props.intl.formatMessage({id: "posts.noImagesFound", defaultMessage: "No images found."})}</h3>
        )

        let loadMoreFallback = (
            <div>
                <p style={{textAlign: "center"}}>
                    {
                        this.props.imageLibrary.fetching ?
                        <span>{this.props.intl.formatMessage({id: "common.loading"})}...</span>
                        :
                        <button className="load-fallback" onClick={() => this.props.fetchImages(true)}>
                            {this.props.intl.formatMessage({id: "common.loadMore"})}
                        </button>
                    }
                </p>
            </div>
        );

        let hasMoreImagesToFetch = this.props.imageLibrary.images.length > 0
                                && this.props.imageLibrary.continuationToken != null;

        return (
            <div className="image-selector-tab">
                <InfiniteScrollView
                    distanceThreshold={140}
                    usesWindowScroll={this.props.forGallery? true : false}
                    loading={this.props.imageLibrary.fetching}
                    loadingBlockRenderer={() => <Loading />}
                    loadingBlockFallbackRenderer={() => loadMoreFallback}
                    onThresholdCrossed={() => this.props.fetchImages(true)}
                    hasNext={hasMoreImagesToFetch} >
                    <div className={this.props.forGallery ? "image-selector-container-gallery" : "image-selector-container"} >
                        { imageList }
                    </div>
                </InfiniteScrollView>
                {
                    this.props.showPreviews && this.props.previewImage &&
                    <div className="image-preview" >
                        <i className="material-icons" onClick={() => {
                            this.props.onPreview(null);
                            this.setState({
                                videoId: null,
                                videoPreviewUrl: null
                            })
                            document.querySelector("body")!.setAttribute("style", "");
                        }}>close</i>
                        {
                            this.props.previewImage.videoId
                            ? this.showVideo(this.props.previewImage)
                            : <img className="img" src={this.props.previewImage.url} alt="preview" />
                        }
                    </div>
                }
            </div>
        );
    }

    private initVideoPlayer() {
        if (this.videoRef) {
            const videojs = (window as any).videojs;
            const self = this;
            const player = videojs(this.videoRef, {});
            player.ready(() => {
                player.src(self.state.videoPreviewUrl);
            })
            .on('ended', () => {
                player.pause().currentTime(0).trigger('loadstart');
            })
            .on('pause', () => {
                player.bigPlayButton.el_.style.display = 'block';
            })
            .on('play', () => {
                player.bigPlayButton.el_.style.display = 'none';
            });
        }
    }

    private showVideo = (previewImage: Image) => {
        if(previewImage.videoId && previewImage.videoId !== this.state.videoId){
            
            mediaApi.GetVideoUrl(previewImage.videoId)
            .then(response => {
                this.setState({
                    videoId: previewImage.videoId, 
                    videoPreviewUrl: response
                }, this.initVideoPlayer)
            })
        }
        return <video
            className="video-js vjs-default-skin post-video-primary vjs-16-9 vjs-big-play-centered"
            ref={(e) => this.videoRef = e!}
            crossOrigin="anonymous"
            controls
            preload="metadata"
            style={{ backgroundColor: "black" }}
            poster={previewImage.url}
            />
    }
}

interface ComponentProps {
    selectedImageIds?: string[]
    imageSelected: (image: any) => void
    imageDeselected: (image: any) => void
    intl: IntlShape;
    showPreviews?: boolean;
    previewImage?: Image | null;
    onPreview?: any;
    showVideoIcons?: boolean;
    forGallery?: boolean;
}

interface ComponentState {
    videoPreviewUrl?: string | null;
    videoId?: string | null;
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps)=>({
        ...ownProps,
        imageLibrary: state.posts.imageLibrary,
    }), 
    {
        fetchImages: Actions.fetchImages
    }
);
  type PropsWithRedux = ConnectedProps<typeof connector>;

export default injectIntl(connector(ImageSelector));
