import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { push } from "react-router-redux";
import * as actions from "../../actionCreator";
import { GlobalApplicationState } from "globalApplicationState";

import { Document } from "../../models";

import MoreOptionsIconButton from "modules/common/components/buttons/moreOptionsIconButton";

import confirm from "utils/notyPopups";

import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import MoreOptionsItem from "modules/common/components/moreOptionsItem";


const MoreDocumentOptions: React.FunctionComponent<PropsWithRedux> = props => {
  const { document } = props;

  return (
    <MoreOptionsIconButton>
      <List disablePadding>
        <MoreOptionsItem
          text="Download file"
          onClick={() => {
            props.onDownloadDocument();
          }}
        />
        <MoreOptionsItem
          text="Edit/replace document"
          onClick={() => {
            props.showEditor(document);
          }}
        />
        {(document.fileType === "jpeg" || document.fileType === "jpg" || document.fileType === "pdf" || document.fileType === "png" || document.fileType === "txt") &&
          <React.Fragment>
            <Divider light />
            <MoreOptionsItem
              text="Preview"
              onClick={() => {
                props.onPreviewDocument();
                props.onSelection();
              }}
            />
          </React.Fragment>
        }
        <Divider light />
        {document.state === "enabled" &&
          <MoreOptionsItem
            text="Disable"
            onClick={() => {
              props.disableDocument(document.id);
              props.onSelection();
            }}
          />
        }
        {document.state !== "enabled" &&
          <MoreOptionsItem
            text="Enable"
            onClick={() => {
              props.enableDocument(document.id);
              props.onSelection();
            }}
          />
        }
        <MoreOptionsItem
          text="Delete"
          textStyle={{color: "#B72026"}}
          onClick={async () => {
            let functionalTitle = document.title === "" ? `${document.fileName}.${document.fileType}` : document.title;
            if (await confirm.show({ text: `You're about to permanently delete '${functionalTitle}'. You cannot undo this action. Are you sure?`,
            title: "Delete Document",
            yesColor: "#a80000",
            yesText: "Delete",
            noText: "Cancel" })) {
              props.deleteDocument(document.id);
              props.onSelection();
            }
          }}
        />
      </List>
    </MoreOptionsIconButton>
  );
}

interface ComponentProps {
  document: Document;
  onDownloadDocument: () => void;
  onPreviewDocument: () => void;
  onSelection: () => void;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    tenantId: state.tenant.id
  }),
  {
    deleteDocument: actions.deleteDocument,
    disableDocument: actions.disableDocument,
    enableDocument: actions.enableDocument,
    redirectTo: push,
    showEditor: actions.showEditor
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(MoreDocumentOptions);