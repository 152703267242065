import * as React from 'react';
import { GlobalApplicationState } from "globalApplicationState";
import { ConnectedProps, connect } from "react-redux";
import { actions } from '..';
import { Drawer, IconButton } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import ArrowBack from '@mui/icons-material/ArrowBack';
import MobileTextAreaInput from './mobileTextAreaInput';
import TextBubble from 'modules/common/components/textBubble';
import { Tooltip } from '@mui/material';
import WarningLabel from './warningLabel'

interface TextBubble {
    id: string;
    name: string;
}

interface ComponentProps { 
    updateToggle: () => any
    eventPrompt: string
    event: string
    bubbles: TextBubble[]
}

interface ComponentState {
    isOpen: boolean;
}

class MobileNotificationPopout extends React.Component<PropsWithRedux, ComponentState> {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: true,
        }
    }

    itemDisplay = () => {
        const LightTooltip = withStyles((theme) => ({
            tooltip: {
                backgroundColor: '#f5f5f9',
                color: 'rgba(0, 0, 0, 0.87)',
                boxShadow: theme.shadows[1],
                fontSize: "12px"
            }
        }))(Tooltip);

        const eventToCheck = this.props.event + "MobileAttempt";
        
        return (
            <div className={"full-list"}>
                <div className="top-options">
                    <IconButton onClick={this.toggleDrawer(false)} size="large">
                        <ArrowBack/>
                    </IconButton>
                    <span className="top-text">{"More "+this.props.eventPrompt+" Settings"}</span> 
                </div>
                <div className="drawer-line-break">
                    <hr className="separator-line-popout"/>
                </div>

                <h4>{"Edit Notification Contents"}</h4>
                <span className="description-text">Change the content of your notifications. Use suggestions to personalize your content.  </span>
                <LightTooltip title="You can edit and personalize the content of the notifications you send out, which include the subject in email notifications, the title and body of mobile app notifications, and the text message of SMS notifications.">
                    <ins className="description-text">Learn More.</ins>
                </LightTooltip>

                <div className="override-titles">
                    <span>Mobile App Notification: Title &amp; Description</span>
                </div>

                {!(this.props.notificationSettings.settings[eventToCheck].toggle) && 
                    <WarningLabel platform="Mobile"/>
                }

                <MobileTextAreaInput 
                    placeholderTop = {this.props.pushContents[this.eventUppercase(eventToCheck)].m_Item1.toString()}
                    placeholderBot = {this.props.pushContents[this.eventUppercase(eventToCheck)].m_Item2.toString()}
                    event={eventToCheck}
                    returnTranslatedContent={this.updatePushContent}
                    />

                <div className="word-bubble-formatting">
                    <p className="suggestions-section-text">Use suggestions to personalize: </p>
                    <TextBubble list={this.props.bubbles} />
                </div>
            </div>
        );
    }

    private toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        
        this.props.updateToggle();
        this.setState({...this.state, isOpen: open});
    }

    private updatePushContent = (translatedContent: any) => {
        const toChange = this.props.event + "MobileAttempt"
        this.props.setPushContent(toChange, translatedContent);
    }

    private eventUppercase = (eventToFix: string) => {
        if(eventToFix !== null && eventToFix.length > 2)
            return eventToFix[0].toUpperCase() + eventToFix.slice(1);
        else
            return "Invalid string to fix."
    }

    public render() { 
        return (
            <Drawer
            anchor={'right'}
            open={this.state.isOpen}
            onClose={this.toggleDrawer(false)}
            >
                {this.itemDisplay()}

            </Drawer>
         );
    }
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        notificationSettings: state.settings.notificationSettings,
        pushContents: state.resources.pushContentsResource
    }),
    {
        setPushContent: actions.SetMobileContent
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default (connector(MobileNotificationPopout));