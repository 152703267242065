import * as actionCreator from "./actionCreator";
import * as reducer from "./reducer";

export const actions = {
  ...actionCreator
}

export const reducers = {
  audiences: reducer.reducer
}

export * from "./actions";
export * from "./models";