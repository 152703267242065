import React, { useState } from "react";
import { MenuItem, FormControlLabel, Checkbox, Select, TextField } from "@mui/material";

import HoverText from "modules/documents/components/action-buttons/hoverText";
import { HomeScreenType, HOME_SCREEN_TYPE_MAP } from "modules/homeScreens/models";
import { InputCounter } from "modules/common/components/forms/inputs/inputCounter";

interface IHomeScreenTypeSelectProps {
    value: HomeScreenType;
    showFeed: boolean;
    publishedBandsCount: number;
    isLoading: boolean;
    feedHeader: string | null;
    onChangeShowFeed: (checked: boolean) => void;
    onChangeFeedHeader: (header: string) => void;
    onChangeScreenType: (newType: HomeScreenType) => void;
}

export const HomeScreenTypeSelect: React.FunctionComponent<IHomeScreenTypeSelectProps> = ({
    value,
    showFeed,
    feedHeader,
    publishedBandsCount,
    isLoading,
    onChangeShowFeed,
    onChangeScreenType,
    onChangeFeedHeader,
}) => {
    const [isHeaderFocused, setIsHeaderFocused] = useState<boolean>(false);
    const isError = value === HomeScreenType.ContentBands && publishedBandsCount === 0;

    return (
        <div className="select-container">
            <Select
                sx={{ height: 44 }}
                value={value}
                onChange={(evt) => onChangeScreenType(evt.target.value as HomeScreenType)}
                className="select"
            >
                <MenuItem value={HomeScreenType.MyFeed}>{HOME_SCREEN_TYPE_MAP[HomeScreenType.MyFeed]}</MenuItem>
                <MenuItem value={HomeScreenType.ContentBands}>{HOME_SCREEN_TYPE_MAP[HomeScreenType.ContentBands]}</MenuItem>
            </Select>
            {value === HomeScreenType.ContentBands && (
                <>
                    <FormControlLabel
                        labelPlacement="end"
                        control={
                            <Checkbox
                                sx={{ fontSize: 14 }}
                                disabled={isError}
                                checked={showFeed}
                                onChange={(evt) => onChangeShowFeed(evt.target.checked)}
                            />
                        }
                        label={<span style={{ fontSize: 14 }}>Show My Feed below content bands</span>}
                    />
                    {showFeed && (
                        <>
                            <FormControlLabel
                                className="feed-control"
                                labelPlacement="start"
                                control={
                                    <TextField
                                        sx={{
                                            fontSize: 14,
                                            width: 295,
                                        }}
                                        placeholder="Ex. Keep reading"
                                        value={feedHeader?.substring(0, 35) || ""}
                                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                                            onChangeFeedHeader(evt.target.value.substring(0, 35))
                                        }
                                        size="small"
                                        inputProps={{
                                            onBlur: () => {
                                                setIsHeaderFocused(false);
                                            },
                                            onFocus: () => {
                                                setIsHeaderFocused(true);
                                            },
                                        }}
                                    />
                                }
                                label={
                                    <HoverText label={<span style={{ fontSize: 14 }}>Header</span>}>
                                        This text appears in between the last band and on top of the posts feed.
                                    </HoverText>
                                }
                            />
                            <InputCounter count={feedHeader?.length || 0} maxCount={35} isFocused={isHeaderFocused} />
                        </>
                    )}
                </>
            )}
            {isError && !isLoading && (
                <span className={`${isError ? "error" : ""}`}>Publish at least 1 content band to use as home screen.</span>
            )}
        </div>
    );
};
