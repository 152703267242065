import * as React from 'react';
import './pageToolBar.sass';

interface FixedOptions {
    fixedAtHeight: number,
}

export interface MenuButton {
    label: string | JSX.Element,
    onClick?: () => void,
    key: string,
    disabled?: boolean

}

interface DefaultButton {
    label: string | JSX.Element,
    key: string
}

interface ComponentProps {
    defaultButtons?: DefaultButton[],
    callToActionButton?: MenuButton,
    refreshButton?: MenuButton,
    rightSideButtons?: MenuButton[],
    overflowButtons?: MenuButton[],
    fixedOptions?: FixedOptions,
}

interface ComponentState {
    toolBarFixed: boolean,
    overflowMenuOpened: boolean
}

export default class PageToolBar extends React.Component<ComponentProps, ComponentState> {

    constructor(props){
        super(props);
        this.state = { toolBarFixed: false, overflowMenuOpened: false };
      }

    public componentDidMount() {
        if (typeof window !== 'undefined' && this.props.fixedOptions) {
            window.addEventListener('scroll', this.windowScrolledHandler);
        }
    }

    public render() {
        let callToAction = ( !this.props.callToActionButton ? null :
            <span className="call-to-action-button" onClick={this.props.callToActionButton.onClick} 
               style={(this.props.callToActionButton.key === "create" || this.props.callToActionButton.key === "newSurvey") ? {'color': '#F4B032'}: {}}>
                {this.props.callToActionButton.label}  
            </span>
        );

        let rightSideButtons = ( !this.props.rightSideButtons || this.props.rightSideButtons.length === 0 ? 
            <div className="left-side context-buttons"> 
                {
                    !!this.props.defaultButtons && this.props.defaultButtons.map(button => {
                        return ( 
                            <span
                            key={button.key}
                            className="button not-active">
                                {button.label}  
                            </span>
                        )
                    })
                }
            </div> :
             <div className="left-side context-buttons"> 
                {
                    this.props.rightSideButtons.map(button => {
                        let labelCheck = button.label.toString();
                        return (
                            <button key={button.key} 
                                className={labelCheck.includes("External") || labelCheck.includes("Compliance") || button.disabled ? "button not-active" : "button"}
                                onClick={button.label.toString().includes("External") || labelCheck.includes("Compliance") || !!button.disabled ? () => {return false;} : button.onClick}>{button.label}</button>
                        )
                    })
                }
            </div>
        );

        let overflowButtons = (
            <div className="overflow context-buttons">
                {
                    !this.props.overflowButtons ? null :
                        this.props.overflowButtons.map(button => {
                            return (
                                <span key={button.key}
                                    className="button"
                                    onClick={button.onClick}>{button.label}</span>
                            )
                        })
                }
            </div>

        );

        let refreshButton = (
            this.props.refreshButton ?
                <div className="refresh-button">
                    <span key={this.props.refreshButton.key}
                        onClick={this.props.refreshButton.onClick}>
                        {this.props.refreshButton.label} <i className="material-icons" style={{verticalAlign: "middle", fontWeight: 'bold', fontSize: '18px'}}>refresh</i> 
                    </span>
                </div>
                : null
        )

        return (
            <div>
                <div className={this.state.toolBarFixed ? "fixed-position" : "relative-position" }>
                    <div className="page-tool-bar">
                        <div className="tool-bar-buttons-wrapper">
                            { callToAction }
                            { rightSideButtons }
                            { overflowButtons }
                            { this.props.refreshButton ? refreshButton : null }
                        </div>
                    </div>
                </div>
                {
                    //prevents content from jumping when transitioning to fixed position since div leaves flow of the page
                    this.state.toolBarFixed &&
                    <div className="relative-position"></div>
                }
            </div>
            
        );
    }

    public componentWillUnmount() {
        window.removeEventListener('click', this.clickedAnywhereOutsideContextMenu);

        if (typeof window !== 'undefined' && this.props.fixedOptions) {
            window.removeEventListener('scroll', this.windowScrolledHandler);
        }
    }

    private windowScrolledHandler = (e) => {
        const fixedHeight = this.props.fixedOptions!.fixedAtHeight;
        const toolBarFixed = fixedHeight && window.pageYOffset >= fixedHeight;
        this.setState({ ...this.state, toolBarFixed:!!toolBarFixed })
    }

    private overflowButtonClicked = (e) => {
       e.stopPropagation();
        if (!this.state.overflowMenuOpened) {
            this.setState({ ...this.state, overflowMenuOpened: true });
            window.addEventListener('click', this.clickedAnywhereOutsideContextMenu);
        } else {
            this.setState({ ...this.state, overflowMenuOpened: false });
        }
    }

    private overflowMenuClicked = (e) => {
        e.stopPropagation();
    }

    private clickedAnywhereOutsideContextMenu = (e) => {
        this.setState({
            ...this.state,
            overflowMenuOpened: false
        })

        window.removeEventListener('click', this.clickedAnywhereOutsideContextMenu);
    }
}
