import { DocumentFileType } from "../models";

export const getMimeType = (fileType: DocumentFileType): string | "" => {
  if (fileType === "docx")
    return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
  if (fileType === "jpeg" || fileType === "jpg")
    return "image/jpeg";
  if (fileType === "pdf")
    return "application/pdf";
  if (fileType === "png")
    return "image/png";
  if (fileType === "pptx")
    return "application/vnd.openxmlformats-officedocument.presentationml.presentation";
  if (fileType === "txt")
    return "text/plain";
  if (fileType === "xlsx")
    return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  return "";
}

export const getStringFromMimeType = (fileType: string) => {
  if (fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document")
    return "docx";
  if (fileType === "image/jpeg")
    return "jpeg";
  if (fileType === "application/pdf")
    return "pdf";
  if (fileType === "image/png")
    return "png";
  if (fileType === "application/vnd.openxmlformats-officedocument.presentationml.presentation")
    return "pptx";
  if (fileType === "text/plain")
    return "txt";
  if (fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
    return "xlsx";
  return "";
}