/* Logic in the original settingsToggleWithBlackout.tsx was a little too hardwired to include SMS stuff. This way we can have an entirely separate component for when we only have 3 channels. */

import React, { useState } from 'react';
import { ConnectedProps, connect } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import ToggleSwitch from 'modules/common/components/toggleSwitch';
import {actions} from '..'

interface ComponentProps {
    title: string; 
    teamsEvent: string;
    mobileEvent: string;
    emailEvent: string;
    description?: string;
    id?: string;
    handleOnClick?: () => void;
}

const SettingsToggleWithBlackoutNoSms: React.FC<PropsWithRedux> = 
    ({
        title, 
        teamsEvent, 
        mobileEvent, 
        emailEvent, 
        description, 
        notificationSettings, 
        toggleBlackoutPeriod, 
        enableAllChannels, 
        setSelectedChannel, 
        eventsWithBlackout, 
        id, 
        handleOnClick
    }) => {
    
    const settings = notificationSettings.settings!;
    const anyChannelChecked = settings[emailEvent].toggle || settings[teamsEvent].toggle || settings[mobileEvent].toggle;
    const blackoutApplied =  eventsWithBlackout.includes(emailEvent) || eventsWithBlackout.includes(teamsEvent) || eventsWithBlackout.includes(mobileEvent);
    const allChannelsChecked = settings[emailEvent].toggle && settings[teamsEvent].toggle && settings[mobileEvent].toggle;
    const [allSelected, setSelectedChannelsChecked] = useState(allChannelsChecked);
    const eventId = (id ?? "");
    
    const updateCheckboxes = (event: string, platform: string, toSet: boolean) => {
        let emailStatus = platform === "email" ? toSet : settings[emailEvent].toggle;
        let teamsStatus = platform === "teams" ? toSet : settings[teamsEvent].toggle;
        let mobileStatus = platform === "mobile" ? toSet : settings[mobileEvent].toggle;
    
        if(!(emailStatus || teamsStatus || mobileStatus))
        {
            enableAllChannels(teamsEvent, mobileEvent, emailEvent, "")
            setSelectedChannelsChecked(true)
        }
        else
        {
            setSelectedChannel(event, toSet);
        }
    }

    return (
        <div>
            <div className="clearfix">
                <div style={{float:"left", display: "inline-block", width: "1000px", fontWeight: "bold"}}>{title} 
                    <span style={{float:"right"}}>
                        <ToggleSwitch 
                            value={anyChannelChecked}
                            onClick={() => {
                                if(!anyChannelChecked) {
                                    setSelectedChannel(emailEvent, true);
                                    setSelectedChannel(mobileEvent, true);
                                    setSelectedChannel(teamsEvent, true);
                                }
                                else {
                                    setSelectedChannel(emailEvent, false);
                                    setSelectedChannel(mobileEvent, false);
                                    setSelectedChannel(teamsEvent, false);
                                }
                            }}      
                        />
                    </span>
                </div>
            </div>
            { anyChannelChecked &&
            <div>
                <div style={{margin: "20px 0px"}} className="description-text">{description} <span className={"pointer-text"} id={id} onClick={handleOnClick}>Open More Notification Settings</span></div>
            
                <div className="settings-checkbox-section">
                    <div className="settings-checkbox-row">
                        <input style={{cursor: "pointer"}} type="checkbox" id={"applyBlackoutPeriod"+eventId}
                            checked={blackoutApplied} 
                            onChange={() => toggleBlackoutPeriod(teamsEvent, mobileEvent, emailEvent, "", !blackoutApplied)}>
                        </input>
                        <label className="input-label-text" htmlFor={"applyBlackoutPeriod"+eventId}>Apply blackout period to notifications</label>
                    </div>
                    
                    <div className="settings-checkbox-radio">
                        <input style={{marginLeft: 20, cursor: "pointer"}}
                            type="radio" id={"enableAllChannels"+eventId}
                            name={title+eventId} 
                            checked={allSelected && allChannelsChecked}
                            onChange={() => {
                                updateCheckboxes(emailEvent, "email", true);
                                updateCheckboxes(mobileEvent, "mobile", true);
                                updateCheckboxes(teamsEvent, "teams", true);

                                setSelectedChannelsChecked(true);
                                }}/>
                        <label className="input-label-text" htmlFor={"enableAllChannels"+eventId}>Enable all channels</label>
                    </div>

                    <div className="settings-checkbox-radio">
                        <input 
                            style={{marginLeft: 20, cursor: "pointer"}} 
                            type="radio" 
                            id={"enableSelectedChannels"+eventId}
                            name={title+eventId}
                            checked={!allSelected || !allChannelsChecked}
                            onChange={() => {setSelectedChannelsChecked(false)}}/>
                    <label className="input-label-text" htmlFor={"enableSelectedChannels"+eventId}>Enable notifications for selected channels:</label></div>

                    { (!allSelected || !allChannelsChecked) &&
                    <div>
                        <div className="settings-checkbox-channel">
                            <input 
                                style={{marginLeft: 40, cursor: "pointer"}} 
                                type="checkbox" id={"enableTeamsChannel"+eventId}
                                checked={settings[teamsEvent].toggle}
                                onChange={() => updateCheckboxes(teamsEvent, "teams", !settings[teamsEvent].toggle)}>
                            </input><label className="input-label-text" htmlFor={"enableTeamsChannel"+eventId}>MS Teams Chatbot</label>
                        </div>
                        <div className="settings-checkbox-channel">
                            <input 
                                style={{marginLeft: 40, cursor: "pointer"}} 
                                type="checkbox" id={"enableMobileChannel"+eventId}
                                checked={settings[mobileEvent].toggle}
                                onChange={() => updateCheckboxes(mobileEvent, "mobile", !settings[mobileEvent].toggle)}>
                            </input><label className="input-label-text" htmlFor={"enableMobileChannel"+eventId}>Mobile App Notification</label>
                        </div>
                        <div className="settings-checkbox-channel">
                            <input 
                                style={{marginLeft: 40, cursor: "pointer"}} 
                                type="checkbox" id={"enableEmailChannel"+eventId}
                                checked={settings[emailEvent].toggle}
                                onChange={() => updateCheckboxes(emailEvent, "email", !settings[emailEvent].toggle)}>
                            </input><label className="input-label-text" htmlFor={"enableEmailChannel"+eventId}>Enable Email</label>
                        </div>
                    </div>
                    }
                </div>
            </div>
            }
        </div>
    )

}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        notificationSettings: state.settings.notificationSettings,
        eventsWithBlackout: state.settings.applyBlackoutPeriodsTo,
    }),
    {
        toggleBlackoutPeriod: actions.ApplyBlackoutPeriod,
        enableAllChannels: actions.EnableAllChannels,
        setSelectedChannel: actions.EnableSelectedChannel,
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default (connector(SettingsToggleWithBlackoutNoSms));