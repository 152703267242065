import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import * as actions from "modules/portalPages/actionCreator";
import * as audiencesActions from "modules/audiences/actionCreator";
import { GlobalApplicationState } from "globalApplicationState";

import { PortalPage } from "modules/portalPages/models";

import ErrorSnackbar from "modules/common/components/snackbars/errorSnackbar";
import LoadingOverlay from "modules/common/components/loadingOverlay";

import { banner_fields } from "./components/banner";
import { one_column_template, two_columns_template, three_columns_template } from "./components/section";
import BackToManagePortalPagesDialog from "./dialogs/backToManagePortalPagesDialog";
import GoToNavigationItemsDialog from "./dialogs/goToNavigationItemsDialog";
import PublishChangesDialog from "./dialogs/publishChangesDialog";
import PublishDialog from "./dialogs/publishDialog";
import Page from "./page";

import { useCMS, useForm, usePlugin } from "tinacms";


const Editor: React.FunctionComponent<PropsWithRedux> = props => {
  const [showPublishDialog, setShowPublishDialog] = useState(false);

  useEffect(() => {
    if (!props.audiences.length) {
      props.getAudiences();
    }
  }, [props]);

  useCMS();
  
  const pageConfig = {
    id: "page-config",
    label: "Edit portal page",
    fields: [
      {
        name: "command_bar",
        page: props.page,
        component: "command-bar"
      },
      {
        name: "command_bar_hr",
        component: "hr"
      },
      {
        name: "page_details_label",
        label: "Page details",
        component: "section-label"
      },
      {
        name: "page_details",
        page: props.page,
        component: "page-details"
      },
      {
        name: "page_details_hr",
        component: "hr"
      },
      {
        name: "navigation",
        label: "Navigation Bar",
        component: "group",
        fields: [
          {
            name: "enabled",
            label: "Enable navigation bar",
            component: "mui-toggle"
          },
          {
            name: "edit_navigation_items",
            label: "Edit Navigation Items",
            component: "edit-navigation-items"
          }
        ]
      },
      {
        name: "navigation_bar_hr",
        component: "hr"
      },
      {
        name: "header",
        label: "Header",
        component: "group",
        fields: [
          {
            name: "enabled",
            label: "Enable header",
            component: "mui-toggle"
          },
          {
            name: "enabled_hr",
            component: "hr"
          }
        ].concat(banner_fields as any[])
      },
      {
        name: "header-hr",
        component: "hr"
      },
      {
        name: "page_sections",
        label: "Page Sections",
        component: "blocks",
        templates: {
          "one_column": one_column_template,
          "two_column": two_columns_template,
          "three_column": three_columns_template
        }
      }
    ],
    initialValues: props.page.portalPageJson[0],
    onChange: () => {
      props.hasChangedSinceSaved();
    },
    buttons: {
      save: "Save",
      reset: "Reset"
    },
    onSubmit: async (data) => {
      props.saveDraft(props.page.draftId, data).then((succeeded) => {
        if (succeeded) {
          props.clearChangedSinceSaved();
          setShowPublishDialog(true);
        }
      });
    },
    reset: () => {
      props.clearChangedSinceSaved();
    }
  };

  const [data, form] = useForm(pageConfig, { fields: pageConfig.fields });

  usePlugin(form);

  return (
    <React.Fragment>
      <Page data={data} isInEditMode />
      <BackToManagePortalPagesDialog draftId={props.page.draftId} data={data} />
      <GoToNavigationItemsDialog draftId={props.page.draftId} data={data} />
      <PublishChangesDialog page={props.page} data={data} />
      <PublishDialog show={showPublishDialog} page={props.page} onClose={() => setShowPublishDialog(false)} />
      <ErrorSnackbar errorMessage={props.errorMessage} clearErrorMessage={props.clearErrorMessage} />
      <LoadingOverlay absolute={true} show={props.isFetchingImage || props.isSaving} />
    </React.Fragment>
  );
}

interface ComponentProps {
  page: PortalPage;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    audiences: state.audiences.audiences,
    errorMessage: state.portalPages.errorMessage,
    isFetchingImage: state.gallery.isFetching,
    isSaving: state.portalPages.isSaving,
    showModifyPortalPageDetails: state.portalPages.showModifyPortalPageDetails,
    showModifyPortalPageTitle: state.portalPages.showModifyPortalPageTitle
  }),
  {
    clearChangedSinceSaved: actions.clearChangedSinceSaved,
    clearErrorMessage: actions.clearErrorMessage,
    getAudiences: audiencesActions.getAudiences,
    hasChangedSinceSaved: actions.hasChangedSinceSaved,
    saveDraft: actions.saveDraft
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(Editor);