import * as React from "react";
import { 
  NewsletterTemplate, 
  SaveNewsletterModel, 
  SaveNewsletterModelStateErrors, 
  NewsletterTheme, 
  NewsletterImageType 
} from "modules/newsletters/models"
import Loading from "modules/common/components/loading";
import { NewsletterTemplateImageType } from "modules/newsletters";
import TemplateWithPopup from "./templateWithPopup";

class TemplateSection extends React.Component<ComponentProps, {}> {
  public render() {
    const { newsletter, templates, themes } = this.props;
    if(!newsletter || !templates || !themes)
      return <Loading/>;

    const selectedTemplate = templates.find(t => t.id === newsletter.emailTemplateId);
    if(!selectedTemplate)
      return <Loading/>;

    return (
      <div className="newsletter-page">
        <TemplateWithPopup 
          firstTime={this.props.firstTime} 
          setFirstTime={this.props.setFirstTime} 
          newsletter={newsletter} 
          onNewsletterChange={this.props.onNewsletterChange} 
          templates={templates} 
          selectedTemplate={selectedTemplate} 
          modelErrors={this.props.modelErrors} 
          onDownloadTemplateHtml={this.props.onDownloadTemplateHtml} 
          onDownloadTemplateImage={this.props.onDownloadTemplateImage} 
          getTemplateImage={this.props.getTemplateImage} 
          setLoadImage={this.props.setLoadImage}
          updateSavedTheme={this.props.updateSavedTheme}
          themes={themes} >
        </TemplateWithPopup>
      </div>
    );
  }
}

interface ComponentProps {
  newsletter: SaveNewsletterModel;
  templates: NewsletterTemplate[];
  themes: NewsletterTheme[];
  modelErrors: SaveNewsletterModelStateErrors | null;
  footerImage?: File | null;
  headerImage?: File | null;
  isLoadingFooterImage?: boolean;
  isLoadingHeaderImage?: boolean;
  firstTime: boolean;
  setFirstTime: (value: boolean) => void;
  onNewsletterChange:  (value: Partial<SaveNewsletterModel>) => void;
  onDownloadTemplateHtml: (template: NewsletterTemplate) => any;
  onDownloadTemplateImage: (template: NewsletterTemplate, image: NewsletterTemplateImageType) => any;
  getTemplateImage: (templateId: string, image: NewsletterTemplateImageType) => Promise<string>;
  setImage: (imageType: string, image?: File) => void;
  setLoadImage: (type: NewsletterImageType, toSet: File) => void;
  updateSavedTheme: (toSet: NewsletterTheme) => void;
  onError: (message:string) => any;
}

export default TemplateSection;