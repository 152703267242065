import ConfirmDialog from 'modules/common/components/dialogs/confirmDialog';
import * as React from 'react';
import { CustomFileData } from '../models';
import FilledInput from 'modules/common/components/forms/inputs/filledInput';
import { imageExtFromMimeType } from '../chonkyFileAdapter';

interface IRenameMediaDialog {
    disableRename: boolean;
    onClose: () => void,
    onConfirm: () => void,
    onDeny: () => void,
    openRenameDialog: boolean;
    fileToRename: CustomFileData | null;
    renameValue: string;
    onRenameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    showRenameError: boolean;
    maxInputCount?: number;
}

export const RenameMediaDialog: React.FunctionComponent<IRenameMediaDialog> = ({
    disableRename,
    onClose,
    onConfirm,
    onDeny,
    openRenameDialog,
    fileToRename,
    renameValue,
    onRenameChange,
    showRenameError,
    maxInputCount
}) => {
    return <ConfirmDialog
        title="Rename"
        denyLabel="Cancel"
        confirmLabel="Rename"
        denyButtonProps={{sx:{color:'grey'}}}
        confirmButtonProps={{disabled: disableRename}}
        onClose={onClose}
        onConfirm={onConfirm}
        onDeny={onDeny}
        open={openRenameDialog && fileToRename != null}
        dialogMaxWidth="sm"
    >
        <div style={{display: 'flex', marginBottom: '8px'}}>
            <span style={{width: '40%'}}>Rename {fileToRename?.isDir ? "folder" : "image"}</span>
            <span style={{width: '60%'}}>{fileToRename?.name}</span>
        </div>
        <FilledInput
            label={"To"}
            required
            value={renameValue}
            characterCount
            maxCount={maxInputCount}
            inputContainerStyle={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}
            inputDivContainerStyle={{width: '60%', display: 'flex'}}
            inputDivStyle={{width: '100%'}}
            outlinedInput
            inputLabelStyle={{marginBottom: 16}}
            controlled
            inputProps={{onChange: onRenameChange}}
            errorHelperText={showRenameError ? `${fileToRename?.isDir ? "Folder" : "Image"} name already exists` : ""}
            inputEndAdornmentText={imageExtFromMimeType(fileToRename?.mimeType)}
        />
    </ConfirmDialog>;
}