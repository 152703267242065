import * as React from "react";
import moment from "moment";

import { Document } from "../../../models";

import Uploader from "modules/common/components/authoring/uploader/uploader";
import SupportedFileTypes from "./supportedFileTypes";


import "../../../styles/documentCreation.sass";


class DocumentUploader extends React.Component<ComponentProps, ComponentState> {
  constructor(props: ComponentProps) {
    super(props);

    this.state = {
      isUploading: false
    };
  }

  componentDidMount() {
    moment.locale("en");
  }

  public render() {
    return (
      <div className="document-creation">
        <div className="document-uploader">
          <Uploader
            message={this.props.message}
            maxUploadable={1}
            uploading={this.state.isUploading}
            setUploading={this.onSetUploading}
            upload={this.props.onUpload}
            attachmentType="files"
            acceptedFiles={this.props.acceptedFiles}
          />
        </div>
        {this.props.showSupportedFileTypes &&
          <SupportedFileTypes />
        }
      </div>
    );
  }

  private onSetUploading = (isUploading: boolean) => {
    this.setState({ isUploading });
  }
}

interface ComponentProps {
  acceptedFiles: string;
  message: string;
  showSupportedFileTypes?: boolean;
  onUpload: (file: File, onProgress, onLoad, onError, onAbort) => Promise<Document>;
}

interface ComponentState {
  isUploading: boolean;
}

export default DocumentUploader;