import React, { ReactElement } from "react";
import { connect, ConnectedProps } from "react-redux";
import * as actions from "../../actionCreator";
import { GlobalApplicationState } from "globalApplicationState";
import { CategoryTagFilter, CategoryTagsListingPage, CategoryTagsListItem } from "../../models";
import moment from "moment";

import Loading from "modules/common/components/loading";

import MoreCategoryTagOptions from "../action-buttons/moreCategoryTagOptions";
import CategoryTagsFilters from "../category-tags-filters/categoryTagsFilters";

import TabContent from "pages/common/tabContent";
import TabContentToolbar from "pages/common/tabContentToolbar";

import confirm from "utils/notyPopups";

import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveAltIcon from "@mui/icons-material/SaveAlt";

import InfoHover from "modules/common/components/hovers/infoHover";
import AudienceChipLabels from "modules/common/components/chips/audienceChipLabels";
import LoadingSpinner from "modules/common/components/loadingSpinner";
import { FileDownloader } from "utils/fileDownloader";

interface HeaderCell {
  disablePadding: boolean;
  id: string;
  label: string;
  icon?: ReactElement<any>;
}


class CategoryTagsList extends React.Component<PropsWithRedux, ComponentState> {
  constructor(props: PropsWithRedux) {
    super(props);

    this.state = {
      filters: { ...this.getDefaultFilterValues() },
      hasFiltersApplied: false,
      isPagedLoad: false,
      categoryTagDetails: undefined,
      selectedItems: [],
      showCategoryTagDetails: false,
      isDownloading: false
    };
  }

  public componentDidMount() {
    moment.locale("en");
  }

  public componentDidUpdate(prevProps: PropsWithRedux) {
    if (this.props.page.id !== prevProps.page.id) {
      this.setState({ selectedItems: [] });
    }
  }

  public render() {
    const { page } = this.props;

    if (!this.props.show)
      return <React.Fragment></React.Fragment>;

    if (page.isFetching && !this.state.isPagedLoad)
      return <Loading />;
      
    return (
      <div className="category-tags-list">
        {this.getToolbar()}
        <TabContent>
          {this.getFilter()}
          {this.getList()}
        </TabContent>
      </div>
    );
  }

  private formatPublishedTime = (time?: string): JSX.Element => {
    if (time) {
      return (
        <div className="category-tag-time">
          <span>{moment(new Date(time)).format("MMM D, YYYY, h:mmA")}</span>
        </div>
      );
    }
    return <div>-</div>;
  }

  private formatState = (enabled: boolean): JSX.Element => {
    if (!enabled)
      return <span className="category-tag-state grey">{"Disabled"}</span>;
    else
      return <span className="category-tag-state green">{"Enabled"}</span>;
  }

  private getDefaultFilterValues = (): Partial<CategoryTagFilter> => {
    const filters: Partial<CategoryTagFilter> = {
      title: "",
      audienceVisibleTo: []
    };
    return filters;
  }


  private getFilter = (): JSX.Element => {
    return (
      <CategoryTagsFilters
        filters={this.state.filters}
        id={this.props.page.id}
        onChangeFilters={this.onChangeFilters}
        onClearFilters={this.onClearFilters}
      />
    );
  }

  private getHeader = (): HeaderCell[] => {
    const { page } = this.props;

    let header: HeaderCell[] = [
      { id: "title", disablePadding: true, label: "Category tag title" },
      { id: "options", disablePadding: true, label: "" }
    ];

    if (page.id === "all")
      header.push({ id: "state", disablePadding: false, label: "State" });

    header.push({ id: "publish", disablePadding: false, label: "Created date" })
    header.push({ id: "modified", disablePadding: false, label: "Last modified by" })
    header.push({ id: "language", disablePadding: false, label: "Language" })

    header.push({ id: "access", disablePadding: false, label: "Default access audience", 
      icon: <InfoHover>These are the default audiences that the content will be accessible to when a tag is used. 
        You can still edit these audiences despite using this tag.</InfoHover>})
    
      header.push({ id: "notify", disablePadding: false, label: "Default notified audience",
      icon: <InfoHover>These are the default audiences that will receive a notification when a tag is used. 
        You can still edit these audiences despite using this tag. </InfoHover>})
    
    return header;
  }

  private getList = (): JSX.Element => {
    const { page } = this.props;
    const { selectedItems } = this.state;

    const rowCount = page.categoryTags.length || 10;
    if (page.isFetching)
      return <Loading />;
    
    if (!page.categoryTags.length)
      return (
        <React.Fragment>
          {this.state.hasFiltersApplied && 
            <Typography variant="h2" className="category-tag-results">Results</Typography>
          }
          <div>No category tags were found.</div>
        </React.Fragment>
    );

    return (
      <React.Fragment>
        {this.state.hasFiltersApplied && 
            <Typography variant="h2" className="category-tag-results">Results</Typography>
        }
        <TableContainer>
          <Table size="medium">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    indeterminate={selectedItems.length > 0 && selectedItems.length < rowCount}
                    checked={rowCount > 0 && selectedItems.length === rowCount}
                    onChange={this.onSelectAllCategoryTags}
                    inputProps={{ "aria-label": "select all pages" }}
                  />
                </TableCell>
                {this.getHeader().map((headerCell) => {
                  if (headerCell.id === "options")
                    return <TableCell key={headerCell.id} padding="checkbox"></TableCell>

                  return (
                    <TableCell
                      key={headerCell.id}
                      align={headerCell.disablePadding ? "left" : "center"}
                      padding={headerCell.disablePadding && headerCell.id !== "title" ? "none" : "normal"}
                    >
                      <div className="category-tag-headers">
                        {headerCell.label}
                        {headerCell.icon}
                      </div>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {page.categoryTags
                .map((categoryTag, index) => this.getListItem(categoryTag, index))
              }
            </TableBody>
          </Table>
        </TableContainer>
        {page.currentPage > 0 && page.totalPages > 1 &&
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            backIconButtonProps={{ color: "primary" }}
            nextIconButtonProps={{ color: "primary" }}
            count={page.totalCategoryTags}
            rowsPerPage={10}
            page={page.currentPage - 1}
            onPageChange={this.onChangePage}
          />
        }
      </React.Fragment>
    );
  }

  private getListItem = (categoryTag: CategoryTagsListItem, index: number): JSX.Element => {
    const isCategoryTagSelected = this.isCategoryTagSelected(categoryTag);
    const labelId = `category-tag-list-checkbox-${index}`;

    return (
      <React.Fragment key={categoryTag.id}>
        <TableRow
          hover
          onClick={() => this.prepareAndView(categoryTag.id, categoryTag)}
          role="checkbox"
          aria-checked={isCategoryTagSelected}
          tabIndex={-1}
          key={categoryTag.id}
          selected={isCategoryTagSelected}
          style={{cursor: "pointer"}}
        >
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              checked={isCategoryTagSelected}
              inputProps={{ "aria-labelledby": labelId }}
              onClick={(ev) => this.onSelectCategoryTag(ev, categoryTag)}
            />
          </TableCell>
          <TableCell component="td" id={labelId} scope="row">{categoryTag.title}</TableCell>
          <TableCell padding="checkbox">
            <MoreCategoryTagOptions categoryTag={categoryTag} /> 
          </TableCell>
          {this.props.page.id === "all" &&
            <TableCell component="td" id={labelId} scope="row" align="center"><div>{this.formatState(categoryTag.enabled)}</div></TableCell>
          }
          <TableCell component="td" id={labelId} scope="row" align="center">{categoryTag.title === "Uncategorized" ? "-" : this.formatPublishedTime(categoryTag.createdDate)}</TableCell>
          <TableCell component="td" id={labelId} scope="row" align="center">{categoryTag.lastModifiedBy ? categoryTag.lastModifiedBy.name : "-"}</TableCell>
          <TableCell component="td" id={labelId} scope="row" align="center">{categoryTag.language}</TableCell>
          <TableCell component="td" id={labelId} scope="row" align="center">{<AudienceChipLabels audienceIds={categoryTag.defaultAudienceAccess} audiences={this.props.audiences}/>}</TableCell>
          <TableCell component="td" id={labelId} scope="row" align="center">{<AudienceChipLabels audienceIds={categoryTag.defaultAudienceNotified} audiences={this.props.audiences} emptyText="None" />}</TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  private getToolbar = (): JSX.Element => {
    const { selectedItems } = this.state;

    if (!selectedItems.length)
      return <React.Fragment></React.Fragment>;
    
    return (
      <TabContentToolbar>
        <div>
          {this.getToolbarIcon(`${selectedItems.length} selected`, <ClearIcon />, this.onClearAllCategoryTags)}
        </div>
        <div>
          {this.getToolbarIcon("Download Selected Reports", this.state.isDownloading ? <LoadingSpinner size={"20px"}/> : <SaveAltIcon/>, () => this.getTagsForDownload(this.state.selectedItems.map(s => s.id)))}
          {this.getToolbarIcon("Delete", <DeleteIcon />, this.onDeleteCategoryTags)}
        </div>
      </TabContentToolbar>
    );
  }

  private getTagsForDownload = async (ids: string[]) => {
    this.setState({isDownloading: true})

    const file = {
      name: `SelectedCategoryTags-${new Date().toISOString()}.csv`
    }  
    this.props.downloadCategoryTags(ids)
      .then((tags) => new FileDownloader(file).downloadBlob(tags))
      .then(_ => this.setState({isDownloading: false}))
      .catch(_ => this.setState({isDownloading: false}))
  }

  private getToolbarIcon = (title: string, icon: React.ReactNode, onClick: () => void) => {
    return (
      <Button aria-label={title.toLowerCase()} color="primary" startIcon={icon} onClick={onClick}>
        {title}
      </Button>
    );
  }

  private isCategoryTagSelected = (categoryTag: CategoryTagsListItem): boolean => {
    return this.state.selectedItems.findIndex((selectedItem) => selectedItem.id === categoryTag.id) !== -1;
  }

  private onApplyFilters = () => {
    this.setState({ hasFiltersApplied: true, isPagedLoad: true });
    this.props.fetchPage(1, this.state.filters);
  }

  private onChangeFilters = (filters: Partial<CategoryTagFilter>) => {
    this.setState({ filters: { ...this.state.filters, ...filters } }, () => {
      this.onApplyFilters();
    });
  }

  private onClearFilters = () => {
    this.setState({ hasFiltersApplied: false, filters: { ...this.getDefaultFilterValues() }, isPagedLoad: true });
    this.props.fetchPage(1, {});
  }


  private onClearAllCategoryTags = () => {
    this.setState({ selectedItems: [] });
  }

  private onDeleteCategoryTags = async () => {
    const confirmationMessage: string = this.state.selectedItems.length > 1
      ? "Are you sure you want to delete these category tags?"
      : "Are you sure you want to delete this category tag?";
    if (await confirm.show({text: confirmationMessage, title: "Delete tag(s)"})) {
      if (this.state.selectedItems.length === 1) {
        const selectedItem: CategoryTagsListItem = this.state.selectedItems[0];
        this.props.deleteCategoryTag(selectedItem.id);
        this.onClearAllCategoryTags();
      } else {
        this.props.deleteCategoryTags(this.state.selectedItems);
        this.onClearAllCategoryTags();
      }
    }
  }

  private onSelectAllCategoryTags = () => {
    if (this.state.selectedItems.length === this.props.page.categoryTags.length)
      this.setState({ selectedItems: [] });
    else
      this.setState({ selectedItems: this.props.page.categoryTags });
  }

  private onSelectCategoryTag = (ev, categoryTag: CategoryTagsListItem) => {
    ev.stopPropagation();
    let selectedItems = this.state.selectedItems.slice();

    if (selectedItems.findIndex((selectedItem) => selectedItem.id === categoryTag.id) === -1)
      selectedItems = selectedItems.concat([categoryTag]);
    else
      selectedItems = selectedItems.filter(selectedItem => selectedItem.id !== categoryTag.id);

    this.setState({ selectedItems });
  }

  private deselectCategoryTag = (id: string) => {
    let selectedItems = this.state.selectedItems.slice();
    selectedItems = selectedItems.filter(selectedItem => selectedItem.id !== id);
    this.setState({ selectedItems });
  }

  private onChangePage = (ev, page: number) => {
    this.props.fetchPage(page + 1, this.state.filters);
  }

  private prepareAndView = (id: string, tag: CategoryTagsListItem | undefined) => {
    //Deselect tag when viewed in detail as a safety precaution in case they delete.
    //Could potentially upgrade this later to be smarter about the deselection, but due to having seperate components it will be complicated.
    this.deselectCategoryTag(id);
    this.props.setTagToView(id, tag);
    this.props.showCategoryTagDetails();
  }
}
  

interface ComponentProps {
  show: boolean;
  page: CategoryTagsListingPage;
  fetchPage: (pageNumber: number, filters: Partial<CategoryTagFilter>) => void;
}

interface ComponentState {
  filters: Partial<CategoryTagFilter>;
  hasFiltersApplied: boolean;
  isPagedLoad: boolean;
  categoryTagDetails: CategoryTagsListItem | undefined;
  selectedItems: CategoryTagsListItem[];
  showCategoryTagDetails: boolean;
  isDownloading: boolean;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    audiences: state.audiences.audiences,
    tenant: state.tenant.id,
  }),
  {
    deleteCategoryTag: actions.deleteCategoryTag,
    deleteCategoryTags: actions.deleteCategoryTags,
    showCategoryTagDetails: actions.showViewCategoryTag,
    setTagToView: actions.setTagToView,
    downloadCategoryTags: actions.downloadCategoryTags,
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(CategoryTagsList);