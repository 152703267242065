/*
  Component designed to have a show/hide section through the use of a Show More / Less button.
  The show more section will automatically close if anywhere outside of it is clicked.
  The actual expanded content is passed in with a prop called "body".
*/

import React, { useEffect } from "react";
import { Button } from "@mui/material";
import './showMoreSection.sass';

export const ShowMoreSection: React.FunctionComponent<ComponentProps> = props => {
  const [showMore, setShowMore] = React.useState(false);

  //https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowMore(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = React.useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
      <div ref={wrapperRef}>
        {showMore &&
            <div>
                {props.body ?? <div/>}
                <div className="content-display">
                    <Button color="primary" size="small" onClick={() => setShowMore(false)}>See less</Button>
                </div>
            </div>
        }
        {!showMore &&
            <div className="content-display">
            <Button color="primary" size="small" onClick={() => setShowMore(true)}>See more</Button>
            </div>
        }
    </div>
  );
}

interface ComponentProps {
    body: JSX.Element;
}