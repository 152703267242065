/*
    This is the component that's used to map the list of senders onto.
    It contains a lock or X depending on if the alias is currently in use.
    It's displayed in a list on the global newsletter settings dialog.
*/

import React from 'react';
import { Button, Divider, IconButton, Popover } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LockIcon from '@mui/icons-material/Lock';
import ArrowForward from '@mui/icons-material/ArrowForwardIos';
import { Address } from 'modules/newsletters/models';

const NewsletterSender: React.FC<ComponentProps> = ({addressToDelete, onlyItem, onDelete, onLocked}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [showPopover, setShowPopover] = React.useState(false);
    const buttonRef = React.useRef(null);

    const truncate = (str: string, n: number) => {
        return (str.length > n) ? str.slice(0, n-1) + "..." : str;
    }

    if(anchorEl === null && buttonRef.current !== null)
        setAnchorEl(buttonRef.current)

    return (
        <div style={{padding: "4px", paddingLeft: "10px"}}>
            <span style={{fontSize: "16px"}}>{truncate(addressToDelete.fromEmailName, 55)}</span>
            
            {onlyItem
                ? 
                <React.Fragment>
                    <div style={{float: "right", padding: "6px"}} ref={buttonRef} onMouseEnter={() => setShowPopover(true)}>
                        <CloseIcon htmlColor="#AAAAAA" style={{fontSize: "20px"}}></CloseIcon>
                    </div>
                    <Popover
                        open={showPopover}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left"
                        }}
                        transformOrigin={{
                        vertical: "top",
                        horizontal: "left"
                        }}
                        PaperProps={{
                            onMouseEnter: () => setShowPopover(true),
                            onMouseLeave: () => setShowPopover(false),
                            style: {
                              maxWidth: 250,
                              padding: "10px"
                            }
                        }}
                        onClose={() => setShowPopover(false)}
                    >
                        <div style={{fontSize: "12px"}}>Your organization is required to have at least one (1) sender email.</div>
                </Popover>
              </React.Fragment>
            
            : !addressToDelete.currentlyInUse 
                    ? <IconButton
                onClick={() => onDelete(addressToDelete)}
                style={{float: "right"}}
                size="large">
                        <CloseIcon htmlColor="red" style={{fontSize: "20px"}}></CloseIcon>
                    </IconButton> 
                    : <Button onClick={() => onLocked(addressToDelete)} size='small' style={{float: "right", color: "#888888"}} startIcon={<LockIcon style={{position: "relative", left: "4px"}}/>} endIcon={<ArrowForward/>}></Button> 
            }
            
            <br/>
            <span style={{fontSize: "13px"}}>{truncate(addressToDelete.fromEmailAddress, 70)}</span>
            <Divider light />
        </div>
    );
    };

interface ComponentProps {
    addressToDelete: Address
    onlyItem: boolean
    onDelete: (sender: Address) => void;
    onLocked: (sender: Address) => void;
}

export default NewsletterSender;