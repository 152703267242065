export interface ReportDetails {
    reportName: string;
    reportType: string;
    reportTypeEnum: ReportTypes;
    recordId: string;
    postId: string;
    userId: string;
    createdTime: Date;
    downloadedBy: string | null;
    downloadedTime: Date | null;
}

export enum ReportTypes {
    UserReadReport,
    AnalyticsCsvReport,
    PlatformAnalyticsReport,
    SMSNotificationReport
}