import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    IContentBand,
    IContentBandSettings,
    ContentBandContentType,
    ContentBandLayoutType,
    ReadStatus,
    IContentBandConfig,
} from "modules/contentBands/models";
import { GlobalApplicationState } from "globalApplicationState";
import {
    contentBandsSlice,
    fetchContentBandConfigAsync,
    fetchContentBandsAsync,
    fetchContentForBandAsync,
    publishContentBandsAsync,
    saveContentBandsAsync,
} from "modules/contentBands/reducer";
import { SortStyle } from "utils/managementUtils";

export const isPinnedDeleted = (contentBands: IContentBand[], publishedBands: IContentBand[] | null, selectedIds: string[]): boolean => {
    const someHandler = (cb: IContentBand) => cb.pinnedIds !== undefined && cb.pinnedIds.some((id: string) => selectedIds.includes(id));

    const isPinnedDeleted = contentBands.some(someHandler) || (publishedBands !== null && publishedBands.some(someHandler));

    return isPinnedDeleted;
};

// helper to cast IcontentBand to Partial<IContentBandSettings> for comparing against other bands
export const castBandToComparableSettings = (x: IContentBand): Partial<IContentBandSettings> => {
    let { posts, events, id, publishedDateTimeUtc, createdById, createdDateTimeUtc, parentId, status, ...settings } = x;

    return settings;
};

export type UseContentBandsReturnType = {
    contentBands: IContentBand[];
    isLoading: boolean;
    latestSavedContentBands: IContentBand[]; // keeps track of what's on server
    isValid: boolean; // are content bands valid
    isContentBandsDirty: boolean;
    isConfigDirty: boolean;
    successMessage: string;
    publishCount: number;
    fetchIdx?: number | null; // the idx of the content band we are fetching content for
    publishedContentBands: IContentBand[] | null;
    addFinished: boolean;
    config: IContentBandConfig | undefined;
    pristineConfig: IContentBandConfig | undefined;
    saveContentBands: (successMessage: string) => void; // save to server
    publishContentBands: (successMessage: string) => void;
    onChangeLayout: (newValue: ContentBandLayoutType, idx: number) => void;
    onChangeContentType: (newValue: ContentBandContentType, idx: number) => void;
    onChangeDisplayFullTitles: (newValue: boolean, idx: number) => void;
    onChangeEnableSeeMoreLink: (newValue: boolean, idx: number) => void;
    onChangeContentCount: (newValue: number, idx: number) => void;
    onChangeSortBy: (newValue: SortStyle, idx: number) => void;
    onChangePinnedIds: (newValue: string[], idx: number) => void;
    onChangeLockToReadStatus: (newValue: ReadStatus, idx: number) => void;
    onChangeLockToTypes: (newValue: string[], idx: number) => void;
    onChangeLockToTopics: (newValue: string[], idx: number) => void;
    onChangeAutoPlayCarousel: (newValue: boolean, idx: number) => void;
    onChangeIgnoreSubscriptions: (newValue: boolean, idx: number) => void;
    onChangeHeaderText: (newValue: string, lcid: string, idx: number) => void;
    fetchContentForBand: (band: IContentBand, idx: number) => void;
};

/**
 * Hook to interact with redux content bands for editor pages
 * @param includeContent control whether or not to fetch content as well on initial fetch
 * @returns UseContentBandsReturnType
 */
export const useContentBands = (includeContent: boolean = false): UseContentBandsReturnType => {
    const dispatch = useDispatch();
    const {
        contentBands,
        pristineContentBands,
        publishedContentBands,
        fetched,
        publishCount,
        fetchIdx,
        successMessage = "",
        addFinished,
        config,
        pristineConfig,
        isLoadingContentBandConfig,
        isLoadingContentBands,
        isLoadingPublishedContentBands,
        isPublishingContentBandConfig,
        isPublishingContentBands,
        isSavingContentBandConfig,
        isSavingContentBands,
    } = useSelector((state: GlobalApplicationState) => state.contentBands);

    // content bands are valid if
    // - content count > 0
    // - carousel content count < 11
    // - all other layouts content count < 21
    const isValid = useMemo<boolean>(() => {
        return !contentBands.some(
            (band: IContentBand) =>
                (band.contentCount || 0) < 1 ||
                (band.layoutType === ContentBandLayoutType.Carousel && (band.contentCount || 0) > 10) ||
                (band.layoutType !== ContentBandLayoutType.Carousel && (band.contentCount || 0) > 20)
        );
    }, [contentBands]);

    // check if pristine matches current bands
    // first, set content to empty so we only check band settings
    const isContentBandsDirty = useMemo<boolean>(
        () =>
            JSON.stringify([...contentBands].map(castBandToComparableSettings)) !==
            JSON.stringify([...pristineContentBands].map(castBandToComparableSettings)),
        [pristineContentBands, contentBands]
    );

    // check current config changes against pristine version
    // do not check for mismatch undefined because those are default values while fetching
    const isConfigDirty =
        pristineConfig !== undefined &&
        config !== undefined &&
        Object.keys(pristineConfig).some((key: string) => pristineConfig[key] !== config[key]);

    const fetchContentForBand = (band: IContentBand, idx: number) => {
        dispatch(fetchContentForBandAsync({ band, idx }));
    };

    // fetch content bands
    // dont fetch if include content goes false and we've already fetched since we already have everything we need
    // dont fetch if we have content already and include content goes true
    useEffect(() => {
        if (fetched && !includeContent) return;

        const haveContentAlready = contentBands.every((cb: IContentBand) => cb.events !== null || cb.posts !== null);
        if (includeContent && fetched && haveContentAlready) return;

        dispatch(fetchContentBandsAsync(includeContent));
    }, [includeContent, dispatch, fetched, contentBands]);

    // pull config into redux if we don't have it
    useEffect(() => {
        if (config === undefined) dispatch(fetchContentBandConfigAsync());
    }, [config, dispatch]);

    // save current state content bands to server
    const saveContentBands = (successMessage: string) => {
        dispatch(saveContentBandsAsync({ contentBands, includeContent, successMessage }));
    };

    // publish current state content bands
    const publishContentBands = (successMessage: string) => {
        dispatch(publishContentBandsAsync({ contentBands, includeContent: false, successMessage }));
    };

    // helper function to modify a specific field and dispatch redux update
    function editContentBandDraftField<T>(idx: number, fieldName: keyof IContentBandSettings, value: T) {
        const originalBand = { ...contentBands[idx] };

        const newBand: IContentBand = {
            ...originalBand,
            // reset values to defaults that do not apply to certain layouts
            autoPlayCarousel: originalBand.layoutType === ContentBandLayoutType.Carousel ? originalBand.autoPlayCarousel : false,
            displayFullTitles: originalBand.layoutType === ContentBandLayoutType.Card ? originalBand.displayFullTitles : false,
            seeMoreEnabled: originalBand.layoutType === ContentBandLayoutType.Carousel ? false : originalBand.seeMoreEnabled,
            [fieldName]: value,
        };

        // reset some values when changing content type
        if (fieldName === "contentType") {
            newBand.lockToTypes = [];
            newBand.pinnedIds = [];

            if (value === ContentBandContentType.Event) newBand.sortBy = SortStyle.startDesc;
            else newBand.sortBy = SortStyle.publishDesc;
        }

        dispatch({
            type: contentBandsSlice.actions.EDIT_CONTENT_BAND,
            payload: {
                values: newBand,
                idx,
            },
        });
    }

    const onChangeLayout = (newValue: ContentBandLayoutType, idx: number) => {
        editContentBandDraftField<ContentBandLayoutType>(idx, "layoutType", newValue);
    };

    const onChangeContentType = (newValue: ContentBandContentType, idx: number) => {
        editContentBandDraftField<ContentBandContentType>(idx, "contentType", newValue);
    };

    const onChangeDisplayFullTitles = (newValue: boolean, idx: number) => {
        editContentBandDraftField<boolean>(idx, "displayFullTitles", newValue);
    };

    const onChangeEnableSeeMoreLink = (newValue: boolean, idx: number) => {
        editContentBandDraftField<boolean>(idx, "seeMoreEnabled", newValue);
    };

    const onChangeContentCount = (newValue: number, idx: number) => {
        editContentBandDraftField<number>(idx, "contentCount", newValue);
    };

    const onChangeSortBy = (newValue: SortStyle, idx: number) => {
        editContentBandDraftField<SortStyle>(idx, "sortBy", newValue);
    };

    const onChangePinnedIds = (newValue: string[], idx: number) => {
        editContentBandDraftField<string[]>(idx, "pinnedIds", newValue);
    };

    const onChangeLockToReadStatus = (newValue: ReadStatus, idx: number) => {
        editContentBandDraftField<ReadStatus>(idx, "lockToReadStatus", newValue);
    };

    const onChangeLockToTypes = (newValue: string[], idx: number) => {
        editContentBandDraftField<string[]>(idx, "lockToTypes", newValue);
    };

    const onChangeLockToTopics = (newValue: string[], idx: number) => {
        editContentBandDraftField<string[]>(idx, "lockToTopicIds", newValue);
    };

    const onChangeIgnoreSubscriptions = (newValue: boolean, idx: number) => {
        editContentBandDraftField<boolean>(idx, "ignoreSubscriptions", newValue);
    };

    const onChangeAutoPlayCarousel = (newValue: boolean, idx: number) => {
        editContentBandDraftField<boolean>(idx, "autoPlayCarousel", newValue);
    };

    const onChangeHeaderText = (newValue: string, lcid: string, idx: number) => {
        const band = { ...contentBands[idx] };

        if (band.headers && Object.keys(band.headers).includes(lcid)) {
            band.headers = {
                ...band.headers,
                [lcid]: {
                    header: newValue,
                },
            };

            editContentBandDraftField(idx, "headers", band.headers);
        }
    };

    return {
        contentBands: contentBands?.map((cb: IContentBand) => ({ ...cb, posts: cb.posts || [], events: cb.events || [] })) || [],
        isLoading:
            isLoadingContentBandConfig ||
            isLoadingContentBands ||
            isLoadingPublishedContentBands ||
            isSavingContentBands ||
            isSavingContentBandConfig ||
            isPublishingContentBandConfig ||
            isPublishingContentBands,
        latestSavedContentBands: pristineContentBands,
        isValid,
        successMessage,
        isContentBandsDirty,
        publishCount,
        fetchIdx,
        publishedContentBands,
        addFinished,
        config,
        pristineConfig,
        isConfigDirty,
        saveContentBands,
        publishContentBands,
        onChangeLayout,
        onChangeContentCount,
        onChangeContentType,
        onChangeSortBy,
        onChangeDisplayFullTitles,
        onChangeEnableSeeMoreLink,
        onChangeLockToReadStatus,
        onChangeLockToTopics,
        onChangeIgnoreSubscriptions,
        onChangeLockToTypes,
        onChangePinnedIds,
        onChangeAutoPlayCarousel,
        onChangeHeaderText,
        fetchContentForBand,
    };
};
