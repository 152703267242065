import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Button, Paper, Menu, MenuItem } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import * as actions from "../../actionCreator";
import { GlobalApplicationState } from "globalApplicationState";
import moment from "moment";
import {
    defaultDraftPostFilterValues,
    defaultSubmissionPostFilterValues,
    defaultPublishedPostFilterValues,
    PostFilterValues,
    PostListingPage,
} from "../../models";
import PostList from "./postList";
import ErrorSnackbar from "modules/common/components/snackbars/errorSnackbar";
import LoadingOverlay from "modules/common/components/loadingOverlay";
import SuccessfullyPublishedDialog from "modules/common/components/successfullyPublishedDialog";
import Tabs from "pages/common/tabs";
import { tagsApi } from "api/instances";
import { TenantSettingsTagGroup } from "modules/settings";
import { UserRoles } from "modules/authorization/models";
import ConditionalWrapper from "modules/common/components/conditionalWrapper";
import { push } from "react-router-redux";
import { SelectOption } from "modules/common/models";

import "./styles/postListing.sass";
import { QUERY_PARAM_KEYS } from "modules/common/hooks/useQueryParams";
import { setShouldDisplayNav } from "modules/adminLayout/actionCreator";

enum TAB_IDS {
    DRAFTS,
    SUBMISSIONS,
    SCHEDULED,
    PUBLISHED,
    ALL,
    // EXPIRED

    __LENGTH // only use for length; keep at the end of the enum
}

enum POST_CREATED_BY_SELECT_VALUES {
    VIEW_MY_POSTS,
    VIEW_ALL_POSTS
}

interface IPostCreatedBySelectOption extends SelectOption {
    id: POST_CREATED_BY_SELECT_VALUES
}

const POST_CREATED_BY_SELECT_OPTS: IPostCreatedBySelectOption[] = [
    {
        id: POST_CREATED_BY_SELECT_VALUES.VIEW_ALL_POSTS,
        key: "allPosts",
        text: "All posts",
        type: "item"
    },
    {
        id: POST_CREATED_BY_SELECT_VALUES.VIEW_MY_POSTS,
        key: "myPosts",
        text: "My posts (Created by me)",
        type: "item"
    }
];

class PostListing extends React.Component<PropsWithRedux, ComponentState> {
    private isAllowedPostCreatedByFilter: boolean;

    constructor(props: PropsWithRedux) {
        super(props);

        this.isAllowedPostCreatedByFilter = props.user.roles.some(ur => ur === UserRoles.Author || ur === UserRoles.Owner);

        this.state = {
            showingPublishErrorsDialog: false,
            selectedTab: TAB_IDS.DRAFTS,
            availableTopics: [],
            postCreatedByAnchorEl: null,
            filters: Array(TAB_IDS.__LENGTH)
                .fill(defaultDraftPostFilterValues, 0, 1)
                .fill(defaultSubmissionPostFilterValues, 1, 2)
                .fill(defaultPublishedPostFilterValues, 2, TAB_IDS.__LENGTH) // published posts default filter sort by published desc
        };
    }

    public componentDidMount() {
        moment.locale("en");

        if (!this.props.shouldDisplayNav)
            this.props.setShouldDisplayNav(true);

        const queryParams = new URLSearchParams(window.location.search);

        const allPageNumber = +(queryParams.get(QUERY_PARAM_KEYS.ALL_PAGE_NUMBER) ?? 1);
        const publishedPageNumber = +(queryParams.get(QUERY_PARAM_KEYS.PUBLISHED_PAGE_NUMBER) ?? 1);
        const scheduledPageNumber = +(queryParams.get(QUERY_PARAM_KEYS.SCHEDULED_PAGE_NUMBER) ?? 1);
        const draftPageNumber = +(queryParams.get(QUERY_PARAM_KEYS.DRAFT_PAGE_NUMBER) ?? 1);
        const submissionPageNumber = +(queryParams.get(QUERY_PARAM_KEYS.SUBMISSION_PAGE_NUMBER) ?? 1);

        let currentTab = +(queryParams.get(QUERY_PARAM_KEYS.TAB_NUMBER) ?? TAB_IDS.DRAFTS);
        if (currentTab >= TAB_IDS.__LENGTH || currentTab < 0) {
            currentTab = TAB_IDS.DRAFTS;
        }

        const freshLoad = (!queryParams.has(QUERY_PARAM_KEYS.ALL_PAGE_NUMBER) || !queryParams.has(QUERY_PARAM_KEYS.PUBLISHED_PAGE_NUMBER)
            || !queryParams.has(QUERY_PARAM_KEYS.SCHEDULED_PAGE_NUMBER) || !queryParams.has(QUERY_PARAM_KEYS.DRAFT_PAGE_NUMBER) || !queryParams.has(QUERY_PARAM_KEYS.TAB_NUMBER));

        if (freshLoad) {
            let newUrl = this.generateUrl(currentTab, allPageNumber, publishedPageNumber, scheduledPageNumber, draftPageNumber, submissionPageNumber);

            window.history.replaceState(null, "", newUrl);
        }

        this.onSelectTab(currentTab, true);
        this.fetchLists(allPageNumber, publishedPageNumber, scheduledPageNumber, draftPageNumber, submissionPageNumber);
        this.getTags();
    }

    private shouldShowLoadingOverlay = () => {
        return (this.props.publishing
            || this.props.deleting
            || this.props.fetching
            || this.props.unpublishing)
            && !this.props.shouldShowPublishedDialog
            && !this.state.showingPublishErrorsDialog;
    }

    public shouldComponentUpdate(nextProps: PropsWithRedux, nextState: ComponentState) {
        return !(this.props.publishing && !nextProps.publishing && !nextState.showingPublishErrorsDialog); //to ensure publishing state is shown
    }

    public componentDidUpdate(prevProps: PropsWithRedux, prevState: ComponentState) {
        if (
            Object.keys(prevProps.publishValidationErrors.errors).length === 0
            && Object.keys(this.props.publishValidationErrors.errors).length > 0
        ) {
            this.setState(prevState => ({ ...prevState, showingPublishErrorsDialog: true }));
        }

        if (this.props.shouldFetch && (this.props.shouldFetch !== prevProps.shouldFetch)) {
            this.fetchLists();
        }
    }

    public render() {
        const { selectedTab } = this.state;

        return (
            <React.Fragment>
                <ConditionalWrapper condition={this.isAllowedPostCreatedByFilter} wrapper={(children) => <div id="post-listing-tabs-container">{children}</div>}>
                    <Tabs
                        tabs={[
                            { label: this.getTabLabel("Drafts", this.props.draftPostList), dotColor: "grey" },
                            { label: this.getTabLabel("Needs Review", this.props.submissionPostList), dotColor: "red" },
                            { label: this.getTabLabel("Scheduled", this.props.scheduledPostList), dotColor: "yellow" },
                            { label: this.getTabLabel("Published", this.props.publishedPostList), dotColor: "green" },
                            { label: this.getTabLabel("All", this.props.allPostList) },
                            // { label: this.getTabLabel("Expired", this.props.expiredPostList) }
                        ]}
                        selectedTab={selectedTab}
                        onSelectTab={this.onSelectTab}
                    />
                    {this.isAllowedPostCreatedByFilter &&
                        <Paper
                            sx={{
                                borderBottomLeftRadius: 0,
                                display: "flex"
                            }}
                        >
                            <Button
                                disabled={this.props.fetching}
                                sx={{
                                    color: "#5e5e5e",
                                    textTransform: "none",
                                    maxHeight: 48,
                                    height: 48,
                                    minHeight: 48
                                }}
                                variant="text"
                                endIcon={<ArrowDropDownIcon />}
                                onClick={this.onPostCreatedByClick}
                            >
                                {this.state.filters[selectedTab].createdByMeOnly
                                    ? "View my posts"
                                    : "View all posts"}
                            </Button>
                            <Menu
                                anchorEl={this.state.postCreatedByAnchorEl}
                                open={Boolean(this.state.postCreatedByAnchorEl)}
                                onClose={() => this.setState({ ...this.state, postCreatedByAnchorEl: null })}
                            >
                                {POST_CREATED_BY_SELECT_OPTS.map((item: IPostCreatedBySelectOption) => {
                                    const isSelected = (
                                        item.id === POST_CREATED_BY_SELECT_VALUES.VIEW_ALL_POSTS &&
                                        !this.state.filters[selectedTab].createdByMeOnly
                                    ) ||
                                        (
                                            item.id === POST_CREATED_BY_SELECT_VALUES.VIEW_MY_POSTS &&
                                            this.state.filters[selectedTab].createdByMeOnly
                                        );
                                    return (
                                        <MenuItem
                                            sx={{
                                                height: 48,
                                                backgroundColor: isSelected ? "#f2f2ef" : "#ffffff"
                                            }}
                                            key={`select-${item.key}`}
                                            onClick={() => this.onSelectPostCreatedBy(item.id)}
                                        >
                                            {item.text}
                                        </MenuItem>
                                    )
                                })}
                            </Menu>
                        </Paper>}
                </ConditionalWrapper>
                <PostList
                    filters={this.state.filters[TAB_IDS.ALL]}
                    onUpdateFilters={this.onUpdateFilters}
                    show={selectedTab === TAB_IDS.ALL}
                    page={this.props.allPostList}
                    fetchPage={this.fetchAllPostList}
                    availableTopics={this.state.availableTopics}
                    defaultPostFilterValues={defaultPublishedPostFilterValues}
                />
                <PostList
                    filters={this.state.filters[TAB_IDS.PUBLISHED]}
                    onUpdateFilters={this.onUpdateFilters}
                    show={selectedTab === TAB_IDS.PUBLISHED}
                    page={this.props.publishedPostList}
                    fetchPage={this.fetchPublished}
                    availableTopics={this.state.availableTopics}
                    defaultPostFilterValues={defaultPublishedPostFilterValues}
                />
                <PostList
                    filters={this.state.filters[TAB_IDS.SCHEDULED]}
                    onUpdateFilters={this.onUpdateFilters}
                    show={selectedTab === TAB_IDS.SCHEDULED}
                    page={this.props.scheduledPostList}
                    fetchPage={this.fetchScheduled}
                    availableTopics={this.state.availableTopics}
                    defaultPostFilterValues={defaultPublishedPostFilterValues}
                />
                <PostList
                    filters={this.state.filters[TAB_IDS.DRAFTS]}
                    onUpdateFilters={this.onUpdateFilters}
                    show={selectedTab === TAB_IDS.DRAFTS}
                    page={this.props.draftPostList}
                    fetchPage={this.fetchDrafts}
                    availableTopics={this.state.availableTopics}
                    defaultPostFilterValues={defaultDraftPostFilterValues}
                />
                <PostList
                    filters={this.state.filters[TAB_IDS.SUBMISSIONS]}
                    onUpdateFilters={this.onUpdateFilters}
                    show={selectedTab === TAB_IDS.SUBMISSIONS}
                    page={this.props.submissionPostList}
                    fetchPage={this.fetchSubmissions}
                    availableTopics={this.state.availableTopics}
                    defaultPostFilterValues={defaultSubmissionPostFilterValues}
                />
                {this.props.shouldShowPublishedDialog &&
                    <SuccessfullyPublishedDialog
                        displayMessage={<p> Please refresh &nbsp;
                            <i className="material-icons" style={{ verticalAlign: "middle", fontSize: '16px' }}>refresh</i>
                            &nbsp; shortly to see this published post.</p>}
                    />
                }
                <ErrorSnackbar errorMessage={this.props.errorMessage} clearErrorMessage={this.props.clearErrorMessage} />
                <LoadingOverlay absolute={true} show={this.shouldShowLoadingOverlay()} />
            </React.Fragment>
        );
    }

    private onUpdateFilters = (newFilters: Partial<PostFilterValues>, callback?: (() => void | undefined)) => {
        let filters = this.state.filters;
        filters[this.state.selectedTab] = newFilters;

        this.setState({ ...this.state, filters }, callback);
    }

    // apply this filter to all tabs
    private onSelectPostCreatedBy = (selection: POST_CREATED_BY_SELECT_VALUES) => {
        let newFilters = [...this.state.filters];

        newFilters = newFilters.map((filter: Partial<PostFilterValues>) => ({
            ...filter,
            createdByMeOnly: selection === POST_CREATED_BY_SELECT_VALUES.VIEW_MY_POSTS
        }));

        this.setState(
            {
                ...this.state,
                postCreatedByAnchorEl: null,
                filters: newFilters
            },
            this.fetchLists
        );
    }

    private onPostCreatedByClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.persist();

        this.setState((prevState) => ({
            ...prevState,
            postCreatedByAnchorEl: event.target
        }));
    }

    private fetchLists = (
        allPageNumber: number = 1,
        publishedPageNumber: number = 1,
        draftsPageNumber: number = 1,
        scheduledPageNumber: number = 1,
        submissionPageNumber: number = 1,
    ) => {
        this.fetchAllPostList(
            allPageNumber,
            {
                sortType: this.state.filters[TAB_IDS.ALL].sortType,
                createdByMeOnly: this.state.filters[TAB_IDS.ALL].createdByMeOnly,
            },
            undefined,
            true
        );

        this.fetchPublished(
            publishedPageNumber,
            {
                sortType: this.state.filters[TAB_IDS.PUBLISHED].sortType,
                createdByMeOnly: this.state.filters[TAB_IDS.PUBLISHED].createdByMeOnly,
            },
            undefined,
            true
        );

        this.fetchDrafts(
            draftsPageNumber,
            {
                sortType: this.state.filters[TAB_IDS.DRAFTS].sortType,
                createdByMeOnly: this.state.filters[TAB_IDS.DRAFTS].createdByMeOnly,
                postStates: this.state.filters[TAB_IDS.DRAFTS].postStates,
            },
            undefined,
            true
        );

        this.fetchScheduled(
            scheduledPageNumber,
            {
                sortType: this.state.filters[TAB_IDS.SCHEDULED].sortType,
                createdByMeOnly: this.state.filters[TAB_IDS.SCHEDULED].createdByMeOnly,
            },
            undefined,
            true
        );

        this.fetchSubmissions(
            submissionPageNumber,
            {
                sortType: this.state.filters[TAB_IDS.SUBMISSIONS].sortType,
                createdByMeOnly: this.state.filters[TAB_IDS.SUBMISSIONS].createdByMeOnly,
                postStates: this.state.filters[TAB_IDS.SUBMISSIONS].postStates,
            },
            undefined,
            true
        );

        this.props.clearShouldFetch();
    }

    private fetchSubmissions = (pageNumber: number, filters: Partial<PostFilterValues>, pageAmount?: number, skipUrlUpdate: boolean = false) => {
        if (!skipUrlUpdate) {
            let newUrl = this.generateUrl(this.state.selectedTab, undefined, undefined, undefined, undefined, pageNumber);
            window.history.replaceState(null, "", newUrl);
        }

        !this.props.submissionPostList.isFetching &&
            this.props.fetchSubmissionPosts(pageNumber, filters, pageAmount);
    }

    private getTabLabel = (label: string, page: PostListingPage): string => {
        if (!!page.currentPage)
            return `${label} (${page.totalPosts})`;
        return label;
    }

    private onSelectTab = (tabIndex: number, skipUrlUpdate: boolean = false) => {
        if (!skipUrlUpdate) {
            let newUrl = this.generateUrl(tabIndex, undefined, undefined, undefined, undefined);
            window.history.replaceState(null, "", newUrl);
        }

        this.setState({ ...this.state, selectedTab: tabIndex });
    }

    //Leaving a page number as undefined means it will not be updated in the function call.
    private generateUrl = (
        tabNumber: number,
        allPageNumber?: number,
        publishedPageNumber?: number,
        scheduledPageNumber?: number,
        draftPageNumber?: number,
        submissionPageNumber?: number
    ) => {
        const queryParams = new URLSearchParams(window.location.search);
        let newUrl = `/${this.props.tenantId}/admin/posts`;

        const urlAllPageNumber = +(queryParams.get(QUERY_PARAM_KEYS.ALL_PAGE_NUMBER) ?? 1);
        const urlPublishedPageNumber = +(queryParams.get(QUERY_PARAM_KEYS.PUBLISHED_PAGE_NUMBER) ?? 1);
        const urlScheduledPageNumber = +(queryParams.get(QUERY_PARAM_KEYS.SCHEDULED_PAGE_NUMBER) ?? 1);
        const urlDraftPageNumber = +(queryParams.get(QUERY_PARAM_KEYS.DRAFT_PAGE_NUMBER) ?? 1);
        const urlDraftSubmissionNumber = +(queryParams.get(QUERY_PARAM_KEYS.SUBMISSION_PAGE_NUMBER) ?? 1);

        newUrl = newUrl + `?${QUERY_PARAM_KEYS.ALL_PAGE_NUMBER}=${allPageNumber ?? urlAllPageNumber}`;
        newUrl = newUrl + `&${QUERY_PARAM_KEYS.PUBLISHED_PAGE_NUMBER}=${publishedPageNumber ?? urlPublishedPageNumber}`;
        newUrl = newUrl + `&${QUERY_PARAM_KEYS.SCHEDULED_PAGE_NUMBER}=${scheduledPageNumber ?? urlScheduledPageNumber}`;
        newUrl = newUrl + `&${QUERY_PARAM_KEYS.DRAFT_PAGE_NUMBER}=${draftPageNumber ?? urlDraftPageNumber}`;
        newUrl = newUrl + `&${QUERY_PARAM_KEYS.SUBMISSION_PAGE_NUMBER}=${submissionPageNumber ?? urlDraftSubmissionNumber}`;
        newUrl = newUrl + `&${QUERY_PARAM_KEYS.TAB_NUMBER}=${tabNumber}`;

        return newUrl;
    }

    private fetchDrafts = (pageNumber: number, filters: Partial<PostFilterValues>, pageAmount?: number, skipUrlUpdate: boolean = false) => {
        if (!skipUrlUpdate) {
            let newUrl = this.generateUrl(this.state.selectedTab, undefined, undefined, undefined, pageNumber);
            window.history.replaceState(null, "", newUrl);
        }

        !this.props.draftPostList.isFetching &&
            this.props.fetchDraftPosts(pageNumber, filters, pageAmount);
    }

    private fetchExpired = (pageNumber: number, filters: Partial<PostFilterValues>) => {
        !this.props.expiredPostList.isFetching &&
            this.props.fetchExpiredPosts(pageNumber, filters);
    }

    private fetchPublished = (pageNumber: number, filters: Partial<PostFilterValues>, pageAmount?: number, skipUrlUpdate: boolean = false) => {
        if (!skipUrlUpdate) {
            let newUrl = this.generateUrl(this.state.selectedTab, undefined, pageNumber, undefined, undefined);
            window.history.replaceState(null, "", newUrl);
        }

        !this.props.publishedPostList.isFetching &&
            this.props.fetchPublishedPosts(pageNumber, filters, pageAmount);
    }

    private fetchScheduled = (pageNumber: number, filters: Partial<PostFilterValues>, pageAmount?: number, skipUrlUpdate: boolean = false) => {
        if (!skipUrlUpdate) {
            let newUrl = this.generateUrl(this.state.selectedTab, undefined, undefined, pageNumber, undefined);
            window.history.replaceState(null, "", newUrl);
        }

        !this.props.scheduledPostList.isFetching &&
            this.props.fetchScheduledPosts(pageNumber, filters, pageAmount);
    }

    private fetchAllPostList = (pageNumber: number, filters: Partial<PostFilterValues>, pageAmount?: number, skipUrlUpdate: boolean = false) => {
        if (!skipUrlUpdate) {
            let newUrl = this.generateUrl(this.state.selectedTab, pageNumber, undefined, undefined, undefined);
            window.history.replaceState(null, "", newUrl);
        }

        !this.props.allPostList.isFetching &&
            this.props.fetchAllPosts(pageNumber, filters, pageAmount);
    }

    private getTags = async () => {
        let tagsToSet: TenantSettingsTagGroup[] = [];

        tagsApi.getUserTags(true).then(response => {
            tagsToSet = response;
        }).catch((err) => {
            tagsToSet = [];
        }).finally(() => {
            this.setState({ availableTopics: tagsToSet });
        });
    }
}

interface ComponentProps {
}

interface ComponentState {
    showingPublishErrorsDialog: boolean;
    selectedTab: TAB_IDS;
    availableTopics: TenantSettingsTagGroup[];
    postCreatedByAnchorEl: any;
    filters: Partial<PostFilterValues>[];
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        tenant: state.tenant.id,
        allPostList: state.posts.allPostList,
        draftPostList: state.posts.draftPostList,
        expiredPostList: state.posts.expiredPostList,
        publishedPostList: state.posts.publishedPostList,
        scheduledPostList: state.posts.scheduledPostList,
        submissionPostList: state.posts.submissionPostList,
        deleting: state.posts.editor.deleting,
        errorMessage: state.posts.errorMessage,
        fetching: state.posts.editor.fetching,
        isInitialLoad: state.posts.isInitialLoad,
        publishing: state.posts.publishing,
        publishValidationErrors: state.posts.publishValidationErrors,
        shouldShowPublishedDialog: state.posts.publishConfirmation.shouldShowDialog,
        unpublishing: state.posts.unpublishing,
        shouldFetch: state.posts.shouldFetch,
        user: state.settings.currentUser,
        tenantId: state.tenant.id,
        shouldDisplayNav: state.adminLayout.shouldDisplayNav
    }),
    {
        clearErrorMessage: actions.clearPostAuthoringErrorMessage,
        clearShouldFetch: actions.clearShouldFetch,
        fetchAllPosts: actions.fetchAllPost,
        fetchDraftPosts: actions.fetchDraftPostList,
        fetchExpiredPosts: actions.FetchExpiredPosts,
        fetchPublishedPosts: actions.fetchPublishedPosts,
        fetchScheduledPosts: actions.fetchScheduledPosts,
        fetchSubmissionPosts: actions.fetchSubmissionPosts,
        redirectTo: push,
        setShouldDisplayNav
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(PostListing);
