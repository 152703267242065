import React from "react";
import MoreOptionsButton from "modules/common/components/buttons/moreOptionsButton";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import MoreOptionsItem from "modules/common/components/moreOptionsItem";


interface ComponentProps {
  onShowActivityLog: () => any;
  onShowGlobalSettings: () => any;
  onShowTemplateThemes: () => any;
}

export const MoreOptions: React.FunctionComponent<ComponentProps> = props => {
  return (
    <div>
      <MoreOptionsButton>
        <List disablePadding>
          <MoreOptionsItem
            text="Employee Activity Log"
            onClick={props.onShowActivityLog}
          />
          <Divider light />
          <MoreOptionsItem
            text="Newsletter Global Settings"
            onClick={props.onShowGlobalSettings}
          />
          <MoreOptionsItem
            text="Themes"
            onClick={props.onShowTemplateThemes}
          />
        </List>
      </MoreOptionsButton>
    </div>
  );
}