import React from "react";
import { ListItem, ListItemText } from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

import useSignOut from "modules/common/hooks/useSignOut";

const SignOutListItem: React.FunctionComponent<ISignOutProps> = (props) => {
    const signOutData = useSignOut();

    const signOutAsync = async () => {
        await signOutData.signOut();
    };

    return (
        <ListItem className={props.optionsMenuItemClassName} onClick={signOutAsync}>
            <ListItemText primary="Sign out" classes={{ primary: props.optionsMenuItemTextClassName }} />
            <ExitToAppIcon />
        </ListItem>
    );
};

interface ISignOutProps {
    optionsMenuItemClassName: string;
    optionsMenuItemTextClassName: string;
}

export default SignOutListItem;
