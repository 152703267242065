import { Action } from "redux";
import * as Actions from "./actions";
import { AudiencePage, AudiencesState } from "./models";


export const defaultPage: AudiencePage = {
  audiences: [],
  currentPage: 0,
  totalAudiences: 0,
  totalPages: 0,
  isFetching: false
};

const defaultState: AudiencesState = {
  audiences: [],
  all: { ...defaultPage },
  enabled: { ...defaultPage },
  disabled: { ...defaultPage  },
  changedSinceSaved: false,
  errorMessage: "",
  isDeleting: false,
  isFetching: false,
  isInitialLoad: true,
  isSaving: false,
  isUploading: false,
  shouldFetch: false
};

const actionHandler: { [actionType: string]: (state: AudiencesState, action: any) => AudiencesState } = {

  [Actions.GET_AUDIENCES_COMPLETE]: (state: AudiencesState, action: Actions.GetAudiencesComplete) => {
    return {
      ...state,
      audiences: action.audiences
    };
  },

  [Actions.GET_ALL_AUDIENCES]: (state: AudiencesState, action: Actions.GetAllAudiences) => {
    return {
      ...state,
      all: {
        ...state.all,
        isFetching: true
      }
    };
  },

  [Actions.GET_ALL_AUDIENCES_COMPLETE]: (state: AudiencesState, action: Actions.GetAllAudiencesComplete) => {
    return {
      ...state,
      all: {
        ...state.all,
        ...action.page,
        isFetching: false
      },
      isInitialLoad: false
    };
  },

  [Actions.GET_DISABLED_AUDIENCES]: (state: AudiencesState, action: Actions.GetDisabledAudiences) => {
    return {
      ...state,
      disabled: {
        ...state.disabled,
        isFetching: true
      }
    };
  },

  [Actions.GET_DISABLED_AUDIENCES_COMPLETE]: (state: AudiencesState, action: Actions.GetDisabledAudiencesComplete) => {
    return {
      ...state,
      disabled: {
        ...state.disabled,
        ...action.page,
        isFetching: false
      },
      isInitialLoad: false
    };
  },

  [Actions.GET_ENABLED_AUDIENCES]: (state: AudiencesState, action: Actions.GetEnabledAudiences) => {
    return {
      ...state,
      enabled: {
        ...state.enabled,
        isFetching: true
      }
    };
  },

  [Actions.GET_ENABLED_AUDIENCES_COMPLETE]: (state: AudiencesState, action: Actions.GetEnabledAudiencesComplete) => {
    return {
      ...state,
      enabled: {
        ...state.enabled,
        ...action.page,
        isFetching: false
      },
      isInitialLoad: false
    };
  },


  [Actions.CREATE_AUDIENCE_COMPLETE]: (state: AudiencesState, action: Actions.CreateAudienceComplete) => {
    return {
      ...state,
      errorMessage: action.succeeded ? "" : "Unable to create new audience",
      shouldFetch: action.succeeded
    };
  },
  
  [Actions.DELETE_AUDIENCE]: (state: AudiencesState, action: Actions.DeleteAudience) => {
    return {
      ...state,
      isDeleting: true
    };
  },
  
  [Actions.DELETE_AUDIENCE_COMPLETE]: (state: AudiencesState, action: Actions.DeleteAudienceComplete) => {
    return {
      ...state,
      errorMessage: action.succeeded ? "" : "Unable to delete audience",
      isDeleting: false,
      shouldFetch: action.succeeded
    };
  },

  [Actions.DELETE_AUDIENCES]: (state: AudiencesState, action: Actions.DeleteAudiences) => {
    return {
      ...state,
      isDeleting: true
    };
  },

  [Actions.DELETE_AUDIENCES_COMPLETE]: (state: AudiencesState, action: Actions.DeleteAudiencesComplete) => {
    return {
      ...state,
      errorMessage: action.succeeded ? "" : "Unable to delete audience(s)",
      isDeleting: false,
      shouldFetch: action.succeeded
    };
  },

  [Actions.DISABLE_AUDIENCE]: (state: AudiencesState, action: Actions.DisableAudience) => {
    return {
      ...state,
      isSaving: true
    };
  },

  [Actions.DISABLE_AUDIENCE_COMPLETE]: (state: AudiencesState, action: Actions.DisableAudienceComplete) => {
    return {
      ...state,
      errorMessage: action.succeeded ? "" : "Unable to disable audience",
      isSaving: false,
      shouldFetch: action.succeeded
    };
  },

  [Actions.DISABLE_AUDIENCES]: (state: AudiencesState, action: Actions.DisableAudiences) => {
    return {
      ...state,
      isSaving: true
    };
  },

  [Actions.DISABLE_AUDIENCES_COMPLETE]: (state: AudiencesState, action: Actions.DisableAudiencesComplete) => {
    return {
      ...state,
      errorMessage: action.succeeded ? "" : "Unable to disable audience(s)",
      isSaving: false,
      shouldFetch: action.succeeded
    };
  },

  [Actions.DOWNLOAD_USER_DATA]: (state: AudiencesState, action: Actions.DownloadUserData) => {
    return {
      ...state,
      isFetching: true
    };
  },

  [Actions.DOWNLOAD_USER_DATA_COMPLETE]: (state: AudiencesState, action: Actions.DownloadUserDataComplete) => {
    return {
      ...state,
      errorMessage: action.succeeded ? "" : "Unable to download user data",
      isFetching: false
    };
  },

  [Actions.ENABLE_AUDIENCE]: (state: AudiencesState, action: Actions.EnableAudience) => {
    return {
      ...state,
      isSaving: true
    };
  },

  [Actions.ENABLE_AUDIENCE_COMPLETE]: (state: AudiencesState, action: Actions.EnableAudienceComplete) => {
    return {
      ...state,
      errorMessage: action.succeeded ? "" : "Unable to enable audience",
      isSaving: false,
      shouldFetch: action.succeeded
    };
  },

  [Actions.ENABLE_AUDIENCES]: (state: AudiencesState, action: Actions.EnableAudiences) => {
    return {
      ...state,
      isSaving: true
    };
  },

  [Actions.ENABLE_AUDIENCES_COMPLETE]: (state: AudiencesState, action: Actions.EnableAudiencesComplete) => {
    return {
      ...state,
      errorMessage: action.succeeded ? "" : "Unable to enable audience(s)",
      isSaving: false,
      shouldFetch: action.succeeded
    };
  },

  [Actions.SAVE_AUDIENCE_COMPLETE]: (state: AudiencesState, action: Actions.SaveAudienceComplete) => {
    return {
      ...state,
      errorMessage: action.succeeded ? "" : "Unable to save changes",
      shouldFetch: action.succeeded
    };
  },


  [Actions.CHANGED_SINCE_SAVED]: (state: AudiencesState, action: Actions.ChangedSinceSaved) => {
    return {
      ...state,
      changedSinceSaved: true
    };
  },

  [Actions.CLEAR_CHANGED_SINCE_SAVED]: (state: AudiencesState, action: Actions.ChangedSinceSaved) => {
    return {
      ...state,
      changedSinceSaved: false
    };
  },

  [Actions.CLEAR_ERROR_MESSAGE]: (state: AudiencesState, action: Actions.ClearErrorMessage) => {
    return {
      ...state,
      errorMessage: ""
    };
  },

  [Actions.CLEAR_SHOULD_FETCH]: (state: AudiencesState, action: Actions.ClearShouldFetch) => {
    return {
      ...state,
      shouldFetch: false
    };
  }

}

export const reducer = (state: AudiencesState, action: Action) => {
  const actionHandlerMethod = actionHandler[action.type];
  if (actionHandlerMethod) {
    return actionHandlerMethod(state, action);
  }
  return state || defaultState;
};
