import React from "react";
import { AppBar, Divider, Theme, Toolbar } from "@mui/material";
import RestoreIcon from '@mui/icons-material/Restore';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

interface IAuthoringAppBar {
    backAction: JSX.Element;
    actions: JSX.Element;
    isSubmissionMode: boolean;
}

const useStylesAppBar = makeStyles<Theme>((theme: Theme) =>
    createStyles({
        header: {
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.primary,
            zIndex: 0,
        },
        wordmark: {
            width: 28,
            height: 28,
        },
        wordMarkContainer: {
            backgroundColor: "#dde1e5",
            height: 50,
            width: 50,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center"
        },
        toolbarActionsContainer: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingLeft: 17,
            paddingRight: 21,
            flex: 1,
            backgroundColor: "#f2f2f2"
        },
        authorActionsContainer: {
            marginLeft: "auto",
            display: "flex",
            alignItems: "center"
        },
        restoreIcon: {
            color: "#d3d3d3",
            marginTop: "auto",
            marginBottom: "auto",
            marginLeft: "5px"
        }
    })
);

const AuthoringAppBar: React.FunctionComponent<IAuthoringAppBar> = ({
    backAction,
    actions,
    isSubmissionMode,
}) => {
    const classes = useStylesAppBar();

    return (
        <AppBar elevation={0} className={classes.header}>
            <Toolbar variant="dense" disableGutters>
                <div className={classes.wordMarkContainer}>
                    <img src={`${process.env.PUBLIC_URL}/images/icons/sparrow-connected-monomark.svg`} alt="wordmark" className={classes.wordmark} />
                </div>
                <div className={classes.toolbarActionsContainer}>
                    {backAction}
                    {
                        isSubmissionMode &&
                        <>
                            <Divider variant="fullWidth" orientation="vertical" flexItem />
                            <RestoreIcon className={classes.restoreIcon} />
                        </>
                    }
                    <div className={classes.authorActionsContainer}>
                        {actions}
                    </div>
                </div>
            </Toolbar>
        </AppBar>
    );
}

export default AuthoringAppBar;
