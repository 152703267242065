import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { GlobalApplicationState } from 'globalApplicationState';

import { NotificationRange, Notifications, Reminder, ReminderChannel } from "./models";

import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Collapse from "@mui/material/Collapse";
import Drawer from "@mui/material/Drawer";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";

import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";


class Reminders extends React.Component<PropsWithRedux, ComponentState> {
    constructor(props: PropsWithRedux) {
      super(props);
      
      this.state = {
        reminders: props.isPreviouslyPublished ? this.getReminders() : [this.getDefaultReminder()]
      };
    }

    public componentDidUpdate(prevProps: PropsWithRedux) {
      if (this.props.show && !prevProps.show) {
        this.setState({
          reminders: !!this.props.notifications
            ? this.getReminders()
            : [this.getDefaultReminder()]
        });
      }
    }

    public render() {
      if (!this.props.show || !this.props.notifications || !this.props.notificationSettings)
        return <React.Fragment></React.Fragment>;

      const { type } = this.props;

      const settings = this.props.notificationSettings.settings;
      const reminderSettingsEnabled = settings[`${type}ReminderEmailAttempt`]?.toggle || settings[`${type}ReminderMobileAttempt`]?.toggle || settings[`${type}ReminderSMSAttempt`]?.toggle || settings[`${type}ReminderTeamsAttempt`]?.toggle;

      if (!reminderSettingsEnabled)
        return <React.Fragment></React.Fragment>;
      
      return (
        <Drawer anchor="right" open={true} onClose={this.onClose} classes={{ paper: "authoring-drawer-panel" }}>
          <Paper square elevation={0} className="authoring-drawer-panel-header">
            <IconButton onClick={this.onClose} size="large">
              <ArrowBackIcon />
            </IconButton>
            <div>More {type} reminder settings</div>
          </Paper>
          <div className="authoring-drawer-panel-content">
            <Paper elevation={0} className="publishing-reminders">
              <div className="drawer-panel-section-header">
                <div>Send reminder if {type} is unread</div>
                <Switch
                  color="primary"
                  checked={!!this.state.reminders.length}
                  onClick={this.onToggleRemindersEnabled}
                />
              </div>
              <Collapse in={!!this.state.reminders.length}>
                <div>
                  <div className="description">
                    Remind employees if the {type} is still unread after a set amount of time. Add/edit additional reminder channels:
                  </div>
                  <div className="reminders">
                    {this.state.reminders.map((reminder, index) =>
                      <Collapse key={index} in={true} appear={index !== 0} classes={{ wrapperInner: "reminder" }}>
                        <Paper elevation={0} className="reminder-setting">
                          <div className="reminder-label">Reminder {index + 1}</div>
                          <div>
                            <div className="reminder-time">
                              <div>If unread, send reminder after</div>
                              <TextField
                                variant="outlined"
                                type="number"
                                size="small"
                                placeholder="Enter no."
                                value={reminder.delay || ""}
                                onChange={(ev) => this.onChangeReminderDelay(index, ev.target.value as unknown as number)}
                                className="reminder-time-input"
                              />
                              <FormControl size="small">
                                <Select
                                  variant="outlined"
                                  value={reminder.delayType}
                                  onChange={(ev) => this.onChangeReminderDelayType(index, ev.target.value as DelayType)}
                                  className="reminder-time-type"
                                >
                                  <MenuItem value="hours">hours</MenuItem>
                                  <MenuItem value="days">days</MenuItem>
                                </Select>
                              </FormControl>
                            </div>
                            <div>
                              <RadioGroup>
                                <FormControlLabel
                                  control={
                                    <Radio
                                      color="primary"
                                      checked={!reminder.customChannels}
                                      onClick={() => this.onSetNotificationReminderChannels(index)}
                                    />
                                  }
                                  label={`Use channels selected in ${type} publishing notifications`}
                                />
                                <FormControlLabel
                                  control={
                                    <Radio
                                      color="primary"
                                      checked={reminder.customChannels}
                                      onClick={() => this.onSetCustomReminderChannels(index)}
                                    />
                                  }
                                  label="Custom channel options"
                                />
                              </RadioGroup>
                              <Collapse in={reminder.customChannels}>
                                <div className="custom-reminder">
                                  {settings[`${type}ReminderTeamsAttempt`]?.toggle &&
                                    <div>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            color="primary"
                                            checked={reminder.teamsRange !== "none"}
                                            onChange={(ev, checked) => this.onChangeReminderChannel(checked, index, "teams")}
                                          />
                                        }
                                        label="MS Teams Chatbot"
                                      />
                                    </div>
                                  }
                                  {settings[`${type}ReminderMobileAttempt`]?.toggle &&
                                    <div>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            color="primary"
                                            checked={reminder.mobileRange !== "none"}
                                            onChange={(ev, checked) => this.onChangeReminderChannel(checked, index, "mobile")}
                                          />
                                        }
                                        label="Mobile App"
                                      />
                                    </div>
                                  }
                                  {settings[`${type}ReminderEmailAttempt`]?.toggle &&
                                    <div>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            color="primary"
                                            checked={reminder.sendEmail}
                                            onChange={(ev, checked) => this.onChangeReminderChannel(checked, index, "email")}
                                          />
                                        }
                                        label="Email"
                                      />
                                      <FormControl size="small" fullWidth>
                                        <Select
                                          variant="outlined"
                                          fullWidth
                                          value={reminder.emailRange}
                                          disabled={!reminder.sendEmail}
                                          onChange={(ev) => this.onChangeReminderRange(index, "email", ev.target.value as NotificationRange)}
                                          className="custom-reminder-type"
                                        >
                                          <MenuItem value="fallback">Only if standard app notifications are not set up</MenuItem>
                                          <MenuItem value="subscribers">To all topic subscribers</MenuItem>
                                        </Select>
                                      </FormControl>
                                    </div>
                                  }
                                  {settings[`${type}ReminderSMSAttempt`]?.toggle &&
                                    <div>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            color="primary"
                                            checked={reminder.sendSMS}
                                            onChange={(ev, checked) => this.onChangeReminderChannel(checked, index, "sms")}
                                          />
                                        }
                                        label="SMS"
                                      />
                                      <FormControl size="small" fullWidth>
                                        <Select
                                          variant="outlined"
                                          fullWidth
                                          value={reminder.smsRange}
                                          disabled={!reminder.sendSMS}
                                          onChange={(ev) => this.onChangeReminderRange(index, "sms", ev.target.value as NotificationRange)}
                                          className="custom-reminder-type"
                                        >
                                          <MenuItem value="fallback">Only if standard app notifications or email are not set up</MenuItem>
                                          <MenuItem value="subscribers">To all topic subscribers</MenuItem>
                                        </Select>
                                      </FormControl>
                                    </div>
                                  }
                                </div>
                              </Collapse>
                            </div>
                          </div>
                        </Paper>
                        {index !== 0 &&
                          <IconButton size="small" onClick={() => this.onDeleteReminder(index)}><DeleteIcon fontSize="small" /></IconButton>
                        }
                      </Collapse>
                    )}
                    {!!this.state.reminders.length && this.state.reminders.length < 5 &&
                      <Button variant="text" color="primary" size="small" startIcon={<AddIcon fontSize="small" />} disabled={!this.state.reminders[this.state.reminders.length - 1].delay} onClick={this.onAddReminder}>Add Reminder</Button>
                    }
                  </div>
                </div>
              </Collapse>
            </Paper>
          </div>
        </Drawer>
      );
    }


    private getDefaultReminder = (): ReminderOption => {
      const { type } = this.props;
      const defaultReminder = this.props.notificationSettings[`default${type.charAt(0).toUpperCase() + type.substr(1)}Reminder`];

      const reminder: number = defaultReminder || 0;
      const delay: number = reminder % 24 === 0 ? reminder / 24 : reminder;
      const delayType: DelayType = reminder > 0 && reminder % 24 === 0 ? "days" : "hours";

      let reminderOption: ReminderOption = {
        customChannels: false,
        delay: delay,
        delayType: delayType,
        emailRange: "fallback",
        mobileRange: "none",
        sendEmail: false,
        sendSMS: false,
        smsRange: "fallback",
        teamsRange: "none"
      };

      reminderOption.customChannels = this.getDefaultCustomerChannels();

      return reminderOption;
    }

    private getDefaultCustomerChannels = () => {

        return !!this.props.notifications && (this.props.notifications.emailOnPublish === "none" && this.props.notifications.mobileOnPublish === "none" && this.props.notifications.smsOnPublish === "none" && this.props.notifications.teamsOnPublish === "none");
    }

    private getReminders = (): ReminderOption[] => {
      if (!!this.props.notifications && !!this.props.notifications.reminders) {
        if (!this.props.notifications.reminders.length)
          return [this.getDefaultReminder()];
        
        let reminders: ReminderOption[] = [];
        this.props.notifications.reminders.map((reminder, index) => {
          const delay: number = reminder.delay % 24 === 0 ? reminder.delay / 24 : reminder.delay;
          const delayType: DelayType = reminder.delay > 0 && reminder.delay % 24 === 0 ? "days" : "hours";

          /** Parse out "default" channels if returned from the API */
          if (reminder.channel as string === "default")
            return reminder;

          if (reminder.channel === "none") {
            reminders.push({
              customChannels: true,
              delay: delay,
              delayType: delayType,
              emailRange: "fallback",
              mobileRange: "none",
              sendEmail: false,
              sendSMS: false,
              smsRange: "fallback",
              teamsRange: "none"
            });
          } else if (reminder.channel === "inherit") {
            reminders.push({
              customChannels: false,
              delay: delay,
              delayType: delayType,
              emailRange: "fallback",
              mobileRange: "none",
              sendEmail: false,
              sendSMS: false,
              smsRange: "fallback",
              teamsRange: "none"
            });
          } else {
            if (index > 0 && (reminders[reminders.length - 1].delay === delay && reminders[reminders.length - 1].delayType === delayType)) {
              if (reminder.channel === "email") {
                reminders[reminders.length - 1].emailRange = reminder.range;
                reminders[reminders.length - 1].sendEmail = true;
              } else if (reminder.channel === "mobile") {
                reminders[reminders.length - 1].mobileRange = reminder.range;
              } else if (reminder.channel === "sms") {
                reminders[reminders.length - 1].sendSMS = true;
                reminders[reminders.length - 1].smsRange = reminder.range;
              } else if (reminder.channel === "teams") {
                reminders[reminders.length - 1].teamsRange = reminder.range;
              }
            } else {
              reminders.push({
                customChannels: true,
                delay: delay,
                delayType: delayType,
                emailRange: reminder.channel === "email" ? reminder.range : "subscribers",
                mobileRange: reminder.channel === "mobile" ? reminder.range : "none",
                sendEmail: reminder.channel === "email",
                sendSMS: reminder.channel === "sms",
                smsRange: reminder.channel === "sms" ? reminder.range : "subscribers",
                teamsRange: reminder.channel === "teams" ? reminder.range : "none"
              });
            }
          }
          return reminder;
        });

        return reminders;
      }

      return [this.getDefaultReminder()];
    }


    private onAddReminder = () => {
      this.setState({ reminders: this.state.reminders.concat([ this.getDefaultReminder() ]) })
    }

    private onApplyReminderSettings = () => {
      let reminders: Reminder[] = [];

      this.state.reminders
        .filter((reminder) => !!reminder.delay)
        .map((reminder) => {
          const delay: number = reminder.delayType === "hours" ? reminder.delay! : reminder.delay! * 24;

          if (!reminder.customChannels) {
            reminders.push({ channel: "inherit", delay, range: "subscribers" });
          } else if (!reminder.sendEmail && reminder.mobileRange === "none" && !reminder.sendSMS && reminder.teamsRange === "none") {
            reminders.push({ channel: "none", delay, range: "fallback" });
          } else {
            if (reminder.sendEmail)
              reminders.push({ channel: "email", delay, range: reminder.emailRange });
            if (reminder.mobileRange !== "none")
              reminders.push({ channel: "mobile", delay, range: reminder.mobileRange });
            if (reminder.sendSMS)
              reminders.push({ channel: "sms", delay, range: reminder.smsRange });
            if (reminder.teamsRange !== "none")
              reminders.push({ channel: "teams", delay, range: reminder.teamsRange });
          }
          return reminder;
        });

      const notifications: Notifications = {
        ...this.props.notifications!,
        reminders: reminders
      };
      this.props.onChangeNotifications(notifications);
    }
    
    private onChangeReminderChannel = (checked: boolean, reminderIndex: number, channel: ReminderChannel) => {
      const reminders: ReminderOption[] = this.state.reminders.map((reminder, index) => {
        if (index === reminderIndex) {
          if (channel === "email")
            return { ...reminder, sendEmail: checked };
          else if (channel === "mobile")
            return { ...reminder, mobileRange: checked ? "subscribers" : "none" };
          else if (channel === "sms")
            return { ...reminder, sendSMS: checked };
          else if (channel === "teams")
            return { ...reminder, teamsRange: checked ? "subscribers" : "none" };
          return reminder;
        }
        return reminder;
      });

      this.setState({ reminders });
    }

    private onChangeReminderDelay = (reminderIndex: number, delay: number) => {
      const reminders: ReminderOption[] = this.state.reminders.map((reminder, index) => {
        if (index === reminderIndex)
          return { ...reminder, delay: isNaN(delay) ? 0 : delay };
        return reminder;
      });

      this.setState({ reminders });
    }

    private onChangeReminderDelayType = (reminderIndex: number, delayType: DelayType) => {
      const reminders: ReminderOption[] = this.state.reminders.map((reminder, index) => {
        if (index === reminderIndex)
          return { ...reminder, delayType };
        return reminder;
      });

      this.setState({ reminders });
    }

    private onChangeReminderRange = (reminderIndex: number, channel: ReminderChannel, range: NotificationRange) => {
      const reminders: ReminderOption[] = this.state.reminders.map((reminder, index) => {
        if (index === reminderIndex) {
          if (channel === "email")
            return { ...reminder, emailRange: range };
          else if (channel === "sms")
            return { ...reminder, smsRange: range };
          return reminder;
        }
        return reminder;
      });

      this.setState({ reminders });
    }

    private onClose = () => {
      this.onApplyReminderSettings();
      this.props.onClose();
    }

    private onDeleteReminder = (reminderIndex: number) => {
      let reminders: ReminderOption[] = this.state.reminders.slice();
      reminders.splice(reminderIndex, 1);
      this.setState({ reminders });
    }

    private onSetCustomReminderChannels = (reminderIndex: number) => {
      const reminders: ReminderOption[] = this.state.reminders.map((reminder, index) => {
        if (index === reminderIndex)
          return { ...reminder, customChannels: true };
        return reminder;
      });

      this.setState({ reminders });
    }

    private onSetNotificationReminderChannels = (reminderIndex: number) => {
      const reminders: ReminderOption[] = this.state.reminders.map((reminder, index) => {
        if (index === reminderIndex)
          return { ...reminder, customChannels: false };
        return reminder;
      });
      
      this.setState({ reminders });
    }

    private onToggleRemindersEnabled = () => {
      if (!!this.state.reminders.length) {
        this.setState({ reminders: [] });
      } else {
        this.setState({ reminders: [this.getDefaultReminder()] });
      }
    }
}


interface ReminderOption {
  customChannels: boolean;
  delay: number | undefined;
  delayType: DelayType;
  emailRange: NotificationRange;
  mobileRange: NotificationRange;
  sendEmail: boolean;
  sendSMS: boolean;
  smsRange: NotificationRange;
  teamsRange: NotificationRange;
}

type DelayType = "hours" | "days";


interface ComponentProps {
  show: boolean;
  isPreviouslyPublished: boolean;
  notifications: Notifications | undefined;
  type: "post" | "event";
  onChangeNotifications: (notifications: Notifications) => void;
  onClose: () => void;
}

interface ComponentState {
  reminders: ReminderOption[];
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    notificationSettings: state.settings.notificationSettings,
    tenantSettings: state.settings.tenantSettings,
  })
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(Reminders);