import * as React from 'react';
import { connect, ConnectedProps } from "react-redux";
import * as actions from "../../actionCreator";
import { GlobalApplicationState } from "../../../../globalApplicationState"
import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { CategoryTagDetails } from '../../models';
import CloseIcon from "@mui/icons-material/Close";
import UserInsightsBlockTiny from 'modules/users/components/userInsightsBlockTiny';
import ToggleFilter from 'modules/common/components/toggleFilter';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import MoreOptions from "./moreOptions";
import Loading from 'modules/common/components/loading';

interface ComponentState {
    categoryTag: CategoryTagDetails;
    selectedFilter: number;
}

const defaultTag = {
    id: "",
    title: "Category Tag Details",
    description: "Category Tag Description",
    accessAudience: [''],
    accessCount: 0,
    notifiedAudience: [''],
    notifyCount: 0,
    totalUsage: 0,
    usageRanking: 0,
    awareness: 0,
    analyticsAllTime: {
        surveyUsage: -1,
        documentUsage: -1,
        quickLinkUsage: -1,
        mediaFileUsage: -1
    },
    analyticsLast30: {
        surveyUsage: -1,
        documentUsage: -1,
        quickLinkUsage: -1,
        mediaFileUsage: -1
    }
}

const defaultViewtag = {
    id: "",
    title: "",
    description: "",
    language: "",
    enabled: true,
    defaultAudienceAccess: [],
    defaultAudienceNotified: [],
    totalUsage: 0,
    usageRanking: 0,
    awareness: 0,
    createdDate: "",
    lastModifiedBy: {
        name: "",
        userId: ""
    }
}

const filterOptions = ["Since Created", "In The Last 30 Days"]

class EditCategoryTagDialog extends React.Component<PropsWithRedux, ComponentState> {
    constructor(props: PropsWithRedux) {
      super(props);

      this.state = {
          categoryTag: defaultTag,
          selectedFilter: 0
      };
    }

    componentDidUpdate(prevProps: PropsWithRedux) {
        if (this.props.viewCategoryTag && !prevProps.viewCategoryTag) {
            this.loadData();
        }
    }


    public render() {
        const categoryTag = this.props.tagToView ?? defaultViewtag ;

        if(this.props.isLoadingDetails)
        {
            return(
                <Dialog open={true} maxWidth={false} onClose={this.onClose}>
                    <Loading padding={12} />
                </Dialog>
            )
        }

        return (
            <Dialog open={this.props.viewCategoryTag} maxWidth={false} onClose={this.onClose}>
                <DialogTitle className="portal-pages-dialog-header">
                    <div className="portal-pages-dialog-header-title">
                        <Typography variant="h2">{this.state.categoryTag.title}</Typography>
                        <div style={{display: "flex", flexDirection: "row"}}>
                            <MoreOptions categoryTag={categoryTag} categoryTagAnalytics={this.state.categoryTag} closeDialog={this.onClose} />
                            <IconButton onClick={this.onClose} style={{float: "right"}} size="large">
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent className="category-tag-dialog-content" style={{paddingTop: "10px", paddingBottom: "10px"}}>
                    <div className="category-tag-dialog-content-form"></div>
                    <div>
                        <strong>Key Insights</strong>
                    </div>
                    <div className="category-tag-data-container">
                        <div className="category-tag-data-display">
                            <UserInsightsBlockTiny
                                stats={this.state.categoryTag.totalUsage}
                                title={"Total uses"}
                            />
                        </div>
                        {/* Hiding for this release, can populate with data later.
                        <div className="category-tag-data-display">
                            <UserInsightsBlockTiny
                                stats={this.getNumberWithOrdinal(this.state.categoryTag.usageRanking)}
                                title={"Most used tag"}
                            />
                        </div>
                        <div className="category-tag-data-display">
                            <UserInsightsBlockTiny
                                stats={this.state.categoryTag.totalUsage}
                                title={"Awareness"}
                            />
                        </div>
                        */}
                    </div>
                    <div style={{marginTop: '15px', marginBottom: '20px'}}>
                        <strong>Uses per content type</strong>
                        <div style={{float: "right"}}>
                            <ToggleFilter
                                filterOptions={filterOptions.map((label: string) => ({ label }))}
                                selectedIndex={this.state.selectedFilter}
                                setSelectedIndex={(e) => this.setSelectedfilter(e)}
                            />
                        </div>
                    </div>
                    <div className="category-tag-data-container">
                        {/* Add once the feature exists.
                        <div className="category-tag-data-display">
                            <UserInsightsBlockTiny
                                icon={<BarChartIcon fontSize={"large"} color={"disabled"} className="category-tag-icon-positioning"/>}
                                stats={this.state.selectedFilter === 0 ? this.state.categoryTag.analyticsAllTime.surveyUsage : this.state.categoryTag.analyticsLast30.surveyUsage }
                                title={"Surveys"}
                            />
                        </div>
                        */}
                        <div className="category-tag-data-display">
                            <UserInsightsBlockTiny
                                icon={<PictureAsPdfIcon fontSize={"large"} color={"disabled"} className="category-tag-icon-positioning"/>}
                                stats={this.state.selectedFilter === 0 ? this.state.categoryTag.analyticsAllTime.documentUsage : this.state.categoryTag.analyticsLast30.documentUsage }
                                title={"Documents"}
                            />
                        </div>
                        {/* Add once the feature exists.
                        <div className="category-tag-data-display">
                            <UserInsightsBlockTiny
                                icon={<LinkIcon fontSize={"large"} color={"disabled"} className="category-tag-icon-positioning"/>}
                                stats={this.state.selectedFilter === 0 ? this.state.categoryTag.analyticsAllTime.quickLinkUsage : this.state.categoryTag.analyticsLast30.quickLinkUsage }
                                title={"Quick Links"}
                            />
                        </div>
                        <div className="category-tag-data-display">

                            <UserInsightsBlockTiny
                                icon={<ImageIcon fontSize={"large"} color={"disabled"} className="category-tag-icon-positioning"/>}
                                stats={this.state.selectedFilter === 0 ? this.state.categoryTag.analyticsAllTime.mediaFileUsage : this.state.categoryTag.analyticsLast30.mediaFileUsage }
                                title={"Media Files"}
                            />
                        </div>
                        */}
                    </div>
                    <hr color={"#dde1e5"}/>

                    <div className="category-tag-text-display">
                        <span className="category-tag-text-title">Description</span>
                        <span className="category-tag-text-description">{this.state.categoryTag.description}</span>
                    </div>

                    <div className="category-tag-text-display">
                        <span className="category-tag-text-title">Total employees with access</span>
                        <span className="category-tag-text-description">{this.state.categoryTag.accessCount}</span>
                    </div>

                    <div className="category-tag-text-display">
                        <span className="category-tag-text-title">Default access audiences</span>
                        <span className="category-tag-text-description">{this.state.categoryTag.accessAudience.map(a => a).join(", ")}</span>
                    </div>

                    <div className="category-tag-text-display">
                        <span className="category-tag-text-title">Total employees notified</span>
                        <span className="category-tag-text-description">{this.state.categoryTag.notifyCount}</span>
                    </div>

                    <div className="category-tag-text-display">
                        <span className="category-tag-text-title">Default notified audiences</span>
                        <span className="category-tag-text-description">{this.state.categoryTag.notifiedAudience.map(a => a).join(", ")}</span>
                    </div>

                    <div style={{height: "15px"}}/>
                </DialogContent>
            </Dialog>
        );
    }

    private onClose = () => {
        this.props.hideViewCategoryTag();
    }

    private getNumberWithOrdinal(number) {
        var ordinals = ["th", "st", "nd", "rd"],
            v = number % 100;
        return number + (ordinals[(v - 20) % 10] || ordinals[v] || ordinals[0]);
    }

    private setSelectedfilter(e) {
        this.setState({selectedFilter: e})
    }

    private async loadData() {
        var data = await this.props.loadTagDetails(this.props.tagToLoadId);

        if(data !== null)
        {
            var newCategoryTag = defaultTag;

            newCategoryTag.title = data["title"] ?? "";
            newCategoryTag.description = data["description"] ?? "";
            newCategoryTag.awareness = data["awareness"] ?? 0;
            newCategoryTag.id = data["id"] ?? "";
            newCategoryTag.awareness = data["awareness"] ?? 0;
            newCategoryTag.totalUsage = data["totalUsage"] ?? 0;
            newCategoryTag.usageRanking = data["usageRanking"] ?? 0;
            newCategoryTag.notifiedAudience = data["notifiedAudience"] ?? [];
            newCategoryTag.notifyCount = data["notifyCount"] ?? 0;
            newCategoryTag.accessAudience = data["accessAudience"] ?? [];
            newCategoryTag.accessCount = data["accessCount"] ?? 0;
            newCategoryTag.analyticsAllTime = data["analyticsAllTime"] ?? defaultTag.analyticsAllTime;
            newCategoryTag.analyticsLast30 = data["analyticsLast30"] ?? defaultTag.analyticsLast30;

            if(newCategoryTag.notifiedAudience.length === 0)
            {
                newCategoryTag.notifiedAudience = ["None(0)"];
            }

            this.setState({categoryTag: newCategoryTag});
        }
    }
}


interface ComponentProps {
}

const connector = connect(
(state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    viewCategoryTag: state.categoryTags.showViewCategoryTag,
    tagToLoadId: state.categoryTags.tagToViewId,
    tagToView: state.categoryTags.tagToView,
    isLoadingDetails: state.categoryTags.isLoadingDetails
}),
{
    hideViewCategoryTag: actions.hideViewCategoryTag,
    loadTagDetails: actions.loadCategoryTagAnalytics,
}
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(EditCategoryTagDialog);
