import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import * as actions from "../../actionCreator";
import { GlobalApplicationState } from "globalApplicationState";
import { CategoryTagModel } from "modules/categorytags/models";

import { Audience } from "modules/audiences/models";

import "../../categoryTags.sass"
import "../../styles/dialogs.sass"

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Chip from "@mui/material/Chip";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import AddIcon from "@mui/icons-material/Add";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import CloseIcon from "@mui/icons-material/Close";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Switch from "@mui/material/Switch";
import InfoHover from "modules/common/components/hovers/infoHover";

import AudiencesDrawer from "./audiencesDrawer";

class EditCategoryTagDialog extends React.Component<PropsWithRedux, ComponentState> {
  constructor(props: PropsWithRedux) {
    super(props);

    this.state = {
      audiences: [],
      visibleAudiences: [],
      notifiedAudiences: [],
      categoryTagTitle: "",
      categoryTagTitleIsValid: true,
      categoryTagDescription: "",
      categoryTagDescriptionIsValid: true,
      categoryTagLanguage: "",
      categoryTagEnabled: true,
      showAudiencesList: false,
      showNotifyAudiencesList: false,
      showVisibleAudiencesList: false,
    };
  }

  componentDidUpdate(prevProps: PropsWithRedux) {
    if (this.props.editCategoryTag && !prevProps.editCategoryTag) {

      var accessAudiences = this.props.tagToEdit?.defaultAudienceAccess ?? [];
      var notifyAudiences = this.props.tagToEdit?.defaultAudienceNotified ?? [];

      var accessToSet = this.props.audiences.filter(a => accessAudiences.includes(a.id));
      var notifyToSet = this.props.audiences.filter(a => notifyAudiences.includes(a.id));

      this.setState({
        audiences: [],
        visibleAudiences: accessToSet,
        notifiedAudiences: notifyToSet,
        categoryTagTitle: this.props.tagToEdit?.title ?? "",
        categoryTagTitleIsValid: true,
        categoryTagDescription: this.props.tagToEdit?.description ?? "",
        categoryTagDescriptionIsValid: true,
        categoryTagLanguage: this.props.tagToEdit?.language ?? "",
        categoryTagEnabled: this.props.tagToEdit?.enabled ?? true,
        showNotifyAudiencesList: false,
        showVisibleAudiencesList: false,
      });
    }
  }

  
  public render() {
    const validLanguages = Object.keys(this.props.lcid);
    var languageOptions:string[] = [];
    validLanguages.forEach((lcid, index) => {
        var toAdd = this.props.lcidMappings[lcid].language
        languageOptions.push(toAdd);
    })

    return (
      <Dialog open={this.props.editCategoryTag} maxWidth={false} onClose={this.onClose}>
        <DialogTitle className="category-tags-dialog-header">
          <div className="category-tags-dialog-header-title">
            <Typography variant="h2">Edit category tag</Typography>
            <IconButton onClick={this.onClose} size="large">
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent className="category-tag-dialog-content">
          <div className="category-tag-dialog-content-form">
            <div>
              <div className="category-tag-prompt-left">Category tag title</div>
              <TextField
                variant="outlined"
                size="small"
                value={this.state.categoryTagTitle}
                autoComplete="off"
                error={!this.state.categoryTagTitleIsValid}
                fullWidth
                inputProps={{
                  maxLength: 25
                }}
                onChange={this.onChangeTitle}
              />
            </div>
            <div>
              <div className="category-tag-prompt-left">Description</div>
              <TextField
                variant="outlined"
                size="small"
                value={this.state.categoryTagDescription}
                autoComplete="off"
                error={!this.state.categoryTagDescriptionIsValid}
                fullWidth
                onChange={this.onChangeDescription}
              />
            </div>
            { languageOptions.length > 1 &&
              <div>
                  <div className="category-tag-prompt-left">Language</div>
                  <Select className="category-tag-language-select"
                      onChange={(e) => this.onChangeLanguage(e)}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left"
                        },
                      }}
                      value={this.state.categoryTagLanguage}
                      variant={"outlined"}
                  >
                      {languageOptions.map((l, index) => (
                        <MenuItem key={index} value={l}>{l}</MenuItem>
                      ))}
                  </Select>
              </div>
            }
            <div>
                <div className="category-tag-prompt-left">Enabled</div>
                <Switch
                  checked={this.state.categoryTagEnabled}
                  onClick={() => this.onChangeEnabled(!this.state.categoryTagEnabled)}
                  color="primary"
                  className="category-tag-enable"
                  size="medium"
                />
            </div>
            <div>
              <div className="audiences-list-label">
                Default access audiences
                <InfoHover>Default audiences who can access contents that use this tag. These audiences can still be edited when creating new content.</InfoHover>
              </div>
              <div>
                {!!this.state.visibleAudiences.length &&
                  <div className="audiences-list">
                    {this.state.visibleAudiences.map((audience) =>
                      <Chip
                        key={audience.id}
                        label={audience.displayName}
                        onDelete={() => this.onRemoveAudienceAccess(audience)}
                        deleteIcon={<CloseIcon />}
                        style={{position: 'relative', top: '12px'}}
                      />
                    )}
                  </div>
                }
                { !this.state.visibleAudiences.length && 
                  <div className="audiences-list">
                    <Chip
                      key={"everyone-default-audience"}
                      label={<i>All users</i>}
                      style={{position: 'relative', top: '12px', backgroundColor: "#dde1e5"}}
                      disabled={true}
                    />
                  </div>
                }
                <Button
                  variant="text"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={this.onShowVisibleAudiencesList}
                  style={{position: 'relative', top: '6px'}}
                >
                  Edit Audiences
                </Button>
                { <AudiencesDrawer show={this.state.showVisibleAudiencesList} selectedAudiences={this.state.visibleAudiences} onChange={this.onChangeAudiencesAccess} /> }
              </div>
            </div>
              <div>
                <div className="audiences-list-label">Default notified audiences {<InfoHover>Default audiences who get notified when content is published with this tag. These audiences can still be edited when creating new content.</InfoHover>}</div>
                <div>
                    {!!this.state.notifiedAudiences.length &&
                    <div className="audiences-list">
                        {this.state.notifiedAudiences.map((audience) =>
                        <Chip
                            key={audience.id}
                            label={audience.displayName}
                            onDelete={() => this.onRemoveAudienceNotify(audience)}
                            deleteIcon={<CloseIcon />}
                            style={{position: 'relative', top: '12px'}}
                        />
                        )}
                    </div>
                    }
                    <div>
                    <Button
                    variant="text"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={this.onShowNotifyAudiencesList}
                    style={{position: 'relative', top: '10px'}}
                    >
                    Edit Audiences
                    </Button>
                    </div>

                    <div>
                    <Button
                    variant="text"
                    color="primary"
                    startIcon={<FileCopyOutlinedIcon />}
                    onClick={this.onCopyAudiences}
                    style={{position: 'relative', top: '8px'}}
                    disabled={this.state.visibleAudiences.length === 0}
                    >
                    Copy Access Audiences
                    </Button>
                    </div>
                    { <AudiencesDrawer show={this.state.showNotifyAudiencesList} selectedAudiences={this.state.notifiedAudiences} onChange={this.onChangeAudiencesNotify} audiencesToCheck={this.state.visibleAudiences}/> }
                </div>
            </div>
        </div>
        </DialogContent>
        <DialogActions className="category-tags-dialog-footer">
          <Button variant="text" color="primary" onClick={this.onClose}>Cancel</Button>
          <Button
            variant="contained"
            color="primary"
            disabled={!this.state.categoryTagTitle || !this.state.categoryTagTitleIsValid || !this.state.categoryTagDescription || !this.state.categoryTagDescriptionIsValid
            || this.state.categoryTagLanguage === ""}
            onClick={this.onEdit}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  private onEdit = () => {
    this.onClose();

    var visibleAudienceIds:string[] = [];
    var notifyAudienceIds:string[] = [];

    this.state.visibleAudiences.forEach(audience => {
      visibleAudienceIds.push(audience.id);
    })

    this.state.notifiedAudiences.forEach(audience => {
      notifyAudienceIds.push(audience.id);
    })

    var toUpload: CategoryTagModel = {
      title: this.state.categoryTagTitle,
      description: this.state.categoryTagDescription,
      language: this.state.categoryTagLanguage,
      enabled: this.state.categoryTagEnabled,
      readonly: false,
      defaultAudienceAccess: visibleAudienceIds,
      defaultAudienceNotified: notifyAudienceIds,
      id: this.props.tagToEdit?.id ?? ""
    }

    this.props.saveCategoryTagEdit(toUpload);
  }

  private onChangeAudiencesAccess = (audiences: Audience[]) => {
    this.setState({ visibleAudiences: audiences, showVisibleAudiencesList: false });

    if(audiences.length > 0) {
        this.setState({notifiedAudiences: this.state.notifiedAudiences.filter(n => audiences.includes(n))})
    }
  } 

  private onChangeAudiencesNotify = (audiences: Audience[]) => {
    this.setState({ notifiedAudiences: audiences, showNotifyAudiencesList: false });
  } 

  private onCopyAudiences = () => {
    this.setState({ notifiedAudiences: this.state.visibleAudiences})
  }

  private onChangeTitle = (event) => {
    const isInvalid = event.target.value.toLowerCase() === "uncategorized" || event.target.toString().length > 200;
    this.setState({ categoryTagTitle: event.target.value, categoryTagTitleIsValid: !isInvalid });
  }

  private onChangeDescription = (event) => {
    const isInvalid = event.target.toString().length > 200; 
    this.setState({ categoryTagDescription: event.target.value, categoryTagDescriptionIsValid: !isInvalid });
  }

  private onClose = () => {
    this.props.hideEditCategoryTag();
  }

  private onRemoveAudienceAccess = (audience: Audience) => {
    this.onChangeAudiencesAccess(this.state.visibleAudiences.filter((selectedAudience) => selectedAudience.id !== audience.id));
  }

  private onRemoveAudienceNotify = (audience: Audience) => {
    this.onChangeAudiencesNotify(this.state.notifiedAudiences.filter((selectedAudience) => selectedAudience.id !== audience.id));
  }

  private onShowVisibleAudiencesList = () => {
    this.setState({ showVisibleAudiencesList: true });
  }

  private onShowNotifyAudiencesList = () => {
    this.setState({ showNotifyAudiencesList: true });
  }

  private onChangeLanguage = (e) => {
      this.setState({categoryTagLanguage: e.target.value})
  }

  private onChangeEnabled = (enabled: boolean) => {
      this.setState({categoryTagEnabled: enabled})
  }
}


interface ComponentProps {}

interface ComponentState {
    audiences: Audience[],
    visibleAudiences: Audience[],
    notifiedAudiences: Audience[],
    categoryTagTitle: string,
    categoryTagTitleIsValid: boolean,
    categoryTagDescription: string,
    categoryTagDescriptionIsValid: boolean,
    categoryTagLanguage: string,
    categoryTagEnabled: boolean,
    showAudiencesList: boolean,
    showVisibleAudiencesList: boolean,
    showNotifyAudiencesList: boolean,
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    editCategoryTag: state.categoryTags.showEditCategoryTag,
    tenantId: state.tenant.id,
    lcidMappings: state.resources.lcidMappings,
    lcid: state.settings.tenantSettings.translatedContent,
    tagToEdit: state.categoryTags.editingTag,
    audiences: state.audiences.audiences
  }),
  {
    showEditCategoryTag: actions.showEditCategoryTag,
    saveCategoryTagEdit: actions.editTagAction,
    hideEditCategoryTag: actions.hideEditCategoryTag,
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(EditCategoryTagDialog);