import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { GlobalApplicationState } from "globalApplicationState";

import BasePage from "../common/basePage";
import Header from "../common/header";
import MainContent from "../common/mainContent";

import MyFeed from "modules/posts/components/post-feed/myFeed";
import FilterIcon from "modules/posts/components/post-feed/components/filters/FilterIcon";
import { useOpenMobileAppBanner } from "modules/common/hooks/useOpenMobileAppBanner";


const PostsPage: React.FunctionComponent<PropsWithRedux> = props => {
    useOpenMobileAppBanner();

    return (
        <BasePage fullWidth>
            <Header
                title="My Feed"
                rightComponent={
                    <div>
                        <FilterIcon />
                    </div>
                }
            />
            <MainContent addPadding>
                <MyFeed selectedPostId={props.match.params.postId} />
            </MainContent>
        </BasePage>
    );
}


interface RouteParams {
    postId: string;
}

interface ComponentProps {
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps & RouteComponentProps<RouteParams>) => ({
        ...ownProps
    }),
    {
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;
export default connector(PostsPage);
