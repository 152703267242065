import React, { useState } from "react";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Link } from "@mui/material";
import { Close, InfoOutlined } from "@mui/icons-material";

import { ReadabilitySettings } from "./readabilitySettings";
import { EMPTY_READABILITY_SETTINGS, IReadabilityOptionModel, IReadabilitySettings, IReadabilitySettingsOptionModel } from "../models";
import Disclaimer from "modules/common/components/disclaimer";
import ConfirmDialog from "modules/common/components/dialogs/confirmDialog";
import SuccessSnackbar from "modules/common/components/snackbars/successSnackbar";

import "./styles/readabilitySettingsDialog.sass";

interface IReadabilitySettingsDialogProps {
    open: boolean;
    readabilityOptions?: IReadabilitySettingsOptionModel;
    readabilitySettings?: IReadabilitySettings;
    defaultReadabilitySettings?: IReadabilitySettings;
    suggestedTextExists: boolean; // is there any suggested text to clear?
    onClose: () => void;
    onChangeReadabilitySettings: (newSettings: IReadabilitySettings) => void;
    resetReadabilitySettings: () => void;
    onClearAllSuggestedText: () => void;
}

export const ReadabilitySettingsDialog: React.FunctionComponent<IReadabilitySettingsDialogProps> = ({
    open,
    readabilityOptions,
    readabilitySettings,
    defaultReadabilitySettings,
    suggestedTextExists,
    onChangeReadabilitySettings,
    resetReadabilitySettings,
    onClearAllSuggestedText,
    onClose,
}) => {
    const [confirmClearSuggestions, setConfirmClearSuggestions] = useState<boolean>(false);
    const [successMessage, setSuccessMessage] = useState<string>("");

    const randomizeSelections = () => {
        if (!readabilityOptions) return;

        let newSelections: IReadabilitySettings = readabilitySettings
            ? { ...readabilitySettings }
            : EMPTY_READABILITY_SETTINGS;

        Object
            .keys(readabilityOptions)
            .forEach((key: string) => {
                const currentSetting: IReadabilityOptionModel[] = readabilityOptions[key];
                const randomSelection = getRandomSelection(currentSetting);

                newSelections[key] = randomSelection;
            });

        onChangeReadabilitySettings(newSelections);
    }

    // get a random element in the options array
    const getRandomSelection = (options: IReadabilityOptionModel[]): string => options[Math.floor(Math.random() * options.length)].name;

    const onConfirmClearAllSuggestions = () => {
        setConfirmClearSuggestions(false);
        onClearAllSuggestedText();

        setSuccessMessage("All suggested text in Smart Content were successfully cleared.");
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}
                fullWidth
                maxWidth="sm"
                id="readability-settings-dialog"
            >
                <DialogTitle classes={{ root: "readability-settings-header"}}>
                    <span>
                    Readability Settings in Smart Content
                    </span>
                    <IconButton onClick={onClose}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent className="padding">
                    <Disclaimer
                        containerStyle={{ marginBottom: 22 }}
                        icon={<InfoOutlined htmlColor="#e6911a" />}
                        text={<div>
                            Our AI tool uses the readability settings as guides when generating body text. <Link target="_blank" href="https://support.sparrowconnected.com/en/smart-content-ai">Learn more.</Link>
                        </div>}
                    />
                    {readabilityOptions && readabilitySettings &&
                        <>
                            <ReadabilitySettings
                                enableDefaults
                                defaults={defaultReadabilitySettings}
                                options={readabilityOptions}
                                selections={readabilitySettings}
                                onChange={onChangeReadabilitySettings}

                            />
                            <div className="padding-horizontal reset-actions">
                                <Button
                                    id="post-smart-content-reset-readability"
                                    type="link"
                                    color="primary"
                                    className="action"
                                    autoCapitalize="false"
                                    onClick={resetReadabilitySettings}
                                    href=""
                                >
                                    Reset to default settings
                                </Button>
                                <Button
                                    id="post-smart-content-randomize-readability"
                                    autoCapitalize="false"
                                    type="link"
                                    color="primary"
                                    className="action"
                                    onClick={randomizeSelections}
                                    href=""
                                >
                                    Randomize
                                </Button>
                            </div>
                        </>}

                </DialogContent>
                <DialogActions classes={{ root: "readability-settings-footer" }}>
                    <div>
                        <p style={{ color: "#7f7f7f", fontSize: 12, }}>
                            Saved changes will only apply to succeeding suggestions, and will not affect any existing ones that were generated prior to the changes.
                        </p>
                        <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                            <Button
                                id="post-smart-content-clear-suggested"
                                type="link"
                                variant="text"
                                className="danger-link"
                                onClick={() => setConfirmClearSuggestions(true)}
                                disabled={!suggestedTextExists}
                                href=""
                            >
                                Clear all existing suggested text
                            </Button>
                        </div>
                    </div>
                </DialogActions>
            </Dialog>
            <ConfirmDialog
                open={confirmClearSuggestions}
                title="Clear all existing suggested text"
                confirmLabel="CLEAR ALL"
                denyLabel="CANCEL"
                onClose={() => setConfirmClearSuggestions(false)}
                onDeny={() => setConfirmClearSuggestions(false)}
                onConfirm={onConfirmClearAllSuggestions}
                headerStyle={{ padding: "16px 24px 8px" }}
                confirmButtonProps={{ id: "post-smart-content-confirm-clear", color: "secondary", style: { backgroundColor: "#b72026" } }}
                denyButtonProps={{ id: "post-smart-content-deny-clear" }}
            >
                <div style={{ minWidth: 400 }}>
                    <div>
                        You are about to clear all Smart Content keywords, titles, summaries and body text. You may not be able to retrieve the same responses again.
                    </div>
                    <br />
                    <div>Do you want to proceed?</div>
                </div>
            </ConfirmDialog>
            <SuccessSnackbar
                successMessage={successMessage}
                clearSuccessMessage={() => setSuccessMessage("")}
            />
        </>
    );
}
