import React, { useEffect, useState } from "react";

import { Theme } from "@mui/material/styles";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    image: {
      boxSizing: "border-box",
      height: "100%",
      maxHeight: "100vh",
      maxWidth: "100vw",
      objectFit: "contain",
      padding: "25px 0 50px 0"
    },
    message: {
      position: "absolute",
      fontSize: 14,
      bottom: 20,
      left: 0,
      right: 0
    },
    screenBase: {
      position: "relative",
      backgroundImage: "url('/images/portal-bg.png')",
      backgroundPosition: "top left",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      fontFamily: theme.typography.fontFamily,
      height: "100vh",
      textAlign: "center"
    }
  })
);

const AdminPortalSplashScreen: React.FunctionComponent<ComponentProps> = props => {
  const [display, setDisplay] = useState(true);
  const [timestamp] = useState(new Date().getTime());
  const classes = useStyles();

  useEffect(() => {
    const timer = setTimeout(() => setDisplay(false), 4500);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  if (props.displaySplashScreen && display) {
    return (
      <div className={classes.screenBase}>
        <img src={`/images/redirecting-to-admin-portal.gif?${timestamp}`} alt="redirecting to admin portal" className={classes.image} />
        <div className={classes.message}>Redirecting you to the Admin Portal...</div>
      </div>
    );
  }

  return (
    <React.Fragment>{props.children}</React.Fragment>
  );
}

interface ComponentProps {
  displaySplashScreen?: boolean;
}

export default AdminPortalSplashScreen;