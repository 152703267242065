import * as React from 'react';

export default class ProgressiveImage extends React.Component<ComponentProps, {}> {

    private _imageReady: boolean = false;
    private _mounted: boolean = false;

    public render() {

        let previewStyles = {
            'background': 'url(' + this.props.previewSrc + ') center center no-repeat',
            'backgroundSize': 'cover'
        }

        return (
            <div className="progressive-image-visible-container" style={{position: 'relative'}}>
                <div className="progressive-image-placeholder" style={previewStyles}></div>
                { !!this._imageReady && <img className="progressive-image" alt={"Progressive"} { ...{ src: this.props.src!, ...this.props.imageProps } } /> }
            </div>
        );
    }

    public componentDidMount() {
        this.setup(this.props.src);
    }

    public componentWillUpdate(nextProps){
        if(nextProps.src !== this.props.src){
            this._mounted = false;
            this._imageReady = false;
            this.setup(nextProps.src)
        }
    }

    private setup(imgSrc: string){
        this._mounted = true;
        let that = this;
        let img = new Image();
        img.src = imgSrc;
        img.onload = (e: any) => {
            that.imageReady(e);
            this.notifyImageLoaded(img);
        }
    }

    public componentWillUnmount() {
        this._mounted = false;
    }

    private imageReady = (e: any) => {
        this._imageReady = true;
        if (this._mounted) {
            this.forceUpdate();
        }
    }

    private notifyImageLoaded = (img: HTMLImageElement) => {
        if (this.props.onImageLoaded) {
            this.props.onImageLoaded(img)
        }
    }
}

interface ComponentProps {
    src: string,
    previewSrc?: string,
    imageProps?: React.ImgHTMLAttributes<HTMLImageElement>,
    onImageLoaded?: (image: HTMLImageElement) => void
}
