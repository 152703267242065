import React, { useMemo } from "react";

import DateBlock from "modules/events/components/date-block/dateBlock";
import { IContentBandLayoutProps } from "./contentBandLayoutPreview";

interface IContentBandCardLayoutProps extends IContentBandLayoutProps {
    rightHeroBadge?: JSX.Element; // component to render top right corner of hero
    fullTitle?: boolean;
}

export const ContentBandCard: React.FunctionComponent<IContentBandCardLayoutProps> = ({
    bannerColor = "#2196f3",
    settings,
    heroUrl,
    title,
    leftInfo,
    rightInfo,
    rightHeroBadge,
    leftHeroBadge,
    dateBlock,
    fullTitle = false,
    size = "sm",
}) => {
    const { displayFullTitles } = settings;

    const usableTitle = useMemo(
        () => (displayFullTitles ? title : `${title.length > 55 ? title.substring(0, 52).trim() + "..." : title}`),
        [displayFullTitles, title]
    );

    return (
        <div className={`content-band-card ${size} ${fullTitle ? "full-title" : ""}`}>
            <div
                className="hero-img"
                style={{
                    backgroundColor: heroUrl ? "rgb(221, 225, 229)" : bannerColor,
                    backgroundImage: `url(${heroUrl})`,
                }}
            >
                <div className="hero-badges">
                    {leftHeroBadge}
                    <div
                        style={{
                            marginRight: 10,
                            marginLeft: leftHeroBadge ? "" : "auto",
                        }}
                    >
                        {rightHeroBadge}
                    </div>
                </div>
                {dateBlock && <DateBlock {...dateBlock} monthFormat="MMM" />}
            </div>
            <div className="content-band-card-content">
                <p className="title">{usableTitle}</p>
                <div className="info">
                    {leftInfo}
                    {rightInfo && <div style={leftInfo ? {} : { marginLeft: "auto" }}>{rightInfo}</div>}
                </div>
            </div>
        </div>
    );
};
