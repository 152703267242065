import React from 'react';
import './styles/messagingCenterForm.sass'
import MessagingMainControls from './messagingMainControls';
// import MessagingSMSControls from './messagingSMSControls';
import MessagingPostControls from './messagingPostControls';
import MessagingEventControls from './messagingEventControls';
import MessagingGeneralControls from './messagingGeneralControls';
import { connect, ConnectedProps } from 'react-redux';
import { GlobalApplicationState } from 'globalApplicationState';
interface ComponentProps {}

interface ComponentState { 
    activeTab: MessagingCenterTabs
}

enum MessagingCenterTabs {
    Main,
    // SMSConsent,
    Post,
    Event,
    General,
    Document,
}
class MessagingCenterForm extends React.Component<PropsWithRedux, ComponentState> {


    constructor(props) {
        super(props);
        this.state = {
            activeTab: MessagingCenterTabs.Main
        }
    }
    public render() {
        const tabMapping = [
            {id: MessagingCenterTabs.Main, title: "Main Controls", content: <MessagingMainControls />},
            // {id: MessagingCenterTabs.SMSConsent, title: "SMS Consent Messages", content: <MessagingSMSControls />},
            {id: MessagingCenterTabs.General, title: "General Notifications", content: <MessagingGeneralControls />},
            {id: MessagingCenterTabs.Post, title: "Post Notifications/Reminders", content: <MessagingPostControls />},
            {id: MessagingCenterTabs.Event, title: "Events Notifications/Reminders", content: <MessagingEventControls />}
        ]

        const tabs = tabMapping.map((tab) => {
            const active = tab.id === this.state.activeTab ? '--active' : '';
            return (
                <div key ={tab.id} className={`sidebar-tab${active}`} onClick={() => this.setState({...this.state, activeTab: tab.id})}> 
                    {tab.title}
                </div>
            )
        })

        const activeTab = tabMapping.find((tab) => tab.id === this.state.activeTab)!

        if(this.props.fetching)
        {
            return null;
        }
        else
        {
            return (
                <div className="messaging-center-container">
                    <div className="messaging-center-sidebar">
                        {tabs}
                    </div>
                    <div className="messaging-center-content">
                        {activeTab.content}
                    </div>
                </div>
            )
        }
    }
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        fetching: state.settings.fetching
    }),
    {
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default (connector(MessagingCenterForm));