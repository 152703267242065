import React from "react";

import { AudienceUser } from "../../../models";

import IconButton from "@mui/material/IconButton";

import ClearIcon from "@mui/icons-material/Clear";


const UserList: React.FunctionComponent<ComponentProps> = props => {
  if (!props.includedUsers.length)
    return <div className="no-search-results">No unique user has been added</div>;

  return (
    <div className="audience-user-list">
      {props.includedUsers.map((user) => (
        <div key={user.userId}>
          <div className="user-suggestion">
            <div className="user-avatar">
              <div>{user.name.charAt(0)}</div>
            </div>
            <div>
              <div className="user-name">{user.name}</div>
              <div className="user-email">{user.email}</div>
            </div>
          </div>
          <div>
            <IconButton size="small" onClick={() => props.onRemoveUser(user.userId)} className="remove-icon"><ClearIcon /></IconButton>
          </div>
        </div>
      ))}
    </div>
  );
}

interface ComponentProps {
  includedUsers: AudienceUser[];
  onRemoveUser: (id: string) => void;
}

export default UserList;