import { Action } from "redux";
import * as  Actions from "./actions";

import { TenantState } from "./models";

const defaultState = {
    id: null
};

const actionHandler: { [actionType: string]: (state: TenantState, action: Action) => TenantState } = {

    [Actions.SET_TENANT_ID]: (state: TenantState, action: Actions.SetTenantId) => {
        return {...state, id: action.tenantId } as TenantState;
    }
}

export const reducer = (state: TenantState, action: Action) => {

    const actionHandlerMethod = actionHandler[action.type];
    if (actionHandlerMethod) {
        return actionHandlerMethod(state, action);
    }

    return state || defaultState;
};
