import * as React from "react";
import moment from "moment";

import { EventType } from "modules/events/models";

import AccessTimeIcon from "@mui/icons-material/AccessTime";


class CutoffTime extends React.Component<ComponentProps, ComponentState> {
  public componentDidMount() {
    moment.locale("en");
  }

  public render() {
    if (!this.props.cutoffTime || this.props.eventType === "informational")
      return <React.Fragment></React.Fragment>;
    
    return (
      <div className="info-field">
        <div className="info-icon">
          <AccessTimeIcon fontSize="small" />
        </div>
        <div>RSVP Deadline - {moment(this.props.cutoffTime).format("ddd, MMM D, YYYY, h:mm A")}</div>
      </div>
    );
  }
}


interface ComponentProps {
  cutoffTime: string | undefined;
  eventType: EventType;
}

interface ComponentState {}

export default CutoffTime;