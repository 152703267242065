import React, { useEffect, useState } from 'react';
import UserInsightsBlock from './userInsightsBlock';
import { usersApi } from '../../../api/instances';

const UserOverviewInsights = () => {    
    interface UserStats {   
        totalUsers: number,
        disabledUsers: number,
        externalAccounts: number,
        newUsers: number,
        engagedUsersLast30Days: number
    }

    const [userStats, setUserStats] = useState<UserStats | undefined>(undefined);
    const [pendingInvites, setPendingInvites] = useState(0);
    useEffect(() => {
        const getStats = async () => {
            const stats = await usersApi.GetUserOverviewStats();
            
            setUserStats(stats);
        }
        const getPendingInvites = async () => {
            const numInvites = await usersApi.GetPendingInviteCount();
            setPendingInvites(numInvites);
        }

        getStats();
        getPendingInvites();
    }, []);

    return (
        <React.Fragment>
            <div className="column-insights">
                <UserInsightsBlock 
                    title={"Total users"}
                    stats={userStats?.totalUsers}
                />
                <UserInsightsBlock 
                    title={"Deactivated users"}
                    stats={userStats?.disabledUsers}
                />
                <UserInsightsBlock
                    title={"Engaged users in the last 30 days"}
                    stats={userStats?.engagedUsersLast30Days} 
                />
                <UserInsightsBlock
                    title={"Social users"}
                    stats={userStats?.externalAccounts}
                />
                <UserInsightsBlock
                    title={"Pending social invite users"}
                    stats={pendingInvites}
                />
            </div>
        </React.Fragment>
    )
}

export default UserOverviewInsights;