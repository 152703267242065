import { List, Popover, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import * as React from 'react';
import MoreOptionsItem from '../../moreOptionsItem';
import { SortStyle } from 'utils/managementUtils';

interface SortOption {
    text: string;
    value: SortStyle;
}

const DocumentGallerySearch: React.FunctionComponent<ComponentProps> = props => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [calloutOpen, setCalloutOpen] = React.useState(false);
    const [text, setText] = React.useState(props.text);

    const SortOptions: SortOption[] = [
        { text: "Last Modified", value: SortStyle.modifiedDesc },
        { text: "Newest first", value: SortStyle.publishDesc },
        { text: "Oldest first", value: SortStyle.publishAsc },
        { text: "A-Z", value: SortStyle.titleAsc },
        { text: "Z-A", value: SortStyle.titleDesc }
    ];

    const checkKeyPress = (e) => {
        if(e.key === "Enter") {
            props.onUpdateText(e.target.value);
        }
    }

    return (
        <div style={{paddingBottom: "15px", display: "flex", flexDirection: "row"}}>
            <TextField 
                variant="outlined"
                size="small"
                value={text}
                placeholder="Search document"
                InputProps={{
                    startAdornment: <SearchIcon className="search-icon"/>,
                    endAdornment: props.text !== "" && <CloseIcon style={{cursor: "pointer"}} className="search-icon" onClick={() => { props.onUpdateText(""); setText(""); }}/>
                }}
                onChange={(e) => setText(e.target.value)}
                onKeyDown={checkKeyPress}
                fullWidth
                className="text-to-search"
                disabled={props.disabled}
            />
            <div style={{width: "20%", height: "100%", color: "#888", fontSize: "14px", textAlign: "center", alignSelf: "center"}} aria-disabled={props.disabled}>
                <div onClick={(ev: any) => { setAnchorEl(ev.currentTarget); setCalloutOpen(true); }}>
                    <span>Sort by</span>
                    <span style={{fontWeight: 500, marginLeft: "5px"}}>{SortOptions.find((option) => option.value === props.sortStyle)!.text}</span>
                </div>
                <Popover
                    open={calloutOpen}
                    anchorEl={anchorEl}
                    onClose={() => setCalloutOpen(false)}
                    anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                    }}
                >
                    <List disablePadding>
                        {SortOptions.map((option) =>
                            <MoreOptionsItem
                            key={option.value}
                            text={option.text}
                            onClick={(e) => {
                                props.onChangeSort(option.value);
                                setCalloutOpen(false);
                            }}
                            />
                        )}
                    </List>
                </Popover>
            </div>
        </div>
    );
}

interface ComponentProps {
    onChangeSort: (sort: SortStyle) => void;
    onUpdateText: (text: string) => void;
    sortStyle: SortStyle;
    text: string;
    disabled: boolean;
}

export default DocumentGallerySearch;