import React, { useState } from "react";

import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";


interface InputSwitchFieldProps {
  description?: string;
  label: string;
  value: string;
  placeholder?: string;
  onChange: (event: any) => void;
  onClear: () => void;
}

const InputSwitchField: React.FunctionComponent<InputSwitchFieldProps> = props => {
  const [disabled, setDisabled] = useState(!props.value);

  return (
    <div className="section-field">
      <div className="section-input-field-heading">
        <label>{props.label}</label>
      </div>
      <div className="section-input-switch-field">
        <Switch
          color="primary"
          checked={!disabled}
          onChange={() => {
            if (!disabled && !!props.value)
              props.onClear();
            setDisabled(!disabled);
          }}
        />
        <TextField
          key={props.value}
          variant="outlined"
          size="small"
          defaultValue={props.value}
          placeholder={disabled ? "" : props.placeholder}
          fullWidth
          autoComplete="off"
          disabled={disabled}
          onBlur={(event) => {
            if (event.target.value !== props.value)
              props.onChange(event);
          }}
        />
        {props.description &&
          <Typography variant="caption">{props.description}</Typography>
        }
      </div>
    </div>
  );
}

export default InputSwitchField;