import * as React from "react";

import PostTypeBanner from "modules/common/components/banners/postTypeBanner";

import { PostFeedItem } from "../../../../../models";
import BreakingFeaturedBanner from "../../../../banners/breakingFeaturedBanner";
import QuickPostBanner from "../../../../banners/quickPostBanner";
import PublishTime from "../../dates/PublishTime";
import ReadDate from "../../dates/ReadDate";
import ReadingTime from "../../dates/ReadingTime";
import Stats from "../../stats/Stats";
import Topics from "../../topics/Topics";


const Tile: React.FunctionComponent<ComponentProps> = props => {
  const { post, preferredLCID, onPostSelected } = props;

  if (!post) {
    return (null);
  }

  const backgroundImageUrl = post.imageUrl || "";
  const selectPost = () => onPostSelected ? onPostSelected(post) : null;

  return (
    <div onClick={selectPost} className="tile">
      <div className="image-container">
        <div 
          style={
            post.isQuickPost ? { backgroundColor: "#000000" } : { 
              backgroundImage: "url('" + backgroundImageUrl + "')", 
              backgroundSize: "contain", backgroundColor: "#DDE1E5" 
          }} 
          className={`image ${post.isQuickPost ? "quick-post" : ""}`}
        >
          <QuickPostBanner {...post} height={30} />
          <PostTypeBanner {...props} {...post} lcid={preferredLCID} />
          {!props.hideStats &&
            <Stats {...post} />
          }
        </div>
      </div>
      <div className="content-container">
        <div className="content">
          <div className="date-bar">
            {!props.hidePublishTime &&
              <PublishTime publishTime={post.publishTime} />
            }
            <ReadDate lastReadTime={post.lastReadTime} />
          </div>
          <div className="title">{post.title}</div>
          {!props.hideStats &&
            <ReadingTime readingTime={post.readingTime} />
          }
          <div className="summary">
            <BreakingFeaturedBanner {...props} {...post} activeLcid={preferredLCID} />
            {!props.hideSummary &&
              post.excerpt
            }
          </div>
          {!props.hideTopics &&
            <Topics topics={post.tags} />
          }
        </div>
      </div>
    </div>
  );
}

interface ComponentProps {
  hidePublishTime?: boolean;
  hideStats?: boolean;
  hideSummary?: boolean;
  hideTopics?: boolean;
  post: PostFeedItem;
  preferredLCID: string;
  onPostSelected: (post: PostFeedItem) => void;
}

export default Tile;