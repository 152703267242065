import React, { useState } from "react";
import Select, { OptionsType } from "react-select";
import { injectIntl, FormattedMessage, IntlShape } from "react-intl";

import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import useIsMounted from "modules/common/hooks/useIsMounted";
import LoginLayout from "routes/_layout/login/loginLayout";
import { Button, Divider, Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import useSignOut from "modules/common/hooks/useSignOut";
import Loading from "modules/common/components/loading";
import tenantManagementUtils, { ISelectOpt } from "utils/tenantManagementUtils";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        primaryButton: {
            backgroundColor: "#3b78ab",
            "&:hover": {
                backgroundColor: "rgb(41, 84, 119)",
            },
            marginTop: 25,
            width: "100%",
        },
        divider: {
            marginTop: 40,
        },
        picker: {
            marginTop: 5,
        },
        buttonLink: {
            fontWeight: "normal",
            color: "rgb(59, 120, 171)",
            textDecoration: "underline",
            background: "transparent",
            textTransform: "none",
            marginTop: 35,
            "&:hover": {
                background: "transparent",
                textDecoration: "underline",
            },
            alignSelf: "center",
            width: 200,
        },
    })
);

const TenantSelection: React.FunctionComponent<PropsWithRedux> = (props) => {
    const [fetching, setFetching] = useState<boolean>(true);
    const [tenantOpts, setTenantOpts] = useState<OptionsType<ISelectOpt>>();
    const [selectedTenantId, setSelectedTenantId] = useState<string>();
    const isMounted = useIsMounted();
    const classes = useStyles();
    const { signOut, isSigningOut } = useSignOut();

    // load tenants into state
    React.useEffect(() => {
        const loadTenants = async () => {
            // run setup, and navigate to the tenant page only if user belongs to a single tenant
            const res = await tenantManagementUtils.setupTenantUserData();
            
            // if there is more than 1 tenant for the user, setup won't fully run so we can set options for user to choose
            if (isMounted() && res.length >= 1) {
                setTenantOpts(res);
                // default selection to first in list
                if (res.length > 0) setSelectedTenantId(res[0].value);
                setFetching(false);
            }
        };

        loadTenants();
    }, [isMounted]);

    // handle continue click
    const onContinue = () => {
        if (!selectedTenantId) return;

        tenantManagementUtils.setTenantUserDataAndNav(selectedTenantId, true);
    };

    // handle tenant selection change
    const onSelectChange = (option: any) => {
        setSelectedTenantId(option.value as string);
    };

    return (
        <LoginLayout>
            {fetching || isSigningOut ? (
                <div>
                    <Loading style={{ color: "#ADB7C1" }} />
                    <div
                        style={{
                            marginTop: "20px",
                            color: "#ffffff",
                            margin: "0 auto",
                            width: "215px",
                        }}
                    >
                        <FormattedMessage id="common.assemblingAnalytics" defaultMessage="Assembling Your Analytics" />
                        {"..."}
                    </div>
                </div>
            ) : tenantOpts ? (
                tenantOpts.length > 0 ? (
                    <>
                        <label>
                            <FormattedMessage id={"common.selectOrganization"} defaultMessage={"Select your organization"} />
                        </label>
                        <div className="select-wrapper">
                            <Select
                                options={tenantOpts}
                                isDisabled={false}
                                placeholder={" "}
                                value={tenantOpts.find((o) => o.value === selectedTenantId)}
                                onChange={onSelectChange}
                                isMulti={false}
                                isClearable={false}
                                backspaceRemovesValue={false}
                                className={classes.picker}
                                components={{
                                    IndicatorSeparator: () => null,
                                }}
                            />
                        </div>
                        <Button
                            color="primary"
                            variant="contained"
                            className={classes.primaryButton}
                            onClick={onContinue}
                            disabled={!selectedTenantId}
                        >
                            CONTINUE
                        </Button>
                        <Divider className={classes.divider} />
                        <Button className={classes.buttonLink} onClick={signOut}>
                            Sign out & switch accounts
                        </Button>
                    </>
                ) : (
                    <div className="options-wrapper">
                        <p className="disabled-wrapper">
                            <FormattedMessage
                                id={"common.accountDisabled"}
                                defaultMessage={"Your account may have been disabled. Please talk to your Sparrow Administrator."}
                            />
                        </p>
                    </div>
                )
            ) : null}
        </LoginLayout>
    );
};

interface ComponentProps {
    intl: IntlShape;
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
    })
);

type PropsWithRedux = ConnectedProps<typeof connector>;

export default injectIntl(connector(TenantSelection));
