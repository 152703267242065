import React from "react";

import { MoreVert, VisibilityOutlined } from "@mui/icons-material";
import { Button, Divider, IconButton, Menu, MenuItem, useMediaQuery, useTheme } from "@mui/material";
import { DockToLeftIcon } from "modules/common/icons/dockToLeftIcon";
import CalloutHover from "../hovers/calloutHover";

export interface IAuthoringAppBarActionsProps {
    actions: Record<AuthoringAppBarAction, IAuthoringAppBarAction>;
}

export enum AuthoringAppBarAction {
    Save,
    Discard,
    Preview,
    Back,
    ToggleSettingsPanel,
    Publish,
}

export interface IAuthoringAppBarAction {
    onClick: () => void;
    disabled: boolean;
    tooltip?: string;
}

export const AuthoringAppBarActions: React.FunctionComponent<IAuthoringAppBarActionsProps> = ({ actions }) => {
    const publishAction = actions[AuthoringAppBarAction.Publish];
    const saveAction = actions[AuthoringAppBarAction.Save];
    const previewAction = actions[AuthoringAppBarAction.Preview];
    const backAction = actions[AuthoringAppBarAction.Back];
    const toggleSettingsAction = actions[AuthoringAppBarAction.ToggleSettingsPanel];
    const discardAction = actions[AuthoringAppBarAction.Discard];

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const showMoreActionsOpen = Boolean(anchorEl);
    const theme = useTheme();
    const isSmallAndSmaller = useMediaQuery(theme.breakpoints.down("md"), { noSsr: true });

    const onClickMoreActions = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event?.currentTarget);
    };

    const onAction = (callback: () => void) => {
        setAnchorEl(null);
        callback();
    };

    const getPublishButton = (): JSX.Element => (
        <Button id="authoring-app-bar-publish" variant="contained" {...publishAction}>
            PUBLISH
        </Button>
    );

    return (
        <>
            {isSmallAndSmaller ? (
                <>
                    <IconButton color="primary" size="small" onClick={onClickMoreActions}>
                        <MoreVert />
                    </IconButton>
                    <Menu
                        id="authoring-app-bar-more-actions-menu"
                        open={showMoreActionsOpen}
                        anchorEl={anchorEl}
                        onClose={() => setAnchorEl(null)}
                    >
                        <MenuItem id="authoring-app-bar-back" onClick={() => onAction(backAction.onClick)} disabled={backAction.disabled}>
                            Back to Manage Home Screen
                        </MenuItem>
                        <MenuItem
                            id="authoring-app-bar-preview"
                            onClick={() => onAction(previewAction.onClick)}
                            disabled={previewAction.disabled}
                        >
                            Preview
                        </MenuItem>
                        <MenuItem
                            id="authoring-app-bar-discard"
                            onClick={() => onAction(discardAction.onClick)}
                            disabled={discardAction.disabled}
                        >
                            Discard
                        </MenuItem>
                        <MenuItem id="authoring-app-bar-save" onClick={() => onAction(saveAction.onClick)} disabled={saveAction.disabled}>
                            Save as draft
                        </MenuItem>
                    </Menu>
                    {getPublishButton()}
                </>
            ) : (
                <>
                    <Button
                        id="authoring-app-bar-discard"
                        {...discardAction}
                        variant="text"
                        sx={{
                            color: "#555555",
                            marginRight: "21px!important",
                        }}
                    >
                        DISCARD
                    </Button>
                    <Button
                        id="authoring-app-bar-save"
                        variant="outlined"
                        sx={{
                            marginRight: "18px!important",
                        }}
                        {...saveAction}
                    >
                        SAVE AS DRAFT
                    </Button>
                    {getPublishButton()}
                    <Divider orientation="vertical" flexItem sx={{ color: "#dde1e5", height: 35, margin: "0px 20px" }} />
                    <CalloutHover
                        component={
                            <IconButton id="authoring-app-bar-preview" {...previewAction}>
                                <VisibilityOutlined />
                            </IconButton>
                        }
                    >
                        Preview
                    </CalloutHover>
                    <CalloutHover
                        component={
                            <IconButton id="authoring-app-bar-settings-panel" {...toggleSettingsAction}>
                                <DockToLeftIcon />
                            </IconButton>
                        }
                    >
                        {toggleSettingsAction.tooltip}
                    </CalloutHover>
                </>
            )}
        </>
    );
};
