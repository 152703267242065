import React, { useEffect, useState } from "react";

import Cookies from "js-cookie";

import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";

import { Theme } from "@mui/material/styles";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { COOKIE_OPT_IN_EXPIRY_DAYS } from "utils/cookie";
import tenantManagementUtils from "utils/tenantManagementUtils";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      position: "relative",
      zIndex: 9999
    },
    cookieBanner: {
      padding: "30px 60px"
    },
    disclaimer: {
      margin: "15px 0"
    },
    options: {
      marginTop: 20,
      textAlign: "right",
      "& > button": {
        marginLeft: 8
      }
    }
  })
);

const CookieBanner: React.FunctionComponent<PropsWithRedux> = ({ tenantId }) => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    if (!Cookies.get("cookies-opt-in"))
      setShowBanner(true);
  }, []);

  const classes = useStyles();

  return (
    <Drawer
      anchor="bottom"
      open={showBanner}
      variant="persistent"
      classes={{
        paper: classes.cookieBanner
      }}
      className={classes.drawer}
    >
      <div>
        <div>Sparrow Connected uses cookies and similar technologies to improve your experience and measure your interactions with our websites, products, and services. We do not share this activity with any vendors. If that’s okay, click “Accept”.</div>
        <div className={classes.disclaimer}>
          <div>Disclaimer Text:</div>
          <div>We won't track your personal information when you visit our site. But in order to comply with your preferences, we'll have to use a cookie so that you're not asked to make this choice again.</div>
        </div>
        </div>
      <div className={classes.options}>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            Cookies.set("cookies-opt-in", "false", { expires: COOKIE_OPT_IN_EXPIRY_DAYS });
            setShowBanner(false);
          }}
        >
          Decline
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={async () => {
            Cookies.set("cookies-opt-in", "true", { expires: COOKIE_OPT_IN_EXPIRY_DAYS });
            setShowBanner(false);
            
            // User accepts cookies, now we can set the tenant cookie data
            await tenantManagementUtils.setupTenantUserData(tenantId);
          }}
        >
          Accept
        </Button>
      </div>
    </Drawer>
  );
};

interface ComponentProps {}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
      ...ownProps,
      tenantId: state.tenant.id,
  })
);

type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(CookieBanner);
