import React from "react"

import BasePage from "../common/basePage";
import Columns from "../common/columns";
import MainContent from "../common/mainContent";

import Details from "modules/profile/containers/details";
import User from "modules/profile/containers/user";


import "modules/profile/styles/profile.sass";


const UserPage: React.FunctionComponent<{}> = props => {
  return (
    <BasePage>
      <Columns
        slim
        leftComponent={
          <div>
            <MainContent id="my-account" addPadding>
              <User />
            </MainContent>
          </div>
        }
        rightComponent={
          <MainContent id="user-profile" addPadding>
            <Details />
          </MainContent>
        }
      />
    </BasePage>
  );
}

export default UserPage;