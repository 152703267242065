import { Action } from "redux";
import * as Actions from "./actions";

import { CategoryTagsListingPage, CategoryTagState } from "./models";

export const defaultCategoryTagPage: CategoryTagsListingPage = {
    id: "",
    currentPage: 0,
    categoryTags: [],
    totalCategoryTags: 0,
    totalPages: 0,
    isFetching: false
}

const DefaultState: CategoryTagState = {
    editingTag: null,
    showNewCategoryTag: false,
    showEditCategoryTag: false,
    showViewCategoryTag: false,
    tagToViewId: "",
    tagToView: undefined,
    isDeleting: false,
    isFetching: false,
    isInitialLoad: true,
    isLoadingDetails: false,
    isSaving: false,
    shouldFetch: false,
    all: { ...defaultCategoryTagPage, id: "all" },
    enabled: { ...defaultCategoryTagPage, id: "enabled" },
    disabled: { ...defaultCategoryTagPage, id: "disabled" },
    userCategoryTags: [],
    errorMessage: ""
}


const actionHandler: { [actionType: string]: (State: CategoryTagState, action: Action) => CategoryTagState } = {
    [Actions.CREATE_NEW_CATEGORY_TAG]: (state: CategoryTagState, action: Actions.AddNewCategoryTagAction) => {
        return state;
    },
    [Actions.CREATE_NEW_CATEGORY_TAG_COMPLETE]: (state: CategoryTagState, action: Actions.AddNewCategoryTagComplete) => {
        return {
            ...state,
            errorMessage: action.succeeded ? "" : "Unable to create tag",
            isSaving: false,
            shouldFetch: action.succeeded
        }
    },
    [Actions.EDIT_CATEGORY_TAG]: (state: CategoryTagState, action: Actions.EditCategoryTagAction) => {
        return {
            ...state,
            isSaving: true,
        }
    },
    [Actions.EDIT_CATEGORY_TAG_COMPLETE]: (state: CategoryTagState, action: Actions.EditCategoryTagComplete) => {
        return {
            ...state,
            errorMessage: action.succeeded ? "" : "Unable to edit tag",
            isSaving: false,
            shouldFetch: action.succeeded
        }
    },
    [Actions.SHOW_NEW_CATEGORY_TAG]: (state: CategoryTagState, action: Actions.ShowNewCategoryTag) => {
        return {
          ...state,
          preview: undefined,
          showNewCategoryTag: true
        };
    },
    [Actions.HIDE_NEW_CATEGORY_TAG]: (state: CategoryTagState, action: Actions.ShowNewCategoryTag) => {
        return {
          ...state,
          showNewCategoryTag: false
        };
    },
    [Actions.SHOW_EDIT_CATEGORY_TAG]: (state: CategoryTagState, action: Actions.ShowEditCategoryTag) => {
        return {
          ...state,
          preview: undefined,
          showEditCategoryTag: true
        };
    },
    [Actions.HIDE_EDIT_CATEGORY_TAG]: (state: CategoryTagState, action: Actions.HideEditCategoryTag) => {
        return {
          ...state,
          showEditCategoryTag: false
        };
    },
    [Actions.SHOW_VIEW_CATEGORY_TAG]: (state: CategoryTagState, action: Actions.ShowViewCategoryTag) => {
        return {
            ...state,
            showViewCategoryTag: true
        };
    },
    [Actions.HIDE_VIEW_CATEGORY_TAG]: (state: CategoryTagState, action: Actions.HideViewCategoryTag) => {
        return {
            ...state,
            showViewCategoryTag: false
        }
    },
    [Actions.CLEAR_SHOULD_FETCH]: (state: CategoryTagState, action: Actions.ClearShouldFetch) => {
        return {
          ...state,
          shouldFetch: false
        };
    },
    [Actions.GET_CATEGORY_TAGS]: (state: CategoryTagState, action: Actions.GetCategoryTags) => {
        return {
            ...state,
            all: {
              ...state.all,
              isFetching: true
            },
        }
    },
    [Actions.GET_CATEGORY_TAGS_COMPLETE]: (state: CategoryTagState, action: Actions.GetCategoryTagsComplete) => {
        return {
          ...state,
          all: {
            ...state.all,
            ...action.page,
            isFetching: false
          },
          isInitialLoad: false
        };
    },
    [Actions.GET_DISABLED_CATEGORY_TAGS]: (state: CategoryTagState, action: Actions.GetDisabledCategoryTags) => {
        return {
            ...state,
            disabled: {
              ...state.disabled,
              isFetching: true
            },
        }
    },
    [Actions.GET_DISABLED_CATEGORY_TAGS_COMPLETE]: (state: CategoryTagState, action: Actions.GetDisabledCategoryTagsComplete) => {
        return {
          ...state,
          disabled: {
            ...state.disabled,
            ...action.page,
            isFetching: false
          },
          isInitialLoad: false
        };
    },
    [Actions.GET_ENABLED_CATEGORY_TAGS]: (state: CategoryTagState, action: Actions.GetEnabledCategoryTags) => {
        return {
            ...state,
            enabled: {
              ...state.enabled,
              isFetching: true
            },
        }
    },
    [Actions.GET_ENABLED_CATEGORY_TAGS_COMPLETE]: (state: CategoryTagState, action: Actions.GetEnabledCategoryTagsComplete) => {
        return {
          ...state,
          enabled: {
            ...state.enabled,
            ...action.page,
            isFetching: false
          },
          isInitialLoad: false
        };
    },
    [Actions.DELETE_CATEGORY_TAG]: (state: CategoryTagState, action: Actions.DeleteCategoryTag) => {
        return {
            ...state,
            isDeleting: true
        }
    },
    [Actions.DELETE_CATEGORY_TAG_COMPLETE]: (state: CategoryTagState, action: Actions.DeleteCategoryTagComplete) => {
        return {
            ...state,
            errorMessage: action.succeeded ? "" : "Unable to delete tag",
            isDeleting: false,
            shouldFetch: action.succeeded
        }
    },
    [Actions.DELETE_CATEGORY_TAGS]: (state: CategoryTagState, action: Actions.DeleteCategoryTags) => {
        return {
            ...state,
            isDeleting: true
        }
    },
    [Actions.DELETE_CATEGORY_TAGS_COMPLETE]: (state: CategoryTagState, action: Actions.DeleteCategoryTagsComplete) => {
        return {
            ...state,
            errorMessage: action.succeeded ? "" : "Unable to delete tags",
            isDeleting: false,
            shouldFetch: action.succeeded
        }
    },

    [Actions.ENABLE_CATEGORY_TAG]: (state: CategoryTagState, action: Actions.EnableCategoryTag) => {
        return {
            ...state,
            isSaving: true
        }
    },
    [Actions.ENABLE_CATEGORY_TAG_COMPLETE]: (state: CategoryTagState, action: Actions.EnableCategoryTagComplete) => {
        return {
            ...state,
            errorMessage: action.succeeded ? "" : "Unable to modify tag",
            isSaving: false,
            shouldFetch: action.succeeded
        }
    },

    [Actions.DISABLE_CATEGORY_TAG]: (state: CategoryTagState, action: Actions.DisableCategoryTag) => {
        return {
            ...state,
            isSaving: true
        }
    },
    [Actions.DISABLE_CATEGORY_TAG_COMPLETE]: (state: CategoryTagState, action: Actions.DisableCategoryTagComplete) => {
        return {
            ...state,
            errorMessage: action.succeeded ? "" : "Unable to delete tag",
            isSaving: false,
            shouldFetch: action.succeeded
        }
    },
    [Actions.GET_USER_CATEGORY_TAGS_COMPLETE]: (state: CategoryTagState, action: Actions.GetUserCategoryTagsComplete) => {
        return {
            ...state,
            userCategoryTags: action.categoryTags
        };
    },
    [Actions.SET_TAG_TO_EDIT]: (state: CategoryTagState, action: Actions.SetTagToEdit) => {
        return {
            ...state,
            editingTag: action.toEdit
        }
    },
    [Actions.SET_TAG_TO_VIEW]: (state: CategoryTagState, action: Actions.SetTagToView) => {
        return {
            ...state,
            tagToViewId: action.id,
            tagToView: action.tag,
        }
    },
    [Actions.LOAD_CATEGORY_TAG_ANALYTICS]: (state: CategoryTagState, action: Actions.LoadCategoryTagAnalytics) => {
        return {
            ...state,
            isLoadingDetails: true
        }
    },
    [Actions.LOAD_CATEGORY_TAG_ANALYTICS_COMPLETE]: (state: CategoryTagState, action: Actions.LoadCategoryTagAnalyticsComplete) => {
        return {
            ...state,
            isLoadingDetails: false
        }
    },
    [Actions.CLEAR_ERROR_MESSAGE]: (state: CategoryTagState, action: Actions.ClearErrorMessage) => {
        return {
          ...state,
          errorMessage: ""
        };
    },
}

export const reducer = (state: CategoryTagState, action: Action) => {
    const actionHandlerMethod = actionHandler[action.type];
    if (actionHandlerMethod) {
      return actionHandlerMethod(state, action);
    }
    return state || DefaultState;
};