import React from "react";
import HoverText from "modules/documents/components/action-buttons/hoverText";
import { getNiceTimerString } from "utils/stringFormatting";
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

interface ITextAndWordsProps {
    estimatedReadingTime?: string;
    wordCount?: number;
    usingGlobalStats?: boolean
    averageWordCount?: number
}

const warning = (): JSX.Element => <WarningIcon style={{ marginRight: 7, fontSize: 24 }} htmlColor="#E6911A" />;
const check = (): JSX.Element => <CheckCircleIcon style={{ marginRight: 7, fontSize: 24 }} htmlColor="#366531" />;

const TextAndWords: React.FunctionComponent<ITextAndWordsProps> = ({
    estimatedReadingTime = "",
    wordCount = 0,
    usingGlobalStats = false,
    averageWordCount = 0,
}) => {
    const bottomRange = averageWordCount < 100 ? 0 : Math.round(averageWordCount - 100); //don't allow bottom limit to go below 0
    const topRange = Math.round(averageWordCount + 100);

    const textIndicator = (title: string, count: string, first: boolean = false, icon?: JSX.Element) => (
        <>
            <div style={first ? {marginTop: 30} : {}} className="text-indicator-container">
                <div className="title" style={{display: "flex"}}>
                    {icon &&
                        <HoverText label={icon}>
                            {`${usingGlobalStats ? "Global" : "Internal"} average word count is ${bottomRange}-${topRange}`}
                        </HoverText>}
                    {title}
                </div>
                <span className="value">{count}</span>
            </div>
        </>
    );

    return (
        <>
            {textIndicator("Estimated Reading Time", getNiceTimerString(estimatedReadingTime))}
            {textIndicator("Word Count", 
                wordCount.toLocaleString(), 
                true, 
                (bottomRange <= wordCount && wordCount <= topRange) ? check() : warning(),
            )}
        </>
    );
}

export default TextAndWords;
