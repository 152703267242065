import { ApiRequest } from './network';
import { ImageScale, PostFeedFilters, PostFilterValues, PostListingPage, PostOverview, PostsSortField } from 'modules/posts/models';
import { SparrowApi } from 'api/network/adapters/SparrowApi';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { FileDownloader } from 'utils/fileDownloader';

export class PostsApi {

    constructor(private _sparrowApi: SparrowApi) {
    }

    public GetPost = (postId: string, imageScale: ImageScale = ImageScale.Mobile) => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().get('{tenant}/api/v2/posts/{id}', { params: { id: postId, imageScale } })
        );
    }

    public GetPublicPost = (tenantId: string, postId: string, imageScale: ImageScale = ImageScale.Mobile) => {
        return new ApiRequest(
            this._sparrowApi.CreateAnonymousRequest().get(`/${tenantId}/api/v2/anonymous/posts/${postId}`, { params: { imageScale } })
        );
    }

    public GetPublicFeed = (tenantId: string, filters: Partial<PostFeedFilters>, pageNumber: number) => {
        return new ApiRequest(
            this._sparrowApi.CreateAnonymousRequest().post(`/${tenantId}/api/v2/anonymous/posts/feed?maxResults=12&pageNumber=${pageNumber}`, filters)
        );
    }

    public GetPostOverviewDetails = (postId: string): ApiRequest => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().get('{tenant}/adminapi/v1/posts/{id}/overview', { params: { id: postId, imagesEncoded: "true", heroImageEncoded: "true" } })
        );
    }

    // V2 of PostOverview endpoint
    public GetPostOverview = (postId: string, imageScale: ImageScale = ImageScale.Mobile): ApiRequest<PostOverview> => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().get('{tenant}/adminapi/v2/posts/{id}/overview', { params: { id: postId, imageScale } })
        );
    }

    public GetPostOverviewAnalytics = (postId: string): ApiRequest => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().get('/{tenant}/insightsapi/v1/postStats/{id}', { params: { id: postId } })
        )
    }

    public GetPostReadershipAnalytics = (postId: string): ApiRequest => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().get('/{tenant}/insightsapi/v1/postStats/{id}/reads', { params: { id: postId } })
        )
    }

    public GetFirstWeekPostStats = (postId: string): ApiRequest => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().get('/{tenant}/insightsapi/v1/postStats/{id}/firstWeekStats', { params: { id: postId } })
        )
    }

    public GetTenantPostAverages = (maxResults: number = 50, fromDate: string = "1970-01-01", toDate: string = "2050-12-31"): ApiRequest => {
        const body = {
            maxResults,
            fromDate,
            toDate
        };

        return new ApiRequest(
            this._sparrowApi.TenantRequest().post('/{tenant}/adminapi/v1/stats/getTopPostAverages', body)
        );
    }

    public GetGlobalPostAverages = (): ApiRequest => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().get('/{tenant}/adminapi/v1/stats/getGlobalPostAverages')
        );
    }

    public GetPostsCSV = (filters: any, onlyCompliance: boolean, currentSort: PostsSortField, sortAscending: boolean): ApiRequest => {
        const body = {
            ...filters,
            postType: onlyCompliance ? "compliance" : "",
            currentSort,
            sortAscending
        }
        return new ApiRequest(
            this._sparrowApi.TenantRequest().post('{tenant}/adminapi/v1/posts/csv', body)
        );
    }

    public GetPostUsersComplianceStatus = (postId: string): ApiRequest => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().get('{tenant}/adminapi/v1/posts/usersComplied/{id}', { params: { id: postId } })
        );
    }

    public GetAllTags = (): ApiRequest => { //to filter by any tag
        return new ApiRequest(
            this._sparrowApi.TenantRequest().get('{tenant}/adminapi/v1/tags/all')
        );
    }

    public GetFirstPublishedPostAnalysisScores = (postId: string): ApiRequest => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().get(`{tenant}/adminapi/v2/postanalysis/{postId}`, { params: { postId } })
        );
    }

    public SendPostClosedEvent = (postId: string, lcid: string): ApiRequest => {
        const body = {
            Events: [{
                Name: "PostClosed",
                Platform: "Web",
                Time: new Date().toISOString(),
                PostId: postId,
                MaxDepth: 1,
                ElapsedTime: 5,
                SessionId: this.getId().toString(),
                PostLanguage: lcid
            }]
        };

        return new ApiRequest(
            this._sparrowApi.TenantRequest().post('{tenant}/api/v1/loggedevents', body)
        );
    }

    public SendPostOpenedEvent = (postId: string, lcid: string): ApiRequest => {
        const body = {
            Events: [{
                Name: "PostOpened",
                Platform: "Web",
                Time: new Date().toISOString(),
                PostId: postId,
                SessionId: this.getId().toString(),
                PostLanguage: lcid
            }]
        };

        return new ApiRequest(
            this._sparrowApi.TenantRequest().post('{tenant}/api/v1/loggedevents', body)
        );
    }

    public SendPublicPostClosedEvent = (tenantId: string, postId: string, lcid: string): ApiRequest => {
        const body = {
            Events: [{
                Name: "PostClosed",
                Platform: "Web",
                Time: new Date().toISOString(),
                PostId: postId,
                MaxDepth: 1,
                ElapsedTime: 5,
                SessionId: this.getId().toString(),
                PostLanguage: lcid
            }]
        };

        return new ApiRequest(
            this._sparrowApi.CreateAnonymousRequest().post(`${tenantId}/api/v1/loggedevents`, body)
        );
    }

    public SendPublicPostOpenedEvent = (tenantId: string, postId: string, lcid: string): ApiRequest => {
        const body = {
            Events: [{
                Name: "PostOpened",
                Platform: "Web",
                Time: new Date().toISOString(),
                PostId: postId,
                SessionId: this.getId().toString(),
                PostLanguage: lcid
            }]
        };

        return new ApiRequest(
            this._sparrowApi.CreateAnonymousRequest().post(`${tenantId}/api/v1/loggedevents`, body)
        );
    }

    public DownloadFile = (fileUrl: string, fileName: string, fileExt: string) => {
        var fileN = this.appendFileExtension(fileExt, fileName);
        const file = {
            name: fileN
        };
        const requestConfig: AxiosRequestConfig = {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json'
            }
        };
        axios.get(fileUrl, requestConfig)
            .then(res => new FileDownloader(file).downloadDoc(res.data))
    }

    public getPagedPosts = (
        pageNumber: number,
        filters: Partial<PostFilterValues>,
        pageAmount?: number,
        imageScale: ImageScale = ImageScale.Email
    ): Promise<AxiosResponse<PostListingPage>> => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().post(
                `/{tenant}/adminapi/v1/posts/paged`,
                filters,
                {
                    params: {
                        pageNumber,
                        pageAmount,
                        imageScale
                    }
                })
        ).catch((err) => {
            throw err;
        });
    }

    private appendFileExtension(fileExt: string, fileName: string) {
        var fileN = fileName;
        if (fileExt) {
            var ext: string | undefined;
            if (fileN.split('.').length > 1) {
                ext = fileN.split('.').pop();
                if (ext) {
                    fileN = fileN.replace(ext, fileExt);
                }
            } else {
                fileN = fileN + '.' + fileExt;
            }
        }
        return fileN;
    }

    private getId(): number {
        return 10000 * (Date.parse(new Date().toISOString()) - Date.parse(new Date(2001, 0, 1).toISOString()));
    }
}
