import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";

import { DocumentFeedItem } from "../../../../../models";
import Document from "./document";


import "../../../../../styles/layouts/card.sass";


const Card: React.FunctionComponent<PropsWithRedux> = props => {
  return (
    <div className="card">
      {props.documents.map(document => (
        <Document
          key={document.id}
          {...props}
          document={document}
        />
      ))}
    </div>
  );
}

interface ComponentProps {
  onDocumentSelected: (document: DocumentFeedItem) => void;
  onDownloadDocument: (document: DocumentFeedItem) => void;
  onViewDocument: (document: DocumentFeedItem) => void;
  onInspectDocument: (document: DocumentFeedItem) => void;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    documents: state.documents.documentFeed.documentFeed.documents
  })
);
type PropsWithRedux = ConnectedProps<typeof connector>;
export default connector(Card);