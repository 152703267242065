import React, { useRef, useState } from "react";

import Popover, { PopoverOrigin } from "@mui/material/Popover";


import "../callout.sass";


const CalloutHover: React.FC<ComponentProps> = ({
    component,
    contentStyle,
    anchorOrigin,
    children,
    arrow = false
}) => {
    const [showPopover, setShowPopover] = useState(false);
    const popoverRef = useRef(null);

    return (
        <>
            <div
                ref={popoverRef}
                onMouseEnter={() => setShowPopover(true)}
                onMouseLeave={() => setShowPopover(false)}
                className="callout-hover-component"
            >
                {component}
            </div>
            <Popover
                open={showPopover}
                anchorEl={popoverRef.current}
                anchorOrigin={anchorOrigin || { vertical: "bottom", horizontal: "center" }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                PaperProps={{
                    onMouseEnter: () => setShowPopover(true),
                    onMouseLeave: () => setShowPopover(false),
                    style: {
                        backgroundColor: "transparent",
                        boxShadow: "none",
                        maxWidth: 250,
                        overflow: "visible"
                    }
                }}
                style={{ pointerEvents: "none" }}
                onClose={() => setShowPopover(false)}
                classes={{
                    paper: "help-text-description"
                }}
            >
                {arrow && <div className="callout-arrow top"></div>}
                <div className="callout">
                    <div className="callout-content" style={contentStyle}>
                        {children}
                    </div>
                </div>
            </Popover>
        </>
    )
}

interface ComponentProps {
    component: JSX.Element;
    contentStyle?: React.CSSProperties;
    arrow?: boolean;
    anchorOrigin?: PopoverOrigin;
}

export default CalloutHover;
