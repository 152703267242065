import { NetworkRequest } from "network/actions";

export interface Handlers {
    onLoad?: (e: ProgressEvent<EventTarget>, xhr: XMLHttpRequest) => void,
    onError?: (e: ProgressEvent<EventTarget>, xhr: XMLHttpRequest) => void,
    onAbort?: (e: ProgressEvent<EventTarget>, xhr: XMLHttpRequest) => void,
    onProgress?: (progress: number) => void,
}

class XhrUpload {
    private url: string;
    private requestDetails: NetworkRequest;
    private xhr: XMLHttpRequest;
    private accessToken;
    private calculatePercentage: () => void;

    /* Expect these to be overriden */
    private onLoad?: (e: ProgressEvent<EventTarget>, xhr: XMLHttpRequest) => void;
    private onError?: (e: ProgressEvent<EventTarget>, xhr: XMLHttpRequest) => void;
    private onAbort?: (e: ProgressEvent<EventTarget>, xhr: XMLHttpRequest) => void;
    private onProgress?: (progress: number) => void;

    /**
     * Use this class to upload files and hook into progress / error / loaded events
     * 
     * @param url the url to upload to
     * @param requestDetails a network request object defining request properties (including the file to upload)
     * @param handlers an object containing onLoad, onError, onAbort, and onProgress handlers
     */
    constructor(url: string, requestDetails: NetworkRequest, handlers?: Handlers) {
        this.url = url;
        this.requestDetails = requestDetails;
        this.xhr = new XMLHttpRequest();

        if (handlers) {
            this.onLoad = handlers.onLoad;
            this.onError = handlers.onError;
            this.onAbort = handlers.onAbort;
            this.onProgress = handlers.onProgress;
        }

        return this;
    }

    /**
     * authenticate the upload with an access token
     */
    public authenticateWith = (accessToken: string) => {
        this.accessToken = accessToken;
        return this;
    }

    /**
     * begin the upload process and return a promise.
     */
    public upload = () => {
        return new Promise((res, rej) => {
            // set headers
            this.xhr.open(this.requestDetails.method, this.url);
            for (let k in this.requestDetails.headers) {
                this.xhr.setRequestHeader(k, this.requestDetails.headers[k]);
            }
            if (this.accessToken)
                this.xhr.setRequestHeader("Authorization", `Bearer ${this.accessToken}`);

            // override callbacks
            this.xhr.onload = e => {
                if (this.onLoad)
                    this.onLoad(e, this.xhr);
                res(this.xhr.responseText);
            };
            this.xhr.onerror = e => {
                if (this.onError)
                    this.onError(e, this.xhr);
                rej(e);
            };
            this.xhr.onabort = e => {
                if (this.onAbort)
                    this.onAbort(e, this.xhr);
                rej(e);
            };
            this.xhr.upload.addEventListener("progress", e => {
                if (this.onProgress)
                    this.onProgress((e.loaded / e.total) * 100 | 5);
            });

            // send the request
            this.xhr.send(this.requestDetails.body);
        });
    }
}

export default XhrUpload;
