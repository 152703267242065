import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { GlobalApplicationState } from 'globalApplicationState';
import * as actions from '../actionCreator';
import * as settingsActions from 'modules/settings/actionCreator';
import {injectIntl, FormattedMessage, IntlShape} from "react-intl";
import TagListItem from "modules/insights/components/tagListItem";
import { UserRoles } from 'modules/authorization/models';
import TagCreationModal from "./tagCreationModal";

import BasePage from "pages/common/basePage";
import Header from "pages/common/header";
import MainContent from "pages/common/mainContent";


export class TagList extends React.Component<PropsWithRedux, ComponentState> {

    private _draggingTagId: string | null = null;

    constructor(props){
        super(props);
        this.state = { showTagCreationModal: false, groupIdForNewTag: "" };
    }
   
    public componentWillMount() {
        if (!this.props.tagStats) {
            this.props.fetchTopChannels();
        }
    }
    
    public componentWillReceiveProps(nextProps: PropsWithRedux) {
        if (this.props.errorMsg !== nextProps.errorMsg && nextProps.errorMsg !== null) {
            this.setState({...this.state, showTagCreationModal: true });
        }
    }

    public render() {
        let newTag = this.props.tagGroups.find(tg => tg.id === this.state.groupIdForNewTag)?.tags.slice(-1).pop();

        return (
            <BasePage fullWidth>
                <Header
                    title="Manage Topics"
                />
                <MainContent>
                    <div className="analytics-page">
                        <div className="all-tags">
                            <div className="page-controls">
                            {
                                this.props.canEdit ?
                                    <button className="add-btn"
                                        disabled={(!this.props.tagStats && !this.props.tenantSettings) || this.props.saving}
                                        onClick={this.addTagGroup}
                                    >
                                        + New Topic Group
                                    </button>
                                    : null
                            }
                            </div>
                            <div className="page-body">    
                                                        
                            {
                                this.props.tagStats && this.props.tenantSettings ? 
                                    <table className="tag-list">
                                        <thead>
                                            <tr>
                                                <th className="empty">
                                                    <div style={{display: "flex", fontSize: "15px", fontWeight: "normal"}}>
                                                        <i className="material-icons" style={{verticalAlign: "sub", fontSize: '16px'}}>priority_high</i>
                                                        {" Mandatory"}
                                                        <i className="material-icons" style={{verticalAlign: "sub", fontSize: '16px', marginLeft: "10px", marginRight: "5px"}}>done</i>
                                                        {" Default"}
                                                        <i className="material-icons" style={{verticalAlign: "sub", fontSize: '16px', marginLeft: "10px", marginRight: "5px"}}>lock</i>
                                                        {" Restricted"}
                                                    </div>
                                                </th>
                                                <th className="num"><FormattedMessage id="insights.subscribers" defaultMessage="Subscribers" /></th>
                                                <th className="num"><FormattedMessage id="posts.posts" defaultMessage="Posts" /></th>
                                                <th className="num"><FormattedMessage id="insights.views" defaultMessage="Views" /></th>
                                            </tr>
                                        </thead>
                                        <tbody> 
                                            {
                                            this.props.tenantSettings.tagGroups.length === 0 && this.props.tagStats.length === 0 ?
                                                <td colSpan={4} style={{textAlign: 'center'}}>There are currently no topics set up</td>
                                                : this.props.tenantSettings.tagGroups.map(tg => 
                                                    <TagListItem 
                                                        key={tg.id} 
                                                        tagGroup={tg} 
                                                        tagStats={this.props.tagStats} 
                                                        createNewTag={this.createNewTag}
                                                        closeModal={this.closeModal}
                                                        onDropOnGroup={this.onDropGroup}
                                                        onDragTagStart={this.onDragTagStart}
                                                        onDragTagEnd={this.onDragTagEnd}
                                                    />
                                                ) 
                                            }            
                                        </tbody>         
                                    </table> 
                                    : <div className="fetching-message"><FormattedMessage id="common.fetching" defaultMessage="Fetching" />...</div>
                                }
                            </div>
                        </div>
                        <TagCreationModal
                            show={this.state.showTagCreationModal}
                            closeModal={this.closeModal}
                            saveNewTag={this.saveNewTag}
                            newTag={newTag}
                            updateTag={this.props.updateTag}
                            errorMssg={this.props.errorMsg}
                            showAudiences={this.props.showAudiences}
                        />
                    </div>
                </MainContent>
            </BasePage>
        );
    }

    private addTagGroup = () => {
        this.props.addTagGroup();
        window.scrollTo(0, document.body.scrollHeight);
    }
    
    private createNewTag = (id: string) => {
        this.setState({...this.state, showTagCreationModal: true, groupIdForNewTag: id });
        this.props.addTag(id);
    }

    private closeModal = () => {
        this.props.removeNewTag(this.state.groupIdForNewTag);
        this.setState({...this.state, showTagCreationModal: false });
    }

    private saveNewTag = () => {
       this.props.saveTenantSettings();
       this.setState({...this.state, showTagCreationModal: false });
    }

    private onDropGroup = (tagGroupID: string) => {
        if (!!this._draggingTagId) {
            this.props.moveTag(this._draggingTagId, tagGroupID);
            this.props.saveTenantSettings();
        }
    }

    private onDragTagStart = (e: React.DragEvent<HTMLDivElement>, tagId) => {
        this._draggingTagId = tagId;
      }
    
    private onDragTagEnd = (e: React.DragEvent<HTMLDivElement>) => {
        this._draggingTagId = null;
    }

}

interface ComponentState { showTagCreationModal: boolean, groupIdForNewTag: string }
interface ComponentProps { intl: IntlShape; }

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        tagStats: state.insights.topChannels,
        tenantSettings: state.settings.tenantSettings,
        saving: state.settings.saving,
        errorMsg: state.settings.errorMsg,
        canEdit: (!!state.settings.currentUser && state.settings.currentUser.roles.indexOf(UserRoles.Owner) > -1) && state.settings.tenantSettings.manageTopicsAudiencesInAdmin,
        tagGroups: state.settings.tenantSettings.tagGroups,
        showAudiences: state.settings.tenantSettings.showFeatures.audiences
    }),
    {
        fetchTopChannels: actions.fetchTopChannels,
        addTagGroup: settingsActions.addTagGroup,
        addTag: settingsActions.addTag,
        updateTag: settingsActions.updateTag,
        saveTenantSettings: settingsActions.saveTenantSettings,
        removeNewTag: settingsActions.removeNewTag,
        moveTag: settingsActions.moveTag
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default injectIntl(connector(TagList))
