import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";

import Chips from "modules/common/components/chips/chips";
import InfoHover from "modules/common/components/hovers/infoHover";

import { SaveNewsletterModel, NewsletterDynamicContentWindow, NewsletterTopicOption, SimpleTag, SaveNewsletterModelStateErrors } from "modules/newsletters/models";
import EditTags from "../../common/editTags";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";

import _ from "lodash";
import { FormControl, FormControlLabel, FormHelperText, MenuItem, Radio, RadioGroup, Select, Typography } from "@mui/material";

enum PostSorting {
  OldestFirst,
  NewestFirst
}

class ContentTypeAndTopicsSettings extends React.Component<PropsWithRedux, ComponentState> {
  constructor(props: PropsWithRedux) {
    super(props);

    this.state = {
      includeUpcomingEvents: false,
      includeUnreadPosts: false
    };
  }

  public render() {
    const { newsletter } = this.props;

    const includeUnreadPosts = this.state.includeUnreadPosts || newsletter.maxPostsToInclude > 0;
    const includeUpcomingEvents = this.state.includeUpcomingEvents || newsletter.maxEventsToInclude > 0;
    const isNewsletterAdhoc = newsletter.recurrenceType === 'Adhoc';

    const postSortingOptions = ["Oldest first", "Newest first"];
    const postSortingCurrentValue = this.props.newsletter.sortPostsAscending ? PostSorting.OldestFirst : PostSorting.NewestFirst;

    return (
      <div className="newsletter-page" style={{ marginBottom: 30 }}>
        <p className="tx-strong">Content type &amp; topics</p>
        <div>
          <Grid container spacing={2}>
            <Grid item xs={4}>
            <Typography style={{lineHeight:'42px'}}>Lock items to specific topics</Typography>
            </Grid>
            <Grid item xs={8}>
                <div>
                <FormControl component="fieldset"  error={_.some(this.props.modelErrors?.CustomTopics)}>
                  <RadioGroup
                    aria-label="topic option"
                    name="topicOption"
                    value={this.props.newsletter.topicOption}
                    onChange={(event)=> this.OnTopicsChange(event.target.value as NewsletterTopicOption)}
                  >
                    <FormControlLabel value="Employee" control={<Radio color="primary" />} label="Use employee's topic subscriptions" />
                    <FormControlLabel value="All" control={<Radio color="primary" />} label="Use all topics (ignore employee's topic subscriptions)" />
                    <FormControlLabel value="Custom" control={<Radio color="primary" />} label="Custom topics" />
                  </RadioGroup>
                  <FormHelperText>{_.first(this.props.modelErrors?.CustomTopics)}</FormHelperText>
                </FormControl>
                {
                  this.props.newsletter.topicOption === "Custom" && 
                  <div style={{display: "inline-block", marginLeft:'32px'}}>
                    <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
                      <Chips
                      chips={newsletter.customTopics || []}
                      emptyText="All topics"
                      onDelete={this.onDeleteCustomTopic}
                      />
                      <EditTags
                      customTopics={newsletter.customTopics || []}
                      tagSettings={this.props.tagSettings}
                      onChange={this.onChangeCustomTopics}
                      />
                    </div>
                  </div>
                }
              </div>
            </Grid>
          </Grid>
        </div>
        <div>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography style={{ lineHeight:'42px' }}>Include posts section</Typography>
                <InfoHover>Upcoming unread posts will be included in the newsletter editions, if any.</InfoHover>
              </div>
            </Grid>
            <Grid item xs={8}>
              <Switch
                color="primary"
                checked={includeUnreadPosts}
                onChange={(event) => this.onIncludePostsChange(event.target.checked)}
              />
            </Grid>
          </Grid>
          {includeUnreadPosts && 
            <Grid container spacing={2} style={{paddingBottom: "6px"}}>
              <Grid item xs={4}>
              </Grid>
              <Grid item xs={8}>
              <Typography style={{ float: 'left', lineHeight:'42px' }}>Include up to</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  autoComplete="off"
                  placeholder="1 - 20"
                  style={{width:'70px', float:'left', margin:'0px 8px'}}
                  error={_.some(this.props.modelErrors?.MaxPostsToInclude)}
                  helperText={_.first(this.props.modelErrors?.MaxPostsToInclude)}
                  value={this.props.newsletter.maxPostsToInclude > 0 ? this.props.newsletter.maxPostsToInclude : ''}
                  onChange={this.onChangeMaxPostsToInclude}
                  inputProps={{
                    maxLength: 2
                  }}
                />
                <Typography style={{float:'left', lineHeight: '42px'}}>unread posts</Typography> 
              </Grid>
            </Grid>
            }
            { includeUnreadPosts && isNewsletterAdhoc &&
            <Grid container spacing={2} style={{paddingBottom: "6px"}}>
              <Grid item xs={4}>
              </Grid>
              <Grid item xs={8}>
                <Typography style={{float:'left', lineHeight:'42px'}}>Published</Typography>
                <TextField
                    variant="outlined"
                    size="small"
                    autoComplete="off"
                    placeholder="days"
                    style={{float:'left', width:'62px', margin:'0px 8px'}}
                    error={_.some(this.props.modelErrors?.PostsToIncludeDaysWindow)}
                    helperText={_.first(this.props.modelErrors?.PostsToIncludeDaysWindow)}
                    value={this.props.newsletter.postsToIncludeDaysWindow > 0 ? this.props.newsletter.postsToIncludeDaysWindow : ''}
                    onChange={this.onChangePostsToIncludeDays}
                    inputProps={{
                      maxLength: 3
                    }}
                    />
                    <Typography style={{float:'left', lineHeight: '42px'}}>day(s) prior to issue date.</Typography> 
              </Grid>
            </Grid>
            }
            { includeUnreadPosts && !isNewsletterAdhoc &&
             <Grid container spacing={2}>
              <Grid item xs={4}>
              </Grid>
                <Grid item xs={8}>
                <div className="newsletter-checkbox-radio">
                  <Typography style={{lineHeight:'42px'}}>Published</Typography>
                </div>
                <div className="newsletter-checkbox-radio">
                  <FormControlLabel control={<Radio name="postsToInclude" value="SinceLastIssue" color="primary" checked={newsletter.postsToInclude === 'SinceLastIssue'} onChange={(event)=> event.target.checked && this.OnPostsToInclude('SinceLastIssue')} />} label="Since the last issue" />
                </div>
                <div className="newsletter-checkbox-radio">
                  <FormControlLabel control={<Radio name="postsToInclude" value="DaysBeforeCurrentIssue" color="primary" checked={newsletter.postsToInclude === 'DaysBeforeCurrentIssue'} onChange={(event)=> event.target.checked && this.OnPostsToInclude('DaysBeforeCurrentIssue')} />} label="Day(s) prior to issue date" style={{float:'left'}} />
                  {
                    this.props.newsletter.postsToInclude === "DaysBeforeCurrentIssue" && 
                    <TextField
                      variant="outlined"
                      size="small"
                      autoComplete="off"
                      style={{width:'62px', float: 'left'}}
                      error={_.some(this.props.modelErrors?.PostsToIncludeDaysWindow)}
                      helperText={_.first(this.props.modelErrors?.PostsToIncludeDaysWindow)}
                      placeholder="days"
                      value={this.props.newsletter.postsToIncludeDaysWindow > 0 ? this.props.newsletter.postsToIncludeDaysWindow : ''}
                      onChange={this.onChangePostsToIncludeDays}
                      inputProps={{
                        maxLength: 3
                      }}
                    />
                  }
                </div>
              </Grid>
            </Grid>
          }
            { includeUnreadPosts &&
              <Grid container spacing={2} style={{paddingBottom: "6px"}}>
                <Grid item xs={4}>
                </Grid>
              <Grid item xs={8}>
                Sorted by
                <Select style={{marginLeft: "12px", width: "180px"}} size="small"
                  value={postSortingCurrentValue} 
                  onChange={(e) => {
                    let sortingValueToSet = e.target.value === 0;
                    this.props.onNewsletterChange({sortPostsAscending: sortingValueToSet});
                  }}
                >
                  <MenuItem value={PostSorting.OldestFirst}>{postSortingOptions[0]}</MenuItem>
                  <MenuItem value={PostSorting.NewestFirst}>{postSortingOptions[1]}</MenuItem>
                </Select>
              </Grid>
              </Grid>
            }
         
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography style={{ lineHeight:'42px' }}>Include events section</Typography>
                <InfoHover>Upcoming events will be included in the newsletter editions, if any.</InfoHover>
              </div>
            </Grid>
            <Grid item xs={8}>
              <Switch
                color="primary"
                checked={includeUpcomingEvents}
                onChange={(event) => this.onIncludeEventsChange(event.target.checked)}
              />
            </Grid>
          </Grid>
          { includeUpcomingEvents && 
          <div>
            <Grid container spacing={2} style={{paddingBottom: "6px"}}>
                <Grid item xs={4}>
              </Grid>
              <Grid item xs={8}>
                <Typography style={{float:'left', lineHeight:'42px'}}>Include up to</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  autoComplete="off"
                  placeholder="1 - 20"
                  style={{width:'70px', float:'left', margin:'0px 8px'}}
                  error={_.some(this.props.modelErrors?.MaxEventsToInclude)}
                  helperText={_.first(this.props.modelErrors?.MaxEventsToInclude)}
                  value={this.props.newsletter.maxEventsToInclude > 0 ? this.props.newsletter.maxEventsToInclude : ''}
                  onChange={this.onChangeMaxEventsToInclude}
                  inputProps={{
                    maxLength: 2
                  }}
                />
                <Typography style={{float:'left', lineHeight: '42px'}}> upcoming events</Typography>
              </Grid>
            </Grid>
                  <Grid container spacing={2} style={{paddingBottom: "6px"}}>
                  <Grid item xs={4}>
                  </Grid>
                    <Grid item xs={8}>
                      <Typography style={{float:'left', lineHeight:'42px'}}> In the next</Typography>
                        <TextField
                          variant="outlined"
                          size="small"
                          autoComplete="off"
                          placeholder="days"
                          style={{float:'left', width: '62px', margin:'0px 8px'}}
                          error={_.some(this.props.modelErrors?.EventsToIncludeDaysWindow)}
                          helperText={_.first(this.props.modelErrors?.EventsToIncludeDaysWindow)}
                          value={this.props.newsletter.eventsToIncludeDaysWindow > 0 ? this.props.newsletter.eventsToIncludeDaysWindow : ''}
                          onChange={this.onChangeEventsToIncludeDays}
                          inputProps={{
                            maxLength: 3
                          }}
                        />
                        <Typography style={{float:'left', lineHeight: '42px'}}>day(s).</Typography> 
                    </Grid>
                  </Grid>
                  </div>
          }
        </div>
      </div>
    );
  }

  private onChangeCustomTopics = (customTopics: SimpleTag[]) => {
    this.props.onNewsletterChange({ customTopics });
  }

  private onChangeEventsToIncludeDays = (event) => {
    const regEx = /^[0-9\b]+$/;
    if(event.target.value === '' || regEx.test(event.target.value)) {
      this.props.onNewsletterChange({eventsToIncludeDaysWindow: event.target.value});
    }
    
    if(this.props.newsletter.maxEventsToInclude && this.props.newsletter.maxEventsToInclude > 50) {
      this.props.onNewsletterChange({maxEventsToInclude: 50});
    }
  }

  private onChangeMaxEventsToInclude = (event) => {
    const regEx = /^[0-9\b]+$/;
    if(event.target.value === '' || regEx.test(event.target.value)) {
      this.props.onNewsletterChange({maxEventsToInclude: event.target.value});
    }
    if(this.props.newsletter.maxEventsToInclude && this.props.newsletter.maxEventsToInclude > 50) {
      this.props.onNewsletterChange({maxEventsToInclude: 50});
    }
  }

  private onChangePostsToIncludeDays = (event) => {
    const regEx = /^[0-9\b]+$/;
    if(event.target.value === '' || regEx.test(event.target.value)) {
      this.props.onNewsletterChange({postsToIncludeDaysWindow: event.target.value});
    }
  }

  private onChangeMaxPostsToInclude = (event) => {
    const regEx = /^[0-9\b]+$/;
    if(event.target.value === '' || regEx.test(event.target.value)) {
      this.props.onNewsletterChange({maxPostsToInclude: event.target.value});
    }
  }

  private OnTopicsChange = (event: NewsletterTopicOption) => {
    this.props.onNewsletterChange({ topicOption: event });
  }

  private OnEventsToInclude = (event: NewsletterDynamicContentWindow) => {
    this.props.onNewsletterChange({ eventsToInclude: event });
  }

  private OnPostsToInclude = (event: NewsletterDynamicContentWindow) => {
    this.props.onNewsletterChange({ postsToInclude: event });
  }

  private onDeleteCustomTopic = (topicId: string) => {
    this.props.onNewsletterChange({ customTopics: this.props.newsletter.customTopics!.filter(topic => topic.id !== topicId) });
  }

  private onIncludePostsChange = (value: boolean) => {
    this.setState({includeUnreadPosts: value});
    if (!value) {
      this.props.onNewsletterChange({maxPostsToInclude: 0});
    }
  }

  private onIncludeEventsChange = (value: boolean) => {
    this.props.onNewsletterChange({includeUpcomingEvents: value});
    this.setState({includeUpcomingEvents: value});
    if (!value) {
      this.props.onNewsletterChange({maxEventsToInclude: 0});
    }
  }
}

interface ComponentProps {
  newsletter: SaveNewsletterModel;
  modelErrors: SaveNewsletterModelStateErrors | null;
  highlight: boolean;
  onNewsletterChange: (value: Partial<SaveNewsletterModel>) => void;
}

interface ComponentState {
  includeUnreadPosts: boolean;
  includeUpcomingEvents: boolean;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    tagSettings: state.settings.tagSettings
  })
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(ContentTypeAndTopicsSettings);