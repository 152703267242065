import React, { useState } from "react";

import Button from "@mui/material/Button";

import { Theme } from "@mui/material/styles";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Callout from "../callout";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    optionsWrapper: {
      display: "inline-block",
      position: "relative"
    },
    options: {
      position: "absolute",
      top: 8
    }
  })
);

const FilterMoreOptionsButton: React.FunctionComponent<ComponentProps> = props => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [calloutOpen, setCalloutOpen] = useState(false);

  const classes = useStyles();

  return (
    <div className={classes.optionsWrapper}>
      <Button
        variant={props.variant ?? "outlined"}
        color={(!props.color || props.color === 'default') ? "primary" : props.color}
        size={props.size ?? "medium"}
        disabled={props.disabled ?? false}
        onClick={(ev: any) => {
          ev.stopPropagation();
          setAnchorEl(ev.currentTarget);
          setCalloutOpen(true);
        }}
        className={props.className}
      >
        {props.text || "More Options"}
        <ArrowDropDownIcon />
      </Button>
      <Callout
        anchorEl={anchorEl}
        open={calloutOpen}
        setOpen={setCalloutOpen}
        footer={props.footer}
        header={props.header}
      >
        {props.children}
      </Callout>
    </div>
  );
}

interface ComponentProps {
  text?: string;
  onClick?: (ev) => void;
  variant?: "text" | "outlined" | "contained" | undefined;
  color?: "inherit" | "primary" | "secondary" | "default" | undefined;
  size?: "small" | "medium" | "large" | undefined;
  disabled?: boolean;
  className?: string;
  footer?: JSX.Element;
  header?: JSX.Element;
}

export default FilterMoreOptionsButton;