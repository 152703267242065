import * as React from "react";

import InfoHover from "modules/common/components/hovers/infoHover";


const Revision: React.FunctionComponent<ComponentProps> = props => {
  if (!props.revisions)
    return <React.Fragment></React.Fragment>;
  
  return (
    <div>
      <div>
        <span>Version no.</span>
        <InfoHover>No. of times the file has been replaced. Version 1 is the initial file uploaded.</InfoHover>
      </div>
      <div className="revisions">{props.revisions}</div>
    </div>
  );
}


interface ComponentProps {
  revisions: number;
}

export default Revision;