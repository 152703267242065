import * as React from "react";

import moment from "moment";


const PublishTime: React.FunctionComponent<ComponentProps> = props => {
  moment.locale("en");
  
  return (
    <div className="publish-time">
      {moment(props.publishTime).format("MMMM D, YYYY")}
    </div>
  );
}

interface ComponentProps {
  publishTime: string;
}

export default PublishTime;