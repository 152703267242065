import { Action } from "redux";
import { ActionCreator } from "typedActions";
import {
    MessagingTabs
} from "./models";


export const SET_ACTIVE_MESSAGING_TAB =  "SET_ACTIVE_MESSAGING_TAB";
export interface SetActiveMessagingTab extends Action {
    activeTab: MessagingTabs;
}
export const CreateSetActiveMessagingTab = ActionCreator<SetActiveMessagingTab>(SET_ACTIVE_MESSAGING_TAB);
