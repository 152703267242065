import * as React from "react";
import ColumnWidthCalculator from "./column-width-calculator";
import shallowEquals from "utils/shallowEquals";

import { Icons } from "modules/common/icons";
import { PostsSortField } from "modules/posts/models";

import "../styles/table-view.sass";

type KeyValuePair<T> = { [key: string]: T };

export interface DataColumn {
    dataKey: string;
    name: string;
    width?: number;
    fixedWidth?: string;
    truncate?: boolean;
    renderer?: (data: any, column: DataColumn, selected: boolean) => JSX.Element;
    sortable?: boolean;
}

interface ComponentProps {
    forPosts?: boolean;
    collapsed?: boolean;
    columns: DataColumn[];
    data: KeyValuePair<any>;
    multiselect?: boolean;
    columnGridMax?: number;
    headerRenderer?: (column: any, index: number) => JSX.Element;
    onSelectionChange?: (keys: string[]) => void;
    onRowClick?: (key: string) => void;
    noCheckbox?: boolean;
    makeRowClickSelect?: boolean;
    forceUpdate?: boolean;
    lastSort?: PostsSortField;
    currentSort?: PostsSortField;
    sortAscending?: boolean; //if false, sort by descending
    setSort?: (sort: PostsSortField, ascending: boolean) => any;
}

interface ComponentState {
    selectedRowKeys: string[];
}

export default class TableView extends React.Component<ComponentProps, ComponentState> {
    private columnWidthCalc: ColumnWidthCalculator;

    constructor(props) {
        super(props);
        this.state = { selectedRowKeys: [] };
    }

    public componentWillMount() {
        let calcSettings = { maxColumns: this.props.columnGridMax || 16 };
        this.columnWidthCalc = new ColumnWidthCalculator(calcSettings);
        this.setState((prev) => ({ ...prev, selectedRowKeys: [] }));
    }

    public shouldComponentUpdate(nextProps, nextState) {
        return (
            nextProps.forceUpdate ||
            !shallowEquals(this.props.data, nextProps.data) ||
            !shallowEquals(this.state.selectedRowKeys, nextState.selectedRowKeys) ||
            !shallowEquals(
                this.props.columns.map((c) => c.dataKey),
                nextProps.columns.map((c) => c.dataKey)
            ) ||
            this.props.collapsed !== nextProps.collapsed
        );
    }

    public componentDidUpdate(prevProps: ComponentProps, prevState: ComponentState) {
        if (!shallowEquals(this.state.selectedRowKeys, prevState.selectedRowKeys)) {
            this.onSelectionChange();
        } else if (!shallowEquals(this.props.data, prevProps.data)) {
            // check to ensure selected keys are still available
            this.setState((prev) => ({ ...prev, selectedRowKeys: this.state.selectedRowKeys.filter((k) => this.props.data[k]) }));
        }
    }

    public render() {
        return (
            <div
                className="tableview"
                style={{ display: this.props.collapsed ? "none" : "block", marginBottom: this.props.forPosts ? "50px" : "0" }}
            >
                {this.renderRowHeadings()}
                {this.renderRows()}
            </div>
        );
    }

    public onRowClicked = (key: string) => {
        if (this.props.makeRowClickSelect) {
            return this.onRowSelected(key);
        }
        if (this.props.onRowClick) {
            return this.props.onRowClick(key);
        }
    };

    public onRowSelected = (key: string) => {
        let index = this.state.selectedRowKeys.indexOf(key);
        if (index > -1) {
            this.setState(
                {
                    ...this.state,
                    selectedRowKeys: this.state.selectedRowKeys.filter((r) => r !== key),
                },
                this.onSelectionChange
            );
        } else {
            this.setState(
                {
                    ...this.state,
                    selectedRowKeys: this.props.multiselect === true ? [...this.state.selectedRowKeys, key] : [key],
                },
                this.onSelectionChange
            );
        }
    };

    public getSelectedRows = () => {
        return this.state.selectedRowKeys;
    };

    public deselectAll = () => {
        this.setState({
            ...this.state,
            selectedRowKeys: [],
        });
    };

    private onSelectionChange = () => {
        if (this.props.onSelectionChange) {
            this.props.onSelectionChange(this.getSelectedRows());
        }
    };

    private isRowSelected = (key) => {
        return this.state.selectedRowKeys.indexOf(key) > -1;
    };

    private renderRowHeadings = () => {
        return (
            <div className="tableview-headers" data-style="display: flex">
                {this.props.noCheckbox ? null : this.renderCheckboxSpaceForHeader()}
                {this.props.columns.map((c, i) => this.renderHeader(c, i))}
            </div>
        );
    };

    private renderRows = () => {
        if (Object.keys(this.props.data).length === 0) {
            return (
                <div className="tableview-rows">
                    <p className="empty-row"> No items were found.</p>
                </div>
            );
        } else {
            return (
                <div className="tableview-rows">
                    {Object.keys(this.props.data).map((k, i) => this.renderRow(this.props.data[k], this.isRowSelected(k), k, i))}
                </div>
            );
        }
    };

    private renderHeader = (column: DataColumn, i) => {
        if (this.props.headerRenderer) {
            return this.props.headerRenderer(column, i);
        }
        return (
            <div
                className="tableview-header"
                onClick={() => {
                    return !!this.props.forPosts && !!column.sortable && this.sortByCol(column.name as PostsSortField);
                }}
                key={column.dataKey}
                style={{ ...this.getColumnWidth(column.dataKey), cursor: this.props.forPosts && column.sortable ? "pointer" : "default" }}
            >
                {column.name}
                {this.props.forPosts ? (
                    column.name === this.props.currentSort ? (
                        <span className={this.props.sortAscending ? "caret up" : "caret"}>▼</span>
                    ) : null
                ) : null}
            </div>
        );
    };

    private renderRow = (dataRow: any, selected: boolean, key: string, index: number) => {
        return (
            <div
                className={"tableview-row" + (selected ? " selected" : "")}
                data-style="display: flex"
                key={`row-${index}`}
                onClick={() => this.onRowClicked(key)}
            >
                {this.props.noCheckbox === true ? null : this.renderSelectableCheckbox(key, selected)}
                {this.props.columns.map((column) => this.renderColumn(dataRow, column, selected))}
            </div>
        );
    };

    private renderCheckboxSpaceForHeader = () => {
        return <div className="tableview-column" style={{ flexGrow: 0, flexBasis: "30px" }}></div>;
    };

    private renderSelectableCheckbox = (key: string, selected: boolean) => {
        return (
            <div
                className="tableview-column"
                style={{
                    cursor: "pointer",
                    flexGrow: 0,
                    flexBasis: "30px",
                    margin: "-10px -2px -10px -16px",
                    padding: "10px 2px 10px 16px",
                }}
                onClick={(e) => {
                    e.stopPropagation();
                    this.onRowSelected(key);
                }}
            >
                <div
                    className="clickTarget"
                    onClick={(e) => {
                        e.stopPropagation();
                        this.onRowSelected(key);
                    }}
                ></div>
                <div className="checkButton">
                    {selected ? (
                        <Icons.CheckBoxChecked width="18" height="18" color="#2196F3" style={{ marginTop: "1px" }} />
                    ) : (
                        <Icons.CheckBox width="18" height="18" color="#757575" style={{ marginTop: "1px" }} />
                    )}
                </div>
            </div>
        );
    };

    private renderColumn = (dataRow: any, column: DataColumn, selected: boolean) => {
        return (
            <div className="tableview-column" key={`col-${column.dataKey}`} style={this.getColumnWidth(column.dataKey)}>
                <div className={column.truncate ? "truncate" : ""}>
                    {column.renderer ? (
                        column.renderer(dataRow, column, selected)
                    ) : (
                        <span style={{ marginLeft: column.truncate ? "5px" : "0" }}>{dataRow[column.dataKey]}</span>
                    )}
                </div>
            </div>
        );
    };

    private getColumnWidth = (colKey) => {
        const column = this.props.columns.find((c) => c.dataKey === colKey);
        if (!!column && column.fixedWidth) {
            return { flexGrow: 0, flexBasis: column.fixedWidth };
        }
        let cols = this.props.columns.map((c) => {
            return { key: c.dataKey, width: c.width };
        });
        return this.columnWidthCalc.CalculateWidth(cols)[colKey];
    };

    private sortByCol = (colName: PostsSortField) => {
        let ascending = this.props.lastSort === colName ? !this.props.sortAscending : true;
        !!this.props.setSort && this.props.setSort(colName, ascending);
    };
}
