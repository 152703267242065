import React, { CSSProperties } from "react";
import { Link } from "react-router-dom";

import TenantLink from "modules/common/components/tenantLink";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { Theme } from "@mui/material/styles";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    breadcrumb: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: 10,
      "& a": {
        "&:hover": {
          textDecoration: "none"
        }
      },
      "& > a": {
        display: "inline-block",
        marginTop: 5
      }
    },
    crumbs: {
      display: "flex",
      alignItems: "center",
      fontWeight: 600,
      "& > div": {
        display: "inline-flex",
        alignItems: "center"
      },
      "& a": {
        color: "#adb7c1"
      }
    },
    separator: {
      color: "#adb7c1",
      padding: "0 8px"
    },
    title: {
      color: "#adb7c1"
    },
    titleLink: {
      color: "#adb7c1",
      cursor: "pointer"
    },
    backItem: {
      fontWeight: 400,
      paddingLeft: 0,
      textTransform: "none",
      "&:hover": {
        backgroundColor: "transparent"
      }
    },
    breadcrumbOptions: {
      "& button, & div": {
        marginRight: 8,
        "&:last-child": {
          marginRight: 0
        }
      }
    }
  })
);

const BackItem: React.FunctionComponent<BackItem> = props => {
  const classes = useStyles();

  const button: JSX.Element =
    <Button variant="text" color="primary" disableRipple startIcon={<ChevronLeftIcon />} className={classes.backItem}>
        <span style={props.textStyle}>{props.title}</span>
    </Button>;

  if (props.link)
    return <TenantLink to={props.link}>{button}</TenantLink>;
  else if (props.onClick)
    return <div onClick={props.onClick}>{button}</div>;
  else
    return button;
}

const Breadcrumb: React.FunctionComponent<ComponentProps> = props => {
  const classes = useStyles();

  return (
    <div className={classes.breadcrumb}>
      <div>
        <div className={classes.crumbs}>
          {props.items.map((item, index) => (
            <div key={item.title ? item.title : index}>
              {item.link
                ? <TenantLink to={item.link}>
                    <Typography variant="h2" className={classes.title}>{item.title}</Typography>
                  </TenantLink>
                : item.onClick
                  ? <Typography variant="h2" onClick={item.onClick} className={classes.titleLink}>{item.title}</Typography>
                  : item.handleLinkByParent
                    ? <Link to="#" onClick={item.handleLinkByParent}>
                        <Typography variant="h2">{item.title}</Typography>
                      </Link>
                    : <Typography variant="h2" className={index === 0 ? classes.title : ""}>{item.title}</Typography>
              }
              {index < props.items.length - 1 ? <div className={classes.separator}>/</div> : null}
            </div>
          ))}
        </div>
        {!!props.backItem &&
          <BackItem {...props.backItem} />
        }
      </div>
      {props.rightComponent &&
        <div className={classes.breadcrumbOptions}>{props.rightComponent}</div>
      }
    </div>
  );
}

interface BreadcrumbItem {
  title: string;
  link?: string;
  handleLinkByParent?: () => void;
  onClick?: () => void;
}

interface BackItem {
  title: string;
  link?: string;
  onClick?: () => void;
  textStyle?: CSSProperties;
}

interface ComponentProps {
  items: BreadcrumbItem[];
  backItem?: BackItem;
  rightComponent?: JSX.Element;
}

export default Breadcrumb;
export { BackItem };
