import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { push } from "react-router-redux";
import * as actions from "modules/portalPages/actionCreator";
import { GlobalApplicationState } from "globalApplicationState";

import { PortalPage } from "modules/portalPages/models";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import CloseIcon from "@mui/icons-material/Close";


class PublishDialog extends React.Component<PropsWithRedux, ComponentState> {
  public render() {
    return (
      <Dialog open={this.props.show} maxWidth={false} onClose={this.onClose}>
        <DialogTitle className="portal-pages-dialog-header">
          <div className="portal-pages-dialog-header-title">
            <Typography variant="h2">Publish portal page</Typography>
            <IconButton onClick={this.onClose} size="large">
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent className="portal-page-dialog-content">
          <div>
            <span>Your changes have been saved. Would you like to publish your changes?</span>
          </div>
        </DialogContent>
        <DialogActions className="portal-pages-dialog-footer">
          <Button variant="text" color="primary" onClick={this.onClose}>Keep editing</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={this.onPublish}
          >
            {this.props.page.hasBeenPreviouslyPublished
              ? this.props.page.state === "Enabled"
                ? "Publish changes"
                : "Publish with changes"
              : "Publish"
            }
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  private onPublish = () => {
    this.onClose();
    this.props.publishDraft(this.props.page.draftId).then((succeeded) => {
      if (succeeded)
        this.props.redirectTo("/" + this.props.tenantId + "/admin/portalPages");
    });
  }

  private onClose = () => {
    this.props.onClose();
  }
}


interface ComponentProps {
  show: boolean;
  page: PortalPage;
  onClose: () => void;
}

interface ComponentState {}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    tenantId: state.tenant.id
  }),
  {
    redirectTo: push,
    publishDraft: actions.publishDraft
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(PublishDialog);