import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import * as actions from "../../actionCreator";
import { GlobalApplicationState } from "globalApplicationState";

import { Address, ConfigChannel, NewsletterConfigDigest, SaveNewsletterConfigModelStateErrors } from "../../models";

import InfoHover from "modules/common/components/hovers/infoHover";
import Loading from "modules/common/components/loading";
import { errorAlert } from "utils/notyPopups";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";

import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";

import _ from "lodash";
import "../../styles/dialogs.sass"
import NewsletterSender from "./newsletterSender";
import ErrorSnackbar from "modules/common/components/snackbars/errorSnackbar";


const LinkPreferences: { [channel in ConfigChannel]: string } = {
  AdminPortal: "Company Portal / Mobile",
  SharePoint: "SharePoint",
  Teams: "MS Teams"
};


class GlobalSettings extends React.Component<PropsWithRedux, ComponentState> {
  constructor(props: PropsWithRedux) {
    super(props);
    this.state = {
      senderName: "",
      senderEmail: "",
      senderFilter: "",
      addresses: [],
      originalAddresses: [],
      displayAddresses: [],
      originalName: "",
      originalEmail: "",
      errorMessage: "",
      senderInFocus: {
        fromEmailAddress: "",
        fromEmailName: "",
      } as Address, 
    };
  }

  public componentDidMount() {
    this.props.getConfig().then((currentConfig) => {
      var copy = JSON.parse(JSON.stringify(currentConfig)); //Make copy so that we aren't accessing the same object twice.
      this.setState({config: copy, currentConfig: currentConfig, addresses: currentConfig.addresses, originalAddresses: currentConfig.addresses, displayAddresses: currentConfig.addresses, 
        originalName: currentConfig.fromEmailName, originalEmail: currentConfig.fromEmailAddress});
    });
  }

  public render() {
    return (
      <React.Fragment>
        <Dialog open={this.props.dialogOpen} maxWidth={false} scroll="paper" onClose={this.props.onClose} classes={{ paper: "newsletter-dialog" }}>
          <DialogTitle className="newsletter-dialog-title">
            <div>Newsletter Global Settings</div>
            <div>
              <IconButton onClick={this.props.onClose} size="large">
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent>
            {this.getContent()}
          </DialogContent>
        </Dialog>
        <Dialog open={this.props.confirmOpen}>
          <DialogTitle className="newsletter-dialog-title">
            <div style={{color: "#2f4050"}}>Delete sender email
              <div style={{float: "right", position: "relative", top: "-2px"}}>
                <IconButton onClick={this.props.closeConfirm} size="small">
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
          </DialogTitle>
          <DialogContent>
              <div>
                <div>
                  Are you sure you want to delete this sender email?
                </div>
                <div style={{paddingTop: "28px"}}>
                  <Button color="primary" variant="contained" onClick={() => this.onDeleteSender(this.state.senderInFocus)} style={{float: "right", paddingBottom: "4px"}}>
                    Yes
                  </Button>
                  <Button onClick={this.props.closeConfirm} style={{float: "right", paddingBottom: "4px"}}>
                    No
                  </Button>
                </div>
              </div>
            </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }

  private getContent = (): JSX.Element => {
    var onlyItem = this.state.addresses.length === 1

    if (!this.state.config)
      return <Loading padding={12} />;

    return (
      <div style={{paddingTop: "8px"}}>
        <div style={{display: "flex", flexDirection: "row"}}>
          <div style={{width: "180px"}}>
            <div>
              Sender Emails 
                <span style={{position: "relative", top: "6px"}}>
                  <InfoHover>List of all 'From' names and emails of your newsletters. Edit a newsletter to modify its sender email. You cannot remove emails currently used in newsletter(s).</InfoHover>
                </span>
            </div>
          </div>
          <div>
            <div className="newsletter-sender-edit-section">
              <div className="global-settings-sender">
                <div>
                  <div>
                    <TextField
                      variant="outlined"
                      size="small"
                      value={this.state.senderName}
                      error={_.some(this.state.modelErrors?.FromEmailName)}
                      helperText={_.first(this.state.modelErrors?.FromEmailName)}
                      fullWidth
                      autoComplete="off"
                      onChange={(event) => this.onChangeSenderName(event.target.value)}
                      placeholder="Enter 'From' name"
                      style={{marginRight: "5px", marginLeft: "5px"}}
                    />
                  </div>
                </div>
                <div>
                  <div className="sender-email">
                    <TextField
                      variant="outlined"
                      size="small"
                      value={this.state.senderEmail}
                      error={_.some(this.state.modelErrors?.FromEmailAddress)}
                      helperText={_.first(this.state.modelErrors?.FromEmailAddress)}
                      fullWidth
                      autoComplete="off"
                      onChange={(event) => this.onChangeSenderEmail(event.target.value)}
                      placeholder="Enter email prefix"
                      style={{marginLeft: "5px", width: "60%"}}
                    />
                    <TextField
                      variant="outlined"
                      size="small"
                      value={"@"+_.last(this.state.config.fromEmailAddress.split("@")) || ""}
                      disabled
                      style={{marginRight: "5px", marginLeft: "5px", width: "40%"}}
                    />
                  </div>
                </div>
                <div>
                  <Button onClick={() => this.onAddNewSender()} color="primary" style={{marginRight: "4px"}}>Add</Button>
                  <Button style={{color: "#888888"}} onClick={() => this.onDiscard()}>Discard</Button>
                </div>
              </div>
            </div>
            <div className="newsletter-sender-edit-section">
                <TextField
                variant="outlined"
                size="small"
                value={this.state.senderFilter}
                placeholder="Search name or email"
                InputProps={{
                  startAdornment: <SearchIcon style={{fill: "#666666", marginRight: "5px"}} />
                }}
                onChange={(event) => this.onUpdateTextToSearch(event.target.value)}
                onKeyUp={this.onKeyUp}
                className="newsletter-search-bar"
                />

              <div className="newsletter-sender-list">
                {this.state.displayAddresses.map((address, index) => {
                  return (
                    <div key={index}>
                      <NewsletterSender onlyItem={onlyItem} addressToDelete={address} onDelete={() => this.setAndPrepareForDelete(address)} onLocked={this.props.onCheckExisting}/>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
        <Divider light />
        {this.state.config.availableLinkPreferences.length > 1 && 
        <div className="global-settings-preferred-channel">
          <div>
            <span>Preferred channel when opening content</span>
            <InfoHover>This setting will redirect the reader to your preferred channel when opening content from the newsletter.</InfoHover>
          </div>
          <div>
            <FormControl error={_.some(this.state.modelErrors?.LinkPreference)}>
              <RadioGroup value={this.state.config.linkPreference} onChange={(event) => this.onChangePreferredChannel(event.target.value as ConfigChannel)}>
                {this.state.config.availableLinkPreferences.map((linkPreference) =>
                  <FormControlLabel key={linkPreference} value={linkPreference} control={<Radio color="primary" />} label={LinkPreferences[linkPreference]} />
                )}
              </RadioGroup>
              <FormHelperText>{_.first(this.state.modelErrors?.LinkPreference)}</FormHelperText>
            </FormControl>
          </div>
        </div>
        }
        <div style={{flex: "content", float: "right", marginTop: "10px", marginBottom: "5px"}}>
          <Button onClick={this.props.onClose}>Cancel</Button>
          <Button variant="contained" color="primary" disabled={_.isEqual(this.state.config, this.state.currentConfig)} onClick={this.onSave}>Save changes</Button>
        </div>

        <ErrorSnackbar errorMessage={this.state.errorMessage} clearErrorMessage={this.clearErrorMessage}/>
      </div>
    );
  }

  private setAndPrepareForDelete = (toInspect: Address) => {
    this.setState({senderInFocus: toInspect});
    this.props.openConfirm();
  }

  private clearErrorMessage = () => {
    this.setState({errorMessage: ""});
  }

  private onChangeSenderEmail = (emailAddress: string) => {
    var acceptableFormat = /^[A-Za-z0-9\.\_\-]+$/ //eslint-disable-line
    if(acceptableFormat.test(emailAddress) || emailAddress === "") {
      this.setState({senderEmail: emailAddress ?? ""})
    }
  }

  private onChangeSenderName = (fromEmailName: string) => {
    this.setState({senderName: fromEmailName ?? ""})
  }

  private onDiscard = () => {
    this.setState({senderEmail: "", senderName: ""})
  }
  
  private onUpdateTextToSearch = (filterText: string) => {
    this.setState({senderFilter: filterText})
  }

  private validateSenderDetails = (newSender: Address) => {
    var domain = _.last(this.state.config?.fromEmailAddress.split("@")) ?? "";
    var endCharacter = this.state.senderEmail.trim().charAt(this.state.senderEmail.length-1);
    var advancedCheck = /^[A-Za-z0-9]+([\.\_\-][A-Za-z0-9]+)*$/ //eslint-disable-line

    if(domain === "")
    {
      this.setState({errorMessage: "Cannot process a sender without a domain."})
      return false;
    }

    if(this.state.senderEmail.trim() === "" || this.state.senderName.trim() === "")
    {
      this.setState({errorMessage: "Cannot process a sender with an empty field."})
      return false;
    }

    if( endCharacter === "." || endCharacter === "_" || endCharacter === "-")
    {
      this.setState({errorMessage: "Cannot process a sender that ends in a special character."})
      return false;
    }

    if (!advancedCheck.test(this.state.senderEmail)) 
    {
      this.setState({errorMessage: "Formatting error. Make sure no special characters are beside each other."})
      return false;
    }

    if(this.state.addresses.filter(a => a.fromEmailAddress === newSender.fromEmailAddress && a.fromEmailName === newSender.fromEmailName).length > 0)
    {
      this.setState({errorMessage: "Cannot add a sender that already exists.  "})
      return false;
    }

    return true;
  }

  private onAddNewSender = () => {
    var domain = _.last(this.state.config?.fromEmailAddress.split("@")) ?? "";
    var newSender = {
      fromEmailAddress: (this.state.senderEmail + "@" + domain).trim(),
      fromEmailName: this.state.senderName.trim(),
      currentlyInUse: false
    }

    var result = this.validateSenderDetails(newSender);
    if(!result)
      return;
    
      this.updateAddressesWithNewAddress(newSender);
  }

  private updateAddressesWithNewAddress = (newSender) => {
    var newAddresses = this.state.addresses;
      newAddresses = newAddresses.concat(newSender);
      var newConfig = this.state.config;
      
      if(!!newConfig) {
        newConfig.addresses = newAddresses;
      }

      this.setState({addresses: newAddresses, config: newConfig, displayAddresses: newAddresses})
  }

  private onDeleteSender = (address: Address) => {
    this.props.closeConfirm();
    if(this.state.addresses.filter(a => a.fromEmailAddress === address.fromEmailAddress && a.fromEmailName === address.fromEmailName).length > 0)
    {
      var newAddresses = this.state.addresses;
      newAddresses = newAddresses.filter(a => !(a.fromEmailAddress === address.fromEmailAddress && a.fromEmailName === address.fromEmailName))
      var newConfig = this.state.config;

      if(!!newConfig) {
        newConfig.addresses = newAddresses;
      }

      this.setState({addresses: newAddresses, config: newConfig, originalAddresses: newAddresses, displayAddresses: newAddresses})
    }
    else
    {
      this.setState({errorMessage: "Cannot delete a sender that doesn't exist."})
    }
  }

  private timeout;

  private onKeyUp = (key) => {
    if (key.keyCode === 13) {
      var newAddresses = this.state.addresses.filter(a => a.fromEmailName.toLocaleLowerCase().includes(this.state.senderFilter.toLocaleLowerCase()) || 
      a.fromEmailAddress.toLocaleLowerCase().includes(this.state.senderFilter.toLocaleLowerCase()))
      this.setState({displayAddresses: newAddresses})
    }
    else{
        clearInterval(this.timeout);
        this.timeout = setTimeout(() => {
            var newAddresses = this.state.addresses.filter(a => a.fromEmailName.toLocaleLowerCase().includes(this.state.senderFilter.toLocaleLowerCase()) || 
            a.fromEmailAddress.toLocaleLowerCase().includes(this.state.senderFilter.toLocaleLowerCase()))
            this.setState({displayAddresses: newAddresses})
        }, 500)
    }
  }

  private onChangePreferredChannel = (linkPreference: ConfigChannel) => {
    this.setState({ config: {...this.state.config!, linkPreference: linkPreference}});
  }

  private onSave = () => {
    this.setState({isSaving: true});
    var newConfig = this.state.config!;
    newConfig.fromEmailAddress = this.state.originalEmail;
    newConfig.fromEmailName = this.state.originalName;
    this.setState({senderName: "", senderEmail: "", config: newConfig});
    this.props.saveConfig(newConfig).then(result => {
      if(!!result.success){
        this.setState({config: result.success, currentConfig: result.success, isSaving: false, modelErrors: null});
        this.props.onClose();
      }else if(!!result.error){
        errorAlert('Unable to Save', 5000);
        this.setState({isSaving: false, modelErrors: result.error.ModelState});
      }
    });
  }
}


interface ComponentProps {
  onClose: () => any;
  dialogOpen: boolean;
  confirmOpen: boolean;
  onCheckExisting: (Address) => void;
  closeConfirm: () => void;
  openConfirm: () => void;
}

interface ComponentState {
  config?: NewsletterConfigDigest;
  currentConfig?: NewsletterConfigDigest;
  modelErrors?: SaveNewsletterConfigModelStateErrors | null;
  addresses: Address[];
  originalAddresses: Address[];
  displayAddresses: Address[];
  isSaving?: boolean;
  senderName: string;
  senderEmail: string;
  senderFilter: string;
  originalName: string;
  originalEmail: string;
  errorMessage: string;
  senderInFocus: Address;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => 
  ({
    ...ownProps,
    tenant: state.tenant.id,
  }),
  {
    getConfig: actions.getConfig,
    saveConfig: actions.saveConfig,
    getAssociatedNewsletters: actions.getAssociatedNewsletters
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(GlobalSettings);