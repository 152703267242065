import * as React from "react";

import { Newsletter, SaveNewsletterModelStateErrors } from "modules/newsletters/models"
import Disclaimer from "modules/common/components/disclaimer";
import Loading from "modules/common/components/loading";
import { Grid, Link, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { NewsletterTemplate, SaveNewsletterModel } from "modules/newsletters";
import InfoHover from "modules/common/components/hovers/infoHover";
import UploaderWithLoad from "modules/newsletters/common/uploader/uploaderWithLoad";
import { useTinyMceAuthoringLogic } from "modules/common/hooks/useTinyMceAuthoringLogic";
import { GlobalApplicationState } from "globalApplicationState";
import { ConnectedProps, connect } from "react-redux";
import * as galleryActions from "modules/gallery/actionCreator";
import * as adminLayoutActions from "modules/adminLayout/actionCreator";
import { AuthoringTinyEditor } from "modules/common/components/authoring/authoringTinyMce";
import IntenseEmphasis from "modules/common/components/authoring/dialogs/intenseEmphasis";

/**
 * TODO: This and headerFooter should be one component
 */
const HeaderAndFooterComponent: React.FunctionComponent<PropsWithRedux> = ({
    emailTemplate,
    isLoadingFooterImage,
    isLoadingHeaderImage,
    newsletter,
    headerImage,
    footerImage,
    defaultLcid,
    setImage,
    setHeaderStyle,
    setMainStyle,
    onError,
    onNewsletterChange,
    clearImage,
    getImage
}) => {
    const {
        editorRef: headerRef,
        intenseEmphasisDialogOpen,
        onShowIntenseEmphasisDialog,
        onApplyIntenseEmphasis,
        onHideIntenseEmphasisDialog,
    } = useTinyMceAuthoringLogic(getImage);

    const bodyRef = React.useRef<any>(null);
    const footerRef = React.useRef<any>(null);

    const onChangeNewsletter = (value: Partial<Newsletter>) => {
        onNewsletterChange(value);
    }

    const onHeaderChange = (event) => {
        onChangeNewsletter({ emailTemplateHeaderText: event });
    }

    const onBodyChange = (event) => {
        onChangeNewsletter({ bodyText: event });
    }

    const onFooterChange = (event) => {
        onChangeNewsletter({ emailTemplateFooterText: event });
    }

    const onFullscreen = (isFullscreen: boolean) => {
        if (isFullscreen) {
            // one more than side nav bar
            setMainStyle({ zIndex: 3 });
            setHeaderStyle({ display: "none" });
        } else {
            setMainStyle(undefined);
            setHeaderStyle(undefined);
        }
    }

    return (
        <>
            <div style={{ marginTop: 30, marginBottom: 30 }}>
                {!emailTemplate.isCustom &&
                    <div>
                        <div>
                            <Grid container spacing={2} style={{ padding: "6px 0px" }}>
                                <Grid item xs={3}>
                                    <Typography style={{ lineHeight: '42px' }}>Header Image</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    {isLoadingHeaderImage
                                        ? <div className="image-loading">
                                            <Loading padding={12} />
                                        </div>
                                        : <UploaderWithLoad
                                            imageSrc={headerImage}
                                            imageType="Header"
                                            setImage={setImage}
                                            imageHeight={emailTemplate.headerImageHeight}
                                            imageWidth={emailTemplate.headerImageWidth}
                                            onError={onError}
                                            isLoading={isLoadingHeaderImage ?? false}
                                        />
                                    }
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} style={{ padding: "6px 0px" }}>
                                <Grid item xs={3}>
                                    <Typography style={{ lineHeight: '42px' }}>Header Text</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <AuthoringTinyEditor
                                        initOptions={{
                                            max_height: 300,
                                            min_height: 200,
                                            menubar: false
                                        }}
                                        enableEmbeddedMedia={false}
                                        enableInlineImages={false}
                                        value={newsletter.emailTemplateHeaderText || ""}
                                        onChange={onHeaderChange}
                                        defaultLcid={defaultLcid}
                                        editorRef={headerRef}
                                        onShowIntenseEmphasisDialog={onShowIntenseEmphasisDialog}
                                        onFullscreen={onFullscreen}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                        <div>
                            <Grid container spacing={2} style={{ padding: "6px 0px" }}>
                                <Grid item xs={3}>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Typography style={{ lineHeight: '42px' }}>Body</Typography>
                                        <InfoHover>Enter any supporting messages or paragraphs.</InfoHover>
                                    </div>
                                </Grid>
                                <Grid item xs={9}>
                                    <AuthoringTinyEditor
                                        initOptions={{
                                            max_height: 300,
                                            min_height: 200,
                                            menubar: false
                                        }}
                                        enableEmbeddedMedia={false}
                                        enableInlineImages={false}
                                        value={newsletter.bodyText || ""}
                                        onChange={onBodyChange}
                                        defaultLcid={defaultLcid}
                                        editorRef={bodyRef}
                                        onShowIntenseEmphasisDialog={onShowIntenseEmphasisDialog}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                        <div style={{ marginTop: 10 }}>
                            <Grid container spacing={2} style={{ padding: "6px 0px" }}>
                                <Grid item xs={3}>
                                    <Typography style={{ lineHeight: '42px' }}>Footer Image</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    {isLoadingFooterImage
                                        ? <div className="image-loading">
                                            <Loading padding={12} />
                                        </div>
                                        : <UploaderWithLoad
                                            imageSrc={footerImage}
                                            imageType="Footer"
                                            setImage={setImage}
                                            imageHeight={emailTemplate.footerImageHeight}
                                            imageWidth={emailTemplate.footerImageWidth}
                                            onError={onError}
                                            isLoading={isLoadingFooterImage ?? false}
                                            onClearImage={clearImage}
                                        />
                                    }
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} style={{ padding: "6px 0px" }}>
                                <Grid item xs={3}>
                                    <Typography style={{ lineHeight: '42px' }}>Footer Text</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <AuthoringTinyEditor
                                        initOptions={{
                                            max_height: 300,
                                            menubar: false,
                                            min_height: 200,
                                        }}
                                        enableEmbeddedMedia={false}
                                        enableInlineImages={false}
                                        value={newsletter.emailTemplateFooterText || ""}
                                        onChange={onFooterChange}
                                        defaultLcid={defaultLcid}
                                        editorRef={footerRef}
                                        onShowIntenseEmphasisDialog={onShowIntenseEmphasisDialog}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </div>}
                {emailTemplate.isCustom &&
                  <>
                    <Disclaimer
                        icon={<InfoOutlinedIcon />}
                        text={
                            <React.Fragment>
                                <span>
                                  Header and Footer images cannot be modified through the Newsletter Editor when using custom templates.
                                  Add or modify custom templates with the help of our Support team. <Link onClick={() => window.open("https://support.sparrowconnected.com/en/how-do-i-create-a-custom-template-for-newsletters")} underline="always">Learn more</Link>.
                                </span>
                            </React.Fragment>
                        }
                    />

                    <Grid container spacing={2} style={{padding: "20px 0px"}}>
                      <Grid item xs={3}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Typography style={{lineHeight:'42px'}}>Body</Typography>
                          <InfoHover>Enter any supporting messages or paragraphs.</InfoHover>
                        </div>
                      </Grid>
                      <Grid item xs={9}>
                        <AuthoringTinyEditor
                            initOptions={{
                                max_height: 300,
                                min_height: 200,
                                menubar: false
                            }}
                            enableEmbeddedMedia={false}
                            enableInlineImages={false}
                            value={newsletter.bodyText || ""}
                            onChange={onBodyChange}
                            defaultLcid={defaultLcid}
                            editorRef={bodyRef}
                            onShowIntenseEmphasisDialog={onShowIntenseEmphasisDialog}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={2} style={{padding: "6px 0px"}}>
                      <Grid item xs={3}>
                        <Typography style={{lineHeight:'42px'}}>Footer Text</Typography>
                      </Grid>
                      <Grid item xs={9}>
                        <AuthoringTinyEditor
                          initOptions={{
                              max_height: 300,
                              menubar: false,
                              min_height: 200,
                          }}
                          enableEmbeddedMedia={false}
                          enableInlineImages={false}
                          value={newsletter.emailTemplateFooterText || ""}
                          onChange={onFooterChange}
                          defaultLcid={defaultLcid}
                          editorRef={footerRef}
                          onShowIntenseEmphasisDialog={onShowIntenseEmphasisDialog}
                        />
                      </Grid>
                    </Grid>
                  </>  
                }
            </div>
            <IntenseEmphasis show={intenseEmphasisDialogOpen} onApply={onApplyIntenseEmphasis} onClose={onHideIntenseEmphasisDialog} />
        </>
    );
}

interface ComponentProps {
    newsletter: SaveNewsletterModel;
    emailTemplate: NewsletterTemplate;
    modelErrors: SaveNewsletterModelStateErrors | null;
    footerImage?: File | null;
    headerImage?: File | null;
    isLoadingFooterImage?: boolean;
    isLoadingHeaderImage?: boolean;
    highlight: boolean;
    onNewsletterChange: (value: Partial<SaveNewsletterModel>) => void;
    setImage: (imageType: string, image?: File) => void;
    clearImage?: (imageType: string) => void;
    onError: (message: string) => any;
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        defaultLcid: state.settings.tenantSettings.defaultLCID,
    }),
    {
        getImage: galleryActions.getImage,
        setMainStyle: adminLayoutActions.setMainStyle,
        setHeaderStyle: adminLayoutActions.setHeaderStyle,
    }
);

type PropsWithRedux = ConnectedProps<typeof connector>;
export const HeaderAndFooter = connector(HeaderAndFooterComponent);
