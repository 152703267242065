import React from "react";

import "./styles/desktopPreview.sass";

interface IDesktopPreviewProps {
}

const DesktopPreview: React.FunctionComponent<IDesktopPreviewProps> = ({
    children
}) => {
    return (
        <div className="desktop-preview-container">
            <div className="desktop-preview-content">
                <div className="desktop-preview-fake-browser-toolbar">
                    <div className="circle" style={{ marginLeft: 12 }}></div>
                    <div className="circle"></div>
                    <div className="circle"></div>
                </div>
                <div className="desktop-preview-fake-browser-content">
                    <div className="desktop-preview-modal">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DesktopPreview;
