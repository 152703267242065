import { GlobalApplicationState } from "globalApplicationState";
import * as Actions from "./actions";
import API from "api";
import { audiencesApi } from "api/instances";

import { Audience, AudienceDetails, AudienceFilterValues, AudiencePage, DraftAudience } from "./models";
import { defaultPage } from "./reducer";


export const getAudiences = () => (dispatch, getState: () => GlobalApplicationState): Promise<Audience[]> => {
  return audiencesApi.getAllAudiences()
    .then(audiences => {
      dispatch(Actions.GetAudiencesComplete({ succeeded: true, audiences }));
      return audiences;
    })
    .catch(err => {
      dispatch(Actions.GetAudiencesComplete({ succeeded: false, audiences: [], err }));
      return [];
    });
}

export const getAudienceDetails = (id: string) => (dispatch, getState: () => GlobalApplicationState): Promise<AudienceDetails> => {
  dispatch(Actions.GetAudienceDetails({}));

  return dispatch(API.audiences.GetAudienceDetails(id))
    .then(response => {
      dispatch(Actions.GetAudienceDetailsComplete({ succeeded: response.status === 200 }));
      return response.json();
    })
    .catch(err => {
      dispatch(Actions.GetAudienceDetailsComplete({ succeeded: false, err }));
      return;
    });
}

export const getAllAudiences = (pageNumber: number, filters: Partial<AudienceFilterValues>, pageAmount?: number) => (dispatch, getState: () => GlobalApplicationState): Promise<AudiencePage> => {
  dispatch(Actions.GetAllAudiences({}));

  return dispatch(API.audiences.GetAllAudiences(pageNumber, pageAmount, filters))
    .then(response => response.json())
    .then(page => {
      dispatch(Actions.GetAllAudiencesComplete({ succeeded: true, page }));
      return page;
    })
    .catch(err => {
      dispatch(Actions.GetAllAudiencesComplete({ succeeded: false, page: defaultPage, err }));
      return;
    });
}

export const getDisabledAudiences = (pageNumber: number, filters: Partial<AudienceFilterValues>, pageAmount?: number) => (dispatch, getState: () => GlobalApplicationState): Promise<AudiencePage> => {
  dispatch(Actions.GetDisabledAudiences({}));

  return dispatch(API.audiences.GetDisabledAudiences(pageNumber, pageAmount, filters))
    .then(response => response.json())
    .then(page => {
      dispatch(Actions.GetDisabledAudiencesComplete({ succeeded: true, page }));
      return page;
    })
    .catch(err => {
      dispatch(Actions.GetDisabledAudiencesComplete({ succeeded: false, page: defaultPage, err }));
      return;
    });
}

export const getEnabledAudiences = (pageNumber: number, filters: Partial<AudienceFilterValues>, pageAmount?: number) => (dispatch, getState: () => GlobalApplicationState): Promise<AudiencePage> => {
  dispatch(Actions.GetEnabledAudiences({}));

  return dispatch(API.audiences.GetEnabledAudiences(pageNumber, pageAmount, filters))
    .then(response => response.json())
    .then(page => {
      dispatch(Actions.GetEnabledAudiencesComplete({ succeeded: true, page }));
      return page;
    })
    .catch(err => {
      dispatch(Actions.GetEnabledAudiencesComplete({ succeeded: false, page: defaultPage, err }));
      return;
    });
}


export const createAudience = (audience: DraftAudience) => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
  return dispatch(API.audiences.CreateAudience(audience))
    .then(response => {
      dispatch(Actions.CreateAudienceComplete({ succeeded: response.status === 200 }));
      return response.status === 200;
    })
    .catch(err => {
      dispatch(Actions.CreateAudienceComplete({ succeeded: false, err }));
      return false;
    });
}

export const deleteAudience = (id: string) => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
  dispatch(Actions.DeleteAudience({}));

  return dispatch(API.audiences.DeleteAudience(id))
    .then(response => {
      dispatch(Actions.DeleteAudienceComplete({ succeeded: response.status === 200 }));
      return response.status === 200;
    })
    .catch(err => {
      dispatch(Actions.DeleteAudienceComplete({ succeeded: false, err }));
      return false;
    });
}

export const deleteAudiences = (ids: string[]) => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
  dispatch(Actions.DeleteAudiences({}));

  return Promise.all(ids.map(id => dispatch(API.audiences.DeleteAudience(id))))
    .then(() => {
      dispatch(Actions.DeleteAudiencesComplete({ succeeded: true }));
      return true;
    })
    .catch(err => {
      dispatch(Actions.DeleteAudiencesComplete({ succeeded: false, err }));
      return false;
    });
}

export const downloadUserData = (id: string) => (dispatch, getState: () => GlobalApplicationState): Promise<any> => {
  dispatch(Actions.DownloadUserData({}));

  return audiencesApi.getAudienceUsersCsv(id)
    .then(response => {
      dispatch(Actions.DownloadUserDataComplete({ succeeded: true }));
      return response;
    })
    .catch(err => {
      dispatch(Actions.DownloadUserDataComplete({ succeeded: false, err }));
      return;
    });
}

export const disableAudience = (id: string) => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
  dispatch(Actions.DisableAudience({}));

  return dispatch(API.audiences.DisableAudience(id))
    .then(response => {
      dispatch(Actions.DisableAudienceComplete({ succeeded: response.status === 200 }));
      return response.status === 200;
    })
    .catch(err => {
      dispatch(Actions.DisableAudienceComplete({ succeeded: false, err }));
      return false;
    });
}

export const disableAudiences = (ids: string[]) => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
  dispatch(Actions.DisableAudiences({}));

  return Promise.all(ids.map(id => dispatch(API.audiences.DisableAudience(id))))
    .then(() => {
      dispatch(Actions.DisableAudiencesComplete({ succeeded: true }));
      return true;
    })
    .catch(err => {
      dispatch(Actions.DisableAudiencesComplete({ succeeded: false, err }));
      return false;
    });
}

export const enableAudience = (id: string) => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
  dispatch(Actions.EnableAudience({}));

  return dispatch(API.audiences.EnableAudience(id))
    .then(response => {
      dispatch(Actions.EnableAudienceComplete({ succeeded: response.status === 200 }));
      return response.status === 200;
    })
    .catch(err => {
      dispatch(Actions.EnableAudienceComplete({ succeeded: false, err }));
      return false;
    });
}


export const enableAudiences = (ids: string[]) => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
  dispatch(Actions.EnableAudiences({}));

  return Promise.all(ids.map(id => dispatch(API.audiences.EnableAudience(id))))
    .then(() => {
      dispatch(Actions.EnableAudiencesComplete({ succeeded: true }));
      return true;
    })
    .catch(err => {
      dispatch(Actions.EnableAudiencesComplete({ succeeded: false, err }));
      return false;
    });
}

export const saveAudience = (id: string, audience: DraftAudience) => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
  return dispatch(API.audiences.SaveAudience(id, audience))
    .then(response => {
      dispatch(Actions.SaveAudienceComplete({ succeeded: response.status === 200 }));
      return response.status === 200;
    })
    .catch(err => {
      dispatch(Actions.SaveAudienceComplete({ succeeded: false, err }));
      return false;
    });
}

//Calls API that fetches unique user count from set of audiences.
export const fetchUniqueUserCount = (ids: string[], grabAll: boolean) => (dispatch, getState: () => GlobalApplicationState): Promise<number> => {
  return dispatch(API.audiences.GetUniqueUsers(ids, grabAll))
  .then(response => {
    return response.json();
  })
  .catch(err => {
    return 0;
  })
}

export const hasChangedSinceSaved = () => (dispatch, getState: () => GlobalApplicationState): void => {
  dispatch(Actions.ChangedSinceSaved({}));
}

export const clearChangedSinceSaved = () => (dispatch, getState: () => GlobalApplicationState): void => {
  dispatch(Actions.ClearChangedSinceSaved({}));
}

export const clearErrorMessage = () => (dispatch, getState: () => GlobalApplicationState): void => {
  dispatch(Actions.ClearErrorMessage({}));
}

export const clearShouldFetch = () => (dispatch, getState: () => GlobalApplicationState): void => {
  dispatch(Actions.ClearShouldFetch({}));
}