import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import * as actions from "modules/documents/actionCreator";
import * as categoryTagsActions from "modules/categorytags/actionCreator";
import moment from "moment";

import { documentsApi } from "api/instances";

import Loading from "modules/common/components/loading";
import LoadingOverlay from "modules/common/components/loadingOverlay";

import { DocumentFeedFilters, DocumentFeedItem, DocumentsSort, DocumentsView } from "../../models";
import { defaultDocumentFeedFilters } from "../../reducer";

import { getMimeType } from "../../utilities/getMimeType";

import Card from "./components/layouts/card";
import List from "./components/layouts/list";
import RequestAccess from "./components/requestAccess";
import DocumentView from "../document-views/documentView";

import Filters from "../document-filters/documentFeedFilters";
import LayoutSelection from "./components/layoutSelection";
import SortSelection from "./components/sortSelection";
import Views from "./components/views";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";


import "../../styles/documentFeed.sass";
import "../../styles/documentViews.sass";
import { FileDownloader } from "utils/fileDownloader";

const imageTypes = ["jpeg", "jpg", "pdf", "png", "txt"]

class DocumentFeed extends React.Component<PropsWithRedux, ComponentState> {
  constructor(props: PropsWithRedux) {
    super(props);

    this.state = {
      isDownloading: false,
      isLoading: false,
      selectedDocument: undefined,
      showRequestAccess: false
    };
  }
  
  public componentWillMount() {
    if (!!this.props.categoryTags && !this.props.categoryTags.length)
      this.props.getCategoryTags();

    if (!!this.props.selectedDocumentId) {
      this.props.getDocumentDetails(this.props.selectedDocumentId).then((selectedDocument) => {
        if (!!selectedDocument)
          this.setState({ selectedDocument });
        else
          this.onShowRequestAccess();
      });
    }

    if (this.props.shouldFetch) {
      this.fetchFeed(1, this.props.filters);
    }
  }

  public componentDidMount() {
    moment.locale("en");
  }

  public componentDidUpdate(prevProps: PropsWithRedux) {
    if (this.props.shouldFetch && (this.props.shouldFetch !== prevProps.shouldFetch)) {
      this.fetchFeed(1, this.props.filters);
    }
  }

  public render() {
    const { documentFeed } = this.props;

    if (this.props.shouldFetch || !this.props.currentUser.userId)
      return <Loading />;

    return (
      <React.Fragment>
        <Filters
          filters={this.props.filters}
          onChangeFilters={this.onChangeFilters}
          onClearFilters={this.onClearFilters}
        />
        <div className="view-bar">
          <Views view={this.props.filters.view!} onChangeView={this.changeView} />
          <div className="view-options">
            <SortSelection onChangeSort={this.changeSort} />
            <LayoutSelection />
          </div>
        </div>
        {(!documentFeed.documents.length && this.props.isFetching)
          ? (
              <Loading />
            )
          : (
              <React.Fragment>
                {this.props.filtersApplied &&
                  <Typography variant="h2" className="feed-results">Results</Typography>
                }
                {documentFeed.documents.length === 0
                  ? <div className="no-feed">No documents were found</div>
                  : this.getLayout()
                }
                {this.props.canLoadMore && (
                  this.props.isFetching
                    ? <Loading />
                    : <div className="load-more">
                        <Button variant="text" color="primary" onClick={this.continueFetching}>Load more documents</Button>
                      </div>
                )}
              </React.Fragment>
            )
        }
        <DocumentView show={!!this.state.selectedDocument} view={this.state.selectedDocument} onDownloadDocument={this.downloadDocument} onViewDocument={this.viewDocument} onClose={this.unselectDocument} />
        <RequestAccess show={this.state.showRequestAccess} onClose={this.onCloseRequestAccess} />
        <LoadingOverlay absolute={true} show={this.state.isDownloading || this.state.isLoading} />
      </React.Fragment>
    );
  }


  private changeSort = (sort: DocumentsSort) => {
    if (sort !== this.props.filters.sort && !this.props.isFetching) {
      this.props.clearDocumentFeed();
      this.fetchFeed(1, { ...this.props.filters, sort });
    }
  }

  private changeView = (view: DocumentsView) => {
    if (view !== this.props.filters.view && !this.props.isFetching) {
      this.props.clearDocumentFeed();
      this.fetchFeed(1, { ...this.props.filters, view });
    }
  }


  private continueFetching = () => {
    this.fetchFeed(this.props.documentFeed.currentPage + 1, this.props.filters);
  }

  private downloadDocument = (selectedDocument: DocumentFeedItem) => {
    this.setState({ isDownloading: true });
    const file = {
      name:  `${selectedDocument.fileName}.${selectedDocument.fileType}`
    }

    documentsApi.GetDocumentFile(selectedDocument.id)
      .then((data) => new FileDownloader(file).downloadDoc(data))
      .then(_ => {
        this.props.updateDocumentFeed(this.props.documentFeed.documents.map((document) => {
          if (document.id === selectedDocument.id)
            return {
              ...document,
              isUnread: false,
              lastReadDate: new Date().toISOString()
            };
          return document;
        }));
      })
      .then(_ => this.setState({ isDownloading: false }))
      .catch(_ => this.setState({ isDownloading: false }));
  }

  private fetchFeed = (pageNumber: number, filters: Partial<DocumentFeedFilters>, hasFiltersApplied: boolean = this.props.filtersApplied) => {
    this.props.getDocumentFeed(pageNumber, filters, hasFiltersApplied);
  }


  private getLayout(): JSX.Element {
    if (this.props.layout === "card")
      return <Card onDocumentSelected={this.selectDocument} onDownloadDocument={this.downloadDocument} onViewDocument={this.viewDocument} onInspectDocument={this.inspectDocument} />;
    else
      return <List onDocumentSelected={this.selectDocument} onDownloadDocument={this.downloadDocument} onViewDocument={this.viewDocument} onInspectDocument={this.inspectDocument} />;
  }

  private selectDocument = (selectedDocument: DocumentFeedItem) => {
    if (imageTypes.includes(selectedDocument.fileType.toLowerCase())) {
      this.viewDocument(selectedDocument);
    }
    else {
      this.downloadDocument(selectedDocument);
    }
  }

  private inspectDocument = (selectedDocument: DocumentFeedItem) => {
    this.setState({selectedDocument})
  }

  private unselectDocument = () => {
    this.setState({ selectedDocument: undefined });
  }

  private viewDocument = (selectedDocument: DocumentFeedItem) => {
    this.setState({ isDownloading: true });
    documentsApi.GetDocumentFile(selectedDocument.id).then((file) => {
      var blob = new Blob([file], { type: getMimeType(selectedDocument.fileType) });
      const fileURL = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = fileURL;
      
      window.open(fileURL);

      this.props.updateDocumentFeed(this.props.documentFeed.documents.map((document) => {
        if (document.id === selectedDocument.id)
          return {
            ...document,
            isUnread: false,
            lastReadDate: new Date().toISOString()
          };
        return document;
      }))
      this.setState({ isDownloading: false });
    });
  }
  

  private onChangeFilters = (filters: Partial<DocumentFeedFilters>) => {
    this.props.clearDocumentFeed();
    this.fetchFeed(1, { ...this.props.filters, ...filters }, true);
  }

  private onClearFilters = () => {
    this.props.clearDocumentFeed();
    this.fetchFeed(1, { ...defaultDocumentFeedFilters, sort: this.props.filters.sort, view: this.props.filters.view }, false);
  }

  private onCloseRequestAccess = () => {
    this.setState({ showRequestAccess: false });
  }

  private onShowRequestAccess = () => {
    this.setState({ showRequestAccess: true });
  }
}


interface ComponentProps {
  selectedDocumentId?: string;
}

interface ComponentState {
  isDownloading: boolean;
  isLoading: boolean;
  selectedDocument: DocumentFeedItem | undefined;
  showRequestAccess: boolean;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    categoryTags: state.categoryTags.userCategoryTags,
    documentFeed: state.documents.documentFeed.documentFeed,
    canLoadMore: state.documents.documentFeed.documentFeed.currentPage < state.documents.documentFeed.documentFeed.totalPages,
    filters: state.documents.documentFeed.filters,
    filtersApplied: state.documents.documentFeed.filtersApplied,
    isFetching: state.documents.documentFeed.isFetching,
    layout: state.documents.documentFeed.layout,
    selectedView: state.documents.documentFeed.filters.view,
    shouldFetch: state.documents.documentFeed.shouldFetch,
    currentUser: state.settings.currentUser
  }),
  {
    clearDocumentFeed: actions.clearDocumentFeed,
    getCategoryTags: categoryTagsActions.getUserCategoryTags,
    getDocumentDetails: actions.getDocumentDetails,
    getDocumentFeed: actions.getDocumentFeed,
    hideDocumentFeedFilters: actions.hideDocumentFeedFilters,
    setDocumentFeedFilters: actions.setDocumentFeedFilters,
    updateDocumentFeed: actions.updateDocumentFeed
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(DocumentFeed);