import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import * as actions from "../actionCreator";
import LoadingOverlay from "modules/common/components/loadingOverlay";
import { injectIntl, IntlShape } from "react-intl";
import TextInput from "modules/common/components/forms/inputs/textInput";
import "./styles/languageSettingsForm.sass";
import Select from 'react-select';
import { settingsApi } from "api/instances";
import { ValueLabel } from "modules/common/components/selectFilter";
import { Grid } from "@mui/material";
import TranslateDialog from "./translateDialog";
import TranslateErrorMaxLengthDialog from "./translateErrorMaxLengthDialog";
import { isBannerText } from "utils/bannerText";

class LanguageSettingsForm extends React.PureComponent<PropsWithRedux, ComponentState> {
    private maxLangCount = this.props.tenantSettings.maxAllowedLanguages;

    constructor(props) {
        super(props);
        this.state = {
            availableTimeZones:[],
            loading: true,
            showTranslateDialog: false,
            showTranslationMaxLengthErrorDialog: false,
            isFetchingTranslation: false,
        };
    }

    public componentDidMount(){
        settingsApi.GetAvailableTimeZones()
        .then(resp => {
            this.setState({...this.state, availableTimeZones: resp, loading: false});
        });
    }

    public render() {
        if (!this.props.lcids) return <div></div>;
        const working = this.props.fetching || this.props.saving || this.state.loading;

        const offeredLocales = Object.keys(this.props.lcidMappings || {}).filter(
            (locale) => this.props.lcids.findIndex((cl) => cl === locale) < 0
        );

        const selectedTimeZone = this.state.availableTimeZones.filter(t=>t.value === this.props.tenantSettings.timeZone).pop();

        return (
            <div>
                <div className="form-section">
                    <h4>Time</h4>
                    <div>
                        <b>Default Time Zone</b>
                        <p>The default time zone is used as a fallback if a user's local time cannot be applied. All reports and scheduled tasks are based of a user's local time zone.</p>

                         {!working && (<Select
                            options={this.state.availableTimeZones}
                            onChange={this.handleTimeZoneChangeDropDown}
                            defaultValue={selectedTimeZone}
                            value={selectedTimeZone}
                            menuPlacement="bottom"
                            menuPortalTarget={document.body}
                            styles={{menuPortal:base => ({...base, zIndex:5})}}
                        />)}
                    </div>
                </div>
                <div className="form-section">
                    <h4>Language</h4>
                    <div style={{padding:10}}>
                        <i className="material-icons" style={{
                            verticalAlign: "middle",
                            fontSize: "15px",
                            marginRight: "5px",
                        }}>info</i>This page allows you to choose your languages and edit post and event banner text and messages per language. You can set up to {this.maxLangCount} languages for your system. These languages can also be used when publishing content. Your company's default language is set to <b>{this.languageFrom(this.props.defaultLcid)}</b>
                    </div>
                    <div className="vertical-tab-ctrl">
                        <div className="vertical-tab-btns language">
                            <div style={{ fontSize: "14px", marginTop: "15px", marginLeft: "15px", color: "#aaa" }}>
                                {this.props.intl.formatMessage(
                                    { id: "common.limitNum", defaultMessage: "Limit {num, number}" },
                                    { num: this.maxLangCount }
                                )}
                            </div>
                            <ul>
                                {this.props.lcids.map((lcid) => (
                                    <li
                                        key={lcid}
                                        unselectable={"on"}
                                        className={"vertical-tab-btn" + (this.props.activeLanguage === lcid ? " active" : "")}
                                        onDragOver={(e) => e.preventDefault()}
                                        onClick={() => {
                                            this.props.setActiveLanguage(lcid);
                                        }}
                                    >
                                        <span>
                                            {this.languageFrom(lcid)}
                                        </span>
                                    </li>
                                ))}
                            </ul>
                            <div className="lang-dropdown">
                                <button disabled={offeredLocales.length < 1 || this.props.lcids.length >= this.maxLangCount}>
                                    {`+ ${this.props.intl.formatMessage({ id: "localization.addLanguage", defaultMessage: "Add Language" })}`}
                                </button>
                                <div className="lang-dropdown-content">
                                    {this.props.lcids.length < this.maxLangCount &&
                                        offeredLocales.map((locale) => (
                                            <span onClick={(e) => this.addLanguage(e, locale)} key={`offered-${this.languageFrom(locale)}`}>
                                                {this.languageFrom(locale)}
                                            </span>
                                        ))}
                                </div>
                            </div>
                        </div>
                        <div className="vertical-tabs">
                            {this.props.lcids.map((lcid, i) => {
                                return (
                                    <div key={lcid + i} className={"vertical-tab" + (this.props.activeLanguage === lcid ? " active" : "")}>
                                        <Grid>
                                            <Grid container justifyContent="flex-end">
                                                <button
                                                    className="translate-btn"
                                                    style={{ zIndex: 2 }}
                                                    disabled={this.props.defaultLcid === this.props.activeLanguage}
                                                    onClick={() => this.onShowTranslateDialog()}
                                                >
                                                    {this.props.intl.formatMessage({
                                                        id: "localization.translateLanguage",
                                                        defaultMessage: "Translate",
                                                    })}
                                                    <i className="material-icons" style={{ verticalAlign: "top", fontSize: "16px", marginLeft: "4px" }}>
                                                        translate
                                                    </i>
                                                </button>
                                                <button
                                                    className="delete-btn"
                                                    style={{ zIndex: 2 }}
                                                    disabled={this.props.defaultLcid === this.props.activeLanguage}
                                                    onClick={() => this.removeLanguage(lcid)}
                                                >
                                                    {this.props.intl.formatMessage({
                                                        id: "localization.removeLanguage",
                                                        defaultMessage: "Remove Language",
                                                    })}
                                                    <i className="material-icons" style={{ verticalAlign: "top", fontSize: "16px", marginLeft: "4px" }}>
                                                        delete
                                                    </i>
                                                </button>
                                            </Grid>
                                        </Grid>
                                        <div className="form-group">
                                            <label style={{ paddingBottom: "25px", fontWeight: "bold" }}>{this.languageFrom(lcid)}</label>
                                            <ul className="lang-list">
                                                {Object.keys(this.props.tenantSettings.translatedContent[lcid]).map((key) => {
                                                    let labels = {
                                                        breakingPost: "Breaking News Thumbnail Banner",
                                                        breakingBannerDescription: "Breaking News Banner Description",
                                                        compliancePost: "Compliance Thumbnail Banner",
                                                        complianceBannerDescription: "Compliance Banner Description",
                                                        complianceText: "Compliance Checkbox Message",
                                                        compliedPost: "Complied Thumbnail Banner",
                                                        compliedBannerDescription: "Complied Banner Description",
                                                        featuredPost: "Featured News Thumbnail Banner",
                                                        featuredBannerDescription: "Featured News Banner Description",
                                                        mandatoryPost: "Mandatory Thumbnail Banner",
                                                        mandatoryBannerDescription: "Mandatory Banner Description",
                                                        publicPost: "Public Posts Thumbnail Banner",
                                                        publicBannerDescription: "Public Banner Description",
                                                        publicBannerDescriptionShareTitle: "Public Banner Description Share Title",
                                                        restrictedPost: "Private Posts and Events Thumbnail Banner",
                                                        restrictedBannerDescription: "Private Banner Description",
                                                    };

                                                    if(!labels[key]) return null;

                                                    return (
                                                        <li key={key} style={{ width: "1000px", marginBottom: "15px" }}>
                                                            <TextInput
                                                                multiline={false}
                                                                label={labels[key]}
                                                                disabled={working}
                                                                onChange={(value) =>  {
                                                                     this.handleTranslatedTextChange(key, value, lcid)
                                                                }}
                                                                isLanguageField={true}
                                                                checkError={this.validateTextLength}
                                                                placeholder={this.getTextPlaceholder(key)}
                                                                value={this.props.tenantSettings.translatedContent[lcid][key]}
                                                                maxLength={(isBannerText(key)) ? 100 : 50}
                                                            />

                                                                <div style={{ fontSize: "12px", marginBottom: "20px", marginTop: "-20px" }}>
                                                                    {this.getBannerTextDescription(key)}
                                                                </div>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <LoadingOverlay show={working} />
                </div>
                <TranslateDialog
                    isLoading={this.state.isFetchingTranslation}
                    show={this.state.showTranslateDialog}
                    onSendTranslateRequest={this.onSendTranslateRequest}
                    onClose={this.onHideTranslateDialog}
                />
                <TranslateErrorMaxLengthDialog 
                    show={this.state.showTranslationMaxLengthErrorDialog}
                    onClose={this.onHideTranslateMaxLengthErrorDialog}
                />
            </div>
        );
    }

    private getBannerTextDescription(key: string) {
        switch (key) {
            case "breakingPost":
                return "If a post is set as Breaking News, the thumbnail banner displays this text."
            case "breakingBannerDescription":
                return "This message supports the breaking news banner, and appears on the hero image within a post set as breaking news."
            case "complianceBannerDescription":
                return "This message supports the compliance banner text, and appears on the hero image within a compliance post."
            case "compliancePost":
                return "Compliance Posts will display this text as a banner on the post thumbnail, which informs readers that the post requires compliance."
            case "complianceText":
                return "This message is accompanied by a checkbox and appears under the body of a compliance post, which allows readers to acknowledge and/or agree to the post.";
            case "compliedPost":
                return "If a reader has already complied to the compliance post, the Complied Banner replaces the Compliance Banner."
            case "compliedBannerDescription":
                return "If a reader has already complied with the post, this message appears on the hero image within a complied post."
            case "featuredPost":
                return "If a post is set as Featured News, the thumbnail banner displays this text."
            case "featuredBannerDescription":
                return "This message supports the featured news banner, and appears on the hero image within a post set as featured news."
            case "mandatoryPost":
                return "Mandatory Posts will display this text as a banner on the post thumbnail, which informs readers that the post is a mandatory read."
            case "mandatoryBannerDescription":
                return "This message supports the mandatory banner text, and appears on the hero image within a mandatory post."
            case "restrictedPost":
                return "This text appears on a thumbnail banner if a post or event is tagged with topics that are restricted/exclusive to specific audiences."
            case "restrictedBannerDescription":
                return "This message supports private post and event banners, and appears on hero images within private posts and events."
            case "publicPost":
                return "This text appears on a thumbnail banner if a post is readable and accessible to everyone in the company."
            case "publicBannerDescription":
                return "This message supports public post banners, and appears on hero images within public posts."
            case "publicBannerDescriptionShareTitle":
                return "This will be displayed to replace the Deep Linking URL at the end of the Public Banner Description."
            default:
                return "";
        }
    }
    private getTextPlaceholder(textField: string) {
        switch (textField) {
            case "complianceText":
                return "Ex. I acknowledge that I have read and understood the post in its entirety.";
            case "restrictedText":
                return "Ex. This post is exlusive to specific audiences.";
            case "publicText":
                return "Ex. This is a public post.";
            case "publicTextShareUrl":
                return ""
            case "publicTextShareTitle":
                return "Ex. Share it through here."
            default:
                return "";
        }
    }
    private addLanguage = (e, code: string) => {
        let i = this.props.offeredLocales.findIndex((cl) => cl === code);
        if (i < 0) return;

        this.props.setLanguageSettings(this.props.lcids.concat(this.props.offeredLocales[i]));
    };

    private removeLanguage = (code: string) => {
        if (this.props.lcids.length <= 1) return;
        this.props.setLanguageSettings(this.props.lcids.filter((locale) => locale !== code));
    };

    // use this at a later time when you are allowed to change the default language
    // private setDefault = (code: string) => {
    //   this.props.setLanguageSettings(this.props.lcids, code)
    // }

    private defaultIndicator = (locale: string) => {
        return locale === this.props.defaultLcid ? (
            <span title="Default Language">
                <i className="material-icons" style={{ verticalAlign: "middle", fontSize: "22px", marginLeft: "5px" }}>
                    settings
                </i>
            </span>
        ) : null;
        // use this at a later time when you are allowed to change the default language
        // : <a onClick={() => this.setDefault(locale)}>
        //     {this.props.intl.formatMessage({id: "common.makeDefault", defaultMessage: "Make Default"})}
        //   </a>;
    };

    private handleTranslatedTextChange = (key: string, newText: string, lcid: string) => {
        this.props.setTranslatedText(key, newText, lcid);
    };

    private validateTextLength = (text: string, maxLength: number) => {
        if(text && text.length > maxLength) {
            return true; 
        } else {
            return false;
        }
    };

    private languageFrom = (lcid: string) => {
        return this.props.lcidMappings[lcid] ? this.props.lcidMappings[lcid].language : lcid;
    };

    private handleTimeZoneChangeDropDown = (o: ValueLabel) => {
        this.props.setTimeZone(o.value);
    }

    private onSendTranslateRequest = async () => {
        this.setState({ isFetchingTranslation: true });
        if(this.props.activeLanguage) {
            var translatedResponse = await this.props.getTranslatedLanguage(this.props.activeLanguage, this.props.defaultLcid);
            //Added default to be defaultLcid if no activeLanguage (but we should always have one...)
            if(translatedResponse) {
                this.checkTranslatedResponseKeys(translatedResponse);
            }  
        }
        this.setState({ showTranslateDialog: false, isFetchingTranslation: false });
    }

    private checkTranslatedResponseKeys(translatedResponse) {
        Object.keys(translatedResponse).forEach((key) => {
            let currentMaxtextLength = isBannerText(key) ? 100 : 50;
            let checkForTextLengthError = translatedResponse[key] && (translatedResponse[key].length > currentMaxtextLength && this.state.showTranslationMaxLengthErrorDialog === false);
            
            this.handleTranslatedTextChange(key, translatedResponse[key], this.props.activeLanguage ?? this.props.defaultLcid)

            if(checkForTextLengthError) {
                this.setState({ showTranslationMaxLengthErrorDialog: true })
            }
        })
    }

    private onHideTranslateDialog = () => {
        this.setState({ showTranslateDialog: false });
    }

    private onShowTranslateDialog = () => {
        this.setState({ showTranslateDialog: true });
    }

    private onHideTranslateMaxLengthErrorDialog = () => {
        this.setState({ showTranslationMaxLengthErrorDialog: false });
    }
}

interface ComponentState {
    availableTimeZones: {value:string, label:string}[];
    loading: boolean;
    showTranslateDialog: boolean;
    showTranslationMaxLengthErrorDialog: boolean;
    isFetchingTranslation: boolean;
}

interface ComponentProps {
    intl: IntlShape;
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        fetching: state.settings.fetching,
        saving: state.settings.saving,
        lcids: Object.keys(state.settings.tenantSettings.translatedContent),
        lcidMappings: state.resources.lcidMappings,
        defaultLcid: state.settings.tenantSettings.defaultLCID,
        offeredLocales: Object.keys(state.resources.lcidMappings),
        tenantSettings: state.settings.tenantSettings,
        activeLanguage: state.settings.activeLanguageLCID,
    }),
    {
        getTranslatedLanguage: actions.GetLanguageTranslation,
        setLanguageSettings: actions.setLanguageSettings,
        setTranslatedText: actions.setTranslatedText,
        setActiveLanguage: actions.setActiveLanguage,
        setTimeZone: actions.setTimeZone
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default injectIntl(connector(LanguageSettingsForm));
