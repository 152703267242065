import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";

import AuthoringSearch from "modules/common/components/filters/authoringSearch";
import FilterContainer from "modules/common/components/filters/filterContainer";
import FilterDropdown from "modules/common/components/filters/filterDropdown";

import { Audience } from "modules/audiences/models";
import { PortalPagesFilterValues } from "../../models";

import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { PickerLocalization } from "modules/common/components/pickerLocalization";

import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";


import moment from "moment";


const States = [
  { id: "Enabled", text: "Enabled" },
  { id: "Draft", text: "Draft" },
  { id: "Disabled", text: "Disabled" }
];

class PortalPagesFilters extends React.Component<PropsWithRedux, ComponentState> {
  constructor(props: PropsWithRedux) {
    super(props);

    this.state = {
      canSearch: !!props.filters.audiences!.length || !!props.filters.currentFilter!.length || !!props.filters.publishedFromDate || !!props.filters.publishedToDate || !!props.filters.searchText,
      searchText: props.filters.searchText || ""
    };
  }

  public componentDidMount() {
    moment.locale("en");
  }

  public componentDidUpdate(prevProps: PropsWithRedux) {
    if (this.props.filters.searchText !== prevProps.filters.searchText)
      this.setState({ searchText: this.props.filters.searchText || "" });
  }

  public render() {
    const { filters } = this.props;

    return (
      <FilterContainer
        filters={
          <React.Fragment>
            <TextField
              variant="outlined"
              size="small"
              value={this.state.searchText}
              placeholder="Search title or administrator"
              InputProps={{
                startAdornment: <SearchIcon className="search-icon" />
              }}
              onChange={this.onUpdateSearchText}
              onKeyUp={this.onKeyUp}
              className="text-to-search"
            />
            {this.props.id === "all" &&
              <FilterDropdown text="State">
                <List disablePadding>
                  {States.map((state) =>
                    <ListItem key={state.id} dense button onClick={() => this.onChangeCurrentFilter(state.id)}>
                      <ListItemIcon className="callout-checkbox">
                        <Checkbox
                          edge="start"
                          tabIndex={-1}
                          disableRipple
                          size="small"
                          color="primary"
                          checked={!!filters.currentFilter!.find((selectedFilter) => selectedFilter === state.id)}
                        />
                      </ListItemIcon>
                      <ListItemText primary={state.text} />
                    </ListItem>
                  )}
                </List>
              </FilterDropdown>
            }
            <FilterDropdown text="Published between">
              <PickerLocalization>
                <div className="published-time">
                  <div>
                    <div>From</div>
                    <DatePicker
                        format="MMM dd yyyy"
                        value={filters.publishedFromDate ? Date.parse(filters.publishedFromDate) : null}
                        slotProps={{ 
                          textField: { size: "small", placeholder: "", inputProps: { readOnly: true }},
                          openPickerIcon: { color: "primary" }
                        }}
                        onChange={this.onChangePublishedFromDate}
                        className="date-picker"
                      />
                  </div>
                  <div>
                    <div>To</div>
                    <DatePicker
                        format="MMM dd yyyy"
                        value={filters.publishedToDate ? Date.parse(filters.publishedToDate) : null}
                        slotProps={{ 
                          textField: { size: "small", placeholder: "", inputProps: { readOnly: true }},
                          openPickerIcon: { color: "primary" }
                        }}
                        onChange={this.onChangePublishedToDate}
                        className="date-picker"
                      />
                  </div>
                </div>
              </PickerLocalization>
            </FilterDropdown>
            <FilterDropdown
              text="Accessible to"
              footer={
                <div>
                  <Button variant="text" color="primary" onClick={this.onClearAudiences}>Clear all</Button>
                </div>
              }
            >
              <List disablePadding className="audiences">
                {this.props.audiences.map((audience) =>
                  <ListItem key={audience.id} dense button onClick={() => this.onChangeAudiences(audience)}>
                    <ListItemIcon className="callout-checkbox">
                      <Checkbox
                        edge="start"
                        tabIndex={-1}
                        disableRipple
                        size="small"
                        color="primary"
                        checked={!!filters.audiences!.find((selectedAudience) => selectedAudience.id === audience.id)}
                      />
                    </ListItemIcon>
                    <ListItemText primary={audience.displayName} />
                  </ListItem>
                )}
              </List>
            </FilterDropdown>
          </React.Fragment>
        }
        filterCommands={
          <AuthoringSearch
            canSearch={this.state.canSearch}
            onApplyFilters={this.onChangeSearchText}
            onClearFilters={this.onClearFilters}
          />
        }
        filterSelection={this.getFilterSelection()}
      />
    );
  }


  private getFilterSelection = (): JSX.Element | undefined => {
    const { filters } = this.props;

    if (!filters.searchText && !filters.currentFilter!.length && !filters.publishedFromDate && !filters.publishedToDate && !filters.audiences!.length)
      return undefined;

    return (
      <React.Fragment>
        {!!filters.searchText &&
          <Chip
            key="search-text"
            label={`"${filters.searchText}"`}
            onDelete={this.onClearSearchText}
            deleteIcon={<CloseIcon />}
          />
        }
        {filters.currentFilter!.map((selectedFilter) =>
          <Chip
            key={selectedFilter}
            label={selectedFilter}
            onDelete={() => this.onClearCurrentFilter(selectedFilter)}
            deleteIcon={<CloseIcon />}
          />
        )}
        {(!!filters.publishedFromDate || !!filters.publishedToDate) &&
          <Chip
            key="published-time"
            label={`Released${!!filters.publishedFromDate ? ` from ${moment(filters.publishedFromDate).format("MMM D, YYYY")}` : ""}${!!filters.publishedToDate ? ` up to ${moment(filters.publishedToDate).format("MMM D, YYYY")}` : ""}`}
            onDelete={this.onClearPublishedTime}
            deleteIcon={<CloseIcon />}
          />
        }
        {filters.audiences!.map((selectedAudience) =>
          <Chip
            key={selectedAudience.id}
            label={selectedAudience.displayName}
            onDelete={() => this.onClearAudience(selectedAudience)}
            deleteIcon={<CloseIcon />}
          />
        )}
      </React.Fragment>
    );
  }


  private onChangeAudiences = (audience: Audience) => {
    const hasSelectedAudience: boolean = !!this.props.filters.audiences!.find((selectedAudience) => selectedAudience.id === audience.id);
    if (hasSelectedAudience)
      this.onChangeFilters({ audiences: this.props.filters.audiences!.filter((selectedAudience) => selectedAudience.id !== audience.id) });
    else
      this.onChangeFilters({ audiences: this.props.filters.audiences!.concat([audience]) });
  }

  private onChangeCurrentFilter = (currentFilter: string) => {
    const hasSelectedFilter: boolean = !!this.props.filters.currentFilter!.find((selectedFilter) => selectedFilter === currentFilter);
    if (hasSelectedFilter)
      this.onChangeFilters({ currentFilter: this.props.filters.currentFilter!.filter((selectedFilter) => selectedFilter !== currentFilter) });
    else
      this.onChangeFilters({ currentFilter: this.props.filters.currentFilter!.concat([currentFilter]) });
  }

  private onChangeFilters = (value: Partial<PortalPagesFilterValues>) => {
    this.props.onChangeFilters({ ...this.props.filters, searchText: this.state.searchText, ...value });
    this.setCanSearch();
  }

  private onChangePublishedFromDate = (date) => {
    this.onChangeFilters({ publishedFromDate: moment(date).toISOString() });
  }

  private onChangePublishedToDate = (date) => {
    this.onChangeFilters({ publishedToDate: moment(date).toISOString() });
  }
  
  private onChangeSearchText = () => {
    this.onChangeFilters({ searchText: this.state.searchText });
  }

  private onClearAudience = (audience: Audience) => {
    this.onChangeFilters({ audiences: this.props.filters.audiences!.filter((selectedAudience) => selectedAudience.id !== audience.id) });
  }

  private onClearAudiences = () => {
    this.onChangeFilters({ audiences: [] });
  }

  private onClearCurrentFilter = (currentFilter: string) => {
    this.onChangeFilters({ currentFilter: this.props.filters.currentFilter!.filter((selectedFilter) => selectedFilter !== currentFilter) });
  }

  private onClearFilters = () => {
    this.clearCanSearch();
    this.props.onClearFilters();
  }

  private onClearPublishedTime = () => {
    this.onChangeFilters({ publishedFromDate: "", publishedToDate: "" });
  }

  private onClearSearchText = () => {
    this.onChangeFilters({ searchText: "" });
  }
  
  private onKeyUp = (key) => {
    if (key.keyCode === 13)
      this.onChangeSearchText();
  }

  private onUpdateSearchText = (event) => {
    this.setState({ searchText: event.target.value });
    if(event.target.value && event.target.value !== "") {
      this.setCanSearch();
    }
    else if(this.hasNoCurrentFilters()){
      this.clearCanSearch();
    }
  }

  private clearCanSearch = () => {
    this.setState({ canSearch: false });
  }

  private setCanSearch = () => {
    this.setState({ canSearch: true });
  }

  private hasNoCurrentFilters = () => {
    let filters = this.props.filters;

    return (!filters.audiences || filters.audiences.length === 0) 
            && filters.searchText === "" 
            && (!filters.currentFilter || filters.currentFilter.length === 0)
            && (!filters.publishedFromDate && !filters.publishedToDate);
  }
}
  

interface ComponentProps {
  id: "" | "all" | "enabled" | "drafts" | "disabled";
  filters: Partial<PortalPagesFilterValues>;
  onChangeFilters: (filters: Partial<PortalPagesFilterValues>) => void;
  onClearFilters: () => void;
}

interface ComponentState {
  canSearch: boolean;
  searchText: string;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    audiences: state.audiences.audiences
  })
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(PortalPagesFilters);