import React, { useCallback, useEffect, useState } from 'react';
import { CreateNewFolderDialog } from './createNewFolderDialog';
import { useNewFolderDebounceValue, useRenameDebounceValue } from '../galleryVfsHooks';
import { ConnectedProps, connect } from 'react-redux';
import { GlobalApplicationState } from 'globalApplicationState';
import * as PostActions from 'modules/posts/actionCreator';
import { RenameMediaDialog } from './renameMediaDialog';
import { CustomFileData } from '../models';
import { ROOT_FOLDER_ID, imageExtFromMimeType } from '../chonkyFileAdapter';
import { DeleteMediaDialog } from './deleteMediaDialog';

const MAX_FILE_LEN = 250;

interface IGalleryVFSFileActionDialogsProps {
    openCreateFolderDialog: boolean;
    onCreateFolderDialogClose: () => void;
    fetchImages: () => void;
    clearExternalFileToRename?: () => void;
    openRenameDialog: boolean;
    fileToRename: CustomFileData | null;
    onRenameDialogClose: () => void;
    filesToDelete: CustomFileData[];
    setSuccessMessage: (message: string) => void;
    onCurrentFolderChange: (parentId: string) => void;
    onDeleteDialogClose: () => void;
    onDeleteStarted: () => void;
    onDeleteFinished: () => void;
};

const GalleryVFSFileActionDialogs: React.FC<PropsWithRedux> = props => {
    const [showFolderError, setFolderError] = React.useState("");
    const [folderNameChecked, setFolderNameChecked] = useState(false);
    const newFolderValueDebounce = useNewFolderDebounceValue(setFolderNameChecked, setFolderError, props.fetchFolderByName, props.imageLibrary.currDir?.id);
    const disableFolderCreation = !folderNameChecked || showFolderError !== "";

    const [showRenameError, setRenameError] = useState(false);
    const [renameChecked, setRenameChecked] = useState(false);
    const renameValueDebounce = useRenameDebounceValue(props.fileToRename, props.fetchFolderByName, props.fetchImageByName, setRenameError, setRenameChecked, props.imageLibrary.currDir?.id);
    const disableRename = !renameChecked || showRenameError;

    const [showDeleteFilesDialog, setShowDeleteFilesDialog] = useState(false);
    const [numImagesToDelete, setNumImagesToDelete] = useState(0);
    const [numFoldersToDelete, setNumFoldersToDelete] = useState(0);

    const resetFolderCreationStates = () => {
        newFolderValueDebounce.setDebVal("");
        setFolderError("");
        setFolderNameChecked(false);
        props.onCreateFolderDialogClose();
    }

    const createFolder = async () => {
        if (!disableFolderCreation) {
            await props.uploadFolder(newFolderValueDebounce.debVal, props.imageLibrary.currDir?.id);
            resetFolderCreationStates();
            props.fetchImages();
        }
    }
    
    const limitFileNameLen = (originalFileName: string, newFileName: string): string => {
        return newFileName.length > MAX_FILE_LEN 
            ? originalFileName
            : newFileName;
    }

    const onFolderNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = limitFileNameLen(newFolderValueDebounce.debVal, e.target.value);
        newFolderValueDebounce.setDebVal(newValue);
    }

    const onRenameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = limitFileNameLen(renameValueDebounce.debVal, e.target.value);
        renameValueDebounce.setDebVal(newValue);
    }

    const resetRenameStates = () => {
        renameValueDebounce.setDebVal("");
        setRenameError(false);
        setRenameChecked(false);
        if (props.clearExternalFileToRename) props.clearExternalFileToRename();
        props.onRenameDialogClose();
    }

    const renameFile = async () => {
        if (!disableRename && props.fileToRename) {
            props.fileToRename?.isDir 
                ? await props.renameFolder(props.fileToRename.id, renameValueDebounce.debVal)
                : await props.renameImage(props.fileToRename.id, `${renameValueDebounce.debVal}${imageExtFromMimeType(props.fileToRename.mimeType)}`);

            resetRenameStates();
            props.fetchImages();
        }
    }

    const resetDeleteFilesStates = () => {
        setShowDeleteFilesDialog(false);
        setNumImagesToDelete(0);
        setNumFoldersToDelete(0);
        props.onDeleteFinished();
        props.onDeleteDialogClose();
    }

    const deleteSelection = async () => {
        if (props.filesToDelete) {
            setShowDeleteFilesDialog(false);
            let deleteFolders = props.deleteBulkFolders(props.filesToDelete.filter(x => x.isDir).map(x => x.id));
            let deleteImages = props.deleteImages(props.filesToDelete.filter(x => !x.isDir).map(x => x.id));
            await Promise.allSettled([deleteFolders, deleteImages]);
        }

        resetDeleteFilesStates();
        props.onDeleteFinished();
        props.setSuccessMessage("Successfully deleted.");

        props.onCurrentFolderChange(props.imageLibrary.currDir?.id ?? ROOT_FOLDER_ID);
    }

    useEffect(() => {
        const getAndSetFolderAndImageCount = async () => {
            const files = props.filesToDelete;
            if (files.length === 0) return;

            props.onDeleteStarted();

            let folders = files.filter(x => x.isDir);
            let images = files.filter(x => !x.isDir);

            setNumImagesToDelete(images.length);
            setNumFoldersToDelete(folders.length);

            if (folders.length > 0) {
                let folderAndImageCounts = await props.getBulkFolderAndImageCount(folders.map(x => x.id));

                setNumImagesToDelete(images.length + folderAndImageCounts.imageCount);
            }

            setShowDeleteFilesDialog(true);
        };

        getAndSetFolderAndImageCount();
    }, [props.filesToDelete]);

    return <>
        <CreateNewFolderDialog
            disableFolderCreation={disableFolderCreation}
            onClose={() => resetFolderCreationStates()}
            onConfirm={() => createFolder()}
            onDeny={() => resetFolderCreationStates()}
            open={props.openCreateFolderDialog}
            value={newFolderValueDebounce.debVal}
            onFolderNameChange={onFolderNameChange}
            errorMsg={showFolderError}
            maxInputCount={MAX_FILE_LEN}
        />
        <RenameMediaDialog
            disableRename={disableRename}
            onClose={() => resetRenameStates()}
            onConfirm={() => renameFile()}
            onDeny={() => resetRenameStates()}
            openRenameDialog={props.openRenameDialog}
            fileToRename={props.fileToRename}
            renameValue={renameValueDebounce.debVal}
            onRenameChange={(e) => onRenameChange(e)}
            showRenameError={showRenameError}
            maxInputCount={MAX_FILE_LEN}
        />
        <DeleteMediaDialog
            onClose={() => resetDeleteFilesStates()}
            onConfirm={() => deleteSelection()}
            onDeny={() => resetDeleteFilesStates()}
            showDeleteFilesDialog={showDeleteFilesDialog}
            filesToDelete={props.filesToDelete}
            numImagesToDelete={numImagesToDelete}
            numFoldersToDelete={numFoldersToDelete}
        />
    </>
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: IGalleryVFSFileActionDialogsProps)=>({
        ...ownProps,
        imageLibrary: state.posts.imageLibrary,
    }), 
    {
        fetchFolderByName: PostActions.fetchFolderByName,
        uploadFolder: PostActions.uploadFolder,
        fetchImageByName: PostActions.fetchImageByName,
        renameFolder: PostActions.renameFolder,
        renameImage: PostActions.renameImage,
        deleteImages: PostActions.deleteImagesFromLibrary,
        deleteBulkFolders: PostActions.deleteBulkFolders,
        getBulkFolderAndImageCount: PostActions.getBulkFolderAndImageCount,
    }
)
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(GalleryVFSFileActionDialogs);