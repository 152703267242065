import React from "react"

interface ComponentProps {
    size: string,
    color?: string,
    style?: React.CSSProperties
}
export default class LoadingSpinner extends React.Component<ComponentProps, {}> {

    public render() {
        return (
            <svg className="loading-spinner-basic"
                 version="1.1"
                 id="basic-loading-spinner"
                 x="0px"
                 y="0px"
                 width={this.props.size} 
                 height={this.props.size}
                 style={{
                    ...this.props.style,
                }}
                 viewBox="0 0 50 50">
                <path
                    id="spinner-path"
                    fill={this.props.color || "#bbb"}
                    d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z">
                </path>
            </svg>
        )
    }
}