import * as React from "react";
import Divider from "@mui/material/Divider";
import { Button } from "@mui/material";

const OneColumnNewsletterPreview: React.FunctionComponent<ComponentProps> = props => {
    return (
        <div style={{height: "900px", backgroundColor: props.primaryBackground, display: "flex", flexDirection: "row", width: "inherit"}}>
            <svg height="900" width="10%">
                <path fill={props.emailBackground} d="M0 0 L0 900 L38 900 L38 0 Z" />
            </svg>
            <div style={{width: "80%"}}>
                <img style={{width: "100%"}} src={"/images/newsletterPreviews/secondHeader.png"} alt="header"></img>

                <div style={{textAlign: "center", marginTop: "4px"}}>
                    <span style={{fontSize: "16px", color: props.primaryText}}>Have you heard?</span>
                </div>

                <div style={{padding: "4px", lineHeight: "0.6"}}>
                    <span style={{fontSize: "9px", color: props.primaryText}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span>
                </div>

                <Divider style={{color: props.secondaryBackground, width: "90%", position: "relative", left: "5%", height: "2px", marginTop: "5px", marginBottom: "5px"}}/>

                <div style={{textAlign: "center"}}>
                    <span style={{fontSize: "12px", color: props.primaryText}}>News &amp; Announcements</span>
                </div>

                <div style={{display: "flex", flexDirection: "row", padding: "10px", paddingTop: "15px"}}>
                    <img style={{width: "50%", height: "60%"}} src={"/images/newsletterPreviews/sampleImageOne.png"} alt="header"></img>
                    <div style={{lineHeight: "0.6", width: "45%", marginLeft: "6px"}}>
                        <span style={{fontSize: "11px", color: props.linkTexts}}>Example of a title</span>
                        <br/><br/>
                        <span style={{fontSize: "9px", paddingTop: "4px", color: props.primaryText}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec aliquet eleifend mauris, et venenatis risus laoreet ultrices. Phasellus dapibus lectus quam, pharetra cursus dolor volutpat hendrerit. Integer rutrum mollis nulla nec tempus.</span>
                    </div>
                </div>

                <div style={{display: "flex", flexDirection: "row", padding: "10px", paddingTop: "15px"}}>
                    <img style={{width: "50%", height: "60%"}} src={"/images/newsletterPreviews/sampleImageTwo.png"} alt="header"></img>
                    <div style={{lineHeight: "0.6", width: "45%", marginLeft: "6px"}}>
                        <span style={{fontSize: "11px", color: props.linkTexts}}>Example of a title</span>
                        <br/><br/>
                        <span style={{fontSize: "9px", paddingTop: "4px", color: props.primaryText}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec aliquet eleifend mauris, et venenatis risus laoreet ultrices. Phasellus dapibus lectus quam, pharetra cursus dolor volutpat hendrerit. Integer rutrum mollis nulla nec tempus.</span>
                    </div>
                </div>


                <div style={{display: "flex", flexDirection: "row", padding: "10px", paddingTop: "15px"}}>
                    <img style={{width: "50%", height: "60%"}} src={"/images/newsletterPreviews/sampleImageThree.png"} alt="header"></img>
                    <div style={{lineHeight: "0.6", width: "45%", marginLeft: "6px"}}>
                        <span style={{fontSize: "11px", color: props.linkTexts}}>Example of a title</span>
                        <br/><br/>
                        <span style={{fontSize: "9px", paddingTop: "4px", color: props.primaryText}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec aliquet eleifend mauris, et venenatis risus laoreet ultrices. Phasellus dapibus lectus quam, pharetra cursus dolor volutpat hendrerit. Integer rutrum mollis nulla nec tempus.</span>
                    </div>
                </div>

                <div style={{textAlign: "center", paddingTop: "25px", paddingBottom: "35px"}}>
                    <Button style={{backgroundColor: props.secondaryBackground, color: props.secondaryText, borderRadius: "24px", fontSize: "9px"}}>See all news</Button>
                </div>

                <img style={{width: "100%"}} src={"/images/newsletterPreviews/secondFooter.png"} alt="header"></img>

                <div style={{width: "100%", height: "53px", backgroundColor: props.secondaryBackground, textAlign: "center"}}>
                    <span style={{fontSize: "9px", color: props.secondaryText}}>This is the footer. Copyright etc.</span>
                    <br/>
                    <span style={{fontSize: "9px", color: props.secondaryText}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</span>
                </div>

                <div style={{padding: "4px", paddingTop: "10px", lineHeight: "0.6", textAlign: "center"}}>
                    <span style={{fontSize: "8px", color: props.primaryText}}>You are receiving this email because you are registered as an employee in &#123;&#123;TenantName&#125;&#125;. Contact your organization's administrators or internal communications team to learn more.</span> 
                    <span style={{fontSize: "8px", color: props.linkTexts}}>Click here to unsubscribe.</span>
                </div>

                <div style={{paddingTop: "20px", textAlign: "center"}}>
                    <Button style={{backgroundColor: props.secondaryBackground, color: props.secondaryText, borderRadius: "24px", fontSize: "9px"}}>Powered by Sparrow Connected</Button>
                </div>
            </div>
            <svg height="900" width="10%">
                <path fill={props.emailBackground} d="M0 0 L0 900 L38 900 L38 0 Z" />
            </svg>
        </div>
    );
}

interface ComponentProps {
    emailBackground: string;
    primaryBackground: string;
    primaryText: string;
    secondaryBackground: string;
    secondaryText: string;
    linkTexts: string;
}

export default OneColumnNewsletterPreview;