import React from "react";

import PluginWrapper from "../plugins/common/pluginWrapper";

import PostFeed from "modules/posts/components/post-feed/postFeed";


const NewsFeed: React.FunctionComponent<{ data: any, columnType: number }> = props => {
  let cardCount: number = 0;

  if (props.data.layout === "window") {
    if (props.columnType === 1)
      cardCount = 3;
    else if (props.columnType === 2)
      cardCount = 2;
    else
      cardCount = 1;
  }

  return (
    <PostFeed
      cardCount={cardCount}
      hidePublishTime={props.data.hide_publish_time}
      hideStats={props.data.hide_stats}
      hideSummary={props.data.hide_summary}
      hideTopics={props.data.hide_topics}
      ignoreSubscriptions={props.data.ignore_subscriptions}
      lockSubscriptions={props.data.lockSubscriptions}
      layout={props.data.layout}
      lockedToTopics={props.data.locked_to_topics}
      maxResults={props.data.max_results}
      postTypes={props.data.post_types}
    />
  );
};

export const NewsFeedBlock: React.FunctionComponent<{ data: any, columnType: number }> = props => {
  return (
    <NewsFeed {...props} />
  );
};

export const news_feed_template = {
  key: "news-feed-block",
  label: "News Feed",
  defaultItem: {
    hide_topics: true,
    layout: "card",
    locked_to_topics: [],
    max_results: 6,
    post_types: []
  },
  fields: [
    {
      name: "content_label",
      label: "Content",
      component: "section-label"
    },
    {
      name: "ignore_subscriptions",
      label: "Ignore employee's topic subscriptions",
      component: "checkbox"
    },
    {
      name: "ignore_subscriptions_spacing",
      component: () => <PluginWrapper />
    },
    {
      name: "locked_to_topics",
      label: "Lock to specific topics",
      description: "The component will only load posts tagged with topics selected. If none are selected, all topics are loaded.",
      component: "select-topics"
    },
    {
      name: "post_types",
      label: "Lock to specific news types",
      description: "The component will only load posts with news types selected. If none are selected, all types are loaded.",
      buttonLabel: "Add news types",
      component: "select-items",
      options: [
        { value: "standard", label: "Standard" },
        { value: "mandatory", label: "Mandatory" },
        { value: "compliance", label: "Compliance" },
        { value: "public", label: "Public" }
      ]
    },
    {
      name: "optional_data_label",
      label: "Optional data",
      description: "Select data you want to hide from each news tile",
      component: "label"
    },
    {
      name: "hide_publish_time",
      label: "Hide publish date",
      component: "checkbox"
    },
    {
      name: "hide_stats",
      label: "Hide activity stats",
      component: "checkbox"
    },
    {
      name: "hide_summary",
      label: "Hide summary",
      component: "checkbox"
    },
    {
      name: "hide_topics",
      label: "Hide topics",
      component: "checkbox"
    },
    {
      name: "optional_data_spacing",
      component: () => <PluginWrapper />
    },
    {
      name: "content_hr",
      component: "hr"
    },
    {
      name: "styling_label",
      label: "Styling",
      component: "section-label"
    },
    {
      name: "layout",
      label: "Layout",
      description: "Select the layout of each news tile",
      component: "select",
      options: [
        { value: "card", label: "Card" },
        { value: "column", label: "Column" },
        { value: "window", label: "Window" },
        { value: "list", label: "List"}
      ]
    },
    {
      name: "max_results",
      label: "Number of tiles",
      description: "Select the maximum number of news tiles to display",
      component: "select",
      options: [
        { value: 1, label: "1" },
        { value: 2, label: "2" },
        { value: 3, label: "3" },
        { value: 4, label: "4" },
        { value: 5, label: "5" },
        { value: 6, label: "6" },
        { value: 7, label: "7" },
        { value: 8, label: "8" },
        { value: 9, label: "9" },
        { value: 10, label: "10" },
        { value: 11, label: "11" },
        { value: 12, label: "12" }
      ]
    }
  ]
};