import React from "react"

import Paper from "@mui/material/Paper";

import { Theme } from "@mui/material/styles";


import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';


const useStyles = (footerHeight: number) => makeStyles((theme: Theme) =>
  createStyles({
    mainContent: {
      boxSizing: "border-box",
      flexShrink: 1,
      flexGrow: 0,
      maxHeight: `calc(100% - ${footerHeight*2}px)`,
      overflowY: "scroll",
      boxShadow:'0px 2px 1px -1px rgb(0 0 0 / 6%), 0px 1px 1px 0px rgb(0 0 0 / 4%), 0px 1px 3px 0px rgb(0 0 0 / 2%)',
      "&.add-content-padding": {
        padding: 20
      },
      "&.add-content-padding-10": {
        padding: 10
      },
      "&.add-margin-top": {
        marginTop: 15
      }
    }
  })
);


const MainContentWithFooter: React.FunctionComponent<ComponentProps> = props => {
  const classes = useStyles(props.footerHeight)();

  return (
    <div>
      {!!props.headerContent &&
        <div style={{paddingBottom: "12px"}}>
          {props.headerContent}
        </div>
      }
      <Paper id={props.id} square={true} elevation={0} className={`${classes.mainContent} ${props.addPadding ? "add-content-padding" : ""} ${props.addPadding10 ? "add-content-padding-10" : ""} ${props.addMarginTop ? "add-margin-top": ""}`}>
        {props.children}
      </Paper>
      <Paper id={props.id ? `${props.id}-footer` : ''} square={true} elevation={0} style={{height:`${props.footerHeight}px`, margin:'1px 0 0 0', padding:'0'}}>
        {props.footerContent}
      </Paper>
    </div>
  );
}

interface ComponentProps {
  id?: string;
  addPadding?: boolean; 
  addPadding10?: boolean; 
  addMarginTop?: boolean;
  footerHeight: number;
  footerContent: React.ReactNode;
  headerContent?: React.ReactNode;
}

export default MainContentWithFooter;