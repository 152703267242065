import { GlobalApplicationState } from 'globalApplicationState';
import * as Actions from './actions';
import { Survey } from './models';
import API from 'api';


export const getSurveys = () => (dispatch, getState: () => GlobalApplicationState): Promise<any> => {
  dispatch(Actions.GetSurveys({}));
  return dispatch(API.surveys.GetSurveys())
    .then(response => response.json())
    .then(surveys => dispatch(Actions.GetSurveysComplete({surveys, succeeded: true})))
    .catch(err => dispatch(Actions.GetSurveysComplete({err, succeeded: false})));
}

export const deleteSurveys = (ids: string[]) => (dispatch, getState: () => GlobalApplicationState): Promise<any> => {
  dispatch(Actions.DeleteSurveys({}));
  return Promise.all(ids.map(id => dispatch(API.surveys.DeleteSurvey(id))))
    .then(results => dispatch(Actions.DeleteSurveysComplete({succeeded: true, deletedSurveys: ids})))
    .catch(err => dispatch(Actions.DeleteSurveysComplete({succeeded: false, err})));
}


export const saveSurvey = (survey: Survey) => (dispatch, getState: () => GlobalApplicationState): Promise<any> => {
  dispatch(Actions.SaveSurvey({}));
  return dispatch(API.surveys.SaveSurvey(survey))
    .then(response => { 
      dispatch(Actions.SaveSurveyComplete({ succeeded: true })); 
      return response.json(); 
    })
    .catch(err => {
      dispatch(Actions.SaveSurveyComplete({succeeded: false, err}));
      return survey;
    });
}

export const updateSurveyExpiry = (survey) => (dispatch, getState: () => GlobalApplicationState) => {
  dispatch(Actions.SaveSurvey({}));
  return dispatch(API.surveys.UpdateSurveyExpiryTime(survey))
    .then(response => {
      dispatch(Actions.SaveSurveyComplete({ succeeded: true })); 
      return response.json();
    })
    .catch(err => {
      dispatch(Actions.SaveSurveyComplete({succeeded: false, err}));
      return survey;
    });
}


export const getSurvey = (id: string) => (dispatch, getState: () => GlobalApplicationState): Promise<Survey> => {
  dispatch(Actions.GetSurvey({}));
  return dispatch(API.surveys.GetSurvey(id))
    .then(response => { dispatch(Actions.GetSurveyComplete({succeeded: true})); return response.json();});
}


export const clearSurveyList = () => (dispatch, getState: () => GlobalApplicationState): void => {
  dispatch(Actions.ClearSurveyList({}));
}