import { useEffect, useState } from "react";
import { useHistory } from "react-router";

import useIsMounted from "../useIsMounted";
import { ICustomCssModelv1 } from "api/files";
import { filesApi, adminFilesApi } from "api/instances";

type UseCustomTinyMceCssContentReturnType = {
    customCss?: ICustomCssModelv1;
    fetching: boolean;
}

/**
 * Fetch custom css file from blob storage
 * @param enabled - tenant setting is enabled for this feature - tinyMceCustomCssEnabled
 * @returns UseCustomTinyMceCssContentReturnType
 */
export const useCustomTinyMceCssContent = (enabled: boolean): UseCustomTinyMceCssContentReturnType => {
    const isMounted = useIsMounted();
    const [customCss, setCustomCss] = useState<ICustomCssModelv1>();
    const [fetching, setFetching] = useState<boolean>(false);
    const { location: { pathname } } = useHistory();

    useEffect(() => {
        const fetch = async () => {
            try {
                setFetching(true);
                const res =  pathname.includes("/admin/")
                    ? await adminFilesApi.getCustomTinyMceCss()
                    : await filesApi.getCustomTinyMceCss();

                if (isMounted())
                    setCustomCss(res);
            } catch {}
            finally {
                if (isMounted())
                setFetching(false);
            }
        }

        if (!fetching && !customCss && enabled)
            fetch();
    }, [customCss, fetching, pathname, isMounted]);

    return {
        customCss,
        fetching
    };
}
