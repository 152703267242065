
import { Grid, TextField, Typography, styled } from '@mui/material';
import React, { useState } from 'react';

interface ComponentProps {
    isDisabled?: boolean;
    placeholder?: string;
    maxLength?: number;
    label: string;
    value: string;
    shareEvent: string;
    onChange: (shareEvent: string, value: string) => void;
}

const SettingsTextField = styled(TextField)({
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#414141"
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "#414141",
      borderWidth: 2
    },
    "& .MuiFormHelperText-contained": {
      marginLeft: 0,
      marginRight: 0,
      "&.Mui-focused": {
        color: "#414141"
      }
    }
  });

const TextInputField: React.FunctionComponent<ComponentProps> = ( { value, isDisabled, placeholder, maxLength, label, shareEvent, onChange } ) => {
  //Used in order to use a state vs. updating via the action creator as it was super laggy and terrible.
  const [currValue, setValue] = useState<string>(value);  
  return (
        <Grid container>
            <Grid item xs={2}>
                <div style={{paddingTop: "8px"}}>
                <Typography variant='body1' align="justify">{label}</Typography>
                </div>
            </Grid>
            <Grid item xs={10}>
                <SettingsTextField
                    variant="outlined"
                    size="small"
                    style={{ width: "100%" }}
                    disabled={isDisabled}
                    placeholder={placeholder}
                    value={ maxLength && currValue ? currValue.slice(0, maxLength) : currValue }
                    onChange={(e) => {
                          setValue(e.target.value);
                    }}
                    onBlur={(e)=> {
                      onChange(shareEvent,e.target.value);
                    }}
                />
            </Grid>
        </Grid>
    );
}

export default TextInputField;