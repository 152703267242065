import React, { useState } from "react";

import Popover from "@mui/material/Popover";


const SupportedFileTypes: React.FunctionComponent<ComponentProps> = props => {
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <React.Fragment>
      <div
        onMouseEnter={(event: any) => setAnchorEl(event.currentTarget)}
        onMouseLeave={() => setAnchorEl(null)}
        className="supported-file-types"
      >
        What are the supported file types?
      </div>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        PaperProps={{
          style: {
            maxWidth: 200
          }
        }}
        style={{ pointerEvents: "none" }}
        onClose={() => setAnchorEl(null)}
        classes={{
          paper: "supported-file-types-description"
        }}
      >
        Sparrow Documents support DOCX, XLSX, PPTX, JPEG, PNG, PDF and TXT files only.
      </Popover>
    </React.Fragment>
  );
}


interface ComponentProps {}

export default SupportedFileTypes;