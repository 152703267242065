import React, { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";

import Tutorial, { Slide } from "modules/common/components/tutorial";

import Cookies from "utils/cookie";
import { Link } from "@mui/material";

const openSupport = () => {
  window.open("https://support.sparrowconnected.com/en/understanding-platform-analytics?hs_preview=TqbAInvr-70636390706", '_blank', 'noopener,noreferrer');
}

const TUTORIAL_SLIDES: Slide[] = [
  {
    title: "Introducing Platform Analytics",
    content: (
      <div>Your Platform Analytics are insights and metrics that measure the reach, engagement, and impact of your communications across all your channels. <br/><br/>
      These insights identify top performing content, empower you to make smart decisions, and provide you with true business intelligence from your communication efforts.
      </div>
    )
  },
  {
    title: "Gain Valuable User Insights",
    content: (
      <div><b>User Trends, Channel Usage</b> and <b>Engaged Users</b> are login sessions, content view and platform usage stats that gauge how many employees are engaged in the platform and which channels get the most traffic. 
      <br/><br/>
      These are metrics that reveal the frequency and depth of your users' content consumption and platform adoption. </div>
    )
  },
  {
    title: "Evaluate Content Engagement",
    content: (
      <div>Identify your <b>highest performing posts, events and surveys</b> and use this intelligence to inform your communications strategy and create more engaging content for your audiences. 
      <br/><br/>
      Content <b>Views, Reactions, Comments</b> and <b>Interactions</b> are responses and engagement metrics that show how well your content strategy aligns with user interest. </div>
    )
  },
  {
    title: "Measure Growth and Performance",
    content: (
      <div>The growth and performance of your content strategy is measured over time. 
      <br/><br/>
      With the help of our <b>date ranges</b>, you can pinpoint spikes and dips in user behavior and content engagement. Identifying these trends helps you plan ahead and refine your communication strategy. </div>
    )
  },
  {
    title: "Give Feedback and Get Support",
    content: (
      <div>Learn more about Sparrow Connected Platform Analytics at: <Link onClick={openSupport}>support.sparrowconnected.com</Link>.
      <br/><br/>
      For feedback, questions or support, get in touch with us at: <Link href="mailto:support@sparrowconnected.com">support@sparrowconnected.com</Link>. </div>
    )
  }
];

const isFirstVisit = (): boolean => {
  if (Cookies.get("ins-visited") === "true")
    return false;

  Cookies.setWithOpts("ins-visited", "true", {expires: 365});
  return true;
}

const InsightsTutorial: React.FunctionComponent<PropsWithRedux> = props => {
  const [showTutorial] = useState(isFirstVisit());

  return (
    <Tutorial openOnLoad={showTutorial} slides={TUTORIAL_SLIDES} imageSrc="/images/tutorials/platform-insights/platform-insights" />
  );
}

interface ComponentProps {}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
  })
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(InsightsTutorial);