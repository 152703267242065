import React from 'react';
import { AccountCreationTypes } from '..';
import UserGeneralDetails from '../components/user-field-components/userGeneralDetails';
import UserNotificationContacts from '../components/user-field-components/userNotificationContacts';
import UserCompanyDetails from '../components/user-field-components/userCompanyDetails';
import UserPermissionsDetails from './user-field-components/userPermissionsDetails';
import { connect, ConnectedProps } from "react-redux"
import { GlobalApplicationState } from "globalApplicationState"
import { actions } from "..";
import InputField from './user-field-components/inputField';

interface ComponentState {
    socialUserEmail: string;
}

interface ComponentProps {
    accountTypeSelected: AccountCreationTypes,
}


class SingleUserCreation extends React.Component<PropsWithRedux, ComponentState> {

    constructor(props) {
        super(props);
        this.state = {
            socialUserEmail: ""
        }
    }
    
    public render() {
        return (
            <div className="single-user-creation-container">
            
                {this.props.accountTypeSelected === AccountCreationTypes.Sparrow ? 
                <React.Fragment>
                    <div className="single-page-creation-section left">

                        <UserGeneralDetails /> 
                    </div>

                    <div className="single-page-creation-section right">
                        <UserCompanyDetails/>
                    </div>

                    <div className="single-page-creation-section left">
                        <UserPermissionsDetails
                            toggleSubmissionManager={this.props.toggleSubmissionManager}
                            toggleUserRole={this.props.toggleUserRole}
                            toggleSubscribedToFlaggingNotifications={this.props.toggleFlaggingNotifications}
                        />
                    </div>

                    <div className="single-page-creation-section right">
                        <UserNotificationContacts />
                    </div>
                </React.Fragment>
                :
                <React.Fragment>
                    <div className="single-page-creation-section left">
                        <InputField 
                            inputType={"email"}
                            title={"Send invite to*"}
                            placeholder={"Enter Email Address"}
                            description={"You are about to create one (1) new user with a social account (external provider e.g. Facebook, Google, Linkedin, Microsoft Personal or Apple). Enter an email address below to invite the user to login."}
                            onChange={this.props.handleInvitedUserEmail}
                            value={this.state.socialUserEmail}
                            setTimer={0}
                        />
                    </div>
                </React.Fragment>
                }
            </div>
        )
    }
}


const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        creatingUser: state.users.creatingUser,

    }),
    {
        handleInvitedUserEmail: actions.setInvitingUserEmail,
        toggleUserRole: actions.handleUserCreationRoles,
        toggleSubmissionManager: actions.handleUserCreationSubmissionManager,
        toggleFlaggingNotifications: actions.HandleUserCreationSubscribedToFlaggingNotifications
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(SingleUserCreation);
