import moment from 'moment';

export default {
    formatDate: (date?: Date | null) : string =>  {
        return !!date ? moment(date).format('L') : '';
    },
    formatTime: (date?: Date | null) : string => {
        return !!date ? moment(date).format(`h:mm a`) : '';
    }
}
