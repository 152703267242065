import * as React from "react";

import TextField from "@mui/material/TextField";

import moment from "moment";
import DateAndTime from "../forms/inputs/dateAndTime";
import HoverText from "modules/documents/components/action-buttons/hoverText";
import { PickerLocalization } from "../pickerLocalization";
import DatePicker from "../forms/inputs/datePicker";


// authoring v2 component
const ExpiryTimev2: React.FunctionComponent<ComponentProps> = ({
    expiryTime,
    onChange,
}) => {
    return (
        <div style={{ marginTop: 20 }}>
            <HoverText label="Expire on" labelContainerClassName="hover-label">
                Schedule to expire this post and hide it from all feeds after the specified time.
            </HoverText>
            <DateAndTime
                clearable
                onChangeDate={onChange}
                date={expiryTime}
                dateStyle={{ width: 250 }}
                datePlaceholder="Select date"
            />
        </div>
    );
}


class ExpiryTime extends React.Component<ComponentProps, ComponentState> {
    public render() {
        const { expiryTime } = this.props;

        return (
            <div className="scheduled-time">
                <div className="scheduled-time-field">
                    <div className="scheduled-time-label">Expire on</div>
                    <div className="scheduled-time-input">
                        <PickerLocalization>
                            <DatePicker
                                clearable
                                date={expiryTime}
                                placeholder="Schedule an expiry date"
                                onChangeDate={this.props.onChange}
                            />
                        </PickerLocalization>
                    </div>
                </div>
                <div className="scheduled-time-input-time">
                    <div>at</div>
                    <TextField
                        variant="outlined"
                        size="small"
                        color="primary"
                        type="time"
                        value={!!expiryTime ? moment(expiryTime).format("HH:mm") : ""}
                        disabled={!expiryTime}
                        inputProps={{
                            step: 900
                        }}
                        onChange={this.onChangeExpiryTime}
                    />
                </div>
            </div>
        );
    }

    private onChangeExpiryTime = (event) => {
        const times: number[] = event.target.value.split(":");
        const expiryTime: string = moment(this.props.expiryTime).set("hour", times[0]).set("minutes", times[1]).toISOString();
        this.props.onChange(expiryTime);
    }
}


interface ComponentProps {
    expiryTime: string | undefined;
    onChange: (expiryTime: string) => void;
}

interface ComponentState { }

export default ExpiryTime;
export { ExpiryTimev2 };
