import { Action, Middleware } from "redux";
import { GlobalApplicationState } from "globalApplicationState";
import { ThunkAction, ThunkDispatch } from "redux-thunk";

export interface CompleteAction extends Action {
    succeeded: boolean,
    err?: string
}

interface ActionCreator<P> {
    (payload: P)
}

export const ActionCreator = <A extends Action>(type: string) => (payload: Omit<A,"type">): A => {
    return Object.assign(payload, {type: type}) as A;
}

export const typedToPlain: Middleware = (store) => (next) => (action: Action) => {
    if (!action.type) {
        action.type = "__UNKNOWN_ACTION_TYPE"
    }

    next(Object.assign({}, action));
};

export type AppThunkResult<ReturnArg=any,ExtraArgument=undefined> = ThunkAction<ReturnArg, GlobalApplicationState, ExtraArgument, Action>;
export type AppThunkDispatch<ExtraArgument=undefined> = ThunkDispatch<GlobalApplicationState, ExtraArgument, Action>;

export interface PropsWithChildren {children?: React.ReactNode}