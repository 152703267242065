import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import * as actions from "../../actionCreator";
import { GlobalApplicationState } from "globalApplicationState";
import { push } from "react-router-redux";
import { PortalPagesFilterValues, PortalPagesListingPage } from "../../models";

import PortalPagesList from "./portalPagesList";

import Tabs from "pages/common/tabs";


import "../../styles/portalPagesListing.sass";
import { SortStyle } from "utils/managementUtils";


const allTab: number = 0;
const enabledTab: number = 1;
const draftTab: number = 2;
const disabledTab: number = 3;


class PortalPagesListing extends React.Component<PropsWithRedux, ComponentState> {
  constructor(props: PropsWithRedux) {
    super(props);

    this.state = {
      hasRefreshed: false,
      selectedTab: 0
    };
  }

  public componentWillMount() {
    if (this.props.isInitialLoad || this.props.shouldFetch) {
      this.fetchLists();
    }
  }

  public componentDidUpdate(prevProps: PropsWithRedux) {
    if (this.props.shouldFetch && (this.props.shouldFetch !== prevProps.shouldFetch)) {
      this.fetchLists();
    }
  }

  public render() {
    const { selectedTab } = this.state;

    return (
      <React.Fragment>
        <div className="portal-pages-listing">
          <Tabs
            tabs={[
              { label: this.getTabLabel("All", this.props.all) },
              { label: this.getTabLabel("Enabled", this.props.enabled), dotColor: "green" },
              { label: this.getTabLabel("Drafts", this.props.drafts), dotColor: "yellow" },
              { label: this.getTabLabel("Disabled", this.props.disabled), dotColor: "grey" }
            ]}
            selectedTab={selectedTab}
            onSelectTab={this.onSelectTab}
          />
          <PortalPagesList show={selectedTab === allTab} page={this.props.all} hasRefreshed={this.state.hasRefreshed} fetchPage={this.fetchAll} initialSort={SortStyle.publishDesc}/>
          <PortalPagesList show={selectedTab === enabledTab} page={this.props.enabled} hasRefreshed={this.state.hasRefreshed} fetchPage={this.fetchEnabled} initialSort={SortStyle.publishDesc}/>
          <PortalPagesList show={selectedTab === draftTab} page={this.props.drafts} hasRefreshed={this.state.hasRefreshed} fetchPage={this.fetchDrafts} initialSort={SortStyle.modifiedDesc}/>
          <PortalPagesList show={selectedTab === disabledTab} page={this.props.disabled} hasRefreshed={this.state.hasRefreshed} fetchPage={this.fetchDisabled} initialSort={SortStyle.publishDesc}/>
        </div>
      </React.Fragment>
    );
  }


  private fetchLists = () => {
    this.setState({ hasRefreshed: false }, () => this.setState({ hasRefreshed: true }));
    this.fetchAll(1, {sortType: SortStyle.publishDesc});
    this.fetchEnabled(1, {sortType: SortStyle.publishDesc});
    this.fetchDrafts(1, {sortType: SortStyle.modifiedDesc});
    this.fetchDisabled(1, {sortType: SortStyle.publishDesc});
    this.props.clearShouldFetch();
  }


  private fetchAll = (pageNumber: number, filters: Partial<PortalPagesFilterValues>, pageAmount?: number) => {
    !this.props.all.isFetching &&
      this.props.getAll(pageNumber, filters, pageAmount);
  }

  private fetchEnabled = (pageNumber: number, filters: Partial<PortalPagesFilterValues>, pageAmount?: number) => {
    !this.props.enabled.isFetching &&
      this.props.getEnabled(pageNumber, filters, pageAmount);
  }

  private fetchDrafts = (pageNumber: number, filters: Partial<PortalPagesFilterValues>, pageAmount?: number) => {
    !this.props.drafts.isFetching &&
      this.props.getDrafts(pageNumber, filters, pageAmount);
  }

  private fetchDisabled = (pageNumber: number, filters: Partial<PortalPagesFilterValues>, pageAmount?: number) => {
    !this.props.disabled.isFetching &&
      this.props.getDisabled(pageNumber, filters, pageAmount);
  }

  private getTabLabel = (label: string, page: PortalPagesListingPage): string => {
    if (!!page.currentPage)
      return `${label} (${page.totalPortalPages})`;
    return label;
  }

  private onSelectTab = (tabIndex: number) => {
    this.setState({ ...this.state, selectedTab: tabIndex });
  }
}
  

interface ComponentProps {
}

interface ComponentState {
  hasRefreshed: boolean;
  selectedTab: number;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    all: state.portalPages.all,
    enabled: state.portalPages.enabled,
    drafts: state.portalPages.drafts,
    disabled: state.portalPages.disabled,
    isFetching: state.portalPages.isFetching,
    isInitialLoad: state.portalPages.isInitialLoad,
    shouldFetch: state.portalPages.shouldFetch,
    tenantSettings: state.settings.tenantSettings,
    tenant: state.tenant.id
  }),
  {
    clearShouldFetch: actions.clearShouldFetch,
    getAll: actions.getPortalPages,
    getDisabled: actions.getDisabledPortalPages,
    getDrafts: actions.getDraftPortalPages,
    getEnabled: actions.getEnabledPortalPages,
    redirectTo: push
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(PortalPagesListing);