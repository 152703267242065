import React from "react";

import ConditionalWrapper from "../components/conditionalWrapper";
import { Collapse, Fade, Grow, Slide, Zoom } from "@mui/material";

import "./tileButton.sass";

export interface ITileButton {
    label: string;
    labelIcon: JSX.Element;
    onClick: () => void;
    id: string;
    transition?: "zoom" | "grow" | "slide" | "fade" | "collapse";
    delay?: number;
    style?: React.CSSProperties;
    className?: string;
}

interface ITileButtonProps {
    button: ITileButton;
}

const TileButton: React.FunctionComponent<ITileButtonProps> = ({
    button: {
        transition,
        label,
        labelIcon,
        delay,
        id,
        style,
        className,
        onClick
    },
}) => {
    /**
     * Decide which transition wrapper to use
     * @param children
     */
    const getTransition = (children: React.ReactElement): JSX.Element => {
        let props = { style: { transitionDelay: `${delay}ms` }, in: true, unmountOnExit: true };
        let wrapper = <></>;

        switch (transition) {
            case "collapse":
                wrapper = <Collapse {...props}>{children}</Collapse>
                break;
            case "fade":
                wrapper = <Fade {...props}>{children}</Fade>
                break;
            case "grow":
                wrapper = <Grow {...props}>{children}</Grow>
                break;
            case "slide":
                wrapper = <Slide {...props}>{children}</Slide>
                break;
            case "zoom":
                wrapper = <Zoom {...props}>{children}</Zoom>
                break;
            default:
                break;
        }

        return wrapper;
    }

    /**
     * Outer conditional handles the optional wrapper prop
     */
    return (
        <ConditionalWrapper
            condition={transition !== undefined}
            wrapper={(children: React.ReactElement) => getTransition(children)}
        >
            {/* extra div here so transitions don't clash */}
            <div>
                <div className={`tile-button ${className}`} id={id} onClick={onClick} style={style}>
                    <div style={{ textAlign: "center" }}>
                        {labelIcon}
                        <br />
                        {label}
                    </div>
                </div>
            </div>
        </ConditionalWrapper>
    );
}

interface ITileButtonFlexContainerProps {
    buttons: ITileButton[];
    delay?: number;
}

const TileButtonFlexContainer: React.FunctionComponent<ITileButtonFlexContainerProps> = ({
    buttons,
    delay = 400,
}) => {
    return (
        <div className="tile-button-container">
            {buttons.map((button: ITileButton, idx: number) => {
                return <TileButton key={button.id} button={button} />;
            })}
        </div>
    );
}

export { TileButton, TileButtonFlexContainer };
