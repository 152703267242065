import * as React from "react";

import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";


const Author: React.FunctionComponent<ComponentProps> = props => {
  return (
    <div className="author">
      <Divider light />
      <div className="author-details">
        <div style={{ backgroundColor: props.avatarColor }} className="author-avatar">{props.author ? props.author[0] : ""}</div>
        <div>
          <div>Organized By</div>
          {props.authorEmail
            ? <Link href={`mailto:${props.authorEmail}`} className="author-name">{props.author}</Link>
            : <div className="author-name">{props.author}</div>
          }
        </div>
      </div>
      <Divider light />
    </div>
  );
}


interface ComponentProps {
  author: string;
  authorEmail: string | undefined;
  avatarColor?: string;
}

export default Author;