import React, { useEffect } from "react";

import { DocumentFeedItem } from "../../../../../models";

import ShareButton from "modules/common/components/buttons/shareButton";

import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import SaveAltIcon from "@mui/icons-material/SaveAlt";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import moment from "moment";
import { dateOptions } from "utils/dateFormatting";


const Document: React.FunctionComponent<ComponentProps> = props => {
  useEffect(() => {
    moment.locale("en");
  }, []);

  const { document } = props;

  return (
    <TableRow
      hover
      onClick={() => props.onDocumentSelected(document)}
      key={document.id}
      className="document"
    >
      <TableCell align="center">
        <img src={`/images/icons/documents/${document.fileType}-list.png`} alt={document.fileType} className="file-type" />
      </TableCell>
      <TableCell component="th" scope="row">{document.title || `${document.fileName}.${document.fileType}`}</TableCell>
      <TableCell align="center">
        {document.isNew
          ? <div className="new">New</div>
          : document.isUpdated &&
            <div className="updated">Updated</div>
        }
      </TableCell>
      <TableCell align="center">{moment(document.lastUpdatedDate).format(dateOptions.basicFormatting)}</TableCell>
      <TableCell align="center">
        {!!document.tags.length &&
          <div>{document.tags.slice(0, 3).map(tag => tag.name).join(", ")}{document.tags.length > 3 ? `, ... +${document.tags.length - 3}` : ""}</div>
        }
      </TableCell>
      <TableCell align="center">
        {document.isUnread
          ? <div className="unread">Unread</div>
          : <div className="read">Read {moment(document.lastReadDate).fromNow()}</div>
        }
      </TableCell>
      <TableCell align="center" onClick={(ev) => ev.stopPropagation()}>
        <IconButton title="Download" size="small" onClick={() => props.onDownloadDocument(document)}>
          <SaveAltIcon fontSize="small" />
        </IconButton>
        <IconButton title="More Info" size="small" onClick={() => props.onInspectDocument(document)}>
          <InfoOutlinedIcon fontSize="small" />
        </IconButton>
        <ShareButton
          articleId={document.id}
          articleTitle={document.title || `${document.fileName}.${document.fileType}`}
          articleType="document"
          size="small"
          warningMessage="This document may not be accessible to other colleagues. Contact your administrator for more details."
        />
      </TableCell>
    </TableRow>
  );
}

interface ComponentProps {
  document: DocumentFeedItem;
  onDocumentSelected: (document: DocumentFeedItem) => void;
  onDownloadDocument: (document: DocumentFeedItem) => void;
  onViewDocument: (document: DocumentFeedItem) => void;
  onInspectDocument: (document: DocumentFeedItem) => void;
}

export default Document;