import ConfirmDialog from 'modules/common/components/dialogs/confirmDialog';
import * as React from 'react';
import { CustomFileData } from '../models';

interface IDeleteMediaDialog {
    onClose: () => void,
    onConfirm: () => void,
    onDeny: () => void,
    showDeleteFilesDialog: boolean;
    filesToDelete: CustomFileData[];
    numImagesToDelete: number,
    numFoldersToDelete: number,
}

export const DeleteMediaDialog: React.FunctionComponent<IDeleteMediaDialog> = ({
    onClose,
    onConfirm,
    onDeny,
    showDeleteFilesDialog,
    filesToDelete,
    numImagesToDelete,
    numFoldersToDelete
}) => {
    const getDeleteDialogText = (): string => {
        let text = "";

        if (numImagesToDelete === 1 && numFoldersToDelete === 0) {
            text = `You're about to delete this image.`;
        }
        else if (numFoldersToDelete > 0) {
            text = `You're about to delete ${numFoldersToDelete} folder${numFoldersToDelete > 1 ? "s" : ""} and ${numImagesToDelete} image${numImagesToDelete > 1 ? "s" : ""}.`;
        }
        else if (numFoldersToDelete === 0) {
            text = `You're about to delete ${numImagesToDelete} images.`;
        }

        return text + " This action cannot be undone. Are you sure?";
    }

    return <ConfirmDialog
        title='Delete'
        denyLabel="Cancel"
        confirmLabel={numImagesToDelete === 1 && numFoldersToDelete === 0 ? "Delete" : "Delete all"}
        denyButtonProps={{sx:{color:'grey'}}}
        confirmButtonProps={{sx: {backgroundColor: '#dc3838', color: 'white'}}}
        onClose={onClose}
        onConfirm={onConfirm}
        onDeny={onDeny}
        dialogMaxWidth="sm"
        open={showDeleteFilesDialog && filesToDelete.length > 0}
    >
        {getDeleteDialogText()}
    </ConfirmDialog>;
}