import * as React from "react";
import { errorAlert } from "utils/notyPopups";

interface componentProps {
    parseCSV: (File) => any;
}

interface componentState {
    dragging: boolean;
}

class UserCSVUploader extends React.Component<componentProps, componentState> {
    constructor(props) {
        super(props);
        this.state = {
            dragging: false,
        };
    }

    public render() {
        return this.renderUploadForm();
    }

    private renderUploadForm() {
        let draggingProps = {
            onDrag: this.dragging(true),
            onDragStart: this.dragging(true),
            onDragEnd: this.dragging(false),
            onDragOver: this.dragging(true),
            onDragEnter: this.dragging(true),
            onDragLeave: this.dragging(false),
            onDrop: this.onFileDropped,
        };

        return (
            <div className={"bulk-user-uploader" + (this.state.dragging ? " is-dragging" : "")} {...draggingProps}>
                <div className="bulk-user-centered-content">
                    <img src="/images/icons/uploadIcon.svg" alt="Arrow pointing upwards" style={{height: "25px", paddingBottom: "20px", paddingTop: "20px"}}></img>
                    <div style={{paddingBottom: "40px"}}>
                        <input
                            className="original-input"
                            id="choose-file"
                            multiple
                            name="choose-file"
                            type="file"
                            accept={this.acceptedFiles()}
                            onChange={this.onFileInputChanged}
                        />
                        <p className="large">
                            <label style={{fontSize: "16px", fontWeight: "normal", color: "#888"}} htmlFor="choose-file">Choose or drag CSV file here </label>
                        </p>
                    </div>
                </div>
            </div>
        );
    }

    private onFileInputChanged = (e: React.FormEvent<HTMLInputElement>) => {
        const files = (e.target as HTMLInputElement).files;
        this.dragging(false, () => {
            this.props.parseCSV(files![0]); // only take in one file
        })(e);
    };

    private acceptedFiles = () => {
        return ".csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv";
    };
    private onFileDropped = (e: React.DragEvent<HTMLDivElement>) => {
        const files = e.dataTransfer.files;
        let errorTimeoutInSeconds = 3500;
        if (!this.acceptedFiles().includes(files![0].type)) {
            errorAlert("Incorrect file format, please upload a .csv file", errorTimeoutInSeconds);
            return;
        }
        this.dragging(false, () => {
            this.props.parseCSV(files[0]); // only take in one file
        })(e);
    };

    private dragging = (isDragging: boolean, callback?: () => void) => (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (typeof callback !== "function") callback = () => {};

        this.setState(
            {
                ...this.state,
                dragging: isDragging,
            },
            callback
        );
        return false;
    };
    
}

export default UserCSVUploader;
