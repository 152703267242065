import React from "react";
import { Select, FormControl, MenuItem } from "@mui/material";

import { ContentBandContentType } from "modules/contentBands/models";
import { IContentBandSettingsInputProps } from "../editor/contentBandSettingsForm";


export const ContentTypeInput: React.FunctionComponent<IContentBandSettingsInputProps<ContentBandContentType>> = ({
    idx,
    value,
    onChange,
}) => {
    return (
        <div className="form-group">
            <label>Content type</label>
            <FormControl>
                <Select
                    id={`cb-content-type-${idx}`}
                    className="input"
                    value={value}
                    variant="outlined"
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => onChange(evt.target.value as ContentBandContentType, idx)}
                    MenuProps={{ disablePortal: true }} // clickawaylistener breaks with selects as children when portal is enabled (more info: https://mui.com/joy-ui/api/menu/#Menu-prop-disablePortal)
                >
                    <MenuItem value={ContentBandContentType.Post}>Posts</MenuItem>
                    <MenuItem value={ContentBandContentType.Event}>Events</MenuItem>
                </Select>
            </FormControl>
        </div>
    );
};
