import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";

import MoreOptionsIconButton from "modules/common/components/buttons/moreOptionsIconButton";

import Divider from "@mui/material/Divider";
import List from "@mui/material/List";

import MoreOptionsItem from "modules/common/components/moreOptionsItem";
import { push } from "react-router-redux";
import { EventListItem } from "modules/events/models";

//TODO: Combine moreOptions components into a single one, rather than having multiple. 

const MoreOptions: React.FunctionComponent<PropsWithRedux> = props => {
  let event = props.event;

  return (
    <MoreOptionsIconButton>
      <List disablePadding>
          <MoreOptionsItem
            text="Edit"
            disabled={event.publishedTime !== null && event.eventType.toLowerCase() === "compliance"}
            onClick={() => {
                props.redirectTo("/" + props.tenant + "/admin/events/edit/" + event.draftId);
            }}
          />
          <MoreOptionsItem
            text="Preview"
            onClick={() => {
                props.onPreview(event);
            }}
          />
          <Divider light />
          <MoreOptionsItem
            text="Manage Participants"
            disabled={event.eventType.toLowerCase() === "informational" || event.status.toLowerCase() === "draft"}
            onClick={() => {
                props.redirectTo("/" + props.tenant + "/admin/events/attendance/" + event.id);
            }}
          />
          <Divider light />

          <MoreOptionsItem
            text="Duplicate"
            onClick={() => {
                props.onClone(event);
            }}
          />
          { event.status.toLowerCase() === "published" && <MoreOptionsItem
            text="Unpublish"
            onClick={() => {
                props.onUnpublish(event);
            }}
          />}
        <MoreOptionsItem
            text="Delete"
            textStyle={{color: "#B72026"}}
            onClick={() => {
                props.onDelete(event);
            }}
        />
      </List>
    </MoreOptionsIconButton>
  );
}

interface ComponentProps {
  event: EventListItem;
  onPreview: (eventToPreview?: EventListItem) => void;
  onClone: (eventToClone?: EventListItem) => void;
  onDelete: (eventToDelete?: EventListItem) => void;
  onUnpublish: (eventToUnpublish?: EventListItem) => void;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    tenant: state.tenant.id
  }),
  {
    redirectTo: push,
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(MoreOptions);