import * as React from "react";
import Loading from "modules/common/components/loading";
import "./audiences.sass";
import { audiencesApi } from "api/instances";
import { UserSuggestions, GroupSuggestions, UpdateAudience, AudienceSuggestions, GroupSuggestionsV1 } from "../models";
import { injectIntl, FormattedMessage, IntlShape } from "react-intl";
import UserGroupPreviewModal from "./userGroupPreviewModal";
import CSVUploader from "./csvUploader";
import { truncateStringWithElipsis } from "utils/stringFormatting";

const FETCH_AMOUNT = 10;

class SuggestionsManager extends React.Component<ComponentProps, ComponentState> {
    constructor(props) {
        super(props);
        this.state = {
            fetching: false,
            userSuggestions: [],
            groupSuggestions: [],
            audienceSuggestions: [],
            amountFetched: 0,
            groupAmountFetched: 0,
            userSearchText: "",
            prevUserSearchText: "",
            groupSearchText: "",
            prevGroupSearchText: "",
            activeTab: "user-audience",
            showGroupPreview: false,
            currentGroup: { name: "", users: [] },
        };
    }

    public render() {
        let currentAudience: null | UpdateAudience = null;
        if (this.props.currentAudience) {
            currentAudience = {
                ...this.props.currentAudience,
                includedUsers: this.props.currentAudience?.includedUsers.map((user) => {
                    return { Id: user.Id, Details: user.Details };
                }),
                includedGroups: this.props.currentAudience?.includedGroups.map((group) => {
                    return { id: group.id, name: group.name, disabled: group.disabled, isIncluded: true };
                }),
            };
        }

        let showBackButton;
        let showNextButton;
        if (this.state.activeTab === "user-audience") {
            if (this.props.currentAudience) {
                showNextButton = this.state.userSuggestions.length >= FETCH_AMOUNT;
            } else {
                showNextButton = this.state.audienceSuggestions.length >= FETCH_AMOUNT;
            }
            showBackButton = this.state.amountFetched > FETCH_AMOUNT;
        } else {
            showBackButton = this.state.groupAmountFetched > FETCH_AMOUNT;
            showNextButton = this.state.groupSuggestions.length >= FETCH_AMOUNT;
        }

        return (
            <div className={`details list ${!this.props.currentAudience ? "subscription" : ""}`}>
                <div className="tabs" style={{ borderBottom: "1px solid #ddd" }}>
                    <ul className="tab-btns">
                        <li>
                            <span
                                onClick={() => {
                                    this.setState({ ...this.state, activeTab: "user-audience" });
                                }}
                                className={"tab-btn" + (this.state.activeTab === "user-audience" ? " active" : "")}
                            >
                                {this.props.currentAudience ? (
                                    <FormattedMessage id="users.users" defaultMessage="Users" />
                                ) : (
                                    <FormattedMessage id="audiences.audiences" defaultMessage="Audiences" />
                                )}
                            </span>
                        </li>
                        <li>
                            <span
                                onClick={() => {
                                    this.setState({
                                        ...this.state,
                                        activeTab:  "groups",
                                    });
                                }}
                                className={"tab-btn" + (this.state.activeTab === "groups" ? " active" : "")}
                                title={
                                    this.props.ADGroupSyncOrEveryoneAudienceEnabled
                                        ? ""
                                        : "Active Directory Synchronization is not setup - speak to your Sparrow Administrator"
                                }
                            >
                                <FormattedMessage id="users.groups" defaultMessage={this.props.syncType + " Groups"} />
                            </span>
                        </li>

                        { this.props.parseCSV != null &&
                        <li>
                            <span
                                onClick={() => {
                                    this.setState({ ...this.state, activeTab: "bulk-upload" });
                                }}
                                className={"tab-btn" + (this.state.activeTab === "bulk-upload" ? " active" : "")}
                            >
                                <FormattedMessage id="users.bulkUpload" defaultMessage="Bulk Upload" />
                            </span>
                        </li>
                        }
                    </ul>
                </div>
                {this.state.activeTab === "user-audience" ? (
                    <div style={{ marginTop: "15px" }}>
                        {!this.props.currentAudience ? (
                            <label style={{ width: "160px", marginLeft: "25px" }}>Search Audiences</label>
                        ) : null}
                        <div className={`input-wrapper ${this.props.currentAudience ? "" : " subscription"}`}>
                            {this.props.currentAudience ? <label style={{ width: "160px" }}>Search Users</label> : null}
                            <input
                                className="form-control"
                                autoComplete="off"
                                value={this.state.userSearchText}
                                onChange={(e) => this.setState({ ...this.setState, userSearchText: e.target.value })}
                                onKeyDown={(e) =>
                                    this.state.userSearchText !== this.state.prevUserSearchText &&
                                    this.state.userSearchText !== "" &&
                                    e.key === "Enter" &&
                                    this.fetchUserOrAudienceSuggestions()
                                }
                            />
                            <button
                                className="search-btn btn-primary"
                                disabled={
                                    this.state.fetching ||
                                    this.props.working ||
                                    this.state.userSearchText === this.state.prevUserSearchText ||
                                    this.state.userSearchText === ""
                                }
                                onClick={() => this.fetchUserOrAudienceSuggestions()}
                            >
                                Search
                            </button>
                        </div>
                        <div className="results">
                            {this.state.fetching ? (
                                <Loading padding={12} />
                            ) : this.props.currentAudience ? (
                                this.state.userSuggestions.length !== 0 ? (
                                    this.state.userSuggestions.map((user) => {
                                        return (
                                            <div key={user.Id} className="item">
                                                {truncateStringWithElipsis(
                                                    `${user.Details.firstName} ${user.Details.lastName} - ${user.Details.email}`,
                                                    35
                                                )}
                                                {this.props.canEdit ? (
                                                    currentAudience?.includedUsers.find(
                                                        (iu) => iu.Details.email === user.Details.email
                                                    ) ? null : (
                                                        <span
                                                            className="add-btn"
                                                            onClick={() =>
                                                                this.props.updateAudience
                                                                    ? this.props.updateAudience("includedUsers", "list", user)
                                                                    : null
                                                            }
                                                        >
                                                            <i className="material-icons">add</i>
                                                        </span>
                                                    )
                                                ) : null}
                                            </div>
                                        );
                                    })
                                ) : (
                                    <div style={{ textAlign: "center", color: "#666" }}>
                                        <FormattedMessage id="common.noResults" defaultMessage="No Results" />
                                    </div>
                                )
                            ) : this.state.audienceSuggestions.length !== 0 ? (
                                this.state.audienceSuggestions.map((a) => {
                                    return (
                                        <div key={a.Id} className="item">
                                            {a.DisplayName}
                                            <input
                                                type="checkbox"
                                                readOnly
                                                id={a.Id}
                                                checked={this.props.currentList?.findIndex((ul) => ul.id === a.Id) !== -1}
                                                onClick={() =>
                                                    this.props.updateList
                                                        ? this.props.updateList(
                                                              a.Id,
                                                              "audience",
                                                              !(this.props.currentList?.findIndex((ul) => ul.id === a.Id) !== -1)
                                                          )
                                                        : null
                                                }
                                            />
                                        </div>
                                    );
                                })
                            ) : (
                                <div style={{ textAlign: "center", color: "#666" }}>
                                    <FormattedMessage id="common.noResults" defaultMessage="No Results" />
                                </div>
                            )}
                        </div>
                    </div>
                ) : this.state.activeTab === "groups" ? (
                    <div style={{ marginTop: "15px" }}>
                        {!this.props.currentAudience && <label style={{ width: "155px", marginLeft: "25px" }}>Search {this.props.syncType} Groups</label>}
                        <div className={`input-wrapper ${this.props.currentAudience ? "" : " subscription"}`}>
                            {this.props.currentAudience && <label style={{ width: "155px" }}>Search {this.props.syncType} Groups</label>}
                            <input
                                className="form-control"
                                autoComplete="off"
                                value={this.state.groupSearchText}
                                onChange={(e) => this.setState({ ...this.setState, groupSearchText: e.target.value })}
                                onKeyDown={(e) =>
                                    this.state.groupSearchText !== this.state.prevGroupSearchText &&
                                    this.state.groupSearchText !== "" &&
                                    e.key === "Enter" &&
                                    this.fetchGroupSuggestions()
                                }
                            />
                            <button
                                className="search-btn btn-primary"
                                disabled={
                                    this.state.fetching ||
                                    this.props.working ||
                                    this.state.groupSearchText === this.state.prevGroupSearchText ||
                                    this.state.groupSearchText === ""
                                }
                                onClick={() => this.fetchGroupSuggestions()}
                            >
                                Search
                            </button>
                        </div>
                        <div className="results">
                            {this.state.fetching ? (
                                <Loading padding={12} />
                            ) : this.state.groupSuggestions.length !== 0 ? (
                                this.state.groupSuggestions.map((group) => {
                                    return (
                                        <div key={group.id} className="item">
                                            {group.name}
                                            <div style={{ display: "inline", float: "right" }}>
                                                <span
                                                    className="add-btn"
                                                    onClick={() =>
                                                        this.props.updateAudience
                                                            ? this.props.updateAudience("includedGroups", "list", group)
                                                            : null
                                                    }
                                                >
                                                    {this.props.canEdit ? (
                                                        this.props.currentAudience ? (
                                                            currentAudience?.includedGroups.find((ig) => ig.id === group.id) ? null : (
                                                                <i className="material-icons">add</i>
                                                            )
                                                        ) : (
                                                            <input
                                                                type="checkbox"
                                                                readOnly
                                                                id={group.id}
                                                                checked={
                                                                    this.props.currentList?.findIndex((ul) => ul.id === group.id) !== -1
                                                                }
                                                                onClick={() =>
                                                                    this.props.updateList
                                                                        ? this.props.updateList(
                                                                              group.id,
                                                                              "userGroup",
                                                                              !(
                                                                                  this.props.currentList?.findIndex(
                                                                                      (ul) => ul.id === group.id
                                                                                  ) !== -1
                                                                              )
                                                                          )
                                                                        : null
                                                                }
                                                            />
                                                        )
                                                    ) : null}
                                                </span>
                                                {this.props.currentAudience ? (
                                                    <span
                                                        className="preview-btn"
                                                        style={{ float: "right" }}
                                                        onClick={() => this.previewUsersInGroup(group)}
                                                    >
                                                        <i className="material-icons">pageview</i>
                                                    </span>
                                                ) : null}
                                            </div>
                                        </div>
                                    );
                                })
                            ) : (
                                <div style={{ textAlign: "center", color: "#666" }}>No results</div>
                            )}
                        </div>
                    </div>
                ) : (
                    <div>
                        <CSVUploader
                          parseCSV={this.props.parseCSV}
                          downloadSample={this.props.downloadSample} />
                    </div>
                )}
                <div className="page-controls">
                    {showBackButton ? (
                        <button
                            className="page-btn"
                            disabled={this.state.fetching || this.props.working}
                            onClick={() =>
                                this.state.activeTab === "user-audience"
                                    ? this.fetchUserOrAudienceSuggestions(true)
                                    : this.fetchGroupSuggestions(true)
                            }
                        >
                            <i className="material-icons" style={{ fontSize: "28px" }}>
                                navigate_before
                            </i>
                        </button>
                    ) : null}
                    {showNextButton ? (
                        <button
                            className="page-btn next"
                            disabled={this.state.fetching || this.props.working}
                            onClick={() =>
                                this.state.activeTab === "user-audience"
                                    ? this.fetchUserOrAudienceSuggestions()
                                    : this.fetchGroupSuggestions()
                            }
                        >
                            <i className="material-icons" style={{ fontSize: "28px" }}>
                                navigate_next
                            </i>
                        </button>
                    ) : null}
                </div>
                <UserGroupPreviewModal show={this.state.showGroupPreview} group={this.state.currentGroup} closeModal={this.closeModal} />
            </div>
        );
    }

    private fetchUserOrAudienceSuggestions = (back?: boolean) => {
        let newAmountFetched;
        if (this.state.prevUserSearchText !== this.state.userSearchText) {
            newAmountFetched = 0;
        } else {
            if (back) {
                if (this.props.currentAudience) {
                    newAmountFetched = this.state.amountFetched - (FETCH_AMOUNT + this.state.userSuggestions.length);
                } else {
                    newAmountFetched = this.state.amountFetched - (FETCH_AMOUNT + this.state.audienceSuggestions.length);
                }
            } else {
                newAmountFetched = this.state.amountFetched;
            }
        }

        this.setState(
            {
                ...this.state,
                fetching: true,
                amountFetched: newAmountFetched,
            },
            () => {
                if (this.state.userSearchText !== "") {
                    if (this.props.currentAudience) {
                        audiencesApi
                            .getUserSuggestions(this.state.userSearchText, FETCH_AMOUNT, this.state.amountFetched)
                            .then((response) => {
                                this.setState({
                                    ...this.state,
                                    userSuggestions: response,
                                    fetching: false,
                                    prevUserSearchText: this.state.userSearchText,
                                    amountFetched: this.state.amountFetched + response.length,
                                });
                            });
                    } else {
                        audiencesApi
                            .getAudienceSuggestions(this.state.userSearchText, FETCH_AMOUNT, this.state.amountFetched)
                            .then((response) => {
                                this.setState({
                                    ...this.state,
                                    audienceSuggestions: response.filter((a) => a.Enabled),
                                    fetching: false,
                                    prevUserSearchText: this.state.userSearchText,
                                    amountFetched: this.state.amountFetched + response.length,
                                });
                            });
                    }
                }
            }
        );
    };

    private fetchGroupSuggestions = (back?: boolean) => {
        let newGroupAmountFetched;
        if (this.state.prevGroupSearchText !== this.state.groupSearchText) {
            newGroupAmountFetched = 0;
        } else {
            if (back) {
                newGroupAmountFetched = this.state.groupAmountFetched - (FETCH_AMOUNT + this.state.groupSuggestions.length);
            } else {
                newGroupAmountFetched = this.state.groupAmountFetched;
            }
        }

        this.setState(
            {
                ...this.state,
                fetching: true,
                groupAmountFetched: newGroupAmountFetched,
            },
            () => {
                if (this.state.groupSearchText !== "") {
                    audiencesApi
                        .getUserGroupSuggestions(this.state.groupSearchText, FETCH_AMOUNT, this.state.groupAmountFetched)
                        .then((response) => {
                            this.setState({
                                ...this.state,
                                groupSuggestions: response
                                    .filter((group: GroupSuggestionsV1) => !group.Disabled)
                                    // response is PascalCase -> convert to camel
                                    .map((group: GroupSuggestionsV1) => ({ id: group.Id, name: group.Name, disabled: group.Disabled })),
                                fetching: false,
                                prevGroupSearchText: this.state.groupSearchText,
                                groupAmountFetched: this.state.groupAmountFetched + response.length,
                            });
                        });
                }
            }
        );
    };

    private previewUsersInGroup = (group: GroupSuggestions) => {
        this.setState({ ...this.state, fetching: true });
        audiencesApi.getUsersInGroup(group.id).then((response) => {
            this.setState({
                ...this.state,
                currentGroup: { name: group.name, users: response },
                fetching: false,
                showGroupPreview: true,
            });
        });
    };

    private closeModal = () => {
        this.setState({ ...this.state, showGroupPreview: false });
    };
}

interface ComponentProps {
    intl: IntlShape;
    working: boolean;
    updateAudience?: (field: string, type: string, value: any) => void;
    currentAudience?: UpdateAudience;
    currentList?: { id: string; type: string }[];
    updateList?: (id: string, type: string, add: boolean) => void;
    ADGroupSyncOrEveryoneAudienceEnabled: boolean;
    canEdit: boolean;
    parseCSV: (file: File) => any;
    downloadSample: any;
    syncType: string;
}

interface ComponentState {
    userSearchText: string;
    prevUserSearchText: string;
    groupSearchText: string;
    prevGroupSearchText: string;
    fetching: boolean;
    userSuggestions: UserSuggestions[];
    groupSuggestions: GroupSuggestions[];
    audienceSuggestions: AudienceSuggestions[];
    amountFetched: number;
    groupAmountFetched: number;
    activeTab: string;
    showGroupPreview: boolean;
    currentGroup: {
        name: string;
        users: any[];
    };
}

export default injectIntl(SuggestionsManager);
