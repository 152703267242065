import React, { useMemo } from "react";
import { Button } from "@mui/material";

import InfoHover from "modules/common/components/hovers/infoHover";
import { HomeScreenType, IHomeScreenConfig } from "modules/homeScreens/models";
import { useDispatch } from "react-redux";
import { homeScreensSlice } from "modules/homeScreens/reducer";
import { HomeScreenSettingsForm } from "./homeScreenSettingsForm";

interface IHomeScreenSettingsProps {
    homeScreenConfigs: IHomeScreenConfig[];
    pristineConfigs: IHomeScreenConfig[];
    publishedBandsCount: number;
    isLoading: boolean;
    onSave: () => Promise<void>;
}

export const HomeScreenSettings: React.FunctionComponent<IHomeScreenSettingsProps> = ({
    homeScreenConfigs,
    pristineConfigs,
    publishedBandsCount,
    isLoading,
    onSave,
}) => {
    const dispatch = useDispatch();

    /**
     * Reset configs to saved values
     */
    const onReset = () => {
        dispatch({ type: homeScreensSlice.actions.RESET_CONFIGS });
    };

    const isPristine = useMemo<boolean>(() => {
        // start off by checking lengths
        let isPristine = pristineConfigs.length === homeScreenConfigs.length;

        // lengths are equal, check pristine config properties against current changes
        if (isPristine) {
            for (let i = 0; i < pristineConfigs.length; i++) {
                const pristineConfig = pristineConfigs[i];
                // grab the same config from current changes
                let current = homeScreenConfigs.find((currConfig: IHomeScreenConfig) => pristineConfig.id === currConfig.id);

                // check each property
                if (current) {
                    let keys = Object.keys(current);
                    isPristine = !keys.some((key: string) => current![key] !== pristineConfig[key]);
                }

                // early exit if there are already changes compared to pristine
                if (!isPristine) break;
            }
        }

        return isPristine;
    }, [homeScreenConfigs, pristineConfigs]);

    const isError =
        homeScreenConfigs.some((c: IHomeScreenConfig) => c.homeScreenType === HomeScreenType.ContentBands) && publishedBandsCount === 0;

    return (
        <>
            <div
                className="info-container"
            >
                <span className="info-label">Home Screens</span>
                <InfoHover>Select your organization's home screen for each channel.</InfoHover>
            </div>
            {homeScreenConfigs.map((config: IHomeScreenConfig) => (
                <HomeScreenSettingsForm
                    isLoading={isLoading}
                    publishedBandsCount={publishedBandsCount}
                    key={config.platform}
                    config={config}
                />
            ))}
            <div id="home-screen-settings-actions" className="half-group">
                <Button
                    id="reset-mobile-app-settings"
                    variant="text"
                    className="reset-btn"
                    onClick={onReset}
                    disabled={isPristine || isError}
                >
                    RESET
                </Button>
                <Button
                    id="save-mobile-app-settings"
                    disabled={isPristine || isError}
                    variant="contained"
                    color="primary"
                    onClick={async () => await onSave()}
                >
                    SAVE CHANGES
                </Button>
            </div>
        </>
    );
};
