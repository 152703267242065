import { EventFeedFilters } from "../../../models";

type View = {
  [view: string]: {
    filters: Partial<EventFeedFilters>;
    sortAscending: boolean;
  };
};

const ViewOptions: View = {
  my: {
    filters: {
      myEventResponses: ["InPerson", "Online","Waitlist"],
      newerThan: new Date().toISOString()
    },
    sortAscending: true
  },
  open: {
    filters: {
      myEventResponses: ["Undecided"],
      newerThan: new Date().toISOString()
    },
    sortAscending: true
  },
  past: {
    filters: {
      olderThan: new Date().toISOString()
    },
    sortAscending: false
  },
  upcoming: {
    filters: {
      newerThan: new Date().toISOString()
    },
    sortAscending: true
  }
};

export default ViewOptions;