import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { push } from "react-router-redux";
import * as actions from "../../actionCreator";
import { GlobalApplicationState } from "globalApplicationState";

import MoreOptionsIconButton from "modules/common/components/buttons/moreOptionsIconButton";

import confirm from "utils/notyPopups";

import Divider from "@mui/material/Divider";
import List from "@mui/material/List";

import { NewsletterDigest } from "modules/newsletters";
import MoreOptionsItem from "modules/common/components/moreOptionsItem";


const MoreNewsletterOptions: React.FunctionComponent<PropsWithRedux> = props => {
  const { newsletter } = props;

  return (
    <MoreOptionsIconButton>
      <List disablePadding>
        {(newsletter.status !== "Draft") &&
          <MoreOptionsItem
            text="Open dashboard"
            onClick={() => {
              props.redirectTo("/" + props.tenantId + "/admin/newsletters/" + props.newsletter.id + "/dashboard");
            }}
          />
        }
        <MoreOptionsItem
          text={newsletter.status === "Draft" ? "Continue editing" : "Edit"}
          onClick={() => {
            props.redirectTo("/" + props.tenantId + "/admin/newsletters/" + props.newsletter.id);
          }}
        />
        <MoreOptionsItem
          text="Duplicate"
          onClick={() => {
            props.onCreateCopy();
          }}
        />
        <Divider light />
        {newsletter.status === "Enabled" &&
          <MoreOptionsItem
            text="Disable"
            onClick={async () => {
              if (await confirm.show({
                title: "Disable '" + newsletter.title +"'",
                text: "You're about to disable this newsletter.<br><br>Are you sure?",
                yesText: "Disable",
                noText: "Cancel"
              })) {
                props.disableNewsletter(newsletter.id).then(() => {props.onNewsletterChanged("Newsletter disabled")});
              }
            }}
          />
        }
        {newsletter.status === "Disabled" &&
          <MoreOptionsItem
            text="Enable"
            onClick={async () => {
              if (await confirm.show({
                title: "Enable '" + newsletter.title +"'",
                text: "You're about to enable this newsletter.<br><br>Are you sure?",
                yesText: "Enable",
                noText: "Cancel"
              })) {
                props.enableNewsletter(newsletter.id).then(() => {props.onNewsletterChanged("Newsletter enabled")});
              }
            }}
          />
        }
        <MoreOptionsItem
          text="Delete"
          textStyle={{color: "#B72026"}}
          onClick={async () => {
            if (await confirm.show({
              title: "Delete Newsletter",
              text: (
                <div>
                  <div>You're about to permanently delete '{newsletter.title}'. You'll lose all queued and sent issues, reports, and settings associated with this. You cannot undo this action.</div>
                  <br />
                  <div>Are you sure?</div>
                </div>
              ),
              yesColor: "#a80000",
              yesText: "Delete",
              noText: "Cancel"
            })) {
              props.deleteNewsletter(newsletter.id).then(() => {props.onNewsletterChanged("Newsletter deleted")});
            }
          }}
        />
      </List>
    </MoreOptionsIconButton>
  );
}

interface ComponentProps {
  newsletter: NewsletterDigest;
  onCreateCopy: () => any;
  onNewsletterChanged: (successMessage:string) => any;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    tenantId: state.tenant.id
  }),
  {
    deleteNewsletter: actions.deleteNewsletter,
    disableNewsletter: actions.disableNewsletter,
    enableNewsletter: actions.enableNewsletter,
    redirectTo: push
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(MoreNewsletterOptions);