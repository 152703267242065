import React from "react";

import { Button, IconButton, TextField } from "@mui/material";
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import copy from "copy-to-clipboard";

import { ISmartContentListPageProps, SmartContentListPage } from "./smartContentListPage";
import { IGPTChoice } from "./models";
import Pagination from "modules/common/components/pagination/pagination";
import Loading from "modules/common/components/loading";

interface ISmartContentDraftPage extends ISmartContentListPageProps {
    onApplyDraft: () => void;
    onChangeDraft: (newText: string) => void;
    onChangeDraftIdx: (newDraftIdx: number) => void;
    drafts: IGPTChoice[];
    draftIdx: number;
    currentDraft: IGPTChoice | null;
}

const SmartContentDraftPage: React.FunctionComponent<ISmartContentDraftPage> = ({
    drafts,
    draftIdx,
    fetching,
    currentDraft,
    onChangeDraftIdx,
    onChangeDraft,
    onApplyDraft,
    ...props
}) => {
    return (
        <SmartContentListPage
            {...props}
            fetching={fetching}
            contentStyle={{
                height: "100%"
            }}
        >
            {fetching
                ? <Loading />
                : <>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <Pagination
                            dropDown
                            selectLabel="Draft"
                            count={drafts?.length || 0}
                            currentPage={draftIdx}
                            onPageChange={onChangeDraftIdx}
                        />
                        <div style={{ marginTop: 10 }}>
                            <IconButton
                                disabled={!currentDraft}
                                id={`smart-content-draft-copy-${currentDraft?.index}`}
                                onClick={() => { copy(currentDraft?.message.content || "", { format: "text/plain" }); }}
                            >
                                <FileCopyOutlinedIcon style={{ fontSize: 19 }} htmlColor="#aaaaaa" />
                            </IconButton>
                            <Button
                                disabled={!currentDraft}
                                id={`smart-content-draft-confirm-${currentDraft?.index}`}
                                color="primary"
                                onClick={() => onApplyDraft()}
                            >
                                APPLY
                            </Button>
                        </div>
                    </div>
                    <TextField
                        placeholder="Edit any text on this suggested draft"
                        style={{
                            minHeight: "calc(100% - 145px)"
                        }}
                        fullWidth
                        label="Edit Draft"
                        multiline
                        variant="outlined"
                        value={currentDraft?.message.content}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChangeDraft(event.currentTarget.value)}
                    />
                </>}
        </SmartContentListPage>
    );
}

export { SmartContentDraftPage };
