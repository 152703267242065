import React, { useCallback, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import MultiSelect from "modules/common/components/forms/inputs/multiselect";
import { Tag } from "modules/tenant/models";
import InlineWarning from "modules/common/components/forms/messages/InlineWarning";

import { Icons } from "modules/common/icons";
import { FormattedMessage, FormattedNumber } from "react-intl";

import { ValueLabel } from "modules/common/components/selectFilter";

import { TagStats } from "modules/insights";
import { insightsApi } from "api/instances";
import { TenantTagGroupsSelector } from "modules/common/commonSelectors";
import InlineError from "modules/common/components/forms/messages/InlineError";
import { TenantSettingsTag } from "modules/settings";

interface ComponentProps {
    value: Tag[] | undefined;
    onChange: (value: { id: string }[]) => any;
    errorMessage?: string;
    showError?: boolean;
}

interface OptionGroup {
    label: string;
    options: Option[]
}
interface Option {
    value: string;
    label: string;
    color: React.CSSProperties;
    restricted: boolean;
}

export default function TopicsSelect(props: ComponentProps) {
    const [tagStats, setTagStats] = useState([] as TagStats[]);
    const [fetchingTagStats, setFetchingTagStats] = useState(false);
    const [isPublicPrivateTagMixed, setIsPublicPrivateTagMixed] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([] as Option[]);
    const [options, setOptions] = useState([] as Option[]);
    const [tags, setTags] = useState([] as TenantSettingsTag[]);
    const [groupOptions, setGroupOptions] = useState([] as OptionGroup[]);
    const tenantTagGroups = useSelector(TenantTagGroupsSelector);

    const setValue = useCallback((value: Tag[] | undefined) => {
        const values = (value || ([] as Tag[])).map((i) => i.id);
        const opts = options.filter((o) => values.some((v) => v === o.value));
        setSelectedOptions(opts);
        setIsPublicPrivateTagMixed(hasPublicAndPrivateOptions(opts || []));
    }, [options]);

    useEffect(() => {
        setFetchingTagStats(true);

        insightsApi
            .GetTagStats()
            .then((response) => {
                setTagStats(Array.isArray(response) ? response : []);
                setFetchingTagStats(false);
            })
            .catch(() => {
                setTagStats([]);
                setFetchingTagStats(false);
            });

        const availableTags = tenantTagGroups
            ?.filter((t) => !t.disabled && !t.deleted)
            .map((tagGroup) => {
                return tagGroup.tags.filter((t) => !t.disabled && !t.deleted) as TenantSettingsTag[];
            })
            .flat();

        setTags(availableTags);
        setOptions(availableTags.map((i) => ({ value: i.id, label: i.name, restricted: i.restricted } as Option)));

        setGroupOptions(tenantTagGroups.map((i) =>( {
            label: i.name, options: updateTagsInGroup(i.tags)
        } as OptionGroup )));


    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setValue(props.value);
    }, [props.value]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (options && options.length > 0) {
            setValue(props.value);
        }
    }, [options, props.value, setValue]);

    const updateTagsInGroup = (tags : TenantSettingsTag[]) => {
        setSelectedOptions(tags.map((i) => ({value: i.id, label: i.name, restricted: i.restricted} as Option)));
        return tags.map((i) => ({value: i.id, label: i.name, restricted: i.restricted} as Option));
    }

    const selectStyles = {
        multiValue: (styles, { data }) => {
            if (data.restricted) {
                return {
                    ...styles,
                    backgroundColor: "rgba(255,166,0,0.2)",
                    border: "1px solid orange",
                    color: "brown",
                };
            } else {
                return {
                    ...styles,
                    backgroundColor: "rgba(242,249,252,0.2)",
                    border: "1px solid rgba(201,230,242)",
                    color: "rgb(3,169,244)",
                };
            }
        },
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            fontSize: "14px",
        }),
    };

    const TagsWarning = (
        <InlineWarning props={{ style: { marginBottom: "10px" } }}>
            <FormattedMessage
                id="posts.warningMixedTopics"
                defaultMessage="Warning: You have mixed a Restricted and Non-Restricted topic. This may make the content available to unintended users."
            />
        </InlineWarning>
    );

    // const isMandatoryTag = (option: Option) => {
    //     return (tagStats.find((t) => t.id === option.value) || { isMandatory: false }).isMandatory;
    // };

    const getSubscriberCountForOption = (option: Option) => {
        let stats = tagStats || ([] as string[]);
        if (stats.length === 0) return <FormattedNumber value={0} />;

        let count = (tagStats.find((t) => t.id === option.value) || { subscriberCount: 0 }).subscriberCount;

        if (!count) {
            return <FormattedNumber value={0} />;
        }

        return <FormattedNumber value={count} />;
    };

    const renderOption = (option: Option) => {
        let count = getSubscriberCountForOption(option);
        return (
            <span>
                <span>{option.label}</span>
                {option.restricted ? (
                    <span style={{ float: "right", marginLeft: "8px", color: "brown" }}>
                        ({count}
                        <Icons.Lock color="brown" width="16" height="16" style={{ transform: "translateY(3px)" }} />)
                    </span>
                ) : (
                    <span style={{ float: "right", marginLeft: "8px", color: "rgb(3,169,244)" }}>
                        ({count}
                        <Icons.User color="rgb(3,169,244)" width="16" height="16" style={{ transform: "translateY(3px)" }} />)
                    </span>
                )}
            </span>
        );
    };

    const hasPublicAndPrivateOptions = (options: Option[]): boolean =>
        options && options?.some((t) => t?.restricted) && options?.some((t) => !t?.restricted);

    const onChange = (newValues: ValueLabel[]) => {
        let convertValuesToTags = tags!
            .filter((t) => {
                return (newValues || []).map((i) => i.value).find((i) => i === t.id);
            })
            .map((t) => ({ id: t.id }));

        props.onChange(convertValuesToTags);
    };

    return (
        <div>
            <label className={"tags-label " + (props.showError && props.errorMessage ? "has-error" : null)}>
                <FormattedMessage id="common.topics" />
            </label>
            {isPublicPrivateTagMixed && TagsWarning}
            <MultiSelect
                placeholder={fetchingTagStats ? "Loading ..." : "Select..."}
                options={groupOptions}
                value={selectedOptions}
                onChange={onChange}
                styles={selectStyles}
                formatOptionLabel={renderOption}
                disabled={fetchingTagStats}
            />
            {props.showError && props.errorMessage && <InlineError>{props.errorMessage}</InlineError>}
        </div>
    );
}
