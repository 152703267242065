import * as React from "react";
import LoadingSpinner from "modules/common/components/loadingSpinner";
import "./modal.sass";

export interface ModalButton {
    title: string;
    isWorking?: boolean;
    workingTitle?: string;
    isPrimary: boolean;
    isEnabled: boolean;
    onClick: () => any;
    className?: string;
}

export interface ModalProps {
    onCloseClick: () => any;
    title: string;
    titleLogo?: string;
    isWorking?: boolean;
    active: boolean;
    buttons?: ModalButton[];
    showControls: boolean;
    hideForNoty?: boolean;
    styles?: any;
}

class Modal extends React.Component<ModalProps, {}> {
    public render() {
        return (
            <div className={"modal" + (this.props.active ? " active" : "")} 
                style={{ zIndex: this.props.hideForNoty ? 0 : 1050 }}>
                <div className="modal-dialog" style={{ maxWidth: this.props.styles?.maxWidth ?? "800px", overflowY: "auto" }}>
                    <div className="modal-dialog-header">
                        <span>
                            {this.props.titleLogo ? (
                                <span style={{ marginRight: "10px" }}>
                                    <img src={this.props.titleLogo} width="25" alt="logo" />
                                </span>
                            ) : null}
                            {this.props.title}
                            {this.props.isWorking ? <LoadingSpinner size="22" /> : null}
                        </span>
                        <button onClick={(e) => this.props.onCloseClick()} className="modal-dialog-close-btn">
                            <span>x</span>
                        </button>
                    </div>
                    <div style={{ overflowY: "auto", height: "100%", padding: "0px 0px 20px 0px" }}>
                        <div className="modal-dialog-content" style={{ marginBottom: this.props.titleLogo ? "0px" : "" }}>
                            {this.props.active ? this.props.children : null}
                        </div>
                        {this.props.showControls ? (
                            <div className="modal-dialog-controls">
                                {this.props.buttons!.map((button) => (
                                    <button
                                        className={"" + (button.isPrimary ? "primary " : "") + (button.className ? button.className : "")}
                                        key={button.title}
                                        onClick={(e) => button.onClick()}
                                        disabled={!button.isEnabled}
                                    >
                                        {button.isWorking ? button.workingTitle || button.title : button.title}
                                        {button.isWorking ? <LoadingSpinner size="12" color="#fff" /> : null}
                                    </button>
                                ))}
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }
}

export default Modal;
