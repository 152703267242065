import * as React from "react";

import TextField from "@mui/material/TextField";


const Description: React.FunctionComponent<ComponentProps> = props => {
  return (
    <div>
      <div>Description</div>
      <TextField
        variant="outlined"
        size="small"
        placeholder="Enter description (optional)"
        value={props.description || ""}
        fullWidth
        inputProps={{
          maxLength: 100
        }}
        onChange={(event) => props.onChange(event.target.value)}
      />
    </div>
  );
}


interface ComponentProps {
  description: string;
  onChange: (description: string) => void;
}

export default Description;