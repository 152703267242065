import * as React from "react";

import AuthoringSearch from "modules/common/components/filters/authoringSearch";
import FilterContainer from "modules/common/components/filters/filterContainer";
import FilterDropdown from "modules/common/components/filters/filterDropdown";

import { UserActivityFilter, SimpleTag, UserActivityLogType} from "../models"

import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";

import moment from "moment";
import _ from "lodash";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { PickerLocalization } from "modules/common/components/pickerLocalization";

import { Grid, TextField, Typography } from "@mui/material";
import { UserActivityType } from "..";

class UserActivitiesFilters extends React.Component<ComponentProps, ComponentState> {
  constructor(props: ComponentProps) {
    super(props);

    this.state = {
      canSearch: false,
      textToSearch: props.filters.textToSearch || ""
    };
  }

  public componentDidUpdate(prevProps: ComponentProps) {
    if (this.props.filters.textToSearch !== prevProps.filters.textToSearch)
      this.setState({ textToSearch: this.props.filters.textToSearch || "" });
  }

  public render() {
    const { filters, userActivityLogType } = this.props;
    return (
      <FilterContainer
        filters={
          <React.Fragment>
            <TextField
          variant="outlined"
          size="small"
          value={this.state.textToSearch}
          placeholder={"Search admin or owner"}
          InputProps={{
            startAdornment: <SearchIcon className="search-icon" />
          }}
          onChange={this.onUpdateTextToSearch}
          onKeyUp={this.onKeyUp}
          className="text-to-search"
        />
            <FilterDropdown text="Activity">
              <List disablePadding>
                {userActivityLogType && userActivityLogType.map((activityEvent) =>
                  <ListItem key={activityEvent.activityType} dense button onClick={() => this.onToggleUserActivityType(activityEvent.activityType as UserActivityType)}>
                    <ListItemIcon className="callout-checkbox">
                      <Checkbox
                        edge="start"
                        tabIndex={-1}
                        disableRipple
                        size="small"
                        color="primary"
                        checked={_.some(filters.activityTypes,(name) => name === activityEvent.activityType)}
                      />
                    </ListItemIcon>
                    <ListItemText primary={activityEvent.activityDescription} />
                  </ListItem>
                )}
              </List>
            </FilterDropdown>
            <FilterDropdown text="Activity Date">
              <PickerLocalization>
                <div className="event-time">
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                  <Typography style={{lineHeight:'42px'}}>From </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <DatePicker
                      format="MMM dd yyyy"
                      value={filters.from ? Date.parse(filters.from) : null}
                      slotProps={{ 
                        textField: { size: "small", placeholder: "", inputProps: { readOnly: true }},
                        openPickerIcon: { color: "primary" }
                      }}
                      onChange={this.onChangeFromDate}
                      className="date-picker"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                  <Typography style={{lineHeight:'42px'}}>To </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <DatePicker
                      format="MMM dd yyyy"
                      value={filters.to ? Date.parse(filters.to) : null}
                      slotProps={{ 
                        textField: { size: "small", placeholder: "", inputProps: { readOnly: true }},
                        openPickerIcon: { color: "primary" }
                      }}
                      onChange={this.onChangeToDate}
                      className="date-picker"
                    />
                  </Grid>
                </Grid>
                </div>
              </PickerLocalization>
            </FilterDropdown>
          </React.Fragment>
        }
        filterCommands={
          <AuthoringSearch
            canSearch={this.state.canSearch}
            onApplyFilters={this.onChangeTextToSearch}
            onClearFilters={this.onClearFilters}
          />
        }
        filterSelection={this.getFilterSelection()}
      />
    );
  }
  
  private getFilterSelection = () => {
    const { filters } = this.props;

    return (
      <React.Fragment>
        {!!filters.textToSearch &&
          <Chip
            key="search-text"
            label={`"${filters.textToSearch}"`}
            onDelete={this.onClearTextToSearch}
            deleteIcon={<CloseIcon />}
          />
        }
        {filters.activityTypes.map((selectedEventName) =>
          <Chip
            key={selectedEventName}
            label={this.getActivityDescription(selectedEventName)}
            onDelete={() => this.onClearActivityType(selectedEventName)}
            deleteIcon={<CloseIcon />}
          />
        )}
        {!!filters.from && <Chip
            label={`From ${moment(filters.from).format("MMM D, YYYY")}`}
            onDelete={this.onClearFromDate}
            deleteIcon={<CloseIcon />}
          /> }
          {!!filters.to && <Chip
              label={`To ${moment(filters.to).format("MMM D, YYYY")}`}
              onDelete={this.onClearToDate}
              deleteIcon={<CloseIcon />}
            /> }
      </React.Fragment>
    );
  }
  
  private getActivityDescription = (selectedActivityType: UserActivityType) => {
    return this.props.userActivityLogType?.find(c => c.activityType === selectedActivityType)?.activityDescription
  }

  private onToggleUserActivityType = (selectedActivityType: UserActivityType) => {
    const isRemoving = _.some(this.props.filters.activityTypes,(e) => e === selectedActivityType);
    if (isRemoving)
      this.onChangeFilters({ activityTypes: _.filter(this.props.filters.activityTypes, e => selectedActivityType !== e) });
    else
      this.onChangeFilters({ activityTypes: _.concat(this.props.filters.activityTypes,[selectedActivityType]) });
  }

  private onChangeFromDate = (date) => {
    this.onChangeFilters({ from: !!date ? date : null });
  }

  private onChangeTextToSearch = () => {
    this.onChangeFilters({ textToSearch: this.state.textToSearch });
  }

  private onChangeToDate = (date) => {
    this.onChangeFilters({ to: !!date ? date : null });
  }

  private onChangeFilters = (value: Partial<UserActivityFilter>) => {
    this.setState({ canSearch: true });
    this.props.onChangeFilters({ ...this.props.filters, textToSearch: this.state.textToSearch, ...value });
  }

  private onClearActivityType = (selectedActivityType: UserActivityType) => {
    this.props.onChangeFilters({...this.props.filters, activityTypes: _.filter(this.props.filters.activityTypes, e => selectedActivityType !== e) });
  }

  private onKeyUp = (key) => {
    if (key.keyCode === 13)
      this.onChangeTextToSearch();
  }

  private onUpdateTextToSearch = (event) => {
    this.setState({ textToSearch: event.target.value });
  }

  private onClearTextToSearch = () => {
    this.props.onChangeFilters({ ...this.props.filters, textToSearch: "" });
  }

  private onClearFromDate = () => {
    this.props.onChangeFilters({...this.props.filters, from: null });
  }

  private onClearToDate = () => {
    this.props.onChangeFilters({...this.props.filters, to: null });
  }

  private onClearFilters = () => {
    this.setState({ canSearch: false });
    this.props.onClearFilters();
  }
}

interface ComponentProps {
  filters: UserActivityFilter;
  activityTypeTags?: SimpleTag[];
  userActivityLogType?: UserActivityLogType[];
  onChangeFilters: (filters: UserActivityFilter) => void;
  onClearFilters: () => void;
}

interface ComponentState {
  canSearch: boolean;
  textToSearch: string;
}

export default UserActivitiesFilters;