import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { push } from "react-router-redux";
import * as actions from "modules/portalPages/actionCreator";
import { GlobalApplicationState } from "globalApplicationState";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import CloseIcon from "@mui/icons-material/Close";


class BackToManagePortalPagesDialog extends React.Component<PropsWithRedux, ComponentState> {
  public render() {
    return (
      <Dialog open={this.props.show} maxWidth={false} onClose={this.onClose}>
        <DialogTitle className="portal-pages-dialog-header">
          <div className="portal-pages-dialog-header-title">
            <Typography variant="h2">Back to Manage Portal Pages</Typography>
            <IconButton onClick={this.onClose} size="large">
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent className="portal-page-dialog-content">
          <div>You are about to leave the page editor.<br /><br />Do you want to continue?</div>
        </DialogContent>
        <DialogActions className="portal-pages-dialog-footer">
          <Button variant="text" onClick={this.onBack}>Discard</Button>
          <Button variant="text" color="primary" onClick={this.onSave}>Save & exit</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={this.onClose}
          >
            Keep editing
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  private onBack = () => {
    this.props.redirectTo("/" + this.props.tenantId + "/admin/portalPages");
    this.onClose();
  }

  private onClose = () => {
    this.props.hideBackToPortalPages();
  }

  private onSave = () => {
    this.props.saveDraft(this.props.draftId, this.props.data).then((succeeded) => {
      if (succeeded)
        this.props.redirectTo("/" + this.props.tenantId + "/admin/portalPages");
    });
    this.onClose();
  }
}


interface ComponentProps {
  draftId: string;
  data: string;
}

interface ComponentState {}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    show: state.portalPages.showBackToPortalPages,
    tenantId: state.tenant.id
  }),
  {
    redirectTo: push,
    saveDraft: actions.saveDraft,
    hideBackToPortalPages: actions.hideBackToPortalPages
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(BackToManagePortalPagesDialog);