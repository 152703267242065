import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";

import { ActivityInsightsDetails } from "modules/posts/models";

import WarningIcon from "@mui/icons-material/Warning";

import "../styles/basic-insights.sass"
import "../styles/graphs.sass"

// TODO: add react-intl where neceessary here post fix

const ActivityInsights: React.FunctionComponent<PropsWithRedux> = (props) => {

    const options = { year: "numeric", month: "short", day: "numeric" };

    const renderCommentContent = (comment: any, index: number): JSX.Element => {
        return(
            <div key={index} className="comment-wrapper">
                <div className="author-avatar" style={{ backgroundColor: `${comment.avatarColor}` }}>
                    {comment.firstName ? comment.firstName[0] : comment.commentAuthor ? comment.commentAuthor[0] : ""}
                </div>
                <div style={{flexGrow: 1}}>
                   <div className="header">
                        <div className="comment-contents">
                            <span className="name-label">{comment.firstName !== undefined ? `${comment.firstName} ${comment.lastName}` : comment.commentAuthor}</span>
                            <span className="date-label">{new Date(comment.createdTime?.toString()).toLocaleDateString(undefined, options as any)}</span>
                        </div>
                        {comment.upvoteCount !== undefined ?
                                comment.upvoteCount > 0 &&
                                <span className="data-label">{`${comment.upvoteCount} like${comment.upvoteCount > 1 ? "s": ""}`}</span>
                            : <span className="data-label">{`${comment.totalFlagActions} flag${comment.totalFlagActions > 1 ? "s": ""}`}</span>
                        }
                    </div> 
                    <div className="body">
                        {comment.commentBody}
                    </div>
                </div>
            </div>
        )
    }

    const calculatePercent = (firstVal: number, secondVal: number) => {
        let result = (firstVal/secondVal)*100;
        if (result < 0.5) {
            return Math.ceil(result)
        }
        return Math.round(result)
    }

    const availableReactions = props.reactions.filter(r => r.enabled 
        || (props.activityInsights?.reactionCounts != null && props.activityInsights.reactionCounts.find(c => c.reactionId === r.id) != null));
    const uniqueViewersWhoReacted = calculatePercent(props.activityInsights?.totalUniqueReactors,props.totalUniqueOpens)
    const uniqueViewersWhoCommented = calculatePercent(props.activityInsights?.totalUniqueCommentors,props.totalUniqueOpens)


    return(
        <React.Fragment>
            {
                (!props.showComments || !props.showReactions) &&
                <div className="graphs-disclaimer">{`*${props.showComments ? "" : "Comments"}${!props.showComments && !props.showReactions ? " and " : ""}${props.showReactions? "" : "Reactions"} were disabled`}</div>
            }
            {
                props.showReactions &&
                <div className="activity-insights-wrapper">
                    <div className="metric-title">
                        {`Reactions: ${props.activityInsights?.totalReactions}`}
                    </div>
                    <div className="activity-insights-row">
                        <div className="number-wrapper activity-widget">
                            <div className="number">
                                {`${uniqueViewersWhoReacted !== Infinity && isNaN(uniqueViewersWhoReacted) === false ? uniqueViewersWhoReacted : 0}%`}
                            </div>
                            <span className="title">
                                of unique viewers reacted
                            </span>
                            <span className="description">
                                {`${props.activityInsights?.totalUniqueReactors ?? 0} of ${props.totalUniqueOpens}`}
                            </span>
                        </div>  
                        <div className="stats-row" style={{justifyContent: "normal", flexGrow: 1, marginLeft: "10px"}}>
                            {
                                availableReactions.map(r => {
                                    var count: null | number = null;
                                    if (props.activityInsights?.reactionCounts.length > 0) {
                                        count = props.activityInsights?.reactionCounts.find(rc => rc.reactionId === r.id)?.count!
                                    }
                                    return (
                                        <div key={r.sentiment}>
                                            <div  className="number-wrapper reaction-widget">
                                                    <img src={`/images/reactions/${r.sentiment}_reaction.png`}
                                                                    alt={r.sentiment}
                                                                    style={{ height: "32px", maxWidth: "40px" }} />
                                                <div className="title" style={{width: "18px"}}>
                                                    {count ? count : 0}
                                                </div>        
                                            </div>
                                            <div className="number-wrapper percent">
                                                <span className="title">
                                                    {`${ count && count > 0 ? (count/(props.activityInsights?.totalReactions ?? 0) * 100).toFixed(1) : 0}%`}
                                                </span>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            }
            {
                props.showComments &&
                <div className="activity-insights-wrapper">
                    <div className="metric-title">
                        {`Comments: ${props.totalComments}`}
                    </div>
                    <div className="activity-insights-row">
                        <div className="number-wrapper activity-widget" style={{marginBottom: 0}}>
                            <div className="number">
                                {`${uniqueViewersWhoCommented !== Infinity && isNaN(uniqueViewersWhoCommented) === false ? uniqueViewersWhoCommented : 0}%`}
                            </div>
                            <span className="title">
                                of unique viewers left a comment
                            </span>
                            <span className="description">
                                {`${props.activityInsights?.totalUniqueCommentors} of ${props.totalUniqueOpens}`}
                            </span>
                        </div>  
                        <div className="comment-content">
                            {
                                props.activityInsights?.topComments.length > 0 &&
                                <span className="comment-heading">Top Comments</span>
                            }
                            {/* take top 3 comments */}
                            {props.activityInsights?.topComments.slice(0, 5).map((c, index) =>  renderCommentContent(c, index))}
                        </div>
                    </div>
                </div>
            }
            {
                <div className="activity-insights-wrapper">
                    <div className="metric-title" style={{display: "flex"}}>
                        {`Flagged Items: ${props.activityInsights?.totalFlaggedItems}`}
                        {
                            props.activityInsights?.totalFlaggedItems > 0 &&
                            <span style={{marginLeft: "10px"}}>
                                <WarningIcon style={{ color: "#b72026"}}/>
                            </span>
                        }
                    </div>
                    <div className="activity-insights-row">
                        <div className="number-wrapper activity-widget" style={{marginBottom: 0}}> 
                            <div className="number">
                                {props.activityInsights?.totalFlaggedItems}
                            </div>
                            <span className="title">
                                total flags found
                            </span>
                        </div>  
                        <div className="comment-content">
                            {
                                props.activityInsights?.totalFlaggedItems > 0 &&
                                <span className="comment-heading">Flagged Items</span>
                            }
                            {props.activityInsights?.flaggedItems.map((c, index) =>  renderCommentContent(c, index))}
                        </div>
                    </div>
                </div>
            }
        </React.Fragment>
    )
}

interface ComponentProps {
    totalReactions: number
    totalComments: number
    activityInsights: ActivityInsightsDetails
    showComments: boolean
    showReactions: boolean
    totalUniqueOpens: number
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        reactions: state.settings.tenantSettings.reactions
    })
);

type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(ActivityInsights);