import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";

import { BannerBlock } from "./components/banner";
import { NavigationBlock } from "./components/navigation";
import { OneColumnBlock, TwoColumnsBlock, ThreeColumnsBlock } from "./components/section";
import { DialogPostView } from "modules/posts/components/post-view/dialogPostView";
import { validGuidRegex } from "utils/regexUserValidation";


const Page: React.FunctionComponent<PropsWithRedux> = props => {
    const { data } = props;
    const [selectedPostId, setSelectedPostId] = useState("");

    //Gets the post ID from the URL.
    useEffect(() => {
        let lastIndex = window.location.href.split("/").pop();
        //Make sure we are not editing a page by checking if admin/portPages
        let isEditingPage = window.location.href.includes("admin/portalPages");
        if (!isEditingPage) {
            setSelectedPostId(lastIndex && lastIndex.match(validGuidRegex) ? lastIndex : "")
        }
    }, [])

    return (
        <div className="portal-page">
            {data.navigation && data.navigation.enabled &&
                <NavigationBlock key="navigation" data={data.navigation} isInEditMode={props.isInEditMode} />
            }
            {data.header && data.header.enabled &&
                <div className="header" style={{ marginTop: "0px", marginBottom: "0px" }}>
                    <BannerBlock key="header" data={data.header} />
                </div>
            }
            {data.page_sections &&
                data.page_sections.map((page_section: any, index: number) => {
                    switch (page_section._template) {
                        case "one_column":
                            return <OneColumnBlock key={index} data={page_section} />;
                        case "two_column":
                            return <TwoColumnsBlock key={index} data={page_section} />;
                        case "three_column":
                            return <ThreeColumnsBlock key={index} data={page_section} />;
                        default:
                            return <React.Fragment></React.Fragment>;
                    }
                })}
            <DialogPostView
                isPublic
                onClose={() => { setSelectedPostId("") }}
                postId={selectedPostId}
                open={!!selectedPostId} />
        </div>
    );
}

interface ComponentProps {
    data: any;
    isInEditMode?: boolean;
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps
    })
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(Page);
