import React from "react";

import ErrorSnackbar from "modules/common/components/snackbars/errorSnackbar";
import LoadingOverlay from "modules/common/components/loadingOverlay";

import { AudienceDetails, AudienceGroup, AudienceType, AudienceUser, DraftAudience, RuleApplicability, Rule, AudienceInvite, RuleComparatorOptions } from "../../models";
import { TenantAttribute } from "modules/settings/models";

import Description from "./components/description";
import EditOptions from "./components/editOptions";
import Enabled from "./components/enabled";
import SmartRules from "./components/smartRules";
import StandardRules from "./components/standardRules";
import Title from "./components/title";
import TotalUsers from "./components/totalUsers";
import Type from "./components/type";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";

import CloseIcon from "@mui/icons-material/Close";


import "../../styles/audienceCreation.sass";


class AudienceCreation extends React.Component<ComponentProps, ComponentState> {
  constructor(props: ComponentProps) {
    super(props);

    this.state = {
      draftAudience: !!props.audienceDetails ? this.setAudience(props.audienceDetails) : { ...this.getDefaultAudience() },
      errorMessage: "",
      isUpdating: false
    };
  }

  public componentDidUpdate(prevProps: ComponentProps) {
    if (this.props.show && !prevProps.show)
      this.setState({
        draftAudience: !!this.props.audienceDetails ? this.setAudience(this.props.audienceDetails) : { ...this.getDefaultAudience() },
        errorMessage: "",
        isUpdating: false
      });
  }

  public render() {
    const { draftAudience } = this.state;
    const id: string = !!this.props.audienceDetails ? this.props.audienceDetails.id : "";

    return (
      <Dialog open={this.props.show} maxWidth={false} scroll="paper" onClose={this.onClose} classes={{ paper: "audience-dialog" }}>
        <DialogTitle className="audience-dialog-title">
          <div>
            {!!id
              ? <span>Edit audience</span>
              : <span>Add new audience</span>
            }
          </div>
          <div className="audience-dialog-actions">
            <EditOptions
              id={id}
              onDelete={this.onDelete}
              onDownloadUserData={this.onDownloadUserData}
            />
            <IconButton onClick={this.onClose} size="large">
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent className="audience-dialog-content" style={{paddingBottom: "8px"}}>
          <div className="audience-details">
            <Title title={draftAudience.title} onChange={this.onChangeTitle} />
            <Description description={draftAudience.description} onChange={this.onChangeDescription} />
            <TotalUsers id={id} totalUsers={!!this.props.audienceDetails ? this.props.audienceDetails.totalUsers : 0} syncType={this.props.syncType}/>
            <Enabled isEnabled={draftAudience.isEnabled} onChange={this.onChangeEnabled} />
            <Type id={id} audienceType={draftAudience.audienceType} tenantAttributes={this.props.tenantAttributes} onChange={this.onChangeType} />
          </div>
          <Divider light />
          {draftAudience.audienceType === "standard" &&
            <StandardRules
              audienceId={!!this.props.audienceDetails ? this.props.audienceDetails.id : ""}
              emails={draftAudience.emails}
              includedGroups={draftAudience.groups}
              includedUsers={draftAudience.users}
              includedInvites={draftAudience.invites}
              totalIndividualUsers={!!this.props.audienceDetails ? this.props.audienceDetails.totalIndividualUsers : draftAudience.users.length}
              totalIndividualInvites={!!this.props.audienceDetails ? this.props.audienceDetails.totalInvites: draftAudience.invites.length}
              onChangeGroups={this.onChangeGroups}
              onChangeEmails={this.onChangeEmails}
              onChangeUsers={this.onChangeUsers}
              onChangeInvites={this.onChangeInvites}
              syncType={this.props.syncType}
            />
          }
          {draftAudience.audienceType === "smart" &&
            <SmartRules
              ruleApplicability={draftAudience.ruleApplicability}
              equalToAnyRuleEnabled={this.props.equalToAnyRuleEnabled}
              rules={draftAudience.rules}
              tenantAttributes={this.props.tenantAttributes}
              onChangeRuleApplicability={this.onChangeRuleApplicability}
              onChangeRules={this.onChangeRules}
            />
          }
        </DialogContent>
        <DialogActions className="audience-dialog-footer">
          <Button variant="text" onClick={this.onClose}>Cancel</Button>
          <Button
            variant="contained"
            color="primary"
            disabled={
              !draftAudience.title ||
              (draftAudience.audienceType === "smart" && (!draftAudience.rules.length || !draftAudience.rules[0].key || !draftAudience.rules[0].value))
            }
            onClick={this.onSave}
          >
            {!!this.props.audienceDetails && !!this.props.audienceDetails.id
              ? "Save changes"
              : "Finish"
            }
          </Button>
        </DialogActions>
        <LoadingOverlay show={this.state.isUpdating} absolute={true} />
        <ErrorSnackbar errorMessage={this.state.errorMessage} clearErrorMessage={this.clearErrorMessage} />
      </Dialog>
    );
  }

  private clearErrorMessage = () => {
    this.setState({ errorMessage: "" });
  }

  private getDefaultAudience = (): DraftAudience => {
    return {
      audienceType: "standard",
      description: "",
      emails: [],
      groups: [],
      isEnabled: true,
      ruleApplicability: "all",
      rules: [],
      title: "",
      users: [],
      invites: []
    };
  }

  private setAudience = (audienceDetails: AudienceDetails): DraftAudience => {
    return {
      audienceType: audienceDetails.audienceType,
      description: audienceDetails.description,
      emails: [],
      groups: audienceDetails.includedGroups,
      isEnabled: audienceDetails.isEnabled,
      ruleApplicability: audienceDetails.ruleApplicability,
      rules: audienceDetails.rules,
      title: audienceDetails.title,
      users: audienceDetails.includedUsers,
      invites: audienceDetails.includedInvites
    };
  }

  private onClose = () => {
    this.setState({ isUpdating: false });
    this.props.onClose();
  }

  private onChangeDescription = (description: string) => {
    this.setState({ draftAudience: { ...this.state.draftAudience, description }});
  }

  private onChangeEmails = (emails: string[]) => {
    this.setState({ draftAudience: { ...this.state.draftAudience, emails }});
  }

  private onChangeEnabled = (isEnabled: boolean) => {
    this.setState({ draftAudience: { ...this.state.draftAudience, isEnabled }});
  }

  private onChangeGroups = (groups: AudienceGroup[]) => {
    this.setState({ draftAudience: { ...this.state.draftAudience, groups }});
  }

  private onChangeRuleApplicability = (ruleApplicability: RuleApplicability) => {
    this.setState({ draftAudience: { ...this.state.draftAudience, ruleApplicability }});
  }

  private onChangeRules = (rules: Rule[]) => {
    this.setState({ draftAudience: { ...this.state.draftAudience, rules }});
  }

  private onChangeTitle = (title: string) => {
    this.setState({ draftAudience: { ...this.state.draftAudience, title }});
  }

  private onChangeType = (audienceType: AudienceType) => {
    this.setState({ draftAudience: { ...this.state.draftAudience, audienceType }});
  }

  private onChangeUsers = (users: AudienceUser[]) => {
    this.setState({ draftAudience: { ...this.state.draftAudience, users }});
  }

  private onChangeInvites = (invites: AudienceInvite[]) => {
    this.setState({ draftAudience: { ...this.state.draftAudience, invites}})
  }

  private onDelete = () => {
    this.setState({ isUpdating: true });
    this.props.onDeleteAudience(this.props.audienceDetails!.id).then((succeeded) => {
      if (succeeded)
        this.onClose();
      else
        this.setState({ isUpdating: false });
    });
  }

  private onDownloadUserData = () => {
    this.setState({ isUpdating: true });
    this.props.onDownloadUserData(this.props.audienceDetails!.id, this.props.audienceDetails!.title).then(() => {
      this.setState({ isUpdating: false });
    });
  }

  private onSave = () => {
    this.setState({ isUpdating: true });

    this.checkAndUpdateRules();

    if (!!this.props.audienceDetails && !!this.props.audienceDetails.id)
      this.props.onSaveAudience(this.props.audienceDetails.id, this.state.draftAudience).then((response) => {
        if (!!response)
          this.onClose();
      });
    else
      this.props.onCreateAudience(this.state.draftAudience).then((response) => {
        if (!!response)
          this.onClose();
      });
  }

  //Doesn't delete the value on equalToAny, just adds a proper one on the end.
  private checkAndUpdateRules = () => {
    var audienceCopy = this.state.draftAudience;

    audienceCopy.rules.forEach(rule => {
      if(rule.comparator === RuleComparatorOptions.equalToAny) {
        var splitValues = rule.value.split("|").map(s => s.trim()).filter(s => s !== "");
        rule.valueCollection = splitValues;
      }
    });

    this.setState({draftAudience: audienceCopy});
  }
}

interface ComponentProps {
  show: boolean;
  audienceDetails?: AudienceDetails;
  tenant: string;
  tenantAttributes: TenantAttribute[];
  syncType: string;
  equalToAnyRuleEnabled?: boolean;
  onCreateAudience: (audience: DraftAudience) => Promise<boolean>;
  onDeleteAudience: (id: string) => Promise<boolean>;
  onDownloadUserData: (id: string, title: string) => Promise<void>;
  onSaveAudience: (id: string, audience: DraftAudience) => Promise<boolean>;
  onClose: () => void;
}

interface ComponentState {
  draftAudience: DraftAudience;
  errorMessage: string;
  isUpdating: boolean;
}

export default AudienceCreation;
