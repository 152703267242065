import React from "react";
import { Close, EditNote } from "@mui/icons-material";
import { Backdrop, Button, IconButton } from "@mui/material";

import "./mobileWarningOverlay.sass";

export interface IMobileWarningOverlayProps {
    open: boolean;
    message: string;
    actionMessage: string;
    backMessage: string;
    onActionClick: () => void;
    onClose: () => void;
    onBack: () => void;
}

export const MobileWarningOverlay: React.FunctionComponent<IMobileWarningOverlayProps> = ({
    open,
    message,
    backMessage,
    actionMessage,
    onActionClick,
    onBack,
    onClose,
}) => {
    return (
        <Backdrop
            open={open}
            id="mobile-overlay"
            sx={{
                zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
        >
            <IconButton
                id="mobile-overlay-close"
                onClick={onClose}
            >
                <Close id="close-icon" htmlColor="#ffffff" />
            </IconButton>
            <div
                className="content-container"
            >
                <EditNote id="edit-icon" />
                <div className="content">
                    {message}
                </div>
            </div>
            <Button
                id="mobile-warning-overlay-action"
                onClick={onActionClick}
                variant="text"
            >
                {actionMessage}
            </Button>
            <Button
                id="mobile-warning-overlay-back"
                variant="text"
                onClick={onBack}
            >
                {backMessage}
            </Button>
        </Backdrop>
    )
}
