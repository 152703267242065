import * as React from "react";

import Checkbox from "@mui/material/Checkbox";
import Collapse from "@mui/material/Collapse";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";

import LinkIcon from "@mui/icons-material/Link";
import PublicIcon from '@mui/icons-material/Public';
import LocationOnIcon from "@mui/icons-material/LocationOn";


class Location extends React.Component<ComponentProps, ComponentState> {
  constructor(props: ComponentProps) {
    super(props);

    this.state = {
      showLocation: !!props.location,
      showLocationLink: !!props.locationLink || !!props.locationLinkLabel
    };
  }

  public render() {
    return (
      <div className="location">
        <div className="location-options">
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.showLocation}
                color="primary"
                onChange={this.onToggleLocation}
              />
            }
            label="In-Person"
            className="location-option"
          />
          <FormControlLabel
            control={
              
              <Checkbox
                checked={this.state.showLocationLink}
                color="primary"
                onChange={this.onToggleLocationLink}
              />
            }
            label="Online"
            className="location-option"
          />
        </div>
        <Collapse in={this.state.showLocation}>
          <div className="location-field">
            <LocationOnIcon color="primary" className="location-icon" />
            <TextField
              key={this.props.location}
              variant="outlined"
              size="small"
              placeholder="Enter address"
              defaultValue={this.props.location}
              fullWidth
              onBlur={this.onChangeLocation}
            />
          </div>
        </Collapse>
        <Collapse in={this.state.showLocationLink}>
        <div className="location-field">
            <PublicIcon color="primary" className="location-icon" />
            <TextField
              key={this.props.locationLinkLabel}
              variant="outlined"
              size="small"
              placeholder="Enter online location (Ex. MS Teams)"
              defaultValue={this.props.locationLinkLabel}
              fullWidth
              onBlur={this.onChangeLocationLinkLabel}
            />
          </div>
          <div className="location-field">
            <LinkIcon color="primary" className="location-icon" />
            <TextField
              key={this.props.locationLink}
              variant="outlined"
              size="small"
              placeholder="Enter online URL"
              defaultValue={this.props.locationLink}
              fullWidth
              onBlur={this.onChangeLocationLink}
            />
          </div>
        </Collapse>
      </div>
    );
  }

  private onChangeLocation = (event) => {
    this.props.onChangeLocation(event.target.value);
  }

  private onChangeLocationLink = (event) => {
    this.props.onChangeLocationLink(event.target.value);
  }

  private onChangeLocationLinkLabel = (event) => {
    this.props.onChangeLocationLinkLabel(event.target.value);
  }

  private onToggleLocation = (event, checked: boolean) => {
    this.setState({ ...this.state, showLocation: checked });
    if (!checked)
      this.props.onChangeLocation("");
  }

  private onToggleLocationLink = (event, checked: boolean) => {
    this.setState({ ...this.state, showLocationLink: checked });
    if (!checked)
    {
      this.props.clearLocationLink();
    }
  }
}

interface ComponentProps {
  location: string | undefined;
  locationLink: string | undefined;
  locationLinkLabel: string | undefined;
  onChangeLocation: (location: string) => void;
  onChangeLocationLink: (locationLink: string) => void;
  onChangeLocationLinkLabel: (locationLink: string) => void;
  clearLocationLink: () => void;
}

interface ComponentState {
  showLocation: boolean;
  showLocationLink: boolean;
}

export default Location;