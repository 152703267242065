import { Action } from "redux";
import * as Actions from "./actions";
import { DocumentFeed, DocumentFeedFilters, DocumentsListingPage, DocumentsState } from "./models";

export const defaultDocumentFeed: DocumentFeed = {
  currentPage: 0,
  documents: [],
  totalDocuments: 0,
  totalPages: 0
};

export const defaultDocumentFeedFilters: DocumentFeedFilters = {
  availabilities: [],
  lcids: [],
  sort: "titleAscending",
  tags: [],
  textToSearch: "",
  view: "all"
};

export const defaultListingPage: DocumentsListingPage = {
  id: "",
  currentPage: 0,
  documents: [],
  totalDocuments: 0,
  totalPages: 0,
  isFetching: false
};

const defaultState: DocumentsState = {
  all: { ...defaultListingPage, id: "all" },
  enabled: { ...defaultListingPage, id: "enabled" },
  disabled: { ...defaultListingPage, id: "disabled" },
  scheduled: { ...defaultListingPage, id: "scheduled" },
  documentFeed: {
    documentFeed: { ...defaultDocumentFeed },
    filters: { ...defaultDocumentFeedFilters },
    filtersApplied: false,
    isFetching: false,
    layout: "list",
    showFilters: false,
    shouldFetch: true
  },
  editableDocument: undefined,
  preview: undefined,
  changedSinceSaved: false,
  errorMessage: "",
  isDeleting: false,
  isFetching: false,
  isInitialLoad: true,
  isSaving: false,
  isUploading: false,
  shouldFetch: false,
  shouldUpdateDraft: false,
  showEditor: false,
  showPreview: false,
  successMessage: ""
};

const actionHandler: { [actionType: string]: (state: DocumentsState, action: any) => DocumentsState } = {

  [Actions.GET_ATTACHED_ARTICLES]: (state: DocumentsState, action: Actions.GetAttachedArticles) => {
    return {
      ...state,
      isFetching: true
    };
  },

  [Actions.GET_ATTACHED_ARTICLES_COMPLETE]: (state: DocumentsState, action: Actions.GetAttachedArticlesComplete) => {
    return {
      ...state,
      errorMessage: action.succeeded ? "" : "Unable to check where document is used as an attachment",
      isFetching: false
    };
  },
  
  [Actions.GET_DOCUMENT_FEED]: (state: DocumentsState, action: Actions.GetDocumentFeed) => {
    return {
      ...state,
      documentFeed: {
        ...state.documentFeed,
        filters: action.filters,
        filtersApplied: !!action.hasFiltersApplied,
        isFetching: true,
        shouldFetch: false
      }
    };
  },

  [Actions.GET_DOCUMENT_FEED_COMPLETE]: (state: DocumentsState, action: Actions.GetDocumentFeedComplete) => {
    return {
      ...state,
      documentFeed: {
        ...state.documentFeed,
        documentFeed: {
          ...action.documentFeed,
          documents: state.documentFeed.documentFeed.documents.concat(action.documentFeed.documents)
        },
        isFetching: false,
        shouldFetch: !action.succeeded
      }
    };
  },

  [Actions.GET_DOCUMENT_DETAILS]: (state: DocumentsState, action: Actions.GetDocumentDetails) => {
    return {
      ...state,
      isFetching: true
    };
  },

  [Actions.GET_DOCUMENT_DETAILS_COMPLETE]: (state: DocumentsState, action: Actions.GetDocumentDetailsComplete) => {
    return {
      ...state,
      isFetching: false
    };
  },

  [Actions.GET_DOCUMENTS]: (state: DocumentsState, action: Actions.GetDocuments) => {
    return {
      ...state,
      all: {
        ...state.all,
        isFetching: true
      }
    };
  },

  [Actions.GET_DOCUMENTS_COMPLETE]: (state: DocumentsState, action: Actions.GetDocumentsComplete) => {
    return {
      ...state,
      all: {
        ...state.all,
        ...action.page,
        isFetching: false
      },
      isInitialLoad: false
    };
  },

  [Actions.GET_DISABLED_DOCUMENTS]: (state: DocumentsState, action: Actions.GetDisabledDocuments) => {
    return {
      ...state,
      disabled: {
        ...state.disabled,
        isFetching: true
      }
    };
  },

  [Actions.GET_DISABLED_DOCUMENTS_COMPLETE]: (state: DocumentsState, action: Actions.GetDisabledDocumentsComplete) => {
    return {
      ...state,
      disabled: {
        ...state.disabled,
        ...action.page,
        isFetching: false
      },
      isInitialLoad: false
    };
  },

  [Actions.GET_ENABLED_DOCUMENTS]: (state: DocumentsState, action: Actions.GetEnabledDocuments) => {
    return {
      ...state,
      enabled: {
        ...state.enabled,
        isFetching: true
      }
    };
  },

  [Actions.GET_ENABLED_DOCUMENTS_COMPLETE]: (state: DocumentsState, action: Actions.GetEnabledDocumentsComplete) => {
    return {
      ...state,
      enabled: {
        ...state.enabled,
        ...action.page,
        isFetching: false
      },
      isInitialLoad: false
    };
  },

  [Actions.GET_SCHEDULED_DOCUMENTS]: (state: DocumentsState, action: Actions.GetScheduledDocuments) => {
    return {
      ...state,
      scheduled: {
        ...state.scheduled,
        isFetching: true
      }
    };
  },

  [Actions.GET_SCHEDULED_DOCUMENTS_COMPLETE]: (state: DocumentsState, action: Actions.GetScheduledDocumentsComplete) => {
    return {
      ...state,
      scheduled: {
        ...state.scheduled,
        ...action.page,
        isFetching: false
      },
      isInitialLoad: false
    };
  },


  [Actions.HIDE_DOCUMENT_FEED_FILTERS]: (state: DocumentsState, action: Actions.HideDocumentFeedFilters) => {
    return {
      ...state,
      documentFeed: {
        ...state.documentFeed,
        showFilters: false
      }
    };
  },

  [Actions.HIDE_EDITOR]: (state: DocumentsState, action: Actions.HideEditor) => {
    return {
      ...state,
      changedSinceSaved: false,
      showEditor: false
    };
  },

  [Actions.HIDE_PREVIEW]: (state: DocumentsState, action: Actions.HidePreview) => {
    return {
      ...state,
      preview: undefined,
      showPreview: false
    };
  },

  [Actions.SHOW_DOCUMENT_FEED_FILTERS]: (state: DocumentsState, action: Actions.ShowDocumentFeedFilters) => {
    return {
      ...state,
      documentFeed: {
        ...state.documentFeed,
        showFilters: true
      }
    };
  },

  [Actions.SHOW_EDITOR]: (state: DocumentsState, action: Actions.ShowEditor) => {
    return {
      ...state,
      changedSinceSaved: false,
      editableDocument: action.document,
      showEditor: true
    };
  },

  [Actions.SHOW_PREVIEW]: (state: DocumentsState, action: Actions.ShowPreview) => {
    return {
      ...state,
      preview: action.document,
      showPreview: true
    };
  },


  [Actions.SET_DOCUMENT_FEED_FILTERS]: (state: DocumentsState, action: Actions.SetDocumentFeedFilters) => {
    return {
      ...state,
      documentFeed: {
        ...state.documentFeed,
        filters: action.filters
      }
    };
  },

  [Actions.SET_DOCUMENT_FEED_LAYOUT]: (state: DocumentsState, action: Actions.SetDocumentFeedLayout) => {
    return {
      ...state,
      documentFeed: {
        ...state.documentFeed,
        layout: action.layout
      }
    };
  },

  
  [Actions.DELETE_DOCUMENT]: (state: DocumentsState, action: Actions.DeleteDocument) => {
    return {
      ...state,
      isDeleting: true
    };
  },
  
  [Actions.DELETE_DOCUMENT_COMPLETE]: (state: DocumentsState, action: Actions.DeleteDocumentComplete) => {
    return {
      ...state,
      errorMessage: action.succeeded ? "" : "Unable to delete document",
      isDeleting: false,
      shouldFetch: action.succeeded
    };
  },

  [Actions.DELETE_DOCUMENTS]: (state: DocumentsState, action: Actions.DeleteDocuments) => {
    return {
      ...state,
      isDeleting: true
    };
  },

  [Actions.DELETE_DOCUMENTS_COMPLETE]: (state: DocumentsState, action: Actions.DeleteDocumentsComplete) => {
    return {
      ...state,
      errorMessage: action.succeeded ? "" : "Unable to delete document(s)",
      isDeleting: false,
      shouldFetch: action.succeeded
    };
  },

  [Actions.DISABLE_DOCUMENT]: (state: DocumentsState, action: Actions.DisableDocument) => {
    return {
      ...state,
      isSaving: true
    };
  },

  [Actions.DISABLE_DOCUMENT_COMPLETE]: (state: DocumentsState, action: Actions.DisableDocumentComplete) => {
    return {
      ...state,
      errorMessage: action.succeeded ? "" : "Unable to disable document",
      isSaving: false,
      shouldFetch: action.succeeded
    };
  },

  [Actions.ENABLE_DOCUMENT]: (state: DocumentsState, action: Actions.EnableDocument) => {
    return {
      ...state,
      isSaving: true
    };
  },

  [Actions.ENABLE_DOCUMENT_COMPLETE]: (state: DocumentsState, action: Actions.EnableDocumentComplete) => {
    return {
      ...state,
      errorMessage: action.succeeded ? "" : "Unable to enable document",
      isSaving: false,
      shouldFetch: action.succeeded
    };
  },
  
  [Actions.REPLACE_FILE]: (state: DocumentsState, action: Actions.ReplaceFile) => {
    return {
      ...state,
      isUploading: true
    };
  },

  [Actions.REPLACE_FILE_COMPLETE]: (state: DocumentsState, action: Actions.ReplaceFileComplete) => {
    return {
      ...state,
      editableDocument: !!action.document ? action.document : state.editableDocument,
      errorMessage: action.succeeded ? "" : "Unable to replace file",
      isUploading: false,
      successMessage: action.succeeded ? "Success! The file has been replaced." : ""
    };
  },

  [Actions.UPDATE_DOCUMENT_FEED]: (state: DocumentsState, action: Actions.UpdateDocumentFeed) => {
    return {
      ...state,
      documentFeed: {
        ...state.documentFeed,
        documentFeed: {
          ...state.documentFeed.documentFeed,
          documents: action.documentFeed
        }
      }
    };
  },

  [Actions.UPDATE_DOCUMENT_PROPERTIES]: (state: DocumentsState, action: Actions.UpdateDocumentProperties) => {
    return {
      ...state,
      isSaving: true
    };
  },

  [Actions.UPDATE_DOCUMENT_PROPERTIES_COMPLETE]: (state: DocumentsState, action: Actions.UpdateDocumentPropertiesComplete) => {
    return {
      ...state,
      changedSinceSaved: !action.succeeded,
      errorMessage: action.succeeded ? "" : "Unable to update properties",
      isSaving: false,
      shouldFetch: action.succeeded
    };
  },

  [Actions.UPLOAD_FILE]: (state: DocumentsState, action: Actions.UploadFile) => {
    return {
      ...state,
      isUploading: true
    };
  },

  [Actions.UPLOAD_FILE_COMPLETE]: (state: DocumentsState, action: Actions.UploadFileComplete) => {
    return {
      ...state,
      changedSinceSaved: action.succeeded,
      errorMessage: action.succeeded ? "" : "Unable to upload file",
      isUploading: false,
      shouldFetch: action.succeeded
    };
  },


  [Actions.CHANGED_SINCE_SAVED]: (state: DocumentsState, action: Actions.ChangedSinceSaved) => {
    return {
      ...state,
      changedSinceSaved: true
    };
  },

  [Actions.CLEAR_CHANGED_SINCE_SAVED]: (state: DocumentsState, action: Actions.ChangedSinceSaved) => {
    return {
      ...state,
      changedSinceSaved: false
    };
  },

  [Actions.CLEAR_DOCUMENT_FEED]: (state: DocumentsState, action: Actions.ClearDocumentFeed) => {
    return {
      ...state,
      documentFeed: {
        ...state.documentFeed,
        documentFeed: { ...defaultDocumentFeed },
        shouldFetch: true
      }
    };
  },

  [Actions.CLEAR_DOCUMENT_FEED_FILTERS]: (state: DocumentsState, action: Actions.ClearDocumentFeedFilters) => {
    return {
      ...state,
      documentFeed: {
        ...state.documentFeed,
        filters: { ...defaultDocumentFeedFilters},
        filtersApplied: false,
        showFilters: false
      }
    };
  },

  [Actions.CLEAR_ERROR_MESSAGE]: (state: DocumentsState, action: Actions.ClearErrorMessage) => {
    return {
      ...state,
      errorMessage: ""
    };
  },

  [Actions.CLEAR_SHOULD_FETCH]: (state: DocumentsState, action: Actions.ClearShouldFetch) => {
    return {
      ...state,
      shouldFetch: false
    };
  },

  [Actions.CLEAR_SHOW_PUBLISH_SUCCESS]: (state: DocumentsState, action: Actions.ClearShowPublishSuccess) => {
    return {
      ...state,
      showPublishSuccess: false
    };
  },

  [Actions.CLEAR_SUCCESS_MESSAGE]: (state: DocumentsState, action: Actions.ClearSuccessMessage) => {
    return {
      ...state,
      successMessage: ""
    };
  }

}

export const reducer = (state: DocumentsState, action: Action) => {
  const actionHandlerMethod = actionHandler[action.type];
  if (actionHandlerMethod) {
    return actionHandlerMethod(state, action);
  }
  return state || defaultState;
};
