import * as React from "react";

import TenantLink from "modules/common/components/tenantLink";

import BasePage from "../common/basePage";
import MainContent from "../common/mainContent";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";


const AdminPageNotFound: React.FunctionComponent<{}> = props => {
  return (
    <BasePage>
      <MainContent addPadding>
        <div id="page-not-found">
          <img src="/images/404.png" alt="404 banner" />
          <Typography variant="h1">Sorry! We can't find what you're looking for.</Typography>
          <div>It may have been removed, changed, or is temporarily unavailable.</div>
          <div>Explore other sections while we fix the problem!</div>
          <TenantLink to="~/admin/dashboard">
            <Button variant="text" color="primary">Back to Dashboard</Button>
          </TenantLink>
        </div>
      </MainContent>
    </BasePage>
  );
}

export default AdminPageNotFound;