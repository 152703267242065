import { TranslatableContent } from "modules/common/components/authoring/models";

export enum SupportTab {
    FAQ = 1,
    ReleaseNotes = 2,
    ReleasePackages = 3,
    KnownIssues = 4
}

export interface SupportItem {
    id: string
    author: string
    authorEmail?: string
    translatedContent: TranslatedContent
    itemType: string
    category: string | null
    dateLastUpdated: any
    isDraft: boolean
    status: string;
    releaseNumber: string;
    associatedLinks: AssociatedLink[];
}

export interface AssociatedLink {
    fileName: string
    blobId: string
    url: string | null
}

export interface TranslatedContent {
    [languageCode: string]: TranslatableSupportContent
}

export interface TranslatableSupportContent extends TranslatableContent {}

export const EmptyDefaultSupportItem: SupportItem = {
    id: '',
    author: '',
    translatedContent: {"en-us": {title: "", body: "", description: ""}},
    itemType: '',
    category: null,
    dateLastUpdated: null,
    isDraft: false,
    status: '',
    releaseNumber: '',
    associatedLinks: []
}

export interface SupportFilter {
    title: string;
    category: string;
    status: string;
    releaseNumber: string;
}

export const defaultSupportFilter: SupportFilter = {
    title: "",
    category: "",
    status: "",
    releaseNumber: ""
}

export const SupportItemTypes = {
    faq: "faq",
    releaseNote: "releaseNote",
    releasePackage: "releasePackage",
    knownIssue: "knownIssue",
    training: "training"
}