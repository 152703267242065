import React from "react";
import { SvgIcon } from "@mui/material";

/**
 * DockToLeftIcon
 * icon: https://fonts.google.com/icons?selected=Material+Symbols+Outlined:dock_to_left:FILL@0;wght@400;GRAD@0;opsz@24&icon.query=panel
 * @returns
 */
export const DockToLeftIcon: React.FunctionComponent = () => {
    return (
        <SvgIcon component="svg" viewBox="0 -960 960 960">
            <path
                d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm440-80h120v-560H640v560Zm-80 0v-560H200v560h360Zm80 0h120-120Z" />
        </SvgIcon>
    );
}

