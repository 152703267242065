import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router";

const IFrameDetector: React.FunctionComponent<ComponentProps & RouteComponentProps> = (props) => {
    
    const isInIFrame = window !== window.parent && !window.opener;
    
    if((isInIFrame && props.renderWhen === 'InFrame') || (!isInIFrame && props.renderWhen === 'NotInFrame')){
        return (<>{props.children}</>);
    }else {
        return null;
    }
}

interface ComponentProps {
    renderWhen: 'InFrame' | 'NotInFrame'
}

export default withRouter(IFrameDetector);