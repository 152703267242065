import * as React from 'react';

interface componentProps {
    color?: string
    width?: string
    height?: string
}

export default class UploadIcon extends React.Component<componentProps, {}> {
    public render() {
        return (
            <svg
                fill={this.props.color || "#000000"}
                height={this.props.height || "24"}
                width={this.props.width || "24"}
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg" >
                <path d="M0 0h24v24H0z" fill="none"/>
                <path d="M9 16h6v-6h4l-7-7-7 7h4zm-4 2h14v2H5z"/>
            </svg>
        );
    }
}