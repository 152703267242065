import React from "react";

import LockIcon from "@mui/icons-material/Lock";
import AddIcon from '@mui/icons-material/Add';

import ChipList from "../../chipList";
import TopicMultiselect from "./topicMultiselect";
import { MultiSelectTopic, MultiSelectTopicGroup } from "modules/settings";

interface ITopicMultiselectAndChips {
    defaultOptions: MultiSelectTopicGroup[];
    selectedOptions?: MultiSelectTopic[];
    containerClassName?: string;
    emptyChipLabel?: string;
    emptyChipStyle?: React.CSSProperties;
    onClose?: () => void;
    onChange: (topics: MultiSelectTopic[]) => void;
}

/**
 * Button and list component
 * - button opens multi-select
 * - selections displayed in chips list
 * TODO: MAKE THIS GENERIC (NOT TOPIC SPECIFIC)
 */
const TopicMultiselectAndChips: React.FunctionComponent<ITopicMultiselectAndChips> = ({
    defaultOptions,
    containerClassName,
    selectedOptions = [],
    emptyChipLabel,
    emptyChipStyle,
    onClose,
    onChange,
}) => {
    const onChangeTags = (currentTag: MultiSelectTopic) => {
        const hasSelectedTag: boolean = !!selectedOptions.find((selectedTag) => selectedTag.id === currentTag.id);
        let newTopics = [...selectedOptions];

        if (hasSelectedTag)
            newTopics = selectedOptions.filter(tag => tag.id !== currentTag.id);
        else
            newTopics.push(currentTag);

        onChange(newTopics);
    }

    return (
        <div className={`${containerClassName || ""}`}>
            <div style={{ width: "100%" }}>
                {defaultOptions.length !== 0 &&
                    <TopicMultiselect
                        defaultOptions={defaultOptions}
                        containerClassName={containerClassName}
                        selectedOptions={selectedOptions}
                        onChange={onChange}
                        startIcon={<AddIcon/>}
                        onClose={onClose}
                    />
                }
            </div>
            <ChipList
                showEmptyChip={Boolean(emptyChipLabel)}
                emptyLabel={emptyChipLabel}
                emptyStyle={emptyChipStyle}
                closable
                onClose={onChangeTags}
                truncateAtIndex={4}
                items={selectedOptions.map((opt: MultiSelectTopic) => ({
                    id: opt.id,
                    name: opt.name,
                    icon: opt.restricted ? <LockIcon style={{ fontSize: "16px", color: "#B72026", position: "relative", top: "2px", marginLeft: "4px" }} /> : undefined
                }))}
            />
        </div>
    );
}

export default TopicMultiselectAndChips;
