export interface ConfigDictionary { 
    SparrowClientApiUrl: string,
    SyncFunctionsApiUrl: string,
    AnalyticsErrorMessage: string,

    FetchPostList: string,
    FetchSinglePost: string,
    FetchTagList: string,
    TagUpdated: string,
    LogEvents: string,
    FetchTenantConfig: string,
    SelectReaction: string,
    Search: string,
    FetchTagSettings: string,
    FetchUserTagSettings: string,
    FetchUserSettings: string

    smsVerify: string

    GetComments: string,
    CreateComment: string,
    UpdateComment: string,
    DeleteComment: string,

    // analytics
    UserCounts: string,
    CommentAndReactionCounts: string,
    PlatformStats: string,
    PostStats: string,
    ReadViewStats: string,
    TagStats: string,
    TopSurveys: string,
    TopEvents: string,
    UserTrends: string

    downloadAllAnalyticsReportAsImage: string,

    totalUsersInAudiences: string,
    totalUsersSubscribedInTopics: string,

    FetchNotificationSettings: string,

    Users: string,
    ExternalUsers: string,
    ClientSettings: string,
    TenantSettings: string,
    LocalUsers: string,
    InviteSparrowUsers: string,

    FetchDraftPosts: string,
    FetchDraftPost: string,
    SaveDraftPost: string,
    CreateNewDraftPost: string,
    DeleteDraftPost: string,

    LCIDMappings: string
    FetchEmailResource: string
    FetchSMSResource: string
    FetchMobileResource: string
 }

export interface ApplicationState {
    config: ConfigDictionary
}

export const DefaultConfigState: ConfigDictionary = {
    SparrowClientApiUrl: `${process.env.REACT_APP_CLIENTAPIURL}`,
    SyncFunctionsApiUrl: `${process.env.REACT_APP_SYNCFUNCTIONSAPIURL}`,
    AnalyticsErrorMessage: "",

    FetchPostList: "/{tenant}/api/v1/posts?format=html",
    FetchSinglePost: "/{tenant}/api/v1/posts/{post}?format=html",
    FetchTagList: "/{tenant}/adminapi/v1/tags",
    TagUpdated: "/{tenant}/api/v1/tags/{mode}?tag={tagId}",
    LogEvents: "/{tenant}/api/v1/loggedEvents",
    FetchTenantConfig: "/{tenant}/api/v1/config",
    SelectReaction: "/{tenant}/api/v1/reactions",
    Search: "/{tenant}/api/v1/search",
    FetchTagSettings: "/{tenant}/adminapi/v1/tags?audienceLocked=true",
    FetchUserTagSettings: "/{tenant}/api/v1/tags?audienceLocked=true",
    FetchUserSettings: "/{tenant}/api/v1/userSettings",

    smsVerify:  "/{tenant}/api/v1/userSettings/smsVerify",

    GetComments: "/{tenant}/api/v1/comment",
    CreateComment: "/{tenant}/api/v1/comment",
    UpdateComment: "/{tenant}/api/v1/comment",
    DeleteComment: "/{tenant}/api/v1/comment",

    // analytics
    UserCounts: "/{tenant}/platformAnalytics/v1/activeUsers",
    CommentAndReactionCounts: "/{tenant}/platformAnalytics/v1/commentsAndReactions",
    PlatformStats: "/{tenant}/platformAnalytics/v1/channelUsage",
    PostStats: "/{tenant}platformAnalytics/v1/postStats",
    ReadViewStats: "/{tenant}/platformAnalytics/v1/postReadsViews",
    TagStats: "/{tenant}/insightsapi/v1/tagStats", //CURRENTLY UNUSED
    TopSurveys: "/{tenant}/platformAnalytics/v1/surveyWithMostResponse",
    TopEvents: "/{tenant}/platformAnalytics/v1/mostViewedEvents",
    UserTrends: "/{tenant}/platformAnalytics/v1/userTrend",

    totalUsersInAudiences: "/{tenant}/platformAnalytics/v1/totalUsersInAudiences",
    totalUsersSubscribedInTopics: "/{tenant}/platformAnalytics/v1/totalUsersSubscribedInTopics",

    // analytics report
    downloadAllAnalyticsReportAsImage: "/{tenant}/platformAnalytics/v1/report/image",

    FetchNotificationSettings: "/{tenant}/adminapi/v1/notifications",

    Users: "/{tenant}/adminapi/v1/users",
    ExternalUsers: "/{tenant}/adminapi/v1/invitedUsers",
    ClientSettings: "/{tenant}/api/v1/config",
    TenantSettings: "/{tenant}/adminapi/v1/tenantSettings",
    LocalUsers: "/{tenant}/adminapi/v1/users/local",
    InviteSparrowUsers: "/{tenant}/adminapi/v1/invitedUsers/inviteSparrow",

    FetchDraftPosts: "/{tenant}/adminapi/v1/drafts",
    FetchDraftPost: "/{tenant}/adminapi/v1/drafts",
    SaveDraftPost: "/{tenant}/adminapi/v1/drafts",
    CreateNewDraftPost: "/{tenant}/adminapi/v1/drafts/newDraft",
    DeleteDraftPost: "/{tenant}/adminapi/v1/drafts",

    LCIDMappings: "/{tenant}/api/v1/Resources/LCIDMappings",
    FetchEmailResource: "/{tenant}/api/v1/Resources/EmailSubjects",
    FetchSMSResource: "/{tenant}/api/v1/Resources/SMSBodies",
    FetchMobileResource: "/{tenant}/api/v1/Resources/PushContents"
}

export const ReactionSentimentMap = {
    add: "add",
    angry: "angry",
    bored: "bored",
    concerned: "concerned",
    curious: "curious",
    excited: "excited",
    idea: "idea",
    like: "like"
}
