import React from "react";

import CircularProgress from "@mui/material/CircularProgress";


interface ComponentProps {
  size: number | string;
  style?: React.CSSProperties;
}

const LoadingCircle: React.FunctionComponent<ComponentProps> = props => {
  return (
    <CircularProgress size={props.size} style={props.style ? props.style : undefined}/>
  );
}

export default LoadingCircle;
