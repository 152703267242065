import React from 'react';
import { ConnectedProps, connect } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import {actions} from '..'
import SettingsToggleOnlyDouble from './settingsToggleOnlyDouble'

interface ComponentProps {
    title: string;
    eventOne: string;
    eventTwo: string;
    description?: string;
    id?: string;
    andPrompt: string;
    eventOnePrompt: string;
    eventTwoPrompt: string;
    handleOnClick?: () => any;
}

const SettingsToggleOrAnd: React.FC<PropsWithRedux> = ({title, eventOne, eventTwo, description, andPrompt, eventOnePrompt, eventTwoPrompt, toggleNotificationEvent, notificationSettings, toggleBlackoutPeriod, enableAllChannels, setSelectedChannel, eventsWithBlackout, id, handleOnClick}) => {

    const settings = notificationSettings.settings;
    const anyChannelChecked = settings[eventOne].toggle || settings[eventTwo].toggle;
    const blackoutApplied =  eventsWithBlackout.includes(eventOne) || eventsWithBlackout.includes(eventTwo);
    const type = (id ?? "");

    const bothChecked = settings[eventOne].toggle && settings[eventTwo].toggle;
    const topChecked = settings[eventOne].toggle && !settings[eventTwo].toggle;
    const botChecked = !settings[eventOne].toggle && settings[eventTwo].toggle;

    return (
        <div>
            <SettingsToggleOnlyDouble
                title={title}
                description={description}
                eventOne={eventOne}
                eventTwo={eventTwo}
                handleOnClick={handleOnClick}
            />

            {anyChannelChecked ?
            <div className="settings-checkbox-section">
                <div className="settings-checkbox-row">
                    <input style={{cursor: "pointer"}} type="checkbox" id={"applyBlackoutPeriod"+type}
                        checked={blackoutApplied}
                        onChange={() => toggleBlackoutPeriod(eventOne, eventTwo, "", "", !blackoutApplied)}>
                    </input><label className="input-label-text" htmlFor={"applyBlackoutPeriod"+type}>Apply blackout period to notifications</label>
                </div>
                <div>
                    <div className="settings-checkbox-orand">
                        <input
                            style={{cursor: "pointer"}}
                            type="radio" id={"enableBothChannel"+type}
                            name={"options"+type}
                            checked={bothChecked}
                            onChange={() => {setSelectedChannel(eventOne, true); setSelectedChannel(eventTwo, true)}}>
                        </input><label className="input-label-text" htmlFor={"enableBothChannel"+type}>{andPrompt}</label>
                    </div>
                    <div className="settings-checkbox-orand">
                        <input
                            style={{cursor: "pointer"}}
                            type="radio" id={"enableFirstChannel"+type}
                            name={"options"+type}
                            checked={topChecked}
                            onChange={() => {setSelectedChannel(eventOne, true); setSelectedChannel(eventTwo, false)}}>
                        </input><label className="input-label-text" htmlFor={"enableFirstChannel"+type}>{eventOnePrompt}</label>
                    </div>
                    <div className="settings-checkbox-orand">
                        <input
                            style={{cursor: "pointer"}}
                            type="radio" id={"enableSecondChannel"+type}
                            name={"options"+type}
                            checked={botChecked}
                            onChange={() => {setSelectedChannel(eventTwo, true); setSelectedChannel(eventOne, false)}}>
                        </input><label className="input-label-text" htmlFor={"enableSecondChannel"+type}>{eventTwoPrompt}</label>
                    </div>
                </div>
            </div>
            : <b/>}
        </div>
    )
}



const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        notificationSettings: state.settings.notificationSettings,
        eventsWithBlackout: state.settings.applyBlackoutPeriodsTo,
    }),
    {
        toggleBlackoutPeriod: actions.ApplyBlackoutPeriod,
        enableAllChannels: actions.EnableAllChannels,
        setSelectedChannel: actions.EnableSelectedChannel,
        toggleNotificationEvent: actions.toggleEvent
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default (connector(SettingsToggleOrAnd));
