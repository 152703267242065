import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { push } from "react-router-redux";
import { GlobalApplicationState } from "globalApplicationState";

import { AccountCategory, UserListItem } from "../../models";

import MoreOptionsIconButton from "modules/common/components/buttons/moreOptionsIconButton";

import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import MoreOptionsItem from "modules/common/components/moreOptionsItem";


const MoreUserOptions: React.FunctionComponent<PropsWithRedux> = props => {
  const { user } = props;

  return (
    <MoreOptionsIconButton>
      <List disablePadding>
        <MoreOptionsItem
          text="View profile"
          onClick={() => {
            props.redirectTo("/" + props.tenantId + "/admin/users/profile/" + user.id);
          }}
        />
        <Divider light />
        {user.status === "enabled" &&
          <MoreOptionsItem
            text="Deactivate"
            onClick={() => {
              props.onDisableUser(user.id);
              props.onSelection();
            }}
          />
        }
        {user.status === "disabled" &&
          <MoreOptionsItem
            text="Activate"
            onClick={() => {
              props.onEnableUser(user.id);
              props.onSelection();
            }}
          />
        }
        {(user.accountCategory === "social" || user.accountCategory === "sparrow") &&
          <MoreOptionsItem
            text="Delete"
            onClick={async () => {
              props.onDeleteUser(user.id, user.accountCategory);
              props.onSelection();
            }}
          />
        }
        <Divider light />
        <MoreOptionsItem
          text="Download user data"
          onClick={() => {
            props.onDownloadUserData(user.id);
            props.onSelection();
          }}
        />
      </List>
    </MoreOptionsIconButton>
  );
}

interface ComponentProps {
  user: UserListItem;
  onDeleteUser: (id: string, accountCategory: AccountCategory) => void;
  onDisableUser: (id: string) => void;
  onDownloadUserData: (id: string) => void;
  onEnableUser: (id: string) => void;
  onSelection: () => void;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    tenantId: state.tenant.id
  }),
  {
    redirectTo: push
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(MoreUserOptions);