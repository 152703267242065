import * as actions from "network/actions";

import { PortalPagesFilterValues } from "modules/portalPages/models";
import cookie from "utils/cookie";


export const CreateNewDraft = (pageTitle: string, pageUrl: string, defaultJSON: any, audiences: string[]) => {
  return actions.NetworkRequest({
    headers: { "content-type": "application/json" },
    method: "POST",
    url: `/{tenant}/portal/v1/pages/drafts`,
    body: JSON.stringify({
      title: pageTitle,
      url: pageUrl,
      portalPageJson: [defaultJSON],
      audiences: audiences
    })
  });
}

export const DeleteDraft = (draftId: string) => {
  return actions.NetworkRequest({
    method: "DELETE",
    url: `/{tenant}/portal/v1/pages/drafts/${draftId}`
  });
}

export const GetDraft = (draftId: string) => {
  return actions.NetworkRequest({
    method: "GET",
    url: `/{tenant}/portal/v1/pages/drafts/${draftId}`
  });
}

export const ModifyDraftDetails = (draftId: string, pageTitle: string, pageUrl: string, audiences: string[]) => {
  return actions.NetworkRequest({
    headers: { "content-type": "application/json" },
    method: "PATCH",
    url: `/{tenant}/portal/v1/pages/drafts/${draftId}`,
    body: JSON.stringify({
      title: pageTitle,
      url: pageUrl,
      audiences: !!audiences.length ? audiences : null
    })
  });
}

export const ModifyDraftTitle = (draftId: string, pageTitle: string) => {
  return actions.NetworkRequest({
    headers: { "content-type": "application/json" },
    method: "PATCH",
    url: `/{tenant}/portal/v1/pages/drafts/${draftId}`,
    body: JSON.stringify({
      title: pageTitle
    })
  });
}

export const PublishDraft = (draftId: string) => {
  return actions.NetworkRequest({
    method: "POST",
    url: `/{tenant}/portal/v1/pages/publish/${draftId}`
  });
}

export const SaveDraft = (draftId: string, body: string) => {
  return actions.NetworkRequest({
    headers: { "content-type": "application/json" },
    method: "PATCH",
    url: `/{tenant}/portal/v1/pages/drafts/${draftId}`,
    body: JSON.stringify({
      portalPageJson: [body]
    })
  });
}


export const GetPage = (id: string) => {
  return actions.NetworkRequest({
    method: "GET",
    url: `/{tenant}/portal/v1/pages/${id}`
  });
}

export const GetPages = (pageNumber: number, filters: Partial<PortalPagesFilterValues>, pageAmount?: number) => {
  let rowsPerPage = pageAmount || cookie.getRowsPerPage();

  return actions.NetworkRequest({
    method: "POST",
    url: `/{tenant}/portal/v1/pages`,
    queryParams: { "pageAmount": rowsPerPage, "pageNumber": pageNumber },
    body: JSON.stringify({
      ...filters,
      audiences: filters.audiences ? filters.audiences.map(audience => audience.id) : []
    })
  });
}

export const GetDisabledPages = (pageNumber: number, filters: Partial<PortalPagesFilterValues>, pageAmount?: number) => {
  let rowsPerPage = pageAmount || cookie.getRowsPerPage();

  return actions.NetworkRequest({
    headers: { "content-type": "application/json" },
    method: "POST",
    url: `/{tenant}/portal/v1/pages`,
    queryParams: { "pageAmount": rowsPerPage, "pageNumber": pageNumber },
    body: JSON.stringify({
      ...filters,
      audiences: filters.audiences ? filters.audiences.map(audience => audience.id) : [],
      currentFilter: ["Disabled"]
    })
  });
}

export const GetDraftPages = (pageNumber: number, filters: Partial<PortalPagesFilterValues>, pageAmount?: number) => {
  let rowsPerPage = pageAmount || cookie.getRowsPerPage();

  return actions.NetworkRequest({
    headers: { "content-type": "application/json" },
    method: "POST",
    url: `/{tenant}/portal/v1/pages`,
    queryParams: { "pageAmount": rowsPerPage, "pageNumber": pageNumber },
    body: JSON.stringify({
      ...filters,
      audiences: filters.audiences ? filters.audiences.map(audience => audience.id) : [],
      currentFilter: ["Draft"]
    })
  });
}

export const GetEnabledPages = (pageNumber: number, filters: Partial<PortalPagesFilterValues>, pageAmount?: number) => {
  let rowsPerPage = pageAmount || cookie.getRowsPerPage();

  return actions.NetworkRequest({
    headers: { "content-type": "application/json" },
    method: "POST",
    url: `/{tenant}/portal/v1/pages`,
    queryParams: { "pageAmount": rowsPerPage, "pageNumber": pageNumber },
    body: JSON.stringify({
      ...filters,
      audiences: filters.audiences ? filters.audiences.map(audience => audience.id) : [],
      currentFilter: ["Enabled"]
    })
  });
}

export const CheckIfUrlExists = (url: string) => {
  return actions.NetworkRequest({
    method: "GET",
    url: `/{tenant}/portal/v1/pages/url?attempt=${url}`
  });
}

export const DeletePage = (id: string) => {
  return actions.NetworkRequest({
    method: "DELETE",
    url: `/{tenant}/portal/v1/pages/${id}`
  });
}

export const DisablePage = (id: string) => {
  return actions.NetworkRequest({
    headers: { "content-type": "application/json" },
    method: "PATCH",
    url: `/{tenant}/portal/v1/pages/${id}`,
    body: JSON.stringify({
      enabled: false
    })
  });
}

export const EnablePage = (id: string) => {
  return actions.NetworkRequest({
    headers: { "content-type": "application/json" },
    method: "PATCH",
    url: `/{tenant}/portal/v1/pages/${id}`,
    body: JSON.stringify({
      enabled: true
    })
  });
}

export const ModifyPageDetails = (id: string, pageTitle: string, pageUrl: string, audiences: string[]) => {
  return actions.NetworkRequest({
    headers: { "content-type": "application/json" },
    method: "PATCH",
    url: `/{tenant}/portal/v1/pages/${id}`,
    body: JSON.stringify({
      title: pageTitle,
      url: pageUrl,
      audiences: !!audiences.length ? audiences : null
    })
  });
}

export const ModifyPageTitle = (id: string, pageTitle: string) => {
  return actions.NetworkRequest({
    headers: { "content-type": "application/json" },
    method: "PATCH",
    url: `/{tenant}/portal/v1/pages/${id}`,
    body: JSON.stringify({
      title: pageTitle
    })
  });
}

export const SetPageAsHome = (id: string) => {
  return actions.NetworkRequest({
    method: "POST",
    url: `/{tenant}/portal/v1/pages/${id}/home`
  });
}


export const CreateNewChildNavigationItem = (portalPageId: string, parentId: string, name: string, url: string, sequence: number) => {
  return actions.NetworkRequest({
    headers: { "content-type": "application/json" },
    method: "POST",
    url: `/{tenant}/portal/v1/navigation`,
    body: JSON.stringify({
      portalPageId,
      parentId,
      name,
      url,
      sequence
    })
  });
}

export const CreateNewNavigationItem = (portalPageId: string, name: string, url: string, sequence: number) => {
  return actions.NetworkRequest({
    headers: { "content-type": "application/json" },
    method: "POST",
    url: `/{tenant}/portal/v1/navigation`,
    body: JSON.stringify({
      portalPageId,
      name,
      url,
      sequence
    })
  });
}

export const DeleteNavigationItem = (id: string) => {
  return actions.NetworkRequest({
    method: "DELETE",
    url: `/{tenant}/portal/v1/navigation/${id}`
  });
}

export const GetNavigation = () => {
  return actions.NetworkRequest({
    method: "GET",
    url: `/{tenant}/portal/v1/navigation`
  });
}

export const GetNavigationItemByURL = (url: string) => {
  return actions.NetworkRequest({
    method: "GET",
    url: `/{tenant}/portal/v1/navigation?url=${url}`
  });
}

export const GetNavigationItems = () => {
  return actions.NetworkRequest({
    method: "GET",
    url: `/{tenant}/portal/v1/navigation/management`
  });
}

export const GetNavigationItem = (id: string) => {
  return actions.NetworkRequest({
    method: "GET",
    url: `/{tenant}/portal/v1/navigation/${id}`
  });
}

export const SetNavigationItemAsParent = (id: string, sequence: number) => {
  return actions.NetworkRequest({
    headers: { "content-type": "application/json" },
    method: "PATCH",
    url: `/{tenant}/portal/v1/navigation/${id}`,
    body: JSON.stringify({
      parentId: "",
      sequence
    })
  });
}

export const SaveNavigationItem = (id: string, body: string) => {
  return actions.NetworkRequest({
    headers: { "content-type": "application/json" },
    method: "POST",
    url: `/{tenant}/portal/v1/navigation/${id}`,
    body: JSON.stringify(body)
  });
}

export const UpdateNavigationItemSequence = (id: string, parentId: string, sequence: number) => {
  return actions.NetworkRequest({
    headers: { "content-type": "application/json" },
    method: "PATCH",
    url: `/{tenant}/portal/v1/navigation/${id}`,
    body: JSON.stringify({
      parentId,
      sequence
    })
  });
}