import * as React from "react"
import { ActivityTab } from "../models";
// import ActivityLog from "./activityLog";
import FlaggedItemsManagement from "./flaggedItemsManagement";
import { FormattedMessage } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import * as actions from '../actionCreator';

import BasePage from "pages/common/basePage";
import Header from "pages/common/header";
import MainContent from "pages/common/mainContent";


class ActivityManagementPage extends React.Component<PropsWithRedux, {}> {

    public componentDidMount() {
        (window as any).scrollTo(0, 0);
    }

    public render() {
        return (
            <BasePage fullWidth>
                <Header
                    title="Activity Tools"
                />
                <MainContent>
                    <div className="activity-page">
                        <div className="tabs">
                            <ul className="tab-btns">
                                {/* Commented out due to activity log not functioning properly.
                                <li>
                                    <span className={`tab-btn ${this.props.activeTab === ActivityTab.ActivityLog && "active"}`}
                                        onClick={() => this.props.setActiveTab(ActivityTab.ActivityLog)}>
                                        <FormattedMessage id="common.activityLog" defaultMessage="Activity Log" />
                                    </span>
                                </li>
                                */}                                
                                <li>
                                    <span className={`tab-btn ${this.props.activeTab === ActivityTab.FlaggedItems && "active"}`}
                                        onClick={() => this.props.setActiveTab(ActivityTab.FlaggedItems)}>
                                        <FormattedMessage id="common.flaggedItems" defaultMessage="Flagged Comments" />
                                    </span>
                                </li>
                            </ul>
                                {/* Commented out due to activity log not functioning properly.
                            <div className={`tab non-list-view ${this.props.activeTab === ActivityTab.ActivityLog && "active"}`}>
                                <ActivityLog />
                            </div>
                                */}
                            <div className={`tab non-list-view ${this.props.activeTab === ActivityTab.FlaggedItems && "active"}`}>
                                <FlaggedItemsManagement />
                            </div>

                        </div>
                    </div>
                </MainContent>
            </BasePage>
        );
    }
}

interface ComponentProps {}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        activeTab: state.activity.activeTab
    }),
    {
        setActiveTab: actions.setActiveTab
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(ActivityManagementPage)