import React, { useState, useEffect } from "react";
import { TextField, FormControl, ClickAwayListener } from "@mui/material";
import { IContentBandSettingsInputProps } from "../editor/contentBandSettingsForm";
import { ContentBandLayoutType } from "modules/contentBands/models";


export interface IContentCountInputProps extends IContentBandSettingsInputProps<number> {
    layoutType: ContentBandLayoutType;
}

export const ContentCountInput: React.FunctionComponent<IContentCountInputProps> = ({ idx, value, layoutType, onChange }) => {
    const isCarousel = layoutType === ContentBandLayoutType.Carousel;
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [isValid, setIsValid] = useState<boolean>(true);
    // keep changes internal until element loses focus
    // this way we aren't sending a request on each character change
    const [internalValue, setInternalValue] = useState<number>(value);

    useEffect(() => {
        setInternalValue(value);
    }, [value]);

    /**
     * When focus lost
     * - send changes
     * - check if valid or not
     */
    useEffect(() => {
        if (!isFocused) {
            if (internalValue === 0 || (isCarousel && internalValue > 10) || (!isCarousel && internalValue > 20)) setIsValid(false);
        } else {
            setIsValid(true);
        }
    }, [isFocused, internalValue, isCarousel, idx]);

    const valueToUse = !isFocused && internalValue === 0 ? "" : internalValue;

    return (
        <div className="form-group">
            <label>
                Content count
                {isCarousel ? <span> (Max. 10)</span> : <span> (Max. 20)</span>}
            </label>
            <FormControl>
                <ClickAwayListener onClickAway={() => onChange(internalValue, idx)}>
                    <TextField
                        type="number"
                        sx={{
                            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                display: "none",
                            },
                            "& input[type=number]": {
                                MozAppearance: "textfield",
                            },
                        }}
                        error={!isValid}
                        value={valueToUse}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setInternalValue(Number(event.target.value))}
                        variant="outlined"
                        onFocus={() => {
                            setIsFocused(true);
                        }}
                        onBlur={() => {
                            setIsFocused(false);
                        }}
                        inputProps={{
                            className: "input",
                            max: isCarousel ? 10 : 20,
                            min: 1,
                        }}
                    />
                </ClickAwayListener>
            </FormControl>
        </div>
    );
};
