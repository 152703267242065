import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import * as actions from "../../actionCreator";
import * as settingsActions from "modules/settings/actionCreator";
import { GlobalApplicationState } from "globalApplicationState";
import { push } from "react-router-redux";
import moment from "moment";

import ErrorSnackbar from "modules/common/components/snackbars/errorSnackbar";
import Loading from "modules/common/components/loading";
import LoadingOverlay from "modules/common/components/loadingOverlay";

import BasePage from "pages/common/basePage";
import Breadcrumb from "pages/common/breadcrumb";
import MainContent from "pages/common/mainContent";

import confirm from "utils/notyPopups";

import { Event, NotificationRange, Reminder, ValidationChecks } from "../../models";
import EventPreview from "../event-views/eventPreview";
import EventEditor from "./eventEditor";
import Validation from "./validation";

import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Hidden from "@mui/material/Hidden";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { PickerLocalization } from "modules/common/components/pickerLocalization";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";


import "modules/common/components/authoring/authoring.sass";
import { ImageScale } from "modules/posts/models";
import { adminFilesApi } from "api/instances";
import { ICustomCssModelv1 } from "api/files";


class EventCreation extends React.Component<PropsWithRedux, ComponentState> {
    private validation: Validation;

    constructor(props: PropsWithRedux) {
        super(props);

        this.validation = new Validation();

        const event = { ...this.getNewEvent() };
        const validationChecks = this.validation.getValidationChecks(event);

        this.state = {
            event: event,
            hasError: false,
            isFetching: true,
            isValid: this.validation.isValid(validationChecks),
            showNotificationSettings: false,
            showPreview: false,
            showReminderSettings: false,
            showTabCommandList: false,
            validationChecks: validationChecks
        };
    }

    componentWillMount() {
        const draftId = this.props.match.params.draftId;

        if (!!draftId) {
            this.props.clearChangedSinceSaved();
            if (draftId === "new") {
                const event = { ...this.getNewEvent() };
                const validationChecks = this.validation.getValidationChecks(event);
                this.setState({ event: event, hasError: false, isFetching: false, isValid: this.validation.isValid(validationChecks), validationChecks: validationChecks });
            } else {
                this.getDraft(draftId);
            }
        }

        if (this.props.tagSettings.shouldFetch || !this.props.tagSettings.tagGroups.length) {
            this.props.getTagSettings();
        }
    }

    async componentDidMount() {
        moment.locale("en");

        try {
            if (this.props.tenantSettings.showFeatures.tinyMceCustomCssEnabled) {
                this.setState({ isFetching: true });
                const res = await adminFilesApi.getCustomTinyMceCss();

                this.setState({ customCss: res });
            }
        } catch { }
        finally {
            this.setState({ isFetching: false });
        }
    }

    componentDidUpdate(prevProps: PropsWithRedux) {
        if (!!this.props.match.params.draftId && (this.props.match.params.draftId !== prevProps.match.params.draftId)) {
            if (this.props.isUpdatingUrl) {
                this.setState({ event: { ...this.state.event, id: this.props.match.params.draftId } });
                this.props.updateUrlComplete();
            } else {
                this.setState({ event: {}, hasError: false, isFetching: true, validationChecks: { checks: [] } });
            }
        }
    }

    public render() {
        return (
            <BasePage fullWidth>
                <Breadcrumb
                    items={[
                        { title: "Events", onClick: this.backToManageEvents },
                        { title: this.props.match.params.draftId === "new" ? "Create new event" : "Edit event" }
                    ]}
                    backItem={{ title: "Back to Manage Events", onClick: this.backToManageEvents }}
                    rightComponent={this.getCommands()}
                />
                <MainContent>
                    <div className="authoring-page">
                        {this.state.isFetching
                            ? <Loading />
                            : this.state.hasError
                                ? <div className="no-event-data">This event could not be loaded.</div>
                                : <PickerLocalization>
                                    <EventEditor
                                        customCss={this.state.customCss}
                                        event={this.state.event}
                                        onChangeEvent={this.onChangeEvent}
                                        isValid={this.state.isValid}
                                        showNotificationSettings={this.state.showNotificationSettings}
                                        showReminderSettings={this.state.showReminderSettings}
                                        validationChecks={this.state.validationChecks}
                                    />
                                </PickerLocalization>
                        }
                        <EventPreview show={this.state.showPreview} event={this.state.event} onEditDetails={this.onHidePreview} onEditNotifications={this.onShowNotificationSettings} onEditReminders={this.onShowReminderSettings} onPublish={this.publishEvent} onClose={this.onHidePreview} />
                        <ErrorSnackbar errorMessage={this.props.errorMessage} clearErrorMessage={this.props.clearErrorMessage} />
                        <LoadingOverlay absolute={true} show={this.props.isSaving} />
                    </div>
                </MainContent>
            </BasePage>
        );
    }

    private getCommands = (): JSX.Element => {
        const { event } = this.state;

        return (
            <React.Fragment>
                <Hidden mdUp>
                    <div className="event-tab-command">
                        <ButtonGroup variant="contained" color="primary">
                            {!this.state.isValid
                                ? <Button onClick={this.saveEvent}>Save as Draft</Button>
                                : <Button onClick={this.previewEvent}>Publish</Button>
                            }
                            <Button color="primary" size="small" onClick={this.onShowTabCommandList}>
                                <ArrowDropDownIcon />
                            </Button>
                        </ButtonGroup>
                        {this.state.showTabCommandList &&
                            <ClickAwayListener onClickAway={this.onHideTabCommandList}>
                                <List className="event-tab-command-list">
                                    {!this.state.isValid
                                        ? <ListItem button disabled={true}>
                                            <ListItemText primary="Publish" className="tab-command-list-item" />
                                        </ListItem>
                                        : <ListItem button onClick={this.saveEvent}>
                                            <ListItemText primary="Save as Draft" className="tab-command-list-item" />
                                        </ListItem>
                                    }
                                    <ListItem button disabled={!event.id} onClick={this.discardEvent}>
                                        <ListItemText primary="Discard" />
                                    </ListItem>
                                </List>
                            </ClickAwayListener>
                        }
                    </div>
                </Hidden>
                <Hidden mdDown>
                    <Button variant="text" disabled={!event.id} onClick={this.discardEvent}>Discard</Button>
                    <Button variant="text" color="primary" onClick={this.saveEvent}>Save as Draft</Button>
                    <Button variant="contained" color="primary" disabled={!this.state.isValid} onClick={this.previewEvent}>Publish</Button>
                </Hidden>
            </React.Fragment>
        );
    }

    private getDraft = (draftId: string) => {
        this.props.getDraft(draftId, ImageScale.Unprocessed).then((event) => {
            if (!!event) {
                const validationChecks = this.validation.getValidationChecks(event);
                this.setState({ event, hasError: false, isFetching: false, isValid: this.validation.isValid(validationChecks), validationChecks: validationChecks });
            } else {
                this.setState({ hasError: true, isFetching: false });
            }
        });
    }

    private getNewEvent = (): Partial<Event> => {
        const { currentUser, notificationSettings, tenantSettings } = this.props;

        const author: string = currentUser ? (currentUser.preferredName ? `${currentUser.preferredName} ${currentUser.lastName}` : `${currentUser.firstName} ${currentUser.lastName}`) : "";
        const authorEmail: string = currentUser ? currentUser.email : "";
        const bannerColor: string = (tenantSettings && tenantSettings.themeSettings && tenantSettings.themeSettings.color) || "#2196f3";
        const lcid: string = tenantSettings ? tenantSettings.defaultLCID : "en-us";
        const eventStartTime: string = moment(new Date()).startOf("hour").add(1, "hour").add(1, "day").toISOString();
        const eventEndTime: string = moment(eventStartTime).add(30, "minutes").toISOString();

        const event: Partial<Event> = {
            id: "",
            attachedContent: [],
            author: author,
            authorEmail: authorEmail,
            bannerColor: bannerColor,
            capacity: {
                InPerson: -1,
                NotAttending: -1,
                Online: -1,
                Waitlist: -1
            },
            commentingEnabled: false,
            eventTimes: {
                [eventStartTime]: eventEndTime
            },
            eventType: "informational",
            fileAttachments: [],
            locationLink: "",
            locationLinkLabel: "",
            notifications: {
                emailOnPublish: notificationSettings.defaultEventSettings?.emailOnPublish as NotificationRange || "none",
                mobileOnPublish: notificationSettings.defaultEventSettings?.mobileOnPublish as NotificationRange || "subscribers",
                reminders: notificationSettings.defaultEventSettings?.reminders as Reminder[] || [],
                smsOnPublish: notificationSettings.defaultEventSettings?.smsOnPublish as NotificationRange || "none",
                teamsOnPublish: notificationSettings.defaultEventSettings?.teamsOnPublish as NotificationRange || "subscribers"
            },
            respondingEnabled: false,
            tags: [],
            translatedContent: {
                [lcid]: {
                    body: "",
                    description: "",
                    title: ""
                }
            }
        };
        return event;
    }

    private onChangeEvent = (value: Partial<Event>) => {
        const event = { ...this.state.event, ...value };
        const validationChecks = this.validation.getValidationChecks(event);
        this.setState({
            ...this.state,
            event: event,
            isValid: this.validation.isValid(validationChecks),
            validationChecks: validationChecks
        });
        this.props.hasChangedSinceSaved();
    }


    private onHidePreview = () => {
        this.setState({ showPreview: false });
    }

    private onHideTabCommandList = () => {
        this.setState({ showTabCommandList: false });
    }

    private onShowNotificationSettings = () => this.setState({ showPreview: false, showNotificationSettings: true }, () => this.setState({ showNotificationSettings: false }));
    private onShowReminderSettings = () => this.setState({ showPreview: false, showReminderSettings: true }, () => this.setState({ showReminderSettings: false }))

    private onShowPreview = () => {
        this.setState({ showPreview: true });
    }

    private onShowTabCommandList = () => {
        this.setState({ showTabCommandList: true });
    }


    private backToManageEvents = async () => {
        if (this.props.changedSinceSaved) {
            if (await confirm.show({
                title: "Back to Manage Events",
                yesColor: "#3b78ab",
                noColor: "#888888",
                text: (
                    <React.Fragment>
                        <div className="emphasis">Your changes will not be saved.</div>
                        <br />
                        <div>Do you want to continue?</div>
                    </React.Fragment>
                )
            })) {
                this.props.clearChangedSinceSaved();
                this.props.redirectTo("/" + this.props.match.params.tenant + "/admin/events");
            }
        } else {
            this.props.redirectTo("/" + this.props.match.params.tenant + "/admin/events");
        }
    }

    private discardEvent = () => {
        this.props.clearChangedSinceSaved();
        this.props.redirectTo("/" + this.props.match.params.tenant + "/admin/events");
    }

    private previewEvent = () => {
        if (!this.state.event.id)
            this.props.updateUrl();

        this.props.saveEvent(this.state.event).then((response) => {
            if (!!response && !!response.id) {
                this.props.redirectTo("/" + this.props.match.params.tenant + "/admin/events/edit/" + response.id);
                this.onShowPreview();
            }
        });
    }

    private publishEvent = (sendNotification: boolean) => {
        this.onHidePreview();
        this.props.publishEvent(this.state.event, sendNotification).then((succeeded) => {
            if (succeeded)
                this.props.redirectTo("/" + this.props.match.params.tenant + "/admin/events");
        });
    }

    private saveEvent = () => {
        if (!this.state.event.id)
            this.props.updateUrl();
        this.props.saveEvent(this.state.event).then((response) => {
            if (!!response && !!response.id)
                this.props.redirectTo("/" + this.props.match.params.tenant + "/admin/events/edit/" + response.id);
        });
    }
}


interface RouteParams {
    tenant: string;
    draftId: string;
}

interface ComponentProps {
}

interface ComponentState {
    event: Partial<Event>;
    hasError: boolean;
    isFetching: boolean;
    isValid: boolean;
    showNotificationSettings: boolean;
    showPreview: boolean;
    showReminderSettings: boolean;
    showTabCommandList: boolean;
    validationChecks: ValidationChecks;
    customCss?: ICustomCssModelv1;
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps & RouteComponentProps<RouteParams>) => ({
        ...ownProps,
        changedSinceSaved: state.events.changedSinceSaved,
        errorMessage: state.events.errorMessage,
        isSaving: state.events.isSaving,
        isUpdatingUrl: state.events.isUpdatingUrl,

        currentUser: state.settings.currentUser,
        notificationSettings: state.settings.notificationSettings,
        tagSettings: state.settings.tagSettings,
        tenantSettings: state.settings.tenantSettings
    }),
    {
        clearChangedSinceSaved: actions.clearChangedSinceSaved,
        clearErrorMessage: actions.clearErrorMessage,
        getDraft: actions.getDraft,
        getTagSettings: settingsActions.getTagSettings,
        hasChangedSinceSaved: actions.hasChangedSinceSaved,
        publishEvent: actions.publishEvent,
        saveEvent: actions.saveEvent,
        updateUrl: actions.updateUrl,
        updateUrlComplete: actions.updateUrlComplete,
        redirectTo: push
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(EventCreation);
