import * as React from "react";

import { Audience } from "modules/audiences/models";

import Chip from "@mui/material/Chip";

import CloseIcon from "@mui/icons-material/Close";


import "./chips.sass";


const AudienceChips: React.FunctionComponent<ComponentProps> = props => {
  if (!props.audienceIds || !props.audienceIds.length) {
    if (props.hideAll)
      return <React.Fragment></React.Fragment>;

    return (
      <div className="audience-chips">
        <Chip
          label="All users"
          disabled={true}
          className="all-users-audience"
        />
      </div>
    );
  }

  const matchedAudiences: Audience[] = props.audienceIds.map((audienceId) => {
    const matchedAudience = props.audiences.find((audience) => audience.id === audienceId);
    if (!matchedAudience)
      return {
        id: "",
        displayName: "",
        description: "",
        enabled: false,
        type: ""
      };
    return matchedAudience;
  }).filter((audience) => !!audience.id);

  return (
    <div className="audience-chips">
      {matchedAudiences.map((audience) => (
        <Chip
          key={audience.id}
          label={`${audience.displayName}${audience.enabled ? "" : " (disabled)"}`}
          onDelete={() => props.onDelete(audience.id)}
          deleteIcon={<CloseIcon />}
        />
      ))}
    </div>
  );
}

interface ComponentProps {
  audienceIds: string[] | undefined;
  audiences: Audience[];
  hideAll?: boolean;
  onDelete: (audienceId: string) => void;
}

export default AudienceChips;