import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";

import { Tag } from "./models";

import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";

import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ClearIcon from "@mui/icons-material/Clear";
import LockIcon from "@mui/icons-material/Lock";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Disclaimer from "../disclaimer";
import { tagsApi } from "api/instances";


interface TagList {
  id: string;
  name: string;
  tags: Tag[];
}


class Tags extends React.Component<PropsWithRedux, ComponentState> {
  constructor(props: PropsWithRedux) {
    super(props);

    this.state = {
      showTagsList: false,
      tags: props.tags,
      tagsList: this.getTagsList(),
      onlyRestricted: false
    };
  }

  public render() {
    const hasMixedRestrictions: boolean = this.hasMixedRestrictions();
    const hasMentionWarning: boolean = this.needsMentionWarning();

    return (
      <React.Fragment>
        <div className="section-card-heading">Topics</div>
        <div className="tags-list">
          {this.props.tags.map((tag) =>
            <Paper key={tag.id}>
              <span>{tag.name}</span>
              <IconButton size="small" onClick={() => this.onRemoveTag(tag)}>
                <ClearIcon fontSize="small" />
              </IconButton>
            </Paper>
          )}
        </div>
        <div className="add-new-item">
          <Button variant="text" color="primary" size="small" startIcon={<AddIcon fontSize="small" />} onClick={this.onOpenTagsList}>Add Topics</Button>
        </div>
        {hasMixedRestrictions &&
          <Disclaimer
            icon={<InfoOutlinedIcon />}
            text={
              <React.Fragment>
                <span className="mixed-restrictions-warning">
                  Selecting both a restricted and a non-restricted topic could make the content available to unintended users.
                </span>
              </React.Fragment>
            }
          />
        }
        {hasMentionWarning &&
          <Disclaimer
            icon={<InfoOutlinedIcon />}
            text={
              <React.Fragment>
                <span className="mixed-restrictions-warning">
                  @Mentioning someone in a restricted post will not send a notification to the user if they do not have access to the restricted topic.
                </span>
              </React.Fragment>
            }
          />        }
        <Drawer anchor="right" open={this.state.showTagsList} onClose={this.onApply} classes={{ paper: "tags-list-drawer" }}>
          <Paper square elevation={0} className="tags-list-header">
            <IconButton onClick={this.onApply} size="large">
              <ArrowBackIcon />
            </IconButton>
            <div>Select topics</div>
          </Paper>
          <List>
            {this.state.tagsList.map((tagGroup) => {
              const tagGroupChecked: boolean = tagGroup.tags.filter((tag) => !!this.state.tags.find((selectedTag) => selectedTag.id === tag.id)).length === tagGroup.tags.length;
              return (
                <React.Fragment key={tagGroup.id}>
                  <ListItem dense button onClick={() => this.onSelectTagGroup(tagGroup, !tagGroupChecked)}>
                    <ListItemIcon className="tags-list-checkbox">
                      <Checkbox
                        edge="start"
                        tabIndex={-1}
                        disableRipple
                        size="small"
                        color="primary"
                        checked={tagGroupChecked}
                      />
                    </ListItemIcon>
                    <ListItemText primary={tagGroup.name} classes={{ primary: "tags-list-tag-group-text" }} />
                  </ListItem>
                  <List disablePadding className="tags-list-tags">
                    {tagGroup.tags.map((tag) =>
                      <ListItem key={tag.id} dense button onClick={() => this.onSelectTag(tag)}>
                        <ListItemIcon className="tags-list-checkbox">
                          <Checkbox
                            edge="start"
                            tabIndex={-1}
                            disableRipple
                            size="small"
                            color="primary"
                            checked={!!this.state.tags.find((selectedTag) => selectedTag.id === tag.id)}
                          />
                        </ListItemIcon>
                        <ListItemText primary={tag.name} />
                        {tag.restricted && <LockIcon fontSize="small" className="restricted-tag-icon" />}
                      </ListItem>
                    )}
                  </List>
                </React.Fragment>
              );
            })}
          </List>
          <Divider light />
          <div className="reset-tags-list">
            <Button variant="text" color="primary" onClick={this.onReset}>Reset selection</Button>
          </div>
        </Drawer>
      </React.Fragment>
    );
  }


  private getTagsList = (): TagList[] => {
    let tagsList: TagList[] = [];

    tagsApi.getUserTags(true).then(tagGroups => {
      tagGroups.map(tagGroup => {
        const tags: Tag[] = tagGroup.tags.filter((tag) => !tag.disabled);
        if (!!tags.length) {
          tagsList.push({
            id: tagGroup.id,
            name: tagGroup.name,
            tags: tags
          });
        }
        return tagGroup;
      });
    }).catch((err) => {
      tagsList = [];
    });

    return tagsList;
  }


  private hasMixedRestrictions = (): boolean => {
    if (!this.props.tags || !this.props.tags.length)
      return false;

    let nonRestrictedFound: boolean = false;
    let restrictedFound: boolean = false;

    this.state.tagsList.map((tagGroup) => {
      tagGroup.tags.map((tag) => {
        if (this.props.tags.findIndex((selectedTag) => selectedTag.id === tag.id) !== -1) {
          if (tag.restricted)
            restrictedFound = true;
          else
            nonRestrictedFound = true;
        }
        return tag;
      });
      return tagGroup;
    });

    return nonRestrictedFound && restrictedFound;
  }

  private needsMentionWarning = (): boolean => {
    if (!this.props.tags || !this.props.tags.length)
      return false;

    let nonRestrictedFound: boolean = false;
    let restrictedFound: boolean = false;

    this.state.tagsList.map((tagGroup) => {
      tagGroup.tags.map((tag) => {
        if (this.props.tags.findIndex((selectedTag) => selectedTag.id === tag.id) !== -1) {
          if (tag.restricted)
            restrictedFound = true;
          else
            nonRestrictedFound = true;
        }
        return tag;
      });
      return tagGroup;
    });

    //Only return true if there's a mention in the text and there's ONLY restricted tags.
    return restrictedFound && !nonRestrictedFound && this.props.hasMention;
  }

  private onApply = () => {
    this.props.onChange(this.state.tags);
    this.onCloseTagsList();
  }

  private onCloseTagsList = () => {
    this.setState({ ...this.state, showTagsList: false });
  }

  private onOpenTagsList = () => {
    this.setState({ ...this.state, showTagsList: true, tags: this.props.tags });
  }

  private onReset = () => {
    this.setState({ ...this.state, tags: this.props.tags });
  }

  private onRemoveTag = (tag: Tag) => {
    this.props.onChange(this.props.tags.filter((selectedTag) => selectedTag.id !== tag.id));
  }

  private onSelectTag = (tag: Tag) => {
    const hasSelectedTag: boolean = !!this.state.tags.find((selectedTag) => selectedTag.id === tag.id);
    if (hasSelectedTag)
      this.setState({ ...this.state, tags: this.state.tags.filter((selectedTag) => selectedTag.id !== tag.id) });
    else
      this.setState({ ...this.state, tags: this.state.tags.concat([{ id: tag.id, name: tag.name }]) });
  }

  private onSelectTagGroup = (tagGroup: TagList, checked: boolean) => {
    let tags: Tag[] = this.state.tags.slice();

    if (checked) {
      tagGroup.tags.map((tag) => {
        if(tags.filter(t => t.id === tag.id).length === 0) {
          tags.push(tag);
        }
        return tag;
      });
      tags = tags.filter((tag) => tag);
    } else {
      tagGroup.tags.map((tag) => {
        tags = tags.filter((tagToFilter) => tagToFilter.id !== tag.id);
        return tag;
      });
    }

    this.setState({ ...this.state, tags });
  }
}


interface ComponentProps {
  tags: Tag[];
  onChange: (tags: Tag[]) => void;
  hasMention: boolean;
}

interface ComponentState {
  showTagsList: boolean;
  tags: Tag[];
  tagsList: TagList[];
  onlyRestricted: boolean;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    tagSettings: state.settings.tagSettings
  })
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(Tags);
