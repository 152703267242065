import { Action } from "redux";
import { ActionCreator, CompleteAction } from "typedActions";
import { CategoryTagModel, CategoryTagsListItem, CategoryTagsListingPage, UserCategoryTag } from "./models";

export const CREATE_NEW_CATEGORY_TAG = "CREATE_NEW_CATEGORY_TAG";
export interface AddNewCategoryTagAction extends Action {
    toUpload: CategoryTagModel;
}
export const CreateAddNewCategoryTagAction = ActionCreator<AddNewCategoryTagAction>(CREATE_NEW_CATEGORY_TAG);

export const CREATE_NEW_CATEGORY_TAG_COMPLETE = "CREATE_NEW_CATEGORY_TAG_COMPLETE";
export interface AddNewCategoryTagComplete extends CompleteAction {};
export const CreateAddNewCategoryTagComplete = ActionCreator<AddNewCategoryTagComplete>(CREATE_NEW_CATEGORY_TAG_COMPLETE);

export const EDIT_CATEGORY_TAG = "EDIT_CATEGORY_TAG";
export interface EditCategoryTagAction extends Action {
    toUpload: CategoryTagModel;
}
export const EditCategoryTagAction = ActionCreator<EditCategoryTagAction>(EDIT_CATEGORY_TAG);

export const EDIT_CATEGORY_TAG_COMPLETE = "EDIT_CATEGORY_TAG_COMPLETE";
export interface EditCategoryTagComplete extends CompleteAction {};
export const EditCategoryTagComplete = ActionCreator<EditCategoryTagComplete>(EDIT_CATEGORY_TAG_COMPLETE);

export const SHOW_NEW_CATEGORY_TAG = "SHOW_NEW_CATEGORY_TAG";
export interface ShowNewCategoryTag extends Action {};
export const ShowNewCategoryTagAction = ActionCreator<ShowNewCategoryTag>(SHOW_NEW_CATEGORY_TAG);

export const HIDE_NEW_CATEGORY_TAG = "HIDE_NEW_CATEGORY_TAG";
export interface HideNewCategoryTag extends Action {};
export const HideNewCategoryTagAction = ActionCreator<HideNewCategoryTag>(HIDE_NEW_CATEGORY_TAG);

export const SHOW_EDIT_CATEGORY_TAG = "SHOW_EDIT_CATEGORY_TAG";
export interface ShowEditCategoryTag extends Action {};
export const ShowEditCategoryTagAction = ActionCreator<ShowEditCategoryTag>(SHOW_EDIT_CATEGORY_TAG);

export const HIDE_EDIT_CATEGORY_TAG = "HIDE_EDIT_CATEGORY_TAG";
export interface HideEditCategoryTag extends Action {};
export const HideEditCategoryTagAction = ActionCreator<HideEditCategoryTag>(HIDE_EDIT_CATEGORY_TAG);

export const CLEAR_SHOULD_FETCH = "CLEAR_SHOULD_FETCH";
export interface ClearShouldFetch extends Action {};
export const ClearShouldFetch = ActionCreator<ClearShouldFetch>(CLEAR_SHOULD_FETCH);

export const GET_CATEGORY_TAGS = "GET_CATEGORY_TAGS";
export interface GetCategoryTags extends Action {};
export const GetCategoryTags = ActionCreator<GetCategoryTags>(GET_CATEGORY_TAGS);

export const GET_CATEGORY_TAGS_COMPLETE = "GET_CATEGORY_TAGS_COMPLETE";
export interface GetCategoryTagsComplete extends CompleteAction { page: CategoryTagsListingPage };
export const GetCategoryTagsComplete = ActionCreator<GetCategoryTagsComplete>(GET_CATEGORY_TAGS_COMPLETE);

export const GET_DISABLED_CATEGORY_TAGS = "GET_DISABLED_CATEGORY_TAGS";
export interface GetDisabledCategoryTags extends Action {};
export const GetDisabledCategoryTags = ActionCreator<GetDisabledCategoryTags>(GET_DISABLED_CATEGORY_TAGS);

export const GET_DISABLED_CATEGORY_TAGS_COMPLETE = "GET_DISABLED_CATEGORY_TAGS_COMPLETE";
export interface GetDisabledCategoryTagsComplete extends CompleteAction { page: CategoryTagsListingPage };
export const GetDisabledCategoryTagsComplete = ActionCreator<GetDisabledCategoryTagsComplete>(GET_DISABLED_CATEGORY_TAGS_COMPLETE);

export const GET_ENABLED_CATEGORY_TAGS = "GET_ENABLED_CATEGORY_TAGS";
export interface GetEnabledCategoryTags extends Action {};
export const GetEnabledCategoryTags = ActionCreator<GetEnabledCategoryTags>(GET_ENABLED_CATEGORY_TAGS); 

export const GET_ENABLED_CATEGORY_TAGS_COMPLETE = "GET_ENABLED_CATEGORY_TAGS_COMPLETE";
export interface GetEnabledCategoryTagsComplete extends CompleteAction { page: CategoryTagsListingPage };
export const GetEnabledCategoryTagsComplete = ActionCreator<GetEnabledCategoryTagsComplete>(GET_ENABLED_CATEGORY_TAGS_COMPLETE);

export const GET_USER_CATEGORY_TAGS_COMPLETE = "GET_USER_CATEGORY_TAGS_COMPLETE";
export interface GetUserCategoryTagsComplete extends CompleteAction { categoryTags: UserCategoryTag[] };
export const GetUserCategoryTagsComplete = ActionCreator<GetUserCategoryTagsComplete>(GET_USER_CATEGORY_TAGS_COMPLETE);

export const DELETE_CATEGORY_TAG = "DELETE_CATEGORY_TAG"
export interface DeleteCategoryTag extends Action {};
export const DeleteCategoryTag = ActionCreator<DeleteCategoryTag>(DELETE_CATEGORY_TAG);

export const DELETE_CATEGORY_TAG_COMPLETE = "DELETE_CATEGORY_TAG_COMPLETE"
export interface DeleteCategoryTagComplete extends CompleteAction {};
export const DeleteCategoryTagComplete = ActionCreator<DeleteCategoryTagComplete>(DELETE_CATEGORY_TAG_COMPLETE);

export const ENABLE_CATEGORY_TAG = "ENABLE_CATEGORY_TAG"
export interface EnableCategoryTag extends Action {};
export const EnableCategoryTag = ActionCreator<EnableCategoryTag>(ENABLE_CATEGORY_TAG);

export const ENABLE_CATEGORY_TAG_COMPLETE = "ENABLE_CATEGORY_TAG_COMPLETE"
export interface EnableCategoryTagComplete extends CompleteAction {};
export const EnableCategoryTagComplete = ActionCreator<EnableCategoryTagComplete>(ENABLE_CATEGORY_TAG_COMPLETE);

export const DISABLE_CATEGORY_TAG = "DISABLE_CATEGORY_TAG"
export interface DisableCategoryTag extends Action {};
export const DisableCategoryTag = ActionCreator<DisableCategoryTag>(DISABLE_CATEGORY_TAG);

export const DISABLE_CATEGORY_TAG_COMPLETE = "DISABLE_CATEGORY_TAG_COMPLETE"
export interface DisableCategoryTagComplete extends CompleteAction {};
export const DisableCategoryTagComplete = ActionCreator<DisableCategoryTagComplete>(DISABLE_CATEGORY_TAG_COMPLETE);

export const GET_CATEGORY_TAG_DOWNLOAD = "GET_CATEGORY_TAG_DOWNLOAD"
export interface GetCategoryTagDownload extends Action {};
export const GetCategoryTagDownload = ActionCreator<GetCategoryTagDownload>(GET_CATEGORY_TAG_DOWNLOAD);

export const GET_CATEGORY_TAG_DOWNLOAD_FROM_ANALYTICS = "GET_CATEGORY_TAG_DOWNLOAD_FROM ANALYTICS"
export interface GetCategoryTagDownloadFromAnalytics extends Action {};
export const GetCategoryTagDownloadFromAnalytics = ActionCreator<GetCategoryTagDownloadFromAnalytics>(GET_CATEGORY_TAG_DOWNLOAD_FROM_ANALYTICS);

export const SET_TAG_TO_EDIT = "SET_TAG_TO_EDIT"
export interface SetTagToEdit extends Action {toEdit: CategoryTagsListItem};
export const SetTagToEdit = ActionCreator<SetTagToEdit>(SET_TAG_TO_EDIT);

export const SET_TAG_TO_VIEW = "SET_TAG_TO_VIEW"
export interface SetTagToView extends Action {id: string, tag: CategoryTagsListItem | undefined};
export const SetTagToView = ActionCreator<SetTagToView>(SET_TAG_TO_VIEW);

export const DELETE_CATEGORY_TAGS = "DELETE_CATEGORY_TAGS"
export interface DeleteCategoryTags extends Action {};
export const DeleteCategoryTags = ActionCreator<DeleteCategoryTags>(DELETE_CATEGORY_TAGS);

export const DELETE_CATEGORY_TAGS_COMPLETE = "DELETE_CATEGORY_TAGS_COMPLETE"
export interface DeleteCategoryTagsComplete extends CompleteAction {};
export const DeleteCategoryTagsComplete = ActionCreator<DeleteCategoryTagsComplete>(DELETE_CATEGORY_TAGS_COMPLETE);

export const SHOW_VIEW_CATEGORY_TAG = "SHOW_VIEW_CATEGORY_TAG";
export interface ShowViewCategoryTag extends Action {};
export const ShowViewCategoryTag = ActionCreator<ShowViewCategoryTag>(SHOW_VIEW_CATEGORY_TAG);

export const HIDE_VIEW_CATEGORY_TAG = "HIDE_VIEW_CATEGORY_TAG";
export interface HideViewCategoryTag extends Action {};
export const HideViewCategoryTag = ActionCreator<HideViewCategoryTag>(HIDE_VIEW_CATEGORY_TAG);

export const LOAD_CATEGORY_TAG_ANALYTICS = "LOAD_CATEGORY_TAG_ANALYTICS";
export interface LoadCategoryTagAnalytics extends Action {};
export const LoadCategoryTagAnalytics = ActionCreator<LoadCategoryTagAnalytics>(LOAD_CATEGORY_TAG_ANALYTICS);

export const LOAD_CATEGORY_TAG_ANALYTICS_COMPLETE = "LOAD_CATEGORY_TAG_ANALYTICS_COMPLETE";
export interface LoadCategoryTagAnalyticsComplete extends CompleteAction {};
export const LoadCategoryTagAnalyticsComplete = ActionCreator<LoadCategoryTagAnalyticsComplete>(LOAD_CATEGORY_TAG_ANALYTICS_COMPLETE);

export const DOWNLOAD_ALL_CATEGORY_TAGS = "DOWNLOAD_ALL_CATEGORY_TAGS";
export interface DownloadAllCategoryTags extends Action {};
export const DownloadAllCategoryTags = ActionCreator<DownloadAllCategoryTags>(DOWNLOAD_ALL_CATEGORY_TAGS);

export const DOWNLOAD_CATEGORY_TAGS = "DOWNLOAD_CATEGORY_TAGS";
export interface DownloadCategoryTags extends Action {};
export const DownloadCategoryTags = ActionCreator<DownloadCategoryTags>(DOWNLOAD_CATEGORY_TAGS);

export const CLEAR_ERROR_MESSAGE = "CLEAR_ERROR_MESSAGE";
export interface ClearErrorMessage extends Action {};
export const ClearErrorMessage = ActionCreator<ClearErrorMessage>(CLEAR_ERROR_MESSAGE);