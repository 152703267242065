import React from "react";

import { HomeScreenPlatform, IHomeScreenConfig } from "modules/homeScreens/models";
import Preview, { IPreview, PreviewType } from "modules/common/components/authoring/dialogs/preview";
import { ContentBandsMobilePreview } from "../layouts/contentBandsMobilePreview";
import { ContentBandsTeamsPreview } from "../layouts/contentBandsTeamsPreview";
import { IMobileConfig } from "modules/settings";
import { IContentBand, IContentBandConfig, MobileAppPages } from "modules/contentBands/models";

interface IContentBandsPreviewProps {
    contentBands: IContentBand[];
    activeLcid: string;
    homeScreenConfigs: IHomeScreenConfig[];
    isLoading: boolean;
    config: IContentBandConfig;
    open: boolean;
    onClose: () => void;
    mobileConfig?: IMobileConfig;
}

export const ContentBandsPreview: React.FC<IContentBandsPreviewProps> = ({
    contentBands,
    homeScreenConfigs,
    activeLcid,
    isLoading,
    config,
    onClose,
    mobileConfig,
    open,
}) => {
    const getPreviews = (): IPreview[] => {
        let results: IPreview[] = [
            {
                type: PreviewType.Mobile,
                label: "MOBILE APP",
                component: (
                    <ContentBandsMobilePreview isLoading={isLoading} contentBands={contentBands} activeLcid={activeLcid} config={config} />
                ),
            },
        ];

        if (homeScreenConfigs && homeScreenConfigs.some((c: IHomeScreenConfig) => c.platform === HomeScreenPlatform.Teams)) {
            results.push({
                type: PreviewType.Teams,
                label: "MS TEAMS",
                component: (
                    <ContentBandsTeamsPreview config={config} isLoading={isLoading} contentBands={contentBands} activeLcid={activeLcid} />
                ),
            });
        }
        return results;
    };

    return (
        <Preview
            isLoading={isLoading}
            open={open}
            onClose={onClose}
            appName={mobileConfig?.appName}
            activePage={MobileAppPages.Home}
            previews={getPreviews()}
        />
    );
};
