import * as React from "react";

import { AttendanceFilterValues } from "../../models";

import AuthoringSearch from "modules/common/components/filters/authoringSearch";
import FilterContainer from "modules/common/components/filters/filterContainer";

import TextField from "@mui/material/TextField";


class AttendanceFilters extends React.Component<ComponentProps, ComponentState> {
  constructor(props: ComponentProps) {
    super(props);

    this.state = {
      canSearch: false
    };
  }

  public render() {
    const { filters } = this.props;

    return (
      <FilterContainer
        filters={
          <React.Fragment>
            <TextField
              variant="outlined"
              size="small"
              value={filters.name}
              placeholder="Search names"
              onChange={this.onChangeName}
              onKeyUp={this.onKeyUp}
              className="text-to-search"
            />
            <TextField
              variant="outlined"
              size="small"
              value={filters.email}
              placeholder="Search e-mails"
              onChange={this.onChangeEmail}
              onKeyUp={this.onKeyUp}
              className="text-to-search"
            />
          </React.Fragment>
        }
        filterCommands={
          <React.Fragment>
            <AuthoringSearch
              canSearch={this.state.canSearch}
              onApplyFilters={this.onApplyFilters}
              onClearFilters={this.onClearFilters}
            />
          </React.Fragment>
        }
      />
    );
  }


  private onApplyFilters = () => {
    this.props.onApplyFilters();
    this.setCanSearch();
  }

  private onChangeFilters = (value: Partial<AttendanceFilterValues>) => {
    this.props.onChangeFilters({ ...this.props.filters, ...value });
    this.setCanSearch();
  }

  private onChangeEmail = (event) => {
    this.onChangeFilters({ email: event.target.value });
  }

  private onChangeName = (event) => {
    this.onChangeFilters({ name: event.target.value });
  }
  
  private onKeyUp = (key) => {
    if (key.keyCode === 13)
      this.onApplyFilters();
  }

  private onClearFilters = () => {
    this.clearCanSearch();
    this.props.onClearFilters();
  }


  private clearCanSearch = () => {
    this.setState({ canSearch: false });
  }

  private setCanSearch = () => {
    this.setState({ canSearch: true });
  }
}
  

interface ComponentProps {
  filters: Partial<AttendanceFilterValues>;
  onApplyFilters: () => void;
  onChangeFilters: (filters: Partial<AttendanceFilterValues>) => void;
  onClearFilters: () => void;
}

interface ComponentState {
  canSearch: boolean;
}

export default AttendanceFilters;