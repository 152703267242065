import { SparrowApi } from "./network/adapters/SparrowApi";
import { ApiRequest } from "./network";
import { StringDict } from "@azure/msal-common";

export class MultitenancyApi {
  constructor(private _sparrowApi: SparrowApi) {
  }

  public getTenants = (): ApiRequest<StringDict> => {
    return new ApiRequest<StringDict>(
      this._sparrowApi.CreateRequest()
        .get('api/v1/tenants')
    );
  }
}
