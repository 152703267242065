import { Button, Dialog, DialogContent, DialogTitle, IconButton, Snackbar, Typography } from '@mui/material';
import * as React from 'react';
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import SaveIcon from "@mui/icons-material/SaveAlt";
import BackArrow from "@mui/icons-material/ArrowBackIos";
import UserCSVUploader from "./userCSVUploader";
import { connect, ConnectedProps } from "react-redux"
import { GlobalApplicationState } from "globalApplicationState"
import UserInsightsBlockTiny from './userInsightsBlockTiny';
import { actions } from "modules/users";
import LoadingSpinner from 'modules/common/components/loadingSpinner';
import { Alert } from '@mui/material';
import { emailRegex } from 'utils/regexUserValidation';
import { SeverityOptions } from 'utils/muiHelpers';
import { FileDownloader } from 'utils/fileDownloader';

interface ComponentProps {
    isOpen: boolean;
    hideCSVInvite: () => void;
    showCSVInvite: () => void;
    showDrawer: () => void;
}

const BulkSocialDialog: React.FunctionComponent<PropsWithRedux> = (props) => {

    const [valid, setValid] = React.useState<string[]>([]);
    const [invalidEmails, setInvalidEmails] = React.useState<string[]>([]);
    const [duplicateEmails, setDuplicateEmails] = React.useState<string[]>([]);
    
    const [verifyOpen, setVerifyOpen] = React.useState(false);
    const [sentOpen, setSentOpen] = React.useState(false);

    const [validNumber, setValidNumber] = React.useState(0);
    const [invalidNumber, setInvalidNumber] = React.useState(0);
    const [dupeNumber, setDupeNumber] = React.useState(0);

    const [alertMessage, setAlertMessage] = React.useState("");
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [alertSeverity, setAlertSeverity] = React.useState<SeverityOptions>("info");

    const parseCSV = async (file: File) => {
        await new Promise<void>((resolve) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                const contents = reader.result!.toString().trim();
                var rows = contents.split("\n");

                if(rows.length > 0)
                {
                    var rowOne = rows[0].split(",");
                    if (rowOne.length !== 1) {
                        setNewAlert("Invalid CSV format detected", true, "error");
                        return;
                    }

                    var header = rowOne[0].toLowerCase().trim();
                    if (header !== "emails" && header !== "email")
                    {
                        setNewAlert("Invalid CSV format detected", true, "error");
                        return;
                    }
                }

                for (var row of rows) {
                    var value = row.split(",")[0].toLowerCase().trim();
                    if (value === "emails" || value === "email") { // skip header, they might format it as emails or email
                      continue;
                    }

                    if (valid.includes(value))
                    {
                        duplicateEmails.push(value);
                    }

                    else if (!validateEmail(value))
                    {
                        invalidEmails.push(value)
                    }

                    else
                    {
                        valid.push(value)
                    }
                }
                resolve();
            };
            reader.readAsText(file);
        });

        props.hideCSVInvite();
        setValidNumber(valid.length);
        setInvalidNumber(invalidEmails.length);
        setDupeNumber(duplicateEmails.length);
        setVerifyOpen(true);
    };
    
    // https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
    const validateEmail = (email: string) => {
        return emailRegex.test(String(email).toLowerCase());
    };

    const exitDialog = () => {
        setValid([]);
        setInvalidEmails([]);
        setDuplicateEmails([]);

        props.hideCSVInvite();
    }

    const swapToCSVInvite = () => {
        setVerifyOpen(false);
        setValid([]);
        setInvalidEmails([]);
        setDuplicateEmails([]);
        props.showCSVInvite();
    }

    const downloadSample = () => {
        let rows = [
            ["email"],
            ["sample1@email.com"],
            ["sample2@email.com"]
        ]

        const file = {
            name: "sampleBulkUpload.csv",
            type: "data:text/csv;charset=utf-8,%EF%BB%BF"
        }
        new FileDownloader(file).downloadSampleCSV(rows);
    }

    const sendInvitesToImported = () => {
        props.sendBulkInvite(valid);
        setVerifyOpen(false)
        setSentOpen(true)
        setValid([]);
        setInvalidEmails([]);
        setDuplicateEmails([]);
    }
    
    const abandonDialogAndResetValues = () => {
        setValid([]);
        setInvalidEmails([]);
        setDuplicateEmails([]);
        setVerifyOpen(false)
    }

    const swapToUserInvites = () => {
        setSentOpen(false);
        props.showDrawer();
    }

    const closeVerifyInvite = () => {
        setValid([]);
        setInvalidEmails([]);
        setDuplicateEmails([]);
        setVerifyOpen(false);
    }

    const downloadSocialBulkDetails = async () => {
        const file = {
            name: `${"bulk-upload-social-users"}-${new Date().toISOString()}.csv`
        }
        props.downloadSocialBulkCSV(valid, invalidEmails, duplicateEmails)
            .then((data) => new FileDownloader(file).downloadBlob(data))
    }

    let hideSnackbar = () => {
        setAlertOpen(false);
        setAlertMessage("");
    }

    let setNewAlert = (message: string, open: boolean, type: SeverityOptions) => {
        setAlertOpen(open)
        setAlertMessage(message)
        setAlertSeverity(type)
    }

    return (
        <div>
            <Dialog open={props.isOpen} onClose={exitDialog} maxWidth={"md"} fullWidth={true}>
                <DialogTitle className="external-user-dialog-formatting">
                    <div className="external-user-dialog-header-title">
                        <Typography variant="h2">Add new social users in bulk</Typography>
                        <IconButton onClick={exitDialog} size="large">
                            <CloseIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent className="external-user-dialog-content" style={{paddingBottom: "20px"}}>
                    <div style={{display: "flex", flexDirection: "row", backgroundColor: "#F2F2F2"}}>
                        <InfoIcon htmlColor="#E6911A" className="icon-formatting"/>
                        <span className="user-dialog-description-text">
                        <b>Social Users</b> are created once the invitees successfully log in using the link in the invitation email. Once the invitation link is opened, the user can create their account through any preferred social account provider (Google, Facebook, LinkedIn or Apple).
                        </span>
                    </div>

                    <div style={{paddingTop: "22px", paddingBottom: "20px"}}>
                        <UserCSVUploader parseCSV={parseCSV} />
                    </div>

                    <Button style={{float: "left"}} color="primary" variant="outlined" startIcon={<SaveIcon/>} onClick={downloadSample}>Download csv template</Button>
                    <Button style={{float: "right"}} onClick={exitDialog}>Cancel</Button>
                </DialogContent>

                <Snackbar open={alertOpen} autoHideDuration={3000} anchorOrigin={{vertical: "top", horizontal: "right"}} onClose={hideSnackbar}>
                    <Alert severity={alertSeverity}>{alertMessage}</Alert>
                </Snackbar>

            </Dialog>

            <Dialog open={verifyOpen} onClose={closeVerifyInvite} maxWidth={"md"} fullWidth={true}>
                <DialogTitle className="external-user-dialog-formatting">
                    <div className="external-user-dialog-header-title">
                        <Typography variant="h2">Add new social users in bulk</Typography>
                        <IconButton onClick={closeVerifyInvite} size="large">
                            <CloseIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent className="external-user-dialog-content" style={{paddingBottom: "20px"}}>
                    <div style={{display: "flex", flexDirection: "row", backgroundColor: "#F2F2F2", marginBottom: "4px"}}>
                        <InfoIcon htmlColor="#366531" className="icon-formatting"/>
                        <span className="user-dialog-description-text">
                            <b style={{color: "#366531"}}>CSV successfully scanned!</b> Download scan results <u style={{cursor: "pointer", color: "#6795BC"}} onClick={downloadSocialBulkDetails}>here</u>.
                        </span>
                    </div>

                    <div className="invitations-data-boxes">
                        <UserInsightsBlockTiny stats={validNumber} title='Valid email addresses'/>
                        <UserInsightsBlockTiny stats={invalidNumber} title='Invalid email addresses'/>
                        <UserInsightsBlockTiny stats={dupeNumber} title='Duplicate entries'/>
                    </div>

                    <div style={{fontSize: "14px", paddingBottom: "20px"}}>
                        <span>Invitations will be sent to valid email addresses only. Click "Send Invite" to send invites.</span>
                        <br/>
                        <span>If an email already exists in the system, an invite will not be sent.</span>
                        <br/>
                        <span>To see a detailed breakdown, download scan results above.</span>
                    </div>

                    <Button style={{float: "left"}} color="primary" startIcon={<BackArrow fontSize="small"/>} onClick={swapToCSVInvite}>Replace CSV file</Button>
                    <Button style={{float: "right", marginLeft: "10px"}} color="primary" variant="contained" onClick={sendInvitesToImported}>Send invite</Button>
                    <Button style={{float: "right"}} onClick={abandonDialogAndResetValues}>Cancel</Button>
                </DialogContent>
            </Dialog>

            <Dialog open={sentOpen} onClose={() => setSentOpen(false)} maxWidth={"sm"} fullWidth={true}>
                    <DialogTitle className="external-user-dialog-formatting">
                        <div className="external-user-dialog-header-title">
                            {props.invitingUser ? <Typography variant="h2">Sending invites...</Typography> : <Typography variant="h2">Successfully sent!</Typography> }
                            <IconButton onClick={() => setSentOpen(false)} size="large">
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </DialogTitle>
                    <DialogContent className="external-user-dialog-content" style={{paddingBottom: "20px"}}>
                        {props.invitingUser ?
                            <LoadingSpinner size={'14px'} />

                        : <div>
                            <div>
                                <span style={{fontSize: "14px"}}>You've successfully sent invites to {props.invitedUserCount} new user(s)! The invitees are required to accept the invitation, and log in using the unique link to set up and complete their user profile.</span>
                            </div>
                            <div style={{paddingTop: "50px"}}>
                                <Button onClick={swapToUserInvites} style={{float: "right", marginLeft: "6px"}} color="primary" variant="contained">Manage User Invites</Button>
                                <Button onClick={() => setSentOpen(false)} style={{float: "right"}}>Back to users & permissions</Button>
                            </div>
                        </div>
                        }
                    </DialogContent>
                </Dialog>
        </div>
    );
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        invitingUser: state.users.invitingUser,
        invitedUserCount: state.users.invitedUsers,
    }),
    {
        sendBulkInvite: actions.inviteBatchUsers,
        downloadSocialBulkCSV: actions.getBulkInviteReportCSV
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(BulkSocialDialog);
