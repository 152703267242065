import React, { useEffect, useState } from "react";

import moment from 'moment';
import { Button, Divider } from "@mui/material";

import { PostOverview } from "modules/posts/models";
import HeroBanner from "../../../common/components/heroBanner";
import PostTypeBanner from "modules/common/components/banners/postTypeBanner";
import BreakingFeaturedBanner from "../banners/breakingFeaturedBanner";
import { postsApi } from "api/instances";
import { ILanguage } from "modules/localization/models";
import { getTranslatedBody, getTranslatedComplianceText, getTranslatedTitle } from "utils/getTranslatedContent";
import { parseAnchors } from "utils/htmlUtils";
import { lcidMappings } from "modules/resources";
import Compliance from "./compliance/compliance";
import Reactions from "./reactions/reactions";
import Comments from "./comments/comments";
import { ClientSettingsReaction } from "modules/settings";
import { CustomCss } from "modules/common/components/customCss";

interface IPostContentViewProps {
    post: PostOverview;
    defaultLcid: string;
    lcidMappings: lcidMappings;
    reactions: ClientSettingsReaction[];
    reactionsEnabledOnTenant: boolean;
    commentsEnabledOnTenant: boolean;
    showComments?: boolean; // if post has comments, do we display them?
    showReactions?: boolean; // if post has reaction, do we display them?
    tinyMceCustomCssEnabled: boolean;
}

const PostContentView: React.FunctionComponent<IPostContentViewProps> = ({
    post,
    lcidMappings,
    defaultLcid,
    reactions,
    reactionsEnabledOnTenant,
    commentsEnabledOnTenant,
    showComments = true,
    showReactions = true,
    tinyMceCustomCssEnabled
}) => {
    const [activeLcid, setActiveLcid] = useState<string>(defaultLcid);
    const [availableLanguages, setAvailableLanguages] = useState<Partial<ILanguage>[]>();

    useEffect(() => {
        const availableLcids: string[] = Object.keys(post.translatedContent!);
        const availableLanguages: Partial<ILanguage>[] = availableLcids.map((lcid) => ({
            language: lcidMappings[lcid]
                ? lcidMappings[lcid].languageShort
                : lcid,
            lcid
        }));
        const activeLcid: string = !!post.translatedContent![post.preferredLCID]
            ? post.preferredLCID
            : defaultLcid;

        setAvailableLanguages(availableLanguages);
        setActiveLcid(activeLcid);

    }, [post.translatedContent, defaultLcid, post.preferredLCID, lcidMappings]);

    const renderLanguages = (): JSX.Element => {
        if (!availableLanguages || availableLanguages.length < 2)
            return <></>;

        return (
            <div className="available-languages">
                <span className="emphasis">Available In: </span>
                {availableLanguages
                    .map<React.ReactNode>(({ language, lcid = defaultLcid }) =>
                        <span
                            key={lcid}
                            onClick={() => setActiveLcid(lcid)}
                            className={lcid === activeLcid ? "" : "inactive-language"}
                        >
                            {language}
                        </span>
                    )
                    .reduce((prev, curr) => [prev, ", ", curr])
                }
            </div>
        );
    }

    const title = getTranslatedTitle(post.translatedContent, activeLcid);

    return (
        <>
            <CustomCss enabled={tinyMceCustomCssEnabled} />
            <div className="post-preview-page">
                <HeroBanner useCanvas={post.status === "draft"} bannerColor={post.bannerColor} image={post.image} imageUrl={post.imageUrl} attachedContent={post.attachedContent} />
                <PostTypeBanner full {...post!} hideCompliance lcid={activeLcid} title={title} />
                <div style={{ backgroundColor: "#ffffff" }}>
                    <div className="page-content" style={{ paddingTop: '10px' }}>
                        <div className="title">{title}</div>
                        <div className="published-time">Published {moment(post.datePublished!).format("MMMM D, YYYY")}</div>
                        {!!post.tags && !!post.tags.length &&
                            <div className="tags" style={{ marginBottom: 14 }}>Topics: {post.tags.map((tag) => tag.name).join(", ")}</div>
                        }
                        <BreakingFeaturedBanner activeLcid={activeLcid} isBreaking={post.isBreaking} isFeatured={post.isFeatured} />
                        <div className="author">
                            <Divider light style={{ marginBottom: 12 }} />
                            <div className="author-details">
                                <div className="author-avatar" style={{ backgroundColor: post && post.author ? post.author.avatar?.color : "" }}>{post.author ? post.author.name[0] : ""}</div>
                                <div>
                                    <div>Post By</div>
                                    {post && post.author && post.author.email
                                        ? <a href={`mailto:${post.author.email}`} className="author-name">{post.author.name}</a>
                                        : <div className="author-name"> {post && post.author ? post.author.name : ""}</div>
                                    }
                                </div>
                            </div>
                            <Divider light style={{ marginTop: 39 }} />
                        </div>
                        {renderLanguages()}
                        <div dangerouslySetInnerHTML={{ __html: parseAnchors(getTranslatedBody(post.translatedContent, activeLcid)) }} className="body"></div>
                        {!!post.fileAttachments && !!post.fileAttachments.length &&
                            <div className="documents">
                                <div className="documents-label">Documents ({post.fileAttachments.length})</div>
                                <div>
                                    {post.fileAttachments.map((fileAttachment) =>
                                        <Button key={fileAttachment.blobId} variant="outlined" size="small" onClick={() => postsApi.DownloadFile(fileAttachment.fileUrl!, fileAttachment.fileName, fileAttachment.fileExtension)}>{fileAttachment.fileName}</Button>
                                    )}
                                </div>
                            </div>
                        }
                        <Compliance
                            postId={post.id}
                            complianceText={getTranslatedComplianceText(post.translatedContent, activeLcid)}
                            postType={post.postType}
                        />
                    </div>
                    {showReactions &&
                        <Reactions
                            preview
                            reactions={reactions}
                            reactionsEnabledOnTenant={reactionsEnabledOnTenant}
                            reactingEnabledOnPost={post.reactingEnabled}
                            postType={post.postType}
                            stats={{ postId: post.id, commentCount: 0, lastOpenTime: "", lastReadTime: "", complianceTime: "", reactions: [], viewCount: 0 }}
                        />}
                    {showComments &&
                        <div style={{ padding: "0 20px" }}>
                            <Comments
                                comments={[]}
                                isSubscribed={false}
                                onUpdateComments={() => { }}
                                onUpdateCommentSubscriptions={() => { }}
                                preview
                                commentsEnabledOnPost={post.commentingEnabled}
                                commentsEnabledOnTenant={commentsEnabledOnTenant}
                                {...post}
                            />
                        </div>}
                </div>
            </div>
        </>
    );
}

export default PostContentView;
