import React, { CSSProperties, useState } from "react";

import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";

import { Theme } from "@mui/material/styles";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    optionsWrapper: {
      display: "inline-block",
      position: "relative"
    },
    options: {
      position: "absolute",
      top: 8
    }
  })
);

const MoreOptionsButton: React.FunctionComponent<ComponentProps> = props => {
  const [anchorEl, setAnchorEl] = useState(null);

  const classes = useStyles();

  const onClose = (ev) => {
    ev.stopPropagation();
    setAnchorEl(null);
  }

  return (
    <div className={classes.optionsWrapper} style={props.divStyle}>
      <Button
        variant={props.variant ?? "outlined"}
        color={(!props.color || props.color === 'default') ? "primary" : props.color}
        size={props.size ?? "medium"}
        disabled={props.disabled ?? false}
        onClick={(ev: any) => {
          ev.stopPropagation();
          setAnchorEl(ev.currentTarget);
        }}
        className={props.className}
      >
        {props.text || "More Options"}
        <ArrowDropDownIcon />
      </Button>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={(ev: any) => {
          onClose(ev);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        onClick={() => setAnchorEl(null)}
      >
        {props.children}
      </Popover>
    </div>
  );
}

interface ComponentProps {
  text?: string;
  onClick?: (ev) => void;
  // onSelectItem: (ev) => void;
  variant?: "text" | "outlined" | "contained" | undefined;
  color?: "inherit" | "primary" | "secondary" | "default" | undefined;
  size?: "small" | "medium" | "large" | undefined;
  disabled?: boolean;
  className?: string;
  divStyle?: CSSProperties;
}

export default MoreOptionsButton;