import { Pagination } from '@mui/material';
import { GlobalApplicationState } from 'globalApplicationState';
import Paging from 'modules/common/components/paging';
import React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { ROWS_PER_PAGE_COOKIE_NAMES } from 'utils/cookie';

interface IGalleryVFSPagingProps {
    maxNumItemsPerPage?: number;
    pageNumber: number;
    onPaginationPageChange: (event: React.ChangeEvent<unknown>, page: number) => void;
    isGridView: boolean;
    onChangePage: (page: number, rowsPerPage: number) => void;
    items: any[];
}

const GalleryVFSPaging: React.FC<PropsWithRedux> = props => {

    const onChangePage = (page: number, rowsPerPage: number) => {
        let newPageNumber = page + 1;

        props.onChangePage(newPageNumber, rowsPerPage);
    }

    return <>
        {
            props.maxNumItemsPerPage 
                ? <Pagination
                    color="primary" 
                    count={props.imageLibrary.totalPages} 
                    page={props.pageNumber} 
                    boundaryCount={2} 
                    showFirstButton 
                    showLastButton 
                    onChange={props.onPaginationPageChange}
                    className="media-gallery-pagination"
                />
                : <Paging
                    currentPage={props.pageNumber}
                    items={props.items}
                    totalItems={props.imageLibrary.totalCount}
                    totalPages={props.imageLibrary.totalPages}
                    onChangePage={onChangePage}
                    rowsCookieName={ROWS_PER_PAGE_COOKIE_NAMES.GALLERY}
                    rowName={props.isGridView ? "items" : "rows"}
                    alwaysShow
                />
        }
    </>
}

const connector = connect((state: GlobalApplicationState, ownProps: IGalleryVFSPagingProps)=>({ ...ownProps, imageLibrary: state.posts.imageLibrary }), {});

type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(GalleryVFSPaging);