import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { GlobalApplicationState } from "globalApplicationState";

import BasePage from "../common/basePage";
import Header from "../common/header";
import MainContent from "../common/mainContent";

import DocumentFeed from "modules/documents/components/document-feed/documentFeed";


const DocumentsPage: React.FunctionComponent<PropsWithRedux> = props => {
  return (
    <BasePage fullWidth>
      <Header
        title="Documents"
      />
      <MainContent id="document-feed" addPadding>
        <DocumentFeed selectedDocumentId={props.match.params.documentId} />
      </MainContent>
    </BasePage>
  );
}


interface RouteParams {
  documentId: string;
}

interface ComponentProps {}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps & RouteComponentProps<RouteParams>) => ({
    ...ownProps
  })
);
type PropsWithRedux = ConnectedProps<typeof connector>;
export default connector(DocumentsPage);