import * as React from "react";

import moment from "moment";


const FullStartDate: React.FunctionComponent<ComponentProps> = props => {
  moment.locale("en");
  
  const day: string = moment(props.eventStartTime).format("MMM D YYYY, ddd");
  const isAllDay: boolean = props.eventStartTime === props.eventEndTime;
  const time: string = isAllDay
    ? `, All Day`
    : `, ${moment(props.eventStartTime).format("h:mmA")}`;
  const startDate: string = day + time;

  return (
    <div className="full-datetime">
      {startDate}
    </div>
  );
}

interface ComponentProps {
  eventStartTime: string;
  eventEndTime: string;
}

export default FullStartDate;