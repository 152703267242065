import React from "react"
import { ConnectedProps, connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router";
import { GlobalApplicationState } from "globalApplicationState";

import Loading from "modules/common/components/loading";

import Account from "modules/profile/components/account";
import ProfileBanner from "modules/profile/components/profileBanner";


class User extends React.Component<PropsWithRedux, {}> {
  public render() {
    if (this.props.isInitialLoad || !this.props.userSettings)
      return <Loading />;
    
    return (
      <React.Fragment>
        <ProfileBanner />
        <Account />
      </React.Fragment>
    );
  }
}

interface ComponentProps {}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps & RouteComponentProps) => ({
    ...ownProps,
    isInitialLoad: state.profile.isInitialLoad,
    userSettings: state.profile.userSettings
  }),
  {
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default withRouter(connector(User));