import { ChonkyActions, FileBrowser, FileBrowserHandle, FileContextMenu, FileList, FileNavbar, FileToolbar } from 'chonky';
import { GlobalApplicationState } from 'globalApplicationState';
import React, { useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { ListHeaderDiv } from './listHeaderDiv';
import { attachDeleteClassNames, attachSearchMatchClassNames, changeChonkyCheckboxIcon } from './customStylingFunctions';
import { useFileActionHandler, useFiles, useFolderChain, useFileActions, useGridViewDebounce } from '../galleryVfsHooks';
import { CustomFileData, MoveFiles, MoveFilesWithSrc, SortGroupName, FilterGroupName } from '../models';
import { onNextFrame } from 'utils/onNextFrame';
import { ChonkyIconFA } from 'chonky-icon-fontawesome';
import GalleryVFSPaging from './galleryVFSPaging';
import { SearchTextField } from './searchTextField';
import * as ProfileActions from 'modules/profile/actionCreator';
import { DebounceState } from "utils/debounceHook";

interface IGalleryVFSProps {
    currentFolderId: string;
    onSingleSelect: (mediaFile: CustomFileData) => void;
    onMultiSelect: (mediaFiles: CustomFileData[]) => void;
    setOpenCreateFolderDialog: React.Dispatch<React.SetStateAction<boolean>>;
    onCurrentFolderChange: (parentId: string) => void;
    setOpenRenameDialog: React.Dispatch<React.SetStateAction<boolean>>;
    setFileToRename: React.Dispatch<React.SetStateAction<CustomFileData | null>>;
    setFilesToDelete: (value: React.SetStateAction<CustomFileData[]>) => void;
    verifyMediaForMove: (mediaFiles: MoveFiles) => Promise<void>;
    setIsInnerDragNDrop: React.Dispatch<React.SetStateAction<boolean>>;
    setMoveFilesForAppBar: React.Dispatch<React.SetStateAction<MoveFilesWithSrc>>;
    fileBrowserRef: React.RefObject<FileBrowserHandle>;
    searchValueDebounce: DebounceState<string>;
    showSearchTextField: boolean;
    showLoading: boolean;
    pageNumber: number;
    pageAmount: number;
    onChangePage: (page: number, rowsPerPage: number) => void;
    singleClickFileSelect?: boolean;
    onSingleClickSelect?: ((mediaFile: CustomFileData) => void);
    disableActions?: boolean;
    maxNumItemsPerPage?: number;
    currSortGroupName: SortGroupName,
    currFilterGroupName: FilterGroupName,
    toggleShowFoldersFirst: boolean,
    onFilterChange: (sortType?: SortGroupName, filterType?: FilterGroupName, showFoldersFirst?: boolean) => void;
    onClearSearchText: () => void,
    setPageNumber: React.Dispatch<React.SetStateAction<number>>,
}

const GalleryVFS: React.FC<PropsWithRedux> = (props) => {
    const [selectedFiles, setSelectedFiles] = useState(new Set<string>());
    const isGridViewValueDebounce = useGridViewDebounce(props.currentUser.showMediaGalleryGridView, props.saveUserSettings, props.currentUser);
    const searchResultsFetched = props.searchValueDebounce.debVal !== undefined && 
        props.searchValueDebounce.debVal !== ""  && 
        props.searchValueDebounce.callbackIsRun && 
        !props.imageLibrary.fetching;

    const files = useFiles(
        props.imageLibrary.imagesGalleryMap, 
        props.currentFolderId
    );

    const folderChain = useFolderChain(props.imageLibrary.imagesGalleryMap, props.imageLibrary.folderChain);

    const attachDeleteClassNamesOnNextFrame = () => onNextFrame(() => attachDeleteClassNames());
    
    const handleFileAction = useFileActionHandler(
        props.onSingleSelect,
        props.onMultiSelect,
        isGridViewValueDebounce.setDebVal,
        setSelectedFiles,
        props.onFilterChange,
        props.setOpenCreateFolderDialog,
        props.toggleShowFoldersFirst,
        props.imageLibrary.imagesGalleryMap,
        props.currentUser,
        props.onCurrentFolderChange,
        props.setOpenRenameDialog,
        props.setFileToRename,
        props.setFilesToDelete,
        props.verifyMediaForMove,
        props.setIsInnerDragNDrop,
        props.setMoveFilesForAppBar,
        attachDeleteClassNamesOnNextFrame,
        props.imageLibrary.currDir,
        props.singleClickFileSelect,
        props.onSingleClickSelect,
    );

    const fileActions = useFileActions(
        selectedFiles, 
        props.currSortGroupName,
        props.currFilterGroupName,
        props.currentUser.userId,
        props.toggleShowFoldersFirst,
        props.searchValueDebounce.debVal,
        props.disableActions,
    );

    useEffect(() => {
        attachDeleteClassNamesOnNextFrame();
    }, [fileActions]);

    useEffect(() => {
        onNextFrame(() => changeChonkyCheckboxIcon(props.toggleShowFoldersFirst));
    }, [props.currSortGroupName, props.toggleShowFoldersFirst]);

    useEffect(() => {
        if (searchResultsFetched) {
            onNextFrame(() => attachSearchMatchClassNames(props.searchValueDebounce.debVal, props.imageLibrary.chonkyFileData, isGridViewValueDebounce.debVal));
        }
    }, [props.searchValueDebounce.callbackIsRun, isGridViewValueDebounce.debVal, searchResultsFetched, props.imageLibrary.chonkyFileData]);

    return <>
        <FileBrowser
            fileActions={fileActions}
            onFileAction={handleFileAction}
            clearSelectionOnOutsideClick
            disableDefaultFileActions
            doubleClickDelay={400}
            ref={props.fileBrowserRef}
            defaultFileViewActionId={isGridViewValueDebounce.debVal ? ChonkyActions.EnableGridView.id : ChonkyActions.EnableListView.id}
            files={files}
            folderChain={folderChain}
            iconComponent={ChonkyIconFA}
        >
            <FileNavbar />
            <FileToolbar />
            <ListHeaderDiv 
                show={!isGridViewValueDebounce.debVal}
                runFn={() => {
                    if (!isGridViewValueDebounce.debVal && searchResultsFetched){
                        attachSearchMatchClassNames(props.searchValueDebounce.debVal, props.imageLibrary.chonkyFileData, isGridViewValueDebounce.debVal);}
                }}
                showKeywordsHeader={
                    searchResultsFetched && 
                    props.imageLibrary.chonkyFileData
                        .filter(file => 
                            file.keywords?.find(keyword => keyword.toLowerCase().includes(props.searchValueDebounce.debVal.toLowerCase()))
                        ).length > 0
                }
            />
            <FileList />
            <FileContextMenu />
        </FileBrowser>
        <SearchTextField
            show={props.showSearchTextField}
            onTextValueChange={(val) => {
                if (!props.showLoading) {
                    // upon first search, reset the page number to 1
                    if (props.searchValueDebounce.debVal === "" && val !== "")
                        props.setPageNumber(1);

                    props.searchValueDebounce.setDebVal(val);
                }
            }}
            onClearSearchText={props.onClearSearchText}
            textValue={props.searchValueDebounce.debVal}
        />
        <GalleryVFSPaging
            maxNumItemsPerPage={props.maxNumItemsPerPage}
            pageNumber={props.pageNumber}
            onPaginationPageChange={(_, page) => props.onChangePage(page, props.pageAmount)}
            items={files}
            onChangePage={props.onChangePage}
            isGridView={isGridViewValueDebounce.debVal}
        />
    </>
};

const connector = connect(
    (state: GlobalApplicationState, ownProps: IGalleryVFSProps)=>({
        ...ownProps,
        imageLibrary: state.posts.imageLibrary,
        currentUser: state.settings.currentUser,
    }), 
    {
        saveUserSettings: ProfileActions.saveUserSettings,
    }
)
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(GalleryVFS);