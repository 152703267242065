import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import { push } from "react-router-redux";

import Button from "@mui/material/Button";

import AddIcon from "@mui/icons-material/Add";


const NewEvent: React.FunctionComponent<PropsWithRedux> = props => {
  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={<AddIcon />}
      onClick={() => props.redirectTo("/" + props.tenant + "/admin/events/edit/new")
    }>
      New Event
    </Button>
  );
}

interface ComponentProps {
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    tenant: state.tenant.id
  }),
  {
    redirectTo: push
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(NewEvent);