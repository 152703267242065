import { ApiRequest } from './network';
import { SparrowApi } from 'api/network/adapters/SparrowApi';

export class TagsApi {
    constructor(private _sparrowApi: SparrowApi) {
    }

    public GetTagUsageStatus = (tagId: string): ApiRequest => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().get('/{tenant}/adminapi/v1/tags/{tagId}', { params: { tagId } })
        );
    }

    public BulkSubscription = (tagId: string, subscribe: boolean, userGroups:  {id: string, type: string}[]): ApiRequest => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().post('/{tenant}/adminapi/v1/tags/bulkSubscription/{tagId}', userGroups, { params: { tagId, subscribe }})
        );
    }

    public GetTopicUserCsv = (id: string) : ApiRequest => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
            .get('/{tenant}/adminapi/v1/tags/csv/{id}', { params: { id }})
        );
    }

    public getAssociatedAudiences = (tagIds: string[]): ApiRequest => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
            .post('/{tenant}/adminapi/v1/tags/audiences', tagIds)
        );
    }

    public getUserTags = (audienceLock: boolean = false): ApiRequest => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().get(`{tenant}/adminapi/v1/tags?audienceLocked=${audienceLock}`)
        );
    }

    public getTagPostCounts = (): ApiRequest => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().get('{tenant}/api/v1/tags/postCounts')
        );
    }

    public getTagEventCounts = (): ApiRequest => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().get('{tenant}/api/v1/tags/eventCounts')
        );
    }
}
