import * as React from "react";
import { ConnectedProps, connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { GlobalApplicationState } from "globalApplicationState";
import * as actions from "../actionCreator";
import * as audiencesActions from "modules/audiences/actionCreator";

import ErrorSnackbar from "modules/common/components/snackbars/errorSnackbar";
import LoadingOverlay from "modules/common/components/loadingOverlay";
import CategoryTagsListing from "../components/category-tags-listing/categoryTagsListing";

import BasePage from "pages/common/basePage";
import Header from "pages/common/header";
import MainContent from "pages/common/mainContent";
import DownloadReports from "../components/action-buttons/downloadReports";
import NewCategoryTag from "../components/action-buttons/newCategoryTag";
import NewCategoryTagDialog from "../components/dialogs/newCategoryTagDialog";
import EditCategoryTagDialog from "../components/dialogs/editCategoryTagDialog";
import ViewCategoryTagDialog from "../components/dialogs/viewCategoryTagDialog";

class CategoryTagsManagementPage extends React.Component<PropsWithRedux, {}> {
  public componentDidMount() {
    if (!this.props.audiences.length)
      this.props.getAudiences();
  }

  public render() {
    return (
      <BasePage fullWidth>
        <Header
          title="Manage Category Tags"
          rightComponent={
            <React.Fragment>
              <DownloadReports />
              <NewCategoryTag />
            </React.Fragment>
          }
        />
        <MainContent>
          <CategoryTagsListing />
          <NewCategoryTagDialog />
          <EditCategoryTagDialog />
          <ViewCategoryTagDialog />
          <ErrorSnackbar errorMessage={this.props.errorMessage} clearErrorMessage={this.props.clearErrorMessage} />
          <LoadingOverlay absolute={true} show={this.props.isDeleting || this.props.isFetching || this.props.isSaving} />
        </MainContent>
      </BasePage>
    );
  }
}

interface ComponentProps {}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps & RouteComponentProps) => ({
    ...ownProps,
    audiences: state.audiences.audiences,
    errorMessage: state.categoryTags.errorMessage,
    isDeleting: state.categoryTags.isDeleting,
    isFetching: state.categoryTags.isFetching,
    isSaving: state.categoryTags.isSaving
  }),
  {
    clearErrorMessage: actions.clearErrorMessage,
    getAudiences: audiencesActions.getAudiences
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(CategoryTagsManagementPage);