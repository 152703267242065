import React, { useRef, useState } from "react";

import Popover from "@mui/material/Popover";


import "./textHover.sass";


const TextHover: React.FC<ComponentProps> = ({ text, children }) => {
  const [showPopover, setShowPopover] = useState(false);
  const popoverRef = useRef(null);

  return (
    <>
      <span
        ref={popoverRef}
        onMouseEnter={() => setShowPopover(true)}
        onMouseLeave={() => setShowPopover(false)}
        className="help-text"
      >
        {text}
      </span>
      <Popover
        open={showPopover}
        anchorEl={popoverRef.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        PaperProps={{
          onMouseEnter: () => setShowPopover(true),
          onMouseLeave: () => setShowPopover(false),
          style: {
            maxWidth: 250
          }
        }}
        style={{ pointerEvents: "none" }}
        onClose={() => setShowPopover(false)}
        classes={{
          paper: "help-text-description"
        }}
      >
        {children}
      </Popover>
    </>
  )
}

interface ComponentProps {
  text: string;
}

export default TextHover;