import React, { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";

import CloseIcon from "@mui/icons-material/Close";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import HelpIcon from "@mui/icons-material/Help";

import { Theme } from "@mui/material/styles";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import "./tutorial.sass";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconButton: {
      color: "#aaaaaa",
      margin: "0 5px"
    }
  })
);


const Tutorial: React.FunctionComponent<PropsWithRedux> = props => {
  const [showTutorial, setShowTutorial] = useState(!!props.openOnLoad);
  const [currentSlide, setCurrentSlide] = useState(0);

  const classes = useStyles();

  const { slides } = props;

  return (
    <React.Fragment>
      <IconButton
        size="small"
        onClick={() => {
          setCurrentSlide(0);
          setShowTutorial(true);
        }}
        className={classes.iconButton}
      >
        <HelpIcon />
      </IconButton>
      <Dialog id="tutorial-dialog" open={showTutorial} maxWidth="sm" onClose={() => setShowTutorial(false)}>
        <IconButton
          onClick={() => setShowTutorial(false)}
          className="close-tutorial"
          size="large">
          <CloseIcon color="inherit" />
        </IconButton>
        <div className="tutorial-slides">
          {slides.map((slide, index) =>
            <div key={index} style={{ transform: `translateX(${currentSlide * - 100}%)` }} className="tutorial-slide">
              <img src={`${props.imageSrc}-${index+1}.gif`} alt="" />
              <div className="tutorial-slide-title">{slide.title}</div>
              <div className="tutorial-slide-content">{slide.content}</div>
            </div>
          )}
        </div>
        <div className="tutorial-footer">
          <div className="tutorial-steps">
            {slides.map((_, index) =>
              <IconButton key={index} color={currentSlide === index ? "primary" : "inherit"} size="small" onClick={() => setCurrentSlide(index)}><FiberManualRecordIcon fontSize="small" /></IconButton>
            )}
          </div>
          <div>
            {currentSlide > 0 &&
              <Button variant="text" onClick={() => setCurrentSlide(currentSlide - 1)} className="previous-slide">Previous</Button>
            }
            {currentSlide < (slides.length - 1)
              ? <Button variant="contained" color="primary" onClick={() => setCurrentSlide(currentSlide + 1)} className="next-slide">Next</Button>
              : <Button variant="contained" color="primary" onClick={() => setShowTutorial(false)} className="finish-tutorial">Finish</Button>
            }
          </div>
        </div>
      </Dialog>
    </React.Fragment>
  );
}


export interface Slide {
  title: string;
  content: JSX.Element;
}

interface ComponentProps {
  openOnLoad?: boolean;
  slides: Slide[];
  imageSrc: string;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
  })
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(Tutorial);