import { store } from "configureStore"
import { SetTenantIdAction } from "modules/tenant";
import { multitenancyApi } from "api/instances";
import * as actions from "modules/settings/actionCreator";
import Cookies from "utils/cookie";

export interface ISelectOpt {
    label: string;
    value: string;
}

export default {
    /**
     * Reusable function for tasks and actions that are needed when first logging in a user into a tenant
     * Sets the tenantId and multitenancy cookies, the redux store's tenantId, as well as a GET for the user's settings.
     * This then navigates to the tenant portal page.
     * @param tenantId      The tenant's id
     * @param multitenancy  true if user belongs to multiple tenants (part of multiple B2C groups), false otherwise
     */
    setTenantUserDataAndNav(tenantId: string, multitenancy: boolean) {
        Cookies.set("tenantId", tenantId);
        Cookies.set("multitenancy", Boolean(multitenancy).toString());
        store.dispatch(SetTenantIdAction({ tenantId }));
        actions.getUserSettings();
    },
    /**
     * Maps tenant data to an array of objects implementing ISelectOpt { label: <tenantName>, value: <tenantId> }
     * @returns an array of formatted tenant data in the form of ISelectOpt[] { label: <tenantName>, value: <tenantId> }[]
     */
    async getTenantData(): Promise<ISelectOpt[]> {
        const res = await multitenancyApi.getTenants();
        let keys = Object.keys(res);
        return keys.sort().map((key) => ({
            label: key,
            value: res[key],
        }));
    },
    /**
     * Get the tenant name and tenant id of tenants the user belongs to from the backend, and then set the corresponding redux stores
     * and cookies in the frontend.
     * 
     * NOTE: If the user belongs to multiple tenants AND a default tenant id is not provided, the setup will not be run, and just tenant data is returned.
     * This is expected behaviour so that on the tenantSelection page, the user can first choose their tenant from a list and then run the setup.
     * @param defaultTenantId   Setup the user to this default tenant in case they belong to multiple.
     * @returns an array of formatted tenant data in the form of ISelectOpt[] { label: <tenantName>, value: <tenantId> }[]
     */
    async setupTenantUserData(defaultTenantId?: string): Promise<ISelectOpt[]> {
        const tenantData: ISelectOpt[] = await this.getTenantData();
        // If there is only 1 tenant, set that tenant's data in the frontend and navigate to the portal
        if (tenantData.length === 1) {
            this.setTenantUserDataAndNav(tenantData[0].value, false);
        }
        // If there are multiple tenants and a default is provided (and matches what we get from the backend), navigate to it
        else if (defaultTenantId && tenantData.some((ele) => ele.value === defaultTenantId)) {
            this.setTenantUserDataAndNav(defaultTenantId, true);
        }
        // we have multiple tenants for the user and a default is not chosen, don't navigate
        else {
            // pass
        }
        return tenantData;
    }
}