import * as React from "react";

import Description from "./common/description";
import TinaLabel from "./common/label";


function Label({ input, meta, field }) {
  return (
    <TinaLabel>
      {field.label}
      {field.description &&
        <Description>{field.description}</Description>
      }
    </TinaLabel>
  );
}

export default Label;