import React from "react";

import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { IHighlightAnalysis } from "modules/common/components/authoring/models";
import { getPercentString } from "utils/stringFormatting";
import HoverText from "modules/documents/components/action-buttons/hoverText";

interface IContentIssues {
    totalWordCount?: number;
    totalSentenceCount?: number;
    lettersPerWord?: string;
    syllablesPerWord?: string;
    wordsPerSentence?: string;
    wordsPerParagraph?: string;
    sentencesPerParagraph?: string;
    issues?: IHighlightAnalysis;
    hideHighlights?: boolean;
}

const PASSIVE_REGEX = /<[a-z]+[0-9\s="]?class="highlight_passive"[a-z]*[0-9\s=]*>(.*?)<\//gm;
const CLICHE_REGEX = /<[a-z]+[0-9\s="]?class="highlight_cliche"[a-z]*[0-9\s=]*>(.*?)<\//gm;

const ContentIssues: React.FunctionComponent<IContentIssues> = ({
    issues = {} as IHighlightAnalysis,
    totalSentenceCount = 0,
    totalWordCount = 0,
    lettersPerWord = 0,
    syllablesPerWord = 0,
    wordsPerParagraph = 0,
    wordsPerSentence = 0,
    sentencesPerParagraph = 0,
    hideHighlights = false,
}) => {
    const {
        spellingErrorCount = 0,
        grammarErrorCount = 0,
        veryLongSentenceCount = 0,
        longSentenceCount = 0,
        longWordCount = 0,
        highSyllableWordCount = 0,
        passiveVoiceCount = 0,
        adverbCount = 0,
        clicheCount = 0,
    } = issues;

    const {
        highlightedText = ""
    } = issues;

    const textIndicator = (title: string, count: string, percent: string, first: boolean = false, icon?: JSX.Element, hoverText?: string): JSX.Element => (
        <>
            <div className="text-percent-indicator-container" style={{ marginTop: first ? 0 : 25 }}>
                <div className="title">
                    {icon &&
                        <HoverText label={icon}>
                            {hoverText}
                        </HoverText>}
                    {title}
                </div>
                <span className="value">{count}</span>
                <span className="value">{percent}</span>
            </div>
        </>
    );

    /**
     * For cliche and passive voice counts, we need to sum up the words identified as cliche or passive
     * - first capture group is the class, which is what we use to determine if marked as cliche vs passive vs something else
     * - second capture group is the contents of the tag, which is what we use to sum up the words identified in the cliche or passive phrase
     * - adapted from code generator at https://regex101.com/
     * - TODO: Figure out the regex to handle both cases in one.
     */
    const getSpecialCount = (regex: RegExp) => {
        if (!highlightedText) return "0%";

        let passiveCount = 0;
        let m: RegExpExecArray | null;

        while ((m = regex.exec(highlightedText)) !== null) {
            // This is necessary to avoid infinite loops with zero-width matches
            if (m.index === regex.lastIndex) {
                regex.lastIndex++;
            }

            let contents = m[1];
            let countToAdd = contents.split(" ").length;

            passiveCount += countToAdd;
        }

        return getPercentString(totalWordCount, passiveCount);
    }

    const warning = (): JSX.Element => <WarningIcon style={{ marginRight: 7, fontSize: 24 }} htmlColor="#E6911A" />;
    const check = (): JSX.Element => <CheckCircleIcon style={{ marginRight: 7, fontSize: 24 }} htmlColor="#366531" />;

    const cliche = getSpecialCount(CLICHE_REGEX);
    const passive = getSpecialCount(PASSIVE_REGEX);

    return (
        <>
            {issues &&
                <>
                    {!hideHighlights && 
                        <>
                        <p>Language</p>
                        {/* percent of words spelt wrong */}
                        {textIndicator("Spelling Issues",
                            spellingErrorCount.toLocaleString(),
                            getPercentString(totalWordCount, spellingErrorCount),
                            true,
                            spellingErrorCount > 0 ? warning() : check(),
                            "Try to aim for no spelling mistakes.")}

                        {/* percent of sentences with a grammar issue */}
                        {textIndicator("Grammar Issues",
                            grammarErrorCount.toLocaleString(),
                            getPercentString(totalSentenceCount,
                                grammarErrorCount),
                            false,
                            grammarErrorCount > 0 ? warning() : check(),
                            "Try to aim for no grammar mistakes."
                        )}

                        <p className="text-indicator-heading">Readability</p>
                        {/* percent of sentences that are very long - aim for less than 6% */}
                        {textIndicator("Sentences > 30 Syllables",
                            veryLongSentenceCount.toLocaleString(),
                            getPercentString(totalSentenceCount, veryLongSentenceCount),
                            true,
                            Math.round((veryLongSentenceCount / totalSentenceCount) * 100) > 6 ? warning() : check(),
                            "Try to aim for less than 6% very long sentences.")}

                        {/* percent of sentences that are long */}
                        {textIndicator("Sentences > 20 Syllables",
                            longSentenceCount.toLocaleString(),
                            getPercentString(totalSentenceCount,
                                longSentenceCount),
                            false,
                            Math.round((longSentenceCount / totalSentenceCount) * 100) > 12 ? warning() : check(),
                            "Try to aim for less than 12% long sentences.")}

                        {/* percent of words with more than 4 syllables */}
                        {textIndicator("Words > 4 Syllables",
                            highSyllableWordCount.toLocaleString(),
                            getPercentString(totalWordCount, highSyllableWordCount),
                            false,
                            Math.round((highSyllableWordCount / totalWordCount) * 100) > 3 ? warning() : check(),
                            "Try to aim for less than 3% difficult words.")}

                        {/* percent of words with more than 12 letters */}
                        {textIndicator("Words > 12 Letters",
                            longWordCount.toLocaleString(),
                            getPercentString(totalWordCount,
                                longWordCount),
                            false,
                            Math.round((longWordCount / totalWordCount) * 100) > 3 ? warning() : check(),
                            "Try to aim for less than 3% long words.")}

                        <p className="text-indicator-heading">Writing Style</p>
                        {/* percent of words involved in passive voice count (1 passive voice count can have multiple words) */}
                        {textIndicator("Passive Voice Count",
                            passiveVoiceCount.toLocaleString(),
                            passive,
                            true,
                            Number(passive.split("%")[0]) > 3 ? warning() : check(),
                            "Try to aim for less than 3% passive voice.")}

                        {/* percent of words that are adverbs */}
                        {textIndicator("Adverb Count",
                            adverbCount.toLocaleString(),
                            getPercentString(totalWordCount, adverbCount),
                            false,
                            Math.round((adverbCount / totalWordCount) * 100) > 4 ? warning() : check(),
                            "Try to aim for less than 4% adverbs.")}

                        {/* percent of words involved in cliches (1 cliche count can have multiple words) */}
                        {textIndicator("Cliché Count",
                            clicheCount.toLocaleString(),
                            cliche,
                            false,
                            Number(cliche.split("%")[0]) > 1 ? warning() : check(),
                            "Try to aim for less than 1% clichés.")}
                        </>
                    }

                    <p className="text-indicator-heading">Text Density</p>
                    {textIndicator("Characters per Word",
                        lettersPerWord.toLocaleString(),
                        "",
                        true,
                        Number(lettersPerWord) === 4.2 ? check() : warning(),
                        "Highly readable content averages 4.2 letters per word.")}
                    {textIndicator("Syllables per Word",
                        syllablesPerWord.toLocaleString(),
                        "",
                        false,
                        Number(syllablesPerWord) === 1.4 ? check() : warning(),
                        "Highly readable content averages 1.4 syllables per word.")}
                    {textIndicator("Words per Sentence",
                        wordsPerSentence.toLocaleString(),
                        "",
                        false,
                        Number(wordsPerSentence) === 14 ? check() : warning(),
                        "Highly readable content averages 14 words per sentence.")}
                    {textIndicator("Words per Paragraph",
                        wordsPerParagraph.toLocaleString(),
                        "",
                        false,
                        Number(wordsPerParagraph) === 60 ? check() : warning(),
                        "Highly readable content averages 60 words per paragraph.")}
                    {textIndicator("Sentences per Paragraph",
                        sentencesPerParagraph.toLocaleString(),
                        "",
                        false,
                        Number(sentencesPerParagraph) === 4 ? check() : warning(),
                        "Highly readable content averages 4 sentences per paragraph.")}
                </>}
        </>
    );
}

export default ContentIssues;
