import React from "react";
import { DragIndicator } from "@mui/icons-material";
import { ListItemButton, IconButton } from "@mui/material";
import moment from "moment";

import { IPosition } from "modules/common/hooks/useDragReorder";
import { IContentSearchResultItem } from "./contentSearch";
import { IContentSearchResultsProps } from "./contentSearchResultList";

export interface IContentSearchResultItemProps extends IContentSearchResultsProps {
    item: IContentSearchResultItem;
    id?: string;
    mousePos?: IPosition;
    idx: number;
}

export const ContentSearchResultItem: React.FunctionComponent<IContentSearchResultItemProps> = ({
    item,
    id,
    draggedIdx,
    idx,
    mousePos = { x: 0, y: 0 },
    action,
    onClick,
    onDrag,
}) => {
    const itemIsButton = Boolean(onClick !== undefined);

    const getListItem = (isFloating: boolean): JSX.Element => (
        <ListItemButton
            id={`${id}${isFloating ? "-floating" : ""}`}
            disableRipple={!itemIsButton}
            className={`search-result ${isFloating ? "floating" : ""}`}
            key={`${item.id}${isFloating ? "-floating" : ""}`}
            onClick={() => {
                if (onClick) onClick(item);
            }}
            sx={{
                "&:hover": {
                    cursor: itemIsButton ? "pointer" : "initial",
                    backgroundColor: itemIsButton ? "rgba(0, 0, 0, 0.04)" : "#ffffff",
                },
            }}
            style={{
                left: isFloating ? mousePos.x : "initial",
                top: isFloating ? mousePos.y : "initial",
            }}
        >
            {onDrag && (
                <IconButton
                    color="primary"
                    onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => {
                        onDrag(item.id);
                        document.body.style.cursor = "move";
                    }}
                >
                    <DragIndicator />
                </IconButton>
            )}
            {item.imageUrl && (
                <div
                    style={{
                        backgroundColor: "rgb(221, 225, 229)",
                        backgroundImage: `url(${item.imageUrl})`,
                    }}
                    className="search-image"
                ></div>
            )}
            <div className="search-content">
                <div className="title">{item.title}</div>
                <div className="author">{item.authorName}</div>
                <div className="time">
                    <span>Published {moment(item.publishTime).format("MMM D, YYYY")}</span>
                    {action && action(item)}
                </div>
            </div>
        </ListItemButton>
    );

    return (
        <>
            {/* floating element that follows our mouse*/}
            {idx === draggedIdx && getListItem(true)}

            {/* actual button */}
            {idx !== draggedIdx && getListItem(false)}
        </>
    );
};
