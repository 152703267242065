import { AxiosInstance, AxiosRequestConfig } from 'axios'
import OktaAuthModule from 'authentication/oktaAuthModule';
import { store } from "configureStore";

//This class uses the Okta Organization Name and Portal ClientId from the state
//to create an OktaAuthModule, which is used to query groups from the SyncFunctions
//as well as to initiate the sync.
export class OktaAuthInterceptor {
    public bind(networkLayer: AxiosInstance) {
        networkLayer.interceptors.request.use(this.handle);
    }

    public handle = (config: AxiosRequestConfig) => {
        const oktaOrganizationName = store.getState().settings.tenantSettings.syncConfig.syncDetails.organizationId;
        const portalClientId = store.getState().settings.tenantSettings.syncConfig.syncDetails.portalClientId;

        return new OktaAuthModule(oktaOrganizationName, portalClientId).getAccessToken()
            .then(accessToken => {
                config.headers.Authorization = `Bearer ${accessToken}`;
                return config;
            });
    }
}
