import * as React from "react";
import Modal from "modules/common/components/modal";
import './audiences.sass'

interface ComponentProps {
    show: boolean
    group: { name: string, users: any[] }
    closeModal: () => void
}

export default class UserGroupPreivewModal extends React.PureComponent<ComponentProps, {}> {

    public render() {

        return   (<Modal active={this.props.show}
                    title={this.props.group.name}
                    showControls={false}
                    onCloseClick={this.handleModalCloseClick}
                    >
                    <div>
                        <label className="audience-title">Users in Group</label>
                        <div className="results" style={{backgroundColor: "#fcfcfc", height: "400px", overflowY: "auto" }}>
                        {
                            this.props.group.users.map(user => {
                                return <div key={user.id} className="item">
                                    {`${user.firstName} ${user.lastName} - ${user.email}`}
                                </div>
                            })
                        }
                        </div>
                    </div>
                   
                </Modal>);
    }

    private handleModalCloseClick = () => {
        this.props.closeModal()
    }
}
