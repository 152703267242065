export interface IHomeScreensState {
    homeScreenConfigs: IHomeScreenConfig[] | undefined;
    status: HomeScreensStateStatus;
    pristineConfigs: IHomeScreenConfig[] | undefined;
    successMessage: string | undefined;
}

export enum HomeScreensStateStatus {
    Succeeded,
    Failed,
    Loading,
    Idle
}

export interface IColorOption {
    hexCode: string;
    rgb: IRgb;
}

export interface IRgb {
    r: number;
    g: number;
    b: number;
}

export interface IHomeScreenConfig {
    id: string;
    showMyFeedBelowContentBands: boolean;
    feedHeader: string | null;
    homeScreenType: HomeScreenType;
    platform: HomeScreenPlatform;
}

export enum HomeScreenPlatform {
    Mobile = "mobile",
    Teams = "teams"
}

export enum HomeScreenType {
    ContentBands = "contentBands",
    MyFeed = "myFeed"
}

export const HOME_SCREEN_TYPE_MAP:  Record<HomeScreenType, string> = {
    [HomeScreenType.ContentBands]: "Content Bands",
    [HomeScreenType.MyFeed]: "My Feed"
}

export const HOME_SCREEN_PLATFORM_MAP: Record<HomeScreenPlatform, string> = {
    [HomeScreenPlatform.Mobile]: "Mobile app",
    [HomeScreenPlatform.Teams]: "MS Teams app"
}
