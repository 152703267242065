import React, { useState } from "react";

import Callout from "modules/common/components/callout";

import { UserDetailsAudience } from "../../models";

import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import AddIcon from "@mui/icons-material/Add";


const EditAudiences: React.FunctionComponent<ComponentProps> = props => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [audiences, setAudiences] = useState(props.audiences);
  const [calloutOpen, setCalloutOpen] = useState(false);

  return (
    <React.Fragment>
      <Button
        variant="text"
        color="primary"
        startIcon={<AddIcon />}
        onClick={(event: any) => {
          setAudiences(props.audiences);
          setAnchorEl(event.currentTarget);
          setCalloutOpen(true);
        }}
      >
        Edit audiences
      </Button>
      <Callout
        anchorEl={anchorEl} 
        setOpen={(toSet) => {
          props.onChange(audiences);
          setCalloutOpen(toSet);
        }}
        open={calloutOpen}
      >
        <List disablePadding className="audiences">
          {!props.availableAudiences.length &&
            <div>No available audiences found</div>
          }
          {props.availableAudiences.map((audience) =>
            <ListItem key={audience.id} dense button disabled={audience.audienceType === "smart" || !audience.isEditable} onClick={() => {
              const hasSelectedAudience: boolean = audiences.findIndex((selectedAudience) => selectedAudience.id === audience.id) !== -1;
              if (hasSelectedAudience)
                setAudiences(audiences.filter((selectedAudience) => selectedAudience.id !== audience.id));
              else
                setAudiences(audiences.concat([{ ...audience, isEditable: true, isIncluded: true }]));
            }}>
              <ListItemIcon className="callout-checkbox">
                <Checkbox
                  edge="start"
                  tabIndex={-1}
                  disableRipple
                  size="small"
                  color="primary"
                  checked={audiences.findIndex((selectedAudience) => selectedAudience.id === audience.id) !== -1}
                />
              </ListItemIcon>
              <ListItemText primary={`${audience.title || ""} (${audience.totalUsers})`} />
            </ListItem>
          )}
        </List>
      </Callout>
    </React.Fragment>
  );
}

interface ComponentProps {
  audiences: UserDetailsAudience[];
  availableAudiences: UserDetailsAudience[];
  onChange: (audiences: UserDetailsAudience[]) => void;
}

export default EditAudiences;