import React from "react";
import { connect, ConnectedProps } from "react-redux";
import * as actions from "../../actionCreator";
import { GlobalApplicationState } from "globalApplicationState";

import { CategoryTagsListItem } from "modules/categorytags";

import MoreOptionsIconButton from "modules/common/components/buttons/moreOptionsIconButton";

import confirm from "utils/notyPopups";

import Divider from "@mui/material/Divider";
import List from "@mui/material/List";

import "../../styles/categoryTagsListing.sass"
import MoreOptionsItem from "modules/common/components/moreOptionsItem";
import { FileDownloader } from "utils/fileDownloader";


const MoreCategoryTagOptions: React.FunctionComponent<PropsWithRedux> = props => {
  const getTagForDownload = async (id: string) => {
    const file = {
      name: `${props.categoryTag.title}-${new Date().toISOString()}.csv`
    }
    props.fetchCategoryTagDetails(id)
      .then((data) => new FileDownloader(file).downloadBlob(data))
  }

  const onDeleteCategoryTags = async (id: string, name: string) => {
    const confirmationMessage: string = "Are you sure you want to delete " + name + "?";
    if (await confirm.show({text: confirmationMessage, title: "Delete tag"})) {
        props.deleteCategoryTag(id);
    }
  }

  const { categoryTag } = props;

  return (
    <MoreOptionsIconButton>
      <List disablePadding>
        {categoryTag.title !== "Uncategorized" && 
          <MoreOptionsItem
            text="Edit tag details"
            onClick={() => {
              props.setTagToEdit(props.categoryTag);
              props.editCategoryTag();
            }}
          />
        }
        <Divider light />
        {categoryTag.enabled && categoryTag.title !== "Uncategorized" &&
          <MoreOptionsItem
            text="Disable"
            onClick={() => {
              props.disableCategoryTag(categoryTag.id);
            }}
          />
        }
        {!categoryTag.enabled &&
          <MoreOptionsItem
            text="Enable"
            onClick={() => {
              props.enableCategoryTag(categoryTag.id);
            }}
          />
        }
        {categoryTag.title !== "Uncategorized" &&
          <MoreOptionsItem
            text="Delete"
            onClick={() => {
              onDeleteCategoryTags(categoryTag.id, categoryTag.title);
            }}
          />
        }
        <Divider light />
        <List disablePadding>
          <MoreOptionsItem
            text="Download report"
            onClick={() => {
              getTagForDownload(categoryTag.id);
            }}
          />
        </List>
      </List>
    </MoreOptionsIconButton>
  );
}

interface ComponentProps {
  categoryTag: CategoryTagsListItem;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    tenantId: state.tenant.id
  }),
  {
    deleteCategoryTag: actions.deleteCategoryTag,
    enableCategoryTag: actions.enableCategoryTag,
    disableCategoryTag: actions.disableCategoryTag,
    fetchCategoryTagDetails: actions.getCategoryTagDownload,
    editCategoryTag: actions.showEditCategoryTag,
    setTagToEdit: actions.setTagToEdit
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(MoreCategoryTagOptions);