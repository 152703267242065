import React, { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";

import { DocumentsSort } from "../../../models";

import List from "@mui/material/List";
import Popover from "@mui/material/Popover";
import MoreOptionsItem from "modules/common/components/moreOptionsItem";

interface SortOption {
  text: string;
  value: DocumentsSort;
}

const SortOptions: SortOption[] = [
  { text: "Recently viewed", value: "readDescending" },
  { text: "Newest / Recently updated", value: "updatedDescending" },
  { text: "Oldest first", value: "updatedAscending" },
  { text: "A-Z", value: "titleAscending" },
  { text: "Z-A", value: "titleDescending" }
];

const SortSelection: React.FunctionComponent<PropsWithRedux> = props => {
  const [anchorEl, setAnchorEl] = useState(null);

  const hideAnchorEl = () => setAnchorEl(null);

  return (
    <div>
      <div onClick={(ev: any) => setAnchorEl(ev.currentTarget)} className="sort-selection">
        <span>Sort by</span>
        <span className="selected-sort">{SortOptions.find((option) => option.value === props.sort)!.text}</span>
      </div>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={hideAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
      >
        <List disablePadding>
          {SortOptions.map((option) =>
            <MoreOptionsItem
              key={option.value}
              text={option.text}
              onClick={() => {
                props.onChangeSort(option.value);
                hideAnchorEl();
              }}
            />
          )}
        </List>
      </Popover>
    </div>
  );
}

interface ComponentProps {
  onChangeSort: (sort: DocumentsSort) => void;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    sort: state.documents.documentFeed.filters.sort
  })
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(SortSelection);