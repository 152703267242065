import * as React from "react";

import { EventType } from "../../../models";

import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import moment from "moment";


class CutoffTime extends React.Component<ComponentProps, ComponentState> {
  public render() {
    const { eventType } = this.props;
    const cutoffTime = eventType === "informational" ? null : this.props.cutoffTime;
    
    return (
      <div className="cutoff-time">
        <div className="cutoff-time-field">
          <div className="cutoff-time-label">RSVP Deadline</div>
          <div className="cutoff-time-input">
            <DatePicker
              format="MMM dd yyyy"
              value={cutoffTime ? Date.parse(cutoffTime) : null}
              slotProps={{ 
                textField: { size: "small", placeholder: "Select date", inputProps: { readOnly: true }},
                openPickerIcon: { color: "primary" }
              }}
              onChange={this.onChangeCutoffDay}
              disabled={this.props.disabled}
              className="date-picker"
            />
          </div>
        </div>
        <div className="cutoff-time-field">
          <div className="cutoff-time-label">Time</div>
          <div>
            <TextField
              variant="outlined"
              size="small"
              color="primary"
              type="time"
              value={!!cutoffTime ? moment(cutoffTime).format("HH:mm") : ""}
              disabled={this.props.disabled || !cutoffTime}
              inputProps={{
                step: 900
              }}
              onChange={this.onChangeCutoffTime}
            />
          </div>
        </div>
      </div>
    );
  }

  private onChangeCutoffDay = (date) => {
    const cutoffTime: string = !!this.props.cutoffTime
      ? moment(date).toISOString()
      : moment(date).startOf("hour").add(1, "hour").toISOString();
    this.props.onChange(cutoffTime);
  }

  private onChangeCutoffTime = (event) => {
    const times: number[] = event.target.value.split(":");
    const cutoffTime: string = moment(this.props.cutoffTime).set("hour", times[0]).set("minutes", times[1]).toISOString();
    this.props.onChange(cutoffTime);
  }
}


interface ComponentProps {
  cutoffTime: string | undefined;
  eventType: EventType | undefined;
  disabled?: boolean;
  onChange: (cutoffTime: string) => void;
}

interface ComponentState {}

export default CutoffTime;