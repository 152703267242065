import { Menu, MenuItem } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import SvgIcon from '@mui/material/SvgIcon';
import Loading from 'modules/common/components/loading';
import * as React from 'react';

interface ComponentProps {
    downloadExcel: () => void;
    downloadImage: () => void;
}

interface ComponentState {
    anchor: null | HTMLElement
}

export default class DownloadIcon extends React.Component<ComponentProps, ComponentState> {

    constructor(props){
        super(props);
        this.state = {
            anchor: null
        };
    }

    showLoadingSpinner = () => {
        return (
            <div style={{marginLeft: "auto", position: "relative", top: "-10px", height: "20px"}}>
                <Loading style={{width: "18px", height: "18px"}}/>
            </div>
        )
    }

    handleClose = () => {
        this.setState({...this.state, anchor: null})
    }

    handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({...this.state, anchor: event.currentTarget})
    }

    public render() {
        return (
            <React.Fragment>
                <IconButton
                    aria-label="download-top-event"
                    aria-controls={Boolean(this.state.anchor) ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={Boolean(this.state.anchor) ? 'true' : undefined}
                    color="primary"
                    onClick={this.handleClick}
                    size="large">
                    <SvgIcon viewBox='0 0 48 48'>
                        <path d="M11 40q-1.2 0-2.1-.9Q8 38.2 8 37v-7.15h3V37h26v-7.15h3V37q0 1.2-.9 2.1-.9.9-2.1.9Zm13-7.65-9.65-9.65 2.15-2.15 6 6V8h3v18.55l6-6 2.15 2.15Z"/>
                    </SvgIcon>
                </IconButton>
                <Menu
                    id="report-menu"
                    anchorEl={this.state.anchor}
                    open={Boolean(this.state.anchor)}
                    onClick={this.handleClose}
                    onClose={this.handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'report-button',
                    }}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                    PaperProps={{
                        style: {
                            transform: 'translateY(6px)',
                        }
                    }}
                >
                    <MenuItem style={{width: "250px"}} onClick={this.props.downloadImage}>
                        Download as an Image
                    </MenuItem>
                    <MenuItem onClick={this.props.downloadExcel}>
                        Download Excel Report
                    </MenuItem>
                </Menu>
            </React.Fragment>
        );
    }
}