import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { GlobalApplicationState } from "globalApplicationState";

import Loading from "modules/common/components/loading";
import PortalPageView from "modules/portalPages/components/portal-pages-views/portalPageView";
import PostsPage from "pages/posts/postsPage";

class HomePage extends React.Component<PropsWithRedux, {}> {
  public render() {
    if (!!this.props.id && !this.props.portalPagesEnabled)
      return <PostsPage {...this.props} />;

    if (this.props.navigation.shouldFetch || this.props.navigation.isFetching)
      return <Loading />;
    
    if (this.props.portalPagesEnabled && !!this.props.navigation.navigationItems.length)
      return (
        <PortalPageView portalPageUrl="" />
      );
    
    return (
      <PostsPage {...this.props} />
    );
  }
}

interface RouteParams {
  portalPageId: string;
  postId: string;
}

interface ComponentProps {}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps & RouteComponentProps<RouteParams>) => ({
    ...ownProps,
    id: state.settings.clientSettings.id,
    navigation: state.portalPages.navigation,
    portalPagesEnabled: state.settings.clientSettings.portalPagesEnabled
  })
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(HomePage);
