import React from "react";
import { FormControlLabel, Checkbox } from "@mui/material";
import { IContentBandSettingsInputProps } from "../editor/contentBandSettingsForm";

export const AutoPlayCarouselInput: React.FunctionComponent<IContentBandSettingsInputProps<boolean>> = ({
    idx,
    value,
    isSmallAndSmaller,
    onChange,
}) => {
    return (
        <div className="form-group">
            <div style={isSmallAndSmaller ? undefined : { marginLeft: "auto", width: 250 }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            className="checkbox"
                            color="primary"
                            checked={value}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.checked, idx)}
                        />
                    }
                    label={<span className="input">Auto-play carousel</span>}
                />
            </div>
        </div>
    );
};
