import * as React from "react";

import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const INLINE_STYLES = {
    container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    label: {
        flex: 1,
    },
    input: {
        width: "100%",
        overflow: "hidden"
    }
}

class PostType extends React.Component<ComponentProps, ComponentState> {

  public render() {
    const { inline = false } = this.props;

    return (
      <div className="type">
        <div className="section-card-heading" style={inline ? INLINE_STYLES.container : undefined}>
          <div style={inline ? INLINE_STYLES.label : undefined}>{this.props.label || "Post Type"}</div>
          <FormControl size="small" style={inline ? { width: 300 } : undefined}>
            <Select
              variant="outlined"
              value={this.props.postType}
              onChange={this.onChangePostType}
              className="type-select"
              style={inline ? INLINE_STYLES.input : undefined}
            >
              <MenuItem value="standard">Standard</MenuItem>
              <MenuItem value="compliance">Compliance</MenuItem>
              <MenuItem value="mandatory">Mandatory</MenuItem>
              <MenuItem value="public">Public</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
    );
  }

  private onChangePostType = (event) => {
    this.props.onChange(event.target.value);
  }
}


interface ComponentProps {
  hasPublished: boolean;
  postType: string;
  inline?: boolean;
  label?: string;
  onChange: (postType: string) => void;
}

interface ComponentState {}

export default PostType;
