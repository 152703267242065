import { GlobalApplicationState } from "globalApplicationState";
import * as Actions from "./actions";
import API from "api";

import { EventFeedItem, EventView } from "modules/events/models";
import { PostFeedItem, PostView } from "modules/posts/models";

import { 
    ActivityLogFilterValues, 
    ActivityLogPage, 
    NewsletterConfig, 
    NewsletterDetails, 
    NewsletterFilterValues, 
    NewsletterIssueInput, 
    NewsletterPastIssuesPage, 
    NewsletterListingPage, 
    DailyEmailEventStats, 
    EmailEventStats, 
    WeeklyEmailEventStats, 
    SaveNewsletterModelStateErrors, 
    NewsletterDigest, 
    NewsletterFutureIssueDetailModel, 
    NewsletterPastIssueDigestModel, 
    SaveNewsletterModel, 
    NewsletterImageType, 
    NewsletterTemplate, 
    NewsletterConfigDigest, 
    SaveNewsletterConfigModelStateErrors, 
    SaveNewsletterIssueModelStateErrors, 
    NewsletterTags, 
    NewsletterTemplateImageType, 
    NewsletterTheme, 
    UploadNewsletterImageModel,
    UploadIssueImageModel} from "./models";
import MsalAuthModule from "authentication/msalAuthModule";
import XhrUpload from "utils/xhrUpload";


export const getActivityLog = (pageNumber: number, filters: ActivityLogFilterValues) => (dispatch, getState: () => GlobalApplicationState): Promise<ActivityLogPage> => {
  dispatch(Actions.GetActivityLog({}));

  return dispatch(API.newsletters.GetActivityLog(pageNumber, filters))
    .then(response => response.json())
    .then(activityLog => {
      dispatch(Actions.GetActivityLogComplete({ succeeded: true }));
      return activityLog;
    })
    .catch(err => {
      dispatch(Actions.GetActivityLogComplete({ succeeded: false, err }));
      return;
    });
}

export const getActivityLogCSV = (filters: ActivityLogFilterValues) => (dispatch, getState: () => GlobalApplicationState): Promise<string> => {
  dispatch(Actions.GetActivityLogCSV({}));

  return dispatch(API.newsletters.GetActivityLogCSV(filters))
    .then(response => {
      dispatch(Actions.GetActivityLogCSVComplete({ succeeded: response.status === 200 }));
      return response.text();
    })
    .catch(err => {
      dispatch(Actions.GetActivityLogCSVComplete({ succeeded: false, err }));
      return "";
    });
}

export const getRecipientsCSV = (newsletterId: string) => (dispatch, getState: () => GlobalApplicationState): Promise<string> => {
  dispatch(Actions.GetRecipientsCSV({}));

  return dispatch(API.newsletters.GetRecipientsCSV(newsletterId))
    .then(response => {
      dispatch(Actions.GetRecipientsCSVComplete({ succeeded: response.status === 200 }));
      return response.text();
    })
    .catch(err => {
      dispatch(Actions.GetRecipientsCSVComplete({ succeeded: false, err }));
      return "";
    });
}

export const getConfig = () => (dispatch, getState: () => GlobalApplicationState): Promise<NewsletterConfigDigest> => {
  dispatch(Actions.GetConfig({}));

  return dispatch(API.newsletters.GetConfig())
    .then(response => response.json())
    .then(config => {
      dispatch(Actions.GetConfigComplete({ succeeded: true }));
      return config;
    })
    .catch(err => {
      dispatch(Actions.GetConfigComplete({ succeeded: false, err }));
      return;
    });
}

export const getEvent = (id: string) => (dispatch, getState: () => GlobalApplicationState): Promise<EventView> => {
  dispatch(Actions.GetEvent({}));

  return dispatch(API.events.GetEvent(id))
    .then(response => {
      dispatch(Actions.GetEventComplete({ succeeded: response.status === 200 }));
      return response.json();
    })
    .catch(err => {
      dispatch(Actions.GetEventComplete({ succeeded: false, err }));
      return;
    });
}


export const GetDailyNewsletterInsights = (newsletterId: string) => (dispatch, getState: () => GlobalApplicationState): Promise<DailyEmailEventStats[]> => {
  dispatch(Actions.GetDailyNewsletterInsights({}));

  return dispatch(API.newsletters.GetDailyNewsletterInsights(newsletterId))
    .then(response => response.json())
    .then(insights => {
      dispatch(Actions.GetDailyNewsletterInsightsComplete({ succeeded: true }));
      return insights;
    })
    .catch(err => {
      dispatch(Actions.GetDailyNewsletterInsightsComplete({ succeeded: false, err }));
      return;
    });
}

export const getTotalNewsletterInsights = (newsletterId: string) => (dispatch, getState: () => GlobalApplicationState): Promise<EmailEventStats> => {
  dispatch(Actions.GetTotalNewsletterInsights({}));

  return dispatch(API.newsletters.GetTotalNewsletterInsights(newsletterId))
    .then(response => response.json())
    .then(insights => {
      dispatch(Actions.GetTotalNewsletterInsightsComplete({ succeeded: true }));
      return insights;
    })
    .catch(err => {
      dispatch(Actions.GetTotalNewsletterInsightsComplete({ succeeded: false, err }));
      return;
    });
}

export const GetWeeklyNewsletterInsights = (newsletterId: string) => (dispatch, getState: () => GlobalApplicationState): Promise<WeeklyEmailEventStats[]> => {
  dispatch(Actions.GetWeeklyNewsletterInsights({}));

  return dispatch(API.newsletters.GetWeeklyNewsletterInsights(newsletterId))
    .then(response => response.json())
    .then(insights => {
      dispatch(Actions.GetWeeklyNewsletterInsightsComplete({ succeeded: true }));
      return insights;
    })
    .catch(err => {
      dispatch(Actions.GetWeeklyNewsletterInsightsComplete({ succeeded: false, err }));
      return;
    });
}


export const getNewsletterDetails = (id: string) => (dispatch, getState: () => GlobalApplicationState): Promise<NewsletterDetails | null> => {
  dispatch(Actions.GetNewsletterDetails({}));

  return dispatch(API.newsletters.GetNewsletterDetails(id))
    .then(response => {
      dispatch(Actions.GetNewsletterDetailsComplete({ succeeded: true }));
      if(response.status === 404)
        return null;
      return response.json();
    })
    .catch(err => {
      dispatch(Actions.GetNewsletterDetailsComplete({ succeeded: false, err }));
      return;
    });
}


export const getNewsletterTags = () => (dispatch, getState: () => GlobalApplicationState): Promise<NewsletterTags> => {
  dispatch(Actions.GetNewsletterTags({}));

  return dispatch(API.newsletters.GetNewsletterTags())
    .then(response => response.json())
    .then(page => {
      dispatch(Actions.GetNewsletterTagsComplete({ succeeded: true }));
      return page;
    })
    .catch(err => {
      dispatch(Actions.GetNewsletterTagsComplete({ succeeded: false, err }));
      return;
    });
}


export const getAllNewsletters = (pageNumber: number, filters: NewsletterFilterValues, pageAmount?: number) => (dispatch, getState: () => GlobalApplicationState): Promise<NewsletterListingPage> => {
  dispatch(Actions.GetNewsletters({}));

  return dispatch(API.newsletters.GetNewslettersPaged(pageNumber, filters, pageAmount))
    .then(response => response.json())
    .then(page => {
      dispatch(Actions.GetNewslettersComplete({ succeeded: true }));
      return page;
    })
    .catch(err => {
      dispatch(Actions.GetNewslettersComplete({ succeeded: false, err }));
      return;
    });
}

export const getDisabledNewsletters = (pageNumber: number, filters: NewsletterFilterValues, pageAmount?: number) => (dispatch, getState: () => GlobalApplicationState): Promise<NewsletterListingPage> => {
  dispatch(Actions.GetDisabledNewsletters({}));
  return dispatch(API.newsletters.GetNewslettersPaged(pageNumber, {...filters, status:'Disabled'}, pageAmount))
    .then(response => response.json())
    .then(page => {
      dispatch(Actions.GetDisabledNewslettersComplete({ succeeded: true }));
      return page;
    })
    .catch(err => {
      dispatch(Actions.GetDisabledNewslettersComplete({ succeeded: false, err }));
      return;
    });
}

export const getDraftNewsletters = (pageNumber: number, filters: NewsletterFilterValues, pageAmount?: number) => (dispatch, getState: () => GlobalApplicationState): Promise<NewsletterListingPage> => {
  dispatch(Actions.GetDraftNewsletters({}));
  return dispatch(API.newsletters.GetNewslettersPaged(pageNumber, {...filters, status:'Draft'}, pageAmount))
    .then(response => response.json())
    .then(page => {
      dispatch(Actions.GetDraftNewslettersComplete({ succeeded: true }));
      return page;
    })
    .catch(err => {
      dispatch(Actions.GetDraftNewslettersComplete({ succeeded: false, err }));
      return;
    });
}

export const getEnabledNewsletters = (pageNumber: number, filters: NewsletterFilterValues, pageAmount?: number) => (dispatch, getState: () => GlobalApplicationState): Promise<NewsletterListingPage> => {
  dispatch(Actions.GetEnabledNewsletters({}));
  return dispatch(API.newsletters.GetNewslettersPaged(pageNumber, {...filters, status:'Enabled'}, pageAmount))
    .then(response => response.json())
    .then(page => {
      dispatch(Actions.GetEnabledNewslettersComplete({ succeeded: true }));
      return page;
    })
    .catch(err => {
      dispatch(Actions.GetEnabledNewslettersComplete({ succeeded: false, err }));
      return;
    });
}

export const getTemplates = () => (dispatch, getState: () => GlobalApplicationState): Promise<NewsletterTemplate[]> => {
  dispatch(Actions.GetTemplates({}));

  return dispatch(API.newsletters.GetTemplates())
    .then(response => response.json())
    .then(templates => {
      dispatch(Actions.GetTemplatesComplete({succeeded: true}));
      return templates;
    })
    .catch(err => {
      dispatch(Actions.GetTemplatesComplete({ succeeded: false, err }));
      return;
    });
}

export const getThemes = () => (dispatch, getState: () => GlobalApplicationState): Promise<NewsletterTheme[]> => {
  dispatch(Actions.GetThemes({}));

  return dispatch(API.newsletters.GetThemes())
    .then(response => response.json())
    .then(themes => {
      dispatch(Actions.GetThemesComplete({succeeded: true}));
      return themes;
    })
    .catch(err => {
      dispatch(Actions.GetThemesComplete({ succeeded: false, err }));
      return;
    });
}

export const saveTheme = (themeToSave: NewsletterTheme) => (dispatch, getState: () => GlobalApplicationState): Promise<NewsletterTheme> => {
  dispatch(Actions.SaveTheme({}));

  return dispatch(API.newsletters.SaveTheme(themeToSave))
    .then(response => response.json())
    .then(theme => {
      dispatch(Actions.SaveThemeComplete({succeeded: true}));
      return theme;
    })
    .catch(err => {
      dispatch(Actions.SaveThemeComplete({ succeeded: false, err }));
      return;
    });
}

export const saveThemeImages = (themeId: string, headerImage: string, footerImage: string) => (dispatch, getState: () => GlobalApplicationState): Promise<string> => {
  dispatch(Actions.SaveThemeImages({}));

  return dispatch(API.newsletters.SaveThemeImages(themeId, headerImage, footerImage))
    .then(response => {
      dispatch(Actions.SaveThemeImagesComplete({succeeded: true}));
      return response;
    })
    .catch(err => {
      dispatch(Actions.SaveThemeImagesComplete({ succeeded: false, err }));
      return;
    });
}

export const getThemeImage = (imageType: NewsletterImageType, themeId: string) => (dispatch, getState: () => GlobalApplicationState): Promise<File> => {
  dispatch(Actions.GetThemeImage({}));

  return dispatch(API.newsletters.GetThemeImage(imageType, themeId))
    .then(response => response.blob())
    .then(image => {
      dispatch(Actions.GetThemeImageComplete({succeeded: true}));
      return image;
    })
    .catch(err => {
      dispatch(Actions.GetThemeImageComplete({ succeeded: false, err }));
      return;
    });
}

export const getNewsletterPreview = (templateId: string) => (dispatch, getState: () => GlobalApplicationState): Promise<string> => {
  dispatch(Actions.GetNewsletterPreview({}));

  return dispatch(API.newsletters.GetNewsletterPreview(templateId))
    .then(image => {
      dispatch(Actions.GetNewsletterPreviewComplete({succeeded: true}));
      return image.text();
    })
    .catch(err => {
      dispatch(Actions.GetNewsletterPreviewComplete({ succeeded: false, err }));
      return;
    });
}

export const deleteThemeImage = (imageType: NewsletterImageType, themeId: string) => (dispatch, getState: () => GlobalApplicationState): Promise<string> => {
  dispatch(Actions.DeleteThemeImage({}));

  return dispatch(API.newsletters.DeleteThemeImage(imageType, themeId))
    .then(response => {
      dispatch(Actions.DeleteThemeImageComplete({succeeded: true}));
      return response;
    })
    .catch(err => {
      dispatch(Actions.DeleteThemeImageComplete({ succeeded: false, err }));
      return;
    });
}

export const setThemeDefault = (themeId: string, isCustom: boolean) => (dispatch, getState: () => GlobalApplicationState): Promise<NewsletterTheme> => {
  dispatch(Actions.SetThemeDefault({}));

  return dispatch(API.newsletters.SetThemeDefault(themeId, isCustom))
    .then(response => response.json())
    .then(theme => {
      dispatch(Actions.SetThemeDefaultComplete({succeeded: true}));
      return theme;
    })
    .catch(err => {
      dispatch(Actions.SetThemeDefaultComplete({ succeeded: false, err }));
      return;
    });
}

export const deleteTheme = (id: string, isCustom: boolean) => (dispatch, getState: () => GlobalApplicationState): Promise<NewsletterTheme[]> => {
  dispatch(Actions.DeleteTheme({}));

  return dispatch(API.newsletters.DeleteTheme(id, isCustom))
    .then(response => response.json())
    .then(themes => {
      dispatch(Actions.DeleteThemeComplete({ succeeded: true }));
      return themes;
    })
    .catch(err => {
      dispatch(Actions.DeleteThemeComplete({ succeeded: false, err }));
      return [];
    });
}

export const getTemplateHtml = (templateId: string) => (dispatch, getState: () => GlobalApplicationState): Promise<string> => {
  dispatch(Actions.GetTemplateHtml({}));

  return dispatch(API.newsletters.GetTemplateHtml(templateId))
    .then(response => {
      dispatch(Actions.GetTemplateHtmlComplete({succeeded: true}));
      return response.text();
    })
    .catch(err => {
      dispatch(Actions.GetTemplateHtmlComplete({ succeeded: false, err }));
      return;
    });
}

export const getTemplateImage = (templateId: string, imageType: NewsletterTemplateImageType) => (dispatch, getState: () => GlobalApplicationState): Promise<string> => {
  dispatch(Actions.GetTemplateImage({}));

  return dispatch(API.newsletters.GetTemplateImage(templateId, imageType))
    .then(response => {
      dispatch(Actions.GetTemplateImageComplete({ succeeded: response.status === 200 }));
      return response.text();
    })
    .catch(err => {
      dispatch(Actions.GetTemplateImageComplete({ succeeded: false, err }));
      return "";
    });
}

export const getPastIssues = (id: string, pageNumber: number) => (dispatch, getState: () => GlobalApplicationState): Promise<NewsletterPastIssuesPage> => {
  dispatch(Actions.GetPastIssues({}));

  return dispatch(API.newsletters.GetPastIssues(id, pageNumber))
    .then(response => response.json())
    .then(page => {
      dispatch(Actions.GetPastIssuesComplete({ succeeded: true }));
      return page;
    })
    .catch(err => {
      dispatch(Actions.GetPastIssuesComplete({ succeeded: false, err }));
      return { currentPage: 1, results: [], totalItems: 0, totalPages: 0 };
    });
}

export const getPastIssue = (newsletterId: string, issueDate: string) => (dispatch, getState: () => GlobalApplicationState): Promise<NewsletterPastIssueDigestModel | null> => {
  dispatch(Actions.GetPastIssue({}));

  return dispatch(API.newsletters.GetPastIssue(newsletterId, issueDate))
    .then(response => {
      dispatch(Actions.GetPastIssueComplete({ succeeded: true}));
      if(response.status === 404)
        return null;
      return response.json();
    })
    .catch(err => {
      dispatch(Actions.GetPastIssueComplete({ succeeded: false, err }));
      return;
    });
}

export const getPost = (id: string) => (dispatch, getState: () => GlobalApplicationState): Promise<PostView> => {
  dispatch(Actions.GetPost({}));

  return dispatch(API.clientPosts.GetPost(id))
    .then(response => {
      dispatch(Actions.GetPostComplete({ succeeded: response.status === 200 }));
      return response.json();
    })
    .catch(err => {
      dispatch(Actions.GetPostComplete({ succeeded: false, err }));
      return;
    });
}

export const deleteNewsletter = (id: string) => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
  dispatch(Actions.DeleteNewsletter({}));

  return dispatch(API.newsletters.UpdateNewsletterStatus(id, 'Deleted'))
    .then(response => {
      dispatch(Actions.DeleteNewsletterComplete({ succeeded: response.status === 200 }));
      return response.status === 200;
    })
    .catch(err => {
      dispatch(Actions.DeleteNewsletterComplete({ succeeded: false, err }));
      return false;
    });
}

export const deleteNewsletters = (ids: string[]) => (dispatch, getState: () => GlobalApplicationState): Promise<any> => {
  dispatch(Actions.DeleteNewsletter({}));

  return Promise.all(ids.map(id => dispatch(API.newsletters.UpdateNewsletterStatus(id, 'Deleted'))
    .then(response => {
      dispatch(Actions.DeleteNewsletterComplete({ succeeded: response.status === 200 }));
      return response.status === 200;
    })
    .catch(err => {
      dispatch(Actions.DeleteNewsletterComplete({ succeeded: false, err }));
      return false;
  })));
}


export const disableNewsletter = (id: string) => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
  dispatch(Actions.DisableNewsletter({}));

  return dispatch(API.newsletters.UpdateNewsletterStatus(id, 'Disabled'))
    .then(response => {
      dispatch(Actions.DisableNewsletterComplete({ succeeded: response.status === 200 }));
      return response.status === 200;
    })
    .catch(err => {
      dispatch(Actions.DisableNewsletterComplete({ succeeded: false, err }));
      return false;
    });
}

export const disableNewsletters = (ids: string[]) => (dispatch, getState: () => GlobalApplicationState): Promise<any> => {
  dispatch(Actions.DisableNewsletter({}));

  return Promise.all(ids.map(id => dispatch(API.newsletters.UpdateNewsletterStatus(id, 'Disabled'))
    .then(response => {
      dispatch(Actions.DisableNewsletterComplete({ succeeded: response.status === 200 }));
      return response.status === 200;
    })
    .catch(err => {
      dispatch(Actions.DisableNewsletterComplete({ succeeded: false, err }));
      return false;
  })));
}

export const enableNewsletter = (id: string) => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
  dispatch(Actions.EnableNewsletter({}));

  return dispatch(API.newsletters.UpdateNewsletterStatus(id, 'Enabled'))
    .then(response => {
      dispatch(Actions.EnableNewsletterComplete({ succeeded: response.status === 200 }));
      return response.status === 200;
    })
    .catch(err => {
      dispatch(Actions.EnableNewsletterComplete({ succeeded: false, err }));
      return false;
    });
}

export const enableNewsletters = (ids: string[]) => (dispatch, getState: () => GlobalApplicationState): Promise<any> => {
  dispatch(Actions.EnableNewsletter({}));

  return Promise.all(ids.map(id => dispatch(API.newsletters.UpdateNewsletterStatus(id, 'Enabled'))
    .then(response => {
      dispatch(Actions.EnableNewsletterComplete({ succeeded: response.status === 200 }));
      return response.status === 200;
    })
    .catch(err => {
      dispatch(Actions.EnableNewsletterComplete({ succeeded: false, err }));
      return false;
  })));
}

export interface SaveNewsletterResponse{
  success?: NewsletterDigest;
  error?: {
    ModelState: SaveNewsletterModelStateErrors;
  };
}

export const saveNewsletter = (newsletter: SaveNewsletterModel) => (dispatch, getState: () => GlobalApplicationState): Promise<SaveNewsletterResponse> => {
  dispatch(Actions.SaveNewsletter({}));
  return dispatch(API.newsletters.SaveNewsletter(newsletter))
  .then(response => {
      dispatch(Actions.SaveNewsletterComplete({ succeeded: response.status === 200 }));
      if(response.status === 400)
        return response.json().then(r=>{return {error:r} as SaveNewsletterResponse;});
      return response.json().then(r=>{return {success:r} as SaveNewsletterResponse});
    })
    .catch(err => {
      dispatch(Actions.SaveNewsletterComplete({ succeeded: false, err }));
      return err;
    });
}

export const cloneNewsletter = (newsletterId: string, title: string) => (dispatch, getState: () => GlobalApplicationState): Promise<SaveNewsletterResponse> => {
  dispatch(Actions.CloneNewsletter({}));
  return dispatch(API.newsletters.CloneNewsletter(newsletterId, title))
  .then(response => {
      dispatch(Actions.CloneNewsletterComplete({ succeeded: response.status === 200 }));
      if(response.status === 400)
        return response.json().then(r=>{return {error:r} as SaveNewsletterResponse;});
      return response.json().then(r=>{return {success:r} as SaveNewsletterResponse});
    })
    .catch(err => {
      dispatch(Actions.CloneNewsletterComplete({ succeeded: false, err }));
      return err;
    });
}

export const getNewsletterImage = (newsletterId: string, imageType: NewsletterImageType) => (dispatch, getState: () => GlobalApplicationState): Promise<File> => {
  dispatch(Actions.GetNewsletterImage({}));

  return dispatch(API.newsletters.GetNewsletterImage(newsletterId, imageType))
    .then(response => response.blob())
    .then(image => {
      dispatch(Actions.GetNewsletterImageComplete({ succeeded: true }));
      return image;
    })
    .catch(err => {
      dispatch(Actions.GetNewsletterImageComplete({ succeeded: false, err }));
      return;
    });
}

export const uploadNewsletterImage = (id: string, imageType: NewsletterImageType, image: File) => (dispatch, getState: () => GlobalApplicationState): Promise<NewsletterDigest> => {
  dispatch(Actions.UploadNewsletterImage({}));

  return MsalAuthModule.getInstance().getAccessToken().then(accessToken => {
    const requestDetails = API.newsletters.UploadNewsletterImage(id, imageType, image);
    const url = getState().config.SparrowClientApiUrl + requestDetails.url
                .replace("{tenant}", getState().tenant.id);
        return new XhrUpload(url, requestDetails)
            .authenticateWith(accessToken)
            .upload();
})
.then((response) => {
     const responseString = (response as string);
     const newsletter = JSON.parse(responseString);
     if(newsletter.id) {
      dispatch(Actions.UploadNewsletterImageComplete({ succeeded: true}));
     }else{
       const errResponse = JSON.parse(responseString)
      dispatch(Actions.UploadNewsletterImageComplete({ succeeded: false, err: errResponse?.Message ?? 'Unable to upload image.'}));
     }
     return newsletter;
})
}

export const uploadNewsletterHeaderFooterImage = (payload: UploadNewsletterImageModel) => (dispatch, getState: () => GlobalApplicationState): Promise<NewsletterDigest> => {
  dispatch(Actions.UploadNewsletterHeaderFooterImage({}));

  return MsalAuthModule.getInstance().getAccessToken().then(accessToken => {
    const requestDetails = API.newsletters.UploadNewsletterHeaderFooterImage(payload);
    const url = getState().config.SparrowClientApiUrl + requestDetails.url
                .replace("{tenant}", getState().tenant.id);
          
    return new XhrUpload(url, requestDetails)
            .authenticateWith(accessToken)
            .upload();
})
.then((response) => {
     const responseString = (response as string);
     const newsletter = JSON.parse(responseString);
     if(newsletter.id) {
      dispatch(Actions.UploadNewsletterHeaderFooterImageComplete({ succeeded: true}));
     }else{
       const errResponse = JSON.parse(responseString)
      dispatch(Actions.UploadNewsletterHeaderFooterImageComplete({ succeeded: false, err: errResponse?.Message ?? 'Unable to upload image.'}));
     }
     return newsletter;
});
}

export const deleteNewsletterImage = (newsletterId: string, imageType: NewsletterImageType) => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
  dispatch(Actions.DeleteNewsletterImage({}));

  return dispatch(API.newsletters.DeleteNewsletterImage(newsletterId, imageType))
    .then(response => {
      dispatch(Actions.DeleteNewsletterImageComplete({ succeeded: response.status === 200 }));
      return response.status === 200;
    })
    .catch(err => {
      dispatch(Actions.DeleteNewsletterImageComplete({ succeeded: false, err }));
      return false;
    });
}

export const resetIssue = (newsletterId: string, localDateAsString: string) => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
  dispatch(Actions.ResetIssue({}));

  return dispatch(API.newsletters.ResetFutureIssue(newsletterId, localDateAsString))
    .then(response => {
      dispatch(Actions.ResetIssueComplete({ succeeded: response.status === 200 }));
      return response.status === 200;
    })
    .catch(err => {
      dispatch(Actions.ResetIssueComplete({ succeeded: false, err }));
      return false;
    });
}

export const resetIssues = (newsletterId: string, localDateAsStrings: string[]) => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
  dispatch(Actions.ResetIssues({}));
  return Promise.all(localDateAsStrings.map(localDateAsString => dispatch(API.newsletters.ResetFutureIssue(newsletterId, localDateAsString))))
    .then(() => {
      dispatch(Actions.ResetIssuesComplete({ succeeded: true }));
      return true;
    })
    .catch(err => {
      dispatch(Actions.ResetIssuesComplete({ succeeded: false, err }));
      return false;
    });
}

export interface SaveNewsletterConfigResponse{
  success?: NewsletterConfigDigest;
  error?: {
    ModelState: SaveNewsletterConfigModelStateErrors;
  };
}

export const saveConfig = (config: NewsletterConfig) => (dispatch, getState: () => GlobalApplicationState): Promise<SaveNewsletterConfigResponse> => {
  dispatch(Actions.SaveConfig({}));

  return dispatch(API.newsletters.SaveConfig(config))
    .then(response => {
      dispatch(Actions.SaveConfigComplete({ succeeded: true }));
      if(response.status === 400)
        return response.json().then(r=>{return {error:r} as SaveNewsletterConfigResponse;});
      return response.json().then(r=>{return {success:r} as SaveNewsletterConfigResponse;});
    })
    .catch(err => {
      dispatch(Actions.SaveConfigComplete({ succeeded: false, err }));
      return false;
    });
}

export interface SaveNewsletterIssueResponse{
  success?: NewsletterFutureIssueDetailModel;
  error?: {
    ModelState: SaveNewsletterIssueModelStateErrors;
  };
}

export const saveIssue = (issue: NewsletterIssueInput) => (dispatch, getState: () => GlobalApplicationState): Promise<SaveNewsletterIssueResponse> => {
  dispatch(Actions.SaveIssue({}));

  return dispatch(API.newsletters.SaveFutureIssue(issue))
    .then(response => {
      dispatch(Actions.SaveIssueComplete({ succeeded: response.status === 200 }));
      if(response.status === 400)
        return response.json().then(r=>{return {error:r} as SaveNewsletterIssueResponse;});
      return response.json().then(r=>{return {success:r} as SaveNewsletterIssueResponse});
    })
    .catch(err => {
      dispatch(Actions.SaveIssueComplete({ succeeded: false, err }));
      return false;
    });
}

export const searchEvents = (searchText: string, pageNumber: number) => (dispatch, getState: () => GlobalApplicationState): Promise<EventFeedItem[]> => {
  dispatch(Actions.SearchEvents({}));

  return dispatch(API.events.GetEventFeed({ textToSearch: searchText }, false, pageNumber))
    .then(response => response.json())
    .then(feed => {
      dispatch(Actions.SearchEventsComplete({ succeeded: true }));
      return feed;
    })
    .catch(err => {
      dispatch(Actions.SearchEventsComplete({ succeeded: false, err }));
      return [];
    });
}

export const searchPosts = (
    searchText: string, 
    pageNumber: number, 
    ignoreSubscriptions: boolean = false
) => (
    dispatch, 
    getState: () => GlobalApplicationState
): Promise<PostFeedItem[]> => {
  dispatch(Actions.SearchPosts({}));

  return dispatch(API.clientPosts.FetchPostsFeed(
        { 
            textToSearch: searchText, 
            ignoreSubscriptions 
        }, 
        pageNumber
    ))
    .then(response => response.json())
    .then(feed => {
      dispatch(Actions.SearchPostsComplete({ succeeded: true }));
      return feed;
    })
    .catch(err => {
      dispatch(Actions.SearchPostsComplete({ succeeded: false, err }));
      return [];
    })
}

export interface SendTestEmailResponse{
  success?: boolean;
  error?: {
    ModelState: SaveNewsletterModelStateErrors;
  };
}

export const sendTestEmail = (newsletterId: string, issueDate: string, additionalAddresses: string[]) => (dispatch, getState: () => GlobalApplicationState): Promise<SendTestEmailResponse> => {
  dispatch(Actions.SendTestEmail({}));

  return dispatch(API.newsletters.SendTestEmail(newsletterId, issueDate, additionalAddresses))
    .then(response => {
      dispatch(Actions.SendTestEmailComplete({ succeeded: response.status === 200 }));
      if(response.status === 400)
        return response.json().then(r=>{return {error:r} as SendTestEmailResponse;});
      return {success:response.status === 200} as SendTestEmailResponse;
    })
    .catch(err => {
      dispatch(Actions.SendTestEmailComplete({ succeeded: false, err }));
      return false;
    });
}

export const getFutureIssueDetails = (newsletterId: string, issueDateLocal: string) => (dispatch, getState: () => GlobalApplicationState): Promise<NewsletterFutureIssueDetailModel> => {
  dispatch(Actions.GetFutureIssueDetails({}));

  return dispatch(API.newsletters.GetFutureIssueDetails(newsletterId, issueDateLocal))
    .then(response => response.json())
    .then(details => {
      dispatch(Actions.GetFutureIssueDetailsComplete({ succeeded: true }));
      return details;
    })
    .catch(err => {
      dispatch(Actions.GetFutureIssueDetailsComplete({ succeeded: false, err }));
      return;
    });
}

export const getIssueImage = (newsletterId: string, issueDateLocal: string, imageType: NewsletterImageType) => (dispatch, getState: () => GlobalApplicationState): Promise<File> => {
  dispatch(Actions.GetIssueImage({}));

  return dispatch(API.newsletters.GetIssueImage(newsletterId, issueDateLocal, imageType))
    .then(response => response.blob())
    .then(image => {
      dispatch(Actions.GetIssueImageComplete({ succeeded: true }));
      return image;
    })
    .catch(err => {
      dispatch(Actions.GetIssueImageComplete({ succeeded: false, err }));
      return;
    });
}

export const uploadIssueImage = (newsletterId: string, issueDate: string, imageType: NewsletterImageType, image: File) => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
  dispatch(Actions.UploadIssueImage({}));

  return MsalAuthModule.getInstance().getAccessToken().then(accessToken => {
    const requestDetails = API.newsletters.UploadIssueImage(newsletterId, issueDate, imageType, image);
    const url = getState().config.SparrowClientApiUrl + requestDetails.url
                .replace("{tenant}", getState().tenant.id);
        return new XhrUpload(url, requestDetails)
            .authenticateWith(accessToken)
            .upload();
    })
    .then(() => {
      dispatch(Actions.UploadIssueImageComplete({ succeeded: true }));
      return true;
    })
    .catch((err) => {
      dispatch(Actions.UploadIssueImageComplete({ succeeded: false, err }));
      return false;
    });
}

export const uploadIssueHeaderFooterImage = (payload: UploadIssueImageModel) => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
  dispatch(Actions.UploadHeaderFooterIssueImage({}));

  return MsalAuthModule.getInstance().getAccessToken().then(accessToken => {
    const requestDetails = API.newsletters.UploadIssueHeaderFooterImage(payload);
    const url = getState().config.SparrowClientApiUrl + requestDetails.url
                .replace("{tenant}", getState().tenant.id);

    return new XhrUpload(url, requestDetails)
            .authenticateWith(accessToken)
            .upload();
    })
    .then(() => {
      dispatch(Actions.UploadHeaderFooterIssueImageComplete({ succeeded: true }));
      return true;
    })
    .catch((err) => {
      dispatch(Actions.UploadHeaderFooterIssueImageComplete({ succeeded: false, err }));
      return false;
    });
}

export const deleteIssueImage = (newsletterId: string, issueDate: string, imageType: NewsletterImageType) => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
  dispatch(Actions.DeleteIssueImage({}));

  return dispatch(API.newsletters.DeleteIssueImage(newsletterId, issueDate, imageType))
    .then(response => {
      dispatch(Actions.DeleteIssueImageComplete({ succeeded: response.status === 200 }));
      return response.status === 200;
    })
    .catch(err => {
      dispatch(Actions.DeleteIssueImageComplete({ succeeded: false, err }));
      return false;
    });
}

export const skipIssue = (newsletterId: string, issueDateLocal: string) => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
  dispatch(Actions.SkipIssue({}));

  return dispatch(API.newsletters.UpdateFutureIssueStatus(newsletterId, issueDateLocal, 'Skip'))
    .then(response => {
      dispatch(Actions.SkipIssueComplete({ succeeded: response.status === 200 }));
      return response.status === 200;
    })
    .catch(err => {
      dispatch(Actions.SkipIssueComplete({ succeeded: false, err }));
      return false;
    });
}

export const unskipIssue = (newsletterId: string, issueDateLocal: string) => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
  dispatch(Actions.UnskipIssue({}));

  return dispatch(API.newsletters.UpdateFutureIssueStatus(newsletterId, issueDateLocal, 'Future'))
    .then(response => {
      dispatch(Actions.UnskipIssueComplete({ succeeded: response.status === 200 }));
      return response.status === 200;
    })
    .catch(err => {
      dispatch(Actions.UnskipIssueComplete({ succeeded: false, err }));
      return false;
    });
}

//Calls the API to fetch all newsletters associated with a specific alias via sender details.
export const getAssociatedNewsletters = (senderName: string, senderEmail: string) => (dispatch, getState: () => GlobalApplicationState): Promise<string[]> => {
  return dispatch(API.newsletters.GetNewslettersUsingAlias(senderName, senderEmail))
  .then(response => response.json())
  .then(response => {
    var onlyTitles = response.map(r => ({title: r.title, id: r.id}))
    return onlyTitles;
  })
  .catch(err => {
    return [];
  })
}