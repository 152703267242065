import React, { useEffect, useLayoutEffect, useState } from "react";

import { ConnectedProps, connect, useDispatch } from "react-redux";
import { Divider } from "@mui/material";
import { useHistory } from "react-router";

import BasePage from "pages/common/basePage";

import Header from "pages/common/header";
import MainContent from "pages/common/mainContent";
import { GlobalApplicationState } from "globalApplicationState";
import ErrorSnackbar from "modules/common/components/snackbars/errorSnackbar";
import LoadingOverlay from "modules/common/components/loadingOverlay";
import SuccessSnackbar from "modules/common/components/snackbars/successSnackbar";
import { useContentBands } from "modules/common/hooks/data/useContentBands";
import { setMainStyle, setShouldDisplayNav } from "modules/adminLayout/actionCreator";
import { useMobileConfig } from "modules/common/hooks/data/useMobileConfig";
import { HomeScreenSettings } from "./homeScreenSettings";
import { ContentBandContentType, IContentBand } from "modules/contentBands/models";
import { ContentBandsButtons } from "modules/contentBands/components/inputs/contentBandsButtons";
import { useHomeScreenConfigs } from "modules/common/hooks/data/useHomeScreenConfigs";
import { homeScreensSlice, saveHomeScreenConfigs } from "modules/homeScreens/reducer";
import { DEFAULT_CONFIG, contentBandsSlice, fetchPublishedBandsAsync } from "modules/contentBands/reducer";
import useQueryParams, { QUERY_PARAM_KEYS } from "modules/common/hooks/useQueryParams";
import ConfirmDialog from "modules/common/components/dialogs/confirmDialog";
import { ContentBandsPreview } from "modules/contentBands/components/dialogs/contentBandsPreview";
import { HOME_SCREEN_PLATFORM_MAP, HomeScreenType, IHomeScreenConfig } from "modules/homeScreens/models";

import "./styles/manageHomeScreenSettingsPage.sass";

interface IManageHomeScreenSettingsPageProps {}

const ManageHomeScreenSettingsPage: React.FunctionComponent<PropsWithRedux> = ({
    tenantId,
    shouldDisplayNav,
    tenantSettings,
    setMainStyle,
    setShouldDisplayNav,
}) => {
    const queryParams = useQueryParams();
    const isAfterPublish = queryParams.get(QUERY_PARAM_KEYS.IS_CONTENT_BAND_PUBLISH);
    const dispatch = useDispatch();
    const [fetchContentBandsContent, setFetchContentBandsContent] = useState<boolean>(false); // only grab content when we need to (they preview)
    const [isPreviewOpen, setIsPreviewOpen] = useState<boolean>(false);
    const history = useHistory();
    const [errorMessage, setErrorMessage] = useState<string>("");
    const { isLoading: isLoadingMobileConfig, mobileConfig } = useMobileConfig();
    const { homeScreenConfigs, pristineConfigs, isLoading: isLoadingHomeScreenConfigs, successMessage } = useHomeScreenConfigs();
    const {
        contentBands,
        config,
        publishedContentBands,
        isLoading: isLoadingBands,
        successMessage: contentBandsSuccess,
    } = useContentBands(fetchContentBandsContent);
    const [confirmHomeScreenTypes, setConfirmHomeScreenTypes] = useState<boolean>(false);
    const isLoadingState = isLoadingMobileConfig || isLoadingBands || isLoadingHomeScreenConfigs;
    const usableConfig = config || DEFAULT_CONFIG;

    useEffect(() => {
        if (isAfterPublish === "true" && homeScreenConfigs) {
            // decide if we need to show confirm change screen types
            let notContentBandsTypes = homeScreenConfigs.filter((c: IHomeScreenConfig) => c.homeScreenType !== HomeScreenType.ContentBands);
            if (notContentBandsTypes?.length) setConfirmHomeScreenTypes(true);

            // remove the query param for future renders
            const urlParams = new URLSearchParams(window.location.search);
            urlParams.delete(QUERY_PARAM_KEYS.IS_CONTENT_BAND_PUBLISH);

            history.replace({
                search: urlParams.toString(),
            });
        }
    }, [isAfterPublish, history, homeScreenConfigs]);

    useLayoutEffect(() => {
        if (!shouldDisplayNav) {
            setShouldDisplayNav(true);
            setMainStyle({ backgroundColor: "unset" });
        }
    }, [shouldDisplayNav, setMainStyle, setShouldDisplayNav]);

    /**
     * Bring in published bands to redux
     * - published bands are null (initial load)
     * - fetchContentBandsContent goes true and there is no content for the content type
     */
    useEffect(() => {
        if (
            publishedContentBands === null ||
            (fetchContentBandsContent &&
                publishedContentBands.some(
                    (cb: IContentBand) =>
                        (cb.contentType === ContentBandContentType.Post && cb.posts === null) ||
                        (cb.contentType === ContentBandContentType.Event && cb.events === null)
                ))
        )
            dispatch(fetchPublishedBandsAsync(fetchContentBandsContent));
    }, [publishedContentBands, dispatch, fetchContentBandsContent]);

    /**
     * Post home screen configs to api
     */
    const onSaveConfigs = async () => {
        if (homeScreenConfigs) dispatch(saveHomeScreenConfigs(homeScreenConfigs));
    };

    const goToEditScreen = () => {
        history.push(`/${tenantId}/admin/contentBands/editor`);
    };

    const onPreview = () => {
        setFetchContentBandsContent(true);
        setIsPreviewOpen(true);
    };

    const onClosePreview = () => {
        setIsPreviewOpen(false);
        setFetchContentBandsContent(false);
    };

    const getConfirmHomeScreensTitle = (): string => {
        let notContentBandsTypes = homeScreenConfigs?.filter((c: IHomeScreenConfig) => c.homeScreenType !== HomeScreenType.ContentBands);
        let result = "";

        if (notContentBandsTypes?.length) {
            if (notContentBandsTypes.length > 1) result = "Home Screens are set to My Feed";
            else result = "Home Screen is set to My Feed";
        }

        return result;
    };

    const getConfirmHomeScreenTypeContent = (): JSX.Element => {
        let notContentBandsTypes = homeScreenConfigs?.filter((c: IHomeScreenConfig) => c.homeScreenType !== HomeScreenType.ContentBands);
        if (!notContentBandsTypes?.length) return <></>;

        let result = (
            <div style={{ minWidth: 400 }}>
                <div>
                    Your organization's{" "}
                    {notContentBandsTypes?.length > 1
                        ? "mobile app and MS Teams app home screens are "
                        : `${HOME_SCREEN_PLATFORM_MAP[notContentBandsTypes[0].platform]} is `}{" "}
                    set to <b>My Feed.</b>
                </div>
                <br />
                <div>
                    Change this to <b>Content Bands</b> to set the recently published bands as your home screen.
                </div>
            </div>
        );

        return result;
    };

    return (
        <BasePage id="mobile-app-settings-page" fullWidth>
            <Header title="Manage Home Screens" />
            <MainContent className="content">
                {isLoadingState && <LoadingOverlay show />}
                <HomeScreenSettings
                    isLoading={isLoadingState}
                    pristineConfigs={pristineConfigs || []}
                    homeScreenConfigs={homeScreenConfigs || []}
                    onSave={onSaveConfigs}
                    publishedBandsCount={publishedContentBands?.length || 0}
                />
                <Divider />
                <div className="form-group home-input">
                    {!isLoadingState && (
                        <ContentBandsButtons
                            publishedBands={publishedContentBands || []}
                            onEdit={goToEditScreen}
                            onPreview={onPreview}
                            contentBands={contentBands}
                        />
                    )}
                </div>
                <ErrorSnackbar errorMessage={errorMessage} clearErrorMessage={() => setErrorMessage("")} />
                <SuccessSnackbar
                    closeOnClickAway={false}
                    successMessage={successMessage || contentBandsSuccess}
                    clearSuccessMessage={() => {
                        dispatch({ type: contentBandsSlice.actions.SET_SUCCESS_MESSAGE, payload: "" });
                        dispatch({ type: homeScreensSlice.actions.SET_SUCCESS_MESSAGE, payload: "" });
                    }}
                />
                <ContentBandsPreview
                    isLoading={isLoadingState}
                    open={isPreviewOpen}
                    onClose={onClosePreview}
                    mobileConfig={mobileConfig}
                    config={usableConfig}
                    contentBands={publishedContentBands || []}
                    homeScreenConfigs={homeScreenConfigs || []}
                    activeLcid={tenantSettings.defaultLCID}
                />
                <ConfirmDialog
                    open={confirmHomeScreenTypes}
                    title={getConfirmHomeScreensTitle()}
                    confirmLabel="OK, GOT IT!"
                    onClose={() => setConfirmHomeScreenTypes(false)}
                    onConfirm={() => setConfirmHomeScreenTypes(false)}
                    confirmButtonProps={{
                        variant: "text",
                    }}
                >
                    {getConfirmHomeScreenTypeContent()}
                </ConfirmDialog>
            </MainContent>
        </BasePage>
    );
};

const connector = connect(
    (state: GlobalApplicationState, ownProps: IManageHomeScreenSettingsPageProps) => ({
        ...ownProps,
        tenantId: state.tenant.id,
        shouldDisplayNav: state.adminLayout.shouldDisplayNav,
        tenantSettings: state.settings.tenantSettings,
    }),
    {
        setShouldDisplayNav,
        setMainStyle,
    }
);

type PropsWithRedux = ConnectedProps<typeof connector>;
export const ConnectedManageHomeScreenSettings = connector(ManageHomeScreenSettingsPage);
