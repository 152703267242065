export enum SortStyle {
    modifiedDesc,
    modifiedAsc,
    titleDesc,
    titleAsc,
    publishDesc,
    publishAsc,
    expiryDesc,
    expiryAsc,
    typeDesc,
    typeAsc,
    authorDesc,
    authorAsc,
    stateDesc,
    stateAsc,
    submittedDesc,
    submittedAsc,
    startDesc,
    startAsc,
    disableDesc,
    disableAsc,
    languageDesc,
    languageAsc,
    viewsDesc,
    viewsAsc,
};

export enum PostState {
    Draft,
    Submission,
    Scheduled,
    Published
};