import * as React from 'react';
import ThreeDots from 'modules/common/components/three-dots';
import WindowResizeListener from 'modules/common/components/window-resize-listener';
import moment from 'moment';
import {injectIntl, IntlShape} from "react-intl";
import {connect, ConnectedProps} from "react-redux";
import { GlobalApplicationState } from 'globalApplicationState';
import { GroupedBarChart, GroupedBarChartProps } from 'modules/insights/components/charts/groupedBarChart';
import PlaceholderWidget from 'modules/insights/components/placeholderWidget';
import { DailyEmailEventStats } from 'modules/newsletters';
import _ from 'lodash';
import ChartSwatchComponent from '../../../../insights/components/charts/chartSwatchComponent';

interface ComponentProps {
    loading?: boolean
    events?: DailyEmailEventStats[];
    intl: IntlShape;
}

const sentSwatch = {color:"#aa214e", label:'Sent'};
const deliveredSwatch = {color:"#3b78ab", label:'Delivered'};
const uniqueOpensSwatch = {color:"#e6911a", label:'Unique opens'};
const clicksSwatch = {color:"#366531", label:'Clicks'};

class DailyEmailEventsWidget extends React.Component<PropsWithRedux, {}> {

    private _chart: GroupedBarChart;
    private _chartElement: HTMLElement;

    public componentDidMount() {
        this._chart = new GroupedBarChart(this.getChartState());
    }

    public componentDidUpdate() {
        if (this._chart) {
            this._chart.update(this.getChartState());
        }
    }

    public componentWillUnmount() {
        if (this._chart) {
            this._chart.destroy();
        }
    }

    public render() {
        moment.locale("en");

        if (!this.props.events || !this.props.events.length)
            return <div className="no-insights-message">No data is currently available.</div>;

        return <React.Fragment>
            <ChartSwatchComponent swatches={[sentSwatch, deliveredSwatch, uniqueOpensSwatch, clicksSwatch]} />
            <div className="analytics-widget" style={{height: "338px"}}>
                    <div className="body" style={{overflowY:'auto'}}>
                    {
                        this.props.loading ?
                            <PlaceholderWidget
                                    title="Daily Email Activity"
                                    height={268} >
                                <ThreeDots />
                            </PlaceholderWidget> :
                        <div>
                        <div ref={(e) => this._chartElement = e!}></div>
                        <WindowResizeListener onChange={() => this._chart.update(this.getChartState())} />
                        </div>
                    }
                    </div>
                    <footer>
                    </footer>
                </div>
            </React.Fragment>;
    }

    private getChartState = (): GroupedBarChartProps => {
        const sent = _.map(this.props.events, e => {return {id:`${e.Day}_0`, label:'Sent', group:moment(e.Day).format("YYYY-MM-DD"), value:e.Processed + e.Dropped }});
        const delivered = _.map(this.props.events, e => {return {id:`${e.Day}_1`, label:'Delivered', group:moment(e.Day).format("YYYY-MM-DD"), value:e.Delivered }});
        const opened = _.map(this.props.events, e => {return {id:`${e.Day}_2`, label:'Unique opens', group:moment(e.Day).format("YYYY-MM-DD"), value:e.UniqueOpened }});
        const clicked = _.map(this.props.events, e => {return {id:`${e.Day}_4`, label:'Clicks', group:moment(e.Day).format("YYYY-MM-DD"), value:e.UniqueClicked }});

        const width = _.max([100,100*sent.length]);

        return {
            el: this._chartElement,
            locale: this.props.locale,
            width: width,
            height: 300,
            data: _.sortBy(_.concat(sent, _.concat(delivered, _.concat(opened, clicked))), d=>d.id),
            labelBars: true,
            colors: [sentSwatch.color, deliveredSwatch.color, uniqueOpensSwatch.color, clicksSwatch.color] ,
            showOnHover: true,
            margin: {top:0, right:0, bottom:0, left:0}
        } as GroupedBarChartProps;
    }
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        locale: state.localization.locale
    })
);
type PropsWithRedux = ConnectedProps<typeof connector>;
export default injectIntl(connector(DailyEmailEventsWidget));

