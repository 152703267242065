import React, { useEffect, useState } from "react";

import { Button, Dialog, DialogContent,  Link,  Tab, Tabs } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import moment from 'moment';

import { TagStats } from "modules/insights";
import { Tab as ITab } from "pages/common/tabs";
import ChipList from "../../chipList";
import InfoHover from "../../hovers/infoHover";
import { TenantNotificationSettings } from "modules/messaging";
import { Notifications } from "../models";
import { TenantSettings } from "modules/settings";
import { CommentingTypes } from "modules/posts/models";
import Loading from "../../loading";
import useIsMounted from "modules/common/hooks/useIsMounted";
import { insightsApi } from "api/instances";
import LoadingSpinner from "../../loadingSpinner";
import { dateOptions } from "utils/dateFormatting";
import OneLastCheckMoreDetails from "./oneLastCheckMoreDetails";
import Disclaimer from "../../disclaimer";

import "../styles/oneLastCheck.sass";

interface IOneLastCheckProps {
    open: boolean;
    publishTime: Date | null | undefined;
    tenantNotificationSettings: TenantNotificationSettings;
    expiryTime: Date | null | undefined;
    breakingTime: Date | null | undefined;
    featuredTime: Date | null | undefined;
    tenantSettings: TenantSettings;
    isPreviouslyPublished?: boolean;
    notifications?: Notifications;
    commentsEnabledOnContent?: boolean;
    reactionsEnabledOnContent?: boolean;
    commentType?: CommentingTypes;
    tags?: string[];
    disableKeepEditing?: boolean;
    onClose: () => void;
    onPublish: () => Promise<void>;
    isContributor: boolean;
    publishBtnName: string;
}

enum ONE_LAST_CHECK_TAB_VALUES {
    DETAILS,
    PREVIEW
}
const ONE_LAST_CHECK_TABS: ITab[] = [
    {
        label: "Post details",
        value: ONE_LAST_CHECK_TAB_VALUES.DETAILS
    },
    {
        label: "Content preview",
        value: ONE_LAST_CHECK_TAB_VALUES.PREVIEW
    },
];

const OneLastCheck: React.FunctionComponent<IOneLastCheckProps> = ({
    open,
    publishTime,
    children,
    tenantNotificationSettings,
    expiryTime,
    breakingTime,
    featuredTime,
    tenantSettings,
    notifications,
    tags = [],
    isPreviouslyPublished = false,
    commentsEnabledOnContent = false,
    reactionsEnabledOnContent = false,
    commentType,
    disableKeepEditing = false,
    onPublish,
    onClose,
    isContributor = false,
    publishBtnName = 'Submit',
}) => {
    const isMounted = useIsMounted();

    const [publishing, setPublishing] = useState<boolean>(false);
    const [fetching, setFetching] = useState<boolean>(true);
    const [totalReach, setTotalReach] = useState<number>();
    const [tagNamesMap, setTagNamesMap] = useState<Record<string, string>>();
    const [tagSubscriberCountsMap, setTagSubscriberCountsMap] = useState<Record<string, number>>();
    const [selectedTab, setSelectedTab] = useState<ONE_LAST_CHECK_TAB_VALUES>(ONE_LAST_CHECK_TAB_VALUES.DETAILS);

    // fetch data
    useEffect(() => {
        const fetch = async () => {
            try {
                const TAG_STATS_IDX = 0;
                const TOTAL_REACH_IDX = 1;
                let results = await Promise.all([insightsApi.GetTagStats(), insightsApi.GetUniqueSubscribersForTopics(tags)]);

                const tagStatsResult = results[TAG_STATS_IDX];
                const totalReachResult = results[TOTAL_REACH_IDX];

                let tagStatsHashMap: Record<string, number> = tagStatsResult.reduce((map: Record<string, number>, curr: TagStats) => ({
                    ...map,
                    [curr.id]: curr.subscriberCount
                }), {});

                let tagNamesHashMap: Record<string, string> = tagStatsResult.reduce((map: Record<string, string>, curr: TagStats) => ({
                    ...map,
                    [curr.id]: curr.name
                }), {});

                if (isMounted()) {
                    setTotalReach(totalReachResult);
                    setTagNamesMap(tagNamesHashMap);
                    setTagSubscriberCountsMap(tagStatsHashMap);
                }
            } catch {

            } finally {
                if (isMounted()) setFetching(false);
            }
        }

        if (open) fetch();
    }, [open, isMounted, tags]);

    const onChangeTab = (event: React.ChangeEvent, newTab: number) => {
        setSelectedTab(newTab);
    }

    const publish = async () => {
        setPublishing(true);
        await onPublish();

        if (isMounted())
            setPublishing(false);
    }

    const getTab = (tab: ITab) => (
        <Tab
            key={`${tab.label}-tab`}
            label={tab.label}
            className={`tab-label ${tab.value === selectedTab ? "selected" : ""}`}
        />
    );

    return (
        <Dialog
            BackdropProps={{ style: { backgroundColor: "rgba(0, 0, 0, 0.8)" } }}
            open={open}
            scroll="body"
            onClose={onClose}
            className="one-last-check"
            fullWidth
            maxWidth={"md"}
            PaperProps={{
                style: {
                    maxWidth: 853
                }
            }}
        >
            <div className="title-container">
                <span className="title">One last check!</span>
                <Button disabled={disableKeepEditing} variant="text" color="primary" onClick={onClose}>
                    KEEP EDITING
                </Button>
                {publishing ?
                    <div style={{ float: 'right' }}><LoadingSpinner size={"14px"} /></div>
                    : <Button variant="contained" color="primary" onClick={publish}>
                        {publishBtnName}
                    </Button>}
            </div>
            <DialogContent className="content-container">
                <>
                    {fetching
                        ? <Loading />
                        : <>
                            <div style={{ padding: "0 21px 0 21px" }}>
                                {
                                    isContributor &&
                                    <Disclaimer
                                        containerStyle={{ marginTop: "21px" }}
                                        icon={<InfoOutlinedIcon />}
                                        text={
                                            <React.Fragment>
                                                <span>
                                                    A submission manager or administrator will review, approve and publish this post for you.&nbsp;
                                                    <Link 
                                                        href="https://support.sparrowconnected.com/en/understanding-roles-and-permissions"
                                                        underline="always"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Learn more
                                                    </Link>.
                                                </span>
                                            </React.Fragment>
                                        }
                                        backgroundColor="#ffe9af"    
                                    />
                                }
                                <div className="info-row" style={{ marginTop: '21px' }}>
                                    <label className="info-label">Publish on</label>
                                    <span className="data">{publishTime ? moment(publishTime).locale("en").format(dateOptions.dateAndTime) : "Now"}</span>
                                </div>
                                <div className="info-row">
                                    <label className="info-label">Total subscribers</label>
                                    <div className="data">
                                        {tagNamesMap && <ChipList
                                            items={tags?.map((tagId: string) => {
                                                const label = `${tagNamesMap[tagId]} (${tagSubscriberCountsMap ? tagSubscriberCountsMap[tagId] : ""})`;
                                                return { id: tagId, name: label };
                                            }) || []}
                                        />}
                                    </div>
                                </div>
                                <div className="info-row">
                                    <label className="info-label">
                                        Total reach
                                        <InfoHover>
                                            Total number of unique users who are subscribed to topic(s) used in this post.
                                        </InfoHover>
                                    </label>
                                    <span className="data">{totalReach?.toLocaleString() || ""}</span>
                                </div>
                            </div>
                            <div className="tabs-container">
                                <Tabs
                                    value={selectedTab}
                                    onChange={onChangeTab}
                                    indicatorColor="primary"
                                    className="one-last-check-tabs"
                                >
                                    {ONE_LAST_CHECK_TABS.map(getTab)}
                                </Tabs>
                                <span className="preview-info">
                                    Preview varies based on device and/or platform.
                                </span>
                            </div>
                            <div hidden={selectedTab !== ONE_LAST_CHECK_TAB_VALUES.PREVIEW} className="preview-tab">
                                {children}
                            </div>
                            <div hidden={selectedTab !== ONE_LAST_CHECK_TAB_VALUES.DETAILS} className="more-details-tab">
                                <div style={{ padding: "0 21px 0 21px" }}>
                                    <OneLastCheckMoreDetails
                                        tenantNotificationSettings={tenantNotificationSettings}
                                        expiryTime={expiryTime}
                                        breakingTime={breakingTime}
                                        featuredTime={featuredTime}
                                        isPreviouslyPublished={isPreviouslyPublished}
                                        notifications={notifications}
                                        commentsEnabledOnContent={commentsEnabledOnContent}
                                        commentType={commentType}
                                        reactionsEnabledOnContent={reactionsEnabledOnContent}
                                        reactionsEnabledOnTenant={tenantSettings.commentsEnabled}
                                    />
                                </div>
                            </div>
                        </>}
                </>
            </DialogContent>
        </Dialog>
    );
}

export default OneLastCheck;
