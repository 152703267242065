import { Action } from 'redux';
import { actionHandler as documentReducer } from './reducer.documentDirectory';
import { actionHandler as editorReducer } from './reducer.editor';
import { actionHandler as postListReducer } from './reducer.postList';
import { actionHandler as postsFeedReducer } from './reducer.postFeed';
import { actionHandler as imageLibraryReducer } from './reducer.imageLibrary';
import { actionHandler as postViewReducer } from './reducer.postView';
import { actionHandler as tipsReducer } from './reducer.tips';
import { actionHandler as supportEditorReducer } from '../support/reducer.supportEditor';
import { PostsState, DefaultPostsState } from './models';

/* Not sure where to put this interface yet. */
export interface Reducer<T> { [actionType: string]: (state: T, action: any) => T }

export const reducer = (prevState: PostsState | undefined, action: Action) => {
    const state = prevState || DefaultPostsState;

    let actionHandler = documentReducer[action.type];
    if (actionHandler) {
        return actionHandler(state, action);
    }

    actionHandler = editorReducer[action.type];
    if (actionHandler) {
        return actionHandler(state, action);
    }

    actionHandler = postListReducer[action.type];
    if (actionHandler) {
        return actionHandler(state, action);
    }

    actionHandler = postsFeedReducer[action.type];
    if (actionHandler) {
        return actionHandler(state, action);
    }

    actionHandler = imageLibraryReducer[action.type];
    if (actionHandler) {
        return actionHandler(state, action);
    }

    actionHandler = postViewReducer[action.type];
    if (actionHandler) {
        return actionHandler(state, action);
    }

    actionHandler = tipsReducer[action.type];
    if (actionHandler) {
        return actionHandler(state, action);
    }

    actionHandler = supportEditorReducer[action.type];
    if (actionHandler) {
        return actionHandler(state, action);
    }

    return state;
};

export const reducers = {
    posts: reducer
}
