import React, { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";

import AudienceChips from "modules/common/components/chips/audienceChips";
import Callout from "modules/common/components/callout";
import InfoHover from "modules/common/components/hovers/infoHover";

import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import AddIcon from "@mui/icons-material/Add";


const AccessibleAudiences: React.FunctionComponent<PropsWithRedux> = props => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [accessibleAudiences, setAccessibleAudiences] = useState(props.accessibleAudiences);
  const [calloutOpen, setCalloutOpen] = useState(false);

  return (
    <div>
      <div>
        <span>Accessible to</span>
        <InfoHover>Audiences who can access and view the file from the company portal and/or app.</InfoHover>
      </div>
      <div>
        <AudienceChips
          audienceIds={props.accessibleAudiences}
          audiences={props.audiences}
          onDelete={(audienceId) => props.onChange(props.accessibleAudiences.filter((selectedAudience) => selectedAudience !== audienceId))}
        />
        <Button
          variant="text"
          color="primary"
          startIcon={<AddIcon />}
          onClick={(event: any) => {
            setAccessibleAudiences(props.accessibleAudiences);
            setAnchorEl(event.currentTarget);
            setCalloutOpen(true);
          }}
        >
          Edit audiences
        </Button>
        <Callout
          anchorEl={anchorEl}
          open={calloutOpen}
          setOpen={(toSet) => {
            props.onChange(accessibleAudiences);
            setCalloutOpen(toSet);
          }}
          footer={
            <div>
              <Button
                variant="text"
                color="primary"
                onClick={() => {
                  props.onChange([]);
                  setAnchorEl(null);
                }}
              >
                Clear all
              </Button>
            </div>
          }
        >
          <List disablePadding className="audiences">
            {props.audiences.map((audience) =>
              <ListItem key={audience.id} dense button onClick={() => {
                const hasSelectedAudience: boolean = accessibleAudiences.findIndex((selectedAudience) => selectedAudience === audience.id) !== -1;
                if (hasSelectedAudience)
                  setAccessibleAudiences(accessibleAudiences.filter((selectedAudience) => selectedAudience !== audience.id));
                else
                  setAccessibleAudiences(accessibleAudiences.concat([audience.id]));
              }}>
                <ListItemIcon className="callout-checkbox">
                  <Checkbox
                    edge="start"
                    tabIndex={-1}
                    disableRipple
                    size="small"
                    color="primary"
                    checked={accessibleAudiences.findIndex((selectedAudience) => selectedAudience === audience.id) !== -1}
                  />
                </ListItemIcon>
                <ListItemText primary={`${audience.displayName || ""}${audience.enabled ? "" : " (disabled)"}`} />
              </ListItem>
            )}
          </List>
        </Callout>
      </div>
    </div>
  );
}


interface ComponentProps {
  accessibleAudiences: string[];
  onChange: (accessibleAudiences: string[]) => void;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    audiences: state.audiences.audiences
  })
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(AccessibleAudiences);