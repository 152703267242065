import React from "react";
import { useDispatch } from "react-redux";

import { IHomeScreenConfig, HomeScreenType, HOME_SCREEN_PLATFORM_MAP } from "modules/homeScreens/models";
import { homeScreensSlice } from "modules/homeScreens/reducer";
import { HomeScreenTypeSelect } from "./homeScreenSelect";

interface IHomeScreenSettingsFormProps {
    config: IHomeScreenConfig;
    publishedBandsCount: number;
    isLoading: boolean;
}

export const HomeScreenSettingsForm: React.FunctionComponent<IHomeScreenSettingsFormProps> = ({
    config,
    publishedBandsCount,
    isLoading,
}) => {
    const dispatch = useDispatch();

    const onChangeHomeScreenType = (newType: HomeScreenType) => {
        dispatch({
            type: homeScreensSlice.actions.SET_HOME_SCREEN_TYPE,
            payload: {
                platform: config.platform,
                homeScreenType: newType,
            },
        });
    };

    const onChangeShowFeed = (checked: boolean) => {
        dispatch({
            type: homeScreensSlice.actions.SET_SHOW_FEED_BELOW,
            payload: {
                platform: config.platform,
                show: checked,
            },
        });
    };

    const onChangeFeedHeader = (newHeader: string) => {
        dispatch({
            type: homeScreensSlice.actions.SET_HEADER_TEXT,
            payload: {
                text: newHeader,
                platform: config.platform,
            },
        });
    };

    return (
        <>
            <div className="full-group">
                <span>{HOME_SCREEN_PLATFORM_MAP[config.platform]}</span>
                <HomeScreenTypeSelect
                    isLoading={isLoading}
                    feedHeader={config.feedHeader}
                    onChangeFeedHeader={onChangeFeedHeader}
                    publishedBandsCount={publishedBandsCount}
                    showFeed={config.showMyFeedBelowContentBands}
                    onChangeShowFeed={onChangeShowFeed}
                    value={config.homeScreenType}
                    onChangeScreenType={onChangeHomeScreenType}
                />
            </div>
        </>
    );
};
