import React, { useState } from "react";

import { Button, Collapse, Divider, Switch } from "@mui/material";

import { CommentingTypes, Post } from "modules/posts/models";
import { Notifications as INotifications } from "./models";
import BreakingTime from "modules/posts/components/post-creation/components/breakingTime";
import FeaturedTime from "modules/posts/components/post-creation/components/featuredTime";
import Reminders from "./reminders";
import Notifications from "./notifications";
import { PublishTimev2 } from "./publishTime";
import { ExpiryTimev2 } from "./expiryTime";
import ReactingEnabled from "modules/posts/components/post-creation/components/reactingEnabled";
import CommentingEnabled from "modules/posts/components/post-creation/components/commentingEnabled";

import "./styles/optionsEditor.sass";

interface IOptionsEditorProps {
    post: Partial<Post>;
    notificationsEnabled: boolean;
    previousNotifications: INotifications | undefined;
    reminderNotificationsEnabled: boolean;
    commentsEnabled: boolean;
    reactionsEnabled: boolean;
    canContributorsToggleReactions: boolean;
    canContributorsToggleComments: boolean;
    onChangeReactionsEnabled: (enabled: boolean) => void;
    onChangeNotifications: (notifications: INotifications) => void;
    onChangeBreakingTime: (breakingTime: string | null) => void;
    onChangePublishTime: (publishTime: string | undefined) => void;
    onChangeFeaturedTime: (featuredTime: string) => void;
    onChangeExpiryTime: (expiryTime: string) => void;
    onChangeCommentingEnabled: (enabled: boolean) => void;
    onChangeCommentingType: (type: CommentingTypes) => void;
    onToggleReminderSettings: (enabled: boolean) => void;
    onToggleNotifications: (enabled: boolean, currToggleVal: boolean) => void;
    isContributor: boolean;
}

const OptionsEditor: React.FunctionComponent<IOptionsEditorProps> = ({
    post,
    previousNotifications, //Original post notifications
    notificationsEnabled, // tenant setting
    reminderNotificationsEnabled, // tenant setting
    reactionsEnabled, // tenant setting
    commentsEnabled, // tenant setting
    canContributorsToggleReactions, // tenant setting
    canContributorsToggleComments, // tenant setting
    onChangeExpiryTime,
    onChangePublishTime,
    onChangeNotifications,
    onChangeBreakingTime,
    onChangeFeaturedTime,
    onChangeReactionsEnabled,
    onChangeCommentingEnabled,
    onChangeCommentingType,
    onToggleReminderSettings,
    onToggleNotifications,
    isContributor,
}) => {
    const [notificationsPanelOpen, setNotificationsPanelOpen] = useState<boolean>(false);
    const [reminderNotificationsPanelOpen, setReminderNotificationsPanelOpen] = useState<boolean>(false);
    const [toggleNotifications, setToggleNotifications] = useState<boolean>(false);

    React.useEffect(() => {
        if(post.isPreviouslyPublished && post.publishTime && new Date(post.publishTime).toISOString() <= new Date().toISOString()) {
            onToggleNotifications(false, toggleNotifications);
        }

        if(post.publishTime && new Date(post.publishTime).toISOString() >= new Date().toISOString() && checkPreviousNotifications()) {
            setToggleNotifications(false);
            onToggleNotifications(false, toggleNotifications);
        }
    }, [previousNotifications]);

    React.useEffect(() => {
        if(previousNotifications?.emailOnPublish === 'none' && 
        previousNotifications.mobileOnPublish === 'none' && 
        previousNotifications?.teamsOnPublish === 'none' && 
        previousNotifications.smsOnPublish === 'none') {
            setToggleNotifications(false);
        }
    }, []);

    const defaultPublishedNotificationState: INotifications = {
        emailOnPublish: 'none',
        teamsOnPublish: 'none',
        smsOnPublish: 'none',
        mobileOnPublish: 'none',
        reminders: []
    }

    const onToggleNotificationSettings = (checked: boolean) => {
        if (checked === true)
            setNotificationsPanelOpen(true);

        // update post values
        setToggleNotifications(checked);
        onToggleNotifications(checked, toggleNotifications);
    }

    const onToggleReminderSettingsInternal = (_: React.ChangeEvent, checked: boolean) => {
        if (!hasReminders())
            setReminderNotificationsPanelOpen(true);

        // update post values
        onToggleReminderSettings(checked);
    }

    const onHideNotificationSettings = () => {
        setNotificationsPanelOpen(false);
    }

    const onShowNotificationSettings = () => {
        setNotificationsPanelOpen(true);
    }

    const onHideReminderSettings = () => {
        setReminderNotificationsPanelOpen(false);
    }

    const onShowReminderSettings = () => {
        setReminderNotificationsPanelOpen(true);
    }

    const postToggleOnPublished = () => {
        if(post.publishTime && new Date(post.publishTime).toISOString() <= new Date().toISOString()) {
            onToggleNotifications(true, toggleNotifications);
            setToggleNotifications(false);
        } else {
            setToggleNotifications(true);
        }
    }

    const checkPreviousNotifications = () => {
        return previousNotifications?.emailOnPublish === 'none' && previousNotifications.mobileOnPublish === 'none' 
        && previousNotifications.smsOnPublish === 'none' && previousNotifications.teamsOnPublish === 'none';
    }

    useState(() => {
        postToggleOnPublished();
    })

    const getDateString = (date: Date | undefined | null): string =>  date ? new Date(date).toISOString() : "";

    const hasReminders = (): boolean => {
        let has = Boolean(post.notifications?.reminders?.length);

        return has;
    }
    
    const remindersToggled = hasReminders();

    return (
        <>
            <div className="options-container settings-padding">
                {
                    !isContributor &&
                    <>
                        <PublishTimev2
                            publishTime={getDateString(post.publishTime)}
                            onChange={onChangePublishTime}
                        />
                        <ExpiryTimev2
                            expiryTime={getDateString(post.expiryTime)}
                            onChange={onChangeExpiryTime}
                        />
                        <Divider style={{ margin: "28px 0", color: "#aaaaaa" }} />
                    </>
                }
                {notificationsEnabled &&
                    <>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}>
                            <label>Notify subscribers upon publishing</label>
                            <Switch
                                color="primary"
                                checked={toggleNotifications}
                                onChange={(e) => onToggleNotificationSettings(e.target.checked)}
                            />
                        </div>
                        <Collapse in={toggleNotifications}>
                            <Button
                                variant="text"
                                color="primary"
                                disableRipple
                                onClick={onShowNotificationSettings}
                                className="notification-options">
                                Edit notifications
                            </Button>
                        </Collapse>
                    </>
                }
                {reminderNotificationsEnabled &&
                    <>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}>
                            <label>Send reminder if post is unread</label>
                            <Switch
                                color="primary"
                                checked={remindersToggled}
                                onChange={onToggleReminderSettingsInternal}
                            />
                        </div>
                        <Collapse in={remindersToggled}>
                            <Button
                                variant="text"
                                color="primary"
                                disableRipple
                                onClick={onShowReminderSettings}
                                className="notification-options"
                            >
                                Edit reminders
                            </Button>
                        </Collapse>
                    </>
                }
                {((!post.isPreviouslyPublished && notificationsEnabled) || reminderNotificationsEnabled) && <Divider style={{ color: "#aaaaaa", margin: "24px 0 28px" }} />}
                {
                    !isContributor &&
                    <>
                        <BreakingTime
                            breakingTime={getDateString(post.breakingTime)}
                            publishTime={getDateString(post.publishTime)}
                            expiryTime={getDateString(post.expiryTime)}
                            onChange={onChangeBreakingTime}
                        />
                        <FeaturedTime
                            featuredTime={getDateString(post.featuredTime)}
                            publishTime={getDateString(post.publishTime)}
                            expiryTime={getDateString(post.expiryTime)}
                            onChange={onChangeFeaturedTime}
                        />
                        <Divider style={{ color: "#aaaaaa", margin: "23px 0 25px" }} />
                    </>
                }
                {
                    reactionsEnabled &&
                    <ReactingEnabled
                        reactingEnabled={(post.reactingEnabled || false)}
                        onChange={onChangeReactionsEnabled}
                        disabled={isContributor && !canContributorsToggleReactions}
                    />
                }
                {
                    commentsEnabled &&
                    <CommentingEnabled
                        commentingEnabled={(post.commentingEnabled || false)}
                        commentingType={post.commentingType || "standard"}
                        onChangeCommentingEnabled={onChangeCommentingEnabled}
                        onChangeCommentingType={onChangeCommentingType}
                        disabled={isContributor && !canContributorsToggleComments}
                    />
                }
            </div>
            <Notifications
                show={notificationsPanelOpen}
                isPreviouslyPublished={!!post.isPreviouslyPublished}
                notifications={post.notifications}
                notificationsToggle={toggleNotifications}
                previousNotifications={previousNotifications}
                postPublished={post.publishTime}
                defaultNotificationsState={defaultPublishedNotificationState}
                type="post"
                onChangeNotifications={onChangeNotifications}
                onToggleNotifications={onToggleNotificationSettings}
                onClose={onHideNotificationSettings}
            />
            <Reminders
                show={reminderNotificationsPanelOpen}
                isPreviouslyPublished={!!post.isPreviouslyPublished}
                notifications={post.notifications}
                type="post"
                onChangeNotifications={onChangeNotifications}
                onClose={onHideReminderSettings}
            />
        </>

    );
}

export default OptionsEditor;
