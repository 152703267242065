import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import * as actions from '../actionCreator';
import { GlobalApplicationState } from 'globalApplicationState';
import { push } from 'react-router-redux';
import TableView from "modules/common/components/table-view/components/table-view";
import { Survey } from '../models';
import PageToolBar, { MenuButton } from 'modules/common/components/pageToolBar';
import moment from 'moment';
import LoadingOverlay from 'modules/common/components/loadingOverlay';
import confirm from 'utils/notyPopups';

class SurveyListing extends React.Component<PropsWithRedux, ComponentState>{
  private _tableView: TableView;
  
  constructor(props){
    super(props);
    this.state = { selectedItems: [] };
  }

  public componentWillMount(){
    this.props.surveyList.length === 0 &&
    !this.props.isFetching &&
    this.props.getSurveys();
  }

  public render() {
    moment.locale("en");

    let defaultButtons = (
      [
          { key: 'edit', label: 'Edit' },
          { key: 'details', label: 'Details' }
      ]
    );

    return (
    <div>
      <PageToolBar
          fixedOptions={{ fixedAtHeight: 91 }}
          callToActionButton={this.getCallToActionButton()}
          rightSideButtons={this.getActionButtons()}
          overflowButtons={this.getDeleteButton()}
          defaultButtons={defaultButtons} />
      <div style={{height: '30px'}} /> 
      <TableView
          columns={this.columns()}
          data={this.tableData()}
          multiselect={true}
          ref={e => this._tableView = e!}
          onSelectionChange={this.onTableSelectionChanged}
          onRowClick={this.onRowClicked}
      />
      <LoadingOverlay absolute={true} show={this.props.isFetching || this.props.isDeleting} /> 
    </div>);
  }

  private tableData = () => {
    return this.props.surveyList.reduce((data, survey) => ({...data, [survey.id]: survey}), {});
  }

  private columns = () => {
    return [
      {
          dataKey: 'title',
          name: 'Title',
          truncate: true,
          renderer: (rowData: Survey) => (<span>{rowData.title}</span>),
          width: 8
      },
      {
        dataKey: 'publishTime',
        name: 'Publish Date',
        width: 2,
        renderer: (rowData: Survey) => (<span>{this.formatPublishTime(rowData.publishTime!)}</span>)
      },
      {
        dataKey: 'expiryTime',
        name: 'Expiry Date',
        width: 2,
        renderer: (rowData: Survey) => (<span>{this.formatExpiryTime(rowData.expiryTime!)}</span>)
      },
      {
          dataKey: 'author',
          name: 'Author',
          width: 2
      },
      {
        dataKey: 'responses',
        name: 'Responses',
        width: 2,
        renderer: (rowData: Survey, column, selected) => (<span>{Object.keys(rowData.aggregatedResults).reduce((acc, key) => acc + rowData.aggregatedResults[key], 0)}</span>)
      }
  ]}

  private onTableSelectionChanged = (selectedItems: string[]) => {
    this.setState({ ...this.state, selectedItems });
  }

  private onRowClicked = (id: string) => {
    let survey = this.props.surveyList.find(s => s.id === id)!;
    if(this.isPublished(survey))
      this.props.redirectTo(`/${this.props.tenant}/admin/surveys/${id}`);
    else
      this.props.redirectTo(`/${this.props.tenant}/admin/surveys/edit/${id}`);
  }

  private isPublished = (survey: Survey) => {
    return !survey.publishTime || new Date(survey.publishTime).getTime() <= Date.now();
  }

  private getActionButtons = () => {
    let buttons = [] as MenuButton[];
    const numSelected = this.state.selectedItems.length;
    if (numSelected === 1){
      buttons.push({onClick:() => this.props.redirectTo(`/${this.props.tenant}/admin/surveys/edit/${this.state.selectedItems[0]}`), label: 'Edit', key:'edit'});
      buttons.push({onClick:() => this.props.redirectTo(`/${this.props.tenant}/admin/surveys/${this.state.selectedItems[0]}`), label: "Details", key: 'details'});
    }
    // if (numSelected > 0){
    //   buttons.push({onClick: this.deleteSurveys, label: `Delete ${numSelected} Survey(s)`, key:'delete'});
    // }
    return buttons;
  }

  private getDeleteButton = () => {
    const numSelected = this.state.selectedItems.length;
    return numSelected > 0 ? 
    [{onClick: this.deleteSurveys, label: `Delete ${numSelected} Survey(s)`, key:'delete'}] : [];
  }

  private getCallToActionButton = () => {
    const numSelected = this.state.selectedItems.length;
    return this.state.selectedItems.length === 0
    ? { onClick: () => this.props.redirectTo(`/${this.props.tenant}/admin/surveys/edit/`), label: "New Survey", key: "newSurvey"}
    : { key: 'deselect', label: `Deselect ${numSelected} Survey(s)`, onClick: this.deselectAllSurveys }
  }

  private deselectAllSurveys = () => {
    this._tableView.deselectAll();
}

  private deleteSurveys = async () => {
    if(await confirm.show({ text: `Are you sure you want to delete these surveys?`, title: "Survey deletion" })){
      this.props.deleteSurveys(this.state.selectedItems);
    }
  }


  private formatPublishTime = (time: string) => {
    if (time){
      return moment(new Date(time)).fromNow();
    }
    return 'Immediate';
  }

  private formatExpiryTime = (time: string) => {
    if (time){
      return moment(new Date(time)).fromNow();
    }
    return 'Never';
  }
}

interface ComponentProps{
}

interface ComponentState{
  selectedItems: string[];
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
      ...ownProps,
      surveyList: state.surveys.surveyList,
      isFetching: state.surveys.isFetching,
      isDeleting: state.surveys.isDeleting,
      tenant: state.tenant.id
  }),
  {
    getSurveys: actions.getSurveys,
    redirectTo: push,
    deleteSurveys: actions.deleteSurveys
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(SurveyListing);