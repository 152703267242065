import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import { AttachedFile, CustomFileData } from "modules/gallery/models";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import GalleryVFSBrowser from "modules/gallery/components/galleryVFSBrowser";
import { chonkyCustomDataToAttachedFile } from "utils/chonkyCustomDataToAttachedFile";
import UploadMediaButton from "modules/gallery/components/uploadMediaButton";
import { DialogActions, Stack } from "@mui/material";
import LaunchIcon from '@mui/icons-material/Launch';
import { onNextFrame } from "utils/onNextFrame";
import { addCustomSelectedFilesStylingDivs } from "modules/gallery/components/customStylingFunctions";

import "../styles/imageGalleryDialog.sass";

class ImageGalleryDialog extends React.Component<PropsWithRedux, ComponentState> {
    private MAX_SELECTABLE_IMAGES: number = 20;

    constructor(props: PropsWithRedux) {
        super(props);

        this.state = {
            imageLink: "",
            selectableImages: this.getSelectableImages(),
            selectedImages: this.getSelectedImages(),
            isLinkDialog: false,
            filesToUpload: null,
        };
    }

  componentDidUpdate(prevProps: PropsWithRedux) {
    if (this.props.show && !prevProps.show)
        this.setState({
            ...this.state,
            selectableImages: this.getSelectableImages(),
            selectedImages: this.getSelectedImages(),
            isLinkDialog: false,
        }, () => this.renderSelectedFilesStyles());
    }

    public render() {
        return (
            <Dialog 
                open={this.props.show} 
                maxWidth={false} 
                onClose={this.onClose} 
                PaperProps={{
                    sx: {
                        width: '900px', 
                        height: this.state.isLinkDialog ? 'fit-content' : '700px'
                    }
                }} 
            >
                <DialogTitle className="events-dialog-header" sx={{ borderBottom: this.state.isLinkDialog ? '1px solid rgba(0, 0, 0, 0.12)' : '' }}>
                    <div className="events-dialog-header-title">
                        <div>
                            <Typography variant="h2">{this.state.isLinkDialog ? "Insert Image Using Link" : "Select Image"}</Typography>
                            {
                                this.props.multiSelect &&
                                <Typography variant="subtitle2">(select up to {this.MAX_SELECTABLE_IMAGES})</Typography>
                            }
                        </div>
                        <div>
                            {
                                !this.state.isLinkDialog &&
                                <UploadMediaButton onFileUpload={files => this.setState({ filesToUpload: files })} />
                            }
                            <IconButton onClick={this.onClose} size="large" edge="end">
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent className="authoring-gallery-vfs">
                    {
                        !this.state.isLinkDialog &&
                        <GalleryVFSBrowser
                            onMultiSelect={(files: CustomFileData[]) => this.onChonkySelectedImages(files)}
                            onSingleSelect={(file: CustomFileData) => this.onChonkySelectedImages([file])}
                            externalFilesToUpload={this.state.filesToUpload}
                            clearExternalFilesToUpload={() => this.setState({filesToUpload: null})}
                            disableActions
                            maxNumItemsPerPage={12}
                            singleClickFileSelect
                            onSingleClickSelect={(file: CustomFileData) => this.onChonkySelectedImages([file])}
                            fullScreenDragNDrop
                            allowUploadCancel
                            onUploadedImages={(files: CustomFileData[]) =>{this.onChonkySelectedImages(files, true)}}
                        />
                    }
                    {
                        this.state.isLinkDialog &&
                        <TextField 
                            className='image-link-textfield' 
                            variant="outlined"
                            value={this.state.imageLink} 
                            onChange={this.onChangeImageLink} 
                            placeholder="Enter URL link"
                            margin="normal"
                        />
                    }
                </DialogContent>
                <DialogActions className={`events-dialog-footer ${this.state.isLinkDialog ? "" : "events-dialog-footer-border"}`}>
                    <div>
                        {
                            this.props.allowLink && !this.state.isLinkDialog &&
                            <Button endIcon={<LaunchIcon />} variant="text" color="primary" onClick={() => this.onUseImageLink(true)}>
                                USE IMAGE LINK
                            </Button>
                        }
                        {
                            this.state.isLinkDialog &&
                            <Button variant="text" color="primary" onClick={() => this.onUseImageLink(false)}>
                                USE IMAGE GALLERY
                            </Button>
                        }
                    </div>
                    <Stack
                        className="events-dialog-actions"
                        gap={2}
                        direction={{
                            xs: 'row-reverse',
                            sm: 'row',
                        }}
                        sx={{
                            alignSelf: { xs: 'flex-end', sm: 'center' },
                        }}
                    >
                        <Button variant="text" color="primary" onClick={this.onClose}>Cancel</Button>
                        <Button 
                            variant="contained" 
                            color="primary" 
                            disabled={this.state.isLinkDialog? !this.state.imageLink.trim() : !this.state.selectedImages.length} 
                            onClick={this.onApply}
                        >
                            Apply
                        </Button>
                    </Stack>
                </DialogActions>
                { this.renderSelectedFilesStyles() }
            </Dialog>
        );
    }

    private onChonkySelectedImages = (images: CustomFileData[], runApplyAfter?: boolean) => {
        if (images.length === 0) return;

        if (this.props.multiSelect) {
            const existingImageIds = new Set(this.state.selectedImages.map(image => image.ospreyId));
            const matchingIds = new Set(images.filter(image => existingImageIds.has(image.id)).map(image => image.id));

            const imagesWithoutAnyMatchingIds = this.state.selectedImages
                .filter(image => !matchingIds.has(image.ospreyId))
                .concat(images.filter(image => !matchingIds.has(image.id)).map(chonkyCustomDataToAttachedFile));

            this.setState(
                { selectedImages: imagesWithoutAnyMatchingIds.splice(0, this.MAX_SELECTABLE_IMAGES) },
                () => runApplyAfter 
                    ? this.onApply() 
                    : this.renderSelectedFilesStyles()
            );
        }
        else {
            const imageToSelect = images[0];

            this.setState((prev) => ({ 
                selectedImages: prev.selectedImages[0]?.ospreyId === imageToSelect.id 
                ? [] 
                : [chonkyCustomDataToAttachedFile(imageToSelect)]
                }), 
                () =>  runApplyAfter ? this.onApply() : this.renderSelectedFilesStyles()
            );
        }
    }

    private onUseImageLink = (isLink: boolean) => {
        this.setState({ isLinkDialog: isLink });
    }

    private getSelectableImages = (): number => {
        if (this.props.multiSelect)
            return this.MAX_SELECTABLE_IMAGES - (this.props.attachedContent || []).length;

        return !!this.props.selectedImage ? 0 : 1;
    }

    private getSelectedImages = (): AttachedFile[] => {
        if (this.props.multiSelect)
            return this.props.attachedContent || [];

        return !!this.props.selectedImage ? [this.props.selectedImage] : []
    }

    private onApply = () => {
        if (!this.state.isLinkDialog)
            this.props.onApply(this.state.selectedImages);
        else
            this.props.onApply([{
                blobId: "",
                fileExtension: "",
                fileName: "",
                fileType: "image",
                fileUrl: this.state.imageLink,
                ospreyId: ""
            }]);
        
        this.onClose();
    }

    private onChangeImageLink = (event) => {
        this.setState({ ...this.state, imageLink: event.target.value.trim() });
    }

    private onClose = () => {
        this.props.onClose();
    }

    private renderSelectedFilesStyles = () => onNextFrame(() => addCustomSelectedFilesStylingDivs(this.state.selectedImages.map(image => image.ospreyId)));
}

interface ComponentProps {
    show: boolean;
    allowLink?: boolean;
    attachedContent?: AttachedFile[];
    includeVideos?: boolean;
    multiSelect?: boolean;
    selectedImage?: AttachedFile;
    onApply: (selectedImages: AttachedFile[]) => void;
    onClose: () => void;
}

interface ComponentState {
    imageLink: string;
    selectableImages: number;
    selectedImages: AttachedFile[];
    isLinkDialog: boolean;
    filesToUpload: FileList | null;
}

const connector = connect((state: GlobalApplicationState, ownProps: ComponentProps) => ( ownProps ));
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(ImageGalleryDialog);