import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { GlobalApplicationState } from 'globalApplicationState';

import { NotificationRange, Notifications as INotifications } from "./models";

import Checkbox from "@mui/material/Checkbox";
import Collapse from "@mui/material/Collapse";
import Drawer from "@mui/material/Drawer";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Switch from "@mui/material/Switch";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import check from 'modules/common/icons/src/check';


class Notifications extends React.Component<PropsWithRedux, ComponentState> {
    constructor(props: PropsWithRedux) {
        super(props);

        this.state = this.getNotifications();
    }

    public render() {
        const { type } = this.props;

        const areAnyPlatformsEnabled =
            this.props.notificationSettings.settings[`${type}PublishedEmailAttempt`].toggle ||
            this.props.notificationSettings.settings[`${type}PublishedTeamsAttempt`].toggle ||
            this.props.notificationSettings.settings[`${type}PublishedSMSAttempt`].toggle ||
            this.props.notificationSettings.settings[`${type}PublishedMobileAttempt`].toggle;

        if (!this.props.show || !this.props.notifications || !this.props.notificationSettings || !areAnyPlatformsEnabled)
            return <React.Fragment></React.Fragment>;

        const { settings } = this.props.notificationSettings;

        return (
            <Drawer anchor="right" open={true} onClose={this.onClose} classes={{ paper: "authoring-drawer-panel" }}>
                <Paper square elevation={0} className="authoring-drawer-panel-header">
                    <IconButton onClick={this.onClose} size="large">
                        <ArrowBackIcon />
                    </IconButton>
                    <div>Edit notification upon publishing</div>
                </Paper>
                <div className="authoring-drawer-panel-content">
                    <Paper elevation={0} className="publishing-notifications">
                        <div className="drawer-panel-section-header">
                            <div>Notify upon publishing</div>
                            <Switch
                                color="primary"
                                checked={this.state.notificationsEnabled}
                                onChange={(evt: any) => this.onTogglePostNotifications(evt.target.checked)}
                                disabled={!areAnyPlatformsEnabled}
                            />
                        </div>
                        <Collapse in={this.state.notificationsEnabled}>
                            <div>
                                <div className="description">
                                    Upon publishing this {type}, employees will be notified through the following channels:
                                </div>
                                {settings[`${type}PublishedTeamsAttempt`]?.toggle && (
                                    <div className="notification-setting">
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    checked={this.state.teamsNotificationRange !== "none"}
                                                    onChange={this.onToggleTeamsEnabled}
                                                />
                                            }
                                            label="MS Teams Chatbot"
                                        />
                                    </div>
                                )}
                                {settings[`${type}PublishedMobileAttempt`]?.toggle && (
                                    <div className="notification-setting">
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    checked={this.state.mobileNotificationRange !== "none"}
                                                    onChange={this.onToggleMobileEnabled}
                                                />
                                            }
                                            label="Mobile App"
                                        />
                                    </div>
                                )}
                                {settings[`${type}PublishedEmailAttempt`]?.toggle && (
                                    <div className="notification-setting">
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    checked={this.state.emailEnabled}
                                                    onChange={(e) => this.onToggleEmailEnabled(e)}
                                                />
                                            }
                                            label="Email"
                                        />
                                        <div className="notification-options">
                                            <RadioGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Radio
                                                            color="primary"
                                                            checked={this.state.emailNotificationRange === "subscribers"}
                                                            disabled={!this.state.emailEnabled}
                                                            onChange={this.onEmailSubscribers}
                                                        />
                                                    }
                                                    label="Send to all topic subscribers"
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Radio
                                                            color="primary"
                                                            checked={this.state.emailNotificationRange === "fallback"}
                                                            disabled={!this.state.emailEnabled}
                                                            onChange={this.onEmailFallback}
                                                        />
                                                    }
                                                    label="Send email only if standard app notifications are not set up for the topic subscriber"
                                                />
                                            </RadioGroup>
                                        </div>
                                    </div>
                                )}
                                {settings[`${type}PublishedSMSAttempt`]?.toggle && (
                                    <div className="notification-setting">
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    checked={this.state.smsEnabled}
                                                    onChange={(e) => this.onToggleSMSEnabled(e)}
                                                />
                                            }
                                            label="SMS"
                                        />
                                        <div className="notification-options">
                                            <RadioGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Radio
                                                            color="primary"
                                                            checked={this.state.smsNotificationRange === "subscribers"}
                                                            disabled={!this.state.smsEnabled}
                                                            onChange={this.onSMSSubscribers}
                                                        />
                                                    }
                                                    label="Send to all topic subscribers"
                                                />
                                            </RadioGroup>
                                            <FormControlLabel
                                                control={
                                                    <Radio
                                                        color="primary"
                                                        checked={this.state.smsNotificationRange === "fallback"}
                                                        disabled={!this.state.smsEnabled}
                                                        onChange={this.onSMSFallback}
                                                    />
                                                }
                                                label="Send SMS only if standard app notifications or email are not set up for the topic subscriber"
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </Collapse>
                    </Paper>
                </div>
            </Drawer>
        );
    }

    /*
    * Gets the current notifications on first mount, if a post has previous notifications set the post notifications. If the post is previouslyPublished disable the notifications
    * else set the default notifications. If toggle is off, set the post notifications that are saved to the post.
    */
    private getNotifications = (): ComponentState => {
        const { notifications } = this.props;

        let isOldPublishedPost = this.props.isPreviouslyPublished && this.props.postPublished && new Date(this.props.postPublished).toISOString() <= new Date().toISOString();

            //Checks if our toggle is on, if it is let's use the post notifications else if it's not, let's use our default notifications
            if (this.props.notificationsToggle) {
                if (isOldPublishedPost || this.props.previousNotifications) {
                return {
                    emailEnabled: !!this.props.previousNotifications && this.props.previousNotifications.emailOnPublish !== "none",
                    emailNotificationRange:
                        !!this.props.previousNotifications && this.props.previousNotifications.emailOnPublish !== "none"
                            ? this.props.previousNotifications.emailOnPublish
                            : "none",
                    mobileNotificationRange:
                        !!this.props.previousNotifications && this.props.previousNotifications.mobileOnPublish !== "none"
                            ? this.props.previousNotifications.mobileOnPublish
                            : "none",
                    notificationsEnabled: isOldPublishedPost ? false : true,
                    smsEnabled: !!this.props.previousNotifications && this.props.previousNotifications.smsOnPublish !== "none",
                    smsNotificationRange:
                        !!this.props.previousNotifications && this.props.previousNotifications.smsOnPublish !== "none"
                            ? this.props.previousNotifications!.smsOnPublish
                            : "none",
                    teamsNotificationRange:
                        !!this.props.previousNotifications && this.props.previousNotifications.teamsOnPublish !== "none"
                            ? this.props.previousNotifications!.teamsOnPublish
                            : "none",
                };
            } else {
                return {
                    emailEnabled: !!this.props.defaultNotificationsState && this.props.defaultNotificationsState.emailOnPublish !== "none",
                    emailNotificationRange:
                        !!this.props.defaultNotificationsState && this.props.defaultNotificationsState.emailOnPublish !== "none"
                            ? this.props.defaultNotificationsState.emailOnPublish
                            : "none",
                    mobileNotificationRange:
                        !!this.props.defaultNotificationsState && this.props.defaultNotificationsState.mobileOnPublish !== "none"
                            ? this.props.defaultNotificationsState.mobileOnPublish
                            : "none",
                    notificationsEnabled: true,
                    smsEnabled: !!this.props.defaultNotificationsState && this.props.defaultNotificationsState!.smsOnPublish !== "none",
                    smsNotificationRange:
                        !!this.props.defaultNotificationsState && this.props.defaultNotificationsState!.smsOnPublish !== "none"
                            ? this.props.defaultNotificationsState!.smsOnPublish
                            : "none",
                    teamsNotificationRange:
                        !!this.props.defaultNotificationsState && this.props.defaultNotificationsState!.teamsOnPublish !== "none"
                            ? this.props.defaultNotificationsState!.teamsOnPublish
                            : "none",
                };
            }
        } else {
            return {
                emailEnabled: !!notifications && notifications.emailOnPublish !== "none",
                emailNotificationRange:
                    !!notifications && notifications.emailOnPublish !== "none" ? notifications.emailOnPublish : "none",
                mobileNotificationRange:
                    !!notifications && notifications.mobileOnPublish !== "none" ? notifications.mobileOnPublish : "none",
                notificationsEnabled: true,
                smsEnabled: !!notifications && notifications.smsOnPublish !== "none",
                smsNotificationRange: !!notifications && notifications.smsOnPublish !== "none" ? notifications.smsOnPublish : "none",
                teamsNotificationRange: !!notifications && notifications.teamsOnPublish !== "none" ? notifications.teamsOnPublish : "none",
            };
        }
    };

    private onApplyNotificationSettings = () => {
        const emailOnPublish: NotificationRange = this.state.emailEnabled ? this.state.emailNotificationRange : "none";
        const mobileOnPublish: NotificationRange = this.state.mobileNotificationRange;
        const smsOnPublish: NotificationRange = this.state.smsEnabled ? this.state.smsNotificationRange : "none";
        const teamsOnPublish: NotificationRange = this.state.teamsNotificationRange;
        const notifications: INotifications = {
            ...this.props.notifications!,
            emailOnPublish: emailOnPublish,
            mobileOnPublish: mobileOnPublish,
            smsOnPublish: smsOnPublish,
            teamsOnPublish: teamsOnPublish,
        };
        this.props.onChangeNotifications(notifications);
    };

    private checkPostNotificationsAreOff = () => {
        if (
            !this.state.emailEnabled &&
            !this.state.smsEnabled &&
            this.state.teamsNotificationRange === "none" &&
            this.state.mobileNotificationRange === "none" &&
            this.props.onToggleNotifications
        ) {
            this.props.onToggleNotifications(false);
        }
    };

    private onClose = () => {
        this.onApplyNotificationSettings();
        this.checkPostNotificationsAreOff();
        this.props.onClose();
    };

    private onEmailFallback = () => {
        this.setState({ emailNotificationRange: "fallback" });
    };

    private onEmailSubscribers = () => {
        this.setState({ emailNotificationRange: "subscribers" });
    };

    private onSMSFallback = () => {
        this.setState({ smsNotificationRange: "fallback" });
    };

    private onSMSSubscribers = () => {
        this.setState({ smsNotificationRange: "subscribers" });
    };

    private onToggleEmailEnabled = (e: any) => {
        if(e.target.checked)
            this.setState({ emailEnabled: e.target.checked, emailNotificationRange: this.checkWhichEmailRangeToUse() });
        else
            this.setState({ emailEnabled: e.target.checked, emailNotificationRange: 'none' });
    };

    private onToggleMobileEnabled = () => {
        if (this.state.mobileNotificationRange === "none") this.setState({ mobileNotificationRange: "subscribers" });
        else this.setState({ mobileNotificationRange: "none" });
    };

    private onToggleTeamsEnabled = () => {
        if (this.state.teamsNotificationRange === "none") this.setState({ teamsNotificationRange: "subscribers" });
        else this.setState({ teamsNotificationRange: "none" });
    };

    private onToggleSMSEnabled = (e: any) => {
        if(e.target.checked)
        this.setState({ smsEnabled: e.target.checked, smsNotificationRange: this.checkWhichSMSRangeToUse() });
    else
        this.setState({ smsEnabled: e.target.checked, smsNotificationRange: 'none' });
    };

    /*
    *  Toggles on or off post previous notifications notifications if checked.
    */
    private onTogglePostNotifications = (checked: boolean) => {
        if (checked) {
            this.onSetPostNotificationsToPost(checked);
        } else {
            this.setPostNotificationsToNone(checked);
        }
    };

    /*
    * Sets the current post notifications, checks if any previous notifications, if none sets to the default notifications
    */
    private onSetPostNotificationsToPost = (checked: boolean) => {
        if ((this.props.isPreviouslyPublished && this.props.postPublished && new Date(this.props.postPublished).toISOString() <= new Date().toISOString()) 
            || this.props.previousNotifications) 
        {
            this.setPreviousPostNotifications(checked);
        } else {
            this.setDefaultPostNotifications(checked);
        }
    }

    /*
    * Sets default notifications of the tenant notification config.
    */
    private setDefaultPostNotifications = (checked: boolean) => {
        const { type } = this.props;
        const defaultSettings = this.props.notificationSettings[`default${type.charAt(0).toUpperCase() + type.substr(1)}Settings`];

        this.setState({
            emailEnabled: defaultSettings?.emailOnPublish !== "none",
            emailNotificationRange: (defaultSettings?.emailOnPublish as NotificationRange) || "subscribers",
            mobileNotificationRange: (defaultSettings?.mobileOnPublish as NotificationRange) || "none",
            notificationsEnabled: checked,
            smsEnabled: defaultSettings?.smsOnPublish !== "none",
            smsNotificationRange: (defaultSettings?.smsOnPublish as NotificationRange) || "subscribers",
            teamsNotificationRange: (defaultSettings?.teamsOnPublish as NotificationRange) || "none",
        });
    }

    /*
    * Sets notifications to none.
    */
    private setPostNotificationsToNone = (checked: boolean) => {
        this.setState({
            emailEnabled: false,
            emailNotificationRange: 'none',
            mobileNotificationRange: 'none',
            notificationsEnabled: checked,
            smsEnabled: false,
            smsNotificationRange: 'none',
            teamsNotificationRange: 'none',
        });
    }

    /*
    * Sets notifications to the posts previous notifications.
    */
    private setPreviousPostNotifications = (checked: boolean) => {
        this.setState({
            emailEnabled:
                this.props.previousNotifications!.emailOnPublish && this.props.previousNotifications!.emailOnPublish !== "none",
            emailNotificationRange: this.props.previousNotifications && this.props.previousNotifications!.emailOnPublish !== "none"
            ? this.props.previousNotifications!.emailOnPublish
            : "none",
            mobileNotificationRange:
                this.props.previousNotifications && this.props.previousNotifications!.mobileOnPublish !== "none"
                    ? this.props.previousNotifications!.mobileOnPublish
                    : "none",
            notificationsEnabled: checked,
            smsEnabled: this.props.previousNotifications!.smsOnPublish !== "none",
            smsNotificationRange: this.props.previousNotifications && this.props.previousNotifications!.smsOnPublish !== "none"
            ? this.props.previousNotifications!.smsOnPublish
            : "none",
            teamsNotificationRange:
                this.props.previousNotifications!.teamsOnPublish !== "none"
                    ? this.props.previousNotifications!.teamsOnPublish
                    : "none",
        });
    }

    /*
    * Checks whether to use previous set emailOnPublish if any, will fall back to the default settings if any else default is subscribers if none are set for tenant settings.
    */
    private checkWhichEmailRangeToUse = () => {
        const { type } = this.props;
        const defaultSettings = this.props.notificationSettings[`default${type.charAt(0).toUpperCase() + type.substr(1)}Settings`];

        if(this.props.previousNotifications && this.props.previousNotifications.emailOnPublish !== 'none')
            return this.props.previousNotifications.emailOnPublish;
        else 
            return defaultSettings.emailOnPublish !== 'none' ? defaultSettings.emailOnPublish : 'subscribers';
    }

    /*
    * Checks whether to use previous set smsOnPublish if any, will fall back to the default settings if any else default is subscribers if none are set for tenant settings.
    */
    private checkWhichSMSRangeToUse = () => {
        const { type } = this.props;
        const defaultSettings = this.props.notificationSettings[`default${type.charAt(0).toUpperCase() + type.substr(1)}Settings`];

        if(this.props.previousNotifications && this.props.previousNotifications.smsOnPublish !== 'none')
            return this.props.previousNotifications.smsOnPublish;
        else
            return defaultSettings.smsOnPublish !== 'none' ? defaultSettings.smsOnPublish : 'subscribers';
    }
}

interface ComponentProps {
  show: boolean;
  isPreviouslyPublished: boolean;
  notifications: INotifications | undefined;
  //optional parameters for now until events authoring gets updated.
  previousNotifications?: INotifications | undefined;
  notificationsToggle?: boolean;
  postPublished?: Date | null | undefined;
  defaultNotificationsState?: INotifications | undefined;
  type: "post" | "event";
  onChangeNotifications: (notifications: INotifications) => void;
  onClose: () => void;
  onToggleNotifications?: (checked: boolean) => void;
}

interface ComponentState {
  emailEnabled: boolean;
  emailNotificationRange: NotificationRange;
  mobileNotificationRange: NotificationRange;
  notificationsEnabled: boolean;
  smsEnabled: boolean;
  smsNotificationRange: NotificationRange;
  teamsNotificationRange: NotificationRange;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    notificationSettings: state.settings.notificationSettings,
    tenantSettings: state.settings.tenantSettings,
  })
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(Notifications);
