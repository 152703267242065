import React from "react";
import { POST_SETTINGS_EDITOR_DRAWER_WIDTH } from "modules/posts/components/post-creation/postCreation";

import { Drawer, DrawerProps, IconButton, useMediaQuery, useTheme } from "@mui/material";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CloseIcon from "@mui/icons-material/Close";

import "./styles/drawerWithBookmarkButton.sass";

interface IDrawerWithBookmarkButtonProps extends DrawerProps {
    showBookmark?: boolean;
    onBookmarkClick: () => void;
}

const DrawerWithBookmarkButton: React.FunctionComponent<IDrawerWithBookmarkButtonProps> = ({
    children,
    showBookmark,
    onBookmarkClick,
    ...drawerProps
}) => {
    const theme = useTheme();
    const isSmallAndSmaller = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });
    const { open } = drawerProps;

    return <>
        {showBookmark && ((drawerProps.open && !isSmallAndSmaller) || !drawerProps.open) && <IconButton
            id="drawer-bookmark-button"
            style={{
                right: open
                    ? POST_SETTINGS_EDITOR_DRAWER_WIDTH - 1
                    : 0
            }}
            className={drawerProps.open
                ? "drawer-bookmark-open"
                : "drawer-bookmark-closed"}
            onClick={onBookmarkClick}
            size="large">
            {open
                ? <KeyboardArrowRightIcon />
                : <KeyboardArrowLeftIcon />}

        </IconButton>}
        <Drawer
            anchor="right"
            variant="persistent"
            SlideProps={{
                timeout: 500 // match drawer-bookmark-open and drawer-bookmark-closed transition in drawerWithBookMarkButton.sass
            }}
            {...drawerProps}
        >
            <>
                {isSmallAndSmaller &&
                    <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                        <IconButton onClick={onBookmarkClick} size="large">
                            <CloseIcon />
                        </IconButton>
                    </div>}
                {children}
            </>
        </Drawer>
    </>;
}

export default DrawerWithBookmarkButton;
