import * as React from "react";
import LoadingSpinner from '../loadingSpinner';
import { FileDownloader } from "utils/fileDownloader";

interface IProps {
  styles?: React.CSSProperties
  onClick: () => any
  text: string
  /** Download type. Default is 'text/csv;charset=utf-8;' */
  type?: string
  downloadName: string
  disabled?: boolean
  usingNewReports?: boolean;
  waitingOnRequest?: boolean;
}

interface IState {
  isLoading: boolean
}

export default class DownloadButton extends React.Component<IProps, IState> {
  
  private defaultStyles: React.CSSProperties = {
    backgroundColor: '#2f4050',
    color: "#ffffff",
    border: 'none', 
    outline: 'none',
    padding: '8px',
    cursor: "pointer",
    display: "inline-block",
    fontSize: "15px",
    borderRadius: '3px'
  }

  private element: any;

  constructor(props){
    super(props);
    this.state = {
      isLoading: false
    };
  }
  
  componentDidMount(){
    this.defaultStyles = {
      ...this.defaultStyles,
      minWidth: `${this.element.offsetWidth}px`,
      minHeight: `${this.element.offsetHeight}px`
    }
  }

  public render(){
    let styles = {...this.defaultStyles, ...this.props.styles};
    return (
      <button ref={(ref) => this.element = ref} 
          disabled={this.state.isLoading || (this.props.disabled ?? false)} 
          style={styles} 
          onClick={this.onClick}
         >
        {
          this.state.isLoading ? 
            <LoadingSpinner 
              size={`${styles.fontSize}`}
              style={{
                height:`${styles.fontSize}`
              }}
            /> : 
            <span>
              <i className="material-icons" style={{verticalAlign: "middle", fontSize: styles.fontSize}}>save_alt</i> 
              {this.props.text}
            </span>
        }
      </button>)
  }

  private onClick = (e) => {
    e.stopPropagation();

      this.setState({ isLoading: true }, () => {
          if (this.props.usingNewReports && this.props.waitingOnRequest !== undefined) {
              this.props.onClick();
              this.setState({ isLoading: this.props.waitingOnRequest });
          } else {
              const file = {
                  name: `${this.props.downloadName}-${new Date().toISOString()}.csv`,
                  type: this.props.type,
              };
              this.props.onClick()
                  .then((data) => new FileDownloader(file).downloadBlob(data))
                  .then((_) => this.setState({ isLoading: false }))
                  .catch((_) => this.setState({ isLoading: false }));
          }
      });
  }
}