import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { GlobalApplicationState } from 'globalApplicationState';
import * as actions from 'modules/posts/actionCreator';

import { ReactionStat } from "../../../models";


class Reaction extends React.Component<PropsWithRedux, ComponentState> {
  constructor(props: PropsWithRedux) {
    super(props);

    this.state = {
      puffOut: false
    };
  }

  public render() {
    const { reaction, preview } = this.props;
    const { puffOut } = this.state;

    return (
      <div

        onClick={this.onSetReaction}
        onAnimationEnd={this.onSetReactionEnd}
        className={`${preview ? "preview" : ""} reaction ${reaction.selected ? "selected" : ""} ${puffOut ? "puff-out" : ""}`}
      >
        <span className="name">{reaction.name}</span>
        <img src={`/images/reactions/${reaction.sentiment}_reaction.png`} alt={reaction.sentiment} />
        <span className="count">{reaction.count}</span>
      </div>
    );
  }

  private onSetReaction = (): void => {
    const { preview = false} = this.props;
    if (preview) return;

    if (!this.state.puffOut) {
      this.setState({ puffOut: true });
      this.props.setReaction(this.props.reaction.postId, !this.props.reaction.selected ? this.props.reaction.id : null);
    }
  }

  private onSetReactionEnd = (): void => {
    this.setState({ puffOut: false });
  }
}


interface ComponentProps {
    preview?: boolean;
    reaction: ReactionStat;
}

interface ComponentState {
  puffOut: boolean;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
  }),
  {
    setReaction: actions.setReaction
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;
export default connector(Reaction);
