import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { push } from "react-router-redux";
import * as actions from "modules/portalPages/actionCreator";
import { GlobalApplicationState } from "globalApplicationState";

import { PortalPage } from "modules/portalPages/models";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import CloseIcon from "@mui/icons-material/Close";


class PublishChangesDialog extends React.Component<PropsWithRedux, ComponentState> {
  public render() {
    return (
      <Dialog open={this.props.showPublishChanges} maxWidth={false} onClose={this.onClose}>
        <DialogTitle className="portal-pages-dialog-header">
          <div className="portal-pages-dialog-header-title">
            <Typography variant="h2">Publish changes</Typography>
            <IconButton onClick={this.onClose} size="large">
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent className="portal-page-dialog-content">
          <div>
            <span>Recent changes in page details were made. These changes will not be applied until the page is published. Would you like to publish now?</span>
            <br /><br />
            <span>Any changes made in the page editor will also be saved and published.</span>
          </div>
        </DialogContent>
        <DialogActions className="portal-pages-dialog-footer">
          <Button variant="text" color="primary" onClick={this.onClose}>Keep editing</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={this.onPublish}
          >
            Publish
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  private onPublish = () => {
    this.onClose();
    this.props.saveDraft(this.props.page.draftId, this.props.data).then((succeeded) => {
      if (succeeded) {
        this.props.clearChangedSinceSaved();
        this.props.publishDraft(this.props.page.draftId).then((succeeded) => {
          if (succeeded)
            this.props.redirectTo("/" + this.props.tenantId + "/admin/portalPages");
        });
      }
    });
  }

  private onClose = () => {
    this.props.hidePublishChanges();
  }
}


interface ComponentProps {
  data: string;
  page: PortalPage;
}

interface ComponentState {}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    showPublishChanges: state.portalPages.showPublishChanges,
    tenantId: state.tenant.id
  }),
  {
    clearChangedSinceSaved: actions.clearChangedSinceSaved,
    hidePublishChanges: actions.hidePublishChanges,
    redirectTo: push,
    publishDraft: actions.publishDraft,
    saveDraft: actions.saveDraft
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(PublishChangesDialog);