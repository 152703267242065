import * as React from "react";
import LoadingCircle from "modules/common/components/loadingCircle";

export interface LoadingProps {
    size?: number;
    padding?: number;
    style?: React.CSSProperties;
    containerStyle?: React.CSSProperties;
}

class Loading extends React.Component<LoadingProps, {}> {
    public render() {
        let size = this.props.size || 40;

        return (
            <div className="outer-container" style={{ ...this.props.containerStyle }}>
                <div style={{ "margin": "0 auto", "width": size + "px", padding: this.props.padding }} className="loading-spinner">
                    <LoadingCircle style={this.props.style ? this.props.style : undefined} size={size} />
                </div>
            </div>
        );
    }
}

export default Loading;
