import * as React from "react";

import Collapse from "@mui/material/Collapse";
import Switch from "@mui/material/Switch";
import moment from "moment";

import DateAndTime from "modules/common/components/forms/inputs/dateAndTime";
import HoverText from "modules/documents/components/action-buttons/hoverText";

class BreakingTime extends React.Component<ComponentProps, ComponentState> {
    constructor(props: ComponentProps) {
        super(props);

        this.state = {
            showBreakingTime: this.props.breakingTime ? true : false
        };
    }

    public render() {
        const { breakingTime = "", publishTime = "", expiryTime } = this.props;

        const isBackdated: boolean = moment(publishTime).isBefore(new Date());
        const minBreakingTime = isBackdated ? moment(publishTime) : moment(publishTime || new Date());
        const maxBreakingTime = expiryTime ? moment(expiryTime) : undefined;

        return (
            <>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}>
                    <div>
                        <span>Set as </span>
                        <HoverText inline label="Breaking News">
                            Displays the Breaking News banner on the post and pins the post on certain web parts until the banner expires.
                        </HoverText>
                    </div>
                    <Switch
                        color="primary"
                        checked={this.state.showBreakingTime}
                        onChange={this.onToggleBreakingTime}
                    />
                </div>
                <div className="collapse-container">
                    <Collapse in={this.state.showBreakingTime}>
                        <DateAndTime
                            minDate={minBreakingTime}
                            maxDate={maxBreakingTime}
                            date={breakingTime}
                            onChangeDate={this.props.onChange}
                            label="Until"
                        />
                    </Collapse>
                </div>
            </>
        );
    }

    private onToggleBreakingTime = () => {
        if (!this.state.showBreakingTime)
            this.props.onChange(moment().add(72, "hours").toISOString());
        else
            this.props.onChange(null);

        this.setState({ showBreakingTime: !this.state.showBreakingTime });
    }
}


interface ComponentProps {
    breakingTime: string | undefined;
    publishTime: string | undefined;
    expiryTime: string | undefined;
    onChange: (breakingTime: string | null | undefined) => void;
}

interface ComponentState {
    showBreakingTime: boolean;
}

export default BreakingTime;
