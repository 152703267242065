import React from "react";

import { AudienceInvite } from "../../../models";

import IconButton from "@mui/material/IconButton";

import ClearIcon from "@mui/icons-material/Clear";


const InviteList: React.FunctionComponent<ComponentProps> = props => {
  if (!props.includedInvites)
    return <div className="no-search-results">No unique invite has been added</div>;

  return (
    <div className="audience-user-list">
      {props.includedInvites.map((user) => (
        <div key={user.inviteId}>
          <div className="user-suggestion">
            <div>
              <div className="user-name">{user.email}</div>
            </div>
          </div>
          <div>
            <IconButton size="small" onClick={() => props.onRemoveUser(user.inviteId)} className="remove-icon"><ClearIcon /></IconButton>
          </div>
        </div>
      ))}
    </div>
  );
}

interface ComponentProps {
  includedInvites: AudienceInvite[];
  onRemoveUser: (id: string) => void;
}

export default InviteList;