import * as React from "react";
import UploadIcon from "./uploadIconSvg";
import { injectIntl, IntlShape } from "react-intl";
import { errorAlert } from "utils/notyPopups";
import { SupportItemTypes } from "modules/support";
import "./uploader.sass";

interface componentProps {
    message?: string | JSX.Element;
    upload: (file: File, onProgress, onLoad, onError, onAbort) => Promise<any>;
    uploading: boolean;
    maxUploadable: number;
    setUploading: (uploading: boolean) => void;
    completed?: (imageId: string) => void;
    imageSelected?: (image: { id: string; url: string }) => void;
    intl: IntlShape;
    onAllComplete?: () => void;
    documentType?: string;
    uploadDocuments?: any;
    onDocumentComplete?: (docId: string, fileName: string, checkSum?: string, file?: File) => void;
    attachmentType: "files" | "media";
    onSupportDocumentComplete?: (docId: string, fileName: string) => void
    acceptedFiles?: string;
    uploaderMessage?: string;
}

export interface UploadStatus {
    fileProgress: Record<string, number>;
    error: string | null;
    aborted: boolean;
}

interface componentState {
    errors: string[];
    dragging: boolean;
    uploadStatus: UploadStatus;
    totalToUpload: number;
    totalUploaded: number;
}

class Uploader extends React.Component<componentProps, componentState> {
    private maxUploads = 20;

    private errorDisplayTimeout: any;

    constructor(props) {
        super(props);

        this.state = {
            errors: [],
            dragging: false,
            totalToUpload: 0,
            totalUploaded: 0,
            uploadStatus: {
                aborted: false,
                error: null,
                fileProgress: {},
            },
        };
    }

    public render() {
        let status = this.state.uploadStatus;

        if (status.aborted) return this.renderUploadingAborted();
        else if (status.error) return this.renderUploadingError();
        else if (this.props.uploading) return this.renderUploadingProgress();
        else return this.renderUploadForm();
    }

    private renderUploadForm() {
        const { maxUploadable } = this.props;

        let draggingProps = {
            onDrag: this.dragging(true),
            onDragStart: this.dragging(true),
            onDragEnd: this.dragging(false),
            onDragOver: this.dragging(true),
            onDragEnter: this.dragging(true),
            onDragLeave: this.dragging(false),
            onDrop: this.onFileDropped,
        };

        return (
            <div className={"uploader" + (this.state.dragging ? " is-dragging" : "")} {...draggingProps}>
                <div className="centered-content">
                    <UploadIcon color="#ccc" width="75px" height="75px" />
                    <div>
                        <input
                            className="original-input"
                            id="choose-file"
                            multiple
                            name="choose-file"
                            type="file"
                            accept={this.props.acceptedFiles ?? this.acceptedFiles()}
                            disabled={!Math.min(this.maxUploads, isNaN(maxUploadable) ? Infinity : maxUploadable)}
                            onChange={this.onFileInputChanged}
                        />
                        <div className="large">
                            {!!this.props.message
                                ? <label className="choose-file-button" htmlFor="choose-file">
                                    {this.props.message}
                                  </label>
                                : <React.Fragment>
                                    <label className="choose-file-button" htmlFor="choose-file">
                                        {this.props.uploaderMessage || `Choose or drag ${this.props.acceptedFiles === "application/pdf" ? "PDF" : "PNG or JPEG"} files here (up to ${this.maxUploads})`}
                                    </label>
                                  </React.Fragment>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private renderUploadingProgress() {
        return (
            <div className="uploader">
                <div className="centered-content">
                    <div>
                        <p className="large">{this.props.intl.formatMessage({ id: "posts.uploading", defaultMessage: "Uploading" })}...</p>
                        {Object.keys(this.state.uploadStatus.fileProgress).map((k) => {
                            return (
                                <div className="progress-bar-wrapper" key={`file-progress-${k}`}>
                                    <label>{k}</label>
                                    <div className="progress">
                                        <div className="bar" style={{ width: `${this.state.uploadStatus.fileProgress[k]}%` }}></div>
                                    </div>
                                </div>
                            );
                        })}
                        <p>
                            {this.state.totalUploaded}/{this.state.totalToUpload} Uploaded
                        </p>
                    </div>
                </div>
            </div>
        );
    }

    private renderUploadingError() {
        return (
            <div className="uploader">
                <div className="centered-content">
                    <div>
                        <p className="large">{this.props.intl.formatMessage({ id: "posts.failedUpload" })}</p>
                        <p>
                            <button onClick={this.resetUploadStatus}>{this.props.intl.formatMessage({ id: "common.tryAgain" })}</button>
                        </p>
                    </div>
                </div>
            </div>
        );
    }

    private renderUploadingAborted() {
        return (
            <div className="uploader">
                <div className="centered-content">
                    <div>
                        <p className="large">{this.props.intl.formatMessage({ id: "posts.uploadCancelled" })}</p>
                        <p>
                            <button className="btn" onClick={this.resetUploadStatus}>
                                {this.props.intl.formatMessage({ id: "posts.uploadAgain" })}
                            </button>
                        </p>
                    </div>
                </div>
            </div>
        );
    }

    private dragging = (isDragging: boolean, callback?: () => void) => (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (typeof callback !== "function") callback = () => {};

        this.setState(
            {
                ...this.state,
                dragging: isDragging,
            },
            callback
        );
        return false;
    };

    private onFileDropped = (e: React.DragEvent<HTMLDivElement>) => {
        const files = e.dataTransfer.files;
        this.dragging(false, () => {
            this.uploadFiles(files);
        })(e);
    };

    private onFileInputChanged = (e: React.FormEvent<HTMLInputElement>) => {
        const files = (e.target as HTMLInputElement).files;
        this.dragging(false, () => {
            this.uploadFiles(files!);
        })(e);
    };

    private uploadFiles = async (files: FileList) => {
        if (files.length < 1) {
            this.SetError(this.props.intl.formatMessage({ id: "posts.noFilesFound" }));
        } else if (files.length > this.maxUploads || files.length > this.props.maxUploadable) {
            let max = Math.min(this.maxUploads, this.props.maxUploadable || this.maxUploads);
            this.SetError(`No more than ${max} file(s) can be uploaded`);
        } else {
            this.props.setUploading(true);
            this.setState(
                (prev) => ({
                    ...prev,
                    totalToUpload: files.length,
                    totalUploaded: 0,
                    uploadStatus: {
                        ...prev.uploadStatus,
                        fileProgress: Array.from(files).reduce((acc, val) => ({ [val.name]: 0 }), {}),
                    },
                }),
                () => {
                    let promises = [] as Promise<any>[];
                    this.resetUploadStatus();
                    for (let i = 0; i < files.length; i++) {
                        let file = files[i];

                        /* https://stackoverflow.com/a/12900504 for the black magic below */
                        let ext = file.name.slice((file.name.lastIndexOf(".") - 1 >>> 0) + 2) || "No file extension found";

                        if (filenameContainsInvalidCharacters(file.name)) {
                            this.SetError(`File name must not contain any special characters. Please reupload without the following characters:  # / ? % * : | " < >`)
                            break;
                        }

                        if (this.props.documentType === SupportItemTypes.faq ||
                            this.props.documentType === SupportItemTypes.knownIssue ||
                            this.props.documentType === SupportItemTypes.releaseNote ||
                            this.props.documentType === SupportItemTypes.releasePackage ||
                            this.props.documentType === SupportItemTypes.training) {

                            if (!fileUnderAllowedFileSize(file, 20)) {
                                this.SetError(this.props.intl.formatMessage({id: "posts.documentSizeLimit"}, {size: '20MB'}));
                                break;
                            }

                            if (isDocumentFileType(file)) {
                                promises.push(this.uploadDocumentViaSupport(file));
                            } else {
                                this.SetError(
                                    this.props.intl.formatMessage(
                                        { id: "unrecognizedFileType", defaultMessage: "File type not recognized: {fileType}" },
                                        { fileType: ext }
                                    )
                                );
                            }
                        }
                        /* Deals with all other cases, such as posts */
                        else {
                            /* Media specific widget in posts */
                            if (this.props.attachmentType === "media") {
                                if (!fileUnderAllowedFileSize(file, 6)) {
                                    this.SetError(this.props.intl.formatMessage({ id: "posts.imageSizeLimit" }, { size: "6MB" }));
                                    break;
                                }
                                if (isImageType(file)) {
                                    promises.push(this.uploadImageViaPost(file)!);
                                }
                                else {
                                    this.SetError(
                                        this.props.intl.formatMessage(
                                            { id: "unrecognizedFileType", defaultMessage: "File type not recognized: {fileType}" },
                                            { fileType: ext }
                                        )
                                    );
                                }
                            /* Document specific widget in posts */
                            } else if (this.props.attachmentType === "files") {
                                if (!fileUnderAllowedFileSize(file, 20)) {
                                    this.SetError(this.props.intl.formatMessage({ id: "posts.documentSizeLimit" }, { size: "20MB" }));
                                    break;
                                }

                                if (isDocumentFileType(file)) {
                                    promises.push(this.uploadDocumentViaPost(file));
                                } else {
                                    this.SetError(
                                        this.props.intl.formatMessage(
                                            { id: "unrecognizedFileType", defaultMessage: "File type not recognized: {fileType}" },
                                            { fileType: ext }
                                        )
                                    );
                                }
                            }
                        }
                    }

                    Promise.all(promises)
                        .then((results) => this.completedAll())
                        .catch((error) => this.completedAll());
                }
            );
        }

        return false;
    };

    private uploadDocumentViaPost(file: File) {
        return this.props
            .upload(
                file,
                (p) => this.onUploadProgress(p, file.name),
                this.onUploadComplete,
                this.onUploadError,
                this.onUploadAborted
            )
            .then((response) => {
                if (!response) {
                    this.setState((prev) => ({ ...prev, totalToUpload: prev.totalToUpload - 1 }));
                } else {
                    this.setState((prev) => ({ ...prev, totalUploaded: prev.totalUploaded + 1 }));
                    if (this.props.onDocumentComplete)
                        this.props.onDocumentComplete(response.id, response.fileName);
                }
            }).catch(err => {
                this.SetError(
                    this.props.intl.formatMessage(
                        { defaultMessage: "Failed to upload file '{file}'"},
                        { file: file.name }
                    )
                );
            });
    }

    private uploadDocumentViaSupport(file: File) {
        return this.props
            .upload(
                file,
                (p) => this.onUploadProgress(p, file.name),
                this.onUploadComplete,
                this.onUploadError,
                this.onUploadAborted)
                .then(response => {
                    if(!response){
                        this.setState(prev => ({...prev, totalToUpload: prev.totalToUpload - 1}));
                    }else{
                        this.setState(prev => ({...prev, totalUploaded: prev.totalUploaded + 1}));
                        if (this.props.onSupportDocumentComplete)
                            this.props.onSupportDocumentComplete(response.id, response.fileName);
                    }
                }).catch(err => {
                    this.SetError(
                        this.props.intl.formatMessage(
                            { defaultMessage: "Failed to upload file '{file}'"},
                            { file: file.name }
                        )
                    );
                });
    }

    private uploadImageViaPost(file: File) {
        return this.props
            .upload(
                file,
                (p) => this.onUploadProgress(p, file.name),
                this.onUploadComplete,
                this.onUploadError,
                this.onUploadAborted
            )
            .then((response) => {
                if (!response) {
                    this.setState((prev) => ({ ...prev, totalToUpload: prev.totalToUpload - 1 }));
                } else {
                    this.setState((prev) => ({ ...prev, totalUploaded: prev.totalUploaded + 1 }));
                    if (this.props.completed)
                        this.props.completed(response.id);
                }
            }).catch(err => {
                this.SetError(
                    this.props.intl.formatMessage(
                        { defaultMessage: "Failed to upload file '{file}'"},
                        { file: file.name }
                    )
                );
            });
    }

    private SetError(error: string) {
        errorAlert(error, 5000);
    }

    private completedAll = () => {
        this.props.setUploading(false);
        this.props.onAllComplete && this.props.onAllComplete();
    };

    private resetUploadStatus = () => {
        this.setState({
            ...this.state,
            uploadStatus: {
                ...this.state.uploadStatus,
                aborted: false,
                error: null,
            },
        });
    };

    private onUploadProgress = (percentCompleted: number, fileName: string) => {
        this.setState((prev) => ({
            ...prev,
            uploadStatus: {
                ...prev.uploadStatus,
                fileProgress: {
                    ...prev.uploadStatus.fileProgress,
                    [fileName]: percentCompleted,
                },
            },
        }));
    };

    private onUploadComplete = (e) => {};

    private onUploadError = (e) => {
        this.setState((prev) => ({
            ...prev,
            uploadStatus: {
                ...prev.uploadStatus,
                error: e.responseText,
            },
        }));
    };

    private onUploadAborted = (e) => {
        this.setState((prev) => ({
            ...prev,
            uploadStatus: {
                ...prev.uploadStatus,
                aborted: true,
            },
        }));
    };

    private acceptedFiles = () => {
        return "image/jpg,image/jpeg,image/png,image/bmp,text/plain,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword,application/pdf,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/zip,application/zip,application/x-zip-compressed";
    };
}

export const invalidCharactersArray = ["#", "\\", "?", ":", "|", "\"", "&", ";", "@", "+", "="];

// this array will match a string that contains any invalid filename characters in invalidCharactersArray
export const invalidCharactersRegex = new RegExp(`[${invalidCharactersArray.join()}]`);

export const filenameContainsInvalidCharacters = (filename: string) => {
    return invalidCharactersRegex.test(filename)
}

export const isDocumentFileType = (file: File) => {
    return /^application\/(vnd.openxmlformats-officedocument.wordprocessingml.document|vnd.openxmlformats-officedocument.presentationml.presentation|vnd.openxmlformats-officedocument.spreadsheetml.sheet|pdf)/.test(file.type) ||
           /^image\/(jpg|jpeg|png)/.test(file.type) ||
           /^text\/plain/.test(file.type);
};

export const isImageType = (file: File) => {
    return /^image\/(jpg|jpeg|png)/.test(file.type);
};

export const isVideoType = (file: File) => {
    return /^video\//.test(file.type);
};

export const fileUnderAllowedFileSize = (file: File, mbSize: number = 6) => {
    return file.size < 1024 * mbSize * 1024;
}

export default injectIntl(Uploader);
