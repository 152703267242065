import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import * as actions from "../../actionCreator";
import { GlobalApplicationState } from "globalApplicationState";
import { push } from "react-router-redux";
import { Answers, Attendee, AttendanceFilterValues, AttendanceListingPage, AttendanceType } from "../../models";
import moment from "moment";
import Loading from "modules/common/components/loading";
import LoadingOverlay from "modules/common/components/loadingOverlay";

import ChangeRSVPStatusDialog from "../dialogs/changeRSVPStatus";
import EditResponseDialog from "../dialogs/editResponse";
import AttendanceFilters from "../event-filters/attendanceFilters";

import TabContent from "pages/common/tabContent";
import TabContentToolbar from "pages/common/tabContentToolbar";

import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import UndoIcon from "@mui/icons-material/Undo";


interface HeaderCell {
  id: string;
  label: string;
}


class AttendanceList extends React.Component<PropsWithRedux, ComponentState> {
  constructor(props: PropsWithRedux) {
    super(props);

    this.state = {
      filters: { ...this.getDefaultFilterValues() },
      hasFiltersApplied: false,
      isPagedLoad: false,
      selectedItems: [],
      showChangeRSVPStatusDialog: false,
      showEditResponseDialog: false
    };
  }

  public componentDidMount() {
    moment.locale("en");
  }

  public render() {
    const { page } = this.props;
    const { selectedItems } = this.state;

    if (!this.props.show)
      return <React.Fragment></React.Fragment>;

    if (page.isFetching && !this.state.isPagedLoad)
      return <Loading />;
    
    if (page.hasError)
      return <div>Could not load participants.</div>

    return (
      <div>
        {this.getToolbar()}
        <TabContent>
          {this.getFilter()}
          {this.getList()}
          <ChangeRSVPStatusDialog show={this.state.showChangeRSVPStatusDialog} attendees={selectedItems} onApply={this.onChangeRSVPStatus} onClose={this.onHideChangeRSVPDialog} />
          <EditResponseDialog show={this.state.showEditResponseDialog} attendee={!!selectedItems.length ? selectedItems[0] : undefined} eventStartTime={page.eventStartTime} questions={page.questions} title={page.title} onApply={this.onEditResponse} onClose={this.onHideEditResponseDialog} />
          <LoadingOverlay absolute={true} show={this.props.isSaving} />
        </TabContent>
      </div>
    );
  }


  private formatAttendanceType = (attendanceType: AttendanceType): string => {
    if (attendanceType === "InPerson")
      return "In-Person";
    else if (attendanceType === "NotAttending")
      return "Not Attending";
    return attendanceType;
  }

  private formatTime = (time?: string): string => {
    if (time) {
      return moment(new Date(time)).format("MMM D, YYYY");
    }
    return "-";
  }


  private getDefaultFilterValues = (): Partial<AttendanceFilterValues> => {
    const filters: Partial<AttendanceFilterValues> = {
      email: "",
      name: ""
    };
    return filters;
  }

  private getFilter = (): JSX.Element => {
    return (
      <AttendanceFilters
        filters={this.state.filters}
        onApplyFilters={this.onApplyFilters}
        onChangeFilters={this.onChangeFilters}
        onClearFilters={this.onClearFilters}
      />
    );
  }

  private getHeader = (): HeaderCell[] => {
    let header: HeaderCell[] = [
      { id: "name", label: "Name" },
      { id: "email", label: "E-mail" },
      { id: "attendanceType", label: "RSVP Status" },
      { id: "rsvpTime", label: "RSVP Time" }
    ];

    return header;
  }

  private getList = (): JSX.Element => {
    const { page } = this.props;
    const { selectedItems } = this.state;

    const rowCount = page.attendees.length || 10;

    if (page.isFetching)
      return <Loading />;

    if (!page.attendees.length)
    return <div>No participants were found.</div>;

    return (
      <React.Fragment>
        {this.state.hasFiltersApplied &&
          <Typography variant="h2" className="filter-results">Results</Typography>
        }
        <TableContainer>
          <Table size="medium">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    indeterminate={selectedItems.length > 0 && selectedItems.length < rowCount}
                    checked={rowCount > 0 && selectedItems.length === rowCount}
                    onChange={this.onSelectAllAttendees}
                    inputProps={{ "aria-label": "select all attendees" }}
                  />
                </TableCell>
                {this.getHeader().map((headerCell) => (
                  <TableCell
                    key={headerCell.id}
                    align={headerCell.id === "name" ? "left" : "center"}
                    padding={headerCell.id === "name" ? "none" : "normal"}
                  >
                    {headerCell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {page.attendees
                .map((attendee, index) => {
                  const isAttendeeSelected = this.isAttendeeSelected(attendee);
                  const labelId = `event-list-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(ev) => this.onSelectAttendee(ev, attendee)}
                      role="checkbox"
                      aria-checked={isAttendeeSelected}
                      tabIndex={-1}
                      key={attendee.id}
                      selected={isAttendeeSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isAttendeeSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        {attendee.name}
                      </TableCell>
                      <TableCell align="center">{attendee.email}</TableCell>
                      <TableCell align="center">{this.formatAttendanceType(attendee.attendanceType)}</TableCell>
                      <TableCell align="center">{this.formatTime(attendee.rsvpTime)}</TableCell>
                    </TableRow>
                  );
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
        {page.currentPage > 0 && page.totalPages > 1 &&
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            backIconButtonProps={{ color: "primary" }}
            nextIconButtonProps={{ color: "primary" }}
            count={page.totalAttendees}
            rowsPerPage={10}
            page={page.currentPage - 1}
            onPageChange={this.onChangePage}
          />
        }
      </React.Fragment>
    );
  }

  private getToolbar = (): JSX.Element => {
    const { selectedItems } = this.state;

    if (!selectedItems.length)
      return <React.Fragment></React.Fragment>;
    
    return (
      <TabContentToolbar>
        <div>
          {this.getToolbarIcon(`${selectedItems.length} selected`, <ClearIcon />, this.onClearAllAttendees)}
        </div>
        <div>
          {selectedItems.length === 1 &&
            this.getToolbarIcon("Edit Response", <EditIcon />, this.onShowEditResponse)
          }
          {selectedItems.length > 1 &&
            this.getToolbarIcon("Change RSVP Status", <UndoIcon />, this.onShowChangeRSVPStatusDialog)
          }
        </div>
      </TabContentToolbar>
    );
  }

  private getToolbarIcon = (title: string, icon: React.ReactNode, onClick: () => void) => {
    return (
      <Button aria-label={title.toLowerCase()} color="primary" startIcon={icon} onClick={onClick}>
        {title}
      </Button>
    );
  }


  private isAttendeeSelected = (attendee: Attendee): boolean => {
    return this.state.selectedItems.findIndex((selectedItem) => selectedItem.id === attendee.id) !== -1;
  }


  private onApplyFilters = () => {
    this.setState({ hasFiltersApplied: true, isPagedLoad: true });
    this.props.fetchPage(1, this.state.filters);
  }

  private onChangeFilters = (filters: Partial<AttendanceFilterValues>) => {
    this.setState({ filters: { ...this.state.filters, ...filters }});
  }

  private onClearFilters = () => {
    this.setState({ filters: { ...this.getDefaultFilterValues() }, hasFiltersApplied: false, isPagedLoad: true });
    this.props.fetchPage(1, {});
  }


  private onClearAllAttendees = () => {
    this.setState({ selectedItems: [] });
  }


  private onChangeRSVPStatus = (attendanceType: AttendanceType) => {
    this.onHideChangeRSVPDialog();
    this.props.changeRSVPStatus(this.props.eventId, this.state.selectedItems.map(selectedItem => selectedItem.id), attendanceType).then((response) => {
      if (response) {
        this.onClearAllAttendees();
      }
    });
  }

  private onEditResponse = (attendanceType: AttendanceType, answers: Answers) => {
    this.onHideEditResponseDialog();
    this.props.editResponse(this.props.eventId, this.state.selectedItems[0].id, attendanceType, answers).then((response) => {
      if (response) {
        this.onClearAllAttendees();
      }
    });
  }


  private onHideChangeRSVPDialog = () => {
    this.setState({ showChangeRSVPStatusDialog: false });
  }

  private onHideEditResponseDialog = () => {
    this.setState({ showEditResponseDialog: false });
  }


  private onSelectAllAttendees = () => {
    if (this.state.selectedItems.length === this.props.page.attendees.length)
      this.setState({ selectedItems: [] });
    else
      this.setState({ selectedItems: this.props.page.attendees });
  }

  private onSelectAttendee = (ev, attendee: Attendee) => {
    let selectedItems = this.state.selectedItems.slice();

    if (selectedItems.findIndex((selectedItem) => selectedItem.id === attendee.id) === -1)
      selectedItems = selectedItems.concat([attendee]);
    else
      selectedItems = selectedItems.filter(selectedItem => selectedItem.id !== attendee.id);

    this.setState({ selectedItems });
  }


  private onShowChangeRSVPStatusDialog = () => {
    this.setState({ showChangeRSVPStatusDialog: true });
  }

  private onShowEditResponse = () => {
    this.setState({ showEditResponseDialog: true });
  }


  private onChangePage = (ev, page: number) => {
    this.setState({ isPagedLoad: true });
    this.props.fetchPage(page + 1, this.state.filters);
  }
}
  

interface ComponentProps {
  show: boolean;
  eventId: string;
  page: AttendanceListingPage;
  fetchPage: (pageNumber: number, filters: Partial<AttendanceFilterValues>) => void;
}

interface ComponentState {
  filters: Partial<AttendanceFilterValues>;
  hasFiltersApplied: boolean;
  isPagedLoad: boolean;
  selectedItems: Attendee[];
  showChangeRSVPStatusDialog: boolean;
  showEditResponseDialog: boolean;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    isSaving: state.events.isSaving,
    tenant: state.tenant.id
  }),
  {
    changeRSVPStatus: actions.changeRSVPStatus,
    editResponse: actions.editResponse,
    redirectTo: push
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(AttendanceList);