import { useState } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import Cookies from "js-cookie";

import MsalAuthModule from "authentication/msalAuthModule";


/**
 * Sign out logic
 * @returns array where:
 * - first element is function to begin sign out process
 * - second element is boolean indicating singing out is in process
*/

export type SignOut = {
  signOut: () => Promise<void>;
  isSigningOut: boolean;
}

const useSignOut = (): SignOut => {
  const isAuthenticated = useIsAuthenticated();
  const { inProgress } = useMsal();
  const [isSigningOut, setIsSigningOut] = useState<boolean>(false);

  /**
   * Sign user out with msal
   */
  const signOut = async () => {
    // msal instance set up to redirect for us on logout
    if (isAuthenticated && inProgress === InteractionStatus.None) {
      setIsSigningOut(true);
      Cookies.remove("tenantId");
      Cookies.remove("multitenancy");
      await MsalAuthModule.getInstance().logout(true);
    }
  };

  return {
    signOut, isSigningOut
  }
};

export default useSignOut;
