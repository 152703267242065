import { Action } from "redux";
import { CompleteAction, ActionCreator } from "typedActions";

import { CurrentUserSettings } from "modules/settings/models";


export const INITIALIZE_USER_SETTINGS = "INITIALIZE_USER_SETTINGS";
export interface InitializeUserSettings extends Action { userSettings: CurrentUserSettings }
export const InitializeUserSettings = ActionCreator<InitializeUserSettings>(INITIALIZE_USER_SETTINGS);

export const UPDATE_USER_SETTINGS = "UPDATE_USER_SETTINGS";
export interface UpdateUserSettings extends Action { userSettings: Partial<CurrentUserSettings> }
export const UpdateUserSettings = ActionCreator<UpdateUserSettings>(UPDATE_USER_SETTINGS);

export const SAVE_USER_SETTINGS = "SAVE_USER_SETTINGS";
export interface SaveUserSettings extends Action {}
export const SaveUserSettings = ActionCreator<SaveUserSettings>(SAVE_USER_SETTINGS);

export const SAVE_USER_SETTINGS_COMPLETE = "SAVE_USER_SETTINGS_COMPLETE";
export interface SaveUserSettingsComplete extends CompleteAction {}
export const SaveUserSettingsComplete = ActionCreator<SaveUserSettingsComplete>(SAVE_USER_SETTINGS_COMPLETE);

export const SEND_SMS_VERIFY = "SEND_SMS_VERIFY"
export interface SendSMSVerify extends Action {}
export const SendSMSVerify = ActionCreator<SendSMSVerify>(SEND_SMS_VERIFY);

export const SEND_SMS_VERIFY_COMPLETE = "SEND_SMS_VERIFY_COMPLETE"
export interface SendSMSVerifyComplete extends Action {}
export const SendSMSVerifyComplete = ActionCreator<SendSMSVerifyComplete>(SEND_SMS_VERIFY_COMPLETE);

export const CLEAR_ERROR_MESSAGE = "CLEAR_ERROR_MESSAGE";
export interface ClearErrorMessage extends Action {}
export const ClearErrorMessage = ActionCreator<ClearErrorMessage>(CLEAR_ERROR_MESSAGE);