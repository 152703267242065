import * as React from "react";

import InfoHover from "modules/common/components/hovers/infoHover";


const TotalUsers: React.FunctionComponent<ComponentProps> = props => {
  if (!props.id)
    return <React.Fragment></React.Fragment>;

  return (
    <div>
      <div>
        <span>Total users</span>
        <InfoHover>Includes all existing accounts added from your Sparrow Directory, {props.syncType} Groups and the invites sent while importing emails.</InfoHover>
      </div>
      <div>{props.totalUsers}</div>
    </div>
  );
}


interface ComponentProps {
  id?: string;
  totalUsers: number;
  syncType: string;
}

export default TotalUsers;