import * as React from 'react';
import * as dom from 'react-dom';
import AnimatedDragPrompt from './animatedDragPrompt';
import shallowEquals from 'utils/shallowEquals';
import Croppie from 'croppie';

// the actual croppie module needs to be 'required' due to dependencies on window object
import 'croppie/croppie.css';

import './cropper.sass';
import Loading from '../../loading';

interface WidthHeightObject { width: number, height: number }

interface componentProps {
    viewport: WidthHeightObject,
    boundary: WidthHeightObject,
    url: string,
    cropPoints: number[],
    zoom?: number;
}

interface componentState {
    loading: boolean;
}

export default class Cropper extends React.Component<componentProps, componentState> {
    private cropperContainer: HTMLElement;
    private croppie;

    constructor(props: componentProps) {
        super(props);

        this.state = {
            loading: false,
        };
    }

    public componentDidMount() {
        /*
         * Force update is called here in order to call didUpdate
         * after the initial render. (The croppie container needs
         * to be rendered before that is called)
         */
        this.initializeCroppie();
        this.forceUpdate();
    }

    public shouldComponentUpdate(nextProps: componentProps, nextState: componentState) {
        return !shallowEquals(this.props, nextProps)
            || !shallowEquals(this.state, nextState);
    }

    componentDidUpdate(prevProps: Readonly<componentProps>, prevState: Readonly<componentState>, snapshot?: any): void {
        if (prevProps.url !== this.props.url) {
            this.bind();
        }
    }

    public render() {
        return (
            <>
                {this.state.loading && <Loading padding={10} />}
                <div id="croppie-parent" className="cropper" style={{ opacity: 0 }}>
                    <div className="cropping-area-wrapper" style={{ opacity: this.state.loading ? 0 : 1 }}>
                        <AnimatedDragPrompt />
                        <div>
                            {/*
                                Dont place anything in this div other than the target img.
                                The cropping tool may remove it.
                            */}
                            <img alt="crop" ref={el => this.cropperContainer = el!} />
                        </div>
                    </div>
                </div>
            </>
        );
    }

    public getData = () => {
        return this.croppie.get();
    }

    private bind() {
        this.setState({ loading: true });

        this.croppie.bind({
            url: this.props.url,
            zoom: this.props.zoom || 0,
            points: this.props.cropPoints || [],
        })
            .then(r => {
                let checkExists = setInterval(() => {
                    let elementExists = document.getElementById("croppie-parent");
                    if (elementExists) {
                        document.getElementById("croppie-parent")!.style.opacity = "1";
                        clearInterval(checkExists);
                    }
                }, 100);
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    private initializeCroppie() {
        if (typeof window === 'undefined') {
            // Croppie has dependencies on window object.
            // Don't execute this server side.
            return null;
        }

        this.croppie = new Croppie(dom.findDOMNode(this.cropperContainer) as HTMLElement, {
            boundary: this.props.boundary,
            viewport: this.props.boundary,
            showZoomer: false,
            enableOrientation: false,
        });

        this.bind();
    }
}
