import * as React from "react";

import { AttachedFile } from "modules/gallery";
import { CroppableImage } from "modules/common/components/authoring/models";
import { Carousel, ICarouselItem } from "modules/common/components/carousel/carousel";

class HeroBanner extends React.Component<ComponentProps, ComponentState> {
    constructor(props: ComponentProps) {
        super(props);

        this.state = {
            selectedCarouselIndex: 0,
            pauseVideo: false
        };
    }

    public render() {
        const hasHeroImage: boolean = (!!this.props.image && !!this.props.image.id) || !!this.props.imageUrl;
        const hasAttachedContent: boolean = !!(this.props.attachedContent || []).length;

        if (!hasHeroImage && !hasAttachedContent)
            return <div style={{ backgroundColor: this.props.bannerColor }} className="banner"></div>;

        let heroImage: Partial<ICarouselItem> | undefined;
        if (Boolean(this.props.image) || Boolean(this.props.imageUrl))
            heroImage = {
                fileUrl: this.props.image?.url || this.props.imageUrl,
                transforms: this.props.image?.transforms,
                useCanvas: this.props.useCanvas
            };

        return (
            <Carousel
                feature={heroImage}
                files={this.getFiles()}
            >
                {this.props.children}
            </Carousel>
        );
    }

    private getFiles(): ICarouselItem[] {
        if (!this.props.image && !this.props.attachedContent) return [];

        const { useCanvas } = this.props;

        let attachedFiles = this.props.attachedContent
            ? this.props.attachedContent.map((attachedFile: AttachedFile) => ({ ...attachedFile, style: {}, useCanvas }))
            : [];

        return [...attachedFiles];
    }
}


interface ComponentProps {
    attachedContent?: AttachedFile[];
    bannerColor: string;
    image?: CroppableImage;
    imageUrl?: string;
    useCanvas?: boolean; // use canvas when drafting, when post is published the image url is to an image with the transforms applied
}

interface ComponentState {
    selectedCarouselIndex: number;
    pauseVideo: boolean;
}

export default HeroBanner;
