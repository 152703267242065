import * as React from "react";
import moment from "moment";

import { Paper } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { createStyles } from "@mui/styles";
import { makeStyles } from "@mui/styles";

import { EventTimes } from "../../models";

import "../../styles/dateBlock.sass";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dateBlock: {
            backgroundColor: theme.palette.background.default,
        },
    })
);

const DateBlock: React.FunctionComponent<IDateBlockProps> = (props) => {
    const classes = useStyles();

    const { monthFormat = "MMMM", showMultiBlocks = true } = props;

    const eventStartTimes: string[] = !!props.eventTimes ? Object.keys(props.eventTimes) : [];
    const localDate = props.isAllDayEvent ? moment.utc(props.eventStartTime).startOf("day") : moment(props.eventStartTime);

    React.useEffect(() => {
        moment.locale("en");
    }, []);

    const getNextEventStartTime = (eventStartTimes: string[]): string => {
        let eventStartTime: string = eventStartTimes[0];

        if (moment(eventStartTime) < moment()) {
            for (let i = 1; i < eventStartTimes.length; i++) {
                if (moment(eventStartTimes[i]) >= moment()) {
                    eventStartTime = eventStartTimes[i];
                    break;
                }
            }
        }

        return eventStartTime;
    };

    const renderDateBlock = (localDate: moment.Moment, eventStartTime: string, eventStartTimes: string[], isAllDayEvent: boolean) => {
        return (
            <React.Fragment>
                <Paper elevation={5} className={`date-block date-block-0 ${classes.dateBlock}`}>
                    <div className="day">{moment(localDate).format("D")}</div>
                    <div className="month">{moment(localDate).format(monthFormat)}</div>
                    <div className="time">
                        {!props.eventTimes![eventStartTime] ||
                        eventStartTime === props.eventTimes![eventStartTime] ||
                        isAllDayEvent === true
                            ? `All Day`
                            : moment(eventStartTime).format("h:mmA")}
                    </div>
                </Paper>
                {showMultiBlocks &&
                    eventStartTimes
                        .slice(1, 3)
                        .map((_, index) => (
                            <Paper key={index} elevation={5} className={`date-block date-block-${index + 1} ${classes.dateBlock}`}></Paper>
                        ))}
            </React.Fragment>
        );
    };

    if (eventStartTimes.length > 1) {
        const eventStartTime = getNextEventStartTime(eventStartTimes);
        return renderDateBlock(localDate, eventStartTime, eventStartTimes, props.isAllDayEvent!);
    }

    return (
        <Paper elevation={0} className={`date-block ${classes.dateBlock}`}>
            <div className="day">{localDate.format("D")}</div>
            <div className="month">{localDate.format(monthFormat)}</div>
            <div className="time">
                {!props.eventEndTime || props.eventStartTime === props.eventEndTime || props.isAllDayEvent === true
                    ? `All Day`
                    : moment(props.eventStartTime).format("h:mmA")}
            </div>
        </Paper>
    );
};

export interface IDateBlockProps {
    eventStartTime: string;
    eventEndTime: string | null;
    eventTimes?: EventTimes;
    isAllDayEvent?: boolean;
    monthFormat?: "M" | "Mo" | "MM" | "MMM" | "MMMM";
    showMultiBlocks?: boolean;
}

export default DateBlock;
