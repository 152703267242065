import * as React from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";


class UpdateInPersonCapacity extends React.Component<ComponentProps, ComponentState> {
  public render() {
    return (
      <Dialog open={this.props.show} onClose={this.props.onCancel}>
        <DialogTitle>Accept RSVPs</DialogTitle>
        <DialogContent>
          <DialogContentText>You removed the in-person location. Do you want to continue accepting In-person RSVPs?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="text" color="primary" onClick={this.onConfirm}>No</Button>
          <Button variant="contained" color="primary" autoFocus onClick={this.onCancel}>Yes</Button>
        </DialogActions>
      </Dialog>
    );
  }

  private onConfirm = () => {
    this.props.onConfirm();
  }

  private onCancel = () => {
    this.props.onCancel();
  }
}


interface ComponentProps {
  show: boolean;
  title?: string;
  onConfirm: () => void;
  onCancel: () => void;
}

interface ComponentState {
}

export default UpdateInPersonCapacity;