import * as React from 'react';

import './listHeaderDiv.sass';
import { Typography } from '@mui/material';
import { onNextFrame } from 'utils/onNextFrame';

interface IListTitleDivProps {
    show: boolean;
    showKeywordsHeader: boolean;
    runFn: () => void;
}

export const ListHeaderDiv: React.FunctionComponent<IListTitleDivProps> = ({
    show,
    showKeywordsHeader,
    runFn
}) => {
    React.useEffect(() => {
        if (runFn) onNextFrame(() => runFn());
    }, []);

    return <div className='list-container-title-div' style={{ display: show ? 'flex' : 'none'}}>
        <div className='list-title-div-file-name-div'>
            <Typography fontSize={14}>Name</Typography>
        </div>
        <div className='list-title-div-last-modified-div list-title-div-property'>
            <Typography fontSize={14}>Modified On</Typography>
        </div>
        <div className='list-title-div-size-div list-title-div-property'>
            <Typography fontSize={14}>Size</Typography>
        </div>
        <div className='list-title-div-keywords-div list-title-div-property' style={{ display: showKeywordsHeader ? 'block' : 'none'}}>
            <Typography fontSize={14}>Keywords</Typography>
        </div>
    </div>
}