import * as Actions from './actions';
import { PostsState } from './models';
import { Reducer } from './index';

export const actionHandler: Reducer<PostsState> = {
  [Actions.SEND_ACKNOWLEDGEMENT_COMPLETE]: (state: PostsState, action: Actions.SendAcknowledgementComplete) => {
    return {
      ...state,
      postView: {
        ...state.postView,
        errorMessage: action.succeeded ? "" : "Unable to send acknowledgement",
        shouldFetch: action.succeeded
      }
    };
  },
  [Actions.SET_REACTION]: (state: PostsState, action: Actions.SetReaction) => {
    return {
      ...state,
      postView: {
        ...state.postView,
        savingReaction: true
      }
    }
  },
  [Actions.SET_REACTION_COMPLETE]: (state: PostsState, action: Actions.SetReactionComplete) => {
    return {
      ...state,
      postView: {
        ...state.postView,
        errorMessage: action.succeeded ? "" : "Unable to set reaction",
        shouldFetch: action.succeeded,
        savingReaction: false
      }
    };
  },

  [Actions.ADD_COMMENT]: (state: PostsState, action: Actions.AddComment) => {
    return {
      ...state,
      postView: {
        ...state.postView,
        savingComment: true
      }
    };
  },

  [Actions.ADD_COMMENT_COMPLETE]: (state: PostsState, action: Actions.AddCommentComplete) => {
    return {
      ...state,
      postView: {
        ...state.postView,
        errorMessage: action.succeeded ? "" : "Unable to add comment",
        savingComment: false,
        shouldFetch: action.succeeded
      }
    };
  },

  [Actions.ADD_COMMENT_REPLY]: (state: PostsState, action: Actions.AddCommentReply) => {
    return {
      ...state,
      postView: {
        ...state.postView,
        savingComment: true
      }
    };
  },

  [Actions.ADD_COMMENT_REPLY_COMPLETE]: (state: PostsState, action: Actions.AddCommentReplyComplete) => {
    return {
      ...state,
      postView: {
        ...state.postView,
        errorMessage: action.succeeded ? "" : "Unable to add comment",
        savingComment: false,
        shouldFetch: action.succeeded
      }
    };
  },

  [Actions.DELETE_COMMENT]: (state: PostsState, action: Actions.DeleteComment) => {
    return {
      ...state,
      postView: {
        ...state.postView,
        savingComment: true
      }
    };
  },

  [Actions.DELETE_COMMENT_COMPLETE]: (state: PostsState, action: Actions.DeleteCommentComplete) => {
    return {
      ...state,
      postView: {
        ...state.postView,
        errorMessage: action.succeeded ? "" : "Unable to delete comment",
        savingComment: false,
        shouldFetch: action.succeeded
      }
    };
  },

  [Actions.DESTAR_COMMENT_COMPLETE]: (state: PostsState, action: Actions.DestarCommentComplete) => {
    return {
      ...state,
      postView: {
        ...state.postView,
        errorMessage: action.succeeded ? "" : "Unable to remove star"
      }
    };
  },

  [Actions.EDIT_COMMENT]: (state: PostsState, action: Actions.EditComment) => {
    return {
      ...state,
      postView: {
        ...state.postView,
        savingComment: true
      }
    };
  },

  [Actions.EDIT_COMMENT_COMPLETE]: (state: PostsState, action: Actions.EditCommentComplete) => {
    return {
      ...state,
      postView: {
        ...state.postView,
        errorMessage: action.succeeded ? "" : "Unable to edit comment",
        savingComment: false,
        shouldFetch: action.succeeded
      }
    };
  },

  [Actions.FLAG_COMMENT]: (state: PostsState, action: Actions.FlagComment) => {
    return {
      ...state,
      postView: {
        ...state.postView,
        savingComment: true
      }
    };
  },

  [Actions.FLAG_COMMENT_COMPLETE]: (state: PostsState, action: Actions.FlagCommentComplete) => {
    return {
      ...state,
      postView: {
        ...state.postView,
        errorMessage: action.succeeded ? "" : "Unable to report comment",
        savingComment: false,
        shouldFetch: action.succeeded
      }
    };
  },

  [Actions.STAR_COMMENT_COMPLETE]: (state: PostsState, action: Actions.StarCommentComplete) => {
    return {
      ...state,
      postView: {
        ...state.postView,
        errorMessage: action.succeeded ? "" : "Unable to star comment"
      }
    };
  },

  [Actions.SUBSCRIBE_TO_COMMENTS_COMPLETE]: (state: PostsState, action: Actions.SubscribeToCommentsComplete) => {
    return {
      ...state,
      postView: {
        ...state.postView,
        errorMessage: action.succeeded ? "" : "Unable to subscribe to comments"
      }
    }
  },

  [Actions.UNSUBSCRIBE_FROM_COMMENTS_COMPLETE]: (state: PostsState, action: Actions.UnsubscribeFromCommentsComplete) => {
    return {
      ...state,
      postView: {
        ...state.postView,
        errorMessage: action.succeeded ? "" : "Unable to unsubscribe from comments"
      }
    }
  },

  [Actions.CLEAR_FETCH_POST]: (state: PostsState, action: Actions.ClearFetchPost) => {
    return {
      ...state,
      postView: {
        ...state.postView,
        shouldFetch: false
      }
    };
  },

  [Actions.CLEAR_POST_VIEW_ERROR_MESSAGE]: (state: PostsState, action: Actions.ClearPostViewErrorMessage) => {
    return {
      ...state,
      postView: {
        ...state.postView,
        errorMessage: ""
      }
    };
  }
}

