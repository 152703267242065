import React from "react";

import Toolbar from "@mui/material/Toolbar";

import { Theme } from "@mui/material/styles";


import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      backgroundColor: theme.palette.background.paper,
      boxSizing: "border-box",
      justifyContent: "space-between",
      marginTop: -48,
      padding: "0 5px",
      position: "sticky",
      top: 0,
      width: "100%",
      zIndex: 3
    }
  })
);


const TabContentToolbar: React.FunctionComponent<{}> = props => {
  const classes = useStyles();

  return (
    <Toolbar variant="dense" disableGutters className={classes.toolbar}>
      {props.children}
    </Toolbar>
  );
}

export default TabContentToolbar;