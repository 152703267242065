import * as Actions from "./actions";
import { LocalizationState, defaultState } from "./models";
import { createReducer } from "@reduxjs/toolkit";

export const reducer = createReducer(defaultState, builder => {
    builder.addCase(Actions.UpdateLocale, (state, action) => {
        return { ...state, locale: action.payload } as LocalizationState;
    });
    builder.addCase(Actions.UpdateMessages, (state, action) => {
        return { ...state, messages: action.payload.messages } as LocalizationState;
    });
    builder.addCase(Actions.UpdateLangPref, (state, action) => {
        return { ...state, langPref: action.payload } as LocalizationState;
    });
});