import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import * as actions from "../../actionCreator";
import { GlobalApplicationState } from "globalApplicationState";

import { PortalPagesListItem } from "../../models";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import CloseIcon from "@mui/icons-material/Close";


class ModifyPortalPageTitleDialog extends React.Component<PropsWithRedux, ComponentState> {
  constructor(props: PropsWithRedux) {
    super(props);

    this.state = {
      pageTitle: props.portalPage ? props.portalPage.title : "",
      pageTitleIsValid: true
    };
  }

  componentDidUpdate(prevProps: PropsWithRedux) {
    if (this.props.show && !prevProps.show)
      this.setState({
        pageTitle: this.props.portalPage ? this.props.portalPage.title : "",
        pageTitleIsValid: true
      });
  }

  public render() {
    return (
      <Dialog open={this.props.show} maxWidth={false} onClose={this.onClose}>
        <DialogTitle className="portal-pages-dialog-header">
          <div className="portal-pages-dialog-header-title">
            <Typography variant="h2">Modify page title</Typography>
            <IconButton onClick={this.onClose} size="large">
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent className="portal-page-dialog-content">
          <div className="portal-page-dialog-content-form">
            <div>
              <div>Page title</div>
              <TextField
                variant="outlined"
                size="small"
                value={this.state.pageTitle}
                autoComplete="off"
                error={!this.state.pageTitleIsValid}
                fullWidth
                inputProps={{
                  maxLength: 25
                }}
                onChange={this.onChangePageTitle}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions className="portal-pages-dialog-footer">
          <Button variant="text" color="primary" onClick={this.onClose}>Cancel</Button>
          <Button
            variant="contained"
            color="primary"
            disabled={!this.state.pageTitle || !this.state.pageTitleIsValid}
            onClick={this.onSave}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  private onSave = () => {
    if (this.props.portalPage!.state === "Draft") {
      this.props.modifyDraftTitle(this.props.portalPage!.draftId, this.state.pageTitle.trim()).then((succeeded) => {
        this.props.updateDraft();
        if (succeeded && !!this.props.onSuccess)
          this.props.onSuccess();
      });
      this.onClose();
    } else {
      this.props.modifyPortalPageTitle(this.props.portalPage!.id, this.state.pageTitle.trim()).then((succeeded) => {
        this.props.updateDraft();
        if (succeeded && !!this.props.onSuccess)
          this.props.onSuccess();
      });
      this.onClose();
    }
  }

  private onChangePageTitle = (event) => {
    const isInvalid = /[^A-Za-z0-9\s]/.test(event.target.value) || event.target.value.toLowerCase() === "home";
    this.setState({ pageTitle: event.target.value, pageTitleIsValid: !isInvalid });
  }

  private onClose = () => {
    this.props.onClose();
  }
}


interface ComponentProps {
  show: boolean;
  portalPage: PortalPagesListItem | undefined;
  onSuccess?: () => void;
  onClose: () => void;
}

interface ComponentState {
  pageTitle: string;
  pageTitleIsValid: boolean;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps
  }),
  {
    modifyDraftTitle: actions.modifyDraftTitle,
    modifyPortalPageTitle: actions.modifyPortalPageTitle,
    updateDraft: actions.updateDraft
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(ModifyPortalPageTitleDialog);