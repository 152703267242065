import React, { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";

import { UserRoles } from "modules/authorization/models";

import { IMenuItem, MenuItem } from "../../components/MenuItem";

import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";

import { Theme } from "@mui/material/styles";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import BuildIcon from "@mui/icons-material/Build";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import PostAddIcon from '@mui/icons-material/PostAdd';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        list: {
            position: "static"
        },
        poweredBy: {
            color: "#aaaaaa",
            fontSize: 10,
            marginBottom: 10,
            marginLeft: 23,
            marginTop: 10,
            opacity: 0,
            "&.expanded": {
                opacity: 1,
                transition: "opacity linear 0.1s 0.2s"
            }
        }
    })
);

const MenuItems = (props: PropsWithRedux): IMenuItem[] => {
    let items: IMenuItem[] = [];

    if (props.portalPagesEnabled && !!props.navigation.navigationItems.length) {
        items = [
            { label: "Home", icon: <HomeOutlinedIcon />, type: "link", url: "~/home" }
        ];

        if (props.portalPagesEnabled && props.navigation.navigationItems.length > 1) {
            items = items.concat([
                {
                    label: "Portal Pages", icon: <MenuBookIcon />, type: "link", children: props.navigation.navigationItems.slice(1).map((navigationItem) => (
                        {
                            label: navigationItem.name, type: "link", url: `~/page/${navigationItem.url}`, children: navigationItem.children.map((childNavigationItem) => (
                                { label: childNavigationItem.name, type: "link", url: `~/page/${childNavigationItem.url}` }
                            ))
                        }
                    ))
                }
            ]);
        }

        items = items.concat([
            { label: "", type: "divider" },
            { label: "My Feed", icon: <LibraryBooksIcon />, type: "link", url: "~/posts" }
        ]);
    } else {
        items = [
            { label: "My Feed", icon: <LibraryBooksIcon />, type: "link", url: "~/home" }
        ];
    }

    items = items.concat([
        { label: "Events", icon: <EventOutlinedIcon />, type: "link", url: "~/events", hide: !props.eventsEnabled },
        { label: "Documents", icon: <FolderOutlinedIcon />, type: "link", url: "~/documents", hide: !props.documentsEnabled },
        { label: "", type: "divider" },
        { label: "My Profile", icon: <AccountCircleOutlinedIcon />, type: "link", url: "~/me" },
        { label: "", type: "divider" },
        { label: "Go to Admin Portal", type: "link", icon: <BuildIcon />, pushToBrowserHistory: true, url: "~/admin/dashboard", userRole: UserRoles.Author },
        { label: "Manage My Posts", type: "link", icon: <PostAddIcon />, pushToBrowserHistory: true, url: "~/admin/posts", userRole: UserRoles.Contributor, strictRole: true },
    ]);

    return items;
}

const SignInMenuItem = (): IMenuItem => {
    return { label: "Sign in", icon: <AccountCircleOutlinedIcon />, type: "link", url: "~/" };
}

const Menu: React.FunctionComponent<PropsWithRedux> = props => {
    const [expandedMenuItem, setExpandedMenuItem] = useState("");
    const classes = useStyles();

    return (
        <Drawer
            open
            variant="permanent"
            PaperProps={{
                elevation: 5
            }}
            classes={{
                paper: props.drawerPaperClassName
            }}
        >
            <List className={classes.list}>
                {!props.publicAccess
                    ? MenuItems(props).map((menuItem, index) => {
                        if (menuItem.type === "divider") {
                            return (
                                <Divider
                                    key={index}
                                    light
                                />
                            );
                        } else {
                            let selected = false;
                            const menuItemUrl = menuItem.url || "";

                            if ((menuItem.url || "").startsWith("~/")) {
                                const menuItemUrlWithTenant = menuItemUrl.replace("~/", "/" + props.tenant + "/");
                                selected = props.locationPathName.indexOf(menuItemUrlWithTenant) >= 0;
                            } else if (!!menuItem.children && !!menuItem.children.length) {
                                selected = !!menuItem.children.find(subMenuItem => {
                                    const subMenuItemUrl = subMenuItem.url || "";
                                    const subMenuItemUrlWithTenant = subMenuItemUrl.replace("~/", "/" + props.tenant + "/");

                                    if (props.locationPathName.indexOf(subMenuItemUrlWithTenant) >= 0) {
                                        return true;
                                    } else if (!!subMenuItem.children && !!subMenuItem.children.length) {
                                        if (!!subMenuItem.children.find(childSubMenuItem => {
                                            const childSubMenuItemUrl = childSubMenuItem.url || "";
                                            const childSubMenuItemUrlWithTenant = childSubMenuItemUrl.replace("~/", "/" + props.tenant + "/");
                                            return props.locationPathName.indexOf(childSubMenuItemUrlWithTenant) >= 0;
                                        }))
                                            return true;
                                        else
                                            return false;
                                    } else {
                                        return false;
                                    }
                                });
                            }

                            return (
                                <MenuItem
                                    key={index}
                                    expand={props.expand}
                                    expandedMenuItem={expandedMenuItem}
                                    menuItem={menuItem}
                                    selected={selected}
                                    setExpand={props.setExpand}
                                    setExpandedMenuItem={setExpandedMenuItem}
                                    hideIcons={props.hideIcons}
                                />
                            );
                        }
                    })
                    : <MenuItem
                        key={0}
                        expand={props.expand}
                        expandedMenuItem={expandedMenuItem}
                        menuItem={SignInMenuItem()}
                        setExpand={props.setExpand}
                        setExpandedMenuItem={setExpandedMenuItem}
                        hideIcons={true}
                    />
                }
            </List>
            <div className={`${classes.poweredBy} ${props.expand ? "expanded" : ""}`}>
                <div>Powered by Sparrow Connected</div>
                <div>{process.env.REACT_APP_ADMINPORTAL_VERSION}</div>
            </div>
        </Drawer>
    );
}

interface ComponentProps {
    expand?: boolean;
    locationPathName: string;
    publicAccess?: boolean;
    setExpand: (expand: boolean) => void;
    tenant: string;
    drawerPaperClassName: string;
    hideIcons?: boolean;
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        documentsEnabled: state.settings.clientSettings.documentsEnabled,
        eventsEnabled: state.settings.clientSettings.mobileEventsEnabled,
        navigation: state.portalPages.navigation,
        portalPagesEnabled: state.settings.clientSettings.portalPagesEnabled,
        currentUser: state.settings.currentUser,
    })
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(Menu);
