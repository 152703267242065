import * as React from "react";

import TextField from "@mui/material/TextField";

import moment from "moment";
import DateAndTime from "../forms/inputs/dateAndTime";
import HoverText from "modules/documents/components/action-buttons/hoverText";
import { PickerLocalization } from "../pickerLocalization";
import DatePicker from "../forms/inputs/datePicker";


// authoring v2 component
const PublishTimev2: React.FunctionComponent<ComponentProps> = ({
    publishTime,
    onChange,
}) => {
    return (
        <>
            <HoverText labelContainerClassName="hover-label" label="Publish on">
                Schedule when this post gets published or change the publish date that appears on the post.
            </HoverText>
            <DateAndTime
                clearable
                onChangeDate={onChange}
                date={publishTime}
                dateStyle={{ width: 250 }}
                datePlaceholder="Select date"
            />
        </>
    );
}


class PublishTime extends React.Component<ComponentProps, ComponentState> {
    public render() {
        const { publishTime } = this.props;

        return (
            <div className="scheduled-time">
                <div className="scheduled-time-field">
                    <div className="scheduled-time-label">Publish on</div>
                    <div className="scheduled-time-input">
                        <PickerLocalization>
                            <DatePicker
                                clearable
                                date={publishTime}
                                onChangeDate={this.props.onChange}
                                placeholder="Schedule a publishing date"
                            />
                        </PickerLocalization>
                    </div>
                </div>
                <div className="scheduled-time-input-time">
                    <div>at</div>
                    <TextField
                        variant="outlined"
                        size="small"
                        color="primary"
                        type="time"
                        value={!!publishTime ? moment(publishTime).format("HH:mm") : ""}
                        disabled={!publishTime}
                        inputProps={{
                            step: 900
                        }}
                        onChange={this.onChangePublishTime}
                    />
                </div>
            </div>
        );
    }

    private onChangePublishTime = (event) => {
        const times: number[] = event.target.value.split(":");
        const publishTime: string = moment(this.props.publishTime).set("hour", times[0]).set("minutes", times[1]).toISOString();
        this.props.onChange(publishTime);
    }
}


interface ComponentProps {
    publishTime: string | undefined;
    onChange: (publishTime: string | undefined) => void;
}

interface ComponentState { }

export default PublishTime;
export { PublishTimev2 };
