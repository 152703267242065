import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import * as actions from "../../actionCreator";
import { GlobalApplicationState } from "globalApplicationState";
import { push } from "react-router-redux";
import { EventFilterValues, EventListingPage } from "../../models";

import ErrorSnackbar from "modules/common/components/snackbars/errorSnackbar";
import LoadingOverlay from "modules/common/components/loadingOverlay";
import PublishSuccessDialog from "../dialogs/publishSuccess";
import EventList from "./eventList";

import Tabs from "pages/common/tabs";
import { SortStyle } from "utils/managementUtils";
import { tagsApi } from "api/instances";
import { TenantSettingsTagGroup } from "modules/settings";

const draftTab: number = 0;
const scheduledTab: number = 1;
const publishedTab: number = 2;
const allTab: number = 3;


class EventListing extends React.Component<PropsWithRedux, ComponentState> {
    constructor(props: PropsWithRedux) {
        super(props);

        this.state = {
            selectedItems: [],
            selectedTab: 0,
            showFilters: false,
            availableTopics: []
        };
    }

    public componentDidMount() {
        if (this.props.isInitialLoad || this.props.shouldFetch) {
            this.fetchLists();
            this.getTags();
        }
    }

    public componentDidUpdate(prevProps: PropsWithRedux) {
        if (this.props.shouldFetch && (this.props.shouldFetch !== prevProps.shouldFetch)) {
            this.fetchLists();
        }
    }

    public componentWillUnmount() {
        this.props.setToDefaultState();
    }

    public render() {
        const { selectedTab } = this.state;

        return (
            <React.Fragment>
                <div>
                    <Tabs
                        tabs={[
                            { label: this.getTabLabel("Drafts", this.props.drafts), dotColor: "grey" },
                            { label: this.getTabLabel("Scheduled", this.props.scheduled), dotColor: "yellow" },
                            { label: this.getTabLabel("Published", this.props.published), dotColor: "green" },
                            { label: this.getTabLabel("All", this.props.allEventsList) }
                        ]}
                        selectedTab={selectedTab}
                        onSelectTab={this.onSelectTab}
                    />
                    <EventList show={selectedTab === allTab} page={this.props.allEventsList} fetchPage={this.fetchAllEventList} initialSort={SortStyle.startDesc} availableTopics={this.state.availableTopics} />
                    <EventList show={selectedTab === publishedTab} page={this.props.published} fetchPage={this.fetchPublished} initialSort={SortStyle.startDesc} availableTopics={this.state.availableTopics} />
                    <EventList show={selectedTab === scheduledTab} page={this.props.scheduled} fetchPage={this.fetchScheduled} initialSort={SortStyle.startDesc} availableTopics={this.state.availableTopics} />
                    <EventList show={selectedTab === draftTab} page={this.props.drafts} fetchPage={this.fetchDrafts} initialSort={SortStyle.modifiedDesc} availableTopics={this.state.availableTopics} />
                    <PublishSuccessDialog />
                </div>
                <ErrorSnackbar errorMessage={this.props.errorMessage} clearErrorMessage={this.props.clearErrorMessage} />
                <LoadingOverlay absolute={true} show={this.props.isDeleting || this.props.isFetching || this.props.isSaving} />
            </React.Fragment>
        );
    }

    private fetchLists = () => {
        this.fetchAllEventList(1, { sortType: SortStyle.startDesc });
        this.fetchDrafts(1, { sortType: SortStyle.modifiedDesc });
        this.fetchPublished(1, { sortType: SortStyle.startDesc });
        this.fetchScheduled(1, { sortType: SortStyle.startDesc });
        this.props.clearShouldFetch();
    }

    private fetchDrafts = (pageNumber: number, filters: Partial<EventFilterValues>, pageAmount?: number) => {
        !this.props.drafts.isFetching &&
            this.props.getDrafts(pageNumber, filters, pageAmount);
    }

    private fetchPublished = (pageNumber: number, filters: Partial<EventFilterValues>, pageAmount?: number) => {
        !this.props.published.isFetching &&
            this.props.getPublished(pageNumber, filters, pageAmount);
    }

    private fetchScheduled = (pageNumber: number, filters: Partial<EventFilterValues>, pageAmount?: number) => {
        !this.props.scheduled.isFetching &&
            this.props.getScheduled(pageNumber, filters, pageAmount);
    }

    private fetchAllEventList = (pageNumber: number, filters: Partial<EventFilterValues>, pageAmount?: number) => {
        !this.props.allEventsList.isFetching &&
            this.props.getAllEvents(pageNumber, filters, pageAmount);
    }

    private getTabLabel = (label: string, page: EventListingPage): string => {
        if (!!page.currentPage)
            return `${label} (${page.totalEvents})`;
        return label;
    }

    private onSelectTab = (tabIndex: number) => {
        this.setState({ ...this.state, selectedTab: tabIndex });
    }

    private getTags = async () => {
        let tagsToSet: TenantSettingsTagGroup[] = [];

        try {
            tagsToSet = await tagsApi.getUserTags();
        } catch (err) {
            tagsToSet = [];
        } finally {
            this.setState({ availableTopics: tagsToSet });
        }
    }
}


interface ComponentProps {
}

interface ComponentState {
    selectedItems: string[];
    selectedTab: number;
    showFilters: boolean;
    availableTopics: TenantSettingsTagGroup[];
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        drafts: state.events.drafts,
        allEventsList: state.events.allEventsList,
        published: state.events.published,
        scheduled: state.events.scheduled,
        errorMessage: state.events.errorMessage,
        isDeleting: state.events.isDeleting,
        isFetching: state.events.isFetching,
        isInitialLoad: state.events.isInitialLoad,
        isSaving: state.events.isSaving,
        shouldFetch: state.events.shouldFetch,
        tenantSettings: state.settings.tenantSettings,
        tenant: state.tenant.id
    }),
    {
        clearErrorMessage: actions.clearErrorMessage,
        clearShouldFetch: actions.clearShouldFetch,
        getAllEvents: actions.getAllEvents,
        getDrafts: actions.getDrafts,
        getPublished: actions.getPublished,
        getScheduled: actions.getScheduled,
        redirectTo: push,
        setToDefaultState: actions.setEventsFeedToDefault
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(EventListing);
