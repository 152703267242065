import React, { useEffect, useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { Divider } from "@mui/material";
import moment from 'moment';

import { TenantNotificationSettings } from "modules/messaging";
import { DelayType, NotificationRange, Notifications, Reminder, ReminderInfo } from "../models";
import { CommentingTypes } from "modules/posts/models";
import Pagination from "../../pagination/pagination";
import { dateOptions } from "utils/dateFormatting";
import { isAnyTenantPostNotificationsEnabled, isAnyTenantRemindersEnabled } from "utils/notificationsHelper";

interface IOneLastCheckMoreDetailsProps {
    tenantNotificationSettings: TenantNotificationSettings;
    notifications?: Notifications;
    breakingTime: Date | null | undefined;
    featuredTime: Date | null | undefined;
    expiryTime: Date | null | undefined;
    commentsEnabledOnContent: boolean;
    commentType?: CommentingTypes;
    reactionsEnabledOnTenant: boolean;
    reactionsEnabledOnContent: boolean;
    isPreviouslyPublished: boolean;
}

const OneLastCheckMoreDetails: React.FunctionComponent<IOneLastCheckMoreDetailsProps> = ({
    tenantNotificationSettings,
    notifications,
    breakingTime,
    featuredTime,
    expiryTime,
    reactionsEnabledOnTenant = false,
    reactionsEnabledOnContent = false,
    commentsEnabledOnContent = false,
    commentType,
    isPreviouslyPublished,
}) => {
    const [reminders, setReminders] = useState<ReminderInfo[]>([]);
    const [reminderIndex, setReminderIndex] = useState<number>(0);

    // setup reminders
    useEffect(() => {
        // Because we cannot edit reminders from this modal, we only need to do this reminder update the first time we render.
        // That's why we can check if the lengths are not matching
        if (notifications && notifications.reminders.length > 0 && reminders.length === 0) {
            let reminderInfos: ReminderInfo[] = [];

            // build usable reminder info objects
            notifications.reminders.forEach((reminder: Reminder, index: number) => {
                const delay: number = reminder.delay % 24 === 0 ? reminder.delay / 24 : reminder.delay;
                const delayType: DelayType = reminder.delay > 0 && reminder.delay % 24 === 0 ? "days" : "hours";

                let newReminderInfo: ReminderInfo | undefined = undefined;

                if (reminder.channel === "none") {
                    newReminderInfo = {
                        delay,
                        delayType,
                        emailRange: "none",
                        mobileRange: "none",
                        smsRange: "none",
                        teamsRange: "none"
                    };
                } else if (reminder.channel === "inherit") {
                    newReminderInfo = {
                        delay,
                        delayType,
                        emailRange: notifications.emailOnPublish,
                        mobileRange: notifications.mobileOnPublish,
                        smsRange: notifications.smsOnPublish,
                        teamsRange: notifications.teamsOnPublish
                    };
                } else {
                    // we get reminders as separate notifications for each channel
                    // this block consolidates all the reminders of the same delay and delayType so we can display as 1 notification with multiple channels

                    // find a previous reminder with the same delay and delay type
                    let previousReminder = reminderInfos.find((info: ReminderInfo) => info.delay === delay && info.delayType === delayType);

                    // if previous reminder with same delay and delay type exists, add the current reminder channel to the previous one
                    if (previousReminder) {
                        if (reminder.channel === "email")
                            previousReminder.emailRange = reminder.range;
                        else if (reminder.channel === "mobile")
                            previousReminder.mobileRange = reminder.range;
                        else if (reminder.channel === "sms")
                            previousReminder.smsRange = reminder.range;
                        else if (reminder.channel === "teams")
                            previousReminder.teamsRange = reminder.range;
                    } else {
                        newReminderInfo = {
                            delay,
                            delayType,
                            emailRange: reminder.channel === "email" ? reminder.range : "none",
                            mobileRange: reminder.channel === "mobile" ? reminder.range : "none",
                            smsRange: reminder.channel === "sms" ? reminder.range : "none",
                            teamsRange: reminder.channel === "teams" ? reminder.range : "none"
                        };
                    }
                }

                if (newReminderInfo) reminderInfos.push(newReminderInfo);
            });

            setReminders(reminderInfos)
        }
    }, [notifications, reminders]);

    const onPageChange = (newPage: number) => {
        setReminderIndex(newPage);
    }

    const getDateString = (date: Date | null | undefined) => {
        return date ? moment(date).locale("en").format(dateOptions.dateAndTime) : "";
    }

    const getCommentsLabel = () => commentsEnabledOnContent
        ? `Enabled - ${commentType || "Standard"}`
        : "Disabled";

    const areNotificationsEnabledOnTenant = isAnyTenantPostNotificationsEnabled(tenantNotificationSettings);

    const areRemindersEnabledOnTenant = isAnyTenantRemindersEnabled(tenantNotificationSettings);

    const getNotificationStatus = (label: string, range: NotificationRange, notificationsEnabled: boolean = true) => {
        if (range === "none" || !notificationsEnabled)
            return <div className="unselected-option"><ClearIcon style={{ marginRight: 11, fontSize: 24 }} />{label}</div>;

        return <div className="selected-option"><CheckIcon style={{ marginRight: 11, fontSize: 24 }} fontSize="small" />{label}</div>
    }

    const divider = (): JSX.Element => (<Divider style={{ margin: "24px 0", color: "#dde1e5" }} />);

    const getRow = (label: string, data: string) => (
        <div className="info-row">
            <label className="info-label">
                {label}
            </label>
            <span className="data">{data}</span>
        </div>
    );

    const getReminder = (reminder: ReminderInfo, index: number): JSX.Element => (
        <div key={`reminder-info-${index}`} className="reminder-row">
            <div className="title">Reminder {index + 1}</div>
            <div>
                <span className="reminder-label">Send in {reminder.delay} {reminder.delayType} if unread</span>
                {getNotificationStatus("MS Teams Chatbot", reminder.teamsRange, tenantNotificationSettings.settings["postReminderTeamsAttempt"].toggle)}
                {getNotificationStatus("Mobile App", reminder.mobileRange, tenantNotificationSettings.settings["postReminderMobileAttempt"].toggle)}
                {getNotificationStatus("Email", reminder.emailRange, tenantNotificationSettings.settings["postReminderEmailAttempt"].toggle)}
                {getNotificationStatus("SMS", reminder.smsRange, tenantNotificationSettings.settings["postReminderSMSAttempt"].toggle)}
            </div>
        </div>
    );

    return (
        <>
            {breakingTime && getRow("Breaking News until", getDateString(breakingTime))}
            {featuredTime && getRow("Featured News until", getDateString(featuredTime))}
            {expiryTime && getRow("Expires on", getDateString(expiryTime))}
            {(expiryTime || featuredTime || breakingTime) && divider()}
            {getRow("Comments", getCommentsLabel())}
            {reactionsEnabledOnTenant && getRow("Reactions", reactionsEnabledOnContent ? "Enabled" : "Disabled")}
            {divider()}
            {notifications &&
                <>
                    <div className="info-row">
                        <label className="info-label">Notifications upon publishing</label>
                        <div className="data">
                            {areNotificationsEnabledOnTenant
                                ? <>
                                    {getNotificationStatus("MS Teams Chatbot", notifications.teamsOnPublish, tenantNotificationSettings.settings["postPublishedTeamsAttempt"]?.toggle)}
                                    {getNotificationStatus("Mobile App", notifications.mobileOnPublish, tenantNotificationSettings.settings["postPublishedMobileAttempt"]?.toggle)}
                                    {getNotificationStatus("Email", notifications.emailOnPublish, tenantNotificationSettings.settings["postPublishedEmailAttempt"]?.toggle)}
                                    {getNotificationStatus("SMS", notifications.smsOnPublish, tenantNotificationSettings.settings["postPublishedSMSAttempt"]?.toggle)}
                                </>
                                : <p>Your organization has disabled post publishing notifications.<br />Change this setting in Tenant Configurations.</p>}
                        </div>
                    </div>
                </>}
            {reminders && reminders.length > 0 &&
                <>
                    <div className="info-row">
                        <label className="info-label">
                            Reminders {`(${reminders.length})`}
                        </label>
                        <div className="data">
                            {areRemindersEnabledOnTenant
                                ? <>
                                    {getReminder(reminders[reminderIndex], reminderIndex)}
                                    <Pagination
                                        dropDown
                                        currentPage={reminderIndex}
                                        count={reminders.length}
                                        onPageChange={onPageChange}
                                    />
                                </>
                                : <p>Your organization has disabled post reminder notifications.<br />Change this setting in Tenant Configurations.</p>}
                        </div>
                    </div>
                </>
            }
        </>
    );
}

export default OneLastCheckMoreDetails;
