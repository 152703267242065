import { Container, IconButton, TextField } from '@mui/material';
import * as React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import InputAdornment from '@mui/material/InputAdornment';

interface ISearchAppBarProps {
    show: boolean;
    onTextValueChange: (val: string) => void;
    textValue: string;
    onClearSearchText: () => void;
}

export const SearchTextField: React.FunctionComponent<ISearchAppBarProps> = ({
    show,
    onTextValueChange,
    textValue,
    onClearSearchText
}) => {
    return <Container 
        sx={{ 
            position: 'absolute', 
            top: '37px',
            display: show ? 'block' : 'none',
            marginLeft: '10px',
            width: 'fit-content'
        }} 
        disableGutters
    >
        <TextField
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
                endAdornment: (
                    textValue &&
                    <InputAdornment position="end">
                        <IconButton
                            onClick={onClearSearchText}
                            edge="end"
                        >                  
                            <ClearIcon />
                        </IconButton>
                    </InputAdornment>
                ),
                sx: {
                    width: '300px',
                    height: '30px',
                    fontSize: '15px !important',
                    paddingLeft: '5px',
                    paddingRight: '5px'
                }
            }}
            variant="outlined"
            hiddenLabel
            size="small"
            margin="dense"
            value={textValue}
            onChange={(e) => onTextValueChange(e.target.value)}
            placeholder="Search"
        />
    </Container>
}