import axios from "axios";
import { RequestFormatInterceptor } from "../interceptors/RequestFormatInterceptor";
import { TenantAutoFillInterceptor } from "../interceptors/TenantAutoFillInterceptor";
import { store } from "configureStore";
import { OktaAuthInterceptor } from "../interceptors/OktaAuthInterceptor";

//This class is used to call the SyncFunctions in order to query Groups using the User's Okta Token, as well
//as to to initiate the Sync of Groups through the SyncFunctions, also using their Token.
export class SyncFunctionsOktaApi {
    constructor(
        private _authInterceptor: OktaAuthInterceptor,
        private _formatInterceptor: RequestFormatInterceptor,
        private _tenantAutoFillInterceptor: TenantAutoFillInterceptor
    ) {}

    public CreateRequest() {
        const instance = axios.create();
        instance.defaults.baseURL = store.getState().config.SyncFunctionsApiUrl;
        this._authInterceptor.bind(instance);
        this._formatInterceptor.bind(instance);
        return instance;
    }

    public TenantRequest() {
        let instance = this.CreateRequest();
        this._tenantAutoFillInterceptor.bind(instance);
        return instance;
    }
}
