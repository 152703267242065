import * as React from "react";
import * as actions from "../actionCreator";
import { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import { push } from "react-router-redux";

import EmailActivityLog from "../common/dialogs/emailActivityLog";
import GlobalSettings from "./components/globalSettings";
import { MoreOptions } from "./components/moreOptions";
import NewsletterListing from "./components/newsletterListing";
import AddIcon from "@mui/icons-material/Add";

import BasePage from "pages/common/basePage";
import Header from "pages/common/header";
import MainContent from "pages/common/mainContent";

import "../styles/dialogs.sass";
import { Button, Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { RouteComponentProps } from "react-router";
import { Address, NewsletterDigest, NewsletterTags } from "..";

import _ from "lodash";
import CopyNewsletterDialog from "../common/dialogs/copyNewsletterDialog";
import CloseIcon from "@mui/icons-material/Close";
import Loading from "modules/common/components/loading";
import ThemeModal from "./components/themeModal";
import SnackbarWrapper from "modules/common/components/snackbars/snackbarWrapper";

interface RouteParams {
  tenant: string;
}

const NewsletterManagementPage : React.FunctionComponent<PropsWithRedux> = (props) => {
  const { getNewsletterTags } = props;
  const [changeCount, setChangeCount] = useState(0);
  const [showActivityLog, setShowActivityLog] = useState(false);
  const [showGlobalSettings, setShowGlobalSettings] = useState(false);
  const [showThemeModal, setShowThemeModal] = useState(window.location.href.toString().includes("managethemes"));
  const [successMessage, setSuccessMessage] = useState(null as string | null);
  const [errorMessage, setErrorMessage] = useState(null as string | null);
  const [newsletters, setNewsletters] = useState(null as (NewsletterTags | null));
  const [copyNewsletter, setCopyNewsletter] = useState(null as (NewsletterDigest | null));

  const [dialogOpen, setDialogOpen] = useState(true);
  const [warningOpen, setWarningOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [sender, setSender] = useState(null as (Address | null));
  const [associated, setAssociated] = useState([] as string[]);
  const [loading, setLoading] = useState(false);

  useEffect(() => { getNewsletterTags()
    .then((tags) => {
    setNewsletters(tags);
  }); },[changeCount, getNewsletterTags]);

  const newsletterTags = _.orderBy(_.concat((newsletters && newsletters.enabled) || [], (newsletters && newsletters.disabled) || []), t=>t.name);

    const closeDialog = () => {
      setWarningOpen(false);
      setDialogOpen(true);
    }

    const closeDelete = () => {
      setConfirmOpen(false);
      setDialogOpen(true);
    }

    const associatedNewslettersMap = (associatedNewsletters): JSX.Element => {
      return (
        <React.Fragment>
          {associatedNewsletters.map((newsletter) => {
            const text: string = `${newsletter.title}`;
            return (
              <li key={newsletter.id}>
                <span
                  onClick={() => {
                    const location: string = window.location.href;
                    const segments: string[] = location.split(props.tenant);
                    window.open(`${segments[0]}${props.tenant}/admin/newsletters/${newsletter.id}/dashboard`);
                  }}
                  className="audience-associated-item"
                >
                  {text}
                </span>
              </li>
            );
          })}
        </React.Fragment>
      );
    }

    const onCheckExisting = async (senderDetails: Address) => {
      setLoading(true);
      setDialogOpen(false);
      setWarningOpen(true);
      const associatedNewsletters = await props.getAssociatedNewsletters(senderDetails.fromEmailName, senderDetails.fromEmailAddress);
      setAssociated(associatedNewsletters);
      setSender(senderDetails);
      setLoading(false);
    }

    const onConfirmDelete = () => {
      setDialogOpen(false);
      setConfirmOpen(true);
    }

    const handleCloseThemeModal = () => {
      setShowThemeModal(false);

      //If we've gotten onto the page via a forced manage theme open, the first time we close it redirect them to the regular page.
      if(window.location.href.toString().includes("managethemes")) {
        let page = `/${props.tenant}/admin/newsletters`;
        props.history.replace(page);
      }
    }

    return (
      <BasePage fullWidth>
        <Header
          title="Manage Newsletters"
          rightComponent={
            <React.Fragment>
              <MoreOptions
                onShowActivityLog={() => setShowActivityLog(true)}
                onShowGlobalSettings={() => setShowGlobalSettings(true)}
                onShowTemplateThemes={() => setShowThemeModal(true)}
              />
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={() => props.redirectTo("/" + props.match.params.tenant + "/admin/newsletters/new")}
              >
                New Newsletter
              </Button>
            </React.Fragment>
          }
        />
        <MainContent>
          <div className="authoring-page">
            <NewsletterListing
              tags={newsletters}
              changeCount={changeCount}
              onCreateCopy={(newsletterDigest) => setCopyNewsletter(newsletterDigest)}
              onNewsletterChanged={(message) => {setSuccessMessage(message); setChangeCount(changeCount+1);}}
              />
          </div>
          { showActivityLog && <EmailActivityLog newsletterTags={newsletterTags} onClose={() => setShowActivityLog(false)} />}
          { showGlobalSettings && <GlobalSettings onClose={() => setShowGlobalSettings(false)} dialogOpen={dialogOpen} onCheckExisting={onCheckExisting} confirmOpen={confirmOpen} openConfirm={onConfirmDelete} closeConfirm={closeDelete} /> }
          { showThemeModal && <ThemeModal dialogOpen={showThemeModal} onClose={() => handleCloseThemeModal()} />}
          {!!copyNewsletter && <CopyNewsletterDialog
              copyingNewsletter={copyNewsletter}
              onClose={(newNewsletterId) =>
                {
                  setCopyNewsletter(null);
                  if(!!newNewsletterId){
                    setSuccessMessage("Newsletter copied");
                    props.redirectTo(`/${props.match.params.tenant}/admin/newsletters/${newNewsletterId}`);
                  }
                }
              } />}
          <Dialog open={warningOpen}>
            <DialogTitle className="newsletter-dialog-title">
              <div style={{color: "#2f4050"}}>Sender email in use
                <div style={{float: "right", position: "relative", top: "-2px"}}>
                  <IconButton onClick={closeDialog} size="small">
                    <CloseIcon />
                  </IconButton>
                </div>
              </div>
            </DialogTitle>
            <DialogContent style={{paddingBottom: 8}}>
              {loading &&
                <div style={{paddingBottom: "12px"}}>
                  <Loading padding={12}></Loading>
                </div>
              }
              {!loading &&
              <div>
                <div>
                  <div style={{wordBreak: "break-word"}}>{sender?.fromEmailName ?? ""}, {sender?.fromEmailAddress ?? ""} cannot be deleted. <br/>The email is currently used in:</div>
                  <ul>
                    {associatedNewslettersMap(associated)}
                  </ul>
                </div>
                <div>
                  <Button color="primary" variant="contained" onClick={closeDialog} style={{float: "right", paddingBottom: "4px"}}>
                    Back to global settings
                  </Button>
                </div>
              </div>
              }
            </DialogContent>
          </Dialog>
          <SnackbarWrapper open={!!successMessage} style={{ top: "60px" }} autoHideDuration={8000} onClose={() => setSuccessMessage(null)} severity="success" message={successMessage} />
          <SnackbarWrapper open={!!errorMessage} style={{ top: "60px" }} autoHideDuration={8000} onClose={() => setErrorMessage(null)} severity="error" message={errorMessage} />
        </MainContent>
      </BasePage>
    );
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: RouteComponentProps<RouteParams>) =>
  ({
    ...ownProps,
    tenant: state.tenant.id
  }),
  {
    redirectTo: push,
    getNewsletterTags: actions.getNewsletterTags,
    getAssociatedNewsletters: actions.getAssociatedNewsletters
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(NewsletterManagementPage);
