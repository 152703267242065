import {pie, arc, select} from 'd3';
const d3 = {pie, arc, select};

export interface IPieChartProps {
    el: any;
    data: IPieChartDataPoint[];
    diameter: number;
    innerDiameter: number;
    locale?: string;
}

export interface IPieChartDataPoint {
    label: string;
    value: number;
    color: string;
}

export class PieChart {

    constructor(props: IPieChartProps) {
        this.update(props);
    }

    public update(props: IPieChartProps) {

        if (!props.el) {
            return;
        }

        props.el.getBoundingClientRect();

        const total = props.data.reduce((val, d) => val + d.value, 0);

        const padAngle = props.data.filter(d=>d.value > 0).length <= 1 ? 0 : 0.03;

        const pie = d3.pie<IPieChartDataPoint>()
            .value((d) => (d.value / total) as number)
            .sort(null)
            .padAngle(padAngle);

        const w = props.diameter, h = props.diameter;

        const outerRadius = w / 2;
        const innerRadius = props.innerDiameter / 2;

        const arc = d3.arc<IPieChartDataPoint>()
                .outerRadius(outerRadius)
                .innerRadius(innerRadius);

        d3.select(props.el)
            .select('svg').remove();

        const svg = d3.select(props.el)
                .append('svg')
                .attr('width', w)
                .attr('height', h)
                .attr('class', 'shadow')
                .append('g')
                .attr('transform', 'translate(' + w / 2 + ',' + h / 2 + ')');

        svg.selectAll('path')
                .data(pie(props.data))
                .enter()
                .append('path')
                .attr('d', arc as any)
                .attr('fill', (d: any, i) => d.data.color);
    }

    public destroy() {
        // Any clean-up would go here
        // in this example there is nothing to do
    }
}

