import React, { useState } from "react";

import { NewsletterDetails } from "../../models";

import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Popover from "@mui/material/Popover";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";


const DashboardActions: React.FunctionComponent<ComponentProps> = props => {
  const [actionsAnchorEl, setActionsAnchorEl] = useState(null);
  const [reportsAnchorEl, setReportsAnchorEl] = useState(null);
  
  const { details } = props;

  const onCloseActions = () => setActionsAnchorEl(null);
  const onCloseReports = () => setReportsAnchorEl(null);

  return (
    <React.Fragment>
      <Button variant="outlined" color="primary" endIcon={<ArrowDropDownIcon />} disabled={!details} onClick={(ev: any) => setReportsAnchorEl(ev.currentTarget)}>Reports</Button>
      <Button variant="contained" color="primary" endIcon={<ArrowDropDownIcon />} disabled={!details} onClick={(ev: any) => setActionsAnchorEl(ev.currentTarget)}>Actions</Button>
      {!!details &&
        <Popover
          open={!!reportsAnchorEl}
          anchorEl={reportsAnchorEl}
          onClose={onCloseReports}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
        >
          <List disablePadding>
            <ListItem
              button
              onClick={() => {
                onCloseReports();
                props.onViewEmailActivity();
              }}
            >
              <ListItemText primary="View employee activity" />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                onCloseReports();
                props.onDownloadRecipientReport();
              }}
            >
              <ListItemText primary="Download recipient report" />
            </ListItem>
          </List>
        </Popover>
      }
      {!!details &&
        <Popover
          open={!!actionsAnchorEl}
          anchorEl={actionsAnchorEl}
          onClose={onCloseActions}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
        >
          <List disablePadding>
            <ListItem
              button
              onClick={() => {
                onCloseActions();
                props.onEdit();
              }}
            >
              <ListItemText primary="Edit newsletter" />
            </ListItem>
            {details.status === "Enabled" &&
              <ListItem
                button
                onClick={() => {
                  onCloseActions();
                  props.onDisable();
                }}
              >
                <ListItemText primary="Disable" />
              </ListItem>
            }
            {details.status === "Disabled" &&
              <ListItem
                button
                onClick={() => {
                  onCloseActions();
                  props.onEnable();
                }}
              >
                <ListItemText primary="Enable" />
              </ListItem>
            }
            <ListItem
              button
              onClick={() => {
                onCloseActions();
                props.onDelete();
              }}
            >
              <ListItemText primary="Delete" />
            </ListItem>
            <Divider light />
            <ListItem
              button
              onClick={() => {
                onCloseActions();
                props.onSendTestEmail();
              }}
            >
              <ListItemText primary="Send test email" />
            </ListItem>
          </List>
        </Popover>
      }
    </React.Fragment>
  );
}


interface ComponentProps {
  details: NewsletterDetails | undefined;
  onEdit: () => void;
  onDelete: () => void;
  onDisable: () => void;
  onDownloadRecipientReport: () => void;
  onSendTestEmail: () => void;
  onViewEmailActivity: () => void;
  onEnable: () => void;
}

export default DashboardActions;