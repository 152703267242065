import * as React from 'react';
import '../styles/InlineError.sass';

interface componentProps {
    props?: any
}

export default class InlineError extends React.Component<componentProps, {}> {

    public render() {
        return (
            <p className="error" { ...this.props.props }>
                { this.props.children }
            </p>
        )
    }
}
