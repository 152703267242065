import React from "react";


const NumberStat: React.FunctionComponent<ComponentProps> = props => {
  return (
    <div className="number-wrapper">
      <div className="number">
        {props.number}
      </div>
      <span className="title">
        {props.title}
      </span>
    </div>
  );
}

interface ComponentProps {
  number: number;
  title: string;
}

export default NumberStat;