import React, { useEffect, useState } from 'react';
import numeral from "numeral";

import { usersApi } from '../../../../api/instances';

import { ResponsivePie } from '@nivo/pie';

const GetLabel = (accountType: string) => {
    switch(accountType) {
        case "work":
            return "Work";
        case "google":
            return "Google";
        case "linkedin":
            return "LinkedIn";
        case "facebook":
            return "Facebook";
        case "apple":
            return "Apple";
        case "microsoft":
            return "Microsoft Personal";
        case "sparrow":
            return "Sparrow";
        default:
            return accountType;
    }
}

const UserPlatformChart = () => {
    interface AccountProvider {
        id: string,
        value: number
    }
    const [accountProviders, setAccountProviders] = useState<AccountProvider[]>([]);
    useEffect(() => {
        const getProviderBreakdown = async () => {
            const result = await usersApi.GetUserProviderBreakdown();
            Object.entries(result).forEach((entry) => {
                const [key, value] = entry;

                if (typeof value === 'number') {
                    setAccountProviders(accountProviders => [...accountProviders, {id: GetLabel(key), value: value} as AccountProvider]);
                }
            })
        }
        getProviderBreakdown();
    }, []);

    return (
        <React.Fragment>
            <ResponsivePie 
                colors={["#e6911a","#366531","#3b78ab","#453a6e","#36404a","#599fa0","#aa214e"]}
                data={accountProviders.map(d => ({ ...d, id: `${d.id}: ${numeral(d.value).format("0,0")}`}))}
                enableArcLabels={false}
                enableArcLinkLabels={false}
                isInteractive={false}
                padAngle={1}
                activeOuterRadiusOffset={8}
                margin={{ top: 40, right: 80, bottom: 20, left: 100 }}
                legends={[
                    {
                        anchor: 'left',
                        direction: 'column',
                        justify: false,
                        translateX: -100,
                        translateY: 0,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemsSpacing: 10,
                        symbolSize: 20,
                        itemDirection: 'left-to-right'
                    }
                ]}   
                arcLinkLabelsColor={{ from: 'color' }}
            />
        </React.Fragment>
    )
}

export default UserPlatformChart;