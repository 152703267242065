import React, { useMemo } from "react";
import SubjectIcon from "@mui/icons-material/Subject";
import TitleIcon from '@mui/icons-material/Title';
import SubtitlesIcon from '@mui/icons-material/Subtitles';

import { ITileButton } from "modules/common/buttons/tileButton";
import { SmartContentHome } from "modules/smartContent/smartContentHome";
import { POST_SMART_CONTENT_PAGES } from "./postSmartContentPanel";

export enum POST_SMART_CONTENT_TILE_BUTTON_IDX {
    GENERATE_IDEAS,
    GENERATE_TITLES,
    GENERATE_SUMMARIES,
    GENERATE_BODY_TEXT,
};

interface IPostSmartContentHomeProps {
    onClose: () => void;
    onClick: (buttonClicked: POST_SMART_CONTENT_TILE_BUTTON_IDX, nextPage: POST_SMART_CONTENT_PAGES) => void;
    onOpenReadabilitySettings: () => void;
    transition: boolean; // flag whether we should transition in the buttons - this allows us to control only transition on first render for example
    keywords?: string;
    highlights?: POST_SMART_CONTENT_TILE_BUTTON_IDX[]; // an array of button indexes that we should highlight via a transition
}

/**
 * Post specific wrapper around SmartContentHome
 */
const PostSmartContentHome: React.FunctionComponent<IPostSmartContentHomeProps> = ({
    highlights,
    keywords,
    transition,
    onClick,
    onOpenReadabilitySettings,
    onClose,
}) => {
    // only apply transition on first render
    const BASE_BUTTON: Partial<ITileButton> = {
        transition: transition ? "zoom" : undefined
    };

    const tileButtons = useMemo<ITileButton[]>(() => {
        return [
            {
                ...BASE_BUTTON,
                id: "smart-content-generate-ideas",
                label: "Generate ideas",
                onClick: () => {
                    let nextPage = keywords
                        ? POST_SMART_CONTENT_PAGES.IDEAS_LIST
                        : POST_SMART_CONTENT_PAGES.KEYWORDS;

                    onClick(POST_SMART_CONTENT_TILE_BUTTON_IDX.GENERATE_IDEAS, nextPage);
                },
                labelIcon: <img src="/images/icons/generate_ideas.svg" width="34" alt="Generate ideas" />,
                className: highlights?.some((element: POST_SMART_CONTENT_TILE_BUTTON_IDX) => element === POST_SMART_CONTENT_TILE_BUTTON_IDX.GENERATE_IDEAS)
                    ? "heart-beat"
                    : "",
            },
            {
                ...BASE_BUTTON,
                id: "smart-content-generate-titles",
                label: "Generate titles",
                onClick: () => {
                    let nextPage = keywords
                        ? POST_SMART_CONTENT_PAGES.TITLES_LIST
                        : POST_SMART_CONTENT_PAGES.KEYWORDS;

                    onClick(POST_SMART_CONTENT_TILE_BUTTON_IDX.GENERATE_TITLES, nextPage);
                },
                labelIcon: <TitleIcon style={{ fontSize: 34 }} htmlColor="#aaaaaa" />,
                delay: 400,
                className: highlights?.some((element: POST_SMART_CONTENT_TILE_BUTTON_IDX) => element === POST_SMART_CONTENT_TILE_BUTTON_IDX.GENERATE_TITLES)
                    ? "heart-beat"
                    : "",
            },
            {
                ...BASE_BUTTON,
                id: "smart-content-generate-summaries",
                label: "Generate summaries",
                onClick: () => {
                    let nextPage = keywords
                        ? POST_SMART_CONTENT_PAGES.SUMMARIES_LIST
                        : POST_SMART_CONTENT_PAGES.KEYWORDS;

                    onClick(POST_SMART_CONTENT_TILE_BUTTON_IDX.GENERATE_SUMMARIES, nextPage);
                },
                labelIcon: <SubtitlesIcon style={{ fontSize: 34 }} htmlColor="#aaaaaa" />,
                delay: 800,
                className: highlights?.some((element: POST_SMART_CONTENT_TILE_BUTTON_IDX) => element === POST_SMART_CONTENT_TILE_BUTTON_IDX.GENERATE_SUMMARIES)
                    ? "heart-beat"
                    : "",
            },
            {
                ...BASE_BUTTON,
                id: "smart-content-generate-body",
                label: "Generate body text",
                onClick: () => {
                    let nextPage = keywords
                        ? POST_SMART_CONTENT_PAGES.BODY_LIST
                        : POST_SMART_CONTENT_PAGES.KEYWORDS;

                    onClick(POST_SMART_CONTENT_TILE_BUTTON_IDX.GENERATE_BODY_TEXT, nextPage);
                },
                labelIcon: <SubjectIcon style={{ fontSize: 34 }} htmlColor="#aaaaaa" />,
                delay: 1200,
                className: highlights?.some((element: POST_SMART_CONTENT_TILE_BUTTON_IDX) => element === POST_SMART_CONTENT_TILE_BUTTON_IDX.GENERATE_BODY_TEXT)
                    ? "heart-beat"
                    : "",
            },
        ];
    }, [highlights, keywords, onClick, BASE_BUTTON]);

    return (
        <SmartContentHome
            onOpenReadabilitySettings={onOpenReadabilitySettings}
            onClose={onClose}
            buttons={tileButtons}
        />
    );
}

export { PostSmartContentHome };
