import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";


import "./typeBanner.sass";


const icoRestricted: string = "/images/icons/restricted_ico.svg";


interface IBanner {
  color: string;
  icon: string;
  text: string;
  description: string;
  urlDescription?: string;
}

type BannerTypes = "restricted";

type Banners = { [bannerType in BannerTypes]: IBanner; };

const BannerText: React.FunctionComponent<{full?: boolean, expanded: boolean, onClick: (e: any) => void, style?: React.CSSProperties}> = (props) => {
  return (
    <div onClick={props.full ? undefined : props.onClick} className={`text ${props.expanded ? "expand" : ""}`}>
      <div style={props.style}>
        {props.children}
        {props.full &&
          <CloseIcon onClick={props.onClick} className="clear-icon" />
        }
      </div>
    </div>
  );
};

const ExpandIcon: React.FunctionComponent<{full?: boolean, expanded: boolean, onClick: (e: any) => void, style?: React.CSSProperties}> = (props) => {
  return (
    <div onClick={props.onClick} style={props.style} className={`icon ${props.expanded ? "expanded" : "collapsed"}`}>
      {props.children}
      {props.full &&
        <ChevronRightIcon className="expand-icon" />
      }
    </div>
  );
};

const TypeBanner: React.FunctionComponent<{className?: string}> = (props) => {
  return (
    <div className={`type-banner ${props.className || ""}`}>
      {props.children}
    </div>
  );
};


const Banner: React.FunctionComponent<{banner: IBanner, full?: boolean, description?: JSX.Element, expanded: boolean, onClick: (e: any) => void, className?: string}> = (props) => {
  const { banner, full, description, expanded, onClick, className } = props;

  return (
    <TypeBanner className={className || ""}>
      <ExpandIcon full={full} expanded={expanded} onClick={onClick} style={{ backgroundColor: banner.color }}>
        <img src={banner.icon} alt="event type banner" />
      </ExpandIcon>
      <BannerText full={full} expanded={expanded} onClick={onClick} style={{ backgroundColor: banner.color }}>
        <div>
          {!full
            ? banner.text
            : description || banner.description
          }
        </div>
      </BannerText>
    </TypeBanner>
  );
}


class EventTypeBanner extends React.Component<PropsWithRedux, ComponentState> {
  constructor(props: PropsWithRedux) {
    super(props);

    this.state = {
      expandRestricted: false
    };
  }

  public componentDidMount() {
    this.onSetBannerExpansions();
  }

  public render() {
    if (!this.props.settings)
      return (null);

    const { settings } = this.props;
    const { specialtyPostColors } = settings;

    const banners: Banners = {
      restricted: {
        color: (specialtyPostColors && specialtyPostColors.restricted) || "#b72026",
        icon: icoRestricted,
        text: this.onGetTranslatedText("restrictedPost") || "Restricted",
        description: this.onGetTranslatedText("restrictedBannerDescription") || "This event is exclusive to specific audiences."
      }
    };

    return (
      <div style={{ position: "absolute" }} className={`type-banners ${this.props.full ? "full" : ""}`}>
        {this._restrictedBanner(banners.restricted)}
      </div>
    );
  }

  private _restrictedBanner = (banner: IBanner) => {
    if (!this.props.isRestricted || !this.props.settings.bannerVisibility.showRestrictedBanner) {
      return (null);
    }

    return (
      <Banner
        banner={banner}
        full={this.props.full}
        expanded={this.state.expandRestricted}
        onClick={this.onToggleExpandRestricted}
        className="restricted" />
    );
  }

  private onGetTranslatedText = (field: string, defaultLCID: string = "en-us") => {
    const { settings, lcid } = this.props;
    const { translatedContent } = settings;

    if (!translatedContent || (!translatedContent[lcid] && !translatedContent[defaultLCID]))
      return "";
    
    return translatedContent[lcid] ? translatedContent[lcid][field] : translatedContent[defaultLCID][field];
  }

  private onSetBannerExpansions = () => {
    const expandRestricted: boolean = this.props.settings.bannerVisibility.showRestrictedBanner && !!this.props.isRestricted;

    this.setState({
      expandRestricted
    });
  }

  private onToggleExpandRestricted = (e: any) => {
    e.stopPropagation();
    if (this.state.expandRestricted)
      this.setState({ expandRestricted: false });
    else
      this.setState({
        expandRestricted: true
      });
  }
}


interface ComponentProps {
  lcid: string;
  eventType: string;
  full?: boolean;
  isRestricted?: boolean;
}

interface ComponentState {
  expandRestricted: boolean;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    lcidMappings: state.resources.lcidMappings,
    settings: !!state.settings.clientSettings.id ? state.settings.clientSettings : state.settings.tenantSettings // Allow preview from authoring
  })
);
type PropsWithRedux = ConnectedProps<typeof connector>;
export default connector(EventTypeBanner);