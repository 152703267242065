import * as React from 'react';
import { useState } from 'react';
import { Comment as IComment, ReplyDetails } from '../../../models';
import { Button } from '@mui/material';
import Comment from './comment';
import ReplyTextbox from './replyTextbox';


interface ComponentProps {
    parentComment: IComment;
    postId: string;
    fetchAllReplies: (parentId: string) => any;
    onStar: (toStarId: string) => Promise<void>;
    onDestar: (toStarId: string) => Promise<void>;
    editingComment: boolean;
    toggleEdit: (isEditing: boolean) => void;
}

const ReplySection: React.FunctionComponent<ComponentProps> = (props) => {
    const {parentComment, postId, fetchAllReplies, onStar, onDestar, editingComment, toggleEdit} = props;

    const [replyDetails, setReplyDetails] = useState<ReplyDetails>({name: "", id: "", time: ""});
    const [replyTextboxVisible, setReplyTextboxVisible] = useState(false);
    const [loadingReplies, setLoadingReplies] = useState(false);
    const [allRepliesVisible, setAllRepliesVisible] = useState(false);
    const [allReplies, setAllReplies] = useState<IComment[]>([]);

    const repliesLoadable = (parentComment.replyCount ?? 0) > 2 && (allReplies.length === 0);

    const onReplyToChild = (comment: IComment) => {
        let newReply = {name: comment.author.name, id: comment.author.id, time: new Date().toISOString()};

        setReplyDetails(newReply);
        setReplyTextboxVisible(true);
    }

    const toggleAllRepliesForParent = async () => {
        setLoadingReplies(true);

        let parentReplies = await fetchAllReplies(parentComment.id);
        setAllRepliesVisible(true);
        setAllReplies(parentReplies);
        setLoadingReplies(false);
    }

    const onCancelReply = () => {
        setReplyTextboxVisible(false);
    }

    return (
        <div style={{marginTop: "20px"}}>
            <Comment 
                key={parentComment.id} 
                {...props} 
                comment={parentComment} 
                onStar={onStar} 
                onDestar={onDestar}
                editingComment={editingComment} 
                toggleEdit={toggleEdit} 
                onReply={() => onReplyToChild(parentComment)}
            />

            <div style={{paddingLeft: "8.3%"}}>
                {allRepliesVisible &&
                    <div>
                    {allReplies.map(reply => 
                        <Comment 
                            key={reply.id} 
                            {...props} 
                            comment={reply} 
                            onStar={(id) => props.onStar(id)} 
                            onDestar={(id) => props.onDestar(id)}
                            onReply={() => onReplyToChild(reply)}
                            confirmCallback={toggleAllRepliesForParent}
                            isReply
                        />
                    )}
                    </div>
                }

                {parentComment.repliesPreview.length > 0 && !allRepliesVisible &&
                    <div>
                        {parentComment.repliesPreview.map(reply => 
                            <Comment 
                                key={reply.id} 
                                {...props} 
                                comment={reply} 
                                onStar={(id) => props.onStar(id)} 
                                onDestar={(id) => props.onDestar(id)}
                                onReply={() => onReplyToChild(reply)}
                                isReply
                            />
                        )}
                    </div>
                }

                {repliesLoadable &&
                    <Button 
                        color='info' 
                        style={{textTransform: "none", marginLeft: "-1%", marginBottom: "18px"}} 
                        onClick={toggleAllRepliesForParent}
                        disabled={loadingReplies}
                    >
                        Load more replies
                    </Button>
                }

                {replyTextboxVisible && 
                    <div key={parentComment.id+"-reply"}>
                        <ReplyTextbox
                            preview={false}
                            placeholder={parentComment.repliesPreview.length > 0 ? "Leave a reply" : "Be the first to reply"}
                            postId={postId}
                            parentCommentId={parentComment.id} 
                            replyDetails={replyDetails}
                            repliesExpanded={allRepliesVisible}
                            loadReplies={toggleAllRepliesForParent}
                            onCancelReply={onCancelReply}
                        /> 
                    </div>
                }
            </div>
        </div>
    )
}

export default ReplySection;