import { ApiRequest } from "./network";
import { SparrowApi } from "api/network/adapters/SparrowApi";
import { AxiosPromise } from "axios";
import { ExternalPostSource, IMobileConfig, TenantSettings, TranslatableLanguage } from "modules/settings/models";

export class SettingsApi {
    constructor(private _sparrowApi: SparrowApi) {}

    public GetTenantSettings = (): ApiRequest => {
        return new ApiRequest(this._sparrowApi.TenantRequest().get("/{tenant}/adminapi/v1/tenantSettings"));
    };

    public GetTenantAttributes = (): ApiRequest => {
        return new ApiRequest(this._sparrowApi.TenantRequest().get("/{tenant}/adminapi/v1/tenantattributes"));
    };

    public GetAvailableTimeZones = (): ApiRequest => {
        return new ApiRequest(this._sparrowApi.TenantRequest().get("/{tenant}/adminapi/v1/tenantSettings/timeZones"));
    };

    public DeleteTenantSettingsExternalPostSource = (id: string): ApiRequest => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().delete("/{tenant}/adminapi/v1/externalPostSources/{id}", { params: { id } })
        );
    };
    public UpdateTenantSettingsExternalPostSource = (externalPostSource: ExternalPostSource): ApiRequest => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().put("/{tenant}/adminapi/v1/externalPostSources/{id}", externalPostSource, {
                params: { id: externalPostSource.id },
            })
        );
    };

    public AddTenantSettingsExternalPostSource = (externalPostSource: ExternalPostSource): ApiRequest => {
        return new ApiRequest(this._sparrowApi.TenantRequest().post("/{tenant}/adminapi/v1/externalPostSources", externalPostSource));
    };

    public UpdateTenantSettings = (tenantSettings: TenantSettings): ApiRequest => {
        return new ApiRequest(this._sparrowApi.TenantRequest().post("/{tenant}/adminapi/v1/tenantsettings", tenantSettings));
    }

    public GetLanguageTranslation = (content: TranslatableLanguage): ApiRequest => {
        return new ApiRequest(this._sparrowApi.TenantRequest().post("/{tenant}/api/v1/cognitiveservices/translatortext/translateTenantLabels", content));
    }

    public getMobileConfig = (): AxiosPromise<IMobileConfig> => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().get(`/{tenant}/api/v1/config/mobile`)
        ).catch((err) => {
            throw err;
        });
    }
}

