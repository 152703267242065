import React, { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";

import Tutorial, { Slide } from "modules/common/components/tutorial";

import Cookies from "utils/cookie";
import { Link } from "@mui/material";

const TUTORIAL_SLIDES: Slide[] = [
  {
    title: "Users, Roles and Elevated Permissions",
    content: (
      <div>
      Manage and specify permissions granted to each member of your internal communications teams—on top of managing their roles and profiles.
      <br/><br/>
      Start with our new Content Creation permissions, and ensure the right teams are reaching the right people with the right message.
      </div>
    )
  },
  {
    title: "Assign specific authoring permissions",
    content: (
      <div>
      Decide which Authors can publish to certain topics, and which authors can reach certain audiences.
      <br/><br/>
      Select the user and assign them with an Author role. Next, specify which topics the user will be able to use when creating and publishing content.
      </div>
    )
  },
  {
    title: "After assigning permissions",
    content: (
      <div>
      Setting authoring permissions and assigning specific topics will hide all other topics from an Author when they are creating new content.
      <br/><br/>
      The Author will be able to create and publish content using only topics they have access to, and will be able to target audiences attached to the topics they used. Learn more at <Link href="https://support.sparrowconnected.com/en/understanding-roles-and-permissions" target="_blank" rel="noopener noreferrer">support.sparrowconnected.com</Link>.
      </div>
    )
  },
];

const isFirstVisit = (): boolean => {
  if (Cookies.get("users-tutorial-visited") === "true")
    return false;

  Cookies.setWithOpts("users-tutorial-visited", "true", {expires: 365});
  return true;
}

const UsersTutorial: React.FunctionComponent<PropsWithRedux> = props => {
  const [showTutorial] = useState(isFirstVisit());

  return (
    <Tutorial openOnLoad={showTutorial} slides={TUTORIAL_SLIDES} imageSrc="/images/tutorials/user-management/management" />
  );
}

interface ComponentProps {}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
  })
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(UsersTutorial);