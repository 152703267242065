import React from "react";
import { connect, ConnectedProps } from "react-redux";
import * as actions from "../../actionCreator";
import { GlobalApplicationState } from "globalApplicationState";
import { push } from "react-router-redux";
import moment from "moment";

import LoadingOverlay from "modules/common/components/loadingOverlay";

import { 
    Capacity as ICapacity, 
    Event, 
    EventTimes as IEventTimes, 
    EventType as IEventType, 
    Notifications as INotifications, 
    Questions as IQuestions, 
    Tag, 
    TranslatedContent as ITranslatedContent, 
    ValidationChecks 
} from "../../models";

import Author from "modules/common/components/authoring/author";
import ExpiryTime from "modules/common/components/authoring/expiryTime";
import FileAttachments from "modules/common/components/authoring/fileAttachments";
import HeroBanner from "modules/common/components/authoring/heroBanner/heroBanner";
import Notifications from "modules/common/components/authoring/notifications";
import PublishTime from "modules/common/components/authoring/publishTime";
import Reminders from "modules/common/components/authoring/reminders";
import Tags from "modules/common/components/authoring/tags";
import TranslatedContent from "modules/common/components/authoring/translatedContent";
import Validator from "modules/common/components/authoring/validator";

import UpdateInPersonCapacityConfirmation from "../confirmations/updateInPersonCapacity";
import UpdateOnlineCapacityConfirmation from "../confirmations/updateOnlineCapacity";

import Capacity from "./components/capacity";
import CutoffTime from "./components/cutoffTime";
import EventTimes from "./components/eventTimes";
import EventType from "./components/eventType";
import Location from "./components/location";
import Questions from "./components/questions";

import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Collapse from "@mui/material/Collapse";
import Hidden from "@mui/material/Hidden";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";

import AssignmentIcon from "@mui/icons-material/Assignment";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PersonIcon from "@mui/icons-material/Person";
import PhotoIcon from "@mui/icons-material/Photo";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import { AttachedFile } from "modules/gallery";
import { CroppableImage } from "modules/common/components/authoring/models";
import { 
    getDefaultNotificationSetting, 
    isAnyTenantEventNotificationsEnabled, 
    isAnyTenantRemindersEnabled 
} from "utils/notificationsHelper";
import { NotificationEvents } from "modules/messaging";
import { ICustomCssModelv1 } from "api/files";
import { mentionCheck } from "utils/regexUserValidation";

class EventEditor extends React.Component<PropsWithRedux, ComponentState> {
    constructor(props: PropsWithRedux) {
        super(props);

        const { currentUser, event } = props;
        const availableLcids: string[] = !!event.translatedContent ? Object.keys(event.translatedContent) : [];

        this.state = {
            hasNotifications: !!event.notifications && (event.notifications.emailOnPublish !== "none" || event.notifications.mobileOnPublish !== "none" || event.notifications.smsOnPublish !== "none" || event.notifications.teamsOnPublish !== "none"),
            hasReminders: !!event.notifications && !!event.notifications.reminders && !!event.notifications.reminders.length,
            showAttachedFiles: !!event.fileAttachments && !!event.fileAttachments.length,
            showAuthor: !!currentUser && ((event.author !== `${currentUser.firstName} ${currentUser.lastName}`) || (event.authorEmail !== currentUser.email) || !(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(event.authorEmail ?? "")),
            showHeroBanner: (!!event.image && !!event.image.id) || (!!event.attachedContent && !!event.attachedContent.length),
            showMoreOptions: false,
            showNotificationSettings: props.showNotificationSettings,
            showQuestions: !!event.questions && !!Object.keys(event.questions).length,
            showReminderSettings: props.showReminderSettings,
            showSummary: !!availableLcids.find((lcid) => !!event.translatedContent![lcid].description),
            showTabCommandsList: false,
            showUpdateInPersonCapacityConfirmation: false,
            showUpdateOnlineCapacityConfirmation: false,
            hasMention: false
        };
    }

    public componentDidMount() {
        moment.locale("en");
    }

    public componentDidUpdate(prevProps: PropsWithRedux) {
        if (this.props.showNotificationSettings && this.props.showNotificationSettings !== prevProps.showNotificationSettings)
            this.onShowNotificationSettings();

        if (this.props.showReminderSettings && this.props.showReminderSettings !== prevProps.showReminderSettings)
            this.onShowReminderSettings();
    }

  public render() {
    const { event, notificationSettings } = this.props;

    const notificationSettingsEnabled = !!event.notifications && isAnyTenantEventNotificationsEnabled(notificationSettings)
    const reminderSettingsEnabled = !!event.notifications && !!event.notifications.reminders && isAnyTenantRemindersEnabled(notificationSettings);

        return (
            <div>
                {this.getCommandBar()}
                <div className="editors-container">
                    <div className="main-editor">
                        <HeroBanner
                            show={this.state.showHeroBanner}
                            attachedContent={event.attachedContent}
                            image={event.image}
                            optional
                            onChangeAttachedContent={this.onChangeAttachedContent}
                            onChangeImage={this.onChangeImage}
                            onRemove={this.onRemoveHeroBanner}
                            onRemoveImage={this.onRemoveImage}
                        />
                        <Author
                            show={this.state.showAuthor}
                            author={event.author}
                            authorEmail={event.authorEmail}
                            type="Organizer"
                            onChangeAuthor={this.onChangeAuthor}
                            onChangeAuthorEmail={this.onChangeAuthorEmail}
                            onRemove={this.onRemoveAuthor}
                        />
                        <TranslatedContent
                            customCss={this.props.customCss}
                            showSummary={this.state.showSummary}
                            id={event.id}
                            translatedContent={event.translatedContent}
                            type="event"
                            getContentAnalysis={this.props.getContentAnalysis}
                            getDraftTranslation={this.props.getDraftTranslation}
                            onChange={this.onChangeTranslatedContent}
                            onRemoveSummary={this.onRemoveSummary}
                        />
                        <FileAttachments show={this.state.showAttachedFiles} fileAttachments={event.fileAttachments} onChange={this.onChangeFileAttachments} onRemove={this.onRemoveAttachedFiles} />
                        <Questions show={this.state.showQuestions} questions={event.questions} onChange={this.onChangeQuestion} onRemove={this.onRemoveQuestions} />
                    </div>
                    <div className="settings-editor">
                        <Paper elevation={0} className="section-card">
                            <Validator validationChecks={this.props.validationChecks}>
                                {event.eventType === "informational" &&
                                    <div className="progress-list-info">
                                        <span className="emphasis">Event Type (Informational)</span> is set to default. Change this setting below.
                                    </div>
                                }
                                {!!event.publishTime && (moment(event.publishTime) < moment()) &&
                                    <div className="progress-list-info">
                                        Take note that the <span className="emphasis">Publish on</span> date is set to a past date.
                                    </div>
                                }
                            </Validator>
                        </Paper>
                        <Paper elevation={0} className="section-card">
                            <EventType eventType={event.eventType} onChange={this.onChangeEventType} />
                            <div className="section-card-subcontent">
                                <Collapse in={event.eventType !== "informational"}>
                                    <CutoffTime cutoffTime={event.cutoffTime} eventType={event.eventType} disabled={!event.respondingEnabled} onChange={this.onChangeCutoffTime} />
                                    <Capacity capacity={event.capacity!} disabled={!event.respondingEnabled} onChange={this.onChangeCapacity} />
                                </Collapse>
                            </div>
                        </Paper>
                        <Paper elevation={0} className="section-card">
                            <Tags tags={event.tags!} onChange={this.onChangeTags} hasMention={this.state.hasMention} />
                        </Paper>
                        <Paper elevation={0} className="section-card date-and-location">
                            <div className="section-card-heading">Event Date</div>
                            <div className="section-card-subcontent">
                                <EventTimes eventTimes={event.eventTimes} onChange={this.onChangeEventTimes} />
                            </div>
                            <div className="section-card-heading">Event Location</div>
                            <div className="section-card-subcontent">
                                <Location location={event.location} locationLink={event.locationLink} locationLinkLabel={event.locationLinkLabel} onChangeLocation={this.onChangeLocation} onChangeLocationLink={this.onChangeLocationLink} onChangeLocationLinkLabel={this.onChangeLocationLinkLabel} clearLocationLink={this.clearLocationLink} />
                            </div>
                        </Paper>
                        <Paper elevation={0} className="section-card">
                            {this.state.showMoreOptions
                                ? <Button variant="text" color="primary" endIcon={<ExpandLessIcon />} onClick={this.onShowLessOptions}>
                                    Show less options
                                </Button>
                                : <Button variant="text" color="primary" endIcon={<ExpandMoreIcon />} onClick={this.onShowMoreOptions}>
                                    Show more options
                                </Button>
                            }
                        </Paper>
                        <Collapse in={this.state.showMoreOptions}>
                            {notificationSettingsEnabled &&
                                <Paper elevation={0} className="section-card">
                                    <div className="section-card-heading">
                                        <div>Notify subscribers upon publishing</div>
                                        <Switch
                                            color="primary"
                                            checked={this.state.hasNotifications}
                                            onChange={this.onSetNotifications}
                                        />
                                    </div>
                                    <Collapse in={this.state.hasNotifications}>
                                        <Button variant="text" color="primary" disableRipple onClick={this.onShowNotificationSettings} className="notification-options">Edit notification upon publishing</Button>
                                    </Collapse>
                                </Paper>
                            }
                            {reminderSettingsEnabled &&
                                <Paper elevation={0} className="section-card">
                                    <div className="section-card-heading">
                                        <div>Send reminder if event is unread</div>
                                        <Switch
                                            color="primary"
                                            checked={this.state.hasReminders}
                                            onChange={this.onToggleReminderSettings}
                                        />
                                    </div>
                                    <Collapse in={this.state.hasReminders}>
                                        <Button variant="text" color="primary" disableRipple onClick={this.onShowReminderSettings} className="notification-options">Edit reminders</Button>
                                    </Collapse>
                                </Paper>
                            }
                            <Paper elevation={0} className="section-card">
                                <PublishTime publishTime={event.publishTime} onChange={this.onChangePublishTime} />
                                <ExpiryTime expiryTime={event.expiryTime} onChange={this.onChangeExpiryTime} />
                            </Paper>
                        </Collapse>
                    </div>
                </div>
                <Notifications show={this.state.showNotificationSettings} isPreviouslyPublished={!!event.isPreviouslyPublished} notifications={event.notifications} type="event" onChangeNotifications={this.onChangeNotifications} onClose={this.onHideNotificationSettings} />
                <Reminders show={this.state.showReminderSettings} isPreviouslyPublished={!!event.isPreviouslyPublished} notifications={event.notifications} type="event" onChangeNotifications={this.onChangeNotifications} onClose={this.onHideReminderSettings} />
                <UpdateInPersonCapacityConfirmation show={this.state.showUpdateInPersonCapacityConfirmation} onConfirm={this.onRemoveInPersonCapacity} onCancel={this.onHideUpdateInPersonCapacityConfirmation} />
                <UpdateOnlineCapacityConfirmation show={this.state.showUpdateOnlineCapacityConfirmation} onConfirm={this.onRemoveOnlineCapacity} onCancel={this.onHideUpdateOnlineCapacityConfirmation} />
                <LoadingOverlay absolute={true} show={this.props.isFetching} />
            </div>
        );
    }


    private getCommandBar = (): JSX.Element => {
        const { event } = this.props;

        return (
            <Paper square className="tab-root">
                <div className="tab-commands">
                    <Button variant="text" color="primary" startIcon={<PhotoIcon />} disabled={this.state.showHeroBanner} onClick={this.onShowHeroBanner}>Insert Hero Banner</Button>
                    <Hidden mdDown>
                        <Button variant="text" color="primary" startIcon={<PlaylistAddIcon />} disabled={this.state.showSummary} onClick={this.onShowSummary}>Add Summary</Button>
                    </Hidden>
                    <Hidden lgDown>
                        <Button variant="text" color="primary" startIcon={<PersonIcon />} disabled={this.state.showAuthor} onClick={this.onShowAuthor}>Custom Organizer</Button>
                    </Hidden>
                    <Hidden xlDown>
                        <Button variant="text" color="primary" startIcon={<AttachFileIcon />} disabled={this.state.showAttachedFiles} onClick={this.onShowAttachedFiles}>Attach Files</Button>
                        {event.eventType !== "informational" &&
                            <Button variant="text" color="primary" startIcon={<AssignmentIcon />} disabled={this.state.showQuestions} onClick={this.onShowQuestions}>Add Question</Button>
                        }
                    </Hidden>
                    <Hidden xlUp>
                        <IconButton onClick={this.onShowTabCommandsList} size="large">
                            <MoreHorizIcon color="primary" />
                        </IconButton>
                        {this.state.showTabCommandsList &&
                            <ClickAwayListener onClickAway={this.onHideTabCommandsList}>
                                <List className="tab-commands-list">
                                    <Hidden mdUp>
                                        <ListItem button disabled={this.state.showSummary} onClick={this.onShowSummary}>
                                            <PlaylistAddIcon color={this.state.showSummary ? "disabled" : "primary"} />
                                            <ListItemText primary="Add Summary" />
                                        </ListItem>
                                    </Hidden>
                                    <Hidden lgUp>
                                        <ListItem button disabled={this.state.showAuthor} onClick={this.onShowAuthor}>
                                            <PersonIcon color={this.state.showAuthor ? "disabled" : "primary"} />
                                            <ListItemText primary="Custom Organizer" />
                                        </ListItem>
                                    </Hidden>
                                    <ListItem button disabled={this.state.showAttachedFiles} onClick={this.onShowAttachedFiles}>
                                        <AttachFileIcon color={this.state.showAttachedFiles ? "disabled" : "primary"} />
                                        <ListItemText primary="Attach Files" />
                                    </ListItem>
                                    {event.eventType !== "informational" &&
                                        <ListItem button disabled={this.state.showQuestions} onClick={this.onShowQuestions}>
                                            <AssignmentIcon color={this.state.showQuestions ? "disabled" : "primary"} />
                                            <ListItemText primary="Add Question" />
                                        </ListItem>
                                    }
                                </List>
                            </ClickAwayListener>
                        }
                    </Hidden>
                </div>
            </Paper>
        );
    }


    private onChangeEvent = (value: Partial<Event>) => {
        this.props.onChangeEvent(value);
    }


    private onChangeAttachedContent = (attachedContent: AttachedFile[]) => {
        this.onChangeEvent({ attachedContent });
    }

    private onChangeAuthor = (author: string) => {
        this.onChangeEvent({ author });
    }

    private onChangeAuthorEmail = (authorEmail: string) => {
        this.onChangeEvent({ authorEmail });
    }

    private onChangeCapacity = (capacity: ICapacity) => {
        this.onChangeEvent({ capacity });
    }

    private onChangeCutoffTime = (cutoffTime: string) => {
        this.onChangeEvent({ cutoffTime });
    }

    private onChangeEventTimes = (eventTimes: IEventTimes) => {
        this.onChangeEvent({ eventTimes });
    }

    private onChangeEventType = (eventType: IEventType) => {
        if (eventType === "informational") {
            this.onChangeEvent({
                capacity: { InPerson: -1, Online: -1, Waitlist: -1, NotAttending: -1 },
                cutoffTime: undefined,
                eventType,
                questions: undefined,
                respondingEnabled: false
            });
            this.setState({ showQuestions: false });
        } else {
            const eventStartTime: string = Object.keys(this.props.event.eventTimes!)[0];
            const cutoffTime: string = moment(eventStartTime).startOf("day").toISOString();
            this.onChangeEvent({ eventType, cutoffTime, respondingEnabled: true });
        }
    }

    private onChangeExpiryTime = (expiryTime: string) => {
        this.onChangeEvent({ expiryTime });
    }

    private onChangeFileAttachments = (fileAttachments: AttachedFile[]) => {
        this.onChangeEvent({ fileAttachments });
    }

    private onChangeImage = (image: CroppableImage) => {
        this.onChangeEvent({ image });
    }

    private onChangeLocation = (location: string) => {
        const { event } = this.props;
        const showConfirmation: boolean = !!event.location && !location && event.eventType !== "informational" && !!event.capacity && event.capacity.InPerson !== 0;
        this.onChangeEvent({ location });
        if (showConfirmation)
            this.setState({ showUpdateInPersonCapacityConfirmation: true });
    }

    private onChangeLocationLink = (locationLink: string) => {
        const { event } = this.props;
        const showConfirmation: boolean = !!event.locationLink && !locationLink && event.eventType !== "informational" && !!event.capacity && event.capacity.Online !== 0;
        this.onChangeEvent({ locationLink });
        if (showConfirmation)
            this.setState({ showUpdateOnlineCapacityConfirmation: true });
    }

    private onChangeLocationLinkLabel = (locationLinkLabel: string) => {
        const { event } = this.props;
        const showConfirmation: boolean = !!event.locationLinkLabel && !locationLinkLabel && event.eventType !== "informational" && !!event.capacity && event.capacity.Online !== 0;
        this.onChangeEvent({ locationLinkLabel });
        if (showConfirmation)
            this.setState({ showUpdateOnlineCapacityConfirmation: true });
    }

    private clearLocationLink = () => {
        this.onChangeEvent({ locationLinkLabel: "", locationLink: "" });
    }

    private onChangeNotifications = (notifications: INotifications) => {
        this.onChangeEvent({ notifications });

        this.setState({
            hasNotifications: !(notifications.emailOnPublish === "none" && notifications.mobileOnPublish === "none" && notifications.smsOnPublish === "none" && notifications.teamsOnPublish === "none"),
            hasReminders: !!notifications.reminders && !!notifications.reminders.length
        });
    }

    private onChangePublishTime = (publishTime: string) => {
        this.onChangeEvent({ publishTime });
    }

    private onChangeQuestion = (questions: IQuestions) => {
        this.onChangeEvent({ questions });
    }

    private onChangeTags = (tags: Tag[]) => {
        this.onChangeEvent({ tags });
    }

    private onChangeTranslatedContent = (translatedContent: ITranslatedContent) => {
        var toTest = JSON.stringify(translatedContent);
        this.setState({ hasMention: mentionCheck.test(toTest) });

        this.onChangeEvent({ translatedContent });
    }

    private onHideNotificationSettings = () => {
        this.setState({ showNotificationSettings: false });
    }

    private onHideReminderSettings = () => {
        this.setState({ showReminderSettings: false });
    }

    private onHideTabCommandsList = () => {
        this.setState({ showTabCommandsList: false });
    }

    private onHideUpdateInPersonCapacityConfirmation = () => {
        this.setState({ showUpdateInPersonCapacityConfirmation: false });
    }

    private onHideUpdateOnlineCapacityConfirmation = () => {
        this.setState({ showUpdateOnlineCapacityConfirmation: false });
    }

    private onRemoveAttachedFiles = () => {
        this.setState({ showAttachedFiles: false });
        this.onChangeEvent({ fileAttachments: [] });
    }

    private onRemoveAuthor = () => {
        const { currentUser } = this.props;

        this.setState({ showAuthor: false });
        this.onChangeEvent({ author: currentUser ? `${currentUser.firstName} ${currentUser.lastName}` : "", authorEmail: currentUser ? currentUser.email : "" });
    }

    private onRemoveHeroBanner = () => {
        this.setState({ showHeroBanner: false });
        this.onChangeEvent({ attachedContent: [], image: undefined });
    }

    private onRemoveImage = () => {
        this.onChangeEvent({ image: undefined });
    }

    private onRemoveInPersonCapacity = () => {
        this.onHideUpdateInPersonCapacityConfirmation();
        this.onChangeEvent({ capacity: { ...this.props.event.capacity!, InPerson: 0 } });
    }

    private onRemoveOnlineCapacity = () => {
        this.onHideUpdateOnlineCapacityConfirmation();
        this.onChangeEvent({ capacity: { ...this.props.event.capacity!, Online: 0 } });
    }

    private onRemoveQuestions = () => {
        this.setState({ showQuestions: false });
        this.onChangeEvent({ questions: undefined });
    }

    private onRemoveSummary = () => {
        this.setState({ showSummary: false });
    }

    private onSetNotifications = (ev, checked: boolean) => {
        if (!checked) {
            this.onChangeEvent({
                notifications: {
                    ...this.props.event.notifications!,
                    emailOnPublish: "none",
                    mobileOnPublish: "none",
                    smsOnPublish: "none",
                    teamsOnPublish: "none"
                }
            });
            this.setState({ hasNotifications: false });
        } else {
            const { defaultEventSettings } = this.props.notificationSettings;

            this.onChangeEvent({
                notifications: {
                    ...this.props.event.notifications!,
                    emailOnPublish: getDefaultNotificationSetting(NotificationEvents.EventPublishedEmail, this.props.notificationSettings),
                    mobileOnPublish: getDefaultNotificationSetting(NotificationEvents.EventPublishedMobile, this.props.notificationSettings),
                    smsOnPublish: getDefaultNotificationSetting(NotificationEvents.EventPublishedSMS, this.props.notificationSettings),
                    teamsOnPublish: getDefaultNotificationSetting(NotificationEvents.EventPublishedTeams, this.props.notificationSettings)
                }
            });
            this.setState({ hasNotifications: true });
            this.onShowNotificationSettings();
        }
    }

    private onShowAttachedFiles = () => {
        this.setState({ showAttachedFiles: true, showTabCommandsList: false });
    }

    private onShowAuthor = () => {
        this.setState({ showAuthor: true, showTabCommandsList: false });
    }

    private onShowHeroBanner = () => {
        this.setState({ showHeroBanner: true });
    }

    private onShowLessOptions = () => {
        this.setState({ showMoreOptions: false });
    }

    private onShowMoreOptions = () => {
        this.setState({ showMoreOptions: true });
    }

    private onShowNotificationSettings = () => {
        this.setState({ showNotificationSettings: true });
    }

    private onShowQuestions = () => {
        this.setState({ showQuestions: true, showTabCommandsList: false });
    }

    private onShowReminderSettings = () => {
        this.setState({ showReminderSettings: true });
    }

    private onShowSummary = () => {
        this.setState({ showSummary: true, showTabCommandsList: false });
    }

    private onShowTabCommandsList = () => {
        this.setState({ showTabCommandsList: true });
    }

    private onToggleReminderSettings = (ev, checked: boolean) => {
        if (!checked) {
            this.onChangeEvent({
                notifications: {
                    ...this.props.event.notifications!,
                    reminders: []
                }
            });
            this.setState({ hasReminders: false });
        } else {
            this.onChangeEvent({
                notifications: {
                    ...this.props.event.notifications!,
                    reminders: this.props.notificationSettings.defaultEventSettings?.reminders || [{ channel: "inherit", delay: this.props.notificationSettings.defaultPostReminder || 0, range: "subscribers" }]
                }
            });
            this.setState({ hasReminders: true });
            this.onShowReminderSettings();
        }
    }
}


interface ComponentProps {
    event: Partial<Event>;
    onChangeEvent: (value: Partial<Event>) => void;
    isValid: boolean;
    showNotificationSettings: boolean;
    showReminderSettings: boolean;
    validationChecks: ValidationChecks;
    customCss?: ICustomCssModelv1;
}

interface ComponentState {
    hasNotifications: boolean;
    hasReminders: boolean;
    showAttachedFiles: boolean;
    showAuthor: boolean;
    showHeroBanner: boolean;
    showMoreOptions: boolean;
    showNotificationSettings: boolean;
    showQuestions: boolean;
    showReminderSettings: boolean;
    showSummary: boolean;
    showTabCommandsList: boolean;
    showUpdateInPersonCapacityConfirmation: boolean;
    showUpdateOnlineCapacityConfirmation: boolean;
    hasMention: boolean;
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        isFetching: state.events.isFetching,
        lcidMappings: state.resources.lcidMappings,
        currentUser: state.settings.currentUser,
        documentGallery: state.events.documentGallery,
        imageGallery: state.events.imageGallery,
        notificationSettings: state.settings.notificationSettings,
        tenantSettings: state.settings.tenantSettings,
        tenant: state.tenant.id
    }),
    {
        getContentAnalysis: actions.getContentAnalysis,
        getDraftTranslation: actions.getDraftTranslation,
        redirectTo: push
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(EventEditor);
