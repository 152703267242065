import * as React from "react";

import AuthoringSearch from "modules/common/components/filters/authoringSearch";
import FilterContainer from "modules/common/components/filters/filterContainer";

import { NewsletterFilterValues } from "../../models";

import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";

import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { NewsletterListComponentTab } from "./newsletterList";
import FilterDropdown from "modules/common/components/filters/filterDropdown";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { PickerLocalization } from "modules/common/components/pickerLocalization";

import { Button, Checkbox, Divider, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import moment from "moment";
import { Audience } from "modules/audiences";
import { SelectOption } from "modules/common/models";


class NewsletterFilters extends React.Component<ComponentProps, ComponentState> {
  constructor(props: ComponentProps) {
    super(props);

    this.state = {
      canSearch: !!props.filters.textToSearch,
      textToSearch: props.filters.textToSearch || ""
    };
  }

  public componentDidUpdate(prevProps: ComponentProps) {
    if (this.props.filters.textToSearch !== prevProps.filters.textToSearch)
      this.setState({ textToSearch: this.props.filters.textToSearch || "" });
  }

  public render() {
    const { filters } = this.props;
    const tagSelected = (filters.tags?.length ?? [].length) > 0;

    return (
      <FilterContainer
        filters={
          <React.Fragment>
            <TextField
              variant="outlined"
              size="small"
              value={this.state.textToSearch}
              placeholder="Search title or description"
              InputProps={{
                startAdornment: <SearchIcon className="search-icon" />
              }}
              onChange={this.onUpdateTextToSearch}
              onKeyUp={this.onKeyUp}
              className="text-to-search"
            />
            {(this.props.currentTab === 'all' || this.props.currentTab === 'enabled' || this.props.currentTab === 'disabled') &&
              <FilterDropdown text="Last sent between">
                <PickerLocalization>
                  <div className="published-time">
                    <div>
                      <div>From</div>
                      <DatePicker
                        format="MMM dd yyyy"
                        value={filters.lastSentFrom ? Date.parse(filters.lastSentFrom) : null}
                        slotProps={{ 
                          textField: { size: "small", placeholder: "", inputProps: { readOnly: true }},
                          openPickerIcon: { color: "primary" }
                        }}
                        onChange={this.onChangeSentFrom}
                        className="date-picker"
                      />
                    </div>
                    <div>
                      <div>To</div>
                      <DatePicker
                        format="MMM dd yyyy"
                        value={filters.lastSentTo ? Date.parse(filters.lastSentTo) : null}
                        slotProps={{ 
                          textField: { size: "small", placeholder: "", inputProps: { readOnly: true }},
                          openPickerIcon: { color: "primary" }
                        }}
                        onChange={this.onChangeSentTo}
                        className="date-picker"
                      />
                    </div>
                  </div>
                </PickerLocalization>
              </FilterDropdown>
            }
            {(this.props.currentTab === 'all' || this.props.currentTab === 'enabled' || this.props.currentTab === 'draft') &&
              <FilterDropdown text="Next queued between">
                <PickerLocalization>
                  <div className="published-time">
                    <div>
                      <div>From</div>
                      <DatePicker
                        format="MMM dd yyyy"
                        value={filters.nextQueuedFrom ? Date.parse(filters.nextQueuedFrom) : null}
                        slotProps={{ 
                          textField: { size: "small", placeholder: "", inputProps: { readOnly: true }},
                          openPickerIcon: { color: "primary" }
                        }}
                        onChange={this.onChangeQueuedFrom}
                        className="date-picker"
                      />
                    </div>
                    <div>
                      <div>To</div>
                      <DatePicker
                        format="MMM dd yyyy"
                        value={filters.nextQueuedTo ? Date.parse(filters.nextQueuedTo) : null}
                        slotProps={{ 
                          textField: { size: "small", placeholder: "", inputProps: { readOnly: true }},
                          openPickerIcon: { color: "primary" }
                        }}
                        onChange={this.onChangeQueuedTo}
                        className="date-picker"
                      />
                    </div>
                  </div>
                </PickerLocalization>
              </FilterDropdown>
            }
            {(this.props.currentTab === 'all' || this.props.currentTab === 'enabled' || this.props.currentTab === 'disabled') &&
              <FilterDropdown text="Sent to">
                <div style={{height: "154px"}}>
                  <List disablePadding style={{maxHeight: "154px", overflow: "auto"}}>
                    {this.props.audiences.map(audience => {
                      return (
                        <React.Fragment key={audience.id}>
                            <ListItem key={audience.id} dense button onClick={() => this.onChangeTags(audience.id)}>
                              <ListItemIcon className="callout-checkbox">
                                <Checkbox
                                  edge="start"
                                  tabIndex={-1}
                                  disableRipple
                                  size="small"
                                  color="primary"
                                  checked={!!filters.tags!.find((selectedTag) => selectedTag === audience.id)}
                                />
                              </ListItemIcon>
                              <ListItemText primary={audience.displayName} />
                            </ListItem>
                        </React.Fragment>
                      )})}
                  </List>
                </div>
                <div>
                  <Divider/>
                  <Button color="primary" style={{float: "right"}} onClick={() => this.onHandleAllTags(tagSelected)}>
                    {tagSelected ? "Unselect" : "Select"} All
                  </Button>
                </div>
              </FilterDropdown>
            }
            {(this.props.currentTab === 'draft' || this.props.currentTab === 'disabled') &&
              <FilterDropdown text="Last modified between">
                <PickerLocalization>
                  <div className="published-time">
                    <div>
                      <div>From</div>
                      <DatePicker
                        format="MMM dd yyyy"
                        value={filters.modifiedFrom ? Date.parse(filters.modifiedFrom) : null}
                        slotProps={{ 
                          textField: { size: "small", placeholder: "", inputProps: { readOnly: true }},
                          openPickerIcon: { color: "primary" }
                        }}
                        onChange={this.onChangeModifiedFrom}
                        className="date-picker"
                      />
                    </div>
                    <div>
                      <div>To</div>
                      <DatePicker
                        format="MMM dd yyyy"
                        value={filters.modifiedTo ? Date.parse(filters.modifiedTo) : null}
                        slotProps={{ 
                          textField: { size: "small", placeholder: "", inputProps: { readOnly: true }},
                          openPickerIcon: { color: "primary" }
                        }}
                        onChange={this.onChangeModifiedTo}
                        className="date-picker"
                      />
                    </div>
                  </div>
                </PickerLocalization>
              </FilterDropdown>
            }
          </React.Fragment>
        }
        filterCommands={
          <AuthoringSearch
            canSearch={this.state.canSearch}
            onApplyFilters={this.onChangeTextToSearch}
            onClearFilters={this.onClearFilters}
          />
        }
        filterSelection={this.getFilterSelection()}
      />
    );
  }

  private getFilterSelection = (): JSX.Element | undefined => {
    const { filters } = this.props;

    if (this.hasNoCurrentFilters())
      return <React.Fragment></React.Fragment>;

    const availableTags: SelectOption[] = this.getAvailableTags();

    return (
      <React.Fragment>
        {!!filters.textToSearch &&
          <Chip
            key="search-text"
            label={`"${filters.textToSearch}"`}
            onDelete={this.onClearTextToSearch}
            deleteIcon={<CloseIcon />}
          />
        }
        {(!!filters.lastSentFrom || !!filters.lastSentTo) &&
          <Chip
            key="published-time"
            label={`Released${!!filters.lastSentFrom? ` from ${moment(filters.lastSentFrom).format("MMM D, YYYY")}` : ""}${!!filters.lastSentTo ? ` up to ${moment(filters.lastSentTo).format("MMM D, YYYY")}` : ""}`}
            onDelete={this.onClearSentTime}
            deleteIcon={<CloseIcon />}
          />
        }
        {(!!filters.nextQueuedFrom || !!filters.nextQueuedFrom) &&
          <Chip
            key="queued-time"
            label={`Next queued${!!filters.nextQueuedFrom? ` from ${moment(filters.nextQueuedFrom).format("MMM D, YYYY")}` : ""}${!!filters.nextQueuedTo ? ` up to ${moment(filters.nextQueuedTo).format("MMM D, YYYY")}` : ""}`}
            onDelete={this.onClearQueuedTime}
            deleteIcon={<CloseIcon />}
          />
        }
        {(!!filters.modifiedFrom || !!filters.modifiedFrom) &&
          <Chip
            key="modified-time"
            label={`Modified${!!filters.modifiedFrom? ` from ${moment(filters.modifiedFrom).format("MMM D, YYYY")}` : ""}${!!filters.modifiedTo ? ` up to ${moment(filters.modifiedTo).format("MMM D, YYYY")}` : ""}`}
            onDelete={this.onClearModifiedTime}
            deleteIcon={<CloseIcon />}
          />
        }
        {filters.tags!.map((selectedTag) =>
          <Chip
            key={selectedTag}
            label={availableTags.find((tag) => tag.key === selectedTag)!.text}
            onDelete={() => this.onClearTag(selectedTag)}
            deleteIcon={<CloseIcon />}
          />
        )}
      </React.Fragment>
    );
  }

  private hasNoCurrentFilters = () => {
    let filters = this.props.filters;

    return (!filters.tags || filters.tags.length === 0) 
            && filters.textToSearch === "" 
            && !filters.lastSentFrom && !filters.lastSentTo
            && !filters.modifiedFrom && !filters.modifiedTo
            && !filters.nextQueuedFrom && !filters.nextQueuedTo
  }

  private onChangeFilters = (value: Partial<NewsletterFilterValues>) => {
    this.props.onChangeFilters({ ...this.props.filters, textToSearch: this.state.textToSearch, ...value });
    this.setCanSearch();
  }
  
  private onChangeTextToSearch = () => {
    this.onChangeFilters({ textToSearch: this.state.textToSearch });
  }

  private onClearFilters = () => {
    this.clearCanSearch();
    this.props.onClearFilters();
  }

  private onClearTextToSearch = () => {
    this.onChangeFilters({ textToSearch: "" });
  }
  
  private onKeyUp = (key) => {
    if (key.keyCode === 13)
      this.onChangeTextToSearch();
  }

  private onUpdateTextToSearch = (event) => {
    this.setState({ textToSearch: event.target.value });
    if(event.target.value && event.target.value !== "") {
      this.setCanSearch();
    }
    else if(this.hasNoCurrentFilters()){
      this.clearCanSearch();
    }
  }

  private clearCanSearch = () => {
    this.setState({ canSearch: false });
  }

  private setCanSearch = () => {
    this.setState({ canSearch: true });
  }

  private onChangeTags = (currentTag: string) => {
    const hasSelectedTag: boolean = !!this.props.filters.tags!.find((selectedTag) => selectedTag === currentTag);
    if (hasSelectedTag)
      this.onChangeFilters({ tags: this.props.filters.tags!.filter((selectedTag) => selectedTag !== currentTag) });
    else
      this.onChangeFilters({ tags: this.props.filters.tags!.concat([currentTag]) });
  }

  private onHandleAllTags = (tagSelected: boolean) => {
    let newFilters = {...this.props.filters};

    if(tagSelected) {
      newFilters.tags = [];
    }

    else {
      let currentTags = this.props.filters.tags ?? [];
      let audiences = this.props.audiences;

      audiences.forEach(audience => {
        if(!currentTags.includes(audience.id)) {
          currentTags.push(audience.id);
        }
      });
      newFilters.tags = currentTags;
    }

    this.onChangeFilters(newFilters);
  }

  private getAvailableTags = (): SelectOption[] => {
    if (!this.props.audiences)
      return [];
    
    let availableTags: SelectOption[] = [];
    this.props.audiences.forEach((audience) => {
      if(audience.enabled) {
        availableTags.push({ key: audience.id, text: audience.displayName, type: "item" });
      }
    });

    return availableTags;
  }

  private onClearTag = (tag: string) => {
    this.onChangeFilters({ tags: this.props.filters.tags!.filter((selectedTag) => selectedTag !== tag) });
  }

  private onClearSentTime = () => {
    this.onChangeFilters({ lastSentFrom: "", lastSentTo: "" });
  }
 
  private onClearModifiedTime = () => {
    this.onChangeFilters({ modifiedFrom: "", modifiedTo: "" });
  }

  private onClearQueuedTime = () => {
    this.onChangeFilters({ nextQueuedFrom: "", nextQueuedTo: "" });
  }

  private onChangeSentFrom = (date) => {
    this.onChangeFilters({ lastSentFrom: moment(date).toISOString() });
  }

  private onChangeSentTo = (date) => {
    this.onChangeFilters({ lastSentTo: moment(date).toISOString() });
  }

  private onChangeQueuedFrom= (date) => {
    this.onChangeFilters({ nextQueuedFrom: moment(date).toISOString() });
  }

  private onChangeQueuedTo = (date) => {
    this.onChangeFilters({ nextQueuedTo: moment(date).toISOString() });
  }

  private onChangeModifiedFrom = (date) => {
    this.onChangeFilters({ modifiedFrom: moment(date).toISOString() });
  }

  private onChangeModifiedTo = (date) => {
    this.onChangeFilters({ modifiedTo: moment(date).toISOString() });
  }
}

interface ComponentProps {
  filters: Partial<NewsletterFilterValues>;
  onChangeFilters: (filters: Partial<NewsletterFilterValues>) => void;
  onClearFilters: () => void;
  currentTab: NewsletterListComponentTab;
  audiences: Audience[];
}

interface ComponentState {
  canSearch: boolean;
  textToSearch: string;
}

export default NewsletterFilters;