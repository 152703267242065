/* Polyfills */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'custom-event-polyfill';
import 'blueimp-canvas-to-blob';

import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/dist/locale-data/en';
import '@formatjs/intl-pluralrules/dist/locale-data/fr';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/dist/locale-data/en';
import '@formatjs/intl-relativetimeformat/dist/locale-data/fr';

import "moment/locale/en-ca.js";
import "moment/locale/fr-ca";

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { store, browserHistory, devToolsExtension } from './configureStore';
import registerServiceWorker from './registerServiceWorker';
import IntlProvider from './i18n';
import Theme from './theme';

import Routes from './routes/routes';
import { DevTools } from './modules/common/components/DevTools';
import { isDevEnvironment } from 'utils/isDevEnvironment';
import MsalAuthModule, { msalConfig } from 'authentication/msalAuthModule';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

//for Navigation
import { CustomNavigationClient } from 'authentication/navigation/customNavigationClient';


import './styles/index.sass';
import './styles/tooltip.sass';


const msalInstance = new PublicClientApplication(msalConfig);

const navigationClient = new CustomNavigationClient(browserHistory);
msalInstance.setNavigationClient(navigationClient);

// The Graph Access Token (used in the Everyone Audience screen, which logs in to login.microsoftonline.com) 
// stays in the msal cache and causes confusion with the Main MSAL instance (which is set to login to B2C (b2clogin.com))
// and causes an error on login. This fixes that issue.
const accounts = msalInstance.getAllAccounts();

if (accounts.length > 0) {
    let b2cAcct = accounts.filter(a => a.environment.includes("b2clogin.com"));
    let currB2CAcct = b2cAcct.length === 0 ? null : b2cAcct[0];
    msalInstance.setActiveAccount(currB2CAcct);
}

MsalAuthModule.setInstance(msalInstance, store);

ReactDOM.render(
    <MsalProvider instance={msalInstance}>
        <Provider store={store}>
            <IntlProvider>
                <ConnectedRouter history={browserHistory}>
                    <Theme>
                        <Routes />
                    </Theme>
                </ConnectedRouter>
            </IntlProvider>
            {isDevEnvironment && !devToolsExtension && <DevTools />}
        </Provider>
    </MsalProvider>,
    document.getElementById('root')
);

registerServiceWorker();