import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import * as actions from "../../actionCreator";
import { GlobalApplicationState } from "globalApplicationState";
import { CategoryTagFilter, CategoryTagsListingPage } from "modules/categorytags";

import CategoryTagsList from "./categoryTagsList";

import Tabs from "pages/common/tabs";

const allTab: number = 0;
const enabledTab: number = 1;
const disabledTab: number = 2;


class CategoryTagsListing extends React.Component<PropsWithRedux, ComponentState> {
  constructor(props: PropsWithRedux) {
    super(props);

    this.state = {
      filters: { ...this.getDefaultFilterValues() },
      hasFiltersApplied: false,
      selectedItems: [],
      selectedTab: 0
    };
  }

  public componentWillMount() {
    if (this.props.isInitialLoad || this.props.shouldFetch) {
      this.fetchLists();
      this.props.getUserCategoryTags();
    }
  }

  public componentDidUpdate(prevProps: PropsWithRedux) {
    if (this.props.shouldFetch && (this.props.shouldFetch !== prevProps.shouldFetch)) {
      this.fetchLists();
      this.props.getUserCategoryTags();
    }
  }

  public render() {
    const { selectedTab } = this.state;

    return (
      <React.Fragment>
        <div className="category-tags-listing">
          <Tabs
            tabs={[
              { label: this.getTabLabel("All", this.props.all) },
              { label: this.getTabLabel("Enabled", this.props.enabled), dotColor: "green" },
              { label: this.getTabLabel("Disabled", this.props.disabled), dotColor: "grey" }
            ]}
            selectedTab={selectedTab}
            onSelectTab={this.onSelectTab}
          />
          { <CategoryTagsList show={selectedTab === allTab} page={this.props.all} fetchPage={this.fetchAll} /> }
          { <CategoryTagsList show={selectedTab === enabledTab} page={this.props.enabled} fetchPage={this.fetchEnabled} /> }
          { <CategoryTagsList show={selectedTab === disabledTab} page={this.props.disabled} fetchPage={this.fetchDisabled} /> }
        </div>
      </React.Fragment>
    );
  }


  private fetchLists = () => {
    this.fetchAll(1, {});
    this.fetchEnabled(1, {});
    this.fetchDisabled(1, {});
    this.props.clearShouldFetch();
  }


  private fetchAll = (pageNumber: number, filters: Partial<CategoryTagFilter>) => {
    !this.props.all.isFetching &&
      this.props.getAll(pageNumber, filters);
  }

  private fetchEnabled = (pageNumber: number, filters: Partial<CategoryTagFilter>) => {
    !this.props.enabled.isFetching &&
      this.props.getEnabled(pageNumber, filters);
  }

  private fetchDisabled = (pageNumber: number, filters: Partial<CategoryTagFilter>) => {
    !this.props.disabled.isFetching &&
      this.props.getDisabled(pageNumber, filters);
  }


  private getDefaultFilterValues = (): Partial<CategoryTagFilter> => {
    const filters: Partial<CategoryTagFilter> = {
      title: ""
    };
    return filters;
  }

  private getTabLabel = (label: string, page: CategoryTagsListingPage): string => {
    if (!!page.currentPage)
      return `${label} (${page.totalCategoryTags})`;
    return label;
  }

  private onSelectTab = (tabIndex: number) => {
    this.setState({ ...this.state, selectedTab: tabIndex });
  }
}
  

interface ComponentProps {
}

interface ComponentState {
  filters: Partial<CategoryTagFilter>;
  hasFiltersApplied: boolean;
  selectedItems: string[];
  selectedTab: number;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    all: state.categoryTags.all,
    enabled: state.categoryTags.enabled,
    disabled: state.categoryTags.disabled,
    isFetching: state.categoryTags.isFetching,
    isInitialLoad: state.categoryTags.isInitialLoad,
    shouldFetch: state.categoryTags.shouldFetch,
    tenantSettings: state.settings.tenantSettings,
    tenant: state.tenant.id
  }),
  {
    clearShouldFetch: actions.clearShouldFetch,
    getAll: actions.getCategoryTags,
    getDisabled: actions.getDisabledCategoryTags,
    getEnabled: actions.getEnabledCategoryTags,
    getUserCategoryTags: actions.getUserCategoryTags
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(CategoryTagsListing);