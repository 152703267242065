import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Prompt, RouteComponentProps } from "react-router-dom";
import * as actions from "../actionCreator";
import * as audiencesActions from "modules/audiences/actionCreator";
import * as settingsActions from "modules/settings/actionCreator";
import { GlobalApplicationState } from "globalApplicationState";
import { push } from "react-router-redux";
import moment from "moment";
import _ from "lodash";

import ErrorSnackbar from "modules/common/components/snackbars/errorSnackbar";
import LoadingOverlay from "modules/common/components/loadingOverlay";
import SuccessSnackbar from "modules/common/components/snackbars/successSnackbar";

import BasePage from "pages/common/basePage";
import Breadcrumb from "pages/common/breadcrumb";
import Columns from "pages/common/columns";
import MainContent from "pages/common/mainContent";
import MainContentWithFooter from "pages/common/mainContentWithFooter";

import Button from "@mui/material/Button";
import Hidden from "@mui/material/Hidden";
import TitleSection from "./components/title";
import { ProgressSection } from "./components/progress";
import TemplateSection from "./components/templateSection";
import SettingsSection from "./components/settingsSection";
import NewsletterPreview from "./components/newsletterPreview";
import "modules/common/components/authoring/authoring.sass";
import {
  Address,
  FOOTER,
  HEADER,
  NewsletterDetails,
  NewsletterImageType,
  NewsletterTemplate,
  NewsletterTemplateImageType,
  NewsletterTheme,
  NewsletterThemeSimple,
  SaveNewsletterModel,
  SaveNewsletterModelStateErrors,
  NewsletterDigest,
  UploadNewsletterImageModel,
} from "modules/newsletters";
import "../styles/newsletter.sass";
import "../styles/progress-bar.sass";
import Loading from "modules/common/components/loading";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Link,
  List,
  TextField,
  Typography
} from "@mui/material";
import { Audience } from "modules/audiences/models";
import SendTestEmailDialog from "../common/dialogs/sendTestEmailDialog";
import Tabs from "pages/common/tabs";
import SubjectAndPreHeader from "./components/subjectAndPreHeader";
import {HeaderAndFooter} from "./components/headerAndFooter";
import MoreOptionsButton from "modules/common/components/buttons/moreOptionsButton";
import Disclaimer from "modules/common/components/disclaimer";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import CloseIcon from "@mui/icons-material/Close";
import { hexCodeFromText } from "utils/hexFunctions";
import MoreOptionsItem from "modules/common/components/moreOptionsItem";
import { getBase64, standardTemplateNames } from "utils/newsletterUtils";
import { FileDownloader } from "utils/fileDownloader";


class EditNewsletterPage extends React.Component<PropsWithRedux, ComponentState> {

  constructor(props: PropsWithRedux) {
    super(props);

    const newsletter = this.getNewNewsletter();

    this.state = {
      newsletter: newsletter,
      modelErrors: null,
      highlightTitleFields: false,
      highlightSubjectAndHeader: false,
      highlightSenderDetails: false,
      showTemplateSection: true,
      showAudienceFrequencySection: false,
      showNewsletterPreview: false,
      showContentSection: false,
      changedSinceSaved: false,
      isSaving: false,
      firstTime: false,
      saveTemplateOpen: false,
      templateXClicked: false,
      hasSavedTheme: false,
      preSaveWarningOpen: false,
      alreadySeenWarning: false,
      hasHeaderImageChanged: false,
      hasFooterImageChanged: false,
      selectedTab: 0,
      themeName: "",
      themeError: "",
      addresses: [],
      originalAddresses: [],
      progressCheck:{
        isComplete: false,
        titleSectionIsComplete: false,
        audienceFrequencySectionIsComplete: false,
        templateSectionIsComplete: false,
        composeSectionIsComplete: false
      }
    };
  }

  componentDidMount = async() => {
    moment.locale("en");

    this.props.getConfig().then((currentConfig) => {
      this.setState({addresses: currentConfig.addresses, originalAddresses: currentConfig.addresses});
    });

    let newsletterToSet;
    let themeToSet;

    newsletterToSet = await this.fetchAndSetTemplate();
    await this.fetchAndSetNewsletter();
    themeToSet = await this.fetchAndSetTheme();

    this.setNewsletterToState(this.state.newsletter, newsletterToSet, themeToSet, false);

    this.props.getAudiences().then(audiences => this.setState({audiences: audiences}));
    this.props.getTagSettings();
  }

  private fetchAndSetNewsletter = () => {
    if(!!this.props.match.params.newsletterId){
      this.props.getNewsletterDetails(this.props.match.params.newsletterId)
      .then((newsletter) => {
        if(!newsletter){
          this.props.redirectTo(`/${this.props.tenant}/admin/newsletters`);
        } else {
          let idToCheck = newsletter.emailTemplate?.isCustom ? newsletter.emailTemplate.id : newsletter.emailTemplateId;
          let selectedTemplate = _.first(_.filter(this.state.templates, t => t.id === idToCheck));
          if(!selectedTemplate) {
            selectedTemplate = _.first(_.filter(this.state.templates, t => t.name.toLowerCase() === standardTemplateNames[0]))
          }

          let selectedTheme = _.first(_.filter(this.state.themes, t => t.id === newsletter.newsletterTheme?.themeId));
          if(!selectedTheme) {
            selectedTheme = _.first(_.filter(this.state.themes, t => t.isDefault));
          }

          //No theme saved, custom!
          if(!newsletter.newsletterTheme) {
            let themeToSet: NewsletterThemeSimple = {
              themeId: "toBeReplaced",
              emailBackground: "#FFFFFF",
              primaryBackground: "#FFFFFF",
              primaryText: "#FFFFFF",
              secondaryBackground: "#FFFFFF",
              secondaryText: "#FFFFFF",
              linkText: "#FFFFFF"
            }
            newsletter.newsletterTheme = themeToSet;
          }

          newsletter.newsletterTheme.themeId = selectedTheme?.id ?? newsletter.newsletterTheme.themeId;
          this.setState({serverNewsletter: newsletter});
          this.setNewsletterToState(newsletter, selectedTemplate, selectedTheme, false);

          if (newsletter.emailTemplateHeaderImageIsCustomized) {
            this.setState({ isLoadingHeaderImage: true });
            this.props.getNewsletterImage(newsletter.id, HEADER).then((image) => {
              this.setState({ headerImage: image, isLoadingHeaderImage: false });
            });
          } else {
            this.setState({ headerImage: null, isLoadingHeaderImage: false });
          }
          if (newsletter.emailTemplateFooterImageIsCustomized) {
            this.setState({ isLoadingFooterImage: true });
            this.props.getNewsletterImage(newsletter.id, FOOTER).then((image) => {
              this.setState({ footerImage: image, isLoadingFooterImage: false });
            });
          } else {
            this.setState({ footerImage: null, isLoadingFooterImage: false });
          }
        }
      })
    }
  }

  private fetchAndSetTemplate = () => {
    this.props.getTemplates().then((templates) => {
      if(!!templates){
        this.setState({templates: templates});
        if(!!this.state.newsletter){
          if(!this.state.newsletter.emailTemplateId) {
            const firstTemplate = templates.find(t => t.name.toLowerCase() === "1 column")
            if (!!firstTemplate) {
              const newsletter = { ...this.state.newsletter, emailTemplateId: firstTemplate.id };
              this.setState({ newsletter, selectedTemplate: firstTemplate });
              return firstTemplate;
            }
          } else{
            const selectedTemplate = _.first(_.filter(templates, t=>t.id === this.state.newsletter.emailTemplateId));
            this.setState({ selectedTemplate: selectedTemplate });
            return selectedTemplate;
          }
        }
      }
    });
  }

  private fetchAndSetTheme = () => {
    this.props.getThemes().then((themes) => {
      if(!!themes){
        let sortedThemes = themes.sort((a, b) => a.name.localeCompare(b.name));
        this.setState({themes: sortedThemes});
        if(!!this.state.newsletter){
          if((!this.state.newsletter.newsletterTheme || !this.state.newsletter.newsletterTheme.themeId) && !this.props.match.params.newsletterId){
            //Check for default themes
            let validDefaultState = themes.filter(t => t.isDefault).length === 1;
            let theme;
            if(validDefaultState)
              theme = themes.find(t => t.isDefault);
            else {
              theme = themes.find(t => t.name.toLowerCase() === "sparrow connected light theme")
            }
            if (!!theme) {
              const newsletter = { ...this.state.newsletter };

              //This is a new newsletter, load the colors of the theme.
              var colorsToSet:NewsletterThemeSimple = {
                themeId: theme.id,
                emailBackground: theme.emailBackground,
                primaryBackground: theme.primaryBackground,
                primaryText: theme.primaryText,
                secondaryBackground: theme.secondaryBackground,
                secondaryText: theme.secondaryText,
                linkText: theme.linkText
              }

              newsletter.newsletterTheme = colorsToSet;
              this.setState({ newsletter: newsletter, firstTime: true });
              return theme;
            }
          } else {
            let selectedTheme = _.first(_.filter(themes, t=>t.id === this.state.newsletter.newsletterTheme.themeId));
            const newsletter = { ...this.state.newsletter };

            //Case where theme to be loaded has been deleted.
            if(!selectedTheme) {
              selectedTheme = themes.find(t => t.isDefault);
              if(selectedTheme) {
                newsletter.newsletterTheme.themeId = selectedTheme.id;
              }
            }

            this.setState({selectedTheme: selectedTheme, newsletter: newsletter});
            return selectedTheme;
          }
        }
      }});
  }

  public render() {
    const {selectedTemplate} = this.state;
    const tabs=[
      { label: "Template", value: 0 },
      { label: "Email details", value: 1 },
      { label: "Settings", value: 2 },
    ];

    if(!selectedTemplate)
      return <Loading />;

    if(!!this.props.match.params.newsletterId && !this.state.serverNewsletter)
      return <Loading />;

    if(!this.state.audiences || !this.props.tagSettings)
      return <Loading />;

    const filledOutImages = !!this.state.headerImage && !!this.state.footerImage;
    const defaultThemeSelected = !this.state.selectedTheme?.isCustom ?? true;

    return (
      <BasePage fullWidth>
        <Prompt when={this.state.changedSinceSaved} message="You have unsaved changes, are you sure you want to leave this page?" />
        <Breadcrumb
          items={[
            { title: "Newsletters", link: "~/admin/newsletters" },
            { title: this.state.newsletter?.title || "New" }
          ]}
          backItem={(!!this.state.serverNewsletter && this.state.serverNewsletter.status !== "Draft")
            ? { title: "Back to dashboard", link: `~/admin/newsletters/${this.props.match.params.newsletterId}/dashboard` }
            : { title: "Back to Manage Newsletters", link: "~/admin/newsletters" }
          }
          rightComponent={this.getCommands()}
        />
        <Columns
          leftComponent={
            <div className="newsletter-page">
              <MainContent addPadding10>
                <ProgressSection {...this.state.progressCheck} onTitleSectionClicked={this.highlightTitleFields} onComposeClicked={this.onComposeClicked} onTemplateSectionClicked={() => this.setState({selectedTab: 0})} onAudienceFrequencySectionClicked={this.onFrequencyClicked} ></ProgressSection>
              </MainContent>
              <MainContent addPadding10 addMarginTop>
                <TitleSection newsletter={this.state.newsletter} highlightFields={this.state.highlightTitleFields} modelErrors={this.state.modelErrors} onNewsletterChange={this.onChangeNewsletter}></TitleSection>
              </MainContent>
            </div>
          }
          rightComponent={
            <MainContentWithFooter footerContent={this.renderFooter()} addPadding footerHeight={51} headerContent={
              <div>
                <Tabs
                    tabs={tabs}
                    selectedTab={this.state.selectedTab}
                    onSelectTab={(e) => this.setState({selectedTab: e})}
                />
              </div>}>
                <div className="newsletter-page" style={{overflowY: "clip", overflowX: "clip", paddingBottom: "20px"}}>
                  {
                    this.state.selectedTab === 0 &&
                      <TemplateSection
                        firstTime={this.state.firstTime}
                        setFirstTime={this.setFirstTime}
                        setImage={this.setImage}
                        newsletter={this.state.newsletter}
                        footerImage={this.state.footerImage}
                        headerImage={this.state.headerImage}
                        isLoadingFooterImage={this.state.isLoadingFooterImage}
                        isLoadingHeaderImage={this.state.isLoadingHeaderImage}
                        onNewsletterChange={this.onChangeNewsletter}
                        templates={this.state.templates || []}
                        modelErrors={this.state.modelErrors}
                        onDownloadTemplateHtml={this.onDownloadTemplateHtml}
                        onDownloadTemplateImage={this.onDownloadTemplateImage}
                        getTemplateImage={this.props.getTemplateImage}
                        onError={(message) => this.setState({errorMessage: message})}
                        setLoadImage={this.setImageOnLoad}
                        updateSavedTheme={this.updateSavedTheme}
                        themes={this.state.themes ?? []} >
                      </TemplateSection>
                  }
                  {
                    this.state.selectedTab === 1 &&
                      <div style={{minHeight: "665px"}}>
                        <SubjectAndPreHeader newsletter={this.state.newsletter} modelErrors={this.state.modelErrors} onNewsletterChange={this.onChangeNewsletter} highlight={this.state.highlightSubjectAndHeader}/>
                        <HeaderAndFooter emailTemplate={selectedTemplate} setImage={this.setImage} clearImage={this.clearImage} newsletter={this.state.newsletter} modelErrors={this.state.modelErrors} footerImage={this.state.footerImage} headerImage={this.state.headerImage} isLoadingFooterImage={this.state.isLoadingFooterImage} isLoadingHeaderImage={this.state.isLoadingHeaderImage} onNewsletterChange={this.onChangeNewsletter} onError={(message) => this.setState({errorMessage: message})} highlight={this.state.highlightSubjectAndHeader}/>
                      </div>
                  }
                  {
                    this.state.selectedTab === 2 &&
                    <SettingsSection newsletter={this.state.newsletter} serverNewsletter={this.state.serverNewsletter} senders={this.state.addresses} onSearchSenders={this.onSearchSenders} audiences={this.state.audiences} modelErrors={this.state.modelErrors} onNewsletterChange={this.onChangeNewsletter} highlight={this.state.highlightSenderDetails}></SettingsSection>
                  }
                </div>
              </MainContentWithFooter>
          }
        />
        {this.state.showNewsletterPreview && <NewsletterPreview audiences={this.state.audiences} publishNewsletter={this.publishNewsletter} newsletter={this.state.newsletter} template={selectedTemplate} headerImage={this.state.headerImage} footerImage={this.state.footerImage} onClose={()=>this.setState({showNewsletterPreview: false})} getUniqueUsers={this.props.getUniqueUsers}/>}
        <Dialog
          open={!!this.state.showUnsavedChangesDialogPriorTo}
          onClose={() => this.setState({ showUnsavedChangesDialogPriorTo: null })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Unsaved Changes</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              There are unsaved changes to the Newsletter.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ showUnsavedChangesDialogPriorTo: null })} color="primary">
              Go back to Editing
            </Button>
            <Button onClick={()=> {
              const nextAction = this.state.showUnsavedChangesDialogPriorTo;
              this.setState({ showUnsavedChangesDialogPriorTo: null });
              this.saveNewsletter(this.state.newsletter).then(succeeded => {
                if(succeeded){
                  switch(nextAction){
                    case 'onShowSendTestEmail':
                      this.onShowSendTestEmail();
                    }
                  }
                });
              }}
              color="primary" autoFocus>
              Save changes
            </Button>
          </DialogActions>
        </Dialog>
        {!!this.state.serverNewsletter &&
          <SendTestEmailDialog
            show={this.state.showSendTestEmailDialog || false}
            newsletterId={this.state.serverNewsletter.id}
            newsletterTitle={this.state.serverNewsletter!.title}
            onError={(modelErrors) => {this.setState({showSendTestEmailDialog: false, modelErrors: modelErrors, errorMessage: _.first(_.first(_.values(modelErrors)))});}}
            onClose={()=>this.setState({ showSendTestEmailDialog: false })}
            onSuccess={(message) => this.setState({successMessage:message})}
          />}

        <Dialog
          open={this.state.saveTemplateOpen}
          onClose={this.handleThemeDialogClose}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle id="alert-dialog-title" style={{borderBottom: "1px solid #dde1e5", paddingBottom: "0px"}}>
            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
              <Typography variant="h5">Save as Theme</Typography>
              <IconButton onClick={this.handleThemeDialogClose} size="large">
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent>
            {(filledOutImages || this.state.templateXClicked) &&
              <div style={{marginTop: "10px"}}>
                <Disclaimer
                  icon={<InfoOutlinedIcon />}
                  text={
                    <React.Fragment>
                      <span className="successfully-scanned">
                        A theme saves the color palette, header image, and footer image only. <Link onClick={() => window.open("https://support.sparrowconnected.com/en/what-are-newsletter-themes")} underline="always">Learn more</Link>.
                      </span>
                    </React.Fragment>
                  }>
                </Disclaimer>
              </div>
            }
            {(!filledOutImages && !this.state.templateXClicked) &&
              <div style={{marginTop: "10px", backgroundColor: "#FFE9AF", display: "flex", flexDirection: "row"}}>
                <Disclaimer
                  icon={<InfoOutlinedIcon />}
                  backgroundColor="#FFE9AF"
                  text={
                    <React.Fragment>
                      <span className="successfully-scanned" style={{backgroundColor: "#FFE9AF"}}>
                        You are saving a theme with the default header/footer images. <Link onClick={this.swapToImagesTab} underline="always">Upload custom images</Link>.
                      </span>
                    </React.Fragment>
                  }>
                </Disclaimer>
                <Button onClick={() => this.setState({templateXClicked: true})}>
                  <CloseIcon style={{color: "grey"}}/>
                </Button>
              </div>
            }

            <TextField
              fullWidth
              variant="outlined"
              size="small"
              placeholder="Enter theme title"
              style={{marginTop: "12px", marginBottom: "6px"}}
              value={this.state.themeName}
              error={this.state.themeError !== ""}
              helperText={this.state.themeError}
              onChange={(e) => this.setState({themeName: e.target.value})}
              onClick={() => this.setState({themeError: ""})}
              onFocus={() => this.setState({themeError: ""})}
            />
          </DialogContent>
          <DialogActions style={{marginLeft: "15px", marginRight: "15px"}}>
            <Button onClick={() => {
                this.setState({ saveTemplateOpen: false })
                window.open(`/${this.props.tenant}/admin/newsletters/managethemes`, '_blank', 'noopener,noreferrer');
              }}
              color="primary"
              endIcon={<OpenInNewIcon/>}
              style={{marginRight: "auto"}}
              >
                Manage Themes
            </Button>
            <Button onClick={() => this.setState({ saveTemplateOpen: false })}>
              Cancel
            </Button>
            <Button onClick={async ()=> {
                let result = await this.saveNewTheme();

                if(result) {
                  this.setState({ saveTemplateOpen: false, successMessage: "Successfully saved as a theme." });

                  this.props.getThemes().then((themes) => {
                    if(!!themes){
                      let sortedThemes = themes.sort((a, b) => a.name.localeCompare(b.name));
                      this.setState({themes: sortedThemes});
                    }
                  });
                }
              }}
              color="primary" autoFocus>
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.preSaveWarningOpen && !this.state.alreadySeenWarning}
          onClose={this.handleUpdateOrSaveClose}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle id="alert-dialog-title" style={{paddingBottom: "6px", paddingTop: "6px", paddingRight: "8px"}}>
            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
              <Typography variant="h5">{defaultThemeSelected ? "Save Theme?" : "Update or Save Theme?"}</Typography>
              <IconButton onClick={this.handleUpdateOrSaveClose} size="large">
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent style={{paddingRight: "24px"}}>
            {defaultThemeSelected 
            ? <span>You've customized the colors and images of your newsletter. Would you like to save this as a new template theme?</span>
            : <span>You've customized the colors and images of your newsletter. Would you like to update the
              '<span style={{fontWeight: "bold"}}>{this.state.selectedTheme?.name ?? ""}</span>'
            theme or save this as a new template theme?</span>
            }
          </DialogContent>
          <DialogActions style={{marginLeft: "24px", marginRight: "18px", marginBottom: "4px"}}>
            <Button
              color="primary"
              onClick={() => this.setState({preSaveWarningOpen: false, saveTemplateOpen: true, templateXClicked: false, alreadySeenWarning: true})}
            >
              Save as new theme
            </Button>
            {!defaultThemeSelected &&
              <Button
                color="primary"
                variant="contained"
                autoFocus
                onClick={this.updateThemeAndMoveOn}
              >
                Update
              </Button>
            }
          </DialogActions>
        </Dialog>
        <LoadingOverlay absolute={true} show={this.state.isSaving || this.state.isDownloading || false} />
        <SuccessSnackbar successMessage={this.state.successMessage || ""} clearSuccessMessage={() => this.setState({ successMessage: null })} />
        <ErrorSnackbar errorMessage={this.state.errorMessage || ""} clearErrorMessage={() => this.setState({ errorMessage: null })} />
      </BasePage>
    );
  }

  private handleUpdateOrSaveClose = () => {
    if(this.state.postWarningRedirect) {
      this.state.postWarningRedirect();
    }
    this.setState({preSaveWarningOpen: false, alreadySeenWarning: true})
  }

  private handleThemeDialogClose = () => {
    this.setState({saveTemplateOpen: false, themeName: ""});
  }

  private swapToImagesTab = () => {
    this.setState({selectedTab: 1, saveTemplateOpen: false});
  }

  private onComposeClicked = () => {
    this.setState({selectedTab: 1, highlightSubjectAndHeader: true, highlightSenderDetails: false});
  }

  private onFrequencyClicked = () => {
    this.setState({selectedTab: 2, highlightSenderDetails: true, highlightSubjectAndHeader: false});
  }

  private renderFooter = (): React.ReactNode => {
    if(this.state.selectedTab === 0) {
      return this.returnTemplateOptions();
    }

    else if(this.state.selectedTab === 1){
      return this.returnContentOptions();
    }

    else if(this.state.selectedTab === 2){
      return this.returnSettingsOptions();
    }
    else {
      return (<div></div>)
    }
  }

  private returnTemplateOptions = () => {
    return (
    <Grid container spacing={0} style={{margin: '0', padding:'7px 28px'}}>
      <Grid item xs={6}>
      </Grid>

      <Grid item xs={6} style={{textAlign:'right'}}>
        <Button variant="contained" color="primary" onClick={() => this.setState({selectedTab: 1})}>Next</Button>
      </Grid>
    </Grid>
    )
  }

  private returnContentOptions = () => {
    return (
    <Grid container spacing={0} style={{margin: '0', padding:'7px 28px'}}>
      <Grid item xs={6} style={{textAlign:'left'}}>
        <Button variant="contained" color="primary" onClick={() => this.setState({selectedTab: 0})}>Previous</Button>
      </Grid>

      <Grid item xs={6} style={{textAlign:'right'}}>
        <Button variant="contained" color="primary" onClick={() => this.setState({selectedTab: 2})}>Next</Button>
      </Grid>
    </Grid>
    );
  }

  private returnSettingsOptions = () => {
    return (
      <Grid container spacing={0} style={{margin: '0', padding:'7px 28px'}}>
        <Grid item xs={6} style={{textAlign:'left'}}>
          <Button variant="contained" color="primary" onClick={() => this.setState({selectedTab: 1})}>Previous</Button>
        </Grid>

        <Grid item xs={6} style={{textAlign:'right'}}>
        </Grid>
      </Grid>
    );
  }

  private getCommands = (): JSX.Element => {
    const currentStatus = !!this.state.serverNewsletter ? this.state.serverNewsletter.status : 'Draft';
    const currentlyCustomTemplate = this.state.templates?.find(t => t.id === this.state.newsletter.emailTemplateId)?.isCustom;
    return (
      <React.Fragment>
        <Hidden mdDown>
          <MoreOptionsButton>
            <List disablePadding>
              <MoreOptionsItem
                text={currentStatus === 'Draft' ? 'Save as draft' : 'Save'}
                onClick={this.saveAsDraftClicked}
                disabled={!this.state.newsletter.title || !this.state.changedSinceSaved}
              />
              <Divider/>
              <MoreOptionsItem
                text="Save as theme"
                onClick={() => this.setState({saveTemplateOpen: true, templateXClicked: false})}
                disabled={currentlyCustomTemplate}
              />
            </List>
          </MoreOptionsButton>
          {currentStatus === 'Draft' && <Button variant="contained" disabled={!this.state.progressCheck.isComplete} color="primary" onClick={this.publishClicked}>Publish</Button>}
        </Hidden>
      </React.Fragment>
    );
  }

  private updateSavedTheme = (toSet: NewsletterTheme) => {
    this.setState({savedTheme: toSet});
  }

  private publishClicked = () => {
    var colorsToCheck = this.state.newsletter.newsletterTheme;
    var backupTheme = this.state.savedTheme; //snapshot of theme to know if we edited or not.

    //If user has changed the theme on the specific newsletter
    // compared to the one they loaded, show the warning a single time.
    if(backupTheme && !this.state.alreadySeenWarning) {
      if(colorsToCheck.emailBackground !== backupTheme.emailBackground
        || colorsToCheck.primaryBackground !== backupTheme.primaryBackground
        || colorsToCheck.primaryText !== backupTheme.primaryText
        || colorsToCheck.secondaryBackground !== backupTheme.secondaryBackground
        || colorsToCheck.secondaryText !== backupTheme.secondaryText
        || colorsToCheck.linkText !== backupTheme.linkText
        || this.state.hasHeaderImageChanged
        || this.state.hasFooterImageChanged
        ) {
          this.setState({preSaveWarningOpen: true, postWarningRedirect: () => this.setState({showNewsletterPreview: true})});
          return;
        }
    }

    this.setState({showNewsletterPreview: true})
  }

  private saveAsDraftClicked = () => {
    var colorsToCheck = this.state.newsletter.newsletterTheme;
    var backupTheme = this.state.savedTheme;

    if(!backupTheme) {
      backupTheme = this.state.themes
        ?.find(t => t.id === this.state.newsletter.newsletterTheme.themeId);
    }

    if(backupTheme && !this.state.alreadySeenWarning) {
      if(colorsToCheck.emailBackground !== backupTheme.emailBackground
        || colorsToCheck.primaryBackground !== backupTheme.primaryBackground
        || colorsToCheck.primaryText !== backupTheme.primaryText
        || colorsToCheck.secondaryBackground !== backupTheme.secondaryBackground
        || colorsToCheck.secondaryText !== backupTheme.secondaryText
        || colorsToCheck.linkText !== backupTheme.linkText
        || this.state.hasHeaderImageChanged
        || this.state.hasFooterImageChanged
        ) {
          this.setState({preSaveWarningOpen: true, postWarningRedirect: () => this.saveNewsletter(this.state.newsletter)});
          return;
      }
    }
    this.saveNewsletter(this.state.newsletter);
  }

  private getNewNewsletter = (): SaveNewsletterModel => {
    const { tenantSettings } = this.props;

    const newsletter: SaveNewsletterModel = {
      status: 'Draft',
      allowUnsubscribe: true,
      audiences: [],
      customTopics: [],
      dailyRecurrenceOn: ['Monday'],
      monthlyRecurrenceWeek:'First',
      monthlyRecurrenceDay:'Monday',
      description: '',
      emailPreheader: '',
      emailSubject: '',
      recurrenceType: 'Adhoc',
      emailTemplateHeaderText: '',
      emailTemplateFooterText: '',
      bodyText: '',
      emailTemplateId: '',
      maxEventsToInclude: 0,
      eventsToInclude: 'SinceLastIssue',
      eventsToIncludeDaysWindow: 0,
      includeUpcomingEvents: false,
      postsToInclude: 'SinceLastIssue',
      maxPostsToInclude: 4,
      postsToIncludeDaysWindow: 7,
      sortPostsAscending: true,
      title: '',
      topicOption: 'Employee',
      tenantId: tenantSettings.tenantId,
      fromEmailAddress: "",
      fromEmailName: "",

      //Overwritten on page load.
      newsletterTheme: {
        themeId: "",
        emailBackground: "#F5F5F5",
        primaryBackground: "#FEFEFA",
        primaryText: "#333333",
        secondaryBackground: "#949494",
        secondaryText: "#FFFFFF",
        linkText: "#3B78AB"
      }
    };
    return newsletter;
  }

  private onChangeNewsletter = (value: Partial<SaveNewsletterModel>) => {
    const newsletter = { ...this.state.newsletter, ...value };
    const selectedTemplate = _.first(_.filter(this.state.templates, t=>t.id === newsletter.emailTemplateId));
    const selectedTheme = _.first(_.filter(this.state.themes, t=>t.id === newsletter.newsletterTheme.themeId));
    this.setNewsletterToState(newsletter, selectedTemplate, selectedTheme, true);
  }

  //Used when the theme is loaded, shouldn't mark things as edited.
  private setImageOnLoad = (type: NewsletterImageType, toSet: File) => {
    let reader = new FileReader();
    if (type === FOOTER && !!toSet) {
      reader.readAsDataURL(toSet);
      reader.onload =  (): void => {
        this.setState({ changedSinceSaved: true, footerImage: toSet, themeHadFooterOnLoad: true, footerImageBase64: reader.result?.toString() });
      };
    }
    else if (type === HEADER  && !!toSet) {
      reader.readAsDataURL(toSet);
      reader.onload =  (): void => {
        this.setState({ changedSinceSaved: true, headerImage: toSet, themeHadHeaderOnLoad: true, headerImageBase64: reader.result?.toString() });
      };
    }
  }

  private setNewsletterToState = (newsletter: SaveNewsletterModel, selectedTemplate: NewsletterTemplate | undefined, selectedTheme: NewsletterTheme | undefined, isChanged: boolean) => {
    const titleSectionIsComplete = this.isTitleSectionComplete(newsletter);
    const templateSectionIsComplete = this.isTemplateSectionComplete(newsletter);
    const audienceFrequencySectionIsComplete= this.isFrequencySectionComplete(newsletter);
    const composeSectionIsComplete = this.isComposeSectionComplete(newsletter);

    this.setState({
      newsletter: newsletter,
      highlightTitleFields: false,
      selectedTemplate: selectedTemplate,
      selectedTheme: selectedTheme,
      changedSinceSaved: isChanged,
      progressCheck: {
        isComplete: titleSectionIsComplete && templateSectionIsComplete && audienceFrequencySectionIsComplete && composeSectionIsComplete,
        titleSectionIsComplete: titleSectionIsComplete,
        audienceFrequencySectionIsComplete: audienceFrequencySectionIsComplete,
        templateSectionIsComplete: templateSectionIsComplete,
        composeSectionIsComplete: composeSectionIsComplete
      }
    });
  }

  private highlightTitleFields = () => {
    if(!this.state.newsletter.title)
      this.setState({ highlightTitleFields: true });
  }

  private isTitleSectionComplete = (newsletter: SaveNewsletterModel): boolean => {
    if (!newsletter.title) {
      return false;
    }
    return true;
  }

  private isTemplateSectionComplete = (newsletter: SaveNewsletterModel): boolean => {
    if(!newsletter.newsletterTheme)
      return false;
    return true;
  }

  private isTemplateSectionInError = () : boolean => {
    if(!this.state.modelErrors)
      return false;

    if(!!this.state.modelErrors.EmailPreheader || !!this.state.modelErrors.EmailSubject || !!this.state.modelErrors.EmailTemplateHeaderText || !!this.state.modelErrors.EmailTemplateId)
      return true;

    return false;
  }

  private isFrequencySectionComplete = (newsletter: SaveNewsletterModel): boolean => {
    if(!newsletter.firstIssueDateTime) {
      return false;
    }
    else if(!newsletter.fromEmailAddress || !newsletter.fromEmailName) {
      return false;
    }
    return true;
  }

  private isComposeSectionComplete = (newsletter: SaveNewsletterModel): boolean => {
    if(!newsletter.emailSubject)
      return false;
    return true;
  }

  private saveNewsletter = (newsletter: SaveNewsletterModel): Promise<boolean> => {
    this.setState({ highlightTitleFields: false, isSaving: true, errorMessage: null });
    return this.props.saveNewsletter(newsletter)
    .then(response => {
      if (!!response.success) {

        this.saveHeaderFooterImage(response.success);

        this.setState({ changedSinceSaved: false, isSaving: false,
            serverNewsletter: { ...response.success, futureIssues: [], sentIssueTags: [], emailBackground: "", primaryBackground: "", primaryText: "", secondaryBackground: "", secondaryText: "", linkTexts: "",
          newsletterTheme: {themeId: "", emailBackground: "", primaryBackground: "", primaryText: "", secondaryBackground: "", secondaryText: "", linkText: ""} }, modelErrors: null, });

        if (this.state.newsletter.id === undefined) {
          let newNewsletter = this.state.newsletter;
          newNewsletter.id = response.success.id;
          this.setState({ newsletter: newNewsletter });
          this.props.redirectTo(`/${this.props.tenant}/admin/newsletters/${response.success.id}`);
        }

        return true;
      } else if(!!response.error) {
        const firstError = _.first(_.values(response.error.ModelState));
        this.setState({ changedSinceSaved: false, isSaving: false, modelErrors: response.error.ModelState, errorMessage: firstError && _.first(firstError) });
        if(this.isTemplateSectionInError()){
          this.showTemplateSection();
        }else{
          this.showAudienceFrequencySection();
        }
      }
      return false;
    });
  }

  private onShowSendTestEmail = () => {
    if(this.state.changedSinceSaved || !this.state.serverNewsletter)
      this.setState({showUnsavedChangesDialogPriorTo: 'onShowSendTestEmail'});
    else
      this.setState({showSendTestEmailDialog: true});
  }

  private publishNewsletter = () => {
    const newsletter = {...this.state.newsletter};
    newsletter.status = 'Enabled';
    this.saveNewsletter(newsletter).then(isSuccess => {
      if (isSuccess){
        this.props.redirectTo(`/${this.props.tenant}/admin/newsletters/${this.state.newsletter.id}/dashboard`);
      }else{
        this.setState({showNewsletterPreview: false});
      }
    });
  }

  private showTemplateSection = () => {
    this.setState({ highlightTitleFields: false, showTemplateSection: true, showAudienceFrequencySection: false });
  }

  private showAudienceFrequencySection = () => {
    this.setState({ highlightTitleFields: false, showTemplateSection: false, showAudienceFrequencySection: true });
  }

  private setImage = (imageType: NewsletterImageType, image?: File) => {
    let reader = new FileReader();
    if (imageType === FOOTER && !!image) {
      reader.readAsDataURL(image);
      reader.onload =  (): void => {
        this.setState({ changedSinceSaved: true, footerImage: image, hasFooterImageChanged: true, highlightTitleFields: false, footerImageBase64: reader.result?.toString() });
      };
    }
    else if (imageType === HEADER  && !!image) {
      reader.readAsDataURL(image);
      reader.onload =  (): void => {
        this.setState({ changedSinceSaved: true, headerImage: image, hasHeaderImageChanged: true, highlightTitleFields: false, headerImageBase64: reader.result?.toString() });
      };
    }
  }

  private onDownloadTemplateHtml = (template: NewsletterTemplate) => {
    this.setState({ isDownloading: true });

    const file = {
      name: `${template.name}.html`
    };
    this.props.getTemplateHtml(template.id)
      .then((data) => new FileDownloader(file).downloadProvided(data))
      .then(_ => this.setState({ isDownloading: false }))
      .catch(_ => this.setState({ isDownloading: false }));
  }

  private onDownloadTemplateImage = (template: NewsletterTemplate, imageType: NewsletterTemplateImageType) => {
    this.setState({ isDownloading: true });
    const file = {
      name: `${template.name} ${imageType}.png`
    }
    this.props.getTemplateImage(template.id, imageType)
      .then((url) => new FileDownloader(file).downloadProvided(url))
      .then(_ => this.setState({ isDownloading: false }))
      .catch(_ => this.setState({ isDownloading: false }));
  }

  private onSearchSenders = (value: string) => {
    var newAddresses = this.state.originalAddresses.filter(a => a.fromEmailAddress.toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) >= 0 || a.fromEmailName.toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) >= 0);
    this.setState({addresses: newAddresses})
  }

  private setFirstTime = (value: boolean) => {
    this.setState({firstTime: value});
  }

  private checkValidNewTheme = () => {
    return (this.validateThemeName() && this.checkValidThemeDetails());
  }

  private checkValidExistingTheme = () => {
    return (this.checkValidThemeDetails());
  }

  private validateThemeName = () => {
    if(this.state.themes) {
      if(this.state.themeName.trim().length === 0) {
        this.setState({themeError: "Enter a name."})
        return false;
      }
      return true;
    }
    return false;
  }

  private checkValidThemeDetails = () => {
    if(this.state.themes){
      if(this.state.themes.find(t => t.name.trim().toLowerCase() === this.state.themeName.trim().toLowerCase())){
        this.setState({themeError: "Theme already exists with current name."})
        return false;
      }
      else if(!this.checkColorsValid()){
        this.setState({themeError: "Cannot save a theme with invalid colors."})
        return false;
      }
      return true;
    }
    return false;
  }

  private saveNewTheme = async() => {
    let currentState = this.state.newsletter.newsletterTheme;
    if(this.checkValidNewTheme()) {
      let toSave:NewsletterTheme =
      {
        id: "00000000-0000-0000-0000-000000000000",
        name: this.state.themeName,
        isDefault: false,
        isCustom: true,
        emailBackground: currentState.emailBackground,
        primaryBackground: currentState.primaryBackground,
        primaryText: currentState.primaryText,
        secondaryBackground: currentState.secondaryBackground,
        secondaryText: currentState.secondaryText,
        linkText: currentState.linkText,
        createdBy: "",
        createdByDisplayName: "",
        createdOnUTC: new Date(),
        lastModifiedBy: "",
        lastModifiedByDisplayName: "",
        lastModifiedOnUTC: new Date(),

        //Done elsewhere and updated independently.
        headerImage: "",
        footerImage: "",
        themeHeaderImageIsCustomized: false,
        themeFooterImageIsCustomized: false,
      }

      //Update images
      let updatedTheme = await this.props.saveTheme(toSave);
      let headerImageEncoded = "";
      let footerImageEncoded = "";

      if(!!this.state.headerImage) {
        headerImageEncoded = (await getBase64(this.state.headerImage)) ?? "";
      }

      if(!!this.state.footerImage) {
        footerImageEncoded = (await getBase64(this.state.footerImage)) ?? "";
      }

      if(headerImageEncoded !== "" || footerImageEncoded !== "") {
        await this.props.saveThemeImages(updatedTheme.id, headerImageEncoded, footerImageEncoded);
      }
      return true;
    }
    return false;
  }

  private updateThemeAndMoveOn = () => {
    let result = this.updateTheme();
    if(result) {
      this.setState({successMessage: "Successfully updated theme."});
    }
    else {
      this.setState({errorMessage: "Could not update theme."});
    }
    this.saveNewsletter(this.state.newsletter);
    this.setState({preSaveWarningOpen: false, alreadySeenWarning: true});
  }

  private updateTheme = () => {
    let currentState = this.state.newsletter.newsletterTheme;
    let themeId = this.state.newsletter.newsletterTheme.themeId;
    let themeToUpdate = this.state.themes?.find(t => t.id === themeId) ?? null;
    if(themeToUpdate && this.checkValidExistingTheme()) {
      let toSave:NewsletterTheme =
      {
        id: this.state.newsletter.newsletterTheme.themeId,
        name: themeToUpdate.name,
        isDefault: themeToUpdate.isDefault,
        isCustom: true,
        emailBackground: currentState.emailBackground,
        primaryBackground: currentState.primaryBackground,
        primaryText: currentState.primaryText,
        secondaryBackground: currentState.secondaryBackground,
        secondaryText: currentState.secondaryText,
        linkText: currentState.linkText,
        createdBy: "",
        createdByDisplayName: "",
        createdOnUTC: new Date(),
        lastModifiedBy: "",
        lastModifiedByDisplayName: "",
        lastModifiedOnUTC: new Date(),

        //Done elsewhere and updated independently.
        headerImage: "",
        footerImage: "",
        themeHeaderImageIsCustomized: false,
        themeFooterImageIsCustomized: false,
      }
      this.props.saveTheme(toSave).then(theme => {
        if(this.state.themes) {
          let toReplaceIndex = this.state.themes.findIndex(t => t.id === theme.id);
          let themeCopy = [...this.state.themes];
          themeCopy[toReplaceIndex] = theme;
          this.setState({themes: themeCopy});
        }
      });
      return true;
    }
    return false;
  }

  private checkColorsValid = () => {
    let toCheck = this.state.newsletter.newsletterTheme;

    let hexEmailBackground = hexCodeFromText(toCheck.emailBackground);
    let hexPrimaryBackground = hexCodeFromText(toCheck.primaryBackground);
    let hexPrimaryText = hexCodeFromText(toCheck.primaryText);
    let hexSecondaryBackground = hexCodeFromText(toCheck.secondaryBackground);
    let hexSecondaryText = hexCodeFromText(toCheck.secondaryText);
    let hexLinkText = hexCodeFromText(toCheck.linkText);

    if(!hexEmailBackground || !hexPrimaryBackground || !hexPrimaryText || !hexSecondaryBackground || !hexSecondaryText || !hexLinkText) {
      return false;
    }
    return true;
  }

  private saveHeaderFooterImage(response: NewsletterDigest) {
    if (this.state.hasFooterImageChanged || this.state.hasHeaderImageChanged || this.state.themeHadHeaderOnLoad || this.state.themeHadFooterOnLoad) {

      if (this.state.hasHeaderImageChanged) {
        if (!this.state.headerImage)
          this.props.deleteNewsletterImage(response.id, HEADER);
      }

      if (this.state.hasFooterImageChanged) {
        if (!this.state.footerImage)
          this.props.deleteNewsletterImage(response.id, FOOTER);
      }

      const uploadImageModel: UploadNewsletterImageModel = {
        newsletterId: response.id,
        headerImage: this.state.headerImageBase64,
        footerImage: this.state.footerImageBase64,
      };

      this.props.uploadNewsletterHeaderFooterImage(uploadImageModel);
    }
  }

  private clearImage = (type: NewsletterImageType) => {
    if(type === 'Header') {
      this.setState({headerImage: null, headerImageBase64: undefined, hasHeaderImageChanged: true});
    }
    else if(type === 'Footer') {
      this.setState({footerImage: null, footerImageBase64: undefined, hasFooterImageChanged: true});
    }
  }
}

interface RouteParams {
  newsletterId?: string;
}

interface ComponentState {
  newsletter: SaveNewsletterModel;
  serverNewsletter?: NewsletterDetails;

  audiences?: Audience[];
  templates?: NewsletterTemplate[];
  themes?: NewsletterTheme[];
  selectedTemplate?: NewsletterTemplate;
  selectedTheme?: NewsletterTheme;
  savedTheme?: NewsletterTheme;

  modelErrors: SaveNewsletterModelStateErrors | null;
  successMessage?: string | null;
  errorMessage?: string | null;

  highlightTitleFields: boolean;
  highlightSubjectAndHeader: boolean;
  highlightSenderDetails: boolean;

  showTemplateSection: boolean;
  showAudienceFrequencySection: boolean;
  showNewsletterPreview?: boolean;
  showSendTestEmailDialog?: boolean;
  showUnsavedChangesDialogPriorTo?: 'onShowSendTestEmail' | null;
  showContentSection: boolean;

  headerImage?: File | null;
  headerImageBase64?: string;
  isLoadingHeaderImage?: boolean;
  hasHeaderImageChanged?: boolean;

  themeHadHeaderOnLoad?: boolean;
  themeHadFooterOnLoad?: boolean;

  footerImage?: File | null;
  footerImageBase64?: string;
  isLoadingFooterImage?: boolean;
  hasFooterImageChanged?: boolean;

  changedSinceSaved:boolean;
  isSaving: boolean;
  isDownloading?: boolean;

  selectedTab: number;

  addresses: Address[];
  originalAddresses: Address[];

  firstTime: boolean;
  saveTemplateOpen: boolean;
  templateXClicked: boolean;
  alreadySeenWarning: boolean;
  postWarningRedirect?: () => void;

  themeName: string;
  themeError: string;
  hasSavedTheme: boolean;
  preSaveWarningOpen: boolean;

  progressCheck: {
    isComplete: boolean;
    composeSectionIsComplete: boolean;
    titleSectionIsComplete: boolean;
    templateSectionIsComplete: boolean;
    audienceFrequencySectionIsComplete: boolean;
  };
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: RouteComponentProps<RouteParams>) => ({
    ...ownProps,
    notificationSettings: state.settings.notificationSettings,
    tenant: state.tenant.id,
    tenantSettings: state.settings.tenantSettings,
    tagSettings: state.settings.tagSettings
  }),
  {
    getNewsletterDetails: actions.getNewsletterDetails,
    getUniqueUsers: audiencesActions.fetchUniqueUserCount,
    getNewsletterImage: actions.getNewsletterImage,
    saveNewsletter: actions.saveNewsletter,
    getAudiences: audiencesActions.getAudiences,
    getTemplates: actions.getTemplates,
    getThemes: actions.getThemes,
    saveTheme: actions.saveTheme,
    getTemplateHtml: actions.getTemplateHtml,
    getTemplateImage: actions.getTemplateImage,
    deleteNewsletterImage: actions.deleteNewsletterImage,
    uploadNewsletterImage: actions.uploadNewsletterImage,
    uploadNewsletterHeaderFooterImage: actions.uploadNewsletterHeaderFooterImage,
    saveThemeImages: actions.saveThemeImages,
    redirectTo: push,
    getTagSettings: settingsActions.getTagSettings,
    getConfig: actions.getConfig,
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(EditNewsletterPage);
