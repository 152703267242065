import { Action } from "redux";
import { CompleteAction, ActionCreator } from "typedActions";
import { Audience, AudiencePage } from "./models";


export const GET_AUDIENCES_COMPLETE = "GET_AUDIENCES_COMPLETE";
export interface GetAudiencesComplete extends CompleteAction { audiences: Audience[] };
export const GetAudiencesComplete = ActionCreator<GetAudiencesComplete>(GET_AUDIENCES_COMPLETE);

export const GET_AUDIENCE_DETAILS = "GET_AUDIENCE_DETAILS";
export interface GetAudienceDetails extends Action {};
export const GetAudienceDetails = ActionCreator<GetAudienceDetails>(GET_AUDIENCE_DETAILS);

export const GET_AUDIENCE_DETAILS_COMPLETE = "GET_AUDIENCE_DETAILS_COMPLETE";
export interface GetAudienceDetailsComplete extends CompleteAction {};
export const GetAudienceDetailsComplete = ActionCreator<GetAudienceDetailsComplete>(GET_AUDIENCE_DETAILS_COMPLETE);

export const GET_ALL_AUDIENCES = "GET_ALL_AUDIENCES";
export interface GetAllAudiences extends Action {};
export const GetAllAudiences = ActionCreator<GetAllAudiences>(GET_ALL_AUDIENCES);

export const GET_ALL_AUDIENCES_COMPLETE = "GET_ALL_AUDIENCES_COMPLETE";
export interface GetAllAudiencesComplete extends CompleteAction { page: AudiencePage };
export const GetAllAudiencesComplete = ActionCreator<GetAllAudiencesComplete>(GET_ALL_AUDIENCES_COMPLETE);

export const GET_DISABLED_AUDIENCES = "GET_DISABLED_AUDIENCES";
export interface GetDisabledAudiences extends Action {};
export const GetDisabledAudiences = ActionCreator<GetDisabledAudiences>(GET_DISABLED_AUDIENCES);

export const GET_DISABLED_AUDIENCES_COMPLETE = "GET_DISABLED_AUDIENCES_COMPLETE";
export interface GetDisabledAudiencesComplete extends CompleteAction { page: AudiencePage };
export const GetDisabledAudiencesComplete = ActionCreator<GetDisabledAudiencesComplete>(GET_DISABLED_AUDIENCES_COMPLETE);

export const GET_ENABLED_AUDIENCES = "GET_ENABLED_AUDIENCES";
export interface GetEnabledAudiences extends Action {};
export const GetEnabledAudiences = ActionCreator<GetEnabledAudiences>(GET_ENABLED_AUDIENCES);

export const GET_ENABLED_AUDIENCES_COMPLETE = "GET_ENABLED_AUDIENCES_COMPLETE";
export interface GetEnabledAudiencesComplete extends CompleteAction { page: AudiencePage };
export const GetEnabledAudiencesComplete = ActionCreator<GetEnabledAudiencesComplete>(GET_ENABLED_AUDIENCES_COMPLETE);


export const CREATE_AUDIENCE_COMPLETE = "CREATE_AUDIENCE_COMPLETE";
export interface CreateAudienceComplete extends CompleteAction {};
export const CreateAudienceComplete = ActionCreator<CreateAudienceComplete>(CREATE_AUDIENCE_COMPLETE);

export const DELETE_AUDIENCE = "DELETE_AUDIENCE";
export interface DeleteAudience extends Action {};
export const DeleteAudience = ActionCreator<DeleteAudience>(DELETE_AUDIENCE);

export const DELETE_AUDIENCE_COMPLETE = "DELETE_AUDIENCE_COMPLETE";
export interface DeleteAudienceComplete extends CompleteAction {};
export const DeleteAudienceComplete = ActionCreator<DeleteAudienceComplete>(DELETE_AUDIENCE_COMPLETE);

export const DELETE_AUDIENCES = "DELETE_AUDIENCES";
export interface DeleteAudiences extends Action {};
export const DeleteAudiences = ActionCreator<DeleteAudiences>(DELETE_AUDIENCES);

export const DELETE_AUDIENCES_COMPLETE = "DELETE_AUDIENCES_COMPLETE";
export interface DeleteAudiencesComplete extends CompleteAction {};
export const DeleteAudiencesComplete = ActionCreator<DeleteAudiencesComplete>(DELETE_AUDIENCES_COMPLETE);

export const DISABLE_AUDIENCE = "DISABLE_AUDIENCE";
export interface DisableAudience extends Action {};
export const DisableAudience = ActionCreator<DisableAudience>(DISABLE_AUDIENCE);

export const DISABLE_AUDIENCE_COMPLETE = "DISABLE_AUDIENCE_COMPLETE";
export interface DisableAudienceComplete extends CompleteAction {};
export const DisableAudienceComplete = ActionCreator<DisableAudienceComplete>(DISABLE_AUDIENCE_COMPLETE);

export const DISABLE_AUDIENCES = "DISABLE_AUDIENCES";
export interface DisableAudiences extends Action {};
export const DisableAudiences = ActionCreator<DisableAudiences>(DISABLE_AUDIENCES);

export const DISABLE_AUDIENCES_COMPLETE = "DISABLE_AUDIENCES_COMPLETE";
export interface DisableAudiencesComplete extends CompleteAction {};
export const DisableAudiencesComplete = ActionCreator<DisableAudiencesComplete>(DISABLE_AUDIENCES_COMPLETE);

export const DOWNLOAD_USER_DATA = "DOWNLOAD_USER_DATA";
export interface DownloadUserData extends Action {};
export const DownloadUserData = ActionCreator<DownloadUserData>(DOWNLOAD_USER_DATA);

export const DOWNLOAD_USER_DATA_COMPLETE = "DOWNLOAD_USER_DATA_COMPLETE";
export interface DownloadUserDataComplete extends CompleteAction {};
export const DownloadUserDataComplete = ActionCreator<DownloadUserDataComplete>(DOWNLOAD_USER_DATA_COMPLETE);

export const ENABLE_AUDIENCE = "ENABLE_AUDIENCE";
export interface EnableAudience extends Action {};
export const EnableAudience = ActionCreator<EnableAudience>(ENABLE_AUDIENCE);

export const ENABLE_AUDIENCE_COMPLETE = "ENABLE_AUDIENCE_COMPLETE";
export interface EnableAudienceComplete extends CompleteAction {};
export const EnableAudienceComplete = ActionCreator<EnableAudienceComplete>(ENABLE_AUDIENCE_COMPLETE);

export const ENABLE_AUDIENCES = "ENABLE_AUDIENCES";
export interface EnableAudiences extends Action {};
export const EnableAudiences = ActionCreator<EnableAudiences>(ENABLE_AUDIENCES);

export const ENABLE_AUDIENCES_COMPLETE = "ENABLE_AUDIENCES_COMPLETE";
export interface EnableAudiencesComplete extends CompleteAction {};
export const EnableAudiencesComplete = ActionCreator<EnableAudiencesComplete>(ENABLE_AUDIENCES_COMPLETE);

export const SAVE_AUDIENCE_COMPLETE = "SAVE_AUDIENCE_COMPLETE";
export interface SaveAudienceComplete extends CompleteAction {};
export const SaveAudienceComplete = ActionCreator<SaveAudienceComplete>(SAVE_AUDIENCE_COMPLETE);


export const CHANGED_SINCE_SAVED = "CHANGED_SINCE_SAVED";
export interface ChangedSinceSaved extends Action {};
export const ChangedSinceSaved = ActionCreator<ChangedSinceSaved>(CHANGED_SINCE_SAVED);

export const CLEAR_CHANGED_SINCE_SAVED = "CLEAR_CHANGED_SINCE_SAVED";
export interface ClearChangedSinceSaved extends Action {};
export const ClearChangedSinceSaved = ActionCreator<ClearChangedSinceSaved>(CLEAR_CHANGED_SINCE_SAVED);

export const CLEAR_ERROR_MESSAGE = "CLEAR_ERROR_MESSAGE";
export interface ClearErrorMessage extends Action {};
export const ClearErrorMessage = ActionCreator<ClearErrorMessage>(CLEAR_ERROR_MESSAGE);

export const CLEAR_SHOULD_FETCH = "CLEAR_SHOULD_FETCH";
export interface ClearShouldFetch extends Action {};
export const ClearShouldFetch = ActionCreator<ClearShouldFetch>(CLEAR_SHOULD_FETCH);