import React from "react";

import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { SelectOption } from "modules/common/models";


interface DialogSelectFieldProps {
  description?: string;
  label: string;
  options: SelectOption[];
  value: string;
  disabled?: boolean;
  onChange: (event: any) => void;
}

const DialogSelectField: React.FunctionComponent<DialogSelectFieldProps> = props => {
  return (
    <div className="dialog-field">
      <div className="dialog-field-heading">
        <label>{props.label}</label>
      </div>
      <div>
        <FormControl variant="outlined" size="small" fullWidth>
          <Select
            value={props.value}
            fullWidth
            disabled={props.disabled}
            onChange={(event) => {
              if (event.target.value !== props.value)
                props.onChange(event);
            }}
          >
            {props.options.map((option) => <MenuItem key={option.key} value={option.key}>{option.text}</MenuItem>)}
          </Select>
          {props.description &&
            <Typography variant="caption">{props.description}</Typography>
          }
        </FormControl>
      </div>
    </div>
  );
}

export default DialogSelectField;