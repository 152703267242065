import { ICustomCssModelv1 } from "./files";
import { ApiRequest } from "./network";
import { SparrowApi } from "./network/adapters/SparrowApi";

export class AdminFilesApi {
    constructor(private _sparrowApi: SparrowApi) {}

    public getCustomTinyMceCss = (): ApiRequest<ICustomCssModelv1> => {
        return new ApiRequest<ICustomCssModelv1>(
            this._sparrowApi
                .TenantRequest()
                .get(`/{tenant}/adminapi/v1/files/css/tinymce`)
        );
    }

    public getCustomPortalPagesCss = (pageId: string): ApiRequest<ICustomCssModelv1> => {
        return new ApiRequest<ICustomCssModelv1>(
            this._sparrowApi
                .TenantRequest()
                .get(`/{tenant}/adminapi/v1/files/css/portalPages?portalPagesDraftId=${pageId}`)
        );
    }
}
