import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useIsMobileDevice } from "./useIsMobileDevice";
import cookies from "utils/cookie";
import { setMobileAppBannerOpen } from "modules/userPortalLayout/actionCreator";
import { GlobalApplicationState } from "globalApplicationState";

export const useOpenMobileAppBanner = () => {
    const { isMobile } = useIsMobileDevice();
    const userEmail = useSelector((state: GlobalApplicationState) => state.settings.currentUser.email);
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(setMobileAppBannerOpen(false));
        }
    }, [dispatch]);

    useEffect(() => {
        const shouldShow = isMobile && !cookies.getMobileAppBannerClosed(userEmail);
        dispatch(setMobileAppBannerOpen(shouldShow));
    }, [dispatch, userEmail, isMobile]);
}
