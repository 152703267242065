import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";

import { DocumentFeedItem } from "../../../models";

import InfoHover from "modules/common/components/hovers/infoHover";

import Divider from "@mui/material/Divider";
import moment from "moment";
import { dateOptions } from "utils/dateFormatting";


const DocumentViewMoreInfo: React.FunctionComponent<PropsWithRedux> = props => {
  const { view } = props;

  return (
    <div>
      <div className="document-info-properties">
        <div>
          <div>Document title</div>
          <div className="">
            {view.title || `${view.fileName}.${view.fileType}`}
          </div>
        </div>
      </div>
      {(!!view.description || !!view.lcid) &&
        <React.Fragment>
          <div className="document-info-properties">
            {!!view.description &&
              <div>
                <div>Description</div>
                <div>{view.description}</div>
              </div>
            }
            {!!view.lcid &&
              <div>
                <div>Language</div>
                <div>{props.lcidMappings[view.lcid].language}</div>
              </div>
            }
          </div>
          <Divider />
        </React.Fragment>
      }
      <div className="document-info-properties">
        <div>
          <div className="revision">
            <span>Version no.</span>
            <InfoHover>No. of times the file has been replaced. Version 1 is the initial file uploaded.</InfoHover>
          </div>
          <div>{view.revisions}</div>
        </div>
      </div>
      <Divider />
      <div className="document-info-properties">
        <div>
          <div>Last modified</div>
          <div className="">
            {moment(view.lastUpdatedDate).format(dateOptions.basicFormatting)}
          </div>
        </div>
      </div>
      {!!view.tags && !!view.tags.length &&
        <React.Fragment>
          <div className="document-info-properties">
            <div>
              <div>Category tags</div>
              <div className="category-tags">
                {view.tags.map(tag => tag.name).join(", ")}
              </div>
            </div>
          </div>
        </React.Fragment>
      }
    </div>
  );
}


interface ComponentProps {
  view: DocumentFeedItem;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    lcidMappings: state.resources.lcidMappings
  })
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(DocumentViewMoreInfo);