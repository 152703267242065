import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";

import EventTypeBanner from "modules/common/components/banners/eventTypeBanner";

import { EventFeedItem } from "../../../../../models";

import Attendance from "../../../../attendance/attendance";
import DateBlock from "../../../../date-block/dateBlock";

import FullStartDate from "../../dates/FullStartDate";


const Event: React.FunctionComponent<PropsWithRedux> = props => {
  const { event, onEventSelected } = props;

  if (!event) {
    return (null);
  }

  const selectEvent = () => onEventSelected ? onEventSelected(event) : null;
  const excerptToDisplay = event.excerpt.length > 297 ? event.excerpt.substring(0, 297) + "..." : event.excerpt;

  return (
    <div onClick={selectEvent} className="event">
      <div className="image-container">
        <div 
          style={{ 
            backgroundColor: event.imageUrl ? "#DDE1E5" : event.bannerColor, 
            backgroundImage: event.imageUrl ? 'url("' + event.imageUrl + '")' : "", 
            backgroundSize: "contain" 
          }} 
          className="image"
        >
          <EventTypeBanner eventType={event.eventType} isRestricted={event.isRestricted} lcid={event.preferredLCID} />
          <DateBlock eventStartTime={event.eventStartTime} eventEndTime={event.eventEndTime} isAllDayEvent={event.isAllDayEvent} />
        </div>
      </div>
      <div className="info">
        <div className="details">
          <div className="title">{event.title}</div>
          <FullStartDate eventStartTime={event.eventStartTime} eventEndTime={event.eventEndTime} />
          <div className="summary">{excerptToDisplay}</div>
        </div>
        <Attendance {...event} />
      </div>
    </div>
  );
}

interface ComponentProps {
  event: EventFeedItem;
  onEventSelected: (event: EventFeedItem) => void;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    currentUser: state.settings.currentUser
  })
);
type PropsWithRedux = ConnectedProps<typeof connector>;
export default connector(Event);