import { useMemo, useState } from "react";

export type UndoableStateReturnType<T> = {
    value: T;
    setValue: (newValue: T) => void;
    undoValue: () => T;
    undoable: boolean;
}

interface IUseUndoableState<T> {
    historyIdx: number;
    history: T[];
    curr: T;
}

/**
 * Undoable state value
 * @param init - default value
 * @returns - [value, setValue, undoValue, undoable (enabled)]
 * - adadpted from https://github.com/jzcling/react-use-undoable-state
 */
function useUndoableState<T>(init: T): UndoableStateReturnType<T> {
    const [state, setState] = useState<IUseUndoableState<T>>({
        historyIdx: 0,
        history: [init],
        curr: init
    });

    const {
        history,
        historyIdx,
        curr
    } = state;

    const undoable = useMemo(() => historyIdx !== 0, [historyIdx]);

    /**
     * Handle value changes
     * - remove all changes after current index
     * - add new value to history array
     * - set curr index to end of history array
     * - set curr value to new value
     * @param value - new value
     */
    const onChangeValue = (value: T) => {
        let newHistory = [...history];

        // this cleans the top of the stack by removing all the changes that happened after our current position
        newHistory.length = historyIdx + 1;
        newHistory.push(value);

        setState({
            ...state,
            history: newHistory,
            historyIdx: newHistory.length - 1,
            curr: value
        });
    }

    /**
     * Handle undo changes
     * - move index back one
     * - set curr to history[newIndex]
     * @returns the new curr value
     */
    const onUndo = (): T => {
        const newIndex = Math.max(0, historyIdx - 1);

        setState({
            ...state,
            historyIdx: newIndex,
            curr: history[newIndex]
        });

        return history[newIndex];
    }

    return { value: curr, setValue: onChangeValue, undoValue: onUndo, undoable };
}

export { useUndoableState };
