/**
 * Helper function to open a post in private user portal
 * @param tenantId
 * @param postId
 */
const openPostInPortal = (tenantId: string, postId: string) => {
    const hrefToOpen = `/${tenantId}/posts/${postId}`;
    const url = `${window.location.origin}${hrefToOpen}`;
    const newWindow = window.open(url, "_blank");

    if (newWindow !== null)
        newWindow.focus();
}

export { openPostInPortal };
