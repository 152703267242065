import * as React from "react";

import Label from "./common/label";

import Switch from "@mui/material/Switch";


const MuiToggle: React.FunctionComponent<{ input, meta, field }> = props => {
  return (
    <div className="mui-toggle">
      <Label>{props.field.label}</Label>
      <Switch
        color="primary"
        checked={!!props.input.value}
        onChange={(ev, checked) => props.input.onChange(checked)}
      />
    </div>
  );
}

export default MuiToggle;