import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";

import * as settingsActions from "modules/settings/actionCreator";

import Callout from "modules/common/components/callout";
import { Tag } from "modules/common/components/authoring/models";

import Description from "./common/description";
import Label from "./common/label";
import PluginWrapper from "./common/pluginWrapper";

import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";

import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import LockIcon from "@mui/icons-material/Lock";


interface TagList {
  id: string;
  name: string;
  tags: Tag[];
}


class SelectTopics extends React.Component<PropsWithRedux, ComponentState> {
  constructor(props: PropsWithRedux) {
    super(props);

    this.state = {
      anchorEl: null,
      calloutOpen: false,
      tags: props.input.value,
      tagsList: this.getTagsList()
    };
  }

  public componentDidMount() {
    if (this.props.tagSettings.shouldFetch || !this.props.tagSettings.tagGroups.length)
      this.props.getUserTagSettings();
  }

  public componentDidUpdate(prevProps: PropsWithRedux) {
    if (this.props.tagSettings.tagGroups.length !== prevProps.tagSettings.tagGroups.length) {
      this.setState({ tagsList: this.getTagsList() });
    }
  }

  public render() {
    return (
      <PluginWrapper>
        <Label>
          {this.props.field.label}
          {this.props.field.description &&
            <Description>{this.props.field.description}</Description>
          }
        </Label>
        <div className="items-list">
          {this.props.input.value.map((tag) =>
            <Paper key={tag.id} className="item-bubble">
              <span>{tag.name}</span>
              <IconButton size="small" onClick={() => this.onRemoveTag(tag)}>
                <ClearIcon fontSize="small" />
              </IconButton>
            </Paper>
          )}
        </div>
        <Button variant="text" color="primary" startIcon={<AddIcon fontSize="small" />} onClick={this.onToggleTagsList} className="add-text-button">Add topics</Button>
        <Callout
          anchorEl={this.state.anchorEl}
          open={this.state.calloutOpen}
          setOpen={(toSet) => {
            this.onApply();
            this.setState({calloutOpen: toSet});
          }}
          footer={
            <div className="clear-content">
              <Button variant="text" color="primary" onClick={this.onClear}>Clear all</Button>
            </div>
          }
        >
          <List disablePadding className="portal-page-callout">
            {this.state.tagsList.map((tagGroup) => {
              const tagGroupChecked: boolean = tagGroup.tags.filter((tag) => !!this.state.tags.find((selectedTag) => selectedTag.id === tag.id)).length === tagGroup.tags.length;
              return (
                <React.Fragment key={tagGroup.id}>
                  <ListItem dense button onClick={() => this.onSelectTagGroup(tagGroup, !tagGroupChecked)}>
                    <ListItemIcon className="callout-checkbox">
                      <Checkbox
                        edge="start"
                        tabIndex={-1}
                        disableRipple
                        size="small"
                        color="primary"
                        checked={tagGroupChecked}
                      />
                    </ListItemIcon>
                    <ListItemText primary={tagGroup.name} classes={{ primary: "items-list-item-group-text" }} />
                  </ListItem>
                  <List disablePadding className="items-list-items">
                    {tagGroup.tags.map((tag) =>
                      <ListItem key={tag.id} dense button onClick={() => this.onSelectTag(tag)}>
                        <ListItemIcon className="callout-checkbox">
                          <Checkbox
                            edge="start"
                            tabIndex={-1}
                            disableRipple
                            size="small"
                            color="primary"
                            checked={!!this.state.tags.find((selectedTag) => selectedTag.id === tag.id)}
                          />
                        </ListItemIcon>
                        <ListItemText primary={tag.name} className="items-list-text" />
                        {tag.restricted && <LockIcon fontSize="small" className="restricted-tag-icon" />}
                      </ListItem>
                    )}
                  </List>
                </React.Fragment>
              );
            })}
          </List>
        </Callout>
      </PluginWrapper>
    );
  }


  private getTagsList = (): TagList[] => {
    let tagsList: TagList[] = [];

    this.props.tagSettings.tagGroups.map((tagGroup) => {
      const tags: Tag[] = tagGroup.tags.filter((tag) => !tag.disabled);
      if (!!tags.length) {
        tagsList.push({
          id: tagGroup.id,
          name: tagGroup.name,
          tags: tags
        });
      }
      return tagGroup;
    });

    return tagsList;
  }


  private onApply = () => {
    this.props.input.onChange(this.state.tags);
    this.onCloseTagsList();
  }

  private onClear = () => {
    this.props.input.onChange([]);
    this.setState({ ...this.state, anchorEl: null, tags: [] });
  }

  private onCloseTagsList = () => {
    this.setState({ ...this.state, anchorEl: null, calloutOpen: false });
  }

  private onOpenTagsList = (event: any) => {
    this.setState({ ...this.state, anchorEl: event.currentTarget, tags: this.props.input.value, calloutOpen: true });
  }

  
  private onRemoveTag = (tag: Tag) => {
    this.props.input.onChange(this.props.input.value.filter((selectedTag) => selectedTag.id !== tag.id));
  }

  private onSelectTag = (tag: Tag) => {
    const hasSelectedTag: boolean = !!this.state.tags.find((selectedTag) => selectedTag.id === tag.id);
    if (hasSelectedTag)
      this.setState({ ...this.state, tags: this.state.tags.filter((selectedTag) => selectedTag.id !== tag.id) });
    else
      this.setState({ ...this.state, tags: this.state.tags.concat([{ id: tag.id, name: tag.name }]) });
  }

  private onSelectTagGroup = (tagGroup: TagList, checked: boolean) => {
    let tags: Tag[] = this.state.tags.slice();

    if (checked) {
      tagGroup.tags.map((tag) => {
        tags.push(tag);
        return tag;
      });
      tags = tags.filter((tag) => tag);
    } else {
      tagGroup.tags.map((tag) => {
        tags = tags.filter((tagToFilter) => tagToFilter.id !== tag.id);
        return tag;
      });
    }

    this.setState({ ...this.state, tags });
  }


  private onToggleTagsList = (event: any) => {
    if (!!this.state.anchorEl) { 
      this.onCloseTagsList();
    }
    else {
      this.onOpenTagsList(event);
    }
  }
}


interface ComponentProps {}

interface ComponentState {
  anchorEl: any;
  calloutOpen: boolean;
  tags: Tag[];
  tagsList: TagList[];
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    tagSettings: state.settings.tagSettings
  }),
  {
    getUserTagSettings: settingsActions.getUserTagSettings
  }
);
type PropsWithRedux = { input, meta, field } & ConnectedProps<typeof connector>;

export default connector(SelectTopics);