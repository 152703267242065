import * as React from "react";
import * as galleryActions from "modules/gallery/actionCreator";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";

import Loading from "modules/common/components/loading";

import { Document } from "modules/documents/models";
import { AttachedFile } from "modules/gallery/models";
import Uploader from "../uploader/uploader";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import Pagination from '@mui/material/Pagination';

import CloseIcon from "@mui/icons-material/Close";
import DocumentGallerySearch from "./documentGallerySearch";
import { SortStyle } from "utils/managementUtils";
import ManagementEmptyResults from "../../managementEmptyResults";

class DocumentGallery extends React.Component<PropsWithRedux, ComponentState> {
  private MAX_SELECTABLE_FILES: number = 20;

  constructor(props: PropsWithRedux) {
    super(props);

    this.state = {
      isUploading: false,
      selectableFiles: this.getSelectableFiles(),
      selectedFiles: this.getSelectedFiles(),
      selectedTab: 0,
      sortStyle: SortStyle.titleAsc,
      searchText: ""
    };
  }

  componentDidUpdate(prevProps: PropsWithRedux) {
    if (this.props.show && !prevProps.show) {
      this.setState({
        ...this.state,
        selectableFiles: this.getSelectableFiles(),
        selectedFiles: this.getSelectedFiles(),
        selectedTab: 0
      });

      this.props.getDocumentGallery(1, "", this.state.sortStyle);
    }
  }

  public render() {
    const { documentGallery } = this.props;

    return (
      <Dialog open={this.props.show} maxWidth={false} onClose={this.onClose}>
        <DialogTitle className="events-dialog-header">
          <div className="events-dialog-header-title">
            <div>
              <Typography variant="h2">Select Documents</Typography>
            </div>
            <IconButton onClick={this.onClose} size="large">
              <CloseIcon />
            </IconButton>
          </div>
          <Tabs
            value={this.state.selectedTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={this.onSelectTab}
          >
            <Tab label="Use existing media" />
            <Tab label="Upload" />
          </Tabs>
        </DialogTitle>
        <DialogContent dividers className="authoring-gallery-content" style={{minHeight: "645px"}}>
          {this.state.selectedTab === 0 &&
            <React.Fragment>
              <DocumentGallerySearch 
                sortStyle={this.state.sortStyle}
                onChangeSort={this.onChangeSort}
                onUpdateText={this.onChangeText}
                text={this.state.searchText}
                disabled={documentGallery.isFetching}
              />
              {documentGallery.isFetching
                ? <Loading padding={12} />
                :
                  <div>
                    {documentGallery.totalDocuments !== 0
                    ? <div className="authoring-gallery-items authoring-gallery-documents">
                      {documentGallery.documents.map((document) => {
                        const isSelectedIndex: number = this.state.selectedFiles.findIndex((selectedFile) => selectedFile.ospreyId === document.id);
                        const isSelected: boolean = isSelectedIndex > -1;
                        return (
                          <div key={document.id} onClick={() => this.onSelectDocument(document, !isSelected)}>
                            <div className={`authoring-gallery-document ${isSelected ? "item-selected" : ""}`}>
                              <img src={`/images/icons/documents/${document.fileType}.png`} alt={document.fileType} />
                              {isSelected &&
                                <div className="item-selected-number">{isSelectedIndex + 1}</div>
                              }
                            </div>
                            <div className="document-name">{document.title || `${document.fileName}.${document.fileType}`}</div>
                          </div>
                        );
                      })}
                    </div>
                    : <div> 
                      <ManagementEmptyResults searchType="matches" hasFilters={false} height="60vh" hidePaging/>
                    </div> 
                    }
                    <Pagination color="primary" count={documentGallery.totalPages} page={documentGallery.currentPage} boundaryCount={2} showFirstButton showLastButton onChange={this.onGetDocumentGalleryPage} />
                  </div>
              }
            </React.Fragment>
          }
          {this.state.selectedTab === 1 &&
            <Uploader
              uploaderMessage={"Choose or drag up to 18 files here"}
              maxUploadable={this.state.selectableFiles}
              uploading={this.state.isUploading}
              setUploading={this.onSetUploading}
              upload={this.onUpload}
              onAllComplete={this.onClose}
              attachmentType="files"
              acceptedFiles="application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/jpeg,application/pdf,image/png,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            />
          }
        </DialogContent>
        <DialogActions className="events-dialog-footer">
          <Button variant="text" color="primary" onClick={this.onClose}>Cancel</Button>
          <Button variant="contained" color="primary" disabled={!this.state.selectedFiles.length} onClick={this.onApply}>Apply</Button>
        </DialogActions>
      </Dialog>
    );
  }

  private getSelectableFiles = (): number => {
    return this.MAX_SELECTABLE_FILES - (this.props.fileAttachments || []).length;
  }

  private onChangeSort = (sort: SortStyle) => {
    this.setState({sortStyle: sort});
    this.onGetDocumentGalleryPage(null, 1, this.state.searchText, sort);
  }

  private onChangeText = (inputText: string) => {
    this.setState({searchText: inputText});
    this.onGetDocumentGalleryPage(null, 1, inputText, this.state.sortStyle);
  }

  private getSelectedFiles = (): AttachedFile[] => {
    return this.props.fileAttachments || [];
  }

  private onApply = () => {
    this.props.onApply(this.state.selectedFiles);
    this.onClose();
  }

  private onClose = () => {
    this.props.onClose();
  }

  private onGetDocumentGalleryPage = (ev, pageNumber: number, text?: string, sort?: SortStyle) => {
    let textToUse = text ?? this.state.searchText;
    let sortToUse = sort ?? this.state.sortStyle;

    this.props.getDocumentGallery(pageNumber, textToUse, sortToUse);
  }

  private onSelectDocument = (document: Document, select: boolean) => {
    if (select) {
      const selectedFile: AttachedFile = {
        blobId: document.id,
        fileExtension: document.fileType,
        fileName: document.title || `${document.fileName}.${document.fileType}`,
        fileType: "application",
        fileUrl: "",
        ospreyId: document.id
      };
      if (this.state.selectableFiles > 0)
        this.setState({ selectedFiles: this.state.selectedFiles.concat([selectedFile]) });
    } else {
      this.setState({ selectedFiles: this.state.selectedFiles.filter((selectedImage) => selectedImage.ospreyId !== document.id) });
    }
  }

  private onSelectTab = (ev, tabIndex: number) => {
    this.setState({ ...this.state, selectedTab: tabIndex });
  }

  private onSetUploading = (isUploading: boolean) => {
    this.setState({ ...this.state, isUploading });
  }

  private onUpload = (file: File, onProgress, onLoad, onError, onAbort): Promise<any> => {
    return this.props.uploadDocument(file, onProgress, onLoad, onError, onAbort).then((document) => {
      const fileAttachments: AttachedFile[] = this.props.fileAttachments || [];
      this.props.onApply(fileAttachments.concat([{
        blobId: document.blobId,
        fileUrl: "",
        fileName: document.fileName,
        fileType: "application",
        fileExtension: document.fileType,
        ospreyId: document.id
      }]));
    });
  }
}


interface ComponentProps {
  show: boolean;
  fileAttachments?: AttachedFile[];
  onApply: (fileAttachments: AttachedFile[]) => void;
  onClose: () => void;
}

interface ComponentState {
  isUploading: boolean;
  selectableFiles: number;
  selectedFiles: AttachedFile[];
  selectedTab: number;
  sortStyle: SortStyle;
  searchText: string;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    documentGallery: state.gallery.documentGallery
  }), {
    getDocument: galleryActions.getDocument,
    getDocumentGallery: galleryActions.getDocumentGallery,
    uploadDocument: galleryActions.uploadDocument
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(DocumentGallery);
