
import { UserRoles } from "modules/authorization/models";
import { CurrentUserSettings } from "modules/settings/models";


export const getRole = (userSettings: CurrentUserSettings) => {
  if (userSettings.roles.indexOf(UserRoles.Owner) >= 0)
    return "Owner";
  else if (userSettings.roles.indexOf(UserRoles.Author) >= 0)
    return "Author";
  else if (userSettings.roles.indexOf(UserRoles.Contributor) >= 0)
    return "Contributor";
  return "User";
}