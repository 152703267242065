import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import * as actions from "modules/events/actionCreator";

import IconButton from "@mui/material/IconButton";

import SearchIcon from "@mui/icons-material/Search";


const FilterIcon: React.FunctionComponent<PropsWithRedux> = props => {
  return (
    <IconButton
      color="primary"
      disabled={props.fetching || props.shouldFetch}
      onClick={() => props.setShowEventFeedFilters(true)}
      size="large">
      <SearchIcon />
    </IconButton>
  );
}

interface ComponentProps {}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    fetching: state.events.eventsFeed.fetching,
    shouldFetch: state.events.eventsFeed.shouldFetch
  }),
  {
    setShowEventFeedFilters: actions.setShowEventFeedFilters
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(FilterIcon);