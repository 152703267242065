import * as Actions from "./actions";
import { GlobalApplicationState } from "globalApplicationState";
import { CategoryTagDetails, CategoryTagModel, CategoryTagsListingPage } from "./models";
import API from "api";
import { CategoryTagFilter, CategoryTagsListItem } from ".";
import { defaultCategoryTagPage } from "./reducer";


export const showNewCategoryTag = () => (dispatch, getState: () => GlobalApplicationState): void => {
    dispatch(Actions.ShowNewCategoryTagAction({}));
}

export const hideNewCategoryTag = () => (dispatch, getState: () => GlobalApplicationState): void => {
    dispatch(Actions.HideNewCategoryTagAction({}));
}

export const showViewCategoryTag = () => (dispatch, getState: () => GlobalApplicationState): void => {
    dispatch(Actions.ShowViewCategoryTag({}));
}

export const hideViewCategoryTag = () => (dispatch, getState: () => GlobalApplicationState): void => {
    dispatch(Actions.HideViewCategoryTag({}));
}

export const showEditCategoryTag = () => (dispatch, getState: () => GlobalApplicationState): void => {
    dispatch(Actions.ShowEditCategoryTagAction({}));
}

export const hideEditCategoryTag = () => (dispatch, getState: () => GlobalApplicationState): void => {
    dispatch(Actions.HideEditCategoryTagAction({}));
}

export const clearErrorMessage = () => (dispatch, getState: () => GlobalApplicationState): void => {
    dispatch(Actions.ClearErrorMessage({}));
}

export const addNewTagAction = (toUpload: CategoryTagModel) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.CreateAddNewCategoryTagAction({toUpload}));

    return dispatch(API.categoryTags.CreateCategoryTag(toUpload))
    .then(response => {
        dispatch(Actions.CreateAddNewCategoryTagComplete({ succeeded: response.status === 200}));
    })
    .catch(err => {
        dispatch(Actions.CreateAddNewCategoryTagComplete({ succeeded: false, err}));
        return false;
    })
} 

export const editTagAction = (toUpload: CategoryTagModel) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.EditCategoryTagAction({toUpload}));

    return dispatch(API.categoryTags.EditCategoryTag(toUpload))
    .then(response => {
        dispatch(Actions.EditCategoryTagComplete({ succeeded: response.status === 200}));
    })
    .catch(err => {
        dispatch(Actions.EditCategoryTagComplete({ succeeded: false, err}));
        return false;
    })
}

export const clearShouldFetch = () => (dispatch, getState: () => GlobalApplicationState): void => {
    dispatch(Actions.ClearShouldFetch({}));
}

export const getCategoryTags = (pageNumber: number, filters: Partial<CategoryTagFilter>) => (dispatch, getState: () => GlobalApplicationState): Promise<CategoryTagsListingPage> => {
    dispatch(Actions.GetCategoryTags({}));

    return dispatch(API.categoryTags.GetCategoryTags(pageNumber, filters))
      .then(response => response.json())
      .then(page => {
        dispatch(Actions.GetCategoryTagsComplete({ succeeded: true, page }));
        return page;
    })
      .catch(err => {
        dispatch(Actions.GetCategoryTagsComplete({ succeeded: false, page: defaultCategoryTagPage, err }));
        return;
    });
}
  
  export const getDisabledCategoryTags = (pageNumber: number, filters: Partial<CategoryTagFilter>) => (dispatch, getState: () => GlobalApplicationState): Promise<CategoryTagsListingPage> => {
    dispatch(Actions.GetDisabledCategoryTags({}));

    return dispatch(API.categoryTags.GetDisabledCategoryTags(pageNumber, filters))
      .then(response => response.json())
      .then(page => {
        dispatch(Actions.GetDisabledCategoryTagsComplete({ succeeded: true, page }));
        return page;
    })
      .catch(err => {
        dispatch(Actions.GetDisabledCategoryTagsComplete({ succeeded: false, page: defaultCategoryTagPage, err }));
        return;
    });
}
  
export const getEnabledCategoryTags = (pageNumber: number, filters: Partial<CategoryTagFilter>) => (dispatch, getState: () => GlobalApplicationState): Promise<CategoryTagsListingPage> => {
    dispatch(Actions.GetEnabledCategoryTags({}));

    return dispatch(API.categoryTags.GetEnabledCategoryTags(pageNumber, filters))
      .then(response => response.json())
      .then(page => {
        dispatch(Actions.GetEnabledCategoryTagsComplete({ succeeded: true, page }));
        return page;
    })
      .catch(err => {
        dispatch(Actions.GetEnabledCategoryTagsComplete({ succeeded: false, page: defaultCategoryTagPage, err }));
        return;
    });
}

export const getUserCategoryTags = () => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
    return dispatch(API.categoryTags.GetUserCategoryTags())
      .then(response => response.json())
      .then(categoryTags => {
        dispatch(Actions.GetUserCategoryTagsComplete({ succeeded: true, categoryTags: categoryTags.map(tag => ({ id: tag.Id, name: tag.Title })) }));
        return true;
      })
      .catch(err => {
        dispatch(Actions.GetUserCategoryTagsComplete({ succeeded: false, categoryTags: [], err }));
        return false;
      });
  }

export const deleteCategoryTag = (id: string) => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
    dispatch(Actions.DeleteCategoryTag({}));

    return dispatch(API.categoryTags.DeleteCategoryTag(id))
      .then(response => {
        dispatch(Actions.DeleteCategoryTagComplete({ succeeded: response.status === 200 }));
        return response.status === 200;
      })
      .catch(err => {
        dispatch(Actions.DeleteCategoryTagComplete({ succeeded: false, err }));
        return false;
      });
}

export const deleteCategoryTags = (categoryTags: CategoryTagsListItem[]) => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
    dispatch(Actions.DeleteCategoryTags({}));

    return Promise.all(categoryTags.map(categoryTag => 
        dispatch(API.categoryTags.DeleteCategoryTag(categoryTag.id))
    ))
    .then(response => {
        dispatch(Actions.DeleteCategoryTagsComplete({ succeeded: true }));
        return true;
    })
    .catch(err => {
        dispatch(Actions.DeleteCategoryTagsComplete({ succeeded: false, err  }));
        return false;
    });
}

export const disableCategoryTag = (id: string) => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
    dispatch(Actions.DisableCategoryTag({}))

    return dispatch(API.categoryTags.ChangeCategoryTagState(false, id))
    .then(response => {
        dispatch(Actions.DisableCategoryTagComplete({ succeeded: response.status === 200 }));
        return response.status === 200;
      })
      .catch(err => {
        dispatch(Actions.DisableCategoryTagComplete({ succeeded: false, err }));
        return false;
    });
}

export const enableCategoryTag = (id: string) => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
    dispatch(Actions.EnableCategoryTag({}))

    return dispatch(API.categoryTags.ChangeCategoryTagState(true, id))
    .then(response => {
        dispatch(Actions.EnableCategoryTagComplete({ succeeded: response.status === 200 }));
        return response.status === 200;
      })
      .catch(err => {
        dispatch(Actions.EnableCategoryTagComplete({ succeeded: false, err }));
        return false;
    });
}

export const getCategoryTagDownload = (id: string) => (dispatch, getState: () => GlobalApplicationState): Promise<string> => {
    dispatch(Actions.GetCategoryTagDownload({}))

    return dispatch(API.categoryTags.GetCategoryTagDownload(id))
    .then(response => {
        return response.json();
      })
      .catch(err => {
        return "";
    });
}

export const getCategoryTagDownloadFromAnalytics = (toDownload: CategoryTagDetails) => (dispatch, getState: () => GlobalApplicationState): Promise<string> => {
    dispatch(Actions.GetCategoryTagDownloadFromAnalytics({}))

    return dispatch(API.categoryTags.GetCategoryTagDownloadFromAnalytics(toDownload))
    .then(response => {
        return response.json();
    })
    .catch(err => {
        return "";
    })
}

export const setTagToEdit = (toEdit: CategoryTagsListItem) => (dispatch, getState: () => GlobalApplicationState): void => {
    dispatch(Actions.SetTagToEdit({toEdit}))
}

export const setTagToView = (id: string, tag: CategoryTagsListItem | undefined) => (dispatch, getState: () => GlobalApplicationState): void => {
    dispatch(Actions.SetTagToView({id, tag}));
}

export const loadCategoryTagAnalytics = (id: string) => (dispatch, getState: () => GlobalApplicationState): void => {
    dispatch(Actions.LoadCategoryTagAnalytics({}))

    return dispatch(API.categoryTags.GetCategoryTagAnalytics(id))
    .then(response => {
        dispatch(Actions.LoadCategoryTagAnalyticsComplete({ succeeded: response.status === 200 }));
        return response.json();
    })
    .catch(err => {
        dispatch(Actions.LoadCategoryTagAnalyticsComplete({ succeeded: false, err }));
        return null;
    })
}

export const downloadAllCategoryTags = () => (dispatch, getState: () => GlobalApplicationState): Promise<string> => {
    dispatch(Actions.DownloadAllCategoryTags({}))

    return dispatch(API.categoryTags.DownloadAllCategoryTags())
    .then(response => {
        return response.json();
    })
    .catch(err => {
        return "";
    })
}

export const downloadCategoryTags = (ids: string[]) => (dispatch, getState: () => GlobalApplicationState): Promise<string> => {
    dispatch(Actions.DownloadCategoryTags({}));

    return dispatch(API.categoryTags.DownloadCategoryTags(ids))
    .then(response => {
        return response.json();
    })
    .catch(err => {
        return "";
    })
}