import React from "react";

import NavigationItems from "modules/portalPages/global-components/navigation-items/navigationItems";


const Navigation: React.FunctionComponent<{ data: any } & ComponentProps> = props => {
  return (
    <NavigationItems isInEditMode={props.isInEditMode} />
  );
};

export const NavigationBlock: React.FunctionComponent<{ data: any } & ComponentProps> = props => {
  return (
    <Navigation {...props} />
  );
};

interface ComponentProps {
  isInEditMode?: boolean;
}