import { GlobalApplicationState } from "globalApplicationState"
import * as actions from "./actions";

export const setMainStyle = (newStyle?: React.CSSProperties) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(actions.CreateSetMainStyle({ newStyle }));
}

export const setHeaderStyle = (newStyle?: React.CSSProperties) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(actions.CreateSetHeaderStyle({ newStyle }));
}

export const setShouldDisplayNav = (shouldDisplay: boolean) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(actions.CreateSetShouldDisplayNav({ shouldDisplay }));
}
