import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";

// import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";

import CloseIcon from "@mui/icons-material/Close";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";


const RequestAccess: React.FunctionComponent<PropsWithRedux> = props => {
  return (
    <Dialog
      open={props.show}
      maxWidth={false}
      scroll="paper"
      onClose={props.onClose}
      classes={{ paper: "document-request-access" }}
    >
      <div className="close-command">
        <IconButton onClick={props.onClose} size="large">
          <CloseIcon />
        </IconButton>
      </div>
      <div className="request-access-image">
        <LockOutlinedIcon color="primary" className="request-access-icon" />
      </div>
      <div className="request-access-title">Request Access</div>
      <div className="request-access-message">Your account does not have access to this document. You can request access from your administrator.</div>
      {/* TODO: Waiting for the backend */}
      {/* <div className="request-access-option">
        <Button variant="contained" color="primary">Request access</Button>
      </div> */}
    </Dialog>
  );
}


interface ComponentProps {
  show: boolean
  onClose: () => void;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps
  })
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(RequestAccess);