import * as React from "react";

import { Questions as IQuestions } from "../../../models";

import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Collapse from "@mui/material/Collapse";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";

import AddIcon from "@mui/icons-material/Add";
import BlockIcon from "@mui/icons-material/Block";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";


class Questions extends React.Component<ComponentProps, ComponentState> {
  constructor(props: ComponentProps) {
    super(props);

    this.state = {
      newQuestion: "",
      requireNewQuestion: false,
      showNewQuestion: false
    };
  }

  componentDidUpdate(prevProps: ComponentProps) {
    if (!prevProps.show && this.props.show) {
      this.setState({ ...this.state, newQuestion: "", requireNewQuestion: false, showNewQuestion: false });
    }
  }

  public render() {
    const { questions } = this.props;
    const questionNumbers: string[] = !!questions ? Object.keys(questions) : [];

    return (
      <Collapse in={this.props.show}>
        <Paper id="questions" elevation={0} className="section-card">
          <div className="optional-content-header">
            <div>Questions</div>
            <div className="remove-optional-content">
              <Button variant="text" color="inherit" size="small" startIcon={<BlockIcon fontSize="small" />} onClick={this.props.onRemove}>Remove All Questions</Button>
            </div>
          </div>
          {!!questionNumbers.length && questionNumbers.map((questionNumber) =>
            <Paper key={questionNumber} elevation={1} className="question">
              <div>{questions![questionNumber].body}</div>
              <div>
                {questions![questionNumber].isRequired && <div className="question-required">*Response required</div>}
                <IconButton size="small" onClick={() => this.onRemoveQuestion(questionNumber)}><DeleteIcon /></IconButton>
              </div>
            </Paper>
          )}
          <div className={`new-question${this.state.showNewQuestion ? " expanded" : ""}`}>
            <div>
              <TextField variant="outlined" size="small" value={this.state.newQuestion} placeholder="Enter a question" fullWidth onKeyUp={this.onKeyUp} onChange={this.onChangeNewQuestion} />
              <IconButton color="primary" size="small" onClick={this.onAddNewQuestion}><DoneIcon /></IconButton>
              <IconButton size="small" onClick={this.onHideNewQuestion}><ClearIcon /></IconButton>
            </div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.requireNewQuestion}
                  onChange={this.onChangeRequireNewQuestion}
                  color="primary"
                />
              }
              label="Require response to this question"
              className="new-question-required"
            />
          </div>
          {!this.state.showNewQuestion && questionNumbers.length < 3 &&
            <Button variant="text" color="primary" size="small" startIcon={<AddIcon fontSize="small" />} onClick={this.onShowNewQuestion} className="add-new-item">Add Question</Button>
          }
          <div className="optional-content-disclaimer">Note: Questions can be answered with text responses only</div>
        </Paper>
      </Collapse>
    );
  }

  private onAddNewQuestion = () => {
    const { questions } = this.props;
    const newQuestion: string = this.state.newQuestion.trim();
    const newQuestionKey: number = !!questions ? Object.keys(questions).length + 1 : 1;

    if (!!newQuestion) {
      this.props.onChange({
        ...questions,
        [newQuestionKey]: {
          body: newQuestion, isRequired: this.state.requireNewQuestion
        }
      });
    }
    
    this.onHideNewQuestion();
  }

  private onChangeNewQuestion = (event) => {
    this.setState({ ...this.state, newQuestion: event.target.value });
  }

  private onChangeRequireNewQuestion = (event, checked: boolean) => {
    this.setState({ ...this.state, requireNewQuestion: checked });
  }

  private onHideNewQuestion = () => {
    this.setState({ ...this.state, newQuestion: "", requireNewQuestion: false, showNewQuestion: false });
  }

  private onKeyUp = (event) => {
    if (event.keyCode === 13)
      this.onAddNewQuestion();
  }

  private onRemoveQuestion = (questionNumber: string) => {
    const remainingQuestions: string[] = Object.keys(this.props.questions!).filter(question => question !== questionNumber);
    let questions: IQuestions = {};
    remainingQuestions.map((remainingQuestion, index) => {
      questions[index + 1] = { ...this.props.questions![remainingQuestion] };
      return remainingQuestion;
    });
    this.props.onChange(questions);
  }

  private onShowNewQuestion = () => {
    this.setState({ ...this.state, showNewQuestion: true });
  }
}


interface ComponentProps {
  show: boolean;
  questions: IQuestions | undefined;
  onChange: (questions: IQuestions) => void;
  onRemove: () => void;
}

interface ComponentState {
  newQuestion: string;
  requireNewQuestion: boolean;
  showNewQuestion: boolean;
}

export default Questions;