import * as actions from "network/actions";

import { PostFeedFilters } from "modules/posts/models";

export const FetchPostsFeed = (filters: Partial<PostFeedFilters>, page: number) => {
  const updatedFilters: Partial<PostFeedFilters> = {
    ...filters,
    postTypes: !!filters.postTypes && !!filters.postTypes.length ? filters.postTypes : undefined,
    tags: !!filters.tags && !!filters.tags.length ? filters.tags : undefined
  };

  return actions.NetworkRequest({
    headers: { "content-type": "application/json" },
    method: "POST",
    url: `/{tenant}/api/v2/posts/feed?maxResults=${12}&pageNumber=${page}&getMandatoryAndCompliance=true`,
    body: JSON.stringify(updatedFilters)
  });
}

export const FetchPostsFeedLocal = (filters: Partial<PostFeedFilters>, maxResults: number, page: number) => {
  const updatedFilters: Partial<PostFeedFilters> = {
    ...filters,
    postTypes: !!filters.postTypes && !!filters.postTypes.length ? filters.postTypes : undefined,
    tags: !!filters.tags && !!filters.tags.length ? filters.tags : undefined
  };

  return actions.NetworkRequest({
    headers: { "content-type": "application/json" },
    method: "POST",
    url: `/{tenant}/api/v2/posts/feed?maxResults=${maxResults}&pageNumber=${page}&getMandatoryAndCompliance=true`,
    body: JSON.stringify(updatedFilters)
  });
}

export const GetPost = (id: string) => {
  return actions.NetworkRequest({
    method: "GET",
    url: `/{tenant}/api/v2/posts/${id}`
  });
}