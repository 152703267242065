import * as React from "react";
import LoadingCircle from "modules/common/components/loadingCircle";

interface ComponentProps {
    mssg: string;
}

export default class AnalyticsErrorBanner extends React.Component<ComponentProps, {}> {
    public render() {
        return (
            <div style={{display: 'table',
                        width: '100%',
                        backgroundColor: 'rgba(134, 153, 172, 0.3)',
                        borderRadius: '4px',
                        fontSize: '16px',
                        color: '#333333'}}>
                <div style={{paddingTop: "8px", paddingLeft: "8px", display: "table-cell", verticalAlign: "middle",}}>
                    <LoadingCircle size={50} />
                </div>
                <span style={{display: "table-cell", verticalAlign: "middle", padding: "10px", width: "100%"}}>{this.props.mssg}</span>

            </div>
        );
    }
}