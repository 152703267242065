import React, { useState } from "react";

import Callout from "modules/common/components/callout";
import Loading from "modules/common/components/loading";

import { AudienceGroup, GroupSuggestions } from "../../../models";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";

import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";


const SearchUserGroups: React.FunctionComponent<ComponentProps> = props => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [results, setResults] = useState([] as GroupSuggestions[]);
  const [searchText, setSearchText] = useState("");
  const [showCallout, setShowCallout] = useState(false);

  return (
    <div>
      <TextField
        variant="outlined"
        size="small"
        fullWidth
        value={searchText}
        placeholder={`Search ${props.syncType} user groups`}
        InputProps={{
          startAdornment: <SearchIcon className="search-icon" />
        }}
        onChange={(ev: any) => {
          setSearchText(ev.target.value);
          setAnchorEl(ev.currentTarget);
        }}
        onKeyUp={(key) => {
          if (key.keyCode === 13 && !!searchText) {
            setIsSearching(true);
            setResults([]);
            setShowCallout(true);
            props.onSearch(searchText).then((userSuggestions) => {
              setResults(userSuggestions);
              setIsSearching(false);
            });
          }
        }}
      />
      <Callout
        anchorEl={showCallout ? anchorEl : null}
        open={showCallout}
        setOpen={setShowCallout}
      >
        <div className="audience-search-results-callout">
          {isSearching
            ? <Loading padding={12} />
            : <div>
                {!results.length
                  ? <div className="no-search-results">No {props.syncType} groups were found</div>
                  : <div className="audience-user-list">
                      {results.map((result) =>
                        <div key={result.id}>
                          <div className="user-suggestion">
                            <div>{result.name}</div>
                          </div>
                          <div>
                            {result.isIncluded || props.includedGroups.findIndex(group => group.groupId === result.id) !== -1
                              ? <IconButton
                                  size="small"
                                  onClick={() => {
                                    props.onRemoveGroup(result.id);
                                  }}
                                  className="remove-icon"
                                >
                                  <ClearIcon />
                                </IconButton>
                              : <Button
                                  variant="text"
                                  color="primary"
                                  onClick={() => {
                                    props.onAddGroup(result);
                                  }}
                                  className="add-button"
                                >
                                  Add
                                </Button>
                            }
                          </div>
                        </div>
                      )}
                    </div>
                  }
                </div>
            }
          </div>
      </Callout>
    </div>
  );
}

interface ComponentProps {
  amountFetched: number;
  includedGroups: AudienceGroup[];
  onAddGroup: (group: GroupSuggestions) => void;
  onRemoveGroup: (id: string) => void;
  onSearch: (searchText: string) => Promise<GroupSuggestions[]>;
  syncType: string;
}

export default SearchUserGroups;