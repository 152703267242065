import * as React from "react";
import { push } from "react-router-redux";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import { usersApi } from "api/instances";

import BasePage from "pages/common/basePage";
import Header from "pages/common/header";
import MainContent from "pages/common/mainContent";
import Tabs from "pages/common/tabs";

import { actions } from "modules/users";
import { UsersTab } from "../models";
import UsersView from "./usersView";
import UserInsightsView from "./userInsightsView";
import InvitedUsersDrawer from "./invitedUsersDrawer";

import './userOverview.sass';

import AddUserButtonDropdown from "./addUserButtonDropdown";
import AdditionalUserOptionsDropdown from "./additionalUserOptionsDropdown";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Button, IconButton, TextField, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import OpenNewIcon from "@mui/icons-material/OpenInNew";
import LoadingSpinner from "modules/common/components/loadingSpinner";
import LoadingOverlay from "modules/common/components/loadingOverlay";

import BulkSocialDialog from "./bulkSocialDialog";
import LocalUserDialog from "./localUserDialog";
import BulkLocalDialog from "./bulkLocalDialog";
import Loading from "modules/common/components/loading";
import UserActivityLogs from "./userActivities";
import { emailRegex } from "utils/regexUserValidation";


import "modules/common/components/authoring/authoring.sass";
import "../styles/userList.sass";
import UsersTutorial from "./dialogs/usersTutorial";
import { FileDownloader } from "utils/fileDownloader";

class UsersManagementPage extends React.Component<PropsWithRedux, ComponentState> {
    constructor(props) {
        super(props);
        this.state = {
            showInvitedUsers: false,
            showInviteSocial: false,
            showUserActivities: false,
            showInvitedUsersSent: false,
            showCSVInvite: false,
            showInviteLocal: false,
            showInviteLocalResult: false,
            showLocalBulk: false,
            emailString: "",
            emailValid: true,
            errorMessage: "",
            isDownloading: false,
            localCreating: false,
            sparrowFailed: false,

            invitedUserEmail: "",
            invitedUserName: ""
        };
    }

  public componentDidMount() {
    this.props.getUsersV1();
  }

  public render() {
    return (
        <BasePage fullWidth>
          <Header
            title={
              <React.Fragment>
              Manage Users & Permissions
              <div style={{position: "relative", top: "-3px"}}><UsersTutorial/></div>
              </React.Fragment>
            }
            rightComponent={
              <React.Fragment>
                <AdditionalUserOptionsDropdown downloadAllUserData={this.downloadUserData} openInvitesDrawer={this.showInvitedUsersDrawer} navigateToLobby={this.navigateToLobby} openUserActivities={this.showUserActivities} />
                <AddUserButtonDropdown openAddModal={this.showInviteSocial} closeAddModal={this.hideInviteSocial} redirectToUserCreation={this.showInviteLocal} />
              </React.Fragment>
            }
          />
          <MainContent id="user-management-list">
            <div className="authoring-page">
              <Tabs
                tabs={[
                  { label: "Users", value: UsersTab.Users },
                  { label: "Insights", value: UsersTab.Insights }
                ]}
                selectedTab={this.props.activeTab}
                onSelectTab={this.props.setActiveTab}
              />
              <UsersView
                show={this.props.activeTab === UsersTab.Users}
                onDownloadUserData={this.downloadUserData}
              />
              <UserInsightsView
                show={this.props.activeTab === UsersTab.Insights}
              />
              <InvitedUsersDrawer
                //Using the time of invite as key to reload when new users are uploaded
                key={this.props.invitedTime.toString()}
                open={this.state.showInvitedUsers}
                onCloseDrawer={this.closeInvitedUsersDrawer}
              />
               { this.state.showUserActivities && <UserActivityLogs onClose={() => this.closeUserActivities()} />}
            </div>
            <LoadingOverlay show={this.state.isDownloading} absolute={true} />
          </MainContent>

          <Dialog open={this.state.showInviteSocial} onClose={this.hideInviteSocial} maxWidth={"md"} fullWidth={true}>
              <DialogTitle className="external-user-dialog-formatting">
                  <div className="external-user-dialog-header-title">
                      <Typography variant="h2">Add a new social user</Typography>
                      <IconButton onClick={this.hideInviteSocial} size="large">
                          <CloseIcon />
                      </IconButton>
                  </div>
              </DialogTitle>
              <DialogContent className="external-user-dialog-content" style={{paddingBottom: "20px"}}>
                  <div style={{display: "flex", flexDirection: "row", backgroundColor: "#F2F2F2", marginTop: 8}}>
                      <InfoIcon htmlColor="#E6911A" className="icon-formatting"/>
                      <span className="user-dialog-description-text">
                      A <b>Social User</b> is created once the invitee successfully logs in using the link in the invitation email. Once the invitation link is opened, the user can create their account through any preferred social account provider (Google, Facebook, LinkedIn or Apple).
                      </span>
                  </div>

                  <div className="invitations-section">
                      <span className="prompt-text">Send invitations to</span>
                      <TextField 
                          placeholder="Enter at least 1 email address (max of 10, separate with comma)"
                          variant="outlined"
                          size="small"
                          fullWidth={true}
                          inputProps={{style: {fontSize: "14px"}}}
                          error={!this.state.emailValid}
                          value={this.state.emailString}
                          onChange={this.processEmails}
                      >
                      </TextField>
                  </div>
                  <div>
                      { !this.state.emailValid ? <span className="email-error-warning-text">{this.state.errorMessage}</span> : <span></span> }
                  </div>

                  <div style={{paddingTop: "70px"}}>
                      <Button onClick={this.swapToCSVInvite} color="primary" endIcon={<OpenNewIcon/>}>Send Bulk Invite</Button>
                      <Button onClick={this.validateAndSendEmails} style={{float: "right", marginLeft: "6px"}} color="primary" variant="contained" disabled={this.state.emailString === "" || !this.state.emailValid}>Send Invite</Button>
                      <Button onClick={this.hideInviteSocial} style={{float: "right"}}>Cancel</Button>
                  </div>
              </DialogContent>
          </Dialog>

          <Dialog open={this.state.showInvitedUsersSent} onClose={this.hideInvitedUsersSent} maxWidth={"sm"} fullWidth={true}>
              <DialogTitle className="external-user-dialog-formatting">
                  <div className="external-user-dialog-header-title">
                      {this.props.invitingUser ? <Typography variant="h2">Sending invites...</Typography> : <Typography variant="h2">Successfully sent!</Typography> }
                      <IconButton onClick={this.hideInvitedUsersSent} size="large">
                          <CloseIcon />
                      </IconButton>
                  </div>
              </DialogTitle>
              <DialogContent className="external-user-dialog-content" style={{paddingBottom: "20px"}}>
                  {this.props.invitingUser ?
                      <LoadingSpinner size={'14px'} />

                  : <div style={{marginTop: 8}}>
                      <div>
                          <span style={{fontSize: "14px"}}>You've successfully sent invites to {this.props.invitedUserCount} new user(s)! The invitees are required to accept the invitation, and log in using the unique link to set up and complete their user profile.</span>
                      </div>
                      <div style={{paddingTop: "50px"}}>
                          <Button onClick={this.swapToUserInvites} style={{float: "right", marginLeft: "6px"}} color="primary" variant="contained">Manage User Invites</Button>
                          <Button onClick={this.hideInvitedUsersSent} style={{float: "right"}}>Back to users & permissions</Button>
                      </div>
                  </div>
                  }
              </DialogContent>
          </Dialog>
                  
          <Dialog open={this.state.showInviteLocalResult} maxWidth={"md"}>
              <DialogTitle className="external-user-dialog-formatting">
                  <div className="external-user-dialog-header-title">
                      {this.state.localCreating ? <Typography variant="h2">Creating user...</Typography> 
                        : !this.state.sparrowFailed ? <Typography variant="h2">Successfully sent!</Typography> : <Typography variant="h2">User Creation Failed.</Typography> }
                      <IconButton onClick={this.hideLocalInviteResult} size="large">
                          <CloseIcon />
                      </IconButton>
                  </div>
              </DialogTitle>
              <DialogContent className="external-user-dialog-content">
                  {this.state.localCreating 
                  ? <Loading/> 
                  : <div style={{paddingTop: 8}}>
                    {!this.state.sparrowFailed && <div>
                      <span style={{fontSize: "14px"}}>
                          You've successfully sent an invite to <strong>{this.state.invitedUserName}</strong> at <strong>{this.state.invitedUserEmail}</strong>. 
                          <br/>
                          The invitee is required to accept the invitation, and use the link to activate their account.
                          <br/><br/>
                          <a style={{float: "left"}} href="https://support.sparrowconnected.com/en/after-adding-new-sparrow-users" target="_blank" rel="noopener noreferrer">Learn what's next</a>
                      </span>
                      <div style={{paddingTop: "50px"}}>
                          <Button onClick={this.swapToUserInvites} style={{float: "right", marginLeft: "6px", marginBottom: "10px"}} color="primary" variant="contained">Manage User Invites</Button>
                          <Button onClick={this.hideLocalInviteResult} style={{float: "right"}}>Back to users &amp; permissions</Button>
                      </div>
                      </div>
                    }
                    {
                      this.state.sparrowFailed && 
                      <div>
                        <span style={{fontSize: "14px"}}>
                            Something has gone wrong uploading the sparrow user.
                            <br/>
                            Please ensure all provided values are valid, and that emails do not match existing users.
                        </span>
                        <div style={{paddingTop: "50px"}}>
                            <Button onClick={this.hideLocalInviteResult} style={{float: "right"}}>Back to users &amp; permissions</Button>
                        </div>
                      </div>
                    }
                    </div>
                  }
              </DialogContent>
          </Dialog>

          <BulkSocialDialog isOpen={this.state.showCSVInvite} hideCSVInvite={this.hideCSVInvite} showCSVInvite={this.showCSVInvite} showDrawer={this.showInvitedUsersDrawer}/>
          <LocalUserDialog 
            isOpen={this.state.showInviteLocal} 
            closeDialog={this.hideInviteLocal} 
            swapDialogs={this.swapAfterSingleCreation} 
            closeReport={this.hideLocalInviteResult} 
            bulkClicked={this.localSwapToBulk} 
            setLoading={this.setLoading} 
            setUploadFailed={this.uploadFailed} 
            updateSparrowUser={this.updateSparrowUser}
          />
          <BulkLocalDialog isOpen={this.state.showLocalBulk} showDialog={this.showBulkInviteLocal} closeDialog={this.hideBulkInviteLocal} showDrawer={this.showInvitedUsersDrawer}/>
        </BasePage>
    );
  }

  private uploadFailed = (status: boolean) => {
    this.setState({sparrowFailed: status});
  }

  private showInvitedUsersDrawer = () => {
    this.setState({ ...this.state, showInvitedUsers: true })
  }

  private closeInvitedUsersDrawer = () => {
    this.setState({ ...this.state, showInvitedUsers: false })
  }

  private showUserActivities = () => {
    this.setState({...this.state, showUserActivities: true})
}

private closeUserActivities = () => {
    this.setState({...this.state, showUserActivities: false})
}

  private swapToCSVInvite = () => {
    this.setState({ ...this.state, showCSVInvite: true, showInviteSocial: false, emailString: "", errorMessage: "", emailValid: true })
  }

  private showCSVInvite = () => {
    this.setState({ ...this.state, showCSVInvite: true })
  }

  private hideCSVInvite = () => {
    this.setState({ ...this.state, showCSVInvite: false })
  }

  private hideInvitedUsersSent = () => {
    this.setState({ ...this.state, showInvitedUsersSent: false })
  }

  private navigateToUserCreation = () => {
    this.props.redirectTo(`/${this.props.tenantId}/admin/users/creation`);
  }

  private navigateToLobby = () => {
    this.props.redirectTo(`/${this.props.tenantId}/admin/users/externallobby`)
  }

  private updateSparrowUser = (name: string, email: string) => {
    this.setState({invitedUserName: name, invitedUserEmail: email});
  }

  private downloadUserData = (userIds?: string[]) => {
    this.setState({ isDownloading: true });

    const file = {
      name: `User Data-${new Date().toISOString()}.csv`
    };
    usersApi.downloadUserData(userIds || [])
      .then((url) => new FileDownloader(file).downloadProvided(url))
      .then( (_) => this.setState({ isDownloading: false }))
      .catch( (_) => this.setState({ isDownloading: false }));
  }

  private showInviteSocial = () => {
    this.setState({ ...this.state, showInviteSocial: true });
  }

  private hideInviteSocial = () => {
    this.setState({ ...this.state, showInviteSocial: false, emailString: "", errorMessage: "", emailValid: true })
  }

  private swapToInviteConfirm = () => {
    this.setState({ ...this.state, showInviteSocial: false, showInvitedUsersSent: true, emailString: "", errorMessage: "", emailValid: true })
  }

  private swapToUserInvites = () => {
    this.setState({ ...this.state, showInvitedUsersSent: false, showInviteLocalResult: false, showInvitedUsers: true })
  }

  private processEmails = (e) => {
    var rawEmails = e.target.value;
    rawEmails = rawEmails.replace(/ /g, '');
    var emails = rawEmails.split(",");

    if (emails.length > 10) {
      this.setState({ emailString: e.target.value, emailValid: false, errorMessage: "Cannot send invitations to more than 10 emails. Consider using bulk import below." })
      return;
    }

    this.setState({ emailString: e.target.value, emailValid: true, errorMessage: "" })
  }

    private showInviteLocal = () => {
        this.setState({...this.state, showInviteLocal: true});
    }

    private hideInviteLocal = () => {
        this.setState({...this.state, showInviteLocal: false});
    }

    private hideLocalInviteResult = () => {
        this.setState({...this.state, showInviteLocalResult: false})
    }
    
    private hideBulkInviteLocal = () => {
        this.setState({ ...this.state, showLocalBulk: false})
    }

    private showBulkInviteLocal = () => {
        this.setState({ ...this.state, showLocalBulk: true})
    }

    private localSwapToBulk = () => {
        this.setState({...this.state, showInviteLocal: false, showLocalBulk: true});
    }

    private setLoading = (loading: boolean) => {
        this.setState({...this.state, localCreating: loading})
    }

    private swapAfterSingleCreation = () => {
        this.setState({...this.state, showInviteLocal: false, showInviteLocalResult: true, localCreating: true});
    }

    private validateAndSendEmails = () => {
        var emailsToCheck = this.state.emailString.replace(/ /g, '').split(",");

        var flaggedEmails:string[] = [];
        var toSend:string[] = [];

        if(this.state.emailString === "")
        {
            this.setState({emailValid: false, errorMessage: "Must have at least one email in order to send invites."})
            return;
        }

        emailsToCheck.forEach(email => {
            var testResult = emailRegex.test(email) || email.trim() === "";

            if(!testResult)
            {
                flaggedEmails.push(email)
            }

            if(email.trim() !== "")
            {
                toSend.push(email);
            }
        })

        if(flaggedEmails.length !== 0 )
        {
            var badEmails = flaggedEmails.join(", ")
            this.setState({emailValid: false, errorMessage: "One or more emails provided is of an invalid format. ("+badEmails+")"})
            return;
        }
        else if(toSend.length === 0)
        {
            this.setState({emailValid: false, errorMessage: "Please enter at least one valid email."})
            return;
        }
        else if(toSend.length > 10)
        {
            this.setState({emailValid: false, errorMessage: "Cannot send invitations to more than 10 emails. Consider using bulk import below."})
            return;
        }
        //Making it here means all the emails are valid.
        this.props.sendBulkInvite(toSend);
        this.swapToInviteConfirm();
    }
}

interface ComponentState {
    showInvitedUsers: boolean;
    showUserActivities: boolean;
    showInviteSocial: boolean;
    showInvitedUsersSent: boolean;
    showInviteLocal: boolean;
    showInviteLocalResult: boolean;
    showCSVInvite: boolean;
    showLocalBulk: boolean;
    emailString: string;
    emailValid: boolean;
    errorMessage: string;
    isDownloading: boolean;
    localCreating: boolean;
    sparrowFailed: boolean;

    invitedUserName: string;
    invitedUserEmail: string;
}
interface ComponentProps { }

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    fetching: state.users.fetching,
    saving: state.users.saving,
    activeTab: state.users.activeTab,
    tenantSettings: state.settings.tenantSettings,
    tenantId: state.tenant.id,
    invitingUser: state.users.invitingUser,
    invitedUserCount: state.users.invitedUsers,
    invitedTime: state.users.invitedTime
  }),
  {
    getUsersV1: actions.getUsersV1,
    sendBulkInvite: actions.inviteBatchUsers,
    setActiveTab: actions.setActiveTab,
    redirectTo: push
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(UsersManagementPage)
