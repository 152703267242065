import { SelectOption } from "modules/common/models";
import { TagSettings } from "modules/settings";

export const getAvailableTags = (
    tagSettings: TagSettings,
    tagCounts: { [key: string]: number; } | undefined
): SelectOption[] => {
    if (!tagSettings)
        return [];
    
    let availableTags: SelectOption[] = [];

    tagSettings.tagGroups.forEach((tagGroup) => {

        if (tagGroup.tags.length) {

            availableTags.push({ key: tagGroup.id, text: tagGroup.name, type: "header" });

            tagGroup.tags.forEach((tag) => {
                
                if (!tag.disabled) {
                    
                    let postCount = tagCounts && tagCounts[tag.id] ? tagCounts[tag.id] : 0;
            
                    availableTags.push({ 
                        key: tag.id, 
                        text: tagCounts ? `${tag.name} (${postCount})` : tag.name,
                        type: "item"
                    });
                }

            });
        }

    });

    return availableTags;
}