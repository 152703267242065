import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import * as actions from "../../actionCreator";
import { GlobalApplicationState } from "globalApplicationState";

import { Audience } from "modules/audiences/models";
import { PortalPagesListItem } from "../../models";

import AudiencesList from "./audiencesList";


import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";


class ModifyPortalPageSettingsDialog extends React.Component<PropsWithRedux, ComponentState> {
  constructor(props: PropsWithRedux) {
    super(props);

    this.state = {
      anchorEl: null,
      audiences: props.portalPage && props.portalPage.audiences ? props.portalPage.audiences.map(audience => this.convertAudienceIdToAudience(audience)) : [],
      isChecking: false,
      pageTitle: props.portalPage ? props.portalPage.title : "",
      pageTitleIsValid: true,
      pageUrl: props.portalPage ? props.portalPage.url : "",
      pageUrlIsValid: true,
      urlErrorMessage: "",
      calloutOpen: false
    };
  }

  componentDidUpdate(prevProps: PropsWithRedux) {
    if (this.props.show && !prevProps.show)
      this.setState({
        anchorEl: null,
        audiences: this.props.portalPage && this.props.portalPage.audiences ? this.props.portalPage.audiences.map(audience => this.convertAudienceIdToAudience(audience)) : [],
        isChecking: false,
        pageTitle: this.props.portalPage ? this.props.portalPage.title : "",
        pageTitleIsValid: true,
        pageUrl: this.props.portalPage ? this.props.portalPage.url : "",
        pageUrlIsValid: true,
        urlErrorMessage: ""
      });
  }

  public render() {
    return (
      <Dialog open={this.props.show} maxWidth={false} onClose={this.onClose}>
        <DialogTitle className="portal-pages-dialog-header">
          <div className="portal-pages-dialog-header-title">
            <Typography variant="h2">Modify page settings</Typography>
            <IconButton onClick={this.onClose} size="large">
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent className="portal-page-dialog-content">
          <div className="portal-page-dialog-content-form" style={{paddingTop: "8px"}}>
            <div>
              <div>Page title</div>
              <TextField
                variant="outlined"
                size="small"
                value={this.state.pageTitle}
                autoComplete="off"
                disabled={this.state.isChecking}
                error={!this.state.pageTitleIsValid}
                helperText={!this.state.pageTitleIsValid ? "Cannot contain numbers or special characters" : ""}
                fullWidth
                inputProps={{
                  maxLength: 25
                }}
                onChange={this.onChangePageTitle}
              />
            </div>
            <div>
              <div>Page URL</div>
              <TextField
                variant="outlined"
                size="small"
                value={this.state.pageUrl}
                autoComplete="off"
                disabled={this.state.isChecking}
                error={!this.state.pageUrlIsValid}
                helperText={this.state.urlErrorMessage || ""}
                fullWidth
                onChange={this.onChangePageUrl}
              />
            </div>
            <div>
              <div className="audiences-list-label">Accessible to</div>
              <div>
                {!this.state.audiences.length
                  ? <div className="audiences-list">
                      <Chip
                        label="All users"
                        disabled={true}
                        className="all-users-audience"
                      />
                    </div>
                  : <div className="audiences-list">
                      {this.state.audiences.map((audience) =>
                        <Chip
                          key={audience.id}
                          label={audience.displayName}
                          onDelete={() => this.onRemoveAudience(audience)}
                          deleteIcon={<CloseIcon />}
                        />
                      )}
                    </div>
                  }
                <Button
                  variant="text"
                  color="primary"
                  startIcon={<AddIcon />}
                  disabled={this.state.isChecking}
                  onClick={this.onShowAudiencesList}
                >
                  Add audience
                </Button>
                <AudiencesList 
                  anchorEl={this.state.anchorEl} 
                  selectedAudiences={this.state.audiences} 
                  onChange={this.onChangeAudiences} 
                  setCalloutOpen={this.setCalloutOpen} 
                  calloutOpen={this.state.calloutOpen}
                />
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="portal-pages-dialog-footer">
          <Button variant="text" color="primary" onClick={this.onClose}>Cancel</Button>
          <Button
            variant="contained"
            color="primary"
            disabled={this.state.isChecking || !this.state.pageTitle || !this.state.pageTitleIsValid || !this.state.pageUrl || !this.state.pageUrlIsValid}
            onClick={this.onSave}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  private convertAudienceIdToAudience = (audienceId: string): Audience => {
    const matchedAudience: Audience | undefined = this.props.audiences.find((audience) => audience.id === audienceId);
    if (!matchedAudience)
      return { id: audienceId, displayName: "", description: "", enabled: true, type: "" };
    return matchedAudience;
  }

  private onSave = () => {
    this.setState({ isChecking: true });

    if (this.props.portalPage?.url === this.state.pageUrl) {
      this.savePageDetails();
    } else {
      this.props.checkIfUrlExists(this.state.pageUrl).then((exists) => {
        this.setState({ isChecking: false }, () => {
          if (exists) {
            this.setState({ pageUrlIsValid: false, urlErrorMessage: "URL already exists" });
          } else {
            this.savePageDetails();
          }
        });
      });
    }
  }

  private savePageDetails(){
    if (this.props.portalPage!.state === "Draft") {
      this.props.modifyDraftDetails(this.props.portalPage!.draftId, this.state.pageTitle.trim(), this.state.pageUrl, this.state.audiences.map(audience => audience.id)).then((succeeded) => {
        this.props.updateDraft();
        if (succeeded && !!this.props.onSuccess)
          this.props.onSuccess();
      });
      this.onClose();
    } else {
      this.props.modifyPortalPageDetails(this.props.portalPage!.id, this.state.pageTitle.trim(), this.state.pageUrl, this.state.audiences.map(audience => audience.id)).then((succeeded) => {
        this.props.updateDraft();
        if (succeeded && !!this.props.onSuccess)
          this.props.onSuccess();
      });
      this.onClose();
    }
  }

  private onChangeAudiences = (audiences: Audience[]) => {
    this.setState({ audiences, anchorEl: null });
  }

  private onChangePageTitle = (event) => {
    const isInvalid = /[^A-Za-z\s]/.test(event.target.value) || event.target.value.toLowerCase() === "home";
    this.setState({ pageTitle: event.target.value, pageTitleIsValid: !isInvalid });
  }

  private onChangePageUrl = (event) => {
    const isInvalid = /[^A-Za-z0-9-]/.test(event.target.value) || event.target.value.toLowerCase() === "home";
    this.setState({ pageUrl: event.target.value, pageUrlIsValid: !isInvalid, urlErrorMessage: isInvalid ? "Cannot contain special characters" : "" });
  }

  private onClose = () => {
    this.props.onClose();
  }

  private onRemoveAudience = (audience: Audience) => {
    this.onChangeAudiences(this.state.audiences.filter((selectedAudience) => selectedAudience.id !== audience.id));
  }

  private onShowAudiencesList = (event: any) => {
    this.setState({ anchorEl: event.currentTarget, calloutOpen: true });
  }

  private setCalloutOpen = (toSet: boolean) => {
    this.setState({calloutOpen: toSet});
  }
}


interface ComponentProps {
  show: boolean;
  portalPage: PortalPagesListItem | undefined;
  onSuccess?: () => void;
  onClose: () => void;
}

interface ComponentState {
  anchorEl: any;
  audiences: Audience[];
  isChecking: boolean;
  pageTitle: string;
  pageTitleIsValid: boolean;
  pageUrl: string;
  pageUrlIsValid: boolean;
  urlErrorMessage: string;
  calloutOpen: boolean;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    audiences: state.audiences.audiences
  }),
  {
    checkIfUrlExists: actions.checkIfUrlExists,
    modifyDraftDetails: actions.modifyDraftDetails,
    modifyPortalPageDetails: actions.modifyPortalPageDetails,
    updateDraft: actions.updateDraft
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(ModifyPortalPageSettingsDialog);