import React, { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import * as actions from "../../../actionCreator";
import { GlobalApplicationState } from "globalApplicationState";

import { Audience } from "modules/audiences/models";
import { NavigationItem } from "../../../models";

import AudienceChipLabels from "modules/common/components/chips/audienceChipLabels";
import Loading from "modules/common/components/loading";

import TabContent from "pages/common/tabContent";
import NewChildNavigationItem from "../action-buttons/newChildNavigationItem";

import confirm from "utils/notyPopups";

import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SubdirectoryArrowLeftIcon from "@mui/icons-material/SubdirectoryArrowLeft";


interface HeaderCell {
  align: "center" | "left" | "right";
  disablePadding: boolean;
  id: string;
  label: string;
}

interface IListItem {
  navigationItem: NavigationItem;
  parentSequence?: number;
  totalItems: number;
  onDelete: (navigationItem: NavigationItem) => void;
  onSetAsParent: (navigationItem: NavigationItem) => void;
  onUpdateSequence: (navigationItem: NavigationItem, sequence: number) => void;
}


const ListItem: React.FunctionComponent<IListItem & { audiences: Audience[] }> = props => {
  const [showChildItems, setShowChildItems] = useState(false);

  const { navigationItem, totalItems } = props;

  return (
    <React.Fragment>
      <TableRow
        hover={!navigationItem.isHome}
        className={!navigationItem.parentId ? "parent-item" : "child-item"}
      >
        <TableCell align="left" padding="checkbox">
          {!!navigationItem.children.length &&
            <IconButton
              size="small"
              onClick={() => setShowChildItems(!showChildItems)}
            >
              {showChildItems ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          }
        </TableCell>
        <TableCell align="left" className="navigation-item-order">
          <FormControl size="small">
            <Select
              variant="outlined"
              value={navigationItem.sequence}
              disabled={navigationItem.isHome}
              onChange={(event) => props.onUpdateSequence(navigationItem, event.target.value as number)}
              className="item-sequence"
            >
              {navigationItem.isHome
                ? <MenuItem key={1} value={1}>1</MenuItem>
                : Array.from(Array(totalItems).keys()).slice(!navigationItem.parentId ? 1 : 0).map((number) => {
                    return <MenuItem key={number} value={number + 1}>{`${!!props.parentSequence ? props.parentSequence + "." : ""}${number + 1}`}</MenuItem>;
                  })
              }
            </Select>
          </FormControl>
        </TableCell>
        <TableCell align="left">{navigationItem.name}{navigationItem.state === "Disabled" ? " (disabled)" : ""}</TableCell>
        <TableCell align="left">/{navigationItem.url}</TableCell>
        <TableCell align="left"><AudienceChipLabels audienceIds={navigationItem.audiences} audiences={props.audiences} /></TableCell>
        <TableCell align="right">
          {!navigationItem.isHome &&
            <React.Fragment>
              {!navigationItem.parentId
                ? <NewChildNavigationItem parentNavigationItem={navigationItem} />
                : <IconButton
                    size="small"
                    onClick={() => props.onSetAsParent(navigationItem)}
                  >
                    <SubdirectoryArrowLeftIcon />
                  </IconButton>
              }
              <IconButton
                size="small"
                onClick={() => props.onDelete(navigationItem)}
              >
                <DeleteIcon />
              </IconButton>
            </React.Fragment>
          }
        </TableCell>
      </TableRow>
      {showChildItems && !!navigationItem.children.length && navigationItem.children.map((childItem: NavigationItem) => {
        return (
          <ListItem key={childItem.id} audiences={props.audiences} navigationItem={childItem} parentSequence={navigationItem.sequence} totalItems={navigationItem.children.length} onDelete={props.onDelete} onSetAsParent={props.onSetAsParent} onUpdateSequence={props.onUpdateSequence} />
        );
      })}
    </React.Fragment>
  );
}

class NavigationItemsList extends React.Component<PropsWithRedux, ComponentState> {
  public render() {
    const { page } = this.props;

    if (page.isFetching)
      return <Loading />;

    return (
      <div>
        <TabContent>
          {this.getList()}
        </TabContent>
      </div>
    );
  }


  private getHeader = (): HeaderCell[] => {
    let header: HeaderCell[] = [
      { id: "expand", disablePadding: false, label: "", align: "left" },
      { id: "order", disablePadding: false, label: "Order", align: "left" },
      { id: "title", disablePadding: true, label: "Page title", align: "left" },
      { id: "url", disablePadding: false, label: "URL", align: "left" },
      { id: "audiences", disablePadding: false, label: "Accessible to", align: "left" },
      { id: "actions", disablePadding: false, label: "Actions", align: "right" }
    ];
    
    return header;
  }

  private getList = (): JSX.Element => {
    const { page } = this.props;

    if (page.isFetching)
      return <Loading />;
    
    if (!page.navigationItems.length)
      return <div>No navigation items were found.</div>;

    return (
      <TableContainer>
        <Table size="medium">
          <TableHead>
            <TableRow>
              {this.getHeader().map((headerCell) => (
                <TableCell
                  key={headerCell.id}
                  align={headerCell.align}
                  padding={headerCell.disablePadding ? "none" : "normal"}
                >
                  {headerCell.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className="navigation-items-list">
            {page.navigationItems
              .map((navigationItem) => {
                return (
                  <ListItem
                    key={navigationItem.id}
                    audiences={this.props.audiences}
                    navigationItem={navigationItem}
                    totalItems={page.navigationItems.length}
                    onDelete={this.onDeleteNavigationItem}
                    onSetAsParent={this.onSetNavigationItemAsParent}
                    onUpdateSequence={this.onUpdateNavigationItemSequence}
                  />
                );
              })
            }
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  private onDeleteNavigationItem = async (navigationItem: NavigationItem) => {
    if (await confirm.show({ text: "Are you sure you want to delete this navigation item?", title: "Delete navigation item" }))
      this.props.deleteNavigationItem(navigationItem.id);
  }

  private onSetNavigationItemAsParent = (navigationItem: NavigationItem) => {
    const sequence: number = this.props.page.navigationItems.length + 1 || 2;
    this.props.setNavigationItemAsParent(navigationItem.id, sequence);
  }

  private onUpdateNavigationItemSequence = (navigationItem: NavigationItem, sequence: number) => {
    this.props.updateNavigationItemSequence(navigationItem.id, navigationItem.parentId, sequence);
  }
}
  

interface ComponentProps {
}

interface ComponentState {}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    audiences: state.audiences.audiences,
    page: state.portalPages.navigationItems
  }),
  {
    deleteNavigationItem: actions.deleteNavigationItem,
    setNavigationItemAsParent: actions.setNavigationItemAsParent,
    updateNavigationItemSequence: actions.updateNavigationItemSequence
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(NavigationItemsList);