import { FileData } from "chonky";
import { IdNamePair } from "modules/common/models";
import { Document } from "modules/documents/models";
import { Epoch } from "modules/posts/models";

export interface GalleryState {
  documentGallery: DocumentGalleryPage;
  imageGallery: ImageGalleryPage;
  isFetching: boolean;
  isUploading: boolean;
}

export interface AttachedFile {
  blobId: string;
  fileUrl?: string | null;
  fileName: string;
  fileType: "image" | "video" | "application";
  fileExtension: string;
  ospreyId: string;
  transforms?: { points: number[], zoom?: number }
}

export interface DocumentGalleryPage {
  currentPage: number;
  documents: Document[];
  totalDocuments: number;
  totalPages: number;
  isFetching: boolean;
  shouldFetch: boolean;
}
export interface Image {
    blobId: string;
    createdTime: Epoch;
    updatedTime?: Epoch;
    deletedTime?: Epoch;
    id: string;
    name: string;
    tenantId: string;
    url: string;
    videoId?: string;
    keywords: string[];
    fileType: ImageType;
    parentId?: string;
    fileSize?: number;
    createdBy?: string;
}

export enum ImageType {
    Png,
    Jpeg,
    Bmp,
    Jpg,
    Gif,
    All
}

export interface ImageGalleryPage {
  currentPage: number;
  images: Image[];
  totalImages: number;
  totalPages: number;
  isFetching: boolean;
  shouldFetch: boolean;
}

export interface CustomFileData extends FileData {
    parentId?: string;
    childrenIds?: string[];
    createdBy?: string;
    mimeType?: string;
    keywords?: string[];
    blobId?: string;
}

export interface ImageUploadReturn<TId, TFileName> extends IdNamePair<TId, TFileName> {}

export enum MediaType {
    Folder,
    Image,
    Video,
}

export interface MediaFile {
    createdTime: Epoch;
    updatedTime?: Epoch;
    deletedTime?: Epoch;
    id: string;
    name: string;
    tenantId: string;
    keyWords: string[];
    isDirectory: boolean;
    childrenIds: string[];
    parentId?: string;
}

export interface VerifiedFile {
    file: File,
    action: FileUploadAction,
}

export interface VerifiedFileData {
    file: CustomFileData,
    action: FileUploadAction,
}

export enum FileUploadAction {
    New,
    Rename,
    Replace,
    Move
}

export interface DuplicateFiles {
    newFile: File,
    duplicateImage: Image,
}

export interface DuplicateFileData {
    fileDataToMove: CustomFileData,
    duplicateFileData: CustomFileData,
}

export interface FileWithUrl {
    name: string;
    url: string;
    id: string;
}

export interface ConsolidatedDuplicateFiles {
    newFile: FileWithUrl
    duplicateFile: FileWithUrl
    isDir?: boolean;
}

export interface MoveFilesWithSrc {
    filesToMove: CustomFileData[];
    moveSrc: CustomFileData | null;
}

export interface MoveFiles extends MoveFilesWithSrc {
    filesToMove: CustomFileData[];
    moveDest: CustomFileData | null;
} 

export interface VerifiedMoveFiles {
    filesToMove: VerifiedFileData[];
    moveDest: CustomFileData | null;
    moveSrc: CustomFileData | null;
}

export interface ImageAndFolderCountReturn {
    folderCount: number;
    imageCount: number;
}

// this is kept in the same order as MediaSortType in the API to match up the enums
export enum SortGroupName {
    Az = "Sort by A-Z",
    Za = "Sort by Z-A",
    Newest = "Sort by Newest",
    Oldest = "Sort by Oldest",
}

// this is kept in the same order as MediaFilterType in the API to match up the enums
export enum FilterGroupName {
    AllImages = "All Images",
    MyImages = "My Images"
}

export interface MediaFiltersModel {
    sortType?: SortGroupName;
    showFoldersFirst?: boolean;
    parentId?: string;
    filterType?: FilterGroupName;
    searchText?: string;
}