import React, { useEffect, useRef, useState } from "react";

import Popover, { PopoverProps } from "@mui/material/Popover";

import useIsMounted from "../hooks/useIsMounted";

import "./callout.sass";

const Callout: React.FunctionComponent<CalloutProps> = ({
    arrowPosition = "top",
    fullSize,
    ...props
}) => {
    const [mouseOver, setMouseOver] = useState<boolean>(false);
    const timerDone = useRef<boolean>(false);
    const [open, setOpen] = useState<boolean>(!!props.anchorEl || props.popoverProps?.anchorPosition !== undefined);
    const isMounted = useIsMounted();
    const setOpenProp = props.setOpen;

    useEffect(() => {
        setOpen(!!props.anchorEl || props.popoverProps?.anchorPosition !== undefined);
    }, [props.anchorEl, props.popoverProps]);

    const { autoClose, autoCloseDuration } = props;

    useEffect(() => {
        let timer: NodeJS.Timeout | undefined = undefined;

        if (open && autoClose && autoCloseDuration) {
            timer = setTimeout(() => {
                // only close if not currently hovered over
                if (isMounted() && !mouseOver) {
                    setOpen(false);

                    if (setOpenProp) setOpenProp(false);
                }

                timerDone.current = true;
            }, autoCloseDuration);
        }

        return () => {
            if (timer)
                clearTimeout(timer);
        }
    }, [open, autoClose, autoCloseDuration, isMounted, mouseOver, setOpenProp]);

    return (
        <Popover
            open={open && !!props.open}
            anchorEl={props.anchorEl}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "center"
            }}
            slotProps={{
                paper: {
                    style: {
                        backgroundColor: "transparent",
                        boxShadow: "none",
                        overflow: "visible",
                    },
                    onMouseOver: () => {
                        setMouseOver(true);
                    },
                    onMouseOut: () => {
                        setMouseOver(false);

                        // close if timer has ran out while hovering
                        if (timerDone.current) {
                            setOpen(false);
                            timerDone.current = false;
                        }
                    },
                }
            }}
            onClose={() => props.setOpen ? props.setOpen(false) : false}
            {...props.popoverProps}
        >
            {arrowPosition !== "none" && <div style={props.arrowStyle} className={`callout-arrow ${arrowPosition}`}></div>}
            <div className={`callout ${fullSize ? "full-size" : ""} `}>
                {!!props.header &&
                    <div className="callout-header">
                        {props.header}
                    </div>
                }
                <div className={`${props.contentClassName || "callout-content"}`}>
                    {props.children}
                </div>
                {!!props.footer &&
                    <div className="callout-footer">
                        {props.footer}
                    </div>
                }
            </div>
        </Popover>
    );
}


interface CalloutProps {
    anchorEl?: any;
    setOpen?: (toSet: boolean) => void;
    open?: boolean;
    arrowStyle?: React.CSSProperties;
    arrowPosition?: "top" | "right" | "none";
    header?: JSX.Element;
    footer?: JSX.Element;
    popoverProps?: Partial<PopoverProps>;
    contentClassName?: string;
    fullSize?: boolean;
    autoClose?: boolean;
    autoCloseDuration?: number; // miliseconds to auto close after
}


export default Callout;
