import * as React from "react";

import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import BlockIcon from "@mui/icons-material/Block";
import FilledInput from "../forms/inputs/filledInput";


class Summary extends React.Component<ComponentProps, ComponentState> {
  constructor(props) {
    super(props);

    this.state = {
      oldSummary: "",
    };
  }

  public render() {
    const { summary } = this.props;

    return (
      <Collapse in={this.props.show}>
        <div className="multilingual-editor-field summary">
          <FilledInput
            refreshable
            label={"Summary"}
            value={summary}
            characterCount
            maxCount={300}
            inputProps={{
              placeholder: "Provide a short summary.",
              style: {
                fontSize: "1rem",
                minHeight: "auto",
                padding: "8.5px 14px",
              },
              onFocus: this.onFocusSummary,
              onBlur: this.onBlurSummary
            }}
            inputStyle={{
              borderRadius: "4px",
              minHeight: "auto",
              padding: "unset",
            }}
          />
          <div className="remove-optional-content">
            <Button variant="text" color="inherit" size="small" startIcon={<BlockIcon fontSize="small" />} onClick={this.props.onRemove}>Remove Summary</Button>
          </div>
        </div>
      </Collapse>
    );
  }

  private onChangeSummary = (newSummary: string) => {
    this.props.onChange(newSummary);
  }

  private onFocusSummary = (event: React.FocusEvent<HTMLInputElement>) => {
    let oldSummary = event.target.value;
    this.setState({ oldSummary });
  }

  private onBlurSummary = (event: React.FocusEvent<HTMLInputElement>) => {
    const newSummary = event.target.value as string;
    if (newSummary && this.state.oldSummary !== newSummary) {
        this.onChangeSummary(newSummary);
    }
  }
}


interface ComponentProps {
  show: boolean;
  summary: string;
  onChange: (summary: string) => void;
  onRemove: () => void;
}

interface ComponentState {
  oldSummary: string;
}

export default Summary;