import React, { useEffect, useMemo, useState } from "react";

import { ContentCopy, Delete, DragIndicator, Refresh } from "@mui/icons-material";
import { ClickAwayListener, Collapse, FormControl, IconButton, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { IContentBand, IHeaderTranslation } from "../../models";
import { ContentBandSettingsForm } from "./contentBandSettingsForm";
import { IContentBandSettingActionProps } from "./contentBandsEditorPage";
import { TenantSettingsTagGroup } from "modules/settings";
import { ContentBandLayoutPreview } from "../layouts/contentBandLayoutPreview";
import { IPosition } from "modules/common/hooks/useDragReorder";
import { GlobalApplicationState } from "globalApplicationState";
import usePrevious from "modules/common/hooks/usePrevious";
import useIsFirstRender from "modules/common/hooks/useIsFirstRender";
import { arraysEqual } from "utils/equalityUtils";
import { contentBandsSlice } from "../../reducer";

import "../../styles/contentBandEditor.sass";
import { InputCounter } from "modules/common/components/forms/inputs/inputCounter";

export interface IContentBandEditorProps extends IContentBandSettingActionProps {
    idx: number;
    defaultLcid: string;
    activeLcid: string;
    contentBand: IContentBand;
    userTagGroups: TenantSettingsTagGroup[];
    isActive: boolean | null;
    draggedIdx: number | null;
    mousePos: IPosition;
    onCopy: (idx: number) => void;
    onRemove: (idx: number) => void;
    onDrag: (idx: number) => void;
    setActiveBand: (idx: number | null) => void;
    fetchContentForBand: (band: IContentBand, idx: number) => void;
}

export const ContentBandEditor: React.FunctionComponent<IContentBandEditorProps> = ({
    idx,
    contentBand,
    defaultLcid,
    userTagGroups,
    activeLcid,
    isActive,
    draggedIdx,
    mousePos,
    onCopy,
    onRemove,
    onDrag,
    setActiveBand,
    fetchContentForBand,
    ...actions
}) => {
    const dispatch = useDispatch();
    const isFirstRender = useIsFirstRender();
    const { fetchIdx, reordered, config, isLoadingContent } = useSelector((state: GlobalApplicationState) => state.contentBands);
    const previousBand = usePrevious<IContentBand>(contentBand);
    const [isRefreshable, setIsRefreshable] = useState<boolean>(false);

    // keep track of if we're currently drag re orderign
    // when dragging we only show the header
    const isDragging = draggedIdx !== null && draggedIdx !== -1;

    const [isHeaderFocused, setIsHeaderFocused] = useState<boolean>(false);

    const header = useMemo<string>(() => {
        const { headers } = contentBand;

        if (!headers) return "";

        let result: IHeaderTranslation | null = null;
        if (Object.keys(headers).includes(activeLcid)) result = headers[activeLcid];

        return result?.header?.substring(0, 35) || "";
    }, [contentBand, activeLcid]);

    // allow user to fetch content for band by refresh button when any of the following change:
    // - pinned ids
    // - types
    // - read status
    // - topic ids
    // - ignore subscriptions flag
    // - content type
    // ignore on first render because useContentBands handles that
    useEffect(() => {
        if (
            !isFirstRender &&
            !reordered &&
            ((contentBand !== undefined && previousBand === undefined) ||
                contentBand.contentType !== previousBand?.contentType ||
                contentBand.contentCount !== previousBand?.contentCount ||
                contentBand.lockToReadStatus !== previousBand?.lockToReadStatus ||
                contentBand.ignoreSubscriptions !== previousBand?.ignoreSubscriptions ||
                contentBand.sortBy !== previousBand?.sortBy ||
                !arraysEqual(contentBand.lockToTopicIds || [], previousBand?.lockToTopicIds || []) ||
                !arraysEqual(contentBand.pinnedIds || [], previousBand?.pinnedIds || []) ||
                !arraysEqual(contentBand.lockToTypes || [], previousBand?.lockToTypes || []))
        ) {
            setIsRefreshable(true);
        }

        // reset reordered flag
        dispatch({ type: contentBandsSlice.actions.SET_REORDERED, payload: false });
    }, [contentBand, dispatch, previousBand, idx, isLoadingContent, isFirstRender, reordered]);

    const onRefresh = () => {
        fetchContentForBand(contentBand, idx);
        setIsRefreshable(false);
    };

    // checks if a button for any content band was clicked
    // used for not setting a band as active on certain clicks
    const isAnyActionButtonClicked = (id: string, nodeName: string): boolean =>
        [
            `cb-drag-icon`,
            `cb-drag`,
            `cb-copy`,
            `cb-copy-icon`,
            `cb-trash`,
            `cb-trash-icon`,
            `cb-refresh-icon`,
            `cb-refresh`,
            `hover-add-btn`,
        ].some((prefix: string) => id.includes(prefix)) ||
        nodeName === "circle" ||
        nodeName === "path" ||
        nodeName === "svg";

    return (
        <ClickAwayListener
            onClickAway={(event: MouseEvent | TouchEvent) => {
                let target = event.target as HTMLElement;
                // when user clicks any action button, do not register as clicking away
                if (isActive && !isAnyActionButtonClicked(target.id, target.nodeName)) setActiveBand(null);
            }}
        >
            <div role="presentation">
                <div
                    id={`cb-${idx}`}
                    className={`content-band-editor-container`}
                    style={{
                        border: isActive || isDragging ? "solid 1px #dde1e5" : "none",
                        padding: isDragging ? "10px" : "20px 27px 0px",
                        width: "initial",
                    }}
                    onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                        let target = event.target as HTMLElement;

                        // only set active when not dragging and it wasn't any of the action buttons clicked
                        if (!isDragging && !isAnyActionButtonClicked(target.id, target.nodeName)) {
                            setActiveBand(idx);
                        }
                    }}
                >
                    <div className="actions">
                        <IconButton id={`cb-drag-${idx}`} onMouseDown={() => onDrag(idx)}>
                            <DragIndicator id={`cb-drag-icon-${idx}`} htmlColor="#3b78ab" />
                        </IconButton>
                        {isDragging ? (
                            <label>{header || <span style={{ color: "#999999" }}>(No header text)</span>}</label>
                        ) : (
                            <>
                                <IconButton id={`cb-copy-${idx}`} onClick={() => onCopy(idx)}>
                                    <ContentCopy id={`cb-copy-icon-${idx}`} htmlColor="#3b78ab" />
                                </IconButton>
                                <IconButton id={`cb-trash-${idx}`} onClick={() => onRemove(idx)}>
                                    <Delete id={`cb-trash-icon-${idx}`} htmlColor="#3b78ab" />
                                </IconButton>
                                {isRefreshable && (
                                    <IconButton id={`cb-refresh-${idx}`} onClick={onRefresh} sx={{ marginLeft: "auto" }}>
                                        <Refresh id={`cb-refresh-icon-${idx}`} color="primary" />
                                    </IconButton>
                                )}
                            </>
                        )}
                    </div>
                    {!isDragging && (
                        <>
                            <FormControl>
                                <TextField
                                    id={`cb-header-${idx}`}
                                    placeholder="(No header text)"
                                    inputProps={{
                                        onBlur: () => setIsHeaderFocused(false),
                                        onFocus: () => setIsHeaderFocused(true),
                                        style: {
                                            fontSize: 18,
                                            color: header && config?.contentBandsHeaderTextColor.hexCode,
                                        },
                                    }}
                                    InputProps={{
                                        disableUnderline: !isHeaderFocused,
                                    }}
                                    sx={{
                                        fontSize: 18,
                                    }}
                                    variant="standard"
                                    value={header}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                        actions.onChangeHeaderText(event.target.value, activeLcid, idx)
                                    }
                                />
                                {isActive && <InputCounter count={header.length || 0} maxCount={35} isFocused={isHeaderFocused} />}
                            </FormControl>
                            <ContentBandLayoutPreview
                                idx={idx}
                                isLoading={fetchIdx === idx}
                                fullWidth={false}
                                activeLcid={activeLcid}
                                contentBand={contentBand}
                            />
                            <Collapse in={isActive || false}>
                                <ContentBandSettingsForm
                                    idx={idx}
                                    defaultLcid={defaultLcid}
                                    settings={contentBand}
                                    userTagGroups={userTagGroups}
                                    {...actions}
                                />
                            </Collapse>
                        </>
                    )}
                </div>
            </div>
        </ClickAwayListener>
    );
};
