import * as React from "react";

import { Capacity as ICapacity } from "../../../models";

import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import Switch from "@mui/material/Switch";


class Capacity extends React.Component<ComponentProps, ComponentState> {
  constructor(props: ComponentProps) {
    super(props);

    this.state = {};
  }

  public render() {
    const { capacity } = this.props;
    
    return (
      <div className="capacities">
        <div className="section-card-subheading">Capacity</div>
        <div className="capacity">
          <div className="capacity-label">In-person</div>
          <div className="capacity-input">
            <Switch
              color="primary"
              checked={capacity.InPerson !== 0}
              onChange={this.onToggleInPerson}
            />
            <FormControl size="small">
              <OutlinedInput
                value={capacity.InPerson > -1 ? capacity.InPerson : ""}
                placeholder="Unlimited"
                type="number"
                disabled={this.props.disabled || capacity.InPerson === 0}
                fullWidth
                endAdornment={
                  <InputAdornment position="end">Pax.</InputAdornment>
                }
                inputProps={{ min: 0 }}
                onChange={this.onChangeInPerson}
              />
            </FormControl>
          </div>
        </div>
        <div className="capacity">
          <div className="capacity-label">Online</div>
          <div className="capacity-input">
            <Switch
              color="primary"
              checked={capacity.Online !== 0}
              onChange={this.onToggleOnline}
            />
            <FormControl size="small">
              <OutlinedInput
                value={capacity.Online > -1 ? capacity.Online : ""}
                placeholder="Unlimited"
                type="number"
                disabled={this.props.disabled || capacity.Online === 0}
                fullWidth
                endAdornment={
                  <InputAdornment position="end">Pax.</InputAdornment>
                }
                inputProps={{ min: 0 }}
                onChange={this.onChangeOnline}
              />
            </FormControl>
          </div>
        </div>
        <div className="capacity">
          <div className="capacity-label">Waitlist</div>
          <div className="capacity-input">
            <Switch
              color="primary"
              checked={capacity.Waitlist !== 0}
              onChange={this.onToggleWaitlist}
            />
            <FormControl size="small">
              <OutlinedInput
                value={capacity.Waitlist > -1 ? capacity.Waitlist : ""}
                placeholder="Unlimited"
                type="number"
                disabled={this.props.disabled || capacity.Waitlist === 0}
                fullWidth
                endAdornment={
                  <InputAdornment position="end">Pax.</InputAdornment>
                }
                inputProps={{ min: 0 }}
                onChange={this.onChangeWaitlist}
              />
            </FormControl>
          </div>
        </div>
      </div>
    );
  }

  private onChangeInPerson = (event) => {
    if (event.target.value !== "")
      this.props.onChange({ ...this.props.capacity, InPerson: event.target.value });
    else
      this.props.onChange({ ...this.props.capacity, InPerson: -1 });
  }

  private onChangeOnline = (event) => {
    if (event.target.value !== "")
      this.props.onChange({ ...this.props.capacity, Online: event.target.value });
    else
      this.props.onChange({ ...this.props.capacity, Online: -1 });
  }

  private onChangeWaitlist = (event) => {
    if (event.target.value !== "")
      this.props.onChange({ ...this.props.capacity, Waitlist: event.target.value });
    else
      this.props.onChange({ ...this.props.capacity, Waitlist: -1 });
  }

  private onToggleInPerson = (ev, checked: boolean) => {
    if (checked)
      this.props.onChange({ ...this.props.capacity, InPerson: -1 });
    else
      this.props.onChange({ ...this.props.capacity, InPerson: 0 });
  }

  private onToggleOnline = (ev, checked: boolean) => {
    if (checked)
      this.props.onChange({ ...this.props.capacity, Online: -1 });
    else
      this.props.onChange({ ...this.props.capacity, Online: 0 });
  }

  private onToggleWaitlist = (ev, checked: boolean) => {
    if (checked)
      this.props.onChange({ ...this.props.capacity, Waitlist: -1 });
    else
      this.props.onChange({ ...this.props.capacity, Waitlist: 0 });
  }
}


interface ComponentProps {
  capacity: ICapacity;
  disabled?: boolean;
  onChange: (capacity: ICapacity) => void;
}

interface ComponentState {
}

export default Capacity;