import * as network from "utils/network";
import { GlobalApplicationState } from "globalApplicationState";
import * as Actions from './actions';

export const getLCIDMappings = () => (dispatch, getState: () => GlobalApplicationState) => {
    const uri = getState().config.LCIDMappings;
    return network.CreateApiRequestInit(uri, {
        type: network.RequestMethod.GET,
        params: {}
    }, getState).then(request => {
        return fetch(request.uri, request.init)
        .then(response => {
            if (response.status === 200) {
                return response.json().then((mappings: {lcids: any}) => {
                    if (mappings) {
                        dispatch(Actions.SetLcidMappings({mappings: mappings.lcids}))
                    }
                });
            }
        });
    });
}

export const getEmailResource = () => (dispatch, getState: () => GlobalApplicationState) => {
    const uri = getState().config.FetchEmailResource;
    return network.CreateApiRequestInit(uri, {
        type: network.RequestMethod.GET,
        params: {}
    }, getState).then(request => {
        return fetch(request.uri, request.init)
        .then (response => {
            if(response.status === 200) {
                return response.json().then((contents: {items: any}) => {
                    if (contents) {
                        dispatch(Actions.SetEmailResource({resource: contents}))
                    }
                });
            }
        });
    });
}

export const getSMSResource = () => (dispatch, getState: () => GlobalApplicationState) => {
    const uri = getState().config.FetchSMSResource;
    return network.CreateApiRequestInit(uri, {
        type: network.RequestMethod.GET,
        params: {}
    }, getState).then(request => {
        return fetch(request.uri, request.init)
        .then (response => {
            if(response.status === 200) {
                return response.json().then((contents: {items: any}) => {
                    if (contents) {
                        dispatch(Actions.SetSmsResource({resource: contents}))
                    }
                });
            }
        });
    });
}

export const getMobileResource = () => (dispatch, getState: () => GlobalApplicationState) => {
    const uri = getState().config.FetchMobileResource;
    return network.CreateApiRequestInit(uri, {
        type: network.RequestMethod.GET,
        params: {}
    }, getState).then(request => {
        return fetch(request.uri, request.init)
        .then (response => {
            if(response.status === 200) {
                return response.json().then((contents: {items: any}) => {
                    if (contents) {
                        dispatch(Actions.SetMobileResource({resource: contents}))
                    }
                });
            }
        });
    });
}