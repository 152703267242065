import MsalAuthModule from "authentication/msalAuthModule";
import { GlobalApplicationState } from "globalApplicationState";

export enum RequestMethod {
    GET,
    POST,
    PUT,
    DELETE
}

export interface CreateApiRequestOptions {
    type: RequestMethod,
    headers?: {[key: string]: string},
    params?: {[key: string]: string},
    queryParams?: {[key: string]: string},
    body?: string | FormData,
    credentials?: string
}

export const CreateApiRequestInit = (path, options: CreateApiRequestOptions, getState: () => GlobalApplicationState) => {
    
    return MsalAuthModule.getInstance().getAccessToken().then(accessToken => {
        return CreateRequestInit(path, options, getState, getState().config.SparrowClientApiUrl, "cors", accessToken);
    });
}

export interface RequestInit {
    uri: string;
    init: any
}

const CreateRequestInit = (path, options: CreateApiRequestOptions, getState: () => GlobalApplicationState, base: string, mode: string, accessToken: string): RequestInit  => {
    const init = {
        method: requestMethodFromEnum(options.type),
        headers: new Headers(),
        // body: null //don't set body=null, add later. null body causes Edge to throw TypeMismatchError because it sucks
        mode: mode as RequestMode
    } as any;

        // ensures cookie is sent locally 
    if (mode === "same-origin") {
        init.credentials = "same-origin";
    }

    const builder = new RequestBuilder(base);
    builder.addPath(path);

    const tenant_id = getState().tenant?.id;
    if (tenant_id) {
        // set tenant if not included in params
        if (!options.params) {
            options.params = {"{tenant}": tenant_id};
        } else if (!options.params["{tenant}"]) {
            options.params["{tenant}"] = tenant_id;
        }
    }

    builder.mapParams(options.params);
    builder.addQueryParams(options.queryParams || {});

    if (options.type === RequestMethod.POST && (!!options.body && typeof options.body === "string")) {
        init.headers.append("Content-Type", "application/json; charset=UTF-8");
    }

    const uri = builder.getUri();

    if (mode !== "same-origin" && !!accessToken) {
        init.headers.append("Authorization", "Bearer " + accessToken);
    }

    if (options.headers) {
        const keys = Object.keys(options.headers);
        for (let i = 0; i < keys.length; i++) {
            init.headers.append(keys[i], options.headers[keys[i]]);
        }
    }

    if (options.body) {
        init.body = options.body;
    }

    if (options.credentials) {
        init.credentials = options.credentials;
    }

    return {
        uri: uri,
        init: init
    };
}

const requestMethodFromEnum = (method: RequestMethod)  => {
    switch (method) {
        case RequestMethod.DELETE:
            return "DELETE"
        case RequestMethod.GET:
            return "GET"
        case RequestMethod.POST:
            return "POST"
        case RequestMethod.PUT:
            return "PUT"
        default:
            return "undetermined"
    }
}

class RequestBuilder {
    private uri: string;

    constructor(baseUri: string) {
        this.uri = baseUri;
    }

    public addPath(path: string) {
        this.uri += path;
    }

    public mapParams(params) {
        const keys = Object.keys(params);
        for (let i = 0; i < keys.length; i++) {
            this.uri = this.uri.replace(keys[i], params[keys[i]]);
        }
    }

    public addQueryParams(queryParams: {[key: string]: string}) {

        if (this.uri.indexOf("?") < 0 && Object.keys(queryParams).length > 0) {
            this.uri += "?";
        }

        this.uri += Object.keys(queryParams)
            .reduce((acc, key) => `${acc}&${key}=${queryParams[key]}`, "");

        if (this.uri.indexOf("?") < 0)
            this.uri += "?platform=Web";
        else
            this.uri += "&platform=Web";
    }

    public getUri() {
        return encodeURI(this.uri);
    }
}
