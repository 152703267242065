import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { push } from "react-router-redux";
import * as actions from "modules/portalPages/actionCreator";
import { GlobalApplicationState } from "globalApplicationState";

import Button from "@mui/material/Button";

import LaunchIcon from "@mui/icons-material/Launch";


const EditNavigationItems: React.FunctionComponent<{ input, meta, field } & PropsWithRedux> = props => {
  return (
    <Button
      variant="text"
      color="primary"
      endIcon={<LaunchIcon />}
      onClick={() => props.showGoToNavigationItems()}
      className="text-button"
    >
      {props.field.label}
    </Button>
  );
}

interface ComponentProps {}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    tenantId: state.tenant.id
  }),
  {
    redirectTo: push,
    showGoToNavigationItems: actions.showGoToNavigationItems
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(EditNavigationItems);