import * as React from "react";
import { connect, ConnectedProps } from "react-redux";

import { GlobalApplicationState } from "globalApplicationState";
import { withRouter, Redirect } from "react-router";

class RedirectToWelcome extends React.Component<PropsWithRedux, ComponentState> {
  public render() {
    return (<Redirect to={`/${this.props.tenant_id || "tenant"}/welcome`} />);
  }
}

interface ComponentProps { }
interface ComponentState { }

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    tenant_id: state.tenant?.id
  })
);
type PropsWithRedux = ConnectedProps<typeof connector>;
export default withRouter(connector(RedirectToWelcome));