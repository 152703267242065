import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import * as actions from "../../actionCreator";
import { GlobalApplicationState } from "globalApplicationState";
import { push } from "react-router-redux";
import { NewsletterDigest, NewsletterFilterValues, NewsletterListingPage, NewsletterTags } from "../../models";

import NewsletterList, { NewsletterListComponentTab } from "./newsletterList";

import Tabs from "pages/common/tabs";

import "../../styles/newsletterListing.sass";
import { SortStyle } from "utils/managementUtils";
import * as audiencesActions from "modules/audiences/actionCreator";
import { Audience } from "modules/audiences";

const componentTabs : NewsletterListComponentTab[] = ['all', 'enabled', 'draft', 'disabled'];
class NewsletterListing extends React.Component<PropsWithRedux, ComponentState> {
  constructor(props: PropsWithRedux) {
    super(props);

    this.state = {
      allIsFetching: false,
      disabledIsFetching: false,
      draftIsFetching: false,
      enabledIsFetching: false,
      selectedTab: 'all',
      audiences: [],
    };
  }

  public componentWillMount() {
    this.fetchLists();
    this.props.getAudiences().then(audiences => this.setState({audiences: audiences.filter(a => a.enabled)})) //Get list of enabled audiences for filtering.
  }

  public componentDidUpdate(prevProps: PropsWithRedux) {
    if(this.props.changeCount > 0 && this.props.changeCount !== prevProps.changeCount)
      this.fetchLists();
  }

  public render() {
    const { selectedTab } = this.state;

    return (
      <React.Fragment>
        <div className="newsletter-listing">
          <Tabs
            tabs={[
              { label: this.getTabLabel("All", (!!this.props.tags && this.props.tags.count) || 0) },
              { label: this.getTabLabel("Enabled", (!!this.props.tags && this.props.tags.enabled.length) || 0), dotColor: "green" },
              { label: this.getTabLabel("Drafts", (!!this.props.tags && this.props.tags.draft.length) || 0), dotColor: "yellow" },
              { label: this.getTabLabel("Disabled", (!!this.props.tags && this.props.tags.disabled.length) || 0), dotColor: "grey" }
            ]}
            selectedTab={componentTabs.indexOf(this.state.selectedTab)}
            onSelectTab={this.onSelectTab}
          />
          <NewsletterList 
            show={selectedTab === 'all'} 
            tab='all' 
            page={this.state.all} 
            isFetching={this.state.allIsFetching} 
            fetchPage={this.fetchAll} 
            onCreateCopy={this.props.onCreateCopy} 
            onNewsletterChanged={this.props.onNewsletterChanged} 
            audiences={this.state.audiences}
          />
          <NewsletterList 
            show={selectedTab === 'enabled'} 
            tab='enabled' page={this.state.enabled} 
            isFetching={this.state.enabledIsFetching} 
            fetchPage={this.fetchEnabled} 
            onCreateCopy={this.props.onCreateCopy} 
            onNewsletterChanged={this.props.onNewsletterChanged} 
            audiences={this.state.audiences}
          />
          <NewsletterList 
            show={selectedTab === 'draft'} 
            tab='draft' page={this.state.draft} 
            isFetching={this.state.draftIsFetching} 
            fetchPage={this.fetchDrafts} 
            onCreateCopy={this.props.onCreateCopy} 
            onNewsletterChanged={this.props.onNewsletterChanged} 
            audiences={this.state.audiences}
          />
          <NewsletterList 
            show={selectedTab === 'disabled'} 
            tab='disabled' page={this.state.disabled} 
            isFetching={this.state.disabledIsFetching} 
            fetchPage={this.fetchDisabled} 
            onCreateCopy={this.props.onCreateCopy} 
            onNewsletterChanged={this.props.onNewsletterChanged} 
            audiences={this.state.audiences}
          />
        </div>
      </React.Fragment>
    );
  }

  /* Re-fetches all lists, first fetching the current tab, then subsequent ones */
  private fetchLists = () => {
    const fetches = [this.fetchAll, this.fetchEnabled, this.fetchDrafts, this.fetchDisabled]
    const firstFetchIndex = componentTabs.indexOf(this.state.selectedTab);
    const firstFetch = fetches[firstFetchIndex];
    firstFetch(1, {sortType: SortStyle.titleDesc}).then(() => {
      fetches.forEach((fetch,index)=> {
        if(index !== firstFetchIndex) fetch(1, {sortType: SortStyle.titleDesc});
      } )
    });
  }

  private fetchAll = (pageNumber: number, filters: Partial<NewsletterFilterValues>, pageAmount?: number): Promise<void> => {
    this.setState({allIsFetching: true});
    return this.props.getAllNewsletters(pageNumber, filters, pageAmount).then(page => {
      this.setState({all:page, allIsFetching:false});
    });
  }

  private fetchEnabled = (pageNumber: number, filters: Partial<NewsletterFilterValues>, pageAmount?: number): Promise<void>  => {
    this.setState({enabledIsFetching: true});
    return this.props.getEnabledNewsletters(pageNumber, filters, pageAmount).then(page => {
      this.setState({enabled:page, enabledIsFetching:false});
    });
  }

  private fetchDrafts = (pageNumber: number, filters: Partial<NewsletterFilterValues>, pageAmount?: number): Promise<void>  => {
    this.setState({draftIsFetching: true});
    return this.props.getDraftNewsletters(pageNumber, filters, pageAmount).then(page => {
      this.setState({draft:page, draftIsFetching:false});
    });
  }

  private fetchDisabled = (pageNumber: number, filters: Partial<NewsletterFilterValues>, pageAmount?: number): Promise<void>  => {
    this.setState({disabledIsFetching: true});
    return this.props.getDisabledNewsletters(pageNumber, filters, pageAmount).then(page => {
      this.setState({disabled:page, disabledIsFetching:false});
    });
  }

  private getTabLabel = (label: string, count?: number): string => {
    if (!!count)
      return `${label} (${count})`;
    return label;
  }

  private onSelectTab = (index: number) => {
    const selectedTab = componentTabs[index];
    this.setState({...this.state, selectedTab: selectedTab});
  }
}

interface ComponentProps {
  tags: NewsletterTags | null;
  changeCount: number;
  onCreateCopy: (newsletter: NewsletterDigest) => any;
  onNewsletterChanged: (successMessage:string) => any;
}
interface ComponentState {
  audiences: Audience[];

  all?: NewsletterListingPage;
  enabled?: NewsletterListingPage;
  draft?: NewsletterListingPage;
  disabled?: NewsletterListingPage;

  allIsFetching: boolean;
  enabledIsFetching: boolean;
  draftIsFetching: boolean;
  disabledIsFetching: boolean;

  selectedTab: NewsletterListComponentTab;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ownProps,
  {
    getAllNewsletters: actions.getAllNewsletters,
    getDisabledNewsletters: actions.getDisabledNewsletters,
    getDraftNewsletters: actions.getDraftNewsletters,
    getEnabledNewsletters: actions.getEnabledNewsletters,
    getAudiences: audiencesActions.getAudiences,
    redirectTo: push
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(NewsletterListing);