import { ApiRequest } from './network';
import { SparrowApi } from 'api/network/adapters/SparrowApi';
import { AnalyticsReportFilters, GetTotalSubscribersModel, TagStats } from 'modules/insights';

export class InsightsApi {

    constructor(private _sparrowApi: SparrowApi) {
    }

    public GetTopPostsFromInsights = (reportFilter: AnalyticsReportFilters) => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
                .post('{tenant}/platformAnalytics/v1/postStats', reportFilter)
        );
    }

    public GetTagStats = () => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
                .get<TagStats[]>('/{tenant}/insightsapi/v1/tagStats')
        );
    }

    public GetUniqueSubscribersForTopics = (topicIds: string[]) => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
                .post<number>('/{tenant}/insightsapi/v1/tagStats/uniqueSubscribers', topicIds)
        );
    }

    public GetSpecificTagStats = (id: string) => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
            .get('{tenant}/insightsapi/v1/tagstats/{id}', {params: {id: id}})
        )
    }

    public GetUsersByMonthStats = () => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
                .get('/{tenant}/insightsapi/v1/userCounts/byMonth')
        )
    }

    public GetRegisteredUsersByDayStats = () => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
                .get('/{tenant}/insightsapi/v1/userCounts/byDay')
        )
    }
    // User Trend - same as UsersByMonth?
    public GetUserTrendStats = () => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
                .get('/{tenant}/insightsapi/v1/userCounts/byMonth')
        )
    }

    // App usage
    public GetAppUsageStats = () => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
                .get('/{tenant}/insightsapi/v1/platformStats')
        )
    }

    // Comments
    public GetCommentsByMonthStats = () => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
                .get('/{tenant}/insightsapi/v1/commentCounts/byMonth')
        )
    }

    // Views by Time of Day
    public GetReadsByTimeOfDayStats = () => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
                .get('/{tenant}/insightsapi/v1/readStats/byTimeOfDay')
        )
    }

    public GetReadViewsByTimeOfDay = () => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
            .get('/{tenant}/insightsapi/v1/readViewStats/byTimeOfDay')
            )
        }
        
    public GetComplianceReportCSV = (postId: string) => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
                .get('/{tenant}/insightsapi/v2/postStats/{id}/complianceReportCSV', { params: { id: postId } })
        );
    }

    public GetPosttInsightBreakdowns = (postId: string) => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
            .get('/{tenant}/insightsapi/v2/postStats/{id}/insightBreakdowns', { params: { id: postId } })
        )
    }

    public GetDetailedPostInsights = (postId: string, fetchCompliance: boolean) => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
            .get('/{tenant}/insightsapi/v3/postStats/{id}/detailedInsights', { params: { id: postId, fetchCompliance } })
        )
    }

    public GetPostActivityInsights = (postId: string, fetchComments: boolean, fetchReactions: boolean) => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
            .get('/{tenant}/insightsapi/v2/postStats/{id}/activityInsights', { params: { id: postId, fetchComments, fetchReactions } })
        )
    }

    public GetPlatformPreferredLanguageStats = () => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
            .get('/{tenant}/insightsapi/v1/platformStats/preferredLanguage')
        )
    }

    public DownloadInsightReport = (url: string, payload: any) => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
                .post(url, payload, { responseType: 'arraybuffer' })
        );
    }

    public GetTotalSubscribers = (url: string, payload: GetTotalSubscribersModel) => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
                .post(url, payload)
        );
    }
}