import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import * as portalPagesActions from "modules/portalPages/actionCreator";
import * as settingsActions from "modules/settings/actionCreator";
import * as resourceActions from "modules/resources/actionCreator";
import { withRouter, RouteComponentProps } from "react-router";

class ClientRouteWrapper extends React.Component<PropsWithRedux, {}> {
    public async componentDidMount() {
        let path = this.props.location.pathname.split(`${this.props.match.url}/`);
        this.props.history.replace(`/${this.props.tenantId}/${path[1] !== undefined ? path[1] : "home"}`);

        //Await first call in case the token is expired, if we do not await then it will call all 
        //others and fail the requests with unauthorized. (This is due to the popup for MSAL)
        await this.props.getLCIDMappings();
        await Promise.all([
            this.props.getClientSettings(),
            this.props.getCurrentUser(),
            this.props.getNavigation(),
            this.props.getNotificationSettings(),
            this.props.getUserTagSettings(),
        ]);
    }

    public render() {
        return <div style={{ height: "100%", minHeight: "100%" }}>{this.props.children}</div>;
    }
}

interface RouteParams {
    tenant: string;
}

interface ComponentProps {}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps & RouteComponentProps<RouteParams>) => ({
        ...ownProps,
        tenantId: state.tenant.id,
    }),
    {
        getClientSettings: settingsActions.getClientSettings,
        getLCIDMappings: resourceActions.getLCIDMappings,
        getCurrentUser: settingsActions.getUserSettings,
        getNavigation: portalPagesActions.getNavigation,
        getNotificationSettings: settingsActions.getNotificationSettings,
        getUserTagSettings: settingsActions.getUserTagSettings
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default withRouter(connector(ClientRouteWrapper));
