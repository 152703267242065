import { LinearProgress } from "@mui/material";
import React, { useEffect, useState } from "react";

import "../styles/indicator.sass";

const SCORE_ICON_SRC: string = "/images/icons/change_history.svg";
const RECC_ICON_SRC: string = "/images/icons/recommended_score_pin.svg";
const GLBL_ICON_SRC: string = "/images/icons/global_score_pin.svg";

export type Range = {
    min: number;
    max: number;
}

interface IIndicatorProps {
    scoreLabel: string;
    startLabel: string;
    endLabel: string;
    title: string;
    range: Range;
    score: number;
    reverse?: boolean; // max numbers and labels on the left
    recommendedScore?: number;
    useGlobalIcon: boolean;
}

/**
 * An indicator component indicating the following:
 * - recommended score (FUTURE ITERATION)
 * - current score
 * - max/min scores
 *
 * The progress bar alg works like this:
 * 1. Split up the full range into two separate equal ranges
 * 2. The two separate range can then be normalized to 0-100 range and applied to a mui progress bar
 * 3. The right to left, rtl, progress bar is then rotated 180 deg so the progress goes from right to left
 *
 * NOTE: the hard coded 100s are the default max value for a React MUI progress component
 */
const Indicator: React.FunctionComponent<IIndicatorProps> = ({
    scoreLabel,
    startLabel,
    endLabel,
    title,
    range,
    score,
    recommendedScore,
    reverse = false,
    useGlobalIcon
}) => {
    const [rtlRange, setRtlRange] = useState<Range>();
    const [ltrRange, setLtrRange] = useState<Range>();

    /**
     * Split up the full range into two equal ranges (ex. if range is 0-100, split into 0-50, 50-100)
     */
    useEffect(() => {
        let half = range.max / 2;

        setRtlRange({ min: range.min, max: half });
        setLtrRange({ min: half, max: range.max });
    }, [range]);

    /**
     * Get the normalized value for the progress bar that grows left to right
     */
    const getLeftToRightValue = (scoreToUse: number): number => {
        if (!rtlRange || !ltrRange) return 0;

        let value = 0;
        if (scoreToUse >= ltrRange.min && scoreToUse <= ltrRange.max)
            value = normalise(scoreToUse, ltrRange.min, ltrRange.max);

        return value;
    }

    /**
     * Get the normalized value for the progress bar that grows from right to left
     */
    const getRightToLeftValue = (scoreToUse: number): number => {
        if (!rtlRange || !ltrRange) return 0;

        let value = 0;

        // minus from 100 here because we're flipping a one directional range, ex. [0 -> 100], to two ranges going the opposite ways
        // for example, a score of 2 in a 0 - 100 range, should take up most of the right to left progress bar in our bi-directional range
        // 100 because that's the range the LinearProgress component uses
        if (scoreToUse >= rtlRange.min && scoreToUse <= rtlRange.max)
            value = 100 -  normalise(scoreToUse, rtlRange.min, rtlRange.max);


        return value;
    }

    // transform a value in one range to the same value in a different range
    // https://mui.com/material-ui/react-progress/#non-standard-ranges
    const normalise = (value: number, min: number, max: number): number => ((value - min) * 100) / (max - min);

    const linearProgress = (left: boolean): JSX.Element => {
        let value = 0;
        const ltrValue = getLeftToRightValue(score);
        const rtlValue = getRightToLeftValue(score);

        // with reverse we put the value from one progress bar in the other and vice versa
        value = reverse === left
            ? ltrValue
            : rtlValue;

        return (
            <LinearProgress
                className={`progress ${left ? "left" : "right"} ${reverse ? "reverse" : ""}`}
                classes={{
                    colorPrimary: "empty",
                    barColorPrimary: "filled"
                }}
                variant="determinate"
                value={value}
            />
        );
    }

    const verticalLine = (): JSX.Element => (
        <div className="vertical-line"></div>
    );

    /**
     * Get the component to display the start and end labels
     */
    const startEndLabels = (): JSX.Element => (
        <div className="start-end-labels">
            {reverse
                ? <>
                    <span className="label">{endLabel}</span>
                    <span className="label">{startLabel}</span>
                </>
                : <>
                    <span className="label">{startLabel}</span>
                    <span className="label">{endLabel}</span>
                </>}
        </div>
    );

    /**
     * Gets the element that displays their score
     */
    const scoreLabelComponent = (): JSX.Element => {
        const ltrValue = getLeftToRightValue(score);
        const rtlValue = getRightToLeftValue(score);

        let valueToUse = rtlValue === 0 ? ltrValue : rtlValue;

        // the label starts off in the middle of the progress bars
        // if the progress bar is going right to left, then we negate the translation percent to move it left
        // by the amount of progress ie rtlValue
        let translateBy = rtlValue > 0 && !reverse ? 0 - valueToUse : valueToUse;

        // handle cases where value is 100 i.e. max score
        let containerStyle: React.CSSProperties = { justifyContent: "center" };
        let labelStyle: React.CSSProperties = { textAlign: "center" };

        // left to right score is maxed OR reverse is on and right to left is maxed
        if ((!reverse && ltrValue === 100) || (reverse && rtlValue === 100)) {
            containerStyle = { justifyContent: "flex-end" };
            labelStyle = { textAlign: "right" };
            translateBy = 0;
            // right to left is maxed OR reverse is on and left to right is maxed
        } else if ((!reverse && rtlValue === 100) || (reverse && ltrValue === 100)) {
            containerStyle = { justifyContent: "flex-start" };
            labelStyle = { textAlign: "unset" };
            translateBy = 0;
        } else if (reverse && ltrValue > 0) {
            translateBy = -1 * translateBy;
        }

        return (
            <div className="score-label-container" style={containerStyle}>
                <p
                    style={{ transform: `translateX(${translateBy}%)`, ...labelStyle }}
                    className="score-label"
                >
                    {scoreLabel} <br />
                    <img src={SCORE_ICON_SRC} width={16} alt={"Your score"} />
                </p>
            </div>
        );
    }

    /**
     * Gets the element that displays the suggested score
     */
    const recommendedScoreLabelComponent = (): JSX.Element => {
        const ltrValue = getLeftToRightValue(recommendedScore!);
        const rtlValue = getRightToLeftValue(recommendedScore!);

        let valueToUse = rtlValue === 0 ? ltrValue : rtlValue;

        // the label starts off in the middle of the progress bars
        // if the progress bar is going right to left, then we negate the translation percent to move it left
        // by the amount of progress ie rtlValue
        let translateBy = rtlValue > 0 && !reverse ? 0 - valueToUse : valueToUse;

        // handle cases where value is 100 i.e. max score
        let containerStyle: React.CSSProperties = { justifyContent: "center" };
        let labelStyle: React.CSSProperties = { textAlign: "center" };

        // left to right score is maxed OR reverse is on and right to left is maxed
        if ((!reverse && ltrValue === 100) || (reverse && rtlValue === 100)) {
            containerStyle = { justifyContent: "flex-end" };
            labelStyle = { textAlign: "right" };
            translateBy = 0;
            // right to left is maxed OR reverse is on and left to right is maxed
        } else if ((!reverse && rtlValue === 100) || (reverse && ltrValue === 100)) {
            containerStyle = { justifyContent: "flex-start" };
            labelStyle = { textAlign: "unset" };
            translateBy = 0;
        } else if (reverse && ltrValue > 0) {
            translateBy = -1 * translateBy;
        }

        return (
            <div className="score-label-container" style={containerStyle}>
                <p
                    style={{ transform: `translateX(${translateBy}%)`, ...labelStyle, position: "relative", top: "33px" }}
                    className="score-label"
                >
                    <img src={useGlobalIcon ? GLBL_ICON_SRC : RECC_ICON_SRC} width={20} alt={"Average score"} />
                </p>
            </div>
        );
    }

    return (
        <div className="indicator">
            <label className="title">{title}</label>
            {recommendedScore !== null && recommendedScoreLabelComponent()}
            {scoreLabelComponent()}
            <div className="progress-container">
                {linearProgress(true)}
                {verticalLine()}
                {linearProgress(false)}
            </div>
            {startEndLabels()}
        </div>
    );
}

export default Indicator;
