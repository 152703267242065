import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import * as actions from "../actionCreator";
import { GlobalApplicationState } from "globalApplicationState";

import { CommentNotificationSettings } from "modules/settings/models";
import DialogSelectField from "./common/dialogSelectField";
import DialogSwitchField from "./common/dialogSwitchField";

import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PreferredSMSPhoneDialog from "./dialogs/preferredSMSPhoneDialog";


class Notifications extends React.Component<PropsWithRedux, ComponentState> {
  constructor(props: PropsWithRedux) {
    super(props);

    this.state = {
      hasChanges: false,
      chatEnabled: props.userSettings.chatEnabled,
      commentNotificationSettings: props.userSettings.commentNotificationSettings,
      preferredSMSConsent: props.userSettings.preferredSMSConsent,
      preferredSMSPhone: props.userSettings.preferredSMSPhone,
      sendNotificationsWhenAuthor: props.userSettings.sendNotificationsWhenAuthor,
      showPreferredSMSPhoneDialog: false,
    };
  }

  public componentDidUpdate(prevProps: PropsWithRedux) {
    if (this.props.show && prevProps.show !== this.props.show)
      this.onReset();
  }

  public render() {
    return (
      <Drawer anchor="right" open={this.props.show} onClose={this.onSave} classes={{ paper: "my-profile-dialog-drawer" }} className="my-profile-dialog">
        <Paper square elevation={0} className="my-profile-dialog-header">
          <IconButton onClick={this.onSave} size="large">
            <ArrowBackIcon />
          </IconButton>
          <div>Notifications</div>
        </Paper>
        <DialogSwitchField
          label="Notify me of comments for posts I've published or authored"
          value={this.state.sendNotificationsWhenAuthor}
          onChange={this.onChangeSendNotificationsWhenAuthor}
        />
        <DialogSelectField
          label="Notify me of comments on"
          options={[
            { key: "accessible", text: "All posts I have access to", type: "item" },
            { key: "engaged", text: "All posts I have commented on or reacted to", type: "item" },
            { key: "subscribed", text: "All posts I'm subscribed to", type: "item" },
            { key: "none", text: "None", type: "item" }
          ]}
          value={this.state.commentNotificationSettings}
          onChange={this.onChangeCommentNotificationSettings}
        />
        {this.props.chatEnabled &&
          <DialogSwitchField
            label="Receive chat notifications"
            description="Get notified whenever you receive a new chat message"
            value={this.state.chatEnabled}
            onChange={this.onChangeChatEnabled}
          />
        }
        <DialogSwitchField
          label="SMS notifications & alerts"
          description="Send me notifications via SMS"
          value={!!this.state.preferredSMSConsent}
          onChange={this.onChangePreferredSMSConsent}
        />
        <Divider light />
        <div className="reset-dialog">
          <Button variant="text" color="primary" onClick={this.onReset}>Reset notifications</Button>
        </div>
        <PreferredSMSPhoneDialog
          show={this.state.showPreferredSMSPhoneDialog}
          preferredSMSPhone={this.state.preferredSMSPhone}
          onChange={this.onChangePreferredSMSPhone}
          onClose={this.onHidePreferredSMSPhoneDialog}
        />
      </Drawer>
    );
  }


  private onSave = () => {
    if (this.state.hasChanges)
      this.props.saveUserSettings({
        chatEnabled: this.state.chatEnabled,
        commentNotificationSettings: this.state.commentNotificationSettings,
        preferredSMSConsent: this.state.preferredSMSPhone !== this.props.userSettings.preferredSMSPhone ? null : this.state.preferredSMSConsent,
        preferredSMSPhone: this.state.preferredSMSPhone,
        sendNotificationsWhenAuthor: this.state.sendNotificationsWhenAuthor,
      });
    this.props.onClose();
  }


  private onChangeChatEnabled = (event, checked) => {
    this.setState({ hasChanges: true, chatEnabled: checked });
  }

  private onChangeCommentNotificationSettings = (event) => {
    this.setState({ hasChanges: true, commentNotificationSettings: event.target.value });
  }

  private onChangePreferredSMSConsent = (event, checked) => {
    if (!checked) {
      this.setState({ hasChanges: true, preferredSMSConsent: false });
    } else {
      if (!this.state.preferredSMSPhone)
        this.onShowPreferredSMSPhoneDialog();
      else
        this.setState({ hasChanges: true, preferredSMSConsent: true });
    }
  }

  private onChangePreferredSMSPhone = (preferredSMSPhone: string) => {
    const preferredSMSConsent: boolean | null = !!preferredSMSPhone ? true : null;

    this.props.saveUserSettings({
      preferredSMSConsent,
      preferredSMSPhone
    });
    this.setState({ preferredSMSConsent, preferredSMSPhone, showPreferredSMSPhoneDialog: false });
  }

  private onChangeSendNotificationsWhenAuthor = (event, checked) => {
    this.setState({ hasChanges: true, sendNotificationsWhenAuthor: checked });
  }

  private onReset = () => {
    this.setState({
      hasChanges: false,
      chatEnabled: this.props.userSettings.chatEnabled,
      commentNotificationSettings: this.props.userSettings.commentNotificationSettings,
      preferredSMSConsent: this.props.userSettings.preferredSMSConsent,
      preferredSMSPhone: this.props.userSettings.preferredSMSPhone,
      sendNotificationsWhenAuthor: this.props.userSettings.sendNotificationsWhenAuthor,
    });
  }


  private onHidePreferredSMSPhoneDialog = () => {
    this.setState({ showPreferredSMSPhoneDialog: false });
  }

  private onShowPreferredSMSPhoneDialog = () => {
    this.setState({ showPreferredSMSPhoneDialog: true });
  }

}


interface ComponentProps {
  show: boolean;
  onClose: () => void;
}

interface ComponentState {
  hasChanges: boolean;
  chatEnabled: boolean;
  commentNotificationSettings: CommentNotificationSettings;
  preferredSMSConsent: boolean | null;
  preferredSMSPhone: string;
  sendNotificationsWhenAuthor: boolean;
  showPreferredSMSPhoneDialog: boolean;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    chatEnabled: state.settings.clientSettings.chatEnabled,
    userSettings: state.profile.userSettings!
  }),
  {
    saveUserSettings: actions.saveUserSettings
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(Notifications);