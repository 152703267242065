import React from 'react';
import './textBubble.sass';

export interface TextBubble {
    id: string;
    name: string;
}
interface ComponentProps {
    removable?: boolean;
    removeFrom?: (any) => void;
    list: any[];
}
const TextBubble: React.FC<ComponentProps> = ({list, removable, removeFrom}) => {

    const displayList = list.map((item) => {
        return (
            <React.Fragment key={item.id}>
                {removable ? 
                <span className="topic-bubble-removable">{item.name}<i onClick={() => removeFrom!(item.id)} className="material-icons">clear</i></span>
                :
                <span className="topic-bubble">{item.name}</span>

                }
            </React.Fragment>
        )
    })

    return (
        <div>
            {displayList}            
        </div>
    )
}

export default TextBubble;