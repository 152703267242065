import React from "react";

import { Rule, RuleApplicability, RuleComparator } from "../../../models";
import { TenantAttribute } from "modules/settings/models";

import Disclaimer from "modules/common/components/disclaimer";

import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";


class SmartRules extends React.Component<ComponentProps, ComponentState> {
  public componentDidMount() {
    if (!this.props.rules.length)
      this.onAddRule();
  }

  public render() {
    const { rules } = this.props;

    return (
      <div className="smart-rules">
        <Disclaimer
          icon={<InfoOutlinedIcon />}
          text="Smart audiences dynamically add and remove users using the rules you apply. At least 1 rule is required."
        />
        <div className="rule-applicability">
          <div>Include users only if</div>
          <FormControl size="small" fullWidth>
            <Select
              variant="outlined"
              fullWidth
              value={this.props.ruleApplicability}
              onChange={(event) => this.props.onChangeRuleApplicability(event.target.value as RuleApplicability)}
            >
              <MenuItem key="all" value="all">ALL rules are applicable to a user</MenuItem>
              <MenuItem key="any" value="any">ANY rule is applicable to a user</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="rules">
          {rules.map((rule, index) => (
            this.getRuleSelection(rule, index + 1)
          ))}
        </div>
        <Button variant="text" color="primary" startIcon={<AddIcon />} disabled={!rules[rules.length - 1] || !rules[rules.length - 1].key || !rules[rules.length - 1].value} onClick={this.onAddRule} className="new-rule">New Rule</Button>
      </div>
    );
  }

  private defaultRule = (): Rule => {
    return {
      key: "",
      comparator: "equals",
      value: ""
    };
  }

  private getRuleSelection = (rule: Rule, number: number) => {
    const RULE_MAX_CHAR_LIMIT = 10000;

    return (
      <Collapse key={`rule-id-${number}`} in={true} appear={number !== 1}>
        <div>
          {(number !== 1 || this.props.rules.length > 1) &&
            <IconButton size="small" onClick={() => this.onRemoveRule(number)} className="remove-rule"><DeleteIcon fontSize="small" /></IconButton>
          }
          <div className="rule-number">Rule {number}. Include a user if their user profile has...</div>
          <div className="rule-options">
            <FormControl size="small" fullWidth>
              <Select
                variant="outlined"
                fullWidth
                value={rule.key}
                displayEmpty
                error={number === 1 && !rule.key}
                onChange={(event) => this.onChangeRuleKey(number, event.target.value as string)}
              >
                <MenuItem key="" value="" disabled>Select a profile field</MenuItem>
                {this.props.tenantAttributes.map((tenantAttribute) =>
                  <MenuItem key={tenantAttribute.key} value={tenantAttribute.key}>{tenantAttribute.title}</MenuItem>
                )}
              </Select>
            </FormControl>
            <FormControl size="small" fullWidth>
              <Select
                variant="outlined"
                fullWidth
                value={rule.comparator}
                onChange={(event) => this.onChangeRuleComparator(number, event.target.value as RuleComparator)}
              >
                <MenuItem key="equals" value="equals">equal to</MenuItem>
                <MenuItem key="notEquals" value="notEquals">NOT equal to</MenuItem>
                <MenuItem key="contains" value="contains">contains</MenuItem>
                <MenuItem key="notContains" value="notContains">does NOT contain</MenuItem>
                {this.props.equalToAnyRuleEnabled && <MenuItem key="equalToAny" value="equalToAny">equal to ANY</MenuItem>}
              </Select>
            </FormControl>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Enter exact text to match"
              value={rule.value}
              fullWidth
              error={number === 1 && !rule.value}
              inputProps={{
                maxLength: RULE_MAX_CHAR_LIMIT
              }}
              onChange={(event) => this.onChangeRuleValue(number, event.target.value)}
            />
          </div>
        </div>
      </Collapse>
    );
  }

  private onAddRule = () => {
    this.props.onChangeRules(this.props.rules.concat({ ...this.defaultRule() }));
  }

  private onChangeRule = (number: number, currentRule: Rule) => {
    let rules: Rule[] = [];
    this.props.rules.map((rule, index) => {
      if (index === number - 1)
        rules.push(currentRule);
      else
        rules.push(rule);
      return rule;
    });
    this.props.onChangeRules(rules);
  }

  private onChangeRuleComparator = (number: number, comparator: RuleComparator) => {
    this.onChangeRule(number, { ...this.props.rules[number - 1], comparator });
  }

  private onChangeRuleKey = (number: number, key: string) => {
    this.onChangeRule(number, { ...this.props.rules[number - 1], key });
  }

  private onChangeRuleValue = (number: number, value: string) => {
    this.onChangeRule(number, { ...this.props.rules[number - 1], value });
  }

  private onRemoveRule = (number: number) => {
    const rules = this.props.rules.filter((_, index) => index !== number - 1);
    this.props.onChangeRules(rules);
  }
}


interface ComponentProps {
  ruleApplicability: RuleApplicability;
  rules: Rule[];
  tenantAttributes: TenantAttribute[];
  equalToAnyRuleEnabled?: boolean;
  onChangeRuleApplicability: (ruleApplicability: RuleApplicability) => void;
  onChangeRules: (rules: Rule[]) => void;
}

interface ComponentState {}

export default SmartRules;