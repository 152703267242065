import React from "react";
import { GlobalApplicationState } from "globalApplicationState";
import { connect, ConnectedProps } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router";
import { createGlobalStyle } from "styled-components";

import { createTheme, ThemeProvider } from "@mui/material/styles"; //v5

const AdminPalette = {
  blue: "#3b78ab",
  rose: "#aa214e"
};

const PublicPalette = {
  blue: "#3b78ab"
};

const TinaTheme = createGlobalStyle<{}>`
  html body {
    --tina-color-primary-light: ${AdminPalette.blue};
    --tina-color-primary: ${AdminPalette.blue};
    --tina-color-primary-dark: ${AdminPalette.blue};
  }
`;

const Theme: React.FunctionComponent<PropsWithRedux> = props => {
  const isAdminTheme: boolean = props.history.location.pathname.includes("/admin");
  const isPublicTheme: boolean = props.history.location.pathname.includes("/public");
  const primary: string = props.themeEnabled ? (props.brand || "#333333") : "#2196f3";

  const theme = createTheme({
    palette: {
      background: {
        default: "#f3f3f4"
      },
      primary: {
        main: isAdminTheme
          ? AdminPalette.blue
          : isPublicTheme
            ? PublicPalette.blue
            : primary
      },
      secondary: {
        main: isAdminTheme
          ? AdminPalette.rose
          : isPublicTheme
            ? PublicPalette.blue
            : primary
      },
      info: {
        main: "#888888"
      }
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
    typography: {
      h1: {
        fontSize: "2rem",
        fontWeight: 500
      },
      h2: {
        fontSize: "1.25rem",
        fontWeight: 500
      },
      h3: {
        fontSize: "1rem",
        fontWeight: 500
      },
      h4: {
        fontSize: "1rem",
        fontWeight: 500
      },
      h5: {
        fontSize: "1rem",
        fontWeight: 500
      },
      h6: {
        fontSize: "1rem",
        fontWeight: 500
      },
      caption: {
        color: "#666666",
        fontSize: 12
      },
      button : {
        color: "red"
      }
    },
    components: {
      MuiLink: {
        styleOverrides: {
          root: {
            color: isAdminTheme
              ? AdminPalette.blue
              : isPublicTheme
                ? PublicPalette.blue
                : primary,
            "&:hover": {
              color: isAdminTheme
                ? AdminPalette.blue
                : isPublicTheme
                  ? PublicPalette.blue
                  : primary
            }
          }
        }
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            backgroundColor: "#ffffff",
            ".Mui-disabled": {
              backgroundColor: "#f0f0f0",
              color: "#888888"
            }
          }
        }
      },
      MuiSnackbar: {
        styleOverrides: {
          root: {
            position: "absolute"
          }
        }
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: "none"
          }
        }
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            backgroundColor: "#f3f3f4"
          }
        }
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            "&$hover": {
              "&:hover": {
                backgroundColor: isAdminTheme ? "rgba(59, 120, 171, 0.08)" : `${primary}14`
              },
              "&$selected": {
                backgroundColor: isAdminTheme ? "rgba(59, 120, 171, 0.08)" : `${primary}14`
              }
            }
          }
        }
      }
    }
  });

  return (
      <ThemeProvider theme={theme}>
        <TinaTheme />
        {props.children}
      </ThemeProvider>
  );
};

interface ComponentProps {}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    brand: state.settings.clientSettings.theme.enabled ? state.settings.clientSettings.theme.color : "",
    themeEnabled: !!state.settings.clientSettings.id ? state.settings.clientSettings.theme.enabled : true
  })
);
type PropsWithRedux = ConnectedProps<typeof connector> & RouteComponentProps<{}>;;

export default withRouter(connector(Theme));