import React from 'react';
import numeral from "numeral";

const UserInsightsBlock = ({ stats, title }) => {
    
    return (
        <div className="block-container">
            <div className="block-container-info">
                <div className="insights-stats">
                    {numeral(stats).format("0,0")}
                </div>
                <div className="insights-title">
                    {title}
                </div>
            </div>
        </div>
    )
}

export default UserInsightsBlock;