import * as React from "react";

import MuiChip from "@mui/material/Chip";

import CloseIcon from "@mui/icons-material/Close";


import "./chips.sass";


const Chips: React.FunctionComponent<ComponentProps> = props => {
  if (!props.chips || !props.chips.length) {
    if (props.hideAll)
      return <React.Fragment></React.Fragment>;

    return (
      <div className="basic-chips">
        <MuiChip
          label={props.emptyText || "All users"}
          disabled={true}
          className="empty-chip"
        />
      </div>
    );
  }

  return (
    <div className="basic-chips">
      {props.chips.map((chip) => (
        <MuiChip
          key={chip.id}
          label={`${chip.name}`}
          onDelete={(!!props.onDelete || !!chip.onDelete)
            ? !!props.onDelete
              ? () => props.onDelete!(chip.id)
              : () => chip.onDelete!(chip.id)
            : undefined
          }
          deleteIcon={<CloseIcon />}
        />
      ))}
    </div>
  );
}

interface ComponentProps {
  chips: { id: string, name: string, onDelete?: (chipId: string) => void }[];
  emptyText?: string;
  hideAll?: boolean;
  onDelete?: (chipId: string) => void;
}

export default Chips;