import React from "react";
import { AccountCircle, HomeOutlined, Menu, MessageOutlined, Search } from "@mui/icons-material";
import { Divider } from "@mui/material";

import { MobileAppPages } from "modules/contentBands/models";

import "./styles/mobilePreview.sass";

export interface IMobileAppPreviewProps {
    activePage: MobileAppPages;
    appName?: string;
}

export const MobilePreview: React.FunctionComponent<IMobileAppPreviewProps> = ({ children, appName = "Sparrow Connected", activePage }) => {
    return (
        <div className="mobile-preview-container">
            <div className="header-bar">
                <Menu htmlColor="#ffffff" style={{ marginRight: 7 }} />
                <span className="app-name">{appName}</span>
                <div className="search">
                    <Search htmlColor="#ffffff" />
                </div>
            </div>
            <div className="mobile-preview-content">
                {children}
                <div className="footer-bar">
                    <Divider style={{ borderBottomWidth: 5 }} />
                    <div className="footer-actions">
                        <HomeOutlined className={`icon ${activePage === MobileAppPages.Home ? "active" : ""}`} />
                        <MessageOutlined className={`icon ${activePage === MobileAppPages.Chat ? "active" : ""}`} />
                        <AccountCircle className={`icon ${activePage === MobileAppPages.Account ? "active" : ""}`} />
                    </div>
                </div>
            </div>
        </div>
    );
};
