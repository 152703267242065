import React from "react";

import { DatePicker as DatePickerMui } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { DateView } from "@mui/x-date-pickers";

interface IDatePickerProps {
    clearable?: boolean;
    placeholder?: string;
    minDate?: moment.Moment;
    maxDate?: moment.Moment;
    date?: string;
    style?: React.CSSProperties;
    views?: DateView[];
    avoidPickerViewLayoutShift?: boolean;
    onChangeDate: (value: string | undefined) => void;
}
/**
 * Wrapper around KeyboardDatePicker
 */
const DatePicker: React.FunctionComponent<IDatePickerProps> = ({
    clearable,
    minDate,
    maxDate,
    placeholder,
    date,
    style,
    views,
    avoidPickerViewLayoutShift,
    onChangeDate
}) => {
    const onChangeDateInternal = (newDate) => {
        const publishTime: string = date
            ? moment(newDate).toISOString()
            : moment(newDate).startOf("hour").add(1, "hour").toISOString();

        onChangeDate(publishTime);
    }

    return (
        <DatePickerMui
            format="MMM dd yyyy"
            minDate={minDate ? new Date(minDate.toISOString()) : null}
            maxDate={maxDate ? new Date(maxDate.toISOString()) : null}
            views={views}
            value={date ? new Date(date) : null}
            slotProps={{
                // copied from:  https://mui.com/x/react-date-pickers/custom-components/#popper
                // there's a calculation that happens when changing views to see if the picker needs to move.
                // It's currently broken and ends up with users unable to select dates/months. This code
                // avoids that by setting height of the popper explicitly.
                popper: {
                    modifiers: [
                        avoidPickerViewLayoutShift
                            ? {
                                name: 'viewHeightModifier',
                                enabled: true,
                                phase: 'beforeWrite',
                                fn: ({ state }: { state: Partial<any> }) => {
                                    state.styles.popper.height = '320px';
                                    if (state.placement.includes('top-start')) {
                                        state.styles.popper = {
                                            ...state.styles.popper,
                                            display: 'flex',
                                            alignItems: 'flex-end',
                                        };
                                    }
                                    if (state.placement.includes('bottom')) {
                                        state.styles.popper = {
                                            ...state.styles.popper,
                                            display: 'block',
                                        };
                                    }
                                },
                            }
                            : {}
                    ],
                },
                textField: {
                    size: "small",
                    placeholder: placeholder || "Select date",
                    inputProps: { readOnly: true },
                    style: { ...style }
                },
                openPickerIcon: {
                    color: "primary"
                },
                actionBar: {
                    actions: clearable
                        ? ['clear']
                        : [] //Swapped clearable functionality above for this.
                }
            }}
            onChange={onChangeDateInternal}
            className="date-picker"
        />
    );
}

export default DatePicker;
