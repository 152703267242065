import * as React from 'react';
import { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { GlobalApplicationState } from 'globalApplicationState';
import * as actions from 'modules/posts/actionCreator';
import { Comment, ImageScale, PostView as IPostView } from '../../models';
import { postsApi } from 'api/instances';
import moment from "moment";

import ErrorSnackbar from "modules/common/components/snackbars/errorSnackbar";
import Loading from "modules/common/components/loading";
import PostTypeBanner from "modules/common/components/banners/postTypeBanner";
import ShareButton from "modules/common/components/buttons/shareButton";
import useIsMounted from 'modules/common/hooks/useIsMounted';
import useIsFirstRender from 'modules/common/hooks/useIsFirstRender';
import { parseAnchors } from 'utils/htmlUtils';
import { CustomCss } from 'modules/common/components/customCss';

import BreakingFeaturedBanner from "../banners/breakingFeaturedBanner";
import Comments from "./comments/comments";
import Compliance from "./compliance/compliance";
import HeroBanner from "../../../common/components/heroBanner";
import Reactions from "./reactions/reactions";

import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";

import './postView.sass';

const PostView: React.FunctionComponent<PropsWithRedux> = (props) => {
    const [activeLcid, setActiveLcid] = useState<string>(props.currentUserPreferredLCID);
    const [post, setPost] = useState<IPostView>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const isMounted = useIsMounted();
    const isFirstRender = useIsFirstRender();

    useEffect(() => {
        moment.locale("en");

        /**
     * Get a public post
     * @param isInitialLoad
     */
        const getPublicPost = async (isInitialLoad: boolean): Promise<IPostView | null> => {
            if (!props.tenantId || !props.postId) return null;

            const response: IPostView = await postsApi.GetPublicPost(props.tenantId, props.postId, ImageScale.Modal);
            if (response && isInitialLoad)
                await postsApi.SendPublicPostOpenedEvent(props.tenantId, response.id, response.defaultLCID);

            return response;
        };

        /**
         * Get a private post
         * @param initialLoad
         */
        const getPrivatePost = async (isInitialLoad: boolean): Promise<IPostView | null> => {
            if (!props.postId) return null;

            const response = await postsApi.GetPost(props.postId, ImageScale.Modal);
            if (response && isInitialLoad)
                await postsApi.SendPostOpenedEvent(response.id, response.defaultLCID);

            return response;
        };

        /**
         * Decide whether we need to get a private or public post and fetch
         * @param initialLoad
         */
        const getPost = async (initialLoad: boolean) => {
            props.clearFetchPost();
            let newPost: IPostView | null;

            try {
                // get the post
                if (props.isPublic && props.tenantId)
                    newPost = await getPublicPost(initialLoad);
                else
                    newPost = await getPrivatePost(initialLoad);

                // if post got, update state
                if (newPost && isMounted()) {
                    setPost(newPost);
                    setActiveLcid(props.currentUserPreferredLCID);

                }
            } catch (error) { }
            finally {
                if (isMounted())
                    setIsLoading(false);
            }
        };

        if (isFirstRender || props.shouldFetch)
            getPost(isFirstRender);

        return () => {
            if (post) {
                if (props.isPublic && props.tenantId)
                    postsApi.SendPublicPostClosedEvent(props.tenantId!, post.id, post.defaultLCID);
                else
                    postsApi.SendPostClosedEvent(post.id, post.defaultLCID);
            }
        };
    }, [isFirstRender, isMounted, post, props]);

    const onSelectLanguage = (language: Language) => {
        setActiveLcid(language.lcid);
    };

    const onUpdateComments = (comments: Comment[]) => {
        if (!post) return;

        setPost({ ...post, comments });
    };

    const onUpdateCommentSubscriptions = (isPostNotificationsActive: boolean) => {
        if (!post) return;

        setPost({ ...post, isPostNotificationsActive });
    };

    const getTranslatedField = (field: string) => {
        if (!post) return;

        if (!post.translatedContent || !post.translatedContent[activeLcid])
            return post![field];

        return post!.translatedContent[activeLcid][field];
    };

    const getAvailableLanguages = (): JSX.Element => {
        if (!post || Object.keys(post.translatedContent).length < 2 || !props.lcidMappings)
            return <></>;

        const { lcidMappings } = props;
        const availableLcids: string[] = Object.keys(post.translatedContent);
        const availableLanguages: Language[] = availableLcids.map((lcid) => ({ language: lcidMappings[lcid] ? lcidMappings[lcid].languageShort : lcid, lcid }));

        return (
            <div className="available-languages">
                <span className="emphasis">Available In: </span>
                {availableLanguages
                    .map<React.ReactNode>((language) =>
                        <span key={language.lcid} onClick={() => onSelectLanguage(language)} className={language.lcid === activeLcid ? "" : "inactive-language"}>{language.language}</span>
                    )
                    .reduce((prev, curr) => [prev, ", ", curr])
                }
            </div>
        );
    };

    const title: string = getTranslatedField("title");

    return (
        <React.Fragment>
            <CustomCss enabled={props.tinyMceCustomCssEnabled} />
            {isLoading && <Loading />}
            {!isLoading && !post &&
                <div className="no-post-data">Unfortunately, this post is no longer available.</div>}

            {!isLoading && post &&
                <div className="post-view">
                    <div>
                        <div className="view-page">
                            <HeroBanner bannerColor={post.bannerColor} imageUrl={post.imageUrl} attachedContent={post.attachedContent} />
                            <PostTypeBanner {...props} {...post} full lcid={activeLcid} />
                            <div className="page-content">
                                <div className="title">
                                    <div>{title}</div>
                                    {(props.shareUrlsEnabled.toggleCompanyPortalUrl || props.shareUrlsEnabled.toggleSpUrl || props.shareUrlsEnabled.toggleTeamsUrl)
                                        &&
                                        <ShareButton
                                            articleId={post.id}
                                            articleTitle={title}
                                            articleType="post"
                                            isPublic={post.postType === "public"}
                                        />
                                    }
                                </div>
                                <div className="published-time">Published {moment(post.publishTime).format("MMMM D, YYYY")}</div>
                                <div className="tags">Topics: {post.tags.map((tag) => tag.name).join(", ")}</div>
                                <div className="author">
                                    <Divider light />
                                    <div className="author-details">
                                        <div className="author-avatar" style={{ backgroundColor: post?.author.avatar.color }}>{post.author.name ? post.author.name[0] : ""}</div>
                                        <div>
                                            <div>Post By</div>
                                            {post && post.author && post.author.email
                                                ? <Link href={`mailto:${post.author.email}`} className="author-name">{post.author.name}</Link>
                                                : <div className="author-name">{post && post.author ?  post.author.name : ""}</div>
                                            }
                                        </div>
                                    </div>
                                    <Divider light />
                                </div>
                                <BreakingFeaturedBanner activeLcid={activeLcid} detailed isBreaking={post.isBreaking} isFeatured={post.isFeatured} />
                                {getAvailableLanguages()}
                                <div dangerouslySetInnerHTML={{ __html: parseAnchors(getTranslatedField("body")) }} className="body"></div>
                                {!!post.fileAttachments && !!post.fileAttachments.length &&
                                    <div className="documents">
                                        <div className="documents-label">Documents ({post.fileAttachments.length})</div>
                                        <div>
                                            {post.fileAttachments.map((fileAttachment) =>
                                                <Button
                                                    key={fileAttachment.blobId}
                                                    variant="outlined"
                                                    size="small"
                                                    onClick={() => postsApi.DownloadFile(fileAttachment.fileUrl!, fileAttachment.fileName, fileAttachment.fileExtension)}
                                                >
                                                    {fileAttachment.fileName}
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                }
                                <Compliance
                                    postId={post.id}
                                    complianceText={getTranslatedField("complianceText")}
                                    postType={post.postType}
                                    stats={post.stats}
                                />
                            </div>
                        </div>
                        <Reactions
                            reactionsEnabledOnTenant={props.reactionsEnabled}
                            reactions={props.reactions}
                            postType={post.postType}
                            reactingEnabledOnPost={post.reactingEnabled}
                            stats={post.stats}
                        />
                    </div>
                    <div className="comments">
                        <Comments
                            id={post.id}
                            commentsEnabledOnTenant={props.commentsEnabled}
                            commentsEnabledOnPost={post.commentingEnabled}
                            comments={post.comments}
                            isSubscribed={post.isPostNotificationsActive}
                            showSubscription={post.userCommentNotificationSettings !== "none"}
                            onUpdateComments={onUpdateComments}
                            onUpdateCommentSubscriptions={onUpdateCommentSubscriptions}
                        />
                    </div>
                </div>}
            <ErrorSnackbar errorMessage={props.errorMessage} clearErrorMessage={props.clearErrorMessage} />
        </React.Fragment>
    );
}

interface Language {
    language: string;
    lcid: string;
}

interface ComponentProps {
    isPublic?: boolean;
    postId: string;
    tenantId?: string;
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        errorMessage: state.posts.postView.errorMessage,
        shouldFetch: state.posts.postView.shouldFetch,
        lcidMappings: state.resources.lcidMappings,
        users: state.users.userList || [],
        reactions: state.settings.clientSettings.reactions,
        reactionsEnabled: state.settings.clientSettings.reactionSystem && state.settings.clientSettings.reactionSystem.enabled,
        commentsEnabled: state.settings.clientSettings.commentSystem && state.settings.clientSettings.commentSystem.enabled,
        currentUserPreferredLCID: state.settings.currentUser.preferredLCID,
        shareUrlsEnabled: state.settings.tenantSettings.shareUrlsConfig,
        tinyMceCustomCssEnabled: state.settings.tenantSettings.showFeatures.tinyMceCustomCssEnabled
    }),
    {
        clearErrorMessage: actions.clearPostViewErrorMessage,
        clearFetchPost: actions.clearFetchPost,
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;
export default connector(PostView);
