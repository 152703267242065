import React, { useState } from 'react';
import AutoSizer from "react-virtualized-auto-sizer";

import { Pie } from '@nivo/pie';
import { useMediaQuery } from '@mui/material';

import ToggleFilter from 'modules/common/components/toggleFilter';

import '../styles/graphs.sass'

// TODO: add react-intl where neceessary here post fix

const PostInsightsCharts: React.FunctionComponent<ComponentProps> = (props) => {

    const [selectedFilterIndex, setSelectedFilterIndex] = useState(0);

    const filterOptions = ["All Time", "First 7 Days", "First 30 Days"]

    const readTypeChartData = props.readTypeData.filter(d => d.interval === filterOptions[selectedFilterIndex])
        .map(d => {
            return { id: d.label, value: d.value, label: `${d.label}: ${d.value}` }
        })

    const engagementChartData = props.engagementData.filter(d => d.interval === filterOptions[selectedFilterIndex])
        .map(d => {
            return { id: d.label, value: d.value, label: `${d.label}: ${d.value}` }
        })

    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    const firstPublished = new Date(props.firstPublishDate).toLocaleDateString(undefined, options as any)

    const matchesMed = useMediaQuery('(max-width:1200px)');
    const matchesSmall = useMediaQuery('(max-width:1024px)');
    const matchesXSmall = useMediaQuery('(max-width:900px)');

    const renderPie = (data): JSX.Element => {
        return (
            <AutoSizer>
                {({ height, width }) => (
                    <Pie
                        height={height}
                        width={width}
                        data={data}
                        colors={{ scheme: 'red_yellow_blue' }}
                        innerRadius={0.7}
                        padAngle={1}
                        enableArcLabels={false}
                        enableArcLinkLabels={false}
                        margin={{ top: props.compliancePost ? 20 : 40, right: 20, bottom: 15, left: 195 }}

                        activeOuterRadiusOffset={15}
                        legends={[
                            {
                                anchor: 'top-left',
                                direction: 'column',
                                justify: false,
                                itemHeight: 20,
                                translateX: -195,
                                itemWidth: 75,
                                itemsSpacing: 10,
                                symbolSize: 20,
                                textColor: '#333333',
                                itemTextColor: '#333333',

                            }
                        ]}
                    />
                )}
            </AutoSizer>
        )
    }

    const switchToColumn = (matchesMed && !matchesSmall) || matchesXSmall;

    return (
        <div style={{ position: 'relative' }}>
            <div className='post-insights-header' style={{ flexDirection: switchToColumn ? 'column' : 'row' }}>
                <div>
                    First Published:
                    <span className='date'>
                        {firstPublished}
                    </span>
                </div>
                <div style={{ marginTop: switchToColumn ? '15px' : '' }}>
                <ToggleFilter filterOptions={filterOptions.map((label: string) => ({ label }))}
                        selectedIndex={selectedFilterIndex}
                        setSelectedIndex={setSelectedFilterIndex}
                    />
                </div>
            </div>
            <div className='pie-wrapper' style={{ flexDirection: switchToColumn ? 'column' : 'row' }}>
                <div className='nivo-wrapper' style={{ width: switchToColumn || props.compliancePost ? '100%' : '50%' }}>
                    <div className='metric-title'>
                        {`Total Views: ${props.totalViews}`}
                    </div>
                    {renderPie(readTypeChartData)}
                </div>
                {
                    !props.compliancePost ?
                        <div className='nivo-wrapper' style={{ width: switchToColumn ? '100%' : '50%' }}>
                            <div className='metric-title'>
                                {`Interactions: ${props.totalEngagement}`}
                            </div>
                            {
                                props.disclaimer && <div className='graphs-disclaimer'>{props.disclaimer}</div>
                            }
                            {renderPie(engagementChartData)}
                        </div>
                        : null
                }

            </div>
        </div>
    )
}

interface ComponentProps {
    readTypeData: { value: number, interval: string, label: string }[]
    engagementData: { value: number, interval: string, label: string }[]
    totalEngagement: number
    totalViews: number
    firstPublishDate: Date
    compliancePost?: boolean
    disclaimer?: string | null
}

export default PostInsightsCharts;
