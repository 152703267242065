import { mediaApi } from 'api/instances';
import { Image, ImageType } from "modules/gallery/models";
import React, { useEffect, useState } from 'react';

import './mediaPreviewer.sass'
import Preview from 'modules/common/components/authoring/dialogs/preview';
import { Container, Divider, Grid, IconButton, Stack, TextField, Typography } from '@mui/material';
import HoverText from 'modules/documents/components/action-buttons/hoverText';
import ChipList, { IChipListItem } from 'modules/common/components/chipList';
import moment from 'moment';
import { IdNamePair } from 'modules/common/models';
import { cmp } from '../customChonkyFileActions';
import EditIcon from '@mui/icons-material/Edit';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { v4 as uuidv4 } from 'uuid';

interface ComponentProps {
    previewImage?: Image;
    onPreviewClose: () => void;
    onKeywordsChange: (keywords: string[]) => void;
    onNameEdit: (imageId: string) => void;
}

const MediaPreviewer: React.FC<ComponentProps> = props => {
    const [videoId, setVideoId] = useState<string | undefined>(undefined);
    const [videoPreviewUrl, setVideoPreviewUrl] = useState<string | undefined>(undefined);
    const [videoRef, setVideoRef] = useState<HTMLVideoElement | undefined>();
    const [keywordsValue, setKeywordsValue] = useState<string>("");
    const [keywordsChipListValue, setKeywordsChipListValue] = useState<IChipListItem<string, string>[]>([]);

    useEffect(() => {
        setKeywordsChipListValue(
            props.previewImage?.keywords
                .sort((a, b) => cmp(a, b)) 
                .map((keyword, i) => ({
                        id: `${i}`, 
                        name: keyword,
                        disabled: false
                    }) as IChipListItem<string, string>
                ) ?? []
        )
    }, [props.previewImage?.keywords])

    const showVideo = (previewImage: Image) => {
        if(previewImage.videoId && previewImage.videoId !== videoId){
            
            mediaApi.GetVideoUrl(previewImage.videoId)
                .then(response => {
                    setVideoId(previewImage.videoId);
                    setVideoPreviewUrl(response);
                });
        }
        return <video
                className="video-js vjs-default-skin post-video-primary vjs-16-9 vjs-big-play-centered"
                ref={(e) => setVideoRef(e!)}
                crossOrigin="anonymous"
                controls
                preload="metadata"
                style={{ backgroundColor: "black" }}
                poster={props.previewImage?.url}
            />;
    }

    useEffect(() => {
        if (videoRef) {
            const videojs = (window as any).videojs;
            const player = videojs(videoRef, {});
            player
                .ready(() => {
                    player.src(videoPreviewUrl);
                })
                .on('ended', () => {
                    player.pause().currentTime(0).trigger('loadstart');
                })
                .on('pause', () => {
                    player.bigPlayButton.el_.style.display = 'block';
                })
                .on('play', () => {
                    player.bigPlayButton.el_.style.display = 'none';
                });
        }
    }, [videoRef, videoPreviewUrl]);

    const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (keywordsValue === "") return;

        if (event.key === 'Enter') {
            const newKeywords = addKeywordsToList([keywordsValue]);
            props.onKeywordsChange(newKeywords);
            setKeywordsValue("");
        }
    };

    const onRemoveKeyword = (item: IdNamePair<string, string>) => {
        const newKeywords = removeKeywordFromList(item.id);
        props.onKeywordsChange(newKeywords);
    }

    useEffect(() => {
        if (!keywordsValue) return;

        const trimmedKeyword = keywordsValue.trim();

        if (trimmedKeyword.endsWith(',') || trimmedKeyword.includes(',')) {
            const keywordsToAdd = trimmedKeyword.split(',').map(keyword => keyword.trim());
            const newKeywords = addKeywordsToList(keywordsToAdd);
            props.onKeywordsChange(newKeywords);
            setKeywordsValue("");
        }
    }, [keywordsValue]);

    const addKeywordsToList = (newKeywords: string[]): string[] => {
        let newKeywordsList = [...keywordsChipListValue];
        newKeywordsList = newKeywordsList.concat(
            newKeywords
                .filter(newKeyword => newKeyword)
                .map(newKeyword => ({id: uuidv4(), name: newKeyword}) as IChipListItem<string, string>)
        );
        setAndSortChipListValue(newKeywordsList);
        return newKeywordsList.map(keywordsList => keywordsList.name);
    }

    const removeKeywordFromList = (keywordIdToRemove: string): string[] => {
        let newKeywordsList = [...keywordsChipListValue];
        newKeywordsList = newKeywordsList.filter(keyword => keyword.id !== keywordIdToRemove);
        setAndSortChipListValue(newKeywordsList);
        return newKeywordsList.map(keywordsList => keywordsList.name);
    }

    const setAndSortChipListValue = (newKeywordsList: IChipListItem<string, string>[]) => {
        setKeywordsChipListValue(newKeywordsList.sort((a, b) => cmp(a.name, b.name)));
    }

    const onPreviewClose = () => {
        setVideoId(undefined);
        setVideoRef(undefined);
        setKeywordsValue("");
        setKeywordsChipListValue([]);

        props.onPreviewClose();
    }

    const downloadImage = async () => {
        if (props.previewImage) {
            const response = await fetch(props.previewImage.url);
            const blob = await response.blob();

            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = props.previewImage.name;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    return(
        <>
            {
                props.previewImage && 
                <Preview 
                    open={true}
                    onClose={onPreviewClose} 
                    extraLabel={"download"}
                    extraAction={() => downloadImage()}
                    extraLabelStartIcon={<FileDownloadIcon />}             
                >
                    <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                        <Grid item xs={8} display={"flex"}>
                            <Container maxWidth="md">
                                {
                                    props.previewImage.videoId
                                    ? showVideo(props.previewImage)
                                    : <img className="image-preview-image" src={props.previewImage.url} alt="preview"/>
                                } 
                            </Container>
                        </Grid>
                        <Grid item xs={4}>
                            <Container disableGutters sx={{display: 'flex', flexDirection: 'row'}}>
                                <Typography variant="h6" sx={{width: 'fit-content', alignSelf: 'center'}}>
                                    {props.previewImage.name}
                                </Typography>
                                <IconButton
                                    onClick={_ => props.previewImage?.id && props.onNameEdit(props.previewImage.id)}
                                >
                                    <EditIcon/>
                                </IconButton>
                            </Container>
                            <Stack spacing={2} divider={<Divider flexItem/>} mt={2}>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <Typography>Created On</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography>{moment(props.previewImage.createdTime.date).format('MMM D, YYYY, h:mm a')}</Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography>Created By</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography>{props.previewImage.createdBy ?? "Unknown"}</Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography>File Type</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography>{ImageType[props.previewImage.fileType]}</Typography>
                                    </Grid>
                                </Grid>
                                <Stack spacing={1} display="flex">
                                    <HoverText label="Keywords for search">
                                        Improve search results by tagging this file with words and phrases people typically use to find it. Separate each word by a comma.
                                    </HoverText>
                                    <TextField 
                                        hiddenLabel 
                                        fullWidth 
                                        placeholder="Enter keywords (separated by commas)" 
                                        onKeyDown={handleKeyPress}
                                        value={keywordsValue}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setKeywordsValue(event.target.value)}
                                    />
                                    <Container disableGutters>
                                        <ChipList
                                            items={keywordsChipListValue}
                                            closable
                                            onClose={onRemoveKeyword}
                                        />
                                    </Container>
                                </Stack>
                            </Stack>
                        </Grid>
                    </Grid>
                </Preview>
            }
        </>
    );
}

export default MediaPreviewer;