import * as React from 'react';
import { GlobalApplicationState } from "globalApplicationState";
import { ConnectedProps, connect } from "react-redux";
import { Tooltip } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import SingleTextAreaInput from './singleTextAreaInput'
import MobileTextAreaInput from './mobileTextAreaInput';
import TextBubble from 'modules/common/components/textBubble';
import WarningLabel from './warningLabel'

interface TextBubble {
    id: string;
    name: string;
}

interface ComponentProps { 
    emailSubjectUpdate: (any) => any,
    smsBodyUpdate: (any) => any,
    pushContentUpdate: (any) => any,
    emailBubbles: TextBubble[],
    smsBubbles: TextBubble[],
    mobileBubbles: TextBubble[],
    event: string
}

interface ComponentState {
}

class TripleNotificationOverride extends React.Component<PropsWithRedux, ComponentState> {
    public render() {
    const maxTextLength = 400;
    const subjectToEdit = this.props.event + "EmailAttempt"
    const bodyToEdit = this.props.event + "SMSAttempt"
    const contentToEdit = this.props.event + "MobileAttempt"
    const emailEventName = this.eventUppercase(subjectToEdit).toString()
    const smsEventName = this.eventUppercase(bodyToEdit).toString()
    const mobileEventName = this.eventUppercase(contentToEdit).toString()

    const LightTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: "12px"
        }
        }))(Tooltip);

        return (
            <div className="overrides-prompt">
                <h4>{"Edit Notification Contents"}</h4>
                <span className="description-text">Change the content of your notifications. Use suggestions to personalize your content.  </span>
                <LightTooltip title="You can edit and personalize the content of the notifications you send out, which include the subject in email notifications, the title and body of mobile app notifications, and the text message of SMS notifications.">
                    <ins className="description-text">Learn More.</ins>
                </LightTooltip>

                <div className="override-titles">
                    <span>Email Notification: Subject</span>
                </div>

                {!(this.props.notificationSettings.settings[subjectToEdit].toggle) && 
                    <WarningLabel platform="Email"/>
                }
                <div className="label-description-block">
                <SingleTextAreaInput
                    returnTranslatedContent={this.props.emailSubjectUpdate}
                    maxTextLength={maxTextLength}
                    placeholder={this.props.emailSubjects[emailEventName].toString()}
                    prompt={"Subject"}
                    toLoad={this.props.notificationSettings.settings[subjectToEdit].translatedSubject}
                    event={subjectToEdit}
                    platform={'email'}
                />                       
                </div>    
                <div className="word-bubble-formatting">
                    <p className="suggestions-section-text">Suggestions: </p>
                    <TextBubble list={this.props.emailBubbles}/>
                </div>

                <div className="override-titles">
                    <span>Mobile App Notification: Title &amp; Description</span>
                </div>

                {!(this.props.notificationSettings.settings[contentToEdit].toggle) && 
                    <WarningLabel platform="Mobile"/>
                }
                <MobileTextAreaInput 
                    placeholderTop = {this.props.pushContents[mobileEventName].m_Item1.toString()}
                    placeholderBot = {this.props.pushContents[mobileEventName].m_Item2.toString()}
                    event={contentToEdit}
                    returnTranslatedContent={this.props.pushContentUpdate}
                />
                <div className="word-bubble-formatting">
                    <p className="suggestions-section-text">Suggestions: </p>
                    <TextBubble list={this.props.mobileBubbles} />
                </div>
                
                <div className="override-titles">
                    <span>SMS Notification: Text Message</span>
                </div>

                {!(this.props.notificationSettings.settings[bodyToEdit].toggle) && 
                    <WarningLabel platform="SMS"/>
                }
                <div className="label-description-block">
                <SingleTextAreaInput
                    returnTranslatedContent={this.props.smsBodyUpdate}
                    maxTextLength={maxTextLength}
                    placeholder={this.props.smsBodies[smsEventName].toString()}
                    prompt={"Text"}
                    toLoad={this.props.notificationSettings.settings[bodyToEdit].translatedBody}
                    event={bodyToEdit}
                    platform={'sms'}
                />                       
                </div>    
                <div className="word-bubble-formatting">
                    <p className="suggestions-section-text">Suggestions: </p>
                    <TextBubble list={this.props.smsBubbles}/>
                </div>

            </div>
        )
    }

    private eventUppercase = (eventToFix: string) => {
        if(eventToFix !== null && eventToFix.length > 2)
            return eventToFix[0].toUpperCase() + eventToFix.slice(1);
        else
            return "Invalid string to fix."
    }
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        notificationSettings: state.settings.notificationSettings,
        emailSubjects: state.resources.emailSubjectsResource,
        smsBodies: state.resources.smsBodiesResource,
        pushContents: state.resources.pushContentsResource
    }),
    {
        
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default (connector(TripleNotificationOverride));