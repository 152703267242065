import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { push } from "react-router-redux";
import * as actions from "modules/portalPages/actionCreator";
import { GlobalApplicationState } from "globalApplicationState";

import { PortalPage } from "modules/portalPages/models";

import Button from "@mui/material/Button";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import SaveIcon from "@mui/icons-material/Save";


const CommandBar: React.FunctionComponent<{ input, meta, field } & PropsWithRedux> = props => {
  return (
    <div className="command-bar">
      <Button
        variant="text"
        color="primary"
        startIcon={<ChevronLeftIcon fontSize="small" />}
        onClick={() => props.showBackToPortalPages()}
        className="text-button"
      >
        <span>Back to Manage Portal Pages</span>
      </Button>
      {props.field.page.hasBeenPreviouslyPublished &&
        <div title={`This page is ${props.field.page.state}; however, you have unpublished, saved changes to this page.`} className="unpublished-changes-icon">
          <SaveIcon />
        </div>
        }
    </div>
  );
}

interface ComponentProps {
  page: PortalPage;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    tenantId: state.tenant.id
  }),
  {
    redirectTo: push,
    showBackToPortalPages: actions.showBackToPortalPages
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(CommandBar);