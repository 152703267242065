import * as React from 'react';
import {injectIntl, IntlShape} from "react-intl";

import { Icons } from 'modules/common/icons';

interface componentState {
    prompts: string[]
}
interface componentProps {
    intl: IntlShape;
}

class AnimatedDragPrompt extends React.Component<componentProps, componentState> {
    private timer;
    
    constructor(props){
        super(props);
        this.state = {prompts: []};
      }

    componentDidMount() {
        this.timer = setTimeout(() => {
            this.setState({prompts: [
                this.props.intl.formatMessage({id: "posts.dragPrompt", defaultMessage: "Drag to Reposition, Scroll to Zoom"})
            ]});
        }, 1250)
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    public render() {
        return (      
                    this.state.prompts.map(prompt => {
                        return (
                            <div className="cropping-drag-prompt" key={prompt}>
                                <Icons.DragHandle color="#fff" width="22" height="22"/> {prompt}
                            </div>
                        )
                    })
        );
    }
}

export default injectIntl(AnimatedDragPrompt);