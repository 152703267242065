import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import * as actions from "../../actionCreator";
import { GlobalApplicationState } from "globalApplicationState";

import Loading from "modules/common/components/loading";
import LoadingOverlay from "modules/common/components/loadingOverlay";

import { getMimeType, getStringFromMimeType } from "../../utilities/getMimeType";
import { Document, DocumentProperties } from "../../models";
import DocumentUploader from "./components/documentUploader";
import DocumentPropertiesEditor from "./documentProperties";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import "modules/common/components/authoring/authoring.sass";
import SnackbarWrapper from "modules/common/components/snackbars/snackbarWrapper";

class DocumentEditor extends React.Component<PropsWithRedux, ComponentState> {
  constructor(props: PropsWithRedux) {
    super(props);

    this.state = {
      documentProperties: {},
      hasError: false,
      isFetching: false,
      showNotificationSettings: false,
      uploadFail: false,
    };
  }

  public componentDidUpdate(prevProps: PropsWithRedux) {
    if (this.props.editableDocument && this.props.editableDocument.id && this.props.showEditor && !prevProps.showEditor)
      this.onGetDocumentProperties(this.props.editableDocument);
  }

  public render() {
    if (!this.props.editableDocument || !this.props.editableDocument.id)
      return <React.Fragment></React.Fragment>;

    return (
      <Dialog open={this.props.showEditor} maxWidth={false} scroll="paper" onClose={this.onClose} classes={{ paper: "document-editor" }}>
        <DialogTitle className="document-editor-title">
          <div>
            <IconButton
              style={{ margin: "-12px 0 -10px -16px" }}
              onClick={this.onClose}
              size="large">
              <ArrowBackIcon />
            </IconButton>
            <span>Edit or replace document</span>
          </div>
          <Button variant="contained" color="primary" disabled={!this.props.changedSinceSaved} onClick={this.publishDocument}>Publish changes</Button>
        </DialogTitle>
        <DialogContent>
          <div className="authoring-page">
            {this.state.isFetching
              ? <Loading />
              : this.state.hasError
                ? <div className="no-event-data">This document could not be loaded.</div>
                : <React.Fragment>
                    <DocumentPropertiesEditor
                      document={this.props.editableDocument}
                      documentProperties={this.state.documentProperties}
                      onChange={this.onChangeDocumentProperties}
                    />
                    <Divider />
                    <DocumentUploader
                      acceptedFiles={getMimeType(this.props.editableDocument.fileType)}
                      message={`Choose or drag a ${this.props.editableDocument.fileType.toUpperCase()} file here to replace this version`}
                      onUpload={this.onUpload}
                    />
                  </React.Fragment>
            }
          </div>
          <SnackbarWrapper open={!!this.props.successMessage} message={this.props.successMessage} severity="success" onClose={this.props.clearSuccessMessage} />
          <SnackbarWrapper open={!!this.props.errorMessage} severity="error" onClose={this.props.clearErrorMessage} message={this.props.errorMessage} />
          <SnackbarWrapper open={this.state.uploadFail} severity="error" onClose={this.onCloseUploadFail} message={"Invalid file type, cannot replace file."} />
        </DialogContent>
        <LoadingOverlay show={this.props.isSaving || this.props.isUploading} styles={{ paddingTop: 150 }} />
      </Dialog>
    );
  }

  private onChangeDocumentProperties = (value: Partial<DocumentProperties>) => {
    const documentProperties = { ...this.state.documentProperties, ...value };
    this.setState({
      documentProperties: documentProperties
    });
    this.props.hasChangedSinceSaved();
  }

  private onClose = () => {
    this.props.hideEditor();
  }

  private onCloseUploadFail = () => {
    this.setState({uploadFail: false});
  }

  private onGetDocumentProperties = (document: Document) => {
    const documentProperties: Partial<DocumentProperties> = {
      accessibleAudiences: document.audiences.map(audience => audience.id),
      availability: document.availability,
      description: document.description,
      enabled: document.state !== "disabled",
      lcid: document.lcid,
      notifications: document.notifications,
      notifiedAudiences: [],
      publishingTime: document.state === "scheduled" ? document.published.date : "",
      tags: document.tags.map(tag => tag.id),
      title: document.title
    };
    this.setState({ documentProperties });
  }

  private onUpload = (file: File, onProgress, onLoad, onError, onAbort): Promise<Document> => {
    var newFileType = getStringFromMimeType(file.type);

    if(this.props.editableDocument !== undefined && (this.props.editableDocument.fileType !== newFileType || (newFileType === "jpeg" && this.props.editableDocument?.fileType === "jpg")))
    {
      this.setState({uploadFail: true})
      return Promise.reject();
    }

    return this.props.replaceFile(this.props.editableDocument!.id!, file, onProgress, onLoad, onError, onAbort).then((document) => {
      return document;
    });
  }

  private publishDocument = () => {
    if(!this.state.documentProperties.tags || this.state.documentProperties.tags?.length === 0) {
      let uncategorizedTag = this.props.categoryTags.find(t => t.name === "Uncategorized");
      let newProperties = this.state.documentProperties;
      newProperties.tags = [uncategorizedTag!.id];
      this.setState({documentProperties: newProperties});
    }
    this.props.updateDocumentProperties(this.props.editableDocument!.id!, this.state.documentProperties).then((succeeded) => {
      if (succeeded) {
        this.props.onSuccess();
        this.onClose();
      }
    });
  }
}


interface ComponentProps {
  onSuccess: () => void;
}

interface ComponentState {
  documentProperties: Partial<DocumentProperties>;
  hasError: boolean;
  isFetching: boolean;
  showNotificationSettings: boolean;
  uploadFail: boolean;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    changedSinceSaved: state.documents.changedSinceSaved,
    editableDocument: state.documents.editableDocument,
    errorMessage: state.documents.errorMessage,
    isSaving: state.documents.isSaving,
    isUploading: state.documents.isUploading,
    showEditor: state.documents.showEditor,
    successMessage: state.documents.successMessage,
    categoryTags: state.categoryTags.userCategoryTags
  }),
  {
    clearErrorMessage: actions.clearErrorMessage,
    clearSuccessMessage: actions.clearSuccessMessage,
    hasChangedSinceSaved: actions.hasChangedSinceSaved,
    hideEditor: actions.hideEditor,
    replaceFile: actions.replaceFile,
    updateDocumentProperties: actions.updateDocumentProperties
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(DocumentEditor);
