import { Action } from 'redux';
import { CompleteAction, ActionCreator } from 'typedActions';
import { Survey } from './models';

export const GET_SURVEYS = 'GET_SURVEYS'
export interface GetSurveys extends Action { }
export const GetSurveys = ActionCreator<GetSurveys>(GET_SURVEYS);

export const GET_SURVEYS_COMPLETE = 'GET_SURVEYS_COMPLETE'
export interface GetSurveysComplete extends CompleteAction {
    surveys?: Survey[]
}
export const GetSurveysComplete = ActionCreator<GetSurveysComplete>(GET_SURVEYS_COMPLETE);



export const GET_SURVEY = 'GET_SURVEY'
export interface GetSurvey extends Action { }
export const GetSurvey = ActionCreator<GetSurvey>(GET_SURVEY);

export const GET_SURVEY_COMPLETE = 'GET_SURVEY_COMPLETE'
export interface GetSurveyComplete extends CompleteAction {}
export const GetSurveyComplete = ActionCreator<GetSurveyComplete>(GET_SURVEY_COMPLETE);



export const DELETE_SURVEYS = 'DELETE_SURVEYS'
export interface DeleteSurveys extends Action { }
export const DeleteSurveys = ActionCreator<DeleteSurveys>(DELETE_SURVEYS);

export const DELETE_SURVEYS_COMPLETE = 'DELETE_SURVEYS_COMPLETE'
export interface DeleteSurveysComplete extends CompleteAction { 
    deletedSurveys?: string[]
}
export const DeleteSurveysComplete = ActionCreator<DeleteSurveysComplete>(DELETE_SURVEYS_COMPLETE);



export const SAVE_SURVEY = 'SAVE_SURVEY';
export interface SaveSurvey extends Action {}
export const SaveSurvey = ActionCreator<SaveSurvey>(SAVE_SURVEY);

export const SAVE_SURVEY_COMPLETE = 'SAVE_SURVEY_COMPLETE';
export interface SaveSurveyComplete extends CompleteAction {}
export const SaveSurveyComplete = ActionCreator<SaveSurveyComplete>(SAVE_SURVEY_COMPLETE);



export const CLEAR_SURVEY_LIST = 'CLEAR_SURVEY_LIST';
export interface ClearSurveyList extends Action {}
export const ClearSurveyList = ActionCreator<ClearSurveyList>(CLEAR_SURVEY_LIST);
