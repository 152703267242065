import { ApiRequest } from './network';
import { SparrowApi } from './network/adapters/SparrowApi';

export class MediaApi {

    constructor(private _sparrowApi: SparrowApi) {
    }

    public GetVideoUrl = (id: string) => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
                .get(`/{tenant}/ospreyapi/v1/videos/${id}`)
        ).then(response => response)
    }

    public GetImageUrl = (id: string) => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
                .get(`/{tenant}/ospreyapi/v1/images/${id}`)
        ).then(response => response.url)
    }
}