import * as actions from 'network/actions';
import * as models from 'modules/surveys/models';

export const GetSurveys = () => {
  return actions.NetworkRequest({
    method: 'GET',
    url: '/{tenant}/adminapi/v1/surveys'
  });
}

export const GetSurvey = (id: string) => {
  return actions.NetworkRequest({
    method: 'GET',
    url: `/{tenant}/adminapi/v1/surveys/${id}`
  });
}

export const UpdateSurveyExpiryTime = (survey: models.Survey) => {
  return actions.NetworkRequest({
    method: 'POST',
    url: `/{tenant}/adminapi/v1/ surveys/${survey.id}/expiryTime`,
    body: JSON.stringify(survey),
    headers: { 'content-type': 'application/json' }
  });
}

export const DeleteSurvey = (id: string) => {
  return actions.NetworkRequest({
    method: 'DELETE',
    url: `/{tenant}/adminapi/v1/surveys/${id}`
  });
}

export const SaveSurvey = (survey: models.Survey) => {
  return actions.NetworkRequest({
    method: survey.id ? 'POST' : 'PUT',
    url: `/{tenant}/adminapi/v1/surveys`,
    body: JSON.stringify(survey),
    headers: { 'content-type': 'application/json' }
  });
}
