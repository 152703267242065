import * as React from 'react';
import { connect, ConnectedProps, shallowEqual } from 'react-redux';
import { GlobalApplicationState } from 'globalApplicationState';
import * as actions from '../actionCreator';
import RealTopPosts from './top-posts';
import UsersByMonth from './usersByMonth';
import CommentsByMonth from './commentsByMonth';
import UserTrend from './userTrend';
import ClientBreakdown from './clientBreakdown';
import ReadsViewsByHour from './readsViewsByHour';
import TopSurveys from './topSurveys';
import {injectIntl, IntlShape} from "react-intl";
import AnalyticsErrorBanner from "modules/common/components/analyticsErrorBanner";
import { withRouter, RouteComponentProps } from 'react-router';

import BasePage from "pages/common/basePage";
import Header from "pages/common/header";
import MainContent from "pages/common/mainContent";
import { Grid } from '@mui/material';
import InsightsTutorial from './dialogs/insightsTutorial';
import InsightFilters from './insightFilters';
import moment from 'moment';
import { Survey } from 'modules/surveys';
import TopEvents from './topEvents';
import { AnalyticsReportFilters, DownloadAllReport, LAST_30_DAYS, PlatformAnalyticsReport, TopEvent } from '..';
import DownloadReportsDropdown from './downloadReportsDropdown';
import { audiencesApi, insightsApi, reportsApi } from 'api/instances';
import * as htmlToImage from 'html-to-image';
import { store } from 'configureStore';
import { dateOptions } from 'utils/dateFormatting';
import { Audience } from 'modules/audiences';
import { FileDownloader } from 'utils/fileDownloader';
import SnackbarWrapper from 'modules/common/components/snackbars/snackbarWrapper';
import { getCurrentTimeInUserTimezone } from 'utils/getCurrentTimeInUserTimezone';

enum loadTypes { "all", "excel", "image" };

class Dashboard extends React.Component<PropsWithRedux, ComponentState> {

    constructor(props){
        super(props);
        const startDate = moment(new Date()).startOf("day").subtract(30, "days").format(dateOptions.basicWithHours);
        const endDate = moment(new Date()).endOf("day").format(dateOptions.basicWithHours);
        this.state = {
            isLoadingUsersByMonthAnalyticsOverview: true,
            isLoadingAppUsageAnalyticsOverview: true,
            isLoadingCommentsByMonthAnalyticsOverview: true,
            isLoadingReadsByTimeOfDayAnalyticsOverview: true,
            isLoadingTopSurveyResponseAnalyticsOverview: true,
            isLoadingTopEventsAnalyticsOverview: true,
            isLoadingUserTrendsAnalyticsOverview: true,
            isLoadingAll: false,
            isLoadingExcel: false,
            isLoadingImage: false,

            reportPayload: {
                startDate: startDate,
                endDate: endDate
            },
            reportFilter: {
                startDate: startDate,
                endDate: endDate,
                rangeString: LAST_30_DAYS,
                audiences: [],
                topics: []
            },
            audiences: [],
            totalSubscribers: 0,
            resultMessage: ""
        };
    }

    public componentWillMount() {
        audiencesApi
            .getAllAudiencesForAuthors()
            .then(audiences => this.setState({audiences: audiences.filter(a => a.enabled)})) //Get list of enabled audiences for filtering.
    }

    public componentDidMount() {
        if(!this.props.toFetch) {
            this.getAllAnalytics();
        }
        else {
            //User has come back to the page, reload the default ranges and graphs.
            this.onChangeFilters(this.state.reportFilter);
            this.props.setToFetch(false);
        }
        (window as any).scrollTo(0, 0);
    }

    public render() {
        const isLoading = (this.state.isLoadingAppUsageAnalyticsOverview || this.state.isLoadingCommentsByMonthAnalyticsOverview || this.state.isLoadingReadsByTimeOfDayAnalyticsOverview || this.state.isLoadingTopEventsAnalyticsOverview || this.state.isLoadingTopSurveyResponseAnalyticsOverview || this.state.isLoadingUserTrendsAnalyticsOverview || this.state.isLoadingUsersByMonthAnalyticsOverview )

        return (
            <BasePage fullWidth>
                <SnackbarWrapper open={!!this.state.resultMessage} style={{ top: "60px" }} autoHideDuration={8000} onClose={() => this.setState({resultMessage: ""})} severity={"success"} message={this.state.resultMessage} />
                <Header
                    title={
                        <React.Fragment>
                          <span>Platform Analytics</span>
                          <InsightsTutorial />
                        </React.Fragment>
                    }
                    rightComponent={
                        <React.Fragment>
                            <DownloadReportsDropdown
                                downloadAllImage={this.downloadAllReportAsImage}
                                downloadAllExcel={this.sendAllExcelReportRequest}
                                loadingImage={this.state.isLoadingImage}
                            />
                        </React.Fragment>
                    }
                />
                <MainContent>
                    <div className="analytics-dashboard">
                        {
                            this.props.analyticsErrorMessage === "" ? null :
                            <span>
                                <AnalyticsErrorBanner mssg={this.props.analyticsErrorMessage}/>
                                <div className="clearfix"/>
                            </span>
                        }

                        <InsightFilters filters={this.state.reportFilter} onChangeFilters={this.onChangeFilters} tenantSettings={this.props.tenantSettings} totalSubscribers={this.state.totalSubscribers} audiences={this.state.audiences} isLoading={isLoading} />

                        <Grid container spacing={1}>
                            <Grid item xs={12} md={2}>
                            {
                                <UserTrend
                                    userTrends={this.props.userTrends}
                                    loading={this.state.isLoadingUsersByMonthAnalyticsOverview}
                                    filterType={this.state.reportFilter.rangeString!}
                                />
                            }
                            </Grid>

                            <Grid item xs={12} md={4}>

                            {
                                <ClientBreakdown
                                    platformStats={this.props.platformStats}
                                    loading={this.state.isLoadingAppUsageAnalyticsOverview}
                                    downloadExcel={() => this.sendExcelReportRequest(PlatformAnalyticsReport.channelUsage)}
                                    downloadImage={() => this.onDownloadImg(this.fetchChannelsImage, PlatformAnalyticsReport.channelUsage)}
                                />
                            }
                            </Grid>

                            <Grid item xs={12} md={6}>
                            {
                                <UsersByMonth
                                    userCounts={this.props.userCounts}
                                    loading={this.state.isLoadingUsersByMonthAnalyticsOverview}
                                    startDate={this.state.reportFilter.startDate!}
                                    endDate={this.state.reportFilter.endDate!}
                                    downloadExcel={() => this.sendExcelReportRequest(PlatformAnalyticsReport.engagedUsers)}
                                    downloadImage={() => this.onDownloadImg(this.fetchEngagedUsersImage, PlatformAnalyticsReport.engagedUsers)}
                                />
                            }
                        </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={6}>
                                {
                                    <RealTopPosts
                                        reportFilter={this.state.reportFilter}
                                        downloadExcel={() => this.sendExcelReportRequest(PlatformAnalyticsReport.mostViewedPost)}
                                        downloadImage={() => this.onDownloadImg(this.fetchMostViewedPostImage, PlatformAnalyticsReport.mostViewedPost)}
                                    /> //Top 10 Posts
                                }
                            </Grid>
                            <Grid item xs={12} md={6}>
                                {
                                    <CommentsByMonth
                                        commentAndReactionCounts={this.props.commentAndReactionCounts}
                                        loading={this.state.isLoadingCommentsByMonthAnalyticsOverview}
                                        startDate={this.state.reportFilter.startDate!}
                                        endDate={this.state.reportFilter.endDate!}
                                        downloadExcel={() => this.sendExcelReportRequest(PlatformAnalyticsReport.commentsAndReactions)}
                                        downloadImage={() => this.onDownloadImg(this.fetchCommentsAndReactionsImage, PlatformAnalyticsReport.commentsAndReactions)}
                                    />
                                }
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={12}>
                                {
                                    <ReadsViewsByHour
                                        readViewStats={this.props.readViewStats}
                                        loading={this.state.isLoadingReadsByTimeOfDayAnalyticsOverview}
                                        downloadExcel={() => this.sendExcelReportRequest(PlatformAnalyticsReport.postViewsByHour)}
                                        downloadImage={() => this.onDownloadImg(this.fetchpostViewsByHourImage, PlatformAnalyticsReport.postViewsByHour)}
                                    />
                                }
                            </Grid>
                        </Grid>

                        <Grid container spacing={1}>
                            <Grid item xs={12} md={6}>
                                {
                                    <TopSurveys
                                        tenant={this.props.tenantState.id}
                                        surveys={this.props.surveys?.length > 0 ? this.props.surveys : new Array<Survey>()}
                                        loading={this.state.isLoadingTopSurveyResponseAnalyticsOverview}
                                        downloadExcel={() => this.sendExcelReportRequest(PlatformAnalyticsReport.surveyWithMostResponse)}
                                        downloadImage={() => this.onDownloadImg(this.fetchSurveyWithMostResponseImage, PlatformAnalyticsReport.surveyWithMostResponse)}
                                    />
                                }
                            </Grid>
                            <Grid item xs={12} md={6}>
                                {
                                    <TopEvents 
                                        tenant={this.props.tenantState.id} 
                                        topEvents={this.props.topEvents?.length > 0 ? this.props.topEvents : new Array<TopEvent>()} 
                                        loading={this.state.isLoadingTopEventsAnalyticsOverview} 
                                        downloadExcel={() => this.sendExcelReportRequest(PlatformAnalyticsReport.mostViewedEvent)}
                                        downloadImage={() => this.onDownloadImg(this.fetchMostViewedEventImage, PlatformAnalyticsReport.mostViewedEvent)}
                                    />
                                }
                            </Grid>
                        </Grid>
                    </div>
                </MainContent>
                <div style={{textAlign: "right", paddingTop: "4px"}}>
                    <span style={{color: "#666666", fontSize: "12px"}}>Data is updated and processed once per hour.</span>
                </div>
            </BasePage>
        );
    }

    private onDownloadImg = async (fetchImage: () => Promise<string | undefined> | undefined, reportType: string) => {
        this.setState({isLoadingImage: true})
        try {
            const payloadToDownload = await fetchImage();
            const reportName = this.GetReportFileName(reportType);
            const file = {
                name: `${reportName} ${this.state.reportPayload.startDate} to ${this.state.reportPayload.endDate}`,
            }

            if (payloadToDownload) {
                new FileDownloader(file).downloadProvided(payloadToDownload)
            }
            this.setState({isLoadingImage: false});
        }
        catch (e) {
            this.setState({isLoadingImage: false});
        }  
    }
    
    private getAllAnalytics = () => {
        if (!this.props.readViewStats) {
            this.fetchReadViewStats(); //Average Post Views
        }
        else {
            this.setState({isLoadingReadsByTimeOfDayAnalyticsOverview: false});
        }

        if (!this.props.userCounts) {
            this.fetchUserCountsByMonth(); //Active Users
        }
        else {
            this.setState({isLoadingUsersByMonthAnalyticsOverview: false});
        }

        if (!this.props.commentAndReactionCounts) {
            this.fetchCommentAndReactionCounts(); //Post Reactions & Comments
        }
        else {
            this.setState({isLoadingCommentsByMonthAnalyticsOverview: false});
        }

        if (!this.props.platformStats) {
            this.fetchPlatformStats(); //Channel usage
        }
        else {
            this.setState({isLoadingAppUsageAnalyticsOverview: false});
        }

        if (!this.props.surveys) {
            this.fetchSurveys(); //Top surveys
        }
        else {
            this.setState({isLoadingTopSurveyResponseAnalyticsOverview: false});
        }

        if(!this.props.topEvents) {
            this.fetchTopEvents();
        }
        else {
            this.setState({isLoadingTopEventsAnalyticsOverview: false});
        }

        if(!this.props.userTrends) {
            this.fetchUserTrends();
        }
        else {
            this.setState({isLoadingUserTrendsAnalyticsOverview: false});
        }
    }

    private fetchUserCountsByMonth = () => {
        this.props.fetchUserCountsByMonth(this.state.reportFilter).then(() => {
            this.setState({isLoadingUsersByMonthAnalyticsOverview: false});
        });
    }

    private fetchPlatformStats = () => {
        this.props.fetchPlatformStats(this.state.reportFilter).then(() => {
            this.setState({isLoadingAppUsageAnalyticsOverview: false});
        });
    }

    private fetchCommentAndReactionCounts = () => {
        this.props.fetchCommentAndReactionCountsByMonth(this.state.reportFilter).then(() => {
            this.setState({isLoadingCommentsByMonthAnalyticsOverview: false});
        });
    }

    private fetchReadViewStats = () => {
        this.props.fetchReadViewStats(this.state.reportFilter).then(() => {
            this.setState({isLoadingReadsByTimeOfDayAnalyticsOverview: false});
        });
    }

    private fetchSurveys = () => {
        this.props.fetchSurveys(this.state.reportFilter).then(() => {
            this.setState({isLoadingTopSurveyResponseAnalyticsOverview: false});
        });
    }

    private fetchTopEvents = () => {
        this.props.fetchTopEvents(this.state.reportFilter).then(() => {
            this.setState({isLoadingTopEventsAnalyticsOverview: false});
        });
    }

    private fetchUserTrends = () => {
        this.props.fetchUserTrends(this.state.reportFilter).then(() => {
            this.setState({isLoadingUserTrendsAnalyticsOverview: false});
        });
    }

    private GetReportFileName = (reportType: string) => {
        switch (reportType) {
            case PlatformAnalyticsReport.channelUsage:
                return 'Channel Usage';
            case PlatformAnalyticsReport.commentsAndReactions:
                return 'Post Reactions and Comments';
            case PlatformAnalyticsReport.engagedUsers:
                return 'Engaged Users';
            case PlatformAnalyticsReport.mostViewedEvent:
                return 'Events with Most Views';
            case PlatformAnalyticsReport.mostViewedPost:
                return 'Posts with Most Views';
            case PlatformAnalyticsReport.postViewsByHour:
                return 'Post Views by Time of Day';
            case PlatformAnalyticsReport.surveyWithMostResponse:
                return 'Surveys with Most Responses';
            default: 
                return "";
        }
    }


    private setReportPayload = (reportPayload: DownloadAllReport) => {
        this.setState({reportPayload: {...this.state.reportPayload, ...reportPayload}});
    }

    private fetchChannelsImage = async () : Promise<string | undefined> => {
        const channelUsageNode = document.getElementById(PlatformAnalyticsReport.channelUsage);

        if(channelUsageNode) {
            const dataUrl = await htmlToImage.toPng(channelUsageNode, {skipFonts: true});
            this.setReportPayload({channelUsage: dataUrl})

            return dataUrl;
        }
        return 
    }

    private fetchMostViewedPostImage = async () : Promise<string | undefined> => {
        const mostViewedPostNode = document.getElementById(PlatformAnalyticsReport.mostViewedPost);
        this.setElementDisplay('none', document.getElementById(`${PlatformAnalyticsReport.mostViewedPost}-goto`));

        if(mostViewedPostNode) {
            const dataUrl = await htmlToImage.toPng(mostViewedPostNode, {skipFonts: true})

            this.setElementDisplay('block', document.getElementById(`${PlatformAnalyticsReport.mostViewedPost}-goto`));
            this.setReportPayload({mostViewedPosts: dataUrl});
            return dataUrl;
        }
    }

    private fetchMostViewedEventImage = async () : Promise<string | undefined> => {
        const mostViewedEventNode = document.getElementById(PlatformAnalyticsReport.mostViewedEvent);
        this.setElementDisplay('none', document.getElementById(`${PlatformAnalyticsReport.mostViewedEvent}-goto`));

        if(mostViewedEventNode) {
            const dataUrl = await htmlToImage.toPng(mostViewedEventNode, {skipFonts: true});
            this.setElementDisplay('block', document.getElementById(`${PlatformAnalyticsReport.mostViewedEvent}-goto`));
            this.setReportPayload({mostViewedEvents: dataUrl});
            return dataUrl;
        }
    }

    private fetchCommentsAndReactionsImage = async () : Promise<string | undefined> => {
        const commentsAndReactionsNode = document.getElementById(PlatformAnalyticsReport.commentsAndReactions);

        if(commentsAndReactionsNode) {
            const dataUrl = await htmlToImage.toPng(commentsAndReactionsNode, {skipFonts: true});
            this.setReportPayload({commentsAndReactions: dataUrl});
            return dataUrl;
        }
    }

    private fetchEngagedUsersImage = async () : Promise<string | undefined> => {
        const engagedUsersNode = document.getElementById(PlatformAnalyticsReport.engagedUsers);

        if(engagedUsersNode) {
            const dataUrl = await htmlToImage.toPng(engagedUsersNode, {skipFonts: true});
            this.setReportPayload({engagedUsers: dataUrl});
            return dataUrl;
        }
    }

    private fetchpostViewsByHourImage = async () : Promise<string | undefined> => {
        const postViewsByHourNode = document.getElementById(PlatformAnalyticsReport.postViewsByHour);
        if(postViewsByHourNode) {
            const dataUrl = await  htmlToImage.toPng(postViewsByHourNode, {skipFonts: true});
            this.setReportPayload({postViewsByHour: dataUrl});
            return dataUrl;
        }
    }

    private fetchSurveyWithMostResponseImage = async () : Promise<string | undefined>=> {
        const surveyWithMostResponseImageNode = document.getElementById(PlatformAnalyticsReport.surveyWithMostResponse);
        this.setElementDisplay('none', document.getElementById(`${PlatformAnalyticsReport.surveyWithMostResponse}-goto`));

        if(surveyWithMostResponseImageNode) {
            const dataUrl = await htmlToImage.toPng(surveyWithMostResponseImageNode, {skipFonts: true});
            this.setElementDisplay('block', document.getElementById(`${PlatformAnalyticsReport.surveyWithMostResponse}-goto`));
            this.setReportPayload({surveysWithMostResponse: dataUrl});

            return dataUrl;
        }
    }

    private setElementDisplay(display: string, element?: HTMLElement | null) {
        if(element) {
            element.style.display = display;
        }
    }

    private sendAllExcelReportRequest = async () => {
        const handleReportRequest = () => {
            try {
                reportsApi.SendAllAnalyticsReportRequest(this.state.reportPayload);
                this.setState({resultMessage:"Your report is being generated and will be emailed to you when it's ready."});
            }
            catch(e){
                this.setState({resultMessage:"Something went wrong. Please try again."});
            }
        }

        this.updateClientTime(handleReportRequest);
    }

    private sendExcelReportRequest = async (reportType: string) => {
        const handleReportRequest = () => {
            try {
                reportsApi.SendAnalyticsReportRequest(this.state.reportPayload, reportType);
                this.setState({resultMessage:"Your report is being generated and will be emailed to you when it's ready."});
            }
            catch(e){
                this.setState({resultMessage:"Something went wrong. Please try again."});
            }
        }

        this.updateClientTime(handleReportRequest);
    }

    private updateClientTime = (action: () => void) => {
        const tz = getCurrentTimeInUserTimezone();
        this.setState({
            reportPayload: {
                ...this.state.reportPayload,
                clientTimeZone: tz.format('z'),
                clientTimeOffset: tz.format('Z')
            }
        }, () => action())
    }

    private downloadAllReportAsImage = async() => {
        this.setState({isLoadingImage: true});

        try {
            await Promise.all([
                this.fetchChannelsImage(),
                this.fetchMostViewedPostImage(),
                this.fetchCommentsAndReactionsImage(),
                this.fetchEngagedUsersImage(),
                this.fetchMostViewedEventImage(),
                this.fetchpostViewsByHourImage(),
                this.fetchSurveyWithMostResponseImage()
            ]);
            this.downloadReport(store.getState().config.downloadAllAnalyticsReportAsImage, loadTypes.image);
        }
        catch {
            this.setState({isLoadingImage: false});
        }
    }

    private downloadReport = (url: string, toReset: loadTypes) => {
        this.updateClientTime(() => this.fetchReport(url, toReset));
    }
    
    private setTotalSubscribers = () => {
        if (this.state.reportFilter.audiences!.length > 0) {
            insightsApi.GetTotalSubscribers(store.getState().config.totalUsersInAudiences, { audiencesOrTopics: this.state.reportFilter.audiences})
            .then((response) => {
                this.setState({totalSubscribers: response});
            });
        } else if (this.state.reportFilter.topics!.length > 0) {
            insightsApi.GetTotalSubscribers(store.getState().config.totalUsersSubscribedInTopics, { audiencesOrTopics: this.state.reportFilter.topics})
            .then((response) => {
                this.setState({totalSubscribers: response});
            });
        }
    }

    private onChangeFilters = (filters: Partial<AnalyticsReportFilters>) => {
        if(!shallowEqual(filters, this.state.reportFilter) || this.props.toFetch) {
            const updatedFilters = { ...this.state.reportFilter, ...filters };
            this.setState({
                reportFilter: updatedFilters,
                reportPayload: {...this.state.reportPayload, ...updatedFilters},
                isLoadingCommentsByMonthAnalyticsOverview: true,
                isLoadingUsersByMonthAnalyticsOverview: true,
                isLoadingReadsByTimeOfDayAnalyticsOverview: true,
                isLoadingTopSurveyResponseAnalyticsOverview: true,
                isLoadingAppUsageAnalyticsOverview: true,
                isLoadingTopEventsAnalyticsOverview: true,
                isLoadingUserTrendsAnalyticsOverview: true,
            }, () => {
                this.fetchCommentAndReactionCounts();
                this.fetchUserCountsByMonth();
                this.fetchReadViewStats();
                this.fetchSurveys();
                this.fetchPlatformStats();
                this.fetchTopEvents();
                this.fetchUserTrends();
                this.setTotalSubscribers();
            });
        }
        if(!this.props.toFetch) {
            this.props.setToFetch(true);
        }
    }

    private fetchReport = (url: string, toReset: loadTypes) => {        
        const file = {
            name: this.assembleReportFileName(),
            type: 'application/x-zip-compressed'
        }
        return insightsApi.DownloadInsightReport(url, this.state.reportPayload)
            .then((data) => new FileDownloader(file).downloadBlob(data))
            .then(_ => this.resetLoading(toReset))
            .catch(_ => this.resetLoading(toReset));
    }

    private assembleReportFileName = () : string => {
        const endFormat = "DD-MMM-YY";
        const originalFormat = 'MMM D, YYYY, h:mmA';

        const startDate = moment(this.state.reportFilter.startDate, originalFormat).format(endFormat);
        const endDate = moment(this.state.reportFilter.endDate, originalFormat).format(endFormat);
        return `Sparrow Connected - ${startDate} to ${endDate}.zip`;
    }

    private resetLoading(toReset: loadTypes) {
        switch (toReset) {
            case loadTypes.image:
                this.setState({ isLoadingImage: false });
                break;

            case loadTypes.excel:
                this.setState({ isLoadingExcel: false });
                break;

            case loadTypes.all:
                this.setState({ isLoadingAll: false });
                break;
        }
    }
}

interface ComponentProps {
    intl: IntlShape;
}

interface ComponentState {
    isLoadingUsersByMonthAnalyticsOverview: boolean;
    isLoadingAppUsageAnalyticsOverview: boolean;
    isLoadingCommentsByMonthAnalyticsOverview: boolean;
    isLoadingReadsByTimeOfDayAnalyticsOverview: boolean;
    isLoadingTopSurveyResponseAnalyticsOverview: boolean;
    isLoadingTopEventsAnalyticsOverview: boolean;
    isLoadingUserTrendsAnalyticsOverview: boolean;
    isLoadingImage: boolean;
    isLoadingExcel: boolean;
    isLoadingAll: boolean;
    reportFilter: AnalyticsReportFilters;
    reportPayload: DownloadAllReport;
    audiences: Audience[];
    totalSubscribers: number;
    resultMessage: string;
}

interface RouteParams { tenant: string }

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps & RouteComponentProps<RouteParams>) => ({
        ...ownProps,
        postStats: state.insights.postStats,
        topTags: state.insights.topChannels,
        userCounts: state.insights.userCounts,
        commentAndReactionCounts: state.insights.commentAndReactionCounts,
        platformStats: state.insights.platformStats,
        readViewStats: state.insights.readViewStats,
        tenantState: state.tenant,
        commentEnabled: state.settings.tenantSettings.commentsEnabled,
        surveys: state.insights.surveys,
        topEvents: state.insights.topEvents,
        userTrends: state.insights.userTrends,
        analyticsErrorMessage: state.config.AnalyticsErrorMessage,
        toFetch: state.insights.toFetch,
        tenantSettings: state.settings.tenantSettings,
        asyncReportsEnabled: state.settings.tenantSettings.showFeatures.asyncReportProcessingEnabled
    }),
    {
        fetchPostStats: actions.fetchPostStats,
        fetchTopTags: actions.fetchTopChannels,
        fetchReadViewStats: actions.fetchReadViewStats,
        fetchUserCountsByMonth: actions.fetchUserCountsByMonth,
        fetchCommentAndReactionCountsByMonth: actions.fetchCommentAndReactionCountsByMonth,
        fetchPlatformStats: actions.fetchPlatformStats,
        fetchSurveys: actions.fetchSurveyStats,
        fetchTopEvents: actions.fetchTopEvents,
        fetchUserTrends: actions.fetchUserTrends,
        setToFetch: actions.setToFetch
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default injectIntl(withRouter(connector(Dashboard)));
