import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import moment from 'moment';
import TopPosts from './components/topPosts';

import shallowEquals from 'utils/shallowEquals';
import { insightsApi } from 'api/instances';
import { GlobalApplicationState } from 'globalApplicationState';
import { AnalyticsReportFilters } from 'modules/insights';


class TopPostsContainer extends React.Component<PropsWithRedux, ComponentState> {
    private _isMounted = false;
   
    constructor(props) {
        super(props);
        this.state = {
            posts: [],
            isLoadingTopPostsAnalyticsOverview: false
        }
    }

    public componentDidMount() {
        this._isMounted = true;
    }

    public shouldComponentUpdate(nextProps: ComponentProps, nextState: ComponentState) {
        if(nextProps.reportFilter !== this.props.reportFilter) {
            this.fetchDataForMyComponent(nextProps.reportFilter);
        }
        return !shallowEquals(this.props, nextProps)
            || !shallowEquals(this.state, nextState)
    }

    public componentWillUnmount() {
        this._isMounted = false;
    }

    public render() {
        moment.locale("en");

        return <TopPosts
                    posts={this.state.posts}
                    tenant={this.props.tenantId}
                    loadData={() => {
                        this.fetchDataForMyComponent(this.props.reportFilter);
                    }}
                    loading={this.state.isLoadingTopPostsAnalyticsOverview}
                    downloadExcel = {this.props.downloadExcel}
                    downloadImage = {this.props.downloadImage}
                />
    }

    private fetchDataForMyComponent = (reportFilter: AnalyticsReportFilters) => {
        this.setState(prev => ({ ...prev, isLoadingTopPostsAnalyticsOverview: true}));
        insightsApi.GetTopPostsFromInsights(reportFilter)
            .then(results => {
                if (this._isMounted) {
                    this.setState((prevState) => ({
                        ...prevState,
                        posts: results,
                        isLoadingTopPostsAnalyticsOverview: false
                    }))
                }
            })
            .catch(error => {
                this.setState((prevState, props) => ({
                    ...this.state,
                    isLoadingTopPostsAnalyticsOverview: false,
                    error: error
                }))
            });
    }
}

interface ComponentProps {
    reportFilter: AnalyticsReportFilters;
    downloadExcel: () => void;
    downloadImage: () => void;
}

interface ComponentState {
    isLoadingTopPostsAnalyticsOverview: boolean,
    posts: any[],
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        tenantId: state.tenant.id
    })
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(TopPostsContainer);
