import React from "react";
import { Paper, PaperProps } from "@mui/material";

interface IMainEditorLayoutProps {
    paperProps?: PaperProps;
}

const MainEditorLayout: React.FunctionComponent<IMainEditorLayoutProps> = ({
    children,
    paperProps,
}) => {
    return (
        <Paper className="main-editor-paper" elevation={8} {...paperProps}>
            <div className="main-editor-content">
                {children}
            </div>
        </Paper>
    );
}

export default MainEditorLayout;
