import { ApiRequest } from './network';
import { LogFilterValues } from "modules/activity/models";
import { SparrowApi } from 'api/network/adapters/SparrowApi';

export class ActivityApi {
    constructor(private _sparrowApi: SparrowApi) {
    }

    public GetFlaggedItems = (): ApiRequest => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().get('/{tenant}/adminapi/v1/activityLog/flaggedItems')
        );
    }

    public IgnoreFlag = (id: string): ApiRequest => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
                .put('/{tenant}/adminapi/v1/activityLog/flaggedItems/{flaggedItemId}/ignore', { params: { flaggedItemId: id , platform: 2 }})
        );
    }

    public fetchActivityLog = (currentFilterState: LogFilterValues, maxResults: number) => {
        const params = {
            before: currentFilterState.beforeDate,
            after: currentFilterState.afterDate,
            actionType:  currentFilterState.activityType ? currentFilterState.activityType.replace(/\s/g,''): null,
            name: currentFilterState.userName,
            maxResults
        }

        return new ApiRequest(
            this._sparrowApi.TenantRequest()
                .get('/{tenant}/adminapi/v1/activityLog', { params: { ...params }})
        );
    }
}

