import { useEffect, useRef, useState } from "react";

import { IMobileConfig } from "modules/settings";
import useIsMounted from "../useIsMounted";
import { settingsApi } from "api/instances";

export type IUseMobileConfigReturn = {
    mobileConfig: IMobileConfig | undefined;
    isError: boolean;
    isLoading: boolean;
};

// Move to redux if we start using in multiple places
export const useMobileConfig = (): IUseMobileConfigReturn => {
    const [mobileConfig, setMobileConfig] = useState<IMobileConfig | undefined>();
    const [isError, setIsError] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const isMounted = useIsMounted();
    const fetched = useRef<boolean>(false);

    useEffect(() => {
        const fetchMobileConfig = async () => {
            try {
                fetched.current = true;
                setIsLoading(true);
                const { data } = await settingsApi.getMobileConfig();

                if (isMounted()) setMobileConfig(data);
            } catch (err) {
                if (isMounted()) setIsError(true);
            } finally {
                if (isMounted()) setIsLoading(false);
            }
        };

        if (!fetched.current) fetchMobileConfig();
    }, [mobileConfig, isMounted]);

    return {
        mobileConfig,
        isError,
        isLoading,
    };
};
