//For use inside the templateThemeSearchAndSelect file.
//Intended to be used in an array.for loop.
import { Checkbox } from '@mui/material';
import * as React from 'react';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import '../../styles/themes.sass'
import { NewsletterTheme, SaveNewsletterModel } from 'modules/newsletters/models';
import moment from 'moment';

interface ComponentProps {
    theme: NewsletterTheme;
    selectedTheme: string;
    onNewsletterChange: (value: SaveNewsletterModel) => any;
    newsletter: SaveNewsletterModel;
    setOpen: (index: number, toSet: boolean) => void;
    isOpen: boolean;
    index: number;
}

const ThemeDisplay: React.FunctionComponent<ComponentProps> = ( {theme, selectedTheme, onNewsletterChange, newsletter, isOpen, setOpen, index} ) => {

    const updateNewsletterWithTheme = (theme: NewsletterTheme) => {
        let toSave = {
            themeId: theme.id, primaryBackground: theme.primaryBackground,
            primaryText: theme.primaryText,
            secondaryBackground: theme.secondaryBackground,
            secondaryText: theme.secondaryText,
            emailBackground: theme.emailBackground,
            linkText: theme.linkText
        }
        onNewsletterChange({
            ...newsletter, 
            newsletterTheme: toSave
        });
    }

    let dateToDisplay = moment(new Date(theme.lastModifiedOnUTC)).format("MMM D, YYYY");
    let nameToDisplay = theme.lastModifiedByDisplayName?.substring(0, 27) + (theme.lastModifiedByDisplayName?.length > 27 ? "..." : "");

    return (
        <div style={{border: "1px solid #E1E5E8", borderRadius: "5px"}}>
            <div style={{display: "flex", flexDirection: "row"}}>
                <Checkbox checked={selectedTheme === theme.id} style={{height: "40px", position: "relative", top: "8px"}} onClick={() => updateNewsletterWithTheme(theme)} color='secondary'/>
                <div style={{display: "flex", flexDirection: "row", cursor: "pointer", width: "100%"}} onClick={() => setOpen(index, !isOpen)}>
                    <div style={{display: "flex", flexDirection: "column", padding: "8px 0"}}>
                        <span style={{fontSize: "16px"}}>{theme.name}</span>
                        {!theme.isCustom 
                            ? <span style={{fontSize: "13px"}}>Standard template theme</span> 
                            : <span style={{fontSize: "13px"}}>{theme.lastModifiedOnUTC ? "Last modified on " + dateToDisplay + " by " + nameToDisplay : "Custom template theme"}</span>
                        }
                    </div>
                    <div style={{display: "flex", alignItems: "center", marginLeft: "auto", marginRight: "20px"}}>
                        {isOpen
                        ? <KeyboardArrowUpIcon style={{fontSize: "28px", float: "right"}}/> 
                        : <KeyboardArrowDownIcon style={{fontSize: "28px", float: "right"}}/> 
                        }
                    </div>
                </div>
            </div>
            {isOpen && 
            <div className="theme-section">
                <div style={{display: "flex", flexDirection: "row", width: "100%", marginTop: "20px"}}>
                    <div className='left-theme-label'>Email background</div>
                    <div style={{height: "20px", width: "20px", backgroundColor: theme.emailBackground, borderRadius: "3px", border: "1px solid #DDE1E5"}}></div>
                    <div className='right-theme-label'>Secondary background</div>
                    <div style={{height: "20px", width: "20px", backgroundColor: theme.secondaryBackground, borderRadius: "3px", border: "1px solid #DDE1E5"}}></div>
                </div>

                <div style={{display: "flex", flexDirection: "row", width: "100%", marginTop: "10px"}}>
                    <div className='left-theme-label'>Primary background</div>
                        <div style={{height: "20px", width: "20px", backgroundColor: theme.primaryBackground, borderRadius: "3px", border: "1px solid #DDE1E5"}}></div>
                    <div className='right-theme-label'>Secondary text</div>
                    <div style={{height: "20px", width: "20px", backgroundColor: theme.secondaryText, borderRadius: "3px", border: "1px solid #DDE1E5"}}></div>
                </div>

                <div style={{display: "flex", flexDirection: "row", width: "100%", marginTop: "10px"}}>
                    <div className='left-theme-label'>Primary text</div>
                    <div style={{height: "20px", width: "20px", backgroundColor: theme.primaryText, borderRadius: "3px", border: "1px solid #DDE1E5"}}></div>
                    <div className='right-theme-label'>Link text</div>
                    <div style={{height: "20px", width: "20px", backgroundColor: theme.linkText, borderRadius: "3px", border: "1px solid #DDE1E5"}}></div>
                </div>
            </div>}
        </div>
    );
}

export default ThemeDisplay;