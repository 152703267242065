import React from "react";
import InfoHover from "modules/common/components/hovers/infoHover";

export const InsightsInfoHover : React.FunctionComponent<ComponentProps> = props => {

  return (
          <InfoHover>
            <div>
              <span className="emphasis">Copies Sent</span> is the number of emails sent out to recipients.
            </div>
            <br />
            <div>
              <span className="emphasis">Delivered</span> is the sum of newsletters received in the recipients' mailboxes.
            </div>
            <br />
            <div>
              <span className="emphasis">Opens</span> is the total number of times the emails were opened. <span className="emphasis">Unique Opens</span> is the total number of recipients who have opened and viewed a newsletter.
            </div>
            <br />
            <div>
              <span className="emphasis">Clicks</span> is the total number of times any links have been clicked within the emails. <span className="emphasis">Unique Clicks</span> is the total number of recipients who clicked links within the emails.
            </div>
            <br />
            <div>
              <span className="emphasis">Unsubscribes</span> is the number of recipients who unsubscribed from the newsletter. <span className="emphasis">Spam Reports</span> is the number of recipients who reported an email as spam.
            </div>
            <br />
            <div>
              <span className="emphasis">Drops</span> is the number of emails that were not allowed to be delivered by the internet service provider.
            </div>
          </InfoHover>
  );
}

interface ComponentProps {}