import { AxiosInstance, AxiosRequestConfig } from 'axios';

export class RequestPlatformInterceptor {

    public bind (networkLayer: AxiosInstance) {
        networkLayer.interceptors.request.use(this.handle);
    }

    public handle = (config: AxiosRequestConfig) => {
        let url = config.url;

        if (!!url && url.indexOf("platform=") < 0) {
          if (url.indexOf("?") < 0)
            url += "?platform=Web";
          else
            url += "&platform=Web";
        }

        return Promise.resolve({ ...config, url });
    }
}
