import { ApiRequest } from './network';
import { SparrowApi } from 'api/network/adapters/SparrowApi';
import MsalAuthModule from 'authentication/msalAuthModule';
import { DEVICE_PLATFORM } from 'modules/common/models';
import { getSessionId } from 'utils/ids';

export class LoggedEventsApi {
    constructor(private _sparrowApi: SparrowApi) {
    }

    public sendFeatureUsageEvent = (featureActionCode: string, userId: string, tenantId: string) => {
        const body = {
            Events: [{
                UserId: userId,
                TenantId: tenantId,
                Name: "FeatureUsage",
                Platform: DEVICE_PLATFORM.WEB,
                Body: {
                    FeatureActionCode: featureActionCode
                },
                Id: getSessionId(),
                Time: new Date().toUTCString()
            }]
        };

        return new ApiRequest(
            this._sparrowApi.TenantRequest().post('{tenant}/api/v1/loggedevents', body)
        );
    }

    public sendPostAuthoringOpenedEvent = (postId: string, time?: string): ApiRequest => {
        const body = {
            Events: [{
                Name: "PostAuthoringOpened",
                Platform: "Web",
                Time: time || new Date().toISOString(),
                Body: {
                    PostId: postId,
                    AuthoringUIVersion: "v2"
                },
            }]
        };

        return new ApiRequest(
            this._sparrowApi.TenantRequest().post(`{tenant}/api/v1/loggedevents`, body)
        );
    }

    /**
     * Send post authoring closed event
     * - uses fetch api in order to use keepalive
     */
    public sendPostAuthoringClosedEvent = async (tenantId: string, postId: string, userId: string) => {
        const url = `${process.env.REACT_APP_CLIENTAPIURL}/${tenantId}/api/v1/loggedevents`;
        const accessToken = await MsalAuthModule.getInstance().getAccessToken();

        const body = {
            Events: [{
                Name: "PostAuthoringClosed",
                Platform: "Web",
                TenantId: tenantId,
                UserId: userId,
                Time: new Date().toISOString(),
                Body: {
                    PostId: postId,
                    AuthoringUIVersion: "v2"
                },
            }]
        };

        const headers = new Headers();
        headers.append("Content-Type", "application/json; charset=utf-8");
        headers.append("Authorization", `Bearer ${accessToken}`);

        const request = {
            method: "POST",
            headers,
            keepalive: true, // keeps request alive even if page unloads
            body: JSON.stringify(body)
        };

        return fetch(url, request);
    }

    /**
     * Sends post authoring closed event via beacon api
     */
    public sendPostAuthoringClosedEventBeacon = async (tenantId: string, postId: string, userId: string) => {
        let url = `${process.env.REACT_APP_CLIENTAPIURL}/${tenantId}/api/v1/loggedevents/beacon`;
        const accessToken = await MsalAuthModule.getInstance().getAccessToken();

        const body = new URLSearchParams({
            "Authorization": accessToken,
            "Events[0]": JSON.stringify({
                Name: "PostAuthoringClosed",
                TenantId: tenantId,
                UserId: userId,
                Platform: "Web",
                Time: new Date().toISOString(),
                Body: {
                    PostId: postId,
                    AuthoringUIVersion: "v2"
                },
            })
        });

        navigator.sendBeacon(url, body);
    }
}
