/**
 * Parse html text
 * - find any anchors tags that do not open in new tab and have hash character
 * - prefix href of thos anchor tags with the current url
 */
export const parseAnchors = (body: string) => {
    let updatedHTML = body;
    let html: HTMLDivElement = document.createElement("div");
    html.innerHTML = body;

    let anchors = html.getElementsByTagName("a");

    for (let i = 0; i < anchors.length; i++) {
        const hasAnchor = !!anchors[i].hash && anchors[i].target !== "_blank";
        if (hasAnchor) {
            const anchorIndex = window.location.href.indexOf("#");
            const locationHref = anchorIndex !== -1 ? window.location.href.slice(0, anchorIndex) : window.location.href;
            updatedHTML = updatedHTML.replace(`href="${anchors[i].hash}"`, `href="${locationHref + anchors[i].hash}"`);
        }
    }

    return updatedHTML;
};


/**
 * Calculates the number of lines a given text content will take up
 * in a container with a specified width, assuming consistent line height.
 *
 * @param {number} width - The width of the container in pixels.
 * @param {string} text - The text content to be measured.
 * @returns {number} - The estimated number of lines the text will occupy.
 */
export const calculateNumberOfLines = (width: number, text: string) => {
    // create a dummy element off-screen
    const temp = document.createElement('div');

    temp.style.width = width + 'px';
    temp.style.position = 'absolute';
    temp.style.visibility = 'hidden';
    temp.style.whiteSpace = 'pre-wrap'; // Preserve line breaks
    temp.innerHTML = text;

    // append the dummy element to the body
    document.body.appendChild(temp);

    // measure the height of the dummy element
    const numberOfLines = temp.clientHeight / parseInt(getComputedStyle(temp).lineHeight);

    // remove the dummy element from the DOM
    document.body.removeChild(temp);

    return numberOfLines;
}

declare global {
    interface Navigator {
        msSaveBlob: (blobOrBase64: Blob | string, filename: string) => void
    }
}
