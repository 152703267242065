import React, { useEffect, useState } from "react";

import { AttachedFile } from "modules/gallery";
import Video from "modules/posts/components/post-view/video";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { IconButton } from "@mui/material";

import "./carousel.sass";
import CanvasPreviewImage from "../canvasPreviewImage";

interface ICarouselProps {
    files?: ICarouselItem[];
    feature?: Partial<ICarouselItem>;
}

export interface ICarouselItem extends AttachedFile {
    style: React.CSSProperties;
    useCanvas?: boolean;
}

const Carousel: React.FunctionComponent<ICarouselProps> = ({
    files,
    feature,
    children
}) => {
    const [carouselIdx, setCarouselIdx] = useState<number>(0);
    const [items, setItems] = useState<ICarouselItem[]>([]);
    const [videoPaused, setVideoPaused] = useState<boolean>(false);

    useEffect(() => {
        let newItems: ICarouselItem[] = [];
        if (files)
            newItems = [...files].map((file) => ({
                ...file,
                style: { backgroundImage: 'url("' + file.fileUrl + '")' }
            }));

        // put feature first
        if (feature)
            newItems = [
                {
                    ...feature,
                    blobId: "",
                    ospreyId: "",
                    fileExtension: "",
                    fileName: "",
                    fileType: "image",
                    style: { backgroundImage: 'url("' + feature.fileUrl + '")' }
                },
                ...newItems
            ];

        setItems(newItems);
    }, [files, feature]);

    useEffect(() => {
        // reset flag to default value
        if (videoPaused)
            setVideoPaused(false);
    }, [videoPaused]);

    const pauseVideo = () => {
        setVideoPaused(true);
    }

    const rightArrow = (): JSX.Element => {
        if (carouselIdx === items.length - 1) return <></>;

        return (
            <IconButton onClick={shiftRight} className="arrow right">
                <ChevronRightIcon style={{ fontSize: 50 }} />
            </IconButton>
        )
    }

    const leftArrow = (): JSX.Element => {
        if (carouselIdx === 0) return <></>;

        return (
            <IconButton onClick={shiftLeft} className="arrow left">
                <ChevronLeftIcon style={{ fontSize: 50}} />
            </IconButton>
        );
    }

    const shiftLeft = () => {
        const newIdx = carouselIdx === 0
            ? items.length - 1
            : carouselIdx - 1;

        setCarouselIdx(newIdx);

        pauseVideo();
    }

    const shiftRight = () => {
        const newIdx = carouselIdx === items.length - 1
            ? 0
            : carouselIdx + 1;

        setCarouselIdx(newIdx);

        pauseVideo();
    }

    const onClickBubble = (idx: number) => {
        if (idx > -1 && idx < items.length) {
            setCarouselIdx(idx);

            pauseVideo();
        }
    }

    const hasFeature = !!feature;
    return (
        <>
            <div className="carousel">
                <div className="items">
                    {items.map((media) =>
                        media.fileType !== 'video' || !media.style
                            ? <React.Fragment key={media.fileUrl!}>
                                {media.useCanvas
                                    ? <div className={`item itemTransition${carouselIdx}`}>
                                        {media.transforms && media.transforms.points && media.transforms.zoom ? 
                                            <CanvasPreviewImage height={450} width={800} src={media.fileUrl!} cropPoints={media.transforms?.points || []} />
                                            : <img src={media.fileUrl!} style={{objectFit: "contain", backgroundColor: "#DDE1E5", height: "450px", width: "800px"}}></img>
                                        }
                                        </div>
                                    : <div
                                        key={media.ospreyId}
                                        style={{...media.style, backgroundSize: "contain", backgroundColor: "#DDE1E5"}}
                                        className={`item image itemTransition${carouselIdx}`}>
                                    </div>
                                }
                            </React.Fragment>
                            : <Video
                                key={media.ospreyId}
                                src={media.fileUrl!}
                                fileName={media.fileName}
                                pauseVideo={videoPaused}
                                style={media.style}
                                className="hero-banner-image"
                            />
                    )}
                </div>
                {leftArrow()}
                {items.length > 1 &&
                    <div className="mediaCounter">
                        {hasFeature
                            ? <div>{carouselIdx === 0 ? "Banner Image" : `${carouselIdx} of ${items.length - 1}`}</div>
                            : <div>{carouselIdx + 1} of {items.length}</div>}
                    </div>}
                {rightArrow()}
                {children}
            </div>
            {items.length > 1 &&
                <div className="bubbles">
                    {items.map((_, idx) =>
                        <div
                            key={`bubble-${idx}`}
                            onClick={() => onClickBubble(idx)}
                        >
                            <FiberManualRecordIcon fontSize="small" className={`${carouselIdx === idx ? "selected" : ""} ${hasFeature && idx === 0 ? "feature" : ""}`} />
                        </div>)}
                </div>
            }
        </>
    );
}

export { Carousel }
