import React, { useEffect, useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Theme,
} from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import moment from "moment";
import Axios from "axios";
import CloseIcon from "@mui/icons-material/Close";

import { resourcesApi } from "api/instances";
import LoadingCircle from "modules/common/components/loadingCircle";
import useIsMounted from "modules/common/hooks/useIsMounted";
import { IUpdateOption } from "./models";
import DownloadButton from "modules/common/components/buttons/muiDownloadButton";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        titleContainer: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
        updateTitle: {
            fontWeight: 500,
            fontSize: 18
        },
        lastUpdated: {
            color: "#7F7F7F",
            marginTop: "1rem"
        },
        footer: {
            padding: "16px 24px"
        },
        closeIconButton: {
            padding: 0,
        },
        instructions: {
            textDecoration: "underline",
        },
        downloadContainer: {
            marginTop: "1rem"
        }
    })
);

/**
 * Update options dialog component
 * - for now we only allow download of SP pacakge so strings are hard coded and update option is singular
 */
const UpdateOptionsDialog: React.FunctionComponent<IUpdateOptionsProps> = ({ open, onClose }) => {
    const classes = useStyles();
    const [loading, setLoading] = useState<boolean>(false);
    const [updateOption, setUpdateOption] = useState<IUpdateOption>();
    const isMounted = useIsMounted();

    useEffect(() => {
        const getUpdates = async () => {
            try {
                const spUpdateOption = await resourcesApi.getSharePointPackage();
                if (isMounted()) setUpdateOption(spUpdateOption);
            } catch {
            } finally {
                if (isMounted()) setLoading(false);
            }
        };

        if (open) {
            setLoading(true);
            getUpdates();
        }
    }, [isMounted, open]);

    /**
     * Converts ISO 8601 date string to local date string formatted like so: Nov 7, 2022
     */
    const getDateString = (date: string) => {
        return moment(date).format("MMM D, YYYY");
    };

    const download = async () => {
        if (!updateOption || !updateOption.downloadLink) return Promise.reject("No file to download");

        try {
            let { data } = await Axios.get(updateOption.downloadLink, { responseType: "arraybuffer" });
            return data;
        } catch (error) {
            return Promise.reject("No file to download");
        }
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>
                <div className={classes.titleContainer}>
                    <span>Update Options</span>
                    <IconButton className={classes.closeIconButton} onClick={onClose} size="large">
                        <CloseIcon />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                {updateOption &&
                    <>
                        <p>Update available:</p>
                        <p className={classes.updateTitle}>Updating Sparrow Intranet Modern SPPKG</p>
                        <span>
                            <a className={classes.instructions} href="https://support.sparrowconnected.com/en/updating-sparrow-intranet-modern-sppkg-file" target="_blank" rel="noopener noreferrer">Follow these instructions</a> and download the installation files below to update your MS SharePoint package for your company intranet.
                        </span>
                        <p className={classes.lastUpdated}>Last updated: {getDateString(updateOption.lastModifiedUTC)}</p>
                    </>}
                {!loading && !updateOption && <span>No updates available at this time.</span>}
                <div className={classes.downloadContainer}>
                    {loading && <LoadingCircle style={{ color: "#a4a4a4" }} size={40} />}
                    {!loading && updateOption &&
                        <DownloadButton
                            downloadName={updateOption.blobName}
                            fileType="application/octet-stream"
                            text="DOWNLOAD PACKAGE"
                            onClick={download}
                        />
                    }
                </div>
            </DialogContent>
            <DialogActions className={classes.footer}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onClose}>
                    DONE
                </Button>
            </DialogActions>
        </Dialog>
    );
};


interface IUpdateOptionsProps {
    open: boolean;
    onClose: () => void;
}

export default UpdateOptionsDialog;
