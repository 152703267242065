import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import * as actions from "../actionCreator";
import * as settingsActions from "modules/settings/actionCreator";
import { usersApi } from "api/instances";
import { push } from "react-router-redux";
import moment from "moment";
import numeral from "numeral";

import confirm from "utils/notyPopups";

import {
  AccountRole,
  AccountStatus,
  AccountType,
  UserListItem,
  UsersFilterValues,
  AccountCategory,
  UserSort,
  UserSortCategory } from "../models";

import TabContent from "pages/common/tabContent";
import TabContentToolbar from "pages/common/tabContentToolbar";

import ErrorSnackbar from "modules/common/components/snackbars/errorSnackbar";
import InfoHover from "modules/common/components/hovers/infoHover";
import Loading from "modules/common/components/loading";
import LoadingOverlay from "modules/common/components/loadingOverlay";
import MoreUserOptions from "./action-buttons/moreUserOptions";
import Paging from "modules/common/components/paging";
import UserFilters from "./userFilters";

import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";

import BlockIcon from "@mui/icons-material/Block";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ClearIcon from "@mui/icons-material/Clear";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";


import "modules/common/components/authoring/authoring.sass";

interface HeaderCell {
  id: string;
  label: string | JSX.Element;
}

interface SortableHeaderCell extends HeaderCell {
  id: string;
  label: string | JSX.Element;
  category: UserSortCategory;
}

class UsersView extends React.Component<PropsWithRedux, ComponentState> {
  private _isMounted = false;

  private defaultSortState : UserSort = {order: "increasing", category: "name"};

  constructor(props) {
    super(props);

    this.state = {
      errorMessage: "",
      isLoading: false,
      isPagedLoad: false,
      isSaving: false,
      filters: { ...this.getDefaultFilterValues() },
      hasFiltersApplied: false,
      selectedUsers: [],
      sort: this.defaultSortState
    };
  }

  public componentDidMount() {
    this._isMounted = true;

    moment.locale("en");

    if (!this.props.tenantAttributes.length)
      this.props.getTenantAttributes();

    this.fetchFilteredUsers(1, { ...this.getDefaultFilterValues() }, this.state.sort);
  }

  public componentWillUnmount() {
    this._isMounted = false;
  }

  public render() {
    if (!this.props.show)
      return <React.Fragment></React.Fragment>;

    if (this.state.isLoading && !this.state.isPagedLoad)
      return <Loading />;
    
    const { userList } = this.props;

    return (
      <React.Fragment>
        {this.getToolbar()}
        <TabContent>
          <div>
            {this.getFilter()}
            {this.getList()}
          </div>
        </TabContent>
        {!this.state.isLoading &&
          <Paging
            currentPage={userList.currentPage}
            items={userList.users}
            totalItems={userList.totalUsers}
            totalPages={userList.totalPages}
            onChangePage={this.onChangePage}
            resetSelection={this.resetSelection}
          />
        }
        <LoadingOverlay show={this.state.isSaving} absolute={true} />
        <ErrorSnackbar errorMessage={this.state.errorMessage} clearErrorMessage={this.clearErrorMessage} />
      </React.Fragment>
    )
  }

  private formatAccountType = (accountType: AccountType): string => {
    switch (accountType) {
      case "adfs":
        return "ADFS";
      case "apple":
        return "Apple";
      case "default":
        return "Pending";
      case "facebook":
        return "Facebook";
      case "google":
        return "Google";
      case "linkedIn":
        return "LinkedIn";
      case "microsoft":
        return "Microsoft Personal";
      case "sparrow":
        return "Sparrow";
      case "work":
        return "Work";
      default:
        return accountType;
    }
  }

  private formatActivity = (user: UserListItem): JSX.Element => {
    return (
      <div className="activity">
        <div>
          <VisibilityOutlinedIcon fontSize="small" />
          <span>{this.formatActivityNumber(user.totalViews)}</span>
        </div>
        <div>
          <MenuBookIcon fontSize="small" />
          <span>{this.formatActivityNumber(user.totalReads)}</span>
        </div>
        <div>
          <CommentOutlinedIcon fontSize="small" />
          <span>{this.formatActivityNumber(user.comments)}</span>
        </div>
        <div>
          <FavoriteBorderIcon fontSize="small" />
          <span>{this.formatActivityNumber(user.reactions)}</span>
        </div>
      </div>
    );
  }

  private formatActivityNumber = (activityNumber: number): string | number => {
    if (activityNumber >= 1000) {
      if ((activityNumber % 1000) < 100)
        return numeral(activityNumber).format("0a");
      return numeral(activityNumber).format("0.0a");
    }
    return activityNumber;
  }

  private formatDate = (date: string): string => {
    if (!date)
      return "-";
    return moment(new Date(date)).format("MMM D, YYYY h:mmA");
  }

  private formatNameAndEmail = (user: UserListItem): JSX.Element => {
    return (
      <div className="name-email">
        <div style={{ backgroundColor: user.avatar }} className="avatar">
          <div className="avatar-icon-container">
            <div className="avatar-letter">{user.initials}</div>
          </div>
        </div>
        <div>
          <div className="name">{user.name}</div>
          <div>{user.email}</div>
        </div>
      </div>
    );
  }

  private formatRole = (role: AccountRole): string => {
    return role.charAt(0).toUpperCase() + role.substr(1);
  }

  private formatStatus = (status: AccountStatus): JSX.Element => {
    if (status === "disabled")
      return <span className="item-state grey">Deactivated</span>;
    else
      return <span className="item-state green">Activated</span>;
  }

  private getDefaultFilterValues = (): Partial<UsersFilterValues> => {
    const filters: Partial<UsersFilterValues> = {
      accountTypes: [],
      roles: [],
      statuses: [],
      textToSearch: "",
      authorTopics: []
    };
    return filters;
  }

  private getFilter = (): JSX.Element => {
    return (
      <UserFilters
        filters={this.state.filters}
        tagGroups={this.props.tagGroups}
        showProfileFields={!!this.props.tenantAttributes.length}
        onChangeFilters={this.onChangeFilters}
        onClearFilters={this.onClearFilters}
      />
    );
  }

  private getHeader = (): HeaderCell[] => {
    return [
      this.createSortableHeader("name", "Name and email"),
      this.createHeader("options", ""),
      this.createSortableHeader("state", "State"),
      this.createSortableHeader("role", "Role"),
      this.createSortableHeader("accountType", "Account type"),
      this.createSortableHeader("lastLogin", "Last login"),
      this.createHeader("activity", (
        <div className="header-view">
          <span>Activity</span>
          <InfoHover>Total Views, Reads, Comments and Reactions of each user.</InfoHover>
        </div>
      )),
    ];
  }

  private createSortableHeader = (category: UserSortCategory, label: string) : SortableHeaderCell => ({ id: category, label, category});
  private createHeader = (id: string, label: string | JSX.Element) : HeaderCell => ({id, label});

  private getList = (): JSX.Element => {
    const { userList } = this.props;
    const { selectedUsers } = this.state;

    const rowCount = userList.users.length || 10;

    if (this.state.isLoading)
      return <Loading />;

    if (!userList.users.length)
      return (
        <React.Fragment>
          {this.state.hasFiltersApplied &&
            <Typography variant="h2" className="filter-results">Results</Typography>
          }
          <div>No users were found.</div>
        </React.Fragment>
      );

    return (
      <React.Fragment>
        {this.state.hasFiltersApplied &&
          <Typography variant="h2" className="filter-results">Results</Typography>
        }
        <TableContainer>
          <Table size="medium">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    indeterminate={selectedUsers.length > 0 && selectedUsers.length < rowCount}
                    checked={rowCount > 0 && selectedUsers.length === rowCount}
                    onChange={this.onSelectAllUsers}
                    inputProps={{ "aria-label": "select all users" }}
                  />
                </TableCell>
                {this.getHeader()
                  .map((hc) => hc as SortableHeaderCell)
                  .map((cell) => (
                  <TableCell
                    key={cell.id}
                    align={cell.id === "name" ? "left" : "center"}
                    padding={cell.id === "name" || cell.id === "options" ? "none" : "normal"}
                  >
                    { cell.category ? this.generateSortableHeaderCell(cell) : cell.label }
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {userList.users
                .map((user, index) => {
                  const isUserSelected = this.isUserSelected(user);
                  const labelId = `user-list-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(ev) => this.onViewProfile(user)}
                      role="checkbox"
                      aria-checked={isUserSelected}
                      tabIndex={-1}
                      key={user.id}
                      selected={isUserSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isUserSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                          onClick={(ev) => this.onSelectUser(ev, user)}
                        />
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        {this.formatNameAndEmail(user)}
                      </TableCell>
                      <TableCell padding="checkbox">
                        <MoreUserOptions
                          user={user}
                          onDeleteUser={this.onDeleteUser}
                          onDisableUser={this.onDisableUser}
                          onDownloadUserData={this.onDownloadUserData}
                          onEnableUser={this.onEnableUser}
                          onSelection={this.onClearAllUsers}
                        />
                      </TableCell>
                      <TableCell align="center">{this.formatStatus(user.status)}</TableCell>
                      <TableCell align="center">{this.formatRole(user.role)}</TableCell>
                      <TableCell align="center">{this.formatAccountType(user.accountType)}</TableCell>
                      <TableCell align="center">{this.formatDate(user.lastLoginDate)}</TableCell>
                      <TableCell align="center">{this.formatActivity(user)}</TableCell>
                    </TableRow>
                  );
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
      </React.Fragment>
    );
  }

  private getToolbar = (): JSX.Element => {
    const { selectedUsers } = this.state;

    if (!selectedUsers.length)
      return <React.Fragment></React.Fragment>;
    
    return (
      <TabContentToolbar>
        <div>
          {this.getToolbarIcon(`${selectedUsers.length} selected`, <ClearIcon />, this.onClearAllUsers)}
        </div>
        <div>
          {this.getToolbarIcon("Download user data", <SaveAltIcon />, this.onDownloadUsersData)}
          {selectedUsers.findIndex(selectedUser => selectedUser.status === "enabled") === -1 && this.getToolbarIcon("Activate", <CheckCircleOutlineIcon />, this.onEnableUsers)}
          {selectedUsers.findIndex(selectedUser => selectedUser.status !== "enabled") === -1 && this.getToolbarIcon("Deactivate", <BlockIcon style={{ transform: "rotate(90deg)" }} />, this.onDisableUsers)}
          {selectedUsers.findIndex(selectedUser => selectedUser.accountCategory === "work") === -1 && this.getToolbarIcon("Delete", <DeleteIcon />, this.onDeleteUsers)}
        </div>
      </TabContentToolbar>
    );
  }

  private clearErrorMessage = () => {
    this.setState({ errorMessage: "" });
  }

  private getToolbarIcon = (title: string, icon: React.ReactNode, onClick: () => void) => {
    return (
      <Button aria-label={title.toLowerCase()} color="primary" startIcon={icon} onClick={onClick}>
        {title}
      </Button>
    );
  }

  private fetchFilteredUsers = (pageNumber: number, filters: Partial<UsersFilterValues>, sort: UserSort, pageAmount?: number) => {
    this.setState({ isLoading: true });
    usersApi.getUsers(pageNumber, filters, sort, pageAmount)
      .then(result => {
        this.props.updateGlobalUsers(result);
        if (this._isMounted) {
          this.setState(prevState => ({
            ...prevState,
            isLoading: false
          }));
        }
      })
      .catch(err => {
        this.setState({ isLoading: false });
      });
  }

  private setSort = (sort: UserSort) => {
    this.setState({ selectedUsers: [], sort });
    this.fetchFilteredUsers(1, this.state.filters, sort);
  }

  private isUserSelected = (user: UserListItem): boolean => {
    return this.state.selectedUsers.findIndex((selectedUser) => selectedUser.id === user.id) !== -1;
  }

  private onChangeFilters = (filters: Partial<UsersFilterValues>) => {
    const updatedFilters = { ...this.state.filters, ...filters };
    this.setState({ filters: updatedFilters, hasFiltersApplied: true, isPagedLoad: true });
    this.fetchFilteredUsers(1, updatedFilters, this.state.sort);
  }

  private onClearFilters = () => {
    this.setState({ hasFiltersApplied: false, filters: { ...this.getDefaultFilterValues() }, isPagedLoad: true });
    this.fetchFilteredUsers(1, { ...this.getDefaultFilterValues() }, this.state.sort);
  }

  private onSelectAllUsers = () => {
    if (this.state.selectedUsers.length === this.props.userList.users.length)
      this.setState({ selectedUsers: [] });
    else
      this.setState({ selectedUsers: this.props.userList.users });
  }

  private onSelectUser = (ev, user: UserListItem) => {
    ev.stopPropagation();
    
    let selectedUsers = this.state.selectedUsers.slice();

    if (selectedUsers.findIndex((selectedUser) => selectedUser.id === user.id) === -1)
      selectedUsers = selectedUsers.concat([user]);
    else
      selectedUsers = selectedUsers.filter(selectedUser => selectedUser.id !== user.id);

    this.setState({ selectedUsers });
  }

  private onClearAllUsers = () => {
    this.setState({ selectedUsers: [] });
  }

  private onDeleteUser = async (id: string, accountCategory: AccountCategory) => {
    if (await confirm.show({
      title: "Delete user",
      text: "Are you sure you want to delete this user? You can't undo this action.",
      yesColor: "#a80000",
      yesText: "Delete",
      noText: "Cancel"
    })) {
      this.setState({ isSaving: true });
      usersApi.DeleteUser(id, accountCategory).then(async () => {
        this.setState({ isSaving: false });
        this.fetchFilteredUsers(1, this.state.filters, this.state.sort);
        await confirm.show({
          title: "User deleted",
          text: (
            <div className="success-checkmark-pop-dialog">
              <div className="success-checkmark-pop-wrapper">
                <div className="success-checkmark-pop-bg">
                  <div className="success-checkmark-pop-checkmark"></div>
                </div>
              </div>
              <div>This user has been successfully deleted.</div>
            </div>
          ),
          yesText: "Ok",
          hideNo: true
        });
      }).catch(() => {
        this.setState({ errorMessage: "Unable to delete user", isSaving: false });
      });
    }
  }

  private onDeleteUsers = async () => {
    const users = this.state.selectedUsers;
    if (await confirm.show({
      title: "Delete user(s)",
      text: "Are you sure you want to delete these user(s)? You can't undo this action.",
      yesColor: "#a80000",
      yesText: "Delete",
      noText: "Cancel"
    })) {
      this.setState({ isSaving: true });
      Promise.all(users.map(user => usersApi.DeleteUser(user.id, user.accountCategory).catch((e) => { throw(e); })))
        .then(async () => {
          this.setState({ isSaving: false });
          this.fetchFilteredUsers(1, this.state.filters, this.state.sort);
          await confirm.show({
            title: "User deleted",
            text: (
              <div className="success-checkmark-pop-dialog">
                <div className="success-checkmark-pop-wrapper">
                  <div className="success-checkmark-pop-bg">
                    <div className="success-checkmark-pop-checkmark"></div>
                  </div>
                </div>
                <div>These user(s) have been successfully deleted.</div>
              </div>
            ),
            yesText: "Ok",
            hideNo: true
          });
        })
        .catch(() => {
          this.setState({ errorMessage: "Unable to delete user(s)", isSaving: false });
        });
    }
  }

  private onDisableUser = (id: string) => {
    this.setState({ isSaving: true });
    usersApi.UpdateUser(id, { isEnabled: false }).then((response) => {
      this.setState({ isSaving: false });
      if (!!response) {
        this.onClearAllUsers();
        this.props.updateGlobalUsers({
          ...this.props.userList,
          users: this.props.userList.users.map(user => user.id === id ? { ...user, status: "disabled" } : user)
        });
      } else {
        this.setState({ errorMessage: "Unable to deactivate user" });
      }
    }).catch(() => {
      this.setState({ errorMessage: "Unable to deactivate user" });
    });
  }

  private onDisableUsers = () => {
    const users = this.state.selectedUsers;
    this.setState({ isSaving: true });
    Promise.all(users.map(user => usersApi.UpdateUser(user.id, { isEnabled: false }).catch((e) => { throw(e); })))
      .then(() => {
        this.setState({ isSaving: false });
        this.onClearAllUsers();
        this.props.updateGlobalUsers({
          ...this.props.userList,
          users: this.props.userList.users.map(user => users.findIndex(userListItem => userListItem.id === user.id) !== -1 ? { ...user, status: "disabled" } : user)
        });
      })
      .catch(() => {
        this.setState({ errorMessage: "Unable to deactivate users" });
      });
  }

  private onDownloadUserData = (id: string) => {
    this.props.onDownloadUserData([id]);
  }

  private onDownloadUsersData = () => {
    this.props.onDownloadUserData(!!this.state.selectedUsers.length ? this.state.selectedUsers.map(selectedUser => selectedUser.id) : []);
  }

  private onEnableUser = (id: string) => {
    this.setState({ isSaving: true });
    usersApi.UpdateUser(id, { isEnabled: true }).then((response) => {
      this.setState({ isSaving: false });
      if (!!response) {
        this.onClearAllUsers();
        this.props.updateGlobalUsers({
          ...this.props.userList,
          users: this.props.userList.users.map(user => user.id === id ? { ...user, status: "enabled" } : user)
        });
      } else {
        this.setState({ errorMessage: "Unable to activate user" });
      }
    }).catch(() => {
      this.setState({ errorMessage: "Unable to activate user" });
    });
  }

  private onEnableUsers = () => {
    const users = this.state.selectedUsers;
    this.setState({ isSaving: true });
    Promise.all(users.map(user => usersApi.UpdateUser(user.id, { isEnabled: true }).catch((e) => { throw(e); })))
      .then(() => {
        this.setState({ isSaving: false });
        this.onClearAllUsers();
        this.props.updateGlobalUsers({
          ...this.props.userList,
          users: this.props.userList.users.map(user => users.findIndex(userListItem => userListItem.id === user.id) !== -1 ? { ...user, status: "enabled" } : user)
        });
      })
      .catch(() => {
        this.setState({ errorMessage: "Unable to activate users" });
      });
  }

  private onViewProfile = (user: UserListItem) => {
    this.props.redirectTo("/" + this.props.tenantId + "/admin/users/profile/" + user.id);
  }

  private resetSelection = () => {
    this.setState({selectedUsers: []});
  }

  private onChangePage = (page: number, rowsPerPage: number) => {
    this.setState({ isPagedLoad: true });
    this.fetchFilteredUsers(page + 1, this.state.filters, this.state.sort, rowsPerPage);
  }

  private generateSortableHeaderCell(headerCell: SortableHeaderCell): React.ReactNode {
    return <TableSortLabel
      active={this.state.sort.category === headerCell.category}
      direction={this.state.sort.order === "increasing" ? "asc" : "desc"}
      onClick={() => {
        this.setSort({
          order: this.state.sort.order === "increasing" ? "decreasing" : "increasing",
          category: headerCell.category
        });
      } }
    >
      {headerCell.label}
    </TableSortLabel>;
  }
}

interface ComponentState {
  errorMessage: string;
  isLoading: boolean;
  isPagedLoad: boolean;
  isSaving: boolean;
  filters: Partial<UsersFilterValues>;
  hasFiltersApplied: boolean;
  selectedUsers: UserListItem[];
  sort: UserSort;
}

interface ComponentProps {
  show: boolean;
  onDownloadUserData: (userIds: string[]) => void;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    saving: state.users.saving,
    userList: state.users.userList || [],
    tenantAttributes: state.settings.tenantAttributes,
    tagGroups: state.settings.tenantSettings.tagGroups,
    tenantId: state.tenant.id,
  }),
  {
    getTenantAttributes: settingsActions.getTenantAttributes,
    saveUser: actions.saveUser,
    updateGlobalUsers: actions.updateGlobalUsers,
    redirectTo: push,
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(UsersView);
