import React from "react";

import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";


interface InputFieldProps {
  description?: string;
  label: string;
  value: string;
  prefix: string;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (event: any) => void;
}

const InputPrefixField: React.FunctionComponent<InputFieldProps> = props => {
  return (
    <div className="section-field">
      <div className="section-input-field-heading">
        <label>{props.label}</label>
      </div>
      <div className="section-input-field">
        <div className="section-input-prefix-field">
          <TextField
            variant="outlined"
            size="small"
            value={props.prefix}
            disabled={true}
          />
          <TextField
            variant="outlined"
            size="small"
            defaultValue={props.value}
            placeholder={props.disabled ? "" : props.placeholder}
            fullWidth
            autoComplete="off"
            disabled={props.disabled}
            onChange={(event) => {
              if (!!props.onChange && event.target.value !== props.value)
                props.onChange(event);
            }}
          />
        </div>
        {props.description &&
          <Typography variant="caption">{props.description}</Typography>
        }
      </div>
    </div>
  );
}

export default InputPrefixField;