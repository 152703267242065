import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";

import Callout from "modules/common/components/callout";

import Description from "./common/description";
import Label from "./common/label";
import PluginWrapper from "./common/pluginWrapper";

import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";

import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";


interface Item {
  label: string;
  value: string;
}


class SelectItems extends React.Component<PropsWithRedux, ComponentState> {
  constructor(props: PropsWithRedux) {
    super(props);

    this.state = {
      anchorEl: null,
      calloutOpen: false,
      items: props.input.value
    };
  }

  public render() {
    return (
      <PluginWrapper>
        <Label>
          {this.props.field.label}
          {this.props.field.description &&
            <Description>{this.props.field.description}</Description>
          }
        </Label>
        <div className="items-list">
          {this.props.input.value.map((item, index) =>
            <Paper key={index} className="item-bubble">
              <span>{item}</span>
              <IconButton size="small" onClick={() => this.onRemoveItem(item)}>
                <ClearIcon fontSize="small" />
              </IconButton>
            </Paper>
          )}
        </div>
        <Button variant="text" color="primary" startIcon={<AddIcon fontSize="small" />} onClick={this.onToggleItemsList} className="add-text-button">{this.props.field.buttonLabel}</Button>
        <Callout
          anchorEl={this.state.anchorEl}
          open={this.state.calloutOpen}
          setOpen={(toSet) => {
            this.onApply();
            this.setState({calloutOpen: toSet});
          }}
          footer={
            <div className="clear-content">
              <Button variant="text" color="primary" onClick={this.onClear}>Clear all</Button>
            </div>
          }
        >
          <List disablePadding className="portal-page-callout">
            {this.props.field.options.map((item) => {
              return (
                <ListItem key={item.value} dense button onClick={() => this.onSelectItem(item)}>
                  <ListItemIcon className="callout-checkbox">
                    <Checkbox
                      edge="start"
                      tabIndex={-1}
                      disableRipple
                      size="small"
                      color="primary"
                      checked={!!this.state.items.find((selectedItem) => selectedItem === item.value)}
                    />
                  </ListItemIcon>
                  <ListItemText primary={item.label} className="items-list-text" />
                </ListItem>
              );
            })}
          </List>
        </Callout>
      </PluginWrapper>
    );
  }


  private onApply = () => {
    this.props.input.onChange(this.state.items);
    this.onCloseItemsList();
  }

  private onClear = () => {
    this.props.input.onChange([]);
    this.setState({ ...this.state, anchorEl: null, items: [] });
  }

  private onCloseItemsList = () => {
    this.setState({ ...this.state, anchorEl: null, calloutOpen: false });
  }

  private onOpenItemsList = (event: any) => {
    this.setState({ ...this.state, anchorEl: event.currentTarget, items: this.props.input.value, calloutOpen: true });
  }

  
  private onRemoveItem = (item: string) => {
    this.props.input.onChange(this.props.input.value.filter((selectedItem) => selectedItem !== item));
  }

  private onSelectItem = (item: Item) => {
    const hasSelectedItem: boolean = !!this.state.items.find((selectedItem) => selectedItem === item.value);
    if (hasSelectedItem)
      this.setState({ ...this.state, items: this.state.items.filter((selectedItem) => selectedItem !== item.value) });
    else
      this.setState({ ...this.state, items: this.state.items.concat([item.value]) });
  }


  private onToggleItemsList = (event: any) => {
    if (!!this.state.anchorEl)
      this.onCloseItemsList();
    else
      this.onOpenItemsList(event);
  }
}


interface ComponentProps {}

interface ComponentState {
  anchorEl: any;
  calloutOpen: boolean;
  items: string[];
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps
  })
);
type PropsWithRedux = { input, meta, field } & ConnectedProps<typeof connector>;

export default connector(SelectItems);