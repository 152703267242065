import * as React from "react";
import { ConnectedProps, connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router";
import { GlobalApplicationState } from "globalApplicationState";

import Avatar from "modules/common/components/avatar";
import UnableToEditMessage from "./unableToEditMessage";

import { generateColor } from "utils/colorGenerator";
import { getRole } from "utils/getRole";

import Divider from "@mui/material/Divider";


const Account: React.FunctionComponent<PropsWithRedux> = props => {
  const { userSettings } = props;

  if (!userSettings)
    return <React.Fragment></React.Fragment>;

  return (
    <div className="account-info">
      <div>
        <div className="avatar">
          <Avatar firstname={props.userSettings.firstName} lastname={props.userSettings.lastName} color={generateColor(props.userSettings.userId)} />
        </div>
        <div className="full-name">{`${userSettings.firstName} ${userSettings.lastName}`}</div>
        <div className="email">{userSettings.email}</div>
      </div>
      <div>
        <Divider light />
        <div className="account-data">
          <div className="account-type">
            <div>Account type</div>
            <div>{userSettings.isSparrowLocalUser ? "Sparrow Account" : userSettings.isSocialUser ? "Social account" : "Work account"}</div>
          </div>
          <div>
            <div>Permission</div>
            <div>{getRole(userSettings)}</div>
          </div>
        </div>
        <Divider light />
        <UnableToEditMessage />
      </div>
    </div>
  );
}

interface ComponentProps {}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps & RouteComponentProps) => ({
    ...ownProps,
    userSettings: state.profile.userSettings!
  })
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default withRouter(connector(Account));