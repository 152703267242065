import React from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import LoadingSpinner from "modules/common/components/loadingSpinner";

const TranslateDialog: React.FunctionComponent<ComponentProps> = props => {
  return (
    <Dialog open={props.show} onClose={props.onClose} classes={{ container: "my-profile-confirmation-dialog", paper: "my-profile-confirmation-dialog-paper" }}>
      <DialogTitle>Auto Translate</DialogTitle>
      <DialogContent>
        <DialogContentText style={{ color: 'black' }}>The banner text will be automatically translated using Microsoft translation services. Do you wish to continue?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="info" onClick={props.onClose}>Cancel</Button>
        <Button variant="contained" color="primary" onClick={() => props.onSendTranslateRequest()}>Okay {props.isLoading ? <LoadingSpinner size="14px" /> : ""}</Button>
      </DialogActions>
    </Dialog>
  );
}

interface ComponentProps {
  isLoading: boolean;
  show: boolean;
  onSendTranslateRequest: () => void;
  onClose: () => void;
}

export default TranslateDialog;