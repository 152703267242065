import * as React from "react";

import { AudienceType } from "../../../models";
import { TenantAttribute } from "modules/settings/models";

import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";


interface MenuItem {
  value: string;
  text: string;
}

const Type: React.FunctionComponent<ComponentProps> = props => {
  return (
    <div>
      <div>Type</div>
      <FormControl size="small" fullWidth>
        <Select
          variant="outlined"
          fullWidth
          value={props.audienceType}
          disabled={!!props.id}
          onChange={(event) => props.onChange(event.target.value as AudienceType)}
        >
          <MenuItem key="standard" value="standard">Standard audience</MenuItem>
          {!!props.tenantAttributes.length &&
            <MenuItem key="smart" value="smart">Smart audience</MenuItem>
          }
        </Select>
        {!!props.tenantAttributes.length &&
          <div className="audience-type-description">You cannot modify the audience type once an audience is created.</div>
        }
      </FormControl>
    </div>
  );
}


interface ComponentProps {
  audienceType: AudienceType;
  id?: string;
  tenantAttributes: TenantAttribute[];
  onChange: (audienceType: AudienceType) => void;
}

export default Type;