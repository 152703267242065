import React from "react";

import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";

import copy from "copy-to-clipboard";


interface IShareLinkProps {
  label: string;
  url: string;
  preview?: boolean;
}

const ShareLink: React.FunctionComponent<IShareLinkProps> = props => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const { label, url, preview = false, } = props;
  const buttonId = "copy-" + "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    var r = Math.random() * 16 | 0, v = c === "x" ? r : ((r & 0x3) | 0x8);
    return v.toString(16);
  });

  const onRenderLabel = () => {
    return (
      <div>
        <label>{label}</label>
        <IconButton
          disabled={preview}
          id={buttonId}
          onClick={(event) => {
            copy(url!);
            setAnchorEl(event.currentTarget);
          }}
          className="copy"
          size="large">
          <FileCopyOutlinedIcon fontSize="small" />
        </IconButton>
        <Popover
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Typography style={{ padding: 5 }}>Copied</Typography>
        </Popover>
      </div>
    );
  };

  return (
    <div>
      {onRenderLabel()}
      <TextField
        variant="outlined"
        value={url}
        size="small"
        fullWidth
        InputProps={{
          readOnly: true
        }} />
    </div>
  );
}

export default ShareLink;
