import { usersApi } from 'api/instances';
import React, { useEffect, useState } from 'react';

interface ComponentProps {
    title: string;
    inputType: string;
    description?: string;
    isDisabled?: boolean;
    onChange?: (any) => any;
    placeholder?: string;
    concatValue: string;
    checkValid?: (any) => any;
}
const InputFieldConcat: React.FunctionComponent<ComponentProps> = ( {title, inputType, description, isDisabled, placeholder, onChange, concatValue} ) => {

    const [term, setTerm] = useState("");
    const [debouncedTerm, setDebouncedTerm] = useState(term);
    const [usernameAvailable, setUsernameAvailable] = useState("");
    const available = usernameAvailable === "✓" ? "username-available-text": "username-unavailable-text";

    useEffect(() => {
        const timerId = setTimeout(() => {
            setDebouncedTerm(term);
        }, 500)

        return () => {
            clearTimeout(timerId);
        }
    }, [term])

    useEffect(() => {
        if (debouncedTerm) {
            usersApi.CheckIfLocalUserIdAvailable(concatValue + debouncedTerm)
            .then((response) => {
                if (response) {
                    onChange!(concatValue + debouncedTerm)
                    setUsernameAvailable("✓")
                } else {
                    setUsernameAvailable("✕")
                }
            })
        }
    }, [concatValue, debouncedTerm, onChange])

    return (
        <div>
            <label className="profile-input-label" htmlFor={`${title}`}>{title}</label>
            <div className="label-description-concat-block">

                <div className="concat-value-field">
                    {concatValue}
                </div> 

                <input 
                    className="profile-input-field-concat description" 
                    id={title} 
                    type={inputType}
                    disabled={isDisabled}
                    placeholder={placeholder}
                    onChange={(e) => setTerm(e.target.value)}
                    />
                <p  className={`${available}`}>{usernameAvailable}</p>
            </div>
        </div>
    )
}

export default InputFieldConcat;