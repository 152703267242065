import * as React from 'react';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

//DateUtils now included automically. See https://mui.com/material-ui/guides/pickers-migration/#muipickersutilsprovider

export const PickerLocalization = props => {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            {props.children}
        </LocalizationProvider>
    )
}