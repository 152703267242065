import { Survey } from 'modules/surveys';
import { Action } from 'redux';
import { CompleteAction,  ActionCreator } from 'typedActions';
import { UserCount, PlatformStats, PostStats, DetailedPostStats, ReadViewStatByHour, TagStats, DetailedTagStats, PostRead, TagStatsPost, CommentAndReactionCount, TopEvent, UserTrends } from './models';

/* Get Ranked Posts */
export const GET_POST_STATS_INIT = 'GET_POST_STATS_INIT';
export interface GetPostStatsInit extends Action { }
export const CreateGetPostStatsInit = ActionCreator<GetPostStatsInit>(GET_POST_STATS_INIT);

export const GET_POST_STATS_COMPLETE =  'GET_POST_STATS_COMPLETE';
export interface GetPostStatsComplete extends CompleteAction {
        posts: PostStats[]
}
export const CreateGetPostStatsComplete = ActionCreator<GetPostStatsComplete>(GET_POST_STATS_COMPLETE);

/* User Counts */
export const GET_USER_COUNTS_INIT = 'GET_USER_COUNTS_INIT';
export interface GetUserCountsInit extends Action { }
export const CreateGetUserCountsInit = ActionCreator<GetUserCountsInit>(GET_USER_COUNTS_INIT);

export const GET_USER_COUNTS_COMPLETE =  'GET_USER_COUNTS_COMPLETE';
export interface GetUserCountsComplete extends CompleteAction {
        userCounts: UserCount[]
}
export const CreateGetUserCountsComplete = ActionCreator<GetUserCountsComplete>(GET_USER_COUNTS_COMPLETE);

/* Comment Counts */
export const GET_COMMENT_REACTION_COUNTS_INIT = 'GET_COMMENT_COUNTS_INIT';
export interface GetCommentAndReactionCountsInit extends Action { }
export const CreateGetCommentAndReactionCountsInit = ActionCreator<GetCommentAndReactionCountsInit>(GET_COMMENT_REACTION_COUNTS_INIT);

export const GET_COMMENT_REACTION_COUNTS_COMPLETE =  'GET_COMMENT_REACTION_COUNTS_COMPLETE';
export interface GetCommentAndReactionCountsComplete extends CompleteAction {
        commentAndReactionCounts: CommentAndReactionCount[]
}
export const CreateGetCommentAndReactionCountsComplete = ActionCreator<GetCommentAndReactionCountsComplete>(GET_COMMENT_REACTION_COUNTS_COMPLETE);


/* Platform stats */
export const GET_PLATFORM_STATS_INIT = 'GET_PLATFORM_STATS_INIT';
export interface GetPlatformStatsInit extends Action { }
export const CreateGetPlatformStatsInit = ActionCreator<GetPlatformStatsInit>(GET_PLATFORM_STATS_INIT);

export const GET_PLATFORM_STATS_COMPLETE =  'GET_PLATFORM_STATS_COMPLETE';
export interface GetPlatformStatsComplete extends CompleteAction {
        platformStats: PlatformStats[]
}
export const CreateGetPlatformStatsComplete = ActionCreator<GetPlatformStatsComplete>(GET_PLATFORM_STATS_COMPLETE);

/* Get Post Stats */
export const GET_DETAILED_POST_STATS_INIT = 'GET_DETAILED_POST_STATS_INIT';
export interface GetDetailedPostStatsInit extends Action {
    postId: string
}
export const CreateGetDetailedPostStatsInit = ActionCreator<GetDetailedPostStatsInit>(GET_DETAILED_POST_STATS_INIT);

export const GET_DETAILED_POST_STATS_COMPLETE =  'GET_DETAILED_POST_STATS_COMPLETE';
export interface GetDetailedPostStatsComplete extends CompleteAction {
    postId: string | null,
    post: DetailedPostStats | null
}
export const CreateGetDetailedPostStatsComplete = ActionCreator<GetDetailedPostStatsComplete>(GET_DETAILED_POST_STATS_COMPLETE);

export const GET_POST_READS_INIT = 'GET_POST_READS_INIT';
export interface GetPostReadsInit extends Action  {
    id: string
}
export const CreateGetPostReadsInit = ActionCreator<GetPostReadsInit>(GET_POST_READS_INIT);

export const GET_POST_READS_COMPLETE =  'GET_POST_READS_COMPLETE';
export interface GetPostReadsComplete extends CompleteAction {
    id: string;
    postReads: PostRead[] | null;
}
export const CreateGetPostReadsComplete = ActionCreator<GetPostReadsComplete>(GET_POST_READS_COMPLETE);

/* Get grouped read stats */
export const GET_READ_VIEW_STATS_INIT = 'GET_READ_VIEW_STATS_INIT';
export interface GetReadViewStatsInit extends Action {}
export const CreateGetReadViewStatsInit = ActionCreator<GetReadViewStatsInit>(GET_READ_VIEW_STATS_INIT);

export const GET_READ_VIEW_STATS_COMPLETE =  'GET_READ_VIEW_STATS_COMPLETE';
export interface GetReadViewStatsComplete extends CompleteAction {
    readViewStats: ReadViewStatByHour[] | null;
}
export const CreateGetReadViewStatsComplete = ActionCreator<GetReadViewStatsComplete>(GET_READ_VIEW_STATS_COMPLETE);

/* Get channel analytics */
export const GET_TAG_STATS_INIT = 'GET_TAG_STATS_INIT';
export interface GetTagStatsInit extends Action  {
    id: string
}
export const CreateGetTagStatsInit = ActionCreator<GetTagStatsInit>(GET_TAG_STATS_INIT);

export const GET_TAG_STATS_COMPLETE =  'GET_TAG_STATS_COMPLETE';
export interface GetTagStatsComplete extends CompleteAction {
    tagStats: DetailedTagStats | null
}
export const CreateGetTagStatsComplete = ActionCreator<GetTagStatsComplete>(GET_TAG_STATS_COMPLETE);

export const GET_TAG_READS_INIT = 'GET_TAG_READS_INIT';
export interface GetTagReadsInit extends Action  {
    id: string
}
export const CreateGetTagReadsInit = ActionCreator<GetTagReadsInit>(GET_TAG_READS_INIT);

export const GET_TAG_READS_COMPLETE =  'GET_TAG_READS_COMPLETE';
export interface GetTagReadsComplete extends CompleteAction {
    id: string;
    postReads: PostRead[] | null;
}
export const CreateGetTagReadsComplete = ActionCreator<GetTagReadsComplete>(GET_TAG_READS_COMPLETE);

export const GET_TAG_POSTS_INIT = 'GET_TAG_POSTS_INIT';
export interface GetTagPostsInit extends Action  {
    id: string
}
export const CreateGetTagPostsInit = ActionCreator<GetTagPostsInit>(GET_TAG_READS_INIT);

export const GET_TAG_POSTS_COMPLETE =  'GET_TAG_POSTS_COMPLETE';
export interface GetTagPostsComplete extends CompleteAction {
    id: string;
    posts: TagStatsPost[] | null;
}
export const CreateGetTagPostsComplete = ActionCreator<GetTagPostsComplete>(GET_TAG_POSTS_COMPLETE);


/* Get Top Channels */
export const GET_TOP_CHANNELS_INIT = 'GET_TOP_CHANNELS_INIT';
export interface GetTopChannelsInit extends Action  { }
export const CreateGetTopChannelsInit = ActionCreator<GetTopChannelsInit>(GET_TOP_CHANNELS_INIT);

export const GET_TOP_CHANNELS_COMPLETE =  'GET_TOP_CHANNELS_COMPLETE';
export interface GetTopChannelsComplete extends CompleteAction {
    tags: TagStats[] | null
}
export const CreateGetTopChannelsComplete = ActionCreator<GetTopChannelsComplete>(GET_TOP_CHANNELS_COMPLETE);

/* Get Top Surveys */
export const GET_TOP_SURVEYS_INIT = 'GET_TOP_SURVEYS_INIT';
export interface GetTopSurveysInit extends Action { }
export const CreateGetTopSurveysInit = ActionCreator<GetTopSurveysInit>(GET_TOP_SURVEYS_INIT);

export const GET_TOP_SURVEYS_COMPLETE =  'GET_TOP_SURVEYS_COMPLETE';
export interface GetTopSurveysComplete extends CompleteAction {
    surveys: Survey[] | null
}
export const CreateGetTopSurveysComplete = ActionCreator<GetTopSurveysComplete>(GET_TOP_SURVEYS_COMPLETE);

/* Get Top Events */
export const GET_TOP_EVENTS_INIT = 'GET_TOP_EVENTS_INIT';
export interface GetTopEventsInit extends Action { }
export const CreateGetTopEventsInit = ActionCreator<GetTopEventsInit>(GET_TOP_EVENTS_INIT);

export const GET_TOP_EVENTS_COMPLETE =  'GET_TOP_EVENTS_COMPLETE';
export interface GetTopEventsComplete extends CompleteAction {
    events: TopEvent[] | null
}
export const CreateGetTopEventsComplete = ActionCreator<GetTopEventsComplete>(GET_TOP_EVENTS_COMPLETE);

/* Get User Trends */
export const GET_USER_TRENDS_INIT = 'GET_USER_TRENDS_INIT';
export interface GetUserTrendsInit extends Action { }
export const CreateGetUserTrendsInit = ActionCreator<GetUserTrendsInit>(GET_USER_TRENDS_INIT);

export const GET_USER_TRENDS_COMPLETE =  'GET_USER_TRENDS_COMPLETE';
export interface GetUserTrendsComplete extends CompleteAction {
    userTrends: UserTrends | null
}
export const CreateGetUserTrendsComplete = ActionCreator<GetUserTrendsComplete>(GET_USER_TRENDS_COMPLETE);

/* Set Fetching Status */
export const SET_TO_FETCH = 'SET_TO_FETCH';
export interface SetToFetch extends Action {toSet: boolean}
export const CreateSetToFetch = ActionCreator<SetToFetch>(SET_TO_FETCH);