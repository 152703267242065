import React, { useEffect, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Editor as IEditor } from "tinymce";
import { RawEditorOptions } from "tinymce";

import { ICustomCssModelv1 } from "api/files";
import Loading from "../loading";
import { TinyMceTemplatesService } from "modules/common/services/tinyMceTemplateService";
import { acceptableLanguages, spellcheckerRpcUrl, lcidToHunspellLcid } from "utils/spellcheckLanguages";

import "./styles/sparrowTinyMce.sass";
import { ospreyApi } from "api/instances";

interface IPrePasteProcessEvent {
    internal: boolean;
    content: string;
}

interface ISparrowTinyMceProps {
    value?: string;
    defaultLcid: string;
    activeLcid?: string;
    livePreview?: boolean;
    loading?: boolean;
    disabled?: boolean;
    editorRef: React.MutableRefObject<any>;
    initOptions?: (RawEditorOptions & { selector?: undefined; target?: undefined; });
    customCss?: ICustomCssModelv1;
    enableInlineImages?: boolean; // enable inline images - opt out
    enableEmbeddedMedia?: boolean; // enable embedded media - opt out
    onShowInlineImageDialog?: (editor) => void;
    onShowIntenseEmphasisDialog: (editor) => void;
    grabMentionUsers?: (filter: string) => any;
    onChange: (value?: string) => void;
    onFocus?: () => void;
    onFullscreen?: (isFullscreen?: boolean) => void;
}

const INTENSE_EMPHASIS_ARROW = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAQAAAD9CzEMAAAAo0lEQVR4Ae3WMQ3CQBxG8XZhrAAUFA2oQAW4ABXgAhP1gAN21i73GL+RhOQlXPh/tzb5DX25dviH1Wo17mwZvj+fH4EXJ0YTAFjYuQCsXNg4QPZg7wLQuDE5QPbkIAFZ4pWAxCsBiVcEEq8GJF4RSLwakHg7BhpXpk5f8srZzHRhNq+KI2Onl516XSfIzj6ZCVIBEqQAJEgL8H4dc34cKKCAAt5OnDwnCyae2QAAAABJRU5ErkJggg==";

const INTENSE_EMPHASIS_STYLE = {
    "background-color": "%color",
    "background-image": "url(" + INTENSE_EMPHASIS_ARROW + ")",
    "background-position": "right 10px center",
    "background-repeat": "no-repeat",
    "background-size": "24px 32px",
    "box-shadow": "-3px 3px 1px 0 rgba(212,212,212,1)",
    "border-radius": "3px",
    "color": "#ffffff",
    "font-weight": "600",
    "display": "inline-block",
    "padding": "15px 40px 15px 20px",
    "text-decoration": "none"
};

const EDIT_IMAGE_CORS_HOSTS: string[] = `${process.env.REACT_APP_EDIT_IMAGE_CORS_HOSTS}`.split(",");

const ALLOWED_BLOB_TYPES = ['image/png', 'image/jpg', 'image/jpeg'];

/**
 * A wrapper around @tinymce/tinymce-react for authoring
 */
const AuthoringTinyEditor: React.FunctionComponent<ISparrowTinyMceProps> = ({
    defaultLcid,
    livePreview, // what's this for?
    editorRef,
    activeLcid = defaultLcid,
    disabled = false,
    loading = false,
    initOptions,
    enableEmbeddedMedia = true,
    enableInlineImages = true,
    customCss,
    onShowInlineImageDialog,
    onShowIntenseEmphasisDialog,
    grabMentionUsers,
    onChange,
    onFullscreen,
    onFocus,
    ...rest
}) => {
    const [spellcheckEnabled, setSpellcheckEnabled] = useState<boolean>(false);
    const [spellcheckLanguage, setSpellcheckLanguage] = useState<string>();
    const [value, setValue] = useState<string | undefined>(rest.value);

    // keep props/state in sync
    useEffect(() => {
        setValue(rest.value);
    }, [rest.value]);

    /**
     * Optional inline image functionality
     * @param editor
     */
    const setupInlineImages = (editor) => {
        if (!enableInlineImages || !onShowInlineImageDialog) return;

        editor.ui.registry.addMenuItem("inlineimage", {
            icon: "image",
            text: "Inline image",
            context: "insert",
            onAction: () => onShowInlineImageDialog(editor),
        });

        editor.ui.registry.addButton("inlineimage", {
            icon: "image",
            tooltip: "Insert inline image",
            onAction: () => onShowInlineImageDialog(editor)
        });
    }

    const onSetup = (editor) => {
        editor.on('init', (e) => {
            editorRef.current = editor;
            if (editor) {
                changeLanguage();
            }
        });

        setupInlineImages(editor);

        editor.ui.registry.addButton("customfullscreen", {
            icon: "fullscreen",
            tooltip: "Fullscreen editor",
            onAction: () => {
                editor.execCommand("mceFullScreen");
                if (onFullscreen)
                    onFullscreen(editor.plugins.fullscreen.isFullscreen())
            }
        });

        editor.ui.registry.addButton("intenseEmphasis", {
            icon: "highlight-bg-color",
            tooltip: "Apply intense emphasis to link",
            disabled: true,
            onAction: () => onShowIntenseEmphasisDialog(editor),
            onSetup: (buttonApi) => {
                const handleIntenseEmphasis = (eventApi) => buttonApi.setEnabled(eventApi.element.nodeName.toLowerCase() === 'a');
                editor.off('NodeChange', handleIntenseEmphasis);
                return () => editor.on('NodeChange', handleIntenseEmphasis);
            }
        });

        const onAction = function (autocompleteApi, rng, value) {
            editor.selection.setRng(rng);
            editor.insertContent(`<mark data-user-id={${value.id}} style="background-color: transparent; color: #3B78AB;">@${value.name}</mark>`);
            autocompleteApi.hide();
        }

        // optional mentions functionality
        if (grabMentionUsers)
            editor.ui.registry.addAutocompleter('specialchars', {
                ch: '@',
                minChars: 0,
                columns: 1,
                highlightOn: ['name_text', 'email_text'],
                onAction: onAction,
                fetch: async function (pattern) {
                    let results = await grabMentionUsers(pattern);

                    let userData = results.map(u => {
                        let firstName = u.firstName;
                        let lastName = u.lastName;
                        let fullName = (!firstName ? "[Unknown]" : firstName + ' ' + lastName);
                        let initials = !firstName || !lastName ? "Un" : firstName[0] + lastName[0];

                        let mappedUser = {
                            fullName: fullName,
                            email: u.email,
                            id: u.id,
                            color: u.color,
                            firstName: firstName,
                            lastName: lastName,
                            initials: initials
                        };

                        return mappedUser;
                    });

                    return new Promise(function (resolve) {
                        let results = userData.map(function (char) {
                            let colorForAvatar = char.color;
                            if (!!char.color)
                                colorForAvatar = colorForAvatar.substr(1);

                            return {
                                type: 'cardmenuitem',
                                value: ({ id: char.id, name: char.fullName === "[Unknown]" ? char.email : char.fullName }),
                                label: char.fullName,
                                items: [
                                    {
                                        type: 'cardcontainer',
                                        direction: 'horizontal',
                                        items: [
                                            {
                                                type: 'cardtext',
                                                text: char.initials,
                                                name: 'name_image',
                                                classes: ['mentions-icon-formatting', 'avatar-color-' + colorForAvatar]
                                            },
                                            {
                                                type: 'cardcontainer',
                                                direction: 'vertical',
                                                items: [
                                                    {
                                                        type: 'cardtext',
                                                        text: char.fullName,
                                                        name: 'name_text'
                                                    },
                                                    {
                                                        type: 'cardtext',
                                                        text: char.email,
                                                        name: 'email_text',
                                                        classes: ['mentions-email-formatting']
                                                    }
                                                ]
                                            }]
                                    }
                                ]
                            }
                        }).slice(0, 6);

                        resolve(results);
                    });
                }
            });
    }

    useEffect(() => {
        if (editorRef.current) {
            changeLanguage();
        }
    }, [editorRef, activeLcid]);

    useEffect(() => {
        if (defaultLcid) {
            let spellcheckLanguage = defaultLcid.substring(0, 2).toLocaleLowerCase();
            let isEnabledNow = acceptableLanguages.includes(spellcheckLanguage.toLocaleLowerCase());

            setSpellcheckEnabled(isEnabledNow);
            setSpellcheckLanguage(spellcheckLanguage);
        }

    }, [defaultLcid]);

    // dynamically apply loading styles
    useEffect(() => {
        const findRuleToRemove = (sheet: CSSStyleSheet, rule: string): number | undefined => {
            let ruleToRemove: number | undefined = undefined;

            // find the rule to remove
            for (let i = 0; i < sheet.cssRules.length; i++) {
                let curr = sheet.cssRules[i];
                if (curr.cssText.includes(rule)) {
                    ruleToRemove = i;
                    break;
                }
            }

            return ruleToRemove;
        }

        if (editorRef && editorRef.current) {
            // grab our custom style sheet from the editor iframe (applied via content_css on init below)
            let sheet: CSSStyleSheet = [...editorRef.current.contentWindow.document.styleSheets]
                .find((sheet: CSSStyleSheet) => sheet.href?.includes(getContentCssUrl()));

            let ruleToRemove: number | undefined, ruleToAdd: string = "";

            // if loading, hide the content portion of the editor
            if (loading) {
                ruleToRemove = findRuleToRemove(sheet, ".mce-content-body { display: block; }");
                ruleToAdd = ".mce-content-body { display: none; }";
                // done loading, re-show the content portion of the editor
            } else {
                ruleToRemove = findRuleToRemove(sheet, ".mce-content-body { display: none; }");
                ruleToAdd = ".mce-content-body { display: block; }";
            }

            if (ruleToRemove !== undefined)
                sheet.deleteRule(ruleToRemove);

            // insert at index so that the import statement in tinyContentCss.css is always first
            sheet.insertRule(ruleToAdd, sheet.cssRules.length);
        }
    }, [loading, editorRef]);

    const changeLanguage = (): void => {
        const currentSetLang = editorRef.current.plugins.tinymcespellchecker.getLanguage();
        if (currentSetLang !== activeLcid) {
            let languageToSet = activeLcid.substring(0, 2).toLocaleLowerCase();
            if (!acceptableLanguages.includes(languageToSet)) {
                languageToSet = "en";
                editorRef.current.execCommand('mceSpellcheckDisable');
            } else {
                editorRef.current.execCommand('mceSpellcheckEnable');
            }

            editorRef.current.plugins.tinymcespellchecker.setLanguage(languageToSet in lcidToHunspellLcid ? lcidToHunspellLcid[languageToSet] : languageToSet);
        }
    }

    const getContentCssUrl = (): string => `${process.env.PUBLIC_URL}/tinyContentCss.css`;

    // for use in onPrePaste - TinyMCE does not support async functions on paste_preprocess and it
    // causes images_upload_handler to run before paste_preprocess is actually finished (if async)
    // so we must fetch the blob data synchronously 
    const fetchBlobDataSynchronously = (blobUrl: string): Blob | null => {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', blobUrl, false);  // Synchronous request
      
        xhr.send();
      
        return xhr.status === 200 ? 
            base64toBlob(xhr.response, xhr.getResponseHeader('content-type')) :
            null;
    }

    const base64toBlob = (base64String: string, mimeType: string | null): Blob => {
        const byteCharacters = Buffer.from(base64String, 'base64');
        return new Blob([byteCharacters.buffer], { type: mimeType ?? 'image/*' });
    }

    /**
     * Process pasted content
     * - remove any images that are not blobs or from our blob storage
     */
    const onPrePaste = (editor: IEditor, args: IPrePasteProcessEvent) => {
        if (args.internal) return;

        // load content into a dom for easy parsing
        let temp = document.createElement("div");
        temp.innerHTML = args.content;

        // find all the img tags
        let imgs = temp.getElementsByTagName("img");

        let showErrorMessage = false;
        let showWrongFileTypeMessage = false;
        let allowedUrlRegex = /($\.jpg)|($\.png)|($\.jpeg)|(:image\/png)|(:image\/jpg)|(:image\/jpeg)/m;

        for (let i = 0; i < imgs.length; i++) {
            let curr = imgs[i];
            // for when a user drag and drops an image from their own computer, it enters as a blob:<window.location.origin>/<file_id>
            // so we transform it into a blob to get the mime-type to see if it's an image.
            // must fetch synchronously as paste_preprocess does not support async functions...
            let blob = fetchBlobDataSynchronously(curr.src);

            // validate url file extension or data/blob uri mime type
            showWrongFileTypeMessage = !(allowedUrlRegex.test(curr.src) || (blob && ALLOWED_BLOB_TYPES.includes(blob.type)));

            // if image is from our blob storage or a data/blob uri, it's allowed to be pasted and so we skip it
            if (
                !showWrongFileTypeMessage && (
                    EDIT_IMAGE_CORS_HOSTS.includes(curr.src) || // check if image is our blob host
                    /data:[\w/\-\.]+;\w+,.*/.test(curr.src) || // check if it's a data url
                    curr.src.includes(window.location.origin) // check if it's a blob uploaded via drag n drop
                )
            )
                continue;
            
            // remove the images that are not from our blob storage nor a data uri
            showErrorMessage = !showWrongFileTypeMessage;
            curr.remove();
        }

        if (showErrorMessage || showWrongFileTypeMessage) {
            editor.notificationManager.open({
                type: "error",
                text: showWrongFileTypeMessage
                    ? "File type not supported"
                    : `Images hosted externally are not allowed.`,
                closeButton: true,
                timeout: 6000
            });

            args.content = temp.innerHTML;
        }
    }

    /**
     * On drag image into editor, send upload request to our blob and return a sas url
     * - this sas url is then injected to the src of the image by tiny
     */
    const uploadImageHandler = async (blobInfo, progress) => {
        if (ALLOWED_BLOB_TYPES.includes(blobInfo.blob().type))
        {
            try {
                let result = await ospreyApi.uploadImage(blobInfo);

                return result.sasUrl;
            } catch (err) {
                return Promise.reject({
                    remove: true,
                    message: "Please try again later."
                });
            }
        }
    }

    /* If any iframes with width/height attributes
     * apply those widths and heights via style attribute
     * - this solves the issue where the responsive styling for when viewing
     * a post overwrites the height attribute causing the video to shrink vertically.
     */
    const processIframes = (value: string): string => {
        let temp = document.createElement("div");
        temp.innerHTML = value;

        let iframes = temp.getElementsByTagName("iframe");

        let updated = false;

        for (let j = 0; j < iframes.length; j++) {
            let curr = iframes[j];

            let styleWidth = curr.style.width;
            let styleHeight = curr.style.height;

            if (curr.width && (styleWidth === undefined || styleWidth === null || styleWidth === "")) {
                curr.style.width = `${curr.width}px`;
                updated = true;
            }

            if (curr.height && (styleHeight === undefined || styleHeight === undefined || styleHeight === "")) {
                curr.style.height = `${curr.height}px`;
                updated = true;
            }
        }

        return updated ? temp.innerHTML : value;
    }

    // update local state and also propage event up tree
    const onBodyLiveChange = (newValue: string) => {
        newValue = processIframes(newValue);

        setValue(newValue);
        onChange(newValue);
    }

    // only update local state
    const onBodyLocalChange = (newValue: string) => {
        newValue = processIframes(newValue);
        
        setValue(newValue);
    }

    const onBodyChange = () => {
        if (editorRef.current?.isDirty()) onChange(value)
    }

    const getContentCss = (): string[] => {
        let results = [getContentCssUrl()];

        if (customCss && customCss.sasUrl)
            results = [...results, customCss.sasUrl];

        return results;
    }

    const mediaUrlResolver = (data, resolve) => {
        const url = new URL(data.url);
        const urlParams = new URLSearchParams(url.search);

        // handle unlisted media urls manually (ones with an h query param)
        // otherwise, fallback to default url resolver by tinymce
        // the default resolver strips all query parameters for vimeo videos and replaces with some defaults
        // you can see this at node_modules/tinymce/plugins/media/plugin.js look for the urlPatterns array and scroll to the vimeo rules
        // here we keep the h parameter only and add the same defaults that tiny does
        const h = urlParams.get("h");

        if (h) {
            const sanitizedUrlParams = new URLSearchParams();
            sanitizedUrlParams.set("h", h);
            sanitizedUrlParams.set("title", "0");
            sanitizedUrlParams.set("byline", "0");

            const sanitizedUrl = `${url.protocol}//${url.hostname}${url.pathname}?${sanitizedUrlParams.toString()}`;

            const embedHtml = `<iframe src="${sanitizedUrl}" width="425" height="350"></iframe>`;
            resolve({ html: embedHtml });
        } else {
            resolve({ html: "" });
        }
    }

    return (
        <>
            {loading &&
                <Loading containerStyle={{
                    position: "relative",
                    top: 216,
                    zIndex: 2,
                    marginBottom: -71,
                }} />}
            <Editor
                disabled={disabled}
                value={value}
                init={{
                    content_css: getContentCss(),
                    entity_encoding: "raw",
                    body_class: "body", // for custom tiny mce styles
                    content_style: "img { margin: 10px } .mce-content-body[data-mce-placeholder]:hover { cursor: text; }",
                    branding: false,
                    placeholder: "Start typing here...",
                    setup: (e) => onSetup(e),
                    convert_urls: false,
                    spellchecker_rpc_url: spellcheckerRpcUrl,
                    spellchecker_language: spellcheckLanguage,
                    spellchecker_active: spellcheckEnabled,
                    spellchecker_languages: process.env.REACT_APP_TINYMCE_SPELLCHECK_LANGS,
                    noneditable_noneditable_class: 'mceNonEditable',
                    styles: {
                        "intense-emphasis": { selector: "a", classes: "intenseEmphasis", styles: INTENSE_EMPHASIS_STYLE },
                    },
                    menubar: "edit insert format tools table",
                    menu: {
                        edit: { title: "Edit", items: "undo redo | selectall | searchreplace" },
                        insert: { title: "Insert", items: `link ${enableInlineImages ? "inlineimage" : ""} ${enableEmbeddedMedia ? "media" : ""} | charmap hr | anchor` },
                        format: { title: "Format", items: "bold italic underline strikethrough superscript subscript | blocks fontfamily fontsize align | forecolor backcolor | removeformat" },
                        tools: { title: "Tools", items: "code" },
                        table: { title: "Table", items: "inserttable | cell row column | tableprops deletetable" }
                    },
                    plugins: [
                        "tinymcespellchecker", "a11ychecker", "anchor", "autolink", "autoresize", "charmap", "code", "link", "lists", "powerpaste", "searchreplace", "table", "advtable", "wordcount",
                        "quickbars", "advcode", "image", "editimage", "fullscreen", "advtemplate", "media", "pageembed"
                    ],
                    link_default_target: "_blank",
                    link_target_list: [
                        { text: 'Current window', value: '_self' },
                        { text: 'New window', value: '_blank' }
                    ],
                    default_link_target: '_blank',
                    editimage_cors_hosts: EDIT_IMAGE_CORS_HOSTS,
                    editimage_toolbar: 'rotateleft rotateright | flipv fliph | editimage imageoptions',
                    image_advtab: true,
                    quickbars_insert_toolbar: "",
                    quickbars_selection_toolbar: 'bold italic | link h2 h3 blockquote',
                    powerpaste_word_import: "merge",
                    powerpaste_html_import: "merge",
                    paste_preprocess: onPrePaste,
                    images_upload_handler: uploadImageHandler,
                    images_reuse_filename: true,
                    toolbar: `undo redo | red | blocks | bold italic underline forecolor backcolor | \
                alignleft aligncenter alignright alignjustify customfullscreen | pastetext bullist numlist outdent indent | \
                link unlink intenseEmphasis inlineimage media pageembed | removeformat a11ycheck | spellchecker language | code inserttemplate addtemplate`,
                    toolbar_mode: livePreview ? "floating" : "sliding",
                    font_family_formats: "Andale Mono=andale mono,times;" +
                        "Arial=arial,helvetica,sans-serif;" +
                        "Arial Black=arial black,avant garde;" +
                        "Book Antiqua=book antiqua,palatino;" +
                        "Comic Sans MS=comic sans ms,sans-serif;" +
                        "Courier New=courier new,courier;" +
                        "Georgia=georgia,palatino;" +
                        "Helvetica=helvetica;" +
                        "Impact=impact,chicago;" +
                        "Roboto=Roboto, sans-serif;" +
                        "Symbol=symbol;" +
                        "Tahoma=tahoma,arial,helvetica,sans-serif;" +
                        "Terminal=terminal,monaco;" +
                        "Times New Roman=times new roman,times;" +
                        "Trebuchet MS=trebuchet ms,geneva;" +
                        "Verdana=verdana,geneva;" +
                        "Webdings=webdings;" +
                        "Wingdings=wingdings,zapf dingbats",
                    advtemplate_list: async () => {
                        return await TinyMceTemplatesService.getCategories({ excludeInlineImages: !enableInlineImages, excludeEmbeddedMedia: !enableEmbeddedMedia });
                    },
                    advtemplate_get_template: TinyMceTemplatesService.getTemplate,
                    advtemplate_create_category: TinyMceTemplatesService.createCategory,
                    advtemplate_create_template: TinyMceTemplatesService.createTemplate,
                    advtemplate_rename_category: TinyMceTemplatesService.renameCategory,
                    advtemplate_rename_template: TinyMceTemplatesService.renameTemplate,
                    advtemplate_delete_category: TinyMceTemplatesService.deleteCategory,
                    advtemplate_delete_template: TinyMceTemplatesService.deleteTemplate,
                    advtemplate_move_template: TinyMceTemplatesService.moveTemplate,
                    advtemplate_move_category_items: TinyMceTemplatesService.moveCategoryItems,
                    media_url_resolver: mediaUrlResolver,
                    video_template_callback: (data) => {
                        let result = `<video width="${data.width}" height="${data.height}"${data.poster ? ` poster="${data.poster}"` : ''} controls="controls">\n` +
                            `<source src="${data.source}"${data.sourcemime ? ` type="${data.sourcemime}"` : ''} />\n` +
                            (data.altsource ? `<source src="${data.altsource}"${data.altsourcemime ? ` type="${data.altsourcemime}"` : ''} />\n` : '') +
                            '</video>';

                        if (data.source.includes("wistia.net/embed/"))
                            result = `<p>
                                <iframe allowfullscreen="allowfullscreen" src="${data.source}" name="wistia_embed" width="560" height="315"></iframe>
                            </p>`;
                        else if (data.source.includes("players.brightcove.net"))
                            result = `<p>
                                <iframe allowfullscreen="allowfullscreen" src="${data.source}" width="560" height="315"></iframe>
                            </p>`;

                        return result;
                    },
                    ...initOptions
                }}
                onEditorChange={livePreview ? onBodyLiveChange : onBodyLocalChange}
                onBlur={onBodyChange}
                onFocus={onFocus}
            />
        </>
    );
}

export { AuthoringTinyEditor };
