import { useState, useRef, useEffect, useCallback, useMemo } from "react";
import { ConsolidatedDuplicateFiles, CustomFileData, DuplicateFiles, FileUploadAction, MediaFile, MoveFiles, MoveFilesWithSrc, VerifiedFile, Image, VerifiedMoveFiles, DuplicateFileData, ImageUploadReturn, VerifiedFileData, FilterGroupName, SortGroupName } from "./models";
import { ChonkyActions, FileAction, FileArray, MapFileActionsToData } from "chonky";
import { CurrentUserSettings } from "modules/settings";
import { CustomActionUnion, ascSortAction, customFileActions, defaultFileActions, deleteSelectionAction, descSortAction, disabledCreateFolder, disabledOpenFileAction, disabledRenameFileAction, getFilterFileActions, getSortFileActions, moveFileAction, newestSortAction, oldestSortAction, openFileAction, renameFileAction, toggleShowFoldersFirst, viewAllImagesAction, viewMyImagesAction } from "./customChonkyFileActions";
import { ROOT_FOLDER_ID, imageExtFromMimeType } from "./chonkyFileAdapter";
import { UploadStatus, fileUnderAllowedFileSize, filenameContainsInvalidCharacters, invalidCharactersArray, invalidCharactersRegex, isImageType } from "modules/common/components/authoring/uploader/uploader";
import { assertFullfilled, assertObjectNotNullOrVoid } from "utils/assertHelpers";
import { DebounceState, useDebounceValue } from "utils/debounceHook";

export const useCustomFileMap = (rootFolderid: string) => {
    const [currentFolderId, setCurrentFolderId] = useState(rootFolderid);

    const currentFolderIdRef = useRef(currentFolderId);
    useEffect(() => {
        currentFolderIdRef.current = currentFolderId;
    }, [currentFolderId]);

    return {
        currentFolderId,
        setCurrentFolderId,
    };
};

export const useFiles = (
    fileMap: Record<string, CustomFileData>,
    currentFolderId: string
): FileArray => {
    return useMemo(() => {
        let fileMapKeys = Object.keys(fileMap);

        if (fileMapKeys.length) {
            const currentFolder = fileMap[currentFolderId];

            if (currentFolder) {
                const childrenIds = currentFolder.childrenIds ?? [];
                const files = childrenIds
                    .filter((fileId: string) => fileMapKeys.includes(fileId))
                    .map((fileId: string) => {
                        let file = fileMap[fileId];

                        if (!file.isDir && file.color === undefined)
                            file.color = '#dde1e5';

                        if (file.isDir && file.thumbnailUrl !== undefined)
                            file.thumbnailUrl = undefined;

                        return file;
                    });
                
                return files;
            }
        }
        
        return [];
    }, [fileMap, currentFolderId]);
};

export const useFolderChain = (
    fileMap: Record<string, CustomFileData>,
    customFolderChain: CustomFileData[],
): FileArray => {
    const useFolderChainMemo = useMemo(() => {
        const folderChain: CustomFileData[] = [fileMap[ROOT_FOLDER_ID], ...customFolderChain];

        return folderChain;
    }, [customFolderChain, fileMap]);

    return useFolderChainMemo;
};

export const useFileActionHandler = (
    onSingleSelect: (mediaFile: CustomFileData) => void,
    onMultiSelect: (mediaFiles: CustomFileData[]) => void,
    setIsGridView: React.Dispatch<React.SetStateAction<boolean>>,
    setSelectedFiles: React.Dispatch<React.SetStateAction<Set<string>>>,
    onFilterChange: (sortType?: SortGroupName, filterType?: FilterGroupName, showFoldersFirst?: boolean) => void,
    setOpenCreateFolderDialog: React.Dispatch<React.SetStateAction<boolean>>,
    showFoldersFirstToggle: boolean,
    imagesGalleryMap: Record<string, CustomFileData>,
    currentUser: CurrentUserSettings,
    onCurrentFolderChange: (folderId: string) => void,
    setOpenRenameDialog: React.Dispatch<React.SetStateAction<boolean>>,
    setFileToRename: React.Dispatch<React.SetStateAction<CustomFileData>>,
    setFilesToDelete: React.Dispatch<React.SetStateAction<CustomFileData[]>>,
    verifyMediaForMove: (mediaFiles: MoveFiles) => Promise<void>,
    setIsInnerDragNDrop: React.Dispatch<React.SetStateAction<boolean>>,
    setShowMoveFilesAppBar: React.Dispatch<React.SetStateAction<MoveFilesWithSrc>>,
    attachDeleteClassNamesOnNextFrame: () => void,
    currDir?: MediaFile,
    singleClickFileSelect?: boolean,
    onSingleClickSelect?: (mediaFile: CustomFileData) => void,
) => {
    return useCallback(
        (data: MapFileActionsToData<CustomActionUnion>) => {
            switch(data.id) {
                case ChonkyActions.OpenFiles.id:
                    const { targetFile, files } = data.payload;

                    if (files.length === 1) {
                        let fileToOpen = targetFile ?? files[0];
    
                        if (fileToOpen.isDir) {
                            onCurrentFolderChange(fileToOpen.id);
                        }
                        else if (fileToOpen.id in imagesGalleryMap) {
                            onSingleSelect(imagesGalleryMap[fileToOpen.id]);
                        }
                    }
                    else if (files.length > 1) {
                        let existingFiles = files.map((x) => imagesGalleryMap[x.id]);
                        onMultiSelect(existingFiles);
                    }
                    break;
                case ChonkyActions.MoveFiles.id:
                    verifyMediaForMove({filesToMove: data.payload.files, moveDest: data.payload.destination, moveSrc: data.payload.source});
                    break;
                case ChonkyActions.CreateFolder.id:
                    setOpenCreateFolderDialog(true);
                    break;
                case ChonkyActions.EnableListView.id:
                    setIsGridView(false);
                    break;
                case ChonkyActions.EnableGridView.id:
                    setIsGridView(true);
                    break;
                case ChonkyActions.ChangeSelection.id:
                    setSelectedFiles(data.payload.selection);
                    break;
                case ascSortAction(SortGroupName.Az).id:
                    onFilterChange(SortGroupName.Az);
                    break;
                case descSortAction(SortGroupName.Az).id:
                    onFilterChange(SortGroupName.Za);
                    break;
                case newestSortAction(SortGroupName.Az).id:
                    onFilterChange(SortGroupName.Newest);
                    break;
                case oldestSortAction(SortGroupName.Az).id:
                    onFilterChange(SortGroupName.Oldest);
                    break;
                case toggleShowFoldersFirst(SortGroupName.Az).id:
                    onFilterChange(undefined, undefined, !showFoldersFirstToggle);
                    break;
                case viewMyImagesAction(FilterGroupName.AllImages, currentUser.userId).id:
                    onFilterChange(undefined, FilterGroupName.MyImages);
                    break;
                case viewAllImagesAction(FilterGroupName.AllImages).id:
                    onFilterChange(undefined, FilterGroupName.AllImages);
                    break;
                case renameFileAction.id:
                    setFileToRename(data.state.selectedFilesForAction[0]);
                    setOpenRenameDialog(true);
                    break;
                case deleteSelectionAction.id:
                    setFilesToDelete(data.state.selectedFilesForAction!);
                    break;
                case moveFileAction.id:
                    setShowMoveFilesAppBar({ filesToMove: data.state.selectedFilesForAction!, moveSrc: currDir ?? null});
                    break;
                case ChonkyActions.StartDragNDrop.id:
                    setIsInnerDragNDrop(true);
                    break;
                case ChonkyActions.EndDragNDrop.id:
                    setIsInnerDragNDrop(false);
                    break;
                case ChonkyActions.MouseClickFile.id:
                    if (
                        data.payload.clickType === "single" && 
                        singleClickFileSelect && 
                        onSingleClickSelect &&
                        !data.payload.file.isDir
                    ) {
                        onSingleClickSelect(data.payload.file);
                    }
                    break;
                case ChonkyActions.OpenFileContextMenu.id:
                    attachDeleteClassNamesOnNextFrame();
            }
        },
        [
            onSingleSelect,
            onMultiSelect,
            setIsGridView,
            setSelectedFiles,
            onFilterChange,
            setOpenCreateFolderDialog,
            showFoldersFirstToggle,
            imagesGalleryMap,
            currentUser,
            onCurrentFolderChange,
            setOpenRenameDialog,
            setFileToRename,
            setFilesToDelete,
            verifyMediaForMove,
            setIsInnerDragNDrop,
            setShowMoveFilesAppBar,
            attachDeleteClassNamesOnNextFrame,
            currDir,
            singleClickFileSelect,
            onSingleClickSelect,
        ]
    );
};

export const useFileActions = (
    selectedFiles: Set<string>,
    currSortGroupName: SortGroupName,
    currFilterGroupName: FilterGroupName,
    userId: string,
    toggleShowFoldersFirst: boolean,
    searchValue: string,
    disableActions?: boolean,
): FileAction[] => {
    return useMemo(() => {
            return [
                ...disableActions ? [] : !!searchValue ? [disabledCreateFolder] : [ChonkyActions.CreateFolder],
                ...defaultFileActions,
                ...disableActions ? [] : [
                    selectedFiles.size === 1 ? openFileAction : disabledOpenFileAction,
                    selectedFiles.size === 1 ? renameFileAction : disabledRenameFileAction,
                ],
                ...getSortFileActions(currSortGroupName, toggleShowFoldersFirst),
                ...getFilterFileActions(currFilterGroupName, userId),
                ...disableActions ? [] : customFileActions,
            ]
        },
        [
            disableActions,
            selectedFiles,
            currSortGroupName,
            toggleShowFoldersFirst,
            currFilterGroupName,
            userId,
            searchValue
        ]
    );
}

export const useNewFolderDebounceValue = (
    setFolderNameChecked: (value: React.SetStateAction<boolean>) => void,
    setFolderError: (value: React.SetStateAction<string>) => void,
    fetchFolderByName: (fileName: string, parentId?: string | undefined) => Promise<any>,
    currDirId?: string,
): DebounceState<string> => {
    const newFolderValueDebounce = useDebounceValue(
        "",
        async () => {
            try {
                if (filenameContainsInvalidCharacters(newFolderValueDebounce.debVal)) {
                    setFolderError(`Invalid characters (${invalidCharactersArray.join(", ")})`);
                }
                else {
                    const existingFolder = await fetchFolderByName(newFolderValueDebounce.debVal, currDirId);
                    setFolderError(existingFolder !== undefined ? "Folder name already exists" : "");
                }
            }
            catch {
                setFolderError("Invalid folder name.");
            }
            finally {
                setFolderNameChecked(true);
            }
        },
        () => {
            setFolderNameChecked(false);
            setFolderError("");
        }
    );

    return newFolderValueDebounce;
}

export const useRenameDebounceValue = (
    fileToRename: CustomFileData | null,
    fetchFolderByName: (fileName: string, parentId?: string | undefined) => Promise<any>,
    fetchImageByName: (filename: string, type: string, parentId?: string | undefined) => Promise<any>,
    setRenameError: (value: React.SetStateAction<boolean>) => void,
    setRenameChecked: (value: React.SetStateAction<boolean>) => void,
    currDirId?: string,
): DebounceState<string> => {
    const renameValueDebounce = useDebounceValue(
        "",
        async () => {
            if (renameValueDebounce.debVal && fileToRename) {
                try {
                    const existingFile = fileToRename.isDir 
                        ? await fetchFolderByName(renameValueDebounce.debVal, currDirId)
                        : await fetchImageByName(`${renameValueDebounce.debVal}${imageExtFromMimeType(fileToRename.mimeType)}`, fileToRename.mimeType ?? "image/*", currDirId);
    
                    setRenameError(existingFile !== undefined);
                }
                catch {
                    setRenameError(true);
                }
                finally {
                    setRenameChecked(true);
                }   
            }
        },
        () => { setRenameError(false); setRenameChecked(false); }
    );

    return renameValueDebounce;
}

export const useDragEventDebounce = (
    isInnerDragNDrop: boolean,
    showDragDropOverlay: boolean,
    setShowDragDropOverlay: React.Dispatch<React.SetStateAction<boolean>>
): DebounceState<React.DragEvent<HTMLDivElement> | null> => {
    const dragEventDebounce = useDebounceValue(
        null as React.DragEvent<HTMLDivElement> | null,
        async () => {
            if (dragEventDebounce.debVal && !isInnerDragNDrop) {
                dragEventDebounce.debVal.preventDefault();
                if (!showDragDropOverlay) setShowDragDropOverlay(true);
            }
            else {
                setShowDragDropOverlay(false);
            }
        },
        undefined,
        500
    );

    return dragEventDebounce;
}

export const useGridViewDebounce = (
    showMediaGalleryGridView: boolean,
    saveUserSettings: (userSettings: Partial<CurrentUserSettings>, shouldDispatchUpdates?: boolean) => Promise<boolean>,
    currentUser: CurrentUserSettings
): DebounceState<boolean> => {
    const isGridViewDebounce = useDebounceValue(
        showMediaGalleryGridView,
        async () => {
            if (isGridViewDebounce.debVal !== currentUser.showMediaGalleryGridView)
                await saveUserSettings({...currentUser, showMediaGalleryGridView: isGridViewDebounce.debVal});
        }
    )

    return isGridViewDebounce;
}

export const useSearchValueDebounce = (
    searchValue: string,
    runCallbackOnEmptyString: boolean,
    setCurrentFolderId:  React.Dispatch<React.SetStateAction<string>>,
    fetchImages: (newPageAmount?: number, newPageNumber?: number, parentId?: string, searchString?: string) => Promise<void>,
): DebounceState<string> => {
    const searchValueDebounce = useDebounceValue(
        searchValue,
        async () => fetchImages(undefined, undefined, undefined, searchValueDebounce.debVal),
        () => setCurrentFolderId(ROOT_FOLDER_ID),
        undefined,
        runCallbackOnEmptyString
    )

    return searchValueDebounce;
}

export const useFileUploadFunctions = (
    maxNumUploads: number,
    maxUploadMbSize: number,
    onErrorMessage: (errorMsg: string) => void,
    onSuccessMessage: (errorMsg: string) => void,
    fetchImageByName: (filename: string, type: string, parentId?: string | undefined) => Promise<Image | undefined>,
    fetchImageByNameAsFileData: (filename: string, type: string, parentId?: string | undefined, asChonkyFileData?: boolean | undefined) => Promise<CustomFileData | undefined>,
    fetchFolderByNameAsFileData: (fileName: string, parentId?: string | undefined, asChonkyFileData?: boolean | undefined) => Promise<CustomFileData | undefined>,
    deleteFolderByName: (folderName: string, parentId?: string | undefined) => Promise<any>,
    deleteImagesFromLibrary: (imageIds: string[]) => Promise<any>,
    getUniqueFolderName: (folderName: string, parentId?: string | undefined) => Promise<string>,
    getUniqueFileName: (filename: string, type: string, parentId?: string | undefined, abortSignal?: AbortSignal | undefined) => Promise<string>,
    renameFolder: (id: string, newFolderName: string) => Promise<any>,
    renameImage: (id: string, newFileName: string) => Promise<any>,
    moveMedia: (movePayload: MoveFiles) => Promise<any>,
    fetchImages: (newPageAmount?: number, newPageNumber?: number, parentId?: string, searchString?: string) => Promise<void>,
    deleteImageByName: (filename: string, type: string, parentId?: string | undefined, abortSignal?: AbortSignal | undefined) => Promise<any>,
    uploadImage: (
        file: File, 
        onProgress: (percentCompleted: number) => void, 
        onLoad?: ((e: ProgressEvent<EventTarget>, xhr: XMLHttpRequest) => void) | undefined, 
        onError?: ((e: ProgressEvent<EventTarget>, xhr: XMLHttpRequest) => void) | undefined, 
        onAbort?: ((e: ProgressEvent<EventTarget>, xhr: XMLHttpRequest) => void) | undefined, parentId?: string, abortSignal?: AbortSignal
    ) => Promise<ImageUploadReturn<string, string> | null>,
    setUploading: (uploading: boolean) => void,
    currDir?: MediaFile,
    onUploadedImages?: (mediaFiles: CustomFileData[]) => void,
    clearExternalFilesToUpload?: () => void,
) => {
    const [invalidFiles, setInvalidFiles] = useState<File[]>([]);
    const [showInvalidFilesConfirmDialog, setShowInvalidFilesConfirmDialog] = useState(false);
    const [verifiedFileData, setVerifiedFileData] = useState<VerifiedMoveFiles>({ filesToMove: [], moveDest: null, moveSrc: null });
    const [duplicateFileData, setDuplicateFileData] = useState<DuplicateFileData[]>([]);
    const [totalToUpload, setTotalToUpload] = useState(0);
    const [totalUploaded, setTotalUploaded] = useState(0);
    const abortController = useRef(new AbortController());
    const [uploadStatus, setUploadStatus] = useState<UploadStatus>({ aborted: false, error: null, fileProgress: {} });
    const uploadedImageIds = useRef<Set<string>>(new Set());
    const [duplicateFilesDirName, setDuplicateFilesDirName] = useState("");
    const [verifiedFiles, setVerifiedFiles] = useState<VerifiedFile[]>([]);
    const [duplicateFiles, setDuplicateFiles] = useState<DuplicateFiles[]>([]);
    const [consolidatedDuplicateFiles, setConsolidatedDuplicateFiles] = useState<ConsolidatedDuplicateFiles[]>([]);
    const [shouldUploadFiles, setShouldUploadFiles] = useState(false);
    const [onInvalidFilesDialogConfirm, setOnInvalidFilesDialogConfirm] = useState<() => void>(() => () => {});
    const [showDuplicateFilesConfirmDialog, setShowDuplicateFilesConfirmDialog] = useState(false);
    const [applyActionToAllDuplicates, setApplyActionToAllDuplicates] = useState(false);

    const verifyUploadFiles = async (fileList: FileList) => {
        const files = Array.from(fileList).slice(0, maxNumUploads);
        let verifiedFilesList: VerifiedFile[] = [];
        let invalidFilesList: File[] = fileList.length <= maxNumUploads 
            ? []
            : Array.from(fileList).slice(maxNumUploads, fileList.length);
        let duplicateFilesList: DuplicateFiles[] = [];

        if (files.length < 1) {
            onErrorMessage("No files to upload.");
            return;
        }

        setDuplicateFilesDirName(currDir?.name ?? "Media Library");

        await Promise.allSettled(
            files.map(async afile => {
                let file = afile;
                if (!fileUnderAllowedFileSize(file, maxUploadMbSize)) {
                    invalidFilesList.push(file);
                    return;
                }

                if (!isImageType(file)) {
                    invalidFilesList.push(file);
                    return;
                }
                
                if (filenameContainsInvalidCharacters(file.name)) {
                    let renamedFile = new File([file], file.name.replace(invalidCharactersRegex, ""), {type: file.type});
                    file = renamedFile;
                }

                let existingImage = await fetchImageByName(file.name, file.type, currDir?.id);
                if (existingImage) {
                    duplicateFilesList.push({
                        newFile: file,
                        duplicateImage: existingImage,
                    });
                }
                else {
                    verifiedFilesList.push({
                        file,
                        action: FileUploadAction.New
                    });
                }
            })
        );

        const consolidatedDuplicateFilesList: ConsolidatedDuplicateFiles[] = duplicateFilesList.map(x => {
            return {
                newFile: { name: x.newFile.name, id: x.newFile.name, url: window.URL.createObjectURL(x.newFile) },
                duplicateFile: { name: x.duplicateImage.name, id: x.duplicateImage.id, url: x.duplicateImage.url}
            } as ConsolidatedDuplicateFiles
        });

        setInvalidFiles(invalidFilesList);
        setVerifiedFiles(verifiedFilesList);
        setDuplicateFiles(duplicateFilesList);
        setConsolidatedDuplicateFiles(consolidatedDuplicateFilesList);
        setShouldUploadFiles(true);

        if (invalidFilesList.length > 0 && duplicateFilesList.length > 0) {
            // first show invalid files modal and then duplicate files modal
            setShowInvalidFilesConfirmDialog(true);
            setOnInvalidFilesDialogConfirm(() => () => {
                setShowInvalidFilesConfirmDialog(false);
                setInvalidFiles([]);
                setOnInvalidFilesDialogConfirm(() => () => {});
                setShowDuplicateFilesConfirmDialog(true);
            });
        }
        else if (invalidFilesList.length > 0) {
            setShowInvalidFilesConfirmDialog(true);
            setOnInvalidFilesDialogConfirm(() => () => {
                setShowInvalidFilesConfirmDialog(false);
                uploadFiles(verifiedFilesList);
            })
        }
        else if (duplicateFilesList.length > 0) {
            setShowDuplicateFilesConfirmDialog(true);
        }
        else {
            await uploadFiles(verifiedFilesList);
        }
    };

    const verifyMediaForMove = async (mediaFiles: MoveFiles) => {
        let verifiedFileDataObj: VerifiedMoveFiles = {
            filesToMove: [],
            moveSrc: mediaFiles.moveSrc,
            moveDest: mediaFiles.moveDest,
        }
        let duplicateFileDataList: DuplicateFileData[] = [];

        if (mediaFiles.filesToMove.length < 1) {
            onErrorMessage("No files to upload.");
            return;
        }

        setDuplicateFilesDirName(currDir?.name ?? "Media Library");

        await Promise.allSettled(
            mediaFiles.filesToMove.map(async media => {
                let existingFileData = media.isDir 
                    ? await fetchFolderByNameAsFileData(media.name, mediaFiles.moveDest?.id)
                    : await fetchImageByNameAsFileData(media.name, media.mimeType ?? "image/*", mediaFiles.moveDest?.id);

                if (existingFileData) {
                    duplicateFileDataList.push({
                        fileDataToMove: media,
                        duplicateFileData: existingFileData,
                    });
                }
                else {
                    verifiedFileDataObj.filesToMove.push({file: media, action: FileUploadAction.Move});
                }
            })
        );

        const consolidatedDuplicateFilesList: ConsolidatedDuplicateFiles[] = duplicateFileDataList.map(x => {
            return {
                newFile: { name: x.fileDataToMove.name, id: x.fileDataToMove.id, url: x.fileDataToMove.thumbnailUrl },
                duplicateFile: { name: x.duplicateFileData.name, id: x.duplicateFileData.id, url: x.duplicateFileData.thumbnailUrl},
                isDir: x.fileDataToMove.isDir
            } as ConsolidatedDuplicateFiles
        });

        setVerifiedFileData(verifiedFileDataObj);
        setDuplicateFileData(duplicateFileDataList);
        setConsolidatedDuplicateFiles(consolidatedDuplicateFilesList);
        setShouldUploadFiles(true);

        if (duplicateFileDataList.length > 0) {
            setShowDuplicateFilesConfirmDialog(true);
        }
        else {
            moveFiles(verifiedFileDataObj);
        }
    }

    const moveFiles = async (allVerifiedFileData: VerifiedMoveFiles) => {
        setShouldUploadFiles(false);
        if (allVerifiedFileData.filesToMove.length > 0) {
            await Promise.allSettled(
                [
                    ...allVerifiedFileData.filesToMove.map(async fileData => {
                        if (fileData.action === FileUploadAction.Replace) {
                            fileData.file.isDir 
                                ? await deleteFolderByName(fileData.file.name, allVerifiedFileData.moveDest?.id)
                                : await deleteImagesFromLibrary([fileData.file.id]);
                        }
                        else if (fileData.action === FileUploadAction.Rename) {
                            let newFileName = fileData.file.isDir 
                                ? await getUniqueFolderName(fileData.file.name, allVerifiedFileData.moveDest?.id)
                                : await getUniqueFileName(fileData.file.name, fileData.file.mimeType ?? "image/*", allVerifiedFileData.moveDest?.id);

                                fileData.file.isDir 
                                    ? await renameFolder(fileData.file.id, newFileName)
                                    : await renameImage(fileData.file.id, newFileName);
                        }
                    })
                ]
            );

            await moveMedia({
                filesToMove: allVerifiedFileData.filesToMove.map(fileData => fileData.file),
                moveDest: allVerifiedFileData.moveDest,
                moveSrc: allVerifiedFileData.moveSrc
            });

            resetAllFilesUploadState();
            fetchImages();
        }
    }

    const uploadFile = async (file: File): Promise<void | ImageUploadReturn<string, string> | null> => {
        return uploadImage(
                file,
                (p) => onXhrUploadProgress(p, file.name),
                (e, xhr) => onXhrUploadComplete(e, xhr, file.name),
                (e, xhr) => onXhrUploadError(e, xhr, file.name),
                (e, xhr) => onXhrUploadAborted(e, xhr, file.name),
                currDir?.id,
                abortController.current.signal
            )
            .then((response) => {
                if (!response) {
                    setTotalToUpload(prev => prev - 1);
                } else {
                    setTotalUploaded(prev => prev + 1);
                }

                return response;
            })
            .catch(_ => onErrorMessage(`Failed to upload file '${file.name}'`));
    }

    const onXhrUploadProgress = (percentCompleted: number, fileName: string) => {};

    const onXhrUploadComplete = (e: ProgressEvent<EventTarget>, xhr: XMLHttpRequest, fileName: string) => { };

    const onXhrUploadError = (e: ProgressEvent<EventTarget>, xhr: XMLHttpRequest, fileName: string) => {
        setUploadStatus({...uploadStatus, error: xhr.responseText});
        onErrorMessage(`File ${fileName} could not be uploaded`);
    };

    const onXhrUploadAborted = (e: ProgressEvent<EventTarget>, xhr: XMLHttpRequest, fileName: string) => {
        setUploadStatus({...uploadStatus, aborted: true, error: xhr.responseText});
        onErrorMessage(`File upload for ${fileName} was aborted`);
    };

    const uploadVerifiedFile = async (verifiedFile: VerifiedFile) => {
        let fileToUpload: File = verifiedFile.file;
        if (verifiedFile.action === FileUploadAction.Replace) {
            await deleteImageByName(verifiedFile.file.name, verifiedFile.file.type, currDir?.id, abortController.current.signal);
        }
        else if (verifiedFile.action === FileUploadAction.Rename) {
            let newFileName = await getUniqueFileName(verifiedFile.file.name, verifiedFile.file.type,currDir?.id, abortController.current.signal)
            let renamedFile = new File([verifiedFile.file], newFileName, {type: verifiedFile.file.type});
            fileToUpload = renamedFile;
        }
        return await uploadFile(fileToUpload);
    }

    const uploadFiles = async (verifiedFilesList: VerifiedFile[]) => {
        setShouldUploadFiles(false);
        if (verifiedFilesList.length > 0) {
            setTotalToUpload(verifiedFilesList.length);
            setTotalUploaded(0);
            setUploadStatus({...uploadStatus, fileProgress: Array.from(verifiedFilesList).reduce((acc, val) => ({ [val.file.name]: 0 }), {})});

            await Promise
                .allSettled(verifiedFilesList.flatMap(file => uploadVerifiedFile(file)!))
                .then(results => completedUpload(true, results))
                .catch((error) => completedUpload(false));
        }
    };

    const completedUpload = async (successful: boolean, uploadedImages?: PromiseSettledResult<void | ImageUploadReturn<string, string> | null>[]) => {
        setUploading(false);

        await fetchImages();

        if (onUploadedImages && uploadedImages) {
            uploadedImageIds.current = new Set(
                uploadedImages
                    .filter(assertFullfilled)
                    .map(i => i.value?.id)
                    .filter(assertObjectNotNullOrVoid)
                );
        }

        if (successful) {
            onSuccessMessage("Successfully added new images.");
        }
        else if (!abortController.current.signal.aborted) {
            onErrorMessage("Images were not able to be uploaded. Please try again later.");
        }
        
        resetAllFilesUploadState();
    };

    const popLastDuplicateFile = (): void => {
        if (duplicateFiles.length > 0) {
            let remainingDupFiles = [...duplicateFiles].slice(1);
            setDuplicateFiles(remainingDupFiles);
            setConsolidatedDuplicateFiles([...consolidatedDuplicateFiles].slice(1));
        }
        else if (duplicateFileData.length > 0) {
            let remainingDupFileData = [...duplicateFileData].slice(1);
            setDuplicateFileData(remainingDupFileData);
            setConsolidatedDuplicateFiles([...consolidatedDuplicateFiles].slice(1));
        }
    }

    const hideDuplicateDialogAndUploadFiles = () => {
        setShowDuplicateFilesConfirmDialog(false);
        if (verifiedFiles.length > 0 && verifiedFileData.filesToMove.length === 0) {
            uploadFiles(verifiedFiles);
        }
        else if (verifiedFileData.filesToMove.length > 0) {
            moveFiles(verifiedFileData);
        }
    }

    const onDuplicateDialogAction = (action: FileUploadAction) => {
        if (applyActionToAllDuplicates) {
            if (duplicateFiles.length > 0) {
                let newVerifiedFiles: VerifiedFile[] = [];
                duplicateFiles.forEach(dupFile => newVerifiedFiles.push({file: dupFile.newFile, action}));
                let allVerifiedFiles = [...verifiedFiles, ...newVerifiedFiles];
                setVerifiedFiles([...verifiedFiles, ...newVerifiedFiles]);
                setShowDuplicateFilesConfirmDialog(false);
                setDuplicateFiles([]);
                setConsolidatedDuplicateFiles([]);
                uploadFiles(allVerifiedFiles);
            }
            else if (duplicateFileData.length > 0) {
                let newVerifiedFileData: VerifiedFileData[] = [];
                duplicateFileData.forEach(dupFileData => newVerifiedFileData.push({file: dupFileData.fileDataToMove, action}));
                let allVerifiedFileData = {...verifiedFileData, filesToMove: [...verifiedFileData.filesToMove, ...newVerifiedFileData]};
                setVerifiedFileData(allVerifiedFileData);
                setShowDuplicateFilesConfirmDialog(false);
                setDuplicateFileData([]);
                setConsolidatedDuplicateFiles([]);
                moveFiles(allVerifiedFileData);
            }
            setApplyActionToAllDuplicates(false);
        }
        else {
            if (duplicateFiles.length > 0) {
                const fileToReplace = duplicateFiles[0].newFile;
                const newVerifiedFiles = [...verifiedFiles, {file: fileToReplace, action}];
                setVerifiedFiles(newVerifiedFiles);
                popLastDuplicateFile();
            }
            else if (duplicateFileData.length > 0) {
                const fileDataToReplace = duplicateFileData[0].fileDataToMove;
                setVerifiedFileData({...verifiedFileData, filesToMove: [...verifiedFileData.filesToMove, {file: fileDataToReplace, action}]});
                popLastDuplicateFile();
            }
        }
    }

    const resetAllFilesUploadState = () => {
        setShouldUploadFiles(false);
        setVerifiedFiles([]);
        setVerifiedFileData({ filesToMove: [], moveDest: null, moveSrc: null });

        setShowInvalidFilesConfirmDialog(false);
        setShowDuplicateFilesConfirmDialog(false);
        setInvalidFiles([]);
        setDuplicateFileData([]);
        setOnInvalidFilesDialogConfirm(() => () => {});
        setConsolidatedDuplicateFiles([]);
        setDuplicateFilesDirName("");
        setTotalToUpload(0);
        setTotalUploaded(0);
        if (clearExternalFilesToUpload) clearExternalFilesToUpload();
        abortController.current = new AbortController();
    }

    useEffect(() => {
        if (
            shouldUploadFiles && 
            (
                (duplicateFiles.length === 0 && verifiedFiles.length > 0 ) ||
                (duplicateFileData.length === 0 && verifiedFileData.filesToMove.length > 0)
            )
        ) {
            hideDuplicateDialogAndUploadFiles();
        }
    }, [duplicateFiles.length, duplicateFileData.length]);

    return {
        invalidFiles,
        duplicateFilesDirName,
        verifiedFiles,
        duplicateFiles,
        consolidatedDuplicateFiles,
        showDuplicateFilesConfirmDialog,
        showInvalidFilesConfirmDialog,
        totalToUpload,
        totalUploaded,
        applyActionToAllDuplicates,
        setApplyActionToAllDuplicates,
        hideDuplicateDialogAndUploadFiles,
        verifyUploadFiles,
        verifyMediaForMove,
        resetAllFilesUploadState,
        popLastDuplicateFile,
        onInvalidFilesDialogConfirm,
        onDuplicateDialogAction
    }
}