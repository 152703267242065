import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";

import { DocumentFeedItem } from "../../../../../models";
import Document from "./document";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";


import "../../../../../styles/layouts/list.sass";


interface Header {
  alignment: "center" | "left";
  label: string;
}

const Headers: Header[] = [
  { alignment: "center", label: "" },
  { alignment: "left", label: "Document title" },
  { alignment: "center", label: ""},
  { alignment: "center", label: "Last modified" },
  { alignment: "center", label: "Category tags" },
  { alignment: "center", label: "Read status" },
  { alignment: "center", label: "Actions" }
];

const List: React.FunctionComponent<PropsWithRedux> = props => {
  return (
    <div className="list">
      <TableContainer>
        <Table size="medium">
          <TableHead>
            <TableRow>
              {Headers.map((header, index) =>
                <TableCell
                  key={index}
                  align={header.alignment}
                  padding="normal"
                >
                  {header.label}
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.documents.map(document => (
              <Document
                key={document.id}
                {...props}
                document={document}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

interface ComponentProps {
  onDocumentSelected: (document: DocumentFeedItem) => void;
  onDownloadDocument: (document: DocumentFeedItem) => void;
  onViewDocument: (document: DocumentFeedItem) => void;
  onInspectDocument: (document: DocumentFeedItem) => void;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    documents: state.documents.documentFeed.documentFeed.documents
  })
);
type PropsWithRedux = ConnectedProps<typeof connector>;
export default connector(List);