import * as React from "react";

import Description from "./common/description";
import Label from "./common/label";
import PluginWrapper from "./common/pluginWrapper";

import Slider from "@mui/material/Slider";


const PercentageSlider: React.FunctionComponent<{ input, meta, field }> = props => {
  return (
    <PluginWrapper>
      {props.field.label &&
        <Label>
          {props.field.label}
          {props.field.description &&
            <Description>{props.field.description}</Description>
          }
        </Label>
      }
      <Slider
        value={props.input.value || 0}
        step={1}
        marks={[
          { value: 0, label: "0%" },
          { value: 100, label: "100%" }
        ]}
        valueLabelDisplay="auto"
        onChange={(ev, checked) => props.input.onChange(checked)}
        className={props.field.label ? "" : "percentage-slider"}
      />
    </PluginWrapper>
  );
}

export default PercentageSlider;