import { audiencesApi, usersApi } from 'api/instances';
import AudienceSidebarSelector from 'modules/audiences/components/audienceSidebarSelector';
import { Audience } from 'modules/audiences/models';
import DownloadButton from 'modules/common/components/buttons/downloadButton';
import TextBubble from 'modules/common/components/textBubble';
import moment from 'moment';
import React from 'react';
import { AccountCreationTypes } from '..';
import BulkUserCSVUploader from './bulkUserCSVUploader';
import { FileDownloader } from 'utils/fileDownloader';

interface ComponentState { 
    showAudiencePicker: boolean;
    audiences: Audience[];
    selectedAudiences: string[];
    uploadedCSV: File | null;
    bulkUploadList: Batch[];
    fileName: string;
}
interface ComponentProps { 
    accountTypeSelected: AccountCreationTypes
    handleCSVUpload: (any) => any;
}

interface TextBubble {
    id: string;
    name: string;
}

interface BulkUploadList {
    batchJobs: Batch[];
}

interface Batch {
    batchId: string;
    timeAttempted: Date;
}
class BulkUserCreation extends React.Component<ComponentProps, ComponentState> {

    public componentDidMount() {
        audiencesApi.getAllAudiences()
        .then((results) => {
            this.setState({
                ...this.state,
                audiences: results
            })
        })

        usersApi.GetBatchUploadIds()
        .then((results) => {
            if (results) {
                this.setState({
                    ...this.state,
                    bulkUploadList: results 
                })
            }
        });
    }

    constructor(props) {
        super(props);
        this.state = {
            showAudiencePicker: false,
            audiences: [],
            selectedAudiences: [],
            uploadedCSV: null,
            bulkUploadList: [],
            fileName: ""
        }
    }


    public render() {
        const downloadButtonStyle: React.CSSProperties = {
            backgroundColor: 'white',
            border: 'none',
            boxShadow: 'none',
            cursor: 'pointer',
            color: '#2196f3'
        }
        const audienceBubbles:TextBubble[] = [];
        this.state.selectedAudiences.forEach(element => {
            audienceBubbles.push({id: element, name: this.state.audiences.find((a) => a.id === element)?.displayName} as TextBubble)
        });

        const bulkBatchRows = this.state.bulkUploadList.map((row) => {
            return (
                <tr key={row.batchId} style={{fontSize: 12}}><td>{moment(row.timeAttempted, 'YYYY MM DD').toString().substring(0, 10)}</td>
                    <td>                           
                        <DownloadButton
                            onClick={() => this.downloadBulkUploadCSV(row.batchId) }
                            text={" Download report"}
                            downloadName={`${row.timeAttempted}-bulk-upload`}
                            styles={downloadButtonStyle}
                        />
                    </td>
                </tr>
            )
        })
        
        return (
            <div className="bulk-user-creation-container">
                <div className="bulk-page-creation-section left">
                <BulkUserCSVUploader
                    returnFileName={this.setFileName}
                    parseCSV={this.props.handleCSVUpload}
                    downloadSample={this.downloadSample} />
                <div style={{marginLeft: 15}} className="description-text">Bulk Imports cannot assign special permissions (Owner, Author, Submission Manager) to the new users.</div>
                
                <div style={{marginLeft: 15, marginTop: 10}} className="input-row">
                    <p className="profile-input-label-wide">File uploaded:</p>
                        <div style={{float:"right", color:"#2196f3"}}className="description-text">
                            {this.state.fileName}
                        </div>
                    </div>
                </div>

                <div className="bulk-page-creation-section right">
                <div className="input-row">
                        <div><b>Bulk upload batches</b></div>
                        <div style={{marginTop:20, maxHeight: 200, overflowY: "scroll"}}>
                            <table style={{width:"100%"}}>
                                <tbody>
                                    <tr style={{textAlign:"left", fontSize: 14, background: "#f3f3f4"}}>
                                        <th>Date Attempted</th><th>Download</th>
                                    </tr>
                                    
                                    {this.state.bulkUploadList.length > 0 ?
                                        bulkBatchRows : null
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                {this.state.showAudiencePicker && 
                    <AudienceSidebarSelector
                        applyAudienceChanges={this.handleApplyAudiences}
                        previousAudiences={this.state.selectedAudiences} 
                        userAudiences={this.state.audiences}
                    />
                }
            </div>
        )
    }

    private setFileName = (fileName: string) => {
        this.setState({
            ...this.state,
            fileName: fileName
        })   
    }
    private downloadBulkUploadCSV = (id: string) => {
        return usersApi.GetBatchCSVForBatchId(id);
    }
    
    private downloadSample = () => {
        const file = {
            name: `sample-bulk-upload.csv`
          }
          usersApi.DownloadSampleBatchCSV()
            .then( data => new FileDownloader(file).downloadBlob(data))
    }

    private handleApplyAudiences = (audiences: string[]) => {
        this.setState({
            ...this.state,
            selectedAudiences: audiences,
            showAudiencePicker: false,
        })
    }
}


export default BulkUserCreation;