import React, { useState } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PhoneField from "../common/phoneField";


const PreferredSMSPhoneDialog: React.FunctionComponent<ComponentProps> = props => {
  const [preferredSMSPhone, setPreferredSMSPhone] = useState(props.preferredSMSPhone);

  return (
      <Dialog
          open={props.show}
          onClose={props.onClose}
          classes={{ container: "my-profile-confirmation-dialog", paper: "my-profile-confirmation-dialog-paper" }}
      >
          <DialogTitle>SMS notifications & alerts</DialogTitle>
          <DialogContent>
              <DialogContentText>Add and save your mobile number to turn on SMS notifications and alerts.</DialogContentText>
              <PhoneField
                  label=""
                  specialLabel="Mobile No."
                  country={"us"}
                  enableSearch={false}
                  autoFormat={true}
                  disabledDropDown={true}
                  placeholder="Enter mobile number"
                  value={preferredSMSPhone}
                  onChange={(event) => setPreferredSMSPhone(event.target.value)}
              />
          </DialogContent>
          <DialogActions>
            <Button variant="text" color="info" onClick={props.onClose}>Cancel</Button>
            <Button variant="text" color="primary" disabled={!preferredSMSPhone} onClick={() => props.onChange(preferredSMSPhone)}>Save</Button>
          </DialogActions>
      </Dialog>
  );
}

interface ComponentProps {
  show: boolean;
  preferredSMSPhone: string;
  onChange: (preferredSMSPhone: string) => void;
  onClose: () => void;
}

export default PreferredSMSPhoneDialog;