import { ApiRequest } from "./network";
import { SparrowApi } from "./network/adapters/SparrowApi";

export class OspreyApi {
    constructor(private _sparrowApi: SparrowApi) {}

    public uploadImage = (blobInfo) => {
        const formData = new FormData();
        formData.append('file', blobInfo.blob(), blobInfo.filename());

        return new ApiRequest(
            this._sparrowApi.TenantRequest().post(`/{tenant}/ospreyapi/v1/images/upload?fileName=${blobInfo.filename()}`, formData)
        );
    }
}
