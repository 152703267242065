import { Grid } from '@mui/material';
import * as React from 'react';

//Generic component meant for a single component to take up the screen. Examples would be downloadReportPage.tsx and accessRestricted.tsx. 
const NoticeWebpage : React.FunctionComponent<ComponentProps> = (props) => {
    return (
        <React.Fragment>
            <Grid container justifyContent="center">
                <Grid item>
                    {props.imageComponent}
                </Grid>
            </Grid>
            <Grid container justifyContent="center" style={{paddingTop: "10%"}}>
                <Grid item>
                    {props.titleComponent}
                </Grid>
            </Grid>
            <Grid container justifyContent="center" style={{paddingTop: "3%"}}>
                <Grid item>
                    {props.bodyComponent}
                </Grid>
            </Grid>
            <Grid container justifyContent="center" style={{paddingTop: "3%"}}>
                <Grid item>
                    {props.buttonComponent}
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

interface ComponentProps { 
    imageComponent: React.ReactFragment
    titleComponent: React.ReactFragment
    bodyComponent: React.ReactFragment
    buttonComponent: React.ReactFragment
}

export default NoticeWebpage;