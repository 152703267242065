import { Action } from "redux";
import { ActionCreator } from "typedActions";

export const SET_LCID_MAPPINGS = "SET_LCID_MAPPINGS";
export interface SetLcidMappings extends Action { mappings: any }
export const SetLcidMappings = ActionCreator<SetLcidMappings>(SET_LCID_MAPPINGS);

export const SET_EMAIL_RESOURCE = "SET_EMAIL_RESOURCE";
export interface SetEmailResource extends Action { resource: any}
export const SetEmailResource = ActionCreator<SetEmailResource>(SET_EMAIL_RESOURCE)

export const SET_SMS_RESOURCE = "SET_SMS_RESOURCE";
export interface SetSmsResource extends Action { resource: any}
export const SetSmsResource = ActionCreator<SetSmsResource>(SET_SMS_RESOURCE)

export const SET_MOBILE_RESOURCE = "SET_MOBILE_RESOURCE";
export interface SetMobileResource extends Action { resource: any}
export const SetMobileResource = ActionCreator<SetMobileResource>(SET_MOBILE_RESOURCE)