//File for storing things such as static newsletter / template / theme names.

export const standardTemplateNames = ["1 column", "2 column"];

export const getBase64 = file => {
    return new Promise<string>(resolve => {
        let baseURL:string = "";
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
        baseURL = reader.result?.toString() ?? "";
        resolve(baseURL);
        };
    });
};