import React, { useState } from "react";

import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";


interface InputFieldProps {
  description?: string;
  label: string;
  value: string;
  items: { value: string; text: string; }[];
  disabled?: boolean;
  onChangeValue: (value: string) => void;
}

const CustomPronouns = (pronouns: string): boolean => {
  return pronouns !== "he/him/his" && pronouns !== "she/her/hers" && pronouns !== "they/them/their";
}

const PronounsInputField: React.FunctionComponent<InputFieldProps> = props => {
  const pronouns = props.value.split("/");
  const isCustom = CustomPronouns(props.value) && pronouns.length === 3;

  const [selectedPronouns, setSelectedPronouns] = useState(!!props.value ? isCustom ? ["custom"] : props.value.split(",") : []);
  const [firstPronoun, setFirstPronoun] = useState(isCustom ? pronouns[0] : "");
  const [secondPronoun, setSecondPronoun] = useState(isCustom ? pronouns[1] : "");
  const [thirdPronoun, setThirdPronoun] = useState(isCustom ? pronouns[2] : "");

  return (
    <div className="section-field">
      <div className="section-input-field-heading">
        <label>{props.label}</label>
      </div>
      <div className="section-input-field">
        <FormControl variant="outlined" size="small" fullWidth>
          <Select
            variant="outlined"
            value={selectedPronouns}
            multiple
            displayEmpty
            fullWidth
            disabled={props.disabled}
            renderValue={(selected: string[]) =>
              !selected.length
                ? "Select all that apply"
                : selected[0] === "custom"
                  ? "My pronouns are..."
                  : selected.join(", ")
            }
            onChange={(event) => {
              if ((event.target.value as string[])[(event.target.value as string[]).length - 1] === "custom") {
                setSelectedPronouns(["custom"]);
                props.onChangeValue("");
              } else {
                const updatedPronouns = (event.target.value as string[]).filter(pronoun => pronoun !== "custom");
                setSelectedPronouns(updatedPronouns);
                props.onChangeValue(updatedPronouns.join(","));
              }
            }}
          >
            {props.items.map(item => <MenuItem key={item.value} value={item.value}>{item.text}</MenuItem>)}
            <MenuItem value="custom">My pronouns are...</MenuItem>
          </Select>
          {!!selectedPronouns.length && selectedPronouns[0] === "custom" &&
            <div className="section-input-pronouns-field">
              <TextField
                variant="outlined"
                size="small"
                value={firstPronoun}
                autoComplete="off"
                onChange={(event) => {
                  setFirstPronoun(event.target.value);
                  props.onChangeValue(event.target.value + "/" + secondPronoun + "/" + thirdPronoun);
                }}
              />
              <div>/</div>
              <TextField
                variant="outlined"
                size="small"
                value={secondPronoun}
                autoComplete="off"
                onChange={(event) => {
                  setSecondPronoun(event.target.value);
                  props.onChangeValue(firstPronoun + "/" + event.target.value + "/" + thirdPronoun);
                }}
              />
              <div>/</div>
              <TextField
                variant="outlined"
                size="small"
                value={thirdPronoun}
                autoComplete="off"
                onChange={(event) => {
                  setThirdPronoun(event.target.value);
                  props.onChangeValue(firstPronoun + "/" + secondPronoun + "/" + event.target.value);
                }}
              />
            </div>
          }
        </FormControl>
        {props.description &&
          <Typography variant="caption">{props.description}</Typography>
        }
      </div>
    </div>
  );
}

export default PronounsInputField;