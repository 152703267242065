import React, { useEffect, useState } from 'react';
import { Audience } from '../models';
import './audienceSidebarSelector.sass';
import { Button, Checkbox, List, ListItem, TextField } from '@mui/material';
import SearchIcon from "@mui/icons-material/Search";
import Callout from 'modules/common/components/callout';
import TextBubble from 'modules/common/components/textBubble';

interface ComponentProps {
    applyAudienceChanges: any;
    previousAudiences: string[];
    userAudiences: Audience[];
    handlePopupClick: HTMLButtonElement | null;
    handleClose: () => void;
    onSelectAudienceClick: () => any;
    textBubbles: TextBubble[];
}

interface TextBubble {
    id: string;
    name: string;
}

const AudienceCalloutSelector: React.FunctionComponent<ComponentProps> = ( {userAudiences, applyAudienceChanges, previousAudiences, handlePopupClick, handleClose, onSelectAudienceClick, textBubbles } ) => {
    const [selectedAudiences, setSelectedAudiences] = useState<string[]>(previousAudiences);
    const [audiences, setAudiences] = useState<Audience[]>(userAudiences);
    const [audienceSearchText, setAudienceSearchText] = useState<string>();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(handlePopupClick);
    const [calloutOpen, setCalloutOpen] = React.useState(false);

    useEffect(() => {
    },[anchorEl])

    const getAllAudiences = userAudiences.filter((audience) => audience.enabled).map((allAudiences) => {
        return allAudiences.id;
    })

    const audienceSelector = audiences.filter((audience) => audience.enabled).map((filteredAudience) => {
        return (
            <React.Fragment key={filteredAudience.id}>
                <ListItem
                    button
                    onClick={() => {
                         modifySelectedAudiences(filteredAudience.id); 
                        }}
                    className="select-popover-group-header-clickable"
                >
                    <Checkbox
                    id={filteredAudience.id}
                    name={filteredAudience.displayName}
                        edge="start"
                        tabIndex={-1}
                        disableRipple
                        size="small"
                        color="primary"
                        checked={selectedAudiences.includes(filteredAudience.id)}
                    />
                    {filteredAudience.displayName}
                </ListItem>
            </React.Fragment>
        );
    })

    const getAudienceFilterFooter = () => {
        const audienceSelected = (selectedAudiences?.length ?? [].length) > 0;

        return <Button color="primary" style={{float: "right"}} onClick={() => onHandleAllAudience(audienceSelected)}>
                {audienceSelected ? "Unselect" : "Select"} All
                </Button>
    }

    const onHandleAllAudience = (audienceSelected: boolean) => {
        if (audienceSelected){
            setSelectedAudiences([]);
        } else {
        setSelectedAudiences(getAllAudiences);
      }
    }

    const getAudienceSearchBox = () => {
        return <TextField
        variant="outlined"
        size="small"
        fullWidth
        value={audienceSearchText}
        placeholder = "Search audience"
        InputProps={{
          startAdornment: <SearchIcon className="search-icon" />
        }}
        onChange={(event) => {onUpdateAudienceTextToSearch(event.target.value)}}
        className="text-to-search" />
    }

    const onUpdateAudienceTextToSearch = (ev: any) => {
        setAudienceSearchText(ev);
        setAudiences(userAudiences.filter(c => c.displayName.toLowerCase().includes(ev.toLowerCase())));
    }
    
    const modifySelectedAudiences = (id: string) => {
        /* We either remove or add an audience on click */
        if (selectedAudiences.includes(id)) {
            setSelectedAudiences(selectedAudiences.filter(audience => audience !== id));
        } else {
            setSelectedAudiences(prev => [...prev, id])
        }
    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
      };

    return (
        <>
            <p className="profile-input-label">Assign To Audiences</p>
            <div className="label-description-block">
                <button
                    className="reset-btn-no-border"
                    onClick={(e) => {
                        handleClick(e);
                        setCalloutOpen(true);
                        onSelectAudienceClick();
                    }}
                >
                    SELECT AUDIENCES
                </button>
                <TextBubble list={textBubbles} />
            </div>
            <Callout
                anchorEl={anchorEl}
                setOpen={(toSet) => {
                    applyAudienceChanges(selectedAudiences);
                    setCalloutOpen(toSet ?? false);
                }}
                open={calloutOpen}
                footer={getAudienceFilterFooter()}
                header={getAudienceSearchBox()}
            >
                <div style={{ height: "254px", width: "350px" }}>
                    <List disablePadding>{audienceSelector}</List>
                </div>
            </Callout>
        </>
    );
}


 export default AudienceCalloutSelector;
