import React, { useEffect, useRef, useState } from "react";

import { Search } from "@mui/icons-material";
import { TextField } from "@mui/material";

import { useAnchorEl } from "modules/common/hooks/useAnchorEl";
import Callout from "../callout";
import CalloutHover from "../hovers/calloutHover";
import { ContentSearchResultList } from "./contentSearchResultList";

import "./styles/contentSearch.sass";

export interface IContentSearchResultItem {
    id: string;
    imageUrl: string;
    title: string;
    authorName: string;
    publishTime: Date | string;
}

interface IContentSearchResultProp {
    onSearch: (text: string) => Promise<IContentSearchResultItem[]>;
    onClick: (item: IContentSearchResultItem) => void;
    onPage?: (text: string) => Promise<IContentSearchResultItem[]>;
    onClose?: () => void;
    pageNumber?: number;
    pageCount?: number;
    placeholder: string;
    autoSearchWaitSeconds?: number;
    autoSearch?: boolean; // search after autoSearchWaitSeconds seconds of no typing
    disabled?: boolean;
}

export const ContentSearch: React.FunctionComponent<IContentSearchResultProp> = ({
    placeholder,
    autoSearch = false,
    autoSearchWaitSeconds = 2000,
    pageNumber,
    pageCount,
    disabled,
    onPage,
    onClose,
    onSearch,
    onClick,
}) => {
    const [results, setResults] = useState<IContentSearchResultItem[]>([]);
    const [isSearching, setIsSearching] = useState<boolean>(false);
    const { open, anchorEl, setAnchorEl } = useAnchorEl<HTMLInputElement>();
    const [searchText, setSearchText] = useState<string>("");

    const timeout = useRef<NodeJS.Timeout | null>(null);
    const target = useRef<HTMLInputElement | null>(null);
    // this variable allows us to see if we have already performed a search by user pressing enter rather than the timeout running
    const searchPerformedByEnter = useRef<boolean>(false);

    useEffect(() => {
        if (disabled) setSearchText("");
    }, [disabled]);

    const onHandleClick = (item: IContentSearchResultItem) => {
        setAnchorEl(null);
        onClick(item);
    };

    const onHandleSearch = async (target: HTMLInputElement) => {
        // clear results
        setResults([]);

        setAnchorEl(target);
        setIsSearching(true);

        let newResults = await onSearch(searchText);

        setResults(newResults);
        setIsSearching(false);
    };

    const onHandlePage = async () => {
        if (!onPage) return;

        setIsSearching(true);

        let newResults = await onPage(searchText);

        setResults([...results, ...newResults]);
        setIsSearching(false);
    };

    const getTextFieldComponent = (): JSX.Element => (
        <TextField
            id="content-search-input"
            disabled={disabled}
            variant="outlined"
            size="small"
            value={searchText}
            placeholder={placeholder}
            autoComplete="off"
            classes={{
                root: `content-search-input ${disabled ? "disabled" : ""}`,
            }}
            style={{
                width: "100%",
            }}
            InputProps={{
                startAdornment: <Search htmlColor="#7f7f7f" />,
                sx: {
                    height: 45,
                    backgroundColor: disabled ? "#f0f0f0" : "",
                },
            }}
            onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                setSearchText(ev.target.value);
            }}
            onKeyUp={async (event: React.KeyboardEvent<HTMLInputElement>) => {
                searchPerformedByEnter.current = false;

                // keep track of callout target so we can open it later when timeout runs
                target.current = event.currentTarget;

                if (autoSearch) {
                    // persist event so we can use it in our timeout below
                    if (timeout.current) clearTimeout(timeout.current);

                    timeout.current = setTimeout(async () => {
                        // if search performed by enter already, do not search again
                        if (!searchPerformedByEnter.current) await onHandleSearch(target.current!);
                    }, autoSearchWaitSeconds);
                }

                if (event.key === "Enter") {
                    searchPerformedByEnter.current = true;
                    await onHandleSearch(target.current!);
                }
            }}
        />
    );

    return (
        <React.Fragment>
            {disabled ? (
                <CalloutHover arrow={true} component={getTextFieldComponent()} contentStyle={{ height: "auto" }}>
                    You've reached the maximum content count. Adjust the limit or unpin other content to add more.
                </CalloutHover>
            ) : (
                getTextFieldComponent()
            )}
            <Callout
                arrowPosition="none"
                anchorEl={anchorEl}
                open={open}
                setOpen={(newValue: boolean) => {
                    target.current = null;
                    setAnchorEl(null);

                    if (onClose) onClose();
                }}
                popoverProps={{
                    disableAutoFocus: true,
                    disableEnforceFocus: true,
                    anchorOrigin: { vertical: 45, horizontal: 185 },
                }}
            >
                <div className="content-search-callout">
                    <ContentSearchResultList
                        emptyMessage="No matching results found"
                        pageCount={pageCount}
                        pageNumber={pageNumber}
                        results={results}
                        onClick={onHandleClick}
                        isLoading={isSearching}
                        onPage={onHandlePage}
                    />
                </div>
            </Callout>
        </React.Fragment>
    );
};
