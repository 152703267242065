import { AxiosInstance, AxiosRequestConfig } from 'axios';

export class RequestFormatInterceptor {

    public bind (networkLayer: AxiosInstance) {
        networkLayer.interceptors.request.use(this.handle);
    }

    public handle = (config: AxiosRequestConfig) => {
        let url = config.url;
        let baseURL = config.baseURL;

        if (!config.params && config.data) {
            config.params = config.data.params;
        }

        (Object.keys(config.params || {}) || []).forEach(p => {
            const regex = new RegExp(`{${p}}`, 'i');
            if (!!url && url.match(regex)) {
                url = url.replace(regex, config.params[p])
                delete config.params[p];
            } else if (!!baseURL && baseURL.match(regex)) {
                baseURL = baseURL.replace(regex, config.params[p])
                delete config.params[p];
            }
        });
        return Promise.resolve({ ...config, url, baseURL });
    }
}
