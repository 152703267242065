import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import * as actions from "../../../actionCreator";
import { GlobalApplicationState } from "globalApplicationState";

import LoadingOverlay from "modules/common/components/loadingOverlay";

import { Document, DocumentProperties } from "../../../models";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";

import Alert from '@mui/material/Alert';

import moment from "moment";


class DocumentSummary extends React.Component<PropsWithRedux, ComponentState> {
  public componentDidMount() {
    moment.locale("en");
  }

  public render() {
    const { document, documentProperties } = this.props;

    if (!document || !documentProperties)
      return <React.Fragment></React.Fragment>;

    return (
      <Dialog
        open={this.props.show}
        maxWidth={false}
        scroll="paper"
        onClose={this.onClose}
        classes={{ paper: "document-summary" }}
      >
        <DialogTitle className="document-summary-title">
          <div>One last check!</div>
          <div>
            <Button variant="text" onClick={this.onClose}>Keep editing</Button>
            <Button variant="contained" color="primary" onClick={this.props.onPublish}>Publish</Button>
          </div>
        </DialogTitle>
        <DialogContent style={{paddingBottom: 6}}>
          <div>
            <div className="document-summary-properties">
              <div>
                <div>File name</div>
                <div>{document.fileName}</div>
              </div>
              {!!documentProperties.title &&
                <div>
                  <div>Title</div>
                  <div>{documentProperties.title}</div>
                </div>
              }
              {!!documentProperties.description &&
                <div>
                  <div>Description</div>
                  <div>{documentProperties.description}</div>
                </div>
              }
              {!!documentProperties.lcid &&
                <div>
                  <div>Language</div>
                  <div>{this.props.lcidMappings[documentProperties.lcid].language}</div>
                </div>
              }
              <div>
                <div>Availability</div>
                <div>
                  {documentProperties.availability === "attachmentOnly"
                    ? "Attachment only"
                    : "Online only"
                  }
                </div>
              </div>
              <div>
                <div>State</div>
                <div>{this.formatState()}</div>
              </div>
              <div>
                <div>Accessible to</div>
                <div>
                  {(!!documentProperties.accessibleAudiences && !!documentProperties.accessibleAudiences.length)
                    ? this.formatAudiences(documentProperties.accessibleAudiences)
                    : "All users"
                  }
                </div>
              </div>
              {!!documentProperties.notifiedAudiences && !!documentProperties.notifiedAudiences.length &&
                <div>
                  <div>Notified audiences</div>
                  <div>{this.formatAudiences(documentProperties.notifiedAudiences)}</div>
                </div>
              }
            </div>
          </div>
        </DialogContent>
        <Snackbar open={!!this.props.errorMessage} anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={6000} onClose={this.props.clearErrorMessage}>
          <Alert variant="filled" severity="error" elevation={6} onClose={this.props.clearErrorMessage}>{this.props.errorMessage}</Alert>
        </Snackbar>
        <LoadingOverlay show={this.props.isSaving} styles={{ paddingTop: 150 }} />
      </Dialog>
    );
  }

  private formatAudiences = (audiences: string[]): string => {
    const matchedAudiences: string[] = audiences.map((accessibleAudience) => {
      const matchedAudience = this.props.audiences.find((audience) => audience.id === accessibleAudience);
      if (!matchedAudience)
        return "";

      return matchedAudience.displayName;
    });

    return matchedAudiences.join(", ");
  }

  private formatState = () => {
    if (!this.props.documentProperties.enabled)
      return "Disabled";
    else if (!this.props.documentProperties.publishingTime || (moment(this.props.documentProperties.publishingTime) <= moment()))
      return "Enabled";
    else
      return `Scheduled for ${moment(this.props.documentProperties.publishingTime).format("MMM D, YYYY")}`;
  }

  private onClose = () => {
    this.props.onClose();
  }
}


interface ComponentProps {
  show: boolean;
  document: Partial<Document>;
  documentProperties: Partial<DocumentProperties>;
  onPublish: () => void;
  onClose: () => void;
}

interface ComponentState {}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    audiences: state.audiences.audiences,
    errorMessage: state.documents.errorMessage,
    isSaving: state.documents.isSaving,
    lcidMappings: state.resources.lcidMappings
  }),
  {
    clearErrorMessage: actions.clearErrorMessage
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(DocumentSummary);