import * as React from "react";
import Modal from "modules/common/components/modal";
// import 'modules/audiences/components/audiences.sass'
import { injectIntl, IntlShape } from "react-intl";
import { Audience } from "modules/audiences/models";
import { audiencesApi, tagsApi } from "api/instances";
import { TenantSettingsSecurityGroups } from "modules/settings";
import SuggestionsManager from "modules/audiences/components/suggestionsManager";

interface ComponentProps {
    show: boolean;
    closeModal: () => void;
    intl: IntlShape;
    userGroups: TenantSettingsSecurityGroups[];
    ADGroupSyncEnabled: boolean;
    tagIsRestricted: boolean;
    id: string;
    canEditTag: boolean;
}

interface ComponentState {
    optIn: boolean;
    activeTab: string;
    userList: {id: string, type: string}[];
    audiences: Audience[];
    fetching: boolean;
    saving: boolean;
}

class TagSubscriptionManagerModal extends React.PureComponent<ComponentProps, ComponentState> {

    constructor(props) {
        super(props);
        this.state = { 
            optIn: true,
            activeTab: "audiences",
            userList: [],
            audiences: [],
            fetching: true,
            saving: false
        }
    }

    public componentDidMount() {
        audiencesApi.getAllAudiences()
        .then(response => {
            this.setState({
                audiences: response,
                fetching: false
            });
        })
    }

    public render() {

        return   (<Modal active={this.props.show}
                    title={this.props.intl.formatMessage({id: "common.manageUserSubscriptions", defaultMessage: 'Manage User Subscriptions'})}
                    showControls={true}
                    onCloseClick={this.handleModalCloseClick}
                    buttons={[
                        { title: "Save", workingTitle: "Saving...", isWorking: this.state.saving, isPrimary: true, isEnabled: !this.state.saving && this.state.userList.length > 0, onClick: this.saveSubscriptions },
                    ]}>
                        <div className="radio-wrapper">
                            <label>Automatically: </label>
                            <input
                                className="input-radio"
                                type="radio"
                                value="in"
                                id="in"
                                onChange={() => this.toggleOptIn(true)}
                                checked={this.state.optIn} />
                                <label htmlFor="in">Subscribe</label>
                            <input
                                className="input-radio"
                                type="radio"
                                value="out"
                                id="out"
                                onChange={() => this.toggleOptIn(false)}
                                checked={!this.state.optIn} />
                                <label htmlFor="out">Unsubscribe</label>
                            
                        </div>
                        <p style={{ textAlign: "left", fontSize: "14px", marginLeft: "15px"}}>{`Automatically ${this.state.optIn? "subscribe" : "unsubscribe"} the selected users.`}</p>
                        {
                            this.state.optIn && this.props.tagIsRestricted?
                            <p style={{ textAlign: "left", fontSize: "14px", marginLeft: "15px"}}>{`Only users that have access to this tag will be subscribed.`}</p>
                            : null
                        }
                        <SuggestionsManager
                            working={this.state.saving || this.state.fetching}
                            ADGroupSyncEnabled={this.props.ADGroupSyncEnabled}
                            canEdit={this.props.canEditTag}
                            currentList={this.state.userList}
                            updateList={this.updateUserList}
                        />
                   
                </Modal>);
    }

    private handleModalCloseClick = () => {
        this.props.closeModal();
    }
    
    private toggleOptIn = (value: boolean) => {
        this.setState({...this.state, optIn: value})
    }

    private updateUserList = (id: string, type: string, add: boolean) => {
        var newUserList;
        if (add) {
            newUserList = [...this.state.userList, {id, type}];
        }
        else {
            newUserList = this.state.userList.filter(ul => ul.id !== id);
        }
        
        this.setState({...this.state, userList: newUserList});
    }

    private saveSubscriptions = () => {
        this.setState({...this.state, saving: true});
        tagsApi.BulkSubscription(this.props.id, this.state.optIn, this.state.userList)
        .then(response => {
            this.setState({
                ...this.state,
                saving: false,
                userList: []
            });
        });
        this.props.closeModal();
    }
}

export default injectIntl(TagSubscriptionManagerModal);