import React from 'react';
import { ConnectedProps, connect } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import ToggleSwitch from 'modules/common/components/toggleSwitch';
import { actions } from '..';

interface ComponentProps {
    title: string;
    eventOne: string;
    eventTwo: string;
    description?: string;
    value?: any;
    handleOnClick?: () => any;
}

const SettingsToggleOnlyDouble: React.FC<PropsWithRedux> = ({title, eventOne, eventTwo, description, value, notificationSettings, toggleChannel, handleOnClick}) => {
    const eitherChecked = notificationSettings.settings[eventOne].toggle || notificationSettings.settings[eventTwo].toggle;
    var toggle = eitherChecked;
    return (

        <div>
            <div className="clearfix">
                <div style={{float:"left", display: "inline-block", width: "1000px", fontWeight: "bold"}}>{title}
                    <span style={{float:"right"}}>
                        <ToggleSwitch
                            value={toggle}
                            onClick={() => updateBothValues(eventOne, eventTwo)}
                        />
                    </span>
                </div>
            </div>
            {toggle && handleOnClick &&
                <div style={{margin: "20px 0px"}} className="description-text">{description} <span className={"pointer-text"} onClick={handleOnClick}>Open More Notification Settings</span></div>
            }
        </div>
    )

    function updateBothValues(eventOne, eventTwo)
    {
        toggle = !toggle;
        if(toggle)
        {
            toggleChannel(eventOne, true);
            toggleChannel(eventTwo, true);
        }
        else
        {
            toggleChannel(eventOne, false);
            toggleChannel(eventTwo, false);
        }
    }
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        notificationSettings: state.settings.notificationSettings,
    }),
    {
        toggleChannel: actions.EnableSelectedChannel
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default (connector(SettingsToggleOnlyDouble));
