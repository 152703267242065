import React from "react";
import { FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { PaginationItem } from "@mui/material";

import "./pagination.sass";

interface IPageCounterProps {
    count: number;
    currIndex: number;
    dropDown: boolean;
    selectLabel?: string;
    onPageChange: (newPage: number) => void;
}

const PageCounter = React.forwardRef<HTMLDivElement, IPageCounterProps>((props, ref) => {
    const onSelectChange = (pageToVisit: number | string) => {
        const num = Number(pageToVisit);
        props.onPageChange(num);
    }

    const getDropDown = (): JSX.Element => {
        return (
            <FormControl size="small" className="pagination-form-control">
                {props.selectLabel && <span className="pagination-select-label">{props.selectLabel}</span>}
                <Select
                    classes={{ select: "pagination-select" }}
                    variant="outlined"
                    value={props.currIndex.toString()}
                    className="dropdown"
                    onChange={(e: SelectChangeEvent) => onSelectChange(e.target.value)}
                    style={{ fontSize: 12 }}
                >
                    {/* this weird looking thing just creates an array so we can loop through the number of pages */}
                    {[...Array(props.count)].map((_, idx: number) => (
                        <MenuItem key={`pagination-select-${idx}`} value={idx}>{idx + 1}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    }

    const renderCounter = (): JSX.Element => {
        let result = <span>{getNonDropDownLabel()}</span>;
        if (props.dropDown)
            result = getDropDown();

        return result;
    }

    const getNonDropDownLabel = (): string => `${props.currIndex + 1} of ${props.count}`;

    return (
        <div ref={ref} className="pagination-item">
            {props.children}
            {props.count === 0
                ? "0 of 0"
                : renderCounter()}
        </div>
    )
});

interface IPaginationProps {
    onPageChange: (newPage: number) => void;
    count: number;
    dropDown?: boolean; // page selector dropdown toggle
    currentPage: number; // 0-based index
    selectLabel?: string; // label before the select drop down
}

const Pagination: React.FunctionComponent<IPaginationProps> = ({
    onPageChange,
    selectLabel,
    currentPage,
    count,
    dropDown = false,
}) => {
    return (
        <div className="pagination">
            <PaginationItem
                onClick={() => onPageChange(0)}
                type="first"
                disabled={currentPage === 0}
                className="pagination-item action" />
            <PaginationItem
                onClick={() => {
                    let newPage = currentPage - 1 > -1 ? currentPage - 1 : 0;
                    onPageChange(newPage);
                }}
                type="previous"
                disabled={currentPage === 0}
                className="pagination-item action"
            />
            <PaginationItem
                selectLabel={selectLabel}
                onPageChange={onPageChange}
                dropDown={dropDown}
                type="page"
                count={count}
                currIndex={currentPage}
                component={PageCounter}
            />
            <PaginationItem
                disabled={currentPage + 1 >= count}
                onClick={() => {
                    let newPage = currentPage + 1 < count ? currentPage + 1 : 0;
                    onPageChange(newPage);
                }}
                type="next"
                className="pagination-item action"
            />
            <PaginationItem
                disabled={currentPage + 1 >= count}
                onClick={() => onPageChange(count - 1)}
                type="last"
                className="pagination-item action"
            />
        </div>
    );
}

export default Pagination;
