import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { push } from "react-router-redux";
import * as actions from "../../actionCreator";
import { GlobalApplicationState } from "globalApplicationState";

import { Audience } from "modules/audiences/models";

import AudiencesList from "./audiencesList";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Chip from "@mui/material/Chip";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";


class NewPortalPageDialog extends React.Component<PropsWithRedux, ComponentState> {
  constructor(props: PropsWithRedux) {
    super(props);

    this.state = {
      anchorEl: null,
      audiences: [],
      isChecking: false,
      pageTitle: "",
      pageTitleIsValid: true,
      pageUrl: "",
      pageUrlIsValid: true,
      urlErrorMessage: "",
      calloutOpen: false,
    };
  }

  componentDidUpdate(prevProps: PropsWithRedux) {
    if (this.props.showNewPortalPage && !prevProps.showNewPortalPage)
      this.setState({
        anchorEl: null,
        audiences: [],
        isChecking: false,
        pageTitle: "",
        pageTitleIsValid: true,
        pageUrl: "",
        pageUrlIsValid: true,
        urlErrorMessage: ""
      });
  }

  public render() {
    return (
      <Dialog open={this.props.showNewPortalPage} maxWidth={false} onClose={this.onClose}>
        <DialogTitle className="portal-pages-dialog-header">
          <div className="portal-pages-dialog-header-title">
            <Typography variant="h2">Create new page</Typography>
            <IconButton onClick={this.onClose} size="large">
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent className="portal-page-dialog-content">
          <div className="portal-page-dialog-content-form">
            <div>
              <div>Page title</div>
              <TextField
                variant="outlined"
                size="small"
                value={this.state.pageTitle}
                autoComplete="off"
                disabled={this.state.isChecking}
                error={!this.state.pageTitleIsValid}
                helperText={!this.state.pageTitleIsValid ? "Cannot contain numbers or special characters" : ""}
                fullWidth
                inputProps={{
                  maxLength: 25
                }}
                onChange={this.onChangePageTitle}
              />
            </div>
            <div>
              <div>Page URL</div>
              <TextField
                variant="outlined"
                size="small"
                value={this.state.pageUrl}
                autoComplete="off"
                disabled={this.state.isChecking}
                error={!this.state.pageUrlIsValid}
                helperText={this.state.urlErrorMessage || ""}
                fullWidth
                onChange={this.onChangePageUrl}
              />
            </div>
            <div>
              <div className="audiences-list-label">Accessible to</div>
              <div>
                {!this.state.audiences.length
                  ? <div className="audiences-list">
                      <Chip
                        label="All users"
                        disabled={true}
                        className="all-users-audience"
                      />
                    </div>
                  : <div className="audiences-list">
                      {this.state.audiences.map((audience) =>
                        <Chip
                          key={audience.id}
                          label={audience.displayName}
                          onDelete={() => this.onRemoveAudience(audience)}
                          deleteIcon={<CloseIcon />}
                        />
                      )}
                    </div>
                }
                <Button
                  variant="text"
                  color="primary"
                  startIcon={<AddIcon />}
                  disabled={this.state.isChecking}
                  onClick={this.onShowAudiencesList}
                >
                  Add audience
                </Button>
                <AudiencesList 
                  anchorEl={this.state.anchorEl} 
                  selectedAudiences={this.state.audiences} 
                  onChange={this.onChangeAudiences} 
                  setCalloutOpen={this.setCalloutOpen} 
                  calloutOpen={this.state.calloutOpen}
                />
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="portal-pages-dialog-footer">
          <Button variant="text" color="primary" onClick={this.onClose}>Cancel</Button>
          <Button
            variant="contained"
            color="primary"
            disabled={this.state.isChecking || !this.state.pageTitle || !this.state.pageTitleIsValid || !this.state.pageUrl || !this.state.pageUrlIsValid}
            onClick={this.onNext}
          >
            Next
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  private onNext = () => {
    this.setState({ isChecking: true });
    this.props.checkIfUrlExists(this.state.pageUrl).then((exists) => {
      this.setState({ isChecking: false });
      if (exists) {
        this.setState({ pageUrlIsValid: false, urlErrorMessage: "URL already exists" });
      } else {
        this.onClose();
        this.props.createNewDraft(this.state.pageTitle.trim(), this.state.pageUrl, this.state.audiences.map(audience => audience.id)).then((draftId) => {
          if (!!draftId)
            this.props.redirectTo("/" + this.props.tenantId + "/admin/portalPages/edit/" + draftId);
        });
      }
    });
  }

  private onChangeAudiences = (audiences: Audience[]) => {
    this.setState({ audiences, anchorEl: null });
  }

  private onChangePageTitle = (event) => {
    const isInvalid = /[^A-Za-z\s]/.test(event.target.value) || event.target.value.toLowerCase() === "home";
    this.setState({ pageTitle: event.target.value, pageTitleIsValid: !isInvalid });
  }

  private onChangePageUrl = (event) => {
    const isInvalid = /[^A-Za-z0-9-]/.test(event.target.value) || event.target.value.toLowerCase() === "home";
    this.setState({ pageUrl: event.target.value, pageUrlIsValid: !isInvalid, urlErrorMessage: isInvalid ? "Cannot contain special characters" : "" });
  }

  private onClose = () => {
    this.props.hideNewPortalPage();
  }
  
  private onRemoveAudience = (audience: Audience) => {
    this.onChangeAudiences(this.state.audiences.filter((selectedAudience) => selectedAudience.id !== audience.id));
  }

  private onShowAudiencesList = (event: any) => {
    this.setState({ anchorEl: event.currentTarget, calloutOpen: true });
  }

  private setCalloutOpen = (toSet: boolean) => {
    this.setState({calloutOpen: toSet});
  }
}


interface ComponentProps {}

interface ComponentState {
  anchorEl: any;
  audiences: Audience[];
  isChecking: boolean;
  pageTitle: string;
  pageTitleIsValid: boolean;
  pageUrl: string;
  pageUrlIsValid: boolean;
  urlErrorMessage: string;
  calloutOpen: boolean;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    showNewPortalPage: state.portalPages.showNewPortalPage,
    tenantId: state.tenant.id
  }),
  {
    checkIfUrlExists: actions.checkIfUrlExists,
    createNewDraft: actions.createNewDraft,
    hideNewPortalPage: actions.hideNewPortalPage,
    redirectTo: push
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(NewPortalPageDialog);