import ConfirmDialog from 'modules/common/components/dialogs/confirmDialog';
import FilledInput from 'modules/common/components/forms/inputs/filledInput';
import * as React from 'react';

interface ICreateNewFolderDialogProps {
    disableFolderCreation: boolean;
    onClose: () => void;
    onConfirm: () => void;
    onDeny: () => void;
    open: boolean;
    value: string;
    onFolderNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    errorMsg: string;
    maxInputCount?: number;
}

export const CreateNewFolderDialog: React.FC<ICreateNewFolderDialogProps> = ({
    disableFolderCreation,
    onClose,
    onConfirm,
    onDeny,
    open,
    value,
    onFolderNameChange,
    errorMsg,
    maxInputCount
}) => {
    return <ConfirmDialog
        title="Create New Folder"
        denyLabel="Cancel"
        confirmLabel="Create"
        denyButtonProps={{sx:{color:'grey'}}}
        confirmButtonProps={{disabled: disableFolderCreation}}
        onClose={onClose}
        onConfirm={onConfirm}
        onDeny={onDeny}
        open={open}
        dialogMaxWidth="sm"
    >
        <FilledInput
            label={"Folder name"}
            required
            value={value}
            characterCount
            maxCount={maxInputCount}
            inputContainerStyle={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}
            inputDivContainerStyle={{width: '60%', display: 'flex'}}
            inputDivStyle={{width: '100%'}}
            outlinedInput
            inputLabelStyle={{marginBottom: 16}}
            controlled
            inputProps={{onChange: onFolderNameChange}}
            errorHelperText={errorMsg}
        />
    </ConfirmDialog>
}