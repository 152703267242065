import React from 'react';
import { connect, ConnectedProps } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router";
import { GlobalApplicationState } from 'globalApplicationState';
import InputField from './inputField';
import { actions } from 'modules/users';

interface ComponentProps { 
}

const UserCompanyDetails: React.FunctionComponent<PropsWithRedux> = ( props ) => {

    return (
        <div>
            <div className="user-input-section-title">Company Details</div>
            <div className="profile-input-rows">
                <div className="input-row">
                    <InputField
                        inputType={"text"}
                        title={"Employee ID"}
                        onChange={props.updateEmployeeID}
                    />
                </div>                
                <div className="input-row">
                    <InputField
                        inputType={"text"}
                        title={"Title/Role"}
                        onChange={props.updateEmployeeTitle}
                    />
                </div>   
                <div className="input-row">
                    <InputField
                        inputType={"text"}
                        title={"Department"}
                        onChange={props.updateEmployeeDepartment}
                    />
                </div> 
                <div className="input-row">
                    <InputField
                        inputType={"text"}
                        title={"Region"}
                        onChange={props.updateEmployeeRegion}
                    />
                </div> 
                <div className="input-row">
                    <InputField
                        inputType={"text"}
                        title={"Country"}
                        onChange={props.updateEmployeeCountry}
                    />
                </div>              
            </div>
        </div>
    )
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps & RouteComponentProps) => ({
        ...ownProps
    }), 
    {
        updateEmployeeID: actions.handleUserCreationEmployeeId,
        updateEmployeeTitle: actions.handleUserCreationTitle,
        updateEmployeeDepartment: actions.handleUserCreationDepartment,
        updateEmployeeRegion: actions.handleUserCreationRegion,
        updateEmployeeCountry: actions.handleUserCreationCountry,
    }
);

type PropsWithRedux = ConnectedProps<typeof connector>;

export default withRouter(connector(UserCompanyDetails));