import * as React from "react";

import { EventType as IEventType } from "../../../models";

import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";


class EventType extends React.Component<ComponentProps, ComponentState> {
  public render() {
    return (
      <div className="type">
        <div className="section-card-heading">
          <div>Event Type</div>
          <FormControl size="small">
            <Select
              variant="outlined"
              value={this.props.eventType}
              onChange={this.onChangeEventType}
              className="type-select"
            >
              <MenuItem value="informational">Informational</MenuItem>
              <MenuItem value="standard">Standard RSVP</MenuItem>
              <MenuItem value="mandatory">Mandatory RSVP</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
    );
  }

  private onChangeEventType = (event) => {
    this.props.onChange(event.target.value);
  }
}


interface ComponentProps {
  eventType: IEventType | undefined;
  onChange: (eventType: string) => void;
}

interface ComponentState {}

export default EventType;