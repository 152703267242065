import * as React from "react";

import Chip from "@mui/material/Chip";


import "./chips.sass";


interface CategoryTag {
  id: string;
  enabled?: boolean;
  name: string;
}

const CategoryTagChipLabels: React.FunctionComponent<ComponentProps> = props => {
  const { tags } = props;

  if (!tags || !tags.length)
    return (
      <div className="category-tag-chip-labels">
        <Chip
          label="Uncategorized"
          disabled={true}
          className="uncategorized-tag"
        />
      </div>
    );

  return (
    <div title={tags.map((tag) => tag.name).join(", ")} className="category-tag-chip-labels">
      {tags.slice(0,2).map((tag) => (
        <Chip
          key={tag.id}
          label={tag.name}
          disabled={!tag.enabled}
        />
      ))}
      {tags.length > 2 &&
        (tags.length === 3
          ? <Chip
              key={tags[2].id}
              label={tags[2].name}
              disabled={!tags[2].enabled}
            />
          : <Chip
              key="more"
              label={`+${tags.length - 2}`}
            />
        )
      }
    </div>
  );
}

interface ComponentProps {
  tags: CategoryTag[];
}

export default CategoryTagChipLabels;