import axios from "axios";
import { AuthInterceptor } from "../interceptors/AuthInterceptor";
import { RequestFormatInterceptor } from "../interceptors/RequestFormatInterceptor";
import { RequestPlatformInterceptor } from "../interceptors/RequestPlatformInterceptor";
import { TenantAutoFillInterceptor } from "../interceptors/TenantAutoFillInterceptor";
import { store } from "configureStore";

export class SparrowApi {
    constructor(
        private _authInterceptor: AuthInterceptor,
        private _formatInterceptor: RequestFormatInterceptor,
        private _platformInterceptor: RequestPlatformInterceptor,
        private _tenantAutoFillInterceptor: TenantAutoFillInterceptor
    ) {}

    public CreateRequest() {
        const instance = axios.create();
        instance.defaults.baseURL = store.getState().config.SparrowClientApiUrl;
        this._authInterceptor.bind(instance);
        this._formatInterceptor.bind(instance);
        this._platformInterceptor.bind(instance);
        return instance;
    }

    public CreateAnonymousRequest() {
        const instance = axios.create();
        instance.defaults.baseURL = store.getState().config.SparrowClientApiUrl;
        this._formatInterceptor.bind(instance);
        this._platformInterceptor.bind(instance);
        return instance;
    }

    public TenantRequest() {
        let instance = this.CreateRequest();
        this._tenantAutoFillInterceptor.bind(instance);
        this._platformInterceptor.bind(instance);
        return instance;
    }
}
