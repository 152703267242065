import * as React from "react";


class Video extends React.Component<ComponentProps, ComponentState> {
  private player;
  private videoRef: HTMLVideoElement;

  constructor(props: ComponentProps) {
    super(props);

    this.state = {
      isPlaying: false
    };
  }

  private getSourceType(src: string, fileName?: string): string | null {
    if (src.toLowerCase().includes("/manifest(format=m3u8-aapl"))
      return "application/x-mpegURL";
    
    if (fileName) {
      const standardizedText = fileName.toLowerCase();
      if (standardizedText.includes(".mp4"))
        return "video/mp4";
      if (standardizedText.includes(".ogg"))
        return "video/ogg";
      if (standardizedText.includes(".webm"))
        return "video/webm";
    }
    return null;
  }

  private initialize(src: string, fileName?: string) {
    if (this.videoRef) {
      const type = this.getSourceType(src, fileName);

      const options = {
        ...this.props,
        html5: {
          nativeVideoTracks: false,
          nativeAudioTracks: false
        },
        sources: src
          ? [{ src: `${src.replace("http://","https://")}#t=0.1`, type: type }]
          : null
      };

      this.player = (window as any).videojs(this.videoRef, options);
      this.player
        .ready(() => {
          this.player.src(src);
        })
        .on("ended", () => {
          this.player.pause().currentTime(0).trigger("loadstart");
        })
        .on("loadeddata", () => {
            if (this.player.paused()) this.player.bigPlayButton.el_.style.display = "block";
        })
        .on("pause", () => {
          this.player.bigPlayButton.el_.style.display = "block";
        })
        .on("play", () => {
          this.player.bigPlayButton.el_.style.display = "none";
        });
    } else {
      this.forceUpdate();
    }
  }

  public componentDidMount() {
    this.initialize(this.props.src, this.props.fileName);
  }

  public componentDidUpdate(prevProps: ComponentProps) {
    if (!!this.props.pauseVideo && (this.props.pauseVideo !== prevProps.pauseVideo))
      this.pauseVideo();
  }

  public componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  public render() {
    const { style, className } = this.props;
    const { isPlaying } = this.state;

    return (
      <div style={{ ...style, zIndex: isPlaying ? 1 : 0 }} className={className}>
        <div data-vjs-player>
          <video
            ref={ node => this.videoRef = node! }
            controls
            data-setup="{'fluid': true}"
            crossOrigin="anonymous"
            preload="metadata"
            onPlay={() => this.setIsPlaying(true)}
            onPause={() => this.setIsPlaying(false)}
            className="video-js vjs-default-skin post-video-primary vjs-16-9 vjs-big-play-centered">
          </video>
        </div>
      </div>
    );
  }

  private setIsPlaying = (isPlaying: boolean) => this.setState({ isPlaying });
  private pauseVideo = () => this.player.pause();
}

interface ComponentProps {
  src: string;
  fileName?: string;
  pauseVideo: boolean;

  style?: React.CSSProperties;
  className?: string;
}

interface ComponentState {
  isPlaying: boolean;
}

export default Video;