import * as React from 'react';
import ThreeDots from "modules/common/components/three-dots";
import numeral from 'numeral';

interface HighlightedNumberProps {
    value: number|string;
    text: string;
    badIfBelow: number;
    goodIfAbove: number;
    loading?: boolean;
    decimalFormat?: boolean;
}

export default class HighlightedNumber extends React.Component<HighlightedNumberProps, {}> {

    public render() {
        return (
        <div className={'hl-stat hl-stat' + this.marker() + (this.props.loading ? ' hl-stat-loading' : '')}>
            <div className={'hl-stat-circle'}>
                { this.props.loading ? <ThreeDots width={30}/> : this.formattedNumber(this.props.value) }
            </div>
            <div className="hl-stat-circle-footer">
                {this.props.text}
            </div>
        </div>)
    }

    private marker = () => {
        let marker = '-neutral';
        if (typeof this.props.value === 'number' && this.props.value < this.props.badIfBelow) {
            marker = '-bad';
        } else if (typeof this.props.value === 'number' && this.props.value > this.props.goodIfAbove) {
            marker = '-good';
        }
        return marker;
    }

    private formattedNumber = (value): string => {
        if(typeof(value) !== 'number') return "*";
        return numeral(value).format(this.props.decimalFormat ? '0.0a' : '0a');
    }
}
