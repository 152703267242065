import {max, select, scaleLinear, event} from 'd3';
const d3 = {max, select, scaleLinear, event};

export interface HBarChartProps {
    el: any;
    barHeight: number;
    data: HBarChartDataPoint[];
    labelWidth: number;
    color?: (d:HBarChartDataPoint) => string;
    locale?: string;
}

export interface HBarChartDataPoint {
    id: string;
    label: string;
    value: number;
    valueLabel?: string;
    onClick?: (point: HBarChartDataPoint) => any;
}

export class HorizontalBarChart {

    constructor(props: HBarChartProps) {
        this.update(props);
    }

    public update(props: HBarChartProps) {
        if (!props.el) return;

        let boundingRect = props.el.getBoundingClientRect();
        let x = d3.scaleLinear()
            .domain([0, d3.max(props.data.map(d => d.value)!)!])
            .range([0, boundingRect.width - props.labelWidth - 40]);

        d3.select(props.el)
            .select('svg').remove();

        const containerHeight = props.data.length * props.barHeight;
        const containerWidth = boundingRect.width;
        let chart = d3.select(props.el)
            .append('svg')
            .attr('height', containerHeight)
            .attr('width', containerWidth)
            .attr('class', 'basic-chart')

        let bar = chart
            .selectAll('*')
            .remove()
            .data(props.data)
            .enter()
            .append('g')
            .attr('class', (d, i) => {
                let classNames = '';
                if (d.onClick) {
                    classNames += 'has-action';
                }

                const barWidth = x(d.value);

                if (barWidth === 0) {
                    classNames += ' is-zero-width';
                }

                return classNames;
            })
            .attr('transform', function (d, i) {
                return 'translate(0,' + i * props.barHeight + ')';
            })
            .on('click', (d) => {
                if (d.onClick) {
                    d.onClick(d);
                }
               d3.event?.stopPropagation();
            });

        bar.append('text')
            .attr('class', 'label')
            .attr('x', 0)
            .attr('y', props.barHeight / 2)
            .attr('dy', '.35em')
            .text(function (d) {
                return d.label;
            })
            .each(function() {
                let self = d3.select(this),
                padding = 5,
                textLength = self.node()!.getComputedTextLength(),
                text = self.text();
                while (textLength > (props.labelWidth - 2 * padding) && text.length > 0) {
                    text = text.slice(0, -1);
                    self.text(text + '...');
                    textLength = self.node()!.getComputedTextLength();
                }
            })

        let rect = bar.append('rect')
            .attr('class', 'bar')
            .attr('width', (d) => x(d.value))
            .attr('height', props.barHeight - 1)
            .attr('transform', 'translate(' + props.labelWidth + ', 0)');

        if(!!props.color)
            rect.attr('fill',props.color);

        bar.append('text')
            .attr('class', 'value')
            .attr('x', (d) => {
                return x(d.value) + props.labelWidth + 5;
            })
            .attr('y', props.barHeight / 2)
            .attr('dy', '.35em')
            .text(function (d) {
                return d.valueLabel ? ' ' + d.valueLabel : d.value;
            });
    }

    public destroy() {
        // Any clean-up would go here
        // in this example there is nothing to do
    }
}

