import * as React from 'react';
import { Button } from "@mui/material";
import FileUploadIcon from '@mui/icons-material/FileUpload';

interface IUploadMediaProps {
    onFileUpload(files: FileList): void;
};

const UploadMediaButton: React.FunctionComponent<IUploadMediaProps> = (props) => {
    const onFileInputChanged = (e: React.FormEvent<HTMLInputElement>): void => {
        const files = (e.target as HTMLInputElement).files;

        props.onFileUpload(files!);
    };

    return(
        <>
            <Button
                variant="contained"
                color="primary"
                startIcon={<FileUploadIcon />}
                component="label"
            >
                Upload
                <input
                    accept="image/png,image/jpg,image/jpeg"
                    type="file"
                    multiple
                    hidden
                    onChange={onFileInputChanged}
                />
            </Button>
        </>
    );
}

export default UploadMediaButton;