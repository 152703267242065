import React from 'react';
import { connect, ConnectedProps } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router";
import { GlobalApplicationState } from 'globalApplicationState';
import InputField from './inputField';
import { actions } from 'modules/users';

interface ComponentProps { 
}

const UserNotificationContacts: React.FunctionComponent<PropsWithRedux> = ( props ) => {

    return (
        <div>
            <div className="user-input-section-title">Contact Details for Alerts, Notifications and Updates</div>
            <div className="profile-input-rows">
                <div className="input-row">
                    <InputField
                        inputType={"text"}
                        title={"Preferred Email"}
                        onChange={props.updateUserEmail}
                    />
                </div>                
                <div className="input-row">
                    <InputField
                        inputType={"number"}
                        title={"SMS mobile no."}
                        onChange={props.updatePreferredSMSPhone}
                        placeholder={"Include country and area code, numbers only"}
                    />
                </div>                
            </div>
        </div>
    )
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps & RouteComponentProps) => ({
        ...ownProps,
        creatingUser: state.users.creatingUser,
    }), 
    {
        updateUserEmail: actions.handleUserCreationEmail,
        updatePreferredSMSPhone: actions.handleUserCreationPreferredSMSPhone,
    }
);

type PropsWithRedux = ConnectedProps<typeof connector>;

export default withRouter(connector(UserNotificationContacts));