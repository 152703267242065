import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import TextField from "@mui/material/TextField";

import SearchIcon from "@mui/icons-material/Search";

class AuthoringFilter extends React.Component<PropsWithRedux, ComponentState> {
  public render() {
    return (
      <React.Fragment>
        <TextField
          variant="outlined"
          size="small"
          value={this.props.textToSearch}
          placeholder={this.props.searchTextPlaceholder || "Search title, key words or content"}
          InputProps={{
            startAdornment: <SearchIcon className="search-icon" />
          }}
          onChange={this.props.onUpdateTextToSearch}
          onKeyUp={this.onKeyUp}
          className="text-to-search"
        />
        {this.props.additionalFilters}
      </React.Fragment>
    );
  }

  private onKeyUp = (key) => {
    if (key.keyCode === 13)
      this.props.onChangeTextToSearch();
  }
}
  

interface ComponentProps {
  lcidToSearch: string;
  textToSearch: string;
  additionalFilters?: JSX.Element;
  searchTextPlaceholder?: string;
  onChangeTextToSearch: () => void;
  onUpdateLcidToSearch: (event) => void;
  onUpdateTextToSearch: (event) => void;
}

interface ComponentState {}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    lcidMappings: state.resources.lcidMappings,
    tenantSettings: state.settings.tenantSettings
  })
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(AuthoringFilter);