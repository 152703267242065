import * as React from 'react';

const RemoteFocusRequestEventName = 'remoteFocusRequestEvent';
const registeredListeners: listeners = {};

/**
 * returning true from the event handler will indicate that the event
 * has been handled and other handlers will not receieve the event.
 */
type requestCallback = (e: CustomEvent) => boolean
interface listeners {
    [fieldName: string]: requestCallback[]
}

export const dispatchRemoteFocusRequestEvent = (fieldName: string) => {
    window.dispatchEvent(new CustomEvent(RemoteFocusRequestEventName, { detail: fieldName }));
}

export class Component extends React.Component<{}, {}> {
    public componentDidMount() {
        window.addEventListener(RemoteFocusRequestEventName, callbackListener)
    }
    public componentWillUnmount() {
        window.removeEventListener(RemoteFocusRequestEventName, callbackListener)
    }
    public render() {
        return <div></div>
    }
}

export const RegisterListener = (fieldName: string, callback: requestCallback) => {
    if (!registeredListeners[fieldName])
        registeredListeners[fieldName] = [];

    registeredListeners[fieldName].push(callback);
}

export const DeregisterListener = (fieldName: string, callback: requestCallback) => {
    const availableListeners = registeredListeners[fieldName].filter(l => l !== callback);
    if (availableListeners.length === 0) // that was the last listener, remove the key
        delete registeredListeners[fieldName]
}

const callbackListener = (e: CustomEvent) => {
    const listeners = registeredListeners[e.detail];
    if (listeners) {
        listeners.forEach(l => {
            if (l(e)) {
                return;
            }
        });
    }
}