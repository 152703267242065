import { Action } from "redux";
import * as Actions from "./actions";
import {SurveysState, Survey} from './models';

const defaultState: SurveysState = {
  surveyList: [],
  isFetching: false,
  isDeleting: false,
  isSaving: false
};

const actionHandler: { [actionType: string]: (state: SurveysState, action: any) => SurveysState } = {

    [Actions.GET_SURVEYS]: (state: SurveysState, action: Actions.GetSurveys) => {
      return {...state, isFetching: true};
    },

    [Actions.GET_SURVEYS_COMPLETE]: (state: SurveysState, action: Actions.GetSurveysComplete) => {
      return {
        ...state,
        isFetching: false,
        surveyList: [...state.surveyList, ...(action.succeeded && !!action.surveys ? action.surveys : ([] as Survey[])) ]
      } as SurveysState;
    },


    [Actions.GET_SURVEY]: (state: SurveysState, action: Actions.GetSurveys) => {
      return {...state, isFetching: true};
    },

    [Actions.GET_SURVEY_COMPLETE]: (state: SurveysState, action: Actions.GetSurveysComplete) => {
      return { ...state,isFetching: false };
    },


    [Actions.DELETE_SURVEYS]: (state: SurveysState, action: Actions.DeleteSurveys) => {
      return { ...state, isDeleting: true };
    },

    [Actions.DELETE_SURVEYS_COMPLETE]: (state: SurveysState, action: Actions.DeleteSurveysComplete) => {
      const surveyList = action.succeeded && !!action.deletedSurveys
        ? state.surveyList.filter(s => action.deletedSurveys!.indexOf(s.id) < 0) 
        : state.surveyList;
      return {
        ...state,
        isDeleting: false,
        surveyList
      }
    },


    [Actions.SAVE_SURVEY]: (state: SurveysState, action: Actions.SaveSurvey) => {
      return {
        ...state,
        isSaving: true
      };
    },

    [Actions.SAVE_SURVEY_COMPLETE]: (state: SurveysState, action: Actions.SaveSurveyComplete) => {
      return {
        ...state,
        isSaving: false
      }
    },

    
    [Actions.CLEAR_SURVEY_LIST]: (state: SurveysState, action: Actions.ClearSurveyList) => {
      return {
        ...state,
        surveyList: []
      }
    }
}

export const reducer = (state: SurveysState, action: Action) => {
    const actionHandlerMethod = actionHandler[action.type];
    if (actionHandlerMethod) {
        return actionHandlerMethod(state, action);
    }
    return state || defaultState;
};
