import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import * as actions from "../../../actionCreator";

import Loading from "modules/common/components/loading";

import { DailyEmailEventStats, EmailEventStats, getEmptyEmailEventStats, NewsletterDetails, WeeklyEmailEventStats } from "../../../models";

import Button from "@mui/material/Button";
import { ButtonGroup } from "@mui/material";

import moment from "moment";
import numeral from 'numeral';
import _ from 'lodash';

import DailyEmailEventsWidget from './dailyEmailEventsWidget';
import WeeklyEmailEventsWidget from './weeklyEmailEventsWidget';
import { InsightsInfoHover } from "./insightsInfoHover";

class NewsletterInsightsTab extends React.Component<PropsWithRedux, ComponentState> {
  constructor(props: PropsWithRedux) {
    super(props);
    this.state = {isLoadingDailyStats: false, totalStatsTimeFrame: 'eightWeeks', graphMode: 'weekly'};
  }

  public componentDidMount(){
    this.props.getTotalNewsletterInsights(this.props.newsletter.id).then((stats) => {
      this.setState({totalStatsAllTime: stats || {}});
    });

    this.props.GetWeeklyNewsletterInsights(this.props.newsletter.id).then((weeklyStats) => {
      const totalStatsEightWeeks = getEmptyEmailEventStats();
      var eightWeeksAgo = moment().add(-9,'week');
      weeklyStats.filter(s=>moment(s.WeekStarting).isAfter(eightWeeksAgo)).forEach(stat=>{
        totalStatsEightWeeks.Bounced += stat.Bounced;
        totalStatsEightWeeks.Clicked += stat.Clicked;
        totalStatsEightWeeks.Deferred += stat.Deferred;
        totalStatsEightWeeks.Delivered += stat.Delivered;
        totalStatsEightWeeks.Dropped += stat.Dropped;
        totalStatsEightWeeks.Opened += stat.Opened;
        totalStatsEightWeeks.Processed += stat.Processed;
        totalStatsEightWeeks.SpamReported += stat.SpamReported;
        totalStatsEightWeeks.Unsubscribed += stat.Unsubscribed;
        totalStatsEightWeeks.UniqueBounced += stat.UniqueBounced;
        totalStatsEightWeeks.UniqueClicked += stat.UniqueClicked;
        totalStatsEightWeeks.UniqueDeferred += stat.UniqueDeferred;
        totalStatsEightWeeks.UniqueDelivered += stat.UniqueDelivered;
        totalStatsEightWeeks.UniqueDropped += stat.UniqueDropped;
        totalStatsEightWeeks.UniqueOpened += stat.UniqueOpened;
        totalStatsEightWeeks.UniqueProcessed += stat.UniqueProcessed;
        totalStatsEightWeeks.UniqueSpamReported += stat.UniqueSpamReported;
        totalStatsEightWeeks.UniqueUnsubscribed += stat.UniqueUnsubscribed;
      });
      this.setState({totalStatsEightWeeks: totalStatsEightWeeks});

      if(weeklyStats.length > 0)
      {
        const currentWeek = moment(_.min(_.map(weeklyStats, w=>w.WeekStarting))).add(1,'weeks');
        const finalWeek = moment(_.max(_.map(weeklyStats, w=>w.WeekStarting)));
        while(currentWeek.isBefore(finalWeek)){
          if(!_.some(weeklyStats,s=>moment(s.WeekStarting).isSame(currentWeek))){
            weeklyStats.push({WeekStarting:currentWeek.toISOString(), ...getEmptyEmailEventStats()});
          }
          currentWeek.add(1,'weeks');
        }
      }

      this.setState({weeklyStats: weeklyStats});
    });
  }

  private viewDailyStats() {
    this.setState({graphMode: 'daily'});

    if(!this.state.dailyStats && !this.state.isLoadingDailyStats)
    {
      this.setState({isLoadingDailyStats: true});

      this.props.GetDailyNewsletterInsights(this.props.newsletter.id)
      .then(dailyStats => {
        if(dailyStats.length > 0)
        {
          const currentDay = moment(_.min(_.map(dailyStats, w=>w.Day))).add(1,'days');;
          const finalDay = moment(_.max(_.map(dailyStats, w=>w.Day)));
          while(currentDay.isBefore(finalDay)){
            if(!_.some(dailyStats,s=>moment(s.Day).isSame(currentDay))){
              dailyStats.push({Day:currentDay.toISOString(), ...getEmptyEmailEventStats()});
            }
            currentDay.add(1,'days');
          }
        }
        this.setState({isLoadingDailyStats: false, dailyStats: dailyStats});
      });
    }
  }

  public render() {
    const { newsletter } = this.props;

    if (!newsletter)
      return <React.Fragment><Loading/></React.Fragment>;

    const totalStats = this.state.totalStatsTimeFrame === 'all' ? this.state.totalStatsAllTime : this.state.totalStatsEightWeeks;
    const totalCopiesSent = !!totalStats ? (totalStats.Processed + totalStats.Dropped) : 0;

    return (
      <div className="newsletter-insights">
        <div className="newsletter-insights-header">
          <div className="header">
            <span>Overall Newsletter Insights</span>
            <InsightsInfoHover />
          </div>
        </div>
        <ButtonGroup variant="outlined">
          <Button onClick={()=>this.setState({totalStatsTimeFrame: 'eightWeeks'})} variant={this.state.totalStatsTimeFrame === 'eightWeeks' ? 'contained' : 'outlined'} color='primary'>Past 8 Weeks</Button>
          <Button onClick={()=>this.setState({totalStatsTimeFrame: 'all'})} variant={this.state.totalStatsTimeFrame === 'all' ? 'contained' : 'outlined'} color='primary'>All Time</Button>
        </ButtonGroup>
        {!totalStats && <Loading/>}
        {!!totalStats && <div className='primary-stats stats-row'>
          <div className='number-wrapper'>
            <div className='number'>
              {numeral(totalCopiesSent).format('0,0')}
            </div>
            <span className='title'>
              Copies Sent
            </span>
          </div>
          <div className='number-wrapper'>
            <div className='number'>
              {numeral(totalCopiesSent === 0 ? 0 : (totalStats.Delivered/totalCopiesSent)).format('0%')}
            </div>
            <span className='title'>
              Delivered
            </span>
            <span className='subtitle'>
              {numeral(totalStats.Delivered).format('0,0')} of {numeral(totalCopiesSent).format('0,0')} copies
            </span>
          </div>
          <div className='number-wrapper'>
            <div className='number'>
              {numeral(totalStats.Delivered === 0 ? 0 : (totalStats.UniqueOpened/totalStats.Delivered)).format('0%')}
            </div>
            <span className='title'>
              Unique Opens
            </span>
            <span className='subtitle'>
              {numeral(totalStats.UniqueOpened).format('0,0')} of {numeral(totalStats.Delivered).format('0,0')} delivered copies
            </span>
          </div>
          <div className='number-wrapper'>
            <div className='number'>
              {numeral(totalStats.Delivered === 0 ? 0 : (totalStats.UniqueClicked/totalStats.Delivered)).format('0%')}
            </div>
            <span className='title'>
              Unique Clicks
            </span>
            <span className='subtitle'>
              {numeral(totalStats.UniqueClicked).format('0,0')} of {numeral(totalStats.Delivered).format('0,0')} delivered copies
            </span>
          </div>
        </div>}
        {!!totalStats &&
          <div className='secondary-stats stats-row'>
            <div className='number-wrapper'>
              <div className='number'>
                {numeral(totalStats.Opened).format('0,0')}
              </div>
              <span className='title'>
                Total Opens
              </span>
            </div>
            <div className='number-wrapper'>
              <div className='number'>
                {numeral(totalStats.UniqueClicked).format('0,0')}
              </div>
              <span className='title'>
                Unique Clicks
              </span>
            </div>
            <div className='number-wrapper'>
              <div className='number'>
                {numeral(totalStats.UniqueBounced).format('0,0')}
              </div>
              <span className='title'>
                Invalid Emails
              </span>
            </div>
            <div className='number-wrapper'>
              <div className='number'>
                {numeral(totalStats.UniqueUnsubscribed).format('0,0')}
              </div>
              <span className='title'>
                Unsubscribes
              </span>
            </div>
            <div className='number-wrapper'>
              <div className='number'>
                {numeral(totalStats.UniqueSpamReported).format('0,0')}
              </div>
              <span className='title'>
                Spam Reports
              </span>
            </div>
            <div className='number-wrapper'>
              <div className='number'>
                {numeral(totalStats.UniqueDropped).format('0,0')}
              </div>
              <span className='title'>
                Drops
              </span>
            </div>
          </div>
        }

        <ButtonGroup variant="outlined">
          <Button onClick={()=>this.viewDailyStats()} variant={this.state.graphMode === 'daily' ? 'contained' : 'outlined'} color='primary'>Daily</Button>
          <Button onClick={()=>this.setState({graphMode: 'weekly'})} variant={this.state.graphMode === 'weekly' ? 'contained' : 'outlined'} color='primary'>Weekly</Button>
        </ButtonGroup>

        {this.state.graphMode === 'daily' && <DailyEmailEventsWidget events={this.state.dailyStats} loading={this.state.isLoadingDailyStats} />}
        {this.state.graphMode === 'weekly' && <WeeklyEmailEventsWidget events={this.state.weeklyStats} loading={!this.state.weeklyStats} />}

      </div>
    );
  }
}

interface ComponentState {
  totalStatsTimeFrame: 'all' | 'eightWeeks';
  graphMode: 'daily' | 'weekly';

  totalStatsAllTime?: EmailEventStats;
  totalStatsEightWeeks?: EmailEventStats;
  weeklyStats?: WeeklyEmailEventStats[];

  isLoadingDailyStats: boolean;
  dailyStats?: DailyEmailEventStats[];
}

interface ComponentProps {
  newsletter: NewsletterDetails;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => (ownProps),
  {
    getTotalNewsletterInsights: actions.getTotalNewsletterInsights,
    GetDailyNewsletterInsights: actions.GetDailyNewsletterInsights,
    GetWeeklyNewsletterInsights: actions.GetWeeklyNewsletterInsights
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(NewsletterInsightsTab);