import * as React from "react";
import UploadIcon from "./uploadIconSvg";

import IconButton from "@mui/material/IconButton";

import ClearIcon from "@mui/icons-material/Clear";

import "./uploader.sass";
import { NewsletterTemplateImageType } from "modules/newsletters";


interface ComponentProps {
  imageSrc?: File | null;
  imageType: NewsletterTemplateImageType;
  setImage: (imageType: string, image?: File) => void;
  imageHeight: number;
  imageWidth: number;
  selectedFile?: File;
  onError: (message:string) => any;
}

interface ComponentState {
  errors: string[];
  dragging: boolean;
  imageSrc: string;
}

class Uploader extends React.Component<ComponentProps, ComponentState> {
  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      dragging: false,
      imageSrc: !!props.imageSrc ? URL.createObjectURL(props.imageSrc) : ""
    };
  }

  public componentDidMount() {
    if (!this.state.imageSrc && !!this.props.imageSrc) {
      this.setState({ imageSrc: URL.createObjectURL(this.props.imageSrc) });
    }
  }

  public componentDidUpdate(prevProps: ComponentProps) {
    if (!!this.props.imageSrc && this.props.imageSrc !== prevProps.imageSrc) {
      this.setState({ imageSrc: URL.createObjectURL(this.props.imageSrc) });
    }
  }

  public render() {
    if (!this.state.imageSrc)
      return this.renderUploadForm();
    else
      return (
        <div className="newsletter-uploader-preview">
          <IconButton size="small" onClick={this.clearImage}>
            <ClearIcon />
          </IconButton>
          <img src={this.state.imageSrc} onLoad={this.onImageLoad} alt="preview" />
        </div>
      );
  }

  private renderUploadForm() {
    let draggingProps = {
      onDrag: this.dragging(true),
      onDragStart: this.dragging(true),
      onDragEnd: this.dragging(false),
      onDragOver: this.dragging(true),
      onDragEnter: this.dragging(true),
      onDragLeave: this.dragging(false),
      onDrop: this.onFileDropped,
    };

    return (
      <div className="newsletter-uploader" {...draggingProps}>
        <div className="centered-content">
          <UploadIcon color="#ccc" width="35px" height="35px" />
          <input
            className="original-input"
            id={"choose-file-" + this.props.imageType}
            multiple
            name={"choose-file-" + this.props.imageType}
            type="file"
            accept={this.acceptedFiles()}
            onChange={this.onFileInputChanged}
          />
          <p className="medium">
            <label className="choose-file-button" htmlFor={"choose-file-" + this.props.imageType}>
              Choose or drag {this.props.imageType.toLowerCase()} image here <br />({this.props.imageWidth}px wide x {this.props.imageHeight}px tall)
            </label>
          </p>
        </div>
      </div>
    );
  }

  private dragging = (isDragging: boolean, callback?: () => void) => (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (typeof callback !== "function") callback = () => { };

    this.setState(
      {
        ...this.state,
        dragging: isDragging,
      },
      callback
    );
    return false;
  };

  private onFileDropped = (e: React.DragEvent<HTMLDivElement>) => {
    const files = e.dataTransfer.files;
    if (files) {
      this.dragging(false, () => {
        this.setImage(files);
      })(e);
    }
  };

  private onFileInputChanged = (e: React.FormEvent<HTMLInputElement>) => {
    const files = (e.target as HTMLInputElement).files;
    if (files) {
      var acceptedFiles = this.acceptedFiles();
      if(acceptedFiles.indexOf(files[0].type) !== -1) {
        this.setImage(files);
      }
      else {
        this.setError(`The ${this.props.imageType.toLowerCase()} image should be a proper image file type`);
        this.setState({ imageSrc: '' });
      }
    }
  };

  private setImage = (files: FileList) => {
    if (files) {
      this.props.setImage(this.props.imageType, files[0]);
      this.setState({ imageSrc: URL.createObjectURL(files[0]) });
    }
  }

  private onImageLoad = ({ target: img }) => {
    if (img.naturalHeight !== this.props.imageHeight || img.naturalWidth !== this.props.imageWidth) {
      this.setError(`The ${this.props.imageType.toLowerCase()} image should be ${this.props.imageWidth}px wide x ${this.props.imageHeight}px tall`);
      this.setState({ imageSrc: '' });
      this.props.setImage(this.props.imageType, undefined);
    }
  }

  private clearImage = (e) => {
    this.setState({ imageSrc: '' });
    this.props.setImage(this.props.imageType, undefined);
  }

  private setError(error: string) {
    this.props.onError(error);
  }

  private acceptedFiles = () => {
    return "image/jpg,image/jpeg,image/png,image/bmp";
  };
}

export default Uploader;