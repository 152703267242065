import * as React from "react";
import * as galleryActions from "modules/gallery/actionCreator";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";

import LoadingOverlay from "modules/common/components/loadingOverlay";
import { Icons } from "modules/common/icons";

import { AttachedFile } from "modules/gallery/models";
import { CroppableImage } from "../models";

import Video from "../video/video";
import ImageCropper from "../dialogs/imageCropper";
import ImageGalleryDialog from "../dialogs/imageGalleryDialog";

import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import Fab from "@mui/material/Fab";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";

import AddIcon from "@mui/icons-material/Add";
import BlockIcon from "@mui/icons-material/Block";
import ClearIcon from "@mui/icons-material/Clear";
import CropIcon from "@mui/icons-material/Crop";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import CanvasPreviewImage from "../../canvasPreviewImage";
import { CONTENT_MODAL_WIDTH, HERO_IMG_HEIGHT } from "utils/generalConstants";
import PortraitImage from "../../paddedImage";


class HeroBanner extends React.Component<PropsWithRedux, ComponentState> {
    constructor(props: PropsWithRedux) {
        super(props);

        this.state = {
            includeVideos: false,
            multiSelect: false,
            pauseVideo: false,
            selectedCarouselIndex: 0,
            selectedImage: undefined,
            showImageCropper: false,
            showImageGallery: false
        };
    }

    public render() {
        const hasCarousel: boolean = !!(this.props.attachedContent || []).length;
        const carouselLength: number = hasCarousel ? this.props.attachedContent!.length : 0;

        const { selectedCarouselIndex } = this.state;

        return (
            <Collapse in={this.props.show}>
                <Paper elevation={0} className="section-card">
                    <div className="optional-content-header">
                        <div>Hero Banner</div>
                        {!!this.props.optional &&
                            <div className="remove-optional-content">
                                <Button variant="text" color="inherit" startIcon={<BlockIcon />} onClick={this.props.onRemove}>Remove Banner</Button>
                            </div>
                        }
                    </div>
                    <div style={{ maxWidth: 800, margin: "auto" }}>
                        <div className="hero-banner" style={{ display: "flex" }}>
                            <div className="hero-banner-images">
                                {(!this.props.image || !this.props.image.id)
                                    ? <p onClick={this.onSelectAnImage} className="empty-image-target">
                                        <Icons.AddImage width="60px" height="60px" />
                                        <span className="instruction">Select an image</span>
                                    </p>
                                    : <div style={{ transform: `translateX(${selectedCarouselIndex * - 100}%)` }} className="hero-banner-image">
                                        {this.props.image.transforms && this.props.image.transforms.points.length !== 0 && this.props.image.transforms.zoom
                                        ? <CanvasPreviewImage height={450} width={800} src={this.props.image.url} cropPoints={this.props.image.transforms.points} />
                                        : <PortraitImage 
                                                src={this.props.image.url}
                                                height="450px"
                                                width="800px"
                                                backgroundColor="#DDE1E5"
                                            />
                                        }
                                        <div className="image-preview-options">
                                            <Fab size="small" title="Crop image" onClick={this.onShowImageCropper}>
                                                <CropIcon />
                                            </Fab>
                                            <Fab size="small" title="Remove" onClick={this.onRemoveImage}>
                                                <ClearIcon />
                                            </Fab>
                                        </div>
                                    </div>
                                }
                                {hasCarousel && this.props.attachedContent!.map((attachedImage) =>
                                    attachedImage.fileType === "image"
                                        ? <div key={attachedImage.ospreyId} style={{ backgroundImage: "url('" + attachedImage.fileUrl + "')", transform: `translateX(${selectedCarouselIndex * - 100}%)`, backgroundSize: "contain", backgroundColor: "#DDE1E5" }} className="hero-banner-image">
                                            <div className="image-preview-options">
                                                <Fab size="small" title="Remove" onClick={() => this.onRemoveAttachedContent(attachedImage)}>
                                                    <ClearIcon />
                                                </Fab>
                                            </div>
                                        </div>
                                        : <div key={attachedImage.ospreyId} style={{ backgroundImage: "url('" + attachedImage.fileUrl + "')", transform: `translateX(${selectedCarouselIndex * - 100}%)` }} className="hero-banner-image">
                                            <Video key={attachedImage.ospreyId} src={attachedImage.fileUrl!} fileName={attachedImage.fileName} pauseVideo={this.state.pauseVideo} />
                                            <div className="image-preview-options">
                                                <Fab size="small" title="Remove" onClick={() => this.onRemoveAttachedContent(attachedImage)}>
                                                    <ClearIcon />
                                                </Fab>
                                            </div>
                                        </div>
                                )}
                            </div>
                            {hasCarousel &&
                                <React.Fragment>
                                    <IconButton
                                        color="primary"
                                        disabled={selectedCarouselIndex === 0}
                                        onClick={this.onGoToPreviousImage}
                                        className="hero-banner-arrow left-arrow"
                                        size="large">
                                        <KeyboardArrowLeftIcon fontSize="inherit" />
                                    </IconButton>
                                    <div className="hero-banner-counter">
                                        {selectedCarouselIndex === 0
                                            ? <span>Banner Image</span>
                                            : <span>{selectedCarouselIndex} of {carouselLength}</span>
                                        }
                                    </div>
                                    <IconButton
                                        color="primary"
                                        disabled={selectedCarouselIndex === carouselLength}
                                        onClick={this.onGoToNextImage}
                                        className="hero-banner-arrow right-arrow"
                                        size="large">
                                        <KeyboardArrowRightIcon fontSize="inherit" />
                                    </IconButton>
                                </React.Fragment>
                            }
                        </div>
                    </div>
                    {hasCarousel &&
                        <div className="hero-banner-steps">
                            <IconButton color={selectedCarouselIndex === 0 ? "primary" : "inherit"} size="small" onClick={() => this.onSelectStep(0)}><FiberManualRecordIcon /></IconButton>
                            {this.props.attachedContent!.map((_, index) =>
                                <IconButton key={index} color={selectedCarouselIndex === index + 1 ? "primary" : "inherit"} size="small" onClick={() => this.onSelectStep(index + 1)}><FiberManualRecordIcon fontSize="small" /></IconButton>
                            )}
                        </div>
                    }
                    <Button variant="text" color="primary" size="small" startIcon={<AddIcon fontSize="small" />} onClick={this.onShowImageGallery} className="add-new-item">Add Media</Button>
                    <ImageCropper
                        show={this.state.showImageCropper}
                        images={[ this.state.selectedImage! ]}
                        onPageChange={this.onApplyImageTransforms}
                        onClose={this.onHideImageCropper}
                        cropBoundary={{ height: HERO_IMG_HEIGHT, width: CONTENT_MODAL_WIDTH }}
                    />
                    <ImageGalleryDialog
                        {...this.props}
                        show={this.state.showImageGallery}
                        attachedContent={this.props.attachedContent}
                        includeVideos={this.state.includeVideos}
                        multiSelect={this.state.multiSelect}
                        onApply={this.onApplyImageChanges}
                        onClose={this.onHideImageGallery}
                    />
                    <LoadingOverlay absolute={true} show={this.props.isFetching} />
                </Paper>
            </Collapse>
        );
    }

    private onApplyImageChanges = (selectedImages: AttachedFile[]) => {
        if (this.state.multiSelect)
            this.onChangeAttachedContent(selectedImages);
        else
            this.onChangeImage(selectedImages);
    }

  private onApplyImageTransforms = (image: CroppableImage, finished: boolean) => {
        this.props.onChangeImage(image);

        if (finished)
            this.onHideImageCropper();
    }

    private onChangeAttachedContent = (selectedImages: AttachedFile[]) => {
        Promise.all(selectedImages.map((selectedImage) => {
            if (selectedImage.fileType === "video") {
                return this.props.getVideo(selectedImage.ospreyId).then((url) => {
                    return {
                        ...selectedImage,
                        fileUrl: url
                    }
                });
            } else {
                // grab the original image so we are not stretching gallery size into 800px wide
                return this.props.getImage(selectedImage.ospreyId).then((selectedImage: AttachedFile) => {
                    return {
                        ...selectedImage
                    };
                })
            }
        })).then((selection) => {
            this.props.getVideosComplete();
            this.props.onChangeAttachedContent(selection);
        });
    }

    private onChangeImage = (selectedImages: AttachedFile[]) => {
        this.props.getImage(selectedImages[0].ospreyId).then((selectedImage) => {
            this.setState({
                ...this.state,
                includeVideos: false,
                selectedImage: { id: selectedImage.ospreyId, url: selectedImage.fileUrl!, transforms: { points: [], zoom: 0 } },
                showImageCropper: true
            });
        });
    }

    private onGoToNextImage = () => {
        this.pauseVideo();
        this.setState({ selectedCarouselIndex: this.state.selectedCarouselIndex + 1 });
    }

    private onGoToPreviousImage = () => {
        this.pauseVideo();
        this.setState({ selectedCarouselIndex: this.state.selectedCarouselIndex - 1 });
    }

    private onHideImageCropper = () => {
        this.setState({ selectedImage: undefined, showImageCropper: false });
    }

    private onHideImageGallery = () => {
        this.setState({ includeVideos: false, showImageGallery: false });
    }

    private onRemoveAttachedContent = (attachedContentToRemove: AttachedFile) => {
        this.pauseVideo();
        if (this.state.selectedCarouselIndex === this.props.attachedContent!.length)
            this.onGoToPreviousImage();
        this.props.onChangeAttachedContent(this.props.attachedContent!.filter((attachedImage) => attachedImage.ospreyId !== attachedContentToRemove.ospreyId));
    }

    private onRemoveImage = () => {
        this.pauseVideo();
        this.props.onRemoveImage();
    }

    private onSelectAnImage = () => {
        this.setState({ includeVideos: false, multiSelect: false, showImageGallery: true });
    }

    private onSelectStep = (index: number) => {
        this.pauseVideo();
        this.setState({ selectedCarouselIndex: index });
    }

    private onShowImageCropper = () => {
        this.setState({ selectedImage: this.props.image, showImageCropper: true });
    }

    private onShowImageGallery = () => {
        this.pauseVideo();
        this.setState({ includeVideos: true, multiSelect: true, showImageGallery: true });
    }

    private pauseVideo = () => {
        this.setState({ pauseVideo: true }, () => this.setState({ pauseVideo: false }));
    }
}


interface ComponentProps {
    show: boolean;
    attachedContent: AttachedFile[] | undefined;
    image: CroppableImage | undefined;
    optional?: boolean;
    onChangeAttachedContent: (selectedImages: AttachedFile[]) => void;
    onChangeImage: (image: CroppableImage) => void;
    onRemove: () => void;
    onRemoveImage: () => void;
}

interface ComponentState {
    includeVideos: boolean;
    multiSelect: boolean;
    pauseVideo: boolean;
    selectedCarouselIndex: number;
    selectedImage: CroppableImage | undefined;
    showImageCropper: boolean;
    showImageGallery: boolean;
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        isFetching: state.gallery.isFetching
    }), {
    getImage: galleryActions.getImage,
    getVideo: galleryActions.getVideo,
    getVideosComplete: galleryActions.getVideosComplete
}
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(HeroBanner);
