import { ActionCreator } from "typedActions";
import { Action } from "redux";

export interface NetworkRequest extends Action {
    method: "GET" | "PATCH" | "POST" | "PUT" | "DELETE",
    url: string,
    body?: any,
    headers?: any
    params?: any,
    queryParams?: any,
    internal?: boolean,
    mode?: RequestMode,
    credentials?: string,
    abortSignal?: AbortSignal,
};

export const NETWORK_REQUEST = "NETWORK_REQUEST";
export const NetworkRequest = ActionCreator<NetworkRequest>(NETWORK_REQUEST);
