import { ApiRequest } from "./network";
import { SparrowApi } from "./network/adapters/SparrowApi";

export interface ICustomCssModelv1 {
    sasUrl?: string;
}

export class FilesApi {
    constructor(private _sparrowApi: SparrowApi) {}

    public getCustomTinyMceCss = (): ApiRequest<ICustomCssModelv1> => {
        return new ApiRequest<ICustomCssModelv1>(
            this._sparrowApi
                .TenantRequest()
                .get(`/{tenant}/api/v1/files/css/tinymce`)
        );
    }

    public getCustomPortalPagesCss = (pageId: string): ApiRequest<ICustomCssModelv1> => {
        return new ApiRequest<ICustomCssModelv1>(
            this._sparrowApi
                .TenantRequest()
                .get(`/{tenant}/api/v1/files/css/portalPages?portalPagesDraftId=${pageId}`)
        );
    }
}
