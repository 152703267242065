import { GlobalApplicationState } from "globalApplicationState";
import { connect, ConnectedProps } from "react-redux";
import { Button, Divider, Menu, MenuItem } from "@mui/material";
import * as React from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

interface ComponentProps { 
    downloadAllUserData: () => void;
    openInvitesDrawer: () => void;
    openUserActivities: () => void;
    navigateToLobby: () => void;
}

const AdditionalUserOptionsDropdown: React.FunctionComponent<PropsWithRedux> = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    return (
        <div>
            <Button
                id="add-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                variant="outlined"
                color="primary"
                endIcon={<ArrowDropDownIcon/>}
            >
                More Options
            </Button>
            <Menu
                id="add-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'add-button',
                }}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                PaperProps={{
                    style: {
                      transform: 'translateY(6px)',
                    }
                }}
            >
                <MenuItem onClick={() => { handleClose(); props.openInvitesDrawer(); }}>Manage user invites</MenuItem>
                <Divider light style={{marginTop: 0, marginBottom: 0}}/> {/* Find a better way to do this? Override in theme.tsx? */}
                <MenuItem onClick={() => { handleClose(); props.openUserActivities(); }}>User management activity log</MenuItem>
                {props.tenantSettings.lobbyEnabled && <MenuItem onClick={props.navigateToLobby}>View lobby</MenuItem> }
                <Divider light style={{marginTop: 0, marginBottom: 0}}/>
                <MenuItem onClick={() => { handleClose(); props.downloadAllUserData(); }}>Download all user data</MenuItem>
            </Menu>
        </div>
    )
}


const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        localAccountsEnabled: state.settings.tenantSettings.localAccountsEnabled,
        tenantSettings: state.settings.tenantSettings,
    }), 
    {
    }
);

type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(AdditionalUserOptionsDropdown);