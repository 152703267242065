import React, { ReactElement, useState } from "react";
import {
    Button,
    Checkbox,
    ListItem,
    ListItemIcon,
    ListItemText
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import CalloutWithSearch from "../../calloutWithSearch";
import { deepCopy } from "utils/deepCopy";
import { MultiSelectTopic, MultiSelectTopicGroup } from "modules/settings";

interface ITopicMultiselect {
    defaultOptions: MultiSelectTopicGroup[];
    selectedOptions: MultiSelectTopic[];
    containerClassName?: string;
    buttonClassName?: string;
    onChange: (topics: MultiSelectTopic[]) => void;
    onClose?: () => void;
    buttonText?: string;
    buttonVariant?: "text" | "outlined";
    startIcon?: ReactElement;
    endIcon?: ReactElement;
    buttonStyle?: React.CSSProperties;
}

const TopicMultiselect: React.FunctionComponent<ITopicMultiselect> = ({
    defaultOptions,
    containerClassName,
    selectedOptions = [],
    onChange,
    onClose,
    buttonClassName,
    buttonText,
    buttonVariant,
    startIcon,
    endIcon,
    buttonStyle,
}) => {
    const [filteredOptions, setFilteredOptions] = useState<MultiSelectTopicGroup[]>([...defaultOptions]);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleSetTopics = () => {
        setFilteredOptions([...defaultOptions]);
    }

    const onCalloutClose = (anchorEl: any) => {
        setAnchorEl(anchorEl);

        if (onClose) onClose();
    }

    const checkGroupSelected = (tagGroup: MultiSelectTopicGroup) => {
        let selectedIds = selectedOptions.map((selected: MultiSelectTopic) => selected.id);
        return tagGroup.tags.every(tag => {
            return selectedIds.includes(tag.id);
        });
    }

    const handleTagGroupClick = (tagGroup: MultiSelectTopicGroup) => {
        let isCurrentlyChecked = checkGroupSelected(tagGroup);

        if (isCurrentlyChecked) {
            deselectTagGroup(tagGroup);
        } else {
            selectTagGroup(tagGroup);
        }
    }

    const selectTagGroup = (tagGroup: MultiSelectTopicGroup) => {
        let currentTags = [...selectedOptions];

        tagGroup.tags.forEach(tag => {
            if (!currentTags.includes(tag)) {
                currentTags.push(tag);
            }
        });

        onChange(currentTags);
    }

    const deselectTagGroup = (tagGroup: MultiSelectTopicGroup) => {
        let currentTags = [...selectedOptions];
        let tagsToRemove = tagGroup.tags.map((t: MultiSelectTopic) => t.id);

        currentTags = currentTags.filter(currentTag => !tagsToRemove.includes(currentTag.id));

        onChange(currentTags);
    }

    const onHandleAllTags = (tagSelected: boolean) => {
        let newTopics: MultiSelectTopic[] = [];

        if (!tagSelected) {
            newTopics = [...selectedOptions];

            filteredOptions.forEach(tagGroup => {
                tagGroup.tags.forEach(tag => {
                    if (!newTopics.includes(tag)) {
                        newTopics.push(tag);
                    }
                });
            });
        }

        onChange(newTopics);
    }

    const onChangeTags = (currentTag: MultiSelectTopic) => {
        const hasSelectedTag: boolean = !!selectedOptions.find((selectedTag) => selectedTag.id === currentTag.id);
        let newTopics = [...selectedOptions];

        if (hasSelectedTag)
            newTopics = selectedOptions.filter(tag => tag.id !== currentTag.id);
        else
            newTopics.push(currentTag);

        onChange(newTopics);
    }

    const onSearch = ( searchtext: string) => {
        searchtext =  searchtext.trim().toLowerCase();
        let newOptions = deepCopy(defaultOptions);

        if (searchtext.length > 0) {
            // search on topic group and topic
            newOptions = newOptions.filter((topicGroup: MultiSelectTopicGroup) => {
                // if the topic group name matches, return all of it's topics
                if (topicGroup.name.trim().toLowerCase().includes(searchtext)) return true;

                // find topics that match the search text and only return those, along with its topic group
                let found = topicGroup.tags.filter((topic: MultiSelectTopic) => topic.name.trim().toLowerCase().includes(searchtext));
                topicGroup.tags = found;

                return found.length > 0;
            });
        }

        // set the new filtered topic groups and topics
        setFilteredOptions(newOptions);
    }

    const anySelections = selectedOptions.length > 0;

    return (
        <div className={`${containerClassName || ""}`}>
            <div style={{ width: "100%" }}>
            <Button
                style={buttonStyle ?? { height: "40px" }}
                startIcon={startIcon}
                endIcon={endIcon}
                color="primary"
                onClick={(event: any) => { setAnchorEl(event.currentTarget); handleSetTopics(); }}
                className={buttonClassName || ""}
                variant={buttonVariant ?? "text"}
            >
                {buttonText ?? "Add/Edit Topics"}
            </Button>
            </div>
            <CalloutWithSearch
                performSearch={onSearch}
                anchorEl={anchorEl}
                placeholder="Search topic group or topic"
                setAnchorEl={onCalloutClose}
                highlight={false}
                searchOnType
                footer={<div>
                    <Button color="primary" style={{ float: "right" }} onClick={() => onHandleAllTags(anySelections)}>
                        {anySelections ? "Unselect" : "Select"} All
                    </Button>
                </div>}
            >
                {
                    filteredOptions.map(tagGroup => {
                        return (
                            tagGroup.tags.length > 0 &&
                            <React.Fragment key={tagGroup.id}>
                                <ListItem button onClick={() => handleTagGroupClick(tagGroup)} className="select-popover-group-header-clickable">
                                    <Checkbox
                                        edge="start"
                                        tabIndex={-1}
                                        disableRipple
                                        size="small"
                                        color="primary"
                                        checked={checkGroupSelected(tagGroup)}
                                    />
                                    {tagGroup.name}
                                </ListItem>
                                    {tagGroup.tags.map(tag => {
                                        return (
                                            <ListItem key={tag.id} dense button onClick={() => onChangeTags(tag)} style={{ paddingLeft: "40px" }}>
                                                <ListItemIcon className="callout-checkbox">
                                                    <Checkbox
                                                        edge="start"
                                                        tabIndex={-1}
                                                        disableRipple
                                                        size="small"
                                                        color="primary"
                                                        checked={!!selectedOptions.find((selectedTag) => selectedTag.id === tag.id)}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={tag.name}
                                                    secondary={tag.restricted ? <LockIcon fontSize="small" htmlColor="#B72026"/> : undefined}
                                                    classes={{
                                                        dense: "callout-list-item-text-dense"
                                                    }}
                                                />
                                            </ListItem>
                                        );
                                    })}
                            </React.Fragment>
                        )
                    })
                }
            </CalloutWithSearch>
        </div>
    );
}

export default TopicMultiselect;
