import React, { useEffect, useState } from 'react';
import { ResponsiveLine } from '@nivo/line';
import { insightsApi } from 'api/instances';


interface Data {
    id: string
    data: Point[]
}

interface Point {
    x: number;
    y: number;
}

const GetLabel = (hour: number) => {
    if (hour === 0)
        return "12:00 AM";
    else if (hour === 12)
        return "12:00 PM";
    else if (hour > 12)
        return (hour - 12) + ":00";
    else
        return hour + ":00";
}

const ActivityTimeOfDayStats: React.FunctionComponent<ComponentProps> = (props) => {

    const [data, setData] = useState<Data[]>([{id: "Activity", data: []} as Data]);

    useEffect(() => {
        insightsApi.GetReadViewsByTimeOfDay()
        .then((response) => {
            const temp = [...[{id: "Activity", data: []} as Data]];
            response.map((stats) => {
                temp[0].data!.push({"x": stats.convertedHour, "y": (stats.readCount + stats.viewCount)});
                return stats;
            });
            temp[0].data.sort((a,b) => (a.x > b.x) ? 1 : ((b.x > a.x) ? -1 : 0));
            setData(temp);
        })
    }, []);
    
    return (
        <div style={{ height: 300 }}>
            <div className="nivo-container-title">Activity by Time of Day ({props.timeZone || "UTC"})</div>
            <ResponsiveLine
                colors={["#e6911a"]}
                data={data}
                enableGridY={false}
                enableGridX={false}
                isInteractive={false}
                axisBottom={{
                    tickSize: 0,
                    tickPadding: 13,
                    tickRotation: 0,
                    format: (tick) => GetLabel(tick)
                }}
                axisLeft={null}
                margin={{ top: 50, right: 60, bottom: 50, left: 60 }}
                theme={{
                    axis: {
                        domain: {
                            line: {
                                stroke: "#dde1e5",
                                strokeWidth: 1
                            }
                        }
                    }
                }} 
            />
        </div>
    )
}

interface ComponentProps {
    timeZone: string;
}

export default ActivityTimeOfDayStats;