// a wrapper around assert.deepStrictEqual to return boolean values

import { AssertionError, deepStrictEqual } from "assert"

export const deepStrictEqualWrapper = (actual: any, expected: any): boolean => {
    try {
        deepStrictEqual(actual, expected, "NOT_EQUAL");

        return true;
    } catch (error) {
        if (error instanceof AssertionError && error.message === "NOT_EQUAL")
            return false;

        throw error;
    }
}

/**
 * Checks if two arrays have the same elements in the same order.
 * @param array1 First array to compare.
 * @param array2 Second array to compare.
 * @returns True if both arrays have the same elements in the same order, false otherwise.
 */
export function arraysEqual<T>(array1: T[], array2: T[]): boolean {
    if (array1.length !== array2.length) {
        return false;
    }

    for (let i = 0; i < array1.length; i++) {
        if (array1[i] !== array2[i]) {
            return false;
        }
    }

    return true;
}
