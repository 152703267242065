import React from "react";

import Paper from "@mui/material/Paper";


import "./authoringFilter.sass";


const FilterContainer: React.FunctionComponent<ComponentProps> = props => {
  return (
    <Paper square style={{ backgroundColor: "#fcfcfc" }} className="authoring-filter">
      <div className="filter-bar">
        <div className="filter-options">
          {props.filters}
        </div>
        <div className="filter-commands">
          {props.filterCommands}
        </div>
      </div>
      {!!props.filterSelection &&
        <div className="filter-selection">
          {props.filterSelection}
        </div>
      }
    </Paper>
  );
}
  

interface ComponentProps {
  filters: JSX.Element;
  filterCommands: JSX.Element;
  filterSelection?: JSX.Element | undefined;
}


export default FilterContainer;