import React from "react";
import { DialogProps, IconButton, Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from "@mui/icons-material/Close";

import PostView from "./postView";
import { DialogContentView } from "modules/common/components/dialogs/dialogContentView";
import { CONTENT_MODAL_WIDTH } from "utils/generalConstants";

interface IDialogPostViewProps extends DialogProps {
    tenantId?: string;
    postId?: string;
    isPublic?: boolean;
    onClose?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        close: {
            position: "absolute",
            top: 0,
            right: 0,
            color: "#ffffff",
            zIndex: 2,
        },
        dialog: {
            minWidth: 200,
        }
    })
);

export const DialogPostView: React.FunctionComponent<IDialogPostViewProps> = ({
    postId,
    onClose,
    isPublic = false,
    open,
    tenantId,
    ...props
}) => {
    const classes = useStyles();

    return (
        <DialogContentView
            {...props}
            id="post-dialog"
            open={open}
            PaperProps={{ style: { maxWidth: CONTENT_MODAL_WIDTH } }}
            classes={{ paper: classes.dialog }}
            onClose={onClose}
        >
            {props.children
                ? props.children
                : <>
                    <IconButton onClick={onClose} className={classes.close} size="large">
                        <CloseIcon color="inherit" />
                    </IconButton>
                    <PostView postId={postId!} isPublic={isPublic} tenantId={tenantId} />
                </>}
        </DialogContentView>
    );
}
