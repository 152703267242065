import React, { useEffect, useState } from "react";

import Callout from "modules/common/components/callout";

import { Tag } from "modules/common/components/authoring/models";
import { SimpleTag } from "modules/newsletters/models";
import { TagSettings } from "modules/settings/models";

import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Theme } from "@mui/material/styles";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import AddIcon from "@mui/icons-material/Add";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tagGroup: {
      color: "#666666",
      fontWeight: 500
    },
    tagList: {
      "& > div": {
        paddingLeft: 50
      }
    }
  })
);

interface TagList {
  id: string;
  name: string;
  tags: Tag[];
}

const EditTags: React.FunctionComponent<ComponentProps> = props => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [calloutOpen, setCalloutOpen] = useState(false);
  const [customTopics, setCustomTopics] = useState(props.customTopics);
  const [tagList, setTagList] = useState([] as TagList[]);

  useEffect(() => {
    let tagsList: TagList[] = [];

    props.tagSettings.tagGroups.map((tagGroup) => {
      const tags: Tag[] = tagGroup.tags.filter((tag) => !tag.disabled);
      if (!!tags.length) {
        tagsList.push({
          id: tagGroup.id,
          name: tagGroup.name,
          tags: tags
        });
      }
      return tagGroup;
    });

    setTagList(tagsList);
  }, [props.tagSettings]);

  const classes = useStyles();

  return (
    <React.Fragment>
      <Button
        variant="text"
        color="primary"
        startIcon={<AddIcon />}
        onClick={(event: any) => {
          setCustomTopics(props.customTopics);
          setAnchorEl(event.currentTarget);
          setCalloutOpen(true);
        }}
      >
        Edit topics
      </Button>
      <Callout
        anchorEl={anchorEl}
        open={calloutOpen}
        setOpen={(toSet) => {
          props.onChange(customTopics);
          setCalloutOpen(toSet)
        }}
        footer={
          <div>
            <Button
              variant="text"
              color="primary"
              onClick={() => {
                props.onChange([]);
                setAnchorEl(null);
              }}
            >
              Clear all
            </Button>
          </div>
        }
      >
        <List disablePadding>
          {tagList.map((tagGroup) => {
            const tagGroupChecked: boolean = tagGroup.tags.filter((tag) => !!customTopics.find((selectedTag) => selectedTag.id === tag.id)).length === tagGroup.tags.length;
            return (
              <React.Fragment key={tagGroup.id}>
                <ListItem dense button onClick={() => {
                  let tags: Tag[] = customTopics.slice();
                  if (!tagGroupChecked) {
                    tagGroup.tags.map((tag) => {
                      if (tags.findIndex(tagToFind => tagToFind.id === tag.id) === -1)
                        tags.push(tag);
                      return tag;
                    });
                  } else {
                    tagGroup.tags.map((tag) => {
                      tags = tags.filter((tagToFilter) => tagToFilter.id !== tag.id);
                      return tag;
                    });
                  }
                  setCustomTopics(tags);
                }}>
                  <ListItemIcon className="callout-checkbox">
                    <Checkbox
                      edge="start"
                      tabIndex={-1}
                      disableRipple
                      size="small"
                      color="primary"
                      checked={tagGroupChecked}
                    />
                  </ListItemIcon>
                  <ListItemText primary={tagGroup.name} classes={{ primary: classes.tagGroup }} />
                </ListItem>
                <List disablePadding className={classes.tagList}>
                  {tagGroup.tags.map((tag) =>
                    <ListItem key={tag.id} dense button onClick={() => {
                      const hasSelectedAudience: boolean = customTopics.findIndex((selectedTag) => selectedTag.id === tag.id) !== -1;
                      if (hasSelectedAudience)
                        setCustomTopics(customTopics.filter((selectedTag) => selectedTag.id !== tag.id));
                      else
                        setCustomTopics(customTopics.concat([{ id: tag.id, name: tag.name }]));
                    }}>
                      <ListItemIcon className="callout-checkbox">
                        <Checkbox
                          edge="start"
                          tabIndex={-1}
                          disableRipple
                          size="small"
                          color="primary"
                          checked={customTopics.findIndex((selectedTag) => selectedTag.id === tag.id) !== -1}
                        />
                      </ListItemIcon>
                      <ListItemText primary={`${tag.name || ""}${tag.restricted ? " (restricted)" : ""}`} />
                    </ListItem>
                  )}
                </List>
              </React.Fragment>
            );
          })}
        </List>
      </Callout>
    </React.Fragment>
  );
}

interface ComponentProps {
  customTopics: SimpleTag[];
  tagSettings: TagSettings;
  onChange: (audiences: SimpleTag[]) => void;
}

export default EditTags;