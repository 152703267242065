import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import PhoneInput from "react-phone-input-2";
import { Button, Grid, useTheme } from "@mui/material";
import 'react-phone-input-2/lib/material.css'
import "modules/profile/styles/profile.sass";
import { removeLeftoverCountryCode, setVerify } from "utils/phoneInputFunctions";
import { removeNonDigits } from "utils/regexUserValidation";

interface PhoneFieldProps {
    description?: string;
    label: string;
    value: string;
    placeholder?: string;
    disabled?: boolean;
    country: string;
    enableSearch: boolean;
    autoFormat: boolean;
    specialLabel?: string;
    disabledDropDown?: boolean;
    onChange?: (event: any) => void;
    onVerifySms?: () => void;
}
const PhoneField: React.FunctionComponent<PhoneFieldProps> = (props) => {
    const styles = useTheme();
    const [isActive, setActive] = useState(false);
    const [isVerifyDisabled, setVerifyDisabled] = useState(false);

    const disabledColor = "#3b78ab";
    const currSecondStyles = styles.palette.secondary.main

    const styleInputClasses = "MuiOutlinedInput-input MuiInputBase-inputMarginDense MuiOutlinedInput-inputMarginDense react-tel-input";

    useEffect(() => {
    }, [isActive, isVerifyDisabled])

    return (
        <div className="section-field">
            <div className="section-input-field-heading">
                <label>{props.label}</label>
            </div>
            <div className="section-input-field">
                <PhoneInput
                    inputClass={ styleInputClasses }
                    inputStyle={{ boxShadow: "none", border: isActive && !props.disabled ? "solid 2px " + currSecondStyles : "", width: "100%", padding: "10.5px 14.5px 10.5px 50px" }}
                    searchStyle={{ width: "93%", padding: "4px", height: "40px", maxHeight: "100%" }}
                    dropdownStyle={{ maxHeight: "320px" }}
                    searchPlaceholder="Search country"
                    key={props.value}
                    autoFormat={props.autoFormat}
                    country={props.country}
                    value={props.value}
                    disableDropdown={props.disabledDropDown ?? true}
                    enableSearch={props.enableSearch}
                    placeholder={props.disabled ? "" : props.placeholder}
                    disabled={props.disabled}
                    specialLabel={props.specialLabel ?? ""}
                    onFocus={() =>  setActive(true)}
                    onChange={(e) => {
                        setVerifyDisabled(setVerify(e));
                    }}
                    onBlur={(event) => {
                    event.target.value = removeLeftoverCountryCode(event.target.value);
                    //Clean the values as it was causing minor bugs in the front end.
                    event.target.value= event.target.value.replace(removeNonDigits, '');

                    if (!!props.onChange && event.target.value !== props.value) props.onChange(event);
                        
                    setActive(false);
                    }}
                />
                <Grid justifyContent="space-between" container >
                    <Grid item>
                        {props.description && <Typography variant="caption">{props.description}</Typography>}
                    </Grid>
                    <Grid item>
                    {props.onVerifySms &&
                    <Button disabled={isVerifyDisabled} onClick={props.onVerifySms} style={{ color: isVerifyDisabled ? "" : disabledColor, height: '20px', textDecoration: "underline", background: "transparent", fontSize: "11px" }}>
                            Verify mobile number
                        </Button>
}
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};
export default PhoneField;