import styled from "styled-components";

const Description = styled.span`
  color: var(--tina-color-grey-6);
  display: block;
  font-size: var(--tina-font-size-0);
  font-style: italic;
  font-weight: lighter;
  margin: 0;
  padding-top: 4px;
  white-space: normal;
`;

export default Description;