import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { push } from "react-router-redux";
import * as actions from "../../actionCreator";
import { GlobalApplicationState } from "globalApplicationState";

import { PortalPagesListItem } from "../../models";

import MoreOptionsIconButton from "modules/common/components/buttons/moreOptionsIconButton";

import confirm from "utils/notyPopups";

import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import MoreOptionsItem from "modules/common/components/moreOptionsItem";


const MorePortalPageOptions: React.FunctionComponent<PropsWithRedux> = props => {
  const { portalPage } = props;

  return (
    <MoreOptionsIconButton>
      <List disablePadding>
        <MoreOptionsItem
          text="Open in page editor"
          onClick={() => {
            props.redirectTo("/" + props.tenantId + "/admin/portalPages/edit/" + props.portalPage.draftId);
          }}
        />
        <Divider light />
        <MoreOptionsItem
          text="Preview"
          onClick={() => {
            props.onPreviewPortalPage();
            props.onSelection();
          }}
        />
        {!portalPage.isHome &&
          <Divider light />
        }
        {!portalPage.isHome && portalPage.state === "Enabled" &&
          <MoreOptionsItem
            text="Set as homepage"
            onClick={async () => {
              if (await confirm.show({
                text: "You are about to set " + portalPage.title + " as the Homepage. This will change the page settings including the URL and the audiences who can access the page.<br /><br />"+
                      "Do you want to continue?",
                title: "Set as homepage"
              })) {
                props.setPortalPageAsHome(portalPage.id);
                props.onSelection();
              }
            }}
          />
        }
        {(portalPage.state === "Draft" || portalPage.hasPendingDraft)  &&
          <MoreOptionsItem
            text={portalPage.hasPendingDraft
              ? portalPage.state === "Enabled"
                ? "Publish changes"
                : "Publish with changes"
              : "Publish now"
            }
            onClick={() => {
              props.publishDraft(portalPage.draftId);
              props.onSelection();
            }}
          />
        }
        {!portalPage.isHome && portalPage.state === "Enabled" &&
          <MoreOptionsItem
            text="Disable"
            onClick={() => {
              props.disablePortalPage(portalPage.id);
              props.onSelection();
            }}
          />
        }
        {portalPage.state === "Disabled" &&
          <MoreOptionsItem
            text="Enable"
            onClick={() => {
              props.enablePortalPage(portalPage.id);
              props.onSelection();
            }}
          />
        }
        {!portalPage.isHome &&
          <MoreOptionsItem
            text="Delete"
            textStyle={{color: "#B72026"}}
            onClick={async () => {
              if (portalPage.state === "Draft") {
                if (await confirm.show({ 
                  text: `You're about to permanently delete '${portalPage.title}'. You cannot undo this action. Are you sure?`, 
                  title: "Delete Draft",
                  yesColor: "#a80000",
                  yesText: "Delete",
                  noText: "Cancel"
                }))
                  props.deleteDraft(portalPage.draftId);
              } else {
                if (await confirm.show({ 
                  text: `You're about to permanently delete '${portalPage.title}'. You cannot undo this action. Are you sure?`, 
                  title: "Delete Portal Page",
                  yesColor: "#a80000",
                  yesText: "Delete",
                  noText: "Cancel"
                }))
                  props.deletePortalPage(portalPage.id);
              }
              props.onSelection();
            }}
          />
        }
      </List>
    </MoreOptionsIconButton>
  );
}

interface ComponentProps {
  portalPage: PortalPagesListItem;
  onPreviewPortalPage: () => void;
  onSelection: () => void;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    tenantId: state.tenant.id
  }),
  {
    deleteDraft: actions.deleteDraft,
    deletePortalPage: actions.deletePortalPage,
    disablePortalPage: actions.disablePortalPage,
    enablePortalPage: actions.enablePortalPage,
    publishDraft: actions.publishDraft,
    redirectTo: push,
    setPortalPageAsHome: actions.setPortalPageAsHome
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(MorePortalPageOptions);