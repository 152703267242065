export type AccountCategory = "social" | "sparrow" | "work";
export type AccountRole = "author" | "default" | "owner" | "user";
export type AccountType = "adfs" | "apple" | "default" | "facebook" | "google" | "linkedIn" | "microsoft" | "sparrow" | "work";
export type AccountStatus = "disabled" | "enabled" | "pending";
export type SortField = "User" | "Registration" | "Comments" | "Reactions" | "Checkbox";
export type UserActivityType = "AddSparrowUser" | "AddSocialUser" | "BulkAddSparrowUser" | "BulkAddSocialUser" | "DeleteSparrowUser" | "DeleteSocialUser" | "UpdateUser" | "ResetUserPassword" | "ResendSocialUserInvite" | "CancelledSocialUserInvite" | "EnabledUser" | "DisabledUser";
export type UserActivityStatus = "New" | "Successful" | "Failed"
export type UserSort = {
    order: "increasing" | "decreasing" | "lastModified";
    category: UserSortCategory;
}

export type UserSortCategory = "name" | "state" | "role" | "accountType" | "lastLogin";


export interface ApplicationState {
    users: UsersState
}

export enum InvitePlatform
{
    Social,
    Sparrow,
}

//Designed to mimic the userRole enum in the backend.
export enum UserRole {
    default,
    author,
    owner,
    user,
    contributor
}

export interface UsersState {
    fetching: boolean;
    saving: boolean;
    activeTab: UsersTab;
    users: User[];
    userList: UserList;
    editingUser: UserEdit | null;
    creatingUser: UserCreation;
    fetchedUser: User;
    invitedUsers: number;
    invitedTime: Date;

    continuationToken?: string;

    invitingUser: boolean;
    deletingUser: boolean;
    externalUserList: ExternalUser[];
    invitingUserEmail: string | null;
    invitingUserValidation: ValidationResponse | null;

    processingToggleEnableEmail: string | null;
    processingReinviteEmail: string | null;

    uploadingBatchInvite: boolean;
    batchUploadValidation: ValidationResponse | null;

    reinvitingPendingUsers: boolean;
    deletingPendingUsers: boolean;

    legacyFixComplete: boolean;
    editingLocalAccountPassword: string;
}

export interface InvitedUser {
    inviteType: InvitePlatform,
    email?: string;
    smsPhone: string;
    enabled?: boolean;
    inviteToken?: string;
    inviteTokenused?: boolean;
    invitedAt?: Date;
    provider?: string;
    user?: User;
    sequenceNumber?: number
    attributes?: object
    userDetails?: object
}

export interface UserActivity {
    userId: string;
    tenantId: string;
    activityType: UserActivityType;
    activityDescription: string;
    time: Date;
    moreInfo?: string;
    message?: string;
    status: string;
    email: string;
    name: string;
    reference: string;
}

export interface UserActivityLogType {
    activityType?: string;
    activityDescription?: string;
}

export interface UserActivityFilter {
    userIds: string[];
    textToSearch: string | null;
    activityTypes: UserActivityType[];
    status: UserActivityStatus | null;
    from: string | null;
    to: string | null;
}

export interface SimpleTag {
    id: string;
    name: string;
}

export interface UserActivityLogPage {
    currentPage: number;
    results: UserActivity[];
    totalItems: number;
    totalPages: number;
}
export interface UserCreation {
    firstName: string;
    lastName: string;
    userName: string;
    preferredName: string;
    initialPassword: string;
    email: string | null;
    audiences: string[];
    employeeId: string | null;
    title: string | null;
    department: string | null;
    reportsTo: string | null;
    region: string | null;
    country: string | null;
    preferredSMSPhone: string | null;
    roles: string[];
    canManageSubmissions: boolean;
    subscribedToFlaggingNotifications: boolean;
}

export interface UserCreationRequiredDetails {
    firstName: string;
    lastName: string;
    userName: string;
    initialPassword: string;
    email: string;
}

export interface UserEdit {
    addSubscribedTags: string[];
    bio: string;
    country: string;
    department: string;
    firstName: string;
    lastName: string;
    isEnabled: boolean;
    isFlaggingSubscribed: boolean;
    isSubmissionManager: boolean;
    preferredName: string;
    pronouns: string;
    officeLocation: string;
    region: string;
    removeSubscribedTags: string[];
    reportsTo: string;
    role: AccountRole;
    smsPhone: string;
    employeeId: string;
    title: string;
    email: string;
    updateCustomDetails: { [key: string]: string };
}

export enum UsersTab {
    Users = 1,
    Insights = 2,
    Permissions = 3,
}

export enum AccountCreationTypes {
    Social,
    Sparrow,
}

export interface UserList {
    currentPage: number;
    totalPages: number;
    totalUsers: number;
    users: UserListItem[];
}

export interface UserListItem {
    id: string;
    accountCategory: AccountCategory;
    accountType: AccountType;
    avatar: string;
    comments: number;
    email: string;
    eventReads: number;
    eventViews: number;
    initials: string;
    lastLoginDate: string;
    lastModifiedDate: string;
    localId: string;
    name: string;
    postReads: number;
    postViews: number;
    reactions: number;
    role: AccountRole;
    status: AccountStatus;
    totalInteractions: number;
    totalReads: number;
    totalViews: number;
}

export interface User {
    id: string;
    azureB2CObjectId: string;
    audiences: string[];
    country: string;
    department: string;
    firstName: string;
    lastName: string;
    email: string;
    employeeId: string;
    providerId: string;
    providerName: string;
    // applicationLastOpened: Date;
    firstLogin: Date | null;
    tagIds: string[];
    region: string;
    reportsTo: string;
    roles: string[];
    avatarColor: string;
    enabled: boolean;
    legacyDisabled: boolean;
    title: string;
    totalComments: number;
    totalReactions: number;
    subscribedToFlaggingNotifications: boolean;
    preferredName: string;
    preferredEmail: string;
    preferredSMSConsent: boolean | null;
    preferredSMSPhone: string
    preferredLCID: string;
    canManageSubmissions: boolean;
}

export interface ExternalUser {
    email: string;
    inviteTokenUsed: boolean;
    inviteToken: string;
    provider: string;
    invitedAt?: Date;
    enabled: boolean;
    user: User;
}

export interface UninvitedUser {
    email: string;
    providerName: string;
    accessDate: Date;
    attempts: number;
}

export interface LobbyUser {
    email: string;
    firstName: string;
    lastName: string;
    objectId: string;
    issuerName: string;
    createdDateTime: Date;
}

export interface ValidationResponse {
    Message: string;
    ModelState: {
        [prop: string]: string[]
    }
}

export interface UserCustomDetail {
    id: string;
    description: string;
    key: string;
    title: string;
    value: string;
}

export interface UserDetails {
    id: string;
    localId: string;
    isLocalIdValidEmail: boolean; // used to differentiate between v1 and v2 sparrow accounts
    accountCategory: AccountCategory;
    accountType: AccountType;
    avatar: string;
    created: string;
    customDetails: UserCustomDetail[];
    dataDetails: {
        comments: number;
        eventReads: number;
        eventViews: number;
        postReads: number;
        postViews: number;
        reactions: number;
        totalInteractions: number;
        totalReads: number;
        totalViews: number;
    };
    email: string;
    initials: string;
    isEnabled: boolean;
    name: string;
    securityDetails: {
        audiences: UserDetailsAudience[];
        isAuthor: boolean;
        isFlaggingSubscribed: boolean;
        isOwner: boolean;
        isSubmissionManager: boolean;
    };
    standardDetails: {
        bio: UserDetailsEditableField;
        country: UserDetailsEditableField;
        department: UserDetailsEditableField;
        email: string;
        firstName: string;
        lastName: string;
        employeeId: string;
        preferredName: string;
        pronouns: UserDetailsEditableField;
        officeLocation: UserDetailsEditableField;
        region: UserDetailsEditableField;
        reportsTo: UserDetailsEditableField;
        smsConsent: boolean;
        smsPhone: string;
        subscribedTags: SubscribedTag[];
        title: UserDetailsEditableField;
    };
    permissionDetails: PermissionDetails;
    isWorkAccount: boolean;
}

export interface UserDetailsAudience {
    id: string;
    audienceType: "smart" | "standard";
    description: string;
    isEditable: boolean;
    isIncluded: boolean;
    title: string;
    totalUsers: number;
}

export interface PermissionDetails {
    role: UserRole;
    topics: string[];
    isSubmissionManager: boolean;
}

export interface UserDetailsEditableField {
    value: string;
    isEditable: boolean;
}

export interface UserSelectFilters {
    userState: string;
    lastLogin: string;
    dateRange: string;
    equality: string;
    userActivity: string;
    role: string;
    type: string;
}

export class UserFilterValues {
    public name: string;
    public email: string;
    public numOf: string;
    public selectVals: UserSelectFilters;
}

export const defaultFilters: UserFilterValues = {
    name: "",
    email: "",
    numOf: "",
    selectVals: {
        userState: "",
        lastLogin: "",
        dateRange: "",
        equality: "",
        userActivity: "",
        role: "",
        type: ""
    }
};

export interface UsersFilterValues {
    accountTypes: AccountType[];
    hasCustomDetails: boolean | null;
    loggedInAfter: string;
    roles: AccountRole[];
    statuses: AccountStatus[];
    textToSearch: string;
    authorTopics: string[];
}

export class ExternalUserFilterValues {
    public registeredEmail: string;
    public invitedEmail: string;
    public inviteState: string;
}

export class PagedInviteFilterValues {
    public invitedEmail: string;
    public pageNumber: number;
    public pageAmount: number;
}

export const defaultExternalFilters: ExternalUserFilterValues = {
    registeredEmail: "",
    invitedEmail: "",
    inviteState: ""
};


export interface SubscribedTag {
    id: string;
    name: string;
    tags: {
        id: string;
        isMandatory: boolean;
        name: string;
    }[];
}
