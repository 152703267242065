import { Action } from 'redux';
import * as Actions from './actions';
import { ResourcesState } from './models';

const defaultState = {
    lcidMappings: {
        'en-us': {
            language: "English (US)"
        }
    },
    emailSubjectsResource: {
        subjects: {
        }
    },
    smsBodiesResource: {
        bodies: {
        }
    },
    pushContentsResource: {
        contents: {
        }
    }
} as ResourcesState;

const actionHandler: { [actionType: string]: (state: ResourcesState, action: Action) => ResourcesState } = {

    [Actions.SET_LCID_MAPPINGS]: (state: ResourcesState, action: Actions.SetLcidMappings) => {
        return {
            ...state,
            lcidMappings: action.mappings
        }
    },
    [Actions.SET_EMAIL_RESOURCE]: (state: ResourcesState, action: Actions.SetEmailResource) => {
        return {
            ...state,
            emailSubjectsResource: action.resource.subjects
        }
    },
    [Actions.SET_SMS_RESOURCE]: (state: ResourcesState, action: Actions.SetSmsResource) => {
        return {
            ...state,
            smsBodiesResource: action.resource.bodies
        }
    },
    [Actions.SET_MOBILE_RESOURCE]: (state: ResourcesState, action: Actions.SetMobileResource) => {
        return {
            ...state,
            pushContentsResource: action.resource.contents
        }
    }

}

export const reducer = (state: ResourcesState, action: Action) => {
    const actionHandlerMethod = actionHandler[action.type];
    return actionHandlerMethod 
        ? actionHandlerMethod(state, action)
        : state || defaultState;
}
