import * as React from "react";
import { ConnectedProps, connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router";
import { GlobalApplicationState } from "globalApplicationState";

import Avatar from "modules/common/components/avatar";
import TenantLink from "modules/common/components/tenantLink";

import { generateColor } from "utils/colorGenerator";

import { Theme } from "@mui/material/styles";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { getRole } from "utils/getRole";
import ConditionalWrapper from "../conditionalWrapper";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        account: {
            display: "inline-flex",
            alignItems: "center",
            marginRight: 10,
            "& a": {
                display: "inline-flex",
                alignItems: "center",
                color: "inherit",
                textDecoration: "none",
            },
            "& a:hover": {
                textDecoration: "none",
                color: "inherit",
            }
        },
        avatar: {
            marginRight: 5,
            "& .avatar-icon-container": {
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                height: 30,
                width: 30,
            },
            "& .avatar-letter": {
                fontSize: 12,
                fontWeight: 300,
                lineHeight: "1em",
                marginTop: 0,
            },
        },
        avatarName: {
            fontSize: 14,
        },
    })
);

const Account: React.FunctionComponent<IAccountPropsWithRedux> = ({ fetching, currentUser, linkToProfile, showRole }) => {
    const classes = useStyles();
    if (!currentUser.userId || fetching) return <></>;

    return (
        <div className={classes.account}>
            <ConditionalWrapper condition={linkToProfile} wrapper={(children) => <TenantLink to="~/me">{children}</TenantLink>}>
                <div className={classes.avatar}>
                    <Avatar firstname={currentUser.firstName} lastname={currentUser.lastName} color={generateColor(currentUser.userId)} />
                </div>
                <div className={classes.avatarName}>
                    {`${currentUser.firstName} ${currentUser.lastName}`} {showRole && `(${getRole(currentUser)})`}
                </div>
            </ConditionalWrapper>
        </div>
    );
};

interface IAccountComponentProps {
    showRole?: boolean;
    linkToProfile?: boolean;
}

const accountConnector = connect((state: GlobalApplicationState, ownProps: IAccountComponentProps & RouteComponentProps) => ({
    ...ownProps,
    fetching: state.settings.fetching,
    currentUser: state.settings.currentUser,
}));
type IAccountPropsWithRedux = ConnectedProps<typeof accountConnector>;
export default withRouter(accountConnector(Account));
