import React from "react";

import MoreOptionsButton from "modules/common/components/buttons/moreOptionsButton";

import List from "@mui/material/List";
import MoreOptionsItem from "modules/common/components/moreOptionsItem";


const EditOptions: React.FunctionComponent<ComponentProps> = props => {
  if (!props.id)
    return <React.Fragment></React.Fragment>;

  return (
    <React.Fragment>
      <MoreOptionsButton text="Actions">
        <List disablePadding>
          <MoreOptionsItem
            text="Download all user data"
            onClick={props.onDownloadUserData}
          />
          <MoreOptionsItem
            text="Delete"
            onClick={props.onDelete}
          />
        </List>
      </MoreOptionsButton>
    </React.Fragment>
  );
}

interface ComponentProps {
  id?: string;
  onDelete: () => void;
  onDownloadUserData: () => void;
}

export default EditOptions;