import React from "react";
import { connect, ConnectedProps } from "react-redux";

import { GlobalApplicationState } from "globalApplicationState";
import FilledInput from "../forms/inputs/filledInput";
import { AuthoringTinyEditor } from "./authoringTinyMce";
import * as actions from 'modules/posts/actionCreator';
import * as galleryActions from "modules/gallery/actionCreator";

import ImageGalleryDialog from "./dialogs/imageGalleryDialog";
import IntenseEmphasis from "./dialogs/intenseEmphasis";
import { useTinyMceAuthoringLogic } from "modules/common/hooks/useTinyMceAuthoringLogic";
import { ICustomCssModelv1 } from "api/files";

interface IBodyv2Props {
    value?: string;
    activeLcid?: string;
    fetchingAnalyses?: boolean;
    customCss?: ICustomCssModelv1;
    onFocus?: () => void;
    onFullscreen?: () => void;
    onChange: (body: string) => void;
}

const Bodyv2: React.FunctionComponent<PropsWithRedux> = ({
    value,
    defaultLcid,
    activeLcid,
    fetchingAnalyses = false,
    customCss,
    onFocus,
    onFullscreen,
    onChange,
    grabMentionUsers,
    getImage,
}) => {
    const {
        editorRef,
        intenseEmphasisDialogOpen,
        inlineImageDialogOpen,
        onShowInlineImageDialog,
        onShowIntenseEmphasisDialog,
        onApplyInlineImage,
        onApplyIntenseEmphasis,
        onHideInlineImageDialog,
        onHideIntenseEmphasisDialog,
    } = useTinyMceAuthoringLogic(getImage);

    return (
        <>
            <FilledInput
                label={"Body"}
                required
                labelOnly
                value={""}
            />
            <AuthoringTinyEditor
                loading={fetchingAnalyses}
                activeLcid={activeLcid}
                customCss={customCss}
                value={value}
                onChange={onChange}
                defaultLcid={defaultLcid}
                editorRef={editorRef}
                onShowInlineImageDialog={onShowInlineImageDialog}
                onShowIntenseEmphasisDialog={onShowIntenseEmphasisDialog}
                grabMentionUsers={grabMentionUsers}
                onFocus={onFocus}
                initOptions={{
                    min_height: 670
                }}
                onFullscreen={onFullscreen}
            />
            <ImageGalleryDialog show={inlineImageDialogOpen} allowLink onApply={onApplyInlineImage} onClose={onHideInlineImageDialog} />
            <IntenseEmphasis show={intenseEmphasisDialogOpen} onApply={onApplyIntenseEmphasis} onClose={onHideIntenseEmphasisDialog} />
        </>
    );
}
const connector = connect(
    (state: GlobalApplicationState, ownProps: IBodyv2Props) => ({
        ...ownProps,
        defaultLcid: state.settings.tenantSettings.defaultLCID,
        activeLanguage: state.settings.activeLanguageLCID,
    }),
    {
        grabMentionUsers: actions.getMentionsUsers,
        getImage: galleryActions.getImage
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;
export default connector(Bodyv2);
