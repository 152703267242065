import * as React from "react";

import Chip from "@mui/material/Chip";

import CloseIcon from "@mui/icons-material/Close";


import "./chips.sass";


interface CategoryTag {
  id: string;
  enabled?: boolean;
  name: string;
}

const CategoryTagChips: React.FunctionComponent<ComponentProps> = props => {
  if (!props.tagIds || !props.tagIds.length) {
    if (props.hideAll)
      return <React.Fragment></React.Fragment>;

    return (
      <div className="category-tag-chips">
        <Chip
          label="Uncategorized"
          disabled={true}
          className="uncategorized-tag"
        />
      </div>
    );
  }

  const matchedTags: CategoryTag[] = props.tagIds.map((tagId) => {
    const matchedTag = props.tags.find((tag) => tag.id === tagId);
    if (!matchedTag)
      return {
        id: tagId,
        name: "(disabled or deleted)",
        enabled: false
      };
    return matchedTag;
  }).filter((tag) => !!tag.id);

  return (
    <div className="category-tag-chips">
      {matchedTags.map((tag) => (
        <Chip
          key={tag.id}
          label={tag.name}
          onDelete={() => props.onDelete(tag.id)}
          deleteIcon={<CloseIcon />}
        />
      ))}
    </div>
  );
}

interface ComponentProps {
  tagIds: string[] | undefined;
  tags: CategoryTag[];
  hideAll?: boolean;
  onDelete: (audienceId: string) => void;
}

export default CategoryTagChips;