import * as React from 'react';
import { GlobalApplicationState } from "globalApplicationState";
import { ConnectedProps, connect } from "react-redux";
import ToggleSwitch from 'modules/common/components/toggleSwitch';
import {actions} from '..'

interface ComponentProps { 
    onPublishName: string
    teamsEvent: string;
    mobileEvent: string;
    emailEvent: string;
    smsEvent: string;
}

interface ComponentState {
}

class AuthoringSettings extends React.Component<PropsWithRedux, ComponentState> {
    public render() {
        const emailDisabled = this.props.notificationSettings[this.props.onPublishName].emailOnPublish === 'none';
        const smsDisabled = this.props.notificationSettings[this.props.onPublishName].smsOnPublish === 'none';
        const teamsDisabled = this.props.notificationSettings[this.props.onPublishName].teamsOnPublish === 'none';
        const mobileDisabled = this.props.notificationSettings[this.props.onPublishName].mobileOnPublish === 'none';

        const settings = this.props.notificationSettings.settings;
    

        return(
            <div className="authoring-prompt">
                <div>
                    <h4>Edit Authoring Default Settings</h4>
                    <span className="description-text">The following details will be the default notification setting for each time a notification is created and/or published.</span>
                </div>
                
                <div className="authoring-settings-zone">
                    <div className="authoring-elements">
                        <div>
                            Notify Upon Publishing
                            <ToggleSwitch 
                                onClick={() => this.toggleAll(emailDisabled, smsDisabled, teamsDisabled, mobileDisabled)}
                                value={!(emailDisabled && smsDisabled && teamsDisabled && mobileDisabled)}
                            />
                        </div>
                        <span className="description-text">Upon publishing a notification, employees will be notified through the following channels:</span>

                        <div className="authoring-checkboxes">
                            <div>
                                <input className="authoring-checkboxes-input"
                                    type="checkbox"
                                    name="teamsOnPublish"
                                    disabled={!settings[this.props.teamsEvent].toggle}
                                    onChange={this.toggleAuthoring}
                                    checked={!teamsDisabled && settings[this.props.teamsEvent].toggle}
                                />
                                <span>MS Teams Chatbot</span>
                            </div>

                            <div>
                                <input className="authoring-checkboxes-input"
                                    type="checkbox"
                                    name="mobileOnPublish"
                                    disabled={!settings[this.props.mobileEvent].toggle}
                                    onChange={this.toggleAuthoring} 
                                    checked={!mobileDisabled && settings[this.props.mobileEvent].toggle}
                                />
                                <span>Mobile App</span>
                            </div>

                            <div>
                                <input className="authoring-checkboxes-input" type="checkbox"
                                    name="emailOnPublish"
                                    disabled={!settings[this.props.emailEvent].toggle}
                                    onChange={this.toggleAuthoring} 
                                    checked={!emailDisabled && settings[this.props.emailEvent].toggle}
                                />
                                <span>Email</span>
                            </div>
                            <div className="authoring-checkboxes">
                                <div className="radio-button-author-spacing">
                                    <input type="radio"
                                        checked={this.props.notificationSettings[this.props.onPublishName].emailOnPublish === 'fallback' && settings[this.props.emailEvent].toggle !== false}
                                        onChange={() => this.props.setOnPublish(this.props.onPublishName, "emailOnPublish", 'fallback')}
                                        disabled={emailDisabled || !settings[this.props.emailEvent].toggle}
                                    />
                                    <span className="description-text">Send email only if standard app notifications are not set up for the topic subscriber</span>
                                </div>
                                <div className="radio-button-author-spacing">
                                    <input type="radio"
                                        checked={this.props.notificationSettings[this.props.onPublishName].emailOnPublish === 'subscribers' && settings[this.props.emailEvent].toggle !== false}
                                        onChange={() => this.props.setOnPublish(this.props.onPublishName, "emailOnPublish", 'subscribers')}
                                        disabled={emailDisabled || !settings[this.props.emailEvent].toggle}
                                    />
                                    <span className="description-text">Send to all topic subscribers</span>
                                </div>
                            </div>

                            <div>
                                <input className="authoring-checkboxes-input" type="checkbox"
                                    name="smsOnPublish"
                                    disabled={!settings[this.props.smsEvent].toggle}
                                    onChange={this.toggleAuthoring} 
                                    checked={!smsDisabled && settings[this.props.smsEvent].toggle}
                                />
                                <span>SMS</span>
                            </div>
                            <div className="authoring-checkboxes">
                                <div className="radio-button-author-spacing">
                                    <input type="radio"
                                        checked={this.props.notificationSettings[this.props.onPublishName].smsOnPublish === 'fallback' && settings[this.props.smsEvent].toggle !== false}
                                        onChange={() => this.props.setOnPublish(this.props.onPublishName, "smsOnPublish", 'fallback')}
                                        disabled={smsDisabled || !settings[this.props.smsEvent].toggle}
                                    />
                                    <span className="description-text">Send email only if standard app notifications or email are not set up for the topic subscriber</span>
                                </div>
                                <div className="radio-button-author-spacing">
                                    <input type="radio"
                                        checked={this.props.notificationSettings[this.props.onPublishName].smsOnPublish === 'subscribers' && settings[this.props.smsEvent].toggle !== false}
                                        onChange={() => this.props.setOnPublish(this.props.onPublishName, "smsOnPublish", 'subscribers')}
                                        disabled={smsDisabled || !settings[this.props.smsEvent].toggle}
                                    />
                                    <span className="description-text">Send to all topic subscribers</span>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private toggleAuthoring = (event) => {
        let valueToChange;
        if(!event.target.checked) {
            valueToChange = 'none'
        }
        else {
            valueToChange = 'subscribers'
        }
        this.props.setOnPublish(this.props.onPublishName, event.target.name, valueToChange);
    }

    private toggleAll = (emailOff:boolean, smsOff:boolean, teamsOff:boolean, mobileOff:boolean) => {
        if(emailOff && smsOff && teamsOff && mobileOff)
        {
            this.props.setAllOnPublish(this.props.onPublishName, 'subscribers')
        }
        else
        {
            this.props.setAllOnPublish(this.props.onPublishName, 'none')
        }
    }
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        notificationSettings: state.settings.notificationSettings,
    }),
    {
        setOnPublish: actions.SetOnPublish,
        setAllOnPublish: actions.SetAllOnPublish
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default (connector(AuthoringSettings));
