import { useRef } from 'react'

/**
 * Hook to determine whether this is the first render
 * - i.e. a componentDidMount, sort of, function component version
 * - adapted from https://usehooks-ts.com/react-hook/use-is-first-render
 */
const useIsFirstRender= (): boolean => {
  const isFirst = useRef<boolean>(true)

  if (isFirst.current) {
    isFirst.current = false

    return true
  }

  return isFirst.current
}

export default useIsFirstRender
