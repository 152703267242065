import { EventTimes as IEventTimes } from "modules/events";

export enum dateOptions {
    basicFormatting = "MMM D, YYYY",
    basicWithHours = "MMM D, YYYY, h:mmA",
    basicWithHoursWithSpacing = "MMM D, YYYY, h:mm A",
    datePickerFormatting = "MMM d, yyyy",
    dateAndTime = "dddd, MMMM Do YYYY, h:mm a" // ex) Monday, February 25th 2019, 2:06 AM
};

/**
 * Used to format all day times to use an exact day. Ie if the event is all day and was created in the timezone est,
 * it will show the correct day as an all day. If it was created on the 20th, it would be showing as the 19th for timezones less than est.
 * Used to format a full date. Ie a full date with month, day, and year.
 * @param date 
 * @param useUtcTimeZone 
 * @returns 
 */
export function formatDate(date: Date, useUtcTimeZone: boolean, includeWeekDay?: boolean): string {
    const options: Intl.DateTimeFormatOptions = {
      weekday: includeWeekDay ? 'short' : undefined,
      day: 'numeric',
      month: 'short',
      year: 'numeric'
    };
    
    if (useUtcTimeZone) {
      options.timeZone = 'UTC';
    }
  
    return new Intl.DateTimeFormat('en-US', options).format(date);
  }

  /**
   * Used to check whether an event is an all day event.
   * @param eventTime 
   * @returns boolean
   */
  export function isAllDayEvent(eventTimes: IEventTimes, eventStartTime: string): boolean {
    return eventTimes[eventStartTime] === null;
  }