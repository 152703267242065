import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { push } from "react-router-redux";
import * as actions from "../../actionCreator";
import { GlobalApplicationState } from "globalApplicationState";

import { PortalPagesListItem } from "../../models";

import Page from "../../tinacms/page";

import PreviewOptions from "../action-buttons/previewOptions";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import CloseIcon from "@mui/icons-material/Close";


class PortalPagePreview extends React.Component<PropsWithRedux, ComponentState> {
  public render() {
    const { portalPagesListItem, preview } = this.props;

    if (!portalPagesListItem || !preview)
      return <React.Fragment></React.Fragment>;

    return (
      <Dialog id="portal-page-preview" open={this.props.showPreview} maxWidth="lg" onClose={this.onClose}>
        <DialogTitle className="portal-page-preview-header">
          <div className="portal-page-preview-header-title">
            <Typography variant="h2">Preview {preview.title}</Typography>
            <div>
              <PreviewOptions portalPage={preview} portalPagesListItem={portalPagesListItem} onShowPortalPageDetails={this.props.onShowPortalPageDetails} onShowPortalPageTitle={this.props.onShowPortalPageTitle} />
              <IconButton onClick={this.onClose} size="large"><CloseIcon /></IconButton>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <div>Page URL: {preview.url}</div>
          <div className="preview-disclaimer">Preview varies based on device and/or platform.</div>
          <div className="portal-page-preview-page">
            <Page data={preview.portalPageJson[0]} isInEditMode />
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  private onClose = () => {
    this.props.hidePreview();
  }
}


interface ComponentProps {
  portalPagesListItem: PortalPagesListItem | undefined;
  onShowPortalPageDetails: (portalPage: PortalPagesListItem) => void;
  onShowPortalPageTitle: (portalPage: PortalPagesListItem) => void;
}

interface ComponentState {}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    preview: state.portalPages.preview,
    showPreview: state.portalPages.showPreview,
    tenant: state.tenant.id
  }),
  {
    hidePreview: actions.hidePreview,
    redirectTo: push
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(PortalPagePreview);