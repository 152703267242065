import React, { useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";

const Disclaimer: React.FunctionComponent<ComponentProps> = ({ icon, containerStyle, text, closable = false, backgroundColor = "#f4f4f1" }) => {
    const [open, setOpen] = useState<boolean>(true);

    const onClose = () => {
        setOpen(false);
    }

    return <>
        {open && <div className="disclaimer-block-text" style={{ backgroundColor: backgroundColor, ...containerStyle }}>
            <div className="info">
                {icon}
            </div>
            <div>
                {text}
            </div>
            {closable &&
                <IconButton onClick={onClose} size="large">
                    <CloseIcon htmlColor="rgba(0, 0, 0, 0.4)" />
                </IconButton>}
        </div>}
    </>;
}

interface ComponentProps {
    icon: JSX.Element;
    text: string | JSX.Element;
    closable?: boolean;
    backgroundColor?: string;
    containerStyle?: React.CSSProperties;
}

export default Disclaimer;
