import { Action } from "redux";

import { IUserPortalLayoutState } from "./models";
import * as actions from './actions';

const DEFAULT_STATE: IUserPortalLayoutState = {
    mobileAppBannerOpen: false,
};

export const actionHandler:  { [actionType: string]: (state: IUserPortalLayoutState, action: Action) => IUserPortalLayoutState } = {
    [actions.SET_MOBILE_APP_BANNER_OPEN]: (state: IUserPortalLayoutState, action: actions.ISetMobileAppBannerOpen) => {
        return {
            ...state,
            mobileAppBannerOpen: action.open
        };
    }
}

export const reducer = (state: IUserPortalLayoutState, action: Action) => {
    const actionHandlerMethod = actionHandler[action.type];
    if (actionHandlerMethod) {
      return actionHandlerMethod(state, action);
    }

    return state || DEFAULT_STATE;
};
