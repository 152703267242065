import React, { useState } from "react";
import * as galleryActions from "modules/gallery/actionCreator";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";

import Description from "./common/description";
import Label from "./common/label";
import PluginWrapper from "./common/pluginWrapper";

import ImageGalleryDialog from "modules/common/components/authoring/dialogs/imageGalleryDialog";
import { Icons } from "modules/common/icons";

import { AttachedFile } from "modules/gallery/models";

import IconButton from "@mui/material/IconButton";

import CloseIcon from "@mui/icons-material/Close";


const Image: React.FunctionComponent<PropsWithRedux> = props => {
  const [showImageGallery, setShowImageGallery] = useState(false);
  return (
    <PluginWrapper>
      <Label>
        {props.field.label}
        {props.field.description &&
          <Description>{props.field.description}</Description>
        }
      </Label>
      {!!props.input.value &&
        <div className="image">
          <img src={props.input.value.fileUrl} alt="" />
          <IconButton onClick={() => props.input.onChange(undefined)} size="large"><CloseIcon /></IconButton>
        </div>
      }
      <p onClick={() => setShowImageGallery(true)} className="empty-image-target">
        <Icons.AddImage width="40px" height="40px" />
        <span className="instruction">Select an image</span>
      </p>
      <ImageGalleryDialog
        show={showImageGallery}
        allowLink
        onApply={(image: AttachedFile[]) => {
          if (!image[0].ospreyId)
            props.input.onChange(image[0]);
          else
            props.getImage(image[0].ospreyId).then((fullsizeImage) => {
              props.input.onChange(fullsizeImage);
            });
        }}
        onClose={() => setShowImageGallery(false)}
      />
    </PluginWrapper>
  );
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: { input, meta, field }) => ({
    ...ownProps
  }),
  {
    getImage: galleryActions.getImage
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(Image);