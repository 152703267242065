import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import * as actions from "modules/documents/actionCreator";

import IconButton from "@mui/material/IconButton";

import ViewListIcon from "@mui/icons-material/ViewList";
import ViewModuleIcon from "@mui/icons-material/ViewModule";


const LayoutSelection: React.FunctionComponent<PropsWithRedux> = props => {
  let option: JSX.Element = (
    <IconButton size="small" onClick={() => props.setDocumentFeedLayout("list")}>
      <ViewListIcon fontSize="small" />
    </IconButton>
  );

  if (props.layout === "list") {
    option = (
      <IconButton size="small" onClick={() => props.setDocumentFeedLayout("card")}>
        <ViewModuleIcon fontSize="small" />
      </IconButton>
    );
  }

  return (
    <div className="layout-selection">{option}</div>
  );
}

interface ComponentProps {}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    layout: state.documents.documentFeed.layout
  }),
  {
    setDocumentFeedLayout: actions.setDocumentFeedLayout
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(LayoutSelection);