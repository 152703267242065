import { messagingApi, reportsApi } from 'api/instances';
import DownloadButton from 'modules/common/components/buttons/downloadButton';
import SnackbarWrapper from 'modules/common/components/snackbars/snackbarWrapper';
import { ReportTypes } from 'modules/reports/models';
import ToolboxButton from 'modules/users/components/toolboxButton';
import UserInsightsBlock from 'modules/users/components/userInsightsBlock';
import React, { useEffect, useState } from 'react';

interface ComponentProps {
    navigateToSettings: () => any;
    userSMSStats: UserSMSStats;
 }

interface UserSMSStats {
    totalMobileNumbers: number,
    optedIn: number,
    pendingOptIns: number,
    optedOut: number,
    invalidNumbers: number
}

const MessagingCenterSMSTab: React.FunctionComponent<ComponentProps> = ({navigateToSettings, userSMSStats}) =>  {

    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [reportRequestState, setReportRequestState] = useState(false);

    const defaultStyles: React.CSSProperties = {
        height: '50px',
        lineHeight: '0px',
        width: '290px',
        textAlign: 'left',
        boxShadow: '0 0 5px #b8b8b8',
        margin: 5,
        borderRadius: 5,
        border: 'none',
        cursor: 'pointer',
        background: 'white',
        color: 'black',
    }

    useEffect(() => {
    }, [successMessage, errorMessage, reportRequestState]);

    const downloadSMSOutbox = async () => {
        setReportRequestState(true);

        try {
            await reportsApi.SendSMSReportRequest(ReportTypes.SMSNotificationReport);
            setSuccessMessage("Your report is being generated and will be emailed to you when it's ready.");
        }
        catch (e) {
            setErrorMessage("Something went wrong. Please try again.");
        }

        setReportRequestState(false);
    }

    return (
        <div className="messaging-center-tab-container">
            <h4>SMS User Count</h4>
            <div className="email-tab-container">
                <div className="center-div">
                    <div className="vertically-center-div">
                        <div className="email-insights">
                            <UserInsightsBlock 
                                title={"Total mobile numbers"}
                                stats={userSMSStats.totalMobileNumbers}
                            />
                            <UserInsightsBlock 
                                title={"Opted-in"}
                                stats={userSMSStats.optedIn}
                            />

                            <UserInsightsBlock 
                                title={"Pending Opted-ins"}
                                stats={userSMSStats.pendingOptIns}
                            />

                            <UserInsightsBlock 
                                title={"Opted-out"}
                                stats={userSMSStats.optedOut}
                            />

                            <UserInsightsBlock 
                                title={"Detected Invalid Numbers"}
                                stats={userSMSStats.invalidNumbers}
                            />
                        </div>
                    </div>
                    <div className="email-toolbox-container">
                        <span className="toolbox-title">SMS Toolbox</span>
                        <div className="toolbox-button-wrapper">    
                            <DownloadButton
                                onClick={() => messagingApi.GetSMSUserCsv() }
                                text={" Download User Data"}
                                downloadName={`notification-sms-user-data`}
                                styles={defaultStyles}
                            />
                            
                            <DownloadButton
                                onClick={() => downloadSMSOutbox()}
                                text={" Download SMS Outbox"}
                                downloadName={`notification-sms-outbox-user-data`}
                                waitingOnRequest={reportRequestState}
                                usingNewReports={true}
                                styles={defaultStyles}
                            />

                            <ToolboxButton 
                                title={"Automated SMS Configurations"}
                                handleClick={() => navigateToSettings()}    
                            />
                            
                        </div>
                    </div>
                </div>
            </div>
            <SnackbarWrapper open={!!successMessage} style={{ top: "60px" }} autoHideDuration={8000} onClose={() => setSuccessMessage("")} severity="success" message={successMessage} />
            <SnackbarWrapper open={!!errorMessage} style={{ top: "60px" }} autoHideDuration={8000} onClose={() => setErrorMessage("")} severity="error" message={errorMessage} />
        </div>
    )
}

export default MessagingCenterSMSTab;