import React, { useRef } from "react";

import Disclaimer from "modules/common/components/disclaimer";
import Loading from "modules/common/components/loading";
import Uploader from "../../common/uploader/uploader";
import * as galleryActions from "modules/gallery/actionCreator";
import * as adminLayoutActions from "modules/adminLayout/actionCreator";
import { useTinyMceAuthoringLogic } from "modules/common/hooks/useTinyMceAuthoringLogic";
import { NewsletterDetails, NewsletterFutureIssueDetailModel, NewsletterTemplateImageType } from "../../models";
import InfoHover from "modules/common/components/hovers/infoHover";
import { AuthoringTinyEditor } from "modules/common/components/authoring/authoringTinyMce";
import { GlobalApplicationState } from "globalApplicationState";

import { ConnectedProps, connect } from "react-redux";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import './styles.sass';
import { Grid, Link, Typography } from "@mui/material";
import IntenseEmphasis from "modules/common/components/authoring/dialogs/intenseEmphasis";

const HeaderFooterComponent: React.FunctionComponent<PropsWithRedux> = ({
    issue,
    newsletter,
    isLoadingFooterImage,
    isLoadingHeaderImage,
    headerImage,
    footerImage,
    setMainStyle,
    setHeaderStyle,
    onError,
    onSetImage,
    onChange,
    ...props
}) => {
    const {
        editorRef: headerRef,
        intenseEmphasisDialogOpen,
        onShowIntenseEmphasisDialog,
        onApplyIntenseEmphasis,
        onHideIntenseEmphasisDialog,
    } = useTinyMceAuthoringLogic(props.getImage);

    const bodyRef = useRef<any>(null);
    const footerRef = useRef<any>(null);

    const onChangeFooterText = (newFooter: string) => {
        onChange({ ...issue, emailTemplateFooterText: newFooter });
    }

    const onChangeHeaderText = (newHeader: string) => {
        onChange({ ...issue, emailTemplateHeaderText: newHeader });
    }

    const onChangeBodyText = (newBody: string) => {
        onChange({ ...issue, bodyText: newBody });
    }

    const onFullscreen = (isFullscreen: boolean) => {
        if (isFullscreen) {
            // one more than side nav bar
            setMainStyle({ zIndex: 3 });
            setHeaderStyle({ display: "none" });
        } else {
            setMainStyle(undefined);
            setHeaderStyle(undefined);
        }
    }

    return (
        <>
            <div className="newsletter-issue-section">
                <div className="newsletter-issue-section-header">Header &amp; Footer</div>
                {newsletter.emailTemplateIsCustom
                    ? 
                    <>
                        <Disclaimer
                            icon={<InfoOutlinedIcon />}
                            text={
                                <React.Fragment>
                                    <span>
                                        Header and Footer images cannot be modified through the Newsletter Editor when using custom templates.
                                        Add or modify custom templates with the help of our Support team. <Link onClick={() => window.open("https://support.sparrowconnected.com/en/how-do-i-create-a-custom-template-for-newsletters")} underline="always">Learn more</Link>.
                                    </span>
                                </React.Fragment>
                            }
                        />

                        <Grid container spacing={2} style={{padding: "20px 0px"}}>
                            <Grid item xs={3}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                <Typography style={{lineHeight:'42px'}}>Body</Typography>
                                <InfoHover>Enter any supporting messages or paragraphs.</InfoHover>
                                </div>
                                </Grid>
                                <Grid item xs={9}>
                                <div style={{ display: "inline"}}>
                                    <AuthoringTinyEditor
                                        initOptions={{
                                            max_height: 300,
                                            min_height: 200,
                                            menubar: false
                                        }}
                                        enableEmbeddedMedia={false}
                                        enableInlineImages={false}
                                        value={issue.bodyText || ""}
                                        onChange={onChangeBodyText}
                                        defaultLcid={props.defaultLcid}
                                        editorRef={bodyRef}
                                        onShowIntenseEmphasisDialog={onShowIntenseEmphasisDialog}
                                    />
                                </div>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} style={{padding: "6px 0px"}}>
                            <Grid item xs={3}>
                                <div>Footer text</div>
                            </Grid>
                            <Grid item xs={9}>
                                <div style={{ display: "inline"}}>
                                    <AuthoringTinyEditor
                                            initOptions={{
                                                max_height: 300,
                                                min_height: 200,
                                                menubar: false
                                            }}
                                            enableEmbeddedMedia={false}
                                            enableInlineImages={false}
                                            value={issue.emailTemplateFooterText || ""}
                                            onChange={onChangeFooterText}
                                            defaultLcid={props.defaultLcid}
                                            editorRef={footerRef}
                                            onShowIntenseEmphasisDialog={onShowIntenseEmphasisDialog}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </>
                    : (
                        <div className="newsletter-issue-details">
                            <div>
                                <div>Header image</div>
                                {issue.emailTemplateHeaderImageIsCustomized && isLoadingHeaderImage
                                    ? <div className="image-loading">
                                        <Loading padding={12} />
                                    </div>
                                    : <div>
                                        <Uploader
                                            imageSrc={headerImage}
                                            imageType="Header"
                                            setImage={onSetImage}
                                            imageHeight={newsletter.emailTemplate?.headerImageHeight || 0}
                                            imageWidth={newsletter.emailTemplate?.headerImageWidth || 0}
                                            onError={onError}
                                        />
                                    </div>
                                }
                            </div>
                            <div>
                                <div>Header text</div>
                                <div style={{ display: "inline" }}>
                                    <AuthoringTinyEditor
                                        initOptions={{
                                            max_height: 300,
                                            min_height: 200,
                                            menubar: false
                                        }}
                                        enableEmbeddedMedia={false}
                                        enableInlineImages={false}
                                        value={issue.emailTemplateHeaderText || ""}
                                        onChange={onChangeHeaderText}
                                        defaultLcid={props.defaultLcid}
                                        editorRef={headerRef}
                                        onShowIntenseEmphasisDialog={onShowIntenseEmphasisDialog}
                                        onFullscreen={onFullscreen}
                                    />
                                </div>
                            </div>

                            <div>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Typography style={{ lineHeight: '42px' }}>Body</Typography>
                                    <InfoHover>Enter any supporting messages or paragraphs.</InfoHover>
                                </div>
                                <div style={{ display: "inline" }}>
                                    <AuthoringTinyEditor
                                        initOptions={{
                                            max_height: 300,
                                            min_height: 200,
                                            menubar: false
                                        }}
                                        enableEmbeddedMedia={false}
                                        enableInlineImages={false}
                                        value={issue.bodyText || ""}
                                        onChange={onChangeBodyText}
                                        defaultLcid={props.defaultLcid}
                                        editorRef={bodyRef}
                                        onShowIntenseEmphasisDialog={onShowIntenseEmphasisDialog}
                                    />
                                </div>
                            </div>
                            <div>
                                <div>Footer image</div>
                                {issue.emailTemplateFooterImageIsCustomized && isLoadingFooterImage
                                    ? <div className="image-loading">
                                        <Loading padding={12} />
                                    </div>
                                    : <div>
                                        <Uploader
                                            imageSrc={footerImage}
                                            imageType="Footer"
                                            setImage={onSetImage}
                                            imageHeight={newsletter.emailTemplate?.footerImageHeight || 0}
                                            imageWidth={newsletter.emailTemplate?.footerImageWidth || 0}
                                            onError={onError}
                                        />
                                    </div>
                                }
                            </div>
                            <div>
                                <div>Footer text</div>
                                <div style={{ display: "inline" }}>
                                    <AuthoringTinyEditor
                                        initOptions={{
                                            max_height: 300,
                                            min_height: 200,
                                            menubar: false
                                        }}
                                        enableEmbeddedMedia={false}
                                        enableInlineImages={false}
                                        value={issue.emailTemplateFooterText || ""}
                                        onChange={onChangeFooterText}
                                        defaultLcid={props.defaultLcid}
                                        editorRef={footerRef}
                                        onShowIntenseEmphasisDialog={onShowIntenseEmphasisDialog}
                                    />
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
            <IntenseEmphasis show={intenseEmphasisDialogOpen} onApply={onApplyIntenseEmphasis} onClose={onHideIntenseEmphasisDialog} />
        </>
    );
}

interface ComponentProps {
    issue: NewsletterFutureIssueDetailModel;
    newsletter: NewsletterDetails;
    footerImage: File | null;
    headerImage: File | null;
    isLoadingFooterImage?: boolean;
    isLoadingHeaderImage?: boolean;
    onError: (message: string) => any;
    onChange: (issue: NewsletterFutureIssueDetailModel) => void;
    onSetImage: (imageType: NewsletterTemplateImageType, image?: File) => void;
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        defaultLcid: state.settings.tenantSettings.defaultLCID,
    }),
    {
        getImage: galleryActions.getImage,
        setMainStyle: adminLayoutActions.setMainStyle,
        setHeaderStyle: adminLayoutActions.setHeaderStyle
    }
);

type PropsWithRedux = ConnectedProps<typeof connector>;
export const HeaderFooter = connector(HeaderFooterComponent);
