import * as React from "react";


class Body extends React.Component<ComponentProps, ComponentState> {
  public render() {
    if (!this.props.body)
      return <React.Fragment></React.Fragment>;
    
    return (
      <div dangerouslySetInnerHTML={{ __html: this.props.body }} className="body"></div>
    );
  }
}


interface ComponentProps {
  body: string;
}

interface ComponentState {}

export default Body;