import * as React from 'react';
import { ConnectedProps, connect } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
 
interface ComponentProps { 
    returnTranslatedContent: (any) => any;
    maxTextLength?: number;
    disabled?: boolean;
    placeholderTop?: string;
    placeholderBot?: string;
    event: string;
}

interface ComponentState {
    defaultLCID: string;
    selectedLCID: string;
}

class MobileTextAreaInput extends React.Component<PropsWithRedux, ComponentState> {
    constructor(props) {
        super(props);
        this.state = {
            defaultLCID: this.props.tenantSettings.defaultLCID,
            selectedLCID: this.props.tenantSettings.defaultLCID,
        }
    }
    
    public componentDidMount()  {
        this.selectLanguage(this.props.tenantSettings.defaultLCID);
    }

    public componentDidUpdate() {
        var toMap = this.props.notificationSettings.settings[this.props.event].translatedContent;

        if(!Object.keys(toMap).includes(this.state.selectedLCID))
        {
            this.setState({selectedLCID: this.state.defaultLCID})
        }
    }
    
    public render() {
        const placeholderTop = this.props.placeholderTop ?? "";
        const placeholderBot = this.props.placeholderBot ?? "";
        
        const lcidToLanguage = {};
        Object.keys(this.props.lcidMappings).forEach(k => lcidToLanguage[k] = this.props.lcidMappings[k].language)

        const availableLCID = Object.keys(this.props.lcid);
        const lcidButtons = availableLCID.map((lcid) => {
            return (
                <span
                    key={`${lcid}-buton`}
                    onClick={() => this.selectLanguage(lcid)}
                    className={`lcid-options-dropdown`}>
                    {lcidToLanguage[lcid]} ({lcid})
                </span>
            )
        })

        const toMap = this.props.notificationSettings.settings[this.props.event].translatedContent;

        const tabs = Object.keys(toMap).map((lcid) => {
            const active = (lcid === this.state.selectedLCID) ? "active" : ""
            return (
                <span
                key={`${lcid}-tab`}
                onClick={() => this.selectLanguageTab(lcid)}
                className={`text-area-lcid-tab ${active}`}>

                    {lcidToLanguage[lcid]} ({lcid}) 
                    {lcid !== this.state.defaultLCID ?
                    <i 
                        style={{marginLeft: 5, cursor: "pointer", color: "#2f4050"}}
                        className="material-icons"
                        onClick={(e) => {
                            e.stopPropagation()
                            this.removeLcid(lcid)}}
                    >delete</i>
                    : null
                    }
                </span>
            )
        })
        return (

            <React.Fragment>
                <div className="language-section">
                    {tabs}
                    <div className="language-button-selector">
                        <button className="dropdown-button-lcid">+</button>
                        <div className="dropdown-btn-content-lcid">
                            {lcidButtons}
                        </div>
                    </div>                    
                </div>
                <div className="text-area-container-small">
                    <div className="text-area-container-single">
                        <span className="text-area-prompt">Title</span>
                        <textarea
                            value={this.props.notificationSettings.settings[this.props.event].translatedContent[this.state.selectedLCID] === undefined ? "" : this.props.notificationSettings.settings[this.props.event].translatedContent[this.state.selectedLCID].m_Item1}
                            className="text-area-language-double-body" 
                            onChange={(e) => this.handleTextArea(e, "top")}
                            maxLength={this.props.maxTextLength}
                            disabled={this.props.disabled}
                            placeholder={placeholderTop}>
                        </textarea>
                    </div>
                    <div className="text-area-container-single">
                        <span className="text-area-prompt">Description</span>
                        <textarea
                            value={this.props.notificationSettings.settings[this.props.event].translatedContent[this.state.selectedLCID] === undefined ? "" : this.props.notificationSettings.settings[this.props.event].translatedContent[this.state.selectedLCID].m_Item2}
                            className="text-area-language-double-body" 
                            onChange={(e) => this.handleTextArea(e, "bot")}
                            maxLength={this.props.maxTextLength}
                            disabled={this.props.disabled}
                            placeholder={placeholderBot}>
                        </textarea>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    private removeLcid = (lcid: string) => {
        var newState = this.props.notificationSettings.settings[this.props.event].translatedContent;

        delete newState[lcid];
        this.setState({selectedLCID: this.props.tenantSettings.defaultLCID})
        this.props.returnTranslatedContent(newState)
    }

    private selectLanguageTab = (lcid: string) => {
        this.setState({
            ...this.state,
            selectedLCID: lcid,
        })
    }

    private selectLanguage = (lcid: string) => {
       var newState = this.props.notificationSettings.settings[this.props.event].translatedContent;
       if (!Object.keys(newState).includes(lcid)){
           newState[lcid] = {
               m_Item1: "",
               m_Item2: ""
           };
           this.props.returnTranslatedContent(newState);
           this.selectLanguageTab(lcid);
       } else {
           this.selectLanguageTab(lcid);
       }
    }

    public handleTextArea = (e: any, location: string) => {
        let toEdit;
        let otherItem;
        switch(location) {
            case "top":
                toEdit = "m_Item1"
                otherItem = "m_Item2"
                break;
            
            case "bot":
                toEdit = "m_Item2"
                otherItem = "m_Item1"
                break;

            default:
                console.log("Critical error, called function with invalid values. Defaulting to top.")
                toEdit = "m_Item1"
                otherItem = "m_Item2"
                break;
        }

        const clone = this.props.notificationSettings.settings[this.props.event].translatedContent;
        clone[this.state.selectedLCID][toEdit] = e.target.value
        clone[this.state.selectedLCID][otherItem] = clone[this.state.selectedLCID][otherItem] ?? ""

        this.props.returnTranslatedContent(clone);
    }
}
 
const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        notificationSettings: state.settings.notificationSettings,
        tenantSettings: state.settings.tenantSettings,
        lcidMappings: state.resources.lcidMappings,
        lcid: state.settings.tenantSettings.translatedContent,
    }),
    {
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default (connector(MobileTextAreaInput));