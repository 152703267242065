import { TenantSettingsTagGroup } from "modules/settings";
import { useEffect, useState } from "react";
import useIsMounted from "../useIsMounted";
import { tagsApi } from "api/instances";

export type UseAuthoringTagsReturnType = {
    tags: TenantSettingsTagGroup[];
};

export const useAuthoringTags = (): UseAuthoringTagsReturnType => {
    const isMounted = useIsMounted();
    const [tags, setTags] = useState<TenantSettingsTagGroup[]>([]);

    useEffect(() => {
        const getTags = async () => {
            let tagsToSet: TenantSettingsTagGroup[] = [];

            try {
                tagsToSet = await tagsApi.getUserTags(true);
            } finally {
                if (isMounted()) setTags(tagsToSet);
            }
        };

        getTags();
    }, [isMounted]);

    return {
        tags,
    };
};
