import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";


import "./breakingFeaturedBanner.sass";
import { RouteComponentProps, withRouter } from "react-router";


interface IBanner {
  color: string;
  text: string;
  textStyle?: React.CSSProperties;
  description: string;
}

type BannerTypes = "breaking" | "featured";

type Banners = { [bannerType in BannerTypes]: IBanner; };

const Banner: React.FunctionComponent<{banner: IBanner, detailed?: boolean}> = (props) => {
  const { banner } = props;

  return (
    <div className={`breaking-featured-banner ${props.detailed ? "detailed" : ""}`}>
      <div style={{ backgroundColor: banner.color }} className="breaking-featured-banner-text">
        <span style={{ ...banner.textStyle }}>{banner.text}</span>
      </div>
      {props.detailed &&
        <div style={{ borderLeftColor: banner.color, color: banner.color }} className="breaking-featured-banner-description">
          {banner.description}
        </div>
      }
    </div>
  );
}


class BreakingFeaturedBanner extends React.Component<PropsWithRedux, ComponentState> {
  public render() {
    const { specialtyPostColors, specialtyPostColorAdmin } = this.props;

    const colorsToUse = this.props.history.location.pathname.includes("/admin")
        ? specialtyPostColorAdmin
        : specialtyPostColors;

    if (!colorsToUse || (!this.props.isBreaking && !this.props.isFeatured))
      return (null);

    const banners: Banners = {
      breaking: {
        color: (colorsToUse && colorsToUse.breaking) || "#b72026",
        text: this.onGetTranslatedText("breakingPost") || "BREAKING",
        textStyle: { fontWeight: 700 },
        description: this.onGetTranslatedText("breakingBannerDescription") || "Breaking News! Share with colleagues, and spread the word."
      },
      featured: {
        color: (colorsToUse && colorsToUse.featured) || "#599fa0",
        text: this.onGetTranslatedText("featuredPost") || "FEATURED",
        description: this.onGetTranslatedText("featuredBannerDescription") || "Keep in the loop and get the latest facts with our featured news and updates."
      }
    };

    return (
      <React.Fragment>
        {this.props.isBreaking
          ? this._breakingBanner(banners.breaking, this.props.detailed)
          : this._featuredBanner(banners.featured, this.props.detailed)
        }
      </React.Fragment>
    );
  }

  private _breakingBanner = (banner: IBanner, detailed?: boolean) => {
    if (!this.props.isBreaking)
      return (null);

    return (
      <Banner banner={banner} detailed={detailed} />
    );
  }

  private _featuredBanner = (banner: IBanner, detailed?: boolean) => {
    if (!this.props.isFeatured)
      return (null);

    return (
      <Banner banner={banner} detailed={detailed} />
    );
  }

  private onGetTranslatedText = (field: string) => {
    const { activeLcid, configTranslations, defaultLCID } = this.props;

    if (configTranslations[activeLcid])
      return configTranslations[activeLcid][field];
    else if (configTranslations[defaultLCID])
      return configTranslations[defaultLCID][field];

    return "";
  }
}


interface ComponentProps {
  activeLcid: string;
  detailed?: boolean;
  isBreaking?: boolean;
  isFeatured?: boolean;
}

interface ComponentState {
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    configTranslations: state.settings.clientSettings.translatedContent,
    defaultLCID: state.settings.clientSettings.defaultLCID,
    specialtyPostColors: state.settings.clientSettings.specialtyPostColors,
    specialtyPostColorAdmin: state.settings.tenantSettings.specialtyPostColors,
  }),
  {
  }
);
type PropsWithRedux = ConnectedProps<typeof connector> & RouteComponentProps<{}>;
export default withRouter(connector(BreakingFeaturedBanner));

