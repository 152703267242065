import React from "react";
import { connect, ConnectedProps } from "react-redux";
import * as actions from "../../actionCreator";
import { GlobalApplicationState } from "globalApplicationState";

import SaveAltIcon from '@mui/icons-material/SaveAlt';

import { Button } from "@mui/material";
import LoadingSpinner from "modules/common/components/loadingSpinner";
import { FileDownloader } from "utils/fileDownloader";

const DownloadReports: React.FunctionComponent<PropsWithRedux> = props => {
  const [downloading, setIsDownloading] = React.useState(false);

  const getTagsForDownload = async () => {
    setIsDownloading(true);
    const file = {
      name: `AllCategoryTags-${new Date().toISOString()}.csv`
    };
  
    props.downloadAllReports()
      .then((tags) => new FileDownloader(file).downloadBlob(tags))
      .then(_ => setIsDownloading(false))
      .catch(_ => setIsDownloading(false))
  }

  return (
    <div>
      <Button
        startIcon={downloading ? <LoadingSpinner size={"20px"}/> : <SaveAltIcon/>}  
        onClick={() => getTagsForDownload()}
        variant={"outlined"}
        color={"primary"}
      >
          Download reports
      </Button>
    </div>
  );

}

interface ComponentProps {
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps
  }),
  {
    downloadAllReports: actions.downloadAllCategoryTags
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(DownloadReports);