import { FilterGroupName, MediaFiltersModel, MoveFiles, SortGroupName } from "modules/gallery";
import { ROOT_FOLDER_ID } from "modules/gallery/chonkyFileAdapter";
import * as actions from "network/actions";

export const FetchImages = (
    forGallery?: boolean, 
    pageAmount?: number, 
    pageNumber?: number, 
    mediaFilter?: MediaFiltersModel
) => {
    const queryParams = {};

    const filterType = Object.values(FilterGroupName).indexOf(mediaFilter?.filterType ?? FilterGroupName.AllImages);
    const sortType = Object.values(SortGroupName).indexOf(mediaFilter?.sortType ?? SortGroupName.Newest);

    queryParams["forGallery"] = !!forGallery;
    queryParams["pageAmount"] = pageAmount;
    queryParams["pageNumber"] = pageNumber;
    queryParams["parentId"] = mediaFilter?.parentId ?? "";
    queryParams["searchText"] = mediaFilter?.searchText ?? "";
    queryParams["filterType"] = filterType !== -1 ? filterType : "";
    queryParams["sortType"] = sortType !== -1 ? sortType : "";
    queryParams["showFoldersFirst"] = mediaFilter?.showFoldersFirst ?? "";

    const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
    };

    return actions.NetworkRequest({
        headers,
        method: "GET",
        url: "/{tenant}/ospreyapi/v1/images",
        queryParams,
    });
}

export const FetchImageByName = (fileName: string, type: string, parentId: string = "", asChonkyFileData: boolean = false) => {
    const queryParams = {};
    queryParams["fileName"] = fileName;
    queryParams["fileType"] = type
    queryParams["parentId"] = parentId;
    queryParams["parseAsChonkyFileData"] = asChonkyFileData;

    return actions.NetworkRequest({
        method: "GET",
        url: "/{tenant}/ospreyapi/v1/images/fileName",
        queryParams,
    });
}

export const FetchFolderByName = (fileName: string, parentId: string = "", asChonkyFileData: boolean = false) => {
    const queryParams = {};
    queryParams["fileName"] = fileName;
    queryParams["parentId"] = parentId;
    queryParams["parseAsChonkyFileData"] = asChonkyFileData;

    return actions.NetworkRequest({
        method: "GET",
        url: "/{tenant}/ospreyapi/v1/folders",
        ...{ queryParams },
    });
}

export const UploadFolder = (fileName: string, parentId: string = "") => {
    const queryParams = {};
    queryParams["fileName"] = fileName;
    queryParams["parentId"] = parentId;

    return actions.NetworkRequest({
        method: "POST",
        url: "/{tenant}/ospreyapi/v1/folders",
        ...{ queryParams },
    });
}

export const GetUniqueFileName = (fileName: string, fileType: string, parentId: string = "", abortSignal?: AbortSignal) => {
    const queryParams = {};
    queryParams["fileName"] = fileName;
    queryParams["fileType"] = fileType;
    queryParams["parentId"] = parentId;

    return actions.NetworkRequest({
        method: "GET",
        url: "/{tenant}/ospreyapi/v1/images/generateFileName",
        queryParams,
        abortSignal
    });
}

export const GetUniqueFolderName = (folderName: string, parentId: string = "") => {
    const queryParams = {};
    queryParams["folderName"] = folderName;
    queryParams["parentId"] = parentId;

    return actions.NetworkRequest({
        method: "GET",
        url: "/{tenant}/ospreyapi/v1/folders/generateFolderName",
        queryParams,
    });
}

export const DeleteImageByName = (fileName: string, fileType: string, parentId: string = "", abortSignal?: AbortSignal) => {
    const queryParams = {};
    queryParams["fileName"] = fileName;
    queryParams["fileType"] = fileType;
    queryParams["parentId"] = parentId;

    return actions.NetworkRequest({
        method: "DELETE",
        url: "/{tenant}/ospreyapi/v1/images/filename",
        queryParams,
        abortSignal
    });
}

export const DeleteFolderByName = (folderName: string, parentId?: string) => {
    const queryParams = {};
    queryParams["folderName"] = folderName;
    queryParams["parentId"] = parentId ?? "";

    return actions.NetworkRequest({
        method: "DELETE",
        url: "/{tenant}/ospreyapi/v1/folders",
        queryParams,
    });
}

export const FetchDocumentForId = (id: string) => {
    return actions.NetworkRequest({
        method: "GET",
        url: "/{tenant}/ospreyapi/v1/files/doc/{id}",
        params: {id: id}
    });
}

export const FetchDocuments = (continuationToken?: string, forDirectory?: boolean) => {
    const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
    };


    return actions.NetworkRequest({
        headers,
        method: "POST",
        url: '/{tenant}/ospreyapi/v1/files',
        body: {continuationToken: continuationToken },
    });
}

export const FetchImageForId = (id: string) => {
    return actions.NetworkRequest({
        method: "GET",
        url: "/{tenant}/ospreyapi/v1/images/{id}",
        params: {id: id}
    });
}

export const GetImageGallery = (pageNumber: number, includeVideos: boolean) => {
    return actions.NetworkRequest({
        headers: { "content-type": "application/json" },
        method: "GET",
        url: `/{tenant}/ospreyapi/v1/images/pagedGallery?pageNumber=${pageNumber}&includeVideos=${includeVideos}`,
    });
}

export const UploadImage = (file: File, parentId: string = "", abortSignal?: AbortSignal) => {
    const body = new FormData();
    body.append("image", file);

    return actions.NetworkRequest({
        method: "POST",
        url: `/{tenant}/ospreyapi/v1/images/upload?fileName=${file.name}&parentId=${parentId}`,
        body,
        abortSignal,
    });
};

export const DeleteImage = (id: string) => {
    return actions.NetworkRequest({
        method: "DELETE",
        url: `/{tenant}/ospreyapi/v1/images/${id}`,
    });
};

export const DeleteBulkImages = (ids: string[]) => {
    const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
    };

    const body = {
        ids
    }

    return actions.NetworkRequest({
        headers,
        method: "DELETE",
        url: `/{tenant}/ospreyapi/v1/images/bulk`,
        body: JSON.stringify(body)
    });
};

export const FetchVideoForId = (id: string) => {
    return actions.NetworkRequest({
        method: "GET",
        url: "/{tenant}/ospreyapi/v1/videos/{id}",
        params: {id: id}
    });
}

export abstract class VideoUploads {
    static PrepareUpload = (fileName: string) => {
        return actions.NetworkRequest({
            method: "POST",
            url: "/{tenant}/ospreyapi/v1/videos/upload/new",
            queryParams: {
                fileName,
            },
        });
    };

    static UploadChunk = (data: Blob, chunkNumber: number, uploadId: string) => {
        const body = new FormData();
        body.append("video", data);
        return actions.NetworkRequest({
            method: "PUT",
            url: `/{tenant}/ospreyapi/v1/videos/upload/${uploadId}`,
            body: body,
            headers: {
                "chunk-number": chunkNumber,
            },
        });
    };

    static CommitUpload = (uploadId: string) => {
        return actions.NetworkRequest({
            method: "POST",
            url: `/{tenant}/ospreyapi/v1/videos/upload/${uploadId}`,
        });
    };

    static AbortUpload = (uploadId: string) => {
        return actions.NetworkRequest({
            method: "DELETE",
            url: `/{tenant}/ospreyapi/v1/videos/upload/${uploadId}`,
        });
    };

    static UploadImageThumbnail = (blob: Blob, videoId: string) => {
        let body = new FormData();
        body.append("image", blob);
        return actions.NetworkRequest({
            method: "POST",
            url: `/{tenant}/ospreyapi/v1/videos/upload/${videoId}/thumb`,
            body: body,
        });
    };
}

export const UploadDocument = (file: File) => {
    const body = new FormData();
    body.append("document", file);
    return actions.NetworkRequest({
        method: "POST",
        url: `/{tenant}/ospreyapi/v1/files/upload?fileName=${file.name}`,
        body,
    });
};

export const UploadSupportingDocument = (file: File, type: string) => {
    const body = new FormData();
    body.append("document", file);
    return actions.NetworkRequest({
        method: "POST",
        url: `/{tenant}/ospreyapi/v1/documents/upload?type=${type}&fileName=${file.name}`,
        body,
    });
};

export const RenameImage = (id: string, newImageName: string) => {
    const queryParams = {};

    queryParams["fileName"] = newImageName;

    const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
    };

    return actions.NetworkRequest({
        headers,
        method: "PUT",
        url: `/{tenant}/ospreyapi/v1/images/${id}/filename`,
        queryParams,
    });
}

export const RenameFolder = (id: string, newFolderName: string) => {
    const queryParams = {};

    queryParams["folderName"] = newFolderName;

    const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
    };

    return actions.NetworkRequest({
        headers,
        method: "PUT",
        url: `/{tenant}/ospreyapi/v1/folders/${id}`,
        queryParams,
    });
}

export const GetFolderAndImageCount = (folderId: string) => {
    const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
    };

    return actions.NetworkRequest({
        headers,
        method: "GET",
        url: `/{tenant}/ospreyapi/v1/folders/${folderId}/folderAndImageCount`
    });
}

export const GetBulkFolderAndImageCount = (folderIds: string[]) => {
    const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
    };

    const body = {
        ids: folderIds
    }

    return actions.NetworkRequest({
        headers,
        method: "POST",
        url: `/{tenant}/ospreyapi/v1/folders/bulkFolderAndImageCount`,
        body: JSON.stringify(body)
    });
}

export const DeleteFolder = (folderId: string) => {
    const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
    };

    return actions.NetworkRequest({
        headers,
        method: "DELETE",
        url: `/{tenant}/ospreyapi/v1/folders/${folderId}`
    });
}

export const DeleteBulkFolders = (folderIds: string[]) => {
    const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
    };

    const body = {
        ids: folderIds
    }

    return actions.NetworkRequest({
        headers,
        method: "DELETE",
        url: `/{tenant}/ospreyapi/v1/folders/bulk`,
        body: JSON.stringify(body)
    });
}

export const MoveMedia = (movePayload: MoveFiles) => {
    let body = {
        mediaToMove: movePayload.filesToMove.map(i => i.id)
    };

    if (movePayload.moveDest && movePayload.moveDest.id !== ROOT_FOLDER_ID) body["destinationFolderId"] = movePayload.moveDest.id;
    if (movePayload.moveSrc && movePayload.moveSrc.id !== ROOT_FOLDER_ID) body["sourceFolderId"] = movePayload.moveSrc.id;

    const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
    };

    return actions.NetworkRequest({
        headers,
        method: "PUT",
        url: `/{tenant}/ospreyapi/v1/folders/move`,
        body: JSON.stringify(body)
    });
}

export const SetImageKeywords = (imageId: string, keywords: string[]) => {
    let body = { keywords };

    const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
    };

    return actions.NetworkRequest({
        headers,
        method: "POST",
        url: `/{tenant}/ospreyapi/v1/images/${imageId}/keywords`,
        body: JSON.stringify(body)
    });
}