import * as React from 'react';
import { LineChart, ILineChartProps, ILineChartDataPoint } from './charts/lineChart';
import { IAwarenessScore } from './postAwarenessCalculator';
import WindowResizeListener from 'modules/common/components/window-resize-listener';
import {FormattedMessage} from "react-intl";

interface Props {
    awarenessScore: IAwarenessScore[];
    title: string;
    xLabel: string;
    locale: string;
}

export default class AwarenessVisualization extends React.PureComponent<Props, {}> {

    private _barChart: LineChart;
    private chart: HTMLElement

    private _dataPoints: ILineChartDataPoint[] = [] as ILineChartDataPoint[];

    public componentWillMount() {
        this._dataPoints = this.processReadStatsToBuildAwarenessDataPoints(this.props.awarenessScore);
    }

    public componentDidMount() {
        this._barChart = new LineChart(this.getChartState());
    }

    public componentWillUpdate(nextProps, state) {
        if (this._barChart) {
            this._dataPoints = this.processReadStatsToBuildAwarenessDataPoints(nextProps.awarenessScore);
        }
    }

    public componentDidUpdate() {
        if (this._barChart) {
            this._barChart.update(this.getChartState());
        }
    }

    public componentWillUnmount() {
        if (this._barChart) {
            this._barChart.destroy();
        }
    }

    private BLUR_FACTOR: number = 7

    public render() {
        return <div className="post-awareness">
            <h3>{this.props.title}</h3>
            {
                this._dataPoints.length > this.BLUR_FACTOR 
                ?   <div className="awareness-graph-wrapper">
                        <div ref={e => this.chart = e!}></div>
                        <span className="xLabel">{this.props.xLabel}</span>
                    </div> 

                :   <div className="awareness-graph-wrapper">
                        <FormattedMessage id="insights.notEnoughData" defaultMessage="Not enough data" />
                    </div>
            }
            <WindowResizeListener onChange={this.handleWindowResize} />
        </div>
    }

    private getChartState = (): ILineChartProps => {
        return {
            el: this.chart,
            data: this.blurDataPoints(this._dataPoints, this.BLUR_FACTOR),
            height: 250,
            maxY: 100,
            numXTicks: 6,
            locale: this.props.locale
        } as ILineChartProps;
    }

    private blurDataPoints = (points: ILineChartDataPoint[], level): ILineChartDataPoint[] => {
        return points.filter((point, index) => {
            return index % level === 0;
        });
    }

    private processReadStatsToBuildAwarenessDataPoints = (score: IAwarenessScore[]): ILineChartDataPoint[] => {
        let awarenessPoints = score || [];
        let graphPoints = [] as ILineChartDataPoint[];

        for (let i = 0; i < awarenessPoints.length; i++) {
            let point = awarenessPoints[i];
            graphPoints.push({
                x: point.x,
                y: point.y,
                label: point.x.toString()
            })
        }

        return graphPoints;
    }

    private handleWindowResize = () => {
        this._dataPoints = this.processReadStatsToBuildAwarenessDataPoints(this.props.awarenessScore);
        this._barChart.update(this.getChartState());
    }
}
