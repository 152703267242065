import * as Actions from './actions';
import { PostsState } from './models';
import { Reducer } from './index';

export const actionHandler: Reducer<PostsState> = {

    [Actions.FETCH_IDEAL_WORD_COUNT]: (state: PostsState, action: Actions.FetchIdealWordCount) => {
        return {
            ...state,
            tips: {
                ...state.tips,
                loading: true,
                dismissed: false
            }
        }
    },

    [Actions.FETCH_IDEAL_WORD_COUNT_COMPLETE]: (state: PostsState, action: Actions.FetchIdealWordCountComplete) => {
        return {
            ...state,
            tips: {
                ...state.tips,
                loading: false,
                idealWordCount: action.succeeded ? action.idealWordCount : state.tips.idealWordCount
            }
        }
    },

    [Actions.DISMISS_IDEAL_WORD_COUNT_TIP]: (state: PostsState, action: Actions.FetchIdealWordCountComplete) => {
        return {
            ...state,
            tips: {
                ...state.tips,
                loading: false,
                dismissed: true
            }
        }
    },
}

