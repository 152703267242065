import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import InfiniteScrollView from "modules/common/components/table-view/components/infinite-scroll-view";
import Loading from 'modules/common/components/loading';
import LoadingOverlay from "modules/common/components/loadingOverlay";
import TenantLink from 'modules/common/components/tenantLink';
import {FormattedMessage} from "react-intl";

import { GlobalApplicationState } from 'globalApplicationState';
import { FlaggedComment } from "../models";
import FlaggedListItem from "./flaggedListItem";
import "./flaggedItemsManagement.sass";
import { activityApi, commentApi } from 'api/instances';

class FlaggedItemsManagement extends React.Component<PropsWithRedux, State> {
    private _isMounted = false;

    constructor(props)
    {
        super(props);
        this.state = {
            fetchingItems: false,
        processingIgnoreAction: null,
        processingDeleteAction: null,
            flaggedItems: []
        }
    }
    
    public componentDidMount() { 
        this._isMounted = true;
        this.fetchFlaggedItems();       
    }

    public componentWillUnmount() {
        this._isMounted = false;
    }

    private shouldShowLoadingOverlay = () => {
        return this.state.fetchingItems || this.props.fetchingSettings;
    }

    public render() {
        let itemData = {}
        this.state.flaggedItems.forEach(item => {
            itemData[item.commentId] = item
        })

        const loadMoreFallback = (
            <div>
                <p style={{textAlign: 'center'}}>
                    {
                        this.state.fetchingItems?
                        <span>Loading...</span>
                        : <button className="load-fallback" onClick={() => 
                                this.fetchFlaggedItems()}>Load More</button>
                    }
                </p>
            </div>
        );

      return(
          <div>
              {
                 this.props.moderationEnabled || this.shouldShowLoadingOverlay() ? null :
                  <p style={{marginLeft: '3%', marginBottom: '20px'}} className="alert-info wrapped">If you want to moderate flagged items, moderation must be enabled. Moderation can be enabled  
                    by turning on "Allow Owners to delete Comments" <TenantLink to="~/admin/settings">here</TenantLink> 
                  </p>
              }
              {
                    this.shouldShowLoadingOverlay() ? null :
                    <InfiniteScrollView
                        usesWindowScroll={true}
                        distanceThreshold={500}
                        hasNext={false}//todo when we move to using continuation
                        loading={this.state.fetchingItems && !this.shouldShowLoadingOverlay()}
                        onThresholdCrossed={this.threshHoldCrossed} //todo when we move to using continuation
                        loadingBlockRenderer={() => this.shouldShowLoadingOverlay() ? null : <Loading padding={12} />}
                        loadingBlockFallbackRenderer={() => loadMoreFallback}
                    >
                        <table className="items-table">
                            <thead>
                                <tr>
                                    <th>
                                        <FormattedMessage id="activity.commentInfo" defaultMessage="Comment Information" />
                                    </th>
                                    <th>    
                                    <FormattedMessage id="activity.actions" defaultMessage="Actions" />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.flaggedItems.length === 0 && !this.state.fetchingItems ?
                                    <tr>
                                        <td colSpan={2} style={{padding: '10px', textAlign: 'center'}}>
                                            There are currently no flagged comments
                                        </td>
                                    </tr>
                                    : this.state.flaggedItems.map(item =>
                                        <FlaggedListItem 
                                                key={item.commentId}
                                                commentItem={item} 
                                                ignoreClick={this.IgnoreFlaggedItem}
                                                deleteClick={this.DeleteComment}
                                                moderationEnabled={this.props.moderationEnabled}
                                                processingIgnore={this.state.processingIgnoreAction}
                                                processingDelete={this.state.processingDeleteAction}
                                                tenantId={this.props.tenantId}/>
                                        )
                                }

                            </tbody>
                        </table>
                    </InfiniteScrollView>
                }
                <LoadingOverlay absolute={true} show={this.shouldShowLoadingOverlay()} />
          </div>
      );
    }

    private threshHoldCrossed = () => null //todo when we move to using continuation

    private fetchFlaggedItems = () => {
        this.setState(prev => ({...prev, fetchingItems: true }));
        activityApi.GetFlaggedItems()
            .then(flaggedItems => {
                if (this._isMounted) {
                    this.setState(prev => ({...prev, flaggedItems, fetchingItems: false}) )
                }
            })
            .catch(err => {
                this.setState(prev => ({...prev, fetchingItems: false }));
            }) 
    }

    private IgnoreFlaggedItem = (id: string) => {
        this.setState(prev => ({...prev, processingIgnoreAction: id }));
        let updatedFlaggedList = this.state.flaggedItems.filter(i => i.commentId !== id);
        activityApi.IgnoreFlag(id)
        .then(result => {
            this.setState(prev => ({...prev, flaggedItems: updatedFlaggedList, processingIgnoreAction: null }));
        })
        .catch(err => {
            if (err.response.status === 404 || err.response.status === 400) {
                this.setState(prev => ({...prev, flaggedItems: updatedFlaggedList, processingIgnoreAction: null }));
            }
            else {
                this.setState(prev => ({...prev, processingIgnoreAction: null }));
            }
        }) 
    }

    private DeleteComment = (id: string) => {
        this.setState(prev => ({...prev, processingDeleteAction: id }));
        let updatedFlaggedList = this.state.flaggedItems.filter(i => i.commentId !== id);

        commentApi.DeleteComment(id)
            .then(_ => {
                this.setState(prev => ({...prev, flaggedItems: updatedFlaggedList, processingDeleteAction: null }));
            })
            .catch(err => {
                if (err.response.status === 404) {
                    this.setState(prev => ({...prev, flaggedItems: updatedFlaggedList, processingDeleteAction: null }));
                }
                else {
                    this.setState(prev => ({...prev, processingDeleteAction: null }));
                }
        })
    }
}

interface State {
    fetchingItems: boolean
    flaggedItems: FlaggedComment[]
    processingIgnoreAction: string | null
    processingDeleteAction: string | null
}

interface ComponentProps {
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps)=>({ 
        ...ownProps,
        moderationEnabled: state.settings.tenantSettings.commentModerationEnabled,
        fetchingSettings: state.settings.fetching,
        tenantId: state.tenant.id
    })
);
type PropsWithRedux = ConnectedProps<typeof connector>;
export default connector(FlaggedItemsManagement);