import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import * as actions from "modules/posts/actionCreator";

import Loading from "modules/common/components/loading";
import { PostFeedFilters, PostFeedItem, Tag } from "../../models";
import Card from "./components/layouts/card/Card";
import Column from "./components/layouts/column/Column";
import Window from "./components/layouts/window/Window";
import List from "./components/layouts/list/List";
import { DialogPostView } from "../post-view/dialogPostView";

import "./styles/NewsFeed.sass";

class PostFeed extends React.Component<PropsWithRedux, ComponentState> {
    _isMounted = false;

    constructor(props: PropsWithRedux) {
        super(props);

        this.state = {
            isFetching: true,
            postsFeed: [],
            selectedPostId: ""
        };
    }

    public componentDidMount() {
        this._isMounted = true;
        this.fetchFeed();
    }

    public componentWillUnmount() {
        this._isMounted = false;
    }

    public componentDidUpdate(prevProps: PropsWithRedux) {
        if (this.props.ignoreSubscriptions !== prevProps.ignoreSubscriptions || this.props.lockSubscriptions !== prevProps.lockSubscriptions || this.props.lockedToTopics !== prevProps.lockedToTopics || this.props.maxResults !== prevProps.maxResults || this.props.postTypes !== prevProps.postTypes)
            this.fetchFeed();
    }

    public render() {
        const { postsFeed } = this.state;

        if (this.state.isFetching)
            return <Loading />;

        return (
            <div id="news-feed" className={this.props.layout === "card" ? "scrollable" : ""}>
                {postsFeed.length === 0
                    ? <div className="no-feed">No posts were found</div>
                    : this.getLayout({ ...this.props, ...this.state }, this.props.currentUser.preferredLCID)
                }
                <DialogPostView
                    isPublic
                    onClose={this.unselectPost}
                    postId={this.state.selectedPostId}
                    open={!!this.state.selectedPostId}
                />
            </div>
        );
    }

    private fetchFeed = () => {
        this.setState({ isFetching: true });

        const filters: Partial<PostFeedFilters> = {
            ignoreSubscriptions: this.props.ignoreSubscriptions,
            lockSubscriptions: !!this.props.lockedToTopics.length,
            postTypes: !!this.props.postTypes.length ? this.props.postTypes : undefined,
            lockedTags: !!this.props.lockedToTopics.length ? this.props.lockedToTopics.map(topic => topic.id) : undefined
        };

        this.props.fetchPostsFeed(filters, this.props.maxResults, 1).then((postsFeed) => {
            if (this._isMounted) {
                this.setState({ isFetching: false, postsFeed });
            }
        });
    }

    /** FUTURE FUNCTIONALITY
     * For now, the user portal will all use the 3-card layout.
     */
    private getLayout(props: ComponentProps & ComponentState, preferredLCID: string): JSX.Element {
        switch (props.layout) {
            case "card":
                return <Card {...props} cardCount={0} preferredLCID={preferredLCID} onPostSelected={this.selectPost} />;
            case "column":
                return <Column {...props} preferredLCID={preferredLCID} onPostSelected={this.selectPost} />;
            case "window":
                return <Window {...props} cardCount={props.cardCount || 3} preferredLCID={preferredLCID} onPostSelected={this.selectPost} />;
            case "list":
                return <List {...props} cardCount={props.cardCount || 10} preferredLCID={preferredLCID} onPostSelected={this.selectPost} />;
            default:
                return <Card {...props} cardCount={0} preferredLCID={preferredLCID} onPostSelected={this.selectPost} />;
        }
    }

    private selectPost = (post: PostFeedItem) => {
        this.setState({ selectedPostId: post.id });
        if (!post.lastReadTime)
            this.setState({
                postsFeed: this.state.postsFeed.map(article => article.id === post.id && !post.lastReadTime
                    ? { ...article, lastReadTime: new Date().toISOString() }
                    : article
                )
            });
    }

    private unselectPost = () => {
        this.setState({ selectedPostId: "" });
    }
}

interface ComponentProps {
    cardCount?: number;
    hidePublishTime?: boolean;
    hideStats?: boolean;
    hideSummary?: boolean;
    hideTopics?: boolean;
    ignoreSubscriptions?: boolean;
    lockSubscriptions?: boolean;
    layout: "card" | "column" | "window" | "list";
    lockedToTopics: Tag[];
    maxResults: number;
    postTypes: string[];
}

interface ComponentState {
    isFetching: boolean;
    postsFeed: PostFeedItem[];
    selectedPostId: string;
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        currentUser: state.settings.currentUser
    }),
    {
        fetchPostsFeed: actions.fetchPostsFeedLocal
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;
export default connector(PostFeed);
