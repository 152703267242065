import * as React from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";


class DeleteComment extends React.Component<ComponentProps, ComponentState> {
  public render() {
    return (
      <Dialog open={this.props.show} onClose={this.props.onCancel}>
        <DialogTitle>{`Delete Comment${this.props.hasReplies ? "s" : ""}`}</DialogTitle>
        <DialogContent>
          <DialogContentText>{this.props.hasReplies && "You're about to delete a comment and its replies. "} This action cannot be undone. Are you sure you want to delete this comment?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="text" color="info" onClick={this.onCancel}>Cancel</Button>
          <Button variant="contained" style={{backgroundColor: "#B72026"}} autoFocus onClick={this.onConfirm}>Delete</Button>
        </DialogActions>
      </Dialog>
    );
  }

  private onConfirm = () => {
    this.props.onConfirm();
  }

  private onCancel = () => {
    this.props.onCancel();
  }
}


interface ComponentProps {
  show: boolean;
  hasReplies: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

interface ComponentState {
}

export default DeleteComment;