import * as React from "react";

import TenantLink from "modules/common/components/tenantLink";

import BasePage from "../common/basePage";
import MainContent from "../common/mainContent";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Box, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
    root: {
      minHeight: "75vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center"
    }
  });

const PageForbidden: React.FunctionComponent<{}> = props => {
    const classes = useStyles();
  return (
    <BasePage>
        <Box
        className={classes.root}
        alignItems="center"
        justifyContent="center"
        >
            <MainContent addPadding>
                <div>
                    <Grid container justifyContent = "center">
                        <Grid item>
                            <img src="/images/403.png" alt="403 banner" style={{maxWidth: "130.6px", maxHeight: "130.6", width: "100%", height: "100%"}}/>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent = "center" style={{paddingTop: "10%"}}>
                        <Grid item>
                            <Typography variant="h1" align="center">Looks like you don’t have access.</Typography>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent = "center" style={{paddingTop: "3%"}}>
                        <Grid item>
                            <Typography variant="body1" align="center">Sorry, you don’t have permission to use the admin side of the portal.</Typography>
                            <Typography variant="body1" align="center">Contact your administrators, managers or internal</Typography>
                            <Typography variant="body1" align="center">communications team to request access.</Typography>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent = "center" style={{paddingTop: "3%"}}>
                        <Grid item>
                        <TenantLink to="~/home">
                                <Button variant="text" color="primary">Back to Home</Button>
                            </TenantLink>
                        </Grid>
                    </Grid>
                </div>
            </MainContent>
        </Box>
    </BasePage>
  );
}

export default PageForbidden;