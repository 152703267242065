import { AxiosPromise } from "axios";
import { HomeScreenPlatform, IHomeScreenConfig } from "modules/homeScreens/models";
import { SparrowApi } from "./network/adapters/SparrowApi";
import { ApiRequest } from "./network";

export interface IHomeScreenConfigApi {
    getHomeScreenConfigs: (platform: HomeScreenPlatform | null) => AxiosPromise<IHomeScreenConfig[]>;
    saveHomeScreenConfigs: (configs: IHomeScreenConfig[]) => AxiosPromise<void>;
}

export class HomeScreenConfigApi implements IHomeScreenConfigApi {
    constructor(private _sparrowApi: SparrowApi) {}

    getHomeScreenConfigs(platform: HomeScreenPlatform | null): AxiosPromise<IHomeScreenConfig[]> {
        return new ApiRequest<IHomeScreenConfig[]>(
            this._sparrowApi.TenantRequest().get(`/{tenant}/adminapi/v1/homescreen`, { params: { platform }})
        ).catch((err) => {
            throw err;
        });
    }

    saveHomeScreenConfigs(configs: IHomeScreenConfig[]): AxiosPromise<void> {
        return new ApiRequest<IHomeScreenConfig[]>(
            this._sparrowApi.TenantRequest().post(`/{tenant}/adminapi/v1/homescreen`, configs)
        ).catch((err) => {
            throw err;
        });
    }
}
