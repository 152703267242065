import { useEffect, useState } from "react";

type IUseDragReorderReturn = {
    mousePos: IPosition;
    dropIdx: number;
};

export interface IUnique<T> {
    id: T;
}

interface IUseDragReorderProps<T> {
    enabled: boolean;
    items: IUnique<T>[];
    draggedIdx: number | null;
    dropZoneClassName: string;
    onDrop: ((id?: T, idx?: number) => void) | null;
}

export interface IPosition {
    x: number;
    y: number;
}

// some common handlers needed for allowing click drag reorder
// taken from: https://dev.to/h8moss/build-a-reorderable-list-in-react-29on
export function useDragReorder<T>(props: IUseDragReorderProps<T>): IUseDragReorderReturn {
    const { items, draggedIdx, enabled, dropZoneClassName, onDrop } = props;
    const isDragging = draggedIdx !== null && draggedIdx !== -1;
    const [mousePos, setMousePos] = useState<IPosition>({ x: 0, y: 0 });
    const [dropIdx, setDropIdx] = useState<number>(0);

    // listen to mouse position if draggable on
    useEffect(() => {
        const mouseMoveHandler = (e: MouseEvent) => {
            setMousePos({ x: e.x, y: e.y });
        };

        const mouseUpHandler = (_: MouseEvent) => {
            document.body.style.cursor = "default";

            if (onDrop) {
                let idxToUse = dropIdx;

                // handle special case where item is dragged to the end
                if (idxToUse >= items.length) idxToUse = items.length - 1;

                onDrop(items[idxToUse]?.id, idxToUse);
            }
        };

        if (enabled) {
            document.addEventListener("mousemove", mouseMoveHandler);
            document.addEventListener("mouseup", mouseUpHandler);
        }

        return () => {
            document.removeEventListener("mousemove", mouseMoveHandler);
            document.removeEventListener("mouseup", mouseUpHandler);
        };
    }, [isDragging, dropIdx, onDrop, items, enabled]);

    // get closest drop zone to mouse position so we only show that one
    // https://dev.to/h8moss/build-a-reorderable-list-in-react-29on
    useEffect(() => {
        if (isDragging) {
            // get all drop-zones
            const elements = Array.from(document.getElementsByClassName(dropZoneClassName));

            // get all drop-zones' y-axis position
            // if we were using a horizontally-scrolling list, we would get the .left property
            const positions = elements.map((e) => {
                return e.getBoundingClientRect().top;
            });

            // get the difference with the mouse's y position
            const absDifferences = positions.map((v) => Math.abs(v - mousePos.y));

            // get the item closest to the mouse
            let result = absDifferences.indexOf(Math.min(...absDifferences));

            if (result > draggedIdx) result += 1;

            setDropIdx(result);
        }
    }, [isDragging, mousePos, items, draggedIdx, dropZoneClassName]);

    return {
        mousePos,
        dropIdx,
    };
}

export function dropItemForward<TItems>(items: TItems[], startPosition: number, endPosition: number): TItems[] {
    let newItems = [...items];

    const temp = newItems[startPosition];

    for (let i = startPosition; i < endPosition; i++) {
        newItems[i] = newItems[i + 1];
    }

    newItems[endPosition - 1] = temp;

    return newItems;
}

export function dropItemBackward<TItems>(items: TItems[], startPosition: number, endPosition: number): TItems[] {
    const newItems = [...items];

    const temp = newItems[startPosition];

    for (let i = startPosition; i > endPosition; i--) {
        newItems[i] = newItems[i - 1];
    }

    newItems[endPosition] = temp;

    return newItems;
}
