import TextBubble from 'modules/common/components/textBubble';
import InputField from 'modules/users/components/user-field-components/inputField';
import React from 'react';
import { Blast, NotificationEvents } from '../models';
import { connect, ConnectedProps } from "react-redux"
import { GlobalApplicationState } from "globalApplicationState"
import TextAreaLanguageSelector from '../../common/components/textAreaLanguageSelector';
import { lcidMappings } from 'modules/resources/models';
import { messagingApi } from 'api/instances';
import LoadingSpinner from 'modules/common/components/loadingSpinner';
import { errorAlert } from 'utils/notyPopups';
import AudienceCalloutSelector from 'modules/audiences/components/audienceCalloutSelector';
import { Audience } from 'modules/audiences';

interface ComponentProps {
    closeModal: () => any;
    onSelectAudienceClick: () => any;
    textBubbles: TextBubble[];
    selectedAudiences: string[];
    onBlastSuccess: ()  => any;
    applyAudienceChanges: any;
    previousAudiences: string[];
    userAudiences: Audience[];
 }

interface ComponentState { 
    showAudiencePicker: boolean;
    translatedContent: lcidMappings
    subject: string;
    sending: boolean;
    anchorEl: HTMLButtonElement | null;
}

interface TextBubble {
    id: string;
    name: string;
}

class EmailBlastModal extends React.Component<PropsWithRedux, ComponentState> {
    
    constructor(props) {
        super(props);
        this.state = {
            showAudiencePicker: false,
            translatedContent: { },
            sending: false,
            subject: "",
            anchorEl: null
        }
    }

    public render() {

        const disabledButton = this.props.notificationSettings[NotificationEvents.MassEmail].toggle ? "apply-btn-2" : "disabled-btn-2"

        const suggestions = [
            {id: "{{preferredName}}", name: "{{preferredName}}"},
            {id: "{{firstName}}", name: "{{firstName}}"},
            {id: "{{lastName}}", name: "{{lastName}}"},
            {id: "{{supportEmail}}", name: "{{supportEmail}}"},

        ] as TextBubble[]
        return(
            <div className="email-blast-container">
                <div className="email-blast-header">
                    <h4 style={{float: "left"}}>Create Email Blast</h4> <p style={{float: "right", cursor: "pointer"}} onClick={() => this.props.closeModal()}>✕</p>
                </div>
                
                <div className="email-blast-fields">
                    <InputField 
                        title={"Sender"}
                        isDisabled={true}
                        inputType={"text"}
                        placeholder={this.props.tenantNotificationSettings.fromEmailAddress}
                    />
                    <InputField 
                        title={"Subject"}
                        inputType={"text"}
                        value={this.state.subject}
                        onChange={(e) => {this.setState({subject: e})}}
                        isDisabled={!this.props.notificationSettings[NotificationEvents.MassEmail].toggle}
                    />

                    <div className="input-row">
                        <AudienceCalloutSelector
                            handlePopupClick={this.state.anchorEl}
                            applyAudienceChanges={this.props.applyAudienceChanges}
                            previousAudiences={this.props.selectedAudiences}
                            userAudiences={this.props.userAudiences}
                            textBubbles={this.props.textBubbles}
                            onSelectAudienceClick={this.props.onSelectAudienceClick}
                            handleClose={this.handleClose}
                        />
                    </div>

                    <div className="input-row">
                        <p className="profile-input-label">Body</p> 
                        <div className="label-description-block">
                            <TextAreaLanguageSelector 
                                returnTranslatedContent={this.getTranslatedContent}
                                disabled={!this.props.notificationSettings[NotificationEvents.MassEmail].toggle}
                            />
                        </div>
                    </div>

                    <div className="input-row">
                        <p className="profile-input-label">Suggestions</p> 
                        <div className="label-description-block">
                            <TextBubble list={suggestions} />
                            <p className="description-text">Use suggestions in brackets (ex. &#123;&#123;First Name&#125;&#125;) to personalize your message for each recipient. Do not change the texts within suggested items to correctly use them.</p>
                        </div>
                    </div>
                    <div className="clearfix">
                        <button
                            disabled={!this.props.notificationSettings[NotificationEvents.MassEmail].toggle}
                            onClick={() => this.SendBlast()}
                            className={disabledButton} 
                            style={{float: "right", marginBottom: 10}}>
                                { this.state.sending ?                                     
                                <LoadingSpinner size={'14px'} />
                                :
                                <span>SEND</span>
                                }</button>  
                        {!this.props.notificationSettings[NotificationEvents.MassEmail].toggle && 
                            <div className="blast-disabled-message">
                            Email Blasts are currently disabled. To send a blast please re-enable them in the settings.
                            </div>            
                        }
                    </div> 
                </div>

            </div>
        )
    }

    private handleClose = () => {
        this.setState( { anchorEl: null });
    };

    private getTranslatedContent = (translatedContent: any) => {
        this.setState({
            ...this.state,
            translatedContent: translatedContent
        })
    }
    
    private SendBlast = () => {
        const blast = {
            audienceIds: this.props.selectedAudiences,
            translatedBody: this.state.translatedContent,
            subject: this.state.subject
        } as Blast;

        this.setState({
            ...this.state,
            sending: true,
        })
        messagingApi.SendEmailBlast(blast)
        .then((response) => {
            this.setState({
                ...this.state,
                sending: false
            })
            if (response === "Success") {
                this.props.onBlastSuccess()
            }
        })
        .catch((error) => {
            errorAlert(`There was an error processing your email blast`, 3000)
            this.setState({
                ...this.state,
                sending: false
            })
        })
    }
}


const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        tenantSettings: state.settings.tenantSettings,
        lcidMappings: state.resources.lcidMappings,
        notificationSettings: state.settings.notificationSettings.settings,
        tenantNotificationSettings: state.settings.notificationSettings,
    }),
    {
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(EmailBlastModal)
