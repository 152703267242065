import React from "react"

import Grid from "@mui/material/Grid";

import { Theme } from "@mui/material/styles";


import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      [theme.breakpoints.up("lg")]: {
        overflowY: "hidden"
      }
    },
    gridItem: {
      [theme.breakpoints.up("lg")]: {
        height: "100%",
        "& > div": {
          maxHeight: "100%"
        },
        "&:last-child > div": {
          height: "100%"
        }
      }
    }
  })
);


const Columns: React.FunctionComponent<ComponentProps> = props => {
  const classes = useStyles();

  return (
    <React.Fragment>
      {props.heading &&
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12}>
            {props.heading}
          </Grid>
        </Grid>
      }
      <Grid container justifyContent="center" spacing={2} className={classes.grid}>
        <Grid item xs={12} lg={4} xl={props.slim ? 3 : 4}  className={classes.gridItem}>
          {props.leftComponent}
        </Grid>
        <Grid item xs={12} lg={8} xl={props.slim ? 9 : 8} className={classes.gridItem}>
          {props.rightComponent}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

interface ComponentProps {
  heading?: JSX.Element;
  slim?: boolean;
  leftComponent: JSX.Element;
  rightComponent: JSX.Element;
}

export default Columns;