import * as React from "react";

import AuthoringSearch from "modules/common/components/filters/authoringSearch";
import FilterContainer from "modules/common/components/filters/filterContainer";
import FilterDropdown from "modules/common/components/filters/filterDropdown";

import { AudienceFilterValues, AudienceType } from "../../models";
import { TenantAttribute } from "modules/settings/models";

import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";

import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";


import moment from "moment";


interface Option {
  id: string;
  text: string;
}

const AudienceTypes: Option[] = [
  { id: "smart", text: "Smart" },
  { id: "standard", text: "Standard" }
];

class AudienceFilters extends React.Component<ComponentProps, ComponentState> {
  constructor(props: ComponentProps) {
    super(props);

    this.state = {
      canSearch: !!props.filters.audienceTypes!.length || !!props.filters.textToSearch,
      textToSearch: props.filters.textToSearch || ""
    };
  }

  public componentDidMount() {
    moment.locale("en");
  }

  public componentDidUpdate(prevProps: ComponentProps) {
    if (this.props.filters.textToSearch !== prevProps.filters.textToSearch)
      this.setState({ textToSearch: this.props.filters.textToSearch || "" });
  }

  public render() {
    const { filters } = this.props;

    return (
      <FilterContainer
        filters={
          <React.Fragment>
            <TextField
              variant="outlined"
              size="small"
              value={this.state.textToSearch}
              placeholder="Search title or description"
              InputProps={{
                startAdornment: <SearchIcon className="search-icon" />
              }}
              onChange={this.onUpdateTextToSearch}
              onKeyUp={this.onKeyUp}
              className="text-to-search"
            />
            <div className="filter-options-group">
              {!!this.props.tenantAttributes.length &&
                <FilterDropdown text="Audience Type">
                  <List disablePadding>
                    {AudienceTypes.map((audienceType) =>
                      <ListItem key={audienceType.id} dense button onClick={() => this.onChangeAudienceType(audienceType.id as AudienceType)}>
                        <ListItemIcon className="callout-checkbox">
                          <Checkbox
                            edge="start"
                            tabIndex={-1}
                            disableRipple
                            size="small"
                            color="primary"
                            checked={!!filters.audienceTypes!.find((selectedType) => selectedType === audienceType.id)}
                          />
                        </ListItemIcon>
                        <ListItemText primary={audienceType.text} />
                      </ListItem>
                    )}
                  </List>
                </FilterDropdown>
              }
            </div>
          </React.Fragment>
        }
        filterCommands={
          <AuthoringSearch
            canSearch={this.state.canSearch}
            onApplyFilters={this.onChangeTextToSearch}
            onClearFilters={this.onClearFilters}
          />
        }
        filterSelection={this.getFilterSelection()}
      />
    );
  }

  private getFilterSelection = (): JSX.Element | undefined => {
    const { filters } = this.props;

    if (!filters.textToSearch && !filters.audienceTypes!.length)
      return undefined;

    return (
      <React.Fragment>
        {!!filters.textToSearch &&
          <Chip
            key="search-text"
            label={`"${filters.textToSearch}"`}
            onDelete={this.onClearTextToSearch}
            deleteIcon={<CloseIcon />}
          />
        }
        {filters.audienceTypes!.map((selectedType) =>
          <Chip
            key={selectedType}
            label={AudienceTypes.find((state) => state.id === selectedType)!.text}
            onDelete={() => this.onClearAudienceType(selectedType)}
            deleteIcon={<CloseIcon />}
          />
        )}
      </React.Fragment>
    );
  }


  private onChangeAudienceType = (audienceType: AudienceType) => {
    const hasSelectedAudienceType: boolean = !!this.props.filters.audienceTypes!.find((selectedType) => selectedType === audienceType);
    if (hasSelectedAudienceType)
      this.onChangeFilters({ audienceTypes: this.props.filters.audienceTypes!.filter((selectedType) => selectedType !== audienceType) });
    else
      this.onChangeFilters({ audienceTypes: this.props.filters.audienceTypes!.concat([audienceType]) });
  }

  private onChangeFilters = (value: Partial<AudienceFilterValues>) => {
    this.props.onChangeFilters({ ...this.props.filters, textToSearch: this.state.textToSearch, ...value });
    this.setCanSearch();
  }
  
  private onChangeTextToSearch = () => {
    this.onChangeFilters({ textToSearch: this.state.textToSearch });
  }

  private onClearAudienceType = (audienceType: AudienceType) => {
    this.onChangeFilters({ audienceTypes: this.props.filters.audienceTypes!.filter((selectedType) => selectedType !== audienceType) });
  }

  private onClearFilters = () => {
    this.clearCanSearch();
    this.props.onClearFilters();
  }

  private onClearTextToSearch = () => {
    this.onChangeFilters({ textToSearch: "" });
  }
  
  private onKeyUp = (key) => {
    if (key.keyCode === 13)
      this.onChangeTextToSearch();
  }

  private onUpdateTextToSearch = (event) => {
    this.setState({ textToSearch: event.target.value });
  }

  private clearCanSearch = () => {
    this.setState({ canSearch: false });
  }

  private setCanSearch = () => {
    this.setState({ canSearch: true });
  }
}
  

interface ComponentProps {
  filters: Partial<AudienceFilterValues>;
  tenantAttributes: TenantAttribute[];
  syncType: string;
  onChangeFilters: (filters: Partial<AudienceFilterValues>) => void;
  onClearFilters: () => void;
}

interface ComponentState {
  canSearch: boolean;
  textToSearch: string;
}

export default AudienceFilters;