import * as React from "react";
import { ConnectedProps, connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { GlobalApplicationState } from "globalApplicationState";
import * as actions from "../actionCreator";
import * as audiencesActions from "modules/audiences/actionCreator";
import * as categoryTagsActions from "modules/categorytags/actionCreator";

import NewDocument from "../components/action-buttons/newDocument";
import DocumentListing from "../components/document-listing/documentListing";

import ErrorSnackbar from "modules/common/components/snackbars/errorSnackbar";
import LoadingOverlay from "modules/common/components/loadingOverlay";

import BasePage from "pages/common/basePage";
import Header from "pages/common/header";
import MainContent from "pages/common/mainContent";


class DocumentManagementPage extends React.Component<PropsWithRedux, {}> {
  public componentDidMount() {
    if (!this.props.audiences.length)
      this.props.getAudiences();
    if (!this.props.categoryTags.length)
      this.props.getCategoryTags();
  }

  public render() {
    return (
      <BasePage fullWidth>
        <Header
          title="Manage Documents"
          rightComponent={
            <React.Fragment>
              <NewDocument />
            </React.Fragment>
          }
        />
        <MainContent>
          <div className="authoring-page">
            <DocumentListing />
            <ErrorSnackbar errorMessage={this.props.errorMessage} clearErrorMessage={this.props.clearErrorMessage} />
            <LoadingOverlay absolute={true} show={this.props.isDeleting || this.props.isFetching || this.props.isSaving} />
          </div>
        </MainContent>
      </BasePage>
    );
  }
}

interface ComponentProps {}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps & RouteComponentProps) => ({
    ...ownProps,
    audiences: state.audiences.audiences,
    categoryTags: state.categoryTags.userCategoryTags,
    errorMessage: state.documents.errorMessage,
    isDeleting: state.documents.isDeleting,
    isFetching: state.documents.isFetching,
    isSaving: state.documents.isSaving
  }),
  {
    clearErrorMessage: actions.clearErrorMessage,
    getAudiences: audiencesActions.getAudiences,
    getCategoryTags: categoryTagsActions.getUserCategoryTags
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(DocumentManagementPage);
