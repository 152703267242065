import * as React from "react";

import Chip from "@mui/material/Chip";


import "./chips.sass";


const ChipLabels: React.FunctionComponent<ComponentProps> = props => {
  if (!props.chips || !props.chips.length)
    return (
      <div className="chip-labels">
        <Chip
          label={props.emptyText}
          disabled={true}
          className="empty-chip"
        />
      </div>
    );

  return (
    <div title={props.chips.map((chip) => chip.name).join(", ")} className="chip-labels">
      {props.chips.slice(0,2).map((chip) => (
        <Chip
          key={chip.id}
          label={chip.name}
        />
      ))}
      {props.chips.length > 2 &&
        (props.chips.length === 3
          ? <Chip
              key={props.chips[2].id}
              label={props.chips[2].name}
            />
          : <Chip
              key="more"
              label={`+${props.chips.length - 2}`}
            />
        )
      }
    </div>
  );
}

interface ComponentProps {
  chips: { id: string, name: string }[];
  emptyText: string;
}

export default ChipLabels;