import React from 'react';
import AutoSizer from "react-virtualized-auto-sizer";

import { Pie } from '@nivo/pie';
// import { useMediaQuery } from '@mui/material';

import '../styles/basic-insights.sass'
import '../styles/graphs.sass'

// TODO: add react-intl where neceessary here post fix

const BreakdownChart: React.FunctionComponent<ComponentProps> = (props) => {

    // const matchesXLarge = useMediaQuery('(max-width:2500px)');

    const getLeftMargin = (legend: boolean = false): number => {
        let margin = 100
        // if (matchesXLarge)
        //     margin = 100

        if (legend)
            margin = margin * -1

        return margin
    }

    // const getReScale = (): number => {
    //     if (matchesXLarge)
    //         return 1.1
    //     return 1
    // }

    return (
        <div className='nivo-wrapper' > 
            {
                props.totalSubscribers || props.totalUniqueViews || props.totalViews ?
                <div className='metric-title'>
                    {props.totalSubscribers ? `Total Subscribers: ${props.totalSubscribers}` :
                         props.totalUniqueViews ? `Total Unique Views: ${props.totalUniqueViews}` :
                        `Total Views: ${props.totalViews}`}
                </div>
                : null
            }
            <AutoSizer>
                {({ height, width }) => (
                    <Pie 
                        height={height}
                        width={width}
                        data={props.chartData}
                        colors={{scheme: 'red_yellow_blue'}}
                        innerRadius={props.innerRadius}
                        padAngle={1}
                        enableArcLabels={false}
                        enableArcLinkLabels={false}
                        margin={{ top: 40, right: 20, bottom: 15, left: getLeftMargin()}}
                        activeOuterRadiusOffset={15}     
                        legends={[
                            {
                                anchor: 'top-left',
                                direction: 'column',
                                justify: false,
                                itemHeight: 20,
                                translateX: getLeftMargin(true),
                                itemWidth: 75,
                                itemsSpacing: 10,
                                symbolSize: 20,
                                textColor: '#333333',
                                itemTextColor: '#333333',
                                
                            }
                        ]}
                    />
                )}
            </AutoSizer>
        </div>
    )

}

interface ComponentProps {
    chartData: {id: string, value: number, label: string }[]
    totalSubscribers?: number
    totalUniqueViews?: number
    totalViews?: number
    innerRadius: number
}

export default BreakdownChart;
