import { AxiosInstance, AxiosRequestConfig } from "axios";
import { store } from "configureStore";

interface StateWithTenant {
    tenant: { id: string } | null;
}

export class TenantAutoFillInterceptor {
    public bind(networkLayer: AxiosInstance) {
        networkLayer.interceptors.request.use(this.handle);
    }

    public handle = (config: AxiosRequestConfig) => {
        let tenantId = store.getState().tenant?.id
        let tenantRegex = new RegExp('{tenant}', 'i');
        
        var url;
        var baseURL;

        if (tenantId) {
            url = config.url!.replace(tenantRegex, tenantId);
            baseURL = config.baseURL!.replace(tenantRegex, tenantId);
        }

        return Promise.resolve({ ...config, url, baseURL });
    };
}
