import React from "react";

import MoreOptionsButton from "modules/common/components/buttons/moreOptionsButton";

import List from "@mui/material/List";
import MoreOptionsItem from "modules/common/components/moreOptionsItem";
import { UserDetails } from "modules/users/models";
import CalloutHover from "modules/common/components/hovers/calloutHover";


const MoreUserProfileOptions: React.FunctionComponent<ComponentProps> = props => {
  if(!props.userDetails)
    return <React.Fragment></React.Fragment>

  return (
    <React.Fragment>
      <MoreOptionsButton text="More Options">
        <List disablePadding style={{width: "162px"}}>
          {props.userDetails.accountCategory === "work" && 
            <CalloutHover
                component={
                    <MoreOptionsItem
                        text="Delete"
                        onClick={() => {}} //Component requires a function but I'll leave it blank.
                        disabled={true}
                    />
                }
            >
                <div className="disabled-button-message">
                    Work accounts added from your {props.syncType} Directory and synced through an {props.syncType} Group cannot be deleted. Disable the account to revoke access from this account.
                </div>
            </CalloutHover>
          }
          {props.userDetails.accountCategory !== "work" && 
            <MoreOptionsItem
                text="Delete"
                textStyle={{color: "#B72026"}}
                onClick={props.deleteUser}
            />
          }
          {!props.enabledStatus &&
            <MoreOptionsItem
                text="Activate"
                onClick={props.enableUser}
            />
          }
          {props.enabledStatus &&
            <MoreOptionsItem
                text="Deactivate"
                onClick={props.disableUser}
            />
          }
        </List>
      </MoreOptionsButton>
    </React.Fragment>
  );
}

interface ComponentProps {
    userDetails: UserDetails | undefined;
    syncType: string;
    enabledStatus: boolean;
    deleteUser: () => void;
    enableUser: () => void;
    disableUser: () => void;
}

export default MoreUserProfileOptions;