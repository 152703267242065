import React, { useState } from "react";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, TextField, Typography } from "@mui/material";
import { checkValidTenantName, emailRegex, validGuidRegex } from "utils/regexUserValidation";

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import LoadingSpinner from "modules/common/components/loadingSpinner";
import "../../styles/tenantManagement.sass"

const NewTenantCreationDialog: React.FunctionComponent<ComponentProps> = props => {
    const [firstNameValid, setFirstNameValid] = useState(false);
    const [lastNameValid, setLastNameValid] = useState(false);
    const [emailValid, setEmailValid] = useState(false);
    const [tenantNameValid, setTenantNameValid] = useState(false);
    const [azureTenantIdValid, setAzureTenantIdValid] = useState(false);

  return (
      <Dialog open={props.show} onClose={props.onClose} fullWidth={true} maxWidth={"md"}>
          <Grid justifyContent="space-between" container>
              <Grid item>
                <DialogTitle component="h2">
                  Create New Tenant
                </DialogTitle>
              </Grid>
              <Grid item className="dialog-tenantmanagement-title">
                  <IconButton size="small" edge="start" color="inherit" onClick={props.onClose} aria-label="close">
                      <CloseOutlinedIcon />
                  </IconButton>
              </Grid>
          </Grid>
          <Divider />
          <DialogContent>
              <div className="dialog-tenantmanagement-content">
                  <Grid container spacing={2}>
                      <Grid item xs={3}>
                          <Typography variant="h6" className="required-label">Tenant Name</Typography>
                      </Grid>
                      <Grid item xs={9}>
                          <TextField
                              placeholder="Enter tenant name"
                              error={tenantNameValid}
                              onChange={(e) => {
                                  props.onUpdateTenantName(e);
                                  checkTenantNameValid(e);
                              }}
                              fullWidth={true}
                              size="small"
                              variant="outlined"
                          />
                      </Grid>
                      <Grid item xs={3}>
                          <Typography variant="h6" className="required-label">First Name</Typography>
                      </Grid>
                      <Grid item xs={9}>
                          <TextField
                              error={firstNameValid}
                              placeholder="Enter first name"
                              onChange={(e) => {
                                  props.onUpdateFirstName(e);
                                  checkFirstNameValid(e);
                              }}
                              fullWidth={true}
                              size="small"
                              variant="outlined"
                          />
                      </Grid>
                      <Grid item xs={3}>
                          <Typography variant="h6" className="required-label">Last Name</Typography>
                      </Grid>
                      <Grid item xs={9}>
                          <TextField
                              error={lastNameValid}
                              placeholder="Enter last name"
                              onChange={(e) => {
                                  props.onUpdateLastName(e);
                                  checkLastNameValid(e);
                              }}
                              fullWidth={true}
                              size="small"
                              variant="outlined"
                          />
                      </Grid>
                      <Grid item xs={3}>
                          <Typography variant="h6" className="required-label">Email</Typography>
                      </Grid>
                      <Grid item xs={9}>
                          <TextField
                              error={emailValid}
                              placeholder="Enter email"
                              onChange={(e) => {
                                  props.onUpdateEmail(e);
                                  checkEmailValid(e);
                              }}
                              fullWidth={true}
                              size="small"
                              variant="outlined"
                          />
                      </Grid>
                      <Grid item xs={3}>
                          <Typography variant="h6">AD Tenant ID (optional)</Typography>
                      </Grid>
                      <Grid item xs={9}>
                          <TextField
                              error={azureTenantIdValid}
                              placeholder="Enter Microsoft Azure Directory tenant Id"
                              onChange={(e) => {
                                  props.onUpdateAzureADTenantId(e);
                                  checkAzureTenantIdValid(e);
                              }}
                              fullWidth={true}
                              size="small"
                              variant="outlined"
                          />
                      </Grid>
                  </Grid>
              </div>
          </DialogContent>
          <DialogActions>
              <Button variant="text" color="info" onClick={props.onClose}>
                  Cancel
              </Button>
              <Button variant="contained" color="primary" disabled={props.disabledCreate} onClick={() => props.onCreate()}>
                  Create
                  {props.isLoading ? <LoadingSpinner size="14px" /> : ""}
              </Button>
          </DialogActions>
      </Dialog>
  );

  function checkTenantNameValid(event: any) {
      setTenantNameValid(event.target.value.length <= 0 || !event.target.value.match(checkValidTenantName));
  }

  function checkFirstNameValid(event: any) {
      setFirstNameValid(event.target.value.length <= 0);
  }

  function checkLastNameValid(event: any) {
      setLastNameValid(event.target.value.length <= 0);
  }

  function checkEmailValid(event: any) {
      setEmailValid(event.target.value.length <= 0 || !event.target.value.match(emailRegex));
  }

  function checkAzureTenantIdValid(event: any) {
      setAzureTenantIdValid(event.target.value.length > 0 && !event.target.value.match(validGuidRegex));
  }
}


interface ComponentProps {
    show: boolean;
    disabledCreate: boolean;
    isLoading: boolean;
    onUpdateFirstName: (event) => void;
    onUpdateLastName: (event) => void;
    onUpdateEmail: (event) => void;
    onUpdateTenantName: (event) => void;
    onUpdateAzureADTenantId: (event) => void;
    onCreate: () => Promise<void>;
    onClose: () => void;
}

export default NewTenantCreationDialog;