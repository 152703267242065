import * as React from "react";
import TextField from "@mui/material/TextField";
import { SaveNewsletterModel } from "modules/newsletters/models";
import { SaveNewsletterModelStateErrors } from "modules/newsletters";
import _ from "lodash";

export class TitleSection extends React.Component<ComponentProps, {}> {
  constructor(props: ComponentProps) {
    super(props);
    this.state = {};
  }

  public render() {
    return (
        <div className="">
          <div className="">
            <div>
              <div>Newsletter title<span className="required-coloring">*</span></div>
              <TextField
                variant="outlined"
                size="small"
                value={this.props.newsletter.title}
                autoComplete="off"
                error={this.props.highlightFields || _.some(this.props.modelErrors?.Title)}
                helperText={_.first(this.props.modelErrors?.Title)}
                placeholder="Internal name"
                fullWidth
                required
                onChange={this.onChangeTitle}
              />
            </div>
            <div className="space-top-10">
              <div>Description</div>
              <TextField
                variant="outlined"
                size="small"
                value={this.props.newsletter.description}
                autoComplete="off"
                placeholder="Internal description"
                fullWidth
                onChange={this.onChangeDescription}
              />
            </div>
          </div>
        </div>
    );
  }

  private onChangeNewsletter = (value: Partial<SaveNewsletterModel>) => {
    this.props.onNewsletterChange(value);
  }

  private onChangeTitle = (event) => {
    this.onChangeNewsletter({title: event.target.value});
  }

  private onChangeDescription = (event) => {
    this.onChangeNewsletter({description: event.target.value})
  }
}

interface ComponentProps {
  newsletter: SaveNewsletterModel;
  highlightFields: boolean;
  modelErrors: SaveNewsletterModelStateErrors | null;
  onNewsletterChange:  (value: Partial<SaveNewsletterModel>) => void;
}

export default TitleSection;