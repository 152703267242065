import * as React from 'react';
import { browserHistory } from 'configureStore';
import Tenantink from 'modules/common/components/tenantLink';
import { HorizontalBarChart, HBarChartProps, HBarChartDataPoint } from './charts/horizontalBarChart';
import WindowResizeListener from 'modules/common/components/window-resize-listener';
import { Survey } from 'modules/surveys';
import {FormattedMessage} from "react-intl";
import {connect, ConnectedProps} from "react-redux";
import { GlobalApplicationState } from 'globalApplicationState';
import Loading from 'modules/common/components/loading';
import EmptyDataMessage from './emptyDataMessage';
import DownloadIcon from './downloadIconSvg';
import { PlatformAnalyticsReport } from '..';

interface ComponentProps {
    tenant: string
    surveys: Survey[];
    loading: boolean;
    downloadImage: () => void;
    downloadExcel: () => void;
}

class TopSurveys extends React.PureComponent<PropsWithRedux, {}> {

    private _chart: HorizontalBarChart;
    private _chartElement: HTMLElement;

    public componentDidMount() {
        this._chart = new HorizontalBarChart(this.getBarChartState());
    }

    public componentDidUpdate() {
        if (this._chart) {
            this._chart.update(this.getBarChartState());
        }
    }

    public componentWillUnmount() {
        if (this._chart) {
            this._chart.destroy();
        }
    }

    public render() {
        const noDataAvailable = !this.props.surveys || this.props.surveys.length === 0;

        return <div id={PlatformAnalyticsReport.surveyWithMostResponse} className="analytics-widget">
            <div className='downloadIcon' id={`${PlatformAnalyticsReport.surveyWithMostResponse}-downloadIcon`} style={{float:'right'}}>  
                <DownloadIcon
                    downloadImage={this.props.downloadImage}
                    downloadExcel={this.props.downloadExcel}
                />
            </div>
            <div className="header">
                Top 10 Survey with Most Responses
            </div>
            {this.props.loading ? 
            <div className="body" style={{position: "relative", top: "55px", height: "85%"}}>
                <Loading/>
            </div>
            : <div className="body" style={{height: noDataAvailable ? "175px" : "215px"}}>
                {
                  noDataAvailable ?
                    <EmptyDataMessage/>
                    
                : <div ref={e => this._chartElement = e!} className="bar-chart-widget"></div>
                }
                <WindowResizeListener onChange={() => this._chart.update(this.getBarChartState())} />
            </div>
            }
            <footer id={`${PlatformAnalyticsReport.surveyWithMostResponse}-goto`} style={{position: "relative", top: "-8px"}}>
                <Tenantink to={`~/admin/surveys`}>
                    <FormattedMessage id="surveys.viewAllSurveys" defaultMessage="Go to Manage Surveys"/>
                </Tenantink>
            </footer>
        </div>
    }

    private getBarChartState = (): HBarChartProps => {
        return {
            el: this._chartElement,
            locale: this.props.locale,
            width: '100%',
            height: 'auto',
            barHeight: 20,
            data: this.props.surveys.map(s => {
                return {
                    id: s.id,
                    //handle surveys with multi-language titles
                    label: s.title,
                    value: Object.keys(s.aggregatedResults).reduce((acc, key) => acc + s.aggregatedResults[key] , 0),
                    onClick: this.handlePostClick
                };
            }).sort((a,b) => b.value - a.value).slice(0,10),
            labelWidth: 200
        } as HBarChartProps;
    }

    private handlePostClick = (p: HBarChartDataPoint) => {
        browserHistory.push(`/${this.props.tenant}/admin/surveys/${p.id}`);
    }
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        locale: state.localization.locale
    })
);
type PropsWithRedux = ConnectedProps<typeof connector>;
export default connector(TopSurveys)