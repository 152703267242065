
import { Action } from "redux";
import { HeadDefinition, DefaultHeadValues } from "./models"
import * as Actions from "./actions"

const actionHandler: { [actionType: string]: (state: HeadDefinition | undefined, action: Action<any>) => HeadDefinition } = {
    [Actions.HEAD_UPDATED]: (state: HeadDefinition, action: Actions.HeadUpdated) => {
      return {
            title: action.payload.title || state.title,
            description: action.payload.description || state.description,
            openGraphTitle: action.payload.openGraphTitle || state.openGraphTitle,
            openGraphDescription: action.payload.openGraphDescription || state.openGraphDescription,
            openGraphUrl: action.payload.openGraphUrl || state.openGraphUrl,
            openGraphImageUrl: action.payload.openGraphImageUrl || state.openGraphImageUrl,
            openGraphVideo: action.payload.openGraphVideo || state.openGraphVideo,
        };
    }
}

export const reducer = (state: HeadDefinition | undefined, action: any) : HeadDefinition => {

    const actionHandlerMethod = actionHandler[action.type];
    if (actionHandlerMethod) {
        return actionHandlerMethod(state, action);
    }

    return state || DefaultHeadValues;
}