import React from "react";
import Snackbar, { SnackbarProps } from "@mui/material/Snackbar";
import { Alert } from '@mui/material';
import { AlertProps } from '@mui/lab';
import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

interface IStyleProps {
    backgroundColor?: string;
}

const useStyles = makeStyles<Theme, IStyleProps> ((theme: Theme) =>
    createStyles({
        snackbar: {
            position: "absolute",
        },
        alert: ({backgroundColor }) => ({
            backgroundColor: backgroundColor || undefined,
            minWidth: 350
        })
    })
);

/**
 * A single component snackbar with alert
 */
const SnackbarWrapper: React.FunctionComponent<SnackbarProps & AlertProps> = ({
    autoHideDuration = 6000, // this only defaults if autoHideDuration is undefined, use autoHideDuration={null} to turn off the autohiding
    anchorOrigin = { vertical: "top", horizontal: "right"},
    elevation = 6,
    message,
    className,
    variant = "filled",
    ...restProps
}) => {
    const styleProps = restProps.severity === "success" ? { backgroundColor: "#366531" } : {};
    const classes = useStyles(styleProps);

    return (
        <>
            <Snackbar {...restProps} anchorOrigin={anchorOrigin} autoHideDuration={autoHideDuration} className={classes.snackbar}>
                <Alert {...restProps} variant={variant} elevation={elevation} className={classes.alert}>
                    {message}
                </Alert>
            </Snackbar>
        </>
    );
};

export default SnackbarWrapper;
