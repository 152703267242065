import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { ExpandMore, VisibilityOutlined } from "@mui/icons-material";

import { ContentBandPublishSettings } from "./contentBandsPublishSettings";
import { IContentBand } from "modules/contentBands/models";
import { TenantSettingsTagGroup } from "modules/settings";
import useAccordion from "modules/common/hooks/useAccordion";

import "../../styles/contentBandsOneLastCheck.sass";

export interface IContentBandsOneLastCheckProps {
    open: boolean;
    contentBands: IContentBand[];
    activeLcid: string;
    userTagGroups: TenantSettingsTagGroup[];
    onClose: () => void;
    onPublish: () => void;
    onPreview: () => void;
}

export const ContentBandsOneLastCheck: React.FunctionComponent<IContentBandsOneLastCheckProps> = ({
    open,
    contentBands,
    activeLcid,
    userTagGroups,
    onPreview,
    onClose,
    onPublish,
}) => {
    const { accordionOpenStates, onChangeAccordionOpen, expandAllLabel, onExpandOrCollapseAll } = useAccordion(contentBands.length, true);

    const getAccordion = (idx: number, contentBand: IContentBand): JSX.Element => (
        <Accordion
            className="cb-one-last-check-accordion"
            classes={{ expanded: accordionOpenStates[idx] ? "expanded" : "collapsed" }}
            key={`cb-accrd-${idx}`}
            expanded={accordionOpenStates[idx]}
            elevation={0}
            onChange={(_: React.ChangeEvent, expanded: boolean) => onChangeAccordionOpen(expanded, idx)}
        >
            <AccordionSummary
                expandIcon={<ExpandMore />}
                style={{
                    fontWeight: 500,
                }}
            >
                {contentBand.headers && contentBand.headers[activeLcid] && contentBand.headers[activeLcid].header ? (
                    `${contentBand.headers[activeLcid].header}`
                ) : (
                    <span className="no-header-text">(No header text)</span>
                )}
            </AccordionSummary>
            <AccordionDetails>
                <ContentBandPublishSettings contentBand={contentBand} userTagGroups={userTagGroups} activeLcid={activeLcid} />
            </AccordionDetails>
        </Accordion>
    );

    return (
        <Dialog
            scroll="paper"
            open={open}
            PaperProps={{
                id: "content-bands-one-last-check",
                style: { maxWidth: 614, height: "90%" },
            }}
            maxWidth={"md"}
            fullWidth
        >
            <div className="cb-one-last-check-header">
                <span className="title">One last check!</span>
                <div className="actions">
                    <Button id="cb-pub-modal-keep" variant="text" color="primary" onClick={onClose}>
                        KEEP EDITING
                    </Button>
                    <Button id="cb-pub-modal-pub" variant="contained" color="primary" onClick={onPublish}>
                        PUBLISH
                    </Button>
                </div>
            </div>
            <DialogContent className="cb-one-last-check-content">
                <div className="sub-header">
                    <span>
                        You're about to publish{" "}
                        <b>
                            {contentBands.length} content band{contentBands.length > 1 ? "s" : ""}:
                        </b>
                    </span>
                </div>
                {contentBands.map((cb: IContentBand, idx: number) => getAccordion(idx, cb))}
            </DialogContent>
            <DialogActions sx={{ justifyContent: "space-between" }}>
                <Button id="cb-pub-modal-prvw" color="primary" variant="text" onClick={onPreview} startIcon={<VisibilityOutlined />}>
                    Preview
                </Button>
                <Button
                    id="cb-pub-modal-accrd"
                    color="primary"
                    variant="text"
                    onClick={onExpandOrCollapseAll}
                    disabled={contentBands.length === 0}
                >
                    {expandAllLabel}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
