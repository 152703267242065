import { IdObject } from 'modules/common/models';
import { ApiRequest } from './network';
import { SparrowApi } from 'api/network/adapters/SparrowApi';
import { ITinyCategoryFilters } from 'modules/common/services/tinyMceTemplateService';

export interface ITinyTemplate {
    id: string;
    title: string;
    content?: string;
}

export interface ITinyCategory {
    id: string;
    title: string;
    content?: string;
    items?: ITinyTemplate[];
}

export class AdminTinyTemplatesApi {
    constructor(private _sparrowApi: SparrowApi) {}

    /**
     * Get all tiny templates for tenant
     * @returns list of templates and categories
     */
    public getAllTinyTemplates = (filters?: ITinyCategoryFilters): ApiRequest<ITinyCategory[]> => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().post("{tenant}/adminapi/v1/tiny/templates", filters)
        );
    }

    /**
     * Upsert TinyMCE template category
     * @returns id of document
     */
    public upsertTinyCategory = (category: ITinyCategory): ApiRequest<IdObject<string>> => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().post("{tenant}/adminapi/v1/tiny/category", category)
        );
    }

    /**
     * Upsert TinyMCE template
     * @returns id of document
     */
    public upsertTinyTemplate = (template: ITinyTemplate): ApiRequest<IdObject<string>> => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().post("{tenant}/adminapi/v1/tiny/template", template)
        );
    }

    /**
     * Delete a TinyMCE template
     */
    public deleteTinyTemplate = (id: string): ApiRequest => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().delete(`{tenant}/adminapi/v1/tiny/template/${id}`)
        );
    }

    /**
     * Delete a TinyMCE template category
     */
    public deleteTinyCategory = (id: string): ApiRequest => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().delete(`{tenant}/adminapi/v1/tiny/category/${id}`)
        );
    }

    /**
     * Get template by id
     */
    public getTinyTemplate = (id: string): ApiRequest<ITinyTemplate | undefined> => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().get(`{tenant}/adminapi/v1/tiny/template/${id}`)
        );
    }
}
