import React from "react";

import { DocumentsView } from "../../../models";

import RoundedTabs from "modules/common/components/roundedTabs";


const Views: React.FunctionComponent<ComponentProps> = props => {
  return (
    <RoundedTabs
      tabs={[
        { label: "All", value: "all" },
        { label: "Recently Updated", value: "recentlyUpdated" },
        { label: "Unread", value: "unread" }
      ]}
      selectedTab={props.view}
      onChangeTab={props.onChangeView}
    />
  );
}

interface ComponentProps {
  view: DocumentsView;
  onChangeView: (view: DocumentsView) => void;
}

export default Views;