import React, { useEffect } from "react";
import ChipLabels from "modules/common/components/chips/chipLabels";
import { NewsletterConfigDigest, NewsletterDetails } from "../../models";
import Divider from "@mui/material/Divider";
import moment from "moment";
import { ShowMoreSection } from "modules/common/components/showMoreSection";

export const Details: React.FunctionComponent<ComponentProps> = props => {
  const { details } = props;
  const [defaultSenderName, setDefaultSenderName] = React.useState("");
  const [defaultSenderEmail, setDefaultSenderEmail] = React.useState("");

  useEffect(() => {
    moment.locale("en");
    if(details?.fromEmailAddress === null || details?.fromEmailName === null)
      props.getConfig().then((currentConfig) => {
        setDefaultSenderName(currentConfig.fromEmailName);
        setDefaultSenderEmail(currentConfig.fromEmailAddress);
      });
  }, [props, details]);

  if (!details)
    return <React.Fragment/>;

  return (
    <div className="newsletter-details">
      <div className="title">{details.title}{details.status === "Disabled" ? <span className="disabled">(Disabled)</span> : ""}</div>
      <div className="description">{details.description}</div>
      <Divider light />
      <div className="details-section">
        <div style={{"wordBreak": "break-word"}}>
          <div>Sender name</div>
            <div>{details.fromEmailName ?? defaultSenderName}</div>
        </div>
        <div style={{"wordBreak": "break-word"}}>
          <div>Sender email</div>
            <div>{details.fromEmailAddress ?? defaultSenderEmail}</div>
        </div>
      </div>
      <Divider light />
      <div className="details-section">
        {!!details.lastIssueSentOn &&
          <div>
            <div>Last sent on</div>
              <div>{moment(details.lastIssueSentOn).format("MMM D, YYYY, h:mmA")}</div>
          </div>
        }
        {!!details.nextIssueSendOn &&
          <div>
            <div>Next queued for</div>
              <div>{moment(details.nextIssueSendOn).format("MMM D, YYYY, h:mmA")}</div>
          </div>
        }
        <div>
          <div>Frequency</div>
            {details.recurrenceType === "Adhoc"
              ? <div>{details.recurrenceType}</div>
              : <div>Every {details.recurrenceType === "Monthly" ? `${details.monthlyRecurrenceWeek} ${details.monthlyRecurrenceDay}` : (details.recurrenceType === 'Biweekly' ? `other ${moment(details.firstIssueDateTime).format('dddd')}` : details.dailyRecurrenceOn.join(", "))}</div>
            }
        </div>
      </div>
      <Divider light />
      <ShowMoreSection body={
        <div>
          <div className="details-section">
            <div>
              <div>Sent to</div>
              <div className="audiences"><ChipLabels chips={details.audiences || []} emptyText="All users" /></div>
            </div>
            <div>
              <div>Topics used</div>
              <div className="topics">
                <ChipLabels
                  chips={details.customTopics || []}
                  emptyText={details.topicOption === "All"
                    ? "All topics"
                    : details.topicOption === "Employee"
                      ? "Employee's subscriptions"
                      : "None"
                  }
                />
              </div>
            </div>
          </div>
          <Divider light />
          <div className="details-section">
            <div>
              <div>Subject</div>
              <div>{details.emailSubject}</div>
            </div>
            {!!details.emailPreheader &&
              <div>
                <div>Preheader</div>
                <div>{details.emailPreheader}</div>
              </div>
            }
          </div>
        </div>
      }/>
    </div>
  );
}

interface ComponentProps {
  details?: NewsletterDetails;
  getConfig: () => Promise<NewsletterConfigDigest>
}