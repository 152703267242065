import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import * as actions from "../../actionCreator";
import { GlobalApplicationState } from "globalApplicationState";

import { DocumentFeedItem } from "../../models";

import LoadingOverlay from "modules/common/components/loadingOverlay";
import ShareButton from "modules/common/components/buttons/shareButton";

import DocumentMoreInfo from "./components/documentViewMoreInfo";

import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Snackbar from "@mui/material/Snackbar";

import CloseIcon from "@mui/icons-material/Close";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import VisibilityIcon from "@mui/icons-material/Visibility";

import Alert from '@mui/material/Alert';


import "../../styles/documentViews.sass";


class DocumentView extends React.Component<PropsWithRedux, ComponentState> {
  constructor(props: PropsWithRedux) {
    super(props);

    this.state = {
    };
  }

  public render() {
    if (!this.props.view)
      return <React.Fragment></React.Fragment>;

    return (
      <Dialog
        open={this.props.show}
        maxWidth={false}
        scroll="paper"
        onClose={this.onClose}
        classes={{ paper: "document-view-paper" }}
        className="document-view"
      >
        <div className="view-commands">
          <IconButton onClick={this.onClose} size="large">
            <CloseIcon />
          </IconButton>
        </div>
        <Paper elevation={6} className="document-info">
          <div className="document-info-header" style={{height: "45px"}}>
            <div style={{fontSize: "18px", paddingLeft: "12px"}}>More Information</div>
          </div>
          <DocumentMoreInfo view={this.props.view} />
          <div className="document-info-footer">
            <div style={{marginLeft: "auto", display: "flex", flexDirection: "row", position: "relative", top: "8px", marginRight: "10px"}}>
              <IconButton
                title="Download"
                size="small"
                onClick={() => {
                  this.props.onDownloadDocument(this.props.view!);
                  this.onClose();
                }}>
                <SaveAltIcon fontSize="small" />
              </IconButton>
              {(this.props.view.fileType === "jpeg" || this.props.view.fileType === "jpg" || this.props.view.fileType === "pdf" || this.props.view.fileType === "png" || this.props.view.fileType === "txt") &&
                <IconButton title="View" size="small" onClick={() => {
                  this.props.onViewDocument(this.props.view!);
                  this.onClose();
                }}>
                  <VisibilityIcon fontSize="small" />
                </IconButton>
              }
              <ShareButton
                articleId={this.props.view.id}
                articleTitle={this.props.view.title || this.props.view.fileName}
                articleType="document"
                size="small"
                warningMessage="This document may not be accessible to other colleagues. Contact your administrator for more details."
              />
            </div>
          </div>
        </Paper>
        <Snackbar open={!!this.props.errorMessage} anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={6000} onClose={this.props.clearErrorMessage}>
          <Alert variant="filled" severity="error" elevation={6} onClose={this.props.clearErrorMessage}>{this.props.errorMessage}</Alert>
        </Snackbar>
        <LoadingOverlay show={this.props.isSaving || this.props.isUploading} styles={{ paddingTop: 150 }} />
      </Dialog>
    );
  }

  private onClose = () => {
    this.props.onClose();
  }
}


interface ComponentProps {
  show: boolean;
  view: DocumentFeedItem | undefined;
  onDownloadDocument: (document: DocumentFeedItem) => void;
  onViewDocument: (document: DocumentFeedItem) => void;
  onClose: () => void;
}

interface ComponentState {
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    errorMessage: state.documents.errorMessage,
    isSaving: state.documents.isSaving,
    isUploading: state.documents.isUploading
  }),
  {
    clearErrorMessage: actions.clearErrorMessage
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(DocumentView);