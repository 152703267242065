import { Action } from "redux";
import { ActionCreator, CompleteAction } from 'typedActions';
import { SupportItem, AssociatedLink, SupportTab } from "./models";

export const CREATE_NEW_SUPPORT_ITEM = 'CREATE_NEW_SUPPORT_ITEM';
export interface CreateNewSupportItem extends Action { };
export const CreateNewSupportItem = ActionCreator<CreateNewSupportItem>(CREATE_NEW_SUPPORT_ITEM);

export const CREATE_NEW_ITEM_COMPLETE = 'CREATE_NEW_ITEM_COMPLETE';
export interface CreateNewItemComplete extends CompleteAction { itemId: string };
export const CreateNewItemComplete = ActionCreator<CreateNewItemComplete>(CREATE_NEW_ITEM_COMPLETE);

export const FETCH_SUPPORT_ITEM = 'FETCH_SUPPORT_ITEM';
export interface FetchSupportItem extends Action { };
export const FetchSupportItem = ActionCreator<FetchSupportItem>(FETCH_SUPPORT_ITEM);

export const FETCH_SUPPORT_ITEM_COMPLETE = 'FETCH_SUPPORT_ITEM_COMPLETE';
export interface FetchSupportItemComplete extends CompleteAction { item: SupportItem | null};
export const FetchSupportItemComplete = ActionCreator<FetchSupportItemComplete>(FETCH_SUPPORT_ITEM_COMPLETE);

export const SAVE_SUPPORT_ITEM = 'SAVE_SUPPORT_ITEM';
export interface SaveSupportItem extends Action { };
export const SaveSupportItem = ActionCreator<SaveSupportItem>(SAVE_SUPPORT_ITEM);

export const SAVE_SUPPORT_ITEM_COMPLETE = 'SAVE_SUPPORT_ITEM_COMPLETE ';
export interface SaveSupportItemComplete extends CompleteAction { };
export const SaveSupportItemComplete = ActionCreator<SaveSupportItemComplete>(SAVE_SUPPORT_ITEM_COMPLETE );

export const DELETE_SUPPORT_ITEM = 'DELETE_SUPPORT_ITEM';
export interface DeleteSupportItem extends Action { };
export const DeleteSupportItem = ActionCreator<DeleteSupportItem>(DELETE_SUPPORT_ITEM);

export const DELETE_SUPPORT_ITEM_COMPLETE = 'DELETE_SUPPORT_ITEM_COMPLETE ';
export interface DeleteSupportItemComplete extends CompleteAction { };
export const DeleteSupportItemComplete = ActionCreator<DeleteSupportItemComplete>(DELETE_SUPPORT_ITEM_COMPLETE );

type supportKey = keyof SupportItem;
export const SUPPORT_EDIT_FIELD_UPDATE = 'SUPPORT_EDIT_FIELD_UPDATE';
export interface FieldUpdate extends Action { field: supportKey, value: any }
export const FieldUpdate = ActionCreator<FieldUpdate>(SUPPORT_EDIT_FIELD_UPDATE);

export const PUBLISH_SUPPORT_ITEM = 'PUBLISH_SUPPORT_ITEM';
export interface PublishSupportItem extends Action { };
export const PublishSupportItem = ActionCreator<PublishSupportItem>(PUBLISH_SUPPORT_ITEM);

export const PUBLISH_SUPPORT_ITEM_COMPLETE = 'PUBLISH_SUPPORT_ITEM_COMPLETE ';
export interface PublishSupportItemComplete extends CompleteAction {
    validationResult?: {
        errors?: { [key: string]: string[] },
        itemTitle?: string,
        id?: string
    }
 };
export const PublishSupportItemComplete = ActionCreator<PublishSupportItemComplete>(PUBLISH_SUPPORT_ITEM_COMPLETE );

export const PUBLISH_SUCCESS = 'PUBLISH_SUCCESS';
export interface PublishSuccess extends Action { }
export const PublishSucess = ActionCreator<PublishSuccess>(PUBLISH_SUCCESS);

export const UPLOAD_DOCUMENT = 'UPLOAD_DOCUMENT';
export interface UploadDocument extends Action { }
export const UploadDocument = ActionCreator<UploadDocument>(UPLOAD_DOCUMENT);

export const SET_UPLOADING_DOC = "SET_UPLOADING_DOC";
export interface SetUploadingDoc extends Action { uploading: boolean }
export const SetUploadingDoc = ActionCreator<SetUploadingDoc>(SET_UPLOADING_DOC);

export const UPLOAD_DOCUMENT_COMPLETE = 'UPLOAD_DOCUMENT_COMPLETE';
export interface UploadDocumentComplete extends CompleteAction { id: string | null, fileName?: string }
export const UploadDocumentComplete = ActionCreator<UploadDocumentComplete>(UPLOAD_DOCUMENT_COMPLETE);

export const UPLOAD_FILES = 'UPLOAD_FILES';
export interface UploadFiles extends Action { }
export const UploadFiles = ActionCreator<UploadFiles>(UPLOAD_FILES);

export const UPLOAD_FILES_COMPLETE = 'UPLOAD_FILES_COMPLETE';
export interface UploadFilesComplete extends CompleteAction { id: string | null, fileName?: string }
export const UploadFilesComplete = ActionCreator<UploadFilesComplete>(UPLOAD_FILES_COMPLETE);

export const SUPPORT_ASSOCIATED_LINKS_UPDATE = 'SUPPORT_ASSOCIATED_LINKS_UPDATE';
export interface SupportAssociatedLinksUpdate extends Action { associatedLink: AssociatedLink }
export const SupportAssociatedLinksUpdate = ActionCreator<SupportAssociatedLinksUpdate>(SUPPORT_ASSOCIATED_LINKS_UPDATE);

export const REMOVE_ASSOCIATED_LINK = 'REMOVE_ASSOCIATED_LINK';
export interface RemoveAssociatedLink extends Action { id: string }
export const RemoveAssociatedLink = ActionCreator<RemoveAssociatedLink>(REMOVE_ASSOCIATED_LINK);

export const SET_ACTIVE_SUPPORT_TAB =  "SET_ACTIVE_SUPPORT_TAB";
export interface SetActiveSupportTab extends Action {
    activeTab: SupportTab;
}
export const SetActiveSupportTab = ActionCreator<SetActiveSupportTab>(SET_ACTIVE_SUPPORT_TAB);

export const CLEAR_VALIDATION_ERRORS = "CLEAR_VALIDATION_ERRORS";
export interface ClearValidationErrors extends Action { }
export const ClearValidationErrors = ActionCreator<ClearValidationErrors>(CLEAR_VALIDATION_ERRORS);