import React from "react";
import { GlobalApplicationState } from "globalApplicationState";
import { connect, ConnectedProps } from "react-redux";
import { push } from "react-router-redux";
import { audiencesApi } from "api/instances";

import { AudienceGroup, AudienceUser, EmailValidation, GroupSuggestions, AudienceUserSuggestionsV2, AudienceInviteSuggestions, AudienceInvite } from "../../../models";

import CSVUploader from "../../csvUploader";
import CSVValidation from "./csvValidation";
import Disclaimer from "modules/common/components/disclaimer";
import ErrorSnackbar from "modules/common/components/snackbars/errorSnackbar";
import GroupList from "./groupList";
import LoadingOverlay from "modules/common/components/loadingOverlay";
import SearchUserGroups from "./searchUserGroups";
import SearchUsers from "./searchUsers";
import UserList from "./userList";

import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import Link from "@mui/material/Link";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SearchInvites from "./searchInvites";
import InviteList from "./inviteList";
import { actions } from "modules/users";
import { FileDownloader } from "utils/fileDownloader";

const FETCH_AMOUNT = 20;

class  StandardRules extends React.Component<PropsWithRedux, ComponentState> {
  constructor(props: PropsWithRedux) {
    super(props);

    this.state = {
      errorMessage: '',
      activeTab: "users",
      isValidatingEmails: false,
      totalIndividualUsers: props.totalIndividualUsers,
      totalIndividualInvites: props.totalIndividualInvites,
      userAmountFetched: 0,
      validationResults: undefined
    };
  }

  public render() {
    return (
      <div className="standard-rules">
        <div className="standard-rules-tabs">
          <Tabs indicatorColor="primary" value={this.state.activeTab} onChange={(_, value) => this.onChangeTab(value)}>
            <Tab value="users" label={`Users (${this.state.totalIndividualUsers})`} />
            {this.props.userGroupsEnabled && <Tab value="groups" label={`${this.props.syncType} Groups (${this.props.includedGroups.length})`} />}
            <Tab value="invites" label={`Invited Users (${this.state.totalIndividualInvites})`} />
            <Tab value="importEmails" label={`Import emails${!!this.state.validationResults ? ` (${this.state.validationResults.validEmailCount})` : ""}`} />
          </Tabs>
        </div>
        {this.state.activeTab === "users" &&
          <div>
            <Disclaimer
              icon={<InfoOutlinedIcon />}
              text={`Search, add or remove unique users from your Sparrow Directory. Users added through ${this.props.syncType} Groups will remain in the Audience unless the Group is removed or the user is removed from the Group.`}
            />
            <SearchUsers
              amountFetched={this.state.userAmountFetched}
              includedUsers={this.props.includedUsers}
              onAddUser={this.onAddUser}
              onRemoveUser={this.onRemoveUser}
              onSearch={this.onSearchUsers}
            />
            <UserList
              includedUsers={this.props.includedUsers}
              onRemoveUser={this.onRemoveUser}
            />
          </div>
        }
        {this.state.activeTab === "invites" &&
          <div>
            <Disclaimer
              icon={<InfoOutlinedIcon />}
              text={<div>
              Invited users are social users accounts that have not been activated yet.
              To activate their accounts, the invite sent to their emails need to be accepted.
              Manage your user invites in <Link variant="body2" component="button" underline="always" onClick={this.goToUsersAndPreferences}>Users & Preferences</Link>.
              </div>}
            />
            <SearchInvites
              amountFetched={this.state.userAmountFetched}
              includedInvites={this.props.includedInvites}
              onAddUser={this.onAddInvite}
              onRemoveUser={this.onRemoveInvite}
              onSearch={this.onSearchInvites}
            />
            <InviteList
              includedInvites={this.props.includedInvites}
              onRemoveUser={this.onRemoveInvite}
            />
          </div>
        }
        {this.state.activeTab === "groups" &&
          <div>
            <Disclaimer
              icon={<InfoOutlinedIcon />}
              text={
                <React.Fragment>
                  Add or remove users using {this.props.syncType} Groups. Can’t find the group you’re looking for?
                  Visit our <Link variant="body2" component="button" underline="always" onClick={this.goToSyncedGroups}>Sync {this.props.syncType} Groups tool</Link> to check if the group is synced to your Sparrow Directory.
                </React.Fragment>
              }
            />
            <SearchUserGroups
              amountFetched={0}
              includedGroups={this.props.includedGroups}
              onAddGroup={this.onAddGroup}
              onRemoveGroup={this.onRemoveGroup}
              onSearch={this.onSearchGroups}
              syncType={this.props.syncType}
            />
            <GroupList
              includedGroups={this.props.includedGroups}
              onRemoveGroup={this.onRemoveGroup}
              syncType={this.props.syncType}
            />
          </div>
        }
        {this.state.activeTab === "importEmails" &&
          (!this.state.validationResults
            ? <CSVUploader
                downloadSample={this.onDownloadSample}
                parseCSV={this.onParseCSV}
              />
            : <CSVValidation
                validationResults={this.state.validationResults}
                onReplaceCSVFile={this.onReplaceCSVFile}
                downloadBulkReport={this.downloadResults}
              />
          )
        }
        <LoadingOverlay absolute={true} show={this.state.isValidatingEmails} styles={{ paddingTop: 150 }} />
        
        <ErrorSnackbar errorMessage={this.state.errorMessage} clearErrorMessage={this.clearErrorMessage} />
      </div>
    );
  }

  private clearErrorMessage = () => {
    this.setState({ errorMessage: "" });
  }

  private goToSyncedGroups = () => {
    this.props.redirectTo("/" + this.props.tenant + "/admin/audiences/everyone");
  }

  private goToUsersAndPreferences = () => {
    this.props.redirectTo("/" + this.props.tenant + "/admin/users");
  }


  private onChangeTab = (value: string) => {
    this.setState({ activeTab: value });
  }


  private onAddGroup = (group: GroupSuggestions) => {
    if(this.props.audienceId){
      audiencesApi.addGroupToAudience(this.props.audienceId, group.id).then((response) => {
        if (response === 'Success')
          this.props.onChangeGroups(this.props.includedGroups.concat([{ groupId: group.id, name: group.name }]));
      }).catch(() => {
        this.setState({ errorMessage: `Unable to add group ${group.name}` });
      });
    } else {
      this.props.onChangeGroups(this.props.includedGroups.concat([{ groupId: group.id, name: group.name }]));
    }
  }

  private onAddUser = (user: AudienceUserSuggestionsV2) => {
    if (this.props.audienceId) {
      // When Editing Audience
      audiencesApi.addUserToAudience(this.props.audienceId, user.id).then((response) => {
        if (response === 'Success') {
          this.props.onChangeUsers(this.props.includedUsers.concat([{ userId: user.id, email: user.email, name: user.name }]));
          this.setState({ totalIndividualUsers: this.state.totalIndividualUsers + 1 });
        }
      }).catch(() => {
        this.setState({ errorMessage: `Unable to add user ${user.email}` });
      });
    }else {
      // When creating new Audience
      this.props.onChangeUsers(this.props.includedUsers.concat([{ userId: user.id, email: user.email, name: user.name }]));
      this.setState({ totalIndividualUsers: this.state.totalIndividualUsers + 1 });
    }
  }

  private onAddInvite = (invite: AudienceInviteSuggestions) => {
    if (this.props.audienceId) {
      // When Editing Audience
      audiencesApi.addInviteToAudience(this.props.audienceId, invite.id).then((response) => {
        if (response === 'Success') {
          this.props.onChangeInvites(this.props.includedInvites.concat([{ inviteId: invite.id, email: invite.email }]));
          this.setState({ totalIndividualInvites: this.state.totalIndividualInvites + 1 });
        }
      }).catch(() => {
        this.setState({ errorMessage: `Unable to add invite ${invite.email}` });
      });
    }else {
      // When creating new Audience
      this.props.onChangeInvites(this.props.includedInvites.concat([{ inviteId: invite.id, email: invite.email }]));
      this.setState({ totalIndividualInvites: this.state.totalIndividualInvites + 1 });
    }
  }

  private onDownloadSample = () => {
    let rows = [
      ["email"],
      ["sample1@email.com"],
      ["sample2@email.com"]
    ];

    const file = {
      name: "sampleBulkAdd.csv",
      type: "data:text/csv;charset=utf-8,%EF%BB%BF"
    };
    new FileDownloader(file).downloadSampleCSV(rows);
  }

  private onParseCSV = async (file: File) => {
    this.setState({ isValidatingEmails: true });

    let emails: string[] = [];
    await new Promise<void>((resolve) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const contents = reader.result!.toString().trim();
        const rows = contents.split("\n");
        for (let row of rows) {
          const email = row.split(",")[0].toLowerCase().trim();
          if (email === "emails" || email === "email") { // skip header, they might format it as emails or email
            continue;
          }
          if (this.validateRow(email, emails)) {
            emails.push(email);
          } else {
            console.log(`Skipped adding ${email}. This could be because the email has an invalid format or is a duplicate`);
          }
        }
        resolve();
      };
      reader.readAsText(file);
    });

    this.props.onChangeEmails(emails);

    this.onValidateEmails(emails);

    return emails;
  }

  private onRemoveGroup = (id: string) => {
    if (this.props.audienceId) {
      audiencesApi.removeGroupToAudience(this.props.audienceId, id).then((response)=> {
        if (response === 'Success')
            this.props.onChangeGroups(this.props.includedGroups.filter(group => group.groupId !== id));
      }).catch(() => {
        this.setState({ errorMessage: `Unable to remove group`});
      });
    }
    else {
      this.props.onChangeGroups(this.props.includedGroups.filter(group => group.groupId !== id));
    }
  }

  private onRemoveUser = (id: string) => {
    if (this.props.audienceId){
      audiencesApi.removeUserToAudience(this.props.audienceId, id).then((response)=> {
        if (response === 'Success'){
          this.props.onChangeUsers(this.props.includedUsers.filter(user => user.userId !== id));
          this.setState({ totalIndividualUsers: this.state.totalIndividualUsers - 1 });
        }
      }).catch(() => {
        this.setState({ errorMessage: `Unable to remove user`});
      });
    }else {
        this.props.onChangeUsers(this.props.includedUsers.filter(user => user.userId !== id));
        this.setState({ totalIndividualUsers: this.state.totalIndividualUsers - 1 });
    }
  }

  private onRemoveInvite = (id: string) => {
    if (this.props.audienceId){
      audiencesApi.removeInviteFromAudience(this.props.audienceId, id).then((response)=> {
        if (response === 'Success'){
          this.props.onChangeInvites(this.props.includedInvites.filter(invite => invite.inviteId !== id));
          this.setState({ totalIndividualInvites: this.state.totalIndividualInvites - 1 });
        }
      }).catch(() => {
        this.setState({ errorMessage: `Unable to remove invite`});
      });
    }else {
        this.props.onChangeInvites(this.props.includedInvites.filter(invite => invite.inviteId !== id));
        this.setState({ totalIndividualInvites: this.state.totalIndividualInvites - 1 });
    }
  }

  private onReplaceCSVFile = () => {
    this.props.onChangeEmails([]);
    this.setState({ validationResults: undefined });
  }

  private onSearchGroups = (searchText: string): Promise<GroupSuggestions[]> => {
    var cleanedInput = searchText.trim();
    return audiencesApi
    .getAudienceUserGroupSuggestion(this.props.audienceId, { maxResults: FETCH_AMOUNT, textToSearch: cleanedInput})
    .then((response) => {
      return response;
    });
  }

  private onSearchUsers = (searchText: string): Promise<AudienceUserSuggestionsV2[]> => {
    var cleanedInput = searchText.trim();
    return audiencesApi
      .getAudienceUserSuggestion(this.props.audienceId, { maxResults: FETCH_AMOUNT, textToSearch: cleanedInput})
      .then((response) => {
        return response;
      });
  }

  private onSearchInvites = (searchText: string): Promise<AudienceInviteSuggestions[]> => {
    var cleanedInput = searchText.trim();
    return audiencesApi
      .getAudienceInviteSuggestion(this.props.audienceId, { maxResults: FETCH_AMOUNT, textToSearch: cleanedInput})
      .then((response) => {
        return response;
      });
  }

  private onValidateEmails = (emails: string[]) => {
    return audiencesApi.validateEmails(emails).then((response) => {
      this.setState({
        isValidatingEmails: false,
        validationResults: response
      });
    });
  }

  // https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
  private validateEmail = (email: string) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  private validateRow = (value: string, values: string[]) => {
    return !values.includes(value) && this.validateEmail(value);
  }

  private downloadResults = () => {
    const file = {
      name: `${"bulk-upload-audience-users"}-${new Date().toISOString()}.csv`
    };
    this.props.downloadSocialBulkCSV(this.props.emails)
      .then(data => new FileDownloader(file).downloadBlob(data));
  }
}

interface ComponentProps {
  audienceId: string;
  emails: string[];
  includedGroups: AudienceGroup[];
  includedUsers: AudienceUser[];
  includedInvites: AudienceInvite[];
  totalIndividualUsers: number;
  totalIndividualInvites: number;
  syncType: string;
  onChangeGroups: (includedGroups: AudienceGroup[]) => void;
  onChangeEmails: (emails: string[]) => void;
  onChangeUsers: (includedUsers: AudienceUser[]) => void;
  onChangeInvites: (includedInvites: AudienceInvite[]) => void;
}

interface ComponentState {
  errorMessage: string;
  activeTab: string;
  isValidatingEmails: boolean;
  totalIndividualUsers: number;
  totalIndividualInvites: number;
  userAmountFetched: number;
  validationResults?: EmailValidation;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    tenant: state.tenant.id,
    userGroupsEnabled: state.settings.tenantSettings.ADGroupSyncEnabled || state.settings.tenantSettings.showFeatures.everyoneAudienceEnabled,
    syncType: state.settings.tenantSettings.syncConfig.syncType
  }),
  {
    redirectTo: push,
    downloadSocialBulkCSV: actions.getBulkImportResults
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(StandardRules);