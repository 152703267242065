import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";

import FilterContainer from "modules/common/components/filters/filterContainer";
import TenantFilterButtons from "./tenantFilterButtons";
import TenantSearch from "./tenantSearch";


const TenantFilter: React.FunctionComponent<ComponentProps> = ({
    textToSearch,
    searchTextPlaceholder,
    canSearch,
    onUpdateTextToSearch
}) => {

    
    return (
    <FilterContainer 
    filters={
        <React.Fragment>
            <TenantSearch 
            textToSearch={textToSearch}
            searchTextPlaceholder={searchTextPlaceholder}
            onUpdateTextToSearch={onUpdateTextToSearch}/>
        </React.Fragment>
    }
    filterCommands={<TenantFilterButtons canSearch={canSearch} 
    onApplyFilters={function (): void {
        throw new Error("Function not implemented.");
    } } onClearFilters={function (): void {
        throw new Error("Function not implemented.");
    } } />}
    />
    );
}

interface ComponentProps {
  textToSearch: string;
  searchTextPlaceholder?: string;
  canSearch: boolean;
  onUpdateTextToSearch: (event) => void;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps
  })
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(TenantFilter);