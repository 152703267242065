import * as React from "react";

import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";

import BlockIcon from "@mui/icons-material/Block";


class Author extends React.Component<ComponentProps, ComponentState> {
  public render() {
    return (
      <Collapse in={this.props.show}>
        <Paper elevation={0} className="section-card">
          <div className="optional-content-header">
            <div></div>
            <div className="remove-optional-content">
              <Button variant="text" color="inherit" startIcon={<BlockIcon />} onClick={this.props.onRemove}>Remove Custom {this.props.type}</Button>
            </div>
          </div>
          <div className="author">
            <div className="custom-author-label">
              {this.props.type}'s Name
              <TextField
                key={this.props.author}
                variant="outlined"
                size="small"
                placeholder="Enter name"
                defaultValue={this.props.author || ""}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
                onBlur={this.onChangeAuthor}
              />
            </div>
            
            <div className="custom-author-label">
              {this.props.type}'s Email Address
              <TextField
                key={this.props.authorEmail}
                variant="outlined"
                size="small"
                placeholder="Enter valid email address"
                defaultValue={this.props.authorEmail || ""}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
                onBlur={this.onChangeAuthorEmail}
              />
            </div>
          </div>
        </Paper>
      </Collapse>
    );
  }

  private onChangeAuthor = (event) => {
    this.props.onChangeAuthor(event.target.value);
  }

  private onChangeAuthorEmail = (event) => {
    this.props.onChangeAuthorEmail(event.target.value);
  }
}


interface ComponentProps {
  show: boolean;
  author: string | undefined;
  authorEmail: string | undefined;
  type: "Author" | "Organizer";
  onChangeAuthor: (author: string) => void;
  onChangeAuthorEmail: (authorEmail: string) => void;
  onRemove: () => void;
}

interface ComponentState {
}

export default Author;