import React from "react";
import { connect, ConnectedProps } from "react-redux";
import * as actions from "../../actionCreator";
import { GlobalApplicationState } from "globalApplicationState";

import MoreOptionsButton from "modules/common/components/buttons/moreOptionsButton";

import List from "@mui/material/List";
import MoreOptionsItem from "modules/common/components/moreOptionsItem";


const OpenGlobalComponents: React.FunctionComponent<PropsWithRedux> = props => {
  return (
    <div>
      <MoreOptionsButton>
        <List disablePadding>
          <MoreOptionsItem
            text="Open Global Components"
            onClick={props.showGlobalComponents}
          />
        </List>
      </MoreOptionsButton>
    </div>
  );
}

interface ComponentProps {}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps
  }),
  {
    showGlobalComponents: actions.showGlobalComponents
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(OpenGlobalComponents);