import * as React from "react";
import Modal from "modules/common/components/modal";
import './tagSubscriptionManagerModal.sass'
import { injectIntl, IntlShape, FormattedMessage } from "react-intl";
import { Audience } from "modules/audiences/models";
import { audiencesApi } from "api/instances";
import { TenantSettingsTag } from "modules/settings";
import confirm from 'utils/notyPopups';
import "./tagListItem.sass";
import ToggleSwitch from "modules/common/components/toggleSwitch";
import MultiSelect from "modules/common/components/forms/inputs/multiselect";

interface ComponentProps {
    show: boolean;
    closeModal: () => void;
    intl: IntlShape;
    saveNewTag: () => void;
    newTag: TenantSettingsTag | undefined;
    updateTag: (id: string,
        name: string,
        mandatory: boolean,
        defaultValue: boolean,
        disabled: boolean,
        deleted: boolean,
        restricted: boolean,
        restrictedTo: string[],
        mandatoryFor: string[],
        defaultFor: string[]
    ) => void;
    errorMssg: string;
    showAudiences: boolean;
}

interface ComponentState {
    audiences: Audience[];
    fetching: boolean;
    saving: boolean;
    confirmShowing: boolean;
}

class TagCreationModal extends React.PureComponent<ComponentProps, ComponentState> {

    constructor(props) {
        super(props);
        this.state = {
            audiences: [],
            fetching: true,
            saving: false,
            confirmShowing: false
        }
    }

    public componentDidMount() {
        audiencesApi.getAllAudiences()
        .then(response => {
            this.setState({
                audiences: response,
                fetching: false
            });
        })
    }

    public render() {
        let tag = this.props.newTag;

        return   (<Modal active={this.props.show}
                    title={this.props.intl.formatMessage({id: "common.topicCreation", defaultMessage: 'Create New Topic'})}
                    showControls={true}
                    onCloseClick={this.handleModalCloseClick}
                    buttons={[
                        { title: "Save", workingTitle: "Saving...", isWorking: this.state.saving, isPrimary: true, isEnabled: !this.state.saving && this.props.newTag?.name !== "", onClick: this.props.saveNewTag },
                        { title: "Cancel", isPrimary: false, isEnabled: !this.state.saving, onClick: this.props.closeModal }

                    ]}
                    hideForNoty={true}
                    >
                        <div className="new-tag-modal">
                            <div className="option-wrapper">
                                <label>Topic name</label>
                                <input className="form-control"
                                    autoComplete="off"
                                    value={this.props.newTag?.name}
                                    style={{width: "200px", height: "18px"}}
                                    onChange={(e) => this.handleTagNameChange(e)}
                                />
                            </div>

                            <div className="option-wrapper">
                                <label>Mandatory</label>
                                <ToggleSwitch
                                    value={tag?.mandatory || false}
                                    onClick={this.toggleTagMandatory}
                                    disabled={tag?.disabled} />

                            </div>
                            <p><FormattedMessage id="common.mandatoryTopic" defaultMessage="Users in the selected audiences will always be subscribed to this topic. If no audiences are selected, all users will always be subscribed."/></p>
                            <div className="select-wrapper">
                            {
                                this.renderSelection("mandatory")
                            }
                            </div>
                            <div className="option-wrapper">
                                <label>Default</label>
                                <ToggleSwitch
                                    value={tag?.default || false}
                                    onClick={this.toggleTagDefault}
                                    disabled={tag?.disabled} />

                            </div>
                            <p><FormattedMessage id="common.defaultTopic" defaultMessage="New users in the selected audiences will always be subscribed to this topic by default. If no audiences are selected, all new users will be subscribed by default."/></p>
                            <div className="select-wrapper">
                            {
                                this.renderSelection("default")
                            }
                            </div>
                            {
                                this.props.showAudiences ?
                                <span>
                                    <div className="option-wrapper">
                                        <label>Restricted</label>
                                        <ToggleSwitch
                                            value={tag?.restricted || false}
                                            onClick={this.toggleTagRestricted}
                                            disabled={tag?.disabled} />

                                    </div>
                                    <p><FormattedMessage id="common.restrictedTopic" defaultMessage="Users must be a part of the selected audiences to see this topic. Restricted topics must have at least one audience."/></p>
                                    <div className="select-wrapper">
                                    {
                                        this.renderSelection("restricted")
                                    }
                                    </div>
                                </span>
                                : null
                            }
                            <div className="error-mssg">
                                {this.props.errorMssg}
                            </div>
                        </div>


                </Modal>);
    }

    private handleModalCloseClick = () => {
        this.props.closeModal();
    }

    private handleTagNameChange = (e: React.FormEvent<HTMLInputElement>) => {
        let input = e.target as HTMLInputElement;
        let tag = this.props.newTag;
        if (tag) {
            this.props.updateTag(tag.id, input.value, tag.mandatory, tag.default, tag.disabled, tag.deleted, tag.restricted, tag.restrictedTo, tag.mandatoryFor, tag.defaultFor);
        }
    }

    private toggleTagMandatory = () => {
        let tag = this.props.newTag;
        if (tag) {
            let newMandatoryFor = tag.mandatoryFor;
            if (tag.mandatory && tag.mandatoryFor.length > 0) { //if mandatory was on and had mandatoryFor list, empty it before turning off
                newMandatoryFor = [];
            }
            this.props.updateTag(tag.id, tag.name, !tag.mandatory, tag.default, tag.disabled, tag.deleted, tag.restricted, tag.restrictedTo, newMandatoryFor, tag.defaultFor);
        }
    }

    private toggleTagDefault = () => {
        let tag = this.props.newTag;
        if (tag) {
            let newDefaultFor = tag.defaultFor;
            if (tag.default && tag.defaultFor.length > 0) { //if default was on and had defaultFor list, empty it before turning off
                newDefaultFor = [];
            }
            this.props.updateTag(tag.id, tag.name, tag.mandatory, !tag.default, tag.disabled, tag.deleted, tag.restricted, tag.restrictedTo, tag.mandatoryFor, newDefaultFor);
        }
    }

    private toggleTagRestricted = async () => {
        let tag = this.props.newTag;
        if (tag) {
            var newRestrictedTo;
            if (!tag.restricted) { //if restricted was off, confirm turning it on
                this.setState({...this.state, confirmShowing: true})
                if (! (await confirm.show({ text: 'Posts published with this topic will only be visible to users in the selected audience(s). <br/><br/>Do you want to continue?', title: 'Set topic as restricted' }))) {
                    this.setState({...this.state, confirmShowing: false})
                    return; //if declines return
                }
                newRestrictedTo = tag.restrictedTo;
            }
            if (tag.restricted) { //if restricted was on, confirm turning it off
                if (! (await confirm.show({ text: 'Posts published with this topic may become visible to anyone in your organization. <br/><br/>Do you want to continue?', title: 'Removing restriction from topic' }))) {
                    return; //if declines return
                }
                newRestrictedTo = [];
            }

            this.props.updateTag(tag.id, tag.name, tag.mandatory, tag.default, tag.disabled, tag.deleted, !tag.restricted, newRestrictedTo, tag.mandatoryFor, tag.defaultFor)
        }
    }

    private renderSelection = (type: string) => {
        let options = this.state.audiences.map(a => ({ label: a.displayName, value: a.id }))

        const selectStyles = {
            multiValue: (styles) => {
                return {
                    ...styles,
                    backgroundColor: "rgba(242,249,252,0.2)",
                    border: "1px solid rgba(201,230,242)",
                    color: "rgb(3,169,244)"
                };
            }
        }

        let placeholder = "";
        let audienceValues: string[] = [];
        let isTypeOn = false;
        if (this.props.newTag) {
            if(type === "mandatory") {
                isTypeOn = this.props.newTag.mandatory;
                audienceValues = this.props.newTag.mandatoryFor;
                if (isTypeOn) {
                    placeholder = "Everyone or select audience(s)...";
                }
            }
            else if(type === "default") {
                isTypeOn = this.props.newTag.default;
                audienceValues = this.props.newTag.defaultFor;
                if (isTypeOn) {
                    placeholder = "Everyone or select audience(s)...";
                }
            }
            else {
                isTypeOn = this.props.newTag.restricted;
                audienceValues = this.props.newTag.restrictedTo;
                if (isTypeOn) {
                    placeholder = "Restricted subscription for....";
                }
            }
        }

        let initialValues = (audienceValues|| []).map(a => ({ label: (this.state.audiences.find(al => al.id === a) || { displayName: 'unknown' }).displayName, value: a }));

        if (!this.props.showAudiences) {
            placeholder = "Everyone";
        }

        return (
            <MultiSelect
                placeholder={placeholder}
                options={options}
                onChange={this.onAudiencesChange(type)}
                value={initialValues}
                isDisabled={!isTypeOn}
                styles={selectStyles}
                components={ !this.props.showAudiences ? { DropdownIndicator:() => null , IndicatorSeparator:() => null } : { } }
            />
        )
    }

    private updateTagGroups = (tag: TenantSettingsTag | undefined, audienceIds: string[], type: string) => {
        if (tag) {
            if (type === "mandatory") {
                this.props.updateTag(tag.id, tag.name, tag.mandatory, tag.default, tag.disabled, tag.deleted, tag.restricted, tag.restrictedTo, audienceIds, tag.defaultFor)
            }
            else if (type === "default") {
                this.props.updateTag(tag.id, tag.name, tag.mandatory, tag.default, tag.disabled, tag.deleted, tag.restricted, tag.restrictedTo, tag.mandatoryFor, audienceIds)
            }
            else {
                this.props.updateTag(tag.id, tag.name, tag.mandatory, tag.default, tag.disabled, tag.deleted, tag.restricted, audienceIds, tag.mandatoryFor, tag.defaultFor)
            }
        }
    }

    private onAudiencesChange = (type: string) => (newValues) => {
        this.updateTagGroups(this.props.newTag, (newValues || []).map(s => s.value), type)
    }
}

export default injectIntl(TagCreationModal);
