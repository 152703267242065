import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";

const TenantFilter: React.FunctionComponent<ComponentProps> = ({
    textToSearch,
    searchTextPlaceholder,
    onUpdateTextToSearch
}) => {
    return (
    <>
      <React.Fragment>
        <TextField
          variant="outlined"
          size="small"
          disabled
          value={textToSearch}
          placeholder={searchTextPlaceholder}
          InputProps={{
            startAdornment: <SearchIcon className="search-icon" />
          }}
          onChange={(e) => {
            onUpdateTextToSearch(e)
          }}
          className="text-to-search"
        />
      </React.Fragment>
    </>
    );
}

interface ComponentProps {
  textToSearch: string;
  searchTextPlaceholder?: string;
  onUpdateTextToSearch: (event) => void;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps
  })
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(TenantFilter);