import ConfirmDialog from 'modules/common/components/dialogs/confirmDialog';
import * as React from 'react';

interface IInvalidFilesDialogProps {
    invalidFiles: File[];
    open: boolean;
    onClose: () => void;
    onDeny: () => void;
    onConfirm: () => void;
    maxUploadMbSize: number;
    maxUploadNumber: number;
    hasValidFilesToUpload: boolean;
}

export const InvalidFilesDialog: React.FunctionComponent<IInvalidFilesDialogProps> = ({
    invalidFiles,
    open,
    onClose,
    onDeny,
    onConfirm,
    children,
    maxUploadMbSize,
    maxUploadNumber,
    hasValidFilesToUpload,
}) => {

    // will give a shortened and formatted filesize converting it's byte unit when necessary and keeping 2 decimal places of precision.
    // ex. a file with a size of 1025 B will show 1.00 KB, 1250000 B will show 1.19 MB and etc
    const formatFileWithFileSize = (file: File): JSX.Element => {
        // range of units ranging from B to TB
        const units = ['B', 'KB', 'MB', 'GB', 'TB'];

        let fileSize = file.size;
        let unitIndex = 0;
    
        // if the filesize is currently over 1024, we can convert it's unit to the next biggest unit up to TBs
        while (fileSize >= 1024 && unitIndex < units.length - 1) {
            fileSize /= 1024;
            unitIndex++;
        }

        return <span>{file.name} <b>{unitIndex === 0 ? fileSize : fileSize.toFixed(2)} {units[unitIndex]}</b></span>;
    }

    const getInvalidFilesErrorList = (): React.ReactElement => {
        return <>
            <div>
                {
                    invalidFiles.length === 1 
                        ? <b>{formatFileWithFileSize(invalidFiles[0])} could not be uploaded. </b>
                        : !hasValidFilesToUpload ? 
                            `All ${invalidFiles.length} files cannot be uploaded. ` :
                            `There are ${invalidFiles.length} files that cannot be uploaded. `
                }
                Only a maxium of {maxUploadNumber} JPG or PNG images with a maximum size of {maxUploadMbSize} MB each are supported.
            </div>
            <ul
                style={{
                    maxHeight: '200px',
                    overflowY: 'auto',
                }}
            >
                {
                    invalidFiles.length > 1 &&
                    invalidFiles.flatMap((invalidFile, i) => {
                        return <li key={i}>
                            {formatFileWithFileSize(invalidFile)}
                        </li>
                    })
                }  
            </ul>
        </>;
    }

    return <ConfirmDialog
        title={invalidFiles.length > 1 ? "Files not supported" : "File not supported"}
        denyLabel="CANCEL"
        confirmLabel={hasValidFilesToUpload ? "CONFIRM" : undefined}
        open={invalidFiles.length > 0 && open}
        onClose={onClose}
        onDeny={onDeny}
        onConfirm={onConfirm}
    >
        <div style={{ minWidth: 400 }}>
            {getInvalidFilesErrorList()}
            <div>{children}</div>
        </div>
    </ConfirmDialog>;
}