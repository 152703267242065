import { adminTinyTemplatesApi } from "api/instances";
import { IdObject } from "../models";
import { ITinyCategory, ITinyTemplate } from "api/adminTinyTemplates";
import { ApiRequest } from "api/network";

export interface ITinyCategoryFilters {
    excludeInlineImages: boolean;
    excludeEmbeddedMedia: boolean;
}

interface ITinyMceTemplateService {
    getCategories: (filters?: ITinyCategoryFilters) => Promise<ITinyCategory[]>;
    getTemplate: (id: string) => Promise<ITinyTemplate | undefined>;
    createCategory: (title: string) => Promise<IdObject<string>>;
    createTemplate: (title: string, content: string, categoryId: string) => Promise<IdObject<string>>;
    renameTemplate: (id: string, title: string) => Promise<{}>;
    renameCategory: (id: string, title: string) => Promise<{}>;
    deleteTemplate: (id: string) => Promise<{}>;
    deleteCategory: (id: string) => Promise<{}>;
    moveTemplate: (templateId: string, categoryId: string) => Promise<{}>;
    moveCategoryItems: (oldCategoryId: string, newCategoryId: string) => Promise<{}>;
}

/**
 * A stateless tiny templates service that implements api here: https://www.tiny.cloud/docs/tinymce/6/advanced-templates
 * The tiny plugin uses references to functions on init and do not update them.
 * Meaning, we can't rely on state values and instead have to hit the API.
 */
export class TinyMceTemplatesService {
    /**
     * advtemplate_list
     *https://www.tiny.cloud/docs/tinymce/6/advanced-templates/#advtemplate_list
     * @returns
     */
    public static async getCategories(filters?: ITinyCategoryFilters): Promise<ITinyCategory[]> {
        let result = await adminTinyTemplatesApi.getAllTinyTemplates(filters);

        // process uncategorized templates
        // remove items array
        return result.map((cat: ITinyCategory) => {
            if (cat.items === null)
                delete cat.items;

            return cat;
        });
    }

    /**
     * advtemplate_create_category
     * https://www.tiny.cloud/docs/tinymce/6/advanced-templates/#advtemplate_create_category
     */
    public static async getTemplate(id: string): Promise<ITinyTemplate | undefined> {
        return await adminTinyTemplatesApi.getTinyTemplate(id);
    }

    /**
     * advtemplate_create_category
     * https://www.tiny.cloud/docs/tinymce/6/advanced-templates/#advtemplate_create_category
     */
    public static async createCategory(title: string): Promise<IdObject<string>> {
        let newCategory: ITinyCategory = {
            title,
            items: [],
            id: "",
        };

        let result = await adminTinyTemplatesApi.upsertTinyCategory(newCategory);

        return result;
    }

    /**
     * advtemplate_create_template
     * https://www.tiny.cloud/docs/tinymce/6/advanced-templates/#advtemplate_create_template
     */
    public static async createTemplate(title: string, content: string, categoryId: string): Promise<IdObject<string>> {
        let newTemplate: ITinyTemplate = {
            id: "",
            title,
            content
        };

        let result: IdObject<string> = await adminTinyTemplatesApi.upsertTinyTemplate(newTemplate);
        newTemplate.id = result.id;

        if (categoryId) {
            await TinyMceTemplatesService.addTemplateToCategory(newTemplate, categoryId);
        }

        return result;
    }

    /**
     * advtemplate_rename_template
     * https://www.tiny.cloud/docs/tinymce/6/advanced-templates/#advtemplate_rename_template
     */
    public static async renameTemplate(id: string, title: string): Promise<{}> {
        const found: ITinyTemplate | undefined = await TinyMceTemplatesService.getTemplate(id);
        if (!found) return {};

        let updatedTemplate: ITinyTemplate = { ...found, title };

        await adminTinyTemplatesApi.upsertTinyTemplate(updatedTemplate);

        return {};
    }

    /**
     * advtemplate_rename_cateogry
     * https://www.tiny.cloud/docs/tinymce/6/advanced-templates/#advtemplate_rename_category
     */
    public static async renameCategory(id: string, title: string): Promise<{}> {
        const found = await TinyMceTemplatesService.getCategory(id);

        if (!found) return {};

        let updatedCategory = { ...found, title };
        await adminTinyTemplatesApi.upsertTinyCategory(updatedCategory);

        return {};
    }

    /**
     * advtemplate_delete_template
     * https://www.tiny.cloud/docs/tinymce/6/advanced-templates/#advtemplate_delete_template
    */
    public static async deleteTemplate(id: string): Promise<{}> {
        await adminTinyTemplatesApi.deleteTinyTemplate(id);
        return {};
    }

    /**
     * advtemplate_delete_category
     * https://www.tiny.cloud/docs/tinymce/6/advanced-templates/#advtemplate_delete_category
     */
    public static async deleteCategory(id: string): Promise<{}> {
        await adminTinyTemplatesApi.deleteTinyCategory(id);
        return {};
    }

    /**
     * advtemplate_move_template
     * - move a template to a different category
     * https://www.tiny.cloud/docs/tinymce/6/advanced-templates/#advtemplate_move_template
     */
    public static async moveTemplate(templateId: string, categoryId: string): Promise<{}> {
        let template = await TinyMceTemplatesService.getTemplate(templateId);
        if (!template) return {};

        let categories = await TinyMceTemplatesService.getCategories();

        let tasks: ApiRequest<IdObject<string>>[] = [];

        let oldCategory = categories.find((category: ITinyCategory) => category.items?.some((template: ITinyTemplate) => template.id === templateId));

        // remove template from old category if necessary
        if (oldCategory) {
            oldCategory = { ...oldCategory };
            oldCategory.items = oldCategory.items?.filter((template: ITinyTemplate) => template.id !== templateId);

            tasks.push(adminTinyTemplatesApi.upsertTinyCategory(oldCategory));
        }

        // add to new category if necessary (categoryId is optional as per tiny docs)
        if (categoryId) {
            let newCategory = categories.find((category: ITinyCategory) => category.id === categoryId);

            if (newCategory) {
                newCategory = { ...newCategory };
                newCategory.items = [...(newCategory.items || []), { ...template }];

                tasks.push(adminTinyTemplatesApi.upsertTinyCategory(newCategory));
            }
        } else {
            // unset id so new uncategorized tiny template gets inserted
            tasks.push(adminTinyTemplatesApi.upsertTinyTemplate({ ...template, id: "" }));
        }

        await Promise.all(tasks);

        return {};
    }

    /**
     * advtemplate_move_category_items
     * - move all templates to a different category
     * https://www.tiny.cloud/docs/tinymce/6/advanced-templates/#advtemplate_move_category_items
     */
    public static async moveCategoryItems(oldCategoryId: string, newCategoryId: string): Promise<{}> {
        let tasks: ApiRequest<IdObject<string>>[] = [];
        let itemsToMove: ITinyTemplate[] = [];
        const categories = await TinyMceTemplatesService.getCategories();

        // remove items from old category if necessary
        if (oldCategoryId) {
            let oldCategory = categories.find((category: ITinyCategory) => category.id === oldCategoryId);

            if (!oldCategory) return {};

            oldCategory = { ...oldCategory };
            itemsToMove = oldCategory.items || [];
            oldCategory.items = [];

            tasks.push(adminTinyTemplatesApi.upsertTinyCategory(oldCategory));
        }

        // add items to new category if necessary
        if (newCategoryId && itemsToMove.length > 0) {
            let newCategory = categories.find((category: ITinyCategory) => category.id === newCategoryId);

            if (!newCategory) return {};

            newCategory = { ...newCategory };
            newCategory.items = [...(newCategory.items || []), ...itemsToMove];

            tasks.push(adminTinyTemplatesApi.upsertTinyCategory(newCategory));
        }

        await Promise.all(tasks);

        return {};
    }

    /**
     * Helper to persist template to a category
     */
    private static async addTemplateToCategory(newTemplate: ITinyTemplate, categoryId: string): Promise<void> {
        const found = await TinyMceTemplatesService.getCategory(categoryId);

        // if category not found or category already has template, do nothing
        if (!found || found.items?.some((template: ITinyTemplate) => template.id === newTemplate.id))
            return;

        let categoryToUpdate = { ...found };
        categoryToUpdate.items = [...(categoryToUpdate.items || []), newTemplate];

        await adminTinyTemplatesApi.upsertTinyCategory(categoryToUpdate);
    }

    /**
     * Helper to find a category
     */
    private static async getCategory(id: string): Promise<ITinyCategory | undefined> {
        const categories = await adminTinyTemplatesApi.getAllTinyTemplates();

        const found: ITinyCategory | undefined = categories.find((category: ITinyCategory) => category.id === id);

        return found;
    }
}
