import React, { useState } from 'react';
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import { Button, Drawer, TablePagination, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search'
import { usersApi } from 'api/instances';
import { InvitedUser } from '../models';
import { useEffect } from 'react';
import moment from 'moment';

import confirm from "utils/notyPopups";
import { PagedInviteFilterValues } from '..';
import LoadingCircle from 'modules/common/components/loadingCircle';
import { Autocomplete } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import InfoIcon from '@mui/icons-material/Info';
import InfoHover from 'modules/common/components/hovers/infoHover';
import { dateOptions } from 'utils/dateFormatting';
import SnackbarWrapper from 'modules/common/components/snackbars/snackbarWrapper';
import { SeverityOptions } from 'utils/muiHelpers';

interface ComponentProps {
    open: boolean;
    onCloseDrawer: () => void;
}

interface PageDetails {
    totalInvites: number;
    currentPage: number;
    totalPages: number;
}

moment.locale("en");

const InvitedUsersDrawer: React.FC<PropsWithRedux> = ({ open, onCloseDrawer }) => {

    const defaultPagedFilter: PagedInviteFilterValues = { invitedEmail: "", pageNumber: 0, pageAmount: 10 }

    const [invitedUsers, setInvitedUsers] = useState<InvitedUser[]>([]);
    const [filter, setFilter] = useState("");
    const [allFilters, setAllFilters] = useState<PagedInviteFilterValues>({ invitedEmail: "", pageNumber: 0, pageAmount: 10 });
    const [pageDetails, setPageDetails] = useState<PageDetails>({ totalInvites: 0, currentPage: 0, totalPages: 0 })
    const [loading, setLoading] = useState(false);
    const [totalUsers, setTotalUsers] = useState(0);

    const [autoCompleteLoading, setAutoCompleteLoading] = useState(false);
    const [autoCompleteOptions, setAutoCompleteOptions] = useState<string[]>([]);

    const [alertMessage, setAlertMessage] = React.useState("");
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [alertSeverity, setAlertSeverity] = React.useState<SeverityOptions>("success");

    let timeout;

    const useStyles = makeStyles({
        paper: {
            width: 850
        }
    });
    const classes = useStyles();

    let loadDefaultUsers = () => {
        setLoading(true)
        usersApi.GetPagedInvitedUsers(defaultPagedFilter)
            .then((response) => {
                var invitedUsers: InvitedUser[] = [];
                invitedUsers = response.users.filter(user => user.inviteTokenUsed !== true);
                setInvitedUsers(invitedUsers);
                setPageDetails({ totalInvites: response.totalInvites, currentPage: response.currentPage, totalPages: response.totalPages })
                setTotalUsers(response.totalInvites)
                setLoading(false)
            })
    }

    useEffect(loadDefaultUsers, []);

    let cleanupAndCloseDrawer = () => {
        onCloseDrawer();
    }

    let handleInvite = async (user: InvitedUser) => {
        const alertMessage = `Invite has been sent for ${user.email}`;

        await usersApi.ResendInvitedUser(user);
        refreshInvited();

        setNewAlert(alertMessage, true, "success");
    }

    let handleDeleteInvite = (userEmail: string) => {
        const alertMessage = `Deleted invite for user ${userEmail}`;
        usersApi.DeletePendingUserInvite([userEmail])
            .then(() => {
                refreshInvited();
                setNewAlert(alertMessage, true, "success");
                setTotalUsers(totalUsers - 1)
            })
            .catch((exception) => {
                setNewAlert(exception.message, true, "error");
            })
    }

    let refreshInvited = () => {
        //Clear search bar as well
        setAllFilters(defaultPagedFilter)
        setFilter("");
        loadDefaultUsers();
    }

    let cancelAllInvites = async () => {
        const confirmationMessage: string = "Are you sure you want to cancel all open invites? (" + totalUsers + ")"
        if (await confirm.show({ text: confirmationMessage, title: "Cancel all invites" })) {
            usersApi.DeleteAllPendingUserInvite()
                .then(() => {
                    refreshInvited();
                    setNewAlert("Cancelled all invites", true, "success");
                })
                .catch((exception) => {
                    setNewAlert(exception.message + " - Unable to cancel all invites.", true, "error");
                })
        }
    }

    let resendAllInvites = async () => {
        const confirmationMessage: string = "Are you sure you want to resend all open invites? (" + totalUsers + ")"
        if (await confirm.show({ text: confirmationMessage, title: "Resend all invites" })) {
            usersApi.ResendAllInvites()
                .then(() => {
                    setNewAlert("Resent all invites", true, "success");
                })
                .catch((exception) => {
                    setNewAlert(exception.message + " - Unable to resend all invites.", true, "error");
                })
        }
    }

    let applyFilter = (e) => {
        if (e.keyCode === 13) {
            var newFilter = allFilters;
            newFilter.pageNumber = 0;
            newFilter.invitedEmail = e.target.value.toLowerCase();
            setAllFilters(newFilter);
            loadUsers();
        }
    }

    let onChangePage = (ev, page: number) => {
        var newFilters = allFilters
        newFilters.pageNumber = page
        setAllFilters(newFilters)

        loadUsers();
    }

    let loadUsers = () => {
        setLoading(true)
        usersApi.GetPagedInvitedUsers(allFilters)
            .then((response) => {
                var invitedUsers: InvitedUser[] = [];
                invitedUsers = response.users.filter(user => user.inviteTokenUsed !== true);
                setInvitedUsers(invitedUsers);
                setPageDetails({ totalInvites: response.totalInvites, currentPage: response.currentPage, totalPages: response.totalPages })
                setLoading(false)
            })
    }

    let handleTextChange = (e) => {
        clearInterval(timeout)
        timeout = setTimeout(_ => {
            updateSearchText(e)
        }
            , 350)
    }

    let updateSearchText = (newText) => {

        setFilter(newText)
        var skipApi = false;

        //Save on api calls by not calling if the information can just be filtered instead.
        if (filter !== "" && newText > filter.length && autoCompleteOptions.length < 8) {
            skipApi = true;
        }
        else {
            setAutoCompleteLoading(true);

            if (skipApi) {
                var newSuggestions = autoCompleteOptions;
                newSuggestions = newSuggestions.filter(o => o.toLowerCase().includes(newText.toLowerCase()))
                setAutoCompleteOptions(newSuggestions)
                setAutoCompleteLoading(false)

            }
            else {
                fetchSuggestions(newText);
            }
        }
    }

    let fetchSuggestions = (toSearch: string) => {
        setAutoCompleteLoading(true)
        updateFilters(toSearch);
        return autoCompleteOptions
    }

    let updateFilters = async (toSearch: string) => {
        await usersApi.FetchEmailSuggestions(toSearch, 8)
            .then((response) => {
                setAutoCompleteOptions(response);
                setAutoCompleteLoading(false);
            });
    }

    let selectOption = (e, value) => {

        var newFilter = allFilters;
        newFilter.pageNumber = 0;
        newFilter.invitedEmail = (value ?? "").toLowerCase()
        setAllFilters(newFilter);
        loadUsers();
    }

    let hideSnackbar = () => {
        setAlertOpen(false);
        setAlertMessage("");
    }

    let setNewAlert = (message: string, open: boolean, type: SeverityOptions) => {
        setAlertOpen(open)
        setAlertMessage(message)
        setAlertSeverity(type)
    }

    return <>
        <Drawer anchor="right" open={open} onClose={cleanupAndCloseDrawer} classes={{ paper: classes.paper }}>
            <div style={{ padding: 20 }}>

                <div className="add-tag-modal-header">
                    <h4>Manage User Invites</h4>
                    <CloseIcon className="close-modal-icon" onClick={cleanupAndCloseDrawer} />
                </div>

                <div style={{ display: "flex", flexDirection: "row", backgroundColor: "#F2F2F2", paddingTop: "10px", paddingBottom: "10px" }}>
                    <InfoIcon htmlColor="#E6911A" style={{ padding: "5px" }} />
                    <span style={{ padding: "7px", paddingLeft: "2px", fontSize: "14px" }} >
                        User Invites are sent to Social users that have received an invitation to join your portal and intranet. They are required to accept the invitation and log in to the portal to complete the user creation process and set up a user profile.
                    </span>
                </div>

                <div>
                    <Button startIcon={<RefreshIcon style={{ position: "relative", top: "-2px" }} />} style={{ position: "relative", float: "left", marginTop: "10px", marginBottom: "10px" }} variant="outlined" onClick={refreshInvited}>Refresh Invites</Button>
                    <Button style={{ position: "relative", float: "right", margin: "10px", marginRight: "0px" }} variant="outlined" onClick={cancelAllInvites}>Cancel All Invites</Button>
                    <Button style={{ position: "relative", float: "right", margin: "10px" }} variant="outlined" color="primary" onClick={resendAllInvites}>Resend All Invites</Button>
                </div>

                <div style={{ clear: "both" }}>
                    <Autocomplete
                        noOptionsText={"No results found"}
                        options={autoCompleteOptions}
                        loading={autoCompleteLoading}
                        onChange={selectOption}
                        clearOnBlur={false}
                        popupIcon={<></>}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                variant="outlined"
                                size="small"
                                fullWidth={true}
                                value={filter}
                                placeholder="Search invited emails"
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: <SearchIcon style={{ fill: "#666666", marginRight: "5px" }} />,
                                }}
                                onChange={(e) => handleTextChange(e.target.value)}
                                onKeyUp={applyFilter}
                                className="text-to-search"
                            />
                        }
                    />
                </div>

                {loading
                    ? <div style={{ textAlign: "center", padding: "15px", paddingTop: "100px", paddingBottom: "100px" }}>
                        <LoadingCircle size={150} />
                    </div>

            : <div>
                <table className="invited-users-table">
                    <tbody>
                        <tr>
                            <th style={{width: "310px"}}>Pending User</th>
                            <th>Invited on</th>
                            <th style={{textAlign: "center", width: "300px"}}>
                                <div style={{position: "relative", top: "3px"}}>
                                    <span style={{position: "relative", top: "-6px"}}>Actions</span>
                                    <InfoHover>Cancelling an invite will invalidate the link on the social user's email invitation.</InfoHover>
                                </div>
                            </th>
                        </tr>
                            {
                                invitedUsers.map(user => (
                                    <tr key={user.inviteToken}>
                                        <td>
                                            {user.email}
                                        </td>
                                        <td>
                                            {moment(user.invitedAt).format(dateOptions.basicWithHours)} {/* Doesn't fit nicely, may need to re-assess.*/}
                                        </td>
                                        <td style={{textAlign: "center"}}>
                                            <Button color="primary" variant="contained" onClick={() => handleInvite(user)}>RESEND INVITE</Button>
                                            <Button style={{marginLeft: 20, backgroundColor:"white"}} variant="outlined" onClick={() => handleDeleteInvite(user.email!)}>CANCEL INVITE</Button>

                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>}

                {pageDetails.totalInvites === 0 && !loading &&
                    <div style={{ paddingTop: "20px", paddingBottom: "50px", textAlign: "center" }}>
                        No results found.
                    </div>
                }

                <div>
                    {!loading &&
                        <TablePagination
                            rowsPerPageOptions={[10]}
                            component="div"
                            backIconButtonProps={{ color: "primary" }}
                            nextIconButtonProps={{ color: "primary" }}
                            count={pageDetails.totalInvites}
                            rowsPerPage={10}
                            page={pageDetails.currentPage}
                            onPageChange={onChangePage}
                        />}
                </div>
            </div>
            <SnackbarWrapper open={alertOpen} message={alertMessage} autoHideDuration={3000} severity={alertSeverity} onClose={hideSnackbar} />
        </Drawer>
    </>;
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
    }),
);

type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(InvitedUsersDrawer);
