import { Survey } from 'modules/surveys';

export interface ApplicationState {
    insights: InsightsState
}

export interface InsightsState {
    postStats: PostStats[];
    userCounts: UserCount[] | null;
    commentAndReactionCounts: CommentAndReactionCount[] | null;
    platformStats: PlatformStats[] | null;
    post: DetailedPostStats | null;
    readViewStats: ReadViewStatByHour[] | null;
    topChannels: TagStats[];
    tagStats: DetailedTagStats;
    surveys: Survey[];
    topEvents: TopEvent[];
    userTrends: UserTrends | null;
    toFetch: boolean;
}

export interface TopEvent {
    eventId: string;
    tenantId: string;
    eventName: string;
    viewCount: number;
    draftId: string;
}

export interface UserTrends {
    uniqueUserReactionCommentOnPost: number;
    uniqueUserViewPostEvent: number;
}

export interface CommentAndReactionCount {
    commentCount: number;
    reactionCount: number;
    rangeStart: Date;
    friendlyRangeDescription: string;
}

export interface UserCount {
    countOfUsers: number;
    activeLast30Days: number;
    registeredUsers: number;
    dateRounded: Date;
    monthName: string;
}

export interface PlatformStats {
    platformName: string;
    platformDescription: string;
    readCount: number;
}

export interface PostStats {
    id: string;
    title: string;
    author: string;
    readCount: number;
    postOpenCount: number;
    isMandatory: boolean;
    dateCreated: Date;
    isDeleted: boolean;
}

export interface DetailedPostStats extends PostStats {
    translatedContent: {
        [lang: string]: {
            title: string;
            body: string;
        }
    };
    imageId: string;
    tags: PostStatsTag[];
    readStats: PostRead[] | null;
    reactions: PostStatsReaction[];
}

export interface PostStatsTag {
    id: string;
    name: string;
}

export interface PostStatsReaction {
    id: string;
    name: string;
    count: number;
}

export interface FirstWeekPostStat {
    postId: string;
    countOfViews: number,
    countOfReads: number,
    publishDate: Date;
    eventDate: Date;
}

export interface PostRead {
    postId: string;
    timestamp: Date;
    userId: string;
    elapsedTime: number;
    depth: number;
}

export interface ReadViewStatByHour {
    viewCount: number;
    readCount: number;
    utcHour: number;
    rangeStart: number;
    rangeEnd: number;
}

export interface TagStats {
    id: string;
    groupName: string;
    name: string;
    isMandatory: boolean;
    subscriberCount: number;
    postCount: number;
    viewCount: number;
}

export const defaultTagStats: TagStats = {
    id: "",
    groupName: "",
    name: "",
    isMandatory: false,
    subscriberCount: 0,
    postCount: 0,
    viewCount: 0
}

export interface DetailedTagStats extends TagStats {
    posts?: TagStatsPost[];
    readStats: PostRead[] | null;
}

export interface TagStatsPost {
    id: string;
    title: string;
    author: string;
    createdTime: Date;
}

export interface AnalyticsReportFilters {
    startDate?: string;
    endDate?: string;
    rangeString?: string;
    clientTimeZone?: string;
    clientTimeOffset?: string;
    topics?: string[];
    audiences?: string[];
}

export interface DownloadAllReport extends AnalyticsReportFilters {
    channelUsage?: string;
    mostViewedPosts?: string;
    mostViewedEvents?: string;
    postViewsByHour?: string;
    engagedUsers?: string;
    commentsAndReactions?: string;
    surveysWithMostResponse?: string;
}

export interface GetTotalSubscribersModel {
    audiencesOrTopics?: string[];
}

export const PlatformAnalyticsReport = {
    channelUsage: "channelUsage",
    engagedUsers: "engagedUsers",
    mostViewedPost: "mostViewedPost",
    mostViewedEvent: "mostViewedEvent",
    commentsAndReactions: "commentsAndReactions",
    postViewsByHour: "postViewsByHour",
    surveyWithMostResponse: "surveyWithMostResponse"
}

export enum ReportTypes {
    UserReadReport,
    SMSNotificationReport
}

export const LAST_30_DAYS = "Last 30 days";
export const THIS_MONTH = "This month";
export const LAST_WEEK = "Last week";
export const LAST_MONTH = "Last month"
export const LAST_3_MONTHS = "Last 3 months";
export const LAST_6_MONTHS = "Last 6 months";
export const ALL_TIME = "All time";
export const CUSTOM_RANGE = "Custom range";


