import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import * as actions from "../../../actionCreator";
import { GlobalApplicationState } from "globalApplicationState";

import { AttachedArticle, DocumentPreview } from "../../../models";

import InfoHover from "modules/common/components/hovers/infoHover";

import Divider from "@mui/material/Divider";


const DocumentPreviewMoreInfo: React.FunctionComponent<PropsWithRedux> = props => {
  const { getAttachedArticles, preview } = props;

  const [attachedPosts, setAttachedPosts] = useState([] as AttachedArticle[]);
  const [attachedEvents, setAttachedEvents] = useState([] as AttachedArticle[]);

  useEffect(() => {
    getAttachedArticles(preview.id).then((attachedArticles) => {
      if (!!attachedArticles.totalArticles) {
        setAttachedPosts(attachedArticles.articles.filter(article => article.articleType === "post"));
        setAttachedEvents(attachedArticles.articles.filter(article => article.articleType === "event"));
      }
    });
  }, [getAttachedArticles, preview]);

  return (
    <div>
      <div className="document-title">{preview.title || `${preview.fileName}.${preview.fileType}`}</div>
      {(!!preview.description || !!preview.lcid) &&
        <React.Fragment>
          <div className="document-info-properties">
            {!!preview.description &&
              <div>
                <div>Description</div>
                <div>{preview.description}</div>
              </div>
            }
            {!!preview.lcid &&
              <div>
                <div>Language</div>
                <div>{props.lcidMappings[preview.lcid].language}</div>
              </div>
            }
          </div>
          <Divider />
        </React.Fragment>
      }
      <div className="document-info-properties">
        <div>
          <div>Original file name</div>
          <div>{preview.fileName}.{preview.fileType}</div>
        </div>
        <div>
          <div className="revision">
            <span>Version no.</span>
            <InfoHover>No. of times the file has been replaced. Version 1 is the initial file uploaded.</InfoHover>
          </div>
          <div>{preview.revisions}</div>
        </div>
      </div>
      <Divider />
      <div className="document-info-properties">
        <div>
          <div>Availability</div>
          <div>
            {preview.availability === "attachmentOnly"
              ? "Attachment only"
              : "Online only"
            }
          </div>
        </div>
      </div>
      {((!!preview.audiences && !!preview.audiences.length) || (!!preview.tags && !!preview.tags.length)) &&
        <React.Fragment>
          <Divider />
          {!!preview.tags && !!preview.tags.length &&
            <div className="document-info-properties">
              <div>
                <div>Category tags</div>
                <div className="category-tags">
                  {preview.tags.map(tag => tag.name).join(", ")}
                </div>
              </div>
            </div>
          }
          {!!preview.audiences && !!preview.audiences.length &&
            <div className="document-info-properties">
              <div>
                <div>Access audiences</div>
                <div className="access-audiences">
                  {preview.audiences.map(audience => audience.name).join(", ")}
                </div>
              </div>
            </div>
          }
        </React.Fragment>
      }
      {(!!attachedPosts.length || !!attachedEvents.length) &&
        <div className="document-info-properties">
          <div>
            <div>Uses</div>
            <div>
              {!!attachedPosts.length &&
                <div>
                  <span>{attachedPosts.length} Post(s) </span>
                  <span>
                    (
                      {attachedPosts.map((attachedPost, index) =>
                        <React.Fragment key={index}>
                          {index !== 0 &&
                            <span>, </span>
                          }
                          <span
                            onClick={() => {
                              const location: string = window.location.href;
                              const segments: string[] = location.split(props.tenant);
                              window.open(`${segments[0]}${props.tenant}/admin/${attachedPost.articleType}s/${attachedPost.id}`);
                            }}
                            className="attached-post"
                          >
                            {attachedPost.title}
                          </span>
                        </React.Fragment>
                      )}
                    )
                  </span>
                </div>
              }
              {!!attachedEvents.length &&
                <div>
                  <span>{attachedEvents.length} Event(s) </span>
                  <span>
                    (
                      {attachedEvents.map((attachedEvent, index) =>
                        <React.Fragment key={index}>
                          {index !== 0 &&
                            <span>, </span>
                          }
                          <span className="attached-event">{attachedEvent.title}</span>
                        </React.Fragment>
                      )}
                    )
                  </span>
                </div>
              }
            </div>
          </div>
        </div>
      }
    </div>
  );
}


interface ComponentProps {
  preview: DocumentPreview;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    lcidMappings: state.resources.lcidMappings,
    tenant: state.tenant.id
  }),
  {
    getAttachedArticles: actions.getAttachedArticles
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(DocumentPreviewMoreInfo);