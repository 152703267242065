import * as React from "react";


function SectionLabel({ input, meta, field }) {
  return (
    <div className="section-label">
      {field.label}
    </div>
  );
}

export default SectionLabel;