import * as React from 'react';
import { browserHistory } from 'configureStore';
import TenantLink from 'modules/common/components/tenantLink';
import { PlatformAnalyticsReport, PostStats } from 'modules/insights/models';
import { HorizontalBarChart, HBarChartProps, HBarChartDataPoint } from 'modules/insights/components/charts/horizontalBarChart';
import '../styles/top-posts.sass';
import WindowResizeListener from 'modules/common/components/window-resize-listener';
import { FormattedMessage, injectIntl, IntlShape } from "react-intl";
import {connect, ConnectedProps} from "react-redux";
import { GlobalApplicationState } from 'globalApplicationState';
import Loading from 'modules/common/components/loading';
import EmptyDataMessage from '../../emptyDataMessage';
import DownloadIcon from '../../downloadIconSvg';
import ContentDeletedDialog from 'modules/common/components/contentDeletedDialog';

class TopPosts extends React.Component<PropsWithRedux, ComponentState> {
    constructor(props: PropsWithRedux) {
        super(props);
    
        this.state = {
          warningOpen: false
        };
    }

    private _chart: HorizontalBarChart;
    private _chartElement: HTMLElement;

    public componentDidMount() {
        this._chart = new HorizontalBarChart(this.getBarChartState());
        this.props.loadData();
    }

    public componentDidUpdate() {
        if (this._chart && !this.props.loading) {
            this._chart.update(this.getBarChartState());
        }
    }

    public componentWillUnmount() {
        if (this._chart) {
            this._chart.destroy();
        }
    }

    public render() {
        return <div id={PlatformAnalyticsReport.mostViewedPost} className="analytics-widget">
            <div className='downloadIcon' id={`${PlatformAnalyticsReport.mostViewedPost}-downloadIcon`} style={{float:'right'}}>  
                <DownloadIcon
                    downloadExcel={this.props.downloadExcel}
                    downloadImage={this.props.downloadImage}
                />
            </div>
            <div className="header">
                Top 10 Posts with Most Views
            </div>
            <div className="body">
                {
                    this.props.loading ?
                    <div className="loading-styling">
                        <Loading/>
                    </div> :
                    <div>
                        {this.props.posts && this.props.posts.length > 0 ?
                        <div ref={this.callbackRef} className="bar-chart-widget top-posts"></div>

                        : <div className="no-data-display">
                            <EmptyDataMessage/>
                        </div> }
                    </div>
                }
                <WindowResizeListener onChange={() => this._chart.update(this.getBarChartState())} />
            </div>
            <ContentDeletedDialog 
                isOpen={this.state.warningOpen}
                contentType={"post"}
                returnButtonText={"Back to Platform Analytics"}
                closeDialog={this.closeDialog}
            />
            <footer id={`${PlatformAnalyticsReport.mostViewedPost}-goto`}>
                {!this.props.loading &&
                <TenantLink to={`~/admin/posts`}>
                    <FormattedMessage id="insights.viewAllPosts" defaultMessage="Go to Manage Posts" />
                </TenantLink>
                }
            </footer>
        </div>
    }

    private callbackRef = (e) => {
        this._chartElement = e;
    }

    private getTopNPosts(n: number): PostStats[] {
        return (this.props.posts || []).filter(post => {
            return post.postOpenCount > 0;
        }).slice(0, n);
    }

    private getBarChartState = (): HBarChartProps => {
        return {
            el: this._chartElement,
            width: '100%',
            height: 'auto',
            barHeight: 20,
            data: this.getTopNPosts(10).map(post => {
                return {
                    id: post.id,
                    label: post.title,
                    value: post.postOpenCount,
                    onClick: this.handlePostClick,
                    locale: this.props.locale
                };
            }),
            labelWidth: 200,
        } as HBarChartProps;
    }

    private handlePostClick = (p: HBarChartDataPoint) => {
        //Check if post is deleted.
        let postToCheck = this.props.posts.find(post => post.id === p.id);
        if(postToCheck) {
            if(postToCheck.isDeleted) {
                this.setState({ warningOpen: true });
            }
            else {
                browserHistory.push(`/${this.props.tenant}/admin/posts/${p.id}#insights`);
            }
        }
    }

    private closeDialog = () => {
        this.setState({warningOpen: false});
    }
}

interface ComponentState {
    warningOpen: boolean;
}

interface ComponentProps {
    loading?: boolean,
    tenant: string,
    posts: PostStats[],
    intl: IntlShape;
    loadData: () => void;
    downloadExcel: () => void;
    downloadImage: () => void;
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        locale: state.localization.locale
    })
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default injectIntl(connector(TopPosts));
