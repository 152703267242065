import React from "react";
import { render } from "react-dom";
import Noty from 'noty';

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";

import CloseIcon from "@mui/icons-material/Close";
import LaunchIcon from "@mui/icons-material/Launch";


const defaultTimeout = 3000;

let resolve;

enum IconTypes {
  None,
  LaunchIcon
}

interface ConfirmProps {
  text: string | JSX.Element;
  title?: string;
  yesColor?: string;
  yesText?: string;
  yesIcon?: IconTypes;
  hideNo?: boolean;
  noColor?: string;
  noText?: string;
}

interface ConfirmState {
  isOpen: boolean;
  text: string | JSX.Element;
  title?: string;
  yesColor?: string;
  yesText?: string;
  yesIcon?: IconTypes;
  hideNo?: boolean;
  noColor?: string;
  noText?: string;
}

export class ConfirmationDialog extends React.Component<{}, ConfirmState> {
  static create(): any {
    const containerElement = document.createElement("div");
    document.body.appendChild(containerElement);
    return render(<ConfirmationDialog />, containerElement);
  }

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      text: ""
    };
  }

  handleNo = () => {
    this.setState({ isOpen: false });
    resolve(false);
  }

  handleYes = () => {
    this.setState({ isOpen: false });
    resolve(true);
  }

  show = (props: ConfirmProps): Promise<boolean> => {
    this.setState({ isOpen: true, text: props.text, title: props.title, yesColor: props.yesColor || "", yesText: props.yesText, hideNo: props.hideNo, noText: props.noText, noColor: props.noColor, yesIcon: props.yesIcon});
    return new Promise((res) => {
      resolve = res;
    });
  }

  //Some components that call confirm are .ts files, and thus can't directly pass in components.
  fetchIcon = (iconToFetch: IconTypes) => {
    switch(iconToFetch) {
      case 0:
        return <></>;

      case 1:
        return <LaunchIcon/>
    }
  }

  render() {
    return (
      <Dialog
        keepMounted
        open={this.state.isOpen}
        onClose={this.handleNo}
        style={{ zIndex: 1500 }}
      >
        <DialogTitle style={{fontSize: "1rem"}}>
          {this.state.title || "Confirmation"}
          <IconButton
            style={{ position: "absolute", top: 3, right: 3 }}
            onClick={this.handleNo}
            size="large">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText component="div" style={{ color: "#333333" }}>
            {typeof(this.state.text) === "string"
              ? <div dangerouslySetInnerHTML={{ __html: this.state.text }}></div>
              : <div>{this.state.text}</div>
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {!this.state.hideNo && <Button variant="text" style={{ color: this.state.noColor }} onClick={this.handleNo}>{this.state.noText || "No"}</Button>}
          <Button variant="contained" color="primary" endIcon={this.fetchIcon(this.state.yesIcon ?? 0)} style={{ backgroundColor: this.state.yesColor }} onClick={this.handleYes}>{this.state.yesText || "Yes"}</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export function infoAlert(info: string, timeout?: number){
  alert(info, timeout || defaultTimeout, "info")
}

export function errorAlert(error: string, timeout?: number){
  alert(error, timeout || defaultTimeout, "error")
}

function alert(text: string, timeout: number, type: Noty.Type){
  new Noty({
    text,
    timeout,
    type
  }).show()
}

export function warning(text: string) {
  new Noty({
    type: 'warning',
    layout: 'center',
    text,
    closeWith: ['button']
  }).show();
}

export default ConfirmationDialog.create();