import React from "react";

import { Paper, Slide, useMediaQuery, useTheme } from "@mui/material";

import "./styles/smartContentPaper.sass";
import "/images/icons/generate_ideas.svg";

interface ISmartContentPaperProps {
    open: boolean;
    id: string;
}

const SmartContentPaper: React.FunctionComponent<ISmartContentPaperProps> = ({
    open,
    children,
    id,
}) => {
    const theme = useTheme();
    const isSmallAndSmaller = useMediaQuery(theme.breakpoints.down('sm'));

    const getContainerStyle = (): React.CSSProperties => {
        let style: React.CSSProperties = { position: "absolute", right: 56 };

        if (isSmallAndSmaller)
            style = {
                ...style,
                right: 0,
                marginTop: 0,
            };

        return style;
    }

    return (
        <div style={getContainerStyle()} id={id}>
            <Slide direction="up" in={open} unmountOnExit>
                <div>
                    <Paper className="smart-content-paper">
                        {children}
                    </Paper>
                </div>
            </Slide>
        </div>
    );
}

export { SmartContentPaper };
