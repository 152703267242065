
export type UseIsMobileDeviceReturnType = {
    isMobile: boolean;
}

/**
 * Detect mobile device by feature detection
 */
export const useIsMobileDevice = (): UseIsMobileDeviceReturnType => {
    const isMobileDevice = "ontouchstart" in document.documentElement;

    return {
        isMobile: isMobileDevice
    };
}
