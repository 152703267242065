import * as React from "react";

class ToggleSwitch extends React.Component<ComponentProps, {}> {

    public render() {
        return (
            <div className="toggleswitch" onClick={(e) => {this._onChangeHandler(e)}} onMouseOver={(e) => {this._onMouseOverHandler(e)}}>
                <input type="checkbox"
                    checked={this.props.value}
                    disabled={this.props.disabled}
                    readOnly={true}
                />
                <div className="slider"></div>
            </div>
        );
    }

    private _onChangeHandler  = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (!this.props.disabled) {
            this.props.onClick(e);
        }
        e.stopPropagation();
    }

    private _onMouseOverHandler  = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (!this.props.disabled) {
            this.props.onMouseOverHandler && this.props.onMouseOverHandler(e);
        }
        e.stopPropagation();
    }
}

interface ComponentProps {
    onClick: (e: any) => void
    onMouseOverHandler?: (e: any) => void;
    value: boolean
    disabled?: boolean
}

export default ToggleSwitch
