import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router";
import { GlobalApplicationState } from 'globalApplicationState';
import InputField from './inputField';
import * as actions from '../../actionCreator';
import InputFieldConcat from './inputFieldConcat';
import TextBubble from 'modules/common/components/textBubble';
import { audiencesApi } from 'api/instances';
import { Audience } from 'modules/audiences/models';
import AudienceSidebarSelector from 'modules/audiences/components/audienceSidebarSelector';

interface ComponentProps { 
    isSocialUser?: boolean;
}

interface TextBubble {
    id: string;
    name: string;
}
const UserGeneralDetails: React.FunctionComponent<PropsWithRedux> = ( props ) => {

    const [disablePasswordField, setDisablePasswordField] = useState(props.isSocialUser);
    const [selectedAudiences, setSelectedAudiences] = useState<string[]>([]);
    const [audiences, setAudiences] = useState<Audience[]>([]);
    const [showSidebar, setShowSidebar] = useState(false);
    // const [textBubble, setTextBubble] = useState<TextBubble[]>([]);

    const { updateAudiences } = props;

    useEffect(() => {
        audiencesApi.getAllAudiences()
        .then((results) => {
            setAudiences(results);
        })
    }, [])


    useEffect(() => {
        if (props.isSocialUser) {
            setDisablePasswordField(true);
        } else {
            setDisablePasswordField(false);
        }
    }, [props.isSocialUser]);
    
    useEffect(() => {
        const audienceBubbles:TextBubble[] = [];
        selectedAudiences.forEach(element => {
            audienceBubbles.push({id: element, name: audiences.find((a) => a.id === element)?.displayName} as TextBubble)
        });
        // setTextBubble(audienceBubbles);
        updateAudiences(selectedAudiences);
    }, [audiences, updateAudiences, selectedAudiences])

    const handleApplyAudiences = (audienceIds: string[]) => {
        setSelectedAudiences(audienceIds);
        setShowSidebar(false);
    }
    return (
        <div>
            <div className={showSidebar ? "audience-selector-visible" : "audience-selector-hidden"}>
                <AudienceSidebarSelector
                    applyAudienceChanges={handleApplyAudiences}
                    previousAudiences={[]} 
                    userAudiences={audiences}
                />
            </div>


            <div className="user-input-section-title">General Details</div>
            <div className="profile-input-rows">
                <div className="input-row">
                    <InputField
                        inputType={"text"}
                        title={"First Name*"}
                        placeholder={"Enter first name"}
                        onChange={props.updateFirstName}

                    />
                </div>                
                <div className="input-row">
                    <InputField
                        inputType={"text"}
                        title={"Last Name*"}
                        placeholder={"Enter last name"}
                        onChange={props.updateLastName}
                    />
                </div>      
                <div className="input-row">
                    <InputFieldConcat
                            inputType={"text"}
                            title={"UserID*"}
                            placeholder={"Enter a unique User ID"}
                            onChange={props.updateUserId}
                            concatValue={props.prefix}
                    />
                </div> 
                <div className="input-row">
                    <InputField
                        inputType={"text"}
                        title={"Password*"}
                        description={"Only Sparrow Standard Users can create and edit their passwords through the app."}
                        isDisabled={disablePasswordField}
                        placeholder={"Enter initial password"}
                        onChange={props.updatePassword}
                    />
                </div>
                {/* <div className="input-row">
                    <p className="profile-input-label">Assign To Audiences</p> 
                    <div className="label-description-block">
                    <button className='reset-btn-no-border' onClick={() => setShowSidebar(true) }>SELECT AUDIENCES</button>
                    <TextBubble list={textBubble} /> 
                    </div>
                </div> */}
            </div>
        </div>
    )
}


const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps & RouteComponentProps) => ({
        ...ownProps,
        creatingUser: state.users.creatingUser,
        prefix: state.settings.tenantSettings.localAccountPrefix
    }), 
    {
        updateFirstName: actions.handleUserCreationFirstName,
        updateLastName: actions.handleUserCreationLastName,
        updateUserId: actions.handleUserCreationUserId,
        updatePassword: actions.handleUserCreationPassword,
        updateAudiences: actions.handleUserCreationAudiences,
        updateUser: actions.updateUser,
    }
);

type PropsWithRedux = ConnectedProps<typeof connector>;

export default withRouter(connector(UserGeneralDetails));