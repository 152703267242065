import React from "react";

interface IConditionalWrapperProps {
    condition?: boolean;
    wrapper: (children: React.ReactNode | React.ReactElement<any, any>) => React.ReactElement;
}

/**
 * Conditionally wrap children in a component
 * - see Account.tsx for usage
 * @returns
 */
const ConditionalWrapper: React.FunctionComponent<IConditionalWrapperProps> = ({ condition, wrapper, children }) =>
    condition ? wrapper(children) : <>{children}</>;

export default ConditionalWrapper;
