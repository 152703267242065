import React from "react";
import { useCustomTinyMceCssContent } from "../hooks/data/useCustomTinyMceCssContent";

interface ICustomCssProps {
    enabled: boolean;
}

/**
 * Renders link tag for tenant custom css file
 */
export const CustomCss: React.FunctionComponent<ICustomCssProps> = ({
    enabled
}) => {
    const { customCss } = useCustomTinyMceCssContent(enabled);

    return (
        <>
            {customCss?.sasUrl &&
                <link rel="stylesheet" type="text/css" href={customCss.sasUrl} />}
        </>
    )
}
