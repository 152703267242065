import React from "react";

import { BannerBlock, banner_template } from "./banner";
import { NewsFeedBlock, news_feed_template } from "./newsFeed";
import { TextBlock, text_template } from "./text";

import Paper from "@mui/material/Paper";

const COMPONENT_TEMPLATES = {
  "banner": banner_template,
  "news-feed": news_feed_template,
  "text": text_template,
  // "video": video_template
};

const Section: React.FunctionComponent<{ components: any[], columnType: number }> = props => {
  if (!props.components)
    return <React.Fragment></React.Fragment>;

  return (
    <React.Fragment>
      {props.components.map((component: any, index: number) => {
        switch(component._template) {
          case "banner":
            return <BannerBlock key={index} data={component} />;
          case "news-feed":
            return <NewsFeedBlock key={index} data={component} columnType={props.columnType} />;
          case "text":
            return <TextBlock key={index} data={component} />;
          // case "video":
          //   return <VideoBlock key={index} data={block} />;
          default:
            return <React.Fragment></React.Fragment>;
        }
      })}
    </React.Fragment>
  );
};

export const OneColumnBlock: React.FunctionComponent<{ data: any }> = props => {
  const { data } = props;
  
  return (
    <div className="section-block">
      <Paper
      className="section-block-one-column-mui-paper"
        style={{
          backgroundColor: data.column_1_data.background_color,
          backgroundImage: !!data.column_1_data.background_image ? `url('${data.column_1_data.background_image.fileUrl}')` : ""
        }}
      >
        <Section components={data.column_1_data.components} columnType={1} />
      </Paper>
    </div>
  );
};

export const TwoColumnsBlock: React.FunctionComponent<{ data: any }> = props => {
  const { data } = props;

  let leftColumnClassName: string = "";
  let rightColumnClassName: string = "";

  if (data.ratio === "one_third") {
    leftColumnClassName = "one-third-column";
    rightColumnClassName = "two-thirds-column";
  } else if (data.ratio === "two_thirds") {
    leftColumnClassName = "two-thirds-column";
    rightColumnClassName = "one-third-column";
  }

  return (
    <div className="section-block two-columns">
      <Paper
        style={{
          backgroundColor: data.column_1_data.background_color,
          backgroundImage: !!data.column_1_data.background_image ? `url('${data.column_1_data.background_image.fileUrl}')` : ""
        }}
        className={`${leftColumnClassName} section-block-two-column-left-mui-paper`}
      >
        <Section components={data.column_1_data.components} columnType={2} />
      </Paper>
      <Paper
        style={{
          backgroundColor: data.column_2_data.background_color,
          backgroundImage: !!data.column_2_data.background_image ? `url('${data.column_2_data.background_image.fileUrl}')` : ""
        }}
        className={`${rightColumnClassName} section-block-two-column-right-mui-paper`}
      >
        <Section components={data.column_2_data.components} columnType={2} />
      </Paper>
    </div>
  );
};

export const ThreeColumnsBlock: React.FunctionComponent<{ data: any }> = props => {
  const { data } = props;

  return (
    <div className="section-block three-columns">
      <Paper
      className="section-block-three-column-left-mui-paper"
        style={{
          backgroundColor: data.column_1_data.background_color,
          backgroundImage: !!data.column_1_data.background_image ? `url('${data.column_1_data.background_image.fileUrl}')` : ""
        }}
      >
        <Section components={data.column_1_data.components} columnType={3} />
      </Paper>
      <Paper
        className="section-block-three-column-center-mui-paper"
        style={{
          backgroundColor: data.column_2_data.background_color,
          backgroundImage: !!data.column_2_data.background_image ? `url('${data.column_2_data.background_image.fileUrl}')` : ""
        }}
      >
        <Section components={data.column_2_data.components} columnType={3} />
      </Paper>
      <Paper
      className="section-block-three-column-right-mui-paper"
        style={{
          backgroundColor: data.column_3_data.background_color,
          backgroundImage: !!data.column_3_data.background_image ? `url('${data.column_3_data.background_image.fileUrl}')` : ""
        }}
      >
        <Section components={data.column_3_data.components} columnType={3} />
      </Paper>
    </div>
  );
};


const column_1_components = {
  name: "components",
  label: "Column Components",
  component: "blocks",
  templates: COMPONENT_TEMPLATES
};

const column_2_components = 
{
  name: "components",
  label: "Column Components",
  component: "blocks",
  templates: COMPONENT_TEMPLATES
};

const column_3_components = {
  name: "components",
  label: "Column Components",
  component: "blocks",
  templates: COMPONENT_TEMPLATES
};


const hr = {
  name: "hr",
  component: "hr"
};


const column_styling_label = {
  name: "column_styling_label",
  label: "Column Styling",
  component: "section-label"
};

const background_image = {
  name: "background_image",
  label: "Background image",
  description: "Select an image as the background of this column",
  component: "image"
};

const background_color = {
  name: "background_color",
  label: "Background color",
  description: "Select a color as the background of this column",
  component: "color"
};


const column_1_components_and_styling = {
  name: "column_1_data",
  label: "Column 1",
  component: "group",
  fields: [
    column_1_components,
    hr,
    column_styling_label,
    background_image,
    background_color
  ]
};

const column_2_components_and_styling = {
  name: "column_2_data",
  label: "Column 2",
  component: "group",
  fields: [
    column_2_components,
    hr,
    column_styling_label,
    background_image,
    background_color
  ]
};

const column_3_components_and_styling = {
  name: "column_3_data",
  label: "Column 3",
  component: "group",
  fields: [
    column_3_components,
    hr,
    column_styling_label,
    background_image,
    background_color
  ]
};


export const one_column_template = {
  key: "one-column-block",
  label: "1-column",
  defaultItem: {
    label: "1-column",
    column_1_data: {
      components: [],
      background_image: "",
      background_color: ""
    }
  },
  itemProps: (item: any) => ({
    label: item.label
  }),
  fields: [
    {
      name: "section_label",
      label: "1-column page section",
      component: "section-label"
    },
    {
      name: "label",
      label: "Section label",
      description: "Ex. Header Section, Section 2, Section 3",
      component: "text"
    },
    {
      name: "hr",
      component: "hr"
    },
    column_1_components_and_styling
  ]
};

export const two_columns_template = {
  key: "two-columns-block",
  label: "2-column",
  defaultItem: {
    label: "2-column",
    ratio: "half",
    column_1_data: {
      components: [],
      background_image: "",
      background_color: ""
    },
    column_2_data: {
      components: [],
      background_image: "",
      background_color: ""
    }
  },
  itemProps: (item: any) => ({
    label: item.label
  }),
  fields: [
    {
      name: "section_label",
      label: "2-column page section",
      component: "section-label"
    },
    {
      name: "label",
      label: "Section label",
      description: "Ex. Header Section, Section 2, Section 3",
      component: "text"
    },
    {
      name: "ratio",
      label: "Ratio",
      component: "select",
      options: [
        { value: "half", label: "1:1" },
        { value: "two_thirds", label: "2:1" },
        { value: "one_third", label: "1:2" }
      ]
    },
    {
      name: "hr",
      component: "hr"
    },
    {
      name: "column_label",
      label: "Column Components & Styling",
      component: "section-label"
    },
    column_1_components_and_styling,
    column_2_components_and_styling
  ]
};

export const three_columns_template = {
  key: "three-columns-block",
  label: "3-column",
  defaultItem: {
    label: "3-column",
    column_1_data: {
      components: [],
      background_image: "",
      background_color: ""
    },
    column_2_data: {
      components: [],
      background_image: "",
      background_color: ""
    },
    column_3_data: {
      components: [],
      background_image: "",
      background_color: ""
    }
  },
  itemProps: (item: any) => ({
    label: item.label
  }),
  fields: [
    {
      name: "section_label",
      label: "3-column page section",
      component: "section-label"
    },
    {
      name: "label",
      label: "Section label",
      description: "Ex. Header Section, Section 2, Section 3",
      component: "text"
    },
    {
      name: "hr",
      component: "hr"
    },
    {
      name: "column_label",
      label: "Column Components & Styling",
      component: "section-label"
    },
    column_1_components_and_styling,
    column_2_components_and_styling,
    column_3_components_and_styling
  ]
};