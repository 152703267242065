import * as actions from "network/actions";
import { CurrentUserSettings } from "modules/settings/models";

export const SaveUserSettings = (userSettings: Partial<CurrentUserSettings>) => {
  return actions.NetworkRequest({
    method: "POST",
    url: `/{tenant}/api/v1/usersettings`,
    queryParams: { platform: "Web" },
    body: JSON.stringify(userSettings),
    headers: { "content-type": "application/json" }
  });
}

export const SendSMSVerify = () => {
  return actions.NetworkRequest({
    method: "GET",
    url: `/{tenant}/api/v1/usersettings/smsVerify`,
    queryParams: { platform: "Web" },
    headers: { "content-type": "application/json" }
  });
}