import React, { useMemo } from "react";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";

import { Button, Link } from "@mui/material";
import { EditOutlined, InfoOutlined, VisibilityOutlined } from "@mui/icons-material";

import { castBandToComparableSettings } from "modules/common/hooks/data/useContentBands";
import HoverText from "modules/documents/components/action-buttons/hoverText";
import { GlobalApplicationState } from "globalApplicationState";
import { IContentBand, IContentBandSettings } from "modules/contentBands/models";
import { deepStrictEqualWrapper } from "utils/equalityUtils";

import "../../styles/contentBandsButton.sass";

interface IContentBandsInput {
    contentBands: IContentBand[];
    publishedBands: IContentBand[];
    onEdit: () => void;
    onPreview: () => void;
}

export const ContentBandsButtons: React.FunctionComponent<IContentBandsInput> = ({ contentBands, publishedBands, onEdit, onPreview }) => {
    const { portalPagesEnabled } = useSelector((state: GlobalApplicationState) => state.settings.tenantSettings.showFeatures);
    const { id: tenantId } = useSelector((state:GlobalApplicationState) => state.tenant);
    const publishCount = publishedBands.length;
    const history = useHistory();

    const isUnpublishedChanges = useMemo<boolean>(() => {
        const comparableContentBands = contentBands.map(castBandToComparableSettings);
        const comparablePublishedBands = publishedBands.map(castBandToComparableSettings);

        return comparableContentBands.some(
            (cb: Partial<IContentBandSettings>, idx: number) => !deepStrictEqualWrapper(cb, comparablePublishedBands[idx])
        );
    }, [contentBands, publishedBands]);

    return (
        <div id="content-bands-button-container">
            <p className="info-label">Content Bands</p>
            <p id="cb-support-text">
                Use{" "}
                <Link id="content-bands-kb" target="_blank" href="https://support.sparrowconnected.com/en/content-bands">
                    Content Bands
                </Link>
                {" "}to customize and filter posts and events that appear on the home screens of your Mobile or MS Teams apps.
                {portalPagesEnabled && (
                    <span>
                        {" "}Go to {" "}
                        <Link id="manage-portal-pages" onClick={() => history.push(`/${tenantId}/admin/portalPages`)}>
                            portal pages
                        </Link>
                        {" "}to edit your Company Portal’s homepage.
                    </span>
                )}
            </p>
            <div id="content-bands-button-content">
                <div style={{ flex: 1, display: "flex" }}>
                    {publishCount === 0 ? (
                        <span className="disabled-text" style={{ marginRight: 7 }}>
                            No bands published
                        </span>
                    ) : (
                        <span style={{ marginRight: 7 }}>
                            {publishCount} band{publishCount === 1 ? "" : "s"} published
                        </span>
                    )}
                    {isUnpublishedChanges && (
                        <HoverText label={<InfoOutlined style={{ color: "#e6911a" }} />} underline={false} inline>
                            There are unpublished changes to your content bands.
                        </HoverText>
                    )}
                </div>
                <Button
                    id="content-bands-preview"
                    color="primary"
                    variant="text"
                    startIcon={<VisibilityOutlined />}
                    disabled={publishCount < 1}
                    classes={{
                        startIcon: "start-icon",
                    }}
                    onClick={onPreview}
                >
                    PREVIEW
                </Button>
                <Button
                    id="content-bands-edit"
                    color="primary"
                    variant="text"
                    startIcon={<EditOutlined />}
                    classes={{
                        startIcon: "start-icon",
                    }}
                    onClick={onEdit}
                >
                    EDIT
                </Button>
            </div>
        </div>
    );
};
