import { IIndicatorDetail } from "modules/common/components/authoring/models";
import React from "react";

import Indicator from "./indicator";

interface IIndicatorWrapperProps {
    indicator?: IIndicatorDetail;
    reverse?: boolean;
    useGlobalIcon: boolean;
    title: string;
}

const IndicatorWrapper: React.FunctionComponent<IIndicatorWrapperProps> = ({
    indicator = {} as IIndicatorDetail,
    reverse = false,
    title,
    useGlobalIcon = false,
}) => {
    const {
        minScore,
        maxScore,
        minLabel,
        maxLabel,
        score,
        recommendedScore,
        scoreLabel
    } = indicator;

    // NOTE: for some reason readable actually sometimes gives us greater than 100, so we just set it to 100
    const scoreToUse = score > maxScore
        ? maxScore
        : score;

    return (
        <div style={{ width: "100%"}}>
            {indicator
                ? <Indicator
                    title={title}
                    range={{ min: minScore, max: maxScore }}
                    score={scoreToUse}
                    startLabel={minLabel}
                    endLabel={maxLabel}
                    scoreLabel={scoreLabel}
                    recommendedScore={recommendedScore}
                    reverse={reverse}
                    useGlobalIcon={useGlobalIcon}
                />
                : <></>}

        </div>
    )
}

export default IndicatorWrapper;
