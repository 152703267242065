import { useState } from "react";

export type UseAnchorEl<T> = {
    setAnchorEl: (el: T | null) => void;
    open: boolean;
    anchorEl: T | null;
}

export function useAnchorEl<T>(): UseAnchorEl<T> {
    const [anchorEl, setAnchorEl] = useState<T | null>(null);

    const onSetAnchorEl = (el: T | null) => {
        setAnchorEl(el);
    }

    return {
        anchorEl,
        setAnchorEl: onSetAnchorEl,
        open: Boolean(anchorEl)
    };
}
