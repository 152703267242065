import { CroppableImage } from "modules/common/components/authoring/models";
import { AttachedFile, Image } from "modules/gallery";
import { SortStyle } from "utils/managementUtils";

export type AttendanceType = "InPerson" | "NotAttending" | "Online" | "Undecided" | "Waitlist";
export type EventType = "informational" | "mandatory" | "standard";


export interface EventsState {
    allEventsList: EventListingPage;
    drafts: EventListingPage;
    published: EventListingPage;
    scheduled: EventListingPage;
    documentGallery: DocumentGalleryPage;
    imageGallery: ImageGalleryPage;
    eventsFeed: EventsFeedState;
    pendingDeleting: EventListItem[];
    pendingPublishing: EventListItem[];
    changedSinceSaved: boolean;
    errorMessage: string;
    isDeleting: boolean;
    isFetching: boolean;
    isInitialLoad: boolean;
    isSaving: boolean;
    isUpdatingUrl: boolean;
    isUploading: boolean;
    shouldFetch: boolean;
    showPublishSuccess: boolean;
    successMessage: SuccessMessage;
}


export interface EventsFeedState {
    eventsFeed: EventFeedItem[];
    canLoadMore: boolean;
    fetching: boolean;
    filters: Partial<EventFeedFilters>;
    filtersApplied: boolean;
    selectedView: string;
    shouldFetch: boolean;
    showFilters: boolean;
}


export interface Event {
    id: string;
    attachedContent: AttachedFile[];
    author: string;
    authorEmail?: string;
    bannerColor?: string;
    capacity?: Capacity;
    commentingEnabled: boolean;
    createdTime?: Date;
    cutoffTime?: string;
    eventTimes?: EventTimes;
    eventType?: EventType;
    expiryTime?: string;
    fileAttachments: AttachedFile[];
    image?: CroppableImage;
    isPreviouslyPublished?: boolean;
    location?: string;
    locationLink?: string;
    locationLinkLabel?: string;
    notifications: Notifications;
    publishTime?: string;
    questions: Questions;
    respondingEnabled: boolean;
    tags: Tag[];
    translatedContent: TranslatedContent;
}

export interface EventFeedItem {
    attachedContent: AttachedFile[];
    attendanceCounts?: any;
    author: {
        avatarColor: string;
        email: string;
        name: string;
    };
    bannerColor: string;
    body: string;
    commentEnabled: boolean;
    createdTime: string;
    currentUserResponse?: any;
    cutoffTime: string;
    defaultLCID: string;
    description: string;
    eventEndTime: string;
    eventStartTime: string;
    eventTimes?: any;
    eventType: EventType;
    excerpt: string;
    fileAttachments: AttachedFile[];
    id: string;
    imageId: string;
    imageUrl: string;
    isAllDayEvent: boolean;
    isBreaking: boolean;
    isCapacityAvailable: boolean;
    isCurrentUserAttending: boolean;
    isCurrentUserAttendingInPerson: boolean;
    isCurrentUserAttendingOnline: boolean;
    isCurrentUserNotAttending: boolean;
    isCurrentUserOnWaitlist: boolean;
    isExpired: boolean;
    isFeatured: boolean;
    isInPersonAvailable: boolean;
    isOnlineAvailable: boolean;
    isResponseEditable: boolean;
    isRestricted: boolean;
    isWaitlistAvailable: boolean;
    location: string;
    locationLink: string;
    locationLinkLabel?: string;
    preferredLCID: string;
    publishTime: string;
    questions?: any;
    reactingEnabled: boolean;
    respondingEnabled: boolean;
    showLimitedDisplayNotice: boolean;
    sourceId: string;
    tags: { id: string, mandatory: boolean, name: string }[];
    title: string;
    translatedContent?: TranslatedContent;
}

export interface EventListItem {
    author: string;
    imageUrl?: string;
    authorFullName: string;
    commentingEnabled: boolean;
    draftId: string;
    eventEndTime: string;
    eventStartTime: string;
  isAllDayEvent: boolean;
    eventType: EventType;
    expiryTime?: Epoch;
    id: string;
    isPreviouslyPublished: boolean;
    lastModifiedBy: string;
    publishedTime: Epoch;
    rsvpCount: number;
    source: string;
    status: string;
    title: string;
    tags: Tag[];
    updatedTime: Epoch;
    translatedContent?: TranslatedContent;
}

// return model from adminapi/v1/events/{id}
export interface IEventOverview {
    id: string;
    draftId: string;
    editable: boolean;
    deletable: boolean;
    eventType: string;
    datePublished: Date;
    expiryDate: Date;
    breakingDate: Date;
    featuredDate: Date;
    imageId: string;
    imageUrl: string;
    bannerColor: string;
    tags: Tag[];
    author: string;
    authorEmail: string;
    translatedContent: TranslatedContent;
    attachedContent: AttachedFile[];
    fileAttachments: AttachedFile[];
    location: string;
    locationLink: string;
    eventTimes: EventTimes;
    eventStartTime: Date;
    eventEndTime: Date;
    cutoffTime: Date;
    capacity: Record<AttendanceType, number>;
    questions: Questions;
}

export interface EventView {
    attachedContent: AttachedFile[];
    attendanceCounts?: AttendanceCounts;
    author: User;
    bannerColor: string;
    commentEnabled: boolean;
    currentUserResponse?: CurrentUserResponse;
    cutoffTime: string;
    defaultLCID: string;
    eventEndTime: string;
    eventStartTime: string;
    eventTimes: EventTimes;
    eventType: EventType;
    fileAttachments: AttachedFile[];
    id: string;
    imageId: string;
    imageUrl: string;
    isAllDayEvent: boolean;
    isBreaking: boolean;
    isCapacityAvailable: boolean;
    isCurrentUserAttending: boolean;
    isCurrentUserAttendingInPerson: boolean;
    isCurrentUserAttendingOnline: boolean;
    isCurrentUserNotAttending: boolean;
    isCurrentUserOnWaitlist: boolean;
    isExpired: boolean;
    isFeatured: boolean;
    isInPersonAvailable: boolean;
    isOnlineAvailable: boolean;
    isResponseEditable: boolean;
    isRestricted: boolean;
    isWaitlistAvailable: boolean;
    location: string;
    locationLink: string;
    locationLinkLabel: string;
    preferredLCID: string;
    questions: Questions;
    respondingEnabled: boolean;
    sourceId: string;
    tags: Tag[];
    translatedContent: TranslatedContent;
}

export interface Answers {
    [answerNumber: string]: string;
}

export interface AttendanceCount {
    capacity: number;
    current: number;
}

export interface AttendanceCounts {
    InPerson: AttendanceCount;
    NotAttending: AttendanceCount;
    Online: AttendanceCount;
    Waitlist: AttendanceCount;
}

export interface AttendanceFilterValues {
    email: string;
    name: string;
}

export interface AttendanceListingPage {
    id: "" | "all" | "attending" | "notAttending" | "undecided" | "waitlist";
    attendees: Attendee[];
    currentPage: number;
    eventEndTime: string;
    eventStartTime: string;
    eventType: EventType;
    questions: Questions;
    title: string;
    totalAttendees: number;
    totalPages: number;
    isFetching: boolean;
    hasError: boolean;
}

export interface Attendee {
    answers: Answers;
    attendanceType: AttendanceType;
    avatarColor: string;
    email: string;
    id: string;
    name: string;
    rsvpTime: string;
}

export interface Capacity {
    InPerson: number;
    NotAttending: number;
    Online: number;
    Waitlist: number;
}

export interface CurrentUserResponse {
    answers: Answers;
    attendanceType: AttendanceType;
    attendee: User;
}

export interface DocumentGalleryPage {
    currentPage: number;
    documents: AttachedFile[];
    totalDocuments: number;
    totalPages: number;
    isFetching: boolean;
}

export interface Epoch {
    date: Date;
    epoch: Number;
}

export interface EventFeedFilters {
    eventTypes: string[];
    eventStates: string[];
    ignoreSubscriptions: boolean;
    lcidToSearch: string;
    myEventResponses: string[];
    newerThan: string;
    olderThan: string;
    textToSearch: string;
    tags: string[];
}

export interface EventFilterValues {
    eventTypes: string[];
    eventStates: string[];
    ignoreSubscriptions: boolean;
    lcidToSearch: string;
    myEventResponses: string[];
    newerThan: string;
    olderThan: string;
    textToSearch: string;
    tags: string[];
    sortType: SortStyle;
    includeExpired: boolean;
}

export interface EventListingPage {
    id: "" | "drafts" | "published" | "scheduled" | "all";
    currentPage: number;
    events: EventListItem[];
    totalEvents: number;
    totalPages: number;
    isFetching: boolean;
}

export interface EventTimes {
    [startTime: string]: string | null;
}

export interface ImageGalleryPage {
    currentPage: number;
    images: Image[];
    totalImages: number;
    totalPages: number;
    isFetching: boolean;
}

export type NotificationRange = "fallback" | "subscribers" | "none";

export interface Notifications {
    emailOnPublish: NotificationRange;
    mobileOnPublish: NotificationRange;
    smsOnPublish: NotificationRange;
    teamsOnPublish: NotificationRange;
    reminders: Reminder[];
}

export interface Question {
    body: string;
    isRequired: boolean;
}

export interface Questions {
    [questionNumber: number]: Question;
}

export interface Reminder {
    channel: ReminderChannel;
    delay: number;
    range: NotificationRange;
}

export type ReminderChannel = "inherit" | "email" | "mobile" | "sms" | "teams" | "none";

export type RSVPType = "initial" | "changeStatus" | "editAnswers";

export interface SuccessMessage {
    icon: "" | "cancel";
    message: string;
    severity: "warning" | "info" | "success";
}

export interface Tag {
    id: string;
    name: string;
    mandatory?: boolean;
    restricted?: boolean;
}

export interface TranslatedContent {
    [languageCode: string]: TranslatableEventContent;
}

export interface TranslatableEventContent {
    title: string;
    body: string;
    description: string;
}

export interface User {
    avatarColor?: string;
    id?: string;
    name: string;
    email: string;
}

export interface ValidationChecks {
    checks: ValidationCheck[];
}

export interface ValidationCheck {
    key: string;
    description: string;
    isValid: boolean;
    link?: string;
    skip?: boolean;
}

export enum EVENT_STATE {
    PUBLISHED = "published",
    DRAFT = "draft",
    SCHEDULED = "scheduled"
}
