import * as React from "react";
import { FormattedMessage } from "react-intl";

class InsufficientPermissions extends React.Component<{}, {}> {

    public render() {
        return (
            <div className="outer-container">
                <h1>
                    <FormattedMessage id="common.accessNotAllowed"
                    defaultMessage="You are not allowed to access this content." />
                </h1>
                <p>
                    <FormattedMessage id="contactAdminForPermission"
                    defaultMessage="Contact your administrator to request permission."/>
                </p>
            </div>
        );
    }
}

export default InsufficientPermissions;
