import * as Actions from './actions';
import { PostsState } from './models';
import { Reducer } from './index';
import { asChonkyFileMap } from 'modules/gallery/chonkyFileAdapter';
import { CustomFileData } from 'modules/gallery';

export const actionHandler: Reducer<PostsState> = {

    [Actions.FETCH_IMAGES_FROM_LIBRARY]: (state: PostsState, action: Actions.FetchImagesFromLibrary) => {
        let images = state.imageLibrary.images || [];
        let chonkyFileData = state.imageLibrary.chonkyFileData || [];

        return { ...state, imageLibrary: { 
            ...state.imageLibrary, 
            fetching: true, 
            images: images,
            imagesGalleryMap: asChonkyFileMap(chonkyFileData),
            chonkyFileData: chonkyFileData,
        }}
    },

    [Actions.FETCH_IMAGES_FROM_LIBRARY_COMPLETE]: (state: PostsState, action: Actions.FetchImagesFromLibraryComplete) => {
        let fileData = action.chonkyFileData ;
        
        const filesToMoveIds = action.moveFilesForAppBar?.filesToMove.map(i => i.id) ?? [];
        if (action.moveFilesForAppBar && fileData.filter(x => filesToMoveIds.includes(x.id)).length > 0)
            fileData = fileData.map(x => x.isDir && filesToMoveIds.includes(x.id) ? {...x, selectable: false, openable: false} : x);
        
        return {
            ...state,
            imageLibrary: {
                ...state.imageLibrary,
                fetching: false,
                shouldFetch: false,
                lastFetched: new Date().getTime(),
                imagesGalleryMap: asChonkyFileMap(fileData, action.currDir),
                chonkyFileData: fileData,
                totalCount: action.totalCount,
                totalPages: action.totalPages,
                currDir: action.currDir,
                folderChain: action.folderChain,
            }
        }
    },

    [Actions.FETCH_IMAGE_FROM_LIBRARY_COMPLETE]: (state: PostsState, action: Actions.FetchImageFromLibraryComplete) => {
        const imageIndex = state.imageLibrary.images.findIndex(i => i.id === action.image.id);
        const existsInLibrary = imageIndex >= 0;
        let images = [...state.imageLibrary.images];
        if(existsInLibrary){
            images[imageIndex] = action.image
        }else{
            images.unshift(action.image);
        }
        return {
            ...state,
            imageLibrary: {
                ...state.imageLibrary,
                images
            }
        }
    },


    [Actions.DELETE_IMAGES_FROM_LIBRARY_INIT]: (state: PostsState, action: Actions.DeleteImagesFromLibraryInit) => {
        return { ...state, imageLibrary: { ...state.imageLibrary, deleting: true, images: state.imageLibrary.images || [] } }
    },

    [Actions.DELETE_IMAGES_FROM_LIBRARY_COMPLETE]: (state: PostsState, action: Actions.DeleteImagesFromLibraryComplete) => {
        return {
            ...state,
            imageLibrary: {
                ...state.imageLibrary,
                deleting: false,
                images: (state.imageLibrary.images || []).filter(i => !action.succeeded || !action.ids || action.ids.indexOf(i.id) < 0)
            }
        }
    },

    [Actions.UPLOAD_IMAGE]: (state: PostsState, action: Actions.UploadImage) => {
        return { ...state, imageLibrary: { ...state.imageLibrary, uploading: true } }
    },

    [Actions.UPLOAD_IMAGE_COMPLETE]: (state: PostsState, action: Actions.UploadImageComplete) => {
        return { ...state, imageLibrary: { ...state.imageLibrary }, shouldFetch: false }
    },

    [Actions.SET_UPLOADING]: (state: PostsState, action: Actions.SetUploading) => {
        return {
            ...state,
            imageLibrary: {
                ...state.imageLibrary,
                uploading: action.uploading
            }
        }
    }
}
