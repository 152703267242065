/**
 * Event handling utility for listening to an event once. Avoid attaching to the window
 * as then the only way to properly remove the listener is by dispatching an event.
 * 
 * @param events event name(s) to listen for separated by spaces. ie. "keydown keyup"
 * @param handler event handler that should be called only on the first event
 */
export default function once(element: any, events: string, handler: (e: Event) => void) {
    events.split(' ').forEach(value => {
        let wrappedHandler = function(e) {
            element.removeEventListener(e.type, wrappedHandler);
            handler(e);
        }
        element.addEventListener(value, wrappedHandler);
    });
}
