import * as React from "react";
import moment from "moment";

import { EventTimes, TranslatedContent } from "../../../models";

import IconButton from "@mui/material/IconButton";

import EventIcon from "@mui/icons-material/Event";
import { FileDownloader } from "utils/fileDownloader";


class AddToCalendar extends React.Component<ComponentProps, ComponentState> {
  public render() {
    const {
        startTime
    } = this.props;
    const hasStartTime: boolean = !!startTime;

    return (
      <IconButton color={hasStartTime ? "primary" : "default"} title="Add to calendar" size={hasStartTime ? "small" : undefined} onClick={this.onAddToCalendar} className={hasStartTime ? "add-time-to-calendar" : ""}>
        <EventIcon fontSize={hasStartTime ? "small" : "medium"} />
      </IconButton>
    );
  }

  private download = (content: string) => {
    const file = {
      name: `event-${new Date().toISOString()}.ics`,
      type: "text/calendar;charset=utf-8;"
    };
    new FileDownloader(file).downloadBlob(content);
  }

  private onAddToCalendar = () => {
    const { activeLcid, location, locationLink, locationLinkLabel, translatedContent } = this.props;
    
    const dateFormat = "YYYYMMDDTHHmmss";
    const allDayDateFormat = "YYYYMMDD";
  
    const body = translatedContent[activeLcid] ? `<div>${translatedContent[activeLcid].body.replace(/\n/g, "")}</div>` : "";
    const title = translatedContent[activeLcid] ? translatedContent[activeLcid].title : "";

    const physicalLocation = location ?? "";
    const digitalLocation = this.fetchDigitalLocation(locationLink, locationLinkLabel);

    const locationLabel = `${physicalLocation} ${(physicalLocation.trim() !== "" && digitalLocation.trim() !== "") ? " / " : ""} ${digitalLocation}`
    const bodyAddition = locationLink && locationLink.trim() !== "" ? `<a href="${locationLink}">${digitalLocation}</a>` : "";

    const startTimes = !!this.props.startTime ? [this.props.startTime] : Object.keys(this.props.eventTimes);
    let calendarEventTimes = "";
    for (let startTime of startTimes) {
      const endTime = this.props.eventTimes[startTime];
      const isAllDay = !endTime;
      calendarEventTimes = [
        calendarEventTimes,
        'BEGIN:VEVENT',
        `URL:${document.URL}`,
        `DTSTART:${isAllDay ? moment(startTime).utc().format(allDayDateFormat) : moment(startTime).format(dateFormat)}`,
        `DTEND:${isAllDay ? moment(startTime).utc().add({day: 1}).format(allDayDateFormat) : moment(endTime!).format(dateFormat)}`,
        `SUMMARY:${title}`,
        `X-ALT-DESC;FMTTYPE=text/html:${body}${bodyAddition}`,
        `LOCATION:${locationLabel}`,
        `BEGIN:VALARM`,
        `TRIGGER:-PT15M`,
        `ACTION:DISPLAY`,
        `DESCRIPTION:Upcoming event: ${title}`,
        `END:VALARM`,
        'END:VEVENT'
      ].join("\n");
    }
    const calendar = [
      'BEGIN:VCALENDAR',
      'VERSION:2.0',
      calendarEventTimes,
      'END:VCALENDAR'
    ].join("\n");

    this.download(calendar);
  }

  private fetchDigitalLocation = (locationLink?: string, locationLinkLabel?: string) => {
    if (locationLinkLabel && locationLinkLabel.trim() !== "") {
      return locationLinkLabel;
    }

    else if (locationLink && locationLink.trim() !== "") {
        return locationLink;
    }

    return "";
  }
}


interface ComponentProps {
  activeLcid: string;
  eventTimes: EventTimes;
  location?: string;
  locationLink?: string;
  locationLinkLabel?: string;
  respondingEnabled?: boolean;
  translatedContent: TranslatedContent;

  startTime?: string;
}

interface ComponentState {}

export default AddToCalendar;
