import { 
    ChonkyActionUnion, 
    ChonkyActions, 
    ChonkyIconName, 
    CustomVisibilityState, 
    FileAction, 
    FileArray, 
    FileHelper, 
    FileSelectionTransform, 
    OptionIds, 
    defineFileAction, 
    reduxActions, 
    selectOptionValue, 
    selectRawFiles, 
    selectSelectedFileIds, 
    thunkRequestFileAction 
} from 'chonky';
import { CustomFileData, FilterGroupName, SortGroupName } from './models';
import { ChonkyDispatch, RootState } from 'chonky/dist/types/redux.types';

// chonky file action for an ascending sort button
export const ascSortAction = (groupName: SortGroupName): FileAction => defineFileAction(
    {
        id: 'asc_sort_action',
        button: {
            name: 'A-Z',
            toolbar: true,
            group: groupName
        },
        customVisibility: () => groupName === SortGroupName.Az ? CustomVisibilityState.Active : CustomVisibilityState.Default
    } as const
);

// chonky file action for an descending sort button
export const descSortAction = (groupName: SortGroupName): FileAction => defineFileAction(
    {
        id: 'desc_sort_action',
        button: {
            name: 'Z-A',
            toolbar: true,
            group: groupName
        },
        customVisibility: () => groupName === SortGroupName.Za ? CustomVisibilityState.Active : CustomVisibilityState.Default
    } as const
);

// chonky file action for sorting by the newest file
export const newestSortAction = (groupName: SortGroupName): FileAction => defineFileAction(
    {
        id: 'newest_sort_action',
        button: {
            name: 'Newest first',
            toolbar: true,
            group: groupName
        },
        customVisibility: () => groupName === SortGroupName.Newest ? CustomVisibilityState.Active : CustomVisibilityState.Default
        
    } as const
);

// chonky file action for sorting by the oldest file
export const oldestSortAction = (groupName: SortGroupName): FileAction => defineFileAction(
    {
        id: 'oldest_sort_action',
        button: {
            name: 'Oldest first',
            toolbar: true,
            group: groupName
        },
        customVisibility: () => groupName === SortGroupName.Oldest ? CustomVisibilityState.Active : CustomVisibilityState.Default
    } as const
);

// chonky file action for displaying folders first
export const toggleShowFoldersFirst = (groupName: SortGroupName, defaultValue: boolean = true): FileAction => defineFileAction({
    id: 'toggle_show_folders_first',
    option: {
        id: OptionIds.ShowFoldersFirst,
        defaultValue: defaultValue,
    },
    button: {
        name: 'Show folders first',
        toolbar: true,
        group: groupName
    },
    } as const
);

// comparison function for either a Date or string type, used for frontend sorting of
// chonky files based on the last modified date (modDate) or by file name
export const cmp = (a: Date | string, b: Date | string): number => {
    if (a > b) return +1;
    if (a < b) return -1;
    return 0;
}

// same as above but in descending order
const cmpDesc = (a: Date | string, b: Date | string): number => {
    if (a > b) return -1;
    if (a < b) return +1;
    return 0;
}

// function for getting the data of the Chonky file to compare
const getFieldToCompare = (field: keyof CustomFileData, data: CustomFileData): string | Date => {
    return field === "modDate" && data.modDate
        ? new Date(data.modDate)
        : typeof data[field] === 'string' 
            ? (data[field] as string).toLowerCase() 
            : data[field];
}

// a custom sort function for sorting by folders first, and then applying either sorting by modDate or filename afterwards
// on the already ordered array
export const sortByFoldersThenByField = (a: CustomFileData, b: CustomFileData, sortName: SortGroupName, showFoldersFirst: boolean): number => {
    const field = ((sortName === SortGroupName.Az || sortName === SortGroupName.Za) ? "name" : "modDate") as keyof CustomFileData;
    const asc = sortName === SortGroupName.Az || sortName === SortGroupName.Oldest;

    const aToCompare = getFieldToCompare(field, a);
    const bToCompare = getFieldToCompare(field, b);

    const fieldCompare = asc 
        ? cmp(aToCompare, bToCompare) 
        : cmpDesc(aToCompare, bToCompare);

    if (showFoldersFirst) {
        return (a.isDir === b.isDir ? fieldCompare : a.isDir ? -1 : 1);
    }

    return fieldCompare;
}

// a helper function for our custom file actions to apply the frontend sorting and filtering 
export const sortAndFilterAction = (reduxDispatch: ChonkyDispatch, getReduxState: () => RootState, groupName: SortGroupName) => {
    const showFoldersFirst = selectOptionValue(OptionIds.ShowFoldersFirst)(getReduxState());
    const rawFileArray = [...selectRawFiles(getReduxState()) as FileArray<CustomFileData>]
        .sort((a,b) => a && b ? sortByFoldersThenByField(a, b, groupName, showFoldersFirst) : 0);

    reduxDispatch(reduxActions.setRawFiles(rawFileArray));
}

// function for returning a list of our custom file actions
export const getSortFileActions = (groupName: SortGroupName, defaultShowFoldersFirst: boolean = true): FileAction[] => [
    ascSortAction(groupName),
    descSortAction(groupName),
    newestSortAction(groupName),
    oldestSortAction(groupName),
    toggleShowFoldersFirst(groupName, defaultShowFoldersFirst),
];

// chonky file action for filtering that shows all files
export const viewAllImagesAction = (groupName: FilterGroupName): FileAction => defineFileAction(
    {
        id: 'view_all_action',
        button: {
            name: 'View all images',
            toolbar: true,
            group: groupName
        },
        customVisibility: () => groupName === FilterGroupName.AllImages ? CustomVisibilityState.Active : CustomVisibilityState.Default
    } as const
);

// chonky file action for filtering that shows only the user's files
export const viewMyImagesAction = (groupName: FilterGroupName, currentUserId: string): FileAction => defineFileAction(
    {
        id: 'view_my_action',
        button: {
            name: 'View my images',
            toolbar: true,
            group: groupName
        },
        customVisibility: () => groupName === FilterGroupName.MyImages ? CustomVisibilityState.Active : CustomVisibilityState.Default
    } as const
);

// function for getting all our chonky filter file actions
export const getFilterFileActions = (groupName: FilterGroupName, currentUserId: string): FileAction[] => [
    viewAllImagesAction(groupName),
    viewMyImagesAction(groupName, currentUserId),
];

// chonky's open file action in the dropdown menu
// we specify it ourselves here so we can toggle between having the button be active/inactive
// depending on if there is just a single file selected
export const openFileAction = defineFileAction(
    {
        id: 'open_selection',
        hotkeys: ['enter'],
        requiresSelection: true,
        fileFilter: FileHelper.isOpenable,
        button: {
            name: 'Open',
            toolbar: true,
            contextMenu: true,
            group: 'Actions',
        },
    } as const,
    ({ state, reduxDispatch }) => {
        reduxDispatch(
            thunkRequestFileAction(ChonkyActions.OpenFiles, {
                files: state.selectedFilesForAction!,
            })
        );
        return undefined;
    }
);

// a disabled version of the openFileAction to display when we have no files or more than 1 selected for actions
export const disabledOpenFileAction = defineFileAction({
    id: 'open_selection_disabled',
    requiresSelection: true,
    fileFilter: _ => false,
    button: {
        name: 'Open',
        toolbar: true,
        contextMenu: true,
        group: 'Actions',
    }
} as const);

// custom file action for renaming a file
export const renameFileAction = defineFileAction(
    {
        id: 'rename_selection',
        requiresSelection: true,
        button: {
            name: 'Rename',
            toolbar: true,
            contextMenu: true,
            group: 'Actions',
        },
    } as const
);

// the disabled version of the renameFileAction
export const disabledRenameFileAction = defineFileAction({
    id: 'rename_selection_disabled',
    requiresSelection: true,
    fileFilter: _ => false,
    button: {
        name: 'Rename',
        toolbar: true,
        contextMenu: true,
        group: 'Actions',
    }
} as const);

// chonky file action for moving files
export const moveFileAction = defineFileAction(
    {
        id: 'move_selection',
        requiresSelection: true,
        button: {
            name: 'Move',
            toolbar: true,
            contextMenu: true,
            group: 'Actions',
        },
    } as const,
    ({ reduxDispatch, getReduxState }) => {
        const rawFileArray = [...selectRawFiles(getReduxState()) as FileArray<CustomFileData>];
        const selectionMap = selectSelectedFileIds(getReduxState());
        const newRawFileArray = rawFileArray.map(f => f && selectionMap.includes(f.id) && f.isDir ? {...f, openable: false, selectable: false} : {...f});
        reduxDispatch(reduxActions.setRawFiles(newRawFileArray));
    },
);

// chonky action for selecting all files
export const selectAllFileAction = defineFileAction({
    id: 'select_all',
    hotkeys: ['ctrl+a'],
    button: {
        name: 'Select all',
        toolbar: true,
        contextMenu: true,
        group: 'Actions',
    },
    selectionTransform: (({ fileIds, hiddenFileIds }) => {
        const newSelection = new Set<string>();
        fileIds.forEach(fileId => {
            if (!hiddenFileIds.has(fileId)) newSelection.add(fileId);
        });
        return newSelection;
    }) as FileSelectionTransform,
} as const);

// chonky file action for clearing file selection
export const clearSelectionFileAction = defineFileAction({
    id: 'clear_selection',
    requiresSelection: true,
    hotkeys: ['escape'],
    button: {
        name: 'Clear selection',
        toolbar: true,
        contextMenu: true,
        group: 'Actions',
    },
    selectionTransform: (({ prevSelection }) => {
        if (prevSelection.size === 0) return null;
        return new Set<string>();
    }) as FileSelectionTransform,
} as const);

// chonky file action for deleting files
export const deleteSelectionAction = defineFileAction(
    {
        id: 'delete_selection',
        requiresSelection: true,
        hotkeys: ['delete'],
        button: {
            name: 'Delete',
            toolbar: true,
            contextMenu: true,
            group: 'Actions',
        }
    } as const
);

// a "hidden" chonky file action for our move files app bar
export const resetMoveFileStateAction = defineFileAction(
    {
        id: 'reset_move_file_state'
    } as const,
    ({ reduxDispatch, getReduxState }) => {
        const rawFileArray = [...selectRawFiles(getReduxState()) as FileArray<CustomFileData>];
        reduxDispatch(reduxActions.setRawFiles(rawFileArray.map(f => f && !f.openable && !f.selectable && f.isDir ? {...f, selctable: true, openable: true} : {...f})));
    },
);

export const disabledCreateFolder = defineFileAction(
    {
        id: "dsiabled_create_folder",
        button: {
            name: "Create folder",
            toolbar: true,
            tooltip: "Create a folder",
            icon: ChonkyIconName.folderCreate
        },
        customVisibility: () => CustomVisibilityState.Disabled
    }
);

// type definitions so we can use them in the file action handler
type CustomActions = typeof renameFileAction | typeof openFileAction | typeof moveFileAction | typeof deleteSelectionAction | typeof resetMoveFileStateAction;
type CustomSortActions = typeof ascSortAction | typeof descSortAction | typeof newestSortAction | typeof oldestSortAction | typeof viewAllImagesAction | typeof viewMyImagesAction;
export type CustomActionUnion = CustomActions | ChonkyActionUnion | CustomSortActions;

// chonky's default file actions
export const defaultFileActions = [
    ChonkyActions.EnableGridView, 
    ChonkyActions.EnableListView,
];

// our custom file actions
export const customFileActions = [
    moveFileAction, 
    selectAllFileAction, 
    clearSelectionFileAction, 
    deleteSelectionAction,
    resetMoveFileStateAction,
];