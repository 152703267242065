import * as React from "react";

import { PostFeedItem } from "../../../../../models";
import Tile from "./Tile";


import "../../../styles/Card.sass";


const Column: React.FunctionComponent<ComponentProps> = props => {
  return (
    <div className="column">
      {props.postsFeed.map(post => <Tile key={post.id} {...props} post={post} />)}
    </div>
  );
}

interface ComponentProps {
  hidePublishTime?: boolean;
  hideStats?: boolean;
  hideSummary?: boolean;
  hideTopics?: boolean;
  postsFeed: PostFeedItem[];
  preferredLCID: string;
  onPostSelected: (post: PostFeedItem) => void;
}

export default Column;