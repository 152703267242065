export interface ApplicationState {
    head: HeadDefinition
}

export interface HeadDefinition {
    title?: string;
    description?: string;
    openGraphTitle?: string;
    openGraphDescription?: string;
    openGraphUrl?: string;
    openGraphImageUrl?: string;
    openGraphVideo?: string;
}

export const DefaultHeadValues = {
    title: "Sparrow",
    description: "",
    openGraphTitle: "",
    openGraphDescription: "",
    openGraphUrl: "https://sparrowapp.io",
    openGraphImageUrl: "",
    openGraphVideo: ""
}
