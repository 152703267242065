import * as React from 'react';
import BaseComponentOverlay from './baseComponentOverlay';
import { Typography } from '@mui/material';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';

import './dragDropOverlay.sass';

interface ComponentProps {
    show: boolean;
    maxNumUploads: number;
    maxUploadMbSize: number;
    onDrop: (e: React.DragEvent<HTMLDivElement>) => void;
    onDragEnd: (e: React.DragEvent<HTMLDivElement>) => void;
    fullScreen?: boolean;
    onClose?: () => void;
}

const DragDropOverlay: React.FC<ComponentProps> = (props) => {
    return (
        <BaseComponentOverlay
            show={props.show}
            fullScreen={props.fullScreen}
            containerStyle={{
                height: '100%',
                justifyContent: 'center',
                border: '3px dashed #DDE1E5',
            }}
            middleComponent={
                <>
                    <FileUploadOutlinedIcon
                        sx={{
                            fontSize: 150,
                            color: '#888'
                        }}
                    />
                    <Typography color={'#888'} fontWeight={600} fontSize={20}>
                        Drag and drop your images here
                    </Typography>
                    <Typography color={'#888'} fontStyle={'italic'}>
                        Maximum of {props.maxNumUploads} ({props.maxUploadMbSize} MB per file)
                    </Typography>
                    <div
                        onKeyDown={(e) => e.key === "Escape" && props.onClose ? props.onClose() : undefined}
                        className='drag-drop-div'
                        onDrop={props.onDrop}
                        onDragOver={(e) => e.preventDefault()} // enable drop
                        onDragEnd={props.onDragEnd}
                        onDragLeave={props.onDragEnd}
                    />
                </>
            }
        />
    );
}

export default DragDropOverlay;