import React from "react";
import moment from "moment";
import numeral from "numeral";

import { AudienceFilterValues, AudienceItem, AudiencePage, AudienceState, AudienceType } from "../../models";
import { TenantAttribute } from "modules/settings/models";

import InfoHover from "modules/common/components/hovers/infoHover";
import Loading from "modules/common/components/loading";
import LoadingOverlay from "modules/common/components/loadingOverlay";

import MoreAudienceOptions from "../action-buttons/moreAudienceOptions";
import AudienceFilters from "../audience-filters/audienceFilters";

import TabContent from "pages/common/tabContent";
import TabContentToolbar from "pages/common/tabContentToolbar";

import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import BlockIcon from "@mui/icons-material/Block";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "@mui/material";
import Paging from "modules/common/components/paging";


interface HeaderCell {
  disablePadding: boolean;
  id: string;
  label: string | JSX.Element;
}


class AudienceList extends React.Component<ComponentProps, ComponentState> {
  constructor(props: ComponentProps) {
    super(props);

    this.state = {
      filters: { ...this.getDefaultFilterValues() },
      hasFiltersApplied: false,
      isDownloading: false,
      isPagedLoad: false,
      selectedItems: [],
      showEditor: false,
    };
  }

  public componentDidMount() {
    moment.locale("en");
  }

  public componentDidUpdate(prevProps: ComponentProps) {
    if (this.props.id !== prevProps.id)
      this.setState({ selectedItems: [] });

    if (this.props.hasRefreshed && !prevProps.hasRefreshed) {
      this.setState({ filters: { ...this.getDefaultFilterValues() }, hasFiltersApplied: false });
    }
  }

  public render() {
    const { page } = this.props;

    if (!this.props.show)
      return <React.Fragment></React.Fragment>;

    if (page.isFetching && !this.state.isPagedLoad)
      return <Loading />;
      
    return (
      <div>
        {this.getToolbar()}
        <TabContent>
          {this.getFilter()}
          {this.props.warningOpen && this.getAudienceWarning()}
          {this.getList()}
          <LoadingOverlay absolute={true} show={this.state.isDownloading} />
        </TabContent>
      </div>
    );
  }

  private formatAudienceType = (audienceType: AudienceType): string => {
    if (audienceType === "smart")
      return "Smart";
    else
      return "Standard";
  }

  private formatUserCount = (userCount: number): string => {
    return numeral(userCount).format("0,0");
  }

  private formatState = (state: AudienceState): JSX.Element => {
    if (state === "disabled")
      return <span className="audience-state grey">Disabled</span>;
    else
      return <span className="audience-state green">Enabled</span>;
  }


  private getDefaultFilterValues = (): Partial<AudienceFilterValues> => {
    const filters: Partial<AudienceFilterValues> = {
      audienceTypes: [],
      textToSearch: ""
    };
    return filters;
  }


  private getFilter = (): JSX.Element => {
    return (
      <AudienceFilters
        filters={this.state.filters}
        tenantAttributes={this.props.tenantAttributes}
        syncType={this.props.syncType}
        onChangeFilters={this.onChangeFilters}
        onClearFilters={this.onClearFilters}
      />
    );
  }

  private getAudienceWarning = (): JSX.Element => {
    return (
      <div style={{marginBottom: "20px"}}>
        <div className="disclaimer-block-text" style={{backgroundColor: "#f4f4f1"}}>
          <InfoOutlinedIcon/>
          <div style={{position: "relative", top: "3px"}}>
              Your organization has a maximum of {this.props.maxAudiences} audiences. Contact <Link href='mailto:support@sparrowconnected.com'>support@sparrowconnected.com</Link> to upgrade your plan.
          </div>
          <CloseIcon onClick={this.props.setWarningClosed} style={{color: "#555555", display: "block", marginLeft: "auto", cursor: "pointer"}}/>
        </div>
      </div>
    );
  }

  private getHeader = (): HeaderCell[] => {
    const { id } = this.props;

    let header: HeaderCell[] = [
      { id: "title", disablePadding: true, label: "Audience" },
      { id: "options", disablePadding: true, label: "" }
    ];

    if (id === "all")
      header.push({ id: "state", disablePadding: false, label: "State" });

    header.push({ id: "audienceType", disablePadding: false, label: "Audience type" });
    header.push({ id: "totalUsers", disablePadding: false, label: (
      <div className="header-view">
        <span>Total users</span>
        <InfoHover>Includes all existing accounts added from your Sparrow Directory, {this.props.syncType} Groups and the invites sent while importing emails.</InfoHover>
      </div>
    )});
    header.push({ id: "workUsers", disablePadding: false, label: `${this.props.syncType} users` });
    header.push({ id: "socialUsers", disablePadding: false, label: (
      <div className="header-view">
        <span>Social users</span>
        <InfoHover>Social users are those with accounts that use login using Google, Facebook, LinkedIn, Apple or Microsoft Personal Account.</InfoHover>
      </div>
    )});
    header.push({ id: "sparrowUsers", disablePadding: false, label: (
      <div className="header-view">
        <span>Sparrow users</span>
        <InfoHover>Sparrow users are those with accounts that use a custom User ID and Password to login.</InfoHover>
      </div>
    )});
    header.push({ id: "invitedUsers", disablePadding: false, label: (
      <div className="header-view">
        <span>Invited users</span>
        <InfoHover>Invited users are social users with open invites that have not yet accepted their invites.</InfoHover>
      </div>
    )});
    
    return header;
  }

  private getList = (): JSX.Element => {
    const { page } = this.props;
    const { selectedItems } = this.state;

    const rowCount = page.audiences.length || 10;

    if (page.isFetching)
      return <Loading />;
    
    if (!page.audiences.length)
      return (
        <React.Fragment>
          {this.state.hasFiltersApplied &&
            <Typography variant="h2" className="audience-results">Results</Typography>
          }
          <div>No audiences were found.</div>
        </React.Fragment>
      );

    return (
      <React.Fragment>
        {this.state.hasFiltersApplied &&
          <Typography variant="h2" className="audience-results">Results</Typography>
        }
        <TableContainer>
          <Table size="medium">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    indeterminate={selectedItems.length > 0 && selectedItems.length < rowCount}
                    checked={rowCount > 0 && selectedItems.length === rowCount}
                    onChange={this.onSelectAllAudiences}
                    inputProps={{ "aria-label": "select all audiences" }}
                  />
                </TableCell>
                {this.getHeader().map((headerCell) => {
                  if (headerCell.id === "options")
                    return <TableCell key={headerCell.id} padding="checkbox"></TableCell>

                  return (
                    <TableCell
                      key={headerCell.id}
                      align={headerCell.disablePadding ? "left" : "center"}
                      padding={headerCell.disablePadding ? "none" : "normal"}
                    >
                      {headerCell.label}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {page.audiences
                .map((audience, index) => this.getListItem(audience, index))
              }
            </TableBody>
          </Table>
        </TableContainer>
        <Paging
          currentPage={page.currentPage}
          items={page.audiences}
          totalItems={page.totalAudiences}
          totalPages={page.totalPages}
          onChangePage={this.onChangePage}
          resetSelection={this.resetSelection}
        />
      </React.Fragment>
    );
  }

  private getListItem = (audience: AudienceItem, index: number): JSX.Element => {
    const isAudienceSelected = this.isAudienceSelected(audience);
    const labelId = `audience-list-checkbox-${index}`;
    
    return (
      <React.Fragment key={audience.id}>
        <TableRow
          hover
          onClick={() => this.onEditAudience(audience.id)}
          role="checkbox"
          aria-checked={isAudienceSelected}
          tabIndex={-1}
          key={audience.id}
          selected={isAudienceSelected}
        >
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              checked={isAudienceSelected}
              inputProps={{ "aria-labelledby": labelId }}
              onClick={(ev) => this.onSelectAudience(ev, audience)}
            />
          </TableCell>
          <TableCell component="th" id={labelId} scope="row" padding="none">{audience.title || "unknown"}</TableCell>
          <TableCell padding="checkbox">
            <MoreAudienceOptions
              audience={audience}
              onDeleteAudience={this.onDeleteAudience}
              onDisableAudience={this.onDisableAudience}
              onDownloadUserData={this.onDownloadUserData}
              onEditAudience={this.onEditAudience}
              onEnableAudience={this.onEnableAudience}
              onSelection={this.onClearAllAudiences}
            />
          </TableCell>
          {this.props.id === "all" && <TableCell align="center">{this.formatState(audience.state)}</TableCell>}
          <TableCell align="center">{this.formatAudienceType(audience.audienceType)}</TableCell>
          <TableCell align="center">{this.formatUserCount(audience.totalUsers)}</TableCell>
          <TableCell align="center">{this.formatUserCount(audience.internalUsers)}</TableCell>
          <TableCell align="center">{this.formatUserCount(audience.socialUsers)}</TableCell>
          <TableCell align="center">{this.formatUserCount(audience.sparrowUsers)}</TableCell>
          <TableCell align="center">{this.formatUserCount(audience.invitedUsers)}</TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  private getToolbar = (): JSX.Element => {
    const { selectedItems } = this.state;

    if (!selectedItems.length)
      return <React.Fragment></React.Fragment>;
    
    return (
      <TabContentToolbar>
        <div>
          {this.getToolbarIcon(`${selectedItems.length} selected`, <ClearIcon />, this.onClearAllAudiences)}
        </div>
        <div>
          {selectedItems.length === 1 &&
            <React.Fragment>
              {this.getToolbarIcon("Edit audience details", <EditIcon />, () => this.onEditAudience(selectedItems[0].id))}
            </React.Fragment>
          }
          {selectedItems.findIndex(selectedItem => selectedItem.state === "enabled") === -1 && this.getToolbarIcon("Enable", <CheckCircleOutlineIcon />, () => this.onEnableAudiences(selectedItems))}
          {selectedItems.findIndex(selectedItem => selectedItem.state !== "enabled") === -1 && this.getToolbarIcon("Disable", <BlockIcon style={{ transform: "rotate(90deg)" }} />, () => this.onDisableAudiences(selectedItems))}
          {this.getToolbarIcon("Delete", <DeleteIcon />, () => this.onDeleteAudiences(selectedItems))}
        </div>
      </TabContentToolbar>
    );
  }

  private getToolbarIcon = (title: string, icon: React.ReactNode, onClick: () => void) => {
    return (
      <Button aria-label={title.toLowerCase()} color="primary" startIcon={icon} onClick={onClick}>
        {title}
      </Button>
    );
  }


  private isAudienceSelected = (audience: AudienceItem): boolean => {
    return this.state.selectedItems.findIndex((selectedItem) => selectedItem.id === audience.id) !== -1;
  }


  private onChangeFilters = (filters: Partial<AudienceFilterValues>) => {
    const updatedFilters = { ...this.state.filters, ...filters };
    this.setState({ filters: updatedFilters, hasFiltersApplied: true, isPagedLoad: true });
    this.props.fetchPage(1, updatedFilters);
  }

  private onClearFilters = () => {
    this.setState({ hasFiltersApplied: false, filters: { ...this.getDefaultFilterValues() }, isPagedLoad: true });
    this.props.fetchPage(1, {});
  }


  private onClearAllAudiences = () => {
    this.setState({ selectedItems: [] });
  }


  private onDeleteAudience = (id: string) => {
    this.props.onDeleteAudience(id).then((succeeded) => {
      if (succeeded)
        this.onClearAllAudiences();
    });
  }

  private onDeleteAudiences = (audiences: AudienceItem[]) => {
    if (audiences.length === 1)
      this.onDeleteAudience(audiences[0].id);
    else
      this.props.onDeleteAudiences(audiences.map(audience => audience.id)).then((succeeded) => {
        if (succeeded)
          this.onClearAllAudiences();
      });
  }

  private onDisableAudience = (id: string) => {
    this.props.onDisableAudience(id).then((succeeded) => {
      if (succeeded)
        this.onClearAllAudiences();
    });
  }

  private onDisableAudiences = (audiences: AudienceItem[]) => {
    if (audiences.length === 1)
      this.onDisableAudience(audiences[0].id);
    else
      this.props.onDisableAudiences(audiences.map(audience => audience.id)).then((succeeded) => {
        if (succeeded)
          this.onClearAllAudiences();
      });
  }

  private onDownloadUserData = (id: string, title: string) => {
    this.props.onDownloadUserData(id, title);
  }

  private onEnableAudience = (id: string) => {
    this.props.onEnableAudience(id).then((succeeded) => {
      if (succeeded)
        this.onClearAllAudiences();
    });
  }

  private onEnableAudiences = (audiences: AudienceItem[]) => {
    if (audiences.length === 1)
      this.onEnableAudience(audiences[0].id);
    else
      this.props.onEnableAudiences(audiences.map(audience => audience.id)).then((succeeded) => {
        if (succeeded)
          this.onClearAllAudiences();
      });
  }

  private onEditAudience = (id: string) => {
    this.props.onEditAudience(id);
  }

  private onSelectAllAudiences = () => {
    if (this.state.selectedItems.length === this.props.page.audiences.length)
      this.setState({ selectedItems: [] });
    else
      this.setState({ selectedItems: this.props.page.audiences });
  }

  private onSelectAudience = (ev, audience: AudienceItem) => {
    ev.stopPropagation();

    let selectedItems = this.state.selectedItems.slice();

    if (selectedItems.findIndex((selectedItem) => selectedItem.id === audience.id) === -1)
      selectedItems = selectedItems.concat([audience]);
    else
      selectedItems = selectedItems.filter(selectedItem => selectedItem.id !== audience.id);

    this.setState({ selectedItems });
  }

  private resetSelection = () => {
    this.setState({selectedItems: []});
  }

  private onChangePage = (page: number) => {
    this.props.fetchPage(page + 1, this.state.filters);
  }
}
  

interface ComponentProps {
  show: boolean;
  id: string;
  page: AudiencePage;
  hasRefreshed?: boolean;
  tenantAttributes: TenantAttribute[];
  syncType: string;
  maxAudiences: number;
  warningOpen: boolean;
  fetchPage: (pageNumber: number, filters: Partial<AudienceFilterValues>) => void;
  onDeleteAudience: (id: string) => Promise<boolean>;
  onDeleteAudiences: (ids: string[]) => Promise<boolean>;
  onDisableAudience: (id: string) => Promise<boolean>;
  onDisableAudiences: (ids: string[]) => Promise<boolean>;
  onDownloadUserData: (id: string, title: string) => void;
  onEditAudience: (id: string) => void;
  onEnableAudience: (id: string) => Promise<boolean>;
  onEnableAudiences: (ids: string[]) => Promise<boolean>;
  setWarningClosed: () => void;
}

interface ComponentState {
  filters: Partial<AudienceFilterValues>;
  hasFiltersApplied: boolean;
  isDownloading: boolean;
  isPagedLoad: boolean;
  selectedItems: AudienceItem[];
  showEditor: boolean;
}

export default AudienceList;