import * as React from "react";

import { Address, SaveNewsletterModel, SaveNewsletterModelStateErrors } from "modules/newsletters/models";
import Divider from "@mui/material/Divider";

import AudienceAndFrequencySettings from "./audienceAndFrequencySettings";
import ContentTypeAndTopicsSettings from "./contentTypeAndTopicSettings";
import {Audience} from "modules/audiences/models";
import { NewsletterDetails } from "modules/newsletters";

class SettingsSection extends React.Component<ComponentProps, {}> {
  constructor(props: ComponentProps) {
    super(props);
    this.state = {};
  }

  public render() {
    return (
      <div className="newsletter-page" style={{ marginBottom: 30 }}>
        <AudienceAndFrequencySettings onSearchSenders={this.props.onSearchSenders} senders={this.props.senders} audiences={this.props.audiences}  onNewsletterChange={this.props.onNewsletterChange} newsletter={this.props.newsletter} serverNewsletter={this.props.serverNewsletter} modelErrors={this.props.modelErrors} highlight={this.props.highlight}></AudienceAndFrequencySettings>
        <Divider style={{ marginTop: "20px", marginBottom: "30px" }} />
        <ContentTypeAndTopicsSettings onNewsletterChange={this.props.onNewsletterChange} newsletter={this.props.newsletter} modelErrors={this.props.modelErrors} highlight={this.props.highlight}></ContentTypeAndTopicsSettings>
      </div>
    );
  }
}
interface ComponentProps {
  newsletter: SaveNewsletterModel;
  serverNewsletter?: NewsletterDetails;
  audiences: Audience[];
  senders: Address[];
  modelErrors: SaveNewsletterModelStateErrors | null;
  highlight: boolean;
  onNewsletterChange: (value: Partial<SaveNewsletterModel>) => void;
  onSearchSenders: (value: string) => void;
}

export default SettingsSection;