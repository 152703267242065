import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import * as actions from "../../actionCreator";
import { GlobalApplicationState } from "globalApplicationState";
import { push } from "react-router-redux";
import { DocumentsFilterValues, DocumentsListingPage } from "../../models";

import DocumentList from "./documentList";

import Tabs from "pages/common/tabs";


import "../../styles/documentListing.sass";
import { SortStyle } from "utils/managementUtils";


const allTab: number = 0;
const enabledTab: number = 1;
const scheduledTab: number = 2;
const disabledTab: number = 3;


class DocumentListing extends React.Component<PropsWithRedux, ComponentState> {
  constructor(props: PropsWithRedux) {
    super(props);

    this.state = {
      hasRefreshed: false,
      selectedTab: 0
    };
  }

  public componentWillMount() {
    if (this.props.isInitialLoad || this.props.shouldFetch) {
      this.fetchLists();
    }
  }

  public componentDidUpdate(prevProps: PropsWithRedux) {
    if (this.props.shouldFetch && (this.props.shouldFetch !== prevProps.shouldFetch)) {
      this.fetchLists();
    }
  }

  public render() {
    const { selectedTab } = this.state;

    return (
      <React.Fragment>
        <div className="documents-listing">
          <Tabs
            tabs={[
              { label: this.getTabLabel("All", this.props.all) },
              { label: this.getTabLabel("Enabled", this.props.enabled), dotColor: "green" },
              { label: this.getTabLabel("Scheduled", this.props.scheduled), dotColor: "yellow" },
              { label: this.getTabLabel("Disabled", this.props.disabled), dotColor: "grey" }
            ]}
            selectedTab={selectedTab}
            onSelectTab={this.onSelectTab}
          />
          <DocumentList show={selectedTab === allTab} page={this.props.all} hasRefreshed={this.state.hasRefreshed} fetchPage={this.fetchAll} initialSort={SortStyle.publishDesc}/>
          <DocumentList show={selectedTab === enabledTab} page={this.props.enabled} hasRefreshed={this.state.hasRefreshed} fetchPage={this.fetchEnabled} initialSort={SortStyle.publishDesc}/>
          <DocumentList show={selectedTab === scheduledTab} page={this.props.scheduled} hasRefreshed={this.state.hasRefreshed} fetchPage={this.fetchScheduled} initialSort={SortStyle.publishDesc}/>
          <DocumentList show={selectedTab === disabledTab} page={this.props.disabled} hasRefreshed={this.state.hasRefreshed} fetchPage={this.fetchDisabled} initialSort={SortStyle.disableDesc}/>
        </div>
      </React.Fragment>
    );
  }


  private fetchLists = () => {
    this.setState({ hasRefreshed: false }, () => this.setState({ hasRefreshed: true }));
    this.fetchAll(1, {sortType: SortStyle.publishDesc});
    this.fetchEnabled(1, {sortType: SortStyle.publishDesc});
    this.fetchScheduled(1, {sortType: SortStyle.publishDesc});
    this.fetchDisabled(1, {sortType: SortStyle.disableDesc});
    this.props.clearShouldFetch();
  }


  private fetchAll = (pageNumber: number, filters: Partial<DocumentsFilterValues>, pageAmount?: number) => {
    !this.props.all.isFetching &&
      this.props.getAll(pageNumber, filters, pageAmount);
  }

  private fetchEnabled = (pageNumber: number, filters: Partial<DocumentsFilterValues>, pageAmount?: number) => {
    !this.props.enabled.isFetching &&
      this.props.getEnabled(pageNumber, filters, pageAmount);
  }

  private fetchScheduled = (pageNumber: number, filters: Partial<DocumentsFilterValues>, pageAmount?: number) => {
    !this.props.scheduled.isFetching &&
      this.props.getScheduled(pageNumber, filters, pageAmount);
  }

  private fetchDisabled = (pageNumber: number, filters: Partial<DocumentsFilterValues>, pageAmount?: number) => {
    !this.props.disabled.isFetching &&
      this.props.getDisabled(pageNumber, filters, pageAmount);
  }


  private getTabLabel = (label: string, page: DocumentsListingPage): string => {
    if (!!page.currentPage)
      return `${label} (${page.totalDocuments})`;
    return label;
  }

  private onSelectTab = (tabIndex: number) => {
    this.setState({ ...this.state, selectedTab: tabIndex });
  }
}
  

interface ComponentProps {}

interface ComponentState {
  hasRefreshed: boolean;
  selectedTab: number;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    all: state.documents.all,
    enabled: state.documents.enabled,
    disabled: state.documents.disabled,
    scheduled: state.documents.scheduled,
    isFetching: state.documents.isFetching,
    isInitialLoad: state.documents.isInitialLoad,
    shouldFetch: state.documents.shouldFetch,
    tenantSettings: state.settings.tenantSettings,
    tenant: state.tenant.id
  }),
  {
    clearShouldFetch: actions.clearShouldFetch,
    getAll: actions.getDocuments,
    getDisabled: actions.getDisabledDocuments,
    getEnabled: actions.getEnabledDocuments,
    getScheduled: actions.getScheduledDocuments,
    redirectTo: push
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(DocumentListing);