import {connect} from "react-redux";
import {IntlProvider} from "react-intl";
import { GlobalApplicationState } from 'globalApplicationState';

export default connect(
  (state: GlobalApplicationState) => ({
      locale: state.localization.locale,
      messages: state.localization.messages,
      key: state.localization.locale,
    })
  )(IntlProvider);