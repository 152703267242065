import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import * as actions from 'modules/posts/actionCreator';
import { GlobalApplicationState } from 'globalApplicationState';
import moment from 'moment';

import './successfullyPublishedDialog.sass';

class SuccessfullyPublishedDialog extends React.Component<PropsWithRedux, {}> {
    private wrapper: HTMLElement;

    public componentDidMount() {
        moment.locale("en");
    }

    public render() {
        return (
            <div className="successful-publish-container">
                <div className="dialog">
                    <div className="success-checkmark-pop-wrapper" onClick={this.onClick} ref={(e) => this.wrapper = e!}>
                        <div className="success-checkmark-pop-bg">
                            <div className="success-checkmark-pop-checkmark"></div>
                        </div>
                    </div>
                    <h3 className="title">{this.props.title ?? 'Published!'}</h3>
                    <div className="modal-info">
                        {
                            this.shouldShowPublishDate() ?
                                <p>
                                    {`This post will be made available at:`}
                                    <br/>
                                    {moment(this.props.expectedPublishTime).format("dddd, MMMM Do YYYY, h:mm:ss a")}
                                </p>
                                : this.props.displayMessage
                        }
                        {
                            this.props.publishedPostContainsVideoContent ?
                                <p>The published post contains video content. As a result this may take a little while to process.</p>
                                :
                                null
                        }
                    </div>
                    <div className="button-wrapper">
                        <button className="button primary" onClick={this.props.closeModal ?? this.onClickOK}>OK</button>
                    </div>
                </div>
            </div>
        );
    }

    private shouldShowPublishDate = () => {
        if (this.props.expectedPublishTime) {
            let futurePublishThreshold = moment().add(15, "minutes");
            if(moment(this.props.expectedPublishTime).isAfter(futurePublishThreshold))
            {
                return true;
            }
        }

        return false;
    }

    private onClick = (e) => {
        this.wrapper.className = "";
        setTimeout(() => {
            this.wrapper.className = "success-checkmark-pop-wrapper";
        }, 50)
    }

    private onClickOK = () => {
       if (this.props.afterPublishSuccess) {
           this.props.afterPublishSuccess();
       }
       this.props.hidePublishSuccessfullDialog();
    }

}

interface ComponentProps {
    displayMessage: JSX.Element;
    afterPublishSuccess?: () => void;
    title?: string;
    closeModal?: () => any;
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        expectedPublishTime: state.posts.publishConfirmation.expectedPublishTime,
        publishedPostContainsVideoContent: state.posts.publishConfirmation.containsVideoContent
    }),
    {
        hidePublishSuccessfullDialog: actions.hidePublishSuccessfulDialog
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(SuccessfullyPublishedDialog);