import { useEffect, useState } from "react";

export interface DebounceState<T> {
    debVal: T;
    setDebVal: React.Dispatch<React.SetStateAction<T>>;
    callbackIsRun: boolean;
}

// This function allows a callback function to debounce/delay the callback until the value has
// stopped changing for a set amount of time set by timeoutInMs.
export function useDebounceValue<T>(
    debounceValue: T, 
    callbackFn: () => Promise<void>, 
    runFnFirst?: () => void,
    timeoutInMs: number = 750,
    runCallbackOnEmptyString: boolean = false
): DebounceState<T> {
    const [debVal, setDebVal] = useState(debounceValue);
    const [isFirstRun, setIsFirstRun] = useState(true);
    const [callbackIsRun, setCallbackIsRun] = useState(false);
    
    useEffect(() => {
        setCallbackIsRun(false);
        if (runFnFirst) runFnFirst();

        if (debVal !== undefined && debVal !== null && (runCallbackOnEmptyString || debVal !== "") && !isFirstRun) {
            const timeoutId = setTimeout(async () => {
                await callbackFn();
                setCallbackIsRun(true);
            }, timeoutInMs);

            return () => clearTimeout(timeoutId);
        }

        if (isFirstRun) setIsFirstRun(false);
    }, [debVal]);

    return {debVal, setDebVal, callbackIsRun};
}