import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { GlobalApplicationState } from 'globalApplicationState';
import * as actions from 'modules/posts/actionCreator';

import { Comment as IComment } from '../../../models';

import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Popover from "@mui/material/Popover";

import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import NotificationsOffOutlinedIcon from "@mui/icons-material/NotificationsOffOutlined";


import "./comments.sass";
import MentionTextbox from './mentionTextbox';
import ReplySection from './replySection';


const SortSelector: React.FunctionComponent<{ sortByOldestFirst: boolean, onSortByOldestFirst: (sortByOldestFirst: boolean) => void }> = props => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  return (
    <React.Fragment>
      <Button
        variant="text"
        disableRipple
        onClick={(e) => setAnchorEl(e.currentTarget)}
        className="sort-selector"
      >
        {props.sortByOldestFirst ? "Oldest First" : "Newest First"}
      </Button>
      <Popover
        id=""
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        <List dense className="sort-menu">
          <ListItem
            button
            onClick={() => {
              props.onSortByOldestFirst(false);
              setAnchorEl(null);
            }}
          >
            <ListItemText primary="Newest First" />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              props.onSortByOldestFirst(true);
              setAnchorEl(null);
            }}
          >
            <ListItemText primary="Oldest First" />
          </ListItem>
        </List>
      </Popover>
    </React.Fragment>
  );
}

class Comments extends React.Component<PropsWithRedux, ComponentState> {
  constructor(props: PropsWithRedux) {
    super(props);

    this.state = {
      pendingSubscribe: false,
      sortByOldestFirst: false,
      editingComment: false,
    };
  }

  public render() {
    const { comments, containerStyle, preview = false } = this.props;
    const { sortByOldestFirst, editingComment } = this.state;

    if (!comments || !this.props.commentsEnabledOnPost || !this.props.commentsEnabledOnTenant) {
      return (null);
    }

    const hasComments = !!comments && !!comments.length;
    
    const commentTotal = comments.reduce((currentTotal, comment) => {
      return currentTotal + (comment.replyCount ?? 0) + 1;
    }, 0 as number);
    const numberOfComments = hasComments ? ` (${commentTotal.toLocaleString()})` : '';

    const parentComments = comments.filter(c => c.parentId === null);
    const sortedComments = sortByOldestFirst ? this.sortByOldestFirst(parentComments) : this.sortByNewestFirst(parentComments);

    return (
      <div style={containerStyle}>
        <div className="comments-heading">
          <span>Comments{numberOfComments}</span>
          {this.props.showSubscription &&
            (this.props.isSubscribed
              ? <IconButton disabled={preview} color="primary" size="small" title="Turn notifications off" onClick={this.onUnsubscribeFromComments}>
                  <NotificationsNoneOutlinedIcon fontSize="small" />
                </IconButton>
              : <IconButton disabled={preview} color="primary" size="small" title="Turn notifications on" onClick={this.onSubscribeToComments}>
                  <NotificationsOffOutlinedIcon fontSize="small" />
                </IconButton>
            )
          }
        </div>
        <Divider light />
        <MentionTextbox
          preview={preview}
          placeholder={hasComments ? "Leave a comment" : "Be the first to comment"}
          postId={this.props.id}
        />
        {hasComments &&
          <div className="sort">
            <span>Sort By </span>
            <SortSelector
              sortByOldestFirst={sortByOldestFirst}
              onSortByOldestFirst={this.onSortByOldestFirst}
            />
          </div>
        }
        {sortedComments.map((comment, index) =>
            <div key={comment.id+"-container"} className={index !== sortedComments.length-1 ? "comment-bottom-border" : ""}>
              <ReplySection 
                parentComment={comment}
                postId={this.props.id}
                fetchAllReplies={this.props.fetchAllReplies}
                onStar={this.onStar}
                onDestar={this.onDestar}
                editingComment={editingComment}
                toggleEdit={this.updateCommentList}
              />
            </div>
        )}
      </div>
    );
  }

  private onSortByOldestFirst = (sortByOldestFirst: boolean) => {
    this.setState({ sortByOldestFirst });
  }

  private sortByNewestFirst(comments: IComment[]): IComment[] {
    return comments.sort((a, b) => {
      const dateA = new Date(a.createdTime);
      const dateB = new Date(b.createdTime);
      return dateA > dateB
        ? -1
        : dateA === dateB
          ? 0
          : 1;
    });
  }

  private sortByOldestFirst(comments: IComment[]): IComment[] {
    return comments.sort((a, b) => {
      const dateA = new Date(a.createdTime);
      const dateB = new Date(b.createdTime);
      return dateA < dateB
        ? -1
        : dateA === dateB
          ? 0
          : 1;
    });
  }

  private onStar = (toStarId: string) => {
    return this.props.starComment(toStarId).then((response) => {
      if(!!response) {
        const comments = this.props.comments.slice().map(comment => {
          if (comment.id === response.id)
            return response;
          
          comment.repliesPreview = comment.repliesPreview.map(reply => {
            if (reply.id === response.id) {
              return response;
            }
            return reply;
          });
    
          return comment;
        });
    
        this.props.onUpdateComments(comments);
      }
    });
  }

  private onDestar = (toDestarId: string) => {
    return this.props.destarComment(toDestarId).then((response) => {
      if(!!response) {
        const comments = this.props.comments.slice().map(comment => {
          if (comment.id === response.id)
            return response;
          
          comment.repliesPreview = comment.repliesPreview.map(reply => {
            if (reply.id === response.id) {
              return response;
            }
            return reply;
          });
    
          return comment;
        });
    
        this.props.onUpdateComments(comments);
      }
    });
  }

  private onSubscribeToComments = () => {
    if (!this.state.pendingSubscribe) {
      this.setState({ pendingSubscribe: true });
      this.props.subscribeToComments(this.props.id).then((response) => {
        if (response)
          this.props.onUpdateCommentSubscriptions(true);
        this.setState({ pendingSubscribe: false });
      });
    }
  }

  private onUnsubscribeFromComments = () => {
    if (!this.state.pendingSubscribe) {
      this.setState({ pendingSubscribe: true });
      this.props.unsubscribeFromComments(this.props.id).then((response) => {
        if (response)
          this.props.onUpdateCommentSubscriptions(false);
        this.setState({ pendingSubscribe: false });
      });
    }
  }

  private updateCommentList = (isEditing: boolean) => {
    this.setState({editingComment: isEditing});
  }
}


interface ComponentProps {
  id: string;
  containerStyle?: React.CSSProperties;
  commentsEnabledOnPost: boolean;
  commentsEnabledOnTenant: boolean;
  comments: IComment[];
  isSubscribed: boolean;
  showSubscription?: boolean;
  preview?: boolean;
  onUpdateComments: (comments: IComment[]) => void;
  onUpdateCommentSubscriptions: (subscribed: boolean) => void;
}

interface ComponentState {
  pendingSubscribe: boolean;
  sortByOldestFirst: boolean;
  editingComment: boolean;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
  }),
  {
    subscribeToComments: actions.subscribeToComments,
    unsubscribeFromComments: actions.unsubscribeFromComments,
    fetchAllReplies: actions.fetchAllReplies,
    starComment: actions.starComment,
    destarComment: actions.destarComment
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;
export default connector(Comments);
