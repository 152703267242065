
import { OktaAuth, OktaAuthOptions, TokenParams } from "@okta/okta-auth-js";
import { GlobalApplicationState } from "globalApplicationState";
import { Store } from "redux";
import MsalAuthModule from "./msalAuthModule";

//This class uses an OktaAuthModule using Okta-Auth-Js which gets a token silently using the User's logged in context
//Or if that fails, it acquires one through redirect. This token is used to query groups from the SyncFunctions
//as well as to initiate the sync.
class OktaAuthModule{

    private _oktaScopes: string[] = `${process.env.REACT_APP_SYNCFUNCTIONSOKTASCOPES}`.split(",");

    //This is the Client Id of the Sparrow Portal App Registration that exists in the customer's Okta
    private _portalClientId: string;

    private _oktaInstance: OktaAuth;

    private _store: Store<GlobalApplicationState> | null = null;
    
    constructor(oktaOrganizationName: string, clientId: string){ 
        this._oktaInstance = new OktaAuth(this.GetOktaAuthOptions(oktaOrganizationName));
        this._portalClientId = clientId;
    }

    public getAccessToken(){

        var userEmail = this._store?.getState()?.settings.currentUser?.email;

        const tokenParams: TokenParams = {
            scopes: this._oktaScopes,
            responseType: 'token',
            loginHint: userEmail,
            clientId: this._portalClientId,
            redirectUri: (!!window ? window.location.origin : "") + "/auth",
          };

        return this._oktaInstance.token.getWithoutPrompt(tokenParams)
        .then((tokenResponse) => {
            return tokenResponse.tokens.accessToken?.accessToken;
        })
        .catch((error) => {
                return this._oktaInstance.token.getWithRedirect(tokenParams)
                .catch((error) => {
                    MsalAuthModule.getInstance().login();
                    return "";
                })
            });
    }  

    private GetOktaAuthOptions(oktaOrganizationName: string): OktaAuthOptions {
        return {
            issuer: `https://${oktaOrganizationName}.okta.com`
        }
    }
}

export default OktaAuthModule;