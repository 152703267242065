import * as React from "react";

import Paper from "@mui/material/Paper";
import { Divider } from "@mui/material";

const ProgressBar: React.FunctionComponent<ComponentProps> = (props) => {
    const valid = (props.audienceFrequencySectionIsComplete ? 1 : 0) + (props.templateSectionIsComplete ? 1 : 0) + (props.titleSectionIsComplete ? 1 : 0) + (props.composeSectionIsComplete ? 1 : 0);
    const total = 4;
    const percentComplete = (valid / total) * 100;
    const progressBarText = `${valid} of ${total}`;
  
    return (
        <Paper elevation={0} className="section-card">
    <div className="validator">
      <div className="progress-info">
        <div className="progress-bar">
          <div style={{ width: percentComplete + "%" }} className="progress-bar-fill"></div>
        </div>
        <div className="progress-bar-text">{progressBarText}</div>
      </div>
      </div>
      </Paper>
    );
};

const ProgressList: React.FunctionComponent<ComponentProps> = (props) => {
  return (
    <div className="">
      <div className="progress-list" style={{cursor: 'pointer'}}>
          <div className={`progress-list-item${props.templateSectionIsComplete ? " item-valid" : ""}`} onClick={props.onTemplateSectionClicked}>
          <div className="item-valid-indicator">
            <div className="item-valid-indicator-icon"></div>
            </div>
            <div className="item-description-header">
              Select a template &amp; theme
            </div>
          </div>
          <Divider></Divider>
          <div className={`progress-list-item${props.titleSectionIsComplete ? " item-valid" : ""}`} onClick={props.onTitleSectionClicked}>
          <div className="item-valid-indicator">
            <div className="item-valid-indicator-icon"></div>
            </div>
            <div className="item-description-header">
              Set a newsletter title (internal name)
            </div>
          </div>
          <Divider></Divider>
          <div className={`progress-list-item${props.composeSectionIsComplete ? " item-valid" : ""}`} onClick={props.onComposeClicked}>
          <div className="item-valid-indicator">
            <div className="item-valid-indicator-icon"></div>
            </div>
            <div className="item-description-header">
              Enter the Subject and compose the email
            </div>
          </div>
          <Divider></Divider>
          <div className={`progress-list-item${props.audienceFrequencySectionIsComplete ? " item-valid" : ""}`} onClick={props.onAudienceFrequencySectionClicked}>
          <div className="item-valid-indicator">
            <div className="item-valid-indicator-icon"></div>
            </div>
            <div className="item-description-header">
              Set the sender name, email &amp; first issue date
            </div>
          </div>
      </div>
    </div>
  );
};


export const ProgressSection: React.FunctionComponent<ComponentProps> = props => {
  return (
    <div> 
        <ProgressBar {...props} ></ProgressBar>
        <ProgressList {...props} ></ProgressList>
    </div>
  );
}

interface ComponentProps {
  titleSectionIsComplete: boolean;
  audienceFrequencySectionIsComplete: boolean;
  templateSectionIsComplete: boolean;
  composeSectionIsComplete: boolean;
  onTitleSectionClicked: () => void;
  onTemplateSectionClicked: () => void;
  onAudienceFrequencySectionClicked: () => void;
  onComposeClicked: () => void;
}