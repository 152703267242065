import React from "react";
import ConfirmDialog from "./confirmDialog";

interface IConfirmRepublishCompliancePostProps {
    open: boolean;
    onConfirm: () => void;
    onDeny: () => void;
    onClose: () => void;
}

export const ConfirmRepublishCompliancePostDialog: React.FunctionComponent<IConfirmRepublishCompliancePostProps> = ({
    open,
    onConfirm,
    onDeny,
    onClose
}) => {
    return (
        <ConfirmDialog
            title="Publish Changes"
            open={open}
            confirmLabel="PUBLISH"
            denyLabel="CANCEL"
            onConfirm={onConfirm}
            onClose={onClose}
            onDeny={onDeny}
        >
            Publishing your changes will reset the Compliance Report of this post, clearing the list of users who have complied to it.
            <br /><br />
            Would you like to proceed?
        </ConfirmDialog>
    );
}
