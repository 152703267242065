/*
  Designed to replace the old content section, this component essentially holds the same functionality but has Dialog functionality.
  This way we can show the enhanced template selection dialogue.
*/

import * as React from "react";
import { NewsletterImageType, NewsletterTemplate, NewsletterTemplateImageType, NewsletterTheme, SaveNewsletterModel, SaveNewsletterModelStateErrors } from "modules/newsletters";
import _ from "lodash";

import Disclaimer from "modules/common/components/disclaimer";
import Loading from "modules/common/components/loading";
import MoreOptionsButton from "modules/common/components/buttons/moreOptionsButton";

import { Button, Checkbox, Dialog, DialogContent, DialogTitle, Divider, Grid, Link, List, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";

import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tabs from "pages/common/tabs";
import ColorOption from "./colorOption";
import OneColumnNewsletterPreview from "./oneColumnNewsletterPreview";
import TwoColumnNewsletterPreview from "./twoColumnNewsletterPreview";
import TemplateThemeSearchAndSelect from "./templateThemeSearchAndSelect";
import ReplayIcon from '@mui/icons-material/Replay';
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import { push } from "react-router-redux";
import { actions } from "modules/newsletters";
import { hexCodeFromText } from "utils/hexFunctions";
import MoreOptionsItem from "modules/common/components/moreOptionsItem";
import { standardTemplateNames } from "utils/newsletterUtils";

const colorLocations = ["emailBackground", "primaryBackground", "primaryText", "secondaryBackground", "secondaryText", "linkText"]

const headerType:NewsletterImageType = "Header";
const footerType:NewsletterImageType = "Footer";

class TemplateWithPopup extends React.Component<PropsWithRedux, ComponentState> {
  constructor(props: PropsWithRedux) {
    super(props);

    this.state = {
      templateSelectionDialogOpen: props.firstTime,
      selectedTab: 0,
      imageHoverClass: "template-thumbnail-image",

      customImageLoading: false,
      loadingPreviewLink: false,
      loadingPreviewTemplateId: "",

      emailBackgroundVisible: false,
      primaryBackgroundVisible: false,
      primaryTextVisible: false,
      secondaryBackgroundVisible: false,
      secondaryTextVisible: false,
      linkTextVisible: false
    };
  }

  componentDidMount = () => {
    this.props.setFirstTime(false);
    if(this.props.selectedTemplate.isCustom && !this.state.previewImage) {
      this.loadThemePreview(this.props.selectedTemplate.id);
    }
  }

  public render() {
    const { selectedTemplate, templates } = this.props;

    const selectedTemplateIsCustom = selectedTemplate.isCustom;
    const hasCustomTemplates = _.some(templates,t => t.isCustom);

    const oneColumnSelected = this.props.selectedTemplate.name.toLowerCase() === standardTemplateNames[0];
    const twoColumnSelected = this.props.selectedTemplate.name.toLowerCase() === standardTemplateNames[1];

    const currentTheme = this.props.themes.find(t => t.id === this.props.newsletter.newsletterTheme.themeId);
    const themeToBeEdited = this.props.newsletter.newsletterTheme;

    let tabs = [
      { label: "Standard" },
    ];

    if(hasCustomTemplates)
      tabs = tabs.concat({label: "Custom"});

    return (
      <div style={{minHeight: "665px"}}>
        <div style={{display: "flex", flexDirection: "row"}}>
          <p className="tx-strong">Select a template</p>
          <Button style={{marginLeft: "auto", marginRight: "16px"}} onClick={this.setTemplateDialogOpen} color="primary" startIcon={<EditIcon/>}>Template &amp; Theme</Button>
        </div>
        <div>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Typography style={{lineHeight:'42px'}}>Template</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography style={{lineHeight:'42px'}}>{selectedTemplate.name}</Typography>
            </Grid>
            <Grid item xs={3}>
            </Grid>
            <Grid item xs={3}>
            </Grid>

            { selectedTemplateIsCustom &&
            <React.Fragment>
              <Grid item xs={12} style={{marginRight: "10px"}}>
                <Disclaimer
                    icon={<InfoOutlinedIcon />}
                    text={
                    <React.Fragment>
                        <span>
                          Header and Footer images cannot be modified through the Newsletter Editor when using custom templates.
                          Add or modify custom templates with the help of our Support team. <Link onClick={() => window.open("https://support.sparrowconnected.com/en/how-do-i-create-a-custom-template-for-newsletters")} underline="always">Learn more</Link>.
                        </span>
                    </React.Fragment>
                    }
                />
              </Grid>
              <Grid item xs={3} style={{paddingLeft: ""}}>
                Preview
              </Grid>
              <Grid item xs={9}>
                {this.state.customImageLoading || !this.state.previewImage
                ? <Loading></Loading>
                : <img style={{width: "100%"}} src={this.state.previewImage ?? ""} alt={"Preview for selected custom newsletter"}></img> }
              </Grid>
            </React.Fragment>
            }
          </Grid>
        </div>
        {!this.props.selectedTemplate.isCustom &&
        <Grid container spacing={2} style={{paddingTop: "20px"}}>
          <Grid item xs={6}>
            <div>
              <p className="tx-strong">Change theme colors </p>
            </div>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <div style={{position: "relative", top: "6px"}}>Email background</div>
              </Grid>
              <Grid item xs={6}>
                <ColorOption
                  isColorPickerVisible={this.state.emailBackgroundVisible}
                  colorSelected={themeToBeEdited.emailBackground}
                  setColor={(e) => this.changeNewsletterColor(e.hexCode, colorLocations[0])}
                  changeText={(e) => this.changeNewsletterColor(e, colorLocations[0])}
                  setVisible={this.toggleEmailBackgroundVisible}
                />
              </Grid>
              <Grid item xs={1}>
                {currentTheme && themeToBeEdited.emailBackground !== currentTheme.emailBackground &&
                  <ReplayIcon
                    style={{fontSize: "20px", position: "relative", top: "7px", color: "#3B78AB"}}
                    onClick={() => this.changeNewsletterColor(currentTheme.emailBackground, colorLocations[0])}
                  />
                }
              </Grid>

              <Grid item xs={5}>
                <div style={{position: "relative", top: "6px"}}>Primary background</div>
              </Grid>
              <Grid item xs={6}>
                <ColorOption
                  isColorPickerVisible={this.state.primaryBackgroundVisible}
                  colorSelected={themeToBeEdited.primaryBackground}
                  setColor={(e) => this.changeNewsletterColor(e.hexCode, colorLocations[1])}
                  changeText={(e) => this.changeNewsletterColor(e, colorLocations[1])}
                  setVisible={this.togglePrimaryBackgroundVisible}
                />
              </Grid>
              <Grid item xs={1}>
                {currentTheme && themeToBeEdited.primaryBackground !== currentTheme.primaryBackground &&
                  <ReplayIcon
                    style={{fontSize: "20px", position: "relative", top: "7px", color: "#3B78AB"}}
                    onClick={() => this.changeNewsletterColor(currentTheme.primaryBackground, colorLocations[1])}
                  />
                }
              </Grid>

              <Grid item xs={5}>
                <div style={{position: "relative", top: "6px"}}>Primary text</div>
              </Grid>
              <Grid item xs={6}>
                <ColorOption
                  isColorPickerVisible={this.state.primaryTextVisible}
                  colorSelected={themeToBeEdited.primaryText}
                  setColor={(e) => this.changeNewsletterColor(e.hexCode, colorLocations[2])}
                  changeText={(e) => this.changeNewsletterColor(e, colorLocations[2])}
                  setVisible={this.togglePrimaryTextVisible}
                />
              </Grid>
              <Grid item xs={1}>
                {currentTheme && themeToBeEdited.primaryText !== currentTheme.primaryText &&
                  <ReplayIcon
                    style={{fontSize: "20px", position: "relative", top: "7px", color: "#3B78AB"}}
                    onClick={() => this.changeNewsletterColor(currentTheme.primaryText, colorLocations[2])}
                  />
                }
              </Grid>
              <Grid item xs={5}>
                <div style={{position: "relative", top: "6px"}}>Secondary background</div>
              </Grid>
              <Grid item xs={6}>
                <ColorOption
                  isColorPickerVisible={this.state.secondaryBackgroundVisible}
                  colorSelected={themeToBeEdited.secondaryBackground}
                  setColor={(e) => this.changeNewsletterColor(e.hexCode, colorLocations[3])}
                  changeText={(e) => this.changeNewsletterColor(e, colorLocations[3])}
                  setVisible={this.toggleSecondaryBackgroundVisible}
                />
              </Grid>
              <Grid item xs={1}>
                {currentTheme && themeToBeEdited.secondaryBackground !== currentTheme.secondaryBackground &&
                  <ReplayIcon
                    style={{fontSize: "20px", position: "relative", top: "7px", color: "#3B78AB"}}
                    onClick={() => this.changeNewsletterColor(currentTheme.secondaryBackground, colorLocations[3])}
                  />
                }
              </Grid>

              <Grid item xs={5}>
                <div style={{position: "relative", top: "6px"}}>Secondary text</div>
              </Grid>
              <Grid item xs={6}>
                <ColorOption
                  isColorPickerVisible={this.state.secondaryTextVisible}
                  colorSelected={themeToBeEdited.secondaryText}
                  setColor={(e) => this.changeNewsletterColor(e.hexCode, colorLocations[4])}
                  changeText={(e) => this.changeNewsletterColor(e, colorLocations[4])}
                  setVisible={this.toggleSecondaryTextVisible}
                />
              </Grid>
              <Grid item xs={1}>
              {currentTheme && themeToBeEdited.secondaryText !== currentTheme.secondaryText &&
                  <ReplayIcon
                    style={{fontSize: "20px", position: "relative", top: "7px", color: "#3B78AB"}}
                    onClick={() => this.changeNewsletterColor(currentTheme.secondaryText, colorLocations[4])}
                  />
                }
              </Grid>

              <Grid item xs={5}>
                <div style={{position: "relative", top: "6px"}}>Link texts</div>
              </Grid>
              <Grid item xs={6}>
                <ColorOption
                  isColorPickerVisible={this.state.linkTextVisible}
                  colorSelected={themeToBeEdited.linkText}
                  setColor={(e) => this.changeNewsletterColor(e.hexCode, colorLocations[5])}
                  changeText={(e) => this.changeNewsletterColor(e, colorLocations[5])}
                  setVisible={this.toggleLinkTextVisible}
                />
              </Grid>
              <Grid item xs={1}>
              {currentTheme && themeToBeEdited.linkText !== currentTheme.linkText &&
                  <ReplayIcon
                    style={{fontSize: "20px", position: "relative", top: "7px", color: "#3B78AB"}}
                    onClick={() => this.changeNewsletterColor(currentTheme.linkText, colorLocations[5])}
                  />
                }
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            { (oneColumnSelected || twoColumnSelected) && <span style={{color: "#666666", fontStyle: "italic", fontSize: "11px" }}>This preview uses placeholder images and texts only.</span> }
            {oneColumnSelected &&
            <OneColumnNewsletterPreview
              emailBackground={themeToBeEdited.emailBackground}
              primaryBackground={themeToBeEdited.primaryBackground}
              primaryText={themeToBeEdited.primaryText}
              secondaryBackground={themeToBeEdited.secondaryBackground}
              secondaryText={themeToBeEdited.secondaryText}
              linkTexts={themeToBeEdited.linkText}
            />
            }
            {twoColumnSelected &&
            <TwoColumnNewsletterPreview
              emailBackground={themeToBeEdited.emailBackground}
              primaryBackground={themeToBeEdited.primaryBackground}
              primaryText={themeToBeEdited.primaryText}
              secondaryBackground={themeToBeEdited.secondaryBackground}
              secondaryText={themeToBeEdited.secondaryText}
              linkTexts={themeToBeEdited.linkText}
            />
            }
          </Grid>
        </Grid>
        }
        {!!this.state.showPreviewImageName &&
          <Dialog open={true} onClose={this.onClosePreviewImage} maxWidth={false} scroll="paper" classes={{ paper: "newsletter-dialog" }}>
            <DialogTitle className="newsletter-dialog-title">
              <div>{this.state.showPreviewImageName} Preview</div>
              <IconButton onClick={this.onClosePreviewImage} size="large">
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
                {!!this.state.previewImage && <img src={this.state.previewImage} alt={this.state.showPreviewImageName} style={{ height:'auto', width:'auto', maxWidth:'100%'}} />}
                {!this.state.previewImage && <Loading />}
            </DialogContent>
          </Dialog>
        }

        <Dialog open={this.state.templateSelectionDialogOpen} maxWidth="md" fullWidth onClose={this.setTemplateDialogClosed}>
          <DialogTitle style={{paddingBottom: "0px"}}>
            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
              <Typography variant="h5">Select Template and Theme</Typography>
              <IconButton onClick={this.setTemplateDialogClosed} size="large">
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>

          <div style={{marginRight: "24px", marginLeft: "24px"}}>
            <Tabs
              onSelectTab={this.swapTab}
              selectedTab={this.state.selectedTab}
              tabs={tabs}
            />
          </div>

          <DialogContent className="newsletter-page" style={{overflowY: "auto", overflowX: "hidden", height: "670px"}}>
            <div style={{paddingTop: "20px", paddingBottom: "16px"}}>
              { this.state.selectedTab === 0 &&
                this.renderStandardTemplatesAndThemes()
              }
              { this.state.selectedTab === 1 &&
                this.renderCustomTemplateList()
              }
            </div>
          </DialogContent>

          <div style={{marginTop: "10px", marginBottom: "24px", marginRight: "24px", marginLeft: "24px"}}>
            <Divider style={{marginBottom: "12px"}}/>
            <Button style={{float: "right", marginLeft: "24px"}} color="primary" variant="contained" onClick={this.setTemplateDialogClosed}>Next</Button>
            <Button
              style={{float: "right"}}
              onClick={() => this.props.redirectTo(`/${this.props.tenant}/admin/newsletters`)}
            >
              Cancel
            </Button>
          </div>
        </Dialog>
    </div>
    );
  }

  private swapTab = (e: any) => {
    //Standard tab
    if(e === 0) {
      let toSelect = this.props.templates.find(t => t.name.toLowerCase() === "1 column")!.id; //All environments will have the basic 1&2 column
      this.onSelectTemplate(toSelect);
    }
    else if(e === 1) {
      let toSet = this.props.templates.filter(t => t.isCustom)[0] ?? null;
      if(toSet !== null) {
        this.onSelectTemplate(toSet.id);
      }
    }
    this.setState({selectedTab: e})
  }

  private setTemplateDialogOpen = () => {
    this.setState({templateSelectionDialogOpen: true});
  }

  private setTemplateDialogClosed = () => {
    let selectedTheme = this.props.themes.find(t => t.id === this.props.newsletter.newsletterTheme.themeId);
    if(!this.props.selectedTemplate.isCustom && this.props.newsletter.newsletterTheme && selectedTheme?.isCustom) {
      if(selectedTheme.themeHeaderImageIsCustomized) {
        //Load header
        this.props.getThemeImage(headerType, selectedTheme.id).then(image => {
          this.props.setLoadImage(headerType, image);
        });
      }
      if(selectedTheme.themeFooterImageIsCustomized) {
        //Load footer
        this.props.getThemeImage(footerType, selectedTheme.id).then(image => {
          this.props.setLoadImage(footerType, image);
        });
      }
    }
    if(selectedTheme)
      this.props.updateSavedTheme(selectedTheme)

    if(this.props.selectedTemplate.isCustom) {
      this.loadThemePreview(this.props.selectedTemplate.id);
    }

    this.setState({templateSelectionDialogOpen: false});
  }

  private loadThemePreview = async(templateId: string) => {
    this.setState({customImageLoading: true});
    let result = await this.props.getCustomPreview(templateId);
    this.setState({customImageLoading: false, previewImage: result});
  }

  private renderStandardTemplatesAndThemes = () => {
    const oneColumnThemeable = this.props.templates.find(t => t.name.toLowerCase() === "1 column")
    const twoColumnThemeable = this.props.templates.find(t => t.name.toLowerCase() === "2 column")

    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <div>
              <div
                style={{cursor: "pointer", border: this.props.selectedTemplate.name.toLowerCase() === "1 column" ? "2px solid #1C6EA4" : ""}}
                onClick={() => this.onSelectTemplate(oneColumnThemeable?.id ?? "")}
              >
                <img style={{width: "100%"}} src={"/images/newsletterPreviews/thumbnailOneColumn.png"} alt="1 column preview"></img>
              </div>
              <div style={{paddingTop: "4px", paddingBottom: "4px"}}>
                <span style={{float: "left", marginTop: "4px"}}>1 Column</span>
                <div style={{float: "right"}}>
                {this.state.loadingPreviewTemplateId !== oneColumnThemeable!.id ?
                  <MoreOptionsButton text="Options">
                    <List disablePadding>
                      <MoreOptionsItem
                        text="Preview"
                        onClick={() => this.openInNewTab(oneColumnThemeable!.id)}
                        disabled={this.state.loadingPreviewLink}
                      />
                      <Divider/>
                      <MoreOptionsItem
                        text="Download HTML"
                        onClick={() => this.onDownloadTemplateHtml(oneColumnThemeable!)}
                      />
                      <MoreOptionsItem
                        text="Download Header Image"
                        onClick={() => this.onDownloadTemplateImage(oneColumnThemeable!, headerType)}
                      />
                      <MoreOptionsItem
                        text="Download Footer Image"
                        onClick={() => this.onDownloadTemplateImage(oneColumnThemeable!, footerType)}
                      />
                    </List>
                  </MoreOptionsButton>
                  : <Loading size={18}/>
                  }
                </div>
              </div>
            </div>

            <div style={{marginTop: "40px"}}>
              <div
                style={{cursor: "pointer", border: this.props.selectedTemplate.name.toLowerCase() === "2 column" ? "2px solid #1C6EA4" : ""}}
                onClick={() => this.onSelectTemplate(twoColumnThemeable?.id ?? "")}
              >
                <img style={{width: "100%"}} src={"/images/newsletterPreviews/thumbnailTwoColumn.png"} alt="2 column preview"></img>
              </div>
              <div style={{paddingTop: "4px", paddingBottom: "4px"}}>
                <span style={{float: "left"}}>2 Column</span>
                <div style={{float: "right"}}>
                {this.state.loadingPreviewTemplateId !== twoColumnThemeable!.id ?
                <MoreOptionsButton text="Options">
                    <List disablePadding>
                      <MoreOptionsItem
                        text="Preview"
                        onClick={() => this.openInNewTab(twoColumnThemeable!.id)}
                        disabled={this.state.loadingPreviewLink}
                      />
                      <Divider/>
                      <MoreOptionsItem
                        text="Download HTML"
                        onClick={() => this.onDownloadTemplateHtml(twoColumnThemeable!)}
                      />
                      <MoreOptionsItem
                        text="Download Header Image"
                        onClick={() => this.onDownloadTemplateImage(twoColumnThemeable!, headerType)}
                      />
                      <MoreOptionsItem
                        text="Download Footer Image"
                        onClick={() => this.onDownloadTemplateImage(twoColumnThemeable!, footerType)}
                      />
                    </List>
                  </MoreOptionsButton>
                : <Loading size={18}/>
                }
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={8}>
            <TemplateThemeSearchAndSelect
              themes={this.props.themes}
              selectedTheme={this.props.newsletter.newsletterTheme.themeId}
              onNewsletterChange={this.props.onNewsletterChange}
              newsletter={this.props.newsletter}
            />
          </Grid>
        </Grid>
      </div>
    )
  }

  private openInNewTab = async(templateId: string) => {
    this.setState({loadingPreviewLink: true, loadingPreviewTemplateId: templateId});

    let result = await this.props.getCustomPreview(templateId);

    this.setState({loadingPreviewLink: false, loadingPreviewTemplateId: ""});

    let w = window.open("");
    let image = new Image();
    image.src = result;
    setTimeout(function(){
      w?.document.write("<title>Newsletter Preview</title>"+image.outerHTML);
    }, 0);
  }

  private renderCustomTemplateList = () => {
    const customTemplates = this.props.templates.filter(t => t.isCustom).sort((a, b) => a.name.localeCompare(b.name));
    return (
      <div>
        {customTemplates.length === 0 &&
          <Grid item style={{fontSize: "16px", color: "#666", paddingLeft: "24px"}}>
            No custom templates have been uploaded for your organization.
          </Grid>
        }
        {customTemplates.length > 0 &&
        <div>
          <div style={{minHeight: "609px", maxHeight: "609px"}}>
            {customTemplates.map((template, index) => (
              <div key={index}>
                <div style={{display: "flex", flexDirection: "row"}}>
                  <Checkbox checked={this.props.selectedTemplate.id === template.id} onClick={() => this.onSelectTemplate(template.id)} color='secondary'/>
                  <span style={{position: "relative", top: "10px", marginLeft: "10px"}}>{template.name}</span>
                  {this.state.loadingPreviewLink && this.state.loadingPreviewTemplateId === template.id
                  ? <div style={{marginLeft: "auto", marginRight: "20px", position: "relative", top: "8px", height: "30px"}} >
                      <Loading size={16}/>
                    </div>
                  : <Button
                    color="primary"
                    style={{marginLeft: "auto", marginRight: "20px", position: "relative", top: "8px", height: "30px"}}
                    onClick={() => this.openInNewTab(template.id)}
                    disabled={this.state.loadingPreviewLink && this.state.loadingPreviewTemplateId !== template.id}>
                    Preview
                  </Button>}
                </div>
                <Divider/>
              </div>
            ))}
          </div>
        </div>
        }
      </div>
    )
  }

  private onDownloadTemplateHtml = (template: NewsletterTemplate) => {
    this.props.onDownloadTemplateHtml(template);
  }

  private onDownloadTemplateImage = (template: NewsletterTemplate, imageType: NewsletterTemplateImageType) => {
    this.props.onDownloadTemplateImage(template, imageType);
  }

  private onSelectTemplate = (templateId: string) => {
    this.props.onNewsletterChange({...this.props.newsletter, emailTemplateId: templateId});
  }

  private onShowPreviewImage = (ev, template: NewsletterTemplate) => {
    ev.stopPropagation();
    var templateAsUri = encodeURI(template.name);
    window.open(`https://sparrowadcommonstorage.blob.core.windows.net/images/${templateAsUri}.png`, "_blank")
  }

  private onClosePreviewImage = () => this.setState({previewImage: null, showPreviewImageName:null });

  private toggleEmailBackgroundVisible = (toSet: boolean) => {
    this.setState({emailBackgroundVisible: toSet});
  }

  private togglePrimaryBackgroundVisible = (toSet: boolean) => {
    this.setState({primaryBackgroundVisible: toSet});
  }

  private togglePrimaryTextVisible = (toSet: boolean) => {
    this.setState({primaryTextVisible: toSet});
  }

  private toggleSecondaryBackgroundVisible = (toSet: boolean) => {
    this.setState({secondaryBackgroundVisible: toSet});
  }

  private toggleSecondaryTextVisible = (toSet: boolean) => {
    this.setState({secondaryTextVisible: toSet});
  }

  private toggleLinkTextVisible = (toSet: boolean) => {
    this.setState({linkTextVisible: toSet});
  }

  private changeNewsletterColor = (toSet: string, toChange: string) => {
    let hexcode = hexCodeFromText(toSet);
    let isHexCodeValid = hexcode !== null;

    if(isHexCodeValid && hexcode) {
      switch(toChange){
        case colorLocations[0]:
          this.props.onNewsletterChange({...this.props.newsletter, newsletterTheme: {...this.props.newsletter.newsletterTheme, emailBackground: hexcode}});
          break;

        case colorLocations[1]:
          this.props.onNewsletterChange({...this.props.newsletter, newsletterTheme: {...this.props.newsletter.newsletterTheme, primaryBackground: hexcode}});
          break;

        case colorLocations[2]:
          this.props.onNewsletterChange({...this.props.newsletter, newsletterTheme: {...this.props.newsletter.newsletterTheme, primaryText: hexcode}});
          break;

        case colorLocations[3]:
          this.props.onNewsletterChange({...this.props.newsletter, newsletterTheme: {...this.props.newsletter.newsletterTheme, secondaryBackground: hexcode}});
          break;

        case colorLocations[4]:
          this.props.onNewsletterChange({...this.props.newsletter, newsletterTheme: {...this.props.newsletter.newsletterTheme, secondaryText: hexcode}});
          break;

        case colorLocations[5]:
          this.props.onNewsletterChange({...this.props.newsletter, newsletterTheme: {...this.props.newsletter.newsletterTheme, linkText: hexcode}});
          break;
      }
    }
  }
}

interface ComponentState {
  showPreviewImageName?: string | null;
  previewImage?: string | null;
  templateSelectionDialogOpen: boolean;
  imageHoverClass: string;
  selectedTab: number;
  customImageLoading: boolean;
  loadingPreviewLink: boolean;
  loadingPreviewTemplateId: string;

  emailBackgroundVisible: boolean;
  primaryBackgroundVisible: boolean;
  primaryTextVisible: boolean;
  secondaryBackgroundVisible: boolean;
  secondaryTextVisible: boolean;
  linkTextVisible: boolean;
}

interface ComponentProps {
  newsletter: SaveNewsletterModel;
  templates: NewsletterTemplate[];
  themes: NewsletterTheme[];
  selectedTemplate: NewsletterTemplate;
  modelErrors: SaveNewsletterModelStateErrors | null;
  firstTime: boolean;
  setFirstTime: (value: boolean) => void;
  onNewsletterChange: (value: SaveNewsletterModel) => any;
  getTemplateImage: (templateId: string, imageType: NewsletterTemplateImageType) => Promise<string>;
  onDownloadTemplateHtml: (template: NewsletterTemplate) => any;
  onDownloadTemplateImage: (template: NewsletterTemplate, imageType: NewsletterTemplateImageType) => any;
  setLoadImage: (type: NewsletterImageType, toSet: File) => void;
  updateSavedTheme: (toSet: NewsletterTheme) => void;
  children: any; //not used but errors out otherwise
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    tenant: state.tenant.id,
  }),
  {
    redirectTo: push,
    getThemeImage: actions.getThemeImage,
    getCustomPreview: actions.getNewsletterPreview
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(TemplateWithPopup);
