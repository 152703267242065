import React from "react";
import { Typography } from "@mui/material";
import PagingEmpty from "./pagingEmpty";

const ManagementEmptyResults: React.FunctionComponent<ComponentProps> = props => {
    let heightToUse = props.height;
    if(!heightToUse)
        heightToUse = props.hasFilters ? "51vh" : "68vh";

    return (
        <React.Fragment>
            {props.hasFilters &&
                <Typography variant="h2" className="filter-results">Results</Typography>
            }
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", minHeight: heightToUse}}>
                <img style={{height: "200px", width: "200px", paddingTop: "100px"}} alt="Magnifying glass over laptop screen" src="/images/empty_search.png"></img>
                <i>{props.hasFilters ? `No Matches Found` : `No ${props.searchType} Found`}</i>
                {!props.hidePaging 
                &&  <div style={{marginLeft: "auto", marginTop: "auto"}}>
                        <PagingEmpty/>
                    </div>
                }
            </div>
        </React.Fragment>
    );
}

interface ComponentProps {
  searchType: string;
  hasFilters: boolean;
  height?: string;
  hidePaging?: boolean;
}

export default ManagementEmptyResults;