import axios from "axios";
import { RequestFormatInterceptor } from "../interceptors/RequestFormatInterceptor";
import { TenantAutoFillInterceptor } from "../interceptors/TenantAutoFillInterceptor";
import { store } from "configureStore";
import { GraphAuthInterceptor } from "../interceptors/GraphAuthInterceptor";

export class SyncFunctionsGraphApi {
    constructor(
        private _authInterceptor: GraphAuthInterceptor,
        private _formatInterceptor: RequestFormatInterceptor,
        private _tenantAutoFillInterceptor: TenantAutoFillInterceptor
    ) {}

    public CreateRequest() {
        const instance = axios.create();
        instance.defaults.baseURL = store.getState().config.SyncFunctionsApiUrl;
        this._authInterceptor.bind(instance);
        this._formatInterceptor.bind(instance);
        return instance;
    }

    public TenantRequest() {
        let instance = this.CreateRequest();
        this._tenantAutoFillInterceptor.bind(instance);
        return instance;
    }
}
