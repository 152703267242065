import React from "react";

import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import ConditionalWrapper from "modules/common/components/conditionalWrapper";

interface InputFieldProps {
    description?: string;
    label: string;
    error?: boolean;
    value: string;
    placeholder?: string;
    refreshable?: boolean;
    multiline?: boolean;
    disabled?: boolean;
    required?: boolean;
    helperText?: string;
    onChange?: (event: any) => void;
}

const InputField: React.FunctionComponent<InputFieldProps> = ({
    refreshable = false,
    ...props
}) => {
    return (
        <div className="section-field">
            <div className="section-input-field-heading">
                {props.required ? <label className={"control-label"}>{props.label}</label> : <label>{props.label}</label>}
            </div>
            <div className="section-input-field">
            <ConditionalWrapper condition={refreshable} wrapper={(children) => <div key={props.value}>{children}</div>}>
                    <TextField
                        variant="outlined"
                        size="small"
                        error={props.error}
                        defaultValue={props.value}
                        placeholder={props.disabled ? "" : props.placeholder}
                        multiline={props.multiline}
                        rows={3}
                        fullWidth
                        helperText={props.helperText}
                        autoComplete="off"
                        disabled={props.disabled}
                        onChange={(event) => {
                            if (!!props.onChange && event.target.value !== props.value)
                                props.onChange(event);
                        }}
                    />
                </ConditionalWrapper>
                {props.description &&
                    <Typography variant="caption">{props.description}</Typography>
                }
            </div>
        </div>
    );
}

export default InputField;
