import _ from 'lodash';

interface IColumnWidthSettings {
    maxColumns: number
}

const defaultSettings: IColumnWidthSettings = {
    maxColumns: 16
}

interface IColumn {
    key: string,
    width?: number
}

export default class ColumnWidthCalculator {
    private settings: IColumnWidthSettings;

    constructor(columnWidthSettings?: IColumnWidthSettings) {
        this.settings = { ...defaultSettings, ...columnWidthSettings };
    }

    public CalculateWidth = (columns: IColumn[]) => {
        this.guardAgainstBadWidths(columns);

        const results = {};
        let modifier = 1;
        const undefineds = columns.filter(c => !c.width);

        // there is an undefined value, so we need to use math
        if (undefineds.length > 0) {
            const constant = columns.filter(c => !!c.width)
                                  .map(c => c.width)
                                  .reduce((a, c) => a! += c!, 0);
            modifier = (this.settings.maxColumns - undefineds.length) / constant!;
            columns.forEach(col => {
                results[col.key] = { flexGrow: ((!!col.width) ? col.width * modifier : 1) };
            });
        } else {
            let smallestWidth = _.min(columns.map(c => c.width));
            columns.forEach(col => {
                results[col.key] = { flexGrow: ((!!col.width) ? col.width : 1) / (smallestWidth || 1) };
            });
        }

        return results;
    }

    private guardAgainstBadWidths = (columns: IColumn[]) => {
        const expectedColumnCount = this.settings.maxColumns;
        const colMap = columns.map(c => c.width);
        if (colMap.indexOf(undefined) === -1) {
            // no columns have undefined / flexible width
            // ensure they add to max column count
            if (colMap.reduce((prev, current) => prev! += current!) !== expectedColumnCount) {
                throw Error('Unexpected Column Widths. Widths do not add up to ' + expectedColumnCount);
            }
        }
    }
}
