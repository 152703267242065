import { messagingApi } from 'api/instances';
import DownloadButton from 'modules/common/components/buttons/downloadButton';
import ToolboxButton from 'modules/users/components/toolboxButton';
import UserInsightsBlock from 'modules/users/components/userInsightsBlock';
import React from 'react';

interface ComponentProps { 
    navigateToSettings: () => any;
    userMobileStats: UserMobileStats;
}

interface UserMobileStats {
    activeDevices: number,
    usersWithApp: number,
    usersWithNoDevice: number
}

const MessagingCenterMobileTab: React.FunctionComponent<ComponentProps> = ({navigateToSettings, userMobileStats}) =>  {

    const defaultStyles: React.CSSProperties = {
        height: '50px',
        lineHeight: '0px',
        width: '290px',
        textAlign: 'left',
        boxShadow: '0 0 5px #b8b8b8',
        margin: 5,
        borderRadius: 5,
        border: 'none',
        cursor: 'pointer',
        background: 'white',
        color: 'black'
    }

    return (
        <div className="messaging-center-tab-container">
            <h4>Mobile App User Count</h4>
            <div className="email-tab-container">
                <div className="center-div">

                    <div className="vertically-center-div">
                        <div className="email-insights">
                            
                            <UserInsightsBlock 
                                title={"Total Number of Active Devices"}
                                stats={userMobileStats.activeDevices}
                            />

                            <UserInsightsBlock 
                                title={"Total Number of Users With App"}
                                stats={userMobileStats.usersWithApp}
                            />

                            <UserInsightsBlock 
                                title={"Total Number of Users With No Device"}
                                stats={userMobileStats.usersWithNoDevice}
                            />
                        </div>
                    </div>

                    <div className="email-toolbox-container">
                        <span className="toolbox-title">Mobile App Toolbox</span>
                        <div className="toolbox-button-wrapper">    

                            <DownloadButton
                                onClick={() => messagingApi.GetMobileUserCsv() }
                                text={" Download User Data"}
                                downloadName={`notification-mobile-user-data`}
                                styles={defaultStyles}
                            />

                            <DownloadButton
                                onClick={() => messagingApi.GetOutbox("mobile") }
                                text={" Download Mobile App Outbox"}
                                downloadName={`notification-mobile-outbox-user-data`}
                                styles={defaultStyles}
                            />

                            <ToolboxButton 
                                title={"Automated Mobile App Configurations"}
                                handleClick={() => navigateToSettings()}    
                            />
                            
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default MessagingCenterMobileTab;