import * as React from 'react';

import { PostViewStats, Reaction as IReaction, ReactionStat } from '../../../models';
import Reaction from "./reaction";


import "./reactions.sass";
import { ClientSettingsReaction } from 'modules/settings';


class Reactions extends React.Component<ComponentProps, ComponentState> {
  constructor(props: ComponentProps) {
    super(props);

    this.state = {};
  }

  public render() {
    const { stats, preview } = this.props;

    if (!stats || !this.props.reactionsEnabledOnTenant || !this.props.reactingEnabledOnPost || this.props.postType === 'compliance') {
      return (null);
    }

    const reactions: ReactionStat[] = this.getReactions(stats.reactions);

    return (
      <div className="reactions">
        <div className="available-reactions">{reactions.map(reaction => <Reaction preview={preview} key={reaction.id} reaction={reaction} />)}</div>
      </div>
    );
  }

  private getReactions = (postReactions: IReaction[]): ReactionStat[] => {
    const reactionsList = this.props.reactions.sort((a, b) => a.order - b.order);
    const reactions = reactionsList.map(reaction => {
      const matchFound = postReactions.filter(postReaction => postReaction.id === reaction.id);
      if (matchFound && matchFound.length > 0) {
        const match = matchFound[0];
        return {
          postId: this.props.stats.postId,
          id: reaction.id,
          sentiment: reaction.sentiment,
          name: reaction.name,
          count: match.count,
          selected: match.selected
        } as ReactionStat;
      }
      return {
        postId: this.props.stats.postId,
        id: reaction.id,
        sentiment: reaction.sentiment,
        name: reaction.name,
        count: 0
      } as ReactionStat;
    });
    return reactions;
  }
}


interface ComponentProps {
  postType: string;
  reactingEnabledOnPost: boolean;
  stats: PostViewStats;
  reactionsEnabledOnTenant: boolean;
  reactions: ClientSettingsReaction[];
  preview?: boolean;
}

interface ComponentState {
}

export default Reactions;
