import { GlobalApplicationState } from "globalApplicationState";
import { Store } from "redux";
import confirm, { errorAlert } from 'utils/notyPopups';
import {
    AccountInfo,
    AuthenticationResult,
    EndSessionRequest,
    PublicClientApplication,
    RedirectRequest,
    SilentRequest,
    SsoSilentRequest
} from "@azure/msal-browser";
import { QUERY_PARAM_KEYS } from "modules/common/hooks/useQueryParams";
import { mapSocialProviderNameToDomainHint } from "utils/idpMapper";

export interface ICustomState {
    originatedFrom?: string;
}

class MsalAuthModule {

    private _scopes: string[] = `${process.env.REACT_APP_B2CSCOPES}`
        .split(",")
        .map((scope) => `https://${process.env.REACT_APP_B2CTENANTNAME}.onmicrosoft.com/${process.env.REACT_APP_B2CAPICLIENTID}/${scope}`);

    private _graphScopes: string[] = `${process.env.REACT_APP_SYNCFUNCTIONSGRAPHSCOPES}`
        .split(",")
        .map((scope) => `https://${process.env.REACT_APP_B2CTENANTNAME}.onmicrosoft.com/${process.env.REACT_APP_SYNCFUNCTIONSAPICLIENTID}/${scope}`);

    //These are Sparrow known subdomains. If a subdomain is in this list, we will not send them as a domain_hint to B2C
    //(which auto directs a user to a specific provider)
    private _knownSubDomains: string[] = `${process.env.REACT_APP_KNOWN_SUBDOMAINS}`.split(",");


    private static instance: MsalAuthModule;
    private _store: Store<GlobalApplicationState> | null = null;

    private _msalInstance: PublicClientApplication;

    private constructor(msalInstance: PublicClientApplication, store: Store<GlobalApplicationState>) {
        this._msalInstance = msalInstance;
        this._store = store;
    }

    public static getInstance(): MsalAuthModule {
        return MsalAuthModule.instance;
    }

    public static setInstance(msalInstance: PublicClientApplication, store: Store<GlobalApplicationState>) {
        if (!MsalAuthModule.instance) {
            MsalAuthModule.instance = new MsalAuthModule(msalInstance, store);
        }
    }

    public notyOpen: boolean = false;

    login = async (requestConfig: RedirectRequest = {} as RedirectRequest): Promise<void> => {
        let baseRequest = this._getBaseRedirectRequest();
        await this._msalInstance.loginRedirect({ ...baseRequest, ...requestConfig });
    };

    logout = async (signOutSuccess: boolean = false): Promise<void> => {
        let currAccount = this.getCurrentAccount();
        const endSessionRequest: EndSessionRequest = { account: currAccount };

        if (signOutSuccess)
            endSessionRequest.postLogoutRedirectUri = `${this.getLogoutRedirectUri()}?${QUERY_PARAM_KEYS.SIGNED_OUT_SUCCESS}=true`;

        await this._msalInstance.logoutRedirect(endSessionRequest);
    };

    invite = async (inviteToken: string, tenantName: string) => {
        const authority = `https://${process.env.REACT_APP_B2CTENANTNAME}.b2clogin.com/${process.env.REACT_APP_B2CTENANTNAME}.onmicrosoft.com/${process.env.REACT_APP_B2C_INVITE_FLOW_NAME}`;
        const request: RedirectRequest = {
            scopes: this._scopes,
            extraQueryParameters: {
                inviteToken: encodeURIComponent(inviteToken),
                tenantName,
            },
            authority,
        };
        await this._msalInstance.loginRedirect(request);
    }

    resetSparrowPassword = async (token: string) => {
        const authority = `https://${process.env.REACT_APP_B2CTENANTNAME}.b2clogin.com/${process.env.REACT_APP_B2CTENANTNAME}.onmicrosoft.com/${process.env.REACT_APP_B2C_RESET_SPARROW_ACCOUNT_PASSWORD_NAME}`;
        const request: RedirectRequest = {
            scopes: this._scopes,
            extraQueryParameters: {
                resetPasswordToken: encodeURIComponent(token),
            },
            authority,
        };

        await this._msalInstance.loginRedirect(request);
    }

    public getAccessToken = async(): Promise<string> => {
        let currAccount = this.getCurrentAccount();
        const sid = currAccount?.idTokenClaims!['sid'];

        // if it is an Azure Ad Account, use ssoSilent/sid method
        // (sid is the session id of their Azure Active Directory session - a custom claim
        // we have configured in the Sparrow Connected Azure Ad Multi Tenant App Registration)
        var tokenRequest: SilentRequest;
        if (!!sid) {
            tokenRequest = {
                scopes: this._scopes,
                tokenQueryParameters: { accountSelectPrompt: "none" },
            };
        }
        else {
            let sub = currAccount?.idTokenClaims!['sub'];
            currAccount!.username = sub;

            tokenRequest = this._getBaseSilentRequest(currAccount);
        }

        try {
            let accessTokenResponse = await this._msalInstance.acquireTokenSilent(tokenRequest);
            return accessTokenResponse.accessToken;
        }
        catch {
            if(!this.notyOpen) {
                this.notyOpen = true;
                
                try {
                    if(await this.showRelogPopup())
                    {
                        let tokenResponse = await this._msalInstance.acquireTokenPopup(tokenRequest);
                        this.notyOpen = false;
                        return tokenResponse.accessToken;

                    } else {
                        let redirectRequest: RedirectRequest;
                        
                        if(!!sid) {
                            redirectRequest = { scopes: this._scopes, sid: sid };
                        }
                        else {
                            redirectRequest = this._getBaseAcquireTokenRedirectRequest(currAccount);
                        }

                        this._msalInstance.acquireTokenRedirect(redirectRequest);
                        return "";
                    }
                }
                catch {
                    this.login();
                    return "";
                }
            }
        }

        return "";
    };

    getGraphAccessToken = (): Promise<string | void | undefined> => {
        let currentAcc = this._msalInstance.getActiveAccount();
        var userEmail = this._store?.getState()?.settings.currentUser?.email;
        var userIdp = currentAcc!.idTokenClaims!['idp'].replace("/v2.0", "");
        currentAcc!.username = userEmail!;

        const silentTokenRequest: SilentRequest = {
            authority: userIdp,
            scopes: this._graphScopes,
            account: currentAcc!,
        };

        return this._msalInstance
            .acquireTokenSilent(silentTokenRequest)
            .then((accessTokenResponse) => {
                return accessTokenResponse.accessToken;
            })
            .catch(async (error) => {
                if (error.errorCode === "consent_required") {
                    errorAlert("Unable to search, Application Consent required, please contact your Administrator or Support");
                }
                // user is not authenticated and will need to login again
                else if (error.errorCode === "interaction_required") {
                    if(!this.notyOpen) {
                        this.notyOpen = true;
                        
                        if(await this.showRelogPopup())
                        {
                            let tokenResponse = await this._msalInstance.acquireTokenPopup(silentTokenRequest);
                            this.notyOpen = false;
                            return tokenResponse.accessToken;

                        } 
                        else if (currentAcc) {
                            let redirectRequest = this._getBaseAcquireTokenRedirectRequest(currentAcc);
                            this._msalInstance.acquireTokenRedirect(redirectRequest);
                            return "";
                        }
                        else {
                            const onErrorTokenRequest: RedirectRequest = { scopes: this._scopes };
                            this._msalInstance.loginRedirect(onErrorTokenRequest);
                            return "";
                        }
                    }
                }
                else {
                    const ssoRequest: SsoSilentRequest = {
                        extraQueryParameters: { accountSelectPrompt: "none" },
                        loginHint: userEmail
                    };

                    return this._msalInstance
                        .ssoSilent(ssoRequest)
                        .then((response) => {
                            this._msalInstance.acquireTokenSilent(silentTokenRequest)
                                .then((accessTokenResponse) => {
                                    return accessTokenResponse.accessToken;
                                })
                                .catch((error) => {
                                    const onErrorTokenRequest: RedirectRequest = { scopes: this._scopes };
                                    this._msalInstance.loginRedirect(onErrorTokenRequest);
                                    return "";
                                })
                        });
                }
            });
    };

    getCurrentAccount = (): AccountInfo => {
        let currAccount = this._msalInstance.getActiveAccount();

        if (!currAccount) {
            const accounts = this._msalInstance.getAllAccounts();
            if (accounts.length > 0) {
                this._msalInstance.setActiveAccount(accounts[0]);
            }

            currAccount = accounts[0];
        }

        return currAccount;
    }

    /**
     * Manually handle our redirects
     */
    public initRedirectHandler = async (handler: (redirectResponse: AuthenticationResult | null) => void) => {
        try {
            const redirectResponse = await this._msalInstance.handleRedirectPromise();
            if (!redirectResponse || !redirectResponse.state) return;

            handler(redirectResponse);
        } catch (error) {
        }
    }

    /**
 * Get the base Silent request to use on Silent Requests that do not use an sid
 * add domain_hint if subdomain is set
 * that way on refresh token or access token renewals the correct auth provider
 * is hit (IE: Okta for SSO) rather than the B2C Homepage.
 * @returns
 */
    private _getBaseSilentRequest = (account: AccountInfo): SilentRequest => {
        let base: SilentRequest =
        {
            scopes: this._scopes,
            extraQueryParameters: { accountSelectPrompt: "none" },
            account: account
        };

        let subdomain = window.location.host.split(".")[0];

        if (!this._subdomainIsKnown(subdomain))
            base.extraQueryParameters = { accountSelectPrompt: "none", domain_hint: subdomain };

        return base;
    };

    /**
     * Get the base AcquireTokenRedirect request to use on acquireTokenRedirect
     * - add domain hint if custom subdomain is set using _getBaseRedirectRequest
     * - note that currently this is only used for non-Azure AD accounts (which have an sid)
     * - and mainly for Okta accounts which need to redirect using the domain_hint
     * @returns
     */
    private _getBaseAcquireTokenRedirectRequest = (account: AccountInfo): RedirectRequest => {
        let base = this._getBaseRedirectRequest();
        let user = this._store?.getState().settings.currentUser;

        let providerName = user?.providerName || "";

        account.username = user?.email || "";

        base.tokenQueryParameters = { accountSelectPrompt: "none" };
        base.account = account;

        if (!base.domainHint)
            base.domainHint = mapSocialProviderNameToDomainHint(providerName) || "";

        return base;
    };

    private _getBaseRedirectRequest = (): RedirectRequest => {
        let base: RedirectRequest = { scopes: this._scopes, state: JSON.stringify({ originatedFrom: window.location.href }) };
        let subdomain = window.location.host.split(".")[0];

        if (!this._subdomainIsKnown(subdomain))
            base.domainHint = subdomain;

        return base;
    };

    /**
 *  Check if the subdomain is a known registered one for Sparrow (such as portal in portal.sparrowconnected.com)
 *  If it is known, it should not be passed into B2C as a domain_hint as portal is not a registered identityProvider
 *  However in a case like Okta with a custom URL, we do want to pass the subdomain in as the domain_hint
 *  to hit the identityProvider page directly to use with SSO
 */
    private _subdomainIsKnown = (subdomain: string): boolean => {
        return this._knownSubDomains.includes(subdomain);
    };

    /**
     * Helper to get the logout redirect uri
     */
    private getLogoutRedirectUri(): string {
        let subdomain = window.location.host.split(".")[0];

        //if this is not a known subdomain, do not redirect them to origin
        //as it will simply log them back in (the subdomain will be passed
        //back in on the login method and they will be logged back in automatically.
        if (!this._subdomainIsKnown(subdomain))
            return `${window.location.origin}/logout` || "";

        return window.location.origin || "";
    }

    private showRelogPopup = async() : Promise<boolean> => {
        return confirm.show({ 
            text: "You've been inactive for a while. For security, we closed the session and logged you out. Sign in to continue.", 
            title: "Your session expired", 
            yesText: "Open sign-in window", 
            yesColor: "#2178B0",
            yesIcon: 1,
            noText: "Close" 
        })
    }
};

export default MsalAuthModule;

export const msalConfig = {
    auth: {
        authority: `https://${process.env.REACT_APP_B2CTENANTNAME}.b2clogin.com/${process.env.REACT_APP_B2CTENANTNAME}.onmicrosoft.com/${process.env.REACT_APP_B2CFLOWNAME}`,
        clientId: `${process.env.REACT_APP_B2CADMINPORTALCLIENTID}`,
        redirectUri: (!!window ? window.location.origin : "") + "/auth",
        knownAuthorities: ["sparrowconnected.b2clogin.com"],
        postLogoutRedirectUri: (!!window ? window.location.origin : "") + "/",
        navigateToLoginRequestUrl: false
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    // To Enable MSAL Logging to the console, uncomment the following
    // system: {
    //     loggerOptions: {
    //         loggerCallback: (level, message, containsPii) => {
    //             if (containsPii) {
    //                 return;
    //             }
    //             switch (level) {
    //                 case LogLevel.Error:
    //                     console.error(message);
    //                     return;
    //                 case LogLevel.Info:
    //                     console.info(message);
    //                     return;
    //                 case LogLevel.Verbose:
    //                     console.debug(message);
    //                     return;
    //                 case LogLevel.Warning:
    //                     console.warn(message);
    //                     return;
    //             }
    //         }
    //     }
    // }
};


