import React from "react";

import { IContentBand, IContentBandConfig } from "../../models";
import { ContentBandLayoutPreview } from "./contentBandLayoutPreview";

import "../../styles/contentBandsMobilePreview.sass";

export interface IContentMobilePreviewProps {
    contentBands: IContentBand[];
    activeLcid: string;
    isLoading?: boolean;
    config: IContentBandConfig;
}

export const ContentBandsMobilePreview: React.FunctionComponent<IContentMobilePreviewProps> = ({
    isLoading,
    contentBands,
    activeLcid,
    config,
}) => {
    const getTitle = (item: IContentBand): string => {
        let result = "";
        if (item.headers) {
            result = item.headers[activeLcid].header;
        }

        return result;
    };

    return (
        <div id="cb-mobile-preview">
            {contentBands.map((item: IContentBand, idx: number) => (
                <React.Fragment key={`cb-mobile-preview-${idx}`}>
                    <p
                        style={{
                            fontSize: 16,
                            fontWeight: 500,
                            margin: "16px 0px 5px 0px",
                            paddingLeft: 7,
                            color: config.contentBandsHeaderTextColor.hexCode,
                        }}
                    >
                        {getTitle(item)}
                    </p>
                    {!isLoading && (
                        <ContentBandLayoutPreview
                            idx={idx}
                            contentBand={item}
                            activeLcid={activeLcid}
                            containerClassName="mobile-preview"
                        />
                    )}
                </React.Fragment>
            ))}
        </div>
    );
};
