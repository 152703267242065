import * as React from "react";
import "./avatar.sass";

interface ComponentProps {
    firstname: string;
    lastname: string;
    color: string;
}

export default class Avatar extends React.Component<ComponentProps, {}> {

    public shouldComponentUpdate(nextProps: ComponentProps, nextState) {
        return nextProps.firstname !== this.props.firstname || nextProps.color !== this.props.color
    }

    public render() {
        let firstLetter = this.props.firstname?.substr(0, 1); 
        let lastletter = this.props.lastname?.substr(0, 1);

        return (
            <div className="avatar-icon-container" style={{"backgroundColor": this.props.color}}>
                <span className="avatar-letter">{firstLetter + lastletter}</span>
            </div>
        );
    }
}
