import { ApiRequest } from './network';
import { SparrowApi } from 'api/network/adapters/SparrowApi';
import { AxiosPromise } from 'axios';
import { IReadabilitySettings, IReadabilitySettingsOptionModel } from 'modules/settings';
import { IGenerateContentRequest, IGenerateContentResponse, ISuggestion } from 'modules/smartContent/models';

interface ITranslateRequest {
    toLcid: string;
    fromLcid: string;
    text: string;
}

interface ITranslatorTextResult {
    Translations: ITranslation[];
}

interface ITranslation {
    text: string;
}

interface ICognitiveServicesApi {
    getSuggestions(): ApiRequest<ISuggestion[]>;
    getReadabilityOptions(): ApiRequest<IReadabilitySettingsOptionModel>;
    getReadabilitySettings(): ApiRequest<IReadabilitySettings>;
    generateContent(req: IGenerateContentRequest): AxiosPromise<IGenerateContentResponse>;
    saveReadabilitySettings(settings: IReadabilitySettings): ApiRequest<IReadabilitySettings>;
    translateText(req: ITranslateRequest): AxiosPromise<ITranslatorTextResult>;
}

export class CognitiveServicesApi implements ICognitiveServicesApi {
    constructor(private _sparrowApi: SparrowApi) { }

    /**
     * Generate content via GPT
     * @param req
     * @returns ApiReqeust<IGenerateContentResponse>
     */
    public generateContent = (req: IGenerateContentRequest): AxiosPromise<IGenerateContentResponse> => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().post("{tenant}/adminapi/v1/cognitiveservices/generateContent?platform=web", req)
        )
        .catch((err) => {
            throw err;
        });
    }

    /**
     * Get tenant suggestions for prompts to GPT
     * @returns ApiRequest<ISuggestion[]>
     */
    public getSuggestions = (): ApiRequest<ISuggestion[]> => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().get(`{tenant}/adminapi/v1/cognitiveservices/suggestions`)
        );
    }

    /**
     * Get tenant's current readability settings
     * @returns ApiRequest<IReadabilitySettings>
     */
    public getReadabilitySettings(): ApiRequest<IReadabilitySettings> {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().get(`{tenant}/adminapi/v1/cognitiveservices/readabilitysettings`)
        );
    }

    /**
     * Get the options for readability settings
     * @returns ApiRequest<IReadabilitySettingsOptionModel>
     */
    public getReadabilityOptions(): ApiRequest<IReadabilitySettingsOptionModel> {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().get(`{tenant}/adminapi/v1/cognitiveservices/readabilitysettings/options`)
        );
    }

    /**
     * Save readability settings
     * @param settings
     * @returns ApiRequest<IReadabilitySettings>
     */
    public saveReadabilitySettings(settings: IReadabilitySettings): ApiRequest<IReadabilitySettings> {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().post(`{tenant}/adminapi/v1/cognitiveservices/readabilitysettings`, settings)
        );
    }

    public translateText(translateReq: ITranslateRequest): AxiosPromise<ITranslatorTextResult> {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().post(`{tenant}/adminapi/v1/cognitiveservices/translate`, translateReq)
        ).catch((err) => {
            throw err;
        });
    }
}
