import { GlobalApplicationState } from "globalApplicationState";
import { connect, ConnectedProps } from "react-redux";
import { IntlShape, injectIntl } from "react-intl";
import { Button, Menu, MenuItem } from "@mui/material";
import * as React from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Loading from "modules/common/components/loading";

interface ComponentProps { 
    downloadAllImage: () => void;
    downloadAllExcel: () => void;
    loadingImage?: boolean;
    loadingExcel?: boolean;
    loadingAll?: boolean;
    intl: IntlShape;
}

const DownloadReportsDropdown: React.FunctionComponent<PropsWithRedux> = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const showLoadingSpinner = () => {
        return (
            <div style={{marginLeft: "auto", position: "relative", top: "-10px", height: "20px"}}>
                <Loading style={{width: "18px", height: "18px"}}/>
            </div>
        )
    }

    return (
        <div>
            <Button
                id="report-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                variant="contained"
                color="primary"
                endIcon={<ArrowDropDownIcon/>}
            >
                Download Reports
            </Button>
            <Menu
                id="report-menu"
                anchorEl={anchorEl}
                open={open}
                onClick={handleClose}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'report-button',
                }}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                PaperProps={{
                    style: {
                      transform: 'translateY(6px)',
                    }
                }}
            >
                <MenuItem style={{width: "250px"}} onClick={props.downloadAllImage} disabled={props.loadingImage}>
                    {props.intl.formatMessage({ id: 'insights.downloadAsImg', defaultMessage: "as Images" })}
                    { props.loadingImage && showLoadingSpinner() }
                </MenuItem>
                <MenuItem onClick={props.downloadAllExcel} disabled={props.loadingExcel}>
                    {props.intl.formatMessage({ id: 'insights.downloadAsExcel', defaultMessage: "as Excel" })}
                    { props.loadingExcel && showLoadingSpinner() }
                </MenuItem>
            </Menu>
        </div>
    )
}


const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
    }), 
    {
    }
);

type PropsWithRedux = ConnectedProps<typeof connector>;

export default injectIntl(connector(DownloadReportsDropdown));