import { Dispatch, SetStateAction, useEffect, useState } from "react";

/**
 * Encapsulate accordion logic
 * @returns {
 *  accordionOpenStates: boolean[];
 *  expandAllLabel: string;
 *  setExpandAllLabel: Dispatch<SetStateAction<string>>;
 *  onChangeAccordionOpen: (expanded: boolean, key: ACCORDIONS) => void;
 *  onExpandAll: () => void;
 *  onCollapseAll: () => void;
 *  onExpandOrCollapseAll: () => void;
 * }
 */

const COLLAPSE_ALL: string = "COLLAPSE ALL";
const EXPAND_ALL: string = "EXPAND ALL";

export type AccordionStatus = {
    accordionOpenStates: boolean[];
    expandAllLabel: string;
    setExpandAllLabel: Dispatch<SetStateAction<string>>;
    onChangeAccordionOpen: (expanded: boolean, key: number) => void;
    onExpandAll: () => void;
    onCollapseAll: () => void;
    onExpandOrCollapseAll: () => void;
}

const useAccordion = (accordionCount: number, defaultValue: boolean = false): AccordionStatus => {
    const [accordionOpenStates, setAccordionOpenStates] = useState<boolean[]>(Array.from({ length: accordionCount }, i => i = defaultValue));
    const [expandAllLabel, setExpandAllLabel] = useState<string>(EXPAND_ALL);

    useEffect(() => {
        if (accordionOpenStates.every((item) => item)) setExpandAllLabel(COLLAPSE_ALL); // all are open
        else if (accordionOpenStates.every((item) => !item)) setExpandAllLabel(EXPAND_ALL); // all are close

    }, [accordionOpenStates, setExpandAllLabel]);

    useEffect(() => {
        setAccordionOpenStates(Array.from({ length: accordionCount }, i => i = defaultValue));
    }, [accordionCount, defaultValue]);

    const onChangeAccordionOpen = (expanded: boolean, key: number) => {
        let newOpenStates = [...accordionOpenStates];
        newOpenStates[key] = expanded;

        setAccordionOpenStates(newOpenStates);
    }

    const onExpandAll = () => {
        setAccordionOpenStates(accordionOpenStates.fill(true));
        setExpandAllLabel(COLLAPSE_ALL);
    }

    const onCollapseAll = () => {
        setAccordionOpenStates(accordionOpenStates.fill(false));
        setExpandAllLabel(EXPAND_ALL);
    }

    const onExpandOrCollapseAll = () => {
        if (expandAllLabel === EXPAND_ALL) onExpandAll();
        else onCollapseAll();
    }

    return {
        accordionOpenStates,
        expandAllLabel,
        setExpandAllLabel,
        onChangeAccordionOpen,
        onExpandAll,
        onCollapseAll,
        onExpandOrCollapseAll
    }
}

export default useAccordion;
