import React, { useRef, useState } from "react";

import { Button, Checkbox, Fab, FormControlLabel, SvgIcon } from "@mui/material";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import Callout from "modules/common/components/callout";

interface ISmartContentPaperFabProps {
    onClick: () => void;
    fabStyle?: React.CSSProperties;
    fabClassName?: string;
    calloutOpen?: boolean;
    onClose?: (skipCallouts: boolean) => void;
    callout: boolean; // enable/disable showing callout at all
    indicator?: boolean;
    setCalloutOpen: (value: boolean) => void;
}

const SmartContentPaperFab: React.FunctionComponent<ISmartContentPaperFabProps> = ({
    onClick,
    fabStyle,
    fabClassName,
    calloutOpen,
    onClose,
    callout = true,
    indicator = false,
    setCalloutOpen
}) => {
    const anchorEl = useRef<HTMLButtonElement | null>(null);

    const [skipCallouts, setSkipCallouts] = useState<boolean>(!callout);

    return (
        <>
            <Fab ref={(button: HTMLButtonElement | null) => anchorEl.current = button} id="smart-post-authoring-fab" className={fabClassName} onClick={onClick} style={{
                backgroundColor: "#3b78ab",
                ...fabStyle,
            }}>
                <SvgIcon viewBox="0 0 30 30" style={{ fontSize: 30 }}>
                    {/* public/images/magic_button.svg */}
                    <g transform="matrix(1 0 0 1 -1384 -897 )">
                        <path d="M 22.897727272727273 6.1499999999999995  L 21.62727272727273 9.15  L 20.356818181818184 6.1499999999999995  L 17.284090909090914 4.77  L 20.356818181818184 3.4199999999999995  L 21.62727272727273 0.5699999999999995  L 22.897727272727273 3.4199999999999995  L 25.970454545454547 4.77  L 22.897727272727273 6.1499999999999995  Z M 22.897727272727273 24.12  L 21.62727272727273 27  L 20.356818181818184 24.12  L 17.284090909090914 22.77  L 20.356818181818184 21.42  L 21.62727272727273 18.39  L 22.897727272727273 21.42  L 25.970454545454547 22.77  L 22.897727272727273 24.12  Z M 11.404545454545453 16.47  L 8.656818181818183 22.379999999999995  L 5.938636363636365 16.469999999999995  L 0 13.77  L 5.938636363636364 11.069999999999999  L 8.656818181818181 5.189999999999999  L 11.404545454545453 11.069999999999999  L 17.313636363636363 13.77  L 11.404545454545453 16.47  Z M 7.268181818181821 15.059999999999999  L 8.656818181818183 17.939999999999998  L 10.075000000000001 15.059999999999999  L 12.970454545454547 13.77  L 10.075000000000003 12.48  L 8.656818181818185 9.6  L 7.268181818181822 12.48  L 4.343181818181821 13.77  L 7.268181818181821 15.059999999999999  Z " fillRule="nonzero" fill="#ffffff" stroke="none" transform="matrix(1 0 0 1 1384 897 )" />
                    </g>
                </SvgIcon>
                {indicator && <FiberManualRecordIcon style={{ fontSize: 18, position: "absolute", bottom: 43, left: 35 }} htmlColor="#b72026" />}
            </Fab>
            {callout && calloutOpen &&
                <Callout
                    contentClassName="smart-content-fab-callout"
                    arrowPosition="right"
                    anchorEl={anchorEl.current}
                    open={calloutOpen}
                    setOpen={setCalloutOpen}
                    popoverProps={{
                        anchorOrigin: {
                            vertical: 700,
                            horizontal: -200,
                        }
                    }}
                >
                    <p>Writer’s block? Get some ideas for a new post using our new Smart Content AI tool!</p>
                    <FormControlLabel
                        style={{ fontSize: 14 }}
                        classes={{
                            label: "font-14"
                        }}
                        label="Skip all Smart Content help popups"
                        control={<Checkbox onChange={(_, checked) => setSkipCallouts(checked)} checked={skipCallouts} size="small" color="primary" />}
                    />
                    <Button
                        disableRipple
                        color="primary"
                        style={{
                            textDecoration: "underline",
                            fontSize: 14
                        }}
                        variant="text"
                        onClick={() => {
                            if (onClose)
                                onClose(skipCallouts);
                        }}
                    >
                        Close
                    </Button>
                </Callout>}
        </>
    );
}

export { SmartContentPaperFab };
