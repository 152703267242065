import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import copy from "copy-to-clipboard";

import { SmartContentPaper } from "../../../../smartContent/smartContentPaper";
import { POST_SMART_CONTENT_TILE_BUTTON_IDX, PostSmartContentHome } from "./postSmartContentHome";
import { TranslatableContent } from "modules/common/components/authoring/models";
import { IBaseSmartContentPageProps, IGPTChoice, IGenerateContentRequest, IGenerateContentResponse, ISuggestion } from "modules/smartContent/models";
import { cognitiveServicesApi } from "api/instances";
import useIsMounted from "modules/common/hooks/useIsMounted";
import { PostSmartContentKeywords } from "./postSmartContentKeywords";
import { useReadabilitySettings } from "modules/common/hooks/data/useReadabilitySettings";
import { SmartContentTitles } from "modules/smartContent/smartContentTitles";
import SnackbarWrapper from "modules/common/components/snackbars/snackbarWrapper";
import { PostSmartContentIdeas } from "./postSmartContentIdeas";
import { SmartContentSummaries } from "modules/smartContent/smartContentSummaries";
import { SmartContentDraftPage } from "modules/smartContent/smartContentDraftPage";
import ConfirmDialog from "modules/common/components/dialogs/confirmDialog";
import SuccessSnackbar from "modules/common/components/snackbars/successSnackbar";

import "../styles/postSmartContent.sass";
import { ReadabilitySettingsDialog } from "modules/settings/components/readabilitySettingsDialog";
import usePrevious from "modules/common/hooks/usePrevious";

interface IPostSmartContentPanelProps {
    open: boolean;
    activeTranslation?: TranslatableContent;
    indicatorOn?: boolean;
    onClose: () => void;
    onChangeTitle: (newTitle: string) => void;
    onChangeBody: (newBody: string) => void;
    onChangeSummary: (newSummary: string) => void;
}

export enum POST_SMART_CONTENT_PAGES {
    HOME,
    KEYWORDS,
    IDEAS_LIST,
    TITLES_LIST,
    SUMMARIES_LIST,
    BODY_LIST,
}

const NO_PREFERENCE = "no preference";

/**
 * Post smart content
 * - TODO: depending on how smart content looks for events move this to a common place and remove post specific language
 */
const PostSmartContentPanel: React.FunctionComponent<IPostSmartContentPanelProps> = ({
    open,
    activeTranslation,
    indicatorOn = false,
    onClose,
    onChangeBody,
    onChangeSummary,
    onChangeTitle
}) => {
    const hasBeenOpen = useRef<boolean>(false);
    const isMounted = useIsMounted();
    const {
        options: readabilityOptions,
        settings: readabilitySettings,
        onChange: onChangeReadabilitySettings,
        resetToDefault: resetReadabilitySettings,
        defaults: defaultReadabilitySettings
    } = useReadabilitySettings();

    const [fetching, setFetching] = useState<boolean>(false);
    const [fetchType, setFetchType] = useState<"full" | "more" | undefined>();
    const [currentPage, setCurrentPage] = useState<POST_SMART_CONTENT_PAGES>(POST_SMART_CONTENT_PAGES.HOME);
    const [highlights, setHighlights] = useState<POST_SMART_CONTENT_TILE_BUTTON_IDX[]>([]); // the buttons to highlight
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [successMessage, setSuccessMessage] = useState<string | undefined>();
    const [confirmApplyBodyDraft, setConfirmApplyBodyDraft] = useState<boolean>(false);
    const [confirmClearBodySuggestions, setConfirmClearBodySuggestions] = useState<boolean>(false);
    const [readabilitySettingsOpen, setReadabilitySettingsOpen] = useState<boolean>(false);
    const previousPage = usePrevious<POST_SMART_CONTENT_PAGES>(currentPage);

    // smart content states
    const buttonUsedToEnterFlow = useRef<POST_SMART_CONTENT_TILE_BUTTON_IDX | undefined>();
    const [keywords, setKeywords] = useState<string | undefined>();
    const [suggestions, setSuggestions] = useState<ISuggestion[]>([]);
    const [ideas, setIdeas] = useState<IGPTChoice[]>([]);
    const [titles, setTitles] = useState<IGPTChoice[]>([]);
    const [bodyDrafts, setBodyDrafts] = useState<IGPTChoice[]>([]);
    const [summaries, setSummaries] = useState<IGPTChoice[]>([]);
    const [draftIdx, setDraftIdx] = useState<number>(0);
    const sessionId = useRef<string | undefined>();

    const transitionButtonsRef = useRef<boolean>(true);

    const currentDraft: IGPTChoice | null = useMemo(() => draftIdx >= 0 && draftIdx < bodyDrafts.length
        ? bodyDrafts[draftIdx]
        : null,
        [bodyDrafts, draftIdx]);

    useEffect(() => {
        let transitionTimeout: NodeJS.Timeout | undefined;

        if (open) {
            transitionTimeout = setTimeout(() => {
                transitionButtonsRef.current = false;
            }, 1600); // 1600 ms is how long button transitions take to complete
        }
        return () => {
            if (transitionTimeout)
                clearTimeout(transitionTimeout);
        }
    }, [open]);

    // handle fetch keyword suggestions
    // only fetch if no keywords
    useEffect(() => {
        const fetchSuggestions = async () => {
            setFetching(true);
            let newSuggestions: ISuggestion[] = [];

            try {
                newSuggestions = await cognitiveServicesApi.getSuggestions();
            } catch (err) {
                setErrorMessage("Something went wrong. Please try again.");
            } finally {
                if (isMounted()) {
                    setFetching(false);
                    setSuggestions(newSuggestions);
                }
            }
        }

        if (!keywords)
            fetchSuggestions();
    }, [keywords, isMounted]);

    const { title, description, body } = activeTranslation || {};

    useEffect(() => {
        let newHighlights: POST_SMART_CONTENT_TILE_BUTTON_IDX[] = [];

        // first time opening
        if (open && !hasBeenOpen.current) {
            // no content, highlight generate ideas
            if (!title && !description && !body) {
                newHighlights = [POST_SMART_CONTENT_TILE_BUTTON_IDX.GENERATE_IDEAS];

                // red dot indicator on, then highlight all empty field generate buttons
            } else if (indicatorOn) {
                if (!title) newHighlights = [...newHighlights, POST_SMART_CONTENT_TILE_BUTTON_IDX.GENERATE_TITLES];
                if (!description) newHighlights = [...newHighlights, POST_SMART_CONTENT_TILE_BUTTON_IDX.GENERATE_SUMMARIES];
                if (!body) newHighlights = [...newHighlights, POST_SMART_CONTENT_TILE_BUTTON_IDX.GENERATE_BODY_TEXT];
            }
        }

        setHighlights(newHighlights);

        if (open)
            hasBeenOpen.current = true;
    }, [title, description, body, hasBeenOpen, open, indicatorOn]);

    const suggestedTextExists = useCallback((): boolean => {
        return titles.length > 0 || ideas.length > 0 || summaries.length > 0 || bodyDrafts.length > 0 || (!!keywords && keywords.length > 0)
    }, [titles, bodyDrafts, keywords, summaries, ideas]);


    /**
     * Handle page change
     */
    const onChangePage = (nextPage: POST_SMART_CONTENT_PAGES) => {
        setCurrentPage(nextPage);
    }

    const onClickTileButton = async (buttonClicked: POST_SMART_CONTENT_TILE_BUTTON_IDX, nextPage: POST_SMART_CONTENT_PAGES) => {
        buttonUsedToEnterFlow.current = buttonClicked;
        onChangePage(nextPage);

        let shouldFetch = false;
        switch (nextPage) {
            case POST_SMART_CONTENT_PAGES.IDEAS_LIST:
                shouldFetch = ideas.length === 0;
                break;
            case POST_SMART_CONTENT_PAGES.TITLES_LIST:
                shouldFetch = titles.length === 0;
                break;
            case POST_SMART_CONTENT_PAGES.SUMMARIES_LIST:
                shouldFetch = summaries.length === 0;
                break;
            case POST_SMART_CONTENT_PAGES.BODY_LIST:
                shouldFetch = bodyDrafts.length === 0;
                break;
            case POST_SMART_CONTENT_PAGES.HOME:
            case POST_SMART_CONTENT_PAGES.KEYWORDS:
            default:
                break;
        }

        // only generate more if we haven't generated once yet
        if (shouldFetch)
            await onGenerate(nextPage);
    }

    /**
     * Helper to get the base request to send to generate content endpoint
     * @param promptType
     */
    const getBaseGenerateContentRequest = (promptType: "idea" | "summarize" | "title" | "body", text: string = ""): IGenerateContentRequest => {
        let result: IGenerateContentRequest = {
            sessionId: sessionId.current,
            contentType: "post",
            promptType,
            text: text || keywords,
        };

        // only send readability settings for bodies
        if (promptType === "body")
            result = {
                ...result,
                readability: readabilitySettings?.readability.toLowerCase() === NO_PREFERENCE ? undefined : readabilitySettings?.readability,
                tone: readabilitySettings?.tone.toLowerCase() === NO_PREFERENCE ? undefined : readabilitySettings?.tone,
                personalism: readabilitySettings?.personalism === NO_PREFERENCE ? undefined : readabilitySettings?.personalism,
                sentiment: readabilitySettings?.sentiment === NO_PREFERENCE ? undefined : readabilitySettings?.sentiment,
                length: readabilitySettings?.length === NO_PREFERENCE ? undefined : readabilitySettings?.length
            };

        return result;
    }


    /**
     * Make api request to make content of promptType
     * @param promptType
     */
    const generateContent = async (promptType: "idea" | "summarize" | "title" | "body", choices: number = 5, text: string = ""): Promise<IGenerateContentResponse> => {
        const req: IGenerateContentRequest = {
            ...getBaseGenerateContentRequest(promptType, text),
            numberOfContentToGenerate: choices,
        };

        const { data } = await cognitiveServicesApi.generateContent(req);
        sessionId.current = data.sessionId;

        return data;
    }

    const onGenerateIdeas = async (concatenate?: boolean): Promise<void> => {
        setFetching(true);
        let newIdeas = concatenate ? [...ideas] : [];

        try {
            const res = await generateContent("idea");

            // reset the indexs of choices so each is unique
            newIdeas = [...newIdeas, ...res.choices]
                .map((choice: IGPTChoice, idx: number) => ({ ...choice, index: idx }));

            setIdeas(newIdeas);
        } catch (err) {
            setErrorMessage("Something went wrong. Please try again.");
        } finally {
            if (isMounted()) {
                setIdeas(newIdeas);
                setFetching(false);
            }
        }
    }

    /**
     * Fetch some more bodies
     * @param concatenate - flag indicating whether or not to clear bodies first
     * @returns
     */
    const onGenerateBodyText = async (concatenate?: boolean, text: string = ""): Promise<void> => {
        setFetching(true);
        let newBodies: IGPTChoice[] = concatenate ? [...bodyDrafts] : [];

        try {
            const res = await generateContent("body", 1, text);

            // reset the indexs of choices so each is unique
            newBodies = [...newBodies, ...res.choices]
                .map((choice: IGPTChoice, idx: number) => ({ ...choice, index: idx }));

            setBodyDrafts(newBodies);
        } catch (err) {
            setErrorMessage("Something went wrong. Please try again.");
        } finally {
            if (isMounted()) {
                setBodyDrafts(newBodies);
                setFetching(false);
            }
        }
    }

    /**
     * Fetch some more summaries
     * @param concatenate - flag indicating whether or not to clear summaries first
     * @returns
     */
    const onGenerateSummaries = async (concatenate?: boolean): Promise<void> => {
        setFetching(true);
        let newSummaries: IGPTChoice[] = concatenate ? [...summaries] : [];

        try {
            const res = await generateContent("summarize");

            // reset the indexs of choices so each is unique
            newSummaries = [...newSummaries, ...res.choices]
                .map((choice: IGPTChoice, idx: number) => ({ ...choice, index: idx }));

            setSummaries(newSummaries);
        } catch (err) {
            setErrorMessage("Something went wrong. Please try again.");
        } finally {
            if (isMounted()) {
                setFetching(false);
                setSummaries(newSummaries);
            }
        }
    }

    /**
     * Fetch some more titles
     * @param concatenate - flag indicating whether or not to clear titles first
     * @returns
     */
    const onGenerateTitles = async (concatenate?: boolean): Promise<void> => {
        setFetching(true);
        let newTitles: IGPTChoice[] = concatenate ? [...titles] : [];

        try {
            const res = await generateContent("title");

            // reset the indexs of choices so each is unique
            newTitles = [...newTitles, ...res.choices]
                .map((choice: IGPTChoice, idx: number) => ({ ...choice, index: idx }));

            setTitles(newTitles);
        } catch (err) {
            setErrorMessage("Something went wrong. Please try again.");
        } finally {
            if (isMounted()) {
                setTitles(newTitles);
                setFetching(false);
            }
        }
    }

    /**
     * Generate more suggestions of promptTypes
     * @param promptType
     */
    const onGenerateMore = async (promptType: "idea" | "summarize" | "title" | "body") => {
        switch (promptType) {
            case "idea":
                await onGenerateIdeas(true);
                break;
            case "summarize":
                await onGenerateSummaries(true);
                break;
            case "title":
                await onGenerateTitles(true);
                break;
            case "body":
                let nextIndex = bodyDrafts.length; // set current draft to one about to be generated
                await onGenerateBodyText(true);
                setDraftIdx(nextIndex);
                break;
        }
    }

    /**
     * Handle generate click from keywords page
     * - decide which type of content to generate
     *   - clear its suggestions
     *   - go its list page
     *   - generate more suggestions
     * @param nextPage - override next page calculation with this param
     */
    const onGenerate = async (nextPage?: POST_SMART_CONTENT_PAGES): Promise<void> => {
        let generate: (() => Promise<void>) | undefined = undefined;

        switch (buttonUsedToEnterFlow.current) {
            case POST_SMART_CONTENT_TILE_BUTTON_IDX.GENERATE_BODY_TEXT:
                //  set current draft to the one about to be generated
                let nextIndex = bodyDrafts.length;

                nextPage = nextPage || POST_SMART_CONTENT_PAGES.BODY_LIST;

                generate = async () => {
                    await onGenerateBodyText(true);
                    setDraftIdx(nextIndex);
                }
                break;
            case POST_SMART_CONTENT_TILE_BUTTON_IDX.GENERATE_IDEAS:
                onClearIdeaSuggestions();
                nextPage = nextPage || POST_SMART_CONTENT_PAGES.IDEAS_LIST;
                generate = onGenerateIdeas;
                break;
            case POST_SMART_CONTENT_TILE_BUTTON_IDX.GENERATE_SUMMARIES:
                onClearSummarySuggestions();
                nextPage = nextPage || POST_SMART_CONTENT_PAGES.SUMMARIES_LIST;
                generate = onGenerateSummaries;
                break;
            case POST_SMART_CONTENT_TILE_BUTTON_IDX.GENERATE_TITLES:
                onClearTitleSuggestions();
                nextPage = nextPage || POST_SMART_CONTENT_PAGES.TITLES_LIST;
                generate = onGenerateTitles;
                break;
            default:
                break;
        }

        if (nextPage && generate) {
            onChangePage(nextPage);
            await generate();
        }
    }

    const getBasePageProps = (backTo: POST_SMART_CONTENT_PAGES = POST_SMART_CONTENT_PAGES.HOME): IBaseSmartContentPageProps => ({
        onBack: () => onChangePage(backTo),
        onClose
    });

    /**
     * Clears all suggested texts
     */
    const onClearSuggestedText = () => {
        setTitles([]);
        setIdeas([]);
        setSummaries([]);
        setBodyDrafts([]);
        setKeywords(undefined);

        onChangePage(POST_SMART_CONTENT_PAGES.KEYWORDS);
        setSuccessMessage("All suggested text in Smart Content were successfully cleared.");
    }

    /**
     * Clear title suggestions
     */
    const onClearTitleSuggestions = () => {
        setTitles([]);
        onChangePage(POST_SMART_CONTENT_PAGES.KEYWORDS);
    }

    /**
     * Clear idea suggestions
     */
    const onClearIdeaSuggestions = () => {
        setIdeas([]);
        onChangePage(POST_SMART_CONTENT_PAGES.KEYWORDS);
    }

    /**
     * Clear summary suggestions
     */
    const onClearSummarySuggestions = () => {
        setSummaries([]);
        onChangePage(POST_SMART_CONTENT_PAGES.KEYWORDS);
    }

    /**
     * Clear body suggestions
     */
    const onClearBodySuggestionsConfirmed = () => {
        setBodyDrafts([]);
        setDraftIdx(0);
        onChangePage(POST_SMART_CONTENT_PAGES.KEYWORDS);
        setSuccessMessage("All suggested bodies in Smart Content were successfully cleared.");
    }

    /**
     * Handle clear body suggestions with confirm dialog
     */
    const onClearBodySuggestions = () => {
        setConfirmClearBodySuggestions(true);
    }

    /**
     * Make changes to current body draft text
     * @param newText
     */
    const onChangeBodyDraft = (newText: string) => {
        setBodyDrafts(bodyDrafts.map(
            (draft: IGPTChoice, idx: number) => idx === draftIdx ? { ...draft, text: newText } : { ...draft }
        ));
    }

    const onChangeDraftIdx = (newIdx: number) => {
        setDraftIdx(newIdx);
    }

    /**
     * Handle "GENERATE POST" click
     * - generate body from idea
     * - set new body as current draft
     * - go to body list page
     * @param idea
     */
    const onGeneratePost = async (idea: IGPTChoice) => {
        setFetchType("full");
        let newIdx = bodyDrafts.length;

        await onGenerateBodyText(true, idea.message.content);

        setDraftIdx(newIdx);

        onChangePage(POST_SMART_CONTENT_PAGES.BODY_LIST);
        setFetchType(undefined);
    }

    /**
     * Handle applying body draft
     * - check whether body has inline images or embedded media, if so show confirm dialog, else apply
     */
    const onApplyBodyDraft = () => {
        if (!currentDraft) return;

        let needToConfirm = false;

        if (body) {
            let temp = document.createElement("div");
            temp.innerHTML = body;

            needToConfirm = Boolean(temp.querySelector("img") || temp.querySelector("iframe"));
        }

        if (needToConfirm)
            setConfirmApplyBodyDraft(true);
        else
            onApplyBodyDraftConfirmed();
    }

    /**
     * Handle body draft apply confirmed
     * - update post
     */
    const onApplyBodyDraftConfirmed = () => {
        setConfirmApplyBodyDraft(false);

        // gpt will give us new line characters (they actually give use \n\r which is windows for line break)
        // since tinymce is an html editor we need to convert to br tags
        // first, strip the \r, then replace the \n with <br/>
        let { message } = { ...currentDraft };
        let newText = message?.content;

        newText = newText?.replaceAll("\r", "");
        newText = newText?.replaceAll("\n", "<br/>");

        if (newText) {
            onChangeBody(newText);
        }
    }

    /**
     * Helper to get the labels for the keywords page (these change depending on the button used to enter the smart content flow)
     */
    const getKeywordsPageLabels = (): { generateLabel: string | undefined, headerTitle: string | undefined } => {
        let result = {} as { generateLabel: string | undefined, headerTitle: string | undefined };

        switch (buttonUsedToEnterFlow.current) {
            case POST_SMART_CONTENT_TILE_BUTTON_IDX.GENERATE_BODY_TEXT:
                result = { generateLabel: "GENERATE BODY TEXT", headerTitle: "Suggested Body Text" };
                break;
            case POST_SMART_CONTENT_TILE_BUTTON_IDX.GENERATE_IDEAS:
                result = { generateLabel: "GENERATE IDEAS", headerTitle: "Suggested Ideas" };
                break;
            case POST_SMART_CONTENT_TILE_BUTTON_IDX.GENERATE_SUMMARIES:
                result = { generateLabel: "GENERATE SUMMARIES", headerTitle: "Suggested Summaries" };
                break;
            case POST_SMART_CONTENT_TILE_BUTTON_IDX.GENERATE_TITLES:
                result = { generateLabel: "GENERATE TITLES", headerTitle: "Suggested Titles" };
                break;
            default:
                break;
        }

        return result;
    }

    const getPage = (): JSX.Element => {
        let page = <></>;

        switch (currentPage) {
            case POST_SMART_CONTENT_PAGES.HOME:
                page = <PostSmartContentHome
                    onOpenReadabilitySettings={() => setReadabilitySettingsOpen(true)}
                    onClose={onClose}
                    transition={transitionButtonsRef.current}
                    highlights={highlights}
                    keywords={keywords}
                    onClick={onClickTileButton}
                />;
                break;
            case POST_SMART_CONTENT_PAGES.KEYWORDS:
                page = <PostSmartContentKeywords
                    {...getBasePageProps()}
                    {...getKeywordsPageLabels()}
                    setCurrentPage={setCurrentPage}
                    previousPage={previousPage || POST_SMART_CONTENT_PAGES.HOME}
                    keywords={keywords || ""}
                    onChangeKeywords={(newKeywords: string) => setKeywords(newKeywords)}
                    suggestions={suggestions}
                    onGenerate={onGenerate}
                />;
                break;
            case POST_SMART_CONTENT_PAGES.IDEAS_LIST:
                page = <PostSmartContentIdeas
                    {...getBasePageProps()}
                    title="Suggested Ideas"
                    fetchType={fetchType}
                    ideas={ideas}
                    onGenerateMore={onGenerateMore}
                    onTrash={onClearIdeaSuggestions}
                    fetching={fetching}
                    onChangePage={onChangePage}
                    onApplyIdea={onGeneratePost}
                    promptType="idea"
                />;
                break;
            case POST_SMART_CONTENT_PAGES.TITLES_LIST:
                page = <SmartContentTitles
                    {...getBasePageProps()}
                    title="Suggested Titles"
                    titles={titles}
                    onGenerateMore={onGenerateMore}
                    onTrash={onClearTitleSuggestions}
                    fetching={fetching}
                    onApplyTitle={onChangeTitle}
                    promptType="title"
                    onChangePage={onChangePage}
                />;
                break;
            case POST_SMART_CONTENT_PAGES.SUMMARIES_LIST:
                page = <SmartContentSummaries
                    {...getBasePageProps()}
                    title="Suggested Summaries"
                    summaries={summaries}
                    onGenerateMore={onGenerateMore}
                    onTrash={onClearSummarySuggestions}
                    fetching={fetching}
                    onApply={onChangeSummary}
                    onChangePage={onChangePage}
                    promptType="summarize"
                />;
                break;
            case POST_SMART_CONTENT_PAGES.BODY_LIST:
                page = <SmartContentDraftPage
                    {...getBasePageProps()}
                    promptType="body"
                    disclaimerText="Applying suggested text will clear and replace any existing content in the body of your post."
                    title="Suggested Body Text"
                    drafts={bodyDrafts}
                    onApplyDraft={onApplyBodyDraft}
                    onGenerateMore={onGenerateMore}
                    onTrash={onClearBodySuggestions}
                    fetching={fetching}
                    onChangePage={onChangePage}
                    draftIdx={draftIdx}
                    onChangeDraft={onChangeBodyDraft}
                    onChangeDraftIdx={onChangeDraftIdx}
                    currentDraft={currentDraft}
                />;
                break;
            default:
                break;
        }

        return page;
    }

    return (
        <>
            <SmartContentPaper open={open} id="post-smart-content-panel">
                {getPage()}
            </SmartContentPaper>
            {open && <SnackbarWrapper
                id="smart-content-snackbar"
                style={{
                    backgroundColor: "#ffcccc",
                    color: "#333333"
                }}
                open={Boolean(errorMessage)}
                autoHideDuration={null}
                message={errorMessage}
                onClose={() => setErrorMessage(undefined)}
                icon={<InfoOutlinedIcon htmlColor="#bb2c31" style={{ marginRight: 10 }} />}
                severity="error"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            />}
            <ConfirmDialog
                title="Clear all suggested body text"
                confirmLabel="CLEAR ALL"
                denyLabel="CANCEL"
                open={confirmClearBodySuggestions}
                onConfirm={() => {
                    onClearBodySuggestionsConfirmed();
                    setConfirmClearBodySuggestions(false);
                    onChangePage(POST_SMART_CONTENT_PAGES.KEYWORDS);
                }}
                onDeny={() => setConfirmClearBodySuggestions(false)}
                onClose={() => setConfirmClearBodySuggestions(false)}
                confirmButtonProps={{ id: "post-smart-content-confirm-clear", color: "secondary", style: { backgroundColor: "#b72026" } }}
                denyButtonProps={{ id: "post-smart-content-deny-clear" }}
            >
                <div style={{ minWidth: 400 }}>
                    <div>
                        You are about to clear all Smart Content’s suggested bodies, including any revisions you've made on the drafts. You may not be able to retrieve the same responses again.
                    </div>
                    <br />
                    <div>Do you want to proceed?</div>
                </div>

            </ConfirmDialog>
            <ConfirmDialog
                title="Your current post body has inline media"
                confirmLabel="APPLY DRAFT"
                denyLabel="COPY TEXT ONLY"
                open={confirmApplyBodyDraft}
                onConfirm={onApplyBodyDraftConfirmed}
                onClose={() => setConfirmApplyBodyDraft(false)}
                onDeny={() => {
                    if (currentDraft)
                        copy(currentDraft.message.content, { format: "text/plain" });

                    setConfirmApplyBodyDraft(false);
                }}
            >
                <div style={{ minWidth: 400 }}>
                    <div>
                        Applying the suggested draft will clear all the text in your current post body, including the images or videos attached.
                    </div>
                    <br />
                    <div>Do you want to proceed?</div>
                </div>
            </ConfirmDialog>
            <SuccessSnackbar
                successMessage={successMessage || ""}
                clearSuccessMessage={() => setSuccessMessage("")}
            />
            <ReadabilitySettingsDialog
                open={readabilitySettingsOpen}
                onClose={() => setReadabilitySettingsOpen(false)}
                onChangeReadabilitySettings={onChangeReadabilitySettings}
                defaultReadabilitySettings={defaultReadabilitySettings}
                readabilityOptions={readabilityOptions}
                readabilitySettings={readabilitySettings}
                resetReadabilitySettings={resetReadabilitySettings}
                onClearAllSuggestedText={onClearSuggestedText}
                suggestedTextExists={suggestedTextExists()}
            />
        </>
    );
}

export { PostSmartContentPanel };
