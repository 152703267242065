import * as React from "react";

import { DocumentFileType } from "../../../models";


const FileName: React.FunctionComponent<ComponentProps> = props => {
  return (
    <div>
      <div>
        {props.revisions < 2
          ? "File name"
          : "Original file name"
        }
      </div>
      <div className="file-name">{props.fileName}.{props.fileType}</div>
    </div>
  );
}


interface ComponentProps {
  fileName: string;
  fileType: DocumentFileType;
  revisions: number;
}

export default FileName;