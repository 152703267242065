import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import * as audiencesActions from "modules/audiences/actionCreator";
import * as categoryTagsActions from "modules/categorytags/actionCreator";
import * as documentAction from "modules/documents/actionCreator";
import { GlobalApplicationState } from "globalApplicationState";
import moment from "moment";

import confirm from "utils/notyPopups";

import { Document, DocumentAvailability, DocumentProperties as IDocumentProperties } from "../../models";

import AccessibleAudiences from "./components/accessibleAudiences";
import Availability from "./components/availability";
import CategoryTags from "./components/categoryTags";
import FileName from "./components/fileName";
import Language from "./components/language";
import Description from "./components/description";
// import NotifiedAudiences from "./components/notifiedAudiences";
import Revision from "./components/revision";
import State from "./components/state";
import Title from "./components/title";

import "../../styles/documentCreation.sass";
import LoadingOverlay from "modules/common/components/loadingOverlay";
import { PickerLocalization } from "modules/common/components/pickerLocalization";


class DocumentProperties extends React.Component<PropsWithRedux, ComponentState> {
  constructor(props: PropsWithRedux) {
    super(props);
    this.state = {
        isFetching: false,
        categoryTagsTotal: []
    };
}

  componentDidMount() {
    moment.locale("en");

    if (!this.props.audiences.length)
      this.props.getAudiences();

    if (!this.props.categoryTags.length)
      this.props.getCategoryTags();
  }

  public render() {
    const { document, documentProperties } = this.props;

    if (!document.id)
      return (
        <div className="document-creation">
          <div className="no-details">No details to display</div>
        </div>
      );

    return (
      <PickerLocalization>
        
        <div className="document-creation">
          <div className="document-properties">
            <FileName fileName={document.fileName || ""} fileType={document.fileType || "pdf"} revisions={document.revisions || 0} />
            {!this.props.isUnpublished &&
              <Revision revisions={document.revisions || 0} />
            }
            <Title title={documentProperties.title || ""} onChange={this.onChangeTitle} />
            <Description description={documentProperties.description || ""} onChange={this.onChangeDescription} />
            <Language lcid={documentProperties.lcid || ""} onChange={this.onChangeLanguage} />
            <Availability availability={documentProperties.availability || "onlineOnly"} onChange={this.onChangeAvailability} />
            <State enabled={!!documentProperties.enabled} publishingTime={documentProperties.publishingTime || ""} isUnpublished={this.props.isUnpublished} onChangePublishingTime={this.onChangePublishingTime} onChangeState={this.onChangeState} />
            <CategoryTags tags={documentProperties.tags || []} onChange={this.onChangeCategoryTags} />
            <AccessibleAudiences accessibleAudiences={documentProperties.accessibleAudiences || []} onChange={this.onChangeAccessibleAudiences} />

            {/* <NotifiedAudiences accessibleAudiences={documentProperties.accessibleAudiences || []} notifiedAudiences={documentProperties.notifiedAudiences || []} onChange={this.onChangeNotifiedAudiences} /> */}
          </div>
        </div>
        { <LoadingOverlay show={this.state.isFetching}/> }
      </PickerLocalization>
      
    );
  }

  private onChange = (documentProperties: Partial<IDocumentProperties>) => {
    this.props.onChange(documentProperties);
  }

  private onChangeAccessibleAudiences = (accessibleAudiences: string[]) => {
    let notifiedAudiences = this.props.documentProperties.notifiedAudiences;
    if (!!notifiedAudiences && !!notifiedAudiences.length)
      notifiedAudiences = notifiedAudiences.filter((notifiedAudience) => accessibleAudiences.includes(notifiedAudience));

    this.onChange({ accessibleAudiences, notifiedAudiences });
  }

  private onChangeAvailability = async (availability: DocumentAvailability) => {
    if (availability === "attachmentOnly") {
      if (await confirm.show(({ text: "You are about to make this document available as an attachment only. This will hide the document in all document feeds and will make it accessible only if it is attached in a post, event or survey.<br /><br />Do you want to continue?", title: "Set as Attachment Only" })))
        this.onChange({ availability });
    } else {
      this.onChange({ availability });
    }
  }

  private onChangeCategoryTags = async (tags: string[]) => {

    if(tags.length > this.state.categoryTagsTotal.length) {
      this.setState({
        isFetching: true
      });

      await this.onChangeTagsHaveAudience(tags);

    }
    this.setState({
      isFetching: false,
      categoryTagsTotal: tags
    });
    this.onChange({ tags });
  }

  private onChangeTagsHaveAudience = async (tagsCategory: string[]) => {
    var audienceTags: string[] = [];
    var removeDuplicates: string[] = [];

    for(var i = 0; i < tagsCategory.length; i++) {
      var tag = await this.props.getCategoryTagsById(tagsCategory[i]);
      if(tag.defaultAudienceAccess.length >= 1) {
        audienceTags = audienceTags.concat(tag.defaultAudienceAccess);
      }
    }
    if(audienceTags.length > 1) {

    removeDuplicates = audienceTags.filter(function(elem, pos) {
      return audienceTags.indexOf(elem) === pos; });

    }

    this.onChangeAccessibleAudiences(removeDuplicates);
  }

  private onChangeDescription = (description: string) => {
    this.onChange({ description });
  }

  private onChangeLanguage = (lcid: string) => {
    this.onChange({ lcid });
  }

  private onChangeNotifiedAudiences = (notifiedAudiences: string[]) => {
    this.onChange({ notifiedAudiences });
  }

  private onChangePublishingTime = (publishingTime: string) => {
    this.onChange({ publishingTime });
  }

  private onChangeState = (enabled: boolean, publishingTime: string) => {
    this.onChange({ enabled, publishingTime });
  }

  private onChangeTitle = (title: string) => {
    this.onChange({ title });
  }
}

interface ComponentProps {
  document: Partial<Document>;
  documentProperties: Partial<IDocumentProperties>;
  isUnpublished?: boolean;
  onChange: (documentProperties: Partial<IDocumentProperties>) => void;
}

interface ComponentState {
  isFetching: boolean,
  categoryTagsTotal: string[]
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    audiences: state.audiences.audiences,
    categoryTags: state.categoryTags.userCategoryTags
  }),
  {
    getAudiences: audiencesActions.getAudiences,
    getCategoryTags: categoryTagsActions.getUserCategoryTags,
    getCategoryTagsById: documentAction.getCategoryTagById
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(DocumentProperties);