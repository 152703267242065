import { messagingApi } from 'api/instances';
import DownloadButton from 'modules/common/components/buttons/downloadButton';
import ToolboxButton from 'modules/users/components/toolboxButton';
import UserInsightsBlock from 'modules/users/components/userInsightsBlock';
import React from 'react';

interface ComponentProps { 
    navigateToSettings: () => any;
    userEmailStats: UserEmailStats;
}


interface UserEmailStats {
    totalEmails: number,
    workAccountsOptedIn: number,
    externalAccountsOptedIn: number,
    optedOut: number,
    invalidEmails: number
}
const MessagingCenterEmailsTab: React.FunctionComponent<ComponentProps> = ({navigateToSettings, userEmailStats}) =>  {

    
    const defaultStyles: React.CSSProperties = {
        height: '50px',
        lineHeight: '0px',
        width: '290px',
        textAlign: 'left',
        boxShadow: '0 0 5px #b8b8b8',
        margin: 5,
        borderRadius: 5,
        border: 'none',
        cursor: 'pointer',
        background: 'white',
        color: 'black'
    }

    return (
        <div className="messaging-center-tab-container">
            
            <h4>Email User Count</h4>
            <div className="email-tab-container">
                <div className="center-div">

                    <div className="vertically-center-div">
                        <div className="email-insights">
                            <UserInsightsBlock 
                                title={"Total email addresses"}
                                stats={userEmailStats.totalEmails}
                            />
                            <UserInsightsBlock 
                                title={"AD Accounts Opted-in"}
                                stats={userEmailStats.workAccountsOptedIn}
                            />

                            <UserInsightsBlock 
                                title={"External Users Opted-in"}
                                stats={userEmailStats.externalAccountsOptedIn}
                            />

                            {/* <UserInsightsBlock 
                                title={"Opted-out"}
                                stats={userEmailStats.optedOut}
                            /> */}

                            <UserInsightsBlock 
                                title={"Detected Invalid Email Addresses"}
                                stats={userEmailStats.invalidEmails}
                            />
                        </div>
                    </div>
                    <div className="email-toolbox-container">
                        <span className="toolbox-title">Email Toolbox</span>
                        <div className="toolbox-button-wrapper">    

                            <DownloadButton
                                onClick={() => messagingApi.GetEmailUserCsv() }
                                text={" Download User Data"}
                                downloadName={`notification-email-user-data`}
                                styles={defaultStyles}
                            />

                            <DownloadButton
                                onClick={() => messagingApi.GetOutbox("email") }
                                text={" Download Email Outbox"}
                                downloadName={`notification-email-outbox-user-data`}
                                styles={defaultStyles}
                            />

                            <ToolboxButton 
                                title={"Automated Email Configurations"}
                                handleClick={() => navigateToSettings()}    
                            />
                            
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default MessagingCenterEmailsTab;