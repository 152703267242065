import * as React from "react";
import { push } from 'react-router-redux';
import { ConnectedProps, connect } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import { audiencesApi } from "api/instances";
import * as actions from "../../actionCreator";
import * as settingsActions from "modules/settings/actionCreator";

import { UserRoles } from "modules/authorization/models";
import ErrorSnackbar from "modules/common/components/snackbars/errorSnackbar";
import LoadingOverlay from "modules/common/components/loadingOverlay";
import RequireRole from "modules/common/components/requireRole";

import confirm from "utils/notyPopups";

import { AudienceDetails, AudienceFilterValues, AudiencePage, DraftAudience } from "../../models";
import AudienceCreation from "../audience-creation/audienceCreation";
import AudienceList from "./audienceList";
import NewAudience from "../action-buttons/newAudience";

import BasePage from "pages/common/basePage";
import Header from "pages/common/header";
import MainContent from "pages/common/mainContent";
import Tabs from "pages/common/tabs";

import Button from "@mui/material/Button";
import { FileDownloader } from "utils/fileDownloader";


const allTab: number = 0;
const enabledTab: number = 1;
const disabledTab: number = 2;


class AudienceListing extends React.Component<PropsWithRedux, ComponentState> {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      isLoadingAudience: false,
      selectedAudience: undefined,
      selectedTab: allTab,
      showAudienceCreation: false,
      totalAudiences: 0,
      hasDeleted: false,
      warningOpen: true
    }
  };

  public componentDidMount() {
    this.fetchLists();
    this.fetchTenantAttributes();
  }

  public componentDidUpdate(prevProps: PropsWithRedux) {
    if (this.props.shouldFetch && !prevProps.shouldFetch) {
      this.fetchLists();
      this.fetchTenantAttributes();
      if (!!this.props.audiences.length)
        this.props.getAudiences();
    }

    if (this.props.all.totalAudiences !== prevProps.all.totalAudiences && (this.props.all.totalAudiences > this.state.totalAudiences || this.state.hasDeleted)) {
      this.setState({ totalAudiences: this.props.all.totalAudiences, hasDeleted: false });
    }
  }

  public render() {
    const { all } = this.props;
    const { selectedTab, totalAudiences } = this.state;
    
    const newAudienceDisabled = (totalAudiences >= this.props.maxAllowedAudiences)

    return (
      <BasePage fullWidth>
        <Header
          title="Manage Audiences"
          rightComponent={
            <div>
              {this.props.everyoneAudienceEnabled &&
                <RequireRole roleName={UserRoles.Owner}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={this.goToSyncedGroups}
                  >
                    Sync {this.props.syncType} Groups
                  </Button>
                </RequireRole>
              }
              {this.props.canEdit &&
                <NewAudience
                  disabled={!all || !all.audiences || newAudienceDisabled}
                  loadingChecks={(this.props.all.isFetching ?? false) || (this.props.enabled.isFetching ?? false) || (this.props.disabled.isFetching ?? false)}
                  onClick={this.createNewAudience}
                />
              }
            </div>
          }
        />
        <MainContent>
          <div className="audience-listing">
            <Tabs
              tabs={[
                { label: this.getTabLabel("All", this.props.all) },
                { label: this.getTabLabel("Enabled", this.props.enabled), dotColor: "green" },
                { label: this.getTabLabel("Disabled", this.props.disabled), dotColor: "grey" }
              ]}
              selectedTab={selectedTab}
              onSelectTab={this.onSelectTab}
            />
            <AudienceList
              id="all"
              show={selectedTab === allTab}
              page={this.props.all}
              fetchPage={this.fetchAll}
              tenantAttributes={this.props.tenantAttributes}
              syncType={this.props.syncType}
              onDeleteAudience={this.deleteAudience}
              onDeleteAudiences={this.deleteAudiences}
              onDisableAudience={this.disableAudience}
              onDisableAudiences={this.disableAudiences}
              onDownloadUserData={this.onDownloadUserData}
              onEditAudience={this.editAudience}
              onEnableAudience={this.enableAudience}
              onEnableAudiences={this.enableAudiences}
              maxAudiences={this.props.maxAudiences}
              warningOpen={this.state.warningOpen}
              setWarningClosed={this.setWarningClosed}
            />
            <AudienceList
              id="disabled"
              show={selectedTab === disabledTab}
              page={this.props.disabled}
              fetchPage={this.fetchDisabled}
              tenantAttributes={this.props.tenantAttributes}
              syncType={this.props.syncType}
              onDeleteAudience={this.deleteAudience}
              onDeleteAudiences={this.deleteAudiences}
              onDisableAudience={this.disableAudience}
              onDisableAudiences={this.disableAudiences}
              onDownloadUserData={this.onDownloadUserData}
              onEditAudience={this.editAudience}
              onEnableAudience={this.enableAudience}
              onEnableAudiences={this.enableAudiences}
              maxAudiences={this.props.maxAudiences}
              warningOpen={this.state.warningOpen}
              setWarningClosed={this.setWarningClosed}
            />
            <AudienceList
              id="enabled"
              show={selectedTab === enabledTab}
              page={this.props.enabled}
              fetchPage={this.fetchEnabled}
              tenantAttributes={this.props.tenantAttributes}
              syncType={this.props.syncType}
              onDeleteAudience={this.deleteAudience}
              onDeleteAudiences={this.deleteAudiences}
              onDisableAudience={this.disableAudience}
              onDisableAudiences={this.disableAudiences}
              onDownloadUserData={this.onDownloadUserData}
              onEditAudience={this.editAudience}
              onEnableAudience={this.enableAudience}
              onEnableAudiences={this.enableAudiences}
              maxAudiences={this.props.maxAudiences}
              warningOpen={this.state.warningOpen}
              setWarningClosed={this.setWarningClosed}
            />
          </div>
          <AudienceCreation
            show={this.state.showAudienceCreation}
            audienceDetails={this.state.selectedAudience}
            tenant={this.props.tenant}
            tenantAttributes={this.props.tenantAttributes}
            equalToAnyRuleEnabled={this.props.equalToAnyRuleEnabled}
            onCreateAudience={this.createAudience}
            onDeleteAudience={this.deleteAudience}
            onDownloadUserData={this.onDownloadUserData}
            onSaveAudience={this.saveAudience}
            onClose={this.onCloseAudienceCreation}
            syncType={this.props.syncType}
          />
          <LoadingOverlay show={this.state.isFetching || this.state.isLoadingAudience || this.props.isDeleting || this.props.isFetching || this.props.isSaving } absolute={true} styles={{ paddingTop: 150 }} />
          <ErrorSnackbar errorMessage={this.props.errorMessage} clearErrorMessage={this.props.clearErrorMessage} />
        </MainContent>
      </BasePage>
    );
  }

  private setWarningClosed = () => {
    this.setState({warningOpen: false});
  }

  private associatedCategoryTags = (associatedCategoryTags): JSX.Element => {
    return (
      <React.Fragment>
        {associatedCategoryTags.map((categoryTag) => {
          const text: string = `${categoryTag.title} (Category tag)`;
          return (
            <li key={categoryTag.id}>
              <span>
                {text}
              </span>
            </li>
          );
        })}
      </React.Fragment>
    );
  }

  private associatedDocuments = (associatedDocuments): JSX.Element => {
    return (
      <React.Fragment>
        {associatedDocuments.map((document) => {
          const text: string = `${document.title || document.name} (Document)`;
          return (
            <li key={document.id}>
              <span>
                {text}
              </span>
            </li>
          );
        })}
      </React.Fragment>
    );
  }

  private associatedNewsletters = (associatedNewsletters): JSX.Element => {
    return (
      <React.Fragment>
        {associatedNewsletters.map((newsletter) => {
          const text: string = `${newsletter.title} (Newsletter)`;
          return (
            <li key={newsletter.id}>
              <span
                onClick={() => {
                  const location: string = window.location.href;
                  const segments: string[] = location.split(this.props.tenant);
                  window.open(`${segments[0]}${this.props.tenant}/admin/newsletters/${newsletter.id}/dashboard`);
                }}
                className="audience-associated-item"
              >
                {text}
              </span>
            </li>
          );
        })}
      </React.Fragment>
    );
  }

  private associatedTopics = (associatedTopics): JSX.Element => {
    return (
      <React.Fragment>
        {associatedTopics.map((topic) => {
          const text: string = `${topic.title} (Topic)`;
          return (
            <li key={topic.id}>
              <span
                onClick={() => {
                  const location: string = window.location.href;
                  const segments: string[] = location.split(this.props.tenant);
                  window.open(`${segments[0]}${this.props.tenant}/admin/topics/${topic.groupId}/${topic.id}`);
                }}
                className="audience-associated-item"
              >
                {text}
              </span>
            </li>
          );
        })}
      </React.Fragment>
    );
  }

  private associatedInvites = (associatedInvites): JSX.Element => {
    return (
      <React.Fragment>
        {associatedInvites.map((invite) => {
          const text: string = `${invite.email} (Invite)`;
          
          return (
            <li key={invite.inviteId}>
              <span
                onClick={() => {
                  const location: string = window.location.href;
                  const segments: string[] = location.split(this.props.tenant);
                  window.open(`${segments[0]}${this.props.tenant}/admin/users`);
                }}
                className="audience-associated-item"
              >
                {text}
              </span>
            </li>
          );
        })}
      </React.Fragment>
    );
  }

  private createNewAudience = () => {
    this.setState({ selectedAudience: undefined, showAudienceCreation: true });
  }

  private createAudience = (audience: DraftAudience): Promise<boolean> => {
    return this.props.createAudience(audience);
  }

  private deleteAudience = async (id: string): Promise<boolean> => {
    this.setState({ isFetching: true });

    const associations = await Promise.all([
      audiencesApi.getAssociatedCategoryTags(id),
      audiencesApi.getAssociatedDocuments(id),
      audiencesApi.getAssociatedNewsletters(id),
      audiencesApi.getAssociatedTopics(id),
      audiencesApi.getAssociatedInvites(id)
    ]);

    const associatedCategoryTags = associations[0];
    const associatedDocuments = associations[1];
    const associatedNewsletters = associations[2];
    const associatedTopics = associations[3];
    const associatedInvites = associations[4];

    this.setState({ isFetching: false });

    return new Promise(async (resolve) => {
      const totalAssociations: number = associatedCategoryTags.length + associatedDocuments.length + associatedNewsletters.length + associatedTopics.length + associatedInvites.length;

      if (!!totalAssociations) {
        await confirm.show({
          title: "Audience in use",
          text: (
            <div>
              <div>This audience cannot be deleted because it is used in {totalAssociations} item(s):</div>
              <ul>
                {this.associatedCategoryTags(associatedCategoryTags)}
                {this.associatedDocuments(associatedDocuments)}
                {this.associatedNewsletters(associatedNewsletters)}
                {this.associatedTopics(associatedTopics)}
                {this.associatedInvites(associatedInvites)}
              </ul>
            </div>
          ),
          yesText: "Ok",
          yesColor: "#3b78ab",
          hideNo: true
        });
        resolve(false);
      } else if (await confirm.show({
        title: "Delete audience",
        text: "Are you sure you want to delete this audience?",
        yesColor: "#a80000",
        yesText: "Delete",
        noText: "Cancel",
        noColor: "#888888"
      })) {
        this.props.deleteAudience(id).then(async (succeeded) => {
          await confirm.show({
            title: "Deleted!",
            text: (
              <div className="success-checkmark-pop-dialog">
                <div className="success-checkmark-pop-wrapper">
                  <div className="success-checkmark-pop-bg">
                    <div className="success-checkmark-pop-checkmark"></div>
                  </div>
                </div>
                <div>This audience has been successfully deleted.</div>
              </div>
            ),
            yesText: "Ok",
            hideNo: true
          });
          this.setState({hasDeleted: true})
          resolve(succeeded);
        });
      } else {
        resolve(false);
      }
    });
  }

  private deleteAudiences = async (ids: string[]): Promise<boolean> => {
    this.setState({ isFetching: true });

    const associations = await Promise.all([
      Promise.all(ids.map(id => audiencesApi.getAssociatedCategoryTags(id))),
      Promise.all(ids.map(id => audiencesApi.getAssociatedDocuments(id))),
      Promise.all(ids.map(id => audiencesApi.getAssociatedNewsletters(id))),
      Promise.all(ids.map(id => audiencesApi.getAssociatedTopics(id)))
    ]);

    const associatedCategoryTags = associations[0].reduce((acc, associations) => acc.concat(associations), []);
    const associatedDocuments = associations[1].reduce((acc, associations) => acc.concat(associations), []);
    const associatedNewsletters = associations[2].reduce((acc, associations) => acc.concat(associations), []);
    const associatedTopics = associations[3].reduce((acc, associations) => acc.concat(associations), []);

    const blockingAudiences = ids.filter((id, index) => associations[0][index].length !== 0 || associations[1][index].length !== 0 || associations[2][index].length !== 0 || associations[3][index].length !== 0);

    let blockingAudienceDetails = await audiencesApi.getAudienceDetails(blockingAudiences);
    
    let blockingAudienceNames = blockingAudienceDetails.map(audience => audience.displayName);
    let blockingNamesDisclaimer = this.getAudienceNameString(blockingAudienceNames);

    this.setState({ isFetching: false });

    return new Promise(async (resolve) => {
      if (!!blockingAudiences && blockingAudiences.length > 0) {
        await confirm.show({
          title: "Audience(s) in Use",
          text: (
            <div>
              <div>
                {blockingNamesDisclaimer} This prevents the {ids.length} selected audiences from getting deleted.
                <br/>
                {blockingAudiences.length === 1 ? "This audience" : "These audiences"} are used in:</div>
                <ul>
                  {this.associatedCategoryTags(associatedCategoryTags.filter((categoryTag, index) => associatedCategoryTags.findIndex(searchCategoryTag => searchCategoryTag.id === categoryTag.id) === index))}
                  {this.associatedDocuments(associatedDocuments.filter((document, index) => associatedDocuments.findIndex(searchDocument => searchDocument.id === document.id) === index))}
                  {this.associatedNewsletters(associatedNewsletters.filter((newsletter, index) => associatedNewsletters.findIndex(searchNewsletter => searchNewsletter.id === newsletter.id) === index))}
                  {this.associatedTopics(associatedTopics.filter((topic, index) => associatedTopics.findIndex(searchTopic => searchTopic.id === topic.id) === index))}
                </ul>
            </div>
          ),
          yesText: "Ok",
          hideNo: true
        });
        resolve(false);
      } else if (await confirm.show({
          title: "Delete audiences",
          text: "Are you sure you want to delete these audiences?",
          yesColor: "#a80000",
          yesText: "Delete",
          noText: "Cancel"
        })) {
          this.props.deleteAudiences(ids).then(async (succeeded) => {
            await confirm.show({
              title: "Deleted!",
              text: (
                <div className="success-checkmark-pop-dialog">
                  <div className="success-checkmark-pop-wrapper">
                    <div className="success-checkmark-pop-bg">
                      <div className="success-checkmark-pop-checkmark"></div>
                    </div>
                  </div>
                  <div>These audiences were successfully deleted.</div>
                </div>
              ),
              yesText: "Ok",
              hideNo: true
            });
            this.setState({hasDeleted: true})
            resolve(succeeded);
          });
      } else {
        resolve(false);
      }
    });
  }

  private getAudienceNameString = (names: string[]) => {
    if(names.length === 1) {
      return (
        <React.Fragment>
          <b>{names[0]}</b> is in use.
        </React.Fragment>
      );
    }
    else if(names.length > 1) {
      let lastName = names.pop();

      return (
        <React.Fragment>
          <b>{names.join(", ")},</b> and <b>{lastName}</b> are in use.
        </React.Fragment>
      );
    }
    else {
      return null;
    }
  }

  private disableAudience = (id: string): Promise<boolean> => {
    return this.props.disableAudience(id);
  }

  private disableAudiences = (ids: string[]): Promise<boolean> => {
    return this.props.disableAudiences(ids);
  }

  private onDownloadUserData = (id: string, title: string): Promise<void> => {
    const file = {name: `${title}-user-data.csv`};
    return this.props.downloadUserData(id)
      .then((userData) => {
      if (!!userData) new FileDownloader(file).downloadBlob(userData)
    });
  }

  private editAudience = (id: string) => {
    this.setState({ isLoadingAudience: true });

    this.props.getAudienceDetails(id).then((audience) => {
      this.setState({ isLoadingAudience: false, selectedAudience: audience, showAudienceCreation: true });
    });
  }

  private enableAudience = (id: string): Promise<boolean> => {
    return this.props.enableAudience(id);
  }

  private enableAudiences = (ids: string[]): Promise<boolean> => {
    return this.props.enableAudiences(ids);
  }

  private fetchLists = () => {
    this.fetchAll(1, {});
    this.fetchDisabled(1, {});
    this.fetchEnabled(1, {});
    this.props.clearShouldFetch();
  }

  private fetchAll = (pageNumber: number, filters: Partial<AudienceFilterValues>, pageAmount?: number) => {
    !this.props.all.isFetching &&
      this.props.getAllAudiences(pageNumber, filters);
  }

  private fetchDisabled = (pageNumber: number, filters: Partial<AudienceFilterValues>, pageAmount?: number) => {
    !this.props.disabled.isFetching &&
      this.props.getDisabledAudiences(pageNumber, filters);
  }

  private fetchEnabled = (pageNumber: number, filters: Partial<AudienceFilterValues>, pageAmount?: number) => {
    !this.props.enabled.isFetching &&
      this.props.getEnabledAudiences(pageNumber, filters);
  }

  private fetchTenantAttributes = () => {
    !this.props.tenantAttributes.length &&
      this.props.getTenantAttributes();
  }

  private getTabLabel = (label: string, page: AudiencePage): string => {
    if (!!page.currentPage)
      return `${label} (${page.totalAudiences})`;
    return label;
  }

  private goToSyncedGroups = () => {
    this.props.redirectTo("/" + this.props.tenant + "/admin/audiences/everyone");
  }

  private saveAudience = (id: string, audience: DraftAudience): Promise<boolean> => {
    return this.props.saveAudience(id, audience);
  }

  private onCloseAudienceCreation = () => {
    this.setState({ showAudienceCreation: false });
  }

  private onSelectTab = (tabIndex: number) => {
    this.setState({ selectedTab: tabIndex });
  }
}

interface ComponentProps {}

interface ComponentState {
  isFetching: boolean;
  isLoadingAudience: boolean;
  selectedAudience: AudienceDetails | undefined;
  selectedTab: number;
  showAudienceCreation: boolean;
  totalAudiences: number;
  hasDeleted: boolean;
  warningOpen: boolean;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    audiences: state.audiences.audiences,
    all: state.audiences.all,
    disabled: state.audiences.disabled,
    enabled: state.audiences.enabled,
    errorMessage: state.audiences.errorMessage,
    everyoneAudienceEnabled: state.settings.tenantSettings.showFeatures.everyoneAudienceEnabled,
    isDeleting: state.audiences.isDeleting,
    isFetching: state.audiences.isFetching,
    isSaving: state.audiences.isSaving,
    maxAllowedAudiences: state.settings.tenantSettings.maxAllowedAudiences,
    shouldFetch: state.audiences.shouldFetch,
    tenant: state.tenant.id,
    equalToAnyRuleEnabled: state.settings.tenantSettings.showFeatures.equalToAnySmartAudienceRuleEnabled,
    maxAudiences: state.settings.tenantSettings.maxAllowedAudiences,
    tenantAttributes: state.settings.tenantAttributes,
    canEdit: state.settings.tenantSettings.manageTopicsAudiencesInAdmin,
    syncType: state.settings.tenantSettings.syncConfig.syncType
  }),
  {
    clearErrorMessage: actions.clearErrorMessage,
    clearShouldFetch: actions.clearShouldFetch,
    createAudience: actions.createAudience,
    deleteAudience: actions.deleteAudience,
    deleteAudiences: actions.deleteAudiences,
    disableAudience: actions.disableAudience,
    disableAudiences: actions.disableAudiences,
    downloadUserData: actions.downloadUserData,
    enableAudience: actions.enableAudience,
    enableAudiences: actions.enableAudiences,
    getAudiences: actions.getAudiences,
    getAudienceDetails: actions.getAudienceDetails,
    getAllAudiences: actions.getAllAudiences,
    getDisabledAudiences: actions.getDisabledAudiences,
    getEnabledAudiences: actions.getEnabledAudiences,
    getTenantAttributes: settingsActions.getTenantAttributes,
    saveAudience: actions.saveAudience,
    redirectTo: push
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(AudienceListing);