import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { push } from "react-router-redux";
import * as actions from "../../actionCreator";
import { GlobalApplicationState } from "globalApplicationState";

import MoreOptionsButton from "modules/common/components/buttons/moreOptionsButton";
import confirm from "utils/notyPopups";

import { PortalPage, PortalPagesListItem } from "../../models";

import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import MoreOptionsItem from "modules/common/components/moreOptionsItem";


const PreviewOptions: React.FunctionComponent<PropsWithRedux> = props => {
  const { portalPagesListItem } = props;

  return (
    <div>
      <MoreOptionsButton text="Actions">
        <List disablePadding>
          <MoreOptionsItem
            text="Open in page editor"
            onClick={() => {
              props.hidePreview();
              props.redirectTo("/" + props.tenantId + "/admin/portalPages/edit/" + props.portalPage.draftId);
            }}
          />
          {!portalPagesListItem.isHome &&
            <Divider light />
          }
          {!portalPagesListItem.isHome && portalPagesListItem.state === "Enabled" &&
            <MoreOptionsItem
              text="Set as homepage"
              onClick={async () => {
                props.hidePreview();
                if (await confirm.show({
                  text: "You are about to set Leaders Hub as the Homepage. This will change the page settings including the URL and the audiences who can access the page.<br /><br />"+
                        "Do you want to continue?",
                  title: "Set as homepage"
                }))
                  props.setPortalPageAsHome(portalPagesListItem.id);
              }}
            />
          }
          {(portalPagesListItem.state === "Draft" || portalPagesListItem.hasPendingDraft)  &&
            <MoreOptionsItem
              text={portalPagesListItem.hasPendingDraft
                ? portalPagesListItem.state === "Enabled"
                  ? "Publish changes"
                  : "Publish with changes"
                : "Publish now"
              }
              onClick={() => {
                props.hidePreview();
                props.publishDraft(portalPagesListItem.draftId);
              }}
            />
          }
          {!portalPagesListItem.isHome && portalPagesListItem.state === "Enabled" &&
            <MoreOptionsItem
              text="Disable"
              onClick={() => {
                props.hidePreview();
                props.disablePortalPage(portalPagesListItem.id);
              }}
            />
          }
          {portalPagesListItem.state === "Disabled" &&
            <MoreOptionsItem
              text="Enable"
              onClick={() => {
                props.hidePreview();
                props.enablePortalPage(portalPagesListItem.id);
              }}
            />
          }
          {!portalPagesListItem.isHome &&
            <MoreOptionsItem
              text="Delete"
              onClick={async () => {
                props.hidePreview();
                if (portalPagesListItem.state === "Draft") {
                  if (await confirm.show({ text: "Are you sure you want to delete this draft?", title: "Delete draft" }))
                    props.deleteDraft(portalPagesListItem.draftId);
                } else {
                  if (await confirm.show({ text: "Are you sure you want to delete this page?", title: "Delete page" }))
                    props.deletePortalPage(portalPagesListItem.id);
                }
              }}
            />
          }
        </List>
      </MoreOptionsButton>
    </div>
  );
}

interface ComponentProps {
  portalPage: PortalPage;
  portalPagesListItem: PortalPagesListItem;
  onShowPortalPageDetails: (portalPage: PortalPagesListItem) => void;
  onShowPortalPageTitle: (portalPage: PortalPagesListItem) => void;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    tenantId: state.tenant.id
  }),
  {
    deleteDraft: actions.deleteDraft,
    deletePortalPage: actions.deletePortalPage,
    disablePortalPage: actions.disablePortalPage,
    enablePortalPage: actions.enablePortalPage,
    getDraft: actions.getDraft,
    hidePreview: actions.hidePreview,
    publishDraft: actions.publishDraft,
    redirectTo: push,
    setPortalPageAsHome: actions.setPortalPageAsHome
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(PreviewOptions);