import {timeParse, scaleLinear, extent, area, curveBasis, max, line, select, axisLeft, axisBottom} from 'd3';
const d3 = {timeParse, scaleLinear, extent, area, curveBasis, max, line, select, axisLeft, axisBottom};

export interface ILineChartProps {
    el: any;
    data: ILineChartDataPoint[];
    height: number;
    maxY?: number;
    numXTicks?: number;
    xLabelGenerator?: (x: number) => string;
    locale?: string;
}

export interface ILineChartDataPoint {
    x: number;
    y: number;
    label: string;
}

export class LineChart {

    constructor(props: ILineChartProps) {
        this.update(props);
    }

    public update(props: ILineChartProps) {
        if (!props.el) {
            return;
        }

        const boundingRect = props.el.getBoundingClientRect();

        // set the dimensions and margins of the graph
        const margin = {top: 20, right: 30, bottom: 40, left: 35},
            width = boundingRect.width - margin.left - margin.right,
            height = props.height - margin.top - margin.bottom;

        d3.timeParse('%d-%b-%y');

        // set the ranges
        const x = d3.scaleLinear()
                .domain(d3.extent(props.data, d => d.x) as number[]) //Used to be [number, number]
                .range([0, width]);

        const y = d3.scaleLinear()
                .domain([0, props.maxY != null ? props.maxY : d3.max(props.data, d => d.y)!])
                .range([height, 0]);

        // define the area
        const area = d3.area<ILineChartDataPoint>()
            .curve(d3.curveBasis)
            .x(function(d) { return x(d.x); })
            .y0(height)
            .y1(function(d) { return y(d.y); });

        // define the line
        const valueline = d3.line<ILineChartDataPoint>()
            .curve(d3.curveBasis)
            .x(function(d) { return x(d.x); })
            .y(function(d) { return y(d.y); });

        d3.select(props.el)
            .select('svg').remove();

        const svg = d3.select(props.el)
                .append('svg')
                .attr('width', width + margin.left + margin.right)
                .attr('height', height + margin.top + margin.bottom)
                // .selectAll('*')
                // .remove()
                .append('g')
                .attr('transform',
                    'translate(' + margin.left + ',' + margin.top + ')');

         // add the area
            svg.append('path')
                .data([props.data])
                .attr('class', 'area')
                .attr('d', area);

            // add the valueline path.
            svg.append('path')
                .data([props.data])
                .attr('class', 'line')
                .attr('d', valueline);

            // add the X Axis
            svg.append('g')
                .attr('class', 'x-axis')
                .attr('transform', 'translate(0,' + height + ')')
                .call(d3.axisBottom(x)
                    .ticks(props.numXTicks != null ? props.numXTicks : props.data.length)
                    .tickFormat(props.xLabelGenerator ? props.xLabelGenerator : (d, i) => {
                        return d.toString();
                    }));

            // add the Y Axis
            svg.append('g')
                .call(d3.axisLeft(y));

    }

    public destroy() {
        // Any clean-up would go here
        // in this example there is nothing to do
    }
}

