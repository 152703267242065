import React, { useEffect, useState } from 'react';

interface ComponentProps {
    title: string;
    inputType: string;
    description?: string;
    isDisabled?: boolean;
    onChange?: (any) => any;
    placeholder?: string;
    value?: string;
    setTimer?: number;
    pristineText?: string;
    maxLength?: number;
}
const InputField: React.FunctionComponent<ComponentProps> = ( {title, inputType, description, isDisabled, placeholder, onChange, value, setTimer, pristineText, maxLength} ) => {

    const [term, setTerm] = useState(value ?? "");
    const [debouncedTerm, setDebouncedTerm] = useState(term);
    const [onChangeTerm] = useState(() => onChange);

    useEffect(() => {
        const timerId = setTimeout(() => {
            setDebouncedTerm(term);
        }, setTimer ?? 0)

        return () => {
            clearTimeout(timerId);
        }
    }, [setTimer, term])

    useEffect(() => {
        if (debouncedTerm) {
            onChangeTerm!(debouncedTerm)
        }
    }, [debouncedTerm, onChangeTerm])

    useEffect(() => {
        if(value === pristineText) {
            setTerm(pristineText ?? "");
        }
    }, [pristineText, value])

    return (
        <div>
            <label className="profile-input-label" htmlFor={`${title}`}>{title}</label>
            <div className="label-description-block">
                <input 
                    className="profile-input-field description" 
                    id={title} 
                    type={inputType}
                    disabled={isDisabled}
                    placeholder={placeholder}
                    value={debouncedTerm}
                    onChange={(e) => setTerm(e.target.value)}
                    maxLength={maxLength ?? 1000}
                    />
                <p  className="description-text">{description}</p>
            </div>
        </div>
    )
}

export default InputField;