import React, { useCallback } from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import ArrowBack from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";

import { Button, Divider, Drawer, FormControl, Link, MenuItem, Select, Snackbar, Switch, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { PermissionDetails, UserDetails, UserRole } from "modules/users/models";
import confirm from "utils/notyPopups";
import "./dialogStyling.scss";
import Disclaimer from "modules/common/components/disclaimer";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LockIcon from "@mui/icons-material/Lock";
import { TenantSettingsTag } from "modules/settings";
import { actions } from "modules/users";
import ErrorSnackbar from "modules/common/components/snackbars/errorSnackbar";
import TopicMultiselect from "modules/common/components/forms/inputs/topicMultiselect";
import { MultiSelectTopic } from "modules/settings";
import { USER_ROLE_DESCRIPTIONS } from "modules/authorization/models";
import ChipList from "modules/common/components/chipList";

const useStyles = makeStyles({
    paper: {
        width: 850
    }
});

const PermissionsDrawer: React.FunctionComponent<PropsWithRedux> = props => {
    //Wrap tag function in callback so we can access it in useEffect.
    const resolveCurrentUserTags = useCallback(() => {
        let allTags:TenantSettingsTag[] = [];
        props.topics.forEach(topicGroup => allTags = allTags.concat(topicGroup.tags)); //Create list of all tags.
        if(props.userData.permissionDetails && props.userData.permissionDetails.topics) {
            allTags = allTags.filter(t => props.userData.permissionDetails.topics.includes(t.id)); //Filter to just what's selected.
        }
        else {
            allTags = [];
        }
        return allTags;
    }, [props.topics, props.userData.permissionDetails]);

    const userRole = props.userData.permissionDetails.role;
    const currentTags = resolveCurrentUserTags();
    let freshClone:UserDetails = JSON.parse(JSON.stringify(props.userData));
    freshClone.permissionDetails.topics = currentTags.map(t => t.id);

    const [selectedRole, setSelectedRole] = React.useState<UserRole>(userRole);
    const [selectedTags, setSelectedTags] = React.useState<MultiSelectTopic[]>(currentTags); //Load from current user data.
    const [userClone, setUserClone] = React.useState<UserDetails>(freshClone); //Object used to keep track of local edits.
    const [isSaving, setIsSaving] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [isSubmissionManager, setIsSubmissionManager] = React.useState(props.userData.securityDetails.isSubmissionManager);


    //Reset dropdown when drawer is closed.
    React.useEffect(() => {
        if(!props.isOpen) {
            const userRole = props.userData.permissionDetails.role;
            setSelectedRole(userRole);
            setSelectedTags(resolveCurrentUserTags()); //Reset this to user data.
        }
    }, [props.isOpen, props.userData.permissionDetails.role, resolveCurrentUserTags]);

    // reset submission manager field when role selection changes
    React.useEffect(() => {
        if (selectedRole !== UserRole.author && selectedRole !== UserRole.owner)
            setIsSubmissionManager(false);
    }, [selectedRole]);

    const classes = useStyles();

    const changesMade = () => {
        let currentUserTopics = props.userData.permissionDetails.topics ?? [];
        let selectedTagIds = selectedTags.map(t => t.id);

        //Check for role match.
        if(userClone.permissionDetails.role !== props.userData.permissionDetails.role) {
            return true;
        }

        //Check for topics mismatch
        else if(selectedTags.length !== currentUserTopics.length) {
            return true;
        }

        //If we make it here they're the same length, so we don't need to worry about checking both
        else if(!currentUserTopics.every(topic => selectedTagIds.includes(topic))) {
            return true;
        } else if(props.userData.securityDetails.isSubmissionManager !== isSubmissionManager) {
            return true;
        }

        return false;
    }

    const closeDrawer = async() => {
        if(changesMade()) {
            if (await confirm.show({
                title: "Back to User Profile",
                text: "You have unsaved permission changes.<br><br>Do you want to continue?",
                yesText: "Keep Editing",
                noText: "Exit Permissions",
            })) {
                //Don't actually do anything except close the dialogue (which happens automatically).
                //Save only happens if they click the button.
            }
            else {
                props.closeDrawer();
            }
        }
        else {
            props.closeDrawer();
        }
    }

    const closeDrawerAndDisableUser = async() => {
        if(changesMade()) {
            if (await confirm.show({
                title: "Back to User Profile",
                text: "You have unsaved permission changes.<br><br>Do you want to continue?",
                yesText: "Keep Editing",
                noText: "Exit Permissions",
            })) {
                //Don't actually do anything except close the dialogue (which happens automatically).
                //Save only happens if they click the button.
            }
            else {
                props.closeDrawer();
                props.disableUser();
            }
        }
        else {
            props.closeDrawer();
            props.disableUser();
        }
    }

    //Update clone of user to have new role.
    const setNewRole = (toSet: UserRole) => {
        let toUpdate = userClone;
        toUpdate.permissionDetails.role = toSet;

        setSelectedRole(toSet);
        setUserClone(toUpdate);
    }

    const getSubmissionManagerSection = (role: string) => {
        return <div>
                    <div className="header">Content Management</div>
                    <div className="submission-manager">
                      <div>
                        <div>Assign as a Submission Manager</div>
                        <div>
                          <Switch color="primary" checked={isSubmissionManager} onChange={(ev, checked) => setIsSubmissionManager(checked)} />
                        </div>
                      </div>
                      <Typography variant="caption">Notify this {role} when a post is submitted by a Contributor.</Typography>
                    </div>
                </div>
    }

    const savePermissions = () => {
        let userToSave:PermissionDetails = {
            role: selectedRole,
            topics: selectedTags.map(t => t.id),
            isSubmissionManager: isSubmissionManager
        };

        setIsSaving(true);

        props.updatePermissions(userToSave, props.userData.id).then((result) => {
            setIsSaving(false);
            if(!result) {
                setErrorMessage("Changes could not be saved. Please try again.");
                return;
            }
            else {
                setUserClone(JSON.parse(JSON.stringify(props.userData))); //Update everything to saved new permissions.

                props.onSave(props.userData);
            }
        }).catch(err => {
            setErrorMessage("Changes could not be saved. Please try again.");
        });
    }

    const onChangeTagsChipList = (currentTag: MultiSelectTopic) => {
        const hasSelectedTag: boolean = !!selectedTags.find((selectedTag) => selectedTag.id === currentTag.id);
        let newTopics = [...selectedTags];

        if (hasSelectedTag)
            newTopics = selectedTags.filter(tag => tag.id !== currentTag.id);
        else
            newTopics.push(currentTag);

        setSelectedTags(newTopics);
    }

    return (
      <Drawer open={props.isOpen} anchor="right" onClose={closeDrawer} classes={{ paper: classes.paper }}>
        <div style={{ padding: 20 }}>

            <div style={{display: "flex", flexDirection: "row"}}>
                <ArrowBack className="close-modal-icon" style={{position: "relative", top: "4px"}} onClick={closeDrawer} />
                <h4 style={{paddingLeft: "14px"}}>Permissions</h4>
                <Button
                    style={{marginLeft: "auto", height: "35px", width: "75px"}}
                    color={"primary"}
                    variant={"contained"}
                    disabled={!changesMade() || isSaving}
                    onClick={savePermissions}
                >
                        Save
                </Button>
            </div>

            <Divider style={{width: "870px", position: "relative", right: "5%", marginTop: "12px"}}/>

            <div style={{display: "flex", flexDirection: "row", marginTop: "30px", marginBottom: "10px"}}>
                <span style={{paddingRight: "35px"}}>Role</span>
                <FormControl size="small" fullWidth>
                    <Select
                        value={selectedRole}
                        variant={"outlined"}
                        style={{position: "relative", top: "-9px"}}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                        }}
                    >
                        <MenuItem value={UserRole.user} onClick={() => setNewRole(UserRole.user)}>User</MenuItem>
                        <MenuItem value={UserRole.contributor} onClick={() => setNewRole(UserRole.contributor)}>Contributor</MenuItem>
                        <MenuItem value={UserRole.author} onClick={() => setNewRole(UserRole.author)}>Author</MenuItem>
                        <MenuItem value={UserRole.owner} onClick={() => setNewRole(UserRole.owner)}>Owner</MenuItem>
                    </Select>
                </FormControl>
            </div>

            {selectedRole === UserRole.user &&
            <React.Fragment>
                <Disclaimer
                    icon={<InfoOutlinedIcon />}
                    text={USER_ROLE_DESCRIPTIONS.INTERNAL}>
                </Disclaimer>

                <div>
                    <div className="header">Content Engagement</div>
                    <span>
                        All activated users in your Sparrow directory are able to sign in to the portal, and view and interact with any published content they have access to.
                        &nbsp;<Link onClick={closeDrawerAndDisableUser}>Deactivate the user</Link> to revoke their access.
                    </span>
                </div>
            </React.Fragment>
            }

            {selectedRole === UserRole.author &&
            <React.Fragment>
                <Disclaimer
                    icon={<InfoOutlinedIcon />}
                    text={USER_ROLE_DESCRIPTIONS.AUTHOR}>
                </Disclaimer>

                <div>
                    <div className="header">Content Engagement</div>
                    <span>
                        All activated users in your Sparrow directory are able to sign in to the portal, and view and interact with any published content they have access to.
                        &nbsp;<Link onClick={closeDrawerAndDisableUser}>Deactivate the user</Link> to revoke their access.
                    </span>
                </div>

                <Divider style={{marginTop: "12px"}}/>

                <div>
                    <div className="header">Content Creation</div>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <div style={{display: "flex", flexDirection: "column", marginBottom: "20px"}}>
                            <span>Assign specific topics when authoring</span>
                            <span style={{fontSize: "12px", color: "#333"}}>If none are selected, Author can publish content using any topic.</span>
                        </div>
                        <TopicMultiselect
                            defaultOptions={props.topics}
                            selectedOptions={selectedTags}
                            onChange={(topics) => {setSelectedTags(topics)}}
                            startIcon={<AddIcon/>}
                        />
                    </div>
                    <div>
                        <ChipList
                            closable
                            items={selectedTags.map((opt: MultiSelectTopic) => ({
                                id: opt.id,
                                name: opt.name,
                                icon: opt.restricted ? <LockIcon style={{ fontSize: "16px", color: "#B72026", position: "relative", top: "2px", marginLeft: "4px" }} /> : undefined
                            }))}
                            onClose={onChangeTagsChipList}
                            truncateAtIndex={5}
                            showEmptyChip
                        />
                    </div>
                </div>

                <Divider />
                {getSubmissionManagerSection('Author')}
                <Snackbar/>
            </React.Fragment>
            }

            {selectedRole === UserRole.owner &&
            <React.Fragment>
                <Disclaimer
                    icon={<InfoOutlinedIcon />}
                    text={USER_ROLE_DESCRIPTIONS.OWNER}>
                </Disclaimer>
                {getSubmissionManagerSection('Owner')}
            </React.Fragment>
            }
            {
                selectedRole === UserRole.contributor &&
                <React.Fragment>
                    <Disclaimer
                        icon={<InfoOutlinedIcon />}
                        text={`Contributors are allowed to submit draft content for review using topics they have access to.
                                They also have limited access to certain settings when creating or publishing content.`}>
                    </Disclaimer>

                    <div>
                        <div className="header">Content Engagement</div>
                        <span>
                            All activated users in your Sparrow directory are able to sign in to the portal, and view and interact with any published content they have access to.
                            &nbsp;<Link onClick={closeDrawerAndDisableUser}>Deactivate the user</Link> to revoke their access.
                        </span>
                    </div>

                    <Divider style={{marginTop: "12px"}}/>

                    <div>
                        <div className="header">Content Submission</div>
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                            <div style={{display: "flex", flexDirection: "column", marginBottom: "20px"}}>
                                <span>Allow to submit content in these topics</span>
                                <span style={{fontSize: "12px", color: "#333"}}>If none are selected, Contributor cannot submit content to any topics.</span>
                            </div>
                            <TopicMultiselect
                                defaultOptions={props.topics}
                                selectedOptions={selectedTags}
                                onChange={(topics) => {setSelectedTags(topics)}}
                                startIcon={<AddIcon/>}
                            />
                        </div>
                        <div>
                            <ChipList
                                closable
                                items={selectedTags.map((opt: MultiSelectTopic) => ({
                                    id: opt.id,
                                    name: opt.name,
                                    icon: opt.restricted ? <LockIcon style={{ fontSize: "16px", color: "#B72026", position: "relative", top: "2px", marginLeft: "4px" }} /> : undefined
                                }))}
                                onClose={onChangeTagsChipList}
                                truncateAtIndex={5}
                                showEmptyChip
                            />
                        </div>

                    </div>
                    <Snackbar/>
                </React.Fragment>
            }

        </div>
        <ErrorSnackbar errorMessage={errorMessage} clearErrorMessage={() => setErrorMessage("")} />
      </Drawer>
    );
}

interface ComponentProps {
    isOpen: boolean;
    userData: UserDetails;
    closeDrawer: () => void;
    disableUser: () => void;
    onSave: (updatedUser: UserDetails) => void;
}

const connector = connect(
(state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    topics: state.settings.tenantSettings.tagGroups
}), {
    updatePermissions: actions.updatePermissions
}
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(PermissionsDrawer);
