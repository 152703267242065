import ToggleSwitch from 'modules/common/components/toggleSwitch';
import React, { useEffect, useState } from 'react';
import { UserRoles } from 'modules/authorization/models';
import { GlobalApplicationState } from "globalApplicationState";
import { ConnectedProps, connect } from "react-redux";

interface ComponentProps {
    toggleUserRole: (any) => any;
    toggleSubscribedToFlaggingNotifications: (any) => any;
    toggleSubmissionManager: (any) => any;
    edit?: boolean;
}

const UserPermissionsDetails: React.FunctionComponent<PropsWithRedux> = (props) => {
    
    const user = props.creatingUser;

    const [roles, setRoles] = useState<string[]>(user!.roles ?? []);

    useEffect(() => {
        setRoles(user!.roles)
    }, [user])

    return (
        <div>
            <div className="user-input-section-title">Permissions</div>
            <div className="clearfix" style={{marginTop: 20}}>
                <div className="profile-input-label-wide">Owner</div> 
                <div style={{float: "left"}}>
                    <ToggleSwitch 
                        value={roles.includes(UserRoles.Owner) ?? false} 
                        onClick={() => props.toggleUserRole(UserRoles.Owner)}
                    />
                </div>
            </div>
            <div className="description-text" style={{marginTop: 10}}>Owners have access to all admin portal features, including managing user permissions.</div>
                { user!.roles?.includes(UserRoles.Owner) &&
                <React.Fragment>
                    <input 
                        type="checkbox" 
                        id="notifications" 
                        checked={user!.subscribedToFlaggingNotifications}
                        onChange={() => props.toggleSubscribedToFlaggingNotifications(!user!.subscribedToFlaggingNotifications)} 
                    />
                    <label className="description-text" htmlFor="notifications">Send notifications to this owner when items are flagged.</label>
                </React.Fragment>
                }

            <div className="clearfix" style={{marginTop: 20}}>
                <div className='profile-input-label-wide'>Author</div> 
                <div style={{float: "left"}}>
                    <ToggleSwitch 
                        value={roles.includes(UserRoles.Author) ?? false} 
                        onClick={() => props.toggleUserRole(UserRoles.Author)} 
                    />
                </div>
            </div>
            <div className="clearfix" style={{marginTop: 20}}>
                <div className='profile-input-label-wide'>Submission Manager</div>
                <div style={{float: "left"}}>
                    <ToggleSwitch 
                        value={user!.canManageSubmissions ?? false} 
                        onClick={() => props.toggleSubmissionManager(!user!.canManageSubmissions)}
                    />
                </div>
            </div>
            <div className="description-text" style={{marginTop: 10}}>This person can edit and publish draft submissions from other users or sources.</div>
        </div>
    )
}


const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        fetchedUser: state.users.fetchedUser,
        creatingUser: state.users.creatingUser,
    }),
    {

    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;
export default connector(UserPermissionsDetails);
