import React from "react";
import { ContentBandContentType, ContentBandLayoutType, IContentBandSettings, ReadStatus } from "../../models";
import { useMediaQuery, useTheme } from "@mui/material";

import { IContentBandSettingActionProps } from "./contentBandsEditorPage";
import { SelectOption } from "modules/common/models";
import { TenantSettingsTagGroup } from "modules/settings";
import { SortStyle } from "utils/managementUtils";
import {
    LayoutInput,
    DisplayFullTitlesInput,
    EnableSeeMoreInput,
    AutoPlayCarouselInput,
    ContentCountInput,
    ContentTypeInput,
    SortByInput,
    PinInput,
    ReadStatusInput,
    TypesInput,
    TopicInput,
    IgnoreSubscriptionsInput,
} from "../inputs";

const EVENT_TYPES: SelectOption[] = [
    {
        key: "informational",
        text: "Informational",
        type: "item",
    },
    {
        key: "standard",
        text: "Standard RSVP",
        type: "item",
    },
    {
        key: "mandatory",
        text: "Mandatory RSVP",
        type: "item",
    },
];

const POST_TYPES: SelectOption[] = [
    {
        key: "standard",
        text: "Standard",
        type: "item",
    },
    {
        key: "compliance",
        text: "Compliance",
        type: "item",
    },
    {
        key: "mandatory",
        text: "Mandatory",
        type: "item",
    },
    {
        key: "public",
        text: "Public",
        type: "item",
    },
];

export interface IContentBandSettingsForm extends IContentBandSettingActionProps {
    settings: IContentBandSettings;
    idx: number;
    defaultLcid: string;
    userTagGroups: TenantSettingsTagGroup[];
}

export const ContentBandSettingsForm: React.FunctionComponent<IContentBandSettingsForm> = ({
    defaultLcid,
    settings: {
        contentCount = 10,
        contentType = ContentBandContentType.Post,
        seeMoreEnabled = false,
        displayFullTitles = false,
        sortBy = SortStyle.publishDesc,
        pinnedIds = [],
        lockToTypes = [],
        lockToReadStatus = ReadStatus.Both,
        lockToTopicIds = [],
        layoutType = ContentBandLayoutType.Card,
        autoPlayCarousel = false,
        ignoreSubscriptions = false,
    },
    idx,
    userTagGroups,
    ...actions
}) => {
    const theme = useTheme();
    const isSmallAndSmaller = useMediaQuery(theme.breakpoints.down("sm"), { noSsr: true });

    const getTypes = (): SelectOption[] => (contentType === ContentBandContentType.Event ? EVENT_TYPES : POST_TYPES);

    return (
        <>
            <LayoutInput onChange={actions.onChangeLayout} value={layoutType} idx={idx} isSmallAndSmaller={isSmallAndSmaller} />
            {layoutType === ContentBandLayoutType.Card && (
                <DisplayFullTitlesInput
                    onChange={actions.onChangeDisplayFullTitles}
                    idx={idx}
                    value={displayFullTitles}
                    isSmallAndSmaller={isSmallAndSmaller}
                />
            )}
            {layoutType !== ContentBandLayoutType.Carousel && (
                <EnableSeeMoreInput
                    idx={idx}
                    onChange={actions.onChangeEnableSeeMoreLink}
                    value={seeMoreEnabled}
                    isSmallAndSmaller={isSmallAndSmaller}
                />
            )}
            {layoutType === ContentBandLayoutType.Carousel && (
                <AutoPlayCarouselInput
                    idx={idx}
                    value={autoPlayCarousel}
                    onChange={actions.onChangeAutoPlayCarousel}
                    isSmallAndSmaller={isSmallAndSmaller}
                />
            )}
            <ContentCountInput
                layoutType={layoutType}
                value={contentCount}
                onChange={actions.onChangeContentCount}
                idx={idx}
                isSmallAndSmaller={isSmallAndSmaller}
            />
            <ContentTypeInput value={contentType} idx={idx} onChange={actions.onChangeContentType} isSmallAndSmaller={isSmallAndSmaller} />
            <SortByInput
                idx={idx}
                value={sortBy}
                onChange={actions.onChangeSortBy}
                contentType={contentType}
                isSmallAndSmaller={isSmallAndSmaller}
            />
            <PinInput
                idx={idx}
                onChange={actions.onChangePinnedIds}
                contentType={contentType}
                value={pinnedIds}
                defaultLcid={defaultLcid}
                contentCount={contentCount}
                isSmallAndSmaller={isSmallAndSmaller}
            />
            <ReadStatusInput
                idx={idx}
                value={lockToReadStatus}
                onChange={actions.onChangeLockToReadStatus}
                isSmallAndSmaller={isSmallAndSmaller}
            />
            <TypesInput
                idx={idx}
                value={lockToTypes}
                contentType={contentType}
                types={getTypes()}
                onChange={actions.onChangeLockToTypes}
                isSmallAndSmaller={isSmallAndSmaller}
            />
            <TopicInput
                idx={idx}
                value={lockToTopicIds}
                onChange={actions.onChangeLockToTopics}
                topicGroups={userTagGroups}
                isSmallAndSmaller={isSmallAndSmaller}
            />
            <IgnoreSubscriptionsInput
                idx={idx}
                value={ignoreSubscriptions}
                onChange={actions.onChangeIgnoreSubscriptions}
                isSmallAndSmaller={isSmallAndSmaller}
            />
        </>
    );
};

export interface IContentBandSettingsInputProps<T> {
    idx: number;
    onChange: (newValue: T, idx: number) => void;
    value: T;
    isSmallAndSmaller: boolean;
}
