import React from "react";


const Banner: React.FunctionComponent<{ data: any }> = props => {
  return (
    <div className="banner-block">
      <div
        style={{
          backgroundImage: !!props.data.background_image ? `url('${props.data.background_image.fileUrl}')` : "",
          backgroundPosition: props.data.image_alignment || "center"
        }}
        className="banner-image"
      >
      </div>
      {props.data.overlay_color &&
        <div
          style={{
            backgroundColor: props.data.overlay_color,
            opacity: !!props.data.overlay_opacity ? props.data.overlay_opacity / 100 : 0
          }}
          className="image-overlay"
        >
        </div>
      }
      <div dangerouslySetInnerHTML={{ __html: props.data.banner_text }} className="banner-text"></div>
    </div>
  );
};


export const banner_fields = [
  {
    name: "content_label",
    label: "Content",
    component: "section-label"
  },
  {
    name: "banner_text",
    label: "Banner text",
    description: "Ex. Welcome, Hello, Page titles",
    component: "tinymce"
  },
  {
    name: "content_hr",
    component: "hr"
  },
  {
    name: "styling_label",
    label: "Styling",
    component: "section-label"
  },
  {
    name: "background_image",
    label: "Background image",
    component: "image"
  },
  {
    name: "image_alignment",
    label: "Image alignment",
    component: "select",
    options: [
      { value: "top", label: "Top" },
      { value: "center", label: "Center" },
      { value: "bottom", label: "Bottom" }
    ]
  },
  {
    name: "overlay_color",
    label: "Overlay color & opacity",
    component: "color"
  },
  {
    name: "overlay_opacity",
    component: "percentage-slider"
  }
];

export const BannerBlock: React.FunctionComponent<{ data: any }> = props => {
  return (
    <Banner data={props.data} />
  );
};

export const banner_template = {
  key: "banner-block",
  label: "Banner",
  defaultItem: {
    banner_text: "<h2>Header</h2>",
    image_alignment: "center",
    overlay_opacity: 50
  },
  fields: banner_fields
};