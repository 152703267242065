import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { GlobalApplicationState } from "globalApplicationState";

import PortalPageView from "modules/portalPages/components/portal-pages-views/portalPageView";


const PortalPagesPage: React.FunctionComponent<PropsWithRedux> = props => {
  return (
    <PortalPageView portalPageUrl={props.match.params.portalPageUrl} />
  );
}


interface RouteParams {
  portalPageUrl: string;
}

interface ComponentProps {}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps & RouteComponentProps<RouteParams>) => ({
    ...ownProps
  })
);
type PropsWithRedux = ConnectedProps<typeof connector>;
export default connector(PortalPagesPage);