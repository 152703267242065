import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { GlobalApplicationState } from "globalApplicationState";

import BasePage from "../common/basePage";
import Header from "../common/header";
import MainContent from "../common/mainContent";

import EventsFeed from "modules/events/components/event-feed/eventFeed";
import FilterIcon from "modules/events/components/event-feed/components/filters/FilterIcon";
import { useOpenMobileAppBanner } from "modules/common/hooks/useOpenMobileAppBanner";


const EventsPage: React.FunctionComponent<PropsWithRedux> = props => {
    useOpenMobileAppBanner();

    return (
        <BasePage fullWidth>
            <Header
                title="Events"
                rightComponent={
                    <div>
                        <FilterIcon />
                    </div>
                }
            />
            <MainContent id="event-feed" addPadding>
                <EventsFeed selectedEventId={props.match.params.eventId} />
            </MainContent>
        </BasePage>
    );
}


interface RouteParams {
    eventId: string;
}

interface ComponentProps {
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps & RouteComponentProps<RouteParams>) => ({
        ...ownProps
    }),
    {
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;
export default connector(EventsPage);
