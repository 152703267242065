import {
    applyMiddleware,
    combineReducers,
    compose,
    createStore,
    ReducersMapObject,
    StoreEnhancer,
    StoreEnhancerStoreCreator
} from "redux";
import thunk from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { DevTools } from './modules/common/components/DevTools';
import { createBrowserHistory, History } from 'history';
import authenticatedRequestMiddleware from './authentication/auth-request-middleware';
import { GlobalApplicationState } from "./globalApplicationState";
import { reducers } from './rootReducer';
import { isDevEnvironment } from "utils/isDevEnvironment";

function buildRootReducer(allReducers: ReducersMapObject<GlobalApplicationState, any>, history: History) {
    return combineReducers<GlobalApplicationState>({
        ...allReducers,
        router: connectRouter(history)
    } as any);
}

// Create browser history to use in the Redux store
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') as string;
export const browserHistory = createBrowserHistory({ basename: baseUrl });

// Build middleware. These are functions that can process the actions before they reach the store.
const windowIfDefined = typeof window === "undefined" ? null : (window as any);
// If devTools is installed, connect to it
export const devToolsExtension = windowIfDefined && (windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__ as () => StoreEnhancer);

export function configureStore(history:History, initialState?: GlobalApplicationState) {

    const middlewares = [thunk, routerMiddleware(history), authenticatedRequestMiddleware];

    const allReducers = buildRootReducer(reducers, history);

    const store = createStore(allReducers, initialState,
        compose(
            applyMiddleware(...middlewares),
            isDevEnvironment ? ( !!devToolsExtension ? devToolsExtension() : DevTools.instrument() ) : <S>(next: StoreEnhancerStoreCreator<S>) => next
        )
    );

    // Enable Webpack hot module replacement for reducers
    if (!!(module as any).hot) {
        (module as any).hot.accept("./rootReducer", () => {
            const nextRootReducer = require("./rootReducer");
            store.replaceReducer(buildRootReducer(nextRootReducer, history));
        });
    }

    return store;
};

export const store = configureStore(browserHistory);