import * as React from 'react';
import shallowEquals from 'utils/shallowEquals'; 

interface componentProps {
    color?: string
    width?: string
    height?: string
    viewBox?: string,
    style?: React.CSSProperties
}

class Icon extends React.Component<componentProps, {}> {

    private defaultColor = "#000000"
    private defaultWidth = "24"
    private defaultHeight = "24"
    private defaultViewBox = `0 0 ${this.defaultWidth} ${this.defaultHeight}`

    public shouldComponentUpdate(nextProps: componentProps) {
        return !shallowEquals(this.props, nextProps)
    }

    public render() {
        let props = {
            ...this.props as any,
            fill: this.props.color || this.defaultColor,
            width: this.props.width || this.defaultWidth,
            height: this.props.height || this.defaultHeight,
            viewBox: this.props.viewBox || this.defaultViewBox
        };

        return (
            <svg {...props}>
                {this.props.children}
            </svg>
        )
    }
}

const iconify = (component: () => JSX.Element) => (props: componentProps) => {
    return (
        <Icon {...props}>
            {component()}
        </Icon>
    )
}

export abstract class Icons {
    static AddImage           = iconify(require('./src/add-image.jsx'))
    static Check              = iconify(require('./src/check.jsx'))
    static Close              = iconify(require('./src/close.jsx'))
    static Crop               = iconify(require('./src/crop.jsx'))
    static DragHandle         = iconify(require('./src/drag-handle.jsx'))
    static Edit               = iconify(require('./src/edit.jsx'))
    static VerticalOverflow   = iconify(require('./src/vertical-overflow.jsx'))
    static User               = iconify(require('./src/user.jsx'))
    static LineGraph          = iconify(require('./src/show-chart.jsx'))
    static Graph              = iconify(require('./src/insert-chart.jsx'))
    static Cloud              = iconify(require('./src/cloud.jsx'))
    static CheckBox           = iconify(require('./src/check-box-empty.jsx'))
    static CheckBoxChecked    = iconify(require('./src/check-box-checked.jsx'))
    static Devices            = iconify(require('./src/devices.jsx'))
    static Comment            = iconify(require('./src/comment.jsx'))
    static Pageview           = iconify(require('./src/pageview.jsx'))
    static Info               = iconify(require('./src/info.jsx'))
    static RssFeed            = iconify(require('./src/rss-feed.jsx'))
    static Sort               = iconify(require('./src/sort.jsx'))
    static AccessTime         = iconify(require('./src/access-time.jsx'))
    static Delete             = iconify(require('./src/deleteIcon.jsx'))
    static Lock               = iconify(require('./src/lock.jsx'))

    //export const CheckedCircleIcon = iconify('./src/checked-circle-icon') // this is a special case
}
