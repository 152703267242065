import { NotificationRange } from "modules/documents";
import { NotificationEvents, TenantNotificationSettings } from "modules/messaging";
import { Post } from "modules/posts/models";

// checks if ANY tenant notifications on post published are enabled (toggle === true)
export const isAnyTenantPostNotificationsEnabled = (notificationSettings: TenantNotificationSettings): boolean => {
    return notificationSettings.settings[NotificationEvents.PostPublishedEmail]?.toggle || 
        notificationSettings.settings[NotificationEvents.PostPublishedMobile]?.toggle || 
        notificationSettings.settings[NotificationEvents.PostPublishedSMS]?.toggle || 
        notificationSettings.settings[NotificationEvents.PostPublishedTeams]?.toggle;
};

// checks if ANY tenant notifications on event published are enabled
export const isAnyTenantEventNotificationsEnabled = (notificationSettings: TenantNotificationSettings): boolean => {
    return notificationSettings.settings[NotificationEvents.EventPublishedEmail]?.toggle || 
        notificationSettings.settings[NotificationEvents.EventPublishedMobile]?.toggle || 
        notificationSettings.settings[NotificationEvents.EventPublishedSMS]?.toggle || 
        notificationSettings.settings[NotificationEvents.EventPublishedTeams]?.toggle;
};

// Get the default NotificationRange tenant setting for all NotificationEvents 
// that's either on post published or event published
export const getDefaultNotificationSetting = (
    event: NotificationEvents, 
    notificationSettings: TenantNotificationSettings
): NotificationRange => {
    let range: NotificationRange = "none";

    if (notificationSettings.settings[event]?.toggle) {
        if (event === NotificationEvents.PostPublishedEmail)
            range = notificationSettings.defaultPostSettings?.emailOnPublish || "none";
        else if (event === NotificationEvents.PostPublishedMobile)
            range = notificationSettings.defaultPostSettings?.mobileOnPublish || "subscribers";
        else if (event === NotificationEvents.PostPublishedSMS)
            range = notificationSettings.defaultPostSettings?.smsOnPublish || "none";
        else if (event === NotificationEvents.PostPublishedTeams)
            range = notificationSettings.defaultPostSettings?.teamsOnPublish || "subscribers";
        else if (event === NotificationEvents.EventPublishedEmail)
            range = notificationSettings.defaultEventSettings?.emailOnPublish || "fallback";
        else if (event === NotificationEvents.EventPublishedMobile)
            range = notificationSettings.defaultEventSettings?.mobileOnPublish || "none";
        else if (event === NotificationEvents.EventPublishedSMS)
            range = notificationSettings.defaultEventSettings?.smsOnPublish || "fallback";
        else if (event === NotificationEvents.EventPublishedTeams)
            range = notificationSettings.defaultEventSettings?.teamsOnPublish || "none";
    }

    return range;
}

// checks if ANY tenant reminders are enabled
export const isAnyTenantRemindersEnabled = (notificationSettings: TenantNotificationSettings): boolean => {
    return notificationSettings.settings[NotificationEvents.PostReminderEmail]?.toggle || 
        notificationSettings.settings[NotificationEvents.PostReminderMobile]?.toggle || 
        notificationSettings.settings[NotificationEvents.PostReminderSMS]?.toggle || 
        notificationSettings.settings[NotificationEvents.PostReminderTeams]?.toggle;
};