import { AxiosPromise } from 'axios';

export class ApiRequest<T = any> {
    private pending: boolean;
    private resolved: boolean;
    private rejected: boolean;
    private promise: AxiosPromise<T>;

    constructor(promise: AxiosPromise<T>) {
        this.pending = true;
        this.resolved = false;
        this.rejected = false;
        this.promise = promise;

        this.promise
        .then((p) => {
            this.pending = false;
            this.resolved = true;
            return p;
        })
        .catch((p) => {
            this.pending = false;
            this.rejected = true;
            return p;
        })
    }

    public isLoading = () => {
        return this.pending;
    }

    public then = (cb: (response: T) => any) => {
        return this.promise.then(req => cb(req.data))
    }

    public catch = (cb: (error: any) => any) => {
        return this.promise.catch(err => cb(err))
    }
}
