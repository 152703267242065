import React, { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";

import CategoryTagChips from "modules/common/components/chips/categoryTagChips";
import Callout from "modules/common/components/callout";

import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import AddIcon from "@mui/icons-material/Add";


const CategoryTags: React.FunctionComponent<PropsWithRedux> = props => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [tags, setTags] = useState(props.tags);
  const [calloutOpen, setCalloutOpen] = useState(false);
  let effectiveCategoryTags = props.categoryTags.filter(t => t.name.toLowerCase() !== "uncategorized");

  return (
    <div>
      <div>Category tags</div>
      <div>
        <CategoryTagChips
          tagIds={props.tags}
          tags={props.categoryTags}
          onDelete={(tagId) => props.onChange(props.tags.filter((selectedTag) => selectedTag !== tagId))}
        />
        <Button
          variant="text"
          color="primary"
          startIcon={<AddIcon />}
          onClick={(event: any) => {
            setTags(props.tags);
            setAnchorEl(event.currentTarget);
            setCalloutOpen(true);
          }}
        >
          Edit category tags
        </Button>
        <Callout
          anchorEl={anchorEl}
          open={calloutOpen}
          setOpen={(toSet) => {
            props.onChange(tags);
            setCalloutOpen(toSet);
          }}
          footer={
            <div>
              <Button
                variant="text"
                color="primary"
                onClick={() => {
                  props.onChange([]);
                  setAnchorEl(null);
                }}
              >
                Clear all
              </Button>
            </div>
          }
        >
          <List disablePadding className="tags">
            {effectiveCategoryTags.map((tag) =>
              <ListItem key={tag.id} dense button onClick={() => {
                const hasSelectedTag: boolean = tags.findIndex((selectedTag) => selectedTag === tag.id) !== -1;
                if (hasSelectedTag)
                  setTags(tags.filter((selectedTag) => selectedTag !== tag.id));
                else
                  setTags(tags.concat([tag.id]));
              }}>
                <ListItemIcon className="callout-checkbox">
                  <Checkbox
                    edge="start"
                    tabIndex={-1}
                    disableRipple
                    size="small"
                    color="primary"
                    checked={tags.findIndex((selectedTag) => selectedTag === tag.id) !== -1}
                  />
                </ListItemIcon>
                <ListItemText primary={tag.name} />
              </ListItem>
            )}
          </List>
        </Callout>
      </div>
    </div>
  );
}


interface ComponentProps {
  tags: string[];
  onChange: (tags: string[]) => void;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    categoryTags: state.categoryTags.userCategoryTags
  })
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(CategoryTags);