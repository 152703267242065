import React, { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import * as actions from "modules/portalPages/actionCreator";
import { GlobalApplicationState } from "globalApplicationState";

import ModifyPortalPageDetailsDialog from "modules/portalPages/components/dialogs/modifyPortalPageDetailsDialog";
import ModifyPortalPageTitleDialog from "modules/portalPages/components/dialogs/modifyPortalPageTitleDialog";


import PluginWrapper from "./common/pluginWrapper";

import { Audience } from "modules/audiences/models";

import Button from "@mui/material/Button";

import EditIcon from "@mui/icons-material/Edit";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";


const PageDetails: React.FunctionComponent<{ input, meta, field } & PropsWithRedux> = props => {
  const [showModifyPortalPageDetails, setShowModifyPortalPageDetails] = useState(false);
  const [showModifyPortalPageTitle, setShowModifyPortalPageTitle] = useState(false);

  const matchedAudiences: Audience[] = (props.field.page.audiences || []).map((audienceId) => {
    const matchedAudience = !!props.audiences ? props.audiences.find((audience) => audience.id === audienceId) : null;
    if (!matchedAudience)
      return {
        id: "",
        displayName: "",
        description: "",
        enabled: false,
        type: ""
      };
    return matchedAudience;
  }).filter((audience) => !!audience.id);

  return (
    <PluginWrapper>
      <div className="page-details">
        <div className="page-detail">
          <div>Page title</div>
          <div>{props.field.page.title}</div>
        </div>
        <div className="page-detail">
          <div>Page URL</div>
          <div>/{props.field.page.url}</div>
        </div>
        <div className="page-detail">
          <div>Accessible to</div>
          {(!props.field.page.audiences || !props.field.page.audiences.length)
            ? <div>All users</div>
            : <div>{matchedAudiences.map(audience => audience.displayName).join(", ")}</div>
          }
        </div>
        <div className="page-details-info">
          <InfoOutlinedIcon />
          <div>Changes to Page Details will not be applied until the page is published.</div>
        </div>
        <Button
          variant="text"
          color="primary"
          startIcon={<EditIcon />}
          onClick={() => {
            if (props.field.page.isHome)
              setShowModifyPortalPageTitle(true);
            else
              setShowModifyPortalPageDetails(true);
          }}
          className="text-button"
        >
          <span>
            {props.field.page.isHome
              ? "Modify page title"
              : "Modify page details"
            }
          </span>
        </Button>
        <ModifyPortalPageDetailsDialog
          show={showModifyPortalPageDetails}
          portalPage={{ ...props.field.page, state: "Draft" }}
          onSuccess={() => props.showPublishChanges()}
          onClose={() => setShowModifyPortalPageDetails(false)}
        />
        <ModifyPortalPageTitleDialog
          show={showModifyPortalPageTitle}
          portalPage={{ ...props.field.page, state: "Draft" }}
          onSuccess={() => props.showPublishChanges()}
          onClose={() => setShowModifyPortalPageTitle(false)}
        />
      </div>
    </PluginWrapper>
  );
}

interface ComponentProps {}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    audiences: state.audiences.audiences
  }),
  {
    showPublishChanges: actions.showPublishChanges
  }
);
type PropsWithRedux = { input, meta, field } & ConnectedProps<typeof connector>;

export default connector(PageDetails);