import { PostAnalysisScores, PostAverages } from 'modules/posts/models';
import React from 'react';
import {injectIntl, IntlShape } from "react-intl";

import '../styles/basic-insights.sass'
import '../styles/graphs.sass'

import { IIndicatorDetail } from 'modules/common/components/authoring/models';
import IndicatorWrapper from 'modules/insights/components/indicatorWrapper';
import { fallbackSentimentLabel } from 'utils/readableLabelMapper';

const ReadingComprehensionAnalysis: React.FunctionComponent<ComponentProps> = (props) => {

    const renderTimeString = (value: string): string => {
        if (props.timeSpentReading) {
            let values = value.split(":")
            let minutes = parseInt(values[0])
            let seconds = parseInt(values[1])
            return `${minutes}m ${seconds}s`;
        }
        return ''
    }

    //https://readable.com/readability/flesch-reading-ease-flesch-kincaid-grade-level/
    const readabilityIndicator:IIndicatorDetail = {
        minScore: 1,
        maxScore: 100,
        minLabel: "Very difficult to read",
        maxLabel: "Very easy to read",
        score: Math.max(parseInt(props.postAnalysis.readabilityScore), 1),
        recommendedScore: props.isUsingTenantAverages ? Math.max(props.tenantAverages.averageReadabilityScore, 1) : Math.max(props.globalAverages.averageReadabilityScore, 1),
        scoreLabel: props.postAnalysis.readabilityText
    }

    const toneIndicator:IIndicatorDetail = {
        minScore: 0,
        maxScore: 100,
        minLabel: "Formal",
        maxLabel: "Conversational",
        score: props.postAnalysis.toneNumber,
        recommendedScore: props.isUsingTenantAverages ? props.tenantAverages.averageTone : props.globalAverages.averageTone,
        scoreLabel: props.postAnalysis.tone
    }

    //Newer property, use neutral value if score doesn't exist.
    const personalismIndicator: IIndicatorDetail = {
        minScore: 0,
        maxScore: 100,
        minLabel: "Impersonal",
        maxLabel: "Personal",
        score: props.postAnalysis.personalism ?? 50,
        recommendedScore: props.isUsingTenantAverages ? props.tenantAverages.averagePersonalism : props.globalAverages.averagePersonalism,
        scoreLabel: props.postAnalysis.personal
    }

    const sentimentIndicator: IIndicatorDetail = {
        minScore: 0,
        maxScore: 1,
        minLabel: "Negative",
        maxLabel: "Positive",
        score: props.postAnalysis.sentimentScore,
        recommendedScore: props.isUsingTenantAverages ? (props.tenantAverages.averageSentiment / 100) : (props.globalAverages.averageSentiment / 100),
        scoreLabel: props.postAnalysis.sentiment ?? fallbackSentimentLabel(props.postAnalysis.sentimentScore, 0, 1, "Negative", "Positive", false) //Fallback for posts older than around 6 months old.
    }

    return (
        <div style={{ marginTop: '10px'}}>
            <div>
                <span className='metric-title'>
                        Reach &amp; Readability
                </span>
                <div className='reading-analysis' style={{display: "flex", flexWrap: "wrap"}}>
                    <div style={{width: "46%", paddingRight: "3%"}}>
                        <IndicatorWrapper
                            title='Readability'
                            indicator={readabilityIndicator}
                            reverse={true}
                            useGlobalIcon={!props.isUsingTenantAverages}
                        />
                    </div>
                    <div style={{width: "46%", paddingLeft: "3%"}}>
                        <IndicatorWrapper
                            title='Sentiment'
                            indicator={sentimentIndicator}
                            reverse={false}
                            useGlobalIcon={!props.isUsingTenantAverages}
                        />
                    </div>
                    <div style={{width: "46%", paddingRight: "3%"}}>
                        <IndicatorWrapper
                            title='Tone'
                            indicator={toneIndicator}
                            reverse={true}
                            useGlobalIcon={!props.isUsingTenantAverages}
                        />
                    </div>
                    <div style={{width: "46%", paddingLeft: "3%"}}>
                        <IndicatorWrapper
                            title='Personalism'
                            indicator={personalismIndicator}
                            reverse={false}
                            useGlobalIcon={!props.isUsingTenantAverages}
                        />
                    </div>
                    <div style={{width: "46%", paddingRight: "3%"}}>
                        <span className='metric-title'>
                            Text &amp; Words
                        </span>
                        <div style={{paddingTop: "52px"}}>
                            <span>Estimated reading time</span>
                            <span style={{float: "right", paddingRight: "24px"}}>{renderTimeString(props.postAnalysis.readingTime)}</span>
                        </div>
                        <div style={{paddingTop: "4px"}}>
                            <span>Actual average reading time</span>
                            <span style={{float: "right", paddingRight: "24px"}}>{renderTimeString(props.timeSpentReading)}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

interface ComponentProps {
    intl: IntlShape;
    postAnalysis: PostAnalysisScores;
    timeSpentReading: string;
    isUsingTenantAverages: boolean;
    tenantAverages: PostAverages;
    globalAverages: PostAverages
}

export default injectIntl(ReadingComprehensionAnalysis)
