import { Action } from "redux";
import * as Actions from "./actions";

import { MessagingState, MessagingTabs } from "./models";

const DefaultState = {
    activeTab: MessagingTabs.Email,
}

const actionHandler: { [actionType: string]: (State: MessagingState, action: Action) => MessagingState } = {
    [Actions.SET_ACTIVE_MESSAGING_TAB]: (state: MessagingState, action: Actions.SetActiveMessagingTab) => {
        return {
            ...state,
            activeTab: action.activeTab
        }
    },
}

export const reducer = (prevState: MessagingState | undefined, action: Action) => {
    const state = prevState || DefaultState;

    const actionHandlerMethod = actionHandler[action.type];
    if (actionHandlerMethod) {
        return actionHandlerMethod(state, action);
    }

    return state;
};
