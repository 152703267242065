/* eslint-disable react/style-prop-object */
import * as React from 'react';
import { PlatformAnalyticsReport, PlatformStats } from '../models';
import { PieChart, IPieChartProps, IPieChartDataPoint } from './charts/pieChart';
import WindowResizeListener from 'modules/common/components/window-resize-listener';
import {FormattedMessage, injectIntl, IntlShape} from "react-intl";
import {connect, ConnectedProps} from "react-redux";
import { GlobalApplicationState } from 'globalApplicationState';
import Loading from 'modules/common/components/loading';
import EmptyDataMessage from './emptyDataMessage';
import DownloadIcon from './downloadIconSvg';

class ClientBreakdown extends React.PureComponent<PropsWithRedux, {}> {

    private _chart: PieChart;
    private _chartElement: HTMLElement;

    private _colors = ['#1B386B', '#36404A', '#356531', '#169BD5', '#E6911A', '#599FA0'];

    public componentDidMount() {
        this._chart = new PieChart(this.getChartState());
    }

    public componentDidUpdate() {
        if (this._chart) {
            this._chart.update(this.getChartState());
        }
    }

    public componentWillUnmount() {
        if (this._chart) {
            this._chart.destroy();
        }
    }

    public groupFlutterWithNative(stats?: PlatformStats[]) {
        let legacyAndroidCount = stats?.find(e => e.platformName === 'Android Legacy')?.readCount;
        let legacyAppleCount = stats?.find(e => e.platformName === 'Apple Legacy')?.readCount;
        let legacyWebCount = stats?.find(e => e.platformName === 'Web Legacy')?.readCount;

        if (typeof stats !== undefined) {
            stats = this.addOrUpdatePlatform(stats!, legacyAndroidCount!, "Android");
            stats = this.addOrUpdatePlatform(stats!, legacyAppleCount!, "Apple");
            stats = this.addOrUpdatePlatform(stats!, legacyWebCount!, "Web");
        }

        stats = stats?.filter(e => e.platformName !== 'Android Legacy' && e.platformName !== 'Apple Legacy' && e.platformName !== 'Web Legacy');
        return stats ?? [];
    }

    public addOrUpdatePlatform(stats: PlatformStats[], count: number, platform: string) {
        if (count <= 0 || count === undefined) {
            return stats;
        }

        const index = stats.findIndex(e => e.platformName === platform)
        if (index < 0) {
            stats.push({ platformName: platform, readCount: count} as PlatformStats);
        } else {
            stats[index].readCount = stats[index].readCount + count;
        }

        return stats;
    }

    public render() {
        let stats = this.props.platformStats || [];
        let total = stats.reduce((sum, point) => sum + point.readCount, 0);

        return <div id={PlatformAnalyticsReport.channelUsage} className="analytics-widget">
                <div className='downloadIcon' id={`${PlatformAnalyticsReport.channelUsage}-downloadIcon`} style={{float:'right'}}>  
                    <DownloadIcon
                        downloadExcel={this.props.downloadExcel}
                        downloadImage={this.props.downloadImage}
                    />
                </div>
            <div className="header">
                Channel Usage
                
            </div>
            <div className="body">
                {
                    this.props.loading ?
                    <div style={{position: "relative", top: "55px"}}>
                        <Loading/>
                    </div> :
                    <div className="app-usage">
                        <div className="chart-wrapper">
                            <div ref={(e) => this._chartElement = e! }></div>
                        </div>
                        {this.props.platformStats && this.props.platformStats.length > 0 ?
                        <div className="legend">
                            <header><FormattedMessage id="insights.readsByPlatform" defaultMessage="Reads by Platform" /></header>
                            <ul>
                                {this.groupFlutterWithNative(stats).map((data, index) => {
                                    return <li key={data.platformName}>
                                        <span className="color-indicator" style={{ background: this._colors[index] }}></span>
                                        {data.platformDescription}: {((data.readCount / total) * 100).toFixed(1)}%
                                    </li>
                                })}
                            </ul>
                        </div>
                        : <EmptyDataMessage/>}
                    </div>   
                }
                <WindowResizeListener onChange={this.handleWindowResize} />
            </div>
            <footer>
            </footer>
        </div>
    }

    private getChartState = (): IPieChartProps => {
        return {
            el: this._chartElement,
            diameter: 150,
            innerDiameter: 100,
            data: (this.groupFlutterWithNative(this.props.platformStats ?? [])).map((count, i) => {
                return {
                    label: count.platformName,
                    value: count.readCount,
                    color: this._colors[i],
                    locale: this.props.locale
                } as IPieChartDataPoint;
            })
        } as IPieChartProps;
    }

    private handleWindowResize = () => {
        this._chart.update(this.getChartState());
    }
}

interface ComponentProps {
    loading?: boolean
    platformStats: PlatformStats[] | null;
    intl: IntlShape;
    downloadExcel: () => void;
    downloadImage: () => void;
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps)=>({
        ...ownProps,
        locale: state.localization.locale
    })
);
type PropsWithRedux = ConnectedProps<typeof connector>;
  
export default injectIntl(connector(ClientBreakdown));
