import React from "react";

import { IIndicatorDetail } from "modules/common/components/authoring/models";
import IndicatorWrapper from "./indicatorWrapper";

interface IReadabilityAndReachIndicatorsProps {
    readabilityIndicator?: IIndicatorDetail;
    toneIndicator?: IIndicatorDetail;
    personalismIndicator?: IIndicatorDetail;
    sentimentIndicator?: IIndicatorDetail;
    isUsingTenantAverages?: boolean;
}

const ReadabilityAndReachIndicators: React.FunctionComponent<IReadabilityAndReachIndicatorsProps> = ({
    readabilityIndicator,
    toneIndicator,
    sentimentIndicator,
    personalismIndicator,
    isUsingTenantAverages = false
}) => {
    return (
        <>
            {readabilityIndicator && <IndicatorWrapper reverse title={"Readability"} indicator={readabilityIndicator} useGlobalIcon={!isUsingTenantAverages} />}
            {toneIndicator && <IndicatorWrapper reverse title="Tone" indicator={toneIndicator} useGlobalIcon={!isUsingTenantAverages} />}
            {personalismIndicator && <IndicatorWrapper title="Personalism" indicator={personalismIndicator} useGlobalIcon={!isUsingTenantAverages} />}
            {sentimentIndicator && <IndicatorWrapper title="Sentiment" indicator={sentimentIndicator} useGlobalIcon={!isUsingTenantAverages} />}
        </>
    );
}

export default ReadabilityAndReachIndicators;
