import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import * as actions from "../../actionCreator";
import { GlobalApplicationState } from "globalApplicationState";

import InfoHover from "modules/common/components/hovers/infoHover";
import LoadingOverlay from "modules/common/components/loadingOverlay";

import {
    NewsletterDetails,
    NewsletterFutureIssueDetailModel,
    NewsletterTemplateImageType,
    SaveNewsletterIssueModelStateErrors,
    UploadIssueImageModel,
    HEADER,
    FOOTER
} from "../../models";

import { HeaderFooter } from "./headerFooter";
import IssueDetails from "./issueDetails";
import PinnedContent from "./pinnedContent";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { PickerLocalization } from "modules/common/components/pickerLocalization";

import CloseIcon from "@mui/icons-material/Close";
import SyncIcon from "@mui/icons-material/Sync";

import moment from "moment";
import _ from "lodash";

import "modules/common/components/authoring/authoring.sass";
import "../../styles/dialogs.sass";


class EditQueuedIssue extends React.Component<PropsWithRedux, ComponentState> {
    constructor(props: PropsWithRedux) {
        super(props);
        this.state = {};
    }

    public componentDidMount() {
        moment.locale("en");

        if (this.props.isAddingNewIssue) {
            this.onAddFutureIssue();
        } else if (!!this.props.editingIssueDateLocal) {
            this.onEditFutureIssue(this.props.editingIssueDateLocal!);
        }
    }

    public render() {
        const { issue } = this.state;
        const { isAddingNewIssue } = this.props;

        return (
            <React.Fragment>
                <Dialog disableEnforceFocus open={!this.state.isLoadingIssue && !!issue} maxWidth={false} scroll="paper" onClose={this.props.onCloseIssue} classes={{ paper: "newsletter-dialog newsletter-issue-dialog" }}>
                    <DialogTitle className="newsletter-dialog-title">
                        <div>
                            {isAddingNewIssue && <span>New issue</span>}
                            {!isAddingNewIssue && <span>Edit issue</span>}
                            <InfoHover>Changing settings for a specific issue will not affect succeeding issues. Edit the newsletter to change the settings of this and all succeeding issues.</InfoHover>
                        </div>
                        <IconButton onClick={this.props.onCloseIssue} size="large">
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <PickerLocalization>
                            <div className="authoring-page">
                                {!this.state.isLoadingIssue && !!issue &&
                                    <React.Fragment>
                                        <IssueDetails issue={issue} issueFromServer={this.state.issueFromServer} issueModelErrors={this.state.issueModelErrors} newsletterTitle={this.props.newsletter.title} onChange={this.onChangeIssue} />
                                        <Divider />
                                        <HeaderFooter
                                            issue={issue}
                                            newsletter={this.props.newsletter}
                                            footerImage={this.state.footerImage || null}
                                            headerImage={this.state.headerImage || null}
                                            isLoadingFooterImage={this.state.isLoadingFooterImage}
                                            isLoadingHeaderImage={this.state.isLoadingHeaderImage}
                                            onChange={this.onChangeIssue}
                                            onSetImage={this.onChangeImage}
                                            onError={this.props.onError} />
                                        <Divider />
                                        <PinnedContent issue={issue} onChange={this.onChangeIssue} />
                                    </React.Fragment>
                                }
                            </div>
                        </PickerLocalization>
                    </DialogContent>
                    {!!issue && <DialogActions className="newsletter-dialog-actions">
                        <div>
                            {!isAddingNewIssue && this.props.newsletter.recurrenceType !== "Adhoc" &&
                                <Button
                                    variant="text"
                                    color="primary"
                                    startIcon={<SyncIcon />}
                                    disabled={!issue.isCustomized}
                                    onClick={this.onResetIssue}
                                >
                                    Reset issue to original settings
                                </Button>
                            }
                        </div>
                        <div>
                            <Button
                                variant="text"
                                color="primary"
                                onClick={this.props.onCloseIssue}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={!issue.emailSubject || (isAddingNewIssue && !issue.overriddenIssueDateTime) || (!isAddingNewIssue && !(!!this.state.hasHeaderImageChanged || !!this.state.hasFooterImageChanged || !_.isEqual(this.state.issue, this.state.issueFromServer)))}
                                onClick={this.onSaveIssue}
                            >
                                Save
                            </Button>
                        </div>
                    </DialogActions>}
                    <LoadingOverlay show={!!this.state.isSaving} styles={{ paddingTop: 150 }} />
                </Dialog>
                <LoadingOverlay show={!!this.state.isLoadingIssue} styles={{ paddingTop: 150 }} />
            </React.Fragment>
        );
    }

    private onAddFutureIssue = () => {
        const { newsletter } = this.props;

        const futureDate: string = moment().add(1, "day").set("hours", 12).set("minutes", 0).toISOString();

        const newFutureIssue: NewsletterFutureIssueDetailModel = {
            issueDateLocal: "",
            issueType: "Adhoc",
            newsletterId: newsletter.id,
            tenantId: newsletter.tenantId,
            emailPreheader: newsletter.emailPreheader,
            emailSubject: newsletter.emailSubject,
            emailTemplateFooterText: newsletter.emailTemplateFooterText,
            emailTemplateHeaderText: newsletter.emailTemplateHeaderText,
            bodyText: newsletter.bodyText,
            featuredContent: [],
            overriddenIssueDateTime: futureDate,
            status: "Future",
            emailTemplateFooterImageIsCustomized: false,
            emailTemplateHeaderImageIsCustomized: false,
            isCustomized: false,
            sendDateTime: ""
        };

        this.setState({ issue: newFutureIssue, issueFromServer: newFutureIssue, isLoadingIssue: false });

        this.loadHeaderAndFooterImage(newFutureIssue);
    }

    private onEditFutureIssue = (issueDateLocal: string) => {
        this.setState({ isLoadingIssue: true });

        this.props.getFutureIssueDetails(this.props.newsletter.id, issueDateLocal)
            .then((issueDetails) => {
                this.setState({
                    issue: issueDetails,
                    issueFromServer: issueDetails,
                    isLoadingIssue: false
                });
                this.loadHeaderAndFooterImage(issueDetails);
            });
    }

    private loadHeaderAndFooterImage = (issueDetails: NewsletterFutureIssueDetailModel) => {
        if (issueDetails.emailTemplateHeaderImageIsCustomized) {
            this.setState({ isLoadingHeaderImage: true });
            this.props.getIssueImage(this.props.newsletter.id, issueDetails.issueDateLocal, HEADER).then((image) => {
                this.setState({ headerImage: image, isLoadingHeaderImage: false });
            });
        } else if (this.props.newsletter.emailTemplateHeaderImageIsCustomized) {
            this.setState({ isLoadingHeaderImage: true });
            this.props.getNewsletterImage(this.props.newsletter.id, HEADER).then((image) => {
                this.setState({ headerImage: image, isLoadingHeaderImage: false });
            });
        } else {
            this.setState({ headerImage: null, isLoadingHeaderImage: false });
        }

        if (issueDetails.emailTemplateFooterImageIsCustomized) {
            this.setState({ isLoadingFooterImage: true });
            this.props.getIssueImage(this.props.newsletter.id, issueDetails.issueDateLocal, FOOTER).then((image) => {
                this.setState({ footerImage: image, isLoadingFooterImage: false });
            });
        }
        else if (this.props.newsletter.emailTemplateFooterImageIsCustomized) {
            this.setState({ isLoadingFooterImage: true });
            this.props.getNewsletterImage(this.props.newsletter.id, FOOTER).then((image) => {
                this.setState({ footerImage: image, isLoadingFooterImage: false });
            });
        }
        else {
            this.setState({ footerImage: null, isLoadingFooterImage: false });
        }
    }

    private onChangeIssue = (changed: Partial<NewsletterFutureIssueDetailModel>) => {
        this.setState({ issue: { ...this.state.issue!, ...changed } });
    }

    private onResetIssue = () => {
        this.setState({ isSaving: true });
        this.props.onResetIssue().then(() => {
            this.setState({ isSaving: false });
            this.props.onCloseIssue();
        });
    }

    private onSaveIssue = (): Promise<void> => {
        this.setState({ isSaving: true })
        return this.props.saveIssue(this.state.issue!)
            .then((result) => {
                if (!!result.success) {
                    return this.onSaveHeaderFooterImage(result.success);
                } else if (!!result.error) {
                    const firstError = _.first(_.values(result.error.ModelState));
                    !!firstError && this.props.onError(firstError[0]);
                    this.setState({ isSaving: false, issueModelErrors: result.error.ModelState });
                }
            }).then(() => {
                this.setState({ isSaving: false, issueModelErrors: null });
                this.props.onSuccess('Issue saved');
                this.props.onIssueChanged();
            });
    }

    private onChangeImage = (imageType: NewsletterTemplateImageType, image?: File) => {
        let reader = new FileReader();
        if (imageType === HEADER && !!image) {
            reader.readAsDataURL(image);
            reader.onload = (): void => {
                this.setState({
                    headerImage: image,
                    hasHeaderImageChanged: true,
                    headerImageBase64: reader.result?.toString()
                });
            };
        }
        else if (imageType === FOOTER && !!image) {
            reader.readAsDataURL(image);
            reader.onload = (): void => {
                this.setState({
                    footerImage: image,
                    hasFooterImageChanged: true,
                    footerImageBase64: reader.result?.toString()
                });
            };
        }
    }

    private onSaveHeaderFooterImage = (issue: NewsletterFutureIssueDetailModel): Promise<void> => {
        return new Promise((resolve) => {
            const {
                hasFooterImageChanged,
                hasHeaderImageChanged,
                headerImageBase64,
                footerImageBase64,
                issueFromServer,
                headerImage,
                footerImage
            } = this.state;

            if (hasFooterImageChanged || hasHeaderImageChanged) {
                if (hasHeaderImageChanged) {
                    if (!headerImage && !!issueFromServer && issueFromServer.emailTemplateHeaderImageIsCustomized)
                        return this.props.deleteIssueImage(this.props.newsletter.id, issue.issueDateLocal, HEADER).then(() => resolve());
                }

                if (hasFooterImageChanged) {
                    if (!footerImage && !!issueFromServer && issueFromServer.emailTemplateFooterImageIsCustomized)
                        return this.props.deleteIssueImage(this.props.newsletter!.id, issue.issueDateLocal, FOOTER).then(() => resolve());
                }

                const uploadImageModel: UploadIssueImageModel = {
                    newsletterId: this.props.newsletter!.id,
                    headerImage: headerImageBase64,
                    footerImage: footerImageBase64,
                    issueDate: issue.issueDateLocal
                };

                return this.props.uploadIssueHeaderFooterImage(uploadImageModel).then(() => resolve());
            } else {
                resolve();
            }
        });
    }
}

interface ComponentProps {
    newsletter: NewsletterDetails;
    isAddingNewIssue: boolean;
    editingIssueDateLocal: string | null;
    onCloseIssue: () => any;
    onIssueChanged: () => any;
    onResetIssue: () => Promise<boolean>;
    onError: (message: string) => any;
    onSuccess: (message: string) => any;
}

interface ComponentState {
    issue?: NewsletterFutureIssueDetailModel;
    issueFromServer?: NewsletterFutureIssueDetailModel;
    isLoadingIssue?: boolean;
    issueModelErrors?: SaveNewsletterIssueModelStateErrors | null;

    headerImage?: File | null;
    headerImageBase64?: string;
    isLoadingHeaderImage?: boolean;
    hasHeaderImageChanged?: boolean;

    footerImage?: File | null;
    footerImageBase64?: string;
    isLoadingFooterImage?: boolean;
    hasFooterImageChanged?: boolean;

    isSaving?: boolean;
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ownProps,
    {
        getFutureIssueDetails: actions.getFutureIssueDetails,
        getNewsletterImage: actions.getNewsletterImage,
        getIssueImage: actions.getIssueImage,
        deleteIssueImage: actions.deleteIssueImage,
        uploadIssueImage: actions.uploadIssueImage,
        uploadIssueHeaderFooterImage: actions.uploadIssueHeaderFooterImage,
        saveIssue: actions.saveIssue
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(EditQueuedIssue);

