import { NavigationClient } from "@azure/msal-browser";

/**
 * This is an example for overriding the default function MSAL uses to navigate to other urls in your webpage
 * Sample taken from https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/performance.md
 */
export class CustomNavigationClient extends NavigationClient{
    private _history : any;

    constructor(history) {
        super();
        this._history = history;
        
    }

    /**
     * Navigates to other pages within the same web application
     * You can use the useHistory hook provided by react-router-dom to take advantage of client-side routing
     * @param url 
     * @param options 
     */
    navigateInternal = async (url, options) => {
        const relativePath = url.replace(window.location.origin, '');
        if (options.noHistory) {
            this._history.replace(relativePath);
        } else {
            this._history.push(relativePath);
        }

        return false;
    }
}