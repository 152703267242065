import * as React from "react";

import { CommentingTypes } from "../../../models";

import Collapse from "@mui/material/Collapse";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";


class CommentingEnabled extends React.Component<ComponentProps, ComponentState> {
    public render() {
        const { disabled = false } = this.props;
        
        return (
            <React.Fragment>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: 15
                }}>
                    <div>Enable Comments</div>
                    <Switch
                        color="primary"
                        checked={this.props.commentingEnabled}
                        onChange={this.onToggleBreakingTime}
                        disabled={disabled}
                    />
                </div>
                <Collapse in={this.props.commentingEnabled} style={{ overflow: "hidden" }}>
                    <FormControl fullWidth size="small">
                        <Select
                            variant="outlined"
                            value={this.props.commentingType}
                            onChange={this.onChangeCommentingType}
                            className="type-select"
                            disabled={disabled}
                        >
                            <MenuItem value="standard">Standard</MenuItem>
                            <MenuItem value="anonymous">Anonymous</MenuItem>
                        </Select>
                    </FormControl>
                </Collapse>
            </React.Fragment>
        );
    }

    private onChangeCommentingType = (event) => {
        this.props.onChangeCommentingType(event.target.value);
    }

    private onToggleBreakingTime = () => {
        this.props.onChangeCommentingEnabled(!this.props.commentingEnabled);
    }
}


interface ComponentProps {
    commentingEnabled: boolean;
    commentingType: CommentingTypes;
    onChangeCommentingEnabled: (commentingEnabled: boolean) => void;
    onChangeCommentingType: (commentingType: CommentingTypes) => void;
    disabled?: boolean;
}

interface ComponentState { }

export default CommentingEnabled;
