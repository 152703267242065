import React from "react";

import { EmailValidation } from "../../../models";

import Disclaimer from "modules/common/components/disclaimer";
import NumberStat from "modules/common/components/numberStat";

import Button from "@mui/material/Button";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";


const CSVValidation: React.FunctionComponent<ComponentProps> = props => {
  return (
    <div>
      <Disclaimer
        icon={<CheckCircleOutlineIcon className="successfully-scanned-icon" />}
        text={
          <React.Fragment>
            <span className="successfully-scanned">CSV successfully scanned!</span> 
            Only emails in the CSV that exist in your directory and match a user profile or a user invite will be added to this audience. 
            Download scan results <u style={{cursor: "pointer", color: "#6795BC"}} onClick={props.downloadBulkReport}>here</u>.
          </React.Fragment>
        }
      />
      <div className="stats-row">
        <NumberStat number={props.validationResults.validEmailCount} title="Emails in the CSV that exist in your directory" />
        <NumberStat number={props.validationResults.invalidEmailCount} title="Cannot be found in your directory" />
      </div>
      <Button variant="text" color="primary" startIcon={<ChevronLeftIcon />} onClick={props.onReplaceCSVFile}>Replace CSV file</Button>
    </div>
  );
}

interface ComponentProps {
  validationResults: EmailValidation;
  onReplaceCSVFile: () => void;
  downloadBulkReport: () => void;
}

export default CSVValidation;