import React from "react";
import { Divider, IconButton } from "@mui/material";
import { Add, FiberManualRecord } from "@mui/icons-material";

import "./styles/hoverAddButton.sass";

interface IHoverAddButtonProps {
    containerStyle?: React.CSSProperties;
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const HoverAddButton: React.FunctionComponent<IHoverAddButtonProps> = ({ onClick, containerStyle }) => {
    return (
        <div className="hover-add-btn-container" style={containerStyle}>
            <Divider className="child line" sx={{ borderColor: "primary.main" }} />
            <IconButton id="hover-add-btn" className="child action" color="primary" onClick={onClick}>
                <FiberManualRecord color="primary" className="circle" />
                <Add className="add" />
            </IconButton>
            <Divider className="child line" sx={{ borderColor: "primary.main" }} />
        </div>
    );
};
