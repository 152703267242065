import TextBubble from 'modules/common/components/textBubble';
import { lcidMappings } from 'modules/resources/models';
import InputField from 'modules/users/components/user-field-components/inputField';
import React from 'react';
import TextAreaLanguageSelector from '../../common/components/textAreaLanguageSelector';
import { Blast, NotificationEvents } from '../models';
import { connect, ConnectedProps } from "react-redux"
import { GlobalApplicationState } from "globalApplicationState"
import Select from 'react-select';
import { messagingApi } from 'api/instances';
import { ValueLabel } from 'modules/common/components/selectFilter';
import { errorAlert } from 'utils/notyPopups';
import AudienceCalloutSelector from 'modules/audiences/components/audienceCalloutSelector';
import { Audience } from 'modules/audiences';
import { Typography } from '@mui/material';

interface ComponentProps {
    closeModal: () => any;
    onSelectAudienceClick: () => any;
    textBubbles: TextBubble[];
    selectedAudiences: string[];
    onBlastSuccess: ()  => any;
    applyAudienceChanges: any;
    previousAudiences: string[];
    userAudiences: Audience[];
 }

interface ComponentState { 
    showAudiencePicker: boolean;
    translatedContent: lcidMappings;
    sending: boolean;
    textBlastSelected: boolean;
    massConsentSelected: boolean;
    reinviteSelected: boolean;
    anchorEl: HTMLButtonElement | null;
}

interface TextBubble {
    id: string;
    name: string;
}
class SmsBlastModal extends React.Component<PropsWithRedux, ComponentState> {
    
    constructor(props) {
        super(props);
        this.state = {
            showAudiencePicker: false,
            translatedContent: { },
            sending: false,
            textBlastSelected: false,
            massConsentSelected: false,
            reinviteSelected: false,
            anchorEl: null,
        }
    }

    public render() {

        const disabledButton = this.props.notificationSettings[NotificationEvents.MassSMS].toggle ? "apply-btn-2" : "disabled-btn-2"

        const selectStyles = {
            container:(provided) => ({
                ...provided,
                width: "100%"
            }),
            control: (provided) => ({
                ...provided,
                width: "100%",
                marginTop: 20,
                marginBottom: 20
            }),
            menu: (provided) => {
                return {
                    ...provided,
                    width: "100%",
                }
            }        
        };

        const smsOptions = [
            { value: "Text Blast", label: "Text Blast"},
            { value: "Mass Consent", label: "Mass Consent Invitation"},
            { value: "Reinvite", label: "Reinvite Opted-out Users"}
        ]


        const maxTextLength = 160;
        const suggestions = [
            {id: "Reply YES ...", name: "Reply YES ..."},
            {id: "Reply STOP ...", name: "Reply STOP ..."},
            {id: "Reply HELP ...", name: "Reply HELP ..."},
            {id: "SMS charges may apply", name: "SMS charges may apply"},
            {id: "{{newLine}}", name: "{{newLine}}"},
            {id: "{{userPreferredName}}", name: "{{userPreferredName}}"},
            {id: "{{userFirstName}}", name: "{{userFirstName}}"},
            {id: "{{userLastName}}", name: "{{userLastName}}"},
            {id: "{{supportEmail}}", name: "{{supportEmail}}"},

        ] as TextBubble[]

        return (
            <div className="email-blast-container">
                <div className="email-blast-header">
                    <h4 style={{ float: "left" }}>Create SMS Blast</h4>{" "}
                    <p style={{ float: "right", cursor: "pointer" }} onClick={() => this.props.closeModal()}>
                        ✕
                    </p>
                </div>

                <div className="email-blast-fields">
                    <InputField
                        title={"Sender"}
                        isDisabled={true}
                        inputType={"text"}
                        description={
                            "This number appears as the Sender once the text message is received. Contact Sparrow to customize your number."
                        }
                        placeholder={"+1 833 684 1574"}
                    />

                    <div className="input-row">
                        <AudienceCalloutSelector
                            handlePopupClick={this.state.anchorEl}
                            applyAudienceChanges={this.props.applyAudienceChanges}
                            previousAudiences={this.props.selectedAudiences}
                            userAudiences={this.props.userAudiences}
                            textBubbles={this.props.textBubbles}
                            onSelectAudienceClick={this.props.onSelectAudienceClick}
                            handleClose={this.handleClose}
                        />
                    </div>

                    <div className="input-row">
                        <p className="profile-input-label">SMS Type</p>
                        <div className="label-description-block">
                            <Select options={smsOptions} styles={selectStyles} onChange={this.handleSMSType} />
                        </div>
                    </div>

                    <div className="input-row">
                        <p className="profile-input-label">Body</p>
                        <div className="label-description-block">
                            <TextAreaLanguageSelector
                                returnTranslatedContent={this.getTranslatedContent}
                                maxTextLength={maxTextLength}
                                disabled={!this.props.notificationSettings[NotificationEvents.MassSMS].toggle}
                            />
                            <div>
                            <Typography variant='caption'>
                                Max character length {maxTextLength}
                            </Typography>
                            </div>
                        </div>
                    </div>

                    <div className="input-row">
                        <p className="profile-input-label">Suggestions</p>
                        <div className="label-description-block">
                            <TextBubble list={suggestions} />
                            <p className="description-text">
                                Use suggestions in brackets (ex. &#123;&#123;First Name&#125;&#125;) to personalize your message for each
                                recipient. &#123;&#123;newLine&#125;&#125; is used to insert a line space between sentences. Do not change
                                the texts within suggested items to correctly use them.
                            </p>
                        </div>
                    </div>
                    <div className="clearfix">
                        <button
                            disabled={!this.props.notificationSettings[NotificationEvents.MassSMS].toggle}
                            className={disabledButton}
                            style={{ float: "right", marginBottom: 10 }}
                            onClick={() => this.SendBlast()}
                        >
                            SEND
                        </button>
                        {!this.props.notificationSettings[NotificationEvents.MassSMS].toggle && (
                            <div className="blast-disabled-message">
                                SMS Blasts are currently disabled. To send a blast please re-enable them in the settings.
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
    
    private handleClose = () => {
        this.setState( { anchorEl: null });
    };

    private getTranslatedContent = (translatedContent: any) => {
        this.setState({
            ...this.state,
            translatedContent: translatedContent
        })
    }
    
    private SendBlast = () => {
        const blast = {
            audienceIds: this.props.selectedAudiences,
            translatedBody: this.state.translatedContent,
        } as Blast;

        this.setState({
            ...this.state,
            sending: true,
        })
        
        if (this.state.textBlastSelected) {
            messagingApi.SendSMSBlast(blast)
            .then((response) => {
                this.setState({
                    ...this.state,
                    sending: false
                })
                if (response === "Success") {
                    this.props.onBlastSuccess()
                }
            })
            .catch((error) => {
                errorAlert(`There was an error processing your email blast`, 3000)
                this.setState({
                    ...this.state,
                    sending: false
                })
            })
        } else {
            messagingApi.MassSendConsent(blast)
            .then((response) => {
                this.setState({
                    ...this.state,
                    sending: false
                })
                if (response === "Success") {
                    this.props.onBlastSuccess()
                }
            })
            .catch((error) => {
                errorAlert(`There was an error processing your email blast`, 3000)
                this.setState({
                    ...this.state,
                    sending: false
                })
            })
        }
    }

    private handleSMSType = (o: ValueLabel) => {
        switch(o.value) {
            case "Text Blast": 
                this.setState({...this.state, textBlastSelected: true});
                break;
            case "Mass Consent":
                this.setState({...this.state, massConsentSelected: true});
                break;
            case "Reinvite": 
                this.setState({...this.state, reinviteSelected: true});
                break;
            default:
                break;
        }
        
    }
}


const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        tenantSettings: state.settings.tenantSettings,
        lcidMappings: state.resources.lcidMappings,
        notificationSettings: state.settings.notificationSettings.settings,
    }),
    {
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(SmsBlastModal)
