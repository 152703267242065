import * as React from "react";
import { withRouter, RouteComponentProps, Prompt } from "react-router";
import { replace } from "react-router-redux";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import * as actions from "../actionCreator";
import { SettingsTab } from "../models";
import LoadingSpinner from "modules/common/components/loadingSpinner";
import GeneralSettingsForm from "./generalSettingsForm";
import ThemeSettingsForm from "./themeSettingsForm";
import LanguageSettingsForm from "./languageSettingsForm";
import FeatureControlsSettingsForm from "./featureControlsSettingsForm";
import ExternalSourcesSettingsForm from "./externalSourcesSettingsForm";
import MessagingCenterForm from "./messagingCenterForm";

import BasePage from "pages/common/basePage";
import Header from "pages/common/header";
import MainContent from "pages/common/mainContent";
import { ContentSettings } from "./contentSettings";
import { useReadabilitySettings } from "modules/common/hooks/data/useReadabilitySettings";
import { cognitiveServicesApi } from "api/instances";

interface ITab {
    id: string;
    name: string;
    content: JSX.Element;
    tab: SettingsTab;
    onSave?: () => Promise<void>;
}

const SettingsPageComponent: React.FunctionComponent<PropsWithRedux> = ({
    tenantSettings,
    fetching,
    location,
    match,
    errorMsg,
    pristine,
    saving: propsSaving,
    modifyURL,
    saveTenantSettings,
    resetTenantSettingsToPristine,
    getTenantSettings,
}) => {
    const HASH_TAB_MAPPING = {
        general: SettingsTab.General,
        theme: SettingsTab.Theme,
        language: SettingsTab.Language,
        featureControls: SettingsTab.Features,
        externalSources: SettingsTab.External,
        newsletter: SettingsTab.Newsletter,
        messaging: SettingsTab.Messaging,
        contentSettings: SettingsTab.ContentSettings
    };

    // readability settings stuff
    const [saving, setSaving] = React.useState<boolean>(false);
    const [activeSettingsTab, setActiveSettingsTab] = React.useState<SettingsTab>();
    const {
        options: readabilityOptions,
        settings: readabilitySettings,
        onChange: onChangeReadabilitySettings,
        defaults: defaultReadabilitySettings,
        resetToDefault: resetReadabilitySettings
    } = useReadabilitySettings();

    // this doesn't work
    const setStartingTab = React.useCallback(() => {
        let hash = location.hash.substring(1);
        setActiveSettingsTab(HASH_TAB_MAPPING[hash] || HASH_TAB_MAPPING.general);
    }, [location.hash, HASH_TAB_MAPPING]);

    React.useEffect(() => {
        if (!tenantSettings) {
            getTenantSettings();
        }

        (window as any).scrollTo(0, 0);
        setStartingTab();
    }, [getTenantSettings, setStartingTab, tenantSettings]);

    const onSaveReadabilitySettings = async () => {
        setSaving(true);

        try {
            if (readabilitySettings)
                await cognitiveServicesApi.saveReadabilitySettings(readabilitySettings);
        } catch (err) {

        } finally {
            setSaving(false);
        }
    }

    let working = saving || fetching || propsSaving;

    let tabs: ITab[] = [
        { id: "General", name: "General", content: <GeneralSettingsForm />, tab: SettingsTab.General, onSave: undefined },
        { id: "Theme", name: "Theme", content: <ThemeSettingsForm />, tab: SettingsTab.Theme },
        { id: "Language", name: "Time & Language", content: <LanguageSettingsForm />, tab: SettingsTab.Language },
        { id: "Features", name: "Feature Controls", content: <FeatureControlsSettingsForm />, tab: SettingsTab.Features },
        { id: "External", name: "External Post Sources", content: <ExternalSourcesSettingsForm />, tab: SettingsTab.External },
        { id: "Messaging", name: "Notifications & Reminders", content: <MessagingCenterForm />, tab: SettingsTab.Messaging }
    ];

    //feature toggle
    if (tenantSettings?.showFeatures?.externalPostSources) {
        tabs.push({ id: "ExternalSources", name: "External Sources", content: <ExternalSourcesSettingsForm />, tab: SettingsTab.External });
    }

    if (tenantSettings.showFeatures.postSmartContentEnabledForTenant) {
        tabs.push({
            id: "ContentSettings",
            name: "Content Settings",
            content:
                <ContentSettings
                    defaultReadabilitySettings={defaultReadabilitySettings}
                    readabilityOptions={readabilityOptions}
                    readabilitySettings={readabilitySettings}
                    onChangeReadabilitySettings={onChangeReadabilitySettings}
                />,
            onSave: onSaveReadabilitySettings,
            tab: SettingsTab.ContentSettings
        });
    }

    const tabClicked = (tabId) => {
        const tabIdAsString = Object.keys(HASH_TAB_MAPPING).find((r) => HASH_TAB_MAPPING[r] === tabId);
        const location = window.location.pathname + window.location.search + "#" + tabIdAsString;

        modifyURL(location);

        setActiveSettingsTab(tabId);
    };

    const resetToPristine = () => {
        resetTenantSettingsToPristine();
        resetReadabilitySettings();
    };

    const isResetDisabled = (): boolean => {
        let isReadabilityPristine = JSON.stringify(defaultReadabilitySettings) === JSON.stringify(readabilitySettings);

        const isDisabled = working ||
            activeSettingsTab === SettingsTab.ContentSettings
            ? isReadabilityPristine
            : pristine;

        return isDisabled;
    }

    return (
        <BasePage fullWidth>
            <Prompt
                message={(location) =>
                    location.pathname !== `/${match.params.tenant}/admin/settings` && !pristine
                        ? "You have unsaved changes, are you sure you want to leave this page?"
                        : true
                }
            />
            <Header
                title="Tenant Configurations"
            />
            <MainContent>
                <div className="settings-page">
                    {!tenantSettings ? (
                        <div className="fetching-message">Fetching settings...</div>
                    ) : (
                        <div className="tabs">
                            <ul className="tab-btns">
                                {tabs.map((tab) => {
                                    return (
                                        <li key={`tab-${tab.id}`}>
                                            <span
                                                className={"tab-btn" + (activeSettingsTab === SettingsTab[tab.id] ? " active" : "")}
                                                onClick={() => tabClicked(SettingsTab[tab.id])}
                                            >
                                                {tab.name}
                                            </span>
                                        </li>
                                    );
                                })}
                            </ul>

                            {tabs.map((tab) => {
                                return (
                                    <div
                                        key={`tab-content-${tab.id}`}
                                        className={
                                            "non-list-view tab" + (activeSettingsTab === SettingsTab[tab.id] ? " active" : "")
                                        }
                                    >
                                        {tab.content}
                                    </div>
                                );
                            })}
                            {errorMsg && <div className="error-msg"> {errorMsg} </div>}
                            <div className="settings-page-actions">
                                <button
                                    type="button"
                                    className="action"
                                    onClick={async () => {
                                        let currentTab = tabs.find(({ tab }) => tab === activeSettingsTab);

                                        if (currentTab && currentTab.onSave)
                                            await currentTab.onSave();
                                        else
                                            await saveTenantSettings();
                                    }}
                                    disabled={working}
                                >
                                    {saving || propsSaving ? "Saving changes..." : "Save Changes"}
                                    <span className="btn-loading-spinner">
                                        {saving && <LoadingSpinner size={"14px"} color="#fff" />}
                                    </span>
                                </button>
                                <button type="button" onClick={resetToPristine} disabled={isResetDisabled()}>
                                    {fetching ? "Resetting..." : "Reset"}
                                    <span className="btn-loading-spinner">
                                        {fetching && <LoadingSpinner size={"14px"} color="#01b5fa" />}
                                    </span>
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </MainContent>
        </BasePage>
    );
}

interface ComponentProps { }

interface RouteParams {
    tenant: string;
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps & RouteComponentProps<RouteParams>) => ({
        ...ownProps,
        fetching: state.settings.fetching,
        saving: state.settings.saving,
        errorMsg: state.settings.errorMsg,
        tenantSettings: state.settings.tenantSettings,
        pristine: state.settings.pristine,
    }),
    {
        getTenantSettings: actions.getTenantSettings,
        saveTenantSettings: actions.saveTenantSettings,
        resetTenantSettingsToPristine: actions.resetTenantSettingsToPristine,
        modifyURL: replace,
    }
);

type PropsWithRedux = ConnectedProps<typeof connector>;

export const SettingsPage = connector(withRouter(SettingsPageComponent));
