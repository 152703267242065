export enum ActivityTab {
    ActivityLog = 1,
    FlaggedItems = 2
}

export interface ActivityState {
    activeTab: ActivityTab
}

export class LogFilterValues {
    public userName: string;
    public activityType: string | null;
    public beforeDate: Date | null;
    public afterDate: Date | null;
}

export const defaultFilters: LogFilterValues = {  
    userName: "",
    activityType: null,
    beforeDate: null,
    afterDate: null
};

export interface FlaggedComment {
    commentId: string
    postId: string
    parentText: string
    parentAuthor: string
    flaggingLastIgnoredAt: Date
    totalFlagActions: number
    flagActionsSinceLastIgnored: number
    commentBody: string
    commentAuthor: string
    createdTime: Date
    avatarColor?: string
}

export interface ActivityLogItem {
    userId: string
    fullName: string
    actionTarget: string
    actionTime: Date
    actionType: string
}

export const ActionTypes = {
    itemFlagged: "ItemFlagged",
    flaggedItemIgnored: "FlaggedItemIgnored",
    userCreatedComment: "UserCreatedComment",
    userUpdatedComment: "UserUpdatedComment",
    userDeletedComment: "UserDeletedComment",
    userPublishedPost: "UserPublishedPost",
    audienceChanged: "AudienceChanged"
}
