import * as React from 'react';
import { HBarChartProps, HorizontalBarChart } from 'modules/insights/components/charts/horizontalBarChart';
import WindowResizeListener from 'modules/common/components/window-resize-listener';
import {injectIntl, IntlShape} from "react-intl";
import {connect, ConnectedProps} from "react-redux";
import { GlobalApplicationState } from 'globalApplicationState';
import { NewsletterPastIssueDigestModel } from 'modules/newsletters';

interface ComponentProps {
    issue: NewsletterPastIssueDigestModel;
    intl: IntlShape
}

class IssueEmailEventsWidget extends React.PureComponent<PropsWithRedux, {}> {

    private _chart: HorizontalBarChart;
    private _chartElement: HTMLElement;

    private _colors = ['#366531', '#70B603', '#E6911A', '#F0BF79', '#3B78AB'];

    public componentDidMount() {
        this._chart = new HorizontalBarChart(this.getChartState());
    }

    public componentDidUpdate() {
        if (this._chart) {
            this._chart.update(this.getChartState());
        }
    }

    public componentWillUnmount() {
        if (this._chart) {
            this._chart.destroy();
        }
    }

    public render() {
        const chartState = this.getChartState();

        return <div className="analytics-widget">
            <header>
                Email Activity
            </header>
            {chartState.data.length === 0
                ? <div>Issue has not yet been published.</div>
                : <div className="body">
                        <div ref={(e) => this._chartElement = e! } className="issue-email-events-chart-widget"></div>
                        <WindowResizeListener onChange={() => this._chart.update(this.getChartState())} />
                    </div>
            }
        </div>
    }

    private getChartState = (): HBarChartProps => {
        const { sentReceipt, stats } = this.props.issue || {};

        const data = !!stats && !!sentReceipt ? [{
            id: '0',
            label: 'Copies sent',
            value: stats.UniqueProcessed
        },{
            id: '1',
            label: 'Delivered',
            value: stats.UniqueDelivered
        },{
            id: '2',
            label: 'Opens',
            value: stats.Opened
        },{
            id: '3',
            label: 'Unique opens',
            value: stats.UniqueOpened
        },{
            id: '4',
            label: 'Unique Clicks',
            value: stats.UniqueClicked
        }] : [];

        return {
            el: this._chartElement,
            locale: this.props.locale,
            height: 'auto',
            barHeight: 20,
            data: data,
            labelWidth: 125,
            color: d=> this._colors[parseInt(d.id)]
        } as HBarChartProps;
    }
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        locale: state.localization.locale,
        tagGroups: state.settings.tenantSettings.tagGroups
    })
);
type PropsWithRedux = ConnectedProps<typeof connector>;
export default injectIntl(connector(IssueEmailEventsWidget))
