import React, { useEffect } from "react";

import { DocumentFeedItem } from "../../../../../models";

import ShareButton from "modules/common/components/buttons/shareButton";

import IconButton from "@mui/material/IconButton";

import SaveAltIcon from "@mui/icons-material/SaveAlt";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import moment from "moment";


const Document: React.FunctionComponent<ComponentProps> = props => {
  useEffect(() => {
    moment.locale("en");
  }, []);

  const { document } = props;

  return (
    <div
      onClick={() => props.onDocumentSelected(document)}
      className="document"
    >
      <div className="details">
        {document.isNew
          ? <div className="new">New</div>
          : document.isUpdated &&
            <div className="updated">Updated</div>
        }
        <img src={`/images/icons/documents/${document.fileType}.png`} alt={document.fileType} className="file-type" />
        <div title={document.title || `${document.fileName}.${document.fileType}`} className="title">{document.title || `${document.fileName}.${document.fileType}`}</div>
        {!!document.tags.length &&
          <div>Category tags: {document.tags.slice(0, 3).map(tag => tag.name).join(", ")}{document.tags.length > 3 ? `, ... +${document.tags.length - 3}` : ""}</div>
        }
      </div>
      <div className="footer">
        {document.isUnread
          ? <div className="unread">Unread</div>
          : <div className="read">Read {moment(document.lastReadDate).fromNow()}</div>
        }
        <div onClick={(ev) => ev.stopPropagation()} className="footer-options">
          <IconButton title="Download" size="small" onClick={() => props.onDownloadDocument(document)}>
            <SaveAltIcon fontSize="small" />
          </IconButton>
          <IconButton title="More Info" size="small" onClick={() => props.onInspectDocument(document)}>
            <InfoOutlinedIcon fontSize="small" />
          </IconButton>
          <ShareButton
            articleId={document.id}
            articleTitle={document.title || `${document.fileName}.${document.fileType}`}
            articleType="document"
            size="small"
            warningMessage="This document may not be accessible to other colleagues. Contact your administrator for more details."
          />
        </div>
      </div>
    </div>
  );
}

interface ComponentProps {
  document: DocumentFeedItem;
  onDocumentSelected: (document: DocumentFeedItem) => void;
  onDownloadDocument: (document: DocumentFeedItem) => void;
  onViewDocument: (document: DocumentFeedItem) => void;
  onInspectDocument: (document: DocumentFeedItem) => void;
}

export default Document;