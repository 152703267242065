import { AxiosInstance, AxiosRequestConfig } from 'axios'
import MsalAuthModule from 'authentication/msalAuthModule';

export class AuthInterceptor {
    public bind(networkLayer: AxiosInstance) {
        networkLayer.interceptors.request.use(this.handle);
    }

    public handle = (config: AxiosRequestConfig) => {
        return MsalAuthModule.getInstance().getAccessToken()
        .then(accessToken => {
            config.headers.Authorization = `Bearer ${accessToken}`;
            return config;
        });
    }
}
