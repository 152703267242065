import React, { useRef, useState } from "react";

import Popover from "@mui/material/Popover";

import { Chip } from "@mui/material";

const ExtraChipsMessage: React.FunctionComponent<ComponentProps> = props => {
    const [showPopover, setShowPopover] = useState(false);
    const popoverRef = useRef(null);

    return (
        <React.Fragment>
            <Chip
                ref={popoverRef}
                onMouseEnter={() => setShowPopover(true)}
                onMouseLeave={() => setShowPopover(false)}
                label={props.chipText}
            ></Chip>
            <Popover
                open={showPopover}
                anchorEl={popoverRef.current}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left"
                }}
                PaperProps={{
                    onMouseEnter: () => setShowPopover(true),
                    onMouseLeave: () => setShowPopover(false),
                    style: {
                        maxWidth: 250
                    }
                }}
                style={{ pointerEvents: "none" }}
                onClose={() => setShowPopover(false)}
                classes={{
                    paper: "help-text-description"
                }}
            >
                {props.hoverText}
            </Popover>
        </React.Fragment>
    );
}

interface ComponentProps {
    chipText: string;
    hoverText: string;
}

export default ExtraChipsMessage;
