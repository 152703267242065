import * as React from "react";
import { push } from 'react-router-redux';
import { connect, ConnectedProps } from "react-redux";

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Button from "@mui/material/Button";

import { GlobalApplicationState } from 'globalApplicationState';

import './backToButton.sass'

const BackToButton: React.FunctionComponent<PropsWithRedux> = (props) => {

    const handleClick = () => {
        props.redirectTo('/' + props.tenant + '/' + props.to)
    }

    return(
        <div className='back-to-wrapper'>

        <Button color="primary" onClick={handleClick} classes={{ root: 'back-to'}}>
            <ArrowBackIosIcon />
            {'Back to ' + props.locationTitle}
        </Button>
        </div>
    ) 

}

interface ComponentProps {
    locationTitle: string
    to: string
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        tenant: state.tenant.id,
    }),
    {
        redirectTo: push,
    }
);

type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(BackToButton);