import * as React from "react";
import Chips from "modules/common/components/chips/chips";
import InfoHover from "modules/common/components/hovers/infoHover";

import { Newsletter, NewsletterRecurrence, SaveNewsletterModelStateErrors, SaveNewsletterModel, SimpleTag, daysOfWeek, recurrenceWeeks, NewsletterDetails, Address } from "modules/newsletters/models";
import { Audience } from "modules/audiences/models";

import EditAudiences from "../../common/editAudiences";

import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { PickerLocalization } from "modules/common/components/pickerLocalization";

import moment from "moment";
import _ from "lodash";
import { Button, Checkbox, Divider, FormControlLabel, FormGroup, FormHelperText, ListItemIcon, ListItemText, MenuProps, Radio, Typography } from "@mui/material";
import { DayOfWeek } from "modules/newsletters";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import CalloutWithSearch from "modules/common/components/calloutWithSearch";


const selectMenuProps : Partial<MenuProps> = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  },
};

class AudienceAndFrequencySettings extends React.Component<ComponentProps, ComponentState> {

  constructor(props: ComponentProps) {
    super(props);
    this.state = {
      anchorEl: null,
      buttonRef: React.createRef(),
      singleOption: props.senders.length === 1
    };
    const now = new Date();
    this._today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  }

  private _today : Date;

  componentDidMount() {
    moment.locale("en");
    if(this.props.newsletter?.fromEmailAddress === "" && this.props.newsletter?.fromEmailName === "" && this.props.highlight) {
      this.state.buttonRef.current.click()
    }

    if(this.state.singleOption)
    {
      this.onSelectSender(this.props.senders[0]);
    }
  }

  private truncate = (str: string, n: number) => {
    return (str.length > n) ? str.slice(0, n-1) + "..." : str;
  }  

  public render() {
    const showFirstIssueDateTime = this.props.newsletter.status === "Draft" || this.props.newsletter.recurrenceType !== 'Adhoc';

    return (
      <div className="newsletter-page" style={{ marginBottom: 30 }}>
        <p className="tx-strong">Send details</p>
        <div className="space-top-10" style={{paddingBottom: "32px"}}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <div style={{ display: "flex", alignItems: "flex-start"}}>
                <Typography style={{float:'left', lineHeight: '42px'}}>Sender name &amp; email<span className="required-coloring">*</span></Typography>
                <div style={{position: "relative", top: "8px"}}>
                  <InfoHover>This is the 'From' name and email address that appears when the emails are received. Go to Global Settings to modify your emails</InfoHover>
                </div>
              </div>
            </Grid>
            {this.props.newsletter.fromEmailAddress && this.props.newsletter.fromEmailName &&
            <React.Fragment>
              <Grid item xs={5}>
                <Typography style={{lineHeight:'42px'}}>{this.truncate(this.props.newsletter.fromEmailName, 30)}</Typography>
              </Grid>
              {!this.state.singleOption ?
              <Grid item xs={3}>
                <Button onClick={(e) => this.setState({ anchorEl: e.currentTarget})} color="primary" startIcon={<EditIcon/>}>Change Email</Button>
              </Grid>
              : <Grid item xs={3}>
                </Grid>
              }
              <Grid item xs={4}>
              </Grid>
              <Grid item xs={8}>
                <Typography>{this.truncate(this.props.newsletter.fromEmailAddress, 50)}</Typography>
              </Grid>
            </React.Fragment>
            }
            {!this.props.newsletter.fromEmailAddress && !this.props.newsletter.fromEmailName && !this.state.singleOption && 
            <React.Fragment>
              <Grid item xs={6}>
                <Button ref={this.state.buttonRef} onClick={(e) => this.setState({ anchorEl: e.currentTarget})} color="primary" startIcon={<EditIcon/>}>Select Email</Button>
              </Grid>
            </React.Fragment>
            }
            <CalloutWithSearch
              anchorEl={this.state.anchorEl}
              placeholder={"Search name or email"}
              setAnchorEl={() => {
                this.setState({anchorEl: null})
              }}
              performSearch={(e) => this.props.onSearchSenders(e)}
              searchOnType={true}
              highlight={(this.props.newsletter.fromEmailAddress === "" && this.props.newsletter.fromEmailName === "" && this.props.highlight)}
            >
              {this.props.senders.map(s => 
                <div key={s.fromEmailAddress+s.fromEmailName}>
                  <div style={{cursor: "pointer", paddingTop: "5px", paddingBottom: "5px"}} onClick={() => this.onSelectSender(s)}>
                    <span>{this.truncate(s.fromEmailName, 50)}</span> <br/>
                    <span style={{fontSize: "12px"}}>{this.truncate(s.fromEmailAddress, 75)}</span>
                  </div>
                <Divider light></Divider>
                </div>
              )}
            </CalloutWithSearch>
          </Grid>
        </div>

        <div className="space-top-10">
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <div style={{ display: "flex", alignItems: "flex-start" }}>
                <Typography style={{float:'left'}}>Send to</Typography>
                <InfoHover>Target the audiences you want to send this newsletter to. If none are selected, the edition(s) will send to all subscribed users in your organization.</InfoHover>
              </div>
            </Grid>
            <Grid item xs={8}>
              <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
                <Chips
                  chips={this.props.newsletter.audiences || []}
                  emptyText="All users"
                  onDelete={this.onDeleteAudience}
                />
                <EditAudiences
                  audiences={this.props.newsletter.audiences || []}
                  availableAudiences={this.props.audiences}
                  onChange={this.onChangeAudiences}
                />
              </div>
              <FormGroup>
                <FormControlLabel control={<Checkbox checked={this.props.newsletter.allowUnsubscribe} onChange={(event) => this.onChangeAllowUnsubscribe(event.target.checked)} color="primary" />} label="Allow recipients to unsubscribe" />
              </FormGroup>
            </Grid>
          </Grid>
        </div>
        <p className="tx-strong">Frequency</p>
        {showFirstIssueDateTime && <div>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography style={{float:'left', lineHeight:'42px'}}>Send first issue on<span className="required-coloring">*</span></Typography>
            </Grid>
            <Grid item xs={8}>
            <div>
              <PickerLocalization>
                  <DatePicker
                    format={"MMM dd yyyy"}
                    value={this.props.newsletter.firstIssueDateTime ? new Date(this.props.newsletter.firstIssueDateTime) : null}
                    slotProps={{ 
                        textField: { 
                          size: "small", 
                          placeholder: "Select date", 
                          inputProps: { 
                            readOnly: true, 
                          },
                          helperText: _.first(this.props.modelErrors?.FirstIssueDateTime),
                          error: _.some(this.props.modelErrors?.FirstIssueDateTime)
                        },
                        openPickerIcon: { color: "primary" }
                    }}
                    onChange={this.onChangeIssueDate}
                    shouldDisableDate={this.shouldDisableDate}
                    className="date-picker"
                  />
                </PickerLocalization>
                <TextField  style={{marginLeft: '15px'}}
                  variant="outlined"
                  size="small"
                  color="primary"
                  type="time"
                  value={ this.props.newsletter.firstIssueDateTime ? moment(this.props.newsletter.firstIssueDateTime).format("HH:mm") : ''}
                  error={ this.props.highlight && this.props.newsletter.firstIssueDateTime === undefined}
                  inputProps={{
                    step: 900
                  }}
                  onChange={this.onChangeIssueTime}
                />
              </div>
            </Grid>
          </Grid>
        </div>}
        <div className="space-top-10">
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography style={{float:'left', lineHeight:'42px'}}>Frequency</Typography>
            </Grid>
            <Grid item xs={8}>
              <div className="newsletter-checkbox-radio">
                <FormControlLabel control={<Radio name="frequency" value="Adhoc" checked={this.props.newsletter.recurrenceType === 'Adhoc'} onChange={(event) => event.target.checked && this.OnFrequencyChange("Adhoc")} color="primary" />} label="Adhoc" style={{marginRight:'0'}} />
                <InfoHover>Selecting adhoc will send only the first issue of this newsletter after publishing and will allow you to manually add and queue succeeding issues.</InfoHover>
              </div>
              <div className="newsletter-checkbox-radio">
                <FormControlLabel control={<Radio name="frequency" value="Daily" checked={this.props.newsletter.recurrenceType === 'Daily'} onChange={(event) => event.target.checked && this.OnFrequencyChange("Daily")} color="primary" />} label="Every" />
              </div>
                {
                  this.props.newsletter.recurrenceType === "Daily" &&  
                    <div style={{marginLeft:'32px'}}>
                      <FormControl variant="outlined" size="small" fullWidth error={_.some(this.props.modelErrors?.DailyRecurrenceOn)}>
                          <Select
                            value={this.props.newsletter.dailyRecurrenceOn}
                            fullWidth
                            multiple={true}
                            MenuProps={selectMenuProps}
                            renderValue={(selected: DayOfWeek[]) => _.orderBy(selected, s=>daysOfWeek.indexOf(s)).join(', ')}
                            onChange={(event) => this.onChangeDailyRecurrenceDay(typeof event.target.value === 'string' ? ((event.target.value as string).split(',') as DayOfWeek[]) : (event.target.value as DayOfWeek[]))}
                          >
                            {daysOfWeek.map((dayOfWeek) =>
                              <MenuItem key={dayOfWeek} value={dayOfWeek} >
                                <ListItemIcon>
                                {this.props.newsletter.dailyRecurrenceOn.indexOf(dayOfWeek) === -1
                                ? <CheckBoxOutlineBlank />
                                : <CheckBox />}
                                </ListItemIcon>
                                <ListItemText>{dayOfWeek}</ListItemText></MenuItem>
                            )}
                          </Select>
                          <FormHelperText>{_.first(this.props.modelErrors?.DailyRecurrenceOn)}</FormHelperText>
                        </FormControl>
                      </div>
                  }
              <div className="newsletter-checkbox-radio">
                <FormControlLabel control={<Radio name="frequency" value="Biweekly" checked={this.props.newsletter.recurrenceType === 'Biweekly'} onChange={(event) => event.target.checked && this.OnFrequencyChange("Biweekly")} color="primary" />} label="Every other week" style={{marginRight:'0'}} />
                <InfoHover>Selecting every other week queues issues biweekly. This setting will follow the day (Sunday-Monday) of the first issue's send date, and queues will skip every other week.</InfoHover>
              </div>
              <div className="newsletter-checkbox-radio">
                <FormControlLabel control={<Radio name="frequency" value="Monthly" checked={this.props.newsletter.recurrenceType === 'Monthly'} onChange={(event) => event.target.checked && this.OnFrequencyChange("Monthly")} color="primary" />} label="Every month" />
              </div>
                {
                this.props.newsletter.recurrenceType === "Monthly" &&
                <div style={{display: "inline-block", marginLeft:'33px'}}>
                  <span style={{lineHeight:'50px', verticalAlign:'bottom'}}>on the</span>
                  <div style={{ display: "inline-block", marginLeft: "15px" }}>
                    <FormControl variant="outlined" size="small" fullWidth error={_.some(this.props.modelErrors?.MonthlyRecurrenceWeek)}>
                      <Select
                        value={this.props.newsletter.monthlyRecurrenceWeek}
                        fullWidth
                        MenuProps={selectMenuProps}
                        onChange={this.onChangeMonthlyRecurrenceWeek}
                      >
                        {recurrenceWeeks.map((weekOption) =>
                          <MenuItem key={weekOption} value={weekOption}>{weekOption}</MenuItem>
                        )}
                      </Select>
                      <FormHelperText>{_.first(this.props.modelErrors?.MonthlyRecurrenceWeek)}</FormHelperText>
                    </FormControl>
                  </div>
                  <div style={{ display: "inline-block", marginLeft: "15px" }}>
                    <FormControl variant="outlined" size="small" fullWidth error={_.some(this.props.modelErrors?.MonthlyRecurrenceDay)}>
                      <Select
                        value={this.props.newsletter.monthlyRecurrenceDay || ""}
                        fullWidth
                        MenuProps={selectMenuProps}
                        onChange={this.onChangeMonthlyRecurrenceDay}
                      >
                        {daysOfWeek.map((dayOfWeek) =>
                          <MenuItem key={dayOfWeek} value={dayOfWeek}>{dayOfWeek}</MenuItem>
                        )}
                      </Select>
                      <FormHelperText>{_.first(this.props.modelErrors?.MonthlyRecurrenceDay)}</FormHelperText>
                    </FormControl>
                  </div>
                </div>
               }
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }

  private shouldDisableDate = (day: Date) => {
    if(day < this._today){
      if(!!this.props.serverNewsletter && !!this.props.serverNewsletter.firstIssueDateTime){
        if(moment(this.props.serverNewsletter.firstIssueDateTime).toDate() !== day)
          return true;
      }else{
        return true;
      }
    }
    switch(this.props.newsletter.recurrenceType)
    {
      case "Adhoc":
      case "Biweekly":
        return false;
      case "Daily":
        const selectedDays = _.map(this.props.newsletter.dailyRecurrenceOn,d=>daysOfWeek.indexOf(d));
        return selectedDays.indexOf(day.getDay()) < 0;
      case "Monthly":
        if(!!this.props.newsletter.monthlyRecurrenceDay && day.getDay() !== daysOfWeek.indexOf(this.props.newsletter.monthlyRecurrenceDay))
          return true;
        const date = day.getDate();
        switch(this.props.newsletter.monthlyRecurrenceWeek)
        {
          case "First":
            return date > 7;
          case "Second":
            return date <=7 || date > 14;
          case "Third":
            return date <= 14 || date > 21;
          case "Last":
            const daysInMonth = new Date(day.getFullYear(), day.getMonth()+1, 0).getDate();
            return date < (daysInMonth - 6);
        }
        break;
    }

    return false;
  };

  private onChange = (value: Partial<Newsletter>) => {
    this.props.onNewsletterChange(value);
  }

  private onChangeAllowUnsubscribe = (value:boolean) => {
    this.onChange({allowUnsubscribe: value});
  }

  private onChangeAudiences = (audiences: SimpleTag[]) => {
    this.onChange({ audiences });
  }

  private onChangeMonthlyRecurrenceDay = (event) => {
    this.onChange({ monthlyRecurrenceDay: event.target.value });
  }

  private onChangeDailyRecurrenceDay = (values: DayOfWeek[]) => {
    this.onChange({ dailyRecurrenceOn: _.orderBy(values,v=>daysOfWeek.indexOf(v)) });
  }

  private onChangeMonthlyRecurrenceWeek = (event) => {
    this.onChange({ monthlyRecurrenceWeek: event.target.value });
  }

  private onDeleteAudience = (audienceId: string) => {
    this.onChange({ audiences: this.props.newsletter.audiences!.filter(audience => audience.id !== audienceId) });
  }

  private OnFrequencyChange = (frequency: NewsletterRecurrence) => {
    this.onChange({recurrenceType: frequency});
  }

  private onChangeIssueDate = (date) => {
    const updatedDate: string = moment(date).toISOString();
    this.onChange({ firstIssueDateTime: updatedDate });
  }

  private onChangeIssueTime = (event) => {
    const times: number[] = event.target.value.split(":");
    const updatedDate: string = moment(this.props.newsletter.firstIssueDateTime).set("hour", times[0]).set("minutes", times[1]).toISOString();
    this.onChange({ firstIssueDateTime: updatedDate });
  }

  private onSelectSender = (s: Address) => {
    this.onChange({ fromEmailName: s.fromEmailName, fromEmailAddress: s.fromEmailAddress })
    this.setState({ anchorEl: null })
  }
}

interface ComponentProps {
  newsletter: SaveNewsletterModel;
  serverNewsletter?: NewsletterDetails;
  audiences: Audience[];
  senders: Address[];
  modelErrors: SaveNewsletterModelStateErrors | null;
  highlight: boolean;
  onNewsletterChange: (value: Partial<SaveNewsletterModel>) => void;
  onSearchSenders: (value: string) => void;
}

interface ComponentState {
  anchorEl: any;
  buttonRef: any;
  singleOption: boolean;
}

export default AudienceAndFrequencySettings;