import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";

import { PostViewStats } from "../../../../models";

import AddCommentIcon from "@mui/icons-material/AddComment";
import FavoriteIcon from "@mui/icons-material/Favorite";


const Stats: React.FunctionComponent<PropsWithRedux>  = props => {
  const { postType, stats } = props;

  const commentCount = props.commentsEnabled ? stats.commentCount : 0;
  const reactionCount = props.reactionsEnabled && postType !== "compliance" && stats.reactions ? stats.reactions.reduce((sum, reaction) => sum + reaction.count, 0) : 0;

  const comments = commentCount > 0 &&
    <div>
      <AddCommentIcon />
      <span>{commentCount}</span>
    </div>;
  const reactions = reactionCount > 0 &&
    <div>
      <FavoriteIcon />
      <span>{reactionCount}</span>
    </div>;

  return (
    <div className="stats">
      {comments}
      {reactions}
    </div>
  );
}

interface ComponentProps {
  postType: string;
  stats: PostViewStats;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    // Checks first for client settings, then tenant settings when in the Admin Portal previews
    commentsEnabled: state.settings.clientSettings.commentSystem.enabled || state.settings.tenantSettings.commentsEnabled,
    reactionsEnabled: (state.settings.clientSettings.reactionSystem && state.settings.clientSettings.reactionSystem.enabled) || state.settings.tenantSettings.reactionsEnabled
  })
);
type PropsWithRedux = ConnectedProps<typeof connector>;
export default connector(Stats);