import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import * as actions from "../actionCreator";
import { GlobalApplicationState } from "globalApplicationState";
import moment from "moment";

import InfoHover from "modules/common/components/hovers/infoHover";
import Loading from "modules/common/components/loading";
import { ClientSettingsTag, ClientSettingsTagGroup } from "modules/settings/models";
import SelectField from "./common/selectField";
import Notifications from "./notifications";
import TagSubscriptions from "./tagSubscriptions";

import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { SelectOption } from "modules/common/models";


class Preferences extends React.Component<PropsWithRedux, ComponentState> {
  constructor(props: PropsWithRedux) {
    super(props);

    this.state = {
      showEditNotifications: false,
      showEditTopicSubscriptions: false,
      showMoreTopics: false
    };
  }

  public componentDidMount() {
    moment.locale("en");
  }

  public render() {
    const { userSettings } = this.props;

    const availableLanguages: SelectOption[] = this.getAvailableLanguages();
    const tagGroups: ClientSettingsTagGroup[] = this.getTags();
    const showTopicsOption: boolean = this.checkShouldShowTopicsOption(tagGroups);

    if (!userSettings)
      return <Loading />;

    return (
      <section>
        <div className="section-heading">
          <Typography variant="h2">Preferences<InfoHover>Customize personal setting for your feeds, notifications, and more.</InfoHover></Typography>
        </div>
        {availableLanguages.length > 1 &&
          <SelectField
            description="If a post is available in the selected language preference, it will display this preference first."
            label="Preferred language"
            options={availableLanguages}
            value={userSettings.preferredLCID}
            onChange={this.onChangePreferredLCID}
          />
        }
        <div className="section-field section-field-button" onClick={this.onShowEditNotifications}>
          <div>
            <label>Notifications</label>
            <Typography variant="caption" className="italicized-description">{this.getNotificationsDescription()}</Typography>
          </div>
          <ChevronRightIcon />
        </div>
        <div className="section-field section-field-selection">
          <div>
            <label>Topic Subscriptions</label>
            <Typography variant="caption">Select topics of interest to filter what content will be prioritized on your feeds.</Typography>
          </div>
          <Button variant="text" color="primary" onClick={this.onShowEditTopicSubscriptions}>Edit Topic Subscriptions</Button>
        </div>
        <div id="subscribed-tags-bubbles">
          {tagGroups.map((tagGroup) => {
            return (
              <div key={tagGroup.id} className="tag-group">
                <div className="tag-group-name">{tagGroup.name}</div>
                <div>
                  {tagGroup.tags.slice(0, 4).map((tag, index) => (
                    <Chip className="chip-styling" key={tag.id} label={tag.name}/>
                  ))}
                  {tagGroup.tags.length === 5 &&
                    <Chip key={tagGroup.tags[4].id} label={tagGroup.tags[4].name} className="chip-styling"/>
                  }
                  {tagGroup.tags.length > 5 &&
                    <React.Fragment>
                      <Collapse in={this.state.showMoreTopics}>
                        {tagGroup.tags.slice(4).map((tag) => (
                          <Chip key={tag.id} label={tag.name} className="chip-styling"/>
                        ))}
                      </Collapse>
                      <Collapse appear={true} in={!this.state.showMoreTopics}>
                        <Chip key={`${tagGroup.id}-more`} label={`+${tagGroup.tags.length - 4}`} className="chip-styling"/>
                      </Collapse>
                    </React.Fragment>
                  }
                </div>
              </div>
            );
          })}
        </div>
        {showTopicsOption &&
          <div className="show-more-topics-option">
            {this.state.showMoreTopics
              ? <Button variant="text" color="primary" onClick={this.onShowLessTopics}>See less topics</Button>
              : <Button variant="text" color="primary" onClick={this.onShowMoreTopics}>See all topics</Button>
            }
          </div>
        }
        <Notifications
          show={this.state.showEditNotifications}
          onClose={this.onCloseEditNotifications}
        />
        <TagSubscriptions
          show={this.state.showEditTopicSubscriptions}
          onClose={this.onCloseEditTopicSubscriptions}
        />
      </section>
    );
  }

  private checkShouldShowTopicsOption = (topics: ClientSettingsTagGroup[]): boolean => {
    return !!topics.find((tagGroup) => tagGroup.tags.length > 5);
  }

  private getAvailableLanguages = (): SelectOption[] => {
    if (!this.props.clientSettings || !this.props.lcidMappings)
      return [];

    let availableLanguages: SelectOption[] = [];
    Object.keys(this.props.clientSettings.translatedContent).map((lcid) => {
      if (!!this.props.lcidMappings[lcid])
        availableLanguages.push({ key: lcid, text: this.props.lcidMappings[lcid].language, type: "item" });
      return lcid;
    });
    return availableLanguages;
  }

  private getTags = (): ClientSettingsTagGroup[] => {
    if (!this.props.tagSettings)
      return [];
    
    let tags: ClientSettingsTagGroup[] = [];
    this.props.tagSettings.tagGroups.map((tagGroup) => {
      if (tagGroup.tags.length && !!tagGroup.tags.find((tag) => this.props.userSettings.subscribedTags.includes(tag.id) && !tag.disabled)) {
        let subscribedTags: ClientSettingsTag[] = tagGroup.tags.filter((tag) => this.props.userSettings.subscribedTags.includes(tag.id) && !tag.disabled);
        tags.push({
          ...tagGroup,
          tags: subscribedTags
        });
      }
      return tagGroup;
    });
    return tags;
  }

  private getNotificationsDescription = () => {
    const { clientSettings, userSettings } = this.props;

    if ((!clientSettings.chatEnabled || !userSettings.chatEnabled) && userSettings.commentNotificationSettings === "none" && !userSettings.preferredSMSConsent && !userSettings.sendNotificationsWhenAuthor)
      return "All preferences disabled";
    else if ((!clientSettings.chatEnabled || userSettings.chatEnabled) && userSettings.commentNotificationSettings !== "none" && userSettings.preferredSMSConsent && userSettings.sendNotificationsWhenAuthor)
      return "All preferences enabled";
    else
      return "Custom preferences set";
  }

  private onChangePreferredLCID = (event) => {
    this.props.saveUserSettings({ preferredLCID: event.target.value });
  }

  private onCloseEditNotifications = () => {
    this.setState({ showEditNotifications: false });
  }

  private onCloseEditTopicSubscriptions = () => {
    this.setState({ showEditTopicSubscriptions: false });
  }

  private onShowEditNotifications = () => {
    this.setState({ showEditNotifications: true });
  }

  private onShowEditTopicSubscriptions = () => {
    this.setState({ showEditTopicSubscriptions: true });
  }

  private onShowLessTopics = () => {
    this.setState({
      showMoreTopics: false
    });
  }

  private onShowMoreTopics = () => {
    this.setState({
      showMoreTopics: true
    });
  }
}


interface ComponentProps {
}

interface ComponentState {
  showEditNotifications: boolean;
  showEditTopicSubscriptions: boolean;
  showMoreTopics: boolean;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    clientSettings: state.settings.clientSettings,
    lcidMappings: state.resources.lcidMappings,
    tagSettings: state.settings.tagSettings,
    userSettings: state.profile.userSettings!
  }),
  {
    saveUserSettings: actions.saveUserSettings
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(Preferences);