import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";

import AuthoringSearch from "modules/common/components/filters/authoringSearch";
import FilterContainer from "modules/common/components/filters/filterContainer";
import FilterDropdown from "modules/common/components/filters/filterDropdown";

import { CategoryTagFilter } from "../../models";

import TextField from "@mui/material/TextField";
import { PickerLocalization } from "modules/common/components/pickerLocalization";

import { Button, Checkbox, Chip, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";

const States = [
    { id: "Enabled", text: "Enabled" },
    { id: "Disabled", text: "Disabled" }
];
class CategoryTagsFilters extends React.Component<PropsWithRedux, ComponentState> {
  constructor(props: PropsWithRedux) {
    super(props);

    this.state = {
      canSearch: !!props.filters.audienceVisibleTo!.length || !!props.filters.title!.length || !!props.filters.startDate || !!props.filters.endDate,
      enabledChecked: false,
      disabledChecked: false,
      title: props.filters.title || ""
    };
  }

  public componentDidUpdate(prevProps: PropsWithRedux) {
    if (this.props.filters.title !== prevProps.filters.title)
      this.setState({ title: this.props.filters.title || "" });
  }

  public render() {
    const { filters } = this.props;

    const validLanguages = Object.keys(this.props.lcid);
    var Languages = [{}];
    validLanguages.forEach((lcid, index) => {
        var toAdd = {id: this.props.lcidMappings[lcid].language, text: this.props.lcidMappings[lcid].language}
        Languages[index] = toAdd;
    })

    return (
      <FilterContainer
        filters={
          <React.Fragment> 
            <TextField
              variant="outlined"
              size="small"
              value={this.state.title}
              placeholder="Search title or administrator"
              InputProps={{
                  startAdornment: <SearchIcon className="search-icon" />
                }}
              onChange={this.onUpdateSearchText}
              onKeyUp={this.onKeyUp}
              className="text-to-search"
            />
            {this.props.id === "all" &&
              <FilterDropdown text="State">
                <List disablePadding>
                  {States.map((state) =>
                    <ListItem key={state.id} dense button onClick={() => this.onChangeEnabledFilter(state.id)}>
                      <ListItemIcon className="filter-checkbox">
                        <Checkbox
                          edge="start"
                          tabIndex={-1}
                          disableRipple
                          size="small"
                          color="primary"
                          checked={state.id === "Enabled" ? (this.state.enabledChecked) : (this.state.disabledChecked)}
                        />
                      </ListItemIcon>
                      <ListItemText primary={state.text} />
                    </ListItem>
                  )}
                </List>
              </FilterDropdown>
            }
            { Languages.length > 1 &&
              <FilterDropdown text="Language">
                  <List disablePadding>
                    {Languages.map((language) =>
                      <ListItem key={language['text']} dense button onClick={() => this.onChangeCurrentLanguages(language['text'], false)}>
                        <ListItemIcon className="filter-checkbox">
                          <Checkbox
                            edge="start"
                            tabIndex={-1}
                            disableRipple
                            size="small"
                            color="primary"
                            checked={this.props.filters.languages?.includes(language['text']) ?? false}
                          />
                        </ListItemIcon>
                        <ListItemText primary={language['text']} style={{whiteSpace: "nowrap"}} />
                      </ListItem>
                    )}
                  </List>
                </FilterDropdown>
            }
              <FilterDropdown text="Published between">
              <PickerLocalization>
                <div className="published-time">
                  <div>
                    <div>From</div>
                    <DatePicker
                      format="MMM dd yyyy"
                      value={filters.startDate || null}
                      slotProps={{ 
                        textField: { size: "small", placeholder: "", inputProps: { readOnly: true }},
                        openPickerIcon: { color: "primary" }
                      }}
                      onChange={this.onChangePublishedFromDate}
                      className="date-picker"
                    />
                  </div>
                  <div>
                    <div>To</div>
                    <DatePicker
                      format="MMM dd yyyy"
                      value={filters.endDate || null}
                      slotProps={{ 
                        textField: { size: "small", placeholder: "", inputProps: { readOnly: true }},
                        openPickerIcon: { color: "primary" }
                      }}
                      onChange={this.onChangePublishedToDate}
                      className="date-picker"
                    />
                  </div>
                </div>
              </PickerLocalization>
            </FilterDropdown>
            <FilterDropdown
              text="Accessible to"
              footer={
                <div>
                  <Button variant="text" color="primary" onClick={this.onClearAudiences}>Clear all</Button>
                </div>
              }
            >
              <List disablePadding className="audiences">
                {this.props.audiences.map((audience) =>
                  <ListItem key={audience.id} dense button onClick={() => this.onChangeAudiences(audience.id, false)}>
                    <ListItemIcon className="filter-checkbox">
                      <Checkbox
                        edge="start"
                        tabIndex={-1}
                        disableRipple
                        size="small"
                        color="primary"
                        checked={!!filters.audienceVisibleTo!.find((selectedAudience) => selectedAudience === audience.id)}
                      />
                    </ListItemIcon>
                    <ListItemText primary={audience.displayName} />
                  </ListItem>
                )}
              </List>
            </FilterDropdown>
            { /* Can add usedIn here, once the feature is actually implemented! */ }
          </React.Fragment>
        }
        filterCommands={
          <AuthoringSearch
            canSearch={this.state.canSearch}
            onApplyFilters={this.onChangeSearchText}
            onClearFilters={this.onClearFilters}
          />
        }
        filterSelection={this.getFilterSelection()}
      />
    );
  }

  private getFilterSelection = (): JSX.Element | undefined => {
    const { filters } = this.props;

    if (this.state.enabledChecked === null && this.state.disabledChecked === null && !filters.audienceVisibleTo!.length && !!filters.startDate && !!filters.endDate && !!filters.title)
      return undefined;

    return (
      <React.Fragment>
        {!!filters.title &&
          <Chip
            key="search-text"
            label={`"${filters.title}"`}
            onDelete={this.onClearSearchText}
            deleteIcon={<CloseIcon />}
          />
        }
        
        {this.state.enabledChecked &&
          <Chip
            key="search-enabled"
            label={"Enabled"}
            onDelete={() => this.onChangeEnabledFilter("Enabled")}
            deleteIcon={<CloseIcon />}
          />
        }

        {this.state.disabledChecked &&
          <Chip
            key="search-disabled"
            label={"Disabled"}
            onDelete={() => this.onChangeEnabledFilter("Disabled")}
            deleteIcon={<CloseIcon />}
          />
        }

        {!!filters.languages && filters.languages.map((language => 
            <Chip
              key={`search-language-${language}`}
              label={language}
              onDelete={() => this.onChangeCurrentLanguages(language, true)}
              deleteIcon={<CloseIcon />}
            />
          ))
        }

        {(!!filters.startDate || !!filters.endDate) &&
          <Chip
            key="published-time"
            label={`Released${!!filters.startDate ? ` from ${moment(filters.startDate).format("MMM D, YYYY")}` : ""}${!!filters.endDate? ` up to ${moment(filters.endDate).format("MMM D, YYYY")}` : ""}`}
            onDelete={this.onClearPublishedTime}
            deleteIcon={<CloseIcon />}
          />
        }

        {filters.audienceVisibleTo!.map((selectedAudience) =>
          <Chip
            key={selectedAudience}
            label={this.props.audiences.find(a => a.id === selectedAudience)?.displayName ?? ""}
            onDelete={() => this.onChangeAudiences(selectedAudience, true)}
            deleteIcon={<CloseIcon />}
          />
        )}
      </React.Fragment>
    );
  }

  private onChangeFilters = (value: Partial<CategoryTagFilter>) => {
    this.props.onChangeFilters({ ...this.props.filters, title: this.state.title, ...value });
    this.setCanSearch();
  }
  
  private onChangeSearchText = () => {
    this.onChangeFilters({ title: this.state.title });
  }

  private onClearSearchText = (event) => {
      this.updateAndReloadFilters({title: ""});
  }

  private updateAndReloadFilters = (toUpdate: {}) => {
   this.props.onChangeFilters(toUpdate);
  }

  private onChangeEnabledFilter = (enabledFilter: string) => {
    switch(enabledFilter){
      case("Enabled"):
        this.setState({enabledChecked: !this.state.enabledChecked}, () => this.updateStateFilter())
        break;

      case("Disabled"):
        this.setState({disabledChecked: !this.state.disabledChecked}, () => this.updateStateFilter())
        break;
    }
  }

  private updateStateFilter = () => {
    if(this.state.enabledChecked && this.state.disabledChecked) {
      this.onChangeFilters({ enabled: null});
    }
    else if(this.state.enabledChecked) {
      this.onChangeFilters({ enabled: true});
    }
    else if(this.state.disabledChecked) {
      this.onChangeFilters({ enabled: false});
    }
    else if(!this.state.enabledChecked && !this.state.disabledChecked)
    {
      this.onChangeFilters({ enabled: null });
    }
  }

  private onChangeCurrentLanguages = (language: string, chipDeleted: boolean) => {
    var languagesListToEdit = this.props.filters.languages ?? [];

    if(languagesListToEdit.indexOf(language) === -1) {
      languagesListToEdit = [...languagesListToEdit, language];
    }
    else if(languagesListToEdit.indexOf(language) !== -1) {
      languagesListToEdit = languagesListToEdit.filter(l => l !== language)
    }

    if(!chipDeleted)
      this.onChangeFilters({languages: languagesListToEdit})
    else
      this.updateAndReloadFilters({languages: languagesListToEdit});
  }

  private onClearFilters = () => {
    this.clearCanSearch();
    this.props.onClearFilters();
    this.setState({enabledChecked: false, disabledChecked: false})
  }

  private onChangePublishedFromDate = (date) => {
    this.onChangeFilters({ startDate: moment(date).toDate() });
  }

  private onChangePublishedToDate = (date) => {
    this.onChangeFilters({ endDate: moment(date).toDate() });
  }

  private onClearPublishedTime = () => {
    this.updateAndReloadFilters({ startDate: undefined, endDate: undefined});
    this.setCanSearch();
  }

  private onChangeAudiences = (audience: string, chipDeleted: boolean) => {
    var audiencesToEdit = this.props.filters.audienceVisibleTo ?? [];

    if(audiencesToEdit.indexOf(audience) === -1) {
      audiencesToEdit = [...audiencesToEdit, audience];
    }
    else if(audiencesToEdit.indexOf(audience) !== -1) {
      audiencesToEdit = audiencesToEdit.filter(l => l !== audience)
    }

    if(!chipDeleted)
      this.onChangeFilters({audienceVisibleTo: audiencesToEdit})
    else
      this.updateAndReloadFilters({audienceVisibleTo: audiencesToEdit});
  }

  private onClearAudiences = () => {
    this.onChangeFilters({ audienceVisibleTo: [] });
  }
  
  private onKeyUp = (key) => {
    if (key.keyCode === 13)
      this.onChangeSearchText();
  }

  private onUpdateSearchText = (event) => {
    this.setState({ title: event.target.value });
  }

  private clearCanSearch = () => {
    this.setState({ canSearch: false });
  }

  private setCanSearch = () => {
    this.setState({ canSearch: true });
  }
}
  

interface ComponentProps {
  filters: Partial<CategoryTagFilter>;
  id: string;
  onChangeFilters: (filters: Partial<CategoryTagFilter>) => void;
  onClearFilters: () => void;
}

interface ComponentState {
  canSearch: boolean;
  enabledChecked: boolean;
  disabledChecked: boolean;
  title: string;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    lcidMappings: state.resources.lcidMappings,
    lcid: state.settings.tenantSettings.translatedContent,
    audiences: state.audiences.audiences
  })
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(CategoryTagsFilters);