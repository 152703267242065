import * as React from 'react';
import { ConnectedProps, connect } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import WarningIcon from '@mui/icons-material/Warning';

interface ComponentProps {
    platform: string
}

const WarningLabel: React.FC<PropsWithRedux> = ({platform}) => {
    return (
        <div>
            <WarningIcon style={{ color: "#e6911a"}}/> <span className="warning-label-text">{platform} notifications are disabled. Content will be saved, but notification will not be sent.</span>
        </div>
    )
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
    }),
    {
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default (connector(WarningLabel));