import React, { useEffect, useState } from 'react';

interface ComponentProps {
    title: string;
    inputType: string;
    description?: string;
    isDisabled?: boolean;
    onChange: (any) => any;
    placeholder?: string;
    value?: string;
    setTimer?: number;
    disabledText: string;
    pristineText?: string;
    maxLength?: number;
    filter?: string;
}
const InputFieldDouble: React.FunctionComponent<ComponentProps> = ( {title, inputType, description, isDisabled, placeholder, onChange, value, setTimer, disabledText, pristineText, maxLength, filter} ) => {

    const [term, setTerm] = useState(value ?? "");
    const [debouncedTerm, setDebouncedTerm] = useState(term);
    const [onChangeTerm] = useState(() => onChange);

    useEffect(() => {
        const timerId = setTimeout(() => {
            setDebouncedTerm(term);
        }, setTimer ?? 0)

        return () => {
            clearTimeout(timerId);
        }
    }, [setTimer, term])

    useEffect(() => {
        if (debouncedTerm) {
            onChangeTerm(debouncedTerm)
        }
    }, [debouncedTerm, onChangeTerm])

    useEffect(() => {
        if(value === pristineText) {
            setTerm(pristineText ?? "");
        }
    }, [pristineText, value])

    const doubleCheck = (e) => {
        if(filter != null) {
            if(e.target.value.match(filter) != null)
                setTerm(e.target.value);
        }
        else {
            setTerm(e.target.value);
        }
    }

    return (
        <div>
            <label className="profile-input-label" htmlFor={`${title}`}>{title}</label>
            <div className="label-description-block-double">
                <input 
                    className="profile-input-field description" 
                    id={title} 
                    type={inputType}
                    disabled={isDisabled}
                    placeholder={placeholder}
                    value={debouncedTerm}
                    onChange={(e) => doubleCheck(e)}
                    maxLength={maxLength ?? 100}
                    />
                <div style={{marginRight: "15px"}}/>
                <input
                    className="profile-input-field description" 
                    disabled={true}
                    placeholder={disabledText}
                />
                <p  className="description-text">{description}</p>
            </div>
        </div>
    )
}

export default InputFieldDouble;