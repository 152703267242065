import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { push } from "react-router-redux";
import * as audiencesActions from "modules/audiences/actionCreator";
import { GlobalApplicationState } from "globalApplicationState";

import { Audience } from "modules/audiences/models";

import Callout from "modules/common/components/callout";
import Loading from "modules/common/components/loading";

import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";


class AudiencesList extends React.Component<PropsWithRedux, ComponentState> {
  constructor(props: PropsWithRedux) {
    super(props);

    this.state = {
      isLoading: false,
      selectedAudiences: props.selectedAudiences,
    };
  }

  public componentDidMount() {
    if (!this.props.audiences.length) {
      this.setState({ isLoading: true });
      this.props.getAudiences().then(() => {
        this.setState({ isLoading: false });
      });
    }
  }

  public componentDidUpdate(prevProps: PropsWithRedux) {
    if (!!this.props.anchorEl && !prevProps.anchorEl)
      this.setState({ selectedAudiences: this.props.selectedAudiences });
  }

  public render() {
    return (
      <Callout
        anchorEl={this.props.anchorEl}
        open={this.props.calloutOpen}
        setOpen={(toSet) => {
          this.onApply();
          this.props.setCalloutOpen(toSet);
        }}
      >
        {this.getList()}
      </Callout>
    );
  }

  private getList = () => {
    if (this.state.isLoading)
      return <Loading />;
    
    if (!this.props.audiences.length)
      return <div>No audiences were found</div>;
    
    return (
      <List disablePadding>
        {this.props.audiences.map((audience) =>
          <ListItem key={audience.id} dense button onClick={() => this.onSelectAudience(audience)}>
            <ListItemIcon className="callout-checkbox">
              <Checkbox
                edge="start"
                tabIndex={-1}
                disableRipple
                size="small"
                color="primary"
                checked={!!this.state.selectedAudiences.find((selectedAudience) => selectedAudience.id === audience.id)}
              />
            </ListItemIcon>
            <ListItemText primary={audience.displayName} />
          </ListItem>
        )}
      </List>
    );
  }

  private onApply = () => {
    this.props.onChange(this.state.selectedAudiences);
  }

  private onSelectAudience = (audience: Audience) => {
    const hasSelectedAudience: boolean = !!this.state.selectedAudiences.find((selectedAudience) => selectedAudience.id === audience.id);
    if (hasSelectedAudience)
      this.setState({ ...this.state, selectedAudiences: this.state.selectedAudiences.filter((selectedAudience) => selectedAudience.id !== audience.id) });
    else
      this.setState({ ...this.state, selectedAudiences: this.state.selectedAudiences.concat([audience]) });
  }
}


interface ComponentProps {
  anchorEl: any;
  selectedAudiences: Audience[];
  onChange: (audiences: Audience[]) => void;
  calloutOpen: boolean;
  setCalloutOpen: (toSet: boolean) => void;
}

interface ComponentState {
  isLoading: boolean;
  selectedAudiences: Audience[];
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    audiences: state.audiences.audiences,
    tenantId: state.tenant.id
  }),
  {
    getAudiences: audiencesActions.getAudiences,
    redirectTo: push
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(AudiencesList);