import * as activity from "./activity";
import * as audiences from "./audiences";
import * as authoring from "./authoring";
import * as clientPosts from "./clientPosts";
import * as comments from "./comments";
import * as compliance from "./compliance";
import * as documents from "./documents";
import * as draftPost from "./draftPosts";
import * as events from "./events";
import * as insights from "./insights";
import * as mediaLibrary from "./mediaLibrary";
import * as portalPages from "./portalPages";
import * as newsletters from "./newsletters";
import * as posts from "./posts";
import * as profile from "./profile";
import * as reactions from "./reactions";
import * as settings from "./settings";
import * as surveys from "./surveys";
import * as users from "./users";
import * as multitenancy from "./multitenancy";
import * as tags from "./tags";
import * as support from "./support";
import * as categoryTags from "./categorytags";
import * as reports from "./reports";
import * as submissionPosts from "./submissionPosts"

export default {
    activity,
    audiences,
    authoring,
    categoryTags,
    clientPosts,
    comments,
    compliance,
    documents,
    draftPost,
    events,
    insights,
    mediaLibrary,
    portalPages,
    newsletters,
    posts,
    profile,
    reactions,
    reports,
    settings,
    surveys,
    users,
    multitenancy,
    tags,
    support,
    submissionPosts
};
