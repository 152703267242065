
function ShallowEquals(objA: any, objB: any) {
    if (objA === objB) {
        return true;
    }

    for (let key in objA) {
        if (objA[key] !== objB[key]) {
            return false
        }
    }

    for (let key in objB) {
        if (objB[key] !== objA[key]) {
            return false
        }
    }

    return true;
}

export default ShallowEquals;
