import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import CloseIcon from "@mui/icons-material/Close";

import { ChromePicker } from "react-color";


class IntenseEmphasis extends React.Component<PropsWithRedux, ComponentState> {
  constructor(props: PropsWithRedux) {
    super(props);

    this.state = {
      color: props.tenantSettings.themeSettings ? props.tenantSettings.themeSettings.color! : "#247ada"
    };
  }

  componentDidUpdate(prevProps: PropsWithRedux) {
    if (this.props.show && !prevProps.show)
      this.setState({ ...this.state, color: this.props.tenantSettings.themeSettings ? this.props.tenantSettings.themeSettings.color! : "#247ada" });
  }

  public render() {
    return (
      <Dialog open={this.props.show} maxWidth={false} onClose={this.onClose}>
        <DialogTitle className="events-dialog-header">
          <div className="events-dialog-header-title">
            <Typography variant="h2">Intense Emphasis</Typography>
            <IconButton onClick={this.onClose} size="large">
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent dividers className="intense-emphasis-content">
          <TextField variant="outlined" label="Color" value={this.state.color} size="small" onChange={this.onChangeColor} style={{ borderRightColor: this.state.color }} className="intense-emphasis-input" />
          <ChromePicker                
            color={this.state.color}
            onChangeComplete={this.onSelectColor} />
        </DialogContent>
        <DialogActions className="events-dialog-footer">
          <Button variant="text" color="primary" onClick={this.onClose}>Cancel</Button>
          <Button variant="contained" color="primary" disabled={!this.state.color.trim()} onClick={this.onApply}>Apply</Button>
        </DialogActions>
      </Dialog>
    );
  }

  private onApply = () => {
    this.props.onApply(this.state.color);
  }

  private onChangeColor = (event) => {
    this.setState({ ...this.state, color: event.target.value.trim() });
  }

  private onClose = () => {
    this.props.onClose();
  }

  private onSelectColor = (color) => {
    this.setState({ ...this.state, color: color.hex });
  }
}


interface ComponentProps {
  show: boolean;
  onApply: (color: string) => void;
  onClose: () => void;
}

interface ComponentState {
  color: string;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    tenantSettings: state.settings.tenantSettings
  }),
  {}
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(IntenseEmphasis);