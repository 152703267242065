import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { GlobalApplicationState } from 'globalApplicationState';
import { HorizontalBar } from 'react-chartjs-2';
import { Survey, SurveyAnswer } from '../models';
import Loading from "modules/common/components/loading";
import * as actions from '../actionCreator';

import BasePage from "pages/common/basePage";
import Breadcrumb from "pages/common/breadcrumb";
import MainContent from "pages/common/mainContent";

import './surveyDetails.sass';
import { withRouter, RouteComponentProps } from 'react-router';

class SurveyDetails extends React.Component<PropsWithRedux, ComponentState>{
  constructor(props){
    super(props);
    this.state = { 
      survey: null
    };
  }

  public componentDidMount() {
    if(this.props.match.params.surveyId){
      this.props.getSurvey(this.props.match.params.surveyId)
      .then(survey => {
        this.setState(prev => ({ ...prev, survey}));
      });
    }
  }
  
  public render() {
    if(this.props.loading || !this.state.survey) return <Loading />;
    return (
      <BasePage fullWidth>
        <Breadcrumb items={[
          {title: "Surveys", link: '~/admin/surveys'},
          {title: "Details"}
        ]} />
        <MainContent>
          <div className="survey-details-wrapper">
            <h1>{this.state.survey.title}</h1>
              <div className="survey-metadata">
                <div className="left">
                  <h2 className='total-responses'>
                    {`Total Responses`}<br/>
                    {Object.keys(this.state.survey.aggregatedResults).reduce((acc, key) => acc + this.state.survey!.aggregatedResults[key] , 0)}
                  </h2>
                </div>
              <div className="right">
                <li><strong>{`Author: `}</strong>{this.state.survey.author}</li>
                <li><strong>{`Publish Time: `}</strong>{this.formattedDate(this.state.survey.publishTime)}</li>
                <li><strong>{`Expiry Time: `}</strong>{this.state.survey.expiryTime ? this.formattedDate(this.state.survey.expiryTime) : 'Never'}</li>
              </div>
            </div>
            <div className="survey-data">
              <h4 className="survey-question">{this.state.survey.questions[0].body}</h4>
              <HorizontalBar
                data={{
                  labels: this.getLabels(0),
                  datasets: [{
                      label: 'Responses',
                      data: this.getQuestionData(),
                      backgroundColor: Array(5).fill('#36A2EB'),
                      borderWidth: 1,
                      stepSize: 1
                  }]
                }}
                options={{scales: { xAxes: [{ ticks: { min: 0, stepSize: 10 }}]}}}
              />
            </div>
          </div>
        </MainContent>
      </BasePage>
    );
  }

  private formattedDate = (dateString) => {
    return new Date(dateString).toLocaleString();
  }

  private getQuestionData = () => {
    const results = this.state.survey!.aggregatedResults;
    return Object.keys(results)
      .map(key => results[key]);
  }

  private getLabels = (questionIndex) => {
    const answers = this.state.survey!.questions[questionIndex].answers;
    return answers
    .map(a => this.makeMultiline(a.body).concat(`${this.isYourAnswer(a) ? ' (your response)' : ''}`));
  }

  private makeMultiline = (label: string): string[] => {
    const words = label.split(" ");
    let newLabel = [] as string[];
    let i = 0;
    while (words.length > 0){
      let selectedWords = words.slice(0, i);
      if(i === words.length - 1 || this.totalCharactersInWords(selectedWords) > 18){
        newLabel.push(words.splice(0, i+1).join(" "));
        i = 0;
      }else{
        i++;
      }
    }
    return newLabel;
  }

  private totalCharactersInWords = (words: string[]) => {
    return words.reduce((acc, word) => acc + word.length, 0);
  }

  private isYourAnswer = (answer: SurveyAnswer) => {
    return !!this.state.survey && this.state.survey.userResponse && answer.id === this.state.survey.userResponse.answerId;
  }
}

interface RouteParams {
  surveyId: string;
}

interface ComponentProps {
}

interface ComponentState{
  survey: Survey | null
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps & RouteComponentProps<RouteParams>) => ({
      ...ownProps,
      loading: state.surveys.isFetching
  }),
  {
      getSurvey: actions.getSurvey
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(withRouter(SurveyDetails));