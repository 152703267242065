import * as React from "react";


class Title extends React.Component<ComponentProps, ComponentState> {
  public render() {
    if (!this.props.title)
      return <React.Fragment></React.Fragment>;
    
    return (
      <div className="title">{this.props.title}</div>
    );
  }
}


interface ComponentProps {
  title: string;
}

interface ComponentState {}

export default Title;