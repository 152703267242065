/**
 * Map userSettings.accountType to b2c identity provider domain
 * Note: returns must match the idp list in our b2c policy
 */
export const mapSocialProviderNameToDomainHint = (providerName: string): string => {
    let result: string = "";
    providerName = providerName.toLowerCase();

    if (providerName.startsWith("azuread"))
        result = "https://login.microsoftonline.com";
    else if (providerName.startsWith("google"))
        result = "google.com";
    else if (providerName.startsWith("linkedin"))
        result = "linkedin.com";
    else if (providerName.startsWith("facebook"))
        result = "Facebook.com";
    else if (providerName.startsWith("apple"))
        result = "https://appleid.apple.com";
    else if (providerName.startsWith("live"))
        result = "https://login.live.com";
    else if (providerName.startsWith("sparrow-local"))
        result = "sparrowconnected.onmicrosoft.com";

    return result;
}
