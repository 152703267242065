import React, { useState } from "react";
import { injectIntl, IntlShape } from "react-intl";

import { PostOverview } from "modules/posts/models";
import ProgressiveImage from "modules/common/components/progressiveImage";
import CanvasPreviewImage from "modules/common/components/canvasPreviewImage";

import { Box, Button, Grid, Typography } from "@mui/material";

import "./contentOverview.sass";
import ChipList from "modules/common/components/chipList";

import LockIcon from "@mui/icons-material/Lock";
import { dateOptions } from "utils/dateFormatting";
import moment from "moment";
import { MultiSelectTopic } from "modules/settings";

// TODO: relocate file if component extended to more than posts
// TODO: add react-intl where neceessary here post fix

const ContentOverviewSideBar: React.FunctionComponent<ComponentProps> = (props) => {
    const [showMore, setShowMore] = useState(false);
    const imgSrc = props.postDetails.imageUrl || null;

    let infoHeadings = [
        props.intl.formatMessage({ id: "posts.published", defaultMessage: "Published on" }),
        props.intl.formatMessage({ id: "posts.lastModified", defaultMessage: "Last modified on" }),
        props.intl.formatMessage({ id: "common.type", defaultMessage: "Type" }),
        props.intl.formatMessage({ id: "posts.state", defaultMessage: "State" }),
    ];

    let authorHeadings = [
        props.intl.formatMessage({ id: "posts.publishedBy", defaultMessage: "Published by" }),
        props.intl.formatMessage({ id: "posts.author", defaultMessage: "Author" }),
    ];

    let postInfo = [
        props.postDetails.datePublished
            ? moment(props.postDetails.datePublished).locale("en").format(dateOptions.basicWithHoursWithSpacing)
            : "",
        props.postDetails.updatedTime
            ? moment(props.postDetails.updatedTime).locale("en").format(dateOptions.basicWithHoursWithSpacing)
            : "",
        capitalizePostType(),
        props.postDetails.state,
    ];

    let authorInfo = [
        props.postDetails.lastPublishedByName, 
        props.postDetails && props.postDetails.author ? props.postDetails.author.name : "",
    ];

    if (props.postDetails.bannersSet === "") {
        let removeIndex = infoHeadings.findIndex((h) => h === "Set as");
        infoHeadings = infoHeadings.filter((h, index) => index !== removeIndex);
        postInfo = postInfo.filter((h, index) => index !== removeIndex);
    }

    function getNotifications() {
        let notificationsReturn = props.postDetails.notifications
            ? (props.postDetails.notifications.teamsOnPublish !== "none" ? "MS Teams, " : "") +
              (props.postDetails.notifications.mobileOnPublish !== "none" ? "Mobile App, " : "") +
              (props.postDetails.notifications.emailOnPublish !== "none" ? "Email, " : "") +
              (props.postDetails.notifications.smsOnPublish !== "none" ? "SMS" : "")
            : "";

        //Checks if there is a comma and a whitespace
        if (notificationsReturn && notificationsReturn.slice(-2) === ", ") {
            return notificationsReturn.substring(0, notificationsReturn.length - 2);
        } else if (notificationsReturn !== "") {
            return notificationsReturn;
        } else {
            return "None";
        }
    }

    function capitalizePostType() {
        return props.postDetails.postType.charAt(0).toUpperCase() + props.postDetails.postType.slice(1);
    }

    return (
        <div className="content-overview">
            <div className="side-bar">
                {!imgSrc ? null : !props.postDetails.cropPoints ? (
                    <ProgressiveImage src={imgSrc} previewSrc={imgSrc} />
                ) : (
                    <CanvasPreviewImage src={imgSrc} cropPoints={props.postDetails.cropPoints} />
                )}
                <div className="info-wrapper">
                    <div className="heading">{props.intl.formatMessage({ id: "common.title", defaultMessage: "Title" })}</div>
                    <div className="post-title">{props.postDetails.translatedContent[props.defaultLCID]["title"]}</div>
                    {props.postDetails.tags.length > 0 ? (
                        <ChipList
                            onClose={() => {}}
                            truncateAtIndex={3}
                            items={props.postDetails.tags.map((opt: MultiSelectTopic) => ({
                                id: opt.id,
                                name: opt.name,
                                icon: opt.restricted ? (
                                    <LockIcon
                                        style={{ fontSize: "16px", color: "#B72026", position: "relative", top: "2px", marginLeft: "4px" }}
                                    />
                                ) : undefined,
                            }))}
                        />
                    ) : null}
                </div>
                <hr />
                <div className="info-wrapper">
                    {authorHeadings.map((item, index) => {
                        return (
                            <Grid key={index} container spacing={0}>
                                <Grid item xs={5}>
                                    <Box sx={{ fontWeight: "bold", m: 0.5, fontSize: "14px" }}>{item}</Box>
                                </Grid>
                                <Grid item xs={7}>
                                    <Box sx={{ fontWeight: "bold", m: 0.5, fontSize: "14px" }}>{authorInfo[index]}</Box>
                                </Grid>
                            </Grid>
                        );
                    })}
                </div>
                <hr />
                <div className="info-wrapper">
                    {infoHeadings.map((item, index) => {
                        return (
                            <Grid key={index} container spacing={0}>
                                <Grid item xs={5}>
                                    <Box sx={{ fontWeight: "bold", m: 0.5, fontSize: "14px" }}>{item}</Box>
                                </Grid>
                                <Grid item xs={7}>
                                    <Box sx={{ fontWeight: "bold", m: 0.5, fontSize: "14px" }}>{postInfo[index]}</Box>
                                </Grid>
                            </Grid>
                        );
                    })}
                </div>
                {showMore && (
                    <div>
                        <hr />
                        <div className="item" style={{ paddingBottom: "4%" }}>
                            <Typography variant="caption" fontSize={"12px"}>
                                {props.intl.formatMessage({
                                    id: "posts.notificationsUponPublishing",
                                    defaultMessage: "Notifications upon publishing",
                                })}
                            </Typography>
                        </div>

                        <Grid container spacing={0}>
                            <Grid item xs={5}>
                                <Box sx={{ fontWeight: "bold", m: 0.5, fontSize: "14px" }}>
                                    {" "}
                                    {props.intl.formatMessage({ id: "posts.notificationsLastSent", defaultMessage: "Last sent on" })}
                                </Box>
                            </Grid>
                            <Grid item xs={7}>
                                <Box sx={{ fontWeight: "bold", m: 0.5, fontSize: "14px" }}>
                                    {props.postDetails.datePublished
                                        ? moment(props.postDetails.datePublished).locale("en").format(dateOptions.basicFormatting)
                                        : ""}
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid container spacing={1}>
                            <Grid item xs={5}></Grid>
                            <Grid item xs={6}>
                                <Typography variant="caption" fontSize="14px">
                                    {getNotifications()}
                                </Typography>
                            </Grid>
                            <Grid item xs={1}></Grid>
                        </Grid>
                    </div>
                )}
                <Box textAlign="center">
                    <Button variant="text" style={{ backgroundColor: "transparent", paddingTop: '8%' }} onClick={() => setShowMore(!showMore)}>
                        {showMore ? "SEE LESS" : "SEE MORE"}
                    </Button>
                </Box>
            </div>
        </div>
    );
};

interface ComponentProps {
    postDetails: PostOverview;
    defaultLCID: string;
    intl: IntlShape;
}

export default injectIntl(ContentOverviewSideBar);
