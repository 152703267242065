import * as React from 'react';
import shallowEquals from 'utils/shallowEquals';

interface ComponentProps {
    distanceThreshold: number
    forPosts?: boolean
    hasNext: boolean
    loading: boolean
    usesWindowScroll?: boolean
    onThresholdCrossed: () => void
    loadingBlockRenderer: () => JSX.Element | null
    loadingBlockFallbackRenderer: () => JSX.Element | null
    collapsed?: boolean
    
}

export default class InfiniteScrollView extends React.Component<ComponentProps, {}> {

    private _container: HTMLElement;
    private _childrenContainer: HTMLElement;

    public shouldComponentUpdate(nextProps: ComponentProps) {
        return !shallowEquals(nextProps, this.props);
    }

    public componentDidMount() {
        if(this.props.usesWindowScroll) {
            window.addEventListener('scroll', this.handleScrollEvent);
        }
    }

    public componentWillUnmount() {
        if(this.props.usesWindowScroll){
            window.removeEventListener('scroll', this.handleScrollEvent)
        }
    }

    public render() {
        const containerStyle: React.CSSProperties = this.props.usesWindowScroll ? {} :
        { position: 'absolute', width: '100%', height: '100%', overflowY: 'scroll', overflowX: 'hidden'}

        return (
            <div className="infinite-scroll-view" ref={e => this._container = e!}
                onScroll={this.onContainerScroll}
                style={{display: this.props.collapsed? 'none' : 'block', paddingBottom: this.props.forPosts? '40px' : '', ...containerStyle}}>
                <div ref={e => this._childrenContainer = e!}>
                    { this.props.children }
                </div>
                <div style={{marginTop: this.props.forPosts ? '-40px' : ''}}>
                    { this.renderLoadingBlock() }
                </div>
            </div>
        );
    }

    public handleScrollEvent = () => {
        if (this.props.loading || !this.props.hasNext) {
            return;
        }

        if (this.getDistance() < this.props.distanceThreshold) {
            this.props.onThresholdCrossed()
        }
    }

    public onContainerScroll = () => {
        if(!this.props.usesWindowScroll){
            this.handleScrollEvent();
        }
    }

    private renderLoadingBlock = () => {
        if (!this.props.loading && !this.props.hasNext) {
            return null;
        }
         else if (this.props.loading) {
            return this.props.loadingBlockRenderer();
        } 
        else {
            return this.props.loadingBlockFallbackRenderer();
        }
    }

    private getDistance = () => {
        if (this.props.usesWindowScroll) {
            let bottomOfWindow = window.pageYOffset + window.innerHeight;
            return this._childrenContainer.clientHeight - bottomOfWindow;
        } else {
            let bottomOfPane = this._container.scrollTop + this._container.offsetHeight;
            return this._childrenContainer.clientHeight - bottomOfPane;
        }
    }
}
