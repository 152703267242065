import * as React from "react";
import { useRef, useState } from "react";

import { CroppableImage } from "../models";
import Pagination from "../../pagination/pagination";
import Cropper from "../cropper/cropper";
import Loading from "../../loading";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { UnfoldMore } from "@mui/icons-material";
import PaddedImage from "../../paddedImage";

const ImageCropper: React.FunctionComponent<ComponentProps> = ({
    images,
    loading = false,
    show,
    ...props
}) => {
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [isCropping, setIsCropping] = useState(true);
    const cropper = useRef<Cropper | null>(null);

    React.useEffect(() => {
        if (!show)
            setCurrentPage(0);
            setIsCropping(true);

    }, [show]);

    const getCurrentImage = () => {
        if (!images) return undefined;

        let result: CroppableImage | undefined = undefined;
        if (currentPage < images.length)
            result = images[currentPage];

        return result;
    }

    const imageToCrop = getCurrentImage();
    const cropBoundary = props.cropBoundary || { width: 560, height: 315 };

    const onPageChange = (newPage: number) => {
        if (!images) return;
        let imageToSet:CroppableImage = { ...imageToCrop! };

        if(isCropping) {
            const cropperData = cropper.current!.getData() as { points: number[], zoom: number };
            imageToSet.transforms = cropperData;
        }

        props.onPageChange(imageToSet, newPage === images.length);
        
        if (newPage < 0) newPage = 0;
        if (newPage >= images.length) newPage = images.length - 1;
        
        setCurrentPage(newPage);
    }

    const getButtonLabel = (): string => {
        let result = "Finish";
        if (!images) return result;

        // if on last image
        if (images.length - 1 === currentPage)
            result = "Finish";
        else
            result = "Next";

        return result;
    }

    return (
        <Dialog PaperProps={{ style: { maxHeight: 616 }}} open={show} maxWidth={false} onClose={props.onClose}>
            <DialogTitle className="events-dialog-header">
                <div className="events-dialog-header-title">
                    <Typography variant="h2">{(images?.length || 0) > 1 ? "Adjust Images" : "Adjust Image"}</Typography>
                    <IconButton onClick={props.onClose} size="large">
                        <CloseIcon />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent dividers className="authoring-image-cropper-content">
                {loading && <Loading padding={10} />}
                {!!imageToCrop && !loading && isCropping &&
                    <Cropper
                        boundary={cropBoundary}
                        viewport={cropBoundary}
                        url={imageToCrop.url}
                        cropPoints={imageToCrop.transforms.points}
                        zoom={imageToCrop.transforms.zoom}
                        ref={(c) => { cropper.current = c }}
                    />
                }
                {!!imageToCrop && !loading && !isCropping && 
                    <PaddedImage 
                        width="800px"
                        height="439px"
                        backgroundColor="#DDE1E5"
                        src={imageToCrop.url}
                    />
                }
            </DialogContent>
            <DialogActions className="events-dialog-footer">
                <Button 
                    variant={!isCropping ? "contained" : "outlined"} 
                    color="primary" 
                    onClick={() => setIsCropping(!isCropping)} 
                    style={{minWidth: "50px", width: "50px", borderRadius: "100%"}}
                >
                    <UnfoldMore style={{transform: "rotate(45deg)"}} fontSize="large"/>
                </Button>
                <div style={{ margin: "auto" }}>
                    <Pagination onPageChange={onPageChange} count={images?.length || 0} currentPage={currentPage} />
                </div>
                <Button disabled={currentPage === 0} variant="text" color="primary" onClick={() => { onPageChange(currentPage - 1); }}>Previous</Button>
                <Button variant="contained" color="primary" onClick={() => { onPageChange(currentPage + 1); }}>{getButtonLabel()}</Button>
            </DialogActions>
        </Dialog>
    );
}

interface ICropBoundary {
    width: number;
    height: number;
}

interface ComponentProps {
    show: boolean;
    images?: CroppableImage[];
    loading?: boolean;
    cropBoundary?: ICropBoundary
    skippable?: boolean;
    onPageChange: (image: CroppableImage, finished: boolean) => void;
    onClose: () => void;
}

export default ImageCropper;
