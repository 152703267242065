import { Action } from "redux";
import { CompleteAction, ActionCreator } from "typedActions";


import { Document } from "modules/documents/models";
import { Image } from "modules/gallery/models";


export const GET_DOCUMENT = "GET_DOCUMENT";
export interface GetDocument extends Action {}
export const GetDocument = ActionCreator<GetDocument>(GET_DOCUMENT);

export const GET_DOCUMENT_COMPLETE = "GET_DOCUMENT_COMPLETE";
export interface GetDocumentComplete extends Action {}
export const GetDocumentComplete = ActionCreator<GetDocumentComplete>(GET_DOCUMENT_COMPLETE);


export const GET_DOCUMENT_GALLERY = "GET_DOCUMENT_GALLERY";
export interface GetDocumentGallery extends Action {}
export const GetDocumentGallery = ActionCreator<GetDocumentGallery>(GET_DOCUMENT_GALLERY);

export const GET_DOCUMENT_GALLERY_COMPLETE = "GET_DOCUMENT_GALLERY_COMPLETE";
export interface GetDocumentGalleryComplete extends CompleteAction {
  currentPage: number;
  documents: Document[];
  totalDocuments: number;
  totalPages: number;
}
export const GetDocumentGalleryComplete = ActionCreator<GetDocumentGalleryComplete>(GET_DOCUMENT_GALLERY_COMPLETE);


export const GET_IMAGE = "GET_IMAGE";
export interface GetImage extends Action {}
export const GetImage = ActionCreator<GetImage>(GET_IMAGE);

export const GET_IMAGE_COMPLETE = "GET_IMAGE_COMPLETE";
export interface GetImageComplete extends CompleteAction {}
export const GetImageComplete = ActionCreator<GetImageComplete>(GET_IMAGE_COMPLETE);


export const GET_IMAGE_GALLERY = "GET_IMAGE_GALLERY";
export interface GetImageGallery extends Action {}
export const GetImageGallery = ActionCreator<GetImageGallery>(GET_IMAGE_GALLERY);

export const GET_IMAGE_GALLERY_COMPLETE = "GET_IMAGE_GALLERY_COMPLETE";
export interface GetImageGalleryComplete extends CompleteAction {
  currentPage: number;
  images: Image[];
  totalImages: number;
  totalPages: number;
}
export const GetImageGalleryComplete = ActionCreator<GetImageGalleryComplete>(GET_IMAGE_GALLERY_COMPLETE);


export const GET_VIDEO = "GET_VIDEO";
export interface GetVideo extends Action {}
export const GetVideo = ActionCreator<GetVideo>(GET_VIDEO);

export const GET_VIDEO_COMPLETE = "GET_VIDEO_COMPLETE";
export interface GetVideoComplete extends CompleteAction {}
export const GetVideoComplete = ActionCreator<GetVideoComplete>(GET_VIDEO_COMPLETE);

export const GET_VIDEOS_COMPLETE = "GET_VIDEOS_COMPLETE";
export interface GetVideosComplete extends Action {}
export const GetVideosComplete = ActionCreator<GetVideosComplete>(GET_VIDEOS_COMPLETE);


export const UPLOAD_DOCUMENT = "UPLOAD_DOCUMENT";
export interface UploadDocument extends Action {}
export const UploadDocument = ActionCreator<UploadDocument>(UPLOAD_DOCUMENT);

export const UPLOAD_DOCUMENT_COMPLETE = "UPLOAD_DOCUMENT_COMPLETE";
export interface UploadDocumentComplete extends CompleteAction {}
export const UploadDocumentComplete = ActionCreator<UploadDocumentComplete>(UPLOAD_DOCUMENT_COMPLETE);


export const UPLOAD_IMAGE = "UPLOAD_IMAGE";
export interface UploadImage extends Action {}
export const UploadImage = ActionCreator<UploadImage>(UPLOAD_IMAGE);

export const UPLOAD_IMAGE_COMPLETE = "UPLOAD_IMAGE_COMPLETE";
export interface UploadImageComplete extends CompleteAction {}
export const UploadImageComplete = ActionCreator<UploadImageComplete>(UPLOAD_IMAGE_COMPLETE);