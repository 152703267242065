//Please note: I have left the functions to be passed in as props instead to leave this component a little more flexible. 

import { Button, Checkbox, Divider, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { TenantSettingsTagGroup } from "modules/settings";
import React from "react";
import FilterDropdown from "./filters/filterDropdown";

const TopicDropdown: React.FunctionComponent<ComponentProps> = ({ tagSelected, availableTopics, currentTags, label = "Topics", handleTagGroupClick, checkGroupSelected, onChangeTags, onHandleAllTags }) => {

    return (
        <FilterDropdown text={label}>
            <div style={{height: "154px"}}>
                <List disablePadding style={{maxHeight: "154px", overflow: "auto"}}>
                {availableTopics.map(tagGroup => {
                    if(tagGroup.tags.length <= 0) { //Don't show topic group when there's 0 available options.
                        return null;
                    }
                    return (
                    <React.Fragment key={tagGroup.id}>
                        <ListItem button onClick={() => handleTagGroupClick(tagGroup)} className="select-popover-group-header-clickable">
                        <Checkbox
                            edge="start"
                            tabIndex={-1}
                            disableRipple
                            size="small"
                            color="primary"
                            checked={checkGroupSelected(tagGroup)}
                        />
                        {tagGroup.name} 
                        </ListItem>
                        {tagGroup.tags.map(tag => {
                        return (
                        <ListItem key={tag.id} dense button onClick={() => onChangeTags(tag.id)} style={{paddingLeft: "40px"}}>
                            <ListItemIcon className="callout-checkbox">
                            <Checkbox
                                edge="start"
                                tabIndex={-1}
                                disableRipple
                                size="small"
                                color="primary"
                                checked={!!currentTags.find((selectedTag) => selectedTag === tag.id)}
                            />
                            </ListItemIcon>
                            <ListItemText primary={tag.name} />
                        </ListItem>
                        )})}
                    </React.Fragment>
                    )
                })}
                </List>
            </div>
            <div>
                <Divider/>
                <Button color="primary" style={{float: "right"}} onClick={() => onHandleAllTags(tagSelected)}>
                {tagSelected ? "Unselect" : "Select"} All
                </Button>
            </div>
        </FilterDropdown>
    )
}

interface ComponentProps {
    tagSelected: boolean;
    availableTopics: TenantSettingsTagGroup[];
    currentTags: string[];
    label?: string;
    handleTagGroupClick: (tagGroup: TenantSettingsTagGroup) => void;
    checkGroupSelected: (tagGroup: TenantSettingsTagGroup) => boolean;
    onChangeTags: (id: string) => void;
    onHandleAllTags: (isSelected: boolean) => void;
}

export default TopicDropdown;