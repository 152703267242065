import React, { useCallback, useEffect, useState } from 'react';
import numeral from "numeral";

import { ResponsiveBar } from '@nivo/bar';

import { insightsApi } from '../../../../api/instances';
import { PlatformStats } from '../../../insights/models';
import InfoHover from 'modules/common/components/hovers/infoHover';


interface Data {
    id: string;
    value: number;
}

const UserPlatformStats = () => {

    const [platformStats, setPlatformStats] = useState<Data[]>([]);

    const groupSimilarResponses = useCallback((stats?: PlatformStats[]) => {
        let legacyAndroidCount = stats?.find(e => e.platformName === 'Android Legacy')?.readCount;
        let legacyAppleCount = stats?.find(e => e.platformName === 'Apple Legacy')?.readCount;

        if (typeof stats !== undefined) {
            stats = addOrUpdatePlatform(stats!, legacyAndroidCount!, "Android");
            stats = addOrUpdatePlatform(stats!, legacyAppleCount!, "Apple");
        }

        stats = stats?.filter(e => e.platformName !== 'Android Legacy' && e.platformName !== 'Apple Legacy');
        return stats ?? [];
    }, []);

    const addOrUpdatePlatform = (stats: PlatformStats[], count: number, platform: string) => {
        if (count <= 0 || count === undefined) {
            return stats;
        }

        const index = stats.findIndex(e => e.platformName === platform)
        if (index < 0) {
            stats.push({ platformName: platform, readCount: count} as PlatformStats);
        } else {
            stats[index].readCount = stats[index].readCount + count;
        }

        return stats;
    }
    useEffect(() => {
        insightsApi.GetAppUsageStats()
        .then((response) => {
            var groupedResponse = groupSimilarResponses(response);
            var remap = groupedResponse.map((response) => {
                return { id: response.platformName, value: response.readCount } as Data
            })
            setPlatformStats(remap.reverse());
        })
    }, [groupSimilarResponses]);


    const palette: string[] = ["#3b78ab","#453a6e","#aa214e","#366531","#e6911a","#599fa0","#36404a"];
    const getColor = (bar): string => palette[bar.index];

    return (
        <div className="nivo-container">
            <div className="nivo-container-title">Channel Usage <InfoHover>Reads by platform</InfoHover></div>
            <div style={{ height: 400, width: 450 }}>
                <ResponsiveBar
                    colors={getColor}
                    data={platformStats.map(d => ({ ...d, id: `${d.id}: ${numeral(d.value).format("0,0")}`}))}
                    layout="horizontal"
                    axisBottom={null}
                    axisLeft={{
                        tickSize: 0,
                        tickPadding: 6,
                        tickRotation: 0
                    }}
                    enableGridX={false}
                    enableGridY={false}
                    enableLabel={false}
                    isInteractive={false}
                    margin={{ top: 40, right: 80, bottom: 20, left: 150 }}
                    theme={{
                        axis: {
                            domain: {
                                line: {
                                    stroke: "#dde1e5",
                                    strokeWidth: 1
                                }
                            }
                        }
                    }} 
                />
            </div>
        </div>
    )
}

export default UserPlatformStats;