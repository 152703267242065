import * as React from "react";
import FilledInput from "../forms/inputs/filledInput";


class Title extends React.Component<ComponentProps, ComponentState> {
  constructor(props) {
    super(props);

    this.state = {
      oldTitle: "",
    };
  }
  public render() {
    return (
      <div className="multilingual-editor-field">
        <FilledInput
          refreshable
          label={"Title"}
          required
          value={this.props.title}
          characterCount
          maxCount={100}
          inputProps={{
              placeholder: `Enter ${this.props.type} title`,
              onFocus: this.onFocusTitle,
              onBlur: this.onBlurTitle,
              style: {
                  fontSize: "1rem",
                  minHeight: "auto",
                  padding: "8.5px 14px",
              }
          }}
          inputStyle={{
              borderRadius: "4px",
              minHeight: "auto",
              padding: "unset",
          }}
        />
      </div>
    );
  }

  private onChangeTitle = (newTitle: string) => {
    this.props.onChange(newTitle);
  }

  private onFocusTitle = (event: React.FocusEvent<HTMLInputElement>) => {
    let oldTitle = event.target.value;
    this.setState({ oldTitle });
  }

  private onBlurTitle = (event: React.FocusEvent<HTMLInputElement>) => {
    let newTitle = event.target.value as string;
    if (this.state.oldTitle !== newTitle) {
        this.onChangeTitle(newTitle);
    }
  }
}


interface ComponentProps {
  title: string;
  type: "post" | "event";
  onChange: (title: string) => void;
}

interface ComponentState {
  oldTitle: string;
}

export default Title;