import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";

import InfoHover from "modules/common/components/hovers/infoHover";

import { DocumentAvailability } from "../../../models";

import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";


interface MenuItem {
  value: string;
  text: string;
}

const Language: React.FunctionComponent<PropsWithRedux> = props => {
  const getAvailableLanguages = () => {
    if (!props.tenantSettings || !props.lcidMappings)
      return [];

    let availableLanguages: MenuItem[] = [];
    Object.keys(props.tenantSettings.translatedContent).map((lcid) => {
      if (!!props.lcidMappings[lcid])
        availableLanguages.push({ value: lcid, text: props.lcidMappings[lcid].language });
      return lcid;
    });
    return availableLanguages;
  }
  
  const availableLanguages = getAvailableLanguages();

  if (availableLanguages.length <= 1)
    return <React.Fragment></React.Fragment>;
  
  return (
    <div>
      <div>
        <span>Language</span>
        <InfoHover>Helps you look for and filter documents by language.</InfoHover>
      </div>
      <FormControl size="small" fullWidth>
        <Select
          variant="outlined"
          displayEmpty
          fullWidth
          value={props.lcid}
          onChange={(event) => props.onChange(event.target.value as DocumentAvailability)}
        >
          <MenuItem value=""><div style={{ color: "rgba(0, 0, 0, 0.4)" }}>Select a language</div></MenuItem>
          {availableLanguages.map(availableLanguage => (
            <MenuItem key={availableLanguage.value} value={availableLanguage.value}>{availableLanguage.text}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}


interface ComponentProps {
  lcid: string;
  onChange: (lcid: string) => void;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    tenantSettings: state.settings.tenantSettings,
    lcidMappings: state.resources.lcidMappings
  })
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(Language);