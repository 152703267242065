import * as React from 'react';
import '../styles/multiselect.sass';
import Select, {Props} from 'react-select';

interface componentProps extends Props {
    registerFocus?: (ref) => void
    disabled?: boolean
}

export default class MultiSelect extends React.PureComponent<componentProps, {}> {

    private instance: any;

    public componentDidMount() {
        if (this.props.registerFocus) {
            this.props.registerFocus(this.instance);
        }
    }

    public render() {
        return (
            <div style={{'flexBasis': '100%', 'maxWidth': '498px'}}>
                <Select
                isMulti { ...this.props }
                menuPortalTarget={document.body}
                menuPosition={'fixed'}  />
            </div>
        )
    }

}
