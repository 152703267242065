import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";

import { ContentAnalysis as IContentAnalysis, TranslatedContent as ITranslatedContent, TranslatableContent } from "./models";
import ContentAnalysis from "./dialogs/contentAnalysis";
import { Body } from "./body";
import Summary from "./summary";
import Title from "./title";

import confirm from "utils/notyPopups";

import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import TimelineIcon from "@mui/icons-material/Timeline";
import TranslateIcon from "@mui/icons-material/Translate";
import { ILanguage } from "modules/localization/models";
import TranslateContentConfirmation from "./dialogs/translateContentConfirmation";
import { ICustomCssModelv1 } from "api/files";

class TranslatedContent extends React.Component<PropsWithRedux, ComponentState> {
    constructor(props: PropsWithRedux) {
        super(props);

        const availableLanguages: ILanguage[] = this.getAvailableLanguages();

        this.state = {
            activeLcid: props.tenantSettings ? props.tenantSettings.defaultLCID : "en-us",
            availableLanguages: availableLanguages,
            displayedLanguages: availableLanguages.filter((availableLanguage) => availableLanguage.visible),
            showAddLanguagesList: false,
            showContentAnalysis: false,
            showTranslationDialog: false,
            translatableLanguagesWithText: [],
            checkIfAnyTranslabableContent: false,
        };
    }

    componentWillMount() {
        this.setLanguageAvailability();
    }

    componentDidUpdate(prevProps: PropsWithRedux, prevState: ComponentState) {
        if ((!prevProps.lcidMappings && !!this.props.lcidMappings) || (!prevProps.tenantSettings && !!this.props.tenantSettings)) {
            const availableLanguages: ILanguage[] = this.getAvailableLanguages();

            this.setState({
                ...this.state,
                availableLanguages: availableLanguages,
                displayedLanguages: availableLanguages.filter((availableLanguage) => availableLanguage.visible),
            });
        }

        if (prevProps.translatedContent !== this.props.translatedContent) {
            this.setLanguageAvailability();
        }
    }

    public render() {
        if (!this.props.lcidMappings || !this.props.tenantSettings) return null;

        const { activeLcid } = this.state;
        const translatedContent: ITranslatedContent = this.props.translatedContent || {
            [activeLcid]: { body: "", description: "", title: "" },
        };
        const content: TranslatableContent = translatedContent[activeLcid] || { body: "", description: "", title: "" };

        const addLanguagesAvailable: boolean = this.state.displayedLanguages.length !== this.state.availableLanguages.length;

        return (
            <div>
                <div className="language-tabs">
                    <div>
                        {this.state.displayedLanguages.map((displayedLanguage, index) => {
                            const isActiveTab: boolean = activeLcid === displayedLanguage.lcid;
                            const text: string = `${displayedLanguage.language} (${displayedLanguage.lcid})`;
                            return (
                                <div
                                    key={displayedLanguage.lcid}
                                    onClick={() => this.onSelectTab(displayedLanguage)}
                                    className={`language-tab${isActiveTab ? " active-tab" : ""}`}
                                >
                                    <Typography key={displayedLanguage.lcid} data-text={text} className="language-tab-text">
                                        {text}
                                    </Typography>
                                    {!!index && (
                                        <IconButton
                                            size="small"
                                            onClick={(ev) => this.onShowRemoveContentConfirmation(ev, displayedLanguage)}
                                            className="delete-language-tab"
                                        >
                                            <DeleteIcon fontSize="small" />
                                        </IconButton>
                                    )}
                                </div>
                            );
                        })}
                        {addLanguagesAvailable && (
                            <ClickAwayListener onClickAway={this.onHideAddLanguagesList}>
                                <div className="add-language">
                                    <Button
                                        variant="text"
                                        color="primary"
                                        size="small"
                                        startIcon={<AddIcon />}
                                        onClick={this.onToggleAddLanguagesList}
                                    >
                                        Add Language
                                    </Button>
                                    {this.state.showAddLanguagesList && (
                                        <List className="add-languages-list">
                                            {this.state.availableLanguages
                                                .filter((availableLanguage) => !availableLanguage.visible)
                                                .map((availableLanguage) => (
                                                    <ListItem
                                                        key={availableLanguage.lcid}
                                                        button
                                                        onClick={() => this.onAddLanguage(availableLanguage)}
                                                    >
                                                        <ListItemText
                                                            primary={`${availableLanguage.language} (${availableLanguage.lcid})`}
                                                            className="add-languages-list-item"
                                                        />
                                                    </ListItem>
                                                ))}
                                        </List>
                                    )}
                                </div>
                            </ClickAwayListener>
                        )}
                    </div>
                    {this.state.activeLcid === "en-us" && !!content.body && (
                        <Button
                            variant="text"
                            color="primary"
                            size="small"
                            startIcon={<TimelineIcon />}
                            onClick={this.onShowContentAnalysis}
                        >
                            Analyze Content
                        </Button>
                    )}

                    <Button
                        variant="text"
                        color="primary"
                        size="small"
                        startIcon={<TranslateIcon />}
                        disabled={this.state.checkIfAnyTranslabableContent}
                        onClick={this.onShowTranslationDialog}
                    >
                        Translate
                    </Button>
                </div>
                {this.state.showTranslationDialog && (
                    <TranslateContentConfirmation
                        show={this.state.showTranslationDialog}
                        onConfirm={this.onConfirmDialog}
                        onCancel={this.onCloseTranslationDialog}
                        currentLanguagesWithText={this.state.translatableLanguagesWithText}
                    />
                )}
                <Paper elevation={0} className="section-card">
                    <div className="multilingual-editor">
                        <Title
                            title={content.title}
                            type={this.props.type}
                            onChange={this.onChangeTitle}
                        />
                        <Summary
                            show={this.props.showSummary}
                            summary={content.description}
                            onChange={this.onChangeSummary}
                            onRemove={this.onRemoveSummary}
                        />
                        <Body
                            customCss={this.props.customCss}
                            body={content.body}
                            lcid={this.state.activeLcid}
                            onChange={this.onChangeBody}
                        />
                    </div>
                </Paper>
                <ContentAnalysis
                    show={this.state.showContentAnalysis}
                    id={this.props.id}
                    body={content.body}
                    getContentAnalysis={this.props.getContentAnalysis}
                    onClose={this.onHideContentAnalysis}
                />
            </div>
        );
    }

    private getAvailableLanguages = (): ILanguage[] => {
        if (!this.props.lcidMappings || !this.props.tenantSettings) return [];

        const defaultLcid: string = this.props.tenantSettings.defaultLCID;
        const lcids: string[] = Object.keys(this.props.tenantSettings.translatedContent);
        let languages: ILanguage[] = [];

        languages.push({
            availableForTranslation: false,
            language: this.props.lcidMappings[defaultLcid]["languageShort"] || this.props.lcidMappings[defaultLcid].language.split(" ")[0],
            lcid: defaultLcid,
            translateAs: defaultLcid,
            visible: true,
        });

        lcids.map((lcid) => {
            if (lcid !== defaultLcid) {
                const currentMapping = this.props.lcidMappings[lcid];

                if (!currentMapping) return lcid;

                languages.push({
                    availableForTranslation: currentMapping["availableForTranslation"],
                    language: currentMapping["languageShort"] || currentMapping.language.split(" ")[0],
                    lcid: lcid,
                    translateAs: currentMapping["translateAs"],
                    visible: !!this.props.translatedContent && !!this.props.translatedContent[lcid],
                });
            }
            return lcid;
        });
        return languages;
    };

    private getDraftTranslation = (inputLanguage: string) => {
        const outputLanguage: ILanguage = this.state.displayedLanguages.find((language) => language.lcid === this.state.activeLcid)!;

        this.props
            .getDraftTranslation(
                this.props.id || "00000000-0000-7368-6172-65706f696e74",
                this.props.translatedContent![inputLanguage],
                outputLanguage.translateAs
            )
            .then((content) => {
                this.props.onChange({
                    ...this.props.translatedContent,
                    [this.state.activeLcid]: {
                        ...content,
                        body: `<p><em>[${this.props.lcidMappings[this.state.activeLcid].autoTranslateText}]</em></p>${content.body}`,
                    },
                });
            });
    };

    private onAddLanguage = (language: ILanguage) => {
        const availableLanguages: ILanguage[] = this.state.availableLanguages.map((availableLanguage) => {
            if (availableLanguage.lcid === language.lcid) return { ...availableLanguage, visible: true };
            return availableLanguage;
        });
        const displayedLanguages: ILanguage[] = this.state.displayedLanguages.concat([language]);

        this.props.onChange({ ...this.props.translatedContent, [language.lcid]: { body: "", description: "", title: "" } });

        this.setState({ ...this.state, activeLcid: language.lcid, availableLanguages, displayedLanguages, showAddLanguagesList: false });
    };

    private onChangeBody = (body: string) => {
        this.props.onChange({
            ...this.props.translatedContent,
            [this.state.activeLcid]: {
                ...this.props.translatedContent![this.state.activeLcid],
                body,
            },
        });
    };

    private onChangeSummary = (summary: string) => {
        this.props.onChange({
            ...this.props.translatedContent,
            [this.state.activeLcid]: {
                ...this.props.translatedContent![this.state.activeLcid],
                description: summary,
            },
        });
    };

    private onChangeTitle = (title: string) => {
        this.props.onChange({
            ...this.props.translatedContent,
            [this.state.activeLcid]: {
                ...this.props.translatedContent![this.state.activeLcid],
                title: title,
            },
        });
    };

    private onHideAddLanguagesList = () => {
        this.setState({ ...this.state, showAddLanguagesList: false });
    };

    private onHideContentAnalysis = () => {
        this.setState({ ...this.state, showContentAnalysis: false });
    };

    private onRemoveLanguage = (language: ILanguage) => {
        const languageToRemove: ILanguage = language;

        const availableLanguages: ILanguage[] = this.state.availableLanguages.map((availableLanguage) => {
            if (availableLanguage.lcid === languageToRemove.lcid) return { ...availableLanguage, visible: false };
            return availableLanguage;
        });
        const displayedLanguages: ILanguage[] = this.state.displayedLanguages.filter(
            (displayedLanguage) => displayedLanguage.lcid !== languageToRemove.lcid
        );
        const activeLcid: string =
            this.state.activeLcid !== languageToRemove.lcid
                ? this.state.activeLcid
                : displayedLanguages[displayedLanguages.length - 1].lcid;

        this.setState({ ...this.state, activeLcid, availableLanguages, displayedLanguages });

        let translatedContent: ITranslatedContent = this.props.translatedContent!;
        delete translatedContent[languageToRemove.lcid];

        this.props.onChange({ ...translatedContent });
    };

    private onRemoveSummary = () => {
        this.props.onRemoveSummary();
        this.onChangeSummary("");
    };

    private onSelectTab = (language: ILanguage) => {
        this.setState({ ...this.state, activeLcid: language.lcid });
    };

    private onShowContentAnalysis = () => {
        this.setState({ ...this.state, showContentAnalysis: true });
    };

    private onShowRemoveContentConfirmation = async (ev, language: ILanguage) => {
        ev.stopPropagation();

        if (
            !!this.props.translatedContent![language.lcid].body ||
            !!this.props.translatedContent![language.lcid].description ||
            !!this.props.translatedContent![language.lcid].title
        ) {
            if (
                await confirm.show({
                    text: "Are you sure you want to remove this content? You may lose some of your work.",
                    title: `Remove ${language.language}`,
                })
            )
                this.onRemoveLanguage(language);
        } else {
            this.onRemoveLanguage(language);
        }
    };

    private onShowReplaceContentConfirmation = async (inputLanguage: string) => {
        if (!!this.props.translatedContent![this.state.activeLcid].body || !!this.props.translatedContent![this.state.activeLcid].title) {
            if (await confirm.show({ text: "Are you sure you want to replace the existing content?", title: "Replace content" }))
                this.getDraftTranslation(inputLanguage);
        } else {
            this.getDraftTranslation(inputLanguage);
        }
    };

    private onToggleAddLanguagesList = () => {
        this.setState({ ...this.state, showAddLanguagesList: !this.state.showAddLanguagesList });
    };

    /**
     * Handles setting the language availabilitys in the state.
     */
    private setLanguageAvailability = () => {
        let currentAvailableTranslations = { ...this.props.translatedContent };
        let languagesWithText: ILanguage[] = [];
        let allAddedLanguages: ILanguage[] = [];

        Object.keys(currentAvailableTranslations).forEach((nextLanguage) => {
            if (this.state.availableLanguages && this.props.translatedContent && (this.props.translatedContent[nextLanguage].body && this.props.translatedContent[nextLanguage].title)) {
                let matchingLang = this.state.availableLanguages.find((l) => l.lcid === nextLanguage);

                if (matchingLang) {
                    languagesWithText.push(matchingLang);
                }
            }
            if (this.state.availableLanguages && this.props.translatedContent) {
                let matchingLang = this.state.availableLanguages.find((l) => l.lcid === nextLanguage);

                if (matchingLang) {
                    allAddedLanguages.push(matchingLang);
                }
            }
        });

        if (languagesWithText.length >= 1 && allAddedLanguages.length > 1) {
            this.setState({
                ...this.state,
                checkIfAnyTranslabableContent: false,
                translatableLanguagesWithText: languagesWithText,
            });
        }
        else {
            this.setState({
                ...this.state,
                checkIfAnyTranslabableContent: true,
                translatableLanguagesWithText: languagesWithText,
            });
        }
    };

    /**
     * Handle showing the translation dialog
     */
    private onShowTranslationDialog = () => {
        this.setState({ ...this.state, showTranslationDialog: true });
    };

    /**
     * Handle closing the translation dialog
     */
    private onCloseTranslationDialog = () => {
        this.setState({ ...this.state, showTranslationDialog: false });
    };

    /**
     * Handle dialog selection for the translation dialog
     */
    private onConfirmDialog = (inputLanguage: string) => {
        this.setState({ ...this.state, showTranslationDialog: false });
        this.onShowReplaceContentConfirmation(inputLanguage);
    };
}

interface ComponentProps {
    showSummary: boolean;
    id: string | undefined;
    translatedContent: ITranslatedContent | undefined;
    customCss?: ICustomCssModelv1;
    type: "post" | "event";
    getContentAnalysis: (id: string, text: string) => Promise<IContentAnalysis>;
    getDraftTranslation: (id: string, content: TranslatableContent, outputLCID: string) => Promise<TranslatableContent>;
    onChange: (translatedContent: ITranslatedContent) => void;
    onRemoveSummary: () => void;
}

interface ComponentState {
    activeLcid: string;
    availableLanguages: ILanguage[];
    displayedLanguages: ILanguage[];
    showAddLanguagesList: boolean;
    showContentAnalysis: boolean;
    showTranslationDialog: boolean;
    translatableLanguagesWithText: ILanguage[];
    checkIfAnyTranslabableContent: boolean;
}

const connector = connect((state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    lcidMappings: state.resources.lcidMappings,
    tenantSettings: state.settings.tenantSettings,
}));
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(TranslatedContent);
