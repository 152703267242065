import * as React from "react";
import { connect, ConnectedProps } from "react-redux";

import { GlobalApplicationState } from "globalApplicationState";
import { EventFeedItem } from "../../../../../models";
import Event from "./Event";

import "../../../styles/visualCard.sass";
import "../../../../../styles/attendance.sass";

const VisualCard: React.FunctionComponent<PropsWithRedux> = props => {
  return (
    <div className="card">
      {props.eventsFeed.map(event => <Event key={event.id} {...props} event={event} />)}
    </div>
  );
}

interface ComponentProps {
  onEventSelected: (event: EventFeedItem) => void;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    eventsFeed: state.events.eventsFeed.eventsFeed
  })
);
type PropsWithRedux = ConnectedProps<typeof connector>;
export default connector(VisualCard);
