import React, { useState } from "react";
import Cookies, { ROWS_PER_PAGE_COOKIE_NAMES } from "utils/cookie";
import numeral from "numeral";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Popover from "@mui/material/Popover";
import TablePagination from "@mui/material/TablePagination";

import { Theme, useTheme } from "@mui/material/styles";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";

const DEFAULT_ROW_NAME = "rows"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paging: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      position: "sticky",
      backgroundColor: "#ffffff",
      bottom: 0,
      padding: "5px 20px 0 20px"
    },
    paginationActions: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
    caption: {
      fontWeight: 500,
      paddingTop: 12
    },
    callout: {
      backgroundColor: "#ffffff",
      boxShadow: "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
      right: 24,
      borderRadius: 5,
      boxSizing: "border-box",
      padding: "5px 0"
    },
    calloutArrow: {
      position: "relative",
      height: 15,
      left: "calc(50% - 20px)",
      width: 30,
      overflow: "hidden",
      "&::after": {
        content: `""`,
        position: "absolute",
        width: 15,
        height: 15,
        backgroundColor: "#ffffff",
        transform: "rotate(45deg)",
        bottom: 8,
        right: 8,
        boxShadow: "0 0 4px #aaaaaa"
      }
    }
  })
);


const getRowsPerPage = (rowsCookieName: string = ROWS_PER_PAGE_COOKIE_NAMES.DEFAULT, defaultRowsPerPage: number = 10): number => {
  const rowsPerPage = Cookies.get(rowsCookieName);
  if (!!rowsPerPage)
    return +rowsPerPage;
  return defaultRowsPerPage;
}

const selectRowsPerPage = (rowsPerPage: number, rowsCookieName: string) => {
  Cookies.set(rowsCookieName, rowsPerPage.toString());
}

const TablePaginationActions = props => {
  const classes = useStyles();
  const theme = useTheme();

  const { count, page, rowsPerPage, onPageChange } = props;
  
  return (
    <div className={classes.paginationActions}>
      <IconButton
        color="primary"
        onClick={(ev) => onPageChange(ev, 0)}
        disabled={page === 0}
        aria-label="first page"
        size="large">
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        color="primary"
        onClick={(ev) => onPageChange(ev, page - 1)}
        disabled={page === 0}
        aria-label="previous page"
        size="large">
        {theme.direction === "rtl" ? <KeyboardArrowRightIcon /> : <KeyboardArrowLeftIcon />}
      </IconButton>
      <IconButton
        color="primary"
        onClick={(ev) => onPageChange(ev, page + 1)}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
        size="large">
        {theme.direction === "rtl" ? <KeyboardArrowLeftIcon /> : <KeyboardArrowRightIcon />}
      </IconButton>
      <IconButton
        color="primary"
        onClick={(ev) => onPageChange(ev, Math.max(0, Math.ceil(count / rowsPerPage) - 1))}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
        size="large">
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

const Paging: React.FunctionComponent<ComponentProps> = props => {
  const [rowsPerPage, setRowsPerPage] = useState(getRowsPerPage(props.rowsCookieName, props.defaultRowsPerPage));
  const [anchorEl, setAnchorEl] = useState(null);
  const rowName = props.rowName ?? DEFAULT_ROW_NAME;
  const classes = useStyles();

  if (!props.alwaysShow && (!props.items.length || props.currentPage < 1 || props.totalItems < 11))
    return <React.Fragment></React.Fragment>;

  const onChangeRowsPerPage = (rows: number) => {
    setRowsPerPage(rows);
    selectRowsPerPage(rows, props.rowsCookieName ?? ROWS_PER_PAGE_COOKIE_NAMES.DEFAULT);
    props.onChangePage(0, rows);
    
    if(props.resetSelection)
      props.resetSelection();
  }
  
  return (
    <div className={classes.paging}>
      <Button
        variant="text"
        color="primary"
        endIcon={<ExpandMoreIcon />}
        onClick={(ev: any) => setAnchorEl(ev.currentTarget)}
      >
        Show {rowsPerPage} {rowName}
      </Button>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            overflow: "visible"
          }
        }}
        onClick={() => setAnchorEl(null)}
        onClose={() => setAnchorEl(null)}
      >
        <div className={classes.callout}>
          <div>
            <List disablePadding>
              {rowsPerPage !== 10 &&
                <ListItem dense button onClick={() => onChangeRowsPerPage(10)}>
                <ListItemText primary={`Show 10 ${rowName}`} />
                </ListItem>
              }
              {rowsPerPage !== 25 &&
                <ListItem dense button onClick={() => onChangeRowsPerPage(25)}>
                <ListItemText primary={`Show 25 ${rowName}`} />
                </ListItem>
              }
              {rowsPerPage !== 50 &&
                <ListItem dense button onClick={() => onChangeRowsPerPage(50)}>
                <ListItemText primary={`Show 50 ${rowName}`} />
                </ListItem>
              }
              {rowsPerPage !== 100 &&
                <ListItem dense button onClick={() => onChangeRowsPerPage(100)}>
                <ListItemText primary={`Show 100 ${rowName}`} />
                </ListItem>
              }
            </List>
          </div>
        </div>
        <div className={classes.calloutArrow}></div>
      </Popover>
      <TablePagination
        rowsPerPageOptions={[rowsPerPage]}
        component="div"
        ActionsComponent={(props) => TablePaginationActions(props)}
        backIconButtonProps={{ color: "primary" }}
        nextIconButtonProps={{ color: "primary" }}
        count={props.totalItems}
        labelDisplayedRows={({ from, to, count }) => `${numeral(from).format("0,0")} - ${numeral(to).format("0,0")} of ${numeral(count).format("0,0")}`}
        rowsPerPage={rowsPerPage}
        page={props.currentPage - 1}
        onPageChange={(ev, page) => props.onChangePage(page, rowsPerPage)}
        classes={{
          selectLabel: classes.caption,
          displayedRows: classes.caption
        }}
      />
    </div>
  );
};

interface ComponentProps {
  currentPage: number;
  items: any[];
  totalItems: number;
  totalPages: number;
  onChangePage: (page: number, rowsPerPage: number) => void;
  resetSelection?: () => void;
  rowsCookieName?: string;
  defaultRowsPerPage?: number;
  rowName?: string;
  alwaysShow?: boolean;
}

export default Paging;