import * as React from 'react';
import { AppBar, Box, Button, Container, IconButton, Toolbar, Typography } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import { MoveFilesWithSrc } from '../models';
import { GlobalApplicationState } from 'globalApplicationState';
import { ConnectedProps, connect } from 'react-redux';

interface IMoveFilesAppBar {
    onClose: () => void;
    onMove: (moveFiles: MoveFilesWithSrc) => void;
    moveFiles: MoveFilesWithSrc;
}

const MoveFilesAppBar: React.FunctionComponent<PropsWithRedux> = (props) => {
    const show = props.moveFiles.filesToMove.length > 0;

    const onMoveFilesForAppBarClick = () => {
        const filesToMove = {...props.moveFiles, moveDest: props.imageLibrary.currDir ?? null};

        props.onClose();
        
        if (props.moveFiles.moveSrc?.id !== props.imageLibrary.currDir?.id)
            props.onMove(filesToMove);
    }

    return <Container sx={{ position: 'absolute', top: '35px', width: '100%', zIndex: 999, display: show ? 'block' : 'none' }} disableGutters maxWidth={false}>
        <AppBar
            position="static" 
            sx={{
                color: 'black',
                backgroundColor: 'white',
                border: '1px lightgrey solid',
                borderRadius: '4px',
                width: '99%',
                marginLeft: 'auto',
                marginRight: 'auto',
            }}
        >
            <Toolbar
                sx={{ 
                    justifyContent: 'space-between', 
                    minHeight: '42px', 
                    height: '42px',
                    marginLeft: '10px',
                    marginRight: '10px'
                }} 
                variant='dense'
                disableGutters
            >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton
                        onClick={props.onClose} 
                        size="medium"
                        edge="start"
                        color="inherit"
                        sx={{ mr: 2 }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography>
                        Move {props.moveFiles.filesToMove.length} item{props.moveFiles.filesToMove.length > 1 ? 's' : ''}
                    </Typography>
                </Box>
                <Button variant="contained" color="primary" onClick={onMoveFilesForAppBarClick}>
                    Move here
                </Button>
            </Toolbar>
        </AppBar>
    </Container>;
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: IMoveFilesAppBar)=>({
        ...ownProps,
        imageLibrary: state.posts.imageLibrary,
    }), 
    {}
)
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(MoveFilesAppBar);
