import React from "react";

import { IContentBand, IContentBandConfig } from "../../models";
import { ContentBandLayoutPreview } from "./contentBandLayoutPreview";

import "../../styles/contentBandsTeamsPreview.sass";

interface IContentBandsTeamsPreview {
    contentBands: IContentBand[];
    activeLcid: string;
    isLoading?: boolean;
    config: IContentBandConfig;
}

export const ContentBandsTeamsPreview: React.FC<IContentBandsTeamsPreview> = ({ contentBands, isLoading, activeLcid, config }) => {
    return (
        <div className="cb-teams-preview">
            {!isLoading &&
                contentBands.map((cb: IContentBand, idx: number) => {
                    const header = (cb.headers && cb.headers[activeLcid].header) || "";
                    return (
                        <React.Fragment key={`cb-teams-preview-${idx}`}>
                            <span className="cb-header" style={{ color: config.contentBandsHeaderTextColor.hexCode }}>
                                {header}
                            </span>
                            <ContentBandLayoutPreview size="lg" idx={idx} contentBand={cb} activeLcid={activeLcid} isLoading={isLoading} />
                        </React.Fragment>
                    );
                })}
        </div>
    );
};
