import * as React from "react";

import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";


const MuiCheckbox: React.FunctionComponent<{ input, meta, field }> = props => {
  return (
    <div className="mui-checkbox">
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            size="small"
            checked={!!props.input.value}
            onChange={(ev, checked) => props.input.onChange(checked)}
          />
        }
        label={props.field.label}
      />
    </div>
  );
}

export default MuiCheckbox;