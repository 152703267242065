import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import * as actions from "../../actionCreator";
import { GlobalApplicationState } from "globalApplicationState";
import { push } from "react-router-redux";
import { AttendanceFilterValues, AttendanceListingPage, Attendee } from "../../models";

import ErrorSnackbar from "modules/common/components/snackbars/errorSnackbar";
import LoadingOverlay from "modules/common/components/loadingOverlay";

import BasePage from "pages/common/basePage";
import Breadcrumb from "pages/common/breadcrumb";
import MainContent from "pages/common/mainContent";
import Tabs from "pages/common/tabs";

import AttendanceList from "./attendanceList";

import IconButton from "@mui/material/IconButton";

import GetAppIcon from "@mui/icons-material/GetApp";
import { FileDownloader } from "utils/fileDownloader";


const allTab: number = 0;
const attendingTab: number = 1;
const notAttendingTab: number = 2;
const undecidedTab: number = 3;
const waitlistedTab: number = 4;


class AttendanceListing extends React.Component<PropsWithRedux, ComponentState> {
  constructor(props: PropsWithRedux) {
    super(props);

    this.state = {
      all: { ...this.getDefaultPageValues(), id: "all" },
      attending: { ...this.getDefaultPageValues(), id: "attending" },
      notAttending: { ...this.getDefaultPageValues(), id: "notAttending" },
      undecided: { ...this.getDefaultPageValues(), id: "undecided" },
      waitlist: { ...this.getDefaultPageValues(), id: "waitlist" },

      selectedItems: [],
      selectedTab: 0,
      showFilters: false
    };
  }

  public componentWillMount() {
    const eventId = this.props.match.params.eventId;
    if (!!eventId) {
      this.fetchLists();
    }
  }

  public componentDidUpdate(prevProps: PropsWithRedux) {
    if ((!!this.props.match.params.eventId && (this.props.match.params.eventId !== prevProps.match.params.eventId)) ||
        (this.props.shouldFetch && (this.props.shouldFetch !== prevProps.shouldFetch))) {
      this.fetchLists();
    }
  }

  public render() {
    const { selectedTab } = this.state;

    return (
      <BasePage fullWidth>
        <Breadcrumb
          items={[
            { title: "Events", link: "~/admin/events" },
            { title: this.getEventTitle() },
            { title: "Manage participants" }
          ]}
          backItem={{ title: "Back to Manage Events", link: "~/admin/events" }}
        />
        <MainContent>
          <div className="authoring-page">
            <div>
              <Tabs
                tabs={[
                  { label: this.getTabLabel("All", this.state.all) },
                  { label: this.getTabLabel("Attending", this.state.attending) },
                  { label: this.getTabLabel("Not Attending", this.state.notAttending) },
                  { label: this.getTabLabel("Undecided", this.state.undecided) },
                  { label: this.getTabLabel("Waitlist", this.state.waitlist) }
                ]}
                tabOptions={
                  <IconButton onClick={this.downloadAttendanceListing} size="large"><GetAppIcon /></IconButton>
                }
                selectedTab={selectedTab}
                onSelectTab={this.onSelectTab}
              />
              <AttendanceList show={selectedTab === allTab} eventId={this.props.match.params.eventId} page={this.state.all} fetchPage={this.fetchAll} />
              <AttendanceList show={selectedTab === attendingTab} eventId={this.props.match.params.eventId} page={this.state.attending} fetchPage={this.fetchAttending} />
              <AttendanceList show={selectedTab === notAttendingTab} eventId={this.props.match.params.eventId} page={this.state.notAttending} fetchPage={this.fetchNotAttending} />
              <AttendanceList show={selectedTab === undecidedTab} eventId={this.props.match.params.eventId} page={this.state.undecided} fetchPage={this.fetchUndecided} />
              <AttendanceList show={selectedTab === waitlistedTab} eventId={this.props.match.params.eventId} page={this.state.waitlist} fetchPage={this.fetchWaitlist} />              
            </div>
            <ErrorSnackbar errorMessage={this.props.errorMessage} clearErrorMessage={this.props.clearErrorMessage} />
            <LoadingOverlay absolute={true} show={this.props.isFetching} />
          </div>
        </MainContent>
      </BasePage>
    );
  }


  private downloadAttendanceListing = () => {
    const file = {
      name: `participants-${new Date().toISOString()}.csv`
    };
    this.props.getAttendanceCSV(this.props.match.params.eventId)
      .then((data) => {
        if (!!data) new FileDownloader(file).downloadBlob(data)
      });
  }


  private fetchLists = () => {
    this.fetchAll(1, {});
    this.fetchAttending(1, {});
    this.fetchNotAttending(1, {});
    this.fetchUndecided(1, {});
    this.fetchWaitlist(1, {});
  }


  private fetchAll = (pageNumber: number, filters: Partial<AttendanceFilterValues>) => {
    this.setState({ all: { ...this.getDefaultPageValues(), id: "all" } });
    this.props.getAttendance(this.props.match.params.eventId, [], pageNumber, filters).then((response) => {
      if (response)
        this.setState({ all: { ...response, isFetching: false } });
      else
        this.setState({ all: { ...this.state.all, hasError: true, isFetching: false } });
    });
  }

  private fetchAttending = (pageNumber: number, filters: Partial<AttendanceFilterValues>) => {
    this.setState({ attending: { ...this.getDefaultPageValues(), id: "attending" } });
    this.props.getAttendance(this.props.match.params.eventId, ["InPerson", "Online"], pageNumber, filters).then((response) => {
      if (response)
        this.setState({ attending: { ...response, isFetching: false } });
      else
        this.setState({ attending: { ...this.state.attending, hasError: true, isFetching: false } });
    });
  }

  private fetchNotAttending = (pageNumber: number, filters: Partial<AttendanceFilterValues>) => {
    this.setState({ notAttending: { ...this.getDefaultPageValues(), id: "notAttending" } });
    this.props.getAttendance(this.props.match.params.eventId, ["NotAttending"], pageNumber, filters).then((response) => {
      if (response)
        this.setState({ notAttending: { ...response, isFetching: false } });
      else
        this.setState({ notAttending: { ...this.state.notAttending, hasError: true, isFetching: false } });
    });
  }

  private fetchUndecided = (pageNumber: number, filters: Partial<AttendanceFilterValues>) => {
    this.setState({ undecided: { ...this.getDefaultPageValues(), id: "undecided" } });
    this.props.getAttendance(this.props.match.params.eventId, ["Undecided"], pageNumber, filters).then((response) => {
      if (response)
        this.setState({ undecided: { ...response, isFetching: false } });
      else
        this.setState({ undecided: { ...this.state.undecided, hasError: true, isFetching: false } });
    });
  }

  private fetchWaitlist = (pageNumber: number, filters: Partial<AttendanceFilterValues>) => {
    this.setState({ waitlist: { ...this.getDefaultPageValues(), id: "waitlist" } });
    this.props.getAttendance(this.props.match.params.eventId, ["Waitlist"], pageNumber, filters).then((response) => {
      if (response)
        this.setState({ waitlist: { ...response, isFetching: false } });
      else
        this.setState({ waitlist: { ...this.state.waitlist, hasError: true, isFetching: false } });
    });
  }


  private getDefaultPageValues = (): AttendanceListingPage => {
    return {
      id: "",
      attendees: [],
      currentPage: 0,
      eventEndTime: "",
      eventStartTime: "",
      eventType: "standard",
      questions: {},
      title: "",
      totalAttendees: 0,
      totalPages: 0,
      hasError: false,
      isFetching: true
    };
  }


  private getEventTitle = (): string => {
    return this.state.all.title || this.state.attending.title || this.state.notAttending.title || this.state.undecided.title || this.state.waitlist.title || "";
  }

  private getTabLabel = (label: string, page: AttendanceListingPage): string => {
    if (!!page.currentPage)
      return `${label} (${page.totalAttendees})`;
    return label;
  }


  private onSelectTab = (tabIndex: number) => {
    this.setState({ ...this.state, selectedTab: tabIndex });
  }
}
  
interface RouteParams {
  tenant: string;
  eventId: string;
}

interface ComponentProps {
}

interface ComponentState {
  all: AttendanceListingPage;
  attending: AttendanceListingPage;
  notAttending: AttendanceListingPage;
  undecided: AttendanceListingPage;
  waitlist: AttendanceListingPage;

  selectedItems: Attendee[];
  selectedTab: number;
  showFilters: boolean;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps & RouteComponentProps<RouteParams>) => ({
    ...ownProps,
    errorMessage: state.events.errorMessage,
    isFetching: state.events.isFetching,
    shouldFetch: state.events.shouldFetch,
    tenant: state.tenant.id
  }),
  {
    clearErrorMessage: actions.clearErrorMessage,
    getAttendance: actions.getAttendance,
    getAttendanceCSV: actions.getAttendanceCSV,
    redirectTo: push
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(AttendanceListing);