import * as actions from "network/actions";
import { ApiRequest } from "./network";
import { SparrowApi } from "./network/adapters/SparrowApi";

export class CommentApi {
  constructor(private _sparrowApi: SparrowApi) {
  }

  public DeleteComment = (id: string): ApiRequest => {
    return new ApiRequest(
        this._sparrowApi.TenantRequest()
            .delete('/{tenant}/api/v1/comment', { params: { id }})
      );
  }

  public GetComment = (id: string): ApiRequest => {
      return new ApiRequest(
          this._sparrowApi.TenantRequest()
              .get('/{tenant}/insightsapi/v1/comments/{commentId}', { params: { commentId: id }})
      );
  }
}

export const AddComment = (postId: string, bodyHtml: string, bodySimple: string) => {
  return actions.NetworkRequest({
    headers: { "content-type": "application/json" },
    method: "POST",
    url: `/{tenant}/api/v2/comment`,
    body: JSON.stringify({
      postId,
      bodyHtml, 
      bodySimple
    })
  });
}

export const AddCommentReply = (postId: string, bodyHtml: string, bodySimple: string, parentId: string) => {
  return actions.NetworkRequest({
    headers: { "content-type": "application/json" },
    method: "POST",
    url: `/{tenant}/api/v2/comment/reply`,
    body: JSON.stringify({
      postId,
      bodyHtml, 
      bodySimple,
      parentId
    })
  });
}

export const DeleteComment = (commentId: string) => {
  return actions.NetworkRequest({
    method: "DELETE",
    url: `/{tenant}/api/v1/comment?id=${commentId}`
  });
}

export const DestarComment = (commentId: string) => {
  return actions.NetworkRequest({
    headers: { "content-type": "application/json" },
    method: "POST",
    url: `/{tenant}/api/v1/comment/${commentId}/destar`,
    body: JSON.stringify({})
  });
}

export const EditComment = (commentId: string, bodyHtml: string, bodySimple: string, snapshot: string) => {
  return actions.NetworkRequest({
    headers: { "content-type": "application/json" },
    method: "PUT",
    url: `/{tenant}/api/v2/comment`,
    body: JSON.stringify({
      id: commentId,
      bodyHtml,
      bodySimple,
      snapshot
    })
  });
}

export const FlagComment = (commentId: string) => {
  return actions.NetworkRequest({
    headers: { "content-type": "application/json" },
    method: "PUT",
    url: `/{tenant}/api/v1/flagging?commentToFlagId=${commentId}&platform=2`,
    body: JSON.stringify({})
  });
}

export const StarComment = (commentId: string) => {
  return actions.NetworkRequest({
    headers: { "content-type": "application/json" },
    method: "POST",
    url: `/{tenant}/api/v1/comment/${commentId}/star`,
    body: JSON.stringify({})
  });
}

export const SubscribeToPostComments = (postId: string, subscribe: boolean) => {
  return actions.NetworkRequest({
    headers: { "content-type": "application/json" },
    method: "POST",
    url: `/{tenant}/api/v1/userPostActivity/updatePostNotifications/${postId}`,
    body: JSON.stringify(subscribe)
  });
}

export const FetchMentionsUsers = (filter: string) => {
  return actions.NetworkRequest({
    headers: { "content-type": "application/json" },
    method: "GET",
    url: `/{tenant}/api/v1/comment/searchUsers?term=${filter}`
  });
}

export const FetchAllReplies = (parentId: string) => {
  return actions.NetworkRequest({
    headers: { "content-type": "application/json" },
    method: "GET",
    url: `/{tenant}/api/v2/comment/${parentId}/replies`
  });
}