import { useState, useRef } from "react";

import { AttachedFile } from "modules/gallery";

type UseTinyMceAuthoringLogicReturnType = {
    inlineImageDialogOpen: boolean;
    intenseEmphasisDialogOpen: boolean;
    editorRef: React.MutableRefObject<any>; // can't get typing for this object
    onShowInlineImageDialog: () => void;
    onShowIntenseEmphasisDialog: () => void;
    onApplyInlineImage: (images: AttachedFile[]) => Promise<void>;
    onApplyIntenseEmphasis: (color: string) => void;
    onHideInlineImageDialog: () => void;
    onHideIntenseEmphasisDialog: () => void;
}

/**
 * Encapsulates common logic when using authoringTinyMce
 */
const useTinyMceAuthoringLogic = (getImage: (id: string) => Promise<AttachedFile> ): UseTinyMceAuthoringLogicReturnType => {
    const [inlineImageDialogOpen, setInlineImageDialogOpen] = useState<boolean>(false);
    const [intenseEmphasisDialogOpen, setIntenseEmphasisDialogOpen] = useState<boolean>(false);

    const editorRef = useRef<any>(null);

    const onHideInlineImageDialog = () => setInlineImageDialogOpen(false);
    const onHideIntenseEmphasisDialog = () => setIntenseEmphasisDialogOpen(false);

    const onShowInlineImageDialog = () => setInlineImageDialogOpen(true);
    const onShowIntenseEmphasisDialog = () => setIntenseEmphasisDialogOpen(true);

    const onChangeInlineImage = (image: AttachedFile) => {
        if (editorRef.current)
            editorRef.current.insertContent(`<img src="${image.fileUrl}" data-id="${image.ospreyId}" />`);
    }

    const onApplyInlineImage = async (images: AttachedFile[]) => {
        try {
            const img = await getImage(images[0].ospreyId);
            onChangeInlineImage(img);
        } catch (error) {
            onChangeInlineImage(images[0]);
        } finally {
            onHideInlineImageDialog();
        }
    }

    const onChangeIntenseEmphasis = (color: string) => {
        if (editorRef.current)
            editorRef.current.formatter.apply("intense-emphasis", { color });
    }

    const onApplyIntenseEmphasis = (color: string) => {
        onChangeIntenseEmphasis(color);
        onHideIntenseEmphasisDialog();
    }

    return {
        inlineImageDialogOpen,
        intenseEmphasisDialogOpen,
        editorRef,
        onApplyInlineImage,
        onShowInlineImageDialog,
        onShowIntenseEmphasisDialog,
        onApplyIntenseEmphasis,
        onHideInlineImageDialog,
        onHideIntenseEmphasisDialog,
    };
}

export { useTinyMceAuthoringLogic };
