import React from "react";
import { Dialog, DialogProps, Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { CONTENT_MODAL_WIDTH } from "utils/generalConstants";
import { GlobalApplicationState } from "globalApplicationState";
import { ConnectedProps, connect } from "react-redux";

interface IDialogContentViewProps extends DialogProps {
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialog: {
            minWidth: 200,
        },
    })
);

const DialogContentViewComponent: React.FunctionComponent<PropsWithRedux> = (props) => {
    const classes = useStyles();
    const { mobileAppBannerOpen, dispatch, ...rest } = props;

    return (
        <Dialog
            {...rest}
            sx={{
                marginTop: mobileAppBannerOpen ? 5 : 0
            }}
            PaperProps={{ style: { maxWidth: CONTENT_MODAL_WIDTH } }}
            classes={{ paper: classes.dialog }}
            maxWidth={"md"}
            fullWidth
        >
            {props.children}
        </Dialog>
    );
}

const connector = connect((state: GlobalApplicationState, ownProps: IDialogContentViewProps) => ({
    ...ownProps,
    mobileAppBannerOpen: state.userPortalLayout.mobileAppBannerOpen
}));
type PropsWithRedux = ConnectedProps<typeof connector>;

export const DialogContentView = connector(DialogContentViewComponent);
