import * as React from "react";
import Loading from "modules/common/components/loading";
import "./audiences.sass";
import { syncFunctionsApi } from "api/instances";
import {
    UserSuggestions,
    AudienceSuggestions,
    AdGroupSuggestions,
    UpdateEveryoneAudience,
    GroupGraphSuggestions,
    SyncType
} from "../models";
import { injectIntl, FormattedMessage, IntlShape } from "react-intl";
import UserGroupPreviewModal from "./userGroupPreviewModal";
import { TenantSettings } from "modules/settings";
import { errorAlert } from "utils/notyPopups";

const FETCH_AMOUNT = 10;

class EveryoneSuggestionsManager extends React.Component<ComponentProps, ComponentState> {
    constructor(props) {
        super(props);
        this.state = {
            fetching: false,
            userSuggestions: [],
            groupSuggestions: [],
            cachedGroupSuggestions: {},
            audienceSuggestions: [],
            amountFetched: 0,
            groupAmountFetched: 0,
            userSearchText: "",
            prevUserSearchText: "",
            groupSearchText: "",
            prevGroupSearchText: "",
            activeTab: "groups",
            showGroupPreview: false,
            currentGroup: { name: "", users: [] },
            skipToken: "",
            currentPage: 0,
            lastPage: 0
        };
    }

    public render() {
        let currentAudience: null | UpdateEveryoneAudience = null;
        let syncType = this.props.tenantSettings.syncConfig.syncType;

        if (this.props.currentAudience) {
            currentAudience = {
                ...this.props.currentAudience,
                // includedUsers: this.props.currentAudience?.includedUsers.map(user => { return { Id: user.Id, Details: user.Details }}),
                includedGroups: this.props.currentAudience?.includedGroups.map((group) => {
                    return { id: group.id, displayName: group.displayName, referenceId: group.referenceId, syncStatus: group.syncStatus };
                }),
            };
        }

        let showBackButton = this.state.currentPage > 0;
        let showNextButton = this.state.skipToken !== "" && this.state.skipToken !== null;
        

        return (
            <div className={`details list ${!this.props.currentAudience ? "subscription" : ""}`}>
                <div className="tabs" style={{ borderBottom: "1px solid #ddd" }}>
                    <ul className="tab-btns">
                        <li>
                            <span
                                onClick={() => {
                                    this.setState({ ...this.state, activeTab: "groups" });
                                }}
                                className={"tab-btn" + (this.state.activeTab === "groups" ? " active" : "")}
                                title={`${syncType} Groups`}
                            >
                                <FormattedMessage id="users.syncedGroups" defaultMessage={`${syncType} Groups`} values= {{ syncType: syncType}} />
                            </span>
                        </li>
                    </ul>
                </div>

                <div style={{ marginTop: "15px" }}>
                    {!this.props.currentAudience ? (
                        <label style={{ width: "155px", marginLeft: "25px" }}>Search Groups (starts with)</label>
                    ) : null}
                    <div className={`input-wrapper ${this.props.currentAudience ? "" : " subscription"}`}>
                        {this.props.currentAudience ? <label style={{ width: "155px" }}>Search Groups (starts with)</label> : null}
                        <input
                            className="form-control"
                            autoComplete="off"
                            value={this.state.groupSearchText}
                            onChange={(e) => this.setState({ ...this.setState, groupSearchText: e.target.value })}
                            onKeyDown={(e) =>
                                this.state.groupSearchText !== this.state.prevGroupSearchText &&
                                this.state.groupSearchText !== "" &&
                                e.key === "Enter" &&
                                this.handleSearchAction()
                            }
                        />
                        <button
                            className="search-btn btn-primary"
                            disabled={
                                this.state.fetching ||
                                this.props.working ||
                                this.state.groupSearchText === this.state.prevGroupSearchText ||
                                this.state.groupSearchText === "" ||
                                !this.props.tenantSettings.canManageEveryoneAudience
                            }
                            onClick={() => this.handleSearchAction()}
                        >
                            Search
                        </button>
                    </div>
                    <div className="results">
                        {this.state.fetching ? (
                            <Loading padding={12} />
                        ) : this.state.cachedGroupSuggestions[this.state.currentPage] !== undefined ? (
                            this.state.cachedGroupSuggestions[this.state.currentPage].map((suggestions) => {
                                return (
                                    <div key={suggestions.group.id} className="item">
                                        {suggestions.group.displayName} ({suggestions.group.memberCount} users)
                                        <div style={{ display: "inline", float: "right" }}>
                                            <span
                                                className="add-btn"
                                                onClick={() => this.addToupdatedAudience("includedGroups", "list", suggestions.group)}
                                            >
                                                {this.props.canEdit ? (
                                                    this.props.currentAudience ? (
                                                        currentAudience?.includedGroups.find(group => group.id === suggestions.group.id || 
                                                            group.referenceId === suggestions.group.id ) ? (
                                                            null
                                                        ) : <i className="material-icons">add</i>
                                                    ) : (
                                                        <input
                                                            type="checkbox"
                                                            readOnly
                                                            id={suggestions.group.id}
                                                            checked={
                                                                this.props.currentList?.findIndex(
                                                                    (ul) => ul.id === suggestions.group.id
                                                                ) !== -1
                                                            }
                                                            onClick={() =>
                                                                this.addToCurrentGroup(suggestions.group.id, suggestions.group.displayName)
                                                            }
                                                        />
                                                    )
                                                ) : null}
                                            </span>
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <div style={{ textAlign: "center", color: "#666" }}>No results</div>
                        )}
                    </div>
                </div>

                <div className="page-controls">
                    {showBackButton ? (
                        <button
                            className="page-btn"
                            disabled={this.state.fetching || this.props.working}
                            onClick={() =>
                                this.setState({
                                    ...this.state,
                                    currentPage: this.state.currentPage - 1,
                                })
                            }
                        >
                            <i className="material-icons" style={{ fontSize: "28px" }}>
                                navigate_before
                            </i>
                        </button>
                    ) : null}
                    {showNextButton || this.state.currentPage !== this.state.lastPage ? (
                        <button
                            className="page-btn next"
                            disabled={this.state.fetching || this.props.working}
                            onClick={() => this.handleNextPage()}
                        >
                            <i className="material-icons" style={{ fontSize: "28px" }}>
                                navigate_next
                            </i>
                        </button>
                    ) : null}
                </div>
                <UserGroupPreviewModal show={this.state.showGroupPreview} group={this.state.currentGroup} closeModal={this.closeModal} />
            </div>
        );
    }

    private handleSearchAction() {
        this.clearDictionary();
        this.setState(
            {
                ...this.state,
                currentPage: 0,
                skipToken: "",
                lastPage: 0,
            }, this.fetchGroupSuggestions
        );
        
    }

    private handleNextPage() {
        if (this.state.cachedGroupSuggestions[this.state.currentPage + 1] !== undefined) {
            this.setState({
                ...this.state,
                currentPage: this.state.currentPage + 1,
            });
        } else {
            this.fetchGroupSuggestions(true);
        }
    }

    private addToCurrentGroup(groupId: string, groupType: string) {
        if (this.props.currentList?.some((values) => values.id === groupId)) {
            return;
        }
        this.props.currentList?.push({ id: groupId, type: groupType });
    }

    private addToupdatedAudience(includedGroups: string, list: string, groupToUpdate: AdGroupSuggestions) {
        if (
            this.props.currentAudience?.includedGroups.some(
                (group) => group.id === groupToUpdate.id || group.referenceId === groupToUpdate.id
            )
        ) {
            return;
        }
        this.props.updateAudience(includedGroups, list, groupToUpdate);
    }

    private fetchGroupSuggestions = (next?: boolean) => {
        let currentPage = this.state.currentPage;
        if (next) {
            currentPage++;
        }

        this.setState(
            {
                ...this.state,
                fetching: true,
            },
            () => {
                if (this.state.groupSearchText !== "") {
                    let syncType = this.props.tenantSettings.syncConfig.syncType;
                    if(syncType === SyncType.MicrosoftAD){
                        syncFunctionsApi
                        .getUserGroupSuggestions(
                            this.state.groupSearchText,
                            FETCH_AMOUNT,
                            this.state.skipToken
                        )
                        .then((response) => {
                            if (response.skipToken === null) {
                                this.setState({
                                    ...this.state,
                                    lastPage: currentPage,
                                });
                            }
                            this.setState({
                                ...this.state,
                                cachedGroupSuggestions: {
                                    ...this.state.cachedGroupSuggestions,
                                    [currentPage]: response.groups,
                                },
                                currentPage,
                                groupSuggestions: response.groups,
                                skipToken: response.skipToken,
                                fetching: false,
                                prevGroupSearchText: this.state.groupSearchText,
                                groupAmountFetched: this.state.groupAmountFetched + response.length,
                            });
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                    }
                    else if(syncType === SyncType.Okta){
                        syncFunctionsApi
                        .getOktaUserGroupSuggestions(
                            this.state.groupSearchText,
                            FETCH_AMOUNT,
                            this.state.skipToken
                        )
                        .then((response) => {
                            if (response.skipToken === null) {
                                this.setState({
                                    ...this.state,
                                    lastPage: currentPage,
                                });
                            }
                            this.setState({
                                ...this.state,
                                cachedGroupSuggestions: {
                                    ...this.state.cachedGroupSuggestions,
                                    [currentPage]: response.groups,
                                },
                                currentPage,
                                groupSuggestions: response.groups,
                                skipToken: response.skipToken,
                                fetching: false,
                                prevGroupSearchText: this.state.groupSearchText,
                                groupAmountFetched: this.state.groupAmountFetched + response.length,
                            });
                        })
                        .catch((error) => {
                            errorAlert(`An error has occured while trying to query your Groups.`);
                        });
                    }
                }
            }
        );
    };

    private clearDictionary = () => {
        var i = 0;
        while (this.state.cachedGroupSuggestions[i] !== undefined) {
            delete this.state.cachedGroupSuggestions[i];
            i++;
        }
    };

    private closeModal = () => {
        this.setState({ ...this.state, showGroupPreview: false });
    };
}

interface ComponentProps {
    intl: IntlShape;
    working: boolean;
    updateAudience: (field: string, type: string, value: any) => void;
    currentAudience?: UpdateEveryoneAudience;
    currentList?: { id: string; type: string }[];
    updateList?: (id: string, type: string, add: boolean) => void;
    ADGroupSyncEnabled: boolean;
    canEdit: boolean;
    tenantSettings: TenantSettings;
}

interface ComponentState {
    userSearchText: string;
    prevUserSearchText: string;
    groupSearchText: string;
    prevGroupSearchText: string;
    fetching: boolean;
    userSuggestions: UserSuggestions[];
    groupSuggestions: GroupGraphSuggestions[];
    cachedGroupSuggestions: {
        [pageNumber: number]: GroupGraphSuggestions[];
    };
    audienceSuggestions: AudienceSuggestions[];
    amountFetched: number;
    groupAmountFetched: number;
    activeTab: string;
    showGroupPreview: boolean;
    currentGroup: {
        name: string;
        users: any[];
    };
    skipToken: string;
    currentPage: number;
    lastPage: number;
}

export default injectIntl(EveryoneSuggestionsManager);
