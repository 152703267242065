import * as React from "react";

import { DocumentAvailability } from "../../../models";

import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";


const Availability: React.FunctionComponent<ComponentProps> = props => {
  return (
    <div>
      <div>Availability</div>
      <FormControl size="small" fullWidth>
        <Select
          variant="outlined"
          fullWidth
          value={props.availability}
          onChange={(event) => props.onChange(event.target.value as DocumentAvailability)}
        >
          <MenuItem value="onlineOnly">Online only</MenuItem>
          <MenuItem value="attachmentOnly">Attachment only</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}


interface ComponentProps {
  availability: DocumentAvailability;
  onChange: (availability: DocumentAvailability) => void;
}

export default Availability;