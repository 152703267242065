import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { browserHistory } from 'configureStore';

export const appInsightsReactPlugin = new ReactPlugin();

if(!!process.env.REACT_APP_APPINSIGHTSKEY){
    const appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: process.env.REACT_APP_APPINSIGHTSKEY,
            extensions: [appInsightsReactPlugin],
            extensionConfig: {
              [appInsightsReactPlugin.identifier]: { history: browserHistory }
            }
        }
    });
    appInsights.loadAppInsights();
}