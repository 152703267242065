import React, { useRef, useState, useCallback, useEffect } from "react";
import { EditOutlined, ArrowBack, InfoOutlined } from "@mui/icons-material";
import { Drawer, Button, IconButton } from "@mui/material";
import { AxiosResponse } from "axios";

import { eventsApi, postsApi } from "api/instances";
import { IContentSearchResultItem, ContentSearch } from "modules/common/components/contentView/contentSearch";
import Disclaimer from "modules/common/components/disclaimer";
import Loading from "modules/common/components/loading";
import ErrorSnackbar from "modules/common/components/snackbars/errorSnackbar";
import useIsMounted from "modules/common/hooks/useIsMounted";
import { IEventOverview, EVENT_STATE, EventListItem } from "modules/events";
import { ImageScale, PostOverview, PostListItem } from "modules/posts/models";
import { arraysEqual } from "utils/equalityUtils";
import { PostState } from "utils/managementUtils";
import { IContentBandInputWithContentType } from "./layoutInput";
import { ContentSearchResultList } from "modules/common/components/contentView/contentSearchResultList";
import { ContentBandContentType } from "modules/contentBands/models";
import { dropItemBackward, dropItemForward } from "modules/common/hooks/useDragReorder";

interface IPinnedInputProps extends IContentBandInputWithContentType<string[]> {
    defaultLcid: string;
    contentCount: number;
}

export const PinInput: React.FunctionComponent<IPinnedInputProps> = ({ idx, value, contentType, defaultLcid, contentCount, onChange }) => {
    // we only need to fetch on initial mount because we will fetch while searching for any new pinned content
    const hasFetched = useRef<boolean>(false);
    const PAGE_COUNT = 10;
    const isMounted = useIsMounted();

    const [pageNumber, setPageNumber] = useState<number>(1);
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const isPost = contentType === ContentBandContentType.Post;
    const [items, setItems] = useState<IContentSearchResultItem[]>([]);
    const itemIds = items.map((item: IContentSearchResultItem) => item.id);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [dragId, setDragId] = useState<string | null>(null);
    const draggedIdx = items.findIndex((item) => item.id === dragId);
    const maxPinnedReach = items.length === contentCount;

    const fetchPinned = useCallback(async () => {
        try {
            setIsFetching(true);
            let tasks: Promise<IContentSearchResultItem>[] = [];

            // for each pinned id, begin fetch and add to tasks array
            value.forEach((id: string) => {
                let taskToAdd: Promise<IContentSearchResultItem>;

                if (contentType === ContentBandContentType.Event)
                    taskToAdd = eventsApi.getEventOverview(id, ImageScale.Email).then(({ data: event }: AxiosResponse<IEventOverview>) => {
                        return {
                            id: event.id,
                            imageUrl: event.imageUrl,
                            title: event.translatedContent[defaultLcid].title,
                            authorName: event.author,
                            publishTime: event.datePublished,
                        };
                    });
                else
                    taskToAdd = postsApi.GetPostOverview(id, ImageScale.Email).then((post: PostOverview) => {
                        return {
                            id: post.id,
                            imageUrl: post.imageUrl,
                            title: post.translatedContent[defaultLcid].title,
                            authorName: post.author.name,
                            publishTime: post.datePublished,
                        };
                    });

                tasks = [...tasks, taskToAdd];
            });

            let results: IContentSearchResultItem[] = await Promise.all(tasks);
            if (isMounted()) setItems(results);
        } catch (err) {
            if (isMounted()) setErrorMessage("Something went wrong. Please try again later.");
        } finally {
            hasFetched.current = true;
            if (isMounted()) setIsFetching(false);
        }
    }, [defaultLcid, contentType, value, isMounted]);

    // clear fetched items when content type changes
    useEffect(() => {
        setItems([]);
    }, [contentType]);

    // fetch pinned items on first load only
    useEffect(() => {
        if (!hasFetched.current) fetchPinned();
    }, [fetchPinned]);

    const getCountLabel = (): string => {
        let result = "";
        let plural = value.length > 1;

        if (value.length < 1) result = `No pinned ${isPost ? "posts" : "events"} found`;
        else result = `${value.length} pinned ${isPost ? (plural ? "posts" : "post") : plural ? "events" : "event"}`;

        return result;
    };

    // search with new text
    const onSearch = async (text: string): Promise<IContentSearchResultItem[]> => {
        setPageNumber(1);

        return await performSearch(text);
    };

    const performSearch = async (text: string, page?: number): Promise<IContentSearchResultItem[]> => {
        let results: IContentSearchResultItem[] = [];
        let pageToUse = page || pageNumber;

        try {
            const filters = {
                textToSearch: text,
                includeImageUrl: true,
            };

            // fetch search results for post/events depending on content type
            if (isPost) {
                let {
                    data: { posts },
                } = await postsApi.getPagedPosts(
                    pageToUse,
                    {
                        ...filters,
                        postStates: [PostState.Published],
                        includeExpired: false
                    },
                    PAGE_COUNT
                );

                results = posts
                    .filter((result: PostListItem) => !itemIds.includes(result.id))
                    .map(
                        (pageItem: PostListItem): IContentSearchResultItem => ({
                            ...pageItem,
                            imageUrl: pageItem.imageUrl || "",
                            authorName: pageItem.authorFullName,
                            publishTime: pageItem.publishedTime.date,
                        })
                    );
            } else {
                let {
                    data: { events },
                } = await eventsApi.getPagedEvents(pageToUse, {
                    ...filters,
                    eventStates: [EVENT_STATE.PUBLISHED],
                    includeExpired: false,
                });

                results = events
                    .filter((result: EventListItem) => !itemIds.includes(result.id))
                    .map(
                        (pageItem: EventListItem): IContentSearchResultItem => ({
                            ...pageItem,
                            imageUrl: pageItem.imageUrl || "",
                            authorName: pageItem.authorFullName,
                            publishTime: pageItem.publishedTime.date,
                        })
                    );
            }
        } catch (err) {
            setErrorMessage("Something went wrong. Please try again later.");
        }

        return results;
    };

    const onPin = (item: IContentSearchResultItem) => {
        if (value.includes(item.id)) return;

        setItems([...items, item]);
        onCalloutClose();
    };

    const onUnpin = (item: IContentSearchResultItem) => {
        let newPinned = items.filter((i: IContentSearchResultItem) => i.id !== item.id);

        setItems(newPinned);
    };

    // paged search
    const onPage = async (text: string): Promise<IContentSearchResultItem[]> => {
        // can't guaruntee page number is updated by time funciton runs so here we use an extra variable
        // and then increase state variable
        let results = await performSearch(text, pageNumber + 1);
        setPageNumber(pageNumber + 1);

        return results;
    };

    const onCalloutClose = () => {
        setPageNumber(1);
    };

    const getUnpinAction = (item: IContentSearchResultItem): JSX.Element => (
        <Button onClick={() => onUnpin(item)} variant="text" className="remove-action">
            Unpin
        </Button>
    );

    const onDrag = (id: string) => {
        setDragId(id);
    };

    // re-order items
    const onDrop = (id: string) => {
        const dragIdCopy = dragId;
        setDragId(null);

        if (dragIdCopy) {
            let currDraggedIdx = items.findIndex((r: IContentSearchResultItem) => r.id === dragIdCopy);
            let droppedIdx = items.findIndex((r: IContentSearchResultItem) => r.id === id);

            let dragged = items.find((r: IContentSearchResultItem) => r.id === dragIdCopy);

            if (currDraggedIdx > -1 && droppedIdx > -1 && dragged) {
                // dont do anything if band is in its original spot
                if (droppedIdx === currDraggedIdx || currDraggedIdx === null) return;

                let newItems =
                    currDraggedIdx < droppedIdx
                        ? dropItemForward(items, currDraggedIdx, droppedIdx)
                        : dropItemBackward(items, currDraggedIdx, droppedIdx);

                setItems(newItems);
            }
        }
    };

    const onDrawerClose = () => {
        setIsDrawerOpen(false);

        // send changes
        const internalIds = items.map((item: IContentSearchResultItem) => item.id);

        if (!arraysEqual(value, internalIds)) onChange(internalIds, idx);
    };

    return (
        <>
            <div className="form-group">
                <label>{isPost ? "Pin posts" : "Pin events"}</label>
                <div className="input">
                    <span className="info-text-color">{getCountLabel()}</span>
                    <IconButton onClick={() => setIsDrawerOpen(true)} id={`cb-pin-${idx}`}>
                        <EditOutlined color="primary" />
                    </IconButton>
                </div>
            </div>
            <Drawer
                id="pinned-drawer"
                anchor="right"
                open={isDrawerOpen}
                onClose={onDrawerClose}
                PaperProps={{
                    classes: {
                        root: "container",
                    },
                }}
            >
                <div className="pinned-header">
                    <IconButton onClick={onDrawerClose}>
                        <ArrowBack />
                    </IconButton>
                    <span>{isPost ? "Pin Posts" : "Pin Events"}</span>
                </div>
                <Disclaimer
                    text="Pinned content will remain pinned despite the employee’s topic subscriptions and its read status."
                    icon={<InfoOutlined htmlColor="#e6911a" />}
                    containerStyle={{
                        margin: "8px 0 14px",
                    }}
                />
                <ContentSearch
                    onClose={onCalloutClose}
                    autoSearch
                    onSearch={onSearch}
                    onClick={onPin}
                    placeholder={isPost ? "Search post" : "Search event"}
                    pageCount={PAGE_COUNT}
                    pageNumber={pageNumber}
                    onPage={onPage}
                    disabled={maxPinnedReach}
                />
                <div className="pinned-list">
                    {items.length === 0 ? (
                        <span className="info-text-color">No pinned {isPost ? "posts" : "events"}</span>
                    ) : isFetching ? (
                        <Loading />
                    ) : (
                        <div className="search-result">
                            <ContentSearchResultList
                                results={items}
                                action={getUnpinAction}
                                emptyMessage=""
                                draggable
                                onDrag={onDrag}
                                onDrop={onDrop}
                                draggedIdx={draggedIdx}
                            />
                        </div>
                    )}
                </div>
            </Drawer>
            <ErrorSnackbar errorMessage={errorMessage} clearErrorMessage={() => setErrorMessage("")} />
        </>
    );
};
