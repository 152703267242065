import React, { useState } from "react";

import Callout from "modules/common/components/callout";

import { Audience } from "modules/audiences/models";
import { SimpleTag } from "modules/newsletters/models";

import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import AddIcon from "@mui/icons-material/Add";


const EditAudiences: React.FunctionComponent<ComponentProps> = props => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [audiences, setAudiences] = useState(props.audiences);
  const [calloutOpen, setCalloutOpen] = useState(false);

  return (
    <React.Fragment>
      <Button
        variant="text"
        color="primary"
        startIcon={<AddIcon />}
        onClick={(event: any) => {
          setAudiences(props.audiences);
          setAnchorEl(event.currentTarget);
          setCalloutOpen(true);
        }}
      >
        Edit audiences
      </Button>
      <Callout
        anchorEl={anchorEl}
        open={calloutOpen}
        setOpen={(toSet) => {
          props.onChange(audiences);
          setCalloutOpen(toSet)
        }}
        footer={
          <div>
            <Button
              variant="text"
              color="primary"
              onClick={() => {
                props.onChange([]);
                setCalloutOpen(false);
              }}
            >
              Clear all
            </Button>
          </div>
        }
      >
        <List disablePadding className="audiences">
          {props.availableAudiences.map((audience) =>
            <ListItem key={audience.id} dense button onClick={() => {
              const hasSelectedAudience: boolean = audiences.findIndex((selectedAudience) => selectedAudience.id === audience.id) !== -1;
              if (hasSelectedAudience)
                setAudiences(audiences.filter((selectedAudience) => selectedAudience.id !== audience.id));
              else
                setAudiences(audiences.concat([{ id: audience.id, name: audience.displayName }]));
            }}>
              <ListItemIcon className="callout-checkbox">
                <Checkbox
                  edge="start"
                  tabIndex={-1}
                  disableRipple
                  size="small"
                  color="primary"
                  checked={audiences.findIndex((selectedAudience) => selectedAudience.id === audience.id) !== -1}
                />
              </ListItemIcon>
              <ListItemText primary={`${audience.displayName || ""}${audience.enabled ? "" : " (disabled)"}`} />
            </ListItem>
          )}
        </List>
      </Callout>
    </React.Fragment>
  );
}

interface ComponentProps {
  audiences: SimpleTag[];
  availableAudiences: Audience[];
  onChange: (audiences: SimpleTag[]) => void;
}

export default EditAudiences;