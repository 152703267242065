import React from "react";

import Switch from "@mui/material/Switch";


const Enabled: React.FunctionComponent<ComponentProps> = props => {
  return (
    <div>
      <div>Enabled?</div>
      <Switch
        color="primary"
        checked={props.isEnabled}
        onChange={(_, checked) => props.onChange(checked)}
      />
    </div>
  );
}


interface ComponentProps {
  isEnabled: boolean;
  onChange: (isEnabled: boolean) => void;
}

export default Enabled;