const getTranslatedContentField = (field: string, tc: {[lang: string]: any}, lang: string): string => {
    const langs = Object.keys(tc || {}).sort();
    if(!tc || langs.length === 0) return "";
    return tc[lang] ? tc[lang][field] : tc[langs[0]][field];
}

export const getTranslatedBody = (tc, lang) => getTranslatedContentField("body", tc, lang)
export const getTranslatedTitle = (tc, lang) => getTranslatedContentField("title", tc, lang)
export const getTranslatedComplianceText = (tc, lang) => getTranslatedContentField("complianceText", tc, lang)
export const getTranslatedDescription = (tc, lang) => getTranslatedContentField("description", tc, lang)
export const getTranslatedExcerpt = (tc, lang) => getTranslatedContentField("excerpt", tc, lang)