import React, { useEffect, useState } from "react";

import { tenantManagementApi } from "api/instances";
import NewTenantCreationDialog from "../dialogs/newTenantCreationDialog";
import { TenantCreation } from "modules/tenantManagement/models";
import ErrorSnackbar from "modules/common/components/snackbars/errorSnackbar";
import SuccessSnackbar from "modules/common/components/snackbars/successSnackbar";
import { checkValidTenantName, emailRegex, validGuidRegex } from "utils/regexUserValidation";
import Button from "@mui/material/Button";

const NewTenant: React.FunctionComponent<ComponentProps> = props => {
    const [showDialog, setShowDialog] = useState(false);
    const [disableCreate, setDisableCreate] = useState(true);
    const [isLoading, setIsLoading] = useState(false); 

    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [tenantName, setTenantName] = useState("");
    const [azureTenantId, setAzureTenantId] = useState("");

    //Validation
    const [emailNotValid, setEmailNotValid] = useState(true);
    const [tenantNameNotValid, setTenantNameNotValid] = useState(true);
    const [azureTenantIdNotValid, setAzureTenantIdNotValid] = useState(true);

    useEffect(() => {
        if(!emailNotValid && email && !tenantNameNotValid && tenantName && firstName && lastName){
            setDisableCreate(false);
        } else if((!azureTenantIdNotValid && azureTenantId.length > 0) && !emailNotValid && email && !tenantNameNotValid && tenantName && firstName && lastName) {
            setDisableCreate(false);        
        } else {
            setDisableCreate(true); 
        }
    }, [firstName, lastName, email, tenantName, azureTenantId, azureTenantIdNotValid, emailNotValid, tenantNameNotValid]);

    function onClose () {
        setShowDialog(false);
    };
    

    async function onCreate() {
        setIsLoading(true);

        const tenantDetails: TenantCreation = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            tenantName: tenantName,
            azureTenantId: azureTenantId ?? "",
        };

        await tenantManagementApi.CreateNewTenant(tenantDetails)
        .then(() => {
            setSuccessMessage("New tenant is being created");
        }).catch(() => {
            setErrorMessage("Oops, something went wrong. Please try again");
        })

        setIsLoading(false);
        setShowDialog(false);
    }

    function onOpenDialog () {
        setShowDialog(true);
    }

    function onUpdateTenantName (event: any) {
        setTenantName(event.target.value);
        setTenantNameNotValid(!checkValidTenantName.test(event.target.value));
    }

    function onUpdateFirstName (event: any) {
        setFirstName(event.target.value);
    }

    function onUpdateLastName (event: any) {
        setLastName(event.target.value);
    }

    function onUpdateEmail (event: any) {
        setEmail(event.target.value);
        setEmailNotValid(!emailRegex.test(event.target.value));
    }

    function onUpdateAzureADTenantId (event: any) {
        setAzureTenantId(event.target.value);
        setAzureTenantIdNotValid(!validGuidRegex.test(event.target.value));
    }

    return (
        <div>
            <Button variant="contained" onClick={onOpenDialog} color="primary">
                + TENANT{" "}
            </Button>
            {showDialog && (
                <NewTenantCreationDialog
                    show={showDialog}
                    disabledCreate={disableCreate}
                    isLoading={isLoading}
                    onUpdateFirstName={onUpdateFirstName}
                    onUpdateLastName={onUpdateLastName}
                    onUpdateEmail={onUpdateEmail}
                    onUpdateTenantName={onUpdateTenantName}
                    onUpdateAzureADTenantId={onUpdateAzureADTenantId}
                    onCreate={onCreate}
                    onClose={onClose}
                />
            )}
            <ErrorSnackbar errorMessage={errorMessage} clearErrorMessage={() => setErrorMessage("")} />
            <SuccessSnackbar successMessage={successMessage} clearSuccessMessage={() => setSuccessMessage("")} />
        </div>
    );
}

interface ComponentProps { }

export default NewTenant;
