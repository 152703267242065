import React from "react";

import { PortalPage } from "../../models";

import TinaEditor from "../../tinacms/editor";
import TinaCMSWrapper from "../../tinacms/setup";
import Loading from "modules/common/components/loading";
import { useCustomPortalPageCssContent } from "modules/common/hooks/data/useCustomPortalPagesCssContent";
import { useLoadCustomCssStyles } from "modules/common/hooks/data/useLoadCustomCssStyles";

const PortalPagesEditor: React.FunctionComponent<PortalPagesEditorProps> = props => {
    const { customCss, fetching } = useCustomPortalPageCssContent(props.portalPage.draftId);
    const { isLoadingStyles } = useLoadCustomCssStyles(customCss);

    if (fetching || isLoadingStyles) return <Loading />;
    return <TinaCMSWrapper> <TinaEditor page={props.portalPage} /> </TinaCMSWrapper>;
}

interface PortalPagesEditorProps {
  portalPage: PortalPage;
  onChangePortalPage: (page: Partial<PortalPage>) => void;
}

export default PortalPagesEditor;