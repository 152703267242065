import { ApiRequest } from "./network";
import { SparrowApi } from "./network/adapters/SparrowApi";

export interface ExpiryLink {
    isExists: boolean;
    isUsed: boolean;
    maskedEmail: string;
    isEnabled: boolean;
    isExpired: boolean;
    isPasswordResetLink: boolean;
}

interface IDynamicLinkApi {
    getDynamicLink: (link: string) => Promise<string>;
}

interface IDynamicLinkResponse {
    shortLink: string;
}

export class DynamicLinkApi implements IDynamicLinkApi {
    constructor(private _sparrowApi: SparrowApi) { }

    async getDynamicLink(link: string): Promise<string> {
        let result = await new ApiRequest<IDynamicLinkResponse>(
            this._sparrowApi.TenantRequest().get(`/{tenant}/api/v1/dynamicLink?link=${link}`)
        );

        return result.shortLink;
    }
}
