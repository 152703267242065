import React, { useRef, useState } from "react";

import Popover from "@mui/material/Popover";

import WarningIcon from "@mui/icons-material/Warning";


import "./infoHover.sass";


const WarningHover: React.FC<ComponentProps> = ({ children, labelText }) => {
  const [showPopover, setShowPopover] = useState(false);
  const popoverRef = useRef(null);

  return (
    <div
      onMouseEnter={() => setShowPopover(true)}
      onMouseLeave={() => setShowPopover(false)}
      ref={popoverRef}
    >
      <WarningIcon
        style={{ color: "#e6911a" }}
        className="warning-icon"
      />
      <span style={{paddingLeft: "5px", position: "relative", top: "-6px", fontSize: "14px"}}>{labelText}</span>
      <Popover
        open={showPopover}
        anchorEl={popoverRef.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        PaperProps={{
          onMouseEnter: () => setShowPopover(true),
          onMouseLeave: () => setShowPopover(false),
          style: {
            maxWidth: 250
          }
        }}
        style={{ pointerEvents: "none" }}
        onClose={() => setShowPopover(false)}
        classes={{
          paper: "warning-icon-description"
        }}
      >
        {children}
      </Popover>
    </div>
  )
}

interface ComponentProps {
  labelText: string;
}

export default WarningHover;