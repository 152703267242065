import * as React from "react";
import { TenantSettingsTagGroup } from "modules/settings";
import { FormattedNumber, FormattedMessage } from "react-intl";
import { TagStats, defaultTagStats } from "modules/insights";
import * as settingsActions from 'modules/settings/actionCreator'
import TenantLink from 'modules/common/components/tenantLink';
import { connect, ConnectedProps } from "react-redux"
import { injectIntl, IntlShape } from "react-intl";
import "./tagListItem.sass";
import { GlobalApplicationState } from "globalApplicationState";
import LoadingSpinner from "modules/common/components/loadingSpinner";
import confirm from 'utils/notyPopups';


interface ComponentProps {
  tagGroup: TenantSettingsTagGroup;
  tagStats: TagStats[];
  intl: IntlShape;
  createNewTag: (id: string) => void;
  closeModal: () => void;
  onDropOnGroup: (tagGroupID: string) => void;
  onDragTagStart: (e: React.DragEvent<HTMLDivElement>, tagId: string) => void;
  onDragTagEnd: (e: React.DragEvent<HTMLDivElement>) => void;
}

interface ComponentState {
  editingGroupId: string
}

class TagListItem extends React.Component<PropsWithRedux, ComponentState> {

  constructor(props) {
    super(props);
    this.state = {
      editingGroupId: ""
    }
  }

  public render() {
    let group = this.props.tagGroup;
    let tags = group.tags;
    let working = this.props.saving || this.props.fetching;

    return <tr onDragOver={(e) => e.preventDefault()}
      onDrop={(e) => { this.props.onDropOnGroup(group.id) }}>
      <td colSpan={4}>
        <div className="group">
          {
            group.isNew || group.id === this.state.editingGroupId ?
              <div className="new-tag">
                <input type="text"
                  className="form-control"
                  value={group.name}
                  placeholder="new topic group name"
                  readOnly={working || !this.props.canEdit}
                  onChange={(e) => this.handleTagGroupNameChange(e, group)}
                />
                <button
                  disabled={group.name === "" || working}
                  onClick={this.handleSave}>
                  {
                    this.props.saving ?
                      <LoadingSpinner size={"14px"} />
                      : <FormattedMessage id="common.save" defaultMessage="Save" />
                  }
                </button>
                {
                  this.state.editingGroupId === group.id ?
                    <button
                      onClick={() => this.setState({ ...this.state, editingGroupId: "" })}>
                      <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
                    </button>
                    : null
                }
              </div>
              : <div style={{ display: "flex" }}>
                <p>{group.name}</p>
                {
                  group.disabled ?
                    <span style={{ color: '#595959', fontStyle: 'italic', marginLeft: '10px' }}>
                      (disabled)
                    </span>
                    : <button
                      className="add-tag"
                      disabled={working}
                      onClick={() => this.props.createNewTag(group.id)}>
                      {
                        this.props.saving ?
                          <LoadingSpinner size={"14px"} />
                          : "+ New Topic"
                      }
                    </button>
                }
                <button className="disable-btn"
                  disabled={working || !this.props.canEdit}
                  onClick={() => this.toggleDisableTagGroup(group)}>
                  {
                    group.disabled ?
                      <FormattedMessage id="common.enableGroup" defaultMessage="Enable group" />
                      : <FormattedMessage id="common.disableGroup" defaultMessage="Disable group" />
                  }
                </button>
                {
                  (group.tags.length === 0 || group.tags.every(t => t.deleted)) ?
                    <button className="grp-delete-btn"
                      disabled={working || !this.props.canEdit}
                      onClick={() => this.toggleDeletedTagGroup(group)}>
                      Delete Group
                      <i className="material-icons" style={{ verticalAlign: "top", fontSize: "16px", marginLeft: '4px' }}>delete</i>
                    </button>
                    : null
                }
                <button
                  className="edit-btn"
                  disabled={working}
                  title="Edit topic group name"
                  onClick={() => this.setState({ ...this.state, editingGroupId: group.id })}>
                  <i className="material-icons">edit</i>
                </button>
              </div>
          }

        </div>
        {
          tags.length === 0 ?
            <div className="tag-detail border" style={{ height: '45px' }} />
            : tags.map(t => {
              let currentTagStats = this.props.tagStats.find(tag => tag.id === t.id) || defaultTagStats;

              return (
                <div className="tag-detail border" key={t.id}
                  draggable={true}
                  onDragStart={(e) => { this.props.onDragTagStart(e, t.id) }}
                  onDragEnd={(e) => { this.props.onDragTagEnd(e) }}>

                  <div className="tag-row">
                    <div style={{
                      display: "flex",
                      flexDirection: "row"
                    }}>
                      <span className="drag-handle">....<br />....</span>
                      {
                        <div>
                          <TenantLink
                            className="post-title-link"
                            to={`~/admin/topics/${group.id}/${t.id}`}>
                            {t.name}
                          </TenantLink>
                          {
                            t.disabled ?
                              <span style={{ color: '#595959', fontStyle: 'italic', marginLeft: '10px' }}>
                                (disabled)
                              </span>
                              : null
                          }
                        </div>
                      }

                      <div style={{ display: "flex" }}>
                        {
                          t.mandatory ?
                            <i className="material-icons" style={{ verticalAlign: "sub", fontSize: '16px', marginLeft: "10px" }}>priority_high</i>
                            : null
                        }
                        {
                          t.default ?
                            <i className="material-icons" style={{ verticalAlign: "sub", fontSize: '16px', marginLeft: "10px" }}>done</i>
                            : null
                        }
                        {
                          t.restricted ?
                            <i className="material-icons" style={{ verticalAlign: "sub", fontSize: '16px', marginLeft: "10px" }}>lock</i>
                            : null
                        }
                      </div>

                    </div>

                    <div className="stat">
                      {
                        currentTagStats.id === ""
                          ? <div title={"Not enough data yet."}>-</div>
                          : <div ><FormattedNumber value={currentTagStats.subscriberCount} /></div>
                      }
                      {
                        currentTagStats.id === "" ? <div title={"Not enough data yet."}>-</div> : <div><FormattedNumber value={currentTagStats.postCount} /></div>
                      }
                      {
                        currentTagStats.id === "" ? <div title={"Not enough data yet."}>-</div> : <div><FormattedNumber value={currentTagStats.viewCount} /></div>
                      }
                    </div>

                  </div>
                </div>)
            })
        }
      </td>
    </tr>

  }

  private handleTagGroupNameChange = (e: React.FormEvent<HTMLInputElement>, tagGroup: TenantSettingsTagGroup) => {
    let input = e.target as HTMLInputElement;
    this.props.updateTagGroup(tagGroup.id, input.value, tagGroup.disabled, tagGroup.deleted);
  }

  private toggleDisableTagGroup = async (tagGroup: TenantSettingsTagGroup) => {
    let toggleGroup = true;
    if (!tagGroup.disabled) toggleGroup = await confirm.show({ text: `Are you sure you want to disable the topic group ${tagGroup.name}? All contained topics will also be disabled.`, title: "Disable topic group" });
    if (toggleGroup) {
      this.props.updateTagGroup(tagGroup.id, tagGroup.name, !tagGroup.disabled, tagGroup.deleted);
      this.props.saveTenantSettings();
    }
  }

  private toggleDeletedTagGroup = async (tagGroup: TenantSettingsTagGroup) => {
    if (await confirm.show({ text: `Are you sure you want to delete the topic group ${tagGroup.name}?`, title: "Delete topic group" })) {
      this.props.updateTagGroup(tagGroup.id, tagGroup.name, tagGroup.disabled, !tagGroup.deleted);
      this.props.saveTenantSettings();
    }
  }

  private handleSave = () => {
    this.setState({ ...this.state, editingGroupId: "" });
    this.props.saveTenantSettings();
  }

}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    fetching: state.settings.fetching,
    saving: state.settings.saving,
    tenantSettings: state.settings.tenantSettings,
    canEdit: state.settings.tenantSettings.manageTopicsAudiencesInAdmin
  }),
  {
    updateTagGroup: settingsActions.updateTagGroup,
    saveTenantSettings: settingsActions.saveTenantSettings
  }
)

type PropsWithRedux = ConnectedProps<typeof connector>;

export default injectIntl(connector(TagListItem));