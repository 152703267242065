import * as React from "react";
import { FlaggedComment } from "../models";
import "./flaggedItemsManagement.sass";
import LoadingSpinner from "modules/common/components/loadingSpinner";
import { FormattedMessage } from "react-intl";

interface Props {
    commentItem: FlaggedComment
    ignoreClick: (id: string) => any
    deleteClick: (id: string, isReply: boolean) => any
    moderationEnabled: boolean
    processingIgnore: string | null
    processingDelete: string | null
    tenantId: string
}

export default class FlaggedListItem extends React.Component<Props, {}> {

    private openPost = () => {
        window.open(`/${this.props.tenantId}/posts/${this.props.commentItem.postId}`, '_blank', 'noopener,noreferrer');
    }

    public render() {
        let options = { year: 'numeric', month: 'long', day: 'numeric' }
        let comment = this.props.commentItem;
        let date = comment.flaggingLastIgnoredAt? new Date(comment.flaggingLastIgnoredAt.toString()) : new Date();
        //need to use new date to be able to use toLocaleDateString
        return <tr key={this.props.commentItem.commentId}>
                    <td className="item-info">
                        <div className="item-wrapper">
                            <span>{comment.commentAuthor}: </span>
                            <span style={{fontStyle: "italic"}}>
                                "{comment.commentBody}"
                            </span>
                            {comment.parentText && 
                                <div style={{paddingLeft: "10%", marginTop: "12px", color: "grey"}}>
                                    {`Replied to ${comment.parentAuthor}: "${comment.parentText}"`}
                                </div>
                            }
                        </div>
                        <hr style={{marginLeft: '10px', marginRight: '15px'}} />
                        <div className="stats-wrapper">
                            <label><FormattedMessage id="activity.flaggingLastIgnored" defaultMessage="Flagging Last Ignored On" />:</label> 
                            <span>{ comment.flaggingLastIgnoredAt ? date.toLocaleDateString (undefined, options as any) : '-' }</span>
                            <label style={{marginLeft: "45px"}}><FormattedMessage id="activity.totalFlags" defaultMessage="Total Flags" />:</label> 
                            <span>{ comment.totalFlagActions }</span>
                            <label style={{marginLeft: "45px"}}><FormattedMessage id="activity.flagsLastIgnored" defaultMessage="Flags Since Last Ignored" />:</label> 
                            <span>{ comment.flagActionsSinceLastIgnored }</span>
                        </div>
                    </td>

                    <td className="actions">
                        <div style={{textAlign: 'center', marginTop: '5px', marginBottom: '5px'}}>
                            <button className="btn flagActionBtn"
                                style={{backgroundColor: '#F4B032'}} 
                                onClick={() => this.props.ignoreClick(comment.commentId)}
                                disabled={this.props.processingIgnore === this.props.commentItem.commentId}>
                                {
                                    this.props.processingIgnore === this.props.commentItem.commentId?
                                    <LoadingSpinner size={'14px'} />
                                    : <span>
                                        <FormattedMessage id="flagging.ignoreFlags" defaultMessage="Ignore Flags" />
                                        <i className="material-icons" style={{verticalAlign: "top", fontSize: "16px", marginLeft: '4px'}}>pan_tool</i>
                                    </span>
                                }
                                
                            </button>
                            <button className="btn flagActionBtn"
                                style={{backgroundColor: '#F4B032'}}
                                disabled={this.props.processingDelete === this.props.commentItem.commentId || this.props.processingIgnore === this.props.commentItem.commentId}
                                onClick={() => this.openPost()}>
                                {
                                    <FormattedMessage id="flagging.goToPost" defaultMessage="Go to post" />
                                }
                                <i className="material-icons" style={{verticalAlign: "top", fontSize: "16px", marginLeft: '4px'}}>arrow_forward</i>

                            </button> 
                            <button className="btn flagActionBtn"
                                style={{backgroundColor: this.props.moderationEnabled ? '#d9534f' : '#CCCCCC', color: this.props.moderationEnabled? '' : '#9B9797', cursor: this.props.moderationEnabled? '' : 'default' }}
                                disabled={!this.props.moderationEnabled || this.props.processingDelete === this.props.commentItem.commentId}
                                onClick={() => this.props.deleteClick(comment.commentId, (!!comment.parentText && comment.parentText !== ""))}>
                                {
                                    this.props.processingDelete === this.props.commentItem.commentId?
                                    <LoadingSpinner size={'14px'} />
                                    : <FormattedMessage id="flagging.deleteComment" defaultMessage="Delete Comment" />
                                }
                                <i className="material-icons" style={{verticalAlign: "top", fontSize: "16px", marginLeft: '4px'}}>delete</i>
                            </button>     
                        </div>
                    </td>
            </tr>
    }

}