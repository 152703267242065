import React from 'react';

import Button from "@mui/material/Button";

export interface ToggleFilterOption {
    label: string;
    icon?: JSX.Element;
    iconOnly?: boolean;
}

const ToggleFilter: React.FunctionComponent<ComponentProps> = (props) => {
    const lastIndex = props.filterOptions.length - 1;

    return (
        <>
            {
                props.filterOptions.map((option: ToggleFilterOption, index: number) =>
                    <Button key={index}
                            color="primary"
                            variant={ props.selectedIndex === index ? 'contained' : 'outlined'}
                            size='small'
                            onClick={() => props.setSelectedIndex(index)}
                            style={{
                                boxShadow: 'none',
                                borderRadius: index === 0 ? '4px 0px 0px 4px' : index === lastIndex ? '0px 4px 4px 0px' : '0'
                            }}
                    >
                        {option.icon && option.icon}
                        {!option.iconOnly && option.label}
                    </Button>
                )
            }
        </>
    );
}

interface ComponentProps {
    filterOptions: ToggleFilterOption[];
    selectedIndex: number
    setSelectedIndex: (index: number) => void
}

export default ToggleFilter;
