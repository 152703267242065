import React from "react";

import Button from "@mui/material/Button";
import Tabs from "@mui/material/Tabs";

import { Theme } from "@mui/material/styles";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    customTab: {
      borderRadius: 24,
      flexShrink: 0
    },
    flexContainer: {
      "& > button": {
        margin: "0 8px",
        "&:first-child": {
          marginLeft: 0
        },
        "&.selected": {
          border: "1px solid",
          borderColor: theme.palette.primary.main,
          padding: "5px 15px"
        }
      }
    },
    root: {
      position: "relative",
      marginBottom: 10
    },
    scrollButtons: {
      position: "absolute",
      top: 0,
      bottom: 11.5,
      backgroundColor: theme.palette.background.paper,
      opacity: 1,
      zIndex: 1,
      "&:first-child": {
        left: 0
      },
      "&:last-child": {
        right: 0
      }
    }
  })
);

const RoundedTab = ({selectedTab, value, label, onChangeTab}) => {
  const classes = useStyles();

  const selected: boolean = selectedTab === value;

  return (
    <Button
      variant={selected ? "contained" : "outlined"}
      color="primary"
      disableElevation
      onClick={() => onChangeTab(value)}
      className={`${classes.customTab} ${selected ? "selected" : ""}`}
    >
      {label}
    </Button>
  );
}

const RoundedTabs: React.FunctionComponent<ComponentProps> = props => {
  const classes = useStyles();

  return (
    <Tabs
      value={props.selectedTab}
      variant="scrollable"
      scrollButtons
      TabIndicatorProps={{ style: { display: "none" } }}
      classes={{
        flexContainer: classes.flexContainer,
        root: classes.root,
        scrollButtons: classes.scrollButtons
      }}
      className={props.className}
      allowScrollButtonsMobile>
      {props.tabs.map((tab) => (
        <RoundedTab key={tab.value} value={tab.value} label={tab.label} {...props} />
      ))}
    </Tabs>
  );
}

interface Tab {
  label: string;
  value: string;
}

interface ComponentProps {
  tabs: Tab[];
  selectedTab: string;
  onChangeTab: (tab: string) => void;
  className?: string;
}

export default RoundedTabs;