import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { push } from "react-router-redux";
import * as actions from "../../actionCreator";
import { GlobalApplicationState } from "globalApplicationState";

import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { Theme } from "@mui/material/styles";


import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    globalComponent: {
      backgroundColor: theme.palette.background.default,
      "&:hover": {
        backgroundColor: "#e8e8e8"
      }
    }
  })
);


const GlobalComponent: React.FunctionComponent<{ onClick: () => void }> = props => {
  const classes = useStyles();

  return (
    <Paper onClick={props.onClick} className={classes.globalComponent}>
      {props.children}
    </Paper>
  );
}


class GlobalComponentsDrawer extends React.Component<PropsWithRedux, ComponentState> {
  public render() {
    return (
      <Drawer anchor="right" open={this.props.showGlobalComponents} onClose={this.onClose} classes={{ paper: "portal-pages-list-drawer" }}>
        <Paper square elevation={0} className="portal-pages-list-header">
          <IconButton onClick={this.onClose} size="large">
            <ArrowBackIcon />
          </IconButton>
          <div>Global Components</div>
        </Paper>
        <div className="global-components-list">
          <GlobalComponent onClick={this.onSelectNavigationItems}>
            <img src="/images/navigation.png" alt="navigation icon" />
            <div>Navigation Items</div>
          </GlobalComponent>
        </div>
      </Drawer>
    );
  }

  private onSelectNavigationItems = () => {
    this.onClose();
    this.props.redirectTo("/" + this.props.tenantId + "/admin/portalPages/navigation");
  }

  private onClose = () => {
    this.props.hideGlobalComponents();
  }
}


interface ComponentProps {
}

interface ComponentState {
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    showGlobalComponents: state.portalPages.showGlobalComponents,
    tenantId: state.tenant.id
  }),
  {
    hideGlobalComponents: actions.hideGlobalComponents,
    redirectTo: push
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(GlobalComponentsDrawer);