import { Button, Typography } from '@mui/material';
import TenantLink from 'modules/common/components/tenantLink';
import NoticeWebpage from 'modules/common/noticeWebpage';
import * as React from 'react';

const AccessRestricted : React.FunctionComponent<{}> = () => {
    return (
        <NoticeWebpage
            imageComponent={<img src="/images/403.png" alt="403 banner" style={{maxWidth: "130.6px", maxHeight: "130.6px", width: "100%", height: "100%"}}/>}
            titleComponent={<Typography variant="h1" align="center">Looks like you don't have access</Typography>}
            bodyComponent={
                <React.Fragment>
                    <Typography variant="body1" align="center">Sorry, you don't have permissions to access this report.</Typography>
                    <Typography variant="body1" align="center">Contact your administrator or regenerate the report.</Typography>
                </React.Fragment>
            }
            buttonComponent={
                <TenantLink to="~/admin/dashboard">
                    <Button variant="text" color="primary">Back to Home</Button>
                </TenantLink>
            }
        />
    );
}

export default AccessRestricted;