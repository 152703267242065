import { messagingApi } from 'api/instances';
import DownloadButton from 'modules/common/components/buttons/downloadButton';
import ToolboxButton from 'modules/users/components/toolboxButton';
import UserInsightsBlock from 'modules/users/components/userInsightsBlock';
import React from 'react';

interface ComponentProps { 
    navigateToSettings: () => any;
    userTeamsStats: UserTeamsStats;
}

interface UserTeamsStats {
    totalUsers: number,
    registeredUsers: number
}

const MessagingCenterTeamsTab: React.FunctionComponent<ComponentProps> = ({navigateToSettings, userTeamsStats}) =>  {

    const defaultStyles: React.CSSProperties = {
        height: '50px',
        lineHeight: '0px',
        width: '290px',
        textAlign: 'left',
        boxShadow: '0 0 5px #b8b8b8',
        margin: 5,
        borderRadius: 5,
        border: 'none',
        cursor: 'pointer',
        background: 'white',
        color: 'black'
    }

    return (
        <div className="messaging-center-tab-container">
            <h4>Teams Chatbot User Count</h4>
            <div className="email-tab-container">
                <div className="center-div">

                    <div className="vertically-center-div">
                        <div className="email-insights">
                            
                            <UserInsightsBlock 
                                title={"Total Work Accounts in Directory"}
                                stats={userTeamsStats.totalUsers}
                            />

                            <UserInsightsBlock 
                                title={"Total Users Installed MS Chatbot"}
                                stats={userTeamsStats.registeredUsers}
                            />
                        </div>
                    </div>

                    <div className="email-toolbox-container">
                        <span className="toolbox-title">Teams Chatbot Toolbox</span>
                        <div className="toolbox-button-wrapper">    

                            <DownloadButton
                                onClick={() => messagingApi.GetTeamsUserCsv() }
                                text={" Download User Data"}
                                downloadName={`notification-teams-user-data`}
                                styles={defaultStyles}
                            />

                            <DownloadButton
                                onClick={() => messagingApi.GetOutbox("teams") }
                                text={" Download Teams Chatbot Outbox"}
                                downloadName={`notification-teams-outbox-user-data`}
                                styles={defaultStyles}
                            />

                            <ToolboxButton 
                                title={"Teams Chatbot Configurations"}
                                handleClick={() => navigateToSettings()}    
                            />
                            
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default MessagingCenterTeamsTab;