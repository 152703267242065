import { GlobalApplicationState } from "globalApplicationState";
import * as Actions from './actions';

import API from "api";
import once from 'utils/once';
import XhrUpload from "utils/xhrUpload";
import MsalAuthModule from "authentication/msalAuthModule";

import { AttachedFile, Image as ImageV1 } from "modules/gallery/models";
import { SortStyle } from "utils/managementUtils";

export const getDocument = (id: string) => (dispatch, getState: () => GlobalApplicationState): Promise<AttachedFile> => {
  dispatch(Actions.GetDocument({}));
  return dispatch(API.mediaLibrary.FetchDocumentForId((id)))
    .then(response => response.json())
    .then(file => {
      dispatch(Actions.GetDocumentComplete({ succeeded: true }));
      const image: AttachedFile = {
        blobId: file.id,
        fileExtension: "",
        fileName: file.name,
        fileType: "application",
        fileUrl: file.url,
        ospreyId: file.id
      };
      return image;
    })
    .catch(err => {
      dispatch(Actions.GetDocumentComplete({ succeeded: false }));
      return;
    });
}


export const getDocumentGallery = (pageNumber: number, text?: string, sort?: SortStyle) => (dispatch, getState: () => GlobalApplicationState): Promise<void> => {
  dispatch(Actions.GetDocumentGallery({}));
  return dispatch(API.documents.GetDocumentGallery(pageNumber, text, sort))
    .then(response => response.json())
    .then(documentGalleryPage => dispatch(Actions.GetDocumentGalleryComplete({ ...documentGalleryPage, succeeded: true })))
    .catch(err => dispatch(Actions.GetDocumentGalleryComplete({ currentPage: 1, documents: [], totalDocuments: 0, totalPages: 0, succeeded: false, err })));
}


const getFirstGifFrame = (gif: File): Promise<File> => {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement("canvas");
    const img = new Image();
    img.onload = function () {
      canvas.width = img.width;
      canvas.height = img.height;
      let context = canvas.getContext("2d")!;
      context.drawImage(img, 0, 0, img.width, img.height);
      canvas.toBlob((newBlob: any) => {
        newBlob.lastModifiedDate = new Date();
        newBlob.name = gif.name;
        return resolve(newBlob as File);
      });
    }
    img.src = URL.createObjectURL(gif);
  });
}


export const getImage = (id: string) => (dispatch, getState: () => GlobalApplicationState): Promise<AttachedFile> => {
  dispatch(Actions.GetImage({}));
  return dispatch(API.mediaLibrary.FetchImageForId(id))
    .then(response => response.json())
    .then(file => {
      dispatch(Actions.GetImageComplete({ succeeded: true }));
      const image: AttachedFile = {
        blobId: file.id,
        fileExtension: "",
        fileName: file.name,
        fileType: !!file.videoId ? "video" : "image",
        fileUrl: file.url,
        ospreyId: file.id
      };
      return image;
    })
    .catch(err => {
      dispatch(Actions.GetImageComplete({ succeeded: false }));
      return;
    });
}


export const getImageGallery = (pageNumber: number = 1, includeVideos: boolean = false) => (dispatch, getState: () => GlobalApplicationState): Promise<void> => {
  dispatch(Actions.GetImageGallery({}));
  return dispatch(API.mediaLibrary.GetImageGallery(pageNumber, includeVideos))
    .then(response => response.json())
    .then(imageGalleryPage => dispatch(Actions.GetImageGalleryComplete({ ...imageGalleryPage, succeeded: true })))
    .catch(err => dispatch(Actions.GetImageGalleryComplete({ currentPage: 1, images: [], totalImages: 0, totalPages: 0, succeeded: false, err })));
}

export const getVideo = (id: string) => (dispatch, getState: () => GlobalApplicationState): Promise<string> => {
  dispatch(Actions.GetVideo({}));
  return dispatch(API.mediaLibrary.FetchVideoForId(id))
    .then(response => response.json())
    .then(url => {
      dispatch(Actions.GetVideoComplete({ succeeded: true }));
      return url;
    })
    .catch(err => {
      dispatch(Actions.GetVideoComplete({ succeeded: false }));
      return;
    });
}

export const getVideosComplete = () => (dispatch, getState: () => GlobalApplicationState): void => {
  dispatch(Actions.GetVideosComplete({}));
}



export const uploadDocument = (file: File, onProgress: (percentCompleted: number) => void, onLoad?: (e) => void, onError?: (e) => void, onAbort?: (e) => void) => async (dispatch, getState) => {
  dispatch(Actions.UploadDocument({}));
  return MsalAuthModule.getInstance().getAccessToken().then(accessToken => {
    const requestDetails = API.documents.UploadAttachedFile(file);
    const url = getState().config.SparrowClientApiUrl + requestDetails.url
      .replace("{tenant}", getState().tenant.id);

    const handlers = { onLoad, onAbort, onError, onProgress };

    return new XhrUpload(url, requestDetails, handlers)
      .authenticateWith(accessToken)
      .upload();
  })
    .then((response: string) => {
      let document = JSON.parse(response);
      dispatch(Actions.UploadDocumentComplete({ succeeded: true }));
      return document;
    });
}

/*
 * In the upload image case we cant use the default fetch implementation,
 * we have to resort to xhr; hence we need to call the authmanager ourselves.
 * This however lets us attach an onProgress event listener.
 * This xhr request could have been abstracted in the middleware, but we would lose
 * the onProgress event when we dispatch the network request.
 */
export const uploadImage = (image: File, onProgress: (percentCompleted: number) => void, onLoad?: (e) => void, onError?: (e) => void, onAbort?: (e) => void) => async (dispatch, getState: () => GlobalApplicationState): Promise<string> => {
  if (!(((((((((((((((((((((((((((((((((((((((((/^image\/(jpg|jpeg|png|bmp)/))))))))))))))))))))))))))))))))))))))))).test(image.type)) {
    Actions.UploadImageComplete({ succeeded: false });
    return Promise.resolve("");
  }

  dispatch(Actions.UploadImage({}));

  const fileToUpload = image.type === "image/gif" ? await getFirstGifFrame(image) : image;

  return MsalAuthModule.getInstance().getAccessToken().then(accessToken => {

    const requestDetails = API.mediaLibrary.UploadImage(fileToUpload);
    const url = getState().config.SparrowClientApiUrl + requestDetails.url
      .replace("{tenant}", getState().tenant.id);

    const handlers = { onLoad, onAbort, onError, onProgress };

    return new XhrUpload(url, requestDetails, handlers)
      .authenticateWith(accessToken)
      .upload();

  }).then((response: string) => {
    let image = JSON.parse(response) as { imageId: string, fileName: string };
    dispatch(Actions.UploadImageComplete({ succeeded: true }));
    return image.imageId;
  });
}

export const uploadThumbImage = (blob: Blob, videoId: string, onProgress: (percentCompleted: number) => void, onLoad?: (e) => void, onError?: (e) => void, onAbort?: (e) => void) => (dispatch, getState: () => GlobalApplicationState) => {
  return MsalAuthModule.getInstance().getAccessToken()
    .then((accessToken) => {
      let requestDetails = API.mediaLibrary.VideoUploads.UploadImageThumbnail(blob, videoId);
      let url = getState().config.SparrowClientApiUrl + requestDetails.url
        .replace('{tenant}', getState().tenant.id);

      let handlers = {
        onProgress,
        onLoad,
        onError,
        onAbort
      };
      return new XhrUpload(url, requestDetails, handlers)
        .authenticateWith(accessToken)
        .upload();
    })
    .then((response: string) => {
      let resultingThumb = JSON.parse(response) as { id: string, url: string, thumbnail: ImageV1 }
      return resultingThumb;
    });
}

function getFileChunksFrom(file: File, chunkSize?: number) {
  let fileChunks = [] as Blob[];
  let maxChunkSize = chunkSize || 1048576;

  file.slice = (file as any).mozSlice ? (file as any).mozSlice :
    (file as any).webkitSlice ? (file as any).webkitSlice :
      (file as any).slice ? (file as any).slice : () => { };

  while ((fileChunks.length * maxChunkSize) < file.size) {

    let byteStart = fileChunks.length * maxChunkSize;
    let byteEnd = byteStart + maxChunkSize;
    let chunk = file.slice(byteStart, byteEnd, file.type);

    if (chunk.size > 0)
      fileChunks.push(chunk);

    if (chunk.size < maxChunkSize)
      break;
  }
  return fileChunks;
}

class VideoFramer {

  private _videoSrc;
  private _videoElement: HTMLVideoElement;
  private _canvasElement: HTMLCanvasElement;

  private _videoReadyStatus = 0;
  private _ready: Promise<any>;

  constructor(src: string) {
    this._videoSrc = src;

    this._videoElement = document.createElement('video');
    this._canvasElement = document.createElement('canvas');

    this._ready = new Promise((resolve, reject) => {
      // start loading video
      once(this._videoElement, 'loadedmetadata loadeddata', (e) => {
        this._videoReadyStatus += 1;
        if (this._videoReadyStatus === 2) {
          // video is ready
          resolve(true);
        }
      });
    });

    this._videoElement.crossOrigin = 'anonymous';
    this._videoElement.src = this._videoSrc;
  }

  public ready = (cb) => {
    this._ready.then(() => {
      cb();
    });
  }

  public getImageBlobAtTimeAsync = (time: number, callback: (blob: Blob | null, error: any) => void) => {
    once(this._videoElement, 'seeked', (e) => {
      this._canvasElement.width = this._videoElement.videoWidth;
      this._canvasElement.height = this._videoElement.videoHeight;

      let context = this._canvasElement.getContext('2d');
      !!context && context.drawImage(this._videoElement, 0, 0, this._videoElement.videoWidth, this._videoElement.videoHeight)

      try {
        this._canvasElement.toBlob((blob) => {
          callback(blob, null);
        }, 'image/jpeg');
      } catch (ex) {
        callback(null, ex)
      }
    });
    this._videoElement.currentTime = time;
  }

  public getVideoDuration = () => {
    return this._videoElement.duration;
  }

  public teardown = () => {
  }
}