import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { GlobalApplicationState } from "globalApplicationState";
import { fetchHomeScreenConfigs } from "modules/homeScreens/reducer";
import { HomeScreensStateStatus, IHomeScreenConfig } from "modules/homeScreens/models";

export type UseHomeScreenConfigsType = {
    homeScreenConfigs: IHomeScreenConfig[] | undefined;
    pristineConfigs: IHomeScreenConfig[] | undefined;
    isLoading: boolean;
    successMessage: string | undefined;
};

/**
 * Fetches home screen configs into redux if not there
 */
export const useHomeScreenConfigs = (): UseHomeScreenConfigsType => {
    const { homeScreenConfigs, status, pristineConfigs, successMessage } = useSelector((state: GlobalApplicationState) => state.homeScreenConfigs);
    const dispatch = useDispatch();

    useEffect(() => {
        if (homeScreenConfigs === undefined) dispatch(fetchHomeScreenConfigs());
    }, [homeScreenConfigs, dispatch]);

    return {
        homeScreenConfigs,
        successMessage,
        pristineConfigs,
        isLoading: status === HomeScreensStateStatus.Loading,
    };
};
