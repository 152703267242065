import React from "react";
import { GlobalApplicationState } from "globalApplicationState";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Theme } from "@mui/material/styles";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import useMediaQuery from '@mui/material/useMediaQuery';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        main: {
            height: "100vh",
            width: "100vw",
            display: "flex",
            flexDirection: "row",
        },
        col: {
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: 15,
        },
        leftCol: {
            backgroundColor: "#fcfcff",
        },
        rightCol: {
            background: `url(${process.env.PUBLIC_URL}/images/login-bg.png) no-repeat`,
            backgroundSize: "cover",
            flex: 1.5,
        },
        form: {
            padding: 25,
            backgroundColor: "rgba(255, 255, 255, 1)",
            display: "flex",
            flexDirection: "column",
            borderRadius: 4,
            width: "75%",
            maxWidth: 529,
        },
        formContainer: {
            marginTop: "auto",
            marginBottom: "auto",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
        engageText: {
            fontWeight: 500,
            fontSize: 36,
            color: "#aa214e",
            lineHeight: 1,
        },
        whatsNew: {
            textTransform: "capitalize",
            textDecoration: "none",
            marginTop: 50,
            fontSize: 16,
            color: "rgb(59, 120, 171)",
            fontWeight: 500,
            "&:hover": {
                textDecoration: "none",
            },
        },
        arrow: {
            fontSize: 16,
            fontWeight: 500,
        },
        poweredByContainer: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            alignSelf: "flex-start",
        },
        monomark: {
            width: 30,
            height: 30,
            marginRight: 7,
        },
        monomarkWhite: {
            background: `url(${process.env.PUBLIC_URL}/images/icons/sparrow-connected-monomark-white.svg)`,
        },
        monomarkRed: {
            background: `url(${process.env.PUBLIC_URL}/images/icons/sparrow-connected-monomark.svg)`,
        },
        poweredBy: {
            fontWeight: 400,
            fontStyle: "normal",
            fontSize: 12,
            color: "#ADB7C1",
        },
        engageContainer: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
        },
        needHelp: {
            color: "#D7D7D7",
            "&:hover,&:focus,&:link,&:visited,&:active": {
                color: "#D7D7D7",
            },
        },
        linksContainer: {
            marginTop: 15,
            textAlign: "center",
            fontSize: 14,
        },
        underline: {
            textDecoration: "underline",
        },
        separator: {
            textDecoration: "none",
            marginLeft: 7,
            marginRight: 7,
            fontSize: 16,
            color: "#D7D7D7",
        },
        font10: {
            fontSize: 10,
        },
    })
);

const LoginLayout: React.FunctionComponent<PropsWithRedux> = ({ children, helpLinks = false }) => {
    const classes = useStyles();
    const isBootstrapSmallAndAbove = useMediaQuery("(min-width: 576px)");
    const isBootstrapExtraSmall = useMediaQuery("(max-width: 575px)");

    // responsive font logic
    const stopFont10 = useMediaQuery("(min-width: 670px)");
    const useFont10Small = useMediaQuery("(max-width: 390px");
    const useFont10Large = useMediaQuery("(min-width: 576px)");
    const useFont10 = !stopFont10 && (useFont10Small || useFont10Large);

    // helper function to render powered by icon and text
    const poweredByText = (iconClass: string) => (
        <div className={classes.poweredByContainer}>
            <div className={`${classes.monomark} ${iconClass}`}></div>
            <span className={classes.poweredBy}>Powered by Sparrow Connected</span>
        </div>
    );

    return (
        <div id="login-layout-container" className={classes.main}>
            {isBootstrapSmallAndAbove && (
                <div className={`${classes.col} ${classes.leftCol}`}>
                    <div className={classes.engageContainer}>
                        <span className={classes.engageText}>Be in-the-know. Discover.</span>
                        <span className={classes.engageText}>Engage. Explore.</span>
                        <a
                            id="whats-new-text"
                            className={classes.whatsNew}
                            href=" https://support.sparrowconnected.com/en/product-updates"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            WHAT'S NEW
                            <span className={classes.arrow}>→</span>
                        </a>
                    </div>
                    {poweredByText(classes.monomarkRed)}
                </div>
            )}
            <div className={`${classes.col} ${classes.rightCol}`}>
                <div className={classes.formContainer}>
                    <div className={classes.form}>{children}</div>
                    {helpLinks && (
                        <div className={`${classes.linksContainer} ${useFont10 && classes.font10}`}>
                            <a
                                href="https://support.sparrowconnected.com/en/forgot-email-or-username"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={`${classes.underline} ${classes.needHelp}`}
                            >
                                I don't know my email or user ID
                            </a>
                            <span className={classes.separator}> | </span>
                            <a
                                href="https://support.sparrowconnected.com/en/sign-in-screen-explained"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={`${classes.underline} ${classes.needHelp}`}
                            >
                                Why am I seeing this?
                            </a>
                        </div>
                    )}
                </div>
                {isBootstrapExtraSmall && poweredByText(classes.monomarkWhite)}
            </div>
        </div>
    );
};

interface ComponentProps {
    helpLinks?: boolean;
}

const connector = connect((state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
}));
type PropsWithRedux = ConnectedProps<typeof connector> & RouteComponentProps;

export default withRouter(connector(LoginLayout));
