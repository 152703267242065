import React, { useRef, useState } from "react";

import Popover from "@mui/material/Popover";

import { Theme } from "@mui/material/styles";


import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    message: {
      color: "#666666",
      fontSize: 12,
      fontStyle: "italic",
      marginTop: 15,
      textDecoration: "underline"
    }
  })
);


const UnableToEditMessage: React.FunctionComponent<{}> = props => {
  const [showPopover, setShowPopover] = useState(false);
  const popoverRef = useRef(null);

  const classes = useStyles();

  return (
    <React.Fragment>
      <div
        ref={popoverRef}
        onMouseEnter={() => setShowPopover(true)}
        onMouseLeave={() => setShowPopover(false)}
        className={classes.message}
      >
        Why can't I edit other fields or delete this account?
      </div>
      <Popover
        open={showPopover}
        anchorEl={popoverRef.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        PaperProps={{
          onMouseEnter: () => setShowPopover(true),
          onMouseLeave: () => setShowPopover(false),
          style: {
            maxWidth: 250
          }
        }}
        style={{ pointerEvents: "none" }}
        onClose={() => setShowPopover(false)}
        classes={{
          paper: "help-text-description"
        }}
      >
        Profiles and details synced from a third-party provider cannot be edited or deleted through Sparrow.
      </Popover>
    </React.Fragment>
  );
}

export default UnableToEditMessage;