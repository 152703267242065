import React from "react";
import { Button, Skeleton } from "@mui/material";

import "../../styles/contentBandSeeMore.sass";

interface IContentBandSeeMore {
    className?: string;
    full?: boolean;
    size?: "sm" | "lg";
}

export const ContentBandCardSeeMore: React.FC<IContentBandSeeMore> = ({ size }) => {
    const isSm = size === "sm";

    return (
        <div className={`content-band-card-see-more ${size}`}>
            <Skeleton variant="rectangular" width={isSm ? 204 : 271} height={isSm ? 110 : 135} />
            <Skeleton width={isSm ? 204 : 271} height={isSm ? undefined : 25} />
            <Skeleton width={isSm ? 204 : 271} height={isSm ? undefined : 25} />
            <Skeleton width={isSm ? 83 : 110} height={isSm ? undefined : 25} />
            <SeeMoreButton />
        </div>
    );
};

export const ContentBandWindowSeeMore: React.FC<IContentBandSeeMore> = ({ size }) => {
    const isSm = size === "sm";

    return (
        <div className={`content-band-card-see-more ${size}`}>
            <Skeleton variant="rectangular" width={isSm ? 171 : 418} height={isSm ? 92 : 226} />
            <SeeMoreButton width={102} />
        </div>
    );
};

export const SeeMoreButton: React.FC<React.CSSProperties> = (style?: React.CSSProperties) => (
    <Button
        className="see-more"
        variant="contained"
        color="primary"
        disableRipple
        sx={{
            "&:hover": {
                cursor: "initial",
                backgroundColor: "#3b78ab",
            },
        }}
        style={{ ...style }}
    >
        SEE MORE
    </Button>
);
