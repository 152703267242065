import { Action } from 'redux';
import * as Actions from './actions'
import { InsightsState } from './models'

const actionHandler: { [actionType: string]: (state: InsightsState, action: Action) => InsightsState } = {
    [Actions.GET_POST_STATS_INIT]: (state: InsightsState, action: Actions.GetPostStatsInit) => {
        return state;
    },
    [Actions.GET_POST_STATS_COMPLETE]: (state: InsightsState, action: Actions.GetPostStatsComplete) => {
        return {
            ...state,
            postStats: action.posts
        };
    },
    [Actions.GET_USER_COUNTS_INIT]: (state: InsightsState, action: Actions.GetUserCountsInit) => {
        return {
            ...state,
            userCounts: null
        } as InsightsState;
    },
    [Actions.GET_USER_COUNTS_COMPLETE]: (state: InsightsState, action: Actions.GetUserCountsComplete) => {
        return {
            ...state,
            userCounts: action.userCounts.map(userCount => {
                return {
                    ...userCount,
                    rangeStart: new Date(userCount.dateRounded),
                }
            })
        } as InsightsState;
    },
    [Actions.GET_COMMENT_REACTION_COUNTS_INIT]: (state: InsightsState, action: Actions.GetCommentAndReactionCountsInit) => {
        return {
            ...state,
            commentCounts: null
        } as InsightsState;
    },
    [Actions.GET_COMMENT_REACTION_COUNTS_COMPLETE]: (state: InsightsState, action: Actions.GetCommentAndReactionCountsComplete) => {
        return {
            ...state,
            commentAndReactionCounts: action.commentAndReactionCounts.map(commentAndReactionCount => {
                return {
                    ...commentAndReactionCount,
                    rangeStart: new Date(commentAndReactionCount.rangeStart),
                }
            })
        };
    },
    [Actions.GET_PLATFORM_STATS_INIT]: (state: InsightsState, action: Actions.GetPlatformStatsInit) => {
        return {
            ...state,
            platformStats: null
        } as InsightsState;
    },
    [Actions.GET_PLATFORM_STATS_COMPLETE]: (state: InsightsState, action: Actions.GetPlatformStatsComplete) => {
        return {
            ...state,
            platformStats: action.platformStats
        };
    },
    [Actions.GET_DETAILED_POST_STATS_INIT]: (state: InsightsState, action: Actions.GetDetailedPostStatsInit) => {
        return {
            ...state,
            post: null
        } as InsightsState;
    },
    [Actions.GET_DETAILED_POST_STATS_COMPLETE]: (state: InsightsState, action: Actions.GetDetailedPostStatsComplete) => {
        return {
            ...state,
            post: action.post
        };
    },
    [Actions.GET_POST_READS_INIT]: (state: InsightsState, action: Actions.GetPostReadsInit) => {
        return state;
    },
    [Actions.GET_POST_READS_COMPLETE]: (state: InsightsState, action: Actions.GetPostReadsComplete) => {
        return {
            ...state,
            post: {
                ...state.post,
                readStats: action.postReads
            }
        } as InsightsState;
    },
    [Actions.GET_READ_VIEW_STATS_INIT]: (state: InsightsState, action: Actions.GetReadViewStatsInit) => {
        return state;
    },
    [Actions.GET_READ_VIEW_STATS_COMPLETE]: (state: InsightsState, action: Actions.GetReadViewStatsComplete) => {
        return {
            ...state,
            readViewStats: action.readViewStats
        };
    },
    [Actions.GET_TAG_STATS_INIT]: (state: InsightsState, action: Actions.GetTagStatsInit) => {
        return state;
    },
    [Actions.GET_TAG_STATS_COMPLETE]: (state: InsightsState, action: Actions.GetTagStatsComplete) => {
        return {
            ...state,
            tagStats: {
                ...state.tagStats,
                ...action.tagStats
            }
        } as InsightsState;
    },
    [Actions.GET_TAG_READS_INIT]: (state: InsightsState, action: Actions.GetTagReadsInit) => {
        return state;
    },
    [Actions.GET_TAG_READS_COMPLETE]: (state: InsightsState, action: Actions.GetTagReadsComplete) => {
        return {
            ...state,
            tagStats: {
                ...state.tagStats,
                readStats: action.postReads
            }
        };
    },
    [Actions.GET_TAG_POSTS_INIT]: (state: InsightsState, action: Actions.GetTagPostsInit) => {
        return state;
    },
    [Actions.GET_TAG_POSTS_COMPLETE]: (state: InsightsState, action: Actions.GetTagPostsComplete) => {
        return {
            ...state,
            tagStats: {
                ...state.tagStats,
                posts: action.posts
            }
        } as InsightsState;
    },
    [Actions.GET_TOP_CHANNELS_INIT]: (state: InsightsState, action: Actions.GetTopChannelsInit) => {
        return state;
    },
    [Actions.GET_TOP_CHANNELS_COMPLETE]: (state: InsightsState, action: Actions.GetTopChannelsComplete) => {
        return {
            ...state,
            topChannels: action.tags
        } as InsightsState;
    },
    [Actions.GET_TOP_SURVEYS_INIT]: (state: InsightsState, action: Actions.GetTopSurveysInit) => {
        return state;
    },
    [Actions.GET_TOP_SURVEYS_COMPLETE]: (state: InsightsState, action: Actions.GetTopSurveysComplete) => {
        return {
            ...state,
            surveys: action.surveys
        } as InsightsState;
    },
    [Actions.GET_TOP_EVENTS_INIT]: (state: InsightsState, action: Actions.GetTopEventsInit) => {
        return state;
    },
    [Actions.GET_TOP_EVENTS_COMPLETE]: (state: InsightsState, action: Actions.GetTopEventsComplete) => {
        return {
            ...state,
            topEvents: action.events
        } as InsightsState;
    },
    [Actions.GET_USER_TRENDS_INIT]: (state: InsightsState, action: Actions.GetUserTrendsInit) => {
        return state;
    },
    [Actions.GET_USER_TRENDS_COMPLETE]: (state: InsightsState, action: Actions.GetUserTrendsComplete) => {
        return {
            ...state,
            userTrends: action.userTrends
        } as InsightsState;
    },
    [Actions.SET_TO_FETCH]: (state: InsightsState, action: Actions.SetToFetch) => {
        return {
            ...state,
            toFetch: action.toSet
        } as InsightsState;
    },
}

export const reducer = (state: InsightsState, action: Action) => {

    const actionHandlerMethod = actionHandler[action.type];
    if (actionHandlerMethod) {
        return actionHandlerMethod(state, action);
    }

    return state || {} as InsightsState;
};
