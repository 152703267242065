import { Button } from "@mui/material";
import * as React from 'react';

interface ComponentProps {
    onAdd: () => void;
    onAddDisabled: boolean;
    onCancel?: () => void;
    letterCount: number;
    maxLength: number;
}

const TextboxButtons: React.FC<ComponentProps> = (props) => {
    const { onAdd, onAddDisabled, onCancel, letterCount, maxLength } = props;
    const isReply = !!onCancel;

    return (
        <div style={{paddingBottom: isReply ? "10px" : ""}}>
            <Button
                variant="contained"
                color="primary"
                disabled={onAddDisabled}
                onClick={onAdd}
            >
                Submit
            </Button>

            {isReply &&
                <Button
                    variant="text"
                    color="primary"
                    onClick={onCancel}
                    style={{marginLeft: "10px"}}
                >
                    Discard
                </Button>   
            }
            <div className="maximum-word-count" style={{ float: 'right' }}>{letterCount}/{maxLength}</div>
        </div>
    );
}

export default TextboxButtons;