import React from "react";

import { postsApi } from "api/instances";
import DownloadButton from "modules/common/components/buttons/muiDownloadButton";
import { FileDownloader } from "utils/fileDownloader";

const DownloadReports: React.FunctionComponent<ComponentProps> = props => {
    const onClick = async (): Promise<any> => {
        const result = await postsApi.GetPostsCSV({
            author: "",
            numOf: "",
            postType: "",
            selectVals: { dateRange: "", equality: "", publishedBetweenTimeOne: null, publishedBetweenTimeTwo: null, tags: [], type: "", userActivity: "" },
            title: ""
          }, false, "Published", false);

          return result;
    };

    return (
        <DownloadButton
            fileType={FileDownloader.DEFAULT_FILE_TYPE}
            text="Download Reports"
            onClick={onClick}
            downloadName={`sparrow-posts-${new Date().toLocaleString().replaceAll(/, | /g, "/")}.csv`}
        />
    );
}

interface ComponentProps { }

export default DownloadReports;
