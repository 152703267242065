import { CustomFileData } from "../models";

const chonkyFileIdAttributeName = 'data-chonky-file-id';

// for finding, attaching the delete style classname and thus applying our custom styling on the 'Delete' button on the context menu and action menu dropdown
export const attachDeleteClassNames = () => {
    const deleteStyleClassName = 'delete-text-styling';

    try {
        const liButtonTextSpan = document.querySelectorAll('li.chonky-baseButton > div.chonky-MuiListItemText-root  > span.chonky-text') as NodeListOf<HTMLSpanElement>;

        liButtonTextSpan.forEach(ele => {
            let grandParentElement = ele.parentElement && ele.parentElement.parentElement
                ? ele.parentElement.parentElement
                : undefined;

            if (ele.textContent === "Delete" && grandParentElement && !grandParentElement.classList.contains(deleteStyleClassName)) {
                grandParentElement.classList.add(deleteStyleClassName);
            }
        });
    }
    catch {}
};

// for helping with styling the inner Chonky components styling for search matches where we want to highlight/bold
// matched filenames and surface the file tags if there are any matching
export const attachSearchMatchClassNames = (searchTerm: string, fileData: CustomFileData[], isGridView: boolean) => {
    if (searchTerm === "") return;
    const icoTag = "/images/icons/tag.svg";
    const searchTextTagDivClassName = 'search-text-tag-div';

    // Function to recursively search for text nodes and highlight matching text
    const insertHighlightClassToMatchingNodeText = (node: Node, searchText: string) => {
        // search for the span with text
        if (node.nodeType === Node.TEXT_NODE) {
            const text = node.textContent || '';

            // find the index of the matching, case insenstive text search
            const index = text.toLowerCase().indexOf(searchText.toLowerCase());
            if (index !== -1) {

                // preserve the node structure from before insertion, before and after the matching substring of text
                const before = document.createTextNode(text.substring(0, index));
                const after = document.createTextNode(text.substring(index + searchText.length));

                // create our span for attaching the highlighting class to the matching substring
                const span = document.createElement('span');
                span.textContent = text.substring(index, index + searchText.length);
                span.classList.add('highlight-search-text'); // Add a CSS class for highlighting

                // attach to the parent node
                if (node.parentNode) {
                    node.parentNode.insertBefore(before, node);
                    node.parentNode.insertBefore(span, node);
                    node.parentNode.insertBefore(after, node);
                    node.parentNode.removeChild(node);
                }
            }
        } else if (node.nodeType === Node.ELEMENT_NODE) {
            // recurse into all children of an element node
            for (const childNode of Array.from(node.childNodes)) {
                insertHighlightClassToMatchingNodeText(childNode, searchText);
            }
        }
    }

    // will build a div with the matching keywords/tags of an image with a tags icon
    const buildSearchTagDiv = (matchingWords: string[], searchTagDivCustomClassName?: string): HTMLDivElement => {
        let newDivElement = document.createElement('div');
        newDivElement.className = `${searchTextTagDivClassName} ${searchTagDivCustomClassName ?? ""}`;

        if (matchingWords.length > 0) {
            let imgIconElement = document.createElement('img');
            imgIconElement.src = icoTag;
            imgIconElement.alt = 'Tag icon';
            imgIconElement.className = 'search-text-icon';

            newDivElement.appendChild(imgIconElement);
        }

        let tagsSpanElement = document.createElement('span');
        tagsSpanElement.innerText = matchingWords.length === 0 ? '  ' : `${matchingWords[0]}${matchingWords.length > 1 ? ', +1' : ''}`;
        newDivElement.appendChild(tagsSpanElement);

        return newDivElement;
    }

    try {
        // depending on the gridview or listview state of Chonky, the display components classnames will differ so we need to account for 2 cases
        if (isGridView) {
            // find the divs that the images are in
            const imageElementGridDivs = document.querySelectorAll('div[class^="gridFileEntryNameContainer"]') as NodeListOf<HTMLElement>;
            // go through all the image grid divs and add in the keywords list if there are matches, and highlight the matching searchTerm substrings
            // to the image title/name and keywords
            imageElementGridDivs.forEach(ele => {
                // encapsulate the former component in a div for styling
                ele.innerHTML = `<div class='search-text-div'>` + ele.innerHTML + '</div>';

                // next we'll need to figure out if there are any matching keywords to the images that are displayed or not
                // image names will show in a span with a title prop within the element grid div
                const imageElementWithTitle = ele.querySelector(`span[title]:not([title=""])`) as HTMLElement;
                if (imageElementWithTitle) {
                    // from our own file data, find the matching image and keywords if there are any
                    const matchingKeywordItem = fileData
                        .filter(item => 
                            item.name === imageElementWithTitle.title && 
                            item.keywords?.filter(keyword => keyword.toLowerCase().includes(searchTerm.toLowerCase()))
                        );
                    
                    // check if this image has any matching keywords with the searchTerm
                    if (matchingKeywordItem.length > 0) {
                        // if there are matching keywords, find the the keywords
                        const matchingKeywords = matchingKeywordItem[0].keywords?.filter(keyword => keyword.toLowerCase().includes(searchTerm.toLowerCase())) ?? [];

                        // attach the keywords to the element
                        if (matchingKeywords && matchingKeywords.length > 0 && document.getElementsByClassName(searchTextTagDivClassName).length === 0)
                            ele.appendChild(buildSearchTagDiv(matchingKeywords));
                    }
                }

                // go through the image grid element and highlight matching text (should only contain the image name, image extension, and matching keywords)
                insertHighlightClassToMatchingNodeText(ele, searchTerm);
            })
        }
        else {
            const anyKeywordMatches = fileData
                .filter(file => file.keywords?.find(keyword => keyword.toLowerCase().includes(searchTerm.toLowerCase()))).length > 0;

            // list view differs in classnames and how the name and data are laid out
            fileData.forEach(file => {
                // for list view, the title is inside of a div with a title prop -- we find and highlight the matching subtext
                let imageElementListTitleDiv = document.querySelector(`div[title="${file.name}"]`) as HTMLElement;
                insertHighlightClassToMatchingNodeText(imageElementListTitleDiv, searchTerm);

                let imageElementListTagDiv = document.querySelector(`div[${chonkyFileIdAttributeName}="${file.id}"]`) as HTMLElement;
                // if there are matching keywords, we will find the parent div that contains all the column data and append the keywords div
                if (anyKeywordMatches && imageElementListTagDiv.getElementsByClassName(searchTextTagDivClassName).length === 0) {
                    // next we need to build the keywords div for list view
                    const matchingKeywords = file.keywords?.filter(keyword => keyword.toLowerCase().includes(searchTerm.toLowerCase())) ?? [];

                    // listFileEntryProperty is needed to keep the styling consistent inside of Chonky for the new column we're adding
                    let newDiv = document.createElement('div');
                    newDiv.className = "listFileEntryProperty";

                    newDiv.appendChild(buildSearchTagDiv(matchingKeywords, 'listview'));
                    imageElementListTagDiv.appendChild(newDiv);

                    // highlight the keywords
                    insertHighlightClassToMatchingNodeText(imageElementListTagDiv, searchTerm);
                }
            });
        }
    }
    catch {}
};

// for overriding the default toggle icon Chonky provides OOTB into a checkmark
export const changeChonkyCheckboxIcon = (toggleOn: boolean) => {
    const checkmarkIcon: string = "/images/icons/checkmark.svg";
    const checkmarkActiveIconClassName = "checkmark-icon-active";
    const checkmarkInactiveIconClassName = "checkmark-icon-inactive";

    try {
        // depending on if the toggle is on/off, the data-icon prop will be set accordingly
        let toggleOnElement = document.querySelector(`svg[data-icon="toggle-on"],.${checkmarkActiveIconClassName}`) as HTMLElement;
        let toggleOffElement = document.querySelector(`svg[data-icon="toggle-off"],.${checkmarkInactiveIconClassName}`) as HTMLElement;

        if (!toggleOnElement && !toggleOffElement) return;

        // have to get the grandparent div so we can insert our checkmark icon
        let toggleParentDiv = toggleOnElement?.parentElement ?? toggleOffElement?.parentElement as HTMLDivElement;
        let toggleGrandParentDiv = toggleParentDiv?.parentElement as HTMLDivElement;

        if (!toggleParentDiv && !toggleGrandParentDiv) return;

        // remove the OOTB toggle button div
        toggleGrandParentDiv.removeChild(toggleParentDiv);

        // make our checkmark div
        let imgDivElement = document.createElement('div');
        imgDivElement.className = 'checkmark-icon-div';

        let imgIconElement = document.createElement('img');
        imgIconElement.src = checkmarkIcon;
        imgIconElement.alt = 'Checkmark icon';
        imgIconElement.className = toggleOn ? checkmarkActiveIconClassName : checkmarkInactiveIconClassName;

        imgDivElement.appendChild(imgIconElement);

        // insert the checkmark div
        toggleGrandParentDiv.appendChild(imgDivElement);
    }
    catch {}
}

// for adding custom styling ontop of the Chonky VFS to handle how selected files look
// when we select multiple for inline post and event creation or banner selection
export const addCustomSelectedFilesStylingDivs = (fileIds: string[]) => {
    const itemNumberSelectedClassName = 'item-selected-number';
    const itemSelectedClassName = 'item-selected';

    try {
        // if we have existing file styling, we remove them for our new fileIds
        let existingItemNumberSelectDivs = document.querySelectorAll(`div.${itemNumberSelectedClassName}`) as NodeListOf<HTMLDivElement>;
        existingItemNumberSelectDivs.forEach(div => div.parentElement?.removeChild(div));

        let existingItemSelectDivs = document.querySelectorAll(`div.${itemSelectedClassName}`) as NodeListOf<HTMLDivElement>;
        existingItemSelectDivs.forEach(div => div.classList.remove(itemSelectedClassName));

        // chonky will have a prop for matching fileIds on their display divs so we can use querySelector to find them
        fileIds.forEach((fileId, ind) => {
            // query select for the fileId in both grid and list view that doesn't have our custom selected file styling
            let gridImageDiv = document.querySelector(`div[class^="gridFileEntry"][${chonkyFileIdAttributeName}="${fileId}"]:not(:has(div.${itemNumberSelectedClassName}))`);
            let listImageDiv = document.querySelector(`div[class^="listFileEntry"][${chonkyFileIdAttributeName}="${fileId}"]:not(:has(div.${itemNumberSelectedClassName}))`);

            // create the div that holds the index number to display
            let fileSelectedDiv = document.createElement('div');
            fileSelectedDiv.className = itemNumberSelectedClassName;
            fileSelectedDiv.textContent = `${ind + 1}`;

            // append the number to the file depending onn the view
            if (gridImageDiv) {
                gridImageDiv.appendChild(fileSelectedDiv);
                gridImageDiv.classList.add(itemSelectedClassName);
            }
            else if (listImageDiv) {
                listImageDiv.appendChild(fileSelectedDiv);
                listImageDiv.classList.add(itemSelectedClassName);
            }
        });
    }
    catch {}
}
