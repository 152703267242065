import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import * as actions from "../actionCreator";
import { GlobalApplicationState } from "globalApplicationState";
import { push } from "react-router-redux";

import BasePage from "pages/common/basePage";
import Breadcrumb from "pages/common/breadcrumb";
import Columns from "pages/common/columns";
import MainContent from "pages/common/mainContent";

import confirm from "utils/notyPopups";

import { NewsletterDetails, NewsletterPastIssuesPage } from "../models";

import DashboardActions from "./components/dashboardActions";
import {Details} from "./components/details";
import FutureIssues from "./components/futureIssues";
import NewsletterInsightsTab from "./components/insights/newsletterInsightsTab";
import IssueInsightsTab from "./components/insights/issueInsightsTab";
import PastIssues from "./components/pastIssues";
import SendTestEmail from "../common/dialogs/sendTestEmailDialog";

import Divider from "@mui/material/Divider";

import moment from "moment";

import "../styles/newsletterDashboard.sass";
import EmailActivityLog from "../common/dialogs/emailActivityLog"
import _ from "lodash";
import LoadingOverlay from "modules/common/components/loadingOverlay";

import Tabs from "pages/common/tabs";
import SnackbarWrapper from "modules/common/components/snackbars/snackbarWrapper";
import { FileDownloader } from "utils/fileDownloader";

type tabs = 'details' | 'issue' | 'insights';
class NewsletterDashboardPage extends React.Component<PropsWithRedux, ComponentState> {
  constructor(props: PropsWithRedux) {
    super(props);

    this.state = {
      isLoadingNewsletter: true,
      isLoadingPastIssues: true,
      selectedTab: 0
    };
  }

  public componentDidMount() {
    moment.locale("en");

    const newsletterId = this.props.match.params.newsletterId;
    if (!!newsletterId) {
      this.onGetNewsletterDetails();
      this.getPastIssues(1);
    }
  }

  private _componentUnmounted = false;
  public componentWillUnmount(){
    this._componentUnmounted = true;
  }

  public render() {
    const { details } = this.state;

    var selectedTab: tabs  = 'details';
    if(!!this.props.match.params.tab)
    {
      if(this.props.match.params.tab === 'insights')
        selectedTab = 'insights';
      else
        selectedTab = 'issue';
    }

    const backToManageNewsletters = { title: "Back to Manage Newsletters", link: "~/admin/newsletters" };
    const backToNewsletterDashboard = { title: "Back to Newsletter Issues", link: `~/admin/newsletters/${this.props.match.params.newsletterId}/dashboard` };

    const breadcrumbItems = [
      { title: "Newsletters", link: "~/admin/newsletters" },
      { title: !!details ? details.title : "" }
    ];

    if(selectedTab !== 'details'){
      breadcrumbItems[1].link = `~/admin/newsletters/${this.props.match.params.newsletterId}/dashboard`;

      if(selectedTab === 'insights')
        breadcrumbItems.push({title: "Overall Insights"});
      else
        breadcrumbItems.push({title: "Issue Insights"});
    }

    var tabs=[
      { label: "Future & Past Issues", value: 0 },
    ];

    if(!!this.state.pastIssues && !!this.state.pastIssues.totalItems)
      tabs = tabs.concat({ label: "Newsletter Insights", value: 1 })

    if(!!this.state.details && !!this.state.details.lastSentIssueDateLocal)
      tabs = tabs.concat({ label: "Recent Issue's Insights", value: 2 })

    return (
      <BasePage>
        <Breadcrumb
          items={breadcrumbItems}
          backItem={selectedTab === 'details' ? backToManageNewsletters : backToNewsletterDashboard}
          rightComponent={
            <DashboardActions
              details={details}
              onDelete={this.onDeleteNewsletter}
              onDisable={this.onDisableNewsletter}
              onDownloadRecipientReport={this.onDownloadRecipientReport}
              onEdit={this.onEditNewsletter}
              onEnable={this.onEnableNewsletter}
              onSendTestEmail={this.onSendTestEmail}
              onViewEmailActivity={() => this.setState({showEmailActivityLog:true})}
            />
          }
        />
        <SnackbarWrapper open={!!this.state.successMessage} autoHideDuration={8000} onClose={() => this.setState({ successMessage: null })} severity="success" message={this.state.successMessage} />
        <SnackbarWrapper open={!!this.state.errorMessage} autoHideDuration={8000} onClose={() => this.setState({ errorMessage: null })} severity="error" message={this.state.errorMessage} />
        <Columns
          leftComponent={
            <React.Fragment>
              <div className="newsletter-dashboard-left-column">
                <MainContent addPadding>
                  <Details details={details} getConfig={this.props.getConfig}/>
                </MainContent>
              </div>
            </React.Fragment>
          }
          rightComponent={
            <MainContent>
              <Tabs
                tabs={tabs}
                selectedTab={this.state.selectedTab}
                onSelectTab={this.onChangeTab}
              />
              <div style={{padding: "10px", paddingTop: "20px"}}>
                {selectedTab === 'details' && this.getDetails()}
                {selectedTab === 'insights' && this.getOverallInsights()}
                {selectedTab === 'issue' && this.getIssueInsights()}
                {!!details && !!this.state.sendTestEmailForIssue &&
                  <SendTestEmail
                    show={true}
                    issueDate={this.state.sendTestEmailForIssue}
                    availableIssues={details.futureIssues.map(i=>i.issueDateLocal)}
                    newsletterId={this.props.match.params.newsletterId}
                    newsletterTitle={details!.title}
                    onError={(modelErrors) => {this.setState({sendTestEmailForIssue: null, errorMessage: _.first(_.first(_.values(modelErrors)))});}}
                    onClose={()=>this.setState({ sendTestEmailForIssue: null })}
                    onSuccess={(message) => this.setState({successMessage:message})}
                  />
                }
              </div>
              {!!this.state.showEmailActivityLog && !!details && <EmailActivityLog selectedNewsletterId={details.id} issueTags={details.sentIssueTags} defaultIssueId={this.state.currentIssueId} displayContext={details.title} onClose={() => this.setState({showEmailActivityLog:false})} />}
              <LoadingOverlay absolute={true} show={this.state.isDownloading || this.state.isLoadingNewsletter || this.state.isSaving || false} />
            </MainContent>
          }
        />
      </BasePage>
    );
  }

  private getDashboardMenu = (selectedTab: tabs) => {
    const newsletter = this.state.details;
    if(!newsletter) return <React.Fragment/>;

    return (
      <MainContent addMarginTop>
        <div className="newsletter-dashboard-contents">
          <div className="dashboard-contents-header">Dashboard Contents</div>
          <div className="dashboard-contents-menu">
            <div onClick={() => this.showIssuesTab()} className={`menu-item ${selectedTab === 'details' ? "selected" : ""}`}>
              <div>
                <div className="item-header">Future &amp; Past Issues</div>
                <div>Track past issues and manage future issues</div>
              </div>
            </div>
            {!!this.state.pastIssues && !!this.state.pastIssues.totalItems &&
              <React.Fragment>
                <div onClick={() => this.showOverallInsightsTab()} className={`menu-item ${selectedTab === 'insights' ? "selected" : ""}`}>
                  <div>
                    <div className="item-header">Overall Newsletter Insights</div>
                    <div>{this.state.pastIssues.totalItems} issue(s)</div>
                  </div>
                </div>
                {!!newsletter && !!newsletter.lastSentIssueDateLocal &&
                  <div onClick={() => this.showIssueInsightsTab(newsletter.lastSentIssueDateLocal!)} className={`menu-item ${selectedTab === 'issue' ? "selected" : ""}`}>
                    <div>
                      <div className="item-header">Insights for Recent Issue</div>
                      <div>Last issue sent on {moment(newsletter.lastIssueSentOn!).format("MMM D, YYYY, h:mmA")}</div>
                    </div>
                  </div>
                }
              </React.Fragment>
            }
          </div>
        </div>
      </MainContent>
    );
  }

  private getDetails = () => {
  if (!this.state.details)
    return <React.Fragment />;

    return (
      <div className="newsletter-issues">
        <FutureIssues
          newsletter={this.state.details}
          onNewsletterUpdated={() => this.onGetNewsletterDetails()}
          onSaving={()=>this.setState({isSaving: true})}
          onError={(message) => this.setState({isSaving: false, errorMessage:message})}
          onSuccess={(message) => this.setState({isSaving: false, successMessage:message})}
        />
        <Divider light />
        <PastIssues pastIssues={this.state.pastIssues} isLoading={this.state.isLoadingPastIssues} onGetPastIssues={this.getPastIssues} onViewInsightsClicked={(issueDateLocal:string) => this.viewInsightsClicked(issueDateLocal)} />
      </div>
    );
  }

  private getOverallInsights = () => {
    const newsletter = this.state.details;
    if(!newsletter)
      return <React.Fragment />;

    return (
      <div>
        <NewsletterInsightsTab
          newsletter={newsletter}
        />
      </div>
    );
  }

  private getIssueInsights = () => {
    const newsletter = this.state.details;
    if(!newsletter)
      return <React.Fragment />;

    return (
      <div style={{minHeight: "749px"}}>
        <IssueInsightsTab
          newsletter={newsletter}
          issueDate={this.props.match.params.tab || ""}
          onIssueLoaded={(issueId) => this.setState({currentIssueId: issueId})}
          goBackToDashboard={()=>this.showIssuesTab()}
        />
      </div>
    );
  }

  private getPastIssues = (pageNumber: number): Promise<void> => {
    this.setState({isLoadingPastIssues: true});
    return this.props.getPastIssues(this.props.match.params.newsletterId, pageNumber).then((pastIssues) => {
      if(this._componentUnmounted) return;
      this.setState({ pastIssues, isLoadingPastIssues: false });
    });
  }

  private onDeleteNewsletter = async () => {
    if (await confirm.show({
      title: `Delete Newsletter`,
      text: (
        <div>
          <div>You're about to permanently delete '{this.state.details!.title}'. You'll lose all queued and sent issues, reports, and settings associated with this. You cannot undo this action.</div>
          <br />
          <div>Are you sure?</div>
        </div>
      ),
      yesColor: "#a80000",
      yesText: "Delete",
      noText: "Cancel"
    })){
      this.setState({isSaving: true});
      this.props.deleteNewsletter(this.props.match.params.newsletterId).then((succeeded) => {
        this.setState({isSaving: false});
        if (succeeded)
          this.redirectBackToNewsletters();
        else
          this.onGetNewsletterDetails();
      });
    }
  }

  private onDisableNewsletter = () => {
    this.setState({isSaving: true});
    this.props.disableNewsletter(this.props.match.params.newsletterId)
      .then((succeeded) => {
        if(succeeded){
          this.setState({successMessage:'Newsletter disabled'});
        }
        this.onGetNewsletterDetails();
        this.setState({isSaving: false});
    });
  }

  private onEnableNewsletter = () => {
    this.setState({isSaving: true});
    this.props.enableNewsletter(this.props.match.params.newsletterId)
    .then((succeeded) => {
      if (succeeded){
        this.setState({successMessage:'Newsletter enabled'});
      }
      this.onGetNewsletterDetails();
      this.setState({isSaving: false});
    });
  }

  private onDownloadRecipientReport = () => {
    this.setState({ isDownloading: true });
    const file = {
      name: `${this.state.details!.title} Recipients.csv`
    };
    this.props.getRecipientsCsv(this.state.details!.id)
      .then((url) => new FileDownloader(file).downloadProvided(url))
      .then(_ => this.setState({ isDownloading: false }))
      .catch(_ => this.setState({ isDownloading: false }));
  }

  private onEditNewsletter = () => {
    this.props.redirectTo("/" + this.props.tenant + "/admin/newsletters/" + this.props.match.params.newsletterId);
  }

  private onGetNewsletterDetails = () => {
    this.setState({ isLoadingNewsletter:true });
    this.props.getNewsletterDetails(this.props.match.params.newsletterId).then((details) => {
      if(this._componentUnmounted) return;
      if (!!details)
        this.setState({ details, isLoadingNewsletter:false });
      else
        this.redirectBackToNewsletters();
    });
  }

  private onSendTestEmail = () => {
    this.setState({ sendTestEmailForIssue: this.state.details!.nextIssueSendOn || this.state.details!.lastIssueSentOn });
  }

  private showIssuesTab = () => {
    this.setState({currentIssueId:null});
    this.props.redirectTo("/" + this.props.match.params.tenant + "/admin/newsletters/" + this.props.match.params.newsletterId + "/dashboard");
  }

  private showOverallInsightsTab = () => {
    this.props.redirectTo("/" + this.props.match.params.tenant + "/admin/newsletters/" + this.props.match.params.newsletterId + "/dashboard/insights");
  }

  private showIssueInsightsTab = (issueDateLocal: string) => {
    this.props.redirectTo("/" + this.props.match.params.tenant + "/admin/newsletters/" + this.props.match.params.newsletterId + "/dashboard/" + issueDateLocal);
  }

  private redirectBackToNewsletters = () => {
    this.props.redirectTo(`/${this.props.tenant}/admin/newsletters`);
  }

  private viewInsightsClicked = (issueDateLocal: string) => {
    this.setState({selectedTab: 2});
    this.showIssueInsightsTab(issueDateLocal);
  }

  private onChangeTab = (e) => {
    const newsletter = this.state.details;
    this.setState({selectedTab: e});

    //Future & Past Issues
    if(e === 0) {
      this.showIssuesTab();
    }

    //Newsletter Insights
    if(e === 1) {
      this.showOverallInsightsTab();
    }

    //Recent Issue
    if(e === 2) {
      if(!!newsletter)
        this.showIssueInsightsTab(newsletter.lastSentIssueDateLocal!)
    }
  }
}


interface RouteParams {
  tenant: string;
  newsletterId: string;
  tab?: string;
}

interface ComponentProps {}

interface ComponentState {
  details?: NewsletterDetails;
  isLoadingNewsletter: boolean;
  pastIssues?: NewsletterPastIssuesPage;
  isLoadingPastIssues: boolean;
  currentIssueId?: string | null;

  sendTestEmailForIssue?: string | null;
  showEmailActivityLog?: boolean;
  showCopyNewsletter?: boolean;
  isDownloading?: boolean;
  isSaving?: boolean;
  selectedTab: number;

  successMessage?: string | null;
  errorMessage?: string | null;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps & RouteComponentProps<RouteParams>) => ({
    ...ownProps,
    tenant: state.tenant.id
  }),
  {
    deleteNewsletter: actions.deleteNewsletter,
    disableNewsletter: actions.disableNewsletter,
    enableNewsletter: actions.enableNewsletter,
    getNewsletterDetails: actions.getNewsletterDetails,
    getPastIssues: actions.getPastIssues,
    getRecipientsCsv: actions.getRecipientsCSV,
    getConfig: actions.getConfig,
    redirectTo: push
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(NewsletterDashboardPage);
