import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import moment from "moment";

import { insightsApi } from "../../../../api/instances";

import EventTypeBanner from "modules/common/components/banners/eventTypeBanner";
import Loading from "modules/common/components/loading";
import { TagStats } from "modules/insights";
import { TenantSettingsTag } from "modules/settings/models";
import { Event, NotificationRange } from "../../models";

import DateBlock from "../date-block/dateBlock";

import Author from "./components/author";
import Body from "./components/body";
import CutoffTime from "./components/cutoffTime";
import EventTimes from "./components/eventTimes";
import FileAttachments from "./components/fileAttachments";
import Location from "./components/location";
import Tags from "./components/tags";
import { ReminderInfo, DelayType } from "modules/common/components/authoring/models";
import Title from "./components/title";
import { parseAnchors } from "utils/htmlUtils";
import HeroBanner from "modules/common/components/heroBanner";
import { CustomCss } from "modules/common/components/customCss";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import CloseIcon from "@mui/icons-material/Close";

import '../../../../styles/views.sass'

class EventPreview extends React.Component<PropsWithRedux, ComponentState> {
    constructor(props: PropsWithRedux) {
        super(props);

        const { lcidMappings } = props;

        const availableLcids: string[] = Object.keys(props.event.translatedContent!);
        const availableLanguages: Language[] = availableLcids.map((lcid) => ({ language: lcidMappings[lcid] ? lcidMappings[lcid].languageShort : lcid, lcid }));

        let reminders: ReminderInfo[] = this.onSetReminderInfo(props.event);

        this.state = {
            activeLcid: availableLcids[0],
            availableLanguages: availableLanguages,
            isFetching: true,
            reminders: reminders,
            sendNotification: true,
            tagStats: []
        };
    }

    public componentDidMount() {
        moment.locale("en");
        if (this.props.show && this.props.event.tags && this.props.event.tags.length) {
            this.fetchTagStats();
        }
    }

    public componentDidUpdate(prevProps: PropsWithRedux) {
        if (this.props.show && (this.props.show !== prevProps.show)) {

            const { lcidMappings } = this.props;

            const availableLcids: string[] = Object.keys(this.props.event.translatedContent!);
            const availableLanguages: Language[] = availableLcids.map((lcid) => ({ language: lcidMappings[lcid] ? lcidMappings[lcid].languageShort : lcid, lcid }));

            let reminders: ReminderInfo[] = this.onSetReminderInfo(this.props.event);

            this.setState({ activeLcid: availableLcids[0], availableLanguages, reminders });

            if (!this.state.tagStats.length)
                this.fetchTagStats();
        }
    }

    public render() {
        const { event } = this.props;

        return (
            <>
                <CustomCss enabled={this.props.tenantSettings.showFeatures.tinyMceCustomCssEnabled} />
                <Dialog fullWidth maxWidth="md" id="preview-event-dialog" open={this.props.show} onClose={this.onClose}
                    PaperProps={{ style: { maxWidth: 850 } }}>
                    <DialogTitle className="events-dialog-header">
                        <div className="events-dialog-header-title">
                            <Typography variant="h2">{!!this.props.onPublish ? "One last check!" : "Preview event"}</Typography>
                            <div>
                                <Button variant="outlined" onClick={this.onEditDetails}>
                                    {!!this.props.onPublish ? "Cancel" : "Edit details"}
                                </Button>
                                {!!this.props.onPublish
                                    ? <Button variant="contained" color="primary" onClick={this.onPublish}>Publish</Button>
                                    : <IconButton onClick={this.onClose} size="large"><CloseIcon /></IconButton>
                                }
                            </div>
                        </div>
                    </DialogTitle>
                    <DialogContent className="event-views event-preview">
                        <div>
                            <div className="preview-header">
                                <div>Additional Details</div>
                                {!!this.props.onPublish &&
                                    <Button variant="text" color="primary" size="small" onClick={this.onEditDetails}>Edit details</Button>
                                }
                            </div>
                            {this.getTimeDetails()}
                            {this.getTagDetails()}
                            {this.getNotificationDetails()}
                            {this.getReminderDetails()}
                        </div>
                        <div>
                            <div className="preview-header">Event Preview</div>
                            <div className="preview-secondary-label">Preview varies based on device and/or platform.</div>
                            <div className="preview-page">
                                <HeroBanner bannerColor={event.bannerColor!} image={event.image} attachedContent={event.attachedContent}>
                                    {this.getTypeBanner()}
                                    <DateBlock isAllDayEvent={(event.eventTimes![Object.keys(event.eventTimes!)[0]] == null)} eventStartTime={Object.keys(event.eventTimes!)[0]} eventEndTime={event.eventTimes![Object.keys(event.eventTimes!)[0]]} eventTimes={event.eventTimes} />
                                </HeroBanner>
                                <div className="page-content">
                                    <div className="content-heading">
                                        <Title title={event.translatedContent![this.state.activeLcid].title} />
                                    </div>
                                    <Location location={event.location} locationLink={event.locationLink} locationLinkLabel={event.locationLinkLabel} />
                                    <EventTimes
                                        activeLcid={this.state.activeLcid}
                                        {...event}
                                        eventTimes={event.eventTimes!}
                                        translatedContent={event.translatedContent!}
                                        showAddToCalendar={false}
                                    />
                                    <CutoffTime cutoffTime={event.cutoffTime} eventType={event.eventType!} />
                                    <Tags tags={event.tags || []} />
                                    <Author author={event.author || ""} authorEmail={event.authorEmail} />
                                    {this.getAvailableLanguages()}
                                    <Body body={parseAnchors(event.translatedContent![this.state.activeLcid].body)} />
                                    <FileAttachments fileAttachments={event.fileAttachments} />
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
            </>
        );
    }

    private fetchTagStats = () => {
        insightsApi.GetTagStats().then((tagStats) => {
            this.setState({ isFetching: false, tagStats });
        });
    }

    private getAvailableLanguages = (): JSX.Element => {
        if (this.state.availableLanguages.length < 2)
            return <React.Fragment></React.Fragment>;

        return (
            <div className="available-languages">
                <span className="emphasis">Available In: </span>
                {this.state.availableLanguages
                    .map<React.ReactNode>((language) =>
                        <span key={language.lcid} onClick={() => this.onSelectLanguage(language)} className={language.lcid === this.state.activeLcid ? "" : "inactive-language"}>{language.language}</span>
                    )
                    .reduce((prev, curr) => [prev, ", ", curr])
                }
            </div>
        );
    }

    private getNotificationDetails = (): JSX.Element => {
        const { event, notificationSettings } = this.props;

        const allNotificationsDisabled = !notificationSettings.settings["eventPublishedEmailAttempt"].toggle &&
            !notificationSettings.settings["eventPublishedTeamsAttempt"].toggle &&
            !notificationSettings.settings["eventPublishedSMSAttempt"].toggle &&
            !notificationSettings.settings["eventPublishedMobileAttempt"].toggle

        if (!this.props.onEditNotifications || !event.notifications)
            return <React.Fragment></React.Fragment>;

        return (
            <React.Fragment>
                <div className="preview-header">
                    <div>Notifications</div>
                    {!allNotificationsDisabled && <Button variant="text" color="primary" size="small" onClick={this.onEditNotifications}>Edit</Button>}
                </div>
                {!allNotificationsDisabled
                    ? <div className="preview-detail">
                        <div className="preview-label">Upon Publishing</div>
                        <div className="preview-detail-field">
                            {this.getNotificationStatus("MS Teams Chatbot", event.notifications.teamsOnPublish, notificationSettings.settings["eventPublishedTeamsAttempt"].toggle)}
                            {this.getNotificationStatus("Mobile App", event.notifications.mobileOnPublish, notificationSettings.settings["eventPublishedMobileAttempt"].toggle)}
                            {this.getNotificationStatus("Email", event.notifications.emailOnPublish, notificationSettings.settings["eventPublishedEmailAttempt"].toggle)}
                            {this.getNotificationStatus("SMS", event.notifications.smsOnPublish, notificationSettings.settings["eventPublishedSMSAttempt"].toggle)}
                        </div>
                    </div>

                    : <div style={{ marginBottom: '10px', marginLeft: '15px' }}>Your organization has disabled event publishing notifications.<br />Change this setting in Tenant Configurations.</div>}
                <Divider className="preview-detail-divider" />
            </React.Fragment>
        );
    }

    private getNotificationStatus = (label: string, range: NotificationRange, notificationToggle: boolean = true) => {
        if (range === "none" || !notificationToggle)
            return <div className="unselected-notification"><ClearIcon />{label}</div>;
        else
            return <div className="selected-notification"><CheckIcon fontSize="small" />{label}</div>;
    }

    private getReminderDetails = (): JSX.Element => {
        const { event, notificationSettings } = this.props;

        const allRemindersDisabled = !notificationSettings.settings["eventReminderEmailAttempt"].toggle &&
            !notificationSettings.settings["eventReminderTeamsAttempt"].toggle &&
            !notificationSettings.settings["eventReminderSMSAttempt"].toggle &&
            !notificationSettings.settings["eventReminderMobileAttempt"].toggle

        if (!this.props.onEditReminders || !event.notifications || !event.notifications.reminders || !event.notifications.reminders.length)
            return <React.Fragment></React.Fragment>;

        return (
            <React.Fragment>
                <div className="preview-header">
                    <div>Reminders</div>
                    {!allRemindersDisabled && <Button variant="text" color="primary" size="small" onClick={this.onEditReminders}>Edit</Button>}
                </div>
                {!allRemindersDisabled
                    ? this.state.reminders.map((reminder, index) => {
                        return (
                            <div key={index} className="preview-detail">
                                <div className="preview-label">Reminder {index + 1}</div>
                                <div className="preview-detail-field">
                                    <div>Send in {reminder.delay} {reminder.delayType} if unread</div>
                                    {this.getNotificationStatus("MS Teams Chatbot", reminder.teamsRange, notificationSettings.settings["eventReminderTeamsAttempt"].toggle)}
                                    {this.getNotificationStatus("Mobile App", reminder.mobileRange, notificationSettings.settings["eventReminderMobileAttempt"].toggle)}
                                    {this.getNotificationStatus("Email", reminder.emailRange, notificationSettings.settings["eventReminderEmailAttempt"].toggle)}
                                    {this.getNotificationStatus("SMS", reminder.smsRange, notificationSettings.settings["eventReminderSMSAttempt"].toggle)}
                                </div>
                            </div>
                        );
                    })

                    : <div style={{ marginBottom: '10px', marginLeft: '15px' }} className="platform-disabled-warning">Your organization has disabled event reminder notifications.<br />Change this setting in Tenant Configurations.</div>}
                <Divider className="preview-detail-divider" />
            </React.Fragment>
        );
    }

    private getTagDetails = (): JSX.Element => {
        const { event } = this.props;

        if (!event.tags || !event.tags.length)
            return <React.Fragment></React.Fragment>;

        if (this.state.isFetching)
            return (
                <React.Fragment>
                    <Loading />
                    <Divider className="preview-detail-divider" />
                </React.Fragment>
            );

        let tags: TagStats[] = [];

        event.tags!.map((tag) => {
            const tagStatIndex: number = this.state.tagStats.findIndex(tagStat => tagStat.id === tag.id);
            if (tagStatIndex !== -1)
                tags.push(this.state.tagStats[tagStatIndex]);
            else
                tags.push({
                    id: tag.id,
                    groupName: "",
                    name: tag.name,
                    isMandatory: !!tag.mandatory,
                    subscriberCount: 0,
                    postCount: 0,
                    viewCount: 0
                });
            return tag;
        });

        return (
            <React.Fragment>
                <div className="preview-detail">
                    <div className="preview-label">Topic Subscribers</div>
                    <div className="preview-detail-field">
                        {tags.map((tag) =>
                            <div key={tag.id}>{tag.name} ({tag.subscriberCount})</div>
                        )}
                    </div>
                </div>
                <Divider className="preview-detail-divider" />
            </React.Fragment>
        );
    }

    private getTimeDetails = (): JSX.Element => {
        const { event } = this.props;

        return (
            <div className="preview-detail-times">
                <div className="preview-detail">
                    <div className="preview-label">Publish on</div>
                    <div className="preview-date">{!!event.publishTime ? moment(event.publishTime).format("MMMM D, YYYY h:mm A (dddd)") : "Now"}</div>
                </div>
                {!!event.expiryTime &&
                    <div className="preview-detail">
                        <div className="preview-label">Expires on</div>
                        <div className="preview-date">{moment(event.expiryTime).format("MMMM D, YYYY h:mm A (dddd)")}</div>
                    </div>
                }
                <Divider className="preview-detail-divider" />
            </div>
        );
    }

    private getTypeBanner = (): JSX.Element => {
        const { event } = this.props;

        let tags: TenantSettingsTag[] = [];

        let availableTags: TenantSettingsTag[] = [];
        this.props.tagGroups.map(tagGroup => {
            availableTags = availableTags.concat(tagGroup.tags);
            return tagGroup;
        });

        event.tags!.map((tag) => {
            const tagIndex: number = availableTags.findIndex(tagStat => tagStat.id === tag.id);
            if (tagIndex !== -1)
                tags.push(availableTags[tagIndex]);
            return tag;
        });

        return (
            <EventTypeBanner eventType={event.eventType!} isRestricted={!!tags.find(tag => tag.restricted)} full lcid={this.state.activeLcid} />
        );
    }

    private onSelectLanguage = (language: Language) => {
        this.setState({ activeLcid: language.lcid });
    }

    private onSetReminderInfo = (event: Partial<Event>): ReminderInfo[] => {
        let reminders: ReminderInfo[] = [];
        if (!!event.notifications) {
            event.notifications.reminders.map((reminder, index) => {
                const delay: number = reminder.delay % 24 === 0 ? reminder.delay / 24 : reminder.delay;
                const delayType: DelayType = reminder.delay > 0 && reminder.delay % 24 === 0 ? "days" : "hours";

                if (reminder.channel === "none") {
                    reminders.push({
                        delay: delay,
                        delayType: delayType,
                        emailRange: "none",
                        mobileRange: "none",
                        smsRange: "none",
                        teamsRange: "none"
                    });
                } else if (reminder.channel === "inherit") {
                    reminders.push({
                        delay: delay,
                        delayType: delayType,
                        emailRange: event.notifications?.emailOnPublish || "none",
                        mobileRange: event.notifications?.mobileOnPublish || "none",
                        smsRange: event.notifications?.smsOnPublish || "none",
                        teamsRange: event.notifications?.teamsOnPublish || "none"
                    });
                } else {
                    if (index > 0 && (reminders[reminders.length - 1].delay === delay && reminders[reminders.length - 1].delayType === delayType)) {
                        if (reminder.channel === "email")
                            reminders[reminders.length - 1].emailRange = reminder.range;
                        else if (reminder.channel === "mobile")
                            reminders[reminders.length - 1].mobileRange = reminder.range;
                        else if (reminder.channel === "sms")
                            reminders[reminders.length - 1].smsRange = reminder.range;
                        else if (reminder.channel === "teams")
                            reminders[reminders.length - 1].teamsRange = reminder.range;
                    } else {
                        reminders.push({
                            delay: delay,
                            delayType: delayType,
                            emailRange: reminder.channel === "email" ? reminder.range : "none",
                            mobileRange: reminder.channel === "mobile" ? reminder.range : "none",
                            smsRange: reminder.channel === "sms" ? reminder.range : "none",
                            teamsRange: reminder.channel === "teams" ? reminder.range : "none"
                        });
                    }
                }
                return reminder;
            });
        }

        return reminders;
    }


    private onEditDetails = () => {
        this.props.onEditDetails();
    }

    private onEditNotifications = () => {
        this.props.onEditDetails();
        this.props.onEditNotifications!();
    }

    private onEditReminders = () => {
        this.props.onEditDetails();
        this.props.onEditReminders!();
    }

    private onPublish = () => {
        this.props.onPublish!(this.state.sendNotification);
    }

    private onClose = () => {
        this.props.onClose();
    }
}

interface Language {
    language: string;
    lcid: string;
}

interface ComponentProps {
    show: boolean;
    event: Partial<Event>;
    onEditDetails: () => void;
    onEditNotifications?: () => void;
    onEditReminders?: () => void;
    onPublish?: (sendNotification: boolean) => void;
    onClose: () => void;
}

interface ComponentState {
    activeLcid: string;
    availableLanguages: Language[];
    isFetching: boolean;
    reminders: ReminderInfo[];
    sendNotification: boolean;
    tagStats: TagStats[];
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        lcidMappings: state.resources.lcidMappings,
        notificationSettings: state.settings.notificationSettings,
        tagGroups: state.settings.tenantSettings.tagGroups,
        tenantSettings: state.settings.tenantSettings
    })
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(EventPreview);
