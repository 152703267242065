import { Button, Typography } from '@mui/material';
import LoadingCircle from 'modules/common/components/loadingCircle';
import * as React from 'react';
import BaseComponentOverlay from './baseComponentOverlay';

interface ComponentProps {
    show: boolean;
    totalNumToUpload: number;
    numCurrentlyUploaded: number;
    allowCancel?: boolean;
    onUploadCancel?: () => void;
}

const UploadingOverlay: React.FC<ComponentProps> = (props) => {
    const [aborting, setAborting] = React.useState(false);

    const onClick = () => {
        setAborting(true);

        if (props.allowCancel && props.onUploadCancel) props.onUploadCancel();
    }

    return (
        <BaseComponentOverlay 
            show={props.show}
            middleComponent={
                <>
                    <LoadingCircle size={80} />
                    <Typography color={'#888'} fontWeight={600} fontSize={20}>
                        { aborting ? 'Aborting...': `Uploading ${props.numCurrentlyUploaded} of ${props.totalNumToUpload} images...` }
                    </Typography>
                    {
                        !aborting && 
                        <Typography color={'#888'} fontStyle={'italic'}>
                            Stay on this page until upload is completed
                        </Typography>
                    }
                    {
                        props.allowCancel && props.onUploadCancel && !aborting && 
                        <Button 
                            variant="text"
                            onClick={onClick}
                            disableFocusRipple
                            href=""
                            type="link"
                            color="primary"
                            autoCapitalize="false"
                            style={{
                                fontWeight: "normal",
                                textTransform: "none",
                                textDecoration: "underline",
                                marginTop: "15px",
                            }}
                        >
                            Cancel upload
                        </Button>
                    }
                </>
            }
            fullScreen
        />
    )
};

export default UploadingOverlay;