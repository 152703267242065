import React from "react";

import { Button, IconButton, PopoverOrigin } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import Callout from "../../callout";

import "../styles/calloutMultiselect.sass";

// think about extending callout props
interface ICalloutMultiselectProps {
    items: Record<string, string>[];
    open: boolean;
    setOpen: (toSet: boolean) => void;
    anchorEl: HTMLElement | null;
    activeValue?: string;
    defaultValue?: string;
    selectedValues?: string[];
    anchorOrigin?: PopoverOrigin;
    onSelectItem: (item: Record<string, string>) => void;
    onAddItem: (item: Record<string, string>) => void; // add button click
    onRemoveItem: (item: Record<string, string>) => void; // list item click
    fullSize?: boolean;
}

const CalloutMultiselect: React.FunctionComponent<ICalloutMultiselectProps> = ({
    items,
    open,
    anchorEl,
    selectedValues = [],
    activeValue = "",
    defaultValue = "",
    anchorOrigin,
    onAddItem,
    onRemoveItem,
    onSelectItem,
    setOpen,
    fullSize = false
}) => {
    const getActions = (isDefault: boolean, isSelected: boolean, item: Record<string, string>): JSX.Element => {
        if (isDefault) return <></>;

        let element: JSX.Element;
        if (isSelected)
            element = <IconButton
                size="small"
                onClick={() => {
                    onRemoveItem(item);
                }}
                className="action"
            >
                <ClearIcon htmlColor="#b72026" />
            </IconButton>;
        else
            element = <Button
                variant="text"
                color="primary"
                onClick={() => {
                    onAddItem(item);
                }}
                className="action"
            >
                Add
            </Button>

        return element;
    }

    return (
        <Callout
            contentClassName={`callout-multiselect-content ${fullSize ? "full-size" : ""}`}
            anchorEl={anchorEl}
            open={open}
            setOpen={setOpen}
            arrowStyle={{ display: "none" }}
            popoverProps={{
                anchorOrigin,
            }}
            fullSize={fullSize}
        >
            {items.map((item: Record<string, string>) => {
                const isActive = item.key === activeValue;
                const isDefault = item.key === defaultValue;
                const isSelected = selectedValues.find((selectedItem) => selectedItem === item.key) !== undefined;

                return (
                    <div key={item.key} className={`callout-multiselect-item ${isActive ? "selected" : ""}`}>
                        <Button style={{ textTransform: "none" }} className={`main-item ${isActive ? "selected" : ""}`} onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                            onSelectItem(item);
                        }}>
                            {item.value}
                        </Button>
                        {isDefault &&
                            <span className="callout-multiselect-default-item action">Default</span>}
                        {getActions(isDefault, isSelected, item)}
                    </div>
                );
            })}
        </Callout>
    );
}

export default CalloutMultiselect;
