import * as React from "react";

import { Audience } from "modules/audiences/models";

import Chip from "@mui/material/Chip";


import "./chips.sass";


const AudienceChipLabels: React.FunctionComponent<ComponentProps> = props => {
  if (!props.audienceIds || !props.audienceIds.length)
    return (
      <div className="audience-chip-labels">
        <Chip
          label={props.emptyText ? props.emptyText : "All users"}
          disabled={true}
          className="all-users-audience"
        />
      </div>
    );

  const matchedAudiences: Audience[] = props.audienceIds.map((audienceId) => {
    const matchedAudience = props.audiences.find((audience) => audience.id === audienceId);
    if (!matchedAudience)
      return {
        id: "",
        displayName: "",
        description: "",
        enabled: false,
        type: ""
      };
    return matchedAudience;
  }).filter((audience) => !!audience.id);

  return (
    <div title={matchedAudiences.map((audience) => audience.displayName).join(", ")} className="audience-chip-labels">
      {matchedAudiences.slice(0,2).map((audience) => (
        <Chip
          key={audience.id}
          label={audience.displayName}
          disabled={!audience.enabled}
        />
      ))}
      {matchedAudiences.length > 2 &&
        (matchedAudiences.length === 3
          ? <Chip
              key={matchedAudiences[2].id}
              label={matchedAudiences[2].displayName}
              disabled={!matchedAudiences[2].enabled}
            />
          : <Chip
              key="more"
              label={`+${matchedAudiences.length - 2}`}
            />
        )
      }
    </div>
  );
}

interface ComponentProps {
  audienceIds: string[] | undefined;
  audiences: Audience[];
  emptyText?: string;
}

export default AudienceChipLabels;