import { Box, Button, Link, Typography } from '@mui/material';
import { reportsApi } from 'api/instances';
import { GlobalApplicationState } from 'globalApplicationState';
import TenantLink from 'modules/common/components/tenantLink';
import BasePage from 'pages/common/basePage';
import MainContent from 'pages/common/mainContent';
import * as React from 'react';
import { useEffect } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ReportDetails } from './models';
import moment from 'moment';
import Loading from 'modules/common/components/loading';
import AccessRestricted from './accessRestricted';
import ReportExpired from './reportExpired';
import NoticeWebpage from 'modules/common/noticeWebpage';
import { FileDownloader } from 'utils/fileDownloader';

interface RouteParams {
    reportId: string;
}

const DownloadReportPage : React.FunctionComponent<PropsWithRedux> = (props) => {
    const reportId = props.match.params.reportId;
    const userId = props.userId;

    const [isLoading, setIsLoading] = React.useState(false);
    const [reportInfo, setReportInfo] = React.useState<ReportDetails | null>(null)
    const [reportString, setReportString] = React.useState("");
    const [isRestricted, setIsRestricted] = React.useState(false);
    const [isExpired, setIsExpired] = React.useState(false);
    const [firstLoad, setFirstLoad] = React.useState(true);

    const processDownload = (reportData: string = reportString, reportName: string) => {
        const file = {
            name: reportName,
            type: isBinaryFile(reportName) ? "application/x-zip-compressed" : FileDownloader.DEFAULT_FILE_TYPE
        }
        new FileDownloader(file).downloadBlob(reportData)
        reportsApi.SetReportDownloaded(reportId); //We don't need to wait for this to complete, this is just for us to log.
    }

    const isBinaryFile = (reportName: string) => {
        const fileExtension = reportName.substring(reportName.lastIndexOf(".") + 1);
        return fileExtension === "zip" || fileExtension === "xlsx";
    }

    useEffect(() => {
        setIsLoading(true);
        let reportInfo;

        reportsApi.GetReportInfo(reportId).then(result => {
            reportInfo = result;
            setReportInfo(result);

            if(reportInfo !== null) {

                //Not the person who generated the report.
                if(reportInfo.userId !== userId) {
                    setIsRestricted(true);
                    return;
                }

                //Report is expired.
                if(moment(reportInfo.createdTime).add(7, "days") < moment(new Date())) {
                    setIsExpired(true);
                    return;
                }
    
                //If passes all checks, fetch the csv and auto download here.
                reportsApi.GetReportForDownload(reportId).then((result) => {
                    
                    const resultData = isBinaryFile(reportInfo.reportName) ? result : new TextDecoder().decode(result)
                    setReportString(resultData)

                    if(firstLoad && !!result) {
                        setFirstLoad(false);
                        processDownload(resultData, reportInfo.reportName);
                    }
                });
            } 
        }).finally(() => {
            setIsLoading(false);
        });
    }, [userId]);

    return (
        <BasePage>
            <Box
            sx={{
                minHeight: "75vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center"
            }}
            alignItems="center"
            justifyContent="center"
            >
                <MainContent addPadding>
                    <div style={{padding: "25px 80px"}}>
                        {isLoading 
                        ? <Loading />
                        : <>
                            {(!isRestricted && !isExpired && !!reportInfo) ? 
                                <NoticeWebpage 
                                    imageComponent={<img src="/images/report-download.png" alt="download banner" style={{maxWidth: "130.6px", maxHeight: "130.6px", width: "100%", height: "100%"}}/>}
                                    titleComponent={
                                        <React.Fragment>
                                            <Typography variant="h1" align="center">Thanks for downloading the</Typography>
                                            <Typography variant="h1" align="center">{reportInfo?.reportType}!</Typography>
                                        </React.Fragment>
                                    }
                                    bodyComponent={
                                        <React.Fragment>
                                            <Typography variant="body1" align="center">If your download doesn't start after 30 seconds,</Typography>
                                            <Typography variant="body1" align="center"><Link onClick={() => processDownload(reportString, reportInfo!.reportName)} style={{color: "#3b78ab"}}>
                                                click here to download manually.
                                            </Link></Typography>
                                        </React.Fragment>
                                    }
                                    buttonComponent={
                                        <TenantLink to="~/admin/dashboard">
                                            <Button variant="text" color="primary">Back to Home</Button>
                                        </TenantLink>
                                    }
                                />
                            : <> {(isRestricted) ? <AccessRestricted /> : <ReportExpired expiryDays={props.expiryDays}/>} </>
                            }
                        </>
                        }
                    </div>
                </MainContent>
            </Box>
        </BasePage>
    );
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: RouteComponentProps<RouteParams>) =>
    ({
        ...ownProps,
        tenant: state.tenant.id,
        userId: state.settings.currentUser.userId,
        expiryDays: state.settings.tenantSettings.reportExpiryDays
    }),
    {
         
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(DownloadReportPage);
