import * as React from 'react';
import { PlatformAnalyticsReport, UserCount } from '../models';
import { VerticalBarChart, VBarChartProps, VBarChartDataPoint } from './charts/verticalBarChart';
import WindowResizeListener from 'modules/common/components/window-resize-listener';
import moment from 'moment';
import {injectIntl, IntlShape} from "react-intl";
import {connect, ConnectedProps} from "react-redux";
import { GlobalApplicationState } from 'globalApplicationState';
import Loading from 'modules/common/components/loading';
import EmptyDataMessage from './emptyDataMessage';
import InfoHover from 'modules/common/components/hovers/infoHover';
import DownloadIcon from './downloadIconSvg';

interface ComponentProps {
    loading?: boolean
    userCounts: UserCount[] | null;
    intl: IntlShape;
    startDate: string;
    endDate: string;
    downloadImage: () => void;
    downloadExcel: () => void;
}

interface ComponentState { 
    widthToUse: string;
    itemsToDisplay: number;
}

class UsersByMonth extends React.Component<PropsWithRedux, ComponentState> {
    constructor(props) {
        super(props);
        this.state = {
            widthToUse: "2400px",
            itemsToDisplay: 2
        }
    }

    private _chart: VerticalBarChart;
    private _chartElement: HTMLElement;

    public componentDidMount() {
        this._chart = new VerticalBarChart(this.getChartState());
    }

    public componentDidUpdate() {
        if (this._chart) {
            this._chart.update(this.getChartState());
        }
    }

    public componentWillUnmount() {
        if (this._chart) {
            this._chart.destroy();
        }
    }


    public render() {
        moment.locale("en");

        return <div className="analytics-widget"  >
                <div className='downloadIcon' id={`${PlatformAnalyticsReport.engagedUsers}-downloadIcon`}>  
                    <DownloadIcon
                        downloadExcel={this.props.downloadExcel}
                        downloadImage={this.props.downloadImage}
                    />
                </div>
                <div style={{overflowX: "auto"}}>
                <div id={PlatformAnalyticsReport.engagedUsers} style={ this.state.itemsToDisplay > 7 ? {width: this.state.widthToUse, backgroundColor: '#fff'} : { backgroundColor: '#fff'}}>
                <div className="header" style={{display: "flex", flexDirection: "row", marginTop: "5px", position: "absolute"}}>
                    Engaged Users
                    <div className="tooltip-placement" >
                        <InfoHover>Engaged Users have opened at least one post or event within the timeframe you've selected.</InfoHover>
                    </div>
                </div>
                <div style={{height: "10px"}}>
                </div>
                <div className="body" style={{marginTop: "40px"}}>
                   {
                        this.props.loading ?
                        <div style={{position: "relative", top: "55px"}}>
                            <Loading/>
                        </div> :
                        <div>
                            {this.props.userCounts && this.props.userCounts.length > 0 ?
                            <div>
                                <div 
                                    ref={(e) => this._chartElement = e!} 
                                    className="dashboard-chart-widget"
                                    style={ this.state.itemsToDisplay > 7 ? {width: this.state.widthToUse} : {}}
                                >
                                </div>
                                <WindowResizeListener onChange={() => this._chart.update(this.getChartState())} />
                            </div> 
                            : <EmptyDataMessage/> }
                        </div>
                   }
                </div>
                </div>
                </div>
            </div>
    }
    private getChartState = (): VBarChartProps => {
        let userCounts = this.props.userCounts ?? [];
        
        //Use first relevant month and skip over the empty ones.
        userCounts = this.getRelevantMonth(userCounts);

        let useShortLabels = userCounts.length > 1 || moment(this.props.startDate, "MMM D, YYYY, h:mmA").month() !== moment(this.props.endDate, "MMM D, YYYY, h:mmA").month();

        let widthToUse = Math.max((userCounts.length * 80), 380).toString() + "px"
        if(this.state.widthToUse !== widthToUse) {
            this.setState({widthToUse, itemsToDisplay: userCounts.length});
        }

        let longLabelToUse = `${moment(this.props.startDate, "MMM D, YYYY, h:mmA").format("MMM DD, YYYY")} - ${moment(this.props.endDate, "MMM D, YYYY, h:mmA").format("MMM DD, YYYY")}`;

        return {
            el: this._chartElement,
            locale: this.props.locale,
            height: 200,
            data: userCounts
                .map((range, index) => {
                    return {
                        x: index,
                        value: range.countOfUsers,
                        label: useShortLabels ? moment(range.dateRounded).format('MMM YYYY') 
                        : longLabelToUse,
                        id: index.toString()
                    } as VBarChartDataPoint;
            }),
            labelBars: true
        } as VBarChartProps;
    }

    private getRelevantMonth = (userCounts: UserCount[]) => {
        if (userCounts.length > 0) {
            let oldestValidData = userCounts.findIndex(u => u.countOfUsers > 0);
            if (oldestValidData === -1)
                oldestValidData = 0;
    
            userCounts = userCounts.slice(oldestValidData);
        }
        return userCounts;
    }
}



const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        locale: state.localization.locale
    })
);
type PropsWithRedux = ConnectedProps<typeof connector>;
export default injectIntl(connector(UsersByMonth));

