import * as network from 'utils/network';
import * as Actions from './actions';
import { PostStats, DetailedPostStats, UserCount, PlatformStats, ReadViewStatByHour, TagStats, DetailedTagStats, PostRead, TagStatsPost, CommentAndReactionCount, TopEvent, UserTrends, AnalyticsReportFilters } from './models';
import { AppThunkResult } from 'typedActions';
import { GlobalApplicationState } from 'globalApplicationState';
import { Survey } from 'modules/surveys';

export const fetchPostStats = (reportFilter: AnalyticsReportFilters): AppThunkResult => (dispatch, getState) => {
    const uri = getState().config.PostStats;

    dispatch(Actions.CreateGetPostStatsInit({}));

    return network.CreateApiRequestInit(uri, { type: network.RequestMethod.POST, body: JSON.stringify(reportFilter) }, getState)
        .then(request => {
            return fetch(request.uri, request.init);
        })
        .then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                const posts = new Array<PostStats>();
                dispatch(Actions.CreateGetPostStatsComplete({ posts, succeeded: false }));
                return undefined;
            }
        })
        .then((posts?: PostStats[] ) => {
            if (posts) {
                dispatch(Actions.CreateGetPostStatsComplete({ posts, succeeded: true }));
        }
    });
    
}

export const fetchUserCountsByMonth = (reportFilter: AnalyticsReportFilters): AppThunkResult => (dispatch, getState) => {
    const uri = getState().config.UserCounts;

    dispatch(Actions.CreateGetUserCountsInit({}));

    return network.CreateApiRequestInit(uri, { type: network.RequestMethod.POST, body: JSON.stringify(reportFilter) }, getState)
        .then(request => {
            return fetch(request.uri, request.init);
        })
        .then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                const userCounts = new Array<UserCount>();
                dispatch(Actions.CreateGetUserCountsComplete({ userCounts, succeeded: false }));
                return undefined;
            }
        })
        .then((userCounts?: UserCount[] ) => {
            if (userCounts) {
                dispatch(Actions.CreateGetUserCountsComplete({ userCounts, succeeded: true }));
        }
    });
}

export const fetchCommentAndReactionCountsByMonth = (reportFilter: AnalyticsReportFilters): AppThunkResult => (dispatch, getState) => {
    const uri = getState().config.CommentAndReactionCounts;
    dispatch(Actions.CreateGetCommentAndReactionCountsInit({}));

    return network.CreateApiRequestInit(uri, { type: network.RequestMethod.POST, body: JSON.stringify(reportFilter) }, getState)
        .then(request => {
            return fetch(request.uri, request.init);
        })
        .then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                const commentAndReactionCounts = new Array<CommentAndReactionCount>();
                dispatch(Actions.CreateGetCommentAndReactionCountsComplete({ commentAndReactionCounts, succeeded: false }));
                return undefined;
            }
        })
        .then((commentAndReactionCounts?: CommentAndReactionCount[] ) => {
            if (commentAndReactionCounts) {
                dispatch(Actions.CreateGetCommentAndReactionCountsComplete({ commentAndReactionCounts, succeeded: true }));
        }
    });
}

export const fetchPlatformStats = (reportFilter: AnalyticsReportFilters): AppThunkResult => (dispatch, getState) => {
    const uri = getState().config.PlatformStats;

    dispatch(Actions.CreateGetPlatformStatsInit({}))

    return network.CreateApiRequestInit(uri, { type: network.RequestMethod.POST, body: JSON.stringify(reportFilter) }, getState)
        .then(request => {
            return fetch(request.uri, request.init);
        })
        .then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                const platformStats = new Array<PlatformStats>();
                dispatch(Actions.CreateGetPlatformStatsComplete({ platformStats, succeeded: false }));
                return undefined;
            }
        })
        .then((platformStats?: PlatformStats[] ) => {
            if (platformStats) {
                dispatch(Actions.CreateGetPlatformStatsComplete({ platformStats, succeeded: true }));
        }
    });
}

export const fetchSurveyStats = (reportFilter: AnalyticsReportFilters): AppThunkResult => (dispatch, getState) => {
    const uri = getState().config.TopSurveys;

    dispatch(Actions.CreateGetTopSurveysInit({}))

    return network.CreateApiRequestInit(uri, { type: network.RequestMethod.POST, body: JSON.stringify(reportFilter) }, getState)
        .then(request => {
            return fetch(request.uri, request.init);
        })
        .then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                const surveys = new Array<Survey>();
                dispatch(Actions.CreateGetTopSurveysComplete({ surveys, succeeded: false }));
                return undefined;
            }
        })
        .then((surveys?: Survey[] ) => {
            if (surveys) {
                dispatch(Actions.CreateGetTopSurveysComplete({ surveys, succeeded: true }));
        }
    });
}

export const fetchTopEvents = (reportFilter: AnalyticsReportFilters): AppThunkResult => (dispatch, getState) => {
    const uri = getState().config.TopEvents;

    dispatch(Actions.CreateGetTopEventsInit({}))

    return network.CreateApiRequestInit(uri, { type: network.RequestMethod.POST, body: JSON.stringify(reportFilter) }, getState)
        .then(request => {
            return fetch(request.uri, request.init);
        })
        .then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                const events = new Array<TopEvent>();
                dispatch(Actions.CreateGetTopEventsComplete({ events, succeeded: false }));
                return undefined;
            }
        })
        .then((events?: TopEvent[] ) => {
            if (events) {
                dispatch(Actions.CreateGetTopEventsComplete({ events, succeeded: true }));
        }
    });
}

export const fetchUserTrends = (reportFilter: AnalyticsReportFilters): AppThunkResult => (dispatch, getState) => {
    const uri = getState().config.UserTrends;

    dispatch(Actions.CreateGetUserTrendsInit({}))

    return network.CreateApiRequestInit(uri, { type: network.RequestMethod.POST, body: JSON.stringify(reportFilter) }, getState)
        .then(request => {
            return fetch(request.uri, request.init);
        })
        .then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                const userTrends:UserTrends = {uniqueUserReactionCommentOnPost: 0, uniqueUserViewPostEvent: 0};
                dispatch(Actions.CreateGetUserTrendsComplete({ userTrends, succeeded: false }));
                return undefined;
            }
        })
        .then((userTrends?: UserTrends ) => {
            if (userTrends) {
                dispatch(Actions.CreateGetUserTrendsComplete({ userTrends, succeeded: true }));
        }
    });
}

export const setToFetch = (toSet: boolean): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.CreateSetToFetch({toSet}));
}

export const fetchDetailedPostStats = (postId): AppThunkResult => (dispatch, getState) => {
    const postStatsBaseUri = getState().config.PostStats + "/" + postId;

    dispatch(Actions.CreateGetDetailedPostStatsInit({ postId }));

    return network.CreateApiRequestInit(postStatsBaseUri, {
                type: network.RequestMethod.GET,
                params: { }
            }, getState).then(request => {
                return fetch(request.uri, request.init).then(response => {
                if (response.status === 200) {
                    return response.json().then((post: DetailedPostStats) => {
                        if (post) {
                            dispatch(Actions.CreateGetDetailedPostStatsComplete({ postId, post, succeeded: true }))
            
                            dispatch(Actions.CreateGetPostReadsInit({id: postId}));
            
                            return network.CreateApiRequestInit(postStatsBaseUri + "/reads", {
                                type: network.RequestMethod.GET,
                                params: { }
                                }, getState).then(readsRequest => {
                                return fetch(readsRequest.uri, readsRequest.init)
                                    .then(response => {
                                        if (response.status === 200) {
                                            return response.json().then((postReads: PostRead[]) => {
                                                if (postReads) {
                                                    dispatch(Actions.CreateGetPostReadsComplete({ id: postId, postReads, succeeded: true}));
                                                }
                                            });
                                        } else {
                                            dispatch(Actions.CreateGetPostReadsComplete({ id: postId, postReads: null, succeeded: false }));
                                        }
                                    });
                                });
                        }
                    });
                } else {
                    dispatch(Actions.CreateGetDetailedPostStatsComplete({ postId, post: null, succeeded: false }))
                }
            });
        });
}

export const fetchReadViewStats = (reportFilter: AnalyticsReportFilters): AppThunkResult => (dispatch, getState) => {
    const uri = getState().config.ReadViewStats;

    dispatch(Actions.CreateGetReadViewStatsInit({ }));

    return network.CreateApiRequestInit(uri, { type: network.RequestMethod.POST, body: JSON.stringify(reportFilter) }, getState)
        .then(request => {
            return fetch(request.uri, request.init);
        })
        .then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                const readViewStats = new Array<ReadViewStatByHour>();
                dispatch(Actions.CreateGetReadViewStatsComplete({ readViewStats, succeeded: false }));
                return undefined;
            }
        })
        .then(( readViewStats?: ReadViewStatByHour[] ) => {
            if (readViewStats) {
                dispatch(Actions.CreateGetReadViewStatsComplete({ readViewStats, succeeded: true }));
        }
    });
}


export const fetchTopChannels = (): AppThunkResult => (dispatch, getState) => {
    const uri = getState().config.TagStats;

    dispatch(Actions.CreateGetTopChannelsInit({}));

    return network.CreateApiRequestInit(uri, { type: network.RequestMethod.GET }, getState)
        .then(request => {
            return fetch(request.uri, request.init);
        })
        .then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                const tags = new Array<TagStats>();
                dispatch(Actions.CreateGetTopChannelsComplete({ tags, succeeded: false }));
                return undefined;
            }
        })
        .then(( tags?: TagStats[] ) => {
            if (tags) {
                dispatch(Actions.CreateGetTopChannelsComplete({ tags, succeeded: true }));
        }
    });
}

export const fetchTagStats = (id) => async (dispatch, getState: () => GlobalApplicationState): Promise<any> => {

    const tagStatsBaseUri = getState().config.TagStats + "/" + id;

    dispatch(Actions.CreateGetTagStatsInit({ id }));

    const detailsPromise = network.CreateApiRequestInit(tagStatsBaseUri, {
        type: network.RequestMethod.GET
    }, getState).then(detailsRequest => {
        return fetch(detailsRequest.uri, detailsRequest.init)
        .then(response => {
            if (response.status === 200) {
                return response.json() as Promise<DetailedTagStats>;
            } else {
                dispatch(Actions.CreateGetTagStatsComplete({ tagStats: null, succeeded: false }));
            }
        })
        .then((tagStats: DetailedTagStats) => {
            if (tagStats) {
                dispatch(Actions.CreateGetTagStatsComplete({ tagStats, succeeded: true }));
            }
        });
    })

    return Promise.resolve(detailsPromise);
}

export const fetchTagReadsAndPostReads = (id) => async (dispatch, getState: () => GlobalApplicationState): Promise<any> => {

    const tagStatsBaseUri = getState().config.TagStats + "/" + id;

    dispatch(Actions.CreateGetTagReadsInit({ id }));
    dispatch(Actions.CreateGetTagPostsInit({ id }));

    const readsPromise = network.CreateApiRequestInit(tagStatsBaseUri + "/reads", {
        type: network.RequestMethod.GET
    }, getState).then(readsRequest => {
        return fetch(readsRequest.uri, readsRequest.init)
        .then(response => {
            if (response.status === 200) {
                return response.json() as Promise<PostRead[]>;
            } else {
                dispatch(Actions.CreateGetTagReadsComplete({ id, postReads: null, succeeded: false }));
            }
        })
        .then((postReads: PostRead[]) => {
            if (postReads) {
                dispatch(Actions.CreateGetTagReadsComplete({ id, postReads, succeeded: true}));
            }
        });
    })

    const postsPromise = network.CreateApiRequestInit(tagStatsBaseUri + "/posts", {
        type: network.RequestMethod.GET
    }, getState).then(postsRequest => {
        return fetch(postsRequest.uri, postsRequest.init)
        .then(response => {
            if (response.status === 200) {
                return response.json() as Promise<TagStatsPost[]>;
            } else {
                dispatch(Actions.CreateGetTagPostsComplete({ id, posts: null, succeeded: false }));
            }
        })
        .then((posts: TagStatsPost[]) => {
            if (posts) {
                dispatch(Actions.CreateGetTagPostsComplete({ id, posts, succeeded: true}));
            }
        });
    });

    return Promise.all([readsPromise, postsPromise]);
}

