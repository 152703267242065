import * as Actions from './actions';
import { PostsState } from './models';
import { Reducer } from './index';


export const actionHandler: Reducer<PostsState> = {
  [Actions.FETCH_POSTS_FEED]: (state: PostsState, action: Actions.FetchPostsFeed) => {
    return {
      ...state,
      postsFeed: {
        ...state.postsFeed,
        errorMessage: "",
        fetching: true,
        filtersApplied: false,
        shouldFetch: false
      }
    };
  },

  [Actions.FETCH_POSTS_FEED_COMPLETE]: (state: PostsState, action: Actions.FetchPostsFeedComplete) => {
    return {
      ...state,
      postsFeed: {
        ...state.postsFeed,
        postsFeed: state.postsFeed.postsFeed.concat(action.postsFeed),
        canLoadMore: action.postsFeed.length === 12,
        errorMessage: action.succeeded ? "" : "Unable to fetch posts",
        fetching: false,
        filtersApplied: action.filtersApplied,
        shouldFetch: false
      }
    };
  },

  [Actions.SET_POST_FEED_FILTERS]: (state: PostsState, action: Actions.SetPostFeedFilters) => {
    return {
      ...state,
      postsFeed: {
        ...state.postsFeed,
        filters: action.filters
      }
    };
  },

  [Actions.SET_SHOW_POST_FEED_FILTERS]: (state: PostsState, action: Actions.SetShowPostFeedFilters) => {
    return {
      ...state,
      postsFeed: {
        ...state.postsFeed,
        showFilters: action.show
      }
    };
  },

  [Actions.UPDATE_POSTS_FEED]: (state: PostsState, action: Actions.UpdatePostsFeed) => {
    return {
      ...state,
      postsFeed: {
        ...state.postsFeed,
        postsFeed: action.postsFeed
      }
    };
  },

  [Actions.CLEAR_POST_FEED]: (state: PostsState, action: Actions.ClearPostFeed) => {
    return {
      ...state,
      postsFeed: {
        ...state.postsFeed,
        postsFeed: [],
        shouldFetch: true
      }
    };
  },

  [Actions.CLEAR_POST_FEED_ERROR_MESSAGE]: (state: PostsState, action: Actions.ClearPostFeedErrorMessage) => {
    return {
      ...state,
      postsFeed: {
        ...state.postsFeed,
        errorMessage: ""
      }
    };
  },

  [Actions.CLEAR_POST_FEED_FILTERS]: (state: PostsState, action: Actions.ClearPostFeedFilters) => {
    return {
      ...state,
      postsFeed: {
        ...state.postsFeed,
        filters: {
          postTypes: [],
          postStates: [],
          tags: [],
          textToSearch: ""
        },
        filtersApplied: false
      }
    }
  }
}

