import React, { useState } from "react";

import { MoreVert } from '@mui/icons-material';
import TranslateIcon from "@mui/icons-material/Translate";
import { Button, PopoverOrigin, useTheme, useMediaQuery } from "@mui/material";

import CalloutMultiselect from "modules/common/components/forms/inputs/calloutMultiselect";
import { ILanguage } from "../models";

import "../styles/languageBar.sass";

interface ILanguageProps {
    languages: Record<string, string>[];
    activeLcid: string;
    defaultLcid: string;
    selectedLanguages: ILanguage[];
    anyAvailableTranslationsCheck: boolean;
    hide?: boolean;
    onAddLanguage: (lang: Record<string, string>) => void;
    onRemoveLanguage: (lang: Record<string, string>) => void;
    onSelectLanguage: (lang: Record<string, string>) => void;
    onTranslate: () => void;
    onOpen?: () => void;
    startIcon?: JSX.Element;
    endIcon?: JSX.Element;
    anchorOrigin?: PopoverOrigin;
}

/**
 * Language selector tool bar
 */
const LanguageBar: React.FunctionComponent<ILanguageProps> = ({
    languages,
    activeLcid,
    selectedLanguages,
    defaultLcid,
    anyAvailableTranslationsCheck,
    hide = false,
    startIcon,
    endIcon,
    anchorOrigin,
    onAddLanguage,
    onRemoveLanguage,
    onTranslate,
    onOpen,
    ...restProps
}) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [calloutOpen, setCalloutOpen] = useState(false);

    const onButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (onOpen)
            onOpen();

        setAnchorEl(event.currentTarget);
        setCalloutOpen(true);
    }

    /**
     * Close popout and send langauge to parent
     */
    const onSelectLanguage = (lang: Record<string, string>) => {
        setCalloutOpen(false);
        restProps.onSelectLanguage(lang);
    }

    return (
        <>
            {!hide &&
                <div className="language-bar-container">
                    <Button disableRipple className="language-select-button" onClick={onButtonClick}>
                        {!endIcon
                            ? <>{startIcon || <MoreVert />}</>
                            : <></>}
                        <span>{languages.find((lang: Record<string, string>) => lang.key === activeLcid)?.value.toLocaleUpperCase()}</span>
                        {endIcon}
                    </Button>
                    <Button
                        variant="text"
                        color="primary"
                        size="small"
                        startIcon={<TranslateIcon />}
                        onClick={onTranslate}
                        disabled={anyAvailableTranslationsCheck}
                    >
                        TRANSLATE
                    </Button>
                    {anchorEl && <CalloutMultiselect
                        fullSize={isSmallScreen}
                        items={languages}
                        anchorOrigin={anchorOrigin}
                        anchorEl={anchorEl}
                        open={calloutOpen}
                        setOpen={setCalloutOpen}
                        defaultValue={defaultLcid}
                        activeValue={activeLcid}
                        selectedValues={selectedLanguages.map((lang: ILanguage) => lang.lcid)}
                        onAddItem={onAddLanguage}
                        onSelectItem={onSelectLanguage}
                        onRemoveItem={onRemoveLanguage}
                    />}
                </div>
            }
        </>
    );
}

export default LanguageBar;
