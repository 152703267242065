import { Action } from "redux";
import * as Actions from "./actions";

import { ActivityState, ActivityTab } from "./models";

const defaultState = {
    activeTab: ActivityTab.FlaggedItems
} as ActivityState;

const actionHandler: { [actionType: string]: (state: ActivityState, action: Action) => ActivityState } = {

    [Actions.SET_ACTIVE_ACTIVITY_TAB]: (state: ActivityState, action: Actions.SetActiveActivityTab) => {
        return {
            ...state,
            activeTab: action.activeTab
        }
    }
}

export const reducer = (state: ActivityState, action: Action) => {

    const actionHandlerMethod = actionHandler[action.type];
    if (actionHandlerMethod) {
        return actionHandlerMethod(state, action);
    }

    return state || defaultState;
}
