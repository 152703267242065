import React from "react";

import { AudienceGroup } from "../../../models";

import IconButton from "@mui/material/IconButton";

import ClearIcon from "@mui/icons-material/Clear";


const GroupList: React.FunctionComponent<ComponentProps> = props => {
  if (!props.includedGroups.length)
    return <div className="no-search-results">No {props.syncType} group has been added</div>;

  return (
    <div className="audience-user-list">
      {props.includedGroups.map((group) => (
        <div key={group.groupId}>
          <div className="user-suggestion">
            <div>{group.name}</div>
          </div>
          <div>
            <IconButton size="small" onClick={() => props.onRemoveGroup(group.groupId)} className="remove-icon"><ClearIcon /></IconButton>
          </div>
        </div>
      ))}
    </div>
  );
}

interface ComponentProps {
  includedGroups: AudienceGroup[];
  syncType: string;
  onRemoveGroup: (id: string) => void;
}

export default GroupList;