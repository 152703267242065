import { useEffect, useRef } from "react";

/**
 * Custom hook to read previous values
 */
const usePrevious = <T extends unknown>(value: T): T | undefined => {
  const ref = useRef<T>();
    useEffect(() => {
      ref.current = value;
  });

  return ref.current;
};

export default usePrevious;
