import * as React from 'react';
import * as dom from 'react-dom';

interface componentProps {
    cropPoints: number[];
    src: string;
    height?: number;
    width?: number;
    onClick?: () => void;
}
interface componentState { }

export default class CanvasPreviewImage extends React.Component<componentProps, componentState> {
    private canvasElement: HTMLCanvasElement

    public componentDidMount() {
        // after the canvas is added to the page, force update to
        // trigger drawing the image on the canvas.
        this.drawImageOnCanvas();
    }

    public componentDidUpdate(prevProps: componentProps, prevState: componentState) {
        if (prevProps.height !== this.props.height
            || prevProps.width !== this.props.width
            || prevProps.src !== this.props.src
            || JSON.stringify(prevProps.cropPoints) !== JSON.stringify(this.props.cropPoints))
            this.drawImageOnCanvas();
    }

    public render() {
        return <canvas onClick={this.props.onClick} ref={el => this.canvasElement = el!} />
    }

    private drawImageOnCanvas() {
        let canvas = dom.findDOMNode(this.canvasElement) as HTMLCanvasElement;
        let image = new Image();

        canvas.width = this.props.width  || canvas.clientWidth;
        canvas.height = this.props.height || canvas.clientHeight;

        this.drawTextInCenterOfCanvas(canvas, "loading...");

        image.addEventListener("load", (e) => {
            let ctx = canvas.getContext('2d')!;
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            let srcHeight = image.naturalHeight;
            let srcWidth = image.naturalWidth;

            let cropPoints = !!this.props.cropPoints && this.props.cropPoints.length === 4 ?
                this.props.cropPoints : [0, 0, srcWidth, srcHeight]

            let croppedX = Number(cropPoints[0]);
            let croppedY = Number(cropPoints[1]);
            let croppedWidth = Number(cropPoints[2]) - croppedX;
            let croppedHeight = Number(cropPoints[3]) - croppedY;

            this.drawImageInMultipleParts(ctx!, image, croppedX, croppedY, croppedWidth, croppedHeight, 0, 0, canvas.width, canvas.height);
        });

        image.onerror = (e) => {
            this.drawTextInCenterOfCanvas(canvas, "Error loading image. Please refresh the page.")
        }

        image.src = this.props.src;
    }

    private drawTextInCenterOfCanvas(canvas: HTMLCanvasElement, text: string) {
        let ctx = canvas.getContext('2d')!;
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.font = '16px Open Sans';
        ctx.textAlign = 'center';
        ctx.fillStyle = '#333';
        ctx.fillText(text, canvas.width / 2, canvas.height / 2);
    }

    /*
    * Internet explorer and Edge seem to have difficulty rendering large images on canvas,
    * particularly on canvases that are smaller than the image. Both browsers throw an IndexSizeError.
    * The solution found is to break the image in multiple parts and draw each part individually.
    * Since there is no technical limit to the size of the image (cropping only constrains aspect ratio)
    * the number of parts needs to be dynamic to allow for larger images.  The 400 pixels increment that
    * was chosen was an abritrary number that seems to make the image render in all browsers.
    */
    private drawImageInMultipleParts(context: CanvasRenderingContext2D, image: HTMLImageElement, srcOffsetX: number, srcOffsetY: number, srcWidth: number, srcHeight: number, targetOffsetX: number, targetOffsetY: number, targetWidth: number, targetHeight: number) {
        let increment = 400;
        let xParts = Math.ceil((srcWidth - srcOffsetX) / increment);
        let yParts = Math.ceil((srcHeight - srcOffsetY) / increment);

        // its possible if the cropped area is small enough or to the right the number will be negative
        xParts = !!xParts && xParts > 0 ? xParts : 1
        yParts = !!yParts && yParts > 0 ? yParts : 1

        let partWidth = srcWidth / xParts;
        let partHeight = srcHeight / yParts;

        /*
         * image src width and height is different from image target width and height
         * because the image may be a much higher resolution so its partWidth might
         * be 200px but it might need to be squished to a targetWidth of 60px
         */
        for (let i = 0; i < xParts; i++) {
            for (let j = 0; j < yParts; j++) {
                context.drawImage(image,
                    // image parts: xOffset, yOffset, partWidth, partHeight
                    srcOffsetX + (partWidth * (i)), srcOffsetY + (partHeight * (j)), partWidth, partHeight,
                    // target positions: xOffset, yOffset, targetPartWidth, taretPartHeight,
                    targetOffsetX + (targetWidth / xParts) * (i), targetOffsetY + (targetHeight / yParts) * (j), (targetWidth / xParts), (targetHeight / yParts)
                );
            }
        }
    }
}
