import React, { useState } from "react";

import { AppBar, Button, ButtonGroup, Dialog, IconButton, Slide, SlideProps, Toolbar, useMediaQuery, useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { Launch, InfoOutlined } from "@mui/icons-material";

import DesktopPreview from "../desktopPreview";
import LoadingOverlay from "../../loadingOverlay";
import { TeamsPreview } from "../teamsPreview";
import { MobilePreview } from "../mobilePreview";
import { MobileAppPages } from "modules/contentBands/models";

import "../styles/preview.sass";
import { GlobalApplicationState } from "globalApplicationState";
import Disclaimer from "../../disclaimer";

export enum PreviewType {
    Mobile,
    Desktop,
    Teams,
}

export interface IPreview {
    label: string;
    component: JSX.Element;
    type: PreviewType;
}

interface IPreviewProps {
    open: boolean;
    previews?: IPreview[];
    editLabel?: string;
    openLabel?: string;
    publishLabel?: string;
    extraLabel?: string;
    extraLabelStartIcon?: JSX.Element;
    activePage?: MobileAppPages;
    appName?: string;
    onClose: () => void;
    openAction?: () => void;
    editAction?: () => void;
    publishAction?: () => void;
    extraAction?: () => void;
    isLoading?: boolean;
}

const Transition = React.forwardRef(function Transition(props: SlideProps, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Preview: React.FunctionComponent<IPreviewProps> = ({ isLoading = false, activePage = MobileAppPages.Home, previews, ...props }) => {
    const theme = useTheme();
    const isSmallAndSmaller = useMediaQuery(theme.breakpoints.down("sm"), { noSsr: true });
    const previewsToUse = isSmallAndSmaller ? previews?.filter((preview: IPreview) => preview.type !== PreviewType.Teams) : previews;

    const [activePreview, setActivePreview] = useState<number>(0);
    const { displayName, mobileEventsEnabled } = useSelector((state: GlobalApplicationState) => state.settings.tenantSettings);

    const getActivePreview = (): JSX.Element => {
        const active = previewsToUse && previewsToUse[activePreview];

        switch (active?.type) {
            case PreviewType.Mobile:
                return <MobilePreview activePage={activePage}>{active.component}</MobilePreview>;
            case PreviewType.Desktop:
                return <DesktopPreview>{active.component}</DesktopPreview>;
            case PreviewType.Teams:
                return (
                    <TeamsPreview eventsEnabled={mobileEventsEnabled} displayName={displayName}>
                        {active.component}
                    </TeamsPreview>
                );
            default:
                return <></>;
        }
    };

    return (
        <>
            <Dialog
                TransitionComponent={Transition}
                fullScreen
                maxWidth={false}
                open={props.open}
                onClose={props.onClose}
                disableEscapeKeyDown={isLoading}
            >
                <LoadingOverlay show={isLoading} />
                <AppBar
                    className="preview-appbar"
                    elevation={0}
                    style={{
                        backgroundColor: "#ffffff",
                    }}
                >
                    <Toolbar className="preview-toolbar">
                        <p className="preview-title">Preview</p>
                        {previewsToUse && previewsToUse.length > 1 && (
                            <ButtonGroup className="preview-pill">
                                {previewsToUse.map((prvw: IPreview, idx: number) => (
                                    <Button
                                        id={`preview-active-btn-${prvw.label}`}
                                        key={prvw.label}
                                        variant={idx === activePreview ? "contained" : "outlined"}
                                        color="primary"
                                        onClick={() => setActivePreview(idx)}
                                    >
                                        {prvw.label}
                                    </Button>
                                ))}
                            </ButtonGroup>
                        )}
                        <div className="preview-actions">
                            {props.openAction && props.openLabel && (
                                <Button
                                    id="preview-open-action"
                                    disabled={isLoading}
                                    className="action"
                                    endIcon={<Launch />}
                                    variant="text"
                                    color="primary"
                                    onClick={props.openAction}
                                >
                                    {props.openLabel}
                                </Button>
                            )}
                            {props.editAction && props.editLabel && (
                                <Button
                                    id="preview-edit-action"
                                    disabled={isLoading}
                                    className="action"
                                    color="primary"
                                    variant="outlined"
                                    onClick={props.editAction}
                                >
                                    {props.editLabel}
                                </Button>
                            )}
                            {props.publishAction && props.publishLabel && (
                                <Button
                                    id="preview-publish-action"
                                    disabled={isLoading}
                                    className="action"
                                    color="primary"
                                    variant="contained"
                                    onClick={props.publishAction}
                                >
                                    {props.publishLabel}
                                </Button>
                            )}
                            {props.extraAction && props.extraLabel && (
                                <Button
                                    disabled={isLoading}
                                    className="action"
                                    startIcon={props.extraLabelStartIcon}
                                    variant="outlined"
                                    color="primary"
                                    onClick={props.extraAction}
                                >
                                    {props.extraLabel}
                                </Button>
                            )}
                            <IconButton
                                id="preview-close-action"
                                disabled={isLoading}
                                edge="end"
                                color="inherit"
                                onClick={() => props.onClose()}
                                aria-label="close"
                                size="large"
                            >
                                <CloseIcon htmlColor="#7f7f7f" />
                            </IconButton>
                        </div>
                    </Toolbar>
                </AppBar>
                <div className="preview-content">
                    {isSmallAndSmaller && previews && previews.some((preview: IPreview) => preview.type === PreviewType.Teams) && (
                        <Disclaimer
                            text="MS teams preview is available on desktop and wider screens only."
                            icon={<InfoOutlined htmlColor="#e6911a" />}
                            containerStyle={{
                                width: 407,
                                marginBottom: 20,
                                boxSizing: "border-box",
                            }}
                        />
                    )}
                    {getActivePreview()}
                    {previews && previews.length > 0 && <p className="preview-help">Preview varies based on device and/or platform.</p>}
                    {props.children}
                </div>
            </Dialog>
        </>
    );
};

export default Preview;
