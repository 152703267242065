import * as React from "react";

import PublicIcon from '@mui/icons-material/Public';
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { Link } from "@mui/material";


class Location extends React.Component<ComponentProps, ComponentState> {
  public render() {
    if (!this.props.location && !this.props.locationLink && !this.props.locationLinkLabel)
      return <React.Fragment></React.Fragment>;

    return (
      <React.Fragment>
        {!!this.props.location &&
          <div className="info-field">
            <div className="info-icon">
              <LocationOnOutlinedIcon fontSize="small" />
            </div>
            <div>{this.props.location}</div>
          </div>
        }
        
        {!!this.props.locationLinkLabel && !!!this.props.locationLink &&
          <div className="info-field">
          <div className="info-icon">
            <PublicIcon fontSize="small" />
          </div>
          <div> {this.props.locationLinkLabel}</div>
          </div>
        }

        {!!this.props.locationLink && !!!this.props.locationLinkLabel &&
          <div className="info-field">
            <div className="info-icon">
              <PublicIcon fontSize="small" />
            </div>
            <div> {<Link href={this.props.locationLink} target="_blank" >{this.props.locationLink}</Link>}</div>
            </div>
        }

        {!!this.props.locationLink && !!this.props.locationLinkLabel &&
          <div className="info-field">
            <div className="info-icon">
              <PublicIcon fontSize="small" />
            </div>
            <div> <Link href={this.props.locationLink} target="_blank" >{this.props.locationLinkLabel}</Link></div>
            </div>
        }

      </React.Fragment>
    );
  }
}


interface ComponentProps {
  location: string | undefined;
  locationLink: string | undefined;
  locationLinkLabel: string | undefined;
}

interface ComponentState { }

export default Location;