import React, { useState } from 'react';
import { Audience } from '../models';
import './audienceSidebarSelector.sass';

interface ComponentProps {
    applyAudienceChanges: any;
    previousAudiences: string[];
    userAudiences: Audience[];
}

const AudienceSidebarSelector: React.FunctionComponent<ComponentProps> = ( {userAudiences, applyAudienceChanges, previousAudiences} ) => {
    const [selectedAudiences, setSelectedAudiences] = useState<string[]>(previousAudiences);
    const pristineAudiences = previousAudiences;

    const audienceSelector = userAudiences.filter((audience) => audience.enabled).map((filteredAudience) => {
        return (
            <div className="audience-checkbox" key={filteredAudience.id}>
                <input  
                    type="checkbox" 
                    id={`${filteredAudience.id}`} 
                    name={`${filteredAudience.displayName}`}
                    checked={selectedAudiences.includes(filteredAudience.id)}
                    onChange={() => modifySelectedAudiences(filteredAudience.id)} />
                <label htmlFor={`${filteredAudience.id}`}>
                    <span className="audience-displayname">{filteredAudience.displayName}</span>
                </label>
            </div>
        )
    })
    
    
    const modifySelectedAudiences = (id: string) => {
        /* We either remove or add an audience on click */
        if (selectedAudiences.includes(id)) {
            setSelectedAudiences(selectedAudiences.filter(audience => audience !== id));
        } else {
            setSelectedAudiences(prev => [...prev, id])
        }
    }

    const reset = () => {
        setSelectedAudiences(pristineAudiences);
    }
    return (
        <div className="audience-sidebar">
            <div className="sidebar-header">
                <p style={{float: "left", margin:"5px 0 5px"}}>Select Audiences</p>
                <button className="apply-btn-2" style={{float: "right"}} onClick={() => applyAudienceChanges(selectedAudiences)}>APPLY</button>
                <button className="reset-btn-no-border" style={{float: "right", marginRight: 10}} onClick={() => reset()}>RESET</button>
            </div>
            <hr style={{width:428, overflow: "visible", marginLeft: -15, borderTop:"0px solid black"}}/>
            {audienceSelector}
        </div>
    )
}

export default AudienceSidebarSelector;
