import * as Actions from './actions';
import { PostsState, EmptyDefaultPost, EditorState } from './models';
import { Reducer } from './index';

import * as Validation from "./components/post-creation/validation";


export const actionHandler: Reducer<PostsState> = {

    [Actions.CREATE_NEW_DRAFT]: (state: PostsState, action: Actions.CreateNewDraft) => {
        return { ...state, creatingNewPost: true }
    },

    [Actions.CREATE_NEW_DRAFT_COMPLETE]: (state: PostsState, action: Actions.CreateNewDraftComplete) => {
        return {
            ...state,
            creatingNewPost: false,
            shouldFetch: action.succeeded
        };
    },

    [Actions.SET_NEW_DRAFT]: (state: PostsState, action: Actions.SetNewDraft) => {
        const validationChecks = Validation.getValidationChecks(action.post);

        return {
            ...state,
            editor: {
                ...state.editor,
                post: action.post,
                fetching: false,
                isValid: Validation.isValid(validationChecks),
                validationChecks: validationChecks
            }
        };
    },

    [Actions.FETCH_DRAFT_POST]: (state: PostsState, action: Actions.FetchDraftPost) => {
        let editorState = {
            ...state.editor,
            post: { },
            fetching: true,
        } as EditorState;

        return { ...state, editor: editorState }
    },

    [Actions.FETCH_DRAFT_POST_COMPLETE]: (state: PostsState, action: Actions.FetchDraftPostComplete) => {
        let postDefaults = {...EmptyDefaultPost};
        let stringProperties = Object.keys(postDefaults).filter(k => typeof postDefaults[k] === 'string')
        let translatedContent = (!!action.post && action.post.translatedContent) ? action.post.translatedContent :
            (state.editor.post.translatedContent || postDefaults.translatedContent);
        let editorState = {
            ...state.editor,
            post: { ...action.post, translatedContent: {...translatedContent} },
            fetching: false,
        } as EditorState;

        // dont allow possible null values in the editor; default to empty string
        stringProperties.forEach((value, index) => {
            if(editorState.post[value] == null)
                editorState.post[value] = ""
        });

        let tc = editorState.post.translatedContent!;
        for(let lang of Object.keys(tc || {})){
            for(let field of Object.keys(tc[lang])){
                if(tc[lang][field] === null)
                    tc[lang][field] = ''
            }
        }

        const validationChecks = Validation.getValidationChecks(editorState.post);

        return {
            ...state,
            editor: {
                ...editorState,
                isValid: Validation.isValid(validationChecks),
                validationChecks: validationChecks
            }
        };
    },

    [Actions.CLEAR_EDITOR]: (state: PostsState, action: Actions.ClearEditor) => {
        return { ...state, editor: { post: {} } as EditorState };
    },

    [Actions.UPDATE_POST]: (state: PostsState, action: Actions.UpdatePost) => {
        const post = {
            ...state.editor.post,
            ...action.post
        };

        const validationChecks = Validation.getValidationChecks(post);

        return {
            ...state,
            editor: {
                ...state.editor,
                changedSinceSaved: true,
                post: post,
                isValid: Validation.isValid(validationChecks),
                validationChecks: validationChecks
            }
        };
    },

    [Actions.POST_EDIT_FIELD_UPDATE]: (state: PostsState, action: Actions.FieldUpdate) => {
        const post = {
            ...state.editor.post,
            [action.field]: action.value
        };

        const validationChecks = Validation.getValidationChecks(post);

        return {
            ...state,
            editor: {
                ...state.editor,
                changedSinceSaved: true,
                post: post,
                isValid: Validation.isValid(validationChecks),
                validationChecks: validationChecks
            }
        };
    },

    [Actions.DRAFT_POST_SAVE]: (state: PostsState, action: Actions.DraftPostSave) => {
        return { ...state, editor: { ...state.editor, saving: true } };
    },

    [Actions.DRAFT_POST_SAVE_COMPLETE]: (state: PostsState, action: Actions.DraftPostSaveComplete) => {
        return { ...state, editor: { ...state.editor, saving: false, changedSinceSaved: false } };
    },

    [Actions.IMAGE_SELECTED]: (state: PostsState, action: Actions.ImageSelected) => {
        return {
            ...state,
            editor: {
                ...state.editor,
                image: {
                    id: action.imageId,
                    url: action.imageUrl,
                    transforms: { points: [] }
                }
            }
        }
    },

    // [Actions.DOCUMENT_SELECTED]: (state: PostsState, action: Actions.DocumentSelected) => {
    //     return {
    //         ... state,
    //         editor: {
    //             ...state.editor,
    //             file: {
    //                 id: action.docId,
    //                 url: action.docUrl,
    //             }
    //         }
    //     }
    // },

    [Actions.IMAGE_TRANSFORMED]: (state: PostsState, action: Actions.ImageTransformed) => {
        return {
            ...state,
            editor: {
                ...state.editor,
                image: {
                    ...state.editor.image,
                    transforms: action.transforms
                }
            }
        } as PostsState;
    },

    [Actions.IMAGE_TWEAK_CONFIRMED]: (state: PostsState, action: Actions.ImageTweaksConfirmed) => {
        return {
            ...state,
            editor: {
                ...state.editor,
                post: {
                    ...state.editor.post,
                    image: { ...state.editor.image }
                },
                image: {
                    id: "",
                    url: "",
                    transforms: { points: [] }
                },
                changedSinceSaved: true
            }
        } as PostsState;
    },

    [Actions.CANCEL_IMAGE_CHANGES]: (state: PostsState, action: Actions.CancelImageChanges) => {
        return {
            ...state,
            editor: {
                ...state.editor,
                image: {
                    id: "",
                    url: "",
                    transforms: { points: [] }
                }
            }
        }
    },

    [Actions.DELETE_EDITOR_POST_IMAGE]: (state: PostsState, action: Actions.DeleteEditorPostImage) => {
        return {
            ...state,
            editor: {
                ...state.editor,
                post: {
                    ...state.editor.post,
                    image: {
                        id: "",
                        url: "",
                        transforms: { points: [] }
                    },
                    video: {
                        id: "",
                        url: ""
                    }
                },
                changedSinceSaved: true
            }
        }
    },

    [Actions.EDIT_PLACED_IMAGE]: (state: PostsState, action: Actions.EditPlacedImage) => {
        let currentPostImage = !!state.editor.post.image ? state.editor.post.image : { id: "", url: "", transforms: { points: [] } }
        return {
            ...state,
            editor: {
                ...state.editor,
                image: { ...currentPostImage },
                changedSinceSaved: true
            }
        }
    },

    [Actions.SET_POST_TYPE]: (state: PostsState, action: Actions.SetPostType) => {
        return {
            ...state,
            editor: {
                ...state.editor,
                post: {
                    ...state.editor.post,
                    postType: action.postType,
                    compliance: action.postType === "compliance",
                    mandatory: action.postType === "mandatory",
                    public: action.postType === "public"
                },
                changedSinceSaved: true
            }
        }
    },

    [Actions.SET_CHANGED_SINCE_SAVE]: (state: PostsState, action: Actions.SetChangedSinceSave) => {
        return {
            ...state,
            editor: {
                ...state.editor,
                changedSinceSaved: action.changed
            }
        }
    },

    [Actions.SET_SAVING]: (state: PostsState, action: Actions.SetSaving) => {
        return { ...state, editor: {...state.editor, saving: action.saving} };
    },

    [Actions.SET_NOTIFICATIONS_ENABLED]: (state: PostsState, action: Actions.SetNotificationsEnabled) => {
        return {
            ...state,
            editor: {
                ...state.editor,
                post: {
                    ...state.editor.post,
                    notificationsEnabled: action.enabled,
                }
            }
        }
    },

    [Actions.SET_COMMENTING_ENABLED]: (state: PostsState, action: Actions.SetCommentingEnabled) => {
        return {
            ...state,
            editor: {
                ...state.editor,
                post: {
                    ...state.editor.post,
                    commentingEnabled: action.enabled,
                }
            }
        }
    },

    [Actions.SET_REACTING_ENABLED]: (state: PostsState, action: Actions.SetReactingEnabled) => {
        return {
            ...state,
            editor: {
                ...state.editor,
                post: {
                    ...state.editor.post,
                    reactingEnabled: action.enabled,
                }
            }
        }
    },

    // [Actions.SET_EMAIL_CHANNEL_ENABLED]: (state: PostsState, action: Actions.SetEmailChannelEnabled) => {
    //     return {
    //         ...state,
    //         editor: {
    //             ...state.editor,
    //             post: {
    //                 ...state.editor.post,
    //                 emailNotifications: {
    //                     ...state.editor.post.emailNotifications,
    //                     enabled: action.enabled
    //                 }
    //             }
    //         }
    //     }
    // },

    // [Actions.SET_SMS_CHANNEL_ENABLED]: (state: PostsState, action: Actions.SetSMSChannelEnabled) => {
    //     return {
    //         ...state,
    //         editor: {
    //             ...state.editor,
    //             post: {
    //                 ...state.editor.post,
    //                 smsNotifications: {
    //                     ...state.editor.post.smsNotifications,
    //                     enabled: action.enabled
    //                 }
    //             }
    //         }
    //     }
    // },

    // [Actions.SET_EMAIL_NOTIFICATION_FREQUENCY]: (state: PostsState, action: Actions.SetEmailNotificationFrequency) => {
    //     return {
    //         ...state,
    //         editor: {
    //             ...state.editor,
    //             post: {
    //                 ...state.editor.post,
    //                 emailNotifications: {
    //                     ...state.editor.post.emailNotifications,
    //                     frequency: action.frequency
    //                 }
    //             }
    //         }
    //     }
    // },

    // [Actions.SET_SMS_NOTIFICATION_HOURS]: (state: PostsState, action: Actions.SetSMSNotificationHours) => {
    //     return {
    //         ...state,
    //         editor: {
    //             ...state.editor,
    //             post: {
    //                 ...state.editor.post,
    //                 smsNotifications: {
    //                     ...state.editor.post.smsNotifications,
    //                     hours: action.hours
    //                 }
    //             }
    //         }
    //     }
    // },

    // [Actions.SET_SMS_NOTIFICATION_FREQUENCY]: (state: PostsState, action: Actions.SetSMSNotificationFrequency) => {
    //     return {
    //         ...state,
    //         editor: {
    //             ...state.editor,
    //             post: {
    //                 ...state.editor.post,
    //                 smsNotifications: {
    //                     ...state.editor.post.smsNotifications,
    //                     frequency: action.frequency
    //                 }
    //             }
    //         }
    //     }
    // },

    // [Actions.SET_EMAIL_NOTIFICATION_HOURS]: (state: PostsState, action: Actions.SetEmailNotificationHours) => {
    //     return {
    //         ...state,
    //         editor: {
    //             ...state.editor,
    //             post: {
    //                 ...state.editor.post,
    //                 emailNotifications: {
    //                     ...state.editor.post.emailNotifications,
    //                     hours: action.hours
    //                 }
    //             }
    //         }
    //     }
    // },

    [Actions.SET_MOBILE_NOTIFICATIONS_ENABLED]: (state: PostsState, action: Actions.SetMobileNotificationsEnabled) => {
        return {
            ...state,
            editor: {
                ...state.editor,
                post: {
                    ...state.editor.post,
                    mobileNotificationsEnabled: action.enabled
                }
            }
        }
    },

    [Actions.SET_CHATBOT_NOTIFICATIONS_ENABLED]: (state: PostsState, action: Actions.SetChatbotNotificationsEnabled) => {
        return {
            ...state,
            editor: {
                ...state.editor,
                post: {
                    ...state.editor.post,
                    chatbotNotificationsEnabled: action.enabled
                }
            }
        }
    },


    [Actions.GET_DRAFT_TRANSLATION]: (state: PostsState, action: Actions.GetDraftTranslation) => {
        return {
          ...state,
        //   isFetching: true
        }
      },

      [Actions.GET_DRAFT_TRANSLATION_COMPLETE]: (state: PostsState, action: Actions.GetDraftTranslationComplete) => {
        return {
          ...state,
          errorMessage: action.succeeded ? "" : "Unable to load draft translation",
        //   isFetching: false
        }
      },


}
