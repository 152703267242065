import {
    Action,
    createAsyncThunk,
    createSlice,
    isRejected,
    isFulfilled,
    isPending
} from "@reduxjs/toolkit";

import { HomeScreenPlatform, HomeScreenType, HomeScreensStateStatus, IHomeScreenConfig, IHomeScreensState } from "./models";
import { homeScreenConfigApi } from "api/instances";
import { ISetSuccessMessageAction } from "modules/contentBands/reducer";

const HOME_SCREENS_DEFAULT_STATE: IHomeScreensState = {
    homeScreenConfigs: undefined,
    pristineConfigs: undefined,
    status: HomeScreensStateStatus.Idle,
    successMessage: undefined,
};

export interface IBaseUpdateHomeScreenConfigPayload {
    platform: HomeScreenPlatform;
}

export interface ISetHomeScreenTypePayload extends IBaseUpdateHomeScreenConfigPayload {
    homeScreenType: HomeScreenType;
}

export interface ISetHomeScreenTypeAction extends Action {
    payload: ISetHomeScreenTypePayload;
}

export interface ISetShowFeedBelowPayload extends IBaseUpdateHomeScreenConfigPayload {
    show: boolean;
}

export interface ISetShowFeedBelowAction extends Action {
    payload: ISetShowFeedBelowPayload;
}

export interface ISetFeedHeaderTextPayload extends IBaseUpdateHomeScreenConfigPayload {
    text: string;
}

export interface ISetFeedHeaderTextAction extends Action {
    payload: ISetFeedHeaderTextPayload;
}

export interface ISetHomeScreenConfigsAction extends Action {
    payload: IHomeScreenConfig[];
}

export interface IResetConfigAction extends Action { }

export const fetchHomeScreenConfigs = createAsyncThunk("FETCH_HOME_SCREEN_CONFIGS", async (_, thunkApi) => {
    try {
        const { data } = await homeScreenConfigApi.getHomeScreenConfigs(null);

        thunkApi.dispatch({ type: homeScreensSlice.actions.SET_HOME_SCREEN_CONFIGS, payload: data });
        thunkApi.dispatch({ type: homeScreensSlice.actions.SET_PRISTINE_CONFIGS, payload: data });
    } catch (err) {
        throw err;
    }
});

export const saveHomeScreenConfigs = createAsyncThunk("SAVE_HOME_SCREEN_CONFIGS", async (payload: IHomeScreenConfig[], thunkApi) => {
    try {
        await homeScreenConfigApi.saveHomeScreenConfigs(payload);

        thunkApi.dispatch({ type: homeScreensSlice.actions.SET_PRISTINE_CONFIGS, payload });
        thunkApi.dispatch({
            type: homeScreensSlice.actions.SET_SUCCESS_MESSAGE,
            payload: "Successfully saved changes and updated your home screens.",
        });
    } catch (err) {
        throw err;
    }
});

const getHomeScreenConfigsList = (state: IHomeScreensState) => state?.homeScreenConfigs || [];

export const homeScreensSlice = createSlice({
    name: "homeScreens",
    initialState: HOME_SCREENS_DEFAULT_STATE,
    reducers: {
        SET_HOME_SCREEN_CONFIGS: (state: IHomeScreensState, action: ISetHomeScreenConfigsAction) => {
            state.homeScreenConfigs = action.payload;
        },
        SET_PRISTINE_CONFIGS: (state: IHomeScreensState, action: ISetHomeScreenConfigsAction) => {
            state.pristineConfigs = action.payload;
        },
        RESET_CONFIGS: (state: IHomeScreensState, _: IResetConfigAction) => {
            state.homeScreenConfigs = state.pristineConfigs;
        },
        SET_HEADER_TEXT: (state: IHomeScreensState, action: ISetFeedHeaderTextAction) => {
            state.homeScreenConfigs = getHomeScreenConfigsList(state).map((c: IHomeScreenConfig) =>
                c.platform === action.payload.platform
                    ? {
                        ...c,
                        feedHeader: action.payload.text,
                    }
                    : c
            );
        },
        SET_SHOW_FEED_BELOW: (state: IHomeScreensState, action: ISetShowFeedBelowAction) => {
            state.homeScreenConfigs = getHomeScreenConfigsList(state).map((c: IHomeScreenConfig) =>
                c.platform === action.payload.platform
                    ? {
                        ...c,
                        showMyFeedBelowContentBands: action.payload.show,
                        // clear feedHeader if turned off
                        feedHeader: action.payload.show ? c.feedHeader : "",
                    }
                    : c
            );
        },
        SET_HOME_SCREEN_TYPE: (state: IHomeScreensState, action: ISetHomeScreenTypeAction) => {
            state.homeScreenConfigs = getHomeScreenConfigsList(state).map((c: IHomeScreenConfig) =>
                c.platform === action.payload.platform
                    ? {
                        ...c,
                        homeScreenType: action.payload.homeScreenType,
                    }
                    : c
            );
        },
        SET_SUCCESS_MESSAGE: (state: IHomeScreensState, action: ISetSuccessMessageAction) => {
            state.successMessage = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(isPending, (state: IHomeScreensState) => {
                state.status = HomeScreensStateStatus.Loading;
            })
            .addMatcher(isFulfilled, (state: IHomeScreensState) => {
                state.status = HomeScreensStateStatus.Succeeded;
            })
            .addMatcher(isRejected, (state: IHomeScreensState) => {
                state.status = HomeScreensStateStatus.Failed;
            });
    },
});
