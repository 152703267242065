import React, { useState } from "react";

import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import Typography from "@mui/material/Typography";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";


interface InputFieldProps {
  description?: string;
  label: string;
  value: string;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (event: any) => void;
  error?: boolean;
}

const PasswordInputField: React.FunctionComponent<InputFieldProps> = props => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="section-field">
      <div className="section-input-field-heading">
        <label>{props.label}</label>
      </div>
      <div className="section-input-field">
        <FormControl variant="outlined" size="small" fullWidth>
          <OutlinedInput
            error={props.error}
            type={showPassword ? "text" : "password"}
            value={props.value}
            placeholder={props.placeholder}
            fullWidth
            autoComplete="off"
            onChange={(event) => {
              if (!!props.onChange && event.target.value !== props.value)
                props.onChange(event);
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={(event) => event.preventDefault()}
                  size="large">
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            }
          />
          {props.description &&
            <Typography variant="caption">{props.description}</Typography>
          }
        </FormControl>
      </div>
    </div>
  );
}

export default PasswordInputField;