import * as React from "react"

import BasePage from "pages/common/basePage";
import Header from "pages/common/header";
import MainContent from "pages/common/mainContent";
import NewTenant from "./action-buttons/newTenant";
import TenantListing from "./tenant-listing/tenantListing";


class TenantManagementPage extends React.Component<{}, {}> {
  public render() {
    return (
      <BasePage fullWidth>
        <Header
          title="Manage Tenants"
          rightComponent={
            <React.Fragment>
              <NewTenant />
            </React.Fragment>
          }
        />
        <MainContent>
          <TenantListing />
        </MainContent>
      </BasePage>
    )
  }
}


export default TenantManagementPage;