import * as React from 'react';

interface PlaceholderWidgetProps {
    title?: string;
    height: number;
}

export default class PlaceholderWidget extends React.PureComponent<PlaceholderWidgetProps, {}> {
    public render() {
        return <div className="analytics-widget placeholder" >
                <header>{this.props.title}</header>
                { this.props.children }
                <footer>
                </footer>
            </div>
    }
}
