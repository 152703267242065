import { Action } from 'redux';
import * as Actions from './actions';
import { GalleryState, DocumentGalleryPage, ImageGalleryPage } from './models';


const defaultDocumentGalleryPage: DocumentGalleryPage = {
  currentPage: 0,
  documents: [],
  totalDocuments: 0,
  totalPages: 0,
  isFetching: false,
  shouldFetch: true
};

const defaultImageGalleryPage: ImageGalleryPage = {
  currentPage: 0,
  images: [],
  totalImages: 0,
  totalPages: 0,
  isFetching: false,
  shouldFetch: true
};

const defaultState: GalleryState = {
  documentGallery: { ...defaultDocumentGalleryPage },
  imageGallery: { ...defaultImageGalleryPage },
  isFetching: false,
  isUploading: false
};

const actionHandler: { [actionType: string]: (state: GalleryState, action: Action) => GalleryState } = {

  [Actions.GET_DOCUMENT_GALLERY]: (state: GalleryState, action: Actions.GetDocumentGallery) => {
    return {
      ...state,
      documentGallery: {
        ...state.documentGallery,
        isFetching: true
      }
    }
  },

  [Actions.GET_DOCUMENT_GALLERY_COMPLETE]: (state: GalleryState, action: Actions.GetDocumentGalleryComplete) => {
    return {
      ...state,
      documentGallery: {
        ...state.documentGallery,
        ...action,
        isFetching: false,
        shouldFetch: !action.succeeded
      }
    }
  },

  [Actions.GET_IMAGE]: (state: GalleryState, action: Actions.GetImage) => {
    return {
      ...state,
      isFetching: true
    };
  },

  [Actions.GET_IMAGE_COMPLETE]: (state: GalleryState, action: Actions.GetImageComplete) => {
    return {
      ...state,
      isFetching: false
    };
  },

  [Actions.GET_IMAGE_GALLERY]: (state: GalleryState, action: Actions.GetImageGallery) => {
    return {
      ...state,
      imageGallery: {
        ...state.imageGallery,
        isFetching: true
      }
    };
  },

  [Actions.GET_IMAGE_GALLERY_COMPLETE]: (state: GalleryState, action: Actions.GetImageGalleryComplete) => {
    return {
      ...state,
      imageGallery: {
        ...action,
        isFetching: false,
        shouldFetch: !action.succeeded
      }
    };
  },

  [Actions.GET_VIDEO]: (state: GalleryState, action: Actions.GetImage) => {
    return { ...state, isFetching: true };
  },

  [Actions.GET_VIDEOS_COMPLETE]: (state: GalleryState, action: Actions.GetImageComplete) => {
    return { ...state, isFetching: false };
  },

  [Actions.UPLOAD_IMAGE]: (state: GalleryState, action: Actions.UploadImage) => {
    return {
      ...state,
      isUploading: true
    };
  },


  [Actions.UPLOAD_IMAGE_COMPLETE]: (state: GalleryState, action: Actions.UploadImageComplete) => {
    return {
      ...state,
      imageGallery: {
        ...state.imageGallery,
        shouldFetch: action.succeeded
      },
      isUploading: false
    };
  },
}

export const reducer = (state: GalleryState, action: Action) => {
    const actionHandlerMethod = actionHandler[action.type];
    return actionHandlerMethod 
        ? actionHandlerMethod(state, action)
        : state || defaultState;
}
