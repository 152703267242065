import * as React from 'react';
import './styles/notificationPopout.sass';
import { GlobalApplicationState } from "globalApplicationState";
import { ConnectedProps, connect } from "react-redux";
import { Drawer } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ArrowBack from '@mui/icons-material/ArrowBack';
import AuthoringSettings from './authoringSettings'
import TripleNotificationOverride from './tripleNotificationOverride'
import { actions } from '..';

interface TextBubble {
    id: string;
    name: string;
}

interface ComponentProps {
    updateToggle: () => any
    eventPrompt: string
    event: string
    emailBubbles: TextBubble[],
    smsBubbles: TextBubble[],
    mobileBubbles: TextBubble[],
    publishName: string
    teamsEvent: string;
    mobileEvent: string;
    emailEvent: string;
    smsEvent: string;
}

interface ComponentState {
    isOpen: boolean;
    activeSettingsTab: number;
}

class RegularNotificationPopout extends React.Component<PropsWithRedux, ComponentState> {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: true,
            activeSettingsTab: 0,
        }
    }

    itemDisplay = () => {
        enum options {
            "Authoring Default Settings" = 0,
            "Notification Contents" = 1,
        }
        let tabs = [
            {
                id: 0, name: "Authoring Default Settings", content: <AuthoringSettings
                    onPublishName={this.props.publishName}
                    emailEvent={this.props.emailEvent}
                    smsEvent={this.props.smsEvent}
                    teamsEvent={this.props.teamsEvent}
                    mobileEvent={this.props.mobileEvent}
                />
            },
            {
                id: 1, name: "Notification Contents", content: <TripleNotificationOverride
                    emailSubjectUpdate={this.updateEmailSubject}
                    emailBubbles={this.props.emailBubbles}
                    smsBodyUpdate={this.updateSmsBody}
                    smsBubbles={this.props.smsBubbles}
                    pushContentUpdate={this.updatePushContent}
                    mobileBubbles={this.props.mobileBubbles}
                    event={this.props.event}
                />
            },
        ];

        return (
            <div className={"full-list"}>
                <div className="top-options">
                    <IconButton onClick={this.toggleDrawer(false)} size="large">
                        <ArrowBack />
                    </IconButton>
                    <span className="top-text">{"More " + this.props.eventPrompt + " Settings"}</span>
                </div>
                <div className="drawer-line-break">
                    <hr className="separator-line-popout" />
                </div>

                <div className="tabs-adjustment">
                    <div className="tabs">
                        <ul className="tab-btns">
                            {tabs.map((tab) => {
                                return (
                                    <li key={`tab-${tab.id}`}>
                                        <span
                                            className={"tab-btn" + (this.state.activeSettingsTab === options[tab.name] ? " active" : "")}
                                            onClick={() => this.tabClicked(tab)}
                                        >
                                            {tab.name}
                                        </span>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>

                <div>
                    {this.state.activeSettingsTab === 0 && tabs[0].content}
                    {this.state.activeSettingsTab === 1 && tabs[1].content}
                </div>
            </div>
        );
    }

    private toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        this.props.updateToggle();
        this.setState({ ...this.state, isOpen: open });
    }

    private tabClicked = (tab) => {
        this.setState({ ...this.state, activeSettingsTab: tab.id })
    };

    private updateEmailSubject = (translatedContent: any) => {
        const toChange = this.props.event + "EmailAttempt"
        this.props.setEmailSubject(toChange, translatedContent);
    }

    private updateSmsBody = (translatedContent: any) => {
        const toChange = this.props.event + "SMSAttempt"
        this.props.setSmsBody(toChange, translatedContent);
    }

    private updatePushContent = (translatedContent: any) => {
        const toChange = this.props.event + "MobileAttempt"
        this.props.setPushContent(toChange, translatedContent);
    }

    public render() {
        return (
            <Drawer
                anchor={'right'}
                open={this.state.isOpen}
                onClose={this.toggleDrawer(false)}
            >
                {this.itemDisplay()}

            </Drawer>
        );
    }
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        notificationSettings: state.settings.notificationSettings,
        emailSubjects: state.resources.emailSubjectsResource,
        smsBodies: state.resources.smsBodiesResource,
        pushContents: state.resources.pushContentsResource
    }),
    {
        setEmailSubject: actions.SetEmailSubject,
        setSmsBody: actions.SetSMSBody,
        setPushContent: actions.SetMobileContent
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default (connector(RegularNotificationPopout));