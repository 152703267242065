import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import * as actions from "../../../actionCreator";
import { GlobalApplicationState } from "globalApplicationState";
import { push } from "react-router-redux";

import NavigationItemsList from "./navigationItemsList";


class NavigationItemsListing extends React.Component<PropsWithRedux, ComponentState> {
  public componentDidMount() {
    this.fetchItems();
  }

  public componentDidUpdate(prevProps: PropsWithRedux) {
    if (this.props.shouldFetch && (this.props.shouldFetch !== prevProps.shouldFetch)) {
      this.fetchItems();
    }
  }

  public render() {
    return (
      <div className="navigation-items-listing">
        <NavigationItemsList />
      </div>
    );
  }


  private fetchItems = () => {
    !this.props.isFetching &&
      this.props.getNavigationItems();
  }
}
  

interface ComponentProps {
}

interface ComponentState {
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    isFetching: state.portalPages.navigationItems.isFetching,
    shouldFetch: state.portalPages.navigationItems.shouldFetch,
    tenantSettings: state.settings.tenantSettings,
    tenant: state.tenant.id
  }),
  {
    getNavigationItems: actions.getNavigationItems,
    redirectTo: push
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(NavigationItemsListing);