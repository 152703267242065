import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import * as actions from "../actionCreator";
import { GlobalApplicationState } from "globalApplicationState";

import { ClientSettingsTag, ClientSettingsTagGroup } from "modules/settings/models";

import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LockIcon from "@mui/icons-material/Lock";


class TagSubscriptions extends React.Component<PropsWithRedux, ComponentState> {
  constructor(props: PropsWithRedux) {
    super(props);

    this.state = {
      hasChanges: false,
      subscribedTags: props.userSettings.subscribedTags
    };
  }

  public componentDidUpdate(prevProps: PropsWithRedux) {
    if ((this.props.show && prevProps.show !== this.props.show) ||
        prevProps.userSettings.subscribedTags.length !== this.props.userSettings.subscribedTags.length)
      this.setState({ hasChanges: false, subscribedTags: this.props.userSettings.subscribedTags });
  }

  public render() {
    return (
      <Drawer anchor="right" open={this.props.show} onClose={this.onSave} classes={{ paper: "my-profile-dialog-drawer" }} className="my-profile-dialog">
        <Paper square elevation={0} className="my-profile-dialog-header">
          <IconButton onClick={this.onSave} size="large">
            <ArrowBackIcon />
          </IconButton>
          <div>Edit topic subscriptions</div>
        </Paper>
        <List>
          {this.props.tagSettings.tagGroups.map((tagGroup) => {
            const tagGroupChecked: boolean = tagGroup.tags.filter((tag) => !!this.state.subscribedTags.find((subscribedTag) => subscribedTag === tag.id)).length === tagGroup.tags.length;
            const tagGroupDisabled: boolean = tagGroup.tags.filter((tag) => tag.mandatory).length === tagGroup.tags.length;
            return (
              <React.Fragment key={tagGroup.id}>
                <ListItem dense button disabled={tagGroupDisabled} onClick={() => this.onSelectTagGroup(tagGroup, !tagGroupChecked)}>
                  <ListItemIcon className="tags-list-checkbox">
                    <Checkbox
                      edge="start"
                      tabIndex={-1}
                      disableRipple
                      size="small"
                      color="primary"
                      checked={tagGroupChecked}
                    />
                  </ListItemIcon>
                  <ListItemText primary={tagGroup.name} classes={{ primary: "tags-list-tag-group-text" }} />
                </ListItem>
                <List disablePadding className="tags-list-tags">
                  {tagGroup.tags.map((tag) =>
                    <ListItem key={tag.id} dense button disabled={tag.mandatory} onClick={() => this.onSelectTag(tag)}>
                      <ListItemIcon className="tags-list-checkbox">
                        <Checkbox
                          edge="start"
                          tabIndex={-1}
                          disableRipple
                          size="small"
                          color="primary"
                          checked={!!this.state.subscribedTags.find((subscribedTag) => subscribedTag === tag.id)}
                        />
                      </ListItemIcon>
                      <ListItemText primary={tag.name} />
                      {tag.restricted && <LockIcon fontSize="small" className="restricted-tag-icon" />}
                    </ListItem>
                  )}
                </List>
              </React.Fragment>
            );
          })}
        </List>
        <Divider light />
        <div className="reset-dialog">
          <Button variant="text" color="primary" onClick={this.onReset}>Reset subscriptions</Button>
        </div>
      </Drawer>
    );
  }


  private onSave = () => {
    if (this.state.hasChanges)
      this.props.saveUserSettings({ subscribedTags: this.state.subscribedTags });
    this.props.onClose();
  }

  private onReset = () => {
    this.setState({ hasChanges: false, subscribedTags: this.props.userSettings.subscribedTags });
  }


  private onChangeSubscribedTags = (subscribedTags: string[]) => {
    this.setState({ hasChanges: true, subscribedTags });
  }
  
  private onSelectTag = (tag: ClientSettingsTag) => {
    const hasSelectedTag: boolean = !!this.state.subscribedTags.find((subscribedTag) => subscribedTag === tag.id);
    if (hasSelectedTag)
      this.onChangeSubscribedTags(this.state.subscribedTags.filter((subscribedTag) => subscribedTag !== tag.id));
    else
      this.onChangeSubscribedTags(this.state.subscribedTags.concat(tag.id));
  }

  private onSelectTagGroup = (tagGroup: ClientSettingsTagGroup, checked: boolean) => {
    let tags: string[] = this.state.subscribedTags.slice();

    if (checked) {
      tagGroup.tags.map((tag) => {
        tags.push(tag.id);
        return tag;
      });
      tags = tags.filter((tag) => tag);
    } else {
      tagGroup.tags.map((tag) => {
        if (!tag.mandatory)
          tags = tags.filter((tagToFilter) => tagToFilter !== tag.id);
        return tag;
      });
    }

    this.onChangeSubscribedTags(tags);
  }
}


interface ComponentProps {
  show: boolean;
  onClose: () => void;
}

interface ComponentState {
  hasChanges: boolean;
  subscribedTags: string[];
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    tagSettings: state.settings.tagSettings,
    userSettings: state.profile.userSettings!
  }),
  {
    saveUserSettings: actions.saveUserSettings
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(TagSubscriptions);