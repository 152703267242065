import { useEffect } from 'react';
import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Bookmark, Editor as IEditor} from "tinymce";

import { BasicTinyMce } from 'modules/common/components/basicTinyMce';
import * as actions from 'modules/posts/actionCreator';
import { GlobalApplicationState } from 'globalApplicationState';

import Tenor from 'react-tenor';
import 'react-tenor/dist/styles.css'
import { ReplyDetails } from 'modules/posts/models';
import { addGif, handleTinyText } from 'utils/textEditorUtils';
import TextboxButtons from './textboxButtons';

interface ComponentProps {
    placeholder: string
    postId: string
    preview?: boolean;
    parentCommentId: string;
    replyDetails: ReplyDetails;
    repliesExpanded: boolean;
    loadReplies: () => void;
    onCancelReply: () => void;
}

const ReplyTextbox: React.FunctionComponent<PropsWithRedux> = ({
    placeholder,
    commentMaxLength,
    savingComment,
    postId,
    preview = false,
    addCommentReply,
    replyDetails,
    parentCommentId,
    repliesExpanded,
    loadReplies,
    onCancelReply
}) => {
    const [text, setText] = React.useState("");
    const [letterCount, setCount] = React.useState(0);
    const [bodyPlaintext, setBodyPlaintext] = React.useState("");
    const [gifsVisible, setGifsVisible] = React.useState(false);
    const [startBookmark, setStartBookmark] = React.useState<Bookmark>();
    const textRef = React.useRef(null)
    const editorRef = React.useRef<IEditor | null>(null);
    const gifRef = React.useRef<any>(null);

    const isOnlyWhitespace = React.useMemo(() => {
        let isOnlyWhitespace = true;
        if (editorRef.current)
            isOnlyWhitespace = (bodyPlaintext.trim() === "" && editorRef.current.getContent().match(/<img id="inserted-tenor-gif"/g) === null);

        return isOnlyWhitespace;
    }, [bodyPlaintext]);

    let commentCooldown = false;
    let timeout: NodeJS.Timeout | undefined = undefined;

    useEffect(() => {
        return () => {
            if (timeout)
                clearTimeout(timeout);
        }
    }, [timeout]);

    useEffect(() => {
        if (!editorRef.current) return;

        let oldMention = editorRef.current.dom.get("initial-reply-mention");

        if(oldMention !== null) {
            oldMention.setAttribute("data-user-id", replyDetails.id);
            oldMention.childNodes[0].nodeValue = "@"+replyDetails.name;
        }
        else {
            editorRef.current.selection.moveToBookmark(startBookmark!);
            editorRef.current.insertContent(`<mark id=initial-reply-mention data-user-id={${replyDetails.id}} style="background-color: transparent; color: #3B78AB;" class="mceNonEditable replyStart">@${replyDetails.name}</mark>`);
        }
    }, [replyDetails]);

    const onAdd = () => {
        if (!editorRef.current) return;

        commentCooldown = true;
        timeout = setTimeout(() => { commentCooldown = false }, 1000);

        let plain = bodyPlaintext;
        let content = editorRef.current.getContent();
        let hasGif = content?.match(/<img id="inserted-tenor-gif"/g);

        if (hasGif && hasGif.length > 0 && bodyPlaintext === "") {
            plain = "📷 GIF";
        }

        addCommentReply(postId, text, plain, parentCommentId).then((response) => {
            if (response) {
                setText('');
                setBodyPlaintext('');
                setCount(0);

                if(repliesExpanded) {
                    loadReplies();
                }
            }
        });
    };

    const initializeCallback = () => {
        if(editorRef.current) {
            let startBookmark = editorRef.current.selection.getBookmark(1, true);
            setStartBookmark(startBookmark);
            editorRef.current.insertContent(`<mark id=initial-reply-mention data-user-id={${replyDetails.id}} style="background-color: transparent; color: #3B78AB;" class="mceNonEditable replyStart">@${replyDetails.name}</mark>`);
        }
    }

    return (
        <div className="mentions-formatting">
            {gifsVisible ?
                <div className="add-comment-tenor">
                    <Tenor 
                        onSelect={(result) => addGif(result, setBodyPlaintext, setGifsVisible, editorRef, gifRef)} 
                        token={'A9HCCDQ5VCW0'} 
                        defaultResults={true} 
                        autoFocus={true} 
                        searchPlaceholder={"Search GIFs"} 
                    />
                </div>
                : null}
            <div className="tiny-text-holder" ref={textRef}>
                <BasicTinyMce
                    isGifsVisible={gifsVisible}
                    onChangeIsGifsVisible={(value: boolean) => setGifsVisible(value)}
                    placeholder={placeholder}
                    editorRef={editorRef}
                    gifRef={gifRef}
                    value={text}
                    id={`tiny-comment-editor-reply-${parentCommentId}`}
                    onChange={() => handleTinyText(setText, setBodyPlaintext, setCount, editorRef.current!)}
                    disabled={preview || savingComment || commentCooldown}
                    initializeCallback={initializeCallback}
                />
            </div>
            <TextboxButtons 
                onAdd={onAdd}
                onAddDisabled={preview || savingComment || commentCooldown || letterCount === 0 || letterCount > commentMaxLength || isOnlyWhitespace || gifsVisible}
                onCancel={onCancelReply}
                letterCount={letterCount}
                maxLength={commentMaxLength}
            />
        </div>
    )
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        commentMaxLength: state.settings.clientSettings.commentSystem.commentMaxLength,
        savingComment: state.posts.postView.savingComment,
    }),
    {
        addCommentReply: actions.addCommentReply,
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;
export default connector(ReplyTextbox);
