import * as Actions from './actions';
import { PostsState } from './models';
import { Reducer } from './index';


export const actionHandler: Reducer<PostsState> = {

    [Actions.FETCH_DOCUMENTS_FROM_DIRECTORY]: (state: PostsState, action: Actions.FetchDocumentsFromDirectory) => {
        return { ...state, documentDirectory: { ...state.documentDirectory, fetching: true, files: state.documentDirectory.files || [] } }
    },

    [Actions.FETCH_DOCUMENTS_FROM_DIRECTORY_COMPLETE]: (state: PostsState, action: Actions.FetchDocumentsFromDirectoryComplete) => {
        let previousDocuments = state.documentDirectory.files || []
        let nextDocuments = action.files || []

        return {
            ...state,
            documentDirectory: {
                ...state.documentDirectory,
                continuationToken: action.continuationToken || null,
                fetching: false,
                files: [ ...previousDocuments, ...nextDocuments ],
                shouldFetch: false,
                lastFetched: new Date().getTime()
            }
        }
    },

       
    [Actions.FETCH_DOCUMENT_FROM_DIRECTORY_COMPLETE]: (state: PostsState, action: Actions.FetchDocumentFromDirectoryComplete) => {
        const docIndex = state.documentDirectory.files.findIndex(i => i.id === action.file.id);
        const existsInLibrary = docIndex >= 0;
        let files = [...state.documentDirectory.files];
        if(existsInLibrary){
            files[docIndex] = action.file
        }else{
            files.unshift(action.file);
        }
        return {
            ...state,
            documentDirectory: {
                ...state.documentDirectory,
                files
            }
        }
    },

}