import React from "react";

import { IBaseSmartContentPageProps, ISuggestion } from "modules/smartContent/models";
import { SmartContentSuggestedHeader } from "modules/smartContent/smartContentSuggestedHeader";
import { Button, TextField } from "@mui/material";
import { SmartContentSuggestion } from "modules/smartContent/smartContentSuggestion";
import { SmartContentFooter } from "modules/smartContent/smartContentFooter";
import { POST_SMART_CONTENT_PAGES } from "./postSmartContentPanel";

import "../styles/postSmartContentKeywords.sass";

interface IPostSmartContentKeywordsProps extends IBaseSmartContentPageProps {
    suggestions: ISuggestion[];
    keywords: string;
    generateLabel?: string;
    headerTitle?: string;
    previousPage: POST_SMART_CONTENT_PAGES;
    setCurrentPage: (newPage: POST_SMART_CONTENT_PAGES) => void;
    onGenerate: () => Promise<void>;
    onChangeKeywords: (newKeywords: string) => void;
}

/**
 * Keywords/topics suggestion page
 * - TODO: depending on how smart content looks for events move this to a common place and remove post specific language
 */
const PostSmartContentKeywords: React.FunctionComponent<IPostSmartContentKeywordsProps> = ({
    suggestions,
    keywords,
    generateLabel = "GENERATE",
    headerTitle = "Suggested Keywords",
    previousPage,
    setCurrentPage,
    onChangeKeywords,
    onBack,
    onClose,
    onGenerate,
}) => {
    // show cancel button when got here from the list page
    // cancel button will take user back to list page instead of home like the back button
    // NOTE: Consider this when adding/removing pages
    const cancelable = previousPage !== POST_SMART_CONTENT_PAGES.HOME &&
        previousPage !== POST_SMART_CONTENT_PAGES.KEYWORDS;

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChangeKeywords(event.currentTarget.value);
    }

    return (
        <>
            <div id="post-smart-content-keywords-suggestions">
                <SmartContentSuggestedHeader
                    title={headerTitle}
                    onBack={onBack}
                    onClose={onClose}
                />
                <div id="post-smart-content-keywords-content" className="smart-content-content">
                    <p className="padding-horizontal prompt" >Do you have a topic or keywords in mind?</p>
                    <TextField
                        autoFocus
                        id="post-smart-content-keywords-input"
                        className="padding-horizontal"
                        value={keywords}
                        onChange={onChange}
                        variant="outlined"
                        multiline
                        rows={10}
                        placeholder="Ex. Employee Recognition Post"
                    />
                    <p className="padding-horizontal label">Suggestions</p>
                    <div className="padding-horizontal">
                        {suggestions.map((suggestion: ISuggestion) =>
                            <SmartContentSuggestion
                                key={suggestion.suggestionId}
                                confirmLabel={"ENTER"}
                                onConfirm={(newKeywordSuggestion: ISuggestion) => onChangeKeywords(keywords.concat(newKeywordSuggestion.suggestion))}
                                suggestion={suggestion}
                                confirmPosition="block"
                            />)}
                    </div>
                </div>
            </div>
            <SmartContentFooter>
                <div style={{ flex: 1, display: "flex", alignItems: "flex-end", justifyContent: "flex-end" }}>
                    {cancelable &&
                        <Button
                            id="post-smart-content-keywords-cancel"
                            variant="text"
                            sx={{
                                color: "#7f7f7f"
                            }}
                            onClick={() => setCurrentPage(previousPage)}
                        >
                            CANCEL
                        </Button>}
                    <Button
                        id="post-smart-content-keywords-generate-ideas"
                        disabled={!keywords}
                        color="primary"
                        variant="contained"
                        onClick={async () => await onGenerate()}
                    >
                        {generateLabel}
                    </Button>
                </div>
            </SmartContentFooter>
        </>
    );
}

export { PostSmartContentKeywords };
