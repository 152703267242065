import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import { usersApi } from "api/instances";
import Loading from "modules/common/components/loading";
import LoadingOverlay from "modules/common/components/loadingOverlay";

import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveAltIcon from "@mui/icons-material/SaveAlt";

import moment from "moment";
import { SimpleTag, UserActivityFilter, UserActivityLogPage, UserActivityLogType } from "../models";
import UserActivitiesFilters from "./userActivitiesFilters";
import { UserActivity } from "..";
import { push } from "react-router-redux";
import Disclaimer from "modules/common/components/disclaimer";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { FileDownloader } from "utils/fileDownloader";

class UserActivityLogs extends React.Component<PropsWithRedux, ComponentState> {
  constructor(props: PropsWithRedux) {
    super(props);

    this.state = {
      activityLog: undefined,
      filters: this.getDefaultFilters(props),
      isDownloading: false,
      isInitialized: false,
      isLoading: false
    };
  }

  public componentDidMount() {
    moment.locale("en");
    this.getActivityTypes();
    this.setState({filters: this.getDefaultFilters(this.props)});
    this.getUserActivityLog(1);
  }
  
  private getDefaultFilters(props: PropsWithRedux): UserActivityFilter {
    return {
      activityTypes: [],
      userIds: !!props.defaultUserId ? [props.defaultUserId] : [],
      status: null,
      from: null,
      to: null,
      textToSearch: ''
    } as UserActivityFilter;
  }

  public render() {
    return (
      <Drawer anchor="right" open={true} onClose={this.props.onClose} classes={{ paper: "newsletter-drawer" }}>
        <Paper square elevation={0} className="newsletter-drawer-header">
          <div>
            <IconButton onClick={this.props.onClose} size="large">
              <ArrowBackIcon />
            </IconButton>
            <div>{`User Management Activity Log${!!this.props.displayContext ? (' - ' + this.props.displayContext) : ''}`}</div>
          </div>
          <Button variant="outlined" color="primary" startIcon={<SaveAltIcon />} onClick={this.downloadActivityLog}>Download results</Button>
        </Paper>
        <div>
          {this.getList()}
        </div>
        <LoadingOverlay show={this.state.isDownloading} styles={{ paddingTop: 150 }} />
      </Drawer>
    );
  }

  private downloadActivityLog = () => {
    this.setState({ isDownloading: true });
    const file = { name: `User Management Activity Log.csv` };
    usersApi.downloadUserActivity(this.state.filters)
      .then((url) => new FileDownloader(file).downloadProvided(url))
      .then(_ => this.setState({ isDownloading: false }))
      .catch(_ => this.setState({ isDownloading: false }));
  }

  private downloadBulkSocialUserReport = (id: string) => {
    this.setState({ isDownloading: true });
    const file = { name: `Bulk Social Users-${new Date().toISOString()}.csv` };

    usersApi.downloadSocialUserReport(id)
      .then((url) => new FileDownloader(file).downloadProvided(url))
      .then(_ => this.setState({ isDownloading: false }))
      .catch(_ => this.setState({ isDownloading: false }));
  }

  private downloadBulkSparrowUserReport = (id: string) => {
    if (!id) return;
    const file = {
      name: `Bulk Sparrow Users-${new Date().toISOString()}.csv`
    }
    return usersApi.GetBatchCSVForBatchId(id)
            .then( data => new FileDownloader(file).downloadBlob(data))
  }

  private getUserActivityLog = (pageNumber: number, filters: UserActivityFilter = this.state.filters) => {
    this.setState({ isLoading: true });
    usersApi.getuserActivity(pageNumber, filters).then((activityLog) => {
      this.setState({ activityLog , isInitialized: true, isLoading: false });
    });
  }

  private getActivityTypes = () => {
    usersApi.getuserActivityTypes().then(response => {
      this.setState({userActivityLogType: response as UserActivityLogType[]})
    });
  }

  private getList = () => {
    const { activityLog } = this.state;
    
    return (
      <div className="newsletter-drawer-content">
        <div style={{"marginBottom": "15px"}}>
          <Disclaimer
              icon={<InfoOutlinedIcon />}
              text={
                <React.Fragment>
                  The User Management Activity Log keeps track of all modifications and actions of Admins/Owners within the Manage Users & Permissions screen.
                </React.Fragment>
              }
          />
        </div>
        <UserActivitiesFilters
          filters={this.state.filters}
          userActivityLogType={this.state.userActivityLogType}
          onChangeFilters={this.onChangeFilters}
          onClearFilters={this.onClearFilters}
        />
        {this.state.isLoading || !activityLog
          ? <Loading />
          : activityLog.totalItems === 0
              ? <div>No activities were found.</div>
              : <React.Fragment>
                  <TableContainer>
                    <Table size="medium">
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">Activity</TableCell>
                          <TableCell align="center">Activity date</TableCell>
                          <TableCell align="center">Admin/Owner</TableCell>
                          <TableCell align="center">Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {activityLog.results.map((activity, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell align="left">{activity.activityDescription}</TableCell>
                              <TableCell align="center">{moment(activity.time).format("MMM D, YYYY, h:mmA")}</TableCell>
                              <TableCell align="center">{activity.name}</TableCell>
                              <TableCell align="center">{this.getAction(activity)}</TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {activityLog.currentPage > 0 && activityLog.totalPages > 1 &&
                    <TablePagination
                      rowsPerPageOptions={[10]}
                      component="div"
                      backIconButtonProps={{ color: "primary" }}
                      nextIconButtonProps={{ color: "primary" }}
                      count={activityLog.totalItems}
                      rowsPerPage={10}
                      page={activityLog.currentPage - 1}
                      onPageChange={(ev, pageNumber) => {
                        this.getUserActivityLog(pageNumber + 1);
                      }}
                    />
                  }
                </React.Fragment>
        }
      </div>
    );
  }

  private getAction = (activity: UserActivity) => {
    if(activity.activityType === "BulkAddSocialUser" ){
      return (<Button variant="text" color="primary" onClick={() =>  this.downloadBulkSocialUserReport(activity.reference)}>DOWNLOAD REPORT</Button>)
    }
    if(activity.activityType === "BulkAddSparrowUser") {
      return (<Button variant="text" color="primary" onClick={() =>  this.downloadBulkSparrowUserReport(activity.reference)}>DOWNLOAD REPORT</Button>)
    }
    if(activity.activityType === "CancelledSocialUserInvite" || activity.activityType === "DeleteSocialUser" || activity.activityType === "DeleteSparrowUser") {
      return "";
    }
    if (activity.activityType === "AddSocialUser" && activity.activityDescription?.includes("@")){
      return (<i>Pending Invite</i>)
    }
    if (activity.activityType === "AddSparrowUser" && activity.activityDescription?.includes("@")){
      return (<i>Pending Invite</i>)
    }
  }

  private onChangeFilters = (filters: UserActivityFilter) => {
    this.setState({ filters: filters });
    this.getUserActivityLog(1, filters);
  }
  
  private onClearFilters = () => {
    const filters = this.getDefaultFilters(this.props);
    this.setState({ filters: filters });
    this.getUserActivityLog(1, filters);
  }
}

interface ComponentProps {
  selectedNewsletterId?: string;
  defaultUserId?: string | null;
  newsletterTags?: SimpleTag[];
  issueTags?: SimpleTag[];
  displayContext?: string;
  onClose: () => any;
}

interface ComponentState {
  activityLog?: UserActivityLogPage;
  userActivityLogType?: UserActivityLogType[];
  filters: UserActivityFilter;
  isDownloading: boolean;
  isLoading: boolean;
  isInitialized:boolean;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    tenant: state.tenant.id
  }),
  {
    redirectTo: push
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(UserActivityLogs);