import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { push } from "react-router-redux";
import * as audiencesActions from "modules/audiences/actionCreator";
import { GlobalApplicationState } from "globalApplicationState";

import { Audience } from "modules/audiences/models";

import Loading from "modules/common/components/loading";

import Checkbox from "@mui/material/Checkbox";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";


class AudiencesDrawer extends React.Component<PropsWithRedux, ComponentState> {
  constructor(props: PropsWithRedux) {
    super(props);

    this.state = {
      isLoading: false,
      selectedAudiences: props.selectedAudiences
    };
  }

  public componentDidMount() {
    if (!this.props.audiences.length) {
      this.setState({ isLoading: true });
      this.props.getAudiences().then(() => {
        this.setState({ isLoading: false });
      });
    }
  }

  public componentDidUpdate(prevProps: PropsWithRedux) {
    if (this.props.show && !prevProps.show)
      this.setState({ selectedAudiences: this.props.selectedAudiences });
  }

  public render() {
    return (
      <Drawer anchor="right" open={this.props.show} onClose={this.onApply} classes={{ paper: "portal-pages-list-drawer" }} style={{zIndex: 1500}}>
        <Paper square elevation={0} className="portal-pages-list-header">
          <IconButton onClick={this.onApply} size="large">
            <ArrowBackIcon />
          </IconButton>
          <div>Add audience</div>
        </Paper>
        <div>
          {this.getList()}
        </div>
      </Drawer>
    );
  }

  private getList = () => {
    if (this.state.isLoading)
      return <Loading />;
    
    if (!this.props.audiences.length)
      return <div>No audiences were found</div>;
    
    var audiencesForList;

    if (this.props.audiencesToCheck && this.props.audiencesToCheck.length > 0)
    {
        audiencesForList = this.props.audiencesToCheck;
    }
    else
    {
        audiencesForList = this.props.audiences;
    }

    return (
      <List disablePadding>
        {audiencesForList.map((audience) =>
          <ListItem key={audience.id} dense button onClick={() => this.onSelectAudience(audience)}>
            <ListItemIcon className="portal-pages-list-checkbox">
              <Checkbox
                edge="start"
                tabIndex={-1}
                disableRipple
                size="small"
                color="primary"
                checked={!!this.state.selectedAudiences.find((selectedAudience) => selectedAudience.id === audience.id)}
              />
            </ListItemIcon>
            <ListItemText primary={audience.displayName} />
          </ListItem>
        )}
      </List>
    );
  }

  private onApply = () => {
    this.props.onChange(this.state.selectedAudiences);
  }

  private onSelectAudience = (audience: Audience) => {
    const hasSelectedAudience: boolean = !!this.state.selectedAudiences.find((selectedAudience) => selectedAudience.id === audience.id);
    if (hasSelectedAudience)
      this.setState({ ...this.state, selectedAudiences: this.state.selectedAudiences.filter((selectedAudience) => selectedAudience.id !== audience.id) });
    else
      this.setState({ ...this.state, selectedAudiences: this.state.selectedAudiences.concat([audience]) });
  }
}


interface ComponentProps {
  show: boolean;
  selectedAudiences: Audience[];
  onChange: (audiences: Audience[]) => void;
  audiencesToCheck?: Audience[];
}

interface ComponentState {
  isLoading: boolean;
  selectedAudiences: Audience[];
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    audiences: state.audiences.audiences,
    tenantId: state.tenant.id
  }),
  {
    getAudiences: audiencesActions.getAudiences,
    redirectTo: push
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(AudiencesDrawer);