import React from "react";

import Snackbar from "@mui/material/Snackbar";

import Alert from '@mui/material/Alert';

import { Theme } from "@mui/material/styles";


import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    snackbar: {
      position: "absolute"
    },
    alert: {
        minWidth: 350
    }
  })
);

/**
 * An error snackbar
 * - if you need more control over the base MUI components use snackbarWrapper.tsx
 */
const ErrorSnackbar: React.FunctionComponent<ComponentProps> = props => {
  const classes = useStyles();

  return (
    <Snackbar open={!!props.errorMessage} anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={6000} onClose={props.clearErrorMessage} className={classes.snackbar}>
      <Alert variant="filled" severity="error" elevation={6} onClose={props.clearErrorMessage} className={classes.alert}>{props.errorMessage}</Alert>
    </Snackbar>
  );
}

interface ComponentProps {
  errorMessage: string;
  clearErrorMessage: () => void;
}

export default ErrorSnackbar;
