
import { Action } from "redux";
import { ActionCreator } from "typedActions";
import { ActivityTab } from "./models";


export const SET_ACTIVE_ACTIVITY_TAB =  "SET_ACTIVE_ACTIVITY_TAB";
export interface SetActiveActivityTab extends Action {
    activeTab: ActivityTab;
}
export const CreateSetActiveActivityTabAction = ActionCreator<SetActiveActivityTab>(SET_ACTIVE_ACTIVITY_TAB);
