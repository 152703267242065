import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";

import { PostViewStats } from "modules/posts/models";

import "../../../styles/PostTypeIcons.sass";
import HoverText from "modules/documents/components/action-buttons/hoverText";

const icoComplied: string = "/images/icons/complied_ico.svg";
const icoMandatory: string = "/images/icons/mandatory_ico.svg";
const icoNotComplied: string = "/images/icons/not_complied_ico.svg";
const icoPublic: string = "/images/icons/public_ico.svg";
const icoRestricted: string = "/images/icons/restricted_ico.svg";


interface IBanner {
  color: string;
  icon: string;
  text: string;
  description?: string;
  urlDescription?: string;
}

type BannerTypes = "complied" | "mandatory" | "notComplied" | "public" | "restricted";

type Banners = { [bannerType in BannerTypes]: IBanner; };

interface ComponentProps {
    lcid: string;
    postType: string;
    full?: boolean;
    id: string;
    isRestricted?: boolean;
    stats?: PostViewStats;
    title: string;
    currentUserHasComplied?: boolean
    hideCompliance?: boolean
    status: string;
}

const PostTypeIcons: React.FunctionComponent<PropsWithRedux> = props => {
  if (!props.clientSettings)
    return (null);

  const { clientSettings } = props;
  const { specialtyPostColors } = clientSettings;

  const onGetTranslatedText = (field: string, defaultLCID: string = "en-us") => {
    const { clientSettings, lcid } = props;
    const { translatedContent } = clientSettings;

    if (!translatedContent || (!translatedContent[lcid] && !translatedContent[defaultLCID]))
      return "";
    
    return translatedContent[lcid] ? translatedContent[lcid][field] : translatedContent[defaultLCID][field];
  }

  const banners: Banners = {
    complied: {
      color: (specialtyPostColors && specialtyPostColors.complianceChecked) || "#05b34b",
      icon: icoComplied,
      text: onGetTranslatedText("compliedPost") || "Complied",
      description: onGetTranslatedText("compliedBannerDescription") || "You have already read and complied to this post."
    },
    mandatory: {
      color: (specialtyPostColors && specialtyPostColors.mandatory) || "#004e79",
      icon: icoMandatory,
      text: onGetTranslatedText("mandatoryPost") || "Mandatory",
      description: onGetTranslatedText("mandatoryBannerDescription") || "You are required to read this post."
    },
    notComplied: {
      color: (specialtyPostColors && specialtyPostColors.compliance) || "#faaf18",
      icon: icoNotComplied,
      text: onGetTranslatedText("compliancePost") || "Compliance",
      description: onGetTranslatedText("complianceBannerDescription") || "If you agree with the content of this post, comply using the checkbox down below."
    },
    public: {
      color: (specialtyPostColors && specialtyPostColors.public) || "#ffffff",
      icon: icoPublic,
      text: onGetTranslatedText("publicPost") || "Public",
      description: onGetTranslatedText("publicBannerDescription") || "This is a public post.",
      urlDescription: onGetTranslatedText("publicBannerDescriptionShareTitle") || "Share it through here"
    },
    restricted: {
      color: (specialtyPostColors && specialtyPostColors.restricted) || "#b72026",
      icon: icoRestricted,
      text: onGetTranslatedText("restrictedPost") || "Restricted",
      description: onGetTranslatedText("restrictedBannerDescription") || "This post is exclusive to specific audiences."
    }
  };

  const hasBanner = props.postType !== "standard" || (props.isRestricted && props.restrictedBannerEnabled);
  const hasTwoIcons = props.postType !== "standard" && props.isRestricted && props.restrictedBannerEnabled;
  const isComplied = props.stats ? (!!props.stats && !!props.stats.complianceTime) : props.currentUserHasComplied;

  return (
    <div style={{paddingRight: hasBanner ? "6px" : "0px", display: "flex", flexDirection: "row"}}>
        {props.postType === "compliance" &&
            <HoverText
                label={
                    <div className={"postIcon"} style={{backgroundColor: isComplied ? banners.complied.color : banners.notComplied.color, marginRight: hasTwoIcons ? "6px" : "0px"}}>
                    {isComplied ?
                        <img src={icoComplied} alt="Complied icon" className="iconImage"></img>
                        : <img src={icoNotComplied} alt="Not complied icon" className="iconImage"></img>}
                    </div>
                }
            >
                {isComplied ? banners.complied.description : banners.notComplied.description}
            </HoverText>
        }

        {props.postType === "mandatory" && 
            <HoverText
                label={
                    <div className={"postIcon"} style={{backgroundColor: banners.mandatory.color, marginRight: hasTwoIcons ? "6px" : "0px"}}>
                        <img src={icoMandatory} alt="Mandatory post icon" className="iconImage"></img>
                    </div>
                }
            >
                {banners.mandatory.description}
            </HoverText>
        }

        {props.postType === "public" && 
            <HoverText
                label={
                    <div className={"postIcon"} style={{backgroundColor: banners.public.color, marginRight: hasTwoIcons ? "6px" : "0px"}}>
                        <img src={icoPublic} alt="Public post icon" className="iconImage"></img>
                    </div>
                }
            >
                {banners.public.description}
            </HoverText>
        }

        {props.isRestricted && props.restrictedBannerEnabled &&
            <HoverText
                label={
                    <div className={"postIcon"} style={{backgroundColor: banners.restricted.color, marginRight: hasTwoIcons ? "6px" : "0px"}}>
                        <img src={icoRestricted} alt="Restricted post icon" className="iconImage"></img>
                    </div>
                    }
            >
                {banners.restricted.description}
            </HoverText>
        }
    </div>
  );
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
      ...ownProps,
      clientSettings: state.settings.clientSettings,
      restrictedBannerEnabled: state.settings.tenantSettings.bannerVisibility.showRestrictedBanner,
      lcidMappings: state.resources.lcidMappings
    }),
    {
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;
export default connector(PostTypeIcons);