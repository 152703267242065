import React from "react"

import Typography from "@mui/material/Typography";
import { Theme } from "@mui/material/styles";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      height: 48,
      marginBottom: 10,
      minHeight: 48
    },
    headerOptions: {
      display: "inline-flex",
      "& button, & div": {
        marginRight: 8,
        "&:last-child": {
          marginRight: 0
        }
      }
    },
    title: {
      display: "inline-flex",
      alignItems: "center"
    }
  })
);

const Header: React.FunctionComponent<ComponentProps> = props => {
  const classes = useStyles();
  
  return (
    <div className={classes.header}>
      <Typography variant="h1" className={classes.title}>{props.title}</Typography>
      {props.rightComponent &&
        <div className={classes.headerOptions}>{props.rightComponent}</div>
      }
    </div>
  );
}

interface ComponentProps {
  rightComponent?: JSX.Element;
  title: string | JSX.Element;
}

export default Header;