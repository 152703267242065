/* Designed to be a simpler version of the contentAnalyses component.
   Will lack some functionality intentionally, primary purpose for is for displaying data but not editing it.
*/

import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Button, useMediaQuery, useTheme } from "@mui/material";
import WhereToVoteIcon from '@mui/icons-material/WhereToVote'; // leaving for when we hook up recommended scores
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import HoverText from "modules/documents/components/action-buttons/hoverText"; // leaving for when we hook up recommended scores
import Disclaimer from "modules/common/components/disclaimer";
import { IIndicatorDetail } from "modules/common/components/authoring/models";
import Loading from "modules/common/components/loading";

import "../styles/contentAnalyses.sass";
import ReadabilityAndReachIndicators from "./readabilityAndReachIndicators";
import TextAndWords from "./textAndWords";
import ContentIssues from "./contentIssues";
import { PostAnalysisScores, PostAverages } from "modules/posts/models";
import PublicIcon from '@mui/icons-material/Public';
import { fallbackSentimentLabel } from "utils/readableLabelMapper";

const SYNC_ARROWS_SRC: string = "/images/icons/sync_arrows.svg";

interface IContentAnalysesProps {
    fetching: boolean;
    content?: string;
    contentAnalyses: PostAnalysisScores;
    tenantAverages: PostAverages;
    globalAverages: PostAverages;
    isUsingTenantAverages: boolean;
    setIsUsingTenantAverages: (toSet: boolean) => void;
    accordionStates: boolean[];
    onChangeAccordion: (expanded: boolean, key: ACCORDIONS) => void;
}

const SCORE_ICON_SRC: string = "/images/icons/change_history.svg";

// indices for open state array
enum ACCORDIONS {
    REACH_AND_READABILITY,
    TEXT_AND_WORDS,
    ISSUES
}

const ContentAnalysesSimple: React.FunctionComponent<IContentAnalysesProps> = ({
    contentAnalyses,
    tenantAverages,
    globalAverages,
    isUsingTenantAverages,
    fetching,
    accordionStates,
    onChangeAccordion,
    setIsUsingTenantAverages
}) => {
    const theme = useTheme();
    const isSmallAndSmaller = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
    let missingContentIssues = false;

    const {
        wordsPerSentence,
        sentencesPerParagraph,
        lettersPerWord,
        wordsPerParagraph,
        syllablesPerWord,
        sentenceCount,
        wordCount,
        readingTime,
    } = contentAnalyses || {};

    if(!contentAnalyses.lettersPerWord || !contentAnalyses.syllablesPerWord || !contentAnalyses.wordsPerSentence || !contentAnalyses.wordsPerParagraph || !contentAnalyses.sentencesPerParagraph) {
        missingContentIssues = true;
    }

    const readabilityIndicator: IIndicatorDetail = {
        minLabel: "Very difficult to read",
        maxLabel: "Very easy to read",
        recommendedScore: isUsingTenantAverages ? Math.max(tenantAverages.averageReadabilityScore, 1) : Math.max(globalAverages.averageReadabilityScore, 1), //Scores can be < 0 technically.
        minScore: 0,
        maxScore: 100,
        score: Math.max(Number.parseInt(contentAnalyses.readabilityScore), 1),
        scoreLabel: contentAnalyses.readabilityText
    }

    const toneIndicator:IIndicatorDetail = {
        minScore: 0,
        maxScore: 100,
        minLabel: "Formal",
        maxLabel: "Conversational",
        score: contentAnalyses.toneNumber,
        recommendedScore: isUsingTenantAverages ? tenantAverages.averageTone : globalAverages.averageTone,
        scoreLabel: contentAnalyses.tone
    }

    //Newer property, use neutral value if score doesn't exist.
    const personalismIndicator: IIndicatorDetail = {
        minScore: 0,
        maxScore: 100,
        minLabel: "Impersonal",
        maxLabel: "Personal",
        score: contentAnalyses.personalism,
        recommendedScore: isUsingTenantAverages ? tenantAverages.averagePersonalism : globalAverages.averagePersonalism,
        scoreLabel: contentAnalyses.personal
    }

    const sentimentIndicator: IIndicatorDetail = {
        minScore: 0,
        maxScore: 1,
        minLabel: "Negative",
        maxLabel: "Positive",
        score: contentAnalyses.sentimentScore,
        recommendedScore: isUsingTenantAverages ? (tenantAverages.averageSentiment / 100) : (globalAverages.averageSentiment / 100),
        scoreLabel: contentAnalyses?.sentiment ?? fallbackSentimentLabel(contentAnalyses.sentimentScore, 0, 1, "Negative", "Positive", false) //Fallback purely for old records.
    }

    const getAccordion = (key: ACCORDIONS, label: string, content: JSX.Element | undefined = <></>) => {
        return (
            <Accordion
                expanded={accordionStates[key]}
                elevation={0}
                onChange={(event: React.ChangeEvent, expanded: boolean) => onChangeAccordion(expanded, key)}
                classes={{
                    expanded: "expanded"
                }}
                className="content-analyses-accordion"
                style={{
                    width: isSmallAndSmaller ? "95%" : 428
                }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    style={{ fontWeight: 500 }}
                >
                    {label}
                </AccordionSummary>
                <AccordionDetails style={{ flexWrap: "wrap" }}>
                    {content}
                </AccordionDetails>
            </Accordion>
        );
    }

    return (
        <div className="content-analyses-container">
            <div className="content-analyses-header">
                <div className="content-analyses-header-item" style={{paddingLeft: "12px"}}>
                    {isUsingTenantAverages ?
                    <>
                        <WhereToVoteIcon htmlColor="#adb7c1" style={{ width: 13, color: "#adb7c1", marginRight: 5}} />
                        <HoverText labelClassName="legend-text" inline label="Internal avg.">
                            Average scores taken from your organization's most engaging posts.
                        </HoverText>
                    </>
                    :
                    <>
                        <PublicIcon htmlColor="#599FA0" style={{ width: 13, color: "#599FA0", marginRight: 5}} />
                        <HoverText labelClassName="legend-text-global" inline label="Global avg.">
                        Global average scores are taken from most engaging posts in Sparrow.
                        </HoverText>
                    </>}
                    <img alt="Your score" src={SCORE_ICON_SRC} width={16} style={{ marginRight: 5, marginLeft: 15, }} />
                    <span className="legend-text" style={{ color: "#3b78ab" }}>Current scores</span>

                </div>
                <Button color="primary" style={{marginLeft: "auto", marginRight: "20px"}} onClick={() => setIsUsingTenantAverages(!isUsingTenantAverages)} startIcon={
                    <img src={SYNC_ARROWS_SRC} style={{width: "26px"}} alt={"Swap average locale icon"}></img>
                }>
                    {isUsingTenantAverages ? "Use Global Scores" : "Use Internal Scores" }
                </Button>
            </div>
            {fetching
                    ? <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center" }}>
                        <Loading />
                    </div>
                    : <div className="content-analyses-content">
                        <Disclaimer
                            containerStyle={{ marginTop: 21, marginBottom: 15, width: isSmallAndSmaller ? "95%" : 428, boxSizing: "border-box" }}
                            icon={<InfoOutlinedIcon />}
                            text={"Content analysis applies to English text only."}
                        />
                        {getAccordion(
                            ACCORDIONS.REACH_AND_READABILITY,
                            "Reach & Readability",
                            <ReadabilityAndReachIndicators
                                isUsingTenantAverages={isUsingTenantAverages}
                                toneIndicator={toneIndicator}
                                readabilityIndicator={readabilityIndicator}
                                personalismIndicator={personalismIndicator}
                                sentimentIndicator={sentimentIndicator}
                            />)}
                        {getAccordion(
                            ACCORDIONS.TEXT_AND_WORDS,
                            "Text & Words",
                            <TextAndWords
                                wordCount={wordCount}
                                estimatedReadingTime={readingTime}
                                averageWordCount={isUsingTenantAverages ? tenantAverages.averageWordCount : globalAverages.averageWordCount}
                                usingGlobalStats={!isUsingTenantAverages}
                            />)}
                        {getAccordion(
                            ACCORDIONS.ISSUES,
                            "Issues",
                            !missingContentIssues
                                ? <ContentIssues
                                    totalSentenceCount={sentenceCount}
                                    totalWordCount={wordCount}
                                    hideHighlights={true}
                                    wordsPerParagraph={wordsPerParagraph}
                                    lettersPerWord={lettersPerWord}
                                    sentencesPerParagraph={sentencesPerParagraph}
                                    syllablesPerWord={syllablesPerWord}
                                    wordsPerSentence={wordsPerSentence}
                                />
                                : <Disclaimer
                                    icon={<InfoOutlinedIcon />}
                                    text={
                                    <React.Fragment>
                                        <span>Oops, something went wrong. </span>
                                        <br/>
                                        <span>Text issues are currently unavailable for this post.</span>
                                    </React.Fragment>
                                    }/>
                            )}
                    </div>
            }
        </div>
    );
}

export default ContentAnalysesSimple;
