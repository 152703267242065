import React, { useEffect, useState } from "react";

import { InputAdornment, InputProps, TextField } from "@mui/material";


interface IIconTextFieldProps {
    iconStart?: JSX.Element;
    iconEnd?: JSX.Element;
    InputProps?: InputProps;
    variant?: "standard" | "filled" | "outlined";
    value?: string;
    error?: boolean;
    errorMessage?: string;
    className?: string;
    size?: "small" | "medium";
    characterCount?: boolean;
    maxCount?: number;
    characterCountThreshold?: number;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

/**
 * A textfield with icons
 * - adapted from https://muhimasri.com/blogs/how-to-create-an-icon-text-field-component-with-material-ui/
 */
const IconTextField: React.FunctionComponent<IIconTextFieldProps> = ({
    iconStart,
    iconEnd,
    InputProps,
    className,
    characterCount,
    maxCount,
    characterCountThreshold = 0,
    size = "medium",
    error = false,
    errorMessage,
    value = "",
    ...props
}) => {
    const [internalValue, setInternalValue] = useState<string>(value);

    useEffect(() => {
        setInternalValue(value);
    }, [value]);

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let newValue = event.target.value;
        let effectiveNewValue = maxCount && newValue.length > maxCount
            ? internalValue // keep value at max count
            : newValue;

        setInternalValue(effectiveNewValue);
    }

    const getErrorStyles = (): React.CSSProperties | undefined => {
        let styles: React.CSSProperties | undefined = undefined;
        if (error)
            styles = { borderColor: "#B72026 " };

        return styles;
    }

    const errorStyles = getErrorStyles();

    return (
        <div>
            <TextField
                size={size}
                className={className}
                onChange={onChange}
                value={internalValue}
                variant={props.variant}
                InputProps={{
                    className: errorStyles ? "icon-text-input-error" : "",
                    style: {
                        height: 40,
                    },
                    startAdornment: iconStart ? (
                        <InputAdornment position="start">{iconStart}</InputAdornment>
                    ) : undefined,
                    endAdornment: iconEnd ? (
                        <InputAdornment position="end">{iconEnd}</InputAdornment>
                    ) : undefined,
                    onBlur: props.onBlur,
                    onFocus: props.onFocus
                }}
            />
            {characterCount && maxCount && internalValue.length >= characterCountThreshold &&
                <div style={{ textAlign: "right" }}>
                    <span style={{
                        color: "#aaaaaa",
                        fontSize: 11,
                    }}>
                        {internalValue.length}/{maxCount}
                    </span>
                </div>}
            {error && errorMessage && <p style={{ color: "#b72026" }}>
                {errorMessage}
            </p>}
        </div>
    );
};

export default IconTextField;
