import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import { withRouter, RouteComponentProps } from "react-router";
import TenantSelection from "modules/tenant/components/tenantSelection";
import { SetTenantIdAction } from "modules/tenant/actions";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus, RedirectRequest } from "@azure/msal-browser";

import MsalAuthModule from "./msalAuthModule";
import useQueryParams, { QUERY_PARAM_KEYS } from "modules/common/hooks/useQueryParams";
import Cookies from "utils/cookie";

const RequireLogin: React.FunctionComponent<PropsWithRedux> = (props) => {
    const isAuthenticated = useIsAuthenticated();
    const { inProgress } = useMsal();
    const query = useQueryParams();

    React.useEffect(() => {
        /**
         * Helper to build login request configs to send to b2c
         */
        const getMSALRedirectConfig = (): RedirectRequest => {
            let config = {} as RedirectRequest;
            let signedOutSuccess = query.get(QUERY_PARAM_KEYS.SIGNED_OUT_SUCCESS);
            config.extraQueryParameters = { signedOutSuccess: signedOutSuccess || "false" };

            return config;
        };

        const tryLoginAsync = async () => {
            // If a user is not logged in and authentication is not already in progress, invoke login
            if (!isAuthenticated && inProgress === InteractionStatus.None) {
                let redirectConfig = getMSALRedirectConfig();
                await MsalAuthModule.getInstance().login(redirectConfig);
            }
        }

        tryLoginAsync();
    }, [isAuthenticated, inProgress, query]);

    const tenantId = checkTenantId() || props.tenantId;
    if (tenantId) props.SetTenantId(tenantId);

    return (
        <>
            {isAuthenticated &&
                <>
                    {tenantId ? props.children : <TenantSelection />}
                </>
            }
        </>
    );
};

const checkTenantId = (): string | undefined => {
    let tenantCookie = Cookies.get("tenantId");
    return tenantCookie;
};

interface ComponentProps { }

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps & RouteComponentProps) => ({
        ...ownProps,
        tenantId: state.tenant.id,
    }),
    {
        SetTenantId: (tenantId: string) => SetTenantIdAction({ tenantId }),
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default withRouter(connector(RequireLogin));
