import { Button, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import * as React from 'react';

const ContentDeletedDialog: React.FunctionComponent<ComponentProps> = (props) => {
    return (
        <Dialog open={props.isOpen} onClose={props.closeDialog}>
            <DialogTitle style={{paddingBottom: "0px"}}>
                <div style={{display: "flex", flexDirection: "row"}}>
                    <Typography variant="h2"><span style={{textTransform: "capitalize"}}>{props.contentType}</span> is no longer available</Typography>
                    <IconButton
                        onClick={props.closeDialog}
                        style={{marginLeft: "auto", position: "relative", top: "-12px"}}
                        size="large">
                        <CloseIcon />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                <div style={{display: "flex", flexDirection: "column"}}>
                    Unfortunately, this {props.contentType} has been deleted and no longer exists in your platform.
                    <Button onClick={props.closeDialog} style={{marginLeft: "auto", marginTop: "20px"}}>{props.returnButtonText}</Button>
                </div>
            </DialogContent>
        </Dialog>
    );
}

interface ComponentProps {
    contentType: string;
    returnButtonText: string;
    isOpen: boolean;
    closeDialog: () => void;
}

export default ContentDeletedDialog;