import * as React from "react";
import LoadingSpinner from '../loadingSpinner';

import Button from "@mui/material/Button";

import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { FileDownloader } from "utils/fileDownloader";


interface IProps {
  onClick: () => Promise<any>;
  text: string;
  downloadName: string;
  fileType?: string;
}

interface IState {
  isLoading: boolean
}

export default class DownloadButton extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      isLoading: false
    };
  }

  public render() {
    return (
      <Button
        variant="outlined"
        color="primary"
        startIcon={<SaveAltIcon />}
        disabled={this.state.isLoading}
        onClick={this.onClick}
      >
        {
          this.state.isLoading ? <LoadingSpinner size="inherit" /> :
          <span>
            {this.props.text}
          </span>
        }
      </Button>
    );
  }

  private onClick = (e) => {
    e.stopPropagation();
    this.setState({isLoading: true}, () => {

      const file = {
        name: this.props.downloadName,
        type: this.props.fileType
      }
      
      this.props.onClick()
        .then(data => new FileDownloader(file).downloadBlob(data))
        .then(_ => this.setState({isLoading: false}))
        .catch(_ => this.setState({isLoading: false}));
    
    });
  }

}
