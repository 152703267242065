import { ApiRequest } from './network';
import { SyncFunctionsGraphApi } from './network/adapters/SyncFunctionsGraphApi';
import { SyncFunctionsOktaApi } from './network/adapters/SyncFunctionsOktaApi';
import { SyncAction, SyncType } from 'modules/audiences/models';

export class SyncApi {
    constructor(private _syncFunctionsGraphApi: SyncFunctionsGraphApi, private _syncFunctionsOktaApi: SyncFunctionsOktaApi) {
    }

    public searchForGroupByName = (groupName: string) => {
        return new ApiRequest(
            this._syncFunctionsGraphApi.CreateRequest()
                .post('api/groups/search', 
                { params: { groupName: groupName }})
        );
    }
    
    public getUserGroupSuggestions = (searchText: string, amount: number, skipToken: string) => {
        return new ApiRequest(
            this._syncFunctionsGraphApi.TenantRequest()
                .get('api/{tenant}/groups/search', 
                { params: { searchText, amount, skipToken } } )
        );
    }

    public getOktaUserGroupSuggestions = (searchText: string, amount: number, skipToken: string) => {
        return new ApiRequest(
            this._syncFunctionsOktaApi.TenantRequest()
                .get('api/{tenant}/groups/search', 
                { params: { searchText, amount, skipToken } } )
        );
    }

    public startGroupSync = (syncAction: SyncAction[], syncType: string = "") : ApiRequest<any> => {
        if(syncType === SyncType.MicrosoftAD){
            return new ApiRequest(
                this._syncFunctionsGraphApi.TenantRequest()
                    .post('api/{tenant}/groups/sync', 
                    { groupIds: syncAction })
            );
        }
        else if(syncType === SyncType.Okta){
            return new ApiRequest(
                this._syncFunctionsOktaApi.TenantRequest()
                    .post('api/{tenant}/groups/sync', 
                    { groupIds: syncAction })
            );
        }
        else{
            throw new Error("syncType is missing from tenantSettings, unable to proceed.");
        }
    }

    public getUsersInGroup = (id: string) => {
        return new ApiRequest(
            this._syncFunctionsGraphApi.CreateRequest()
            .get('api/groups/{id}/members',
            {params: {id} } )
        );
    }

    public manageAdUserSync = (action: string) => {
        return new ApiRequest(
            this._syncFunctionsGraphApi.TenantRequest()
            .post('api/{tenant}/users/sync', { action: action})
        )
    }
}