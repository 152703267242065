import { CategoryTagDetails, CategoryTagFilter, CategoryTagModel } from "modules/categorytags/models";
import * as actions from "network/actions";

export const CreateCategoryTag = (toUpload: CategoryTagModel) => {
    return actions.NetworkRequest({
        headers: { "content-type": "application/json" },
        method: "POST",
        url: `/{tenant}/adminapi/v1/categoryTags`,
        body: JSON.stringify(
            toUpload
        )
    });
}

export const EditCategoryTag = (toUpload: CategoryTagModel) => {
    return actions.NetworkRequest({
        headers: { "content-type": "application/json" },
        method: "PUT",
        url: `/{tenant}/adminapi/v1/categoryTags/modify/${toUpload.id}`,
        body: JSON.stringify(
            toUpload
        )
    });
}

export const GetCategoryTags = (pageNumber: number, filters: Partial<CategoryTagFilter>) => {
    filters.pageNumber = pageNumber;
    return actions.NetworkRequest({
      headers: { "content-type": "application/json" },
      method: "POST",
      url: `/{tenant}/adminapi/v1/categoryTags/filtered`,
      body: filters
    });
}
  
export const GetDisabledCategoryTags = (pageNumber: number, filters: Partial<CategoryTagFilter>) => {
    filters.pageNumber = pageNumber;
    filters.enabled = false;
    return actions.NetworkRequest({
        headers: { "content-type": "application/json" },
        method: "POST",
        url: `/{tenant}/adminapi/v1/categoryTags/filtered`,
        body: filters
    });
}
  
export const GetEnabledCategoryTags = (pageNumber: number, filters: Partial<CategoryTagFilter>) => {
    filters.pageNumber = pageNumber;
    filters.enabled = true;
    return actions.NetworkRequest({
        headers: { "content-type": "application/json" },
        method: "POST",
        url: `/{tenant}/adminapi/v1/categoryTags/filtered`,
        body: filters
    });
}

export const GetUserCategoryTags = () => {
    return actions.NetworkRequest({
        method: "GET",
        url: `/{tenant}/api/v1/categoryTags/list`
    });
}

export const GetCategoryTagsById = (id: string) => {
    return actions.NetworkRequest({
        method: "GET",
        url: `/{tenant}/adminapi/v1/categoryTags/${id}`
    })
}

export const DeleteCategoryTag = (id: string) => {
    return actions.NetworkRequest({
        method: "DELETE",
        url: `/{tenant}/adminapi/v1/categoryTags/${id}`,
    })
}

export const ChangeCategoryTagState = (enabled: boolean, id: string) => {
    return actions.NetworkRequest({
        headers: { "content-type": "application/json" },
        method: "PUT",
        url: `/{tenant}/adminapi/v1/categoryTags/modify/${id}`,
        body: JSON.stringify({enabled: enabled})
    })
}

export const GetCategoryTagDownload = (id: string) => {
    return actions.NetworkRequest({
        method: "GET",
        url: `/{tenant}/adminapi/v1/categoryTags/analytics/download/${id}`
    })
}

export const GetCategoryTagDownloadFromAnalytics = (toDownload: CategoryTagDetails) => {
    return actions.NetworkRequest({
        method: "POST",
        url: `/{tenant}/adminapi/v1/categoryTags/analytics/download`,
        body: JSON.stringify(toDownload)
    })
}

export const GetCategoryTagAnalytics = (id: string) => {
    return actions.NetworkRequest({
        method: "GET",
        url: `/{tenant}/adminapi/v1/categoryTags/analytics/${id}`
    })
}

export const DownloadAllCategoryTags = () => {
    return actions.NetworkRequest({
        method: "POST",
        url: `/{tenant}/adminapi/v1/categoryTags/analytics/massDownload`
    })
}

export const DownloadCategoryTags = (ids: string[]) => {
    return actions.NetworkRequest({
        method: "POST",
        url: `/{tenant}/adminapi/v1/categoryTags/analytics/massDownload`,
        body: JSON.stringify(ids)
    })
}