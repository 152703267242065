import * as React from "react"
import TenantLink from "modules/common/components/tenantLink";

export interface DashboardTileProps {
    actionText: string;
    materialIconName?: string;
    materialIconColor?: string;
    linkTo?: string;
    icon?: JSX.Element;
}

export default class DashboardTile extends React.PureComponent<DashboardTileProps, {}> {
    public render() {
        return (
            <TenantLink className="dashboard-tile" to={!!this.props.linkTo ? this.props.linkTo : ''}>
                {this.props.icon
                    ? this.props.icon
                    : <i className="dashboard-tile-icon material-icons"
                        style={{ color: this.props.materialIconColor }}>{this.props.materialIconName}</i>}
                <span className="dashboard-tile-action">
                    {this.props.actionText}
                </span>
            </TenantLink>
        );
    }
}
