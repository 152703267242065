import * as React from "react";

import Collapse from "@mui/material/Collapse";
import Switch from "@mui/material/Switch";

import moment from "moment";
import DateAndTime from "modules/common/components/forms/inputs/dateAndTime";
import HoverText from "modules/documents/components/action-buttons/hoverText";

class FeaturedTime extends React.Component<ComponentProps, ComponentState> {
    constructor(props: ComponentProps) {
        super(props);

        this.state = {
            showFeaturedTime: !!this.props.featuredTime
        };
    }

    public render() {
        const { featuredTime, publishTime, expiryTime } = this.props;

        const isBackdated: boolean = moment(publishTime).isBefore(new Date());
        const nonBackdatedLimit = moment(this.props.publishTime || new Date()).add(14, "days");

        const minFeaturedTime = isBackdated 
            ? moment(publishTime) 
            : moment(publishTime || new Date());

        //If it's not backdated, check if the 14 day hard limit or the expiry time is closer to now and use that one.
        const maxFeaturedTime = isBackdated 
            ? moment(new Date()).add(14, "days") 
            : (expiryTime && nonBackdatedLimit.isAfter(expiryTime) 
                ? moment(expiryTime) 
                : nonBackdatedLimit);

        return (
            <>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: 15
                }}>
                    <div>
                        <span>Set as </span>
                        <HoverText inline label="Featured News">
                            Displays the Featured News banner on the post until it the specified date.
                        </HoverText>
                    </div>
                    <Switch
                        color="primary"
                        checked={this.state.showFeaturedTime}
                        onChange={this.onToggleFeaturedTime}
                    />
                </div>
                <div className="collapse-container">
                    <Collapse in={this.state.showFeaturedTime}>
                        <DateAndTime
                            avoidPickerViewLayoutShift
                            minDate={minFeaturedTime}
                            maxDate={maxFeaturedTime}
                            onChangeDate={this.props.onChange}
                            label="Until"
                            date={featuredTime}
                            views={["month", "day"]}
                        />
                    </Collapse>
                </div>
            </>
        );
    }

    private onToggleFeaturedTime = () => {
        if (!this.state.showFeaturedTime)
            this.props.onChange(moment().add(14, "days").toISOString());
        else
            this.props.onChange(null);

        this.setState({ showFeaturedTime: !this.state.showFeaturedTime });
    }
}


interface ComponentProps {
    featuredTime: string | undefined;
    publishTime: string | undefined;
    expiryTime: string | undefined;
    onChange: (featuredTime: string | null | undefined) => void;
}

interface ComponentState {
    showFeaturedTime: boolean;
}

export default FeaturedTime;
