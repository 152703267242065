import React from "react";
import { Check, Close } from "@mui/icons-material";

import {
    CONTENT_BAND_LAYOUT_TYPE_MAP,
    ContentBandContentType,
    ContentBandLayoutType,
    IContentBand,
    READ_STATUS_MAP,
    ReadStatus,
    SORT_STYLE_MAP,
} from "modules/contentBands/models";
import { TenantSettingsTag, TenantSettingsTagGroup } from "modules/settings";
import ChipList from "modules/common/components/chipList";
import { SortStyle } from "utils/managementUtils";

interface IContentBandPublishSettingsProps {
    contentBand: IContentBand;
    activeLcid: string;
    userTagGroups: TenantSettingsTagGroup[];
}

export const ContentBandPublishSettings: React.FunctionComponent<IContentBandPublishSettingsProps> = ({
    contentBand,
    userTagGroups,
}) => {
    const getSetting = (isOn: boolean, label: string): JSX.Element => {
        return (
            <div className={isOn ? "setting-on" : "setting-off"}>
                {isOn ? <Check htmlColor="#366531" /> : <Close htmlColor="7f7f7f" />}
                <span>{label}</span>
            </div>
        );
    };

    const getContentBlurb = (): string => {
        let result =
            `${contentBand.contentCount} ${READ_STATUS_MAP[contentBand.lockToReadStatus || ReadStatus.Both]} ` +
            `${contentBand.contentType}s, sorted by ${SORT_STYLE_MAP[contentBand.sortBy || SortStyle.publishDesc]}, ` +
            `${contentBand.pinnedIds?.length || 0} pinned`;

        return result;
    };

    const getTopicChips = () => {
        const topicIds = contentBand.lockToTopicIds || [];

        const chips = userTagGroups
            .flatMap((group: TenantSettingsTagGroup) => group.tags)
            .filter((userTag: TenantSettingsTag) => topicIds.includes(userTag.id))
            .map((userTag: TenantSettingsTag) => ({ ...userTag }));

        return chips;
    };

    const isPosts = contentBand.contentType === ContentBandContentType.Post;
    const bandTypes: string[] = contentBand.lockToTypes || [];
    const isCards = contentBand.layoutType === ContentBandLayoutType.Card;
    const isWindow = contentBand.layoutType === ContentBandLayoutType.Window;
    const isCarousel = contentBand.layoutType === ContentBandLayoutType.Carousel;

    return (
        <>
            <div className="setting-container">
                <span className="setting-label">Layout</span>
                <div className="setting">
                    <span>
                        {contentBand.layoutType ? CONTENT_BAND_LAYOUT_TYPE_MAP[contentBand.layoutType] : CONTENT_BAND_LAYOUT_TYPE_MAP.card}
                    </span>
                    {isCards && getSetting(!!contentBand.displayFullTitles, "Display full title")}
                    {(isCards || isWindow) && getSetting(!!contentBand.seeMoreEnabled, "See more link")}
                    {isCarousel && getSetting(!!contentBand.autoPlayCarousel, "Auto-plays")}
                </div>
            </div>
            <div className="setting-container">
                <span className="setting-label">Content</span>
                <div className="setting">
                    <span>{getContentBlurb()}</span>
                    {isPosts ? (
                        <>
                            {getSetting(bandTypes.includes("standard"), "Standard")}
                            {getSetting(bandTypes.includes("mandatory"), "Mandatory")}
                            {getSetting(bandTypes.includes("compliance"), "Compliance")}
                            {getSetting(bandTypes.includes("public"), "Public")}
                        </>
                    ) : (
                        <>
                            {getSetting(bandTypes.includes("informational"), "Informational")}
                            {getSetting(bandTypes.includes("standard"), "Standard RSVP")}
                            {getSetting(bandTypes.includes("mandatory"), "Mandatory RSVP")}
                        </>
                    )}
                </div>
            </div>
            <div className="setting-container">
                <span className="setting-label">Topics</span>
                <div className="setting">
                    <div className="chip-container">
                        <ChipList
                            items={getTopicChips()}
                            truncateAtIndex={4}
                            showEmptyChip
                            emptyLabel="All topics"
                            emptyStyle={{ width: 89, fontSize: 14 }}
                            chipStyle={{
                                marginRight: "8px",
                                marginBottom: "4px",
                                backgroundColor: "#e0e0e0", //Background color prevents background change on hover.
                                fontSize: 14,
                            }}
                        />
                    </div>
                    {getSetting(contentBand.ignoreSubscriptions, "Ignore topic subscriptions")}
                </div>
            </div>
        </>
    );
};
