import React from "react";

import { DocumentPreview } from "../../../models";

import PersonIcon from "@mui/icons-material/Person";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";


const DocumentPreviewInfo: React.FunctionComponent<ComponentProps> = props => {
  const { preview } = props;

  return (
    <div>
      <div className="document-title">{preview.title || `${preview.fileName}.${preview.fileType}`}</div>
      {(!!preview.totalDownloads || !!preview.uniqueDownloads) &&
        <div className="document-views">
          <div>
            <PersonIcon fontSize="small" />
            <span>{preview.uniqueDownloads} unique view(s)</span>
          </div>
          <div>
            <VisibilityOutlinedIcon fontSize="small" />
            <span>{preview.totalDownloads} total view(s)</span>
          </div>
        </div>
      }
      {preview.tags.length > 0 &&
        <div>
          <span>Category tags: </span>
          {preview.tags.slice(0, 3).map(tag => tag.name).join(", ")}{preview.tags.length > 3 ? `, ... +${preview.tags.length - 3}` : ""}
        </div>
      }
      <div>
        {preview.availability === "attachmentOnly"
          ? "Available as an attachment only"
          : "Available online only"
        }
      </div>
    </div>
  );
}


interface ComponentProps {
  preview: DocumentPreview;
}

export default DocumentPreviewInfo;