import React from "react";
import { Select, FormControl, MenuItem } from "@mui/material";
import { ReadStatus } from "modules/contentBands/models";
import { IContentBandSettingsInputProps } from "../editor/contentBandSettingsForm";

export const ReadStatusInput: React.FunctionComponent<IContentBandSettingsInputProps<ReadStatus>> = ({ idx, value, onChange }) => {
    return (
        <div className="form-group">
            <label>Lock to read status</label>
            <FormControl>
                <Select
                    id={`cb-read-status-${idx}`}
                    className="input"
                    value={value}
                    variant="outlined"
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => onChange(evt.target.value as ReadStatus, idx)}
                    MenuProps={{ disablePortal: true }} // clickawaylistener breaks with selects as children when portal is enabled (more info: https://mui.com/joy-ui/api/menu/#Menu-prop-disablePortal)
                >
                    <MenuItem id={`cb-read-status-${idx}-b`} value={ReadStatus.Both}>
                        Both read and unread content
                    </MenuItem>
                    <MenuItem id={`cb-read-status-${idx}-r`} value={ReadStatus.Read}>
                        Read content only
                    </MenuItem>
                    <MenuItem id={`cb-read-status-${idx}-u`} value={ReadStatus.Unread}>
                        Unread content only
                    </MenuItem>
                </Select>
            </FormControl>
        </div>
    );
};
