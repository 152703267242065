import * as React from "react";
import { GlobalApplicationState } from "globalApplicationState";
import { ConnectedProps, connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { push } from "react-router-redux";
import { actions } from "../..";
import moment from "moment";

import { audiencesApi, usersApi } from "api/instances";

import { UserDetails, UserEdit, UserDetailsAudience } from "../../models";

import ErrorSnackbar from "modules/common/components/snackbars/errorSnackbar";
import Loading from "modules/common/components/loading";
import LoadingOverlay from "modules/common/components/loadingOverlay";

import Account from "./account";
import ProfileDetails from "./profileDetails";
import UnableToEditMessage from "./unableToEditMessage";

import confirm from "utils/notyPopups";

import BasePage from "pages/common/basePage";
import Breadcrumb from "pages/common/breadcrumb";
import Columns from "pages/common/columns";
import MainContent from "pages/common/mainContent";

import Button from "@mui/material/Button";

import "modules/profile/styles/profile.sass";
import "../../styles/userProfile.sass";
import { passwordRegex } from "../../../../utils/regexUserValidation";
import MoreUserProfileOptions from "./moreUserProfileOptions";
import PermissionsDrawer from "../dialogs/permissionsDrawer";
import SuccessSnackbar from "modules/common/components/snackbars/successSnackbar";


type Tabs = "details" | "settings";

class UserProfilePage extends React.PureComponent<PropsWithRedux, ComponentState> {
  constructor(props: PropsWithRedux) {
    super(props);

    this.applyAudienceChanges = this.applyAudienceChanges.bind(this);

    this.state = {
      includableAudiences: [],
      user: {},
      userDetails: undefined,
      changedSinceSaved: false,
      password: "",
      selectedAudiences: [],
      selectedTab: "details",
      errorMessage: "",
      successMessage: "",
      loading: false,
      saving: false,
      errorPassword: false,
      errorFirstname: false,
      errorLastname: false,
      permissionsOpen: false,
    };
  }

  componentDidMount() {
    moment.locale("en");

    this.setState({ loading: true });

    if (!!this.props.match.params.userId) {
      usersApi.GetUserById(this.props.match.params.userId)
        .then((response: UserDetails) => {
          if (response.accountType !== "default")
            this.setState({ selectedAudiences: response.securityDetails.audiences, userDetails: response, loading: false });
          else
            this.props.redirectTo("/" + this.props.tenantId + "/admin/users");
        }).catch(() => {
          this.props.redirectTo("/" + this.props.tenantId + "/admin/users");
        });

      usersApi.GetIncludableAudiences(this.props.match.params.userId)
        .then((response) => {
          this.setState({ includableAudiences: response });
        });
    } else {
      this.props.redirectTo("/" + this.props.tenantId + "/admin/users");
    }
  }

  componentWillUnmount() {
    this.setState({
      includableAudiences: [],
      selectedAudiences: [],
      user: {},
      userDetails: undefined,
      password: "",
      errorPassword: false,
      errorFirstname: false,
      errorLastname: false
    });
  }

  public render() {
    const { userDetails } = this.state;

    if (this.state.loading || !userDetails)
      return <Loading />;

    const name = userDetails.standardDetails.preferredName ? `${userDetails.standardDetails.lastName}, ${userDetails.standardDetails.firstName} (${userDetails.standardDetails.preferredName})` :
    userDetails.standardDetails.firstName && userDetails.standardDetails.lastName ? `${userDetails.standardDetails.lastName}, ${userDetails.standardDetails.firstName}` :
      "[Name Not Provided]";

    //Get the current state of the account WITH edits, rather than what's on the server.
    const enabledStatus = this.state.user.isEnabled ?? this.state.userDetails!.isEnabled; 

    return (
      <BasePage fullWidth>
        <Breadcrumb
          items={[
            { title: "Manage Users", onClick: this.onBackToManageUsers },
            { title: `${name}` },
          ]}
          backItem={{ title: "Back to Users & Permissions", onClick: this.onBackToManageUsers }}
          rightComponent={
            <React.Fragment>
              <MoreUserProfileOptions 
                userDetails={this.state.userDetails} 
                syncType={this.props.syncType}
                deleteUser={this.deleteUser}
                disableUser={this.disableUser}
                enableUser={this.enableUser}
                enabledStatus={enabledStatus}
              />

              <Button variant="contained" color="primary" disabled={!userDetails || !this.state.changedSinceSaved || this.state.errorFirstname || this.state.errorLastname} onClick={this.saveChanges}>Save changes</Button>
            </React.Fragment>
          }
        />
        <Columns
          slim
          leftComponent={
            <div className="user-profile-left-column">
              <MainContent id="user-account" addPadding>
                <Account userDetails={userDetails!} onChangeUser={this.onChangeUser} isEnabled={enabledStatus} onOpenDrawer={this.openDrawer}/>
                <PermissionsDrawer isOpen={this.state.permissionsOpen} closeDrawer={this.closeDrawer} userData={this.state.userDetails!} onSave={this.updatePermissions} disableUser={this.disableUser}/>
              </MainContent>
              <UnableToEditMessage />
            </div>
          }
          rightComponent={
            <MainContent id="user-profile" addPadding>
              <ProfileDetails
                localAccountPrefix={this.props.localAccountPrefix}
                password={this.state.password}
                errorPassword={this.state.errorPassword}
                errorFirstname={this.state.errorFirstname}
                errorLastname={this.state.errorLastname}
                userDetails={userDetails!}
                onChangePassword={this.onChangePassword}
                onChangeUser={this.onChangeUser}
                onSMSVerifyMessage={this.sendSMSVerifyMessage}
                onChangeAudiences={this.onChangeAudiences}
                selectedAudiences={this.state.selectedAudiences}
                includableAudiences={this.state.includableAudiences}
                syncLevel={this.props.syncLevel}
              />
            </MainContent>
          }
        />
        <LoadingOverlay absolute={true} show={this.state.saving} />
        <SuccessSnackbar successMessage={this.state.successMessage || ""} clearSuccessMessage={() => this.setState({ successMessage: "" })} />
        <ErrorSnackbar errorMessage={this.state.errorMessage} clearErrorMessage={this.onClearMessage} />
      </BasePage>
    );
  }

  private enableUser = () => {
    this.onChangeUser({ isEnabled: true });
  }

  private disableUser = () => {
    this.onChangeUser({ isEnabled: false });
  }


  private onBackToManageUsers = async () => {
    if (this.state.changedSinceSaved) {
      if (!(await confirm.show({
          title: "Back to Users & Permissions",
          text: (
            <div style={{ minWidth: 400 }}>
              <div style={{ fontWeight: 500 }}>Your changes will not be saved.</div>
              <br />
              <div>Do you want to continue?</div>
            </div>
          ),
          yesText: "Keep editing",
          noText: "Exit"
      }))) {
        this.setState({ changedSinceSaved: false });
        this.props.redirectTo("/" + this.props.tenantId + "/admin/users");
      }
    } else {
      this.props.redirectTo("/" + this.props.tenantId + "/admin/users");
    }
  }

  private onChangePassword = (password: string) => {
    this.setState({ changedSinceSaved: true, password });
  }

  private onChangeUser = (value: Partial<UserEdit>) => {
    if(this.state.errorFirstname || this.state.errorLastname) {
      this.onClearMessage();
    }
    this.setState({ changedSinceSaved: true, user: { ...this.state.user!, ...value }});
  }

  private onClearMessage = () => {
    this.setState({ errorMessage: "", errorPassword: false, errorFirstname: false, errorLastname: false });
  }

  private onChangeAudiences = (audiences: UserDetailsAudience[]) => {
    this.setState({ changedSinceSaved: true, selectedAudiences: audiences });
  }

  private deleteUser = async () => {
    if (await confirm.show({
      title: "Delete user",
      text: "Are you sure you want to delete this user? You can't undo this action.",
      yesColor: "#a80000",
      yesText: "Delete",
      noText: "Cancel"
    })) {
      this.setState({ saving: true });
      usersApi.DeleteUser(this.state.userDetails!.id, this.state.userDetails!.accountCategory)
        .then(async () => {
          await confirm.show({
            title: "User deleted",
            text: (
              <div className="success-checkmark-pop-dialog">
                <div className="success-checkmark-pop-wrapper">
                  <div className="success-checkmark-pop-bg">
                    <div className="success-checkmark-pop-checkmark"></div>
                  </div>
                </div>
                <div>This user has been successfully deleted.</div>
              </div>
            ),
            yesText: "Ok",
            hideNo: true
          });
          this.props.redirectTo("/" + this.props.tenantId + "/admin/users");
        })
        .catch(() => {
          this.setState({ errorMessage: "Something went wrong deleting this user. Please try again.", saving: false });
        });
    }
  }


  private saveChanges = () => {
    this.setState({ saving: true });

    if(this.state.password === "" || this.state.password.match(passwordRegex)) {
      let user = this.state.user;
      let accountCategory = this.state.userDetails?.accountCategory === "sparrow" || this.state.userDetails?.accountCategory === "social";

        if(this.state.user.firstName === "" && accountCategory ) {
          this.setState({ errorFirstname: true, saving: false });
        } 

        if(this.state.user.lastName === "" && accountCategory) {
          this.setState({ errorLastname: true, saving: false });
        } 

        if(!this.state.errorFirstname && !this.state.errorLastname) {
        this.props.saveUser(this.state.userDetails!.id, user)
          .then((userDetails) => {
            this.setState({ changedSinceSaved: false, userDetails: userDetails || this.state.userDetails, saving: false });
        });

        /** NOTE: Remove this and uncomment the above code once addSubscribedTags and removeSubscribedTags has been updated to addSubscribedAudiences and removeSubscribedAudiences */
        if (this.state.userDetails!.securityDetails.audiences !== this.state.selectedAudiences) {
          audiencesApi.updateAudiencesForUser(this.state.selectedAudiences.map(audience => audience.id), this.state.userDetails!.id);
        }

        if (!!this.state.password) {
          usersApi.ResetLocalUserPassword(this.state.userDetails!.id, this.state.password).then(() => {
            this.setState({ password: "" });
          });
        }
      }
    } else {
      this.setState({ errorMessage:"Password must be 8-32 characters long and must contain at least one capital letter, one special character and one number.", errorPassword: true, saving: false });
    }
  }

  private applyAudienceChanges = (audiences: UserDetailsAudience[]) => {
    this.setState({ selectedAudiences: audiences });
  }

  private openDrawer = () => {
    this.setState({ permissionsOpen: true });
  }

  private closeDrawer = () => {
    this.setState({ permissionsOpen: false });
  }

  private sendSMSVerifyMessage = (id: string) => {
    this.props.sendSMSVerify(id)
    .then((response) => {
        if(response === true) {
            this.setState({ successMessage: "An SMS verification and opt-in invite was sent to your number." })
        } else {
            this.setState({ errorMessage: "Something went wrong. Please try again." })
        }
    })
  }

  private updatePermissions = () => {
    this.setState({ permissionsOpen: false, loading: true });
    if (!!this.props.match.params.userId) {
      usersApi.GetUserById(this.props.match.params.userId)
      .then((response: UserDetails) => {
        if (response.accountType !== "default")
          this.setState({ userDetails: response, loading: false, successMessage: "Successfully updated user permissions!" });
      });
    }
  }
}


interface RouteParams {
  userId: string;
}

interface ComponentProps {}

interface ComponentState {
  includableAudiences: UserDetailsAudience[];
  userDetails: UserDetails | undefined;
  user: Partial<UserEdit>;
  changedSinceSaved: boolean;
  password: string;
  selectedAudiences: UserDetailsAudience[];
  selectedTab: Tabs;
  errorMessage: string;
  loading: boolean;
  saving: boolean;
  errorPassword: boolean;
  errorFirstname: boolean;
  errorLastname: boolean;
  permissionsOpen: boolean;
  successMessage: string;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps & RouteComponentProps<RouteParams>) => ({
    ...ownProps,
    localAccountPrefix: state.settings.tenantSettings.localAccountPrefix,
    tenantId: state.tenant.id,
    syncType: state.settings.tenantSettings.syncConfig.syncType,
    syncLevel: state.settings.tenantSettings.syncConfig.syncLevel,
  }),
  {
    saveUser: actions.updateUser,
    sendSMSVerify: actions.sendSMSVerify,
    redirectTo: push,
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;
export default connector(UserProfilePage);
