import { GlobalApplicationState } from "globalApplicationState";
import { connect, ConnectedProps } from "react-redux";
import { Button, Menu, MenuItem } from "@mui/material";
import * as React from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

interface ComponentProps { 
    openAddModal: () => void;
    closeAddModal: () => void;
    redirectToUserCreation: () => void;
}

const AddUserButtonDropdown: React.FunctionComponent<PropsWithRedux> = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const socialUserClick = () => {
        setAnchorEl(null);
        props.openAddModal();
    }

    const sparrowUserClick = () => {
        setAnchorEl(null);
        props.redirectToUserCreation();
    }

    return (
        <div>
            <Button
                id="add-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                variant="contained"
                color="primary"
                endIcon={<ArrowDropDownIcon/>}
            >
                Add New
            </Button>
            <Menu
                id="add-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'add-button',
                }}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                PaperProps={{
                    style: {
                      transform: 'translateY(6px)',
                    }
                }}
            >
                {props.localAccountsEnabled && <MenuItem onClick={sparrowUserClick}>Sparrow User</MenuItem>}
                <MenuItem onClick={socialUserClick}>Social User</MenuItem>
                {/*<MenuItem onClick={handleClose}>Permission</MenuItem>*/}
            </Menu>
        </div>
    )
}


const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        localAccountsEnabled: state.settings.tenantSettings.localAccountsEnabled,
    }), 
    {
    }
);

type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(AddUserButtonDropdown);