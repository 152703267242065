import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { GlobalApplicationState } from 'globalApplicationState';

interface ComponentProps { 
    returnTranslatedContent: (content, platform, event) => any;
    maxTextLength?: number;
    disabled?: boolean;
    placeholder?: string;
    prompt: string;
    toLoad?: any;
    event: string;
    platform: string;
}

interface ComponentState {
    defaultLCID: string;
    selectedLCID: string;
}


class SingleTextAreaInput extends React.Component<PropsWithRedux, ComponentState> {

    constructor(props) {
        super(props);
        this.state = {
            defaultLCID: this.props.tenantSettings.defaultLCID,
            selectedLCID: this.props.tenantSettings.defaultLCID,
        }
    }

    public componentDidMount()  {
        this.selectLanguage(this.props.tenantSettings.defaultLCID);
    }

    public componentDidUpdate() {
        var toMap;
        if(this.props.platform === 'sms')
        {
            toMap = this.props.notificationSettings.settings[this.props.event].translatedBody;
        }
        else if(this.props.platform === 'email')
        {
            toMap = this.props.notificationSettings.settings[this.props.event].translatedSubject;
        }
        
        if(!Object.keys(toMap).includes(this.state.selectedLCID))
        {
            this.setState({selectedLCID: this.state.defaultLCID})
        }
    }

    public render() {
        const placeholder = this.props.placeholder ?? "";

        const lcidToLanguage = {};
        Object.keys(this.props.lcidMappings).forEach(k => lcidToLanguage[k] = this.props.lcidMappings[k].language)

        const availableLCID = Object.keys(this.props.lcid);
        const lcidButtons = availableLCID.map((lcid) => {
            return (
                <span
                    key={`${lcid}-buton`}
                    onClick={() => this.selectLanguage(lcid)}
                    className={`lcid-options-dropdown`}>
                    {lcidToLanguage[lcid]} ({lcid})
                </span>
            )
        })

        var toMap;
        if(this.props.platform === 'sms')
        {
            toMap = this.props.notificationSettings.settings[this.props.event].translatedBody;
        }
        else if(this.props.platform === 'email')
        {
            toMap = this.props.notificationSettings.settings[this.props.event].translatedSubject;
        }

        const tabs = Object.keys(toMap).map((lcid) => {
            const active = (lcid === this.state.selectedLCID) ? "active" : ""
            return (
                <span
                key={`${lcid}-tab`}
                onClick={() => this.selectLanguageTab(lcid)}
                className={`text-area-lcid-tab ${active}`}>

                    {lcidToLanguage[lcid]} ({lcid}) 
                    {lcid !== this.state.defaultLCID ?
                    <i 
                        style={{marginLeft: 5, cursor: "pointer", color: "#2f4050"}}
                        className="material-icons"
                        onClick={(e) => {
                            e.stopPropagation()
                            this.removeLcid(lcid)}}
                    >delete</i>
                    : null
                    }
                </span>
            )
        })

        return (

            <React.Fragment>
                <div className="language-section">
                    {tabs}
                    <div className="language-button-selector">
                        <button className="dropdown-button-lcid">+</button>
                        <div className="dropdown-btn-content-lcid">
                            {lcidButtons}
                        </div>
                    </div>                    
                </div>
                <div className="text-area-container-small">
                    <div className="text-area-container-single">
                        <span className="text-area-prompt">{this.props.prompt}</span>
                        <textarea 
                            value={this.props.platform === "sms" ? this.props.notificationSettings.settings[this.props.event].translatedBody[this.state.selectedLCID] : this.props.notificationSettings.settings[this.props.event].translatedSubject[this.state.selectedLCID]}
                            className="text-area-language-double-body" 
                            onChange={(e) => this.handleTextArea(e)}
                            maxLength={this.props.maxTextLength}
                            disabled={this.props.disabled}
                            placeholder={placeholder}>
                        </textarea>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    private removeLcid = (lcid: string) => {
        var newState;

        if(this.props.platform === 'sms')
        {
            newState = this.props.notificationSettings.settings[this.props.event].translatedBody;
        }
        else if(this.props.platform === 'email')
        {
            newState = this.props.notificationSettings.settings[this.props.event].translatedSubject;
        }

        delete newState[lcid];

        this.setState({selectedLCID: this.props.tenantSettings.defaultLCID}, () =>
        this.props.returnTranslatedContent(newState, this.props.platform, this.props.event))
        
    }

    private selectLanguageTab = (lcid: string) => {
        this.setState({
            ...this.state,
            selectedLCID: lcid,
        })
    }

    private selectLanguage = (lcid: string) => {
        var newState;
        if(this.props.platform === 'sms')
        {
            newState = this.props.notificationSettings.settings[this.props.event].translatedBody;
        }
        else if(this.props.platform === 'email')
        {
            newState = this.props.notificationSettings.settings[this.props.event].translatedSubject;
        }

        if (!Object.keys(newState).includes(lcid)) {
            newState[lcid] = "";
            this.props.returnTranslatedContent(newState, this.props.platform, this.props.event);
            this.selectLanguageTab(lcid)
        } else {
            this.selectLanguageTab(lcid)
        }
    }

    private handleTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        var toReturn
        if(this.props.platform === 'sms')
        {
            toReturn = this.props.notificationSettings.settings[this.props.event].translatedBody;
        }
        else if(this.props.platform === 'email')
        {
            toReturn = this.props.notificationSettings.settings[this.props.event].translatedSubject;
        }

        toReturn[this.state.selectedLCID] = e.target.value;
        this.props.returnTranslatedContent(toReturn, this.props.platform, this.props.event);
    }
}



const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        tenantSettings: state.settings.tenantSettings,
        lcidMappings: state.resources.lcidMappings,
        lcid: state.settings.tenantSettings.translatedContent,
        notificationSettings: state.settings.notificationSettings,
    }),
    {
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default (connector(SingleTextAreaInput));

