import React, { useState } from 'react';

import { ApiRequest } from 'api/network';

import Button from "@mui/material/Button";
import LoadingSpinner from '../loadingSpinner';
import { FileDownloader } from 'utils/fileDownloader';

const DownloadButtonv2: React.FunctionComponent<ComponentProps> = (props) => {

    const [isLoading, setIsLoading] = useState(false)

    const onClick = (e) => {
        e.stopPropagation();
        setIsLoading(true)
        const file =  {
          name: `${props.downloadName}-${new Date().toLocaleString().replaceAll(/, | /g, "/")}.csv`
        };
        props.onClick()
          .then(data => new FileDownloader(file).downloadBlob(data))
          .then(_ => setIsLoading(false))
          .catch(_ => setIsLoading(false));
    }

    return(
        <Button {...props.styles} onClick={(e) => onClick(e)}>
            {props.text}
            { isLoading ? 
                <span style={{paddingLeft: '3px', paddingTop: "2px"}}>
                    <LoadingSpinner size={'14px'} /> 
                </span>
                : null
            }
        </Button>
    )
} 

export default DownloadButtonv2;

interface ComponentProps {
    onClick: () => ApiRequest
    text: any
    downloadName: string
    styles: { //for material UI component 
        size: any
        variant?: any
        color: any
        disableElevation: boolean
        startIcon?: any
    }
}