import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";

import AuthoringSearch from "modules/common/components/filters/authoringSearch";
import FilterContainer from "modules/common/components/filters/filterContainer";
import FilterDropdown from "modules/common/components/filters/filterDropdown";

import { Audience } from "modules/audiences/models";
import { CategoryTag, DocumentAvailability, DocumentsFilterValues, DocumentState } from "../../models";

import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { PickerLocalization } from "modules/common/components/pickerLocalization";

import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";


import moment from "moment";


interface Option {
  id: string;
  text: string;
}

const Availabilities: Option[] = [
  { id: "onlineOnly", text: "Online only" },
  { id: "attachmentOnly", text: "Attachment only" }
];

const States: Option[] = [
  { id: "enabled", text: "Enabled" },
  { id: "scheduled", text: "Scheduled" },
  { id: "disabled", text: "Disabled" }
];

class DocumentFilters extends React.Component<PropsWithRedux, ComponentState> {
  constructor(props: PropsWithRedux) {
    super(props);

    this.state = {
      canSearch: !!props.filters.audiences!.length || !!props.filters.availabilities!.length || !!props.filters.lcids!.length || !!props.filters.tags!.length || !!props.filters.states!.length || !!props.filters.newerThan || !!props.filters.olderThan || !!props.filters.textToSearch,
      textToSearch: props.filters.textToSearch || ""
    };
  }

  public componentDidMount() {
    moment.locale("en");
  }

  public componentDidUpdate(prevProps: PropsWithRedux) {
    if (this.props.filters.textToSearch !== prevProps.filters.textToSearch)
      this.setState({ textToSearch: this.props.filters.textToSearch || "" });
  }

  public render() {
    const { filters } = this.props;

    const availableLanguages: Option[] = this.getAvailableLanguages();

    return (
      <FilterContainer
        filters={
          <React.Fragment>
            <TextField
              variant="outlined"
              size="small"
              value={this.state.textToSearch}
              placeholder="Search title, key words or author"
              InputProps={{
                startAdornment: <SearchIcon className="search-icon" />
              }}
              onChange={this.onUpdateTextToSearch}
              onKeyUp={this.onKeyUp}
              className="text-to-search"
            />
            <div className="filter-options-group">
              {this.props.id === "all" &&
                <FilterDropdown text="State">
                  <List disablePadding>
                    {States.map((state) =>
                      <ListItem key={state.id} dense button onClick={() => this.onChangeState(state.id as DocumentState)}>
                        <ListItemIcon className="callout-checkbox">
                          <Checkbox
                            edge="start"
                            tabIndex={-1}
                            disableRipple
                            size="small"
                            color="primary"
                            checked={!!filters.states!.find((selectedState) => selectedState === state.id)}
                          />
                        </ListItemIcon>
                        <ListItemText primary={state.text} />
                      </ListItem>
                    )}
                  </List>
                </FilterDropdown>
              }
              <FilterDropdown
                text={this.props.id === "scheduled"
                        ? "Scheduled for"
                        : this.props.id === "disabled"
                          ? "Disabled between"
                          : "Published between"
                    }
              >
                <PickerLocalization>
                  <div className="published-time">
                    <div>
                      <div>From</div>
                      <DatePicker
                          format="MMM dd yyyy"
                          value={filters.newerThan ? Date.parse(filters.newerThan) : null}
                          slotProps={{ 
                            textField: { size: "small", placeholder: "", inputProps: { readOnly: true }},
                            openPickerIcon: { color: "primary" }
                          }}
                          onChange={this.onChangePublishedFromDate}
                          className="date-picker"
                        />
                    </div>
                    <div>
                      <div>To</div>
                      <DatePicker
                          format="MMM dd yyyy"
                          value={filters.olderThan ? Date.parse(filters.olderThan) : null}
                          slotProps={{ 
                            textField: { size: "small", placeholder: "", inputProps: { readOnly: true }},
                            openPickerIcon: { color: "primary" }
                          }}
                          onChange={this.onChangePublishedToDate}
                          className="date-picker"
                        />
                    </div>
                  </div>
                </PickerLocalization>
              </FilterDropdown>
              {availableLanguages.length > 1 &&
                <FilterDropdown text="Language">
                  <List disablePadding>
                  {availableLanguages.map((language) =>
                    <ListItem key={language.id} dense button onClick={() => this.onChangeLcids(language.id)}>
                      <ListItemIcon className="callout-checkbox">
                        <Checkbox
                          edge="start"
                          tabIndex={-1}
                          disableRipple
                          size="small"
                          color="primary"
                          checked={!!filters.lcids!.find((lcid) => lcid === language.id)}
                        />
                      </ListItemIcon>
                      <ListItemText primary={language.text} />
                    </ListItem>
                  )}
                </List>
                </FilterDropdown>
              }
              {this.props.categoryTags.length > 0 &&
                <FilterDropdown
                  text="Category tags"
                  footer={
                    <div>
                      <Button variant="text" color="primary" onClick={this.onClearCategoryTags}>Clear all</Button>
                    </div>
                  }
                >
                  <List disablePadding className="category-tags">
                    {this.props.categoryTags.map((tag) =>
                      <ListItem key={tag.id} dense button onClick={() => this.onChangeCategoryTags(tag)}>
                        <ListItemIcon className="callout-checkbox">
                          <Checkbox
                            edge="start"
                            tabIndex={-1}
                            disableRipple
                            size="small"
                            color="primary"
                            checked={!!filters.tags!.find((selectedTag) => selectedTag === tag.id)}
                          />
                        </ListItemIcon>
                        <ListItemText primary={tag.name} />
                      </ListItem>
                    )}
                  </List>
                </FilterDropdown>
              }
              <FilterDropdown
                text="Accessible to"
                footer={
                  <div>
                    <Button variant="text" color="primary" onClick={this.onClearAudiences}>Clear all</Button>
                  </div>
                }
              >
                <List disablePadding className="audiences">
                  {this.props.audiences.map((audience) =>
                    <ListItem key={audience.id} dense button onClick={() => this.onChangeAudiences(audience)}>
                      <ListItemIcon className="callout-checkbox">
                        <Checkbox
                          edge="start"
                          tabIndex={-1}
                          disableRipple
                          size="small"
                          color="primary"
                          checked={!!filters.audiences!.find((selectedAudience) => selectedAudience === audience.id)}
                        />
                      </ListItemIcon>
                      <ListItemText primary={audience.displayName} />
                    </ListItem>
                  )}
                </List>
              </FilterDropdown>
              <FilterDropdown text="Availability">
                <List disablePadding>
                  {Availabilities.map((availability) =>
                    <ListItem key={availability.id} dense button onClick={() => this.onChangeAvailability(availability.id as DocumentAvailability)}>
                      <ListItemIcon className="callout-checkbox">
                        <Checkbox
                          edge="start"
                          tabIndex={-1}
                          disableRipple
                          size="small"
                          color="primary"
                          checked={!!filters.availabilities!.find((selectedAvailability) => selectedAvailability === availability.id)}
                        />
                      </ListItemIcon>
                      <ListItemText primary={availability.text} />
                    </ListItem>
                  )}
                </List>
              </FilterDropdown>
            </div>
          </React.Fragment>
        }
        filterCommands={
          <AuthoringSearch
            canSearch={this.state.canSearch}
            onApplyFilters={this.onChangeTextToSearch}
            onClearFilters={this.onClearFilters}
          />
        }
        filterSelection={this.getFilterSelection()}
      />
    );
  }


  private getAvailableLanguages = (): Option[] => {
    if (!this.props.tenantSettings || !this.props.lcidMappings)
      return [];

    let availableLanguages: Option[] = [];
    Object.keys(this.props.tenantSettings.translatedContent).map((lcid) => {
      if (!!this.props.lcidMappings[lcid])
        availableLanguages.push({ id: lcid, text: this.props.lcidMappings[lcid].language });
      return lcid;
    });
    return availableLanguages;
  }

  private getFilterSelection = (): JSX.Element | undefined => {
    const { audiences, categoryTags, filters, lcidMappings } = this.props;

    if (!filters.textToSearch && !filters.states!.length && !filters.newerThan && !filters.olderThan && !filters.lcids!.length && !filters.tags!.length && !filters.audiences!.length && !filters.availabilities!.length)
      return undefined;

    return (
      <React.Fragment>
        {!!filters.textToSearch &&
          <Chip
            key="search-text"
            label={`"${filters.textToSearch}"`}
            onDelete={this.onClearTextToSearch}
            deleteIcon={<CloseIcon />}
          />
        }
        {filters.states!.map((selectedState) =>
          <Chip
            key={selectedState}
            label={States.find((state) => state.id === selectedState)!.text}
            onDelete={() => this.onClearState(selectedState)}
            deleteIcon={<CloseIcon />}
          />
        )}
        {(!!filters.newerThan || !!filters.olderThan) &&
          <Chip
            key="published-time"
            label={`Released${!!filters.newerThan ? ` from ${moment(filters.newerThan).format("MMM D, YYYY")}` : ""}${!!filters.olderThan ? ` up to ${moment(filters.olderThan).format("MMM D, YYYY")}` : ""}`}
            onDelete={this.onClearPublishedTime}
            deleteIcon={<CloseIcon />}
          />
        }
        {filters.lcids!.map((selectedLcid) =>
          <Chip
            key={selectedLcid}
            label={lcidMappings[selectedLcid].language}
            onDelete={() => this.onClearLcid(selectedLcid)}
            deleteIcon={<CloseIcon />}
          />
        )}
        {filters.tags!.map((selectedTag) =>
          <Chip
            key={selectedTag}
            label={categoryTags.find((tag) => tag.id === selectedTag)!.name}
            onDelete={() => this.onClearCategoryTag(selectedTag)}
            deleteIcon={<CloseIcon />}
          />
        )}
        {filters.audiences!.map((selectedAudience) =>
          <Chip
            key={selectedAudience}
            label={audiences.find((audience) => audience.id === selectedAudience)!.displayName}
            onDelete={() => this.onClearAudience(selectedAudience)}
            deleteIcon={<CloseIcon />}
          />
        )}
        {filters.availabilities!.map((selectedAvailability) =>
          <Chip
            key={selectedAvailability}
            label={Availabilities.find((availability) => availability.id === selectedAvailability)!.text}
            onDelete={() => this.onClearAvailability(selectedAvailability)}
            deleteIcon={<CloseIcon />}
          />
        )}
      </React.Fragment>
    );
  }


  private onChangeAudiences = (audience: Audience) => {
    const hasSelectedAudience: boolean = !!this.props.filters.audiences!.find((selectedAudience) => selectedAudience === audience.id);
    if (hasSelectedAudience)
      this.onChangeFilters({ audiences: this.props.filters.audiences!.filter((selectedAudience) => selectedAudience !== audience.id) });
    else
      this.onChangeFilters({ audiences: this.props.filters.audiences!.concat([audience.id]) });
  }

  private onChangeAvailability = (availability: DocumentAvailability) => {
    const hasSelectedAvailability: boolean = !!this.props.filters.availabilities!.find((selectedAvailability) => selectedAvailability === availability);
    if (hasSelectedAvailability)
      this.onChangeFilters({ availabilities: this.props.filters.availabilities!.filter((selectedAvailability) => selectedAvailability !== availability) });
    else
      this.onChangeFilters({ availabilities: this.props.filters.availabilities!.concat([availability]) });
  }

  private onChangeCategoryTags = (tag: CategoryTag) => {
    const hasTag: boolean = !!this.props.filters.tags!.find((selectedTag) => selectedTag === tag.id);
    if (hasTag)
      this.onChangeFilters({ tags: this.props.filters.tags!.filter((selectedTag) => selectedTag !== tag.id) });
    else
      this.onChangeFilters({ tags: this.props.filters.tags!.concat([tag.id]) });
  }

  private onChangeFilters = (value: Partial<DocumentsFilterValues>) => {
    this.props.onChangeFilters({ ...this.props.filters, textToSearch: this.state.textToSearch, ...value });
    this.setCanSearch();
  }

  private onChangeLcids = (lcid: string) => {
    const hasSelectedLcid: boolean = !!this.props.filters.lcids!.find((selectedLcid) => selectedLcid === lcid);
    if (hasSelectedLcid)
      this.onChangeFilters({ lcids: this.props.filters.lcids!.filter((selectedLcid) => selectedLcid !== lcid) });
    else
      this.onChangeFilters({ lcids: this.props.filters.lcids!.concat([lcid]) });
  }

  private onChangePublishedFromDate = (date) => {
    this.onChangeFilters({ newerThan: moment(date).toISOString() });
  }

  private onChangePublishedToDate = (date) => {
    this.onChangeFilters({ olderThan: moment(date).toISOString() });
  }

  private onChangeState = (state: DocumentState) => {
    const hasSelectedState: boolean = !!this.props.filters.states!.find((selectedState) => selectedState === state);
    if (hasSelectedState)
      this.onChangeFilters({ states: this.props.filters.states!.filter((selectedState) => selectedState !== state) });
    else
      this.onChangeFilters({ states: this.props.filters.states!.concat([state]) });
  }
  
  private onChangeTextToSearch = () => {
    this.onChangeFilters({ textToSearch: this.state.textToSearch });
  }

  private onClearAudience = (audience: string) => {
    this.onChangeFilters({ audiences: this.props.filters.audiences!.filter((selectedAudience) => selectedAudience !== audience) });
  }

  private onClearAudiences = () => {
    this.onChangeFilters({ audiences: [] });
  }

  private onClearAvailability = (availability: string) => {
    this.onChangeFilters({ availabilities: this.props.filters.availabilities!.filter((selectedAvailability) => selectedAvailability !== availability) });
  }

  private onClearCategoryTag = (tag: string) => {
    this.onChangeFilters({ tags: this.props.filters.tags!.filter((selectedTag) => selectedTag !== tag) });
  }

  private onClearCategoryTags = () => {
    this.onChangeFilters({ tags: [] });
  }

  private onClearFilters = () => {
    this.clearCanSearch();
    this.props.onClearFilters();
  }

  private onClearLcid = (lcid: string) => {
    this.onChangeFilters({ lcids: this.props.filters.lcids!.filter((selectedLcid) => selectedLcid !== lcid) });
  }

  private onClearPublishedTime = () => {
    this.onChangeFilters({ newerThan: "", olderThan: "" });
  }

  private onClearState = (state: DocumentState) => {
    this.onChangeFilters({ states: this.props.filters.states!.filter((selectedState) => selectedState !== state) });
  }

  private onClearTextToSearch = () => {
    this.onChangeFilters({ textToSearch: "" });
  }
  
  private onKeyUp = (key) => {
    if (key.keyCode === 13)
      this.onChangeTextToSearch();
  }

  private onUpdateTextToSearch = (event) => {
    this.setState({ textToSearch: event.target.value });
    if(event.target.value && event.target.value !== "") {
      this.setCanSearch();
    }
    else if(this.hasNoCurrentFilters()){
      this.clearCanSearch();
    }
  }

  private clearCanSearch = () => {
    this.setState({ canSearch: false });
  }

  private setCanSearch = () => {
    this.setState({ canSearch: true });
  }

  private hasNoCurrentFilters = () => {
    let filters = this.props.filters;

    return (!filters.tags || filters.tags.length === 0) 
            && filters.textToSearch === "" 
            && (!filters.states || filters.states.length === 0)
            && !filters.newerThan && !filters.olderThan
            && (!filters.tags || filters.tags.length === 0)
            && (!filters.audiences || filters.audiences.length === 0)
            && (!filters.availabilities || filters.availabilities.length === 0);
  }
}
  

interface ComponentProps {
  id: "" | "all" | "enabled" | "scheduled" | "disabled" ;
  filters: Partial<DocumentsFilterValues>;
  onChangeFilters: (filters: Partial<DocumentsFilterValues>) => void;
  onClearFilters: () => void;
}

interface ComponentState {
  canSearch: boolean;
  textToSearch: string;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    audiences: state.audiences.audiences,
    categoryTags: state.categoryTags.userCategoryTags,
    lcidMappings: state.resources.lcidMappings,
    tenantSettings: state.settings.tenantSettings
  })
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(DocumentFilters);