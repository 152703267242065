import React from "react";
import { connect, ConnectedProps } from "react-redux";
import * as actions from "../../actionCreator";
import { GlobalApplicationState } from "globalApplicationState";

import Loading from "modules/common/components/loading";
import LoadingOverlay from "modules/common/components/loadingOverlay";

import { ActivityLogFilterValues, ActivityLogPage, SimpleTag, toEmailActivityEventName } from "../../models";

import EmailActivityLogFilters from "./emailActivityLogFilters";

import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveAltIcon from "@mui/icons-material/SaveAlt";

import moment from "moment";
import { FileDownloader } from "utils/fileDownloader";

class EmailActivityLog extends React.Component<PropsWithRedux, ComponentState> {
  constructor(props: PropsWithRedux) {
    super(props);

    this.state = {
      activityLog: undefined,
      filters: this.getDefaultFilters(props),
      isDownloading: false,
      isInitialized: false,
      isLoading: false
    };
  }

  public componentDidMount() {
    moment.locale("en");
    this.setState({filters: this.getDefaultFilters(this.props)});
    this.getActivityLog(1);
  }
  
  private getDefaultFilters(props: PropsWithRedux): ActivityLogFilterValues {
    return {
      eventNames: [],
      issueIds: !!props.defaultIssueId ? [props.defaultIssueId] : [],
      newsletterIds: !!props.selectedNewsletterId ? [props.selectedNewsletterId] : [],
      eventDateFrom: null,
      eventDateTo: null
    } as ActivityLogFilterValues;
  }

  public render() {
    return (
      <Drawer anchor="right" open={true} onClose={this.props.onClose} classes={{ paper: "newsletter-drawer" }}>
        <Paper square elevation={0} className="newsletter-drawer-header">
          <div>
            <IconButton onClick={this.props.onClose} size="large">
              <ArrowBackIcon />
            </IconButton>
            <div>{`Employee Activity Log${!!this.props.displayContext ? (' - ' + this.props.displayContext) : ''}`}</div>
          </div>
          <Button variant="outlined" color="primary" startIcon={<SaveAltIcon />} onClick={this.downloadActivityLog}>Download results</Button>
        </Paper>
        <div>
          {this.getList()}
        </div>
        <LoadingOverlay show={this.state.isDownloading} styles={{ paddingTop: 150 }} />
      </Drawer>
    );
  }

  private downloadActivityLog = () => {
    this.setState({ isDownloading: true });
    const file = {
      name:  `Newsletter Activity Log${!!this.props.displayContext ? (' - ' + this.props.displayContext) : ''}`
    };

    this.props.getActivityLogCSV(this.state.filters)
      .then((url) => new FileDownloader(file).downloadProvided(url))
      .then(_ => this.setState({ isDownloading: false }))
      .catch(_ => this.setState({ isDownloading: false }));
  }

  private getActivityLog = (pageNumber: number, filters: ActivityLogFilterValues = this.state.filters) => {
    this.setState({ isLoading: true });
    this.props.getActivityLog(pageNumber, filters).then((activityLog) => {
      this.setState({ activityLog, isInitialized: true, isLoading: false });
    });
  }

  private getList = () => {
    const { activityLog } = this.state;
    
    return (
      <div className="newsletter-drawer-content">
        <EmailActivityLogFilters
          filters={this.state.filters}
          newsletterTags={this.props.newsletterTags}
          issueTags={this.props.issueTags}
          onChangeFilters={this.onChangeFilters}
          onClearFilters={this.onClearFilters}
        />
        {this.state.isLoading || !activityLog
          ? <Loading />
          : activityLog.totalItems === 0
              ? <div>No activities were found.</div>
              : <React.Fragment>
                  <TableContainer>
                    <Table size="medium">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">Activity</TableCell>
                          <TableCell align="center">Event time</TableCell>
                          <TableCell align="center">Name</TableCell>
                          <TableCell align="center">Email</TableCell>
                          {!this.props.selectedNewsletterId && <TableCell align="center">Newsletter</TableCell>}
                          <TableCell align="center">Issue</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {activityLog.results.map((activity, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell align="left">{toEmailActivityEventName(activity.activity)}</TableCell>
                              <TableCell align="center">{moment(activity.eventTime).format("MMM D, YYYY, h:mmA")}</TableCell>
                              <TableCell align="center">{activity.userFullName}</TableCell>
                              <TableCell align="center">{activity.userEmail}</TableCell>
                              {!this.props.selectedNewsletterId && <TableCell align="center">{activity.newsletterTitle}</TableCell>}
                              <TableCell align="center">{moment(activity.issueDate,"YYYY-MM-DD").format("MMM D, YYYY")}</TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {activityLog.currentPage > 0 && activityLog.totalPages > 1 &&
                    <TablePagination
                      rowsPerPageOptions={[10]}
                      component="div"
                      backIconButtonProps={{ color: "primary" }}
                      nextIconButtonProps={{ color: "primary" }}
                      count={activityLog.totalItems}
                      rowsPerPage={10}
                      page={activityLog.currentPage - 1}
                      onPageChange={(ev, pageNumber) => {
                        this.getActivityLog(pageNumber + 1);
                      }}
                    />
                  }
                </React.Fragment>
        }
      </div>
    );
  }


  private onChangeFilters = (filters: ActivityLogFilterValues) => {
    this.setState({ filters: filters });
    this.getActivityLog(1, filters);
  }
  
  private onClearFilters = () => {
    const filters = this.getDefaultFilters(this.props);
    this.setState({ filters: filters });
    this.getActivityLog(1, filters);
  }
}

interface ComponentProps {
  selectedNewsletterId?: string;
  defaultIssueId?: string | null;
  newsletterTags?: SimpleTag[];
  issueTags?: SimpleTag[];
  displayContext?: string;
  onClose: () => any;
}

interface ComponentState {
  activityLog?: ActivityLogPage;
  filters: ActivityLogFilterValues;
  isDownloading: boolean;
  isLoading: boolean;
  isInitialized:boolean;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps
  }),
  {
    getActivityLog: actions.getActivityLog,
    getActivityLogCSV: actions.getActivityLogCSV
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(EmailActivityLog);