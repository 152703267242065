import { PostRead } from '../models';
import { UserListItem } from 'modules/users/models';

export interface IAwarenessScore {
    x: number;
    y: number;
    monthIndex: string;
}

export class TagAwarenessCalculator {
    public static calculate = (readData: PostRead[], userList: UserListItem[]): IAwarenessScore[] => {
        let points = new Array<IAwarenessScore & {monthIndex: string}>();
        let readStats = readData || [];
        let users = userList || [];

        if (readStats.length && users.length) {

            const firstDate = new Date(readStats[0].timestamp);
            const today = new Date();

            const numDaysInSet = TagAwarenessCalculator.daysBetween(firstDate, today) + 1;

            // create initial list of data points
            for (let i = 0; i < numDaysInSet; i++) {

                const pointDate = new Date(firstDate.valueOf());
                pointDate.setUTCDate(pointDate.getUTCDate() + i);

                const point = {
                    x: i,
                    y: 0,
                    date: pointDate,
                    monthIndex: pointDate.getUTCMonth() + '-' + pointDate.getUTCFullYear()
                };
                points.push(point);
            }

            const userScoreDict = {};

            const alreadyReadThatDayDict = {};

            for (let i = 0; i < readStats.length; i++) {

                const readStat = readStats[i];
                const readStatDate = new Date(readStat.timestamp);
                const readStatIndex = TagAwarenessCalculator.daysBetween(firstDate, readStatDate);

                const alreadyReadThatDayDictKey = `${readStatDate.getMonth()}/${readStatDate.getDate()}/${readStatDate.getFullYear()}|${readStat.userId}`;
                if (alreadyReadThatDayDict[alreadyReadThatDayDictKey]) {
                    continue;
                } else {
                    alreadyReadThatDayDict[alreadyReadThatDayDictKey] = 1;
                }

                const point = points[readStatIndex];
                const existingScoreKey = `${readStat.userId}|${readStat.postId}`;
                const existingUserScore = userScoreDict[existingScoreKey];

                const usersScore = Math.min(existingUserScore ? existingUserScore * (1.15 * readStat.depth) : 1 * readStat.depth, 1);

                userScoreDict[existingScoreKey] = usersScore;

                point.y += usersScore;
            }

            // calculate actuals using decay
            for (let i = 0; i < points.length; i++) {

                let previousStep = i > 0 ? points[i - 1] : null;
                let decayedPreviousStepAwareness = previousStep ? previousStep.y * 0.99 : 0;

                let currentStep = points[i];

                let numberOfUsers = users.length;

                let currentStepAwareness = (currentStep.y / numberOfUsers) * 100;

                currentStep.y = Math.min(100, decayedPreviousStepAwareness + currentStepAwareness);
            }
        }
        return points;
    }

    private static daysBetween = ( date1, date2 ) => {
        // Get 1 day in milliseconds
        const one_day = 1000 * 60 * 60 * 24;

        // Convert both dates to milliseconds
        const date1_ms = date1.getTime();
        const date2_ms = date2.getTime();

        // Calculate the difference in milliseconds
        const difference_ms = date2_ms - date1_ms;

        // Convert back to days and return
        return Math.round(difference_ms / one_day);
    }
}

