import * as actions from 'network/actions';

export const FetchIdealWordCountLimit = () => {
    return actions.NetworkRequest({
        method: 'GET',
        url: '/{tenant}/adminapi/v1/authoring/wordCountTip',
    })
}

export const FetchCurrentPostSentiment = (content: string) => {
    //only send text content for sentiment analysis
    const strippedContent = new DOMParser().parseFromString(content, "text/html").body.textContent || ""
    return actions.NetworkRequest({
        method: 'POST',
        url: '/{tenant}/adminapi/v1/authoring/sentiment',
        body: { 'Content': strippedContent }
    })
}
