import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import { withRouter, RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import Cookies from "utils/cookie";

import SignOutListItem from "./SignOutListItem";
import Account from "./Account";

import AppBar from "@mui/material/AppBar";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Popover from "@mui/material/Popover";
import Toolbar from "@mui/material/Toolbar";

import { Theme } from "@mui/material/styles";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import MenuIcon from "@mui/icons-material/Menu";
import { KeyboardArrowRight } from "@mui/icons-material";
import { Backdrop, Button, useMediaQuery, useTheme } from "@mui/material";
import Loading from "../loading";
import { MobileAppBanner } from "../banners/openInMobileAppBanner";
import { truncateStringWithElipsis } from "utils/stringFormatting";

interface IStyleProps {
    headerBackgroundColor?: string;
}

const useStyles = makeStyles<Theme, IStyleProps>((theme: Theme) =>
    createStyles({
        header: ({ headerBackgroundColor }) => ({
            backgroundColor: headerBackgroundColor || theme.palette.background.default,
            color: theme.palette.text.primary,
            "& .tenant-name": {
                marginLeft: 24,
            },
        }),
        info: {
            display: "inline-flex",
            alignItems: "center",
        },
        infoDivider: {
            marginBottom: 8,
            marginTop: 8,
        },
        menuIcon: {
            marginRight: 5,
        },
        options: {
            position: "relative",
        },
        optionsMenu: {
            fontSize: 14,
            "& a": {
                color: "inherit",
                textDecoration: "none",
            },
        },
        optionsMenuItem: {
            "&:hover": {
                backgroundColor: theme.palette.background.default,
                cursor: "pointer",
            },
            "& svg": {
                fill: "#888888",
            },
        },
        optionsMenuItemIcon: {
            minWidth: 36,
        },
        optionsMenuItemText: {
            fontSize: 14,
        },
        toolbar: {
            display: "flex",
            justifyContent: "space-between",
            padding: "0 9px",
        },
        wordmark: {
            marginLeft: 10,
            width: 190,
            marginRight: 20,
        },
        updateHeader: {
            backgroundColor: "#366531"
        },
        updateToolbar: {
            display: "flex",
            justifyContent: "center",
        },
        updateToolbarBtn: {
            textTransform: "none",
            color: "#FFFFFF"
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            backgroundColor: "#ffffff80",
        }
    })
);

const Header: React.FunctionComponent<PropsWithRedux> = ({
    publicAccess,
    onClickMenu,
    displayName,
    wordMark,
    history,
    headerBackgroundColor,
    showRole,
    linkToProfile,
    light,
    headerStyle,
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const classes = useStyles({ headerBackgroundColor });
    const [showUpdateAppBar, setShowUpdateAppBar] = useState(false);
    const [waitingForUpdate, setWaitingForUpdate] = useState(false);
    const theme = useTheme();
    const isSmallAndSmaller = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });

    const update = async () => {
        try {
            setWaitingForUpdate(true);
            const registration = await navigator.serviceWorker.getRegistration();

            // if somehow the user is allowed to update but we don't have a service worker, reload to re-render the page
            if (registration === undefined) return;

            // check to see if we are running an older version of the application
            if (registration.active && registration.waiting) {
                // force the waiting service worker to skip its waiting and replace the older version
                // see https://github.com/facebook/create-react-app/issues/5316
                registration.waiting.postMessage({ type: 'SKIP_WAITING' });
            }
        }
        catch(_) { window.location.reload(); }
    }

    useEffect(() => {
        const checkForUpdate = async () => {
            if (!('serviceWorker' in navigator)) return;

            // get our current service worker registration
            const registration = await navigator.serviceWorker.getRegistration();
            if (registration === undefined) return;

            // if we have a service worker waiting on an active service worker,
            // it means we have an update pending and user is on an older version
            if (registration.active && registration.waiting) {
                setShowUpdateAppBar(true);
            }
            else {
                // set a listener on the registration for updates to display the banner
                registration.onupdatefound = () => {
                    const installingWorker = registration.installing as ServiceWorker;
                    installingWorker.onstatechange = () => {
                        // if an update is found, and our service worker's state has shifted from
                        // installing -> installed/waiting, then the update is ready to go
                        if (installingWorker.state === 'installed' && navigator.serviceWorker.controller) {
                            setShowUpdateAppBar(true);
                        }
                    };
                };
            }
        }

        checkForUpdate();
    }, []);

    return (
        <>
            <MobileAppBanner />
            {showUpdateAppBar &&
                <AppBar position="relative" className={classes.updateHeader}>
                    <Toolbar variant="dense" className={classes.updateToolbar}>
                        <Button
                            className={classes.updateToolbarBtn}
                            onClick={update}
                            endIcon={!waitingForUpdate && <KeyboardArrowRight />}
                        >
                            A new version is available.
                            {waitingForUpdate ? ' Updating...' : ' Update now'}
                        </Button>
                    </Toolbar>
                </AppBar>
            }
            <Backdrop open={waitingForUpdate} className={classes.backdrop}>
                <Loading style={{ padding: 50 }} containerStyle={{ height: "100%" }} />
            </Backdrop>
            <AppBar position="relative" elevation={0} className={classes.header} style={headerStyle}>
                <Toolbar variant="dense" disableGutters className={classes.toolbar}>
                    <div className={classes.info}>
                        <IconButton onClick={onClickMenu} className={classes.menuIcon}>
                            <MenuIcon />
                        </IconButton>
                        <Divider light={light} flexItem orientation="vertical" className={classes.infoDivider} />
                        {wordMark && <img src="/images/wordmark.svg" alt="wordmark" className={classes.wordmark} />}
                        <div className="tenant-name">
                            {isSmallAndSmaller
                                ? truncateStringWithElipsis(displayName, 18)
                                : displayName}
                        </div>
                    </div>
                    <div className={classes.options}>
                        <Account showRole={showRole} linkToProfile={linkToProfile} />
                        <IconButton onClick={(event: any) => setAnchorEl(event.currentTarget)}>
                            <MoreVertIcon />
                        </IconButton>
                        <Popover
                            open={!!anchorEl}
                            anchorEl={anchorEl}
                            onClose={() => setAnchorEl(null)}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            className={classes.optionsMenu}
                        >
                            <List disablePadding>
                                <a href="https://support.sparrowconnected.com" rel="noopener noreferrer" target="_blank">
                                    <ListItem className={classes.optionsMenuItem}>
                                        <ListItemIcon className={classes.optionsMenuItemIcon}>
                                            <HelpOutlineIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Help Center" classes={{ primary: classes.optionsMenuItemText }} />
                                    </ListItem>
                                </a>
                                <Divider light />
                                {!publicAccess && Cookies.get("multitenancy") === "true" && (
                                    <React.Fragment>
                                        <ListItem
                                            onClick={() => {
                                                Cookies.remove("tenantId");
                                                Cookies.remove("multitenancy");
                                                history.replace("/");
                                                window.location.reload();
                                            }}
                                            className={classes.optionsMenuItem}
                                        >
                                            <ListItemIcon className={classes.optionsMenuItemIcon}>
                                                <SyncAltIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Switch organization" classes={{ primary: classes.optionsMenuItemText }} />
                                        </ListItem>
                                        <Divider light />
                                    </React.Fragment>
                                )}
                                {!publicAccess ? (
                                    <SignOutListItem optionsMenuItemClassName={classes.optionsMenuItem} optionsMenuItemTextClassName={classes.optionsMenuItemText} />
                                ) : (
                                    <Link to="/">
                                        <ListItem className={classes.optionsMenuItem}>
                                            <ListItemText primary="Sign in" classes={{ primary: classes.optionsMenuItemText }} />
                                            <ExitToAppIcon />
                                        </ListItem>
                                    </Link>
                                )}
                            </List>
                        </Popover>
                    </div>
                </Toolbar>
            </AppBar>
        </>
    );
};

interface ComponentProps {
    onClickMenu: () => void;
    publicAccess?: boolean;
    wordMark?: boolean;
    displayName: string;
    headerBackgroundColor?: string;
    showRole?: boolean;
    linkToProfile?: boolean;
    light?: boolean;
    headerStyle?: React.CSSProperties;
}

const connector = connect((state: GlobalApplicationState, ownProps: ComponentProps & RouteComponentProps) => ({
    ...ownProps,
}));
type PropsWithRedux = ConnectedProps<typeof connector>;

export default withRouter(connector(Header));

