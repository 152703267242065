import React from "react";

import CalloutHover from "modules/common/components/hovers/calloutHover";

import Button from "@mui/material/Button";

import AddIcon from "@mui/icons-material/Add";


const NewAudience: React.FunctionComponent<ComponentProps> = props => {
  if (!props.disabled)
    return (
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={props.onClick}
        disabled={props.loadingChecks}
      >
        New Audience
      </Button>
    );

  return (
    <CalloutHover
      arrow
      component={
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          disabled={props.disabled}
        >
          New Audience
        </Button>
      }
    >
      <div className="disabled-button-message">
        You have reached the limit for creating new audiences. Contact support to upgrade your plan.
      </div>
    </CalloutHover>
  );
}

interface ComponentProps {
  disabled: boolean;
  loadingChecks: boolean;
  onClick: () => void;
}

export default NewAudience;
