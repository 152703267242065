import { Button, Dialog, DialogContent, DialogTitle, Divider, IconButton, Link, TextField } from '@mui/material';
import { GlobalApplicationState } from 'globalApplicationState';
import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';

import "../../styles/dialogs.sass"
import SearchIcon from '@mui/icons-material/Search';
import { NewsletterTheme } from 'modules/newsletters/models';
import { actions } from 'modules/newsletters';
import Loading from 'modules/common/components/loading';
import ThemeDisplayWithDropdown from 'modules/newsletters/edit/components/themeDisplayWithDropdown';
import Disclaimer from 'modules/common/components/disclaimer';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ErrorSnackbar from 'modules/common/components/snackbars/errorSnackbar';

interface ThemeListItem {
  theme: NewsletterTheme;
  isOpen: boolean;
}

class ThemeModal extends React.Component<PropsWithRedux, ComponentState> {
    constructor(props: PropsWithRedux) {
      super(props);
      this.state = {
        searchText: "",
        filteredThemes: [],
        themes: [],
        errorMessage: ""
      }
  }

  private loadThemes = () => {
    this.props.getThemes().then((themes) => {
      if(!!themes){
        let sortedThemes = themes
          .sort((a, b) => a.name.localeCompare(b.name));

        let mappedThemes = sortedThemes
          .map(t => ({theme: t, isOpen: false}));

        this.setState({themes: sortedThemes, filteredThemes: mappedThemes});
      }
    })
  }

  public componentDidMount = () => {
    this.loadThemes();
  }

  //Takes in a list of themes and both sorts and updates the list.
  private updateThemes = (themes: NewsletterTheme[], updateNonFiltered: boolean) => {
    let sortedThemes = themes
          .sort((a, b) => a.name.localeCompare(b.name));

    let mappedThemes = sortedThemes
      .map(t => ({theme: t, isOpen: false}));

    if(updateNonFiltered)
      this.setState({themes: sortedThemes, filteredThemes: mappedThemes});
    else
      this.setState({filteredThemes: mappedThemes});
  }

  private deleteTheme = async(id: string, isCustom: boolean) => {
    let themeToRemove = this.state.themes.find(t => t.id === id);
    if(!!themeToRemove) {
      if(themeToRemove.isCustom && !themeToRemove.isDefault) {
        let newThemes = await this.props.deleteTheme(id, isCustom);
        if(newThemes.length > 0) {
          this.updateThemes(newThemes, true);
        }
      }
      else {
        this.setState({errorMessage: "Cannot remove this theme, is default or standard."});
      }
    }
    else {
      this.setState({errorMessage: "Theme does not exist."});
    }
  }

  private setDefaultTheme = async(id: string, isCustom: boolean) => {
    let themeToSet = this.state.themes.find(t => t.id === id);
    if(!!themeToSet) {
      await this.props.setDefault(id, isCustom);
      this.loadThemes();
    }
    else {
      this.setState({errorMessage: "Theme does not exist."});
    }
  }
  
  public render() {
    const totalOpen = this.state.filteredThemes.filter(f => f.isOpen).length;

    return (
      <React.Fragment>
        <Dialog maxWidth="md" fullWidth scroll="paper" open={this.props.dialogOpen} onClose={this.props.onClose} classes={{ paper: "newsletter-dialog" }}>
          <ErrorSnackbar errorMessage={this.state.errorMessage} clearErrorMessage={this.clearErrorMessage} />
          <DialogTitle className="newsletter-dialog-title">
            <div>Manage Themes</div>
            <div>
              <IconButton onClick={this.props.onClose} size="large">
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent style={{height: "700px"}}>
            {this.state.themes.length === 0 && //can do this because there's always at least 1 theme.
              <div>
                <Loading />
              </div>
            }
            {this.state.themes.length > 0 &&
              <div style={{marginRight: "4px", paddingTop: "8px"}}>

                <Disclaimer
                  icon={<InfoOutlinedIcon />}
                  text={
                    <React.Fragment>
                      <span className="successfully-scanned">
                        Set a theme as default to automatically load its colors and images when new newsletters are created. 
                        Standard themes cannot be edited or deleted. <Link onClick={() => window.open("https://support.sparrowconnected.com/en/managing-newsletter-themes")} underline="always">Learn more</Link>.
                      </span>
                    </React.Fragment>
                  }>
                </Disclaimer>
                
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={this.state.searchText}
                  placeholder="Search theme or author"
                  InputProps={{
                    startAdornment: <SearchIcon className="search-icon" />,
                    endAdornment: this.state.filteredThemes.length !== this.state.themes.length 
                        && <CloseIcon htmlColor="grey" style={{cursor: "pointer"}} onClick={this.resetSearch}/>
                  }}
                  onChange={(e) => this.onUpdateTextToSearch(e.target.value)}
                  onKeyUp={this.onKeyPress}
                  style={{marginTop: "14px", width: "82%"}}
                />
                
                {totalOpen >= 1
                && <Button style={{marginTop: "14px", float: "right"}} onClick={() => this.setFlip(false)}>
                  Collapse All
                </Button>
                }
                {totalOpen === 0
                && <Button style={{marginTop: "14px", float: "right"}} onClick={() => this.setFlip(true)}>
                  Expand All
                </Button>
                }

                <div style={{marginTop: "15px", width: "100%", maxHeight: "500px", overflowY: "auto", minHeight: "500px"}}>
                    {this.state.filteredThemes.map((themeData, key) => 
                      <ThemeDisplayWithDropdown 
                        key={key} 
                        theme={themeData.theme} 
                        isOpen={themeData.isOpen} 
                        setOpen={this.setIndexOpen} 
                        index={key}
                        deleteTheme={this.deleteTheme}
                        defaultTheme={this.setDefaultTheme}
                      />
                    )}
                </div>
              </div>
              }

              <div>
                <Divider />
                <Button style={{marginTop: "10px", float: "right"}} onClick={() => this.props.onClose()}>Back to manage newsletters</Button>
              </div>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }

  private clearErrorMessage = () => {
    this.setState({ errorMessage: "" });
  }

  private onUpdateTextToSearch = (toSet: string) => {
      this.setState({searchText: toSet})
  }

  private setIndexOpen = (index: number, toSet: boolean) => {
    let newState = this.state.filteredThemes;
    if(index < this.state.filteredThemes.length) {
      newState[index].isOpen = toSet;
    }
    this.setState({filteredThemes: newState});
  }

  private setFlip = (toSet: boolean) => {
    if(toSet) {
      let newFiltered = this.state.filteredThemes;
      newFiltered = newFiltered.map(theme => {
        theme.isOpen = true;
        return theme;
      });
      this.setState({filteredThemes: newFiltered});
    }
    else
    {
      let newFiltered = this.state.filteredThemes;
      newFiltered = newFiltered.map(theme => {
        theme.isOpen = false;
        return theme;
      });
      this.setState({filteredThemes: newFiltered});
    }
  }

  private onKeyPress = (key) => {
      if (key.keyCode === 13 && !!this.state.searchText) {
          var newThemes = this.state.themes
          .filter(t => t.name.toLowerCase().includes(this.state.searchText.toLowerCase())
            || t.lastModifiedByDisplayName?.toLowerCase()
          .includes(this.state.searchText.toLowerCase()))
          this.updateThemes(newThemes, false);
      }
      else if(key.keyCode === 13 && !this.state.searchText) {
        this.resetSearch();
      }
  }

  private resetSearch = () => {
    this.updateThemes(this.state.themes, false);
    this.setState({searchText: ""});
  }

}

interface ComponentProps {
  onClose: () => any;
  dialogOpen: boolean;
}

interface ComponentState {
  searchText: string;
  filteredThemes: ThemeListItem[];
  themes: NewsletterTheme[];
  errorMessage: string;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => 
  ({
    ...ownProps,
    tenant: state.tenant.id,
  }),
  {
    getThemes: actions.getThemes,
    deleteTheme: actions.deleteTheme,
    setDefault: actions.setThemeDefault
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(ThemeModal);